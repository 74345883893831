import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import { Button, Select } from '../../../../../../../common/components';
import valueGet from '../common/valueGet';
import widgetConfigParamGet from '../common/widgetConfigParamGet';

const OthersConfig = (props) => {
  const assetTypeCollection = widgetConfigParamGet(
    props.widgetConfig,
    'assetType'
  ).options;

  const [initialized, setInitialized] = useState(false);

  const [assetType, setAssetType] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setAssetType(conf && conf.assetType && conf.assetType.value);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      assetType: valueGet(assetType),
      ...object
    };

    props.appInputParamChanged(value);
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={12}>
            <div className='new-input-wrap'>
              <label className='label-text'>Asset Type</label>

              <Select
                isMulti={false}
                options={assetTypeCollection.reduce((memo, value) => {
                  return [
                    ...memo,
                    {
                      label: value,
                      value
                    }
                  ];
                }, [])}
                placeholder='Select Asset Type'
                value={(() => {
                  const value = assetType;

                  return {
                    label: value,
                    value
                  };
                })()}
                onChange={({ value }) => {
                  setAssetType(value);

                  appInputParamChangedHandle({
                    assetType: valueGet(value)
                  });
                }}
              />
            </div>
          </Col>
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default OthersConfig;
