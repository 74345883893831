import { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';

import collectionGet from './collectionGet';
import { URL } from '../../../../../constants';

const allPlantString = 'All Plant';

const memoize = (fn) => {
  let cache = {};

  return (...args) => {
    const key = JSON.stringify(args);

    return cache[key]
      ? Promise.resolve(cache[key])
      : (() => {
          return fn(...args).then((result) => {
            cache = {
              ...cache,
              [key]: result
            };

            return result;
          });
        })();
  };
};

const tagOptionCollectionByPlantIdGet = memoize((plantId) => {
  const isAllPlant = plantId === allPlantString;

  return fetch(
    `${URL}/tags/details/${isAllPlant ? '' : `?plant_id=${plantId}`}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: JSON.parse(localStorage.getItem('smartsense.authToken'))
      }
    }
  )
    .then((response) => {
      return response.json();
    })
    .then(({ result }) => {
      const optionCollection = result.map(
        ({ tag_name, tag_id, parameter_id, plant_id }) => ({
          label: tag_name,
          value: {
            tagId: tag_id,
            parameterId: parameter_id,
            plantId: plant_id
          }
        })
      );

      return isAllPlant
        ? (() => {
            const plantIdCollection = JSON.parse(
              localStorage.getItem('smartsense.member_plants')
            ).map(({ plantid }) => plantid);

            const optionCollectionGrouped = optionCollection.reduce(
              (memo, option) => {
                const key = option.value.tagId;

                return {
                  ...memo,
                  [key]: [
                    ...(memo[key] || []),
                    ...(() => {
                      return plantIdCollection.includes(option.value.plantId)
                        ? [
                            (() => {
                              const {
                                value: { plantId, ...value }
                              } = option;

                              return {
                                ...option,
                                value
                              };
                            })()
                          ]
                        : [];
                    })()
                  ]
                };
              },
              {}
            );

            const output = Object.values(optionCollectionGrouped).reduce(
              (memo, _optionCollectionGrouped) => {
                return [
                  ...memo,
                  _optionCollectionGrouped.reduce((_memo, option) => {
                    return {
                      ..._memo,
                      ...option,
                      value: {
                        ...option.value,
                        parameterIdCollection: [
                          ...((_memo.value &&
                            _memo.value.parameterIdCollection) ||
                            []),
                          option.value.parameterId
                        ]
                      }
                    };
                  }, {})
                ];
              },
              []
            );

            return output;
          })()
        : optionCollection;
    });
});

const usePlantCollection = (tagConfCollection, allPlantFlag) => {
  const plantOptions = (() => {
    const key = 'smartsense.member_plants';

    return localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key)).map(({ name, plantid }) => ({
          label: name,
          value: { plantId: plantid }
        }))
      : [];
  })();

  const [plantCollection, setPlantCollection] = useState([]);

  const plantCollectionGet = useCallback((tagConfCollection) => {
    return tagConfCollection.reduce((memo, { plant }) => {
      return [...memo, plant];
    }, []);
  }, []);

  useEffect(() => {
    setPlantCollection(plantCollectionGet(tagConfCollection));
  }, [tagConfCollection, plantCollectionGet]);

  return [
    plantCollection,
    setPlantCollection,
    [
      ...(allPlantFlag
        ? [{ label: allPlantString, value: { plantId: allPlantString } }]
        : []),
      ...plantOptions
    ]
  ];
};

const useTagOptionsCollection = (tagConfCollection) => {
  const [tagOptionsCollection, setTagOptionsCollection] = useState([]);

  const [tagOptionsLoaded, setTagOptionsLoaded] = useState();

  const tagOptionsCollectionGet = useCallback((tagConfCollection) => {
    return tagConfCollection.reduce(
      (_memo, { plant: { value: { plantId } = {} } = {} }) => {
        return _memo.then((memo) => {
          return plantId
            ? tagOptionCollectionByPlantIdGet(plantId).then((tagOptions) => {
                return [...memo, tagOptions];
              })
            : [...memo, []];
        });
      },
      Promise.resolve([])
    );
  }, []);

  useEffect(() => {
    setTagOptionsLoaded(false);

    tagOptionsCollectionGet(tagConfCollection).then((tagOptionsCollection) => {
      tagConfCollection.length === tagOptionsCollection.length &&
        (() => {
          setTagOptionsCollection(tagOptionsCollection);

          setTagOptionsLoaded(true);
        })();
    });
  }, [tagConfCollection, tagOptionsCollectionGet]);

  return [
    tagOptionsCollection,
    tagOptionsLoaded,
    setTagOptionsCollection,
    setTagOptionsLoaded
  ];
};

const useTagCollection = (tagConfCollection) => {
  const [tagCollection, setTagCollection] = useState([]);

  const tagCollectionGet = useCallback((tagConfCollection) => {
    return tagConfCollection.reduce((memo, { tag }) => {
      return [...memo, tag];
    }, []);
  }, []);

  useEffect(() => {
    setTagCollection(tagCollectionGet(tagConfCollection));
  }, [tagConfCollection, tagCollectionGet]);

  return [tagCollection, setTagCollection];
};

export default (tagConfCollection, allPlantFlag) => {
  const [plantCollection, setPlantCollection, plantOptions] =
    usePlantCollection(tagConfCollection, allPlantFlag);

  const [
    tagOptionsCollection,
    tagOptionsLoaded,
    setTagOptionsCollection,
    setTagOptionsLoaded
  ] = useTagOptionsCollection(tagConfCollection);

  const [tagCollection, setTagCollection] = useTagCollection(tagConfCollection);

  const onPlantChangeHandle = useCallback(
    (plant, index) => {
      setPlantCollection((plantCollection) => {
        return !_.isEqual(plant, plantCollection[index])
          ? (() => {
              setTagCollection((tagCollection) => {
                return [
                  ...tagCollection.slice(0, index),
                  null,
                  ...tagCollection.slice(index + 1)
                ];
              });

              setTagOptionsCollection((tagOptionsCollection) => {
                return collectionGet([], index, tagOptionsCollection);
              });

              setTagOptionsLoaded(false);

              plant.value.plantId
                ? tagOptionCollectionByPlantIdGet(plant.value.plantId).then(
                    (tagOptions) => {
                      setTagOptionsCollection((tagOptionsCollection) => {
                        return collectionGet(
                          tagOptions,
                          index,
                          tagOptionsCollection
                        );
                      });

                      setTagOptionsLoaded((tagOptionsLoaded) => {
                        return tagOptionsLoaded ? tagOptionsLoaded : true;
                      });
                    }
                  )
                : setTagOptionsCollection((tagOptionsCollection) => {
                    return collectionGet([], index, tagOptionsCollection);
                  });

              return collectionGet(plant, index, plantCollection);
            })()
          : plantCollection;
      });
    },
    [
      setPlantCollection,
      setTagCollection,
      setTagOptionsCollection,
      setTagOptionsLoaded
    ]
  );

  const onTagChangeHandle = (tag, index, callback) => {
    const value = {
      plant: plantCollection[index],
      tag
    };

    callback(value, index);
  };

  return [
    plantCollection,
    plantOptions,
    tagCollection,
    tagOptionsCollection,
    onPlantChangeHandle,
    onTagChangeHandle,
    tagOptionsLoaded
  ];
};
