import React, {
  useMemo,
  useContext,
  useCallback,
  useState,
  useEffect,
  Fragment
} from 'react';
import queryString from 'query-string';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './ConfigComponents/WidgetConfig.scss';

import { Context } from '../../../Main/context';
import { mergeConfig, defaultParamsConfig, widgets } from '../../widgetsConfig';
import {
  getAllWidgetConfig,
  createWidget,
  resetCreateWidget,
  editWidget,
  resetEditWidget
} from '../../action';
import {
  addTemplateWidget,
  resetAddWidgetTemplate,
  updateWidgetTemplate,
  resetUpdateWidgetTemplate
} from '../../../AssetScreenDesign/action';
import { getServiceByName } from '../../../../../common/utils/services';
import WidgetConfigHistory from '../WidgetConfigHistory';
import MetaConfig from './ConfigComponents/MetaConfig';
import DateTimeConfig from './ConfigComponents/DateTimeConfig';
import GraphParameterConfig from './ConfigComponents/Graph/ParameterConfig';
import DigitboxParameterConfig from './ConfigComponents/DigitboxParameter/DigitboxParameter';
import DialParameterConfig from './ConfigComponents/Dial/ParameterConfig';
import PreviewConfig from './ConfigComponents/PreviewConfig';
import DialOthersConfig from './ConfigComponents/Dial/OthersConfig';
import CycleDistributionParameterConfig from './ConfigComponents/CycleDistribution/ParameterConfig';
import CycleDistributionOthersConfig from './ConfigComponents/CycleDistribution/OthersConfig';
import RunHoursParameterConfig from './ConfigComponents/RunHours/ParameterConfig';
import ImageUploadDateTimeConfig from './ConfigComponents/ImageUpload/DateTimeConfig';
import ImageUploadParameterConfig from './ConfigComponents/ImageUpload/ParameterConfig';
import ImageUploadOthersConfig from './ConfigComponents/ImageUpload/OthersConfig';
import HeatmapParameterConfig from './ConfigComponents/Heatmap/ParameterConfig';
import AssetInsightOthersConfig from './ConfigComponents/AssetInsight/OthersConfig';
import SensorHealthOthersConfig from './ConfigComponents/SensorHealth/OthersConfig';
import MapViewDateTimeConfig from './ConfigComponents/MapView/DateTimeConfig';
import ParameterPerformanceDateTimeConfig from './ConfigComponents/ParameterPerformance/DateTimeConfig';
import ParameterPerformanceOthersConfig from './ConfigComponents/ParameterPerformance/OthersConfig';
import TableDateTimeConfig from './ConfigComponents/Table/DateTimeConfig';
import PieChartParameterConfig from './ConfigComponents/PieChart/ParameterConfig';
import OverviewParameterConfig from './ConfigComponents/Overview/ParameterConfig';
import ParameterTrackingPrameterConfig from './ConfigComponents/ParameterTracking/ParameterConfig';
import AnomalyDetectionParameterConfig from './ConfigComponents/AnomalyDetection/ParameterConfig';
import ListViewParameterConfig from './ConfigComponents/ListView/ParameterConfig';
import ListViewOthersConfig from './ConfigComponents/ListView/OthersConfig';
import InsightAlertSummaryOthersConfig from './ConfigComponents/InsightAlertSummary/OthersConfig';
import AlertListOthersConfig from './ConfigComponents/AlertList/OthersConfig';
import SmartInfoOthersConfig from './ConfigComponents/SmartInfo/OthersConfig';
import InsightGraphParameterConfig from './ConfigComponents/InsightGraph/ParameterConfig';
import GroupOthersConfig from './ConfigComponents/Group/OthersConfig';
import ContainerOthersConfig from './ConfigComponents/Container/OthersConfig';
import LiveDataParameterConfig from './ConfigComponents/LiveData/ParameterConfig';
import LiveDataOthersConfig from './ConfigComponents/LiveData/OthersConfig';
import AssetHealthCountOthersConfig from './ConfigComponents/AssetHealthCount/OthersConfig';
import AssetInsightAlertOthersConfig from './ConfigComponents/AssetInsightAlert/OthersConfig';
import AssetEventLogOthersConfig from './ConfigComponents/AssetEventLog/OthersConfig';
import FactoriesParameterConfig from './ConfigComponents/Factories/ParameterConfig';

const tabDefinition = [
  { id: 'meta', label: 'Meta' },
  { id: 'dateTime', label: 'Date & Time' },
  { id: 'parameters', label: 'Parameters' },
  { id: 'others', label: 'Others' },
  { id: 'preview', label: 'Preview' }
];

const widgetTypeCollection = [
  'Graph',
  'Digitbox',
  'Dial',
  'Cycle Distribution',
  'OnOff',
  'ImageUpload',
  'Heatmap',
  'Insight',
  'Health',
  'GIS Plant',
  'Parameter Performance',
  'Table',
  'Pie',
  'Overview',
  'Parameter Tracking',
  'Anomaly Detection',
  'Section',
  'Analyze',
  'List View',
  'Insight-alert-summary',
  'Report Download',
  'Asset History',
  'Alert List',
  'Smart Info',
  'Insight Graph',
  'Group',
  'Container',
  'Live Data',
  'Asset Health Count',
  'Asset Insight & Alert',
  'Asset Event Log',
  'Asset Detail List',
  'Factories'
];

const widgetConfigurationMap = {
  [widgetTypeCollection[0]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: GraphParameterConfig,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[1]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: DigitboxParameterConfig,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[2]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: DialParameterConfig,
    [tabDefinition[3].id]: DialOthersConfig
  },
  [widgetTypeCollection[3]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: CycleDistributionParameterConfig,
    [tabDefinition[3].id]: CycleDistributionOthersConfig
  },
  [widgetTypeCollection[4]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: RunHoursParameterConfig,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[5]]: {
    [tabDefinition[1].id]: ImageUploadDateTimeConfig,
    [tabDefinition[2].id]: ImageUploadParameterConfig,
    [tabDefinition[3].id]: ImageUploadOthersConfig
  },
  [widgetTypeCollection[6]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: HeatmapParameterConfig,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[7]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: AssetInsightOthersConfig
  },
  [widgetTypeCollection[8]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: SensorHealthOthersConfig
  },
  [widgetTypeCollection[9]]: {
    [tabDefinition[1].id]: MapViewDateTimeConfig,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[10]]: {
    [tabDefinition[1].id]: ParameterPerformanceDateTimeConfig,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: ParameterPerformanceOthersConfig
  },
  [widgetTypeCollection[11]]: {
    [tabDefinition[1].id]: TableDateTimeConfig,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[12]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: PieChartParameterConfig,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[13]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: OverviewParameterConfig,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[14]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: ParameterTrackingPrameterConfig,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[15]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: AnomalyDetectionParameterConfig,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[16]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[17]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[18]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: ListViewParameterConfig,
    [tabDefinition[3].id]: ListViewOthersConfig
  },
  [widgetTypeCollection[19]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: InsightAlertSummaryOthersConfig
  },
  [widgetTypeCollection[20]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[21]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[22]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: AlertListOthersConfig
  },
  [widgetTypeCollection[23]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: SmartInfoOthersConfig
  },
  [widgetTypeCollection[24]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: InsightGraphParameterConfig,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[25]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: GroupOthersConfig
  },
  [widgetTypeCollection[26]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: ContainerOthersConfig
  },
  [widgetTypeCollection[27]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: LiveDataParameterConfig,
    [tabDefinition[3].id]: LiveDataOthersConfig
  },
  [widgetTypeCollection[28]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: AssetHealthCountOthersConfig
  },
  [widgetTypeCollection[29]]: {
    [tabDefinition[1].id]: DateTimeConfig,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: AssetInsightAlertOthersConfig
  },
  [widgetTypeCollection[30]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: AssetEventLogOthersConfig
  },
  [widgetTypeCollection[31]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: null,
    [tabDefinition[3].id]: null
  },
  [widgetTypeCollection[32]]: {
    [tabDefinition[1].id]: null,
    [tabDefinition[2].id]: FactoriesParameterConfig,
    [tabDefinition[3].id]: null
  }
};

const widgetParamsDefaultValueMap = {
  refresh_frequency: 0,
  period: 'Day',
  from: '0',
  to: '0',
  startTime: '0',
  endTime: '0',
  dataFrequency: '15 min'
};

const _widgetParams = {
  refresh_frequency: {
    value: widgetParamsDefaultValueMap.refresh_frequency
  }
};

const widgetConfigParamGet = (widgetType, paramName) => {
  return widgets()
    .find(({ type }) => type === widgetType)
    .widgetParams.find(({ name }) => name === paramName);
};

const widgetParamsExtensionGet = (widgetType) => {
  switch (widgetType) {
    case widgetTypeCollection[11]:
      return {
        table_widget_params: {
          value: {
            dateRange: {
              period: widgetParamsDefaultValueMap.period,
              from: widgetParamsDefaultValueMap.from,
              to: widgetParamsDefaultValueMap.to,
              startTime: widgetParamsDefaultValueMap.startTime,
              endTime: widgetParamsDefaultValueMap.endTime
            }
          }
        }
      };

    case widgetTypeCollection[10]:
      return {
        live_timeperiod: {
          value: widgetConfigParamGet(
            widgetTypeCollection[10],
            'live_timeperiod'
          ).value
        }
      };

    case widgetTypeCollection[5]:
      return {
        datatype: {
          value: {
            datatype: 0,
            dateRule: {
              period: widgetParamsDefaultValueMap.period,
              from: widgetParamsDefaultValueMap.from,
              to: widgetParamsDefaultValueMap.to,
              startTime: widgetParamsDefaultValueMap.startTime,
              endTime: widgetParamsDefaultValueMap.endTime
            }
          }
        }
      };

    default:
      return widgets()
        .find(({ type }) => type === widgetType)
        .widgetParams.reduce(
          (memo, { name, value }) => {
            return {
              ...memo,
              [name]: { value }
            };
          },
          {
            period: {
              value: widgetParamsDefaultValueMap.period
            },
            from: {
              value: widgetParamsDefaultValueMap.from
            },
            to: {
              value: widgetParamsDefaultValueMap.to
            },
            startTime: {
              value: widgetParamsDefaultValueMap.startTime
            },
            endTime: {
              value: widgetParamsDefaultValueMap.endTime
            },
            dataFrequency: {
              value: widgetParamsDefaultValueMap.dataFrequency
            }
          }
        );
  }
};

const WidgetConfig = (_props) => {
  const {
    //getAllWidgetConfig,
    resetAddWidgetTemplate,
    resetUpdateWidgetTemplate,
    resetEditWidget,
    resetCreateWidget,
    ...props
  } = _props;

  const locationPathnameFragments = useMemo(() => {
    return props.location.pathname.split(/\?/)[0].split(/\//);
  }, [props.location.pathname]);

  const widgetKey = (() => {
    return locationPathnameFragments[4];
  })();

  const dashboardKey = (() => {
    return locationPathnameFragments[3];
  })();

  const [editMode, routeEditWidgetState = {}] = (() => {
    const { edit_id } =
      (!!location.search && queryString.parse(location.search)) || {};

    const routeEditWidgetState =
      props.location.state && props.location.state.widget;

    return [!!edit_id, routeEditWidgetState];
  })();

  const widgetConfig = props.widgetConfig.filter(
    (wid) => wid && wid.id == widgetKey
  )[0];

  const getServiceId = useCallback((locationPathnameFragments, location) => {
    return (() => {
      switch (true) {
        case locationPathnameFragments[1] === 'Templates':
          return getServiceByName('Asset Insights').id;

        case !!location.state && !!location.state.path:
          return JSON.parse(localStorage.getItem('smartsense.services')).filter(
            ({ name }) => {
              return name === location.state.path;
            }
          )[0].id;

        default:
          return 1;
      }
    })();
  }, []);

  const widgetParamsGet = useCallback(
    (editMode, routeEditWidgetStateConfig) => {
      return editMode
        ? Object.entries(routeEditWidgetStateConfig).reduce(
            (memo, [key, value]) => {
              return {
                ...memo,
                [key]: {
                  isInValid: false,
                  value
                }
              };
            },
            {}
          )
        : {};
    },
    []
  );

  const context = useContext(Context);

  const [tabActive, setTabActive] = useState(tabDefinition[0].id);

  const [widgetParams, setWidgetParams] = useState();

  const [loading, setLoading] = useState(false);

  const [auditTrailShow, setAuditTrailShow] = useState(false);

  const tabEnabledDefinition =
    widgetConfig &&
    tabDefinition.reduce((memo, _tabDefinition) => {
      const value = (() => {
        switch (_tabDefinition.id) {
          case tabDefinition[0].id:
            return {
              ..._tabDefinition,
              component: MetaConfig
            };

          case tabDefinition[4].id:
            return {
              ..._tabDefinition,
              component: PreviewConfig
            };

          default:
            return (() => {
              const component =
                widgetConfigurationMap[widgetConfig.type][_tabDefinition.id];

              return component && { ..._tabDefinition, component };
            })();
        }
      })();

      return value ? [...memo, value] : memo;
    }, []);

  const onAddWidgetCompletedSuccessHandle = useCallback(
    (message) => {
      context.notify.success(message);

      props.history.goBack();
    },
    [context.notify, props.history]
  );

  const onAddWidgetCompletedErrorHandle = useCallback(
    (message) => {
      context.notify.error(message);
    },
    [context.notify]
  );

  const onAddWidgetCompletedHandle = useCallback(() => {
    switch (true) {
      case !!props.addedTemplateWidget.success:
        resetAddWidgetTemplate();

        onAddWidgetCompletedSuccessHandle('Template Widget Added');

        return null;

      case !!props.addedTemplateWidget.error:
        resetAddWidgetTemplate();

        onAddWidgetCompletedErrorHandle('Template Widget Add Failed');

        return null;

      case !!props.updateTemplateWidget.success:
        resetUpdateWidgetTemplate();

        onAddWidgetCompletedSuccessHandle('Template Widget Updated');

        return null;

      case !!props.updateTemplateWidget.error:
        resetUpdateWidgetTemplate();

        onAddWidgetCompletedErrorHandle('Template Widget Update Failed');

        return null;

      case !!props.createWidgetData.success:
        resetCreateWidget();

        onAddWidgetCompletedSuccessHandle('Widget Added');

        return null;

      case !!props.createWidgetData.error:
        resetCreateWidget();

        onAddWidgetCompletedErrorHandle(
          props.createWidgetData.data &&
            props.createWidgetData.data.response &&
            props.createWidgetData.data.response.message
        );

        return null;

      case !!props.editWidgetData.success:
        resetEditWidget();

        onAddWidgetCompletedSuccessHandle('Widget Updated');

        return null;

      case !!props.editWidgetData.error:
        resetEditWidget();

        onAddWidgetCompletedErrorHandle(
          props.editWidgetData.data &&
            props.editWidgetData.data.response &&
            props.editWidgetData.data.response.message
        );

        return null;
    }
  }, [
    props.addedTemplateWidget,
    resetAddWidgetTemplate,
    props.updateTemplateWidget,
    resetUpdateWidgetTemplate,
    props.createWidgetData,
    resetCreateWidget,
    props.editWidgetData,
    resetEditWidget,
    onAddWidgetCompletedSuccessHandle,
    onAddWidgetCompletedErrorHandle
  ]);

  useEffect(() => {
    setLoading(
      props.addedTemplateWidget.loading ||
        props.updateTemplateWidget.loading ||
        props.createWidgetData.loading ||
        props.editWidgetData.loading
    );
  }, [
    props.addedTemplateWidget.loading,
    props.updateTemplateWidget.loading,
    props.createWidgetData.loading,
    props.editWidgetData.loading
  ]);

  useEffect(() => {
    onAddWidgetCompletedHandle();
  }, [onAddWidgetCompletedHandle]);

  useEffect(() => {
    widgetConfig &&
      setWidgetParams({
        ..._widgetParams,
        ...widgetParamsExtensionGet(widgetConfig && widgetConfig.type),
        ...widgetParamsGet(editMode, routeEditWidgetState.config)
      });
  }, [editMode, routeEditWidgetState.config, widgetConfig, widgetParamsGet]);

  useEffect(() => {
    //getAllWidgetConfig({
    //service_id: getServiceId(locationPathnameFragments, props.location)
    //});
  }, [
    locationPathnameFragments,
    props.location,
    getServiceId
    //getAllWidgetConfig
  ]);

  const onTabChangeTriggerHandle = (direction) => {
    return setTabActive((activeTab) => {
      return tabEnabledDefinition[
        tabEnabledDefinition.findIndex(({ id }) => {
          return id === activeTab;
        }) + direction
      ].id;
    });
  };

  const isWidgetConfigurationInValid = (widgetParams) => {
    const isDefaultParamsValid = defaultParamsConfig.reduce(
      (memo, defaultParam) => {
        const isInValid =
          defaultParam.required &&
          (!widgetParams ||
            !widgetParams[defaultParam.name] ||
            widgetParams[defaultParam.name].isInValid);

        return isInValid ? [...(memo || []), defaultParam.label] : memo;
      },
      null
    );

    const isAppParamsValid =
      widgetConfig && widgetConfig.widgetParams
        ? widgetConfig.widgetParams.reduce((memo, param) => {
            const isInValid =
              param.required &&
              (!widgetParams ||
                !widgetParams[param.name] ||
                widgetParams[param.name].isInValid);

            return isInValid ? [...(memo || []), param.label] : memo;
          }, null)
        : null;

    return [
      ...(isDefaultParamsValid || []),
      ...(isAppParamsValid || [])
    ].reduce((memo, label) => {
      const exists =
        memo &&
        memo.find((_label) => {
          return _label === label;
        });

      return exists ? memo : [...(memo || []), label];
    }, null);
  };

  const appInputParamChangedHandle = useCallback((_widgetParams) => {
    setWidgetParams((widgetParams) => {
      return {
        ...widgetParams,
        ..._widgetParams
      };
    });
  }, []);

  const onAddWidgetHandle = () => {
    const isInValid = isWidgetConfigurationInValid(widgetParams);

    const config = Object.entries(widgetParams).reduce(
      (memo, [key, { value }]) => {
        return {
          ...memo,
          [key]: value
        };
      },
      {}
    );

    switch (true) {
      case !!isInValid:
        return context.notify.error(
          `Invalid Configuration - ${isInValid.join(', ')}`
        );

      case locationPathnameFragments[1] === 'Templates' && !editMode:
        return props.addTemplateWidget({
          template_id: dashboardKey,
          data: {
            widget_id: widgetKey,
            sequence: 1,
            config
          }
        });

      case locationPathnameFragments[1] === 'Templates' && !!editMode:
        return props.updateWidgetTemplate({
          widget_id: widgetKey,
          template_id: dashboardKey,
          id: routeEditWidgetState.id,
          data: {
            widget_id: parseInt(widgetKey),
            sequence: 1,
            config
          }
        });

      case !!editMode:
        return props.editWidget({
          id: routeEditWidgetState.id,
          dashboard_id: dashboardKey,
          widget_id: widgetKey,
          section: '',
          sequence: null,
          config
        });

      default:
        return props.createWidget({
          id: dashboardKey,
          widget_id: widgetKey,
          section: '',
          sequence: null,
          config
        });
    }
  };

  const renderTabComponent = (tabId, ConfigurationComponent) => {
    return (
      ConfigurationComponent && (
        <ConfigurationComponent
          widgetConfig={widgetConfig}
          widgetParams={widgetParams}
          appInputParamChanged={appInputParamChangedHandle}
          onTabChangeTrigger={onTabChangeTriggerHandle}
          {...(() => {
            switch (tabId) {
              case tabDefinition[4].id:
                return {
                  widgetKey,
                  dashboardKey,
                  isWidgetConfigurationInValid:
                    isWidgetConfigurationInValid(widgetParams),
                  pathname:
                    locationPathnameFragments[1] == 'Templates' ||
                    locationPathnameFragments[1] == 'CBM',
                  loading,
                  onAddWidget: onAddWidgetHandle,
                  isActiveTab: tabId === tabActive
                };

              default:
                return {};
            }
          })()}
        />
      )
    );
  };

  const renderFn = () => {
    return (
      <div className='widgetConfig_wrapper'>
        <h3>
          {widgetConfig.name}: &nbsp;
          {widgetParams &&
          widgetParams.widget_name &&
          widgetParams.widget_name.value
            ? widgetParams.widget_name.value
            : 'Widget Name'}
        </h3>

        <div className='vertical-tab'>
          <Nav tabs vertical>
            {tabEnabledDefinition.map(({ id, label }, index) => {
              return (
                <Fragment key={id}>
                  <NavItem>
                    <NavLink
                      active={
                        !auditTrailShow &&
                        tabActive == tabEnabledDefinition[index].id
                      }
                      onClick={() => {
                        setTabActive(tabEnabledDefinition[index].id);

                        setAuditTrailShow(false);
                      }}
                    >
                      {label}
                    </NavLink>
                  </NavItem>
                </Fragment>
              );
            })}

            <NavItem>
              <NavLink
                active={auditTrailShow}
                onClick={() => {
                  setTabActive(null);

                  setAuditTrailShow(true);
                }}
              >
                Audit Trail
              </NavLink>
            </NavItem>
          </Nav>

          {auditTrailShow ? (
            <TabContent>
              <TabPane>
                <WidgetConfigHistory
                  dashboardId={dashboardKey}
                  editWidgetId={routeEditWidgetState.id}
                  className='new'
                />
              </TabPane>
            </TabContent>
          ) : (
            <TabContent activeTab={tabActive}>
              {tabEnabledDefinition.map(({ id, component }, index) => {
                return (
                  <Fragment key={id}>
                    <TabPane tabId={tabEnabledDefinition[index].id}>
                      {renderTabComponent(id, component)}
                    </TabPane>
                  </Fragment>
                );
              })}
            </TabContent>
          )}
        </div>
      </div>
    );
  };

  return <Fragment>{tabEnabledDefinition && renderFn()}</Fragment>;
};

const mapStateToProps = (state) => {
  return {
    widgetConfig: mergeConfig(state.allWidgetConfig.data),
    createWidgetData: state.createWidget,
    editWidgetData: state.editWidget,
    addedTemplateWidget: state.addTemplateWidget,
    updateTemplateWidget: state.updateWidgetTemplate
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllWidgetConfig,
    addTemplateWidget,
    resetAddWidgetTemplate,
    updateWidgetTemplate,
    resetUpdateWidgetTemplate,
    editWidget,
    createWidget,
    resetEditWidget,
    resetCreateWidget
  })(WidgetConfig)
);