export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILURE = 'GET_ALL_CLIENTS_FAILURE';

export const GET_ALL_LOGIN_LOGOUT_DETAILS = 'GET_ALL_LOGIN_LOGOUT_DETAILS';
export const GET_ALL_LOGIN_LOGOUT_DETAILS_SUCCESS = 'GET_ALL_LOGIN_LOGOUT_DETAILS_SUCCESS';
export const GET_ALL_LOGIN_LOGOUT_DETAILS_FAILURE = 'GET_ALL_LOGIN_LOGOUT_DETAILS_FAILURE';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET';

export const EDIT_USER_PROFILE = 'EDIT_USER_PROFILE';
export const EDIT_USER_PROFILE_SUCCESS = 'EDIT_USER_PROFILE_SUCCESS';
export const EDIT_USER_PROFILE_FAILURE = 'EDIT_USER_PROFILE_FAILURE';
export const EDIT_USER_PROFILE_RESET = 'EDIT_USER_PROFILE_RESET';

export const NOTIFICATION_CATEGORY = 'NOTIFICATION_CATEGORY';
export const GET_NOTIFICATION_SETTING = 'GET_NOTIFICATION_SETTING';
export const TURN_OFF_NOTIFICATION = 'TURN_OFF_NOTIFICATION';
export const TURN_OFF_NOTIFICATION_RESET = 'TURN_OFF_NOTIFICATION_RESET';