export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
export const GET_ALL_EVENTS_FAILURE = 'GET_ALL_EVENTS_FAILURE';

export const GET_ALL_EVENT_LOGS = 'GET_ALL_EVENT_LOGS';
export const GET_ALL_EVENT_LOGS_SUCCESS = 'GET_ALL_EVENT_LOGS_SUCCESS';
export const GET_ALL_EVENT_LOGS_FAILURE = 'GET_ALL_EVENT_LOGS_FAILURE';

export const CHECK_ALL_EVENT = 'CHECK_ALL_EVENT';
export const CHECK_ALL_EVENT_SUCCESS = 'CHECK_ALL_EVENT_SUCCESS';
export const CHECK_ALL_EVENT_FAILURE = 'CHECK_ALL_EVENT_FAILURE';

export const CHECK_EVENT = 'CHECK_EVENT';
export const CHECK_EVENT_SUCCESS = 'CHECK_EVENT_SUCCESS';

export const CHECK_EVENT_LOG = 'CHECK_EVENT_LOG';
export const CHECK_EVENT_LOG_SUCCESS = 'CHECK_EVENT_LOG_SUCCESS';

export const GET_ALL_EVENT_BY_ID = 'GET_ALL_EVENT_BY_ID';
export const GET_ALL_EVENT_BY_ID_SUCCESS = 'GET_ALL_EVENT_BY_ID_SUCCESS';
export const GET_ALL_EVENT_BY_ID_FAILURE = 'GET_ALL_EVENT_BY_ID_FAILURE';

export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';
export const DELETE_EVENT_RESET = 'DELETE_EVENT_RESET';

export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';
export const CREATE_EVENT_RESET = 'CREATE_EVENT_RESET';

export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';
export const UPDATE_EVENT_RESET = 'UPDATE_EVENT_RESET';

export const CHECK_ALL_REPORT_SUCCESS = 'CHECK_ALL_REPORT_SUCCESS';
export const CHECK_REPORT_SUCCESS = 'CHECK_REPORT_SUCCESS';