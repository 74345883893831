import queryString from 'query-string';

import {
  GET_ALERT_CONNECTION,
  GET_ALERT_CONNECTION_FAILURE,
  GET_ALERT_CONNECTION_SUCCESS,
  GET_INSIGHT_CONNECTION,
  GET_INSIGHT_CONNECTION_FAILURE,
  GET_INSIGHT_CONNECTION_SUCCESS,
  GET_ACTIVITY_CONNECTION,
  GET_ACTIVITY_CONNECTION_FAILURE,
  GET_ACTIVITY_CONNECTION_SUCCESS,
  GET_EVENT_CONFIGURATION,
  GET_EVENT_CONFIGURATION_FAILURE,
  GET_EVENT_CONFIGURATION_SUCCESS,
  ADD_USER_ACTIVITY,
  ADD_USER_ACTIVITY_FAILURE,
  ADD_USER_ACTIVITY_SUCCESS,
  ADD_USER_ACTIVITY_RESET,
  GET_ASSET_HISTORY_TIMELINE_DATA,
  GET_ASSET_HISTORY_TIMELINE_DATA_SUCCESS,
  GET_ASSET_HISTORY_TIMELINE_DATA_FAILURE,
  GET_NETWORK_HISTORY_TIMELINE_DATA,
  GET_NETWORK_HISTORY_TIMELINE_DATA_SUCCESS,
  GET_NETWORK_HISTORY_TIMELINE_DATA_FAILURE,
  GET_ASSET_HISTORY_TIMELINE_LOG,
  GET_ASSET_HISTORY_TIMELINE_LOG_SUCCESS,
  GET_ASSET_HISTORY_TIMELINE_LOG_FAILURE,
  GET_ASSET_SENSOR,
  GET_ASSET_SENSOR_FAILURE,
  GET_ASSET_SENSOR_SUCCESS,
  EXPORT_TIMELINE_LOG,
  EXPORT_TIMELINE_LOG_FAILURE,
  EXPORT_TIMELINE_LOG_SUCCESS
} from './constant';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  success: false
};

const getAlertConnectionData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALERT_CONNECTION:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        data: {}
      };

    case GET_ALERT_CONNECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        data: {}
      };

    case GET_ALERT_CONNECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: {
          ...action.response,
          result: action.response.result.reduce((memo, alert) => {
            return [
              ...memo,
              {
                ...Object.entries(alert).reduce((_memo, [_key, value]) => {
                  const key = (() => {
                    switch (_key) {
                      case 'created_at':
                        return 'date';

                      case 'priority_level':
                        return 'category';

                      case 'alert_status':
                        return 'status';

                      default:
                        return _key;
                    }
                  })();

                  return { ..._memo, [key]: value };
                }, {}),
                type: 'alert'
              }
            ];
          }, [])
        }
      };

    default:
      return state;
  }
};

const getInsightConnectionData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INSIGHT_CONNECTION:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        data: {}
      };

    case GET_INSIGHT_CONNECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        data: {}
      };

    case GET_INSIGHT_CONNECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: {
          ...action.response,
          result: action.response.result.reduce((memo, insight) => {
            return [
              ...memo,
              {
                ...Object.entries(insight.insight_id).reduce(
                  (_memo, [_key, _value]) => {
                    const key = (() => {
                      switch (_key) {
                        case 'name':
                          return 'name';

                        case 'is_critical':
                          return 'category';

                        default:
                          return _key;
                      }
                    })();

                    const value = (() => {
                      switch (key) {
                        case 'category':
                          return _value ? 'critical' : 'non-critical';

                        default:
                          return _value;
                      }
                    })();

                    return {
                      ..._memo,
                      [key]: value
                    };
                  },
                  {}
                ),
                date: insight.action_date,
                name: insight.insight_name,
                type: 'insight',
                description: insight.comment,
                category: insight.is_critical ? 'critical' : 'non-critical',
                status: insight.status,
                username: insight.created_by
              }
            ];
          }, [])
        }
      };

    default:
      return state;
  }
};

const getActivityConnectionData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ACTIVITY_CONNECTION:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        data: {}
      };

    case GET_ACTIVITY_CONNECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        data: {}
      };

    case GET_ACTIVITY_CONNECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: {
          ...action.response,
          result: action.response.result.reduce((memo, activity) => {
            return [
              ...memo,
              {
                ...Object.entries(activity).reduce((_memo, [_key, value]) => {
                  const key = (() => {
                    switch (_key) {
                      case 'created_at':
                        return 'date';

                      case 'event_type':
                        return 'category';

                      default:
                        return _key;
                    }
                  })();

                  return { ..._memo, [key]: value };
                }, {}),
                type: 'activity'
              }
            ];
          }, [])
        }
      };

    default:
      return state;
  }
};

const getEventConfigurationData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EVENT_CONFIGURATION:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        data: {}
      };

    case GET_EVENT_CONFIGURATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        data: {}
      };

    case GET_EVENT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.response
      };

    default:
      return state;
  }
};

const addUserActivityData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_USER_ACTIVITY:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        data: null
      };

    case ADD_USER_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        data: null
      };

    case ADD_USER_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.response
      };

    case ADD_USER_ACTIVITY_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        data: null
      };

    default:
      return state;
  }
};

const getAssetHistoryTimelineData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ASSET_HISTORY_TIMELINE_DATA:
      return {
        ...state,
        data: {
          [action.payload.widgetId]: {}
        },
        loading: {
          [action.payload.widgetId]: true
        },
        success: {
          [action.payload.widgetId]: undefined
        },
        error: {
          [action.payload.widgetId]: false
        }
      };

    case GET_ASSET_HISTORY_TIMELINE_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            result: action.response.result.reduce((memo, _result) => {
              const value = Object.entries(_result).reduce(
                (memo, [_key, value]) => {
                  const key = (() => {
                    switch (_key) {
                      case 'name':
                        return 'username';

                      case 'event_name':
                        return 'name';

                      default:
                        return _key;
                    }
                  })();

                  return key
                    ? {
                        ...memo,
                        [key]: value
                      }
                    : memo;
                },
                {}
              );

              return [...memo, value];
            }, [])
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_ASSET_HISTORY_TIMELINE_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(
          action.payload.request.url.split(/\?/)[1]
        );

        return {
          ...state,
          data: {
            [widgetId]: {}
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.success,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};


const getNetworkHistoryTimelineData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NETWORK_HISTORY_TIMELINE_DATA:
      return {
        ...state,
        data: {
          [action.payload.widgetId]: {}
        },
        loading: {
          [action.payload.widgetId]: true
        },
        success: {
          [action.payload.widgetId]: undefined
        },
        error: {
          [action.payload.widgetId]: false
        }
      };

    case GET_NETWORK_HISTORY_TIMELINE_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            result: action.response.result.reduce((memo, _result) => {
              const value = Object.entries(_result).reduce(
                (memo, [_key, value]) => {
                  const key = (() => {
                    switch (_key) {
                      case 'name':
                        return 'username';

                      case 'event_name':
                        return 'name';

                      default:
                        return _key;
                    }
                  })();

                  return key
                    ? {
                        ...memo,
                        [key]: value
                      }
                    : memo;
                },
                {}
              );

              return [...memo, value];
            }, [])
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_NETWORK_HISTORY_TIMELINE_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(
          action.payload.request.url.split(/\?/)[1]
        );

        return {
          ...state,
          data: {
            [widgetId]: {}
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.success,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};
const getAssetHistoryTimelineLogData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ASSET_HISTORY_TIMELINE_LOG:
      return {
        ...state,
        data: {
          [action.payload.widgetId]: {}
        },
        loading: {
          [action.payload.widgetId]: true
        },
        success: {
          [action.payload.widgetId]: undefined
        },
        error: {
          [action.payload.widgetId]: false
        }
      };

    case GET_ASSET_HISTORY_TIMELINE_LOG_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            result: action.response.result.reduce((memo, _result) => {
              const value = Object.entries(_result).reduce(
                (memo, [_key, value]) => {
                  const key = (() => {
                    switch (_key) {
                      case 'name':
                        return 'username';

                      case 'event_name':
                        return 'name';

                      default:
                        return _key;
                    }
                  })();

                  return key
                    ? {
                        ...memo,
                        [key]: value
                      }
                    : memo;
                },
                {}
              );

              return [...memo, value];
            }, [])
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_ASSET_HISTORY_TIMELINE_LOG_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(
          action.payload.request.url.split(/\?/)[1]
        );

        return {
          ...state,
          data: {
            [widgetId]: {}
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.success,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

const getAssetSensor = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ASSET_SENSOR:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        data: null
      };

    case GET_ASSET_SENSOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        data: null
      };

    case GET_ASSET_SENSOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.response
      };

    default:
      return state;
  }
};

export {
  getAlertConnectionData,
  getInsightConnectionData,
  getActivityConnectionData,
  getEventConfigurationData,
  addUserActivityData,
  getAssetHistoryTimelineData,
  getNetworkHistoryTimelineData,
  getAssetHistoryTimelineLogData,
  getAssetSensor,
};