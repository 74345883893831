export const GET_ALL_TEMPLATE_LIST = 'GET_ALL_TEMPLATE_LIST';
export const GET_ALL_TEMPLATE_LIST_SUCCESS = 'GET_ALL_TEMPLATE_LIST_SUCCESS';
export const GET_ALL_TEMPLATE_LIST_FAILURE = 'GET_ALL_TEMPLATE_LIST_FAILURE';
export const CHECK_TEMPLATE = 'CHECK_TEMPLATE';
export const CHECK_ALL_TEMPLATES = 'CHECK_ALL_TEMPLATES';

export const GET_TEMPLATE_DETAILS = 'GET_TEMPLATE_DETAILS';
export const GET_TEMPLATE_DETAILS_SUCCESS = 'GET_TEMPLATE_DETAILS_SUCCESS';
export const GET_TEMPLATE_DETAILS_FAILURE = 'GET_TEMPLATE_DETAILS_FAILURE';

export const GET_ASSET_NAME_DETAILS = 'GET_ASSET_NAME_DETAILS';
export const GET_ASSET_NAME_DETAILS_SUCCESS = 'GET_ASSET_NAME_DETAILS_SUCCESS';
export const GET_ASSET_NAME_DETAILS_FAILURE = 'GET_ASSET_NAME_DETAILS_FAILURE';
export const GET_ASSET_NAME_DETAILS_RESET = 'GET_ASSET_NAME_DETAILS_RESET';

export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_FAILURE = 'UPDATE_TEMPLATE_FAILURE';
export const UPDATE_TEMPLATE_RESET = 'UPDATE_TEMPLATE_RESET';

export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';
export const DELETE_TEMPLATE_RESET = 'DELETE_TEMPLATE_RESET';

export const GET_ALL_ASSET_LIST = 'GET_ALL_ASSET_LIST';
export const GET_ALL_ASSET_LIST_SUCCESS = 'GET_ALL_ASSET_LIST_SUCCESS';
export const GET_ALL_ASSET_LIST_FAILURE = 'GET_ALL_ASSET_LIST_FAILURE';

export const CREATE_TEMPLATES = 'CREATE_TEMPLATES';
export const CREATE_TEMPLATES_SUCCESS = 'CREATE_TEMPLATES_SUCCESS';
export const CREATE_TEMPLATES_FAILURE = 'CREATE_TEMPLATES_FAILURE';
export const CREATE_TEMPLATE_RESET = 'CREATE_TEMPLATE_RESET';

// export const GET_ALL_METADATA = 'GET_ALL_METADATA';
// export const GET_ALL_METADATA_SUCCESS = 'GET_ALL_METADATA_SUCCESS';
// export const GET_ALL_METADATA_FAILURE = 'GET_ALL_METADATA_FAILURE';

// export const GET_ALL_DASHBOARD_LIST = 'GET_ALL_DASHBOARD_LIST';
// export const GET_ALL_DASHBOARD_LIST_SUCCESS = 'GET_ALL_DASHBOARD_LIST_SUCCESS';
// export const GET_ALL_DASHBOARD_LIST_FAILURE = 'GET_ALL_DASHBOARD_LIST_FAILURE';

export const IS_OPEN_SCREEN_DESIGN_MODAL = 'IS_OPEN_SCREEN_DESIGN_MODAL';

export const ADD_TEMPLATE_WIDGET = 'ADD_TEMPLATE_WIDGET';
export const ADD_TEMPLATE_WIDGET_SUCCESS = 'ADD_TEMPLATE_WIDGET_SUCCESS';
export const ADD_TEMPLATE_WIDGET_FAILURE = 'ADD_TEMPLATE_WIDGET_FAILURE';
export const ADD_TEMPLATE_WIDGET_RESET = 'ADD_TEMPLATE_WIDGET_RESET';

export const GET_TEMPLATE_WIDGETS = 'GET_TEMPLATE_WIDGETS';
export const GET_TEMPLATE_WIDGETS_SUCCESS = 'GET_TEMPLATE_WIDGETS_SUCCESS';
export const GET_TEMPLATE_WIDGETS_FAILURE = 'GET_TEMPLATE_WIDGETS_FAILURE';

export const GET_WIDGET_DETAILS_TEMPLATE = 'GET_WIDGET_DETAILS_TEMPLATE';
export const GET_GROUP_WIDGET_DETAILS_TEMPLATE = 'GET_GROUP_WIDGET_DETAILS_TEMPLATE';
export const GET_GROUP_WIDGET_DETAILS_TEMPLATE_SUCCESS = 'GET_GROUP_WIDGET_DETAILS_TEMPLATE_SUCCESS';
export const GET_GROUP_WIDGET_DETAILS_TEMPLATE_FAILURE = 'GET_GROUP_WIDGET_DETAILS_TEMPLATE_FAILURE';
export const GET_WIDGET_DETAILS_TEMPLATE_SUCCESS = 'GET_WIDGET_DETAILS_TEMPLATE_SUCCESS';
export const GET_WIDGET_DETAILS_TEMPLATE_FAILURE = 'GET_WIDGET_DETAILS_TEMPLATE_FAILURE';

export const UPDATE_WIDGET_TEMPLATE = 'UPDATE_WIDGET_TEMPLATE';
export const UPDATE_WIDGET_TEMPLATE_SUCCESS = 'UPDATE_WIDGET_TEMPLATE_SUCCESS';
export const UPDATE_WIDGET_TEMPLATE_FAILURE = 'UPDATE_WIDGET_TEMPLATE_FAILURE';
export const UPDATE_WIDGET_TEMPLATE_RESET = 'UPDATE_WIDGET_TEMPLATE_RESET';

export const DELETE_WIDGET_OF_TEMPLATE = 'DELETE_WIDGET_OF_TEMPLATE';
export const DELETE_WIDGET_OF_TEMPLATE_SUCCESS = 'DELETE_WIDGET_OF_TEMPLATE_SUCCESS';
export const DELETE_WIDGET_OF_TEMPLATE_FAILURE = 'DELETE_WIDGET_OF_TEMPLATE_FAILURE';
export const DELETE_WIDGET_OF_TEMPLATE_RESET = 'DELETE_WIDGET_OF_TEMPLATE_RESET';

export const GET_DEFAULT_TEMPLATE_OF_ASSET = 'GET_DEFAULT_TEMPLATE_OF_ASSET';
export const GET_DEFAULT_TEMPLATE_OF_ASSET_SUCCESS = 'GET_DEFAULT_TEMPLATE_OF_ASSET_SUCCESS';
export const GET_DEFAULT_TEMPLATE_OF_ASSET_FAILURE = 'DELETE_WIDGET_OF_TEMPLATE_FAILURE';

export const GET_ALL_SERVICE_LIST = 'GET_ALL_SERVICE_LIST';

export const GET_SERVICE_TEMPLATE_PLANT = 'GET_SERVICE_TEMPLATE_PLANT';
export const GET_SERVICE_TEMPLATE_PLANT_SUCCESS = 'GET_SERVICE_TEMPLATE_PLANT_SUCCESS';
export const GET_SERVICE_TEMPLATE_PLANT_FAILURE = 'GET_SERVICE_TEMPLATE_PLANT_FAILURE';

export const GET_CLIENT_TEMPLATE = 'GET_CLIENT_TEMPLATE';
export const GET_CLIENT_TEMPLATE_SUCCESS = 'GET_CLIENT_TEMPLATE_SUCCESS';
export const GET_CLIENT_TEMPLATE_FAILURE = 'GET_CLIENT_TEMPLATE_FAILURE';

export const CREATE_SERVICE_TEMPLATE = 'CREATE_SERVICE_TEMPLATE';
export const CREATE_SERVICE_TEMPLATE_SUCCESS = 'CREATE_SERVICE_TEMPLATE_SUCCESS';
export const CREATE_SERVICE_TEMPLATE_FAILURE = 'CREATE_SERVICE_TEMPLATE_FAILURE';
export const CREATE_SERVICE_TEMPLATE_RESET = 'CREATE_SERVICE_TEMPLATE_RESET';

export const DELETE_SERVICE_TEMPLATE = 'DELETE_SERVICE_TEMPLATE';
export const DELETE_SERVICE_TEMPLATE_SUCCESS = 'DELETE_SERVICE_TEMPLATE_SUCCESS';
export const DELETE_SERVICE_TEMPLATE_FAILURE = 'DELETE_SERVICE_TEMPLATE_FAILURE';
export const DELETE_SERVICE_TEMPLATE_RESET = 'DELETE_SERVICE_TEMPLATE_RESET';

export const UPDATE_SERVICE_TEMPLATE = 'UPDATE_SERVICE_TEMPLATE';
export const UPDATE_SERVICE_TEMPLATE_SUCCESS = 'UPDATE_SERVICE_TEMPLATE_SUCCESS';
export const UPDATE_SERVICE_TEMPLATE_FAILURE = 'UPDATE_SERVICE_TEMPLATE_FAILURE';
export const UPDATE_SERVICE_TEMPLATE_RESET = 'UPDATE_SERVICE_TEMPLATE_RESET';

export const GET_SERVICE_TEMPLATE = 'GET_SERVICE_TEMPLATE';
export const GET_SERVICE_TEMPLATE_SUCCESS = 'GET_SERVICE_TEMPLATE_SUCCESS';
export const GET_SERVICE_TEMPLATE_FAILURE = 'GET_SERVICE_TEMPLATE_FAILURE';
export const CHECK_SERVICE = 'CHECK_SERVICE';
export const CHECK_ALL_SERVICES = 'CHECK_ALL_SERVICES';

export const GET_WIDGETS_DETAILS = 'GET_WIDGETS_DETAILS';
export const GET_WIDGETS_DETAILS_SUCCESS = 'GET_WIDGETS_DETAILS_SUCCESS';
export const GET_WIDGETS_DETAILS_FAILURE = 'GET_WIDGETS_DETAILS_FAILURE';

export const CREATE_DASHBOARD_TEMPLATE = 'CREATE_DASHBOARD_TEMPLATE';
export const CREATE_DASHBOARD_TEMPLATE_SUCCESS = 'CREATE_DASHBOARD_TEMPLATE_SUCCESS';
export const CREATE_DASHBOARD_TEMPLATE_FAILURE = 'CREATE_DASHBOARD_TEMPLATE_FAILURE';
export const CREATE_DASHBOARD_TEMPLATE_RESET = 'CREATE_DASHBOARD_TEMPLATE_RESET';

export const GET_DASHBOARD_TEMPLATE = 'GET_DASHBOARD_TEMPLATE';
export const GET_DASHBOARD_TEMPLATE_SUCCESS = 'GET_DASHBOARD_TEMPLATE_SUCCESS';
export const GET_DASHBOARD_TEMPLATE_FAILURE = 'GET_DASHBOARD_TEMPLATE_FAILURE';

export const ADD_DASHBOARD_WIDGET = 'ADD_DASHBOARD_WIDGET';
export const ADD_DASHBOARD_WIDGET_SUCCESS = 'ADD_DASHBOARD_WIDGET_SUCCESS';
export const ADD_DASHBOARD_WIDGET_FAILURE = 'ADD_DASHBOARD_WIDGET_FAILURE';
export const ADD_DASHBOARD_WIDGET_RESET = 'ADD_DASHBOARD_WIDGET_RESET';

export const GET_DASHBOARD_WIDGETS = 'GET_DASHBOARD_WIDGETS';
export const GET_DASHBOARD_WIDGETS_SUCCESS = 'GET_DASHBOARD_WIDGETS_SUCCESS';
export const GET_DASHBOARD_WIDGETS_FAILURE = 'GET_DASHBOARD_WIDGETS_FAILURE';

export const DELETE_DASHBOARD_WIDGET = 'DELETE_DASHBOARD_WIDGET';
export const DELETE_DASHBOARD_WIDGET_SUCCESS = 'DELETE_DASHBOARD_WIDGET_SUCCESS';
export const DELETE_DASHBOARD_WIDGET_FAILURE = 'DELETE_DASHBOARD_WIDGET_FAILURE';
export const DELETE_DASHBOARD_WIDGET_RESET = 'DELETE_DASHBOARD_WIDGET_RESET';

export const EDIT_DASHBOARD_WIDGET = 'EDIT_DASHBOARD_WIDGET';
export const EDIT_DASHBOARD_WIDGET_SUCCESS = 'EDIT_DASHBOARD_WIDGET_SUCCESS';
export const EDIT_DASHBOARD_WIDGET_FAILURE = 'EDIT_DASHBOARD_WIDGET_FAILURE';
export const EDIT_DASHBOARD_WIDGET_RESET = 'EDIT_DASHBOARD_WIDGET_RESET';

export const EDIT_DASHBOARD_ORDER = 'EDIT_DASHBOARD_ORDER';
export const EDIT_DASHBOARD_ORDER_SUCCESS = 'EDIT_DASHBOARD_ORDER_SUCCESS';
export const EDIT_DASHBOARD_ORDER_FAILURE = 'EDIT_DASHBOARD_ORDER_FAILURE';
export const EDIT_DASHBOARD_ORDER_RESET = 'EDIT_DASHBOARD_ORDER_RESET';

export const EDIT_WIDGET_SERVICE = 'EDIT_WIDGET_SERVICE';
export const EDIT_WIDGET_SERVICE_SUCCESS = 'EDIT_WIDGET_SERVICE_SUCCESS';
export const EDIT_WIDGET_SERVICE_FAILURE = 'EDIT_WIDGET_SERVICE_FAILURE';
export const EDIT_WIDGET_SERVICE_RESET = 'EDIT_WIDGET_SERVICE_RESET';

export const SERVICE_DEFAULT = 'SERVICE_DEFAULT';
export const SERVICE_DEFAULT_SUCCESS = 'SERVICE_DEFAULT_SUCCESS';
export const SERVICE_DEFAULT_FAILURE = 'SERVICE_DEFAULT_FAILURE';
export const SERVICE_DEFAULT_RESET = 'SERVICE_DEFAULT_RESET';

export const SERVICE_DEFAULT_EXTENDED = 'SERVICE_DEFAULT_EXTENDED';
export const SERVICE_DEFAULT_EXTENDED_SUCCESS =
  'SERVICE_DEFAULT_EXTENDED_SUCCESS';
export const SERVICE_DEFAULT_EXTENDED_FAILURE =
  'SERVICE_DEFAULT_EXTENDED_FAILURE';
export const SERVICE_DEFAULT_EXTENDED_RESET = 'SERVICE_DEFAULT_EXTENDED_RESET';

export const GET_ALL_CALCULATED_PARAMETER_LIST = 'GET_ALL_CALCULATED_PARAMETER_LIST';
export const GET_ALL_CALCULATED_PARAMETER_LIST_SUCCESS = 'GET_ALL_CALCULATED_PARAMETER_LIST_SUCCESS';
export const GET_ALL_CALCULATED_PARAMETER_LIST_FAILURE = 'GET_ALL_CALCULATED_PARAMETER_LIST_FAILURE';

export const CHECK_CALCULATED_PARAMETER = 'CHECK_CALCULATED_PARAMETER';
export const CHECK_ALL_CALCULATED_PARAMETERS = 'CHECK_ALL_CALCULATED_PARAMETERS';

export const CHECK_NETWORK = 'CHECK_NETWORK';
export const CHECK_ALL_NETWORK = 'CHECK_ALL_NETWORK';

export const CREATE_CALCULATED_PARAMETERS = 'CREATE_CALCULATED_PARAMETERS';
export const CREATE_CALCULATED_PARAMETERS_SUCCESS = 'CREATE_CALCULATED_PARAMETERS_SUCCESS';
export const CREATE_CALCULATED_PARAMETERS_FAILURE = 'CREATE_CALCULATED_PARAMETERS_FAILURE';
export const CREATE_CALCULATED_PARAMETER_RESET = 'CREATE_CALCULATED_PARAMETER_RESET';
export const CHECKDATA_CALCULATED_PARAMETER_RESET = 'CHECKDATA_CALCULATED_PARAMETER_RESET';

export const UPDATE_CALCULATED_PARAMETER = 'UPDATE_CALCULATED_PARAMETER';
export const UPDATE_CALCULATED_PARAMETER_SUCCESS = 'UPDATE_CALCULATED_PARAMETER_SUCCESS';
export const UPDATE_CALCULATED_PARAMETER_FAILURE = 'UPDATE_CALCULATED_PARAMETER_FAILURE';
export const UPDATE_CALCULATED_PARAMETER_RESET = 'UPDATE_CALCULATED_PARAMETER_RESET';

export const DELETE_CALCULATED_PARAMETER = 'DELETE_CALCULATED_PARAMETER';
export const DELETE_CALCULATED_PARAMETER_SUCCESS = 'DELETE_CALCULATED_PARAMETER_SUCCESS';
export const DELETE_CALCULATED_PARAMETER_FAILURE = 'DELETE_CALCULATED_PARAMETER_FAILURE';
export const DELETE_CALCULATED_PARAMETER_RESET = 'DELETE_CALCULATED_PARAMETER_RESET';

export const GET_ALL_CLIENT_LIST = 'GET_ALL_CLIENT_LIST';
export const GET_ALL_CLIENT_LIST_SUCCESS = 'GET_ALL_CLIENT_LIST_SUCCESS';
export const GET_ALL_CLIENT_LIST_FAILURE = 'GET_ALL_CLIENT_LIST_FAILURE';

export const GET_ALL_PLANT_LIST = 'GET_ALL_PLANT_LIST';
export const GET_ALL_PLANT_LIST_SUCCESS = 'GET_ALL_PLANT_LIST_SUCCESS';
export const GET_ALL_PLANT_LIST_FAILURE = 'GET_ALL_PLANT_LIST_FAILURE';


export const IS_OPEN_CALCULATED_PARAMETER_MODAL = 'IS_OPEN_CALCULATED_PARAMETER_MODAL';

export const VERIFY_TARGET_SENSOR_PARAMETER = 'VERIFY_TARGET_SENSOR_PARAMETER';
export const VERIFY_TARGET_SENSOR_PARAMETER_SUCCESS = 'VERIFY_TARGET_SENSOR_PARAMETER_SUCCESS';
export const VERIFY_TARGET_SENSOR_PARAMETER_FAILURE = 'VERIFY_TARGET_SENSOR_PARAMETER_FAILURE';
export const VERIFY_TARGET_SENSOR_PARAMETER_RESET = 'VERIFY_TARGET_SENSOR_PARAMETER_RESET';

export const CREATE_ASSET_CALCULATED_PARAMETERS = 'CREATE_ASSET_CALCULATED_PARAMETERS';
export const CREATE_ASSET_CALCULATED_PARAMETERS_SUCCESS = 'CREATE_ASSET_CALCULATED_PARAMETERS_SUCCESS';
export const CREATE_ASSET_CALCULATED_PARAMETERS_FAILURE = 'CREATE_ASSET_CALCULATED_PARAMETERS_FAILURE';
export const CREATE_ASSET_CALCULATED_PARAMETER_RESET = 'CREATE_ASSET_CALCULATED_PARAMETER_RESET';
export const CHECKDATA_ASSET_CALCULATED_PARAMETER_RESET = 'CHECKDATA_ASSET_CALCULATED_PARAMETER_RESET';


export const GET_ASSET_SENSOR_TYPES = 'GET_ASSET_SENSOR_TYPES';
export const GET_ASSET_SENSOR_TYPES_SUCCESS = 'GET_ASSET_SENSOR_TYPES_SUCCESS';
export const GET_ASSET_SENSOR_TYPES_FAILURE = 'GET_ASSET_SENSOR_TYPES_FAILURE';

export const GET_ALL_ASSET_CALCULATED_LIST = "GET_ALL_ASSET_CALCULATED_LIST"
export const GET_ALL_ASSET_CALCULATED_LIST_SUCCESS = "GET_ALL_ASSET_CALCULATED_LIST_SUCCESS"
export const GET_ALL_ASSET_CALCULATED_LIST_FAILURE = "GET_ALL_ASSET_CALCULATED_LIST_FAILURE"

export const CHECK_ASSET_CALCULATED_PARAMETER = 'CHECK_ASSET_CALCULATED_PARAMETER';
export const CHECK_ALL_ASSET_CALCULATED_PARAMETERS =
  'CHECK_ALL_ASSET_CALCULATED_PARAMETERS';


export const UPDATE_ASSET_CALCULATED_PARAMETER = 'UPDATE_ASSET_CALCULATED_PARAMETER';
export const UPDATE_ASSET_CALCULATED_PARAMETER_SUCCESS = 'UPDATE_ASSET_CALCULATED_PARAMETER_SUCCESS';
export const UPDATE_ASSET_CALCULATED_PARAMETER_FAILURE = 'UPDATE_ASSET_CALCULATED_PARAMETER_FAILURE';
export const UPDATE_ASSET_CALCULATED_PARAMETER_RESET = 'UPDATE_ASSET_CALCULATED_PARAMETER_RESET';

export const GET_FACILITY_HOLIDAY = 'GET_FACILITY_HOLIDAY';
export const GET_FACILITY_HOLIDAY_SUCCESS = 'GET_FACILITY_HOLIDAY_SUCCESS';
export const GET_FACILITY_HOLIDAY_FAILURE = 'GET_FACILITY_HOLIDAY_FAILURE';

export const DELETE_PLANT_ALL_HOLIDAY = 'DELETE_PLANT_ALL_HOLIDAY';
export const DELETE_PLANT_ALL_HOLIDAY_SUCCESS = 'DELETE_PLANT_ALL_HOLIDAY_SUCCESS';
export const DELETE_PLANT_ALL_HOLIDAY_FAILURE = 'DELETE_PLANT_ALL_HOLIDAY_FAILURE';
export const DELETE_PLANT_ALL_HOLIDAY_RESET = 'DELETE_PLANT_ALL_HOLIDAY_RESET';

export const GET_NETWORK_TYPE = 'GET_NETWORK_TYPE';
export const GET_NETWORK_TYPE_SUCCESS = 'GET_NETWORK_TYPE_SUCCESS';
export const GET_NETWORK_TYPE_FAILURE = 'GET_NETWORK_TYPE_FAILURE';

export const GET_DYNAMIC_VARIABLE_TEMPLATE = 'GET_DYNAMIC_VARIABLE_TEMPLATE';
export const GET_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS =
  'GET_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS';
export const GET_DYNAMIC_VARIABLE_TEMPLATE_FAILURE =
  'GET_DYNAMIC_VARIABLE_TEMPLATE_FAILURE';

export const UPDATE_DYNAMIC_VARIABLE_TEMPLATE =
  'UPDATE_DYNAMIC_VARIABLE_TEMPLATE';
export const UPDATE_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS =
  'UPDATE_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS';
export const UPDATE_DYNAMIC_VARIABLE_TEMPLATE_FAILURE =
  'UPDATE_DYNAMIC_VARIABLE_TEMPLATE_FAILURE';
export const UPDATE_DYNAMIC_VARIABLE_TEMPLATE_RESET =
  'UPDATE_DYNAMIC_VARIABLE_TEMPLATE_RESET';

export const DELETE_DYNAMIC_VARIABLE_TEMPLATE =
  'DELETE_DYNAMIC_VARIABLE_TEMPLATE';
export const DELETE_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS =
  'DELETE_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS';
export const DELETE_DYNAMIC_VARIABLE_TEMPLATE_FAILURE =
  'DELETE_DYNAMIC_VARIABLE_TEMPLATE_FAILURE';
export const DELETE_DYNAMIC_VARIABLE_TEMPLATE_RESET =
  'DELETE_DYNAMIC_VARIABLE_TEMPLATE_RESET';

export const GET_DYNAMIC_VARIABLE_MAPPING = 'GET_DYNAMIC_VARIABLE_MAPPING';
export const GET_DYNAMIC_VARIABLE_MAPPING_SUCCESS =
  'GET_DYNAMIC_VARIABLE_MAPPING_SUCCESS';
export const GET_DYNAMIC_VARIABLE_MAPPING_FAILURE =
  'GET_DYNAMIC_VARIABLE_MAPPING_FAILURE';

export const UPDATE_DYNAMIC_VARIABLE_MAPPING =
  'UPDATE_DYNAMIC_VARIABLE_MAPPING';
export const UPDATE_DYNAMIC_VARIABLE_MAPPING_SUCCESS =
  'UPDATE_DYNAMIC_VARIABLE_MAPPING_SUCCESS';
export const UPDATE_DYNAMIC_VARIABLE_MAPPING_FAILURE =
  'UPDATE_DYNAMIC_VARIABLE_MAPPING_FAILURE';
export const UPDATE_DYNAMIC_VARIABLE_MAPPING_RESET =
  'UPDATE_DYNAMIC_VARIABLE_MAPPING_RESET';

export const DELETE_DYNAMIC_VARIABLE_MAPPING =
  'DELETE_DYNAMIC_VARIABLE_MAPPING';
export const DELETE_DYNAMIC_VARIABLE_MAPPING_SUCCESS =
  'DELETE_DYNAMIC_VARIABLE_MAPPING_SUCCESS';
export const DELETE_DYNAMIC_VARIABLE_MAPPING_FAILURE =
  'DELETE_DYNAMIC_VARIABLE_MAPPING_FAILURE';
export const DELETE_DYNAMIC_VARIABLE_MAPPING_RESET =
  'DELETE_DYNAMIC_VARIABLE_MAPPING_RESET';

export const GET_ASSET_DYNAMIC_VARIABLES = 'GET_ASSET_DYNAMIC_VARIABLES';
export const GET_ASSET_DYNAMIC_VARIABLES_SUCCESS =
  'GET_ASSET_DYNAMIC_VARIABLES_SUCCESS';
export const GET_ASSET_DYNAMIC_VARIABLES_FAILURE =
  'GET_ASSET_DYNAMIC_VARIABLES_FAILURE';

export const GET_NETWORK_DYNAMIC_VARIABLES = 'GET_NETWORK_DYNAMIC_VARIABLES';
export const GET_NETWORK_DYNAMIC_VARIABLES_SUCCESS =
  'GET_NETWORK_DYNAMIC_VARIABLES_SUCCESS';
export const GET_NETWORK_DYNAMIC_VARIABLES_FAILURE =
  'GET_NETWORK_DYNAMIC_VARIABLES_FAILURE';

export const GET_ALL_ASSET_ALERT_LIST = 'GET_ALL_ASSET_ALERT_LIST';
export const GET_ALL_ASSET_ALERT_LIST_SUCCESS =
  'GET_ALL_ASSET_ALERT_LIST_SUCCESS';
export const GET_ALL_ASSET_ALERT_LIST_FAILURE =
  'GET_ALL_ASSET_ALERT_LIST_FAILURE';

export const GET_ALL_NETWORK_ALERT_LIST = 'GET_ALL_NETWORK_ALERT_LIST';
export const GET_ALL_NETWORK_ALERT_LIST_SUCCESS =
  'GET_ALL_NETWORK_ALERT_LIST_SUCCESS';
export const GET_ALL_NETWORK_ALERT_LIST_FAILURE =
  'GET_ALL_NETWORK_ALERT_LIST_FAILURE';

export const CREATE_ASSET_ALERT = 'CREATE_ASSET_ALERT';
export const CREATE_ASSET_ALERT_SUCCESS = 'CREATE_ASSET_ALERT_SUCCESS';
export const CREATE_ASSET_ALERT_FAILURE = 'CREATE_ASSET_ALERT_FAILURE';
export const CREATE_ASSET_ALERT_RESET = 'CREATE_ASSET_ALERT_RESET';

export const UPDATE_ASSET_ALERT = 'UPDATE_ASSET_ALERT';
export const UPDATE_ASSET_ALERT_SUCCESS = 'UPDATE_ASSET_ALERT_SUCCESS';
export const UPDATE_ASSET_ALERT_FAILURE = 'UPDATE_ASSET_ALERT_FAILURE';
export const UPDATE_ASSET_ALERT_RESET = 'UPDATE_ASSET_ALERT_RESET';

export const DELETE_ASSET_ALERT_TEMPLATE = 'DELETE_ASSET_ALERT_TEMPLATE';
export const DELETE_ASSET_ALERT_TEMPLATE_SUCCESS =
  'DELETE_ASSET_ALERT_TEMPLATE_SUCCESS';
export const DELETE_ASSET_ALERT_TEMPLATE_FAILURE =
  'DELETE_ASSET_ALERT_TEMPLATE_FAILURE';
export const DELETE_ASSET_ALERT_TEMPLATE_RESET =
  'DELETE_ASSET_ALERT_TEMPLATE_RESET';

export const DELETE_NETWORK_ALERT_TEMPLATE = 'DELETE_NETWORK_ALERT_TEMPLATE';
export const DELETE_NETWORK_ALERT_TEMPLATE_SUCCESS =
  'DELETE_NETWORK_ALERT_TEMPLATE_SUCCESS';
export const DELETE_NETWORK_ALERT_TEMPLATE_FAILURE =
  'DELETE_NETWORK_ALERT_TEMPLATE_FAILURE';
export const DELETE_NETWORK_ALERT_TEMPLATE_RESET =
  'DELETE_NETWORK_ALERT_TEMPLATE_RESET';


  export const CREATE_NETWORK_ALERT = 'CREATE_NETWORK_ALERT';
  export const CREATE_NETWORK_ALERT_SUCCESS = 'CREATE_NETWORK_ALERT_SUCCESS';
  export const CREATE_NETWORK_ALERT_FAILURE = 'CREATE_NETWORK_ALERT_FAILURE';
  export const CREATE_NETWORK_ALERT_RESET = 'CREATE_NETWORK_ALERT_RESET';

  export const UPDATE_NETWORK_ALERT = 'UPDATE_NETWORK_ALERT';
  export const UPDATE_NETWORK_ALERT_SUCCESS = 'UPDATE_NETWORK_ALERT_SUCCESS';
  export const UPDATE_NETWORK_ALERT_FAILURE = 'UPDATE_NETWORK_ALERT_FAILURE';
  export const UPDATE_NETWORK_ALERT_RESET = 'UPDATE_NETWORK_ALERT_RESET';

export const GET_ALL_NETWORK_LIST = 'GET_ALL_NETWORK_LIST';
export const GET_ALL_NETWORK_LIST_SUCCESS = 'GET_ALL_NETWORK_LIST_SUCCESS';
export const GET_ALL_NETWORK_LIST_FAILURE = 'GET_ALL_NETWORK_LIST_FAILURE';
export const GET_ALL_NETWORK_LIST_RESET = 'GET_ALL_NETWORK_LIST_RESET';

export const CREATE_NETWORK_TEMPLATES = 'CREATE_NETWORK_TEMPLATES';
export const CREATE_NETWORK_TEMPLATES_SUCCESS =
  'CREATE_NETWORK_TEMPLATES_SUCCESS';
export const CREATE_NETWORK_TEMPLATES_FAILURE =
  'CREATE_NETWORK_TEMPLATES_FAILURE';
export const CREATE_NETWORK_TEMPLATES_RESET = 'CREATE_NETWORK_TEMPLATES_RESET';

export const UPDATE_NETWORK = 'UPDATE_NETWORK';
export const UPDATE_NETWORK_SUCCESS = 'UPDATE_NETWORK_SUCCESS';
export const UPDATE_NETWORK_FAILURE = 'UPDATE_NETWORK_FAILURE';
export const UPDATE_NETWORK_RESET = 'UPDATE_NETWORK_RESET';

export const DELETE_NETWORK = 'DELETE_NETWORK';
export const DELETE_NETWORK_SUCCESS = 'DELETE_NETWORK_SUCCESS';
export const DELETE_NETWORK_FAILURE = 'DELETE_NETWORK_FAILURE';
export const DELETE_NETWORK_RESET = 'DELETE_NETWORK_RESET';

export const GET_NETWORK_CALCULATED_PARAM_TEMPLATE =
  'GET_NETWORK_CALCULATED_PARAM_TEMPLATE';
export const GET_NETWORK_CALCULATED_PARAM_TEMPLATE_SUCCESS =
  'GET_NETWORK_CALCULATED_PARAM_TEMPLATE_SUCCESS';
export const GET_NETWORK_CALCULATED_PARAM_TEMPLATE_FAILURE =
  'GET_NETWORK_CALCULATED_PARAM_TEMPLATE_FAILURE';

export const DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE =
  'DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE';
export const DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE_SUCCESS =
  'DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE_SUCCESS';
export const DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE_FAILURE =
  'DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE_FAILURE';
export const DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE_RESET =
  'DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE_RESET';

export const GET_WORKING_NON_WORKING_HOURS_LIST =
  'GET_WORKING_NON_WORKING_HOURS_LIST';
export const GET_WORKING_NON_WORKING_HOURS_LIST_SUCCESS =
  'GET_WORKING_NON_WORKING_HOURS_LIST_SUCCESS';
export const GET_WORKING_NON_WORKING_HOURS_LIST_FAILURE =
  'GET_WORKING_NON_WORKING_HOURS_LIST_FAILURE';

export const DELETE_WORKING_NON_WORKING_HOURS_LIST =
'DELETE_WORKING_NON_WORKING_HOURS_LIST';
export const DELETE_WORKING_NON_WORKING_HOURS_LIST_SUCCESS =
'DELETE_WORKING_NON_WORKING_HOURS_LIST_SUCCESS';
export const DELETE_WORKING_NON_WORKING_HOURS_LIST_FAILURE =
'DELETE_WORKING_NON_WORKING_HOURS_LIST_FAILURE';
 
export const GET_PRODUCTION_NON_PRODUCTION_HOURS_LIST =
  'GET_PRODUCTION_NON_PRODUCTION_HOURS_LIST';
export const GET_PRODUCTION_NON_PRODUCTION_HOURS_LIST_SUCCESS =
  'GET_PRODUCTION_NON_PRODUCTION_HOURS_LIST_SUCCESS';
export const GET_PRODUCTION_NON_PRODUCTION_HOURS_LIST_FAILURE =
  'GET_PRODUCTION_NON_PRODUCTION_HOURS_LIST_FAILURE';

export const DELETE_PRODUCTION_NON_PRODUCTION_HOURS_LIST =
'DELETE_PRODUCTION_NON_PRODUCTION_HOURS_LIST';
export const DELETE_PRODUCTION_NON_PRODUCTION_HOURS_LIST_SUCCESS =
'DELETE_PRODUCTION_NON_PRODUCTION_HOURS_LIST_SUCCESS';
export const DELETE_PRODUCTION_NON_PRODUCTION_HOURS_LIST_FAILURE =
'DELETE_PRODUCTION_NON_PRODUCTION_HOURS_LIST_FAILURE';

export const GET_SHIFT_LIST =
  'GET_SHIFT_LIST';
export const GET_SHIFT_LIST_SUCCESS =
  'GET_SHIFT_LIST_SUCCESS';
export const GET_SHIFT_LIST_FAILURE =
  'GET_SHIFT_LIST_FAILURE';

export const DELETE_SHIFT_LIST =
'DELETE_SHIFT_LIST';
export const DELETE_SHIFT_LIST_SUCCESS =
'DELETE_SHIFT_LIST_SUCCESS';
export const DELETE_SHIFT_LIST_FAILURE =
'DELETE_SHIFT_LIST_FAILURE';

export const GET_NETWORK_INSIGHT_LIST =
'GET_NETWORK_INSIGHT_LIST';
export const GET_NETWORK_INSIGHT_LIST_SUCCESS =
'GET_NETWORK_INSIGHT_LIST_SUCCESS';
export const GET_NETWORK_INSIGHT_LIST_FAILURE =
'GET_NETWORK_INSIGHT_LIST_FAILURE';

export const GET_NETWORK_INSIGHT_DETAILS = 'GET_NETWORK_INSIGHT_DETAILS';
export const GET_NETWORK_INSIGHT_DETAILS_SUCCESS = 'GET_NETWORK_INSIGHT_DETAILS_SUCCESS';
export const GET_NETWORK_INSIGHT_DETAILS_FAILURE = 'GET_NETWORK_INSIGHT_DETAILS_FAILURE';

export const GET_NETWORK_SENSOR_TYPES = 'GET_NETWORK_SENSOR_TYPES';
export const GET_NETWORK_SENSOR_TYPES_SUCCESS = 'GET_NETWORK_SENSOR_TYPES_SUCCESS';
export const GET_NETWORK_SENSOR_TYPES_FAILURE = 'GET_NETWORK_SENSOR_TYPES_FAILURE';

export const GET_NETWORK_READING_TYPES = 'GET_NETWORK_READING_TYPES';
export const GET_NETWORK_READING_TYPES_SUCCESS = 'GET_NETWORK_READING_TYPES_SUCCESS';
export const GET_NETWORK_READING_TYPES_FAILURE = 'GET_NETWORK_READING_TYPES_FAILURE';

export const GET_ALL_ACTIONS_FOR_NETWORK = 'GET_ALL_ACTIONS_FOR_NETWORK';
export const GET_ALL_ACTIONS_FOR_NETWORK_SUCCESS = 'GET_ALL_ACTIONS_FOR_NETWORK_SUCCESS';
export const GET_ALL_ACTIONS_FOR_NETWORK_FAILURE = 'GET_ALL_ACTIONS_FOR_NETWORK_FAILURE';

export const CREATE_MANUAL_NETWORK_INSIGHT = "CREATE_MANUAL_NETWORK_INSIGHT";
export const CREATE_MANUAL_NETWORK_INSIGHT_SUCCESS = 'CREATE_MANUAL_NETWORK_INSIGHT_SUCCESS';
export const CREATE_MANUAL_NETWORK_INSIGHT_FAILURE = 'CREATE_MANUAL_NETWORK_INSIGHT_FAILURE';

export const GET_NETWORK_SME_CLIENT_SENSOR_LOCATIONS =
  'GET_NETWORK_SME_CLIENT_SENSOR_LOCATIONS';
export const GET_NETWORK_SME_CLIENT_SENSOR_LOCATIONS_SUCCESS =
  'GET_NETWORK_SME_CLIENT_SENSOR_LOCATIONS_SUCCESS';
export const GET_NETWORK_SME_CLIENT_SENSOR_LOCATIONS_FAILURE =
  'GET_NETWORK_SME_CLIENT_SENSOR_LOCATIONS_FAILURE';
export const GET_NETWORK_SME_CLIENT_SENSOR_LOCATIONS_RESET =
  'GET_NETWORK_SME_CLIENT_SENSOR_LOCATIONS_RESET';

export const CREATE_SYSTEM_TEMPLATE = 'CREATE_SYSTEM_TEMPLATE';
export const CREATE_SYSTEM_TEMPLATE_SUCCESS = 'CREATE_SYSTEM_TEMPLATE_SUCCESS';
export const CREATE_SYSTEM_TEMPLATE_FAILURE = 'CREATE_SYSTEM_TEMPLATE_FAILURE';
export const CREATE_SYSTEM_TEMPLATE_RESET = 'CREATE_SYSTEM_TEMPLATE_RESET';

export const UPDATE_SYSTEM_TEMPLATE = 'UPDATE_SYSTEM_TEMPLATE';
export const UPDATE_SYSTEM_TEMPLATE_SUCCESS = 'UPDATE_SYSTEM_TEMPLATE_SUCCESS';
export const UPDATE_SYSTEM_TEMPLATE_FAILURE = 'UPDATE_SYSTEM_TEMPLATE_FAILURE';
export const UPDATE_SYSTEM_TEMPLATE_RESET = 'UPDATE_SYSTEM_TEMPLATE_RESET';

export const DELETE_SYSTEM_TEMPLATE = 'DELETE_SYSTEM_TEMPLATE';
export const DELETE_SYSTEM_TEMPLATE_SUCCESS = 'DELETE_SYSTEM_TEMPLATE_SUCCESS';
export const DELETE_SYSTEM_TEMPLATE_FAILURE = 'DELETE_SYSTEM_TEMPLATE_FAILURE';
export const DELETE_SYSTEM_TEMPLATE_RESET = 'DELETE_SYSTEM_TEMPLATE_RESET';

export const GET_SYSTEM_TEMPLATES = 'GET_SYSTEM_TEMPLATES';
export const GET_SYSTEM_TEMPLATES_SUCCESS = 'GET_SYSTEM_TEMPLATES_SUCCESS';
export const GET_SYSTEM_TEMPLATES_FAILURE = 'GET_SYSTEM_TEMPLATES_FAILURE';
export const GET_SYSTEM_TEMPLATES_RESET = 'GET_SYSTEM_TEMPLATES_RESET';

export const GET_SYSTEM_TEMPLATE = 'GET_SYSTEM_TEMPLATE';
export const GET_SYSTEM_TEMPLATE_SUCCESS = 'GET_SYSTEM_TEMPLATE_SUCCESS';
export const GET_SYSTEM_TEMPLATE_FAILURE = 'GET_SYSTEM_TEMPLATE_FAILURE';
export const GET_SYSTEM_TEMPLATE_RESET = 'GET_SYSTEM_TEMPLATE_RESET';

export const GET_SYSTEM_TEMPLATE_WIDGETS = 'GET_SYSTEM_TEMPLATE_WIDGETS';
export const GET_SYSTEM_TEMPLATE_WIDGETS_SUCCESS =
  'GET_SYSTEM_TEMPLATE_WIDGETS_SUCCESS';
export const GET_SYSTEM_TEMPLATE_WIDGETS_FAILURE =
  'GET_SYSTEM_TEMPLATE_WIDGETS_FAILURE';
export const GET_SYSTEM_TEMPLATE_WIDGETS_RESET =
  'GET_SYSTEM_TEMPLATE_WIDGETS_RESET';

export const GET_SYSTEM_TEMPLATE_WIDGET = 'GET_SYSTEM_TEMPLATE_WIDGET';
export const GET_SYSTEM_TEMPLATE_WIDGET_SUCCESS =
  'GET_SYSTEM_TEMPLATE_WIDGET_SUCCESS';
export const GET_SYSTEM_TEMPLATE_WIDGET_FAILURE =
  'GET_SYSTEM_TEMPLATE_WIDGET_FAILURE';
export const GET_SYSTEM_TEMPLATE_WIDGET_RESET =
  'GET_SYSTEM_TEMPLATE_WIDGET_RESET';

export const _GET_SYSTEM_TEMPLATE_WIDGET = '_GET_SYSTEM_TEMPLATE_WIDGET';
export const _GET_SYSTEM_TEMPLATE_WIDGET_SUCCESS =
  '_GET_SYSTEM_TEMPLATE_WIDGET_SUCCESS';
export const _GET_SYSTEM_TEMPLATE_WIDGET_FAILURE =
  '_GET_SYSTEM_TEMPLATE_WIDGET_FAILURE';
export const _GET_SYSTEM_TEMPLATE_WIDGET_RESET =
  '_GET_SYSTEM_TEMPLATE_WIDGET_RESET';

export const CREATE_SYSTEM_TEMPLATE_WIDGET = 'CREATE_SYSTEM_TEMPLATE_WIDGET';
export const CREATE_SYSTEM_TEMPLATE_WIDGET_SUCCESS =
  'CREATE_SYSTEM_TEMPLATE_WIDGET_SUCCESS';
export const CREATE_SYSTEM_TEMPLATE_WIDGET_FAILURE =
  'CREATE_SYSTEM_TEMPLATE_WIDGET_FAILURE';
export const CREATE_SYSTEM_TEMPLATE_WIDGET_RESET =
  'CREATE_SYSTEM_TEMPLATE_WIDGET_RESET';

export const UPDATE_SYSTEM_TEMPLATE_WIDGET = 'UPDATE_SYSTEM_TEMPLATE_WIDGET';
export const UPDATE_SYSTEM_TEMPLATE_WIDGET_SUCCESS =
  'UPDATE_SYSTEM_TEMPLATE_WIDGET_SUCCESS';
export const UPDATE_SYSTEM_TEMPLATE_WIDGET_FAILURE =
  'UPDATE_SYSTEM_TEMPLATE_WIDGET_FAILURE';
export const UPDATE_SYSTEM_TEMPLATE_WIDGET_RESET =
  'UPDATE_SYSTEM_TEMPLATE_WIDGET_RESET';

export const DELETE_SYSTEM_TEMPLATE_WIDGET = 'DELETE_SYSTEM_TEMPLATE_WIDGET';
export const DELETE_SYSTEM_TEMPLATE_WIDGET_SUCCESS =
  'DELETE_SYSTEM_TEMPLATE_WIDGET_SUCCESS';
export const DELETE_SYSTEM_TEMPLATE_WIDGET_FAILURE =
  'DELETE_SYSTEM_TEMPLATE_WIDGET_FAILURE';
export const DELETE_SYSTEM_TEMPLATE_WIDGET_RESET =
  'DELETE_SYSTEM_TEMPLATE_WIDGET_RESET';

export const UPDATE_SYSTEM_TEMPLATE_WIDGETS_ORDERING =
  'UPDATE_SYSTEM_TEMPLATE_WIDGETS_ORDERING';
export const UPDATE_SYSTEM_TEMPLATE_WIDGETS_ORDERING_SUCCESS =
  'UPDATE_SYSTEM_TEMPLATE_WIDGETS_ORDERING_SUCCESS';
export const UPDATE_SYSTEM_TEMPLATE_WIDGETS_ORDERING_FAILURE =
  'UPDATE_SYSTEM_TEMPLATE_WIDGETS_ORDERING_FAILURE';
export const UPDATE_SYSTEM_TEMPLATE_WIDGETS_ORDERING_RESET =
  'UPDATE_SYSTEM_TEMPLATE_WIDGETS_ORDERING_RESET';

export const GET_SYSTEM_META_FIELDS = 'GET_SYSTEM_META_FIELDS';
export const GET_SYSTEM_META_FIELDS_SUCCESS = 'GET_SYSTEM_META_FIELDS_SUCCESS';
export const GET_SYSTEM_META_FIELDS_FAILURE = 'GET_SYSTEM_META_FIELDS_FAILURE';
export const GET_SYSTEM_META_FIELDS_RESET = 'GET_SYSTEM_META_FIELDS_RESET';

export const GET_SYSTEM_NAMES = 'GET_SYSTEM_NAMES';
export const GET_SYSTEM_NAMES_SUCCESS = 'GET_SYSTEM_NAMES_SUCCESS';
export const GET_SYSTEM_NAMES_FAILURE = 'GET_SYSTEM_NAMES_FAILURE';
export const GET_SYSTEM_NAMES_RESET = 'GET_SYSTEM_NAMES_RESET';

export const GET_SYSTEM_DETAILS = 'GET_SYSTEM_DETAILS';
export const GET_SYSTEM_DETAILS_SUCCESS = 'GET_SYSTEM_DETAILS_SUCCESS';
export const GET_SYSTEM_DETAILS_FAILURE = 'GET_SYSTEM_DETAILS_FAILURE';
export const GET_SYSTEM_DETAILS_RESET = 'GET_SYSTEM_DETAILS_RESET';


export const GET_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE = 'GET_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE';
export const GET_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS =
  'GET_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS';
export const GET_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_FAILURE =
  'GET_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_FAILURE';

export const DELETE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE =
  'DELETE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE';
export const DELETE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS =
  'DELETE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS';
export const DELETE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_FAILURE =
  'DELETE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_FAILURE';
export const DELETE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_RESET =
  'DELETE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_RESET';

export const UPDATE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE =
  'UPDATE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE';
export const UPDATE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS =
  'UPDATE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_SUCCESS';
export const UPDATE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_FAILURE =
  'UPDATE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_FAILURE';
export const UPDATE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_RESET =
  'UPDATE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_RESET';