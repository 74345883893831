import formulaConfIdGet from './formulaConfIdGet';
import tagConfInputGet from './tagConfInputGet';

export default () => {
  return {
    id: formulaConfIdGet(),
    type: 'parameter',
    alias: '',
    parameter: tagConfInputGet()
  };
};
