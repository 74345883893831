import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import axios from 'axios';
import { httpMethodGetJSON, httpMethodPatch, httpMethodDelete, httpMethodPost, } from '../https';
import { ajax } from 'rxjs/ajax';
import { map } from 'lodash';
import { URL } from '../constants';
import * as AppConstants from './constant';


export const getAllUsers = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_USERS),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/iam/users/`);
    })
);

export const getUserDetails = action$ => action$.pipe(
    ofType(AppConstants.GET_USER_DETAILS),
    mergeMap(action => {
        return httpMethodGetJSON({ type: AppConstants.GET_USER_DETAILS }, `${URL}/iam/users/${action.payload.id}/`);
    })
);

export const createUser = action$ => action$.pipe(
    ofType(AppConstants.CREATE_USER),
    mergeMap(action => {
        return httpMethodPost(action, `${URL}/iam/users/`);
    })

);

export const updateUser = action$ => action$.pipe(
    ofType(AppConstants.UPDATE_USER),
    mergeMap(action => {
        return httpMethodPatch({ type: AppConstants.UPDATE_USER, payload: action.payload.data }, `${URL}/iam/users/${action.payload.user_id}/`);
    })
);

export const deleteUser = action$ => action$.pipe(
    ofType(AppConstants.DELETE_USER),
    mergeMap((action, a) => {
        return axios.delete(`${URL}/iam/users/${action.payload.member_id}/`, {
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
            },
            data: action.payload,
        }).then((response) => {
            return { type: AppConstants.DELETE_USER_SUCCESS, payload: response.data };
        }).catch(error => {
            return {
                type: AppConstants.DELETE_USER_FAILURE,
                error: true,
                payload: error
            }
        })

    })
);

export const getAllRoles = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_ROLES),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/iam/roles/`);
    })
);

export const getDashboardsByUserId = action$ => action$.pipe(
    ofType(AppConstants.GET_DASHBOARD_BY_USER_ID),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/iam/users/${action.payload}/dashboards/`);
    })
);

export const getWidgetsByDashboardAndUserId = action$ => action$.pipe(
    ofType(AppConstants.GET_WIDGETS_BY_DASHBOARD_AND_USER_ID),
    mergeMap(action => {
        return httpMethodGetJSON({ type: AppConstants.GET_WIDGETS_BY_DASHBOARD_AND_USER_ID, payload: action.payload.dashboard_id }, `${URL}/iam/users/${action.payload.user_id}/dashboards/${action.payload.dashboard_id}/widgets/`);
    })
);


export const userEpics = {
    getAllUsers,
    getUserDetails,
    createUser,
    updateUser,
    deleteUser,
    getAllRoles,
    getDashboardsByUserId,
    getWidgetsByDashboardAndUserId
}