import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";
import { httpMethodGetJSON } from "../https";
import { REPORT_DOWNLOAD_WIDGET_DATA } from "./constant";
import { URL } from "../constants";

export const getReportDownloadData = (action$) =>
    action$.pipe(
        ofType(REPORT_DOWNLOAD_WIDGET_DATA),
        mergeMap((action) => {
            return httpMethodGetJSON(action, `${URL}/reports/asset/${action.payload.id}/`);
        }),
    );