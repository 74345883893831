import { GET_CBM_ASSETS, GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME, GET_ALL_ASSETS } from '../../../AssetInsights/constant';
import {
  OVERVIEW_WIDGET_DATA,
  OVERVIEW_READING_DATA,
  OVERVIEW_READING_LIVE,
  
  DIAL_LIVE_WIDGET_DATA,
  DIAL_COMPUTED_WIDGET_DATA,
  HEALTH_WIDGET_DATA,
  HEATMAP_WIDGET_DATA,
  HEATMAP_GRAPH_DATA,
  HEATMAP_GRAPH_DATA_RESET,
  PIECHART_WIDGET_DATA,
  PIECHART_WIDGET_DATA_RESET,
  INSIGHT_WIDGET_DATA,
  CYCLE_DISTRIBUTION_WIDGET_DATA,
  ANOMALY_WIDGET_DATA,
  GET_ANOMALY_IMPACT_DATA,
  DIGITBOX1_WIDGET_DATA,
  DIGITBOX2_WIDGET_DATA,
  DIGITBOX3_WIDGET_DATA,
  DIGITBOX3_SERIES_WIDGET_DATA,
  DIGITBOX2_LIVE_WIDGET_DATA,
  DIGITBOX2_TREND_WIDGET_DATA,
  DIGITBOX2_COST_WIDGET_DATA,
  DIGITBOX2_COST_SERIES_WIDGET_DATA,
  PARAM_PERF_WIDGET_DATA,
  PARAM_PERF_SERIES_DATA,
  RUN_HOUR_SERIES_DATA, 
  RUN_HOUR_COMPUTED_DATA,
  GRAPH_NORMAL_DATA,
  GRAPH_COMPUTED_DATA,
  GRAPH_PARAMETER_DATA,
  ALERT_LOG_DATA, 
  LIVETABLE_WIDGET_DATA,
  TABLE_WIDGET_DATA, 
  GET_ALL_TREES, 
  GET_TREE_DATA, 
  CREATE_TREE_DATA, 
  CREATE_NODE_DATA, 
  CREATE_NODE_DATA_RESET,
  DELETE_NODE, 
  GET_TREE_SENSOR_HEALTH_DATA, 
  GET_GIS_SENSOR_WISE_DATA,
  GET_FACTORY_DATA,
  GET_FACTORY_INFOWINDOW_DATA,
  EDIT_NODE_DATA,
  EDIT_NODE_DATA_RESET,
  ALARM_DATA,
  UPLOAD_IMAGE_CONFIGURATION,
  GET_UPLOAD_IMAGE_WIDGET_DATA, 
  GET_LIST_VIEW_PARAM_DATA, 
  GET_PARAM_SUMMARY_DATA, 
  GET_ASSET_HISTORY,
  GET_ASSET_INSIGHT_PARAMS, 
  GET_ASSET_OPEN_INSIGHTS, 
  GET_ASSET_INSIGHTS,
  GET_ASSET_INSIGHTS_CONFIG, 
  SUBMIT_ASSET_FEEDBACK, 
  SUBMIT_ASSET_FEEDBACK_RESET, 
  GET_INSIGHT_GRAPH_DATA, 
  GET_INSIGHT_GRAPH_DATA_RESET,
  GET_PARAM_META_DATA, 
  DIGITBOX4_WIDGET_DATA,
  GET_INSIGHTS_ALERTS_DATA,
  GET_SYSTEMS_INSIGHTS_ALERTS_DATA,
  ADD_SELECTED_INSIGHTS_ALERT,
  OPEN_CRITICAL_EVENT,
  GET_WELLNESS_DATA,
  GET_ALERTS_WIDGET_DATA,
  CHANGE_ALERTS_WIDGET_MODAL,
  UPDATE_ALERTS_WIDGET_STATUS,
  GET_ASSET_INSIGHT_ACTION,
  GET_ALERTS_WIDGET_TABLEDATA,
  CHANGE_ALERTS_GRAPH_MODAL,
  SELECT_ALERTS_LIST,
  GET_ALERTS_WIDGET_GRAPH_DATA,
  GET_WIDGET_LIST_FROM_TEMPLATE,
  GET_SYSTEM_WIDGET_LIST_FROM_TEMPLATE,
  ANALYZE_DATA,
  GRAPH_READING_SERIES,
	GET_TAG_DATA,
  GET_CXO_SUMMARY,
  GET_TAG_TIMESERIES_DATA,
  SCORECARD_WIDGET_DATA,
  SCORECARD_WIDGET_DATA_RESET,
  GET_TAG_TIMESERIES_COMPUTED_DATA,
  GET_FACILITY_DATA,
  GET_INSIGHT_IMPACT_CONFIG,
  UPDATE_INSIGHT_IMPACT_CONFIG,
  GET_TAG_READING_DATA,
  ARCH_CHART_WIDGET_DATA,
  GET_CONFIGURED_ALERT_COUNT,
  INFLUNCERS_DATA,
  INFLUNCERS_DATA_RESET,
  GET_ALL_INSIGHT_FOR_GROUP,
  GET_SYSTEM_ASSET_CATEGORY,
  GET_SYSTEM_ASSET_PERFORMANCE_DATA,
  GET_SYSTEMS_AND_ASSET_INSIGHTS_ALERTS_DATA,
  GET_ASSET_CBM_ASSETS_OVERVIEW,
  GET_ASSET_CBM_ASSETS_OVERVIEW_RESET
} from './widgetConstants';


export function getOverviewData(value) {
  return {
    type: OVERVIEW_WIDGET_DATA,
    payload: value
  }
}

export function getOverviewReadingData(value) {
  return {
    type: OVERVIEW_READING_DATA,
    payload: value
  }
}

export function getOverviewReadingLive(value) {
  return {
    type: OVERVIEW_READING_LIVE,
    payload: {...value, readingtype_sum: true}
  }
}

export function getDialLiveData(value) {
  return {
    type: DIAL_LIVE_WIDGET_DATA,
    payload: value
  }
}

export function getDialComputedData(value) {
  return {
    type: DIAL_COMPUTED_WIDGET_DATA,
    payload: value
  }
}

export function getHealthData(value) {
  return {
    type: HEALTH_WIDGET_DATA,
    payload: value
  }
}

export function getInsightData(value) {
  return {
    type: INSIGHT_WIDGET_DATA,
    payload: value
  }
}

export function getCycleDistributionData(value) {
  return {
    type: CYCLE_DISTRIBUTION_WIDGET_DATA,
    payload: value
  }
}

export function getAnoamlyData(value) {
  return {
    type: ANOMALY_WIDGET_DATA,
    payload: value
  }
}

export const getAnomalyImpactData = (value) => {
  return {
    type: GET_ANOMALY_IMPACT_DATA,
    payload: value
  };
};

export function getHeatmapData(value) {
  return {
    type: HEATMAP_WIDGET_DATA,
    payload: value
  }
}

export function getHeatmapGraphData(value) {
  return {
    type: HEATMAP_GRAPH_DATA,
    payload: value
  }
}

export function resetHeatmapGraphData(value) {
  return {
    type: HEATMAP_GRAPH_DATA_RESET,
    payload: value
  }
}

export function getPieChartData(value) {
  return {
    type: PIECHART_WIDGET_DATA,
    payload: value
  }
}

export function pieChartDataReset(value) {
  return {
    type: PIECHART_WIDGET_DATA_RESET,
    payload: value
  }
}

export function getArchChartData(value) {
  return {
    type: ARCH_CHART_WIDGET_DATA,
    payload: value
  };
}

export function getDigitbox1Data(value) {
  return {
    type: DIGITBOX1_WIDGET_DATA,
    payload: value
  }
}

export function getDigitbox2Data(value) {
  return {
    type: DIGITBOX2_WIDGET_DATA,
    payload: value
  }
}

export function getDigitbox2Live(value) {
  return {
    type: DIGITBOX2_LIVE_WIDGET_DATA,
    payload: value
  }
}

export function getDigitbox2Trend(value) {
  return {
    type: DIGITBOX2_TREND_WIDGET_DATA,
    payload: value
  }
}

export function getDigitbox2Cost(value) {
  return {
    type: DIGITBOX2_COST_WIDGET_DATA,
    payload: value
  }
}

export function getDigitbox2CostSeries(value) {
  return {
    type: DIGITBOX2_COST_SERIES_WIDGET_DATA,
    payload: value
  }
}

export function getDigitbox3Data(value) {
  return {
    type: DIGITBOX3_WIDGET_DATA,
    payload: value
  }
}

export function getDigitbox3DataSeries(value) {
  return {
    type: DIGITBOX3_SERIES_WIDGET_DATA,
    payload: value
  }
}

export function getDigitbox4Data(value) {
  return {
    type: DIGITBOX4_WIDGET_DATA,
    payload: value
  }
}

export function getParamPerfData(value) {
  return {
    type: PARAM_PERF_WIDGET_DATA,
    payload: value
  }
}

export function getParamPerfSeriesData(value) {
  return {
    type: PARAM_PERF_SERIES_DATA,
    payload: value
  }
}

export function getRunHourSeriesData(value) {
  return {
    type: RUN_HOUR_SERIES_DATA,
    payload: value
  }
}

export function getRunHourComputedData(value) {
  return {
    type: RUN_HOUR_COMPUTED_DATA,
    payload: value
  }
}

export function getGraphComputedData(value) {
  return {
    type: GRAPH_COMPUTED_DATA,
    payload: value
  }
}

export function getGraphNormalData(value) {
  return {
    type: GRAPH_NORMAL_DATA,
    payload: value
  }
}

export function getGraphReadingData(value) {
  return {
    type: GRAPH_READING_SERIES,
    payload: value
  }
}

export function getAlertLogData(value) {
  return {
    type: ALERT_LOG_DATA,
    payload: value
  }
}

export function getAlarmData(value) {
  return {
    type: ALARM_DATA,
    payload: value
  }
}

export function getTableData(value) {
  return {
    type: TABLE_WIDGET_DATA,
    payload: value
  }
}

export function getAllTrees(value) {
  return {
    type: GET_ALL_TREES,
    payload: value
  }
}

export function getTreeData(value) {
  return {
    type: GET_TREE_DATA,
    payload: value
  }
}

export function createTree(value) {
  return {
    type: CREATE_TREE_DATA,
    payload: value
  }
}
export function createNode(value) {
  return {
    type: CREATE_NODE_DATA,
    payload: value
  }
}

export function createNodeReset(value) {
  return {
    type: CREATE_NODE_DATA_RESET,
    payload: value
  }
}

export function editNode(value) {
  return {
    type: EDIT_NODE_DATA,
    payload: value
  }
}

export function editNodeReset(value) {
  return {
    type: EDIT_NODE_DATA_RESET,
    payload: value
  }
}

export function deleteNode(value) {
  return {
    type: DELETE_NODE,
    payload: value
  }
}

export function treeSonsorHealth(value) {
  return {
    type: GET_TREE_SENSOR_HEALTH_DATA,
    payload: value
  }
}

export function getLiveTableData(value) {
  return {
    type: LIVETABLE_WIDGET_DATA,
    payload: value
  }
}

export function getGisSensorWiseData(value) {
  return {
    type: GET_GIS_SENSOR_WISE_DATA,
    payload: value
  }
}

export function getFactoryData(value) {
  return {
    type: GET_FACTORY_DATA,
    payload: value
  }
}

export function getFactoryInfoWindowData(value) {
  return {
    type: GET_FACTORY_INFOWINDOW_DATA,
    payload: value
  }
}

export function getInsightsAlertsData(value) {
  return {
    type: GET_INSIGHTS_ALERTS_DATA,
    payload: value
  }
}

export function getSystemInsightsAlertsData(value) {
  return {
    type: GET_SYSTEMS_INSIGHTS_ALERTS_DATA,
    payload: value
  }
}


export function getSystemAndAssetInsightsData(value) {
  return {
    type: GET_SYSTEMS_AND_ASSET_INSIGHTS_ALERTS_DATA,
    payload: value
  }
}



export function addSelectedInsightsAlert(value) {
  return {
    type: ADD_SELECTED_INSIGHTS_ALERT,
    payload: value
  }
}

export function getOpenCriticalEvents(value) {
  return {
    type: OPEN_CRITICAL_EVENT,
    payload: value
  }
}

export function getAlertsWidgetData(value) {
  return {
    type: GET_ALERTS_WIDGET_DATA,
    payload: value
  }
}

export function getAlertsWidgetTableData(value) {
  return {
    type: GET_ALERTS_WIDGET_TABLEDATA,
    payload: value
  }
}

export function getAlertsWidgetGraphData(value) {
  return {
    type: GET_ALERTS_WIDGET_GRAPH_DATA,
    payload: value
  }
}

export function getWellnessData(value) {
  return {
    type: GET_WELLNESS_DATA,
    payload: value
  }
}

export function uploadImageConfiguration(value) {
  return {
    type: UPLOAD_IMAGE_CONFIGURATION,
    payload: value
  }
}

export function getUploadImageWidgetData(value) {
  return {
    type: GET_UPLOAD_IMAGE_WIDGET_DATA,
    payload: value
  }
}

export function getListViewParamData(value) {
  return {
    type: GET_LIST_VIEW_PARAM_DATA,
    payload: value
  }
}

export function getParamSummaryData(value) {
  return {
    type: GET_PARAM_SUMMARY_DATA,
    payload: value
  }
}

export function getParamSummaryMetaData(value) {
  return {
    type: GET_PARAM_META_DATA,
    payload: value
  }
}

export function getAssetHistory(value) {
  return {
    type: GET_ASSET_HISTORY,
    payload: value
  }
}

export const getSpecificAssetDetailsByAssetName = value => ({
  type: GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME,
  payload: value
});

export function getAssetInsightParamList(value) {
  return {
      type: GET_ASSET_INSIGHT_PARAMS,
      payload: value
  }
}

export const getCbmAssets = value => ({
  type: GET_CBM_ASSETS,
  payload: value
});

export const getAllAssets = value => ({
  type: GET_ALL_ASSETS,
  payload: value
});

export const getAssetsOpenInsights = value => ({
  type: GET_ASSET_OPEN_INSIGHTS,
  payload: value
});

export const getAssetsInsights = value => ({
  type: GET_ASSET_INSIGHTS,
  payload: value
});

export const getAssetsInsightConfig = value => ({
  type: GET_ASSET_INSIGHTS_CONFIG,
  payload: value
});

export const submitAssetFeedback = value => ({
  type: SUBMIT_ASSET_FEEDBACK,
  payload: value
});

export const submitAssetFeedbackReset = value => ({
  type: SUBMIT_ASSET_FEEDBACK_RESET,
  payload: value
});

export const getAssetInsightGraphData = value => ({
  type: GET_INSIGHT_GRAPH_DATA,
  payload: value
});

export const getAssetInsightGraphDataReset = (value) => ({
  type: GET_INSIGHT_GRAPH_DATA_RESET,
  payload: value
});

export const getInfluencersData = value => ({
  type: INFLUNCERS_DATA,
  payload: value
});

export const resetGetInfluencersData = (value) => ({
  type: INFLUNCERS_DATA_RESET,
  payload: value
});

export const changeAlertsWidgetModal = value => ({
  type: CHANGE_ALERTS_WIDGET_MODAL,
  payload: value
});

export const changeAlertsGraphModal = value => ({
  type: CHANGE_ALERTS_GRAPH_MODAL,
  payload: value
});

export const selectAlertsList = value => ({
  type: SELECT_ALERTS_LIST,
  payload: value
});

export const updateAlertWidgetStatus = value => ({
  type: UPDATE_ALERTS_WIDGET_STATUS,
  payload: value
});

export const getAssetInsightAction = value => ({
  type: GET_ASSET_INSIGHT_ACTION,
  payload: value
});

export const getWidgetListFromTemplateId = value => ({
  type: GET_WIDGET_LIST_FROM_TEMPLATE,
  payload: value
});


export const getSystemWidgetListFromTemplateId = value => ( {
  type: GET_SYSTEM_WIDGET_LIST_FROM_TEMPLATE,
  payload: value
});

export function getAnalyzeData(value) {
  return {
    type: ANALYZE_DATA,
    payload: value
  }
};

export function getGraphParameterData(value) {
  return {
    type: GRAPH_PARAMETER_DATA,
    payload: value
  }
};
export function getTagData(value="") {
return {
  type: GET_TAG_DATA,
  payload: value
}
};

export function getCxoSummary(value="") {
  return {
    type: GET_CXO_SUMMARY,
    payload: value
  }
  };

export function getCxoSummaryComputed(value="") {
  return {
    type: GET_CXO_SUMMARY,
    payload: value
  }
  };  

export const getTagTimeseriesData = (value = '') => {
  return {
    type: GET_TAG_TIMESERIES_DATA,
    payload: value
  };
};

export function getScoreCardData(value) {
  return {
    type: SCORECARD_WIDGET_DATA,
    payload: value
  }
}

export const getScoreCardDataReset = (value) => {
  return {
    type: SCORECARD_WIDGET_DATA_RESET,
    payload: value
  };
};

export const getTagTimeseriesComputedData = (value = '') => {
  return {
    type: GET_TAG_TIMESERIES_COMPUTED_DATA,
    payload: value
  };
};

export function getFacilityData(value) {
  return {
    type: GET_FACILITY_DATA,
    payload: value
  }
}

export function getInsightImpactConfig(value) {
  return {
    type: GET_INSIGHT_IMPACT_CONFIG,
    payload: value
  }
}

export function updateInsightImpactConfig(value) {
  return {
    type: UPDATE_INSIGHT_IMPACT_CONFIG,
    payload: value
  }
}

export const getTagReadingData = (value) => {
  return {
    type: GET_TAG_READING_DATA,
    payload: value
  };
};

export const getConfiguredAlertCount = (value) => {
  return {
    type: GET_CONFIGURED_ALERT_COUNT,
    payload: value
  };
};

export function getAllInsightForGroup(value) {
  return {
    type: GET_ALL_INSIGHT_FOR_GROUP,
    payload: value
  };
}

export function getSystemAssetCategory(value) {
  return {
    type: GET_SYSTEM_ASSET_CATEGORY,
    payload: value
  };
}

export function getSystemAssetPerformanceData(value) {
  return {
    type: GET_SYSTEM_ASSET_PERFORMANCE_DATA,
    payload: value
  };
}

export const getAssetCbmAssetsOverview = (value) => {
  return {
    type: GET_ASSET_CBM_ASSETS_OVERVIEW,
    payload: value
  };
};

export const getAssetCbmAssetsOverviewReset = (value) => {
  return {
    type: GET_ASSET_CBM_ASSETS_OVERVIEW_RESET,
    payload: value
  };
};