import tagConfInputGet from './tagConfInputGet';
import dashboardFilterConfigInputMeta from './dashboardFilterConfigInputMeta';

export default () => {
  return {
    tagConfs: Array.from({ length: 2 }).fill({
      ...dashboardFilterConfigInputMeta,
      ...tagConfInputGet()
    })
  };
};
