import { WHATIF_WIDGET_DATA, WHATIF_WIDGET_DATA_SUCCESS, WHATIF_WIDGET_DATA_FAILURE } from "./constant";

const INITIAL_STATE = {
    data: [],
    loading: false,
    error: false,
    success: undefined,
};

const WIDGET_INITIAL_STATE = {
    data: { ...INITIAL_STATE, data: {} },
    loading: false,
    error: false,
    success: undefined,
};

export function getWhatIfData(state = WIDGET_INITIAL_STATE, action) {
    switch (action.type) {
        case WHATIF_WIDGET_DATA:
            let prevData = state.data;
            prevData[action.payload.widgetId] = { ...INITIAL_STATE, loading: true };
            return {
                ...state,
                loading: true,
                data: prevData,
                error: false,
                success: undefined,
            };
        case WHATIF_WIDGET_DATA_SUCCESS:
            let newData = state.data;
            let res = action.response;
            if (action.response && action.response[0]) {
                action.response[0].alias = action.param.alias;
                action.response[0].function = action.param.function;
                action.response[0].target = action.param.target;
                action.response[0].section = action.param.section;
                action.response[0].costRate = action.param.costRate;
                action.response[0].costCurrency = action.param.costCurrency;
                action.response[0].plant = action.param.plant;
            }

            if (action.param.isComputed) {
                let d = !isNaN(action.response.response.data) ?
                    Number(action.response.response.data.toFixed(2)) :
                    undefined;

                res = [{
                    value: d,
                    alias: action.param.alias,
                    isComputed: action.param.isComputed,
                    unit: action.param.unit,
                    configParam: action.param.configParam,
                    costRate: action.param.costRate,
                    costCurrency: action.param.costCurrency,
                    function: action.param.function,
                    target: action.param.target,
                    section: action.param.section,
                    plant: action.param.plant,
                }]
            }

            newData[action.param.widgetId] = {
                ...newData[action.param.widgetId],
                data: [...state.data[action.param.widgetId].data, ...res],
                loading: false,
                success: true,
                error: false
            };

            return {
                ...state,
                data: newData,
                error: false,
                loading: false,
                success: true,
            };
        case WHATIF_WIDGET_DATA_FAILURE:
            let errorData = state.data;
            errorData[action.payload.widgetId] = { ...INITIAL_STATE, error: true };

            return {
                ...state,
                error: true,
                data: errorData,
                success: false,
            };
        default:
            return state;
    }
}