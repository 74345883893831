import React, { useState, useEffect, Fragment } from 'react';

import { CheckBox, Button } from '../../../../../../../common/components';

const OthersConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [showSystemAlerts, setShowSystemAlerts] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams['Alerts-Widget'] &&
        props.widgetParams['Alerts-Widget'].value;

      setShowSystemAlerts(conf ? conf.showSystemAlerts : true);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      'Alerts-Widget': {
        value: {
          showSystemAlerts,
          ...object
        }
      }
    };

    props.appInputParamChanged(value);
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <div className='new-input-wrap mb-3'>
          <div className='new-custom-checkbox'>
            <CheckBox
              label='Show System Alerts'
              size='sm'
              checked={showSystemAlerts}
              checkboxClicked={(showSystemAlerts) => {
                setShowSystemAlerts(showSystemAlerts);

                appInputParamChangedHandle({ showSystemAlerts });
              }}
            />
          </div>
        </div>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{renderFn()}</Fragment>;
};

export default OthersConfig;
