import { GET_ASSETS_CONDITION_DATA, GET_ASSETS_CONDITION_DATA_SUCCESS, GET_ASSETS_CONDITION_DATA_FAILURE, GET_ASSETS_DETAIL_DATA, GET_ASSETS_DETAIL_DATA_SUCCESS, GET_ASSETS_DETAIL_DATA_FAILURE, GET_ASSETS_LIST_DATA, GET_ASSETS_LIST_DATA_SUCCESS,GET_ASSETS_LIST_DATA_FAILURE, GET_ASSET_ACTION_DATA, GET_ASSET_ACTION_DATA_SUCCESS, GET_ASSET_ACTION_DATA_FAILURE, POST_ASSET_ACTION_DATA, POST_ASSET_ACTION_DATA_SUCCESS, POST_ASSET_ACTION_DATA_FAILURE, GET_ASSETS_ALERT_DATA, GET_ASSETS_ALERT_DATA_SUCCESS,GET_ASSETS_ALERT_DATA_FAILURE, POST_ALERT_ACTION_DATA,POST_ALERT_ACTION_DATA_SUCCESS, POST_ALERT_ACTION_DATA_FAILURE,
  GET_ASSETS_OPEN_INSIGHT_DATA,
  GET_ASSETS_OPEN_INSIGHT_DATA_SUCCESS,
  GET_ASSETS_OPEN_INSIGHT_DATA_FAILURE,
  GET_ASSETS_HEALTH_INDEX_DATA,
  GET_ASSETS_HEALTH_INDEX_DATA_SUCCESS,
  GET_ASSETS_HEALTH_INDEX_DATA_FAILURE

} from '../constants'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false
}

export function getAssetsDetailsDataReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ASSETS_DETAIL_DATA:

      // polling call do not empty state as success will update actual data
      if (action.payload.polling === true) {
        return state;
      }

      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case GET_ASSETS_DETAIL_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case GET_ASSETS_DETAIL_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}


export function getAssetsListDataReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ASSETS_LIST_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case GET_ASSETS_LIST_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case GET_ASSETS_LIST_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}



export function getAssetsActionDataReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ASSET_ACTION_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case GET_ASSET_ACTION_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case GET_ASSET_ACTION_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}



  export function postAssetsActionDataReducer (state = INITIAL_STATE, action) {
    switch (action.type) {
      case POST_ASSET_ACTION_DATA:
        return {
          ...state,
          loading: true,
          data: [],
          error: false
        }
      case POST_ASSET_ACTION_DATA_SUCCESS:
        return {
          ...state,
          data: action.payload.response,
          error: false,
          loading: false
        }
      case POST_ASSET_ACTION_DATA_FAILURE:
        return {
          ...state,
          error: true,
          data: [],
          loading: false
        }
      default:
        return state
    }
  }


export function postAlertActionDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case POST_ALERT_ACTION_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case POST_ALERT_ACTION_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload.response,
        error: false,
        loading: false
      }
    case POST_ALERT_ACTION_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}

    


export function getAssetsAlertDataReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ASSETS_ALERT_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case GET_ASSETS_ALERT_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case GET_ASSETS_ALERT_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}

//   export function getAssetsDetailConditionDataReducer(state = INITIAL_STATE, action) {
//     switch (action.type) {
//       case GET_ASSETS_CONDITION_DATA:
//         return {
//           ...state,
//           loading: true,
//           data: [],
//           error: false
//         }
//       case GET_ASSETS_CONDITION_DATA_SUCCESS:
//         return {
//           ...state,
//           data: action.payload,
//           error: false,
//           loading: false
//         }
//       case GET_ASSETS_CONDITION_DATA_FAILURE:
//         return {
//           ...state,
//           error: true,
//           data: [],
//           loading: false
//         }
//       default:
//         return state
//     }
// }



export function getAssetsOpenInsightsDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ASSETS_OPEN_INSIGHT_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case GET_ASSETS_OPEN_INSIGHT_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case GET_ASSETS_OPEN_INSIGHT_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}



export function getAssetsHeathDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ASSETS_HEALTH_INDEX_DATA:
      
      // polling call do not empty state as success will update actual data
      if (action.payload.polling === true) {
        return state;
      }

      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case GET_ASSETS_HEALTH_INDEX_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case GET_ASSETS_HEALTH_INDEX_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}
