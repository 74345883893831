import queryString from 'query-string';

import {
  GET_WIDGET_DETAILS_TEMPLATE,
  GET_WIDGET_DETAILS_TEMPLATE_FAILURE,
  GET_WIDGET_DETAILS_TEMPLATE_SUCCESS,
  GET_GROUP_WIDGET_DETAILS_TEMPLATE,
  GET_GROUP_WIDGET_DETAILS_TEMPLATE_FAILURE,
  GET_GROUP_WIDGET_DETAILS_TEMPLATE_SUCCESS,
} from "../../../AssetScreenDesign/constant";
import {
  OVERVIEW_WIDGET_DATA,
  OVERVIEW_WIDGET_DATA_SUCCESS,
  OVERVIEW_WIDGET_DATA_FAILURE,
  OVERVIEW_READING_DATA,
  OVERVIEW_READING_DATA_FAILURE,
  OVERVIEW_READING_DATA_SUCCESS,
  OVERVIEW_READING_LIVE,
  OVERVIEW_READING_LIVE_FAILURE,
  OVERVIEW_READING_LIVE_SUCCESS,
  DIAL_LIVE_WIDGET_DATA,
  DIAL_LIVE_WIDGET_DATA_SUCCESS,
  DIAL_LIVE_WIDGET_DATA_FAILURE,
  DIAL_COMPUTED_WIDGET_DATA,
  DIAL_COMPUTED_WIDGET_DATA_SUCCESS,
  DIAL_COMPUTED_WIDGET_DATA_FAILURE,
  HEALTH_WIDGET_DATA,
  HEALTH_WIDGET_DATA_SUCCESS,
  HEALTH_WIDGET_DATA_FAILURE,
  INSIGHT_WIDGET_DATA,
  INSIGHT_WIDGET_DATA_SUCCESS,
  INSIGHT_WIDGET_DATA_FAILURE,
  HEATMAP_WIDGET_DATA,
  HEATMAP_WIDGET_DATA_SUCCESS,
  HEATMAP_WIDGET_DATA_FAILURE,
  HEATMAP_GRAPH_DATA,
  HEATMAP_GRAPH_DATA_SUCCESS,
  HEATMAP_GRAPH_DATA_FAILURE,
  HEATMAP_GRAPH_DATA_RESET,
  PIECHART_WIDGET_DATA,
  PIECHART_WIDGET_DATA_SUCCESS,
  PIECHART_WIDGET_DATA_FAILURE,
  PIECHART_WIDGET_DATA_RESET,
  CYCLE_DISTRIBUTION_WIDGET_DATA,
  CYCLE_DISTRIBUTION_WIDGET_DATA_SUCCESS,
  CYCLE_DISTRIBUTION_WIDGET_DATA_FAILURE,
  ANOMALY_WIDGET_DATA,
  ANOMALY_WIDGET_DATA_SUCCESS,
  ANOMALY_WIDGET_DATA_FAILURE,
  GET_ANOMALY_IMPACT_DATA,
  GET_ANOMALY_IMPACT_DATA_SUCCESS,
  GET_ANOMALY_IMPACT_DATA_FAILURE,
  DIGITBOX1_WIDGET_DATA,
  DIGITBOX1_WIDGET_DATA_SUCCESS,
  DIGITBOX1_WIDGET_DATA_FAILURE,
  DIGITBOX2_WIDGET_DATA,
  DIGITBOX2_WIDGET_DATA_SUCCESS,
  DIGITBOX2_WIDGET_DATA_FAILURE,
  DIGITBOX3_WIDGET_DATA,
  DIGITBOX3_WIDGET_DATA_SUCCESS,
  DIGITBOX3_WIDGET_DATA_FAILURE,
  DIGITBOX3_SERIES_WIDGET_DATA,
  DIGITBOX3_SERIES_WIDGET_DATA_SUCCESS,
  DIGITBOX3_SERIES_WIDGET_DATA_FAILURE,
  DIGITBOX2_LIVE_WIDGET_DATA,
  DIGITBOX2_LIVE_WIDGET_DATA_SUCCESS,
  DIGITBOX2_LIVE_WIDGET_DATA_FAILURE,
  DIGITBOX2_TREND_WIDGET_DATA,
  DIGITBOX2_TREND_WIDGET_DATA_SUCCESS,
  DIGITBOX2_TREND_WIDGET_DATA_FAILURE,
  DIGITBOX2_COST_WIDGET_DATA_FAILURE,
  DIGITBOX2_COST_WIDGET_DATA_SUCCESS,
  DIGITBOX2_COST_WIDGET_DATA,
  PARAM_PERF_WIDGET_DATA,
  PARAM_PERF_WIDGET_DATA_SUCCESS,
  PARAM_PERF_WIDGET_DATA_FAILURE,
  PARAM_PERF_SERIES_DATA,
  PARAM_PERF_SERIES_DATA_SUCCESS,
  PARAM_PERF_SERIES_DATA_FAILURE,
  RUN_HOUR_SERIES_DATA,
  RUN_HOUR_SERIES_DATA_SUCCESS,
  RUN_HOUR_SERIES_DATA_FAILURE,
  RUN_HOUR_COMPUTED_DATA,
  RUN_HOUR_COMPUTED_DATA_SUCCESS,
  RUN_HOUR_COMPUTED_DATA_FAILURE,
  GET_ALL_TREES,
  GET_ALL_TREES_SUCCESS,
  GET_ALL_TREES_FAILURE,
  GET_TREE_DATA,
  GET_TREE_DATA_SUCCESS,
  GET_TREE_DATA_FAILURE,
  CREATE_TREE_DATA,
  CREATE_TREE_DATA_SUCCESS,
  CREATE_TREE_DATA_FAILURE,
  CREATE_NODE_DATA,
  CREATE_NODE_DATA_SUCCESS,
  CREATE_NODE_DATA_FAILURE,
  DELETE_NODE,
  DELETE_NODE_SUCCESS,
  DELETE_NODE_FAILURE,
  GET_TREE_SENSOR_HEALTH_DATA,
  GET_TREE_SENSOR_HEALTH_DATA_SUCCESS,
  GET_TREE_SENSOR_HEALTH_DATA_FAILURE,
  GRAPH_COMPUTED_DATA,
  GRAPH_COMPUTED_DATA_FAILURE,
  GRAPH_COMPUTED_DATA_SUCCESS,
  GRAPH_NORMAL_DATA,
  GRAPH_NORMAL_DATA_FAILURE,
  GRAPH_NORMAL_DATA_SUCCESS,
  ALERT_LOG_DATA,
  ALERT_LOG_DATA_SUCCESS,
  ALERT_LOG_DATA_FAILURE,
  LIVETABLE_WIDGET_DATA,
  LIVETABLE_WIDGET_DATA_SUCCESS,
  LIVETABLE_WIDGET_DATA_FAILURE,
  TABLE_WIDGET_DATA,
  TABLE_WIDGET_DATA_SUCCESS,
  TABLE_WIDGET_DATA_FAILURE,
  GET_GIS_SENSOR_WISE_DATA,
  GET_GIS_SENSOR_WISE_DATA_SUCCESS,
  GET_GIS_SENSOR_WISE_DATA_FAILURE,
  GET_FACTORY_DATA,
  GET_FACTORY_DATA_SUCCESS,
  GET_FACTORY_DATA_FAILURE,
  EDIT_NODE_DATA,
  EDIT_NODE_DATA_SUCCESS,
  EDIT_NODE_DATA_FAILURE,
  ALARM_DATA,
  ALARM_DATA_SUCCESS,
  ALARM_DATA_FAILURE,
  UPLOAD_IMAGE_CONFIGURATION,
  UPLOAD_IMAGE_CONFIGURATION_SUCCESS,
  UPLOAD_IMAGE_CONFIGURATION_FAILURE,
  GET_UPLOAD_IMAGE_WIDGET_DATA,
  GET_UPLOAD_IMAGE_WIDGET_DATA_SUCCESS,
  GET_UPLOAD_IMAGE_WIDGET_DATA_FAILURE,
  DIGITBOX2_COST_SERIES_WIDGET_DATA,
  DIGITBOX2_COST_SERIES_WIDGET_DATA_SUCCESS,
  DIGITBOX2_COST_SERIES_WIDGET_DATA_FAILURE,
  CREATE_NODE_DATA_RESET,
  EDIT_NODE_DATA_RESET,
  GET_LIST_VIEW_PARAM_DATA_SUCCESS,
  GET_LIST_VIEW_PARAM_DATA_FAILURE,
  GET_LIST_VIEW_PARAM_DATA,
  GET_PARAM_SUMMARY_DATA,
  GET_PARAM_SUMMARY_DATA_SUCCESS,
  GET_PARAM_SUMMARY_DATA_FAILURE,
  GET_ASSET_HISTORY,
  GET_ASSET_HISTORY_SUCCESS,
  GET_ASSET_HISTORY_FAILURE,
  GET_ASSET_INSIGHT_PARAMS,
  GET_ASSET_INSIGHT_PARAMS_SUCCESS,
  GET_ASSET_INSIGHT_PARAMS_FAILURE,
  GET_ASSET_OPEN_INSIGHTS,
  GET_ASSET_OPEN_INSIGHTS_SUCCESS,
  GET_ASSET_OPEN_INSIGHTS_FAILURE,
  GET_ASSET_INSIGHTS,
  GET_ASSET_INSIGHTS_SUCCESS,
  GET_ASSET_INSIGHTS_FAILURE,
  GET_ASSET_INSIGHTS_CONFIG,
  GET_ASSET_INSIGHTS_CONFIG_FAILURE,
  GET_ASSET_INSIGHTS_CONFIG_SUCCESS,
  SUBMIT_ASSET_FEEDBACK,
  SUBMIT_ASSET_FEEDBACK_SUCCESS,
  SUBMIT_ASSET_FEEDBACK_FAILURE,
  SUBMIT_ASSET_FEEDBACK_RESET,
  GET_INSIGHT_GRAPH_DATA,
  GET_INSIGHT_GRAPH_DATA_SUCCESS,
  GET_INSIGHT_GRAPH_DATA_FAILURE,
  GET_INSIGHT_GRAPH_DATA_RESET,
  GET_PARAM_META_DATA,
  GET_PARAM_META_DATA_SUCCESS,
  GET_PARAM_META_DATA_FAILURE,
  DIGITBOX4_WIDGET_DATA,
  DIGITBOX4_WIDGET_DATA_SUCCESS,
  DIGITBOX4_WIDGET_DATA_FAILURE,
  GET_FACTORY_INFOWINDOW_DATA,
  GET_FACTORY_INFOWINDOW_DATA_SUCCESS,
  GET_FACTORY_INFOWINDOW_DATA_FAILURE,
  GET_INSIGHTS_ALERTS_DATA,
  GET_INSIGHTS_ALERTS_DATA_SUCCESS,
  GET_INSIGHTS_ALERTS_DATA_FAILURE,
  GET_SYSTEMS_INSIGHTS_ALERTS_DATA,
  GET_SYSTEMS_INSIGHTS_ALERTS_DATA_FAILURE,
  GET_SYSTEMS_INSIGHTS_ALERTS_DATA_SUCCESS,
  GET_SYSTEMS_AND_ASSET_INSIGHTS_ALERTS_DATA,
  GET_SYSTEMS_AND_ASSET_INSIGHTS_ALERTS_DATA_SUCCESS,
  GET_SYSTEMS_AND_ASSET_INSIGHTS_ALERTS_DATA_FAILURE,
  ADD_SELECTED_INSIGHTS_ALERT,
  ADD_SELECTED_INSIGHTS_ALERT_SUCCESS,
  OPEN_CRITICAL_EVENT,
  OPEN_CRITICAL_EVENT_SUCCESS,
  GET_WELLNESS_DATA,
  GET_WELLNESS_DATA_SUCCESS,
  GET_WELLNESS_DATA_FAILURE,
  GET_ALERTS_WIDGET_DATA,
  GET_ALERTS_WIDGET_DATA_SUCCESS,
  GET_ALERTS_WIDGET_DATA_FAILURE,
  CHANGE_ALERTS_WIDGET_MODAL,
  UPDATE_ALERTS_WIDGET_STATUS,
  UPDATE_ALERTS_WIDGET_STATUS_SUCCESS,
  UPDATE_ALERTS_WIDGET_STATUS_FAILURE,
  GET_ASSET_INSIGHT_ACTION,
  GET_ASSET_INSIGHT_ACTION_FAILURE,
  GET_ASSET_INSIGHT_ACTION_RESET,
  GET_ASSET_INSIGHT_ACTION_SUCCESS,
  GET_ALERTS_WIDGET_TABLEDATA,
  GET_ALERTS_WIDGET_TABLEDATA_SUCCESS,
  GET_ALERTS_WIDGET_TABLEDATA_FAILURE,
  CHANGE_ALERTS_GRAPH_MODAL,
  GET_ALERTS_WIDGET_GRAPH_DATA,
  GET_ALERTS_WIDGET_GRAPH_DATA_SUCCESS,
  GET_ALERTS_WIDGET_GRAPH_DATA_FAILURE,
  GET_WIDGET_LIST_FROM_TEMPLATE,
  GET_WIDGET_LIST_FROM_TEMPLATE_SUCCESS,
  GET_WIDGET_LIST_FROM_TEMPLATE_FAILURE,
  GET_SYSTEM_WIDGET_LIST_FROM_TEMPLATE,
  GET_SYSTEM_WIDGET_LIST_FROM_TEMPLATE_FAILURE,
  GET_SYSTEM_WIDGET_LIST_FROM_TEMPLATE_SUCCESS,
  SELECT_ALERTS_LIST,
  ANALYZE_DATA,
  ANALYZE_DATA_SUCCESS,
  ANALYZE_DATA_FAILURE,
  GRAPH_READING_SERIES,
  GRAPH_READING_SERIES_SUCCESS,
  GRAPH_READING_SERIES_FAILURE,
  GET_TAG_DATA,
  GET_TAG_DATA_SUCCESS,
  GET_TAG_DATA_FAILURE,
  GRAPH_PARAMETER_DATA,
  GRAPH_PARAMETER_DATA_SUCCESS,
  GRAPH_PARAMETER_DATA_FAILURE,
  GET_CXO_SUMMARY,
  GET_CXO_SUMMARY_SUCCESS,
  GET_CXO_SUMMARY_FAILURE,
  GET_TAG_TIMESERIES_DATA,
  GET_TAG_TIMESERIES_DATA_SUCCESS,
  GET_TAG_TIMESERIES_DATA_FAILURE,
  SCORECARD_WIDGET_DATA,
  SCORECARD_WIDGET_DATA_SUCCESS,
  SCORECARD_WIDGET_DATA_FAILURE,
  SCORECARD_WIDGET_DATA_RESET,
  GET_TAG_TIMESERIES_COMPUTED_DATA,
  GET_TAG_TIMESERIES_COMPUTED_DATA_SUCCESS,
  GET_TAG_TIMESERIES_COMPUTED_DATA_FAILURE,
  GET_FACILITY_DATA,
  GET_FACILITY_DATA_SUCCESS,
  GET_FACILITY_DATA_FAILURE,
  GET_INSIGHT_IMPACT_CONFIG,
  GET_INSIGHT_IMPACT_CONFIG_SUCCESS,
  GET_INSIGHT_IMPACT_CONFIG_FAILURE,
  UPDATE_INSIGHT_IMPACT_CONFIG,
  UPDATE_INSIGHT_IMPACT_CONFIG_SUCCESS,
  UPDATE_INSIGHT_IMPACT_CONFIG_FAILURE,
  GET_TAG_READING_DATA,
  GET_TAG_READING_DATA_SUCCESS,
  GET_TAG_READING_DATA_FAILURE,
  ARCH_CHART_WIDGET_DATA_FAILURE,
  ARCH_CHART_WIDGET_DATA_SUCCESS,
  ARCH_CHART_WIDGET_DATA,
  GET_CONFIGURED_ALERT_COUNT,
  GET_CONFIGURED_ALERT_COUNT_SUCCESS,
  GET_CONFIGURED_ALERT_COUNT_FAILURE,
  INFLUNCERS_DATA,
  INFLUNCERS_DATA_SUCCESS,
  INFLUNCERS_DATA_FAILURE,
  INFLUNCERS_DATA_RESET,
  GET_ALL_INSIGHT_FOR_GROUP_FAILURE,
  GET_ALL_INSIGHT_FOR_GROUP_SUCCESS,
  GET_ALL_INSIGHT_FOR_GROUP,
  GET_SYSTEM_ASSET_CATEGORY,
  GET_SYSTEM_ASSET_CATEGORY_SUCCESS,
  GET_SYSTEM_ASSET_CATEGORY_FAILURE,
  GET_SYSTEM_ASSET_PERFORMANCE_DATA,
  GET_SYSTEM_ASSET_PERFORMANCE_DATA_SUCCESS,
  GET_SYSTEM_ASSET_PERFORMANCE_DATA_FAILURE,
  GET_ASSET_CBM_ASSETS_OVERVIEW,
  GET_ASSET_CBM_ASSETS_OVERVIEW_SUCCESS,
  GET_ASSET_CBM_ASSETS_OVERVIEW_FAILURE,
  GET_ASSET_CBM_ASSETS_OVERVIEW_RESET
} from './widgetConstants';

import {
  getAlertConnectionData,
  getInsightConnectionData,
  getActivityConnectionData,
  getEventConfigurationData,
  addUserActivityData,
	getAssetHistoryTimelineData,
  getNetworkHistoryTimelineData,
  getSystemHistoryTimelineData,
	getAssetHistoryTimelineLogData,
  getSystemHistoryTimelineLogData,
  getAssetSensor,
} from "./AssetHistory/reducer";

import {
  getInsightAlertSummaryData,
	getInsightAlertSummaryCbmAssetData,
  getInsightAlertSummaryAssetsMinData,
} from './AssetInsightAlert/reducer';

import {
  getInsightTimelineAssetHistoryData,
  getInsightTimelineActivityData,
  getInsightTimelineCbmAssetData,
	getInsightTimelineAlertLogData,
	getInsightTimelineInsightData,
  getInsightTimelineAssetsMinData,

} from './AssetEventLog/reducer';
import {
  getAssetHealthCountCbmAssetData,
  getAssetHealthCountAssetsData,
  getSystemHealthCountCbmAssetData
} from './AssetHealthCount/reducer';
import {
  getGisViewReadingData,
  getKpiViewData,
  getKpiPlantData,
  getKpiViewReadingData,
  getKpiRedirectionDashboard
} from './GISView/reducer';
import {
  getUsecaseSummaryCbmAssetData,
	getUsecaseSummaryAllAssetsListData,
  getUsecaseSummaryAssetsData,
	getUsecaseSummaryAlertLogData,
	getUsecaseSummaryInsightData,
	getUsecaseSummaryTagData,
	getUsecaseSummaryImpactData
} from './UsecaseSummary/reducer';
import {
  getAssetDetailListCbmAssetData,
  getAssetDetailListAssetsData,
  getAssetCbmAssetNamesMember,
  getSystemDetailList
} from './AssetDetailList/reducer';

import {
  getWorkplaceGraphNormal,
  getWorkplaceGraphComputed
} from './WorkplaceGraph/reducer';

import { 
  getNetworkDetailsData,
  deleteNetworkNode,
  updateNetworkNode,
  getNetworkParameters,
  getNodeParameters,
  getNodeConfig,
  createNodeParameters,
  createNodeConfig,
  setNetworkMainNode,
  getNodeSubtree,
  getNetworkNodeLocationConfig,
  updateNetworkNodeLocationConfig,
  getNetworkAssetCbmAsset,
  getNetworkAssetMeta,
  getNetworkInsightImpactedNodes
} from './NetworkView/reducer';

import {
  getAssetCbmTopContributors,
  getInsightsByPlantId,
  getSystemTopContributors
} from './AssetTopContributors/reducer';

import {
  getAssetCbmInsightOccurrence,
  getNetworkInsightsOccurence
} from './KpiTracker/reducer';

import {
  getNetworkInsights,
  getSystemInsights,
  getSystemAssetInsights,
  getSystemAlerts
} from './GroupWidget/reducer';

import {
  getNetworkInsightsOccurrence,
  getNetworkInsightsActions,
  createNetworkInsightsAction,
  getSystemInsightsOccurrence,
  getSystemInsightsActions,
  createSystemInsightsAction
} from './InsightGraph/reducer';

import { getSystemInsightsTrend } from './SystemInsightsTrend/reducer';

import moment from 'moment';
import {data} from 'autoprefixer';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  errorMessage:undefined,
  success: undefined,
};

const WIDGET_INITIAL_STATE = {
  data: { ...INITIAL_STATE, data: {} },
  loading: false,
  error: false,
  success: undefined,
};

export function getOverviewData(state = WIDGET_INITIAL_STATE, action) {
  switch (action.type) {
    case OVERVIEW_WIDGET_DATA:
      let prevData = state.data;
      prevData[action.payload.widgetId] = { ...INITIAL_STATE, loading: true };

      return {
        ...state,
        loading: true,
        data: prevData,
        error: false,
        success: undefined,
      };
    case OVERVIEW_WIDGET_DATA_SUCCESS:
      let newData = state.data;

      newData[action.param.widgetId] = {
        ...newData[action.param.widgetId],
        data: action.response,
        loading: false,
        success: true,
        error: false,
      };

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case OVERVIEW_WIDGET_DATA_FAILURE:
      let errorData = state.data;
      errorData[action.payload.widgetId] = { ...INITIAL_STATE, error: true };

      return {
        ...state,
        error: true,
        data: errorData,
        success: false,
      };
    default:
      return state;
  }
}

export function getOverviewReadingLive(state = INITIAL_STATE, action) {
  switch (action.type) {
    case OVERVIEW_READING_LIVE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case OVERVIEW_READING_LIVE_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
      };
    case OVERVIEW_READING_LIVE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}

export function getOverviewReadingData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case OVERVIEW_READING_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case OVERVIEW_READING_DATA_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
      };
    case OVERVIEW_READING_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}

export function getDialLiveData(
  state = {
    data: null,
    loading: false,
    error: false,
    errorMessage: undefined,
    success: undefined
  },
  action
) {
  switch (action.type) {
    case DIAL_LIVE_WIDGET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: null
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case DIAL_LIVE_WIDGET_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.param,
            ...(action.param.isComputed
              ? action.response.response
              : action.response)
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case DIAL_LIVE_WIDGET_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getDialComputedData(
  state = {
    data: null,
    loading: false,
    error: false,
    errorMessage: undefined,
    success: undefined
  },
  action
) {
  switch (action.type) {
    case DIAL_COMPUTED_WIDGET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: null
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case DIAL_COMPUTED_WIDGET_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.param,
            ...(action.param.isComputed
              ? action.response.response
              : action.response)
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case DIAL_COMPUTED_WIDGET_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getHealthData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HEALTH_WIDGET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: {}
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: true
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    case HEALTH_WIDGET_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: action.response
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: false
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case HEALTH_WIDGET_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: {}
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getInsightData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case INSIGHT_WIDGET_DATA:
      let newData = [...state.data];
      newData[action.payload.widgetId] = [];
      return {
        ...state,
        loading: true,
        data: newData,
        error: false,
        success: undefined,
      };
    case INSIGHT_WIDGET_DATA_SUCCESS:
      let newData1 = [...state.data];
      newData1[action.param.widgetId] = action.response;
      return {
        ...state,
        data: newData1,
        error: false,
        loading: false,
        success: true,
      };
    case INSIGHT_WIDGET_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getCycleDistributionData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CYCLE_DISTRIBUTION_WIDGET_DATA:
      let newData = [...state.data];
      newData[action.payload.widgetId] = [];
      return {
        ...state,
        loading: true,
        data: newData,
        error: false,
        success: undefined,
      };
    case CYCLE_DISTRIBUTION_WIDGET_DATA_SUCCESS:
      let newData1 = [...state.data];
      newData1[action.param.widgetId] = action.response;
      return {
        ...state,
        data: newData1,
        error: false,
        loading: false,
        success: true,
      };
    case CYCLE_DISTRIBUTION_WIDGET_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getAnomalyData(
  state = {
    data: null,
    loading: false,
    error: false,
    errorMessage: undefined,
    success: undefined
  },
  action
) {
  switch (action.type) {
    case ANOMALY_WIDGET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: action.payload.subId
            ? state.data[action.payload.widgetId]
            : {}
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: action.payload.subId
            ? state.error[action.payload.subId]
            : false
        }
      };

    case ANOMALY_WIDGET_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...(state.data[action.param.widgetId] || []),
            [action.param.subId]: { ...action.param, ...action.response }
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case ANOMALY_WIDGET_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export const getAnomalyImpactData = (
  state = {
    data: null,
    loading: false,
    error: false,
    errorMessage: undefined,
    success: undefined
  },
  action
) => {
  switch (action.type) {
    case GET_ANOMALY_IMPACT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: null
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_ANOMALY_IMPACT_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.param,
            ...action.response.result
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_ANOMALY_IMPACT_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export function getHeatmapData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HEATMAP_WIDGET_DATA:
      let newData = {...state.data};
      newData[action.payload.widgetId] = {
        loading: true,
        error: false,
        success: undefined,
      };
      return {
        ...state,
        loading: true,
        data: newData,
        error: false,
        success: undefined,
      };
    case HEATMAP_WIDGET_DATA_SUCCESS:
      let newData1 = {...state.data};
      newData1[action.param.widgetId] = {...action.response,error: false,loading: false,success: true,};
      return {
        ...state,
        data: newData1,
        error: false,
        loading: false,
        success: true,
      };
    case HEATMAP_WIDGET_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: {},
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getHeatmapGraphData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HEATMAP_GRAPH_DATA:
      let newData = [...state.data];
      newData[action.payload.widgetId] = [];
      return {
        ...state,
        loading: true,
        data: newData,
        error: false,
        success: undefined,
      };
    case HEATMAP_GRAPH_DATA_SUCCESS:
      let newData1 = [...state.data];
      newData1[action.param.widgetId] = action.response;
      return {
        ...state,
        data: newData1,
        error: false,
        loading: false,
        success: true,
      };
    case HEATMAP_GRAPH_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
      };
    case HEATMAP_GRAPH_DATA_RESET: 
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
      }; 
    default:
      return state;
  }
}

export function getPiechartData(
  state = {
    data: {},
    loading: false,
    error: false,
    success: undefined
  },
  action
) {
  switch (action.type) {
    case PIECHART_WIDGET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: action.payload.subId
            ? (state.data &&
                action.payload.subId &&
                state.data[action.payload.widgetId]) ||
              []
            : []
        },
        callCount: {
          ...(state.callCount || {}),
          [action.payload.widgetId]: action.payload.subId
            ? state.callCount[action.payload.widgetId]
            : 0
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case PIECHART_WIDGET_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: [
            ...state.data[action.param.widgetId],
            {
              ...action.param,
              color: decodeURIComponent(action.param.color || ''),
              ...(action.param.isComputed
                ? {
                    ...action.response.response,
                    value: action.response.response.data
                  }
                : action.response[0])
            }
          ]
        },
        callCount: {
          ...state.callCount,
          [action.param.widgetId]:
            (state.callCount[action.param.widgetId] || 0) + 1
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case PIECHART_WIDGET_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    case PIECHART_WIDGET_DATA_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: []
        },
        callCount: {
          ...state.callCount,
          [action.payload.widgetId]: 0
        }
      };

    default:
      return state;
  }
}

export function getDigitbox1Data(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DIGITBOX1_WIDGET_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: newData1,
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case DIGITBOX1_WIDGET_DATA_SUCCESS:
      let newData = [...state.data];
      newData[action.param.widgetId] = [];

			if (action.param.isTag) {
        const d =
          (action.response.timeseries[action.response.timeseries.length - 1] &&
            action.response.timeseries[action.response.timeseries.length - 1]
              .value) ||
          0;

        let res = [
          {
            subId: action.param.subId,
            isTag: action.param.isTag,
            alias: action.param.alias,
            value: d
          }
        ];

        newData[action.param.widgetId].push(...res);
      }

      if (action.param.isComputed) {
        let d = !isNaN(action.response.response.data)
          ? Number(action.response.response.data.toFixed(2))
          : undefined;

        let res = [
          {
            value: d,
            alias: action.param.alias,
            isComputed: action.param.isComputed,
            unit: action.param.unit,
            subId: action.param.subId,
          },
        ];

        newData[action.param.widgetId].push(...res);
      } 

			if (action.param.isNormal) {
        let resp = [...action.response];
				resp[0]['isNormal'] = action.param.isNormal;
        resp[0]["alias"] = action.param.alias;
        resp[0]["subId"] = action.param.subId;
        if (state.data[action.param.widgetId]) {
          newData[action.param.widgetId] = [
            ...state.data[action.param.widgetId],
          ];
        }
        newData[action.param.widgetId].push(...resp);
      }

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: newData,
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case DIGITBOX1_WIDGET_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getDigitbox2Data(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DIGITBOX2_WIDGET_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: newData1,
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case DIGITBOX2_WIDGET_DATA_SUCCESS:
      let newData = [...state.data];
      if (action.param.isComputed) {
        let d = !isNaN(action.response.response.data)
          ? Number(action.response.response.data.toFixed(2))
          : undefined;

        let res = [
          {
            value: d,
            alias: action.param.alias,
            isComputed: action.param.isComputed,
            unit: action.param.unit,
          },
        ];

        newData[action.param.widgetId] = res;
      } else {
        newData[action.param.widgetId] = action.response;
      }

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: newData,
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case DIGITBOX2_WIDGET_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getDigitbox2Live(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DIGITBOX2_LIVE_WIDGET_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: newData1,
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case DIGITBOX2_LIVE_WIDGET_DATA_SUCCESS:
      let newData = [...state.data];

      if (action.param.isComputed) {
        let res = [
          {
            value: action.response.response && action.response.response.data,
            alias: action.param.alias,
            isComputed: action.param.isComputed,
            unit: action.param.unit,
          },
        ];

        newData[action.param.widgetId] = res;
      } else {
        newData[action.param.widgetId] = action.response;
      }

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: newData,
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case DIGITBOX2_LIVE_WIDGET_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getDigitbox2Trend(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DIGITBOX2_TREND_WIDGET_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: newData1,
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case DIGITBOX2_TREND_WIDGET_DATA_SUCCESS:
      let newData = [...state.data];
      newData[action.param.widgetId] = action.response;

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: newData,
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case DIGITBOX2_TREND_WIDGET_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getDigitbox2Cost(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DIGITBOX2_COST_WIDGET_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: newData1,
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case DIGITBOX2_COST_WIDGET_DATA_SUCCESS:
      let newData = [...state.data];
      newData[action.param.widgetId] = action.response;

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: newData,
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case DIGITBOX2_COST_WIDGET_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getDigitbox2CostSeries(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DIGITBOX2_COST_SERIES_WIDGET_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: newData1,
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case DIGITBOX2_COST_SERIES_WIDGET_DATA_SUCCESS:
      let newData = [...state.data];
      newData[action.param.widgetId] = action.response;

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: newData,
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case DIGITBOX2_COST_SERIES_WIDGET_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getDigitbox3Data(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DIGITBOX3_WIDGET_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: newData1,
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case DIGITBOX3_WIDGET_DATA_SUCCESS:
      let newData = [...state.data];
      if (action.param.isComputed) {
        let d = Number(
          Object.values(action.response.response.data)[0].toFixed(2)
        );

        let res = [
          {
            value: d,
            alias: action.param.alias,
            isComputed: action.param.isComputed,
            unit: action.param.unit,
          },
        ];

        newData[action.param.widgetId] = res;
      } else {
        newData[action.param.widgetId] = [
          ...(state.data[action.param.widgetId] || []),
          ...action.response,
        ];
      }

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: newData,
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case DIGITBOX3_WIDGET_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getDigitbox3DataSeries(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DIGITBOX3_SERIES_WIDGET_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: newData1,
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case DIGITBOX3_SERIES_WIDGET_DATA_SUCCESS:
      let newData = [...state.data];

      if (action.param.isComputed) {
        newData[action.param.widgetId] = {
          ...action.param,
          data: action.response.response && action.response.response.data,
        };
      } else {
        newData[action.param.widgetId] = action.response;
      }

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: newData,
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case DIGITBOX3_SERIES_WIDGET_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getDigitbox4Data(state = { ...WIDGET_INITIAL_STATE }, action) {
  switch (action.type) {
    case DIGITBOX4_WIDGET_DATA:
      let newData1 = state.data;
      newData1[action.payload.widgetId] = {
        loading: true,
        error: false,
        data: null,
      };

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case DIGITBOX4_WIDGET_DATA_SUCCESS:
      let newData = state.data;
      newData[action.param.widgetId] = {
        loading: false,
        error: false,
        data: action.response,
      };

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case DIGITBOX4_WIDGET_DATA_FAILURE:
      let errorData = state.data;
      errorData[action.payload.widgetId] = {};
      errorData[action.payload.widgetId] = {
        loading: false,
        error: true,
        data: {},
      };

      return {
        ...state,
        error: true,
        data: errorData,
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getParamPerfData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PARAM_PERF_WIDGET_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case PARAM_PERF_WIDGET_DATA_SUCCESS:
      let newData = [...state.data];
      newData[action.param.widgetId] = action.response;

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case PARAM_PERF_WIDGET_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getParamPerfSeriesData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PARAM_PERF_SERIES_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case PARAM_PERF_SERIES_DATA_SUCCESS:
      let newData = [...state.data];
      newData[action.param.widgetId] = action.response;

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case PARAM_PERF_SERIES_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getRunHourSeriesData(
  state = {
    data: null,
    success: undefined,
    loading: false,
    error: false
  },
  action
) {
  switch (action.type) {
    case RUN_HOUR_SERIES_DATA:
      return {
        ...state,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]: {
            widgetId: action.payload.widgetId,
            __date: moment().format()
          }
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]:
            (state.error && state.error[action.payload.widgetId]) || false
        }
      };

    case RUN_HOUR_SERIES_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            ...action.response
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case RUN_HOUR_SERIES_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getRunHourComputedData(
  state = {
    data: null,
    success: undefined,
    loading: false,
    error: false
  },
  action
) {
  switch (action.type) {
    case RUN_HOUR_COMPUTED_DATA:
      return {
        ...state,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]: {
            widgetId: action.payload.widgetId,
            __date: moment().format()
          }
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]:
            (state.error && state.error[action.payload.widgetId]) || false
        }
      };

    case RUN_HOUR_COMPUTED_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            ...action.response
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case RUN_HOUR_COMPUTED_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getGraphComputedData(
  state = {
    data: null,
    loading: false,
    error: false,
    success: undefined
  },
  action
) {
  switch (action.type) {
    case GRAPH_COMPUTED_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: action.payload.subId
            ? (state.data && state.data[action.payload.widgetId]) || {}
            : {}
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GRAPH_COMPUTED_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.subId]: {
              ...(() => {
                const { data, ..._data } = action.response.response;

                return {
                  ..._data,
                  timeseries: data
                };
              })(),
              subId: action.param.subId
            }
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GRAPH_COMPUTED_DATA_FAILURE:
      return (() => {
        const { widgetId } = JSON.parse(action.payload.request.body);

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getGraphNormalData(
  state = {
    data: null,
    loading: false,
    error: false,
    success: undefined
  },
  action
) {
  switch (action.type) {
    case GRAPH_NORMAL_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: action.payload.subId
            ? (state.data && state.data[action.payload.widgetId]) || {}
            : {}
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GRAPH_NORMAL_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.subId]: {
              ...action.response,
              timeseries: (
                action.response.timeseries ||
                (action.response.reading_series &&
                  action.response.reading_series[0] &&
                  action.response.reading_series[0].timeseries) ||
                []
              ).reduce((memo, _timeseries) => {
                const value =
                  _timeseries[action.response.readingtype[0].readingtypeid];

                return [
                  ...memo,
                  {
                    timeStamp: _timeseries.timeStamp,
                    value
                  }
                ];
              }, []),
              subId: action.param.subId
            }
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GRAPH_NORMAL_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getGraphReadingData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GRAPH_READING_SERIES:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case GRAPH_READING_SERIES_SUCCESS:
      let newData = [...state.data];
      newData[action.param.widgetId] = action.response;

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case GRAPH_READING_SERIES_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getAlertLogData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ALERT_LOG_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case ALERT_LOG_DATA_SUCCESS:
      let newData = [...state.data];
      newData[action.param.widgetId] = action.response;
      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case ALERT_LOG_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}

export function getAlarmData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ALARM_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];
      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case ALARM_DATA_SUCCESS:
      let newData = [...state.data];
      newData[action.param.widgetId] = action.response;
      newData[action.param.widgetId]["subId"] = action.param.subId;
      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case ALARM_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}

export function getLiveTableData(state = INITIAL_STATE, action) {
  
  switch (action.type) {
    case LIVETABLE_WIDGET_DATA:
      let newData1 = state.data;
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
        errorMessage:null,
      };
    case LIVETABLE_WIDGET_DATA_SUCCESS:
      let newData = state.data;

      let formatedData =
        Array.isArray(action.response) &&
        action.response.reduce((result, elem) => {
          result[Object.keys(elem)[0]] = Object.values(elem)[0];
          return result;
        }, {});

      newData[action.param.widgetId] = {
        ...state.data[action.param.widgetId],
        ...formatedData,
      };

      return {
        ...state,
        data: newData,
        error: false,
        errorMessage:null,
        loading: false,
        success: true,
      };
    case LIVETABLE_WIDGET_DATA_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage:action.payload.response.message,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading:false,
        success: false,
        
      };
    default:
      return state;
  }
}

export function getTableData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TABLE_WIDGET_DATA:
      let newData1 = state.data;
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case TABLE_WIDGET_DATA_SUCCESS:
      let tableData = state.data[action.param.widgetId] || [];

      if (!Array.isArray(tableData[action.param.row])) {
        tableData[action.param.row] = [];
      }

      if (!Array.isArray(tableData[action.param.row][action.param.col])) {
        // computed apis use post so that this handeling req.
        let d =
          action.param.isComputed === true
            ? action.response.response.data
            : action.response[0] && action.response[0][action.param.sensorid]
            ? action.response[0][action.param.sensorid][
                action.param.readingtypeid
              ]
            : "";

        if (action.param.isComputed !== true && !d) {
          d = action.response[0] && action.response[0].value;
        }
        // add one col for label col
        tableData[action.param.row][parseInt(action.param.col) + 1] = d;
      }

      let newData = state.data;
      newData[action.param.widgetId] = [...tableData];

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case TABLE_WIDGET_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
      };
    default:
      return state;
  }
}

export function getAllTrees(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_TREES:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case GET_ALL_TREES_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
      };
    case GET_ALL_TREES_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}

export function getTreeData1(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TREE_DATA:
      let newData1 = state.data;
      newData1[action.payload.widgetId] = {};

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case GET_TREE_DATA_SUCCESS:
      let newData = state.data;
      newData[action.param.widgetId] = {
        ...state.data[action.param.widgetId],
        ...action.response,
      };

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case GET_TREE_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
      };
    default:
      return state;
  }
}

export function createTree(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_TREE_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case CREATE_TREE_DATA_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true,
      };
    case CREATE_TREE_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}

export function createTreeNode(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_NODE_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case CREATE_NODE_DATA_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true,
      };
    case CREATE_NODE_DATA_FAILURE:
      let message = "Something went wrong";
      if (action.payload.status === 400) {
        message =
          action.payload.response.message && action.payload.response.message[0];
      }

      return {
        ...state,
        error: true,
        data: {
          message: message,
        },
        success: false,
      };
    case CREATE_NODE_DATA_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    default:
      return state;
  }
}

export function editTreeNode(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EDIT_NODE_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case EDIT_NODE_DATA_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true,
      };
    case EDIT_NODE_DATA_FAILURE:
      let message = "Something went wrong";
      if (action.payload.status === 400) {
        message =
          action.payload.response.message && action.payload.response.message[0];
      }

      return {
        ...state,
        error: true,
        data: {
          message: message,
        },
        success: false,
      };
    case EDIT_NODE_DATA_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    default:
      return state;
  }
}

export function deleteTreeNode(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_NODE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case DELETE_NODE_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true,
      };
    case DELETE_NODE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}

export function getTreeSensorHealthData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TREE_SENSOR_HEALTH_DATA:
      let newData1 = state.data;
      newData1[action.payload.widgetId] = {};

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case GET_TREE_SENSOR_HEALTH_DATA_SUCCESS:
      let newData = state.data;
      newData[action.param.widgetId] = {
        ...state.data[action.param.widgetId],
        ...action.response,
      };

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case GET_TREE_SENSOR_HEALTH_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
      };
    default:
      return state;
  }
}

export function getGisSensorWiseData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_GIS_SENSOR_WISE_DATA:
      let newData1 = state.data;
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case GET_GIS_SENSOR_WISE_DATA_SUCCESS:
      let newData = state.data;
      newData[action.param.widgetId] = [
        ...state.data[action.param.widgetId],
        ...action.response,
      ];

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case GET_GIS_SENSOR_WISE_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getFactoryData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_FACTORY_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case GET_FACTORY_DATA_SUCCESS:
      let newData = { ...state.data };
      newData[action.param.widgetId] = newData[action.param.widgetId]
        ? [...newData[action.param.widgetId]]
        : [];
      let res;
      if (action.param.isComputed) {
        let d = !isNaN(action.response.response.data)
          ? Number(action.response.response.data.toFixed(2))
          : undefined;
        res = [
          {
            value: d,
            alias: action.param.alias,
            isComputed: action.param.isComputed,
            unit: action.param.unit,
            index: action.param.index,
            scope: action.param.scope,
          },
        ];
      } else {
        let e =
          action.response[0].value && !isNaN(action.response[0].value)
            ? Number(action.response[0].value.toFixed(2))
            : undefined;
        res = [
          {
            value: e,
            alias: action.param.alias,
            index: action.param.index,
            scope: action.param.scope,
          },
        ];
      }
      if (state.data[action.param.widgetId]) {
        newData[action.param.widgetId] = [...state.data[action.param.widgetId]];
      }
      newData[action.param.widgetId].push(res);

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case GET_FACTORY_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getFactoryInfoWindowData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_FACTORY_INFOWINDOW_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = {};

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case GET_FACTORY_INFOWINDOW_DATA_SUCCESS:
      let newData = { ...state.data };
      newData[action.param.widgetId] = newData[action.param.widgetId]
        ? [...newData[action.param.widgetId]]
        : {};
      let res;
      if (action.param.isComputed) {
        let d = !isNaN(action.response.response.data)
          ? Number(action.response.response.data.toFixed(2))
          : undefined;
        res = {
          value: d,
          alias: action.param.alias,
          isComputed: action.param.isComputed,
          unit: action.param.unit,
          index: action.param.index,
          scope: action.param.scope,
          showOnToolTip: action.param.showOnToolTip
        };
      } else {
        let e =
          action.response[0].value && !isNaN(action.response[0].value)
            ? Number(action.response[0].value.toFixed(2))
            : undefined;
        res = {
          value: e,
          alias: action.param.alias,
          index: action.param.index,
          scope: action.param.scope,
          showOnToolTip: action.param.showOnToolTip
        };
      }

      if (state.data[action.param.widgetId]) {
        newData[action.param.widgetId] = {
          ...state.data[action.param.widgetId],
        };
      }

      if (!newData[action.param.widgetId][action.param.locationId]) {
        newData[action.param.widgetId][action.param.locationId] = {};
      }

      newData[action.param.widgetId][action.param.locationId][
        action.param.scope
      ] = res;

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case GET_FACTORY_INFOWINDOW_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function updateAlertWidgetStatus(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_ALERTS_WIDGET_STATUS:
      return {
        successState: false,
        loading: true,
      };
    case UPDATE_ALERTS_WIDGET_STATUS_SUCCESS:
      return {
        successState: true,
        loading: false,
      };
    case UPDATE_ALERTS_WIDGET_STATUS_FAILURE:
      return {
        successState: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getWellnessData(
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) {
  switch (action.type) {
    case GET_WELLNESS_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]:
            action.payload.cardIndex || action.payload.parameterIndex
              ? state.data[action.payload.widgetId]
              : {}
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: true
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    case GET_WELLNESS_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.cardIndex]: {
              ...(state.data[action.param.widgetId][action.param.cardIndex] ||
                {}),
              [action.param.parameterIndex]: {
                ...action.param,
                ...(action.param.isComputed
                  ? { value: action.response.response.data }
                  : (() => {
                      const { value = 0, readingtype: { unit = '' } = {} } =
                        action.response[0] || {};

                      return {
                        value,
                        unit
                      };
                    })())
              }
            }
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_WELLNESS_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function getInsightsAlertsData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_INSIGHTS_ALERTS_DATA:
      const data = { ...state.data };
      data[action.payload.widgetId] = {};

      return {
        ...state,
        data,
        loading: true,
        error: false,
        success: undefined,
      };

    case GET_INSIGHTS_ALERTS_DATA_SUCCESS:
      const oldData = { ...state.data };
      const { widgetId, type } = action.param;

      const result = {};

      if (type === "alert") {
        result.openCriticalEvents =
          action.response && action.response.alert_count;
        result.graphData = action.response && action.response.alert_graph;
      } else {
        result.openCriticalEvents =
          action.response && action.response.open_critical_events;
        result.graphData = action.response && action.response.insight_data;
      }

      if (oldData[widgetId]) {
        oldData[widgetId] = {
          ...oldData[widgetId],
          [type]: result,
        };
      }

      return {
        ...state,
        data: oldData,
        error: false,
        loading: false,
        success: true,
      };

    case GET_INSIGHTS_ALERTS_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getSystemInsightsAlertsData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SYSTEMS_INSIGHTS_ALERTS_DATA:
      const data = { ...state.data };
      data[action.payload.widgetId] = {};

      return {
        ...state,
        data,
        loading: true,
        error: false,
        success: undefined,
      };

    case GET_SYSTEMS_INSIGHTS_ALERTS_DATA_SUCCESS:
      const oldData = { ...state.data };
      const { widgetId, type } = action.param;

      const result = {};

      if (type === "alert") {
        result.openCriticalEvents =
          action.response && action.response.alert_count;
        result.graphData = action.response && action.response.alert_graph;
      } else {
        result.openCriticalEvents =
          action.response && action.response.open_critical_events;
        result.graphData = action.response && action.response.insight_data;
      }

      if (oldData[widgetId]) {
        oldData[widgetId] = {
          ...oldData[widgetId],
          [type]: result,
        };
      }

      return {
        ...state,
        data: oldData,
        error: false,
        loading: false,
        success: true,
      };

    case GET_SYSTEMS_INSIGHTS_ALERTS_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}


export function getSystemAndAssetInsightsData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SYSTEMS_AND_ASSET_INSIGHTS_ALERTS_DATA:
      const data = { ...state.data };
      data[action.payload.widgetId] = {};

      return {
        ...state,
        data,
        loading: true,
        error: false,
        success: undefined,
      };

    case GET_SYSTEMS_AND_ASSET_INSIGHTS_ALERTS_DATA_SUCCESS:
      const oldData = { ...state.data };
      const { widgetId, type } = action.param;

      const result = {};

    
        result.openCriticalEvents =
          action.response && action.response.open_critical_events;
        result.graphData = action.response && action.response.insight_data;
      

      if (oldData[widgetId]) {
        oldData[widgetId] = {
          ...oldData[widgetId],
          [type]: result,
        };
      }

      return {
        ...state,
        data: oldData,
        error: false,
        loading: false,
        success: true,
      };

    case GET_SYSTEMS_AND_ASSET_INSIGHTS_ALERTS_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function addSelectedInsightsAlerts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_SELECTED_INSIGHTS_ALERT:
      let { widgetType="" } = action.payload;
      return {
        ...state,
        data:widgetType,
        loading: true,
        error: false,
        success: undefined,
      };
    case ADD_SELECTED_INSIGHTS_ALERT_SUCCESS:
      let res = ""
      if(action.payload && action.payload.widgetType){
        res = action.payload.widgetType;
      }
      return {
        ...state,
        data : res,
        loading: false,
        error: false,
        success: undefined,
      };
    default:
      return {...state, data:""};
  }
}

export function getOpenCriticalEvents(state = INITIAL_STATE, action) {
  switch (action.type) {
    case OPEN_CRITICAL_EVENT:
      let { insightCount=0 } = action.payload;
      return {
        ...state,
        data:insightCount,
        loading: true,
        error: false,
        success: undefined,
      };
    case OPEN_CRITICAL_EVENT_SUCCESS:
      let res = 0
      if(action.payload && action.payload.insightCount){
        res = action.payload.insightCount;
      }
      return {
        ...state,
        data : res,
        loading: false,
        error: false,
        success: undefined,
      };
    default:
      return state;
  }
}

export function getAlertsWidgetData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALERTS_WIDGET_DATA:
      const data = { ...state.data };
      data[action.payload.widgetId] = {};

      return {
        ...state,
        data,
        loading: true,
        error: false,
        success: undefined,
      };

    case GET_ALERTS_WIDGET_DATA_SUCCESS:
      const oldData = { ...state.data };
      const { widgetId } = action.param;
      const result = action.response;

      if (oldData[widgetId]) {
        oldData[widgetId] = {
          ...oldData[widgetId],
          result,
        };
      }

      return {
        ...state,
        data: oldData,
        error: false,
        loading: false,
        success: true,
      };

    case GET_ALERTS_WIDGET_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getAlertsWidgetTableData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALERTS_WIDGET_TABLEDATA:
      const data = { ...state.data };
      data[action.payload.widgetId] = {};

      return {
        ...state,
        data,
        loading: true,
        error: false,
        success: undefined,
      };

    case GET_ALERTS_WIDGET_TABLEDATA_SUCCESS:
      const oldData = { ...state.data };
      const { widgetId } = action.param;
      const result = action.response;

      if (oldData[widgetId]) {
        oldData[widgetId] = {
          ...oldData[widgetId],
          result,
        };
      }

      return {
        ...state,
        data: oldData,
        error: false,
        loading: false,
        success: true,
      };

    case GET_ALERTS_WIDGET_TABLEDATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getAlertsWidgetGraphData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALERTS_WIDGET_GRAPH_DATA:
      const data = { ...state.data };
      data[action.payload.widgetId] = {};

      return {
        ...state,
        data,
        loading: true,
        error: false,
        success: undefined,
      };

    case GET_ALERTS_WIDGET_GRAPH_DATA_SUCCESS:
      const oldData = { ...state.data };
      const { widgetId } = action.param;
      const result = action.response;

      if (oldData[widgetId]) {
        oldData[widgetId] = {
          ...oldData[widgetId],
          result,
        };
      }

      return {
        ...state,
        data: oldData,
        error: false,
        loading: false,
        success: true,
      };

    case GET_ALERTS_WIDGET_GRAPH_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function uploadImageConfiguration(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPLOAD_IMAGE_CONFIGURATION:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case UPLOAD_IMAGE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true,
      };
    case UPLOAD_IMAGE_CONFIGURATION_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}

export function getUploadImageWidgetData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_UPLOAD_IMAGE_WIDGET_DATA:
      let newData1 = state.data;
      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: {...(state.loading || {}),
        [action.payload.widgetId]: true},
        data: newData1,
        error: false,
        success: undefined,
      };
    case GET_UPLOAD_IMAGE_WIDGET_DATA_SUCCESS:
      let d =
        action.param.isComputed === true
          ? action.response.response.data
          : action.response[0] && action.response[0][action.param.locationid]
          ? action.response[0][action.param.locationid][
              action.param.readingtypeid
            ]
          : "";

      if (action.param.isComputed == false) {
        d = action.response[0].value;
      }

      let newData = state.data;
      if (!Array.isArray(newData[action.param.widgetId])) {
        newData[action.param.widgetId] = [];
      }

      newData[action.param.widgetId].push({
        value: d,
        meta: action.param.meta || {},
      });

      return {
        ...state,
        data: newData,
        error: false,
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: true,
      };

    case GET_UPLOAD_IMAGE_WIDGET_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          error: true,
          data: state.data,
          success: false,
          loading: {
            ...state.loading,
            [widgetId]: false
          }
        };
      })();

    default:
      return state;
  }
}

export function getGroupWidgetConfigById(
  state = { ...INITIAL_STATE, data: {} },
  action
) {
  switch (action.type) {
    case GET_WIDGET_DETAILS_TEMPLATE:
      let newData1 = { ...state.data };
      if (action.payload.empty) {
        newData1[action.payload.currentWidgetId] = {};
      }

      if (Array.isArray(newData1[action.payload.currentWidgetId].loaders)) {
        newData1[action.payload.currentWidgetId]["loaders"].push(
          action.payload.widget_id
        );
      } else {
        newData1[action.payload.currentWidgetId]["loaders"] = [
          action.payload.widget_id,
        ];
      }

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case GET_WIDGET_DETAILS_TEMPLATE_SUCCESS:
      let res = {};
      let newData = { ...state.data };
      let loaded = newData[action.param.currentWidgetId] && newData[action.param.currentWidgetId].loaders;

      if (Array.isArray(loaded) && loaded.includes(action.param.widget_id)) {
        loaded.splice(loaded.indexOf(action.param.widget_id), 1);
      }

      res[action.response.id] = {
        ...action.response,
        sortorder: action.param.sortorder,
      };
      newData[action.param.currentWidgetId]["data"] = {
        ...state.data[action.param.currentWidgetId] && state.data[action.param.currentWidgetId].data,
        ...res,
      };
      newData[action.param.currentWidgetId]["loaders"] = loaded;

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case GET_WIDGET_DETAILS_TEMPLATE_FAILURE:
      return {
        ...state,
        error: true,
        // data: [],
        success: false,
      };
    default:
      return { ...state };
  }
}

export function getGroupWidgetConfigDataById(
  state = { ...INITIAL_STATE, data: {} },
  action
) {
  switch (action.type) {
    case GET_GROUP_WIDGET_DETAILS_TEMPLATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.currentWidgetId]: {
            ...(state.data[action.payload.currentWidgetId] || {}),
            loaders: [
              ...(state.data[action.payload.currentWidgetId]?.loaders || []),
              action.payload.widget_id
            ]
          }
        },
        loading: true,
        error: false,
        success: undefined
      };

    case GET_GROUP_WIDGET_DETAILS_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.currentWidgetId]: {
            data: {
              ...(state.data[action.param.currentWidgetId].data || {}),
              [action.response.id]: {
                ...action.response,
                sortorder: action.param.sortorder
              }
            },
            loaders: state.data[action.param.currentWidgetId].loaders.filter(
              (widgetId) => {
                return widgetId !== action.param.widget_id;
              }
            )
          }
        },
        error: false,
        loading: false,
        success: true
      };

    case GET_GROUP_WIDGET_DETAILS_TEMPLATE_FAILURE:
      return {
        ...state,
        error: true,
        success: false
      };

    default:
      return state;
  }
}

export function getListViewParamData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LIST_VIEW_PARAM_DATA:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = {};

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case GET_LIST_VIEW_PARAM_DATA_SUCCESS:
      let newData = [...state.data];
      newData[action.param.widgetId] = action.response;

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case GET_LIST_VIEW_PARAM_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getParamSummaryData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PARAM_SUMMARY_DATA:
      let newData1 = [...state.data];
      if (action.payload.chartIndex === 0) {
        newData1[action.payload.widgetId] = {};
      }
      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case GET_PARAM_SUMMARY_DATA_SUCCESS:
      let newData = { ...state.data };

      if (action.param.isComputed) {
        newData[action.param.widgetId][action.param.chartIndex] = {
          isComputed: action.param.isComputed,
          ...action.response.response,
        };
      } else {
        newData[action.param.widgetId][action.param.chartIndex] = {
          isComputed: action.param.isComputed,
          ...action.response,
        };
      }

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case GET_PARAM_SUMMARY_DATA_FAILURE:
      return {
        ...state,
        error: true,
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getParamSummaryMetaData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PARAM_META_DATA:
      let newData1 = [...state.data];
      if (action.payload.chartIndex === 0) {
        newData1[action.payload.widgetId] = {};
      }
      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined,
      };
    case GET_PARAM_META_DATA_SUCCESS:
      let newData = { ...state.data };
      newData[action.param.widgetId][action.param.chartIndex] = action.response;

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case GET_PARAM_META_DATA_FAILURE:
      return {
        ...state,
        error: true,
        success: false,
      };
    default:
      return state;
  }
}

export function getAssetHistoryData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ASSET_HISTORY:
      let newData1 = [...state.data];
      newData1[action.payload.widgetId] = {};

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: newData1,
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        }
      };
    case GET_ASSET_HISTORY_SUCCESS:
      let newData = [...state.data];
      newData[action.param.widgetId] = action.response;

      return {
        ...state,
        data: newData,
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        },
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        }
      };
    case GET_ASSET_HISTORY_FAILURE:
      return {
        ...state,
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: true
        },
        data: { ...(state.data || {}), [action.param.widgetId]: {} },
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: false
        },
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        }
      };
    default:
      return state;
  }
}

export function getAssetInsightParamList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ASSET_INSIGHT_PARAMS:
      return {
        ...state,
        loading: true,
        data: [...state.data],
        error: false,
        success: undefined,
      };
    case GET_ASSET_INSIGHT_PARAMS_SUCCESS:
      return {
        ...state,
        data: action.response && action.response.result,
        error: false,
        loading: false,
        success: true,
      };
    case GET_ASSET_INSIGHT_PARAMS_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}

export function getAssetOpenInsights(
  state = { data: undefined, loading: false, success: undefined, error: false },
  action
) {
  switch (action.type) {
    case GET_ASSET_OPEN_INSIGHTS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload._widgetId]: {
            ...state.data?.[action.payload._widgetId],
            [action.payload.widgetId]: undefined
          }
        },
        loading: true
      };

    case GET_ASSET_OPEN_INSIGHTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param._widgetId]: {
            ...state.data[action.param._widgetId],
            [action.param.widgetId]: {
              ...state.data[action.param.widgetId],
              results: [
                ...(state.data[action.param.widgetId]?.results || []),
                ...action.response.result
              ],
              total: action.response.total_record,
              asset_id: action.param.asset_id,
              totalGetFlag: action.param.totalGetFlag
            }
          }
        },
        loading: false,
        success: true,
        error: false
      };

    case GET_ASSET_OPEN_INSIGHTS_FAILURE:
      return {
        ...state,
        data: undefined,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
}

export function getAssetInsights(
  state = { ...INITIAL_STATE, data: {}, loaders:{} },
  action
) {
  switch (action.type) {
    case GET_ASSET_INSIGHTS:
      let newState = [...state.data];
      let prevWidgetData = newState[action.payload.widgetId] || {};
      newState[action.payload.widgetId] = { ...prevWidgetData, loading: true };
      let newLoad = {...state.loaders}
      if(newLoad[action.payload.currentWidgetId]){
        newLoad[action.payload.currentWidgetId].push(action.payload.widgetId)
      }else{
        newLoad[action.payload.currentWidgetId] = [action.payload.widgetId]
      } 

      return {
        ...state,
        loading: true,
        data: newState,
        loaders: newLoad,
        error: false,
        success: undefined,
      };
    case GET_ASSET_INSIGHTS_SUCCESS:
      let newData = [...state.data];
      let prevData = newData[action.param.widgetId] && newData[action.param.widgetId].results || [];
      let datas = []
      if(action.response.total_record === action.response.result.length){
        datas = action.response.result
      }else{
        datas = [...prevData, ...action.response.result]
      }
      newData[action.param.widgetId] = {
        results: Array.from(new Set(
          [...datas].map((a)=>a.id)
        )).map(id => {
          return [...datas].find(a => a.id === id)
        }),
        total: action.response.total_record,
        error: false,
        loading: false,
        success: true,
      };
      let newLoader = {...state.loaders}
      if(Array.isArray(newLoader[action.param.currentWidgetId]) && newLoader[action.param.currentWidgetId].includes(action.param.widgetId)){
        newLoader[action.param.currentWidgetId].splice(newLoader[action.param.currentWidgetId].indexOf(action.param.widgetId), 1);

      }
      return {
        ...state,
        data: newData,
        loaders:newLoader,
        error: false,
        loading: false,
        success: true,
      };
    case GET_ASSET_INSIGHTS_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loaders:{},
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getAssetInsightsConfig(  state = { ...INITIAL_STATE, data: {} } , action) {
  switch (action.type) {
    case GET_ASSET_INSIGHTS_CONFIG:
      let newState = {...state.data};
      newState[action.payload.id] = {};
      return {
        ...state,
        data: newState,
        loading: true,
        error: false,
        success: undefined,
      };
    case GET_ASSET_INSIGHTS_CONFIG_SUCCESS:
      let newData = {...state.data}
      newData[action.param.id] = action.response
      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case GET_ASSET_INSIGHTS_CONFIG_FAILURE:
      return {
        ...state,
        error: true,
        data: {},
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function submitAssetFeedback(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SUBMIT_ASSET_FEEDBACK:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case SUBMIT_ASSET_FEEDBACK_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
      };
    case SUBMIT_ASSET_FEEDBACK_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    case SUBMIT_ASSET_FEEDBACK_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    default:
      return state;
  }
}

export function getAssetInsightGraphData(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case GET_INSIGHT_GRAPH_DATA:
      return {
        ...state,
        loading: true,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        error: false,
        success: undefined
      };

    case GET_INSIGHT_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: action.response
        },
        error: false,
        loading: false,
        success: true
      };

    case GET_INSIGHT_GRAPH_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false
      };

    case GET_INSIGHT_GRAPH_DATA_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: false,
        error: false,
        success: undefined
      };

    default:
      return state;
  }
}

export function getInfluencersData(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false,
    errorMessage: undefined
  },
  action
) {
  switch (action.type) {
    case INFLUNCERS_DATA:
      let newData1 = { ...state.data };
      newData1[action.payload.widgetId] = {};

      return {
        ...state,
        loading: true,
        data: newData1,
        error: false,
        success: undefined
      };
    case INFLUNCERS_DATA_SUCCESS:
      let newData = { ...state.data };
      newData[action.param.widgetId] = action.response;

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true
      };
    case INFLUNCERS_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
        errorMessage: action.payload.response.message
      };
    case INFLUNCERS_DATA_RESET:
      return {
        ...state,
        error: false,
        data: [],
        success: undefined,
        loading: false
      };
    default:
      return state;
  }
}

export function getAnalzeData(
  state = {
    data: null,
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case ANALYZE_DATA:
      return {
        ...state,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]:
            (action.payload.index &&
              state.data &&
              state.data[action.payload.widgetId]) ||
            {}
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]:
            (action.payload.index &&
              state.error &&
              state.error[action.payload.widgetId]) ||
            false
        }
      };

    case ANALYZE_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.index]: {
              ...action.response,
              params: action.param
            }
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case ANALYZE_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export function changeAlertsWidgetModal(state = false, action) {
  switch (action.type) {
    case CHANGE_ALERTS_WIDGET_MODAL:
      return action.payload;
    default:
      return state;
  }
}

export function changeAlertsGraphModal(state = false, action) {
  switch (action.type) {
    case CHANGE_ALERTS_GRAPH_MODAL:
      return action.payload;
    default:
      return state;
  }
}

export function selectAlertsList(state = {}, action) {
  switch (action.type) {
    case SELECT_ALERTS_LIST:
      
      const { widgetId, checked, alertId, allAlertId } = action.payload
      const selectedAlertsList = state
      
      if(!selectedAlertsList[widgetId]) selectedAlertsList[widgetId] = []

      if(checked) {
        return {
          ...selectedAlertsList,
          [widgetId]: alertId ? [
            ...selectedAlertsList[widgetId],
            alertId
          ] : [ ...allAlertId ]
        }
      } else {
        const alertsListSet = new Set(selectedAlertsList[widgetId])
        if(alertId) alertsListSet.delete(alertId)
        const newAlertsList = alertId ? Array.from(alertsListSet) : []
        return {
          ...selectedAlertsList,
          [widgetId]: [
            ...newAlertsList
          ]
        }
      }
    default:
      return state;
  }
}

export function getAssetInsightAction(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ASSET_INSIGHT_ACTION:
      let initalData = state.data;
      initalData[action.payload.id] = {}
      return {
        ...state,
        loading: true,
        data: initalData,
        error: false,
        success: undefined,
      };
    case GET_ASSET_INSIGHT_ACTION_SUCCESS:
      let oldData = state.data;
      oldData[action.param.id] = action.response
      return {
        ...state,
        data: oldData,
        error: false,
        loading: false,
        success: true,
      };
    case GET_ASSET_INSIGHT_ACTION_FAILURE:
      return {
        ...state,
        error: true,
        data: {},
        success: false,
      };
    case GET_ASSET_INSIGHT_ACTION_RESET:
      return {
        ...state,
        loading: true,
        data: {},
        error: false,
        success: undefined,
      };
    default:
      return state;
  }
}

export function getWidgetListFromTemplateId(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_WIDGET_LIST_FROM_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case GET_WIDGET_LIST_FROM_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
      };
    case GET_WIDGET_LIST_FROM_TEMPLATE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}




export function getSystemWidgetListFromTemplateId(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SYSTEM_WIDGET_LIST_FROM_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case GET_SYSTEM_WIDGET_LIST_FROM_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
      };
    case GET_SYSTEM_WIDGET_LIST_FROM_TEMPLATE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}

export function getGraphParameterData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GRAPH_PARAMETER_DATA:
      return {
        ...state,
        loading: true,
        data: action.payload.subId ? state.data : {},
        error: false,
        success: undefined
      };
    case GRAPH_PARAMETER_DATA_SUCCESS:
      return {
        ...state,
          data: {
            ...state.data,
            [action.param.subId]: {
              ...action.response,
              params: action.param
            }
          },
          error: false,
          loading: false,
          success: true
      }
    case GRAPH_PARAMETER_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}

export function getTagData(state = {...INITIAL_STATE, data: {}}, action) {
  switch (action.type) {
      case GET_TAG_DATA:
        //let initalData = state.data;
        //if(action.payload && action.payload.plant_id){
          //initalData[action.payload.plant_id] = {}
        //}else{
          //initalData.all = {}
        //}
          return {
              ...state,
              loading: true,
              //data: initalData,
              error: false,
              success: undefined
          };
      case GET_TAG_DATA_SUCCESS:
        //let oldData = state.data;
        //if(action.param && action.param.plant_id){
          //oldData[action.param.plant_id] = action.response
        //}else{
          //oldData.all = action.response
        //}
          return {
              ...state,
              //data: oldData,
              data: {
                ...state.data,
                [action.param.plant_id || 'all']: action.response
              },
              error: false,
              loading: false,
              success: true
          };
      case GET_TAG_DATA_FAILURE:
          return {
              ...state,
              error: true,
              data: {},
              success: false,
              loading: false,
          };
      default:
          return state;
  }
}

export function getCxoSummary(state = { ...INITIAL_STATE, data: {} }, action) {
  switch (action.type) {
    case GET_CXO_SUMMARY:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: {
            ...state.data[action.payload.widgetId],
            [action.payload.parameter_ids]: {
              loading: true,
              success: undefined,
              error: false
            }
          }
        },
        loading: true,
        success: undefined,
        error: false
      };

    case GET_CXO_SUMMARY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.parameter_ids]: {
              ...state.data[action.param.parameter_ids],
              ...(action.param.isComputed
                ? { timeseries: action.response.response.data }
                : action.response),
              loading: false,
              success: true,
              error: false
            }
          }
        },
        error: false,
        loading: false,
        success: true
      };

    case GET_CXO_SUMMARY_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: {
              ...state.data[widgetId],
              [action.param.parameter_ids]: {
                timeseries: [],
                loading: false,
                success: false,
                error: true
              }
            }
          },
          loading: false,
          success: false,
          error: true
        };
      })();

    default:
      return state;
  }
}

const getTagTimeseriesData = (
  state = {
    data: null,
    success: undefined,
    loading: false,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_TAG_TIMESERIES_DATA:
      return {
        ...state,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]: action.payload.subId
            ? (state.data && state.data[action.payload.widgetId]) || []
            : []
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_TAG_TIMESERIES_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: [
            ...state.data[action.param.widgetId],
            {
              ...action.param,
              ...(() => {
                const { boxType, subType } = action.param;

                return (boxType === 'Digitbox 2' &&
                  (subType === 'value' || subType === 'trend')) ||
                  boxType === 'Digitbox 3' ||
                  boxType === 'Digitbox 4'
                  ? action.response.timeseries[
                      action.response.timeseries.length - 1
                    ]
                  : action.response[action.response.length - 1];
              })(),
              ...action.response
            }
          ].sort((a, b) => {
            return a.subId - b.subId;
          })
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_TAG_TIMESERIES_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export const getScoreCardData = (
  state = {
    data: null,
    callCount: null,
    success: undefined,
    loading: false,
    error: false
  },
  action
) => {
  switch (action.type) {
    case SCORECARD_WIDGET_DATA:
      return {
        ...state,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]:
            (action.payload.index &&
              state.data &&
              state.data[action.payload.widgetId]) ||
            {}
        },
        callCount: {
          ...(state.callCount || {}),
          [action.payload.widgetId]: action.payload.subId
            ? state.callCount[action.payload.widgetId]
            : 0
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]:
            (action.payload.index &&
              state.error &&
              state.error[action.payload.widgetId]) ||
            false
        }
      };

    case SCORECARD_WIDGET_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.index]: {
              ...action.param,
              ...(() => {
                const value =
                  state.data[action.param.widgetId][action.param.index]
                    ?.value || 0;

                switch (true) {
                  case !!action.param.isNormal:
                  case !!action.param.isTag && !action.param.isComputed:
                    return {
                      ...action.response[0],
                      value: value + action.response[0].value
                    };

                  case !!action.param.isComputed:
                    return {
                      value: value + action.response.response.data
                    };
                }
              })()
            }
          }
        },
        callCount: {
          ...state.callCount,
          [action.param.widgetId]:
            (state.callCount[action.param.widgetId] || 0) + 1
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case SCORECARD_WIDGET_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    case SCORECARD_WIDGET_DATA_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: {}
        },
        callCount: {
          ...state.callCount,
          [action.payload.widgetId]: 0
        }
      };

    default:
      return state;
  }
};

const getTagTimeseriesComputedData = (
  state = {
    data: null,
    success: undefined,
    loading: false,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_TAG_TIMESERIES_COMPUTED_DATA:
      return {
        ...state,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]: action.payload.subId
            ? (state.data && state.data[action.payload.widgetId]) || []
            : []
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_TAG_TIMESERIES_COMPUTED_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: [
            ...state.data[action.param.widgetId],
            {
              ...action.param,
              ...action.response.response.data[
                action.response.response.data.length - 1
              ],
              ...(() => {
                const { boxType, subType } = action.param;

                return (boxType === 'Digitbox 2' &&
                  (subType === 'value' || subType === 'trend')) ||
                  boxType === 'Digitbox 3' ||
                  boxType === 'Digitbox 4'
                  ? {
                      timeseries: action.response.response.data
                    }
                  : {};
              })(),
              ...action.response.response
            }
          ].sort((a, b) => {
            return a.subId - b.subId;
          })
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_TAG_TIMESERIES_COMPUTED_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);
        return {
          ...state,
          loading: {
            ...state.loading,
            [action.param.widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [action.param.widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export function getFacilityData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_FACILITY_DATA:
      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]: []
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_FACILITY_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: action.response
        },
        error: false,
        loading: false,
        success: true,
      };
    case GET_FACILITY_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}


export function getInsightImpactConfig(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_INSIGHT_IMPACT_CONFIG:
    case UPDATE_INSIGHT_IMPACT_CONFIG:
      return {
        ...state,
        loading: true,
        data: undefined,
        error: false,
        success: undefined,
      };
    case GET_INSIGHT_IMPACT_CONFIG_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        error: false,
        loading: false,
        success: true,
      };
    case UPDATE_INSIGHT_IMPACT_CONFIG_SUCCESS:
      return {
        ...state,
        data: action.response.response.result.config,
        error: false,
        loading: false,
        success: true,
      };
    case GET_INSIGHT_IMPACT_CONFIG_FAILURE:
    case UPDATE_INSIGHT_IMPACT_CONFIG_FAILURE:
      return {
        ...state,
        error: true,
        data: undefined,
        success: false,
      };
    default:
      return state;
  }
}

const getTagReadingData = (
  state = {
    data: null,
    success: undefined,
    loading: false,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_TAG_READING_DATA:
      return {
        ...state,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]: action.payload.subId
            ? (state.data && state.data[action.payload.widgetId]) || {}
            : {}
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_TAG_READING_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.subId]: {
              ...state.data[action.param.widgetId][action.param.subId],
              ...action.param,
              ...(() => {
                const [response, timeseries, value] = (() => {
                  const response = action.param.isComputed
                    ? action.response.response
                    : action.response;

                  const key = action.param.isComputed ? 'data' : 'timeseries';

                  const timeseries = response[key];

                  const value = timeseries[timeseries.length - 1];

                  return [response, timeseries, value];
                })();

                return {
                  ...action.param,
                  ...response,
                  timeseries,
                  ...value
                };
              })()
            }
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_TAG_READING_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export function getArchChartData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ARCH_CHART_WIDGET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: {}
        },
        loading: true,
        error: false,
        success: undefined
      };

    case ARCH_CHART_WIDGET_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.appliance_type]: action.response
          }
        },
        error: false,
        loading: false,
        success: true
      };

    case ARCH_CHART_WIDGET_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false
      };

    default:
      return state;
  }
}

export function getConfiguredAlertCount(
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case GET_CONFIGURED_ALERT_COUNT:
      return {
        ...state,
        loading: true,
        error: false,
        success: undefined
      };
    case GET_CONFIGURED_ALERT_COUNT_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case GET_CONFIGURED_ALERT_COUNT_FAILURE:
      return {
        ...state,
        data: [],
        error: true,
        loading: false,
        success: false
      };
    default:
      return state;
  }
}

export function getAllInsightForGroup(
  state = {
    data: undefined,
    loading: false,
    error: false,
    success: undefined
  },
  action
) {
  switch (action.type) {
    case GET_ALL_INSIGHT_FOR_GROUP:
      return {
        ...state,
        loading: true,
        error: false,
        success: undefined
      };

    case GET_ALL_INSIGHT_FOR_GROUP_SUCCESS:
      return {
        ...state,
        data: action.response || [],
        error: false,
        loading: false,
        success: true
      };

    case GET_ALL_INSIGHT_FOR_GROUP_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        loading: false
      };

    default:
      return state;
  }
} 

export function getSystemAssetCategoryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SYSTEM_ASSET_CATEGORY:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case GET_SYSTEM_ASSET_CATEGORY_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
      };
    case GET_SYSTEM_ASSET_CATEGORY_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}

export function getSystemAssetPerformanceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SYSTEM_ASSET_PERFORMANCE_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case GET_SYSTEM_ASSET_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
      };
    case GET_SYSTEM_ASSET_PERFORMANCE_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    default:
      return state;
  }
}

export const getAssetCbmAssetsOverview = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case GET_ASSET_CBM_ASSETS_OVERVIEW:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_ASSET_CBM_ASSETS_OVERVIEW_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            param: action.param
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case GET_ASSET_CBM_ASSETS_OVERVIEW_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: undefined
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    case GET_ASSET_CBM_ASSETS_OVERVIEW_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: false
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    default:
      return state;
  }
};

export const widgetsReducer = {
  overviewData: getOverviewData,
  overviewReadingData: getOverviewReadingData,
  overviewReadingLive: getOverviewReadingLive,

  dialLiveData: getDialLiveData,
  dialComputedData: getDialComputedData,
  healthData: getHealthData,
  insightData: getInsightData,
  heatmapData: getHeatmapData,
  heatmapGraphData: getHeatmapGraphData,
  piechartData: getPiechartData,
  cycleDistributionData: getCycleDistributionData,
  factoryData: getFactoryData,
  factoryInfoWindowData: getFactoryInfoWindowData,
  insightsAlertsData: getInsightsAlertsData,
  systemInsightsAlertData: getSystemInsightsAlertsData,
  systemAndAssetsInsightsData: getSystemAndAssetInsightsData,
  selectedInsightsAlert: addSelectedInsightsAlerts,
  OpenCriticalEvents: getOpenCriticalEvents,
  alertsWidgetData: getAlertsWidgetData,
  alertsWidgetTableData: getAlertsWidgetTableData,
  alertsWidgetGraphData: getAlertsWidgetGraphData,
  wellnessData: getWellnessData,
  updateAlertWidgetStatusData: updateAlertWidgetStatus,
  anomalyData: getAnomalyData,
  anomalyImpactData: getAnomalyImpactData,
  digitbox1Data: getDigitbox1Data,
  digitbox2Data: getDigitbox2Data,
  digitbox2Live: getDigitbox2Live,
  digitbox2Trend: getDigitbox2Trend,
  digitbox2Cost: getDigitbox2Cost,
  digitbox2CostSeries: getDigitbox2CostSeries,
  digitbox3Data: getDigitbox3Data,
  digitbox3DataSeries: getDigitbox3DataSeries,
  digitbox4Data: getDigitbox4Data,
  paramPerfData: getParamPerfData,
  paramPerfSeriesData: getParamPerfSeriesData,
  runHourSeriesData: getRunHourSeriesData,
  runHourComputedData: getRunHourComputedData,
  graphComputedData: getGraphComputedData,
  graphNormalData: getGraphNormalData,
  getGraphReadingData: getGraphReadingData,
  alertLogData: getAlertLogData,
  alarmData: getAlarmData,
  getLiveTableData: getLiveTableData,
  getTableData: getTableData,
  getAllTrees: getAllTrees,
  getTreeData1: getTreeData1,
  createTree: createTree,
  createTreeNode: createTreeNode,
  deleteTreeNode: deleteTreeNode,
  editTreeNode: editTreeNode,
  treeSensorHealth: getTreeSensorHealthData,
  gisSensorWiseData: getGisSensorWiseData,
  uploadImageConfiguration: uploadImageConfiguration,
  getUploadImageWidget: getUploadImageWidgetData,
  getGroupWidgetConfigById: getGroupWidgetConfigById,
  getGroupWidgetConfigDataById: getGroupWidgetConfigDataById,
  getListViewParamData: getListViewParamData,
  getParamSummaryData: getParamSummaryData,
  getParamSummaryMetaData: getParamSummaryMetaData,
  assetHistoryData: getAssetHistoryData,
  assetInsightParamList: getAssetInsightParamList,
  assetOpenInsights: getAssetOpenInsights,
  assetInsights: getAssetInsights,
  assetInsightsConfig: getAssetInsightsConfig,
  submitAssetFeedback: submitAssetFeedback,
  insightGraphData: getAssetInsightGraphData,
  alertsWidgetModal: changeAlertsWidgetModal,
  alertsGraphModal: changeAlertsGraphModal,
  selectedAlertsList: selectAlertsList,
  alertConnectionData: getAlertConnectionData,
  insightConnectionData: getInsightConnectionData,
  activityConnectionData: getActivityConnectionData,
  eventConfigurationData: getEventConfigurationData,
  userActivityData: addUserActivityData,
  assetHistoryTimelineData: getAssetHistoryTimelineData,
  networkHistoryTimelineData: getNetworkHistoryTimelineData,
  systemHistoryTimelineData: getSystemHistoryTimelineData,
  assetHistoryTimelineLogData: getAssetHistoryTimelineLogData,
  systemHistoryTimelineLogData: getSystemHistoryTimelineLogData,
  assetSensor: getAssetSensor,
  assetInsightAction: getAssetInsightAction,
  widgetListFromTemplateId: getWidgetListFromTemplateId,
  systemWidgetListFromTemplateId: getSystemWidgetListFromTemplateId,
  getAnalzeData: getAnalzeData,
  insightAlertSummaryData: getInsightAlertSummaryData,
  insightTimelineAssetHistoryData: getInsightTimelineAssetHistoryData,
  insightAlertSummaryCbmAssetData: getInsightAlertSummaryCbmAssetData,
  insightAlertSummaryAssetsMinData: getInsightAlertSummaryAssetsMinData,
  insightTimelineActivityData: getInsightTimelineActivityData,
  insightTimelineCbmAssetData: getInsightTimelineCbmAssetData,
  insightTimelineAlertLogData: getInsightTimelineAlertLogData,
  insightTimelineInsightData: getInsightTimelineInsightData,
  insightTimelineAssetsMinData: getInsightTimelineAssetsMinData,
  assetHealthCountCbmAssetData: getAssetHealthCountCbmAssetData,
  assetHealthCountAssetsData: getAssetHealthCountAssetsData,
  systemHealthCountCbmAssetData: getSystemHealthCountCbmAssetData,
  insightsByPlantId: getInsightsByPlantId,
  gisViewReadingData: getGisViewReadingData,
  getKpiViewData: getKpiViewData,
  getKpiPlantData: getKpiPlantData,
  getKpiViewReadingData: getKpiViewReadingData,
  kpiRedirectionDashboard: getKpiRedirectionDashboard,
  getGraphParameterData: getGraphParameterData,
  usecaseSummaryCbmAssetData: getUsecaseSummaryCbmAssetData,
  usecaseSummaryAllAssetsListData: getUsecaseSummaryAllAssetsListData,
  usecaseSummaryAssetsData: getUsecaseSummaryAssetsData,
  usecaseSummaryAlertLogData: getUsecaseSummaryAlertLogData,
  usecaseSummaryInsightData: getUsecaseSummaryInsightData,
  usecaseSummaryTagData: getUsecaseSummaryTagData,
  usecaseSummaryImpactData: getUsecaseSummaryImpactData,
  getTagData: getTagData,
  getCxoSummary: getCxoSummary,
  assetDetailListCbmAssetData: getAssetDetailListCbmAssetData,
  assetDetailListAssetsData: getAssetDetailListAssetsData,
  tagData: getTagTimeseriesData,
  getScoreCardData: getScoreCardData,
  tagTimeseriesComputedData: getTagTimeseriesComputedData,
  facilityData: getFacilityData,
  insightImpactConfig: getInsightImpactConfig,
  workplaceGraphNormal: getWorkplaceGraphNormal,
  workplaceGraphComputed: getWorkplaceGraphComputed,
  tagReadingData: getTagReadingData,
  archChartData: getArchChartData,
  configuredAlertCount: getConfiguredAlertCount,
  networkDetailsData: getNetworkDetailsData,
  deleteNetworkNode: deleteNetworkNode,
  updateNetworkNode: updateNetworkNode,
  networkParameters: getNetworkParameters,
  nodeParameters: getNodeParameters,
  nodeConfig: getNodeConfig,
  createNodeParameters: createNodeParameters,
  createNodeConfig,
  setNetworkMainNode: setNetworkMainNode,
  getNodeSubtree: getNodeSubtree,
  networkNodeLocationConfig: getNetworkNodeLocationConfig,
  updateNetworkNodeLocationConfig,
  networkAssetCbmAsset: getNetworkAssetCbmAsset,
  networkAssetMeta: getNetworkAssetMeta,
  assetCbmAssetNamesMember: getAssetCbmAssetNamesMember,
  assetCbmTopContributors: getAssetCbmTopContributors,
  systemTopContributors: getSystemTopContributors,
  assetCbmInsightOccurrence: getAssetCbmInsightOccurrence,
  networkInsightOccurrence: getNetworkInsightsOccurence,
  getInfluencersData: getInfluencersData,
  allInsightForGroup: getAllInsightForGroup,
  networkInsights: getNetworkInsights,
  networkInsightsOccurrence: getNetworkInsightsOccurrence,
  networkInsightsActions: getNetworkInsightsActions,
  createNetworkInsightsAction,
  networkInsightImpactedNodes: getNetworkInsightImpactedNodes,
  systemInsights: getSystemInsights,
  systemInsightsOccurrence: getSystemInsightsOccurrence,
  systemAssetCategoryReducer : getSystemAssetCategoryReducer,
  systemAssetPerformanceReducer :getSystemAssetPerformanceReducer,
  systemDetailList: getSystemDetailList,
  systemInsightsActions: getSystemInsightsActions,
  createSystemInsightsAction,
  systemAssetInsights: getSystemAssetInsights,
  systemAlerts: getSystemAlerts,
  assetCbmAssetsOverview: getAssetCbmAssetsOverview,
  systemInsightsTrend: getSystemInsightsTrend
}