import {ofType} from 'redux-observable';
import {mergeMap, map, catchError} from 'rxjs/operators'
import {ajax} from 'rxjs/ajax';
import {of} from 'rxjs'
import qs from "query-string";
import moment from "moment";
import {
    AssetUrl,
    ERROR,
    getLocalItem,
    getPercentageChange,
    handleValueUnit
} from '../../constants';
import {
    GET_LIVE_CONSUMPTION_DATA,
    GET_LIVE_CONSUMPTION_DATA_SUCCESS,
    GET_LIVE_CONSUMPTION_DATA_FAILURE,

    GET_ENERGY_FORECAST_DATA,
    GET_ENERGY_FORECAST_DATA_SUCCESS,
    GET_ENERGY_FORECAST_DATA_FAILURE,

    GET_TOTAL_CONSUMPTION_DATA,
    GET_TOTAL_CONSUMPTION_DATA_SUCCESS,
    GET_TOTAL_CONSUMPTION_DATA_FAILURE,

    GET_DATERANGE_ENERGY_CONSUMPTION_DATA,
    GET_DATERANGE_ENERGY_CONSUMPTION_DATA_SUCCESS,
    GET_DATERANGE_ENERGY_CONSUMPTION_DATA_FAILURE,

    GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA,
    GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_SUCCESS,
    GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_FAILURE,

    GET_RANGE_ENERGY_CONSUMPTION_CHART,
    GET_RANGE_ENERGY_CONSUMPTION_CHART_SUCCESS,

    GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_SUCCESS,
    GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_FAILURE,

    GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_SUCCESS,
    GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_FAILURE
} from './constant';

const getLiveConsumptionDataSuccess = payload => ({type: GET_LIVE_CONSUMPTION_DATA_SUCCESS, payload});
const getEnergyForecastDataSuccess = payload => ({type: GET_ENERGY_FORECAST_DATA_SUCCESS, payload});
const getTotalConsumptionDataSuccess = payload => ({type: GET_TOTAL_CONSUMPTION_DATA_SUCCESS, payload});
const getDateRangeEnergyConsumptionDataSuccess = payload => ({
    type: GET_DATERANGE_ENERGY_CONSUMPTION_DATA_SUCCESS,
    payload
});
const getDateRangeEnergyForeCastDataSuccess = payload => ({
    type: GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_SUCCESS,
    payload
});
const getDateRangeEnergyConsumptionDiffDataSuccess = payload => ({
    type: GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_SUCCESS,
    payload
});
const getDateRangeEnergyForeCastDiffDataSuccess = payload => ({
    type: GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_SUCCESS,
    payload
});
const getDateRangeEnergyConsumptionTimeSeriesSuccess = payload => ({
    type: GET_RANGE_ENERGY_CONSUMPTION_CHART_SUCCESS,
    payload
});

export const getLiveConsumptionDataEpic = action$ => action$.pipe(
    ofType(GET_LIVE_CONSUMPTION_DATA),
    mergeMap(action => {
        const {payload} = action;
        const memberInfo = getLocalItem('smartsense.member', {});
        const urlParams = {
            fromdate: payload.from_date,
            todate: payload.to_date,
            memberId: memberInfo.memberid,
            readingtypeid: payload.reading_type_id,
        };
        if (payload.location !== null) {
            urlParams['locationid'] = payload.location;
        }
        return ajax.getJSON(
            `${AssetUrl}/api/reading_live/?${qs.stringify(urlParams)}`,
            {
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`
            }
        ).pipe(
            map(
                response => getLiveConsumptionDataSuccess(
                    handleValueUnit(
                        response.value,
                        payload.reading_type_id,
                        1000,
                        response.readingtype.unit
                    )
                )
            ),
            catchError(
                error => of(
                    {type: GET_LIVE_CONSUMPTION_DATA_FAILURE, payload: []},
                    {type: ERROR, payload: error}
                )
            )
        )
    })
);

export const getEnergyForecastDataEpic = action$ => action$.pipe(
    ofType(GET_ENERGY_FORECAST_DATA),
    mergeMap(action => {
        const {payload} = action;
        const memberInfo = getLocalItem('smartsense.member', {});
        const urlParams = {
            memberId: memberInfo.memberid,
            readingtypeid: payload.reading_type_id,
            forecast_month: payload.forecast_month
        };
        if (payload.location !== null) {
            urlParams['locationid'] = payload.location;
        }
        return ajax.getJSON(
            `${AssetUrl}/api/insights/enterprise/forecast/?${qs.stringify(urlParams)}`,
            {
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`
            }
        ).pipe(
            map(
                response => getEnergyForecastDataSuccess(
                    handleValueUnit(
                        response.value,
                        payload.reading_type_id,
                        1000,
                        response.readingtype.unit
                    )
                )
            ),
            catchError(
                error => of(
                    {type: GET_ENERGY_FORECAST_DATA_FAILURE, payload: []},
                    {type: ERROR, payload: error}
                )
            )
        )
    })
);

export const getTotalConsumptionDataEpic = action$ => action$.pipe(
    ofType(GET_TOTAL_CONSUMPTION_DATA),
    mergeMap(action => {
        const {payload} = action;
        const memberInfo = getLocalItem('smartsense.member', {});
        const {reading_type_id} = payload;
        const urlParams = {
            fromdate: payload.from_date,
            todate: payload.to_date,
            memberId: memberInfo.memberid,
            readingtypeid: reading_type_id,
            data_format: 'value'
        };
        if (payload.location !== null) {
            urlParams['locationid'] = payload.location;
        }
        return ajax.getJSON(
            `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
            {Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`}
        ).pipe(
            map(
                response => getTotalConsumptionDataSuccess(
                    handleValueUnit(
                        response.timeseries[reading_type_id],
                        reading_type_id,
                        1000,
                        response.parameters.readingtype.unit
                    )
                )
            ),
            catchError(
                error => of(
                    {type: GET_TOTAL_CONSUMPTION_DATA_FAILURE, payload: []},
                    {type: ERROR, payload: error}
                )
            )
        );
    })
);

export const getDateRangeEnergyConsumptionDataEpic = action$ => action$.pipe(
    ofType(GET_DATERANGE_ENERGY_CONSUMPTION_DATA),
    mergeMap(action => {
        const {payload} = action;
        const memberInfo = getLocalItem('smartsense.member', {});
        const urlParams = {
            fromdate: payload.from_date,
            todate: payload.to_date,
            memberId: memberInfo.memberid,
            readingtypeid: payload.reading_type_id,
            data_format: 'value'
        };
        if (payload.location !== null) {
            urlParams['locationid'] = payload.location;
        }
        return ajax.getJSON(
            `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
            {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
        ).pipe(
            map(
                response => {
                    const actualValue = response.timeseries[payload.reading_type_id];
                    return getDateRangeEnergyConsumptionDataSuccess({
                        ...handleValueUnit(
                            response.timeseries[payload.reading_type_id],
                            payload.reading_type_id,
                            1000,
                            response.parameters.readingtype.unit
                        ),
                        actualValue,
                        ...payload
                    });
                }
            ),
            catchError(
                error => of(
                    {type: GET_DATERANGE_ENERGY_CONSUMPTION_DATA_FAILURE, payload: payload},
                    {type: ERROR, payload: error}
                )
            )
        );
    })
);

export const getDateRangeEnergyForeCastDataEpic = action$ => action$.pipe(
    ofType(GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA),
    mergeMap(action => {
        const {payload} = action;
        const memberInfo = getLocalItem('smartsense.member', {});
        const {forecast_month, reading_type_id} = payload;
        const urlParams = {
            memberId: memberInfo.memberid,
            readingtypeid: reading_type_id,
            forecast_month: forecast_month
        };
        if (payload.location !== null) {
            urlParams['locationid'] = payload.location;
        }
        return ajax.getJSON(
            `${AssetUrl}/api/insights/enterprise/forecast/?${qs.stringify(urlParams)}`,
            {
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`
            }
        ).pipe(
            map(
                response => {
                    const actualValue = response.value;
                    return getDateRangeEnergyForeCastDataSuccess({
                        ...handleValueUnit(
                            actualValue,
                            reading_type_id,
                            1000,
                            response.readingtype.unit
                        ),
                        actualValue,
                        from_date: forecast_month,
                        ...payload
                    });
                }
            ),
            catchError(
                error => of(
                    {type: GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_FAILURE, payload: payload},
                    {type: ERROR, payload: error}
                )
            )
        );
    })
);

export const getDateRangeEnergyConsumptionDataDiffEpic = action$ => action$.pipe(
    ofType(GET_DATERANGE_ENERGY_CONSUMPTION_DATA_SUCCESS),
    mergeMap(action => {
        const {payload} = action;
        const memberInfo = getLocalItem('smartsense.member', {});
        const dateFormat = 'YYYY-MM-DD';
        const dates = [
            moment(payload.from_date, dateFormat)
                .subtract(1, 'month')
                .startOf('day')
                .format(),
            moment(payload.to_date, dateFormat)
                .subtract(1, 'month')
                .endOf('day')
                .format()
        ];
        const urlParams = {
            fromdate: dates[0],
            todate: dates[1],
            memberId: memberInfo.memberid,
            readingtypeid: payload.reading_type_id,
            data_format: 'value'
        };
        if (payload.location !== null) {
            urlParams['locationid'] = payload.location;
        }
        return ajax.getJSON(
            `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
            {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
        ).pipe(
            map(
                response => getDateRangeEnergyConsumptionDiffDataSuccess({
                    ...payload,
                    ...getPercentageChange(payload.actualValue, response.timeseries[payload.reading_type_id]),
                })
            ),
            catchError(
                error => of(
                    {type: GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_FAILURE, payload: payload},
                    {type: ERROR, payload: error}
                )
            )
        );
    })
);

export const getDateRangeEnergyForeCastDataDiffEpic = action$ => action$.pipe(
    ofType(GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_SUCCESS),
    mergeMap(action => {
        const {payload} = action;
        const memberInfo = getLocalItem('smartsense.member', {});
        const urlParams = {
            memberId: memberInfo.memberid,
            readingtypeid: payload.reading_type_id,
            forecast_month: payload.forecast_month
        };
        if (payload.location !== null) {
            urlParams['locationid'] = payload.location;
        }
        return ajax.getJSON(
            `${AssetUrl}/api/insights/enterprise/forecast/?${qs.stringify(urlParams)}`,
            {
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`
            }
        ).pipe(
            map(
                response => getDateRangeEnergyForeCastDiffDataSuccess({
                    ...payload,
                    ...getPercentageChange(payload.actualValue, response.value),
                })
            ),
            catchError(
                error => of(
                    {type: GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_FAILURE, payload: payload},
                    {type: ERROR, payload: error}
                )
            )
        );
    })
);

export const getDateRangeEnergyConsumptionTimeSeriesEpic = action$ => {
    return action$.pipe(
        ofType(GET_RANGE_ENERGY_CONSUMPTION_CHART),
        mergeMap(action => {
            const {payload} = action;
            const memberInfo = getLocalItem('smartsense.member', {});
            const urlParams = {
                fromdate: payload.from_date,
                todate: payload.to_date,
                memberId: memberInfo.memberid,
                readingtypeid: payload.reading_type_id,
                grain: payload.grain
            };
            if (payload.location !== null) {
                urlParams['locationid'] = payload.location;
            }
            return ajax.getJSON(
                `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
                {Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`}
            ).pipe(
                map(getDateRangeEnergyConsumptionTimeSeriesSuccess),
                catchError(
                    error => of(
                        {type: GET_DATERANGE_ENERGY_CONSUMPTION_DATA_FAILURE, payload: []},
                        {type: ERROR, payload: error}
                    )
                )
            )
        })
    )
};
