import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Input, LocationSelector, Table, TableHeaderCell } from '../../../../../common/components/';
import PropTypes from 'prop-types';
import ParameterSelector  from "./parameterSelector";
import {locationBasedParamSelector} from '../../widgetsConfig';

const TABLE_HEADER = [
  {
    name: "Alias"
  },
  {
    name: "Locations"
  },
  {
    name: "Metric Name"
  }
]
function LocationBasedParamSelector(props) {
  var [data, setData] = useState([])

  useEffect(() => {
    if(props.appParams[locationBasedParamSelector] && props.appParams[locationBasedParamSelector].value){
      setData(props.appParams[locationBasedParamSelector].value);
    }
  }, [props.appParams]);

  function getTableCellAtIndex(row, colm, value, key){
    if(!data[row]){
      return ''
    }

    switch (colm) {
      case 0:
        return(
          <div className='input-wrapper' onClick={e => e.stopPropagation()}>
            <Input
              label=''
              name='type'
              value={data[row] && data[row].alias ? data[row].alias : ''}
              required={true}
              type='text'
              inputChanged={(newVal, isInValid,) => {
                let newData = [...data]
                newData[row].alias = newVal;
                // setData(newData);
              }}
              placeholder={''}
              required={false}
              width={12}
              />
          </div>
        )
        break;
      case 1 : 
          return data[row].locationname
        break;
      case 2 : 
          return <div style = {{position:"absolute", width : "250px"}}>
            <ParameterSelector 
                onChange={(value, isInValid) => {
                  let newData = [...data]
                  newData[row].param = value;
                  props.appInputParamChanged(newData, false, props.name)

                }}
                name={props.name}
                label=''
                required={false}
                value={data[row] && data[row].param ? data[row].param : null}
                placeholder={''}
                isMulti={false}
                width={12}
                appParams={{parameter:{value: data[row].param}}}
            />
          </div>
        break;
    }
    return "TSET"
  }

  return (
    <div className="widget-builder-locationbasedparam" style = {{width:"90%", padding:"10px"}}>
      <LocationSelector
        onChange={value => {
          let newState = [];
          value.map((item) => {
            let isThere = false;
            let match = {}

            data.map((dataItem) => {
              if(item.locationid === dataItem.locationid){
                isThere = true;
                match = dataItem
              }
            })

            if (item.locationid && !isThere) {
              newState.push({
                locationname: item.name,
                locationid: item.locationid,
                alias: '',
                param: null
              });
            } else {
              newState.push(match)
            }
          })

          setData(newState)
          props.appInputParamChanged(newState, false, props.name)
         }}
        selectedLocations={data && Array.isArray(data) ? data.map(i => i.locationid) : []}
        name={props.name}
      />
      <div className = 'locationbasedparam-table' >
        <Table
          key = {data.length}
          getDataForPageIndex={()=>{}}
          noOfPages={1}
          data={data}
          noOfColms={TABLE_HEADER.length}
          getHeaderAtIndex={(i) => {
            return new TableHeaderCell(TABLE_HEADER[i].name)
          }}
          pageSize={data.length}
          pageNo={1}
          showPagination = {false}
          getTableCellAtIndex={getTableCellAtIndex}
        />
      </div>
    </div>
  );
}


LocationBasedParamSelector.defaultProps = {
  value: [],
  name: '',
  appInputParamChanged: function () {},
  appParams: {}
}

LocationBasedParamSelector.propTypes = {
  value: PropTypes.array,
  name: PropTypes.string,
  appInputParamChanged: PropTypes.func,
  appParams: PropTypes.object
}

export default LocationBasedParamSelector;