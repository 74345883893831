export const GET_WORKPLACE_GRAPH_NORMAL = 'GET_WORKPLACE_GRAPH_NORMAL';

export const GET_WORKPLACE_GRAPH_NORMAL_SUCCESS =
  'GET_WORKPLACE_GRAPH_NORMAL_SUCCESS';

export const GET_WORKPLACE_GRAPH_NORMAL_FAILURE =
  'GET_WORKPLACE_GRAPH_NORMAL_FAILURE';

export const GET_WORKPLACE_GRAPH_COMPUTED = 'GET_WORKPLACE_GRAPH_COMPUTED';

export const GET_WORKPLACE_GRAPH_COMPUTED_SUCCESS =
  'GET_WORKPLACE_GRAPH_COMPUTED_SUCCESS';

export const GET_WORKPLACE_GRAPH_COMPUTED_FAILURE =
  'GET_WORKPLACE_GRAPH_COMPUTED_FAILURE';
