import React, { Fragment, useEffect, useState, useContext, useRef, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Icon, Loader, DropDown, Badge, Button, SkeletonLoading } from '../../../../../common/components/';
import WidgetSelector from '../WidgetSelector/';
import WidgetLayout from '../WidgetLayout';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Context, UserContext, DateTimeContext, PlantContext } from '../../../Main/context';
import { mergeDashboardDataWithConfig, DASHBOARD_ROW_HEIGHT, handleGroupOrContainerWidget, DEFAULT_WIDGET_ACTIONS, DEFAULT_WIDGET_SUB_ACTIONS, SUSTAIN_WIDGET_SUB_ACTIONS } from '../../widgetsConfig';
import {
  getAllWidget,
  deleteWidget,
  resetDeleteWidget,
  createWidget,
  resetCreateWidget,
  deleteDashboard,
  resetDeleteDashboard,
  getDashboardConfigHistory,
  setPoputWidget,
  openPoputWidget,
  setFactoryBackdrop
} from '../../action';
import DateTime from '../../../../../common/utils/dateTimeUtils';
import htmlToCanvas from '../../../../../common/utils/Export';
import MemoizedWidget from './MemoizedWidget';
import DashboardConfigHistory from '../DashboardConfigHistory';
import WidgetPopout from '../WidgetPopout';
import moment from 'moment';
import { ICON_POSITION } from '../../../../../common/components/Icon/constants';
import brandlogo from '../../../../../../assets/images/Brand/Logo-ColorSVG.svg';
import { ToolTip } from '../../../../../common/components';
import { NOT_ACCESS_MESSAGE } from '../../../Main/appSetting';
import { DASHBOARD } from '../../../Main/permissionContants';
import dashboardFilterConfigInputGet from '../WidgetBuilder/ConfigComponents/common/dashboardFilterConfigInputGet';
//const DISABLE_SECTION = ['chintansoni@ecolibrium.io', 'CF@ecolibrium.io'];
const USERNAME = localStorage.getItem('username');
let WIDGET_OPTIONS = ["Energy", "Factories", "GHG Emissions", "What-If Energy", "Energy Consumption Mix", "Parameter Tracking", "Insight-alert-summary", "Alert List", "Live Data", "List View", "Asset Health Count", "Asset Detail List", "Asset Insight & Alert", "Asset Event Log", "Weather Forecast", "Workplace Overview", "Project Status", "Usecase Summary", "Network View", "Section", "Cxo Summary", "Smart Info", "Scorecard", "Facility", "Health", "KPI Tracker", "Asset Top Contributors", 'Asset Performance']
const WIDGET_ROW = ["Energy","GHG Emissions","Energy Consumption Mix"]
let GRADIENT = ["Scorecard", "Smart Info", "Health", "Section", "Network View", "KPI Tracker", "Asset Health Count", "Asset Top Contributors"]
const CUSTOM_BG = ["Graph", "Pie", "Anomaly Detection"]


const TAG_OPTIONS = ["Cxo Summary"]

import WidgetCalenderConfig from '../Widgets/CommonControls/calendar';
import { getDashboardWidgets, getTemplateWidgets, makeServiceDefault } from '../../../AssetScreenDesign/action';
const ResponsiveGridLayout = WidthProvider(Responsive);
var intervalIds = [];

const OPTIONS = [
  {
    key: 'ADD NEW WIDGET',
    value: (
      <span className="item-container">
        <i className="fas fa-plus-square"></i>Add New Widget
      </span>
    ),
    optionIndex: 0,
    permission: DASHBOARD.ADD_WIDGET_TO_DASHBOARD_BY_ID
  },
  {
    key: 'EDIT DASHBOARD',
    value: (
      <span className="item-container">
        <i className="fas fa-edit"></i>Edit Dashboard
      </span>
    ),
    optionIndex: 1,
    permission: DASHBOARD.EDIT_DASHBOARD_BY_ID
  },
  {
    key: 'DELETE DASHBOARD',
    value: (
      <span className="item-container">
        <i className="fa fa-trash"></i>Delete Dashboard
      </span>
    ),
    optionIndex: 2,
    permission: DASHBOARD.DELETE_DASHBOARD_BY_ID
  },
  {
    key: 'DOWNLOAD DASHBOARD AS IMAGE',
    value: (
      <span className="item-container">
        <i className="fas fa-cloud-download-alt"></i>Download Dashboard As Image
      </span>
    ),
    optionIndex: 3,
    permission: DASHBOARD.DOWNLOAD_DASHBOARD_AS_IMAGE
  },
  {
    key: 'DOWNLOAD DASHBOARD AS PDF',
    value: (
      <span className="item-container">
        <i className="fas fa-cloud-download-alt"></i>Download Dashboard As PDF
      </span>
    ),
    optionIndex: 4,
    permission: DASHBOARD.DOWNLOAD_DASHBOARD_AS_PDF
  },
  {
    key: 'AUDIT TRAIL',
    value: (
      <span className="item-container">
        <i className="fas fa-cogs"></i>Audit Trail
      </span>
    ),
    optionIndex: 5,
    permission: DASHBOARD.GET_DASHBOARD_ACTIVITY_LOGS
  },
]

const PAGE_SIZE = 500;

function DashboardContainer(props) {
  const context = useContext(Context);
  const userContext = useContext(UserContext);
  const dateTimeContext = useContext(DateTimeContext);
  const plantContext = useContext(PlantContext);

  let oHtmlToCanvas = new htmlToCanvas();

  const [selectedWidgetIndex, selectWidget] = useState(0);
  const [widgetsData, setWidgetsData] = useState([]);

  const [isOpen, setModal] = useState(false);
  // const [isPoputWidgetOpen, setIsPoputWidgetOpen] = useState(false);
  // const [poputWidget, setPoputWidget] = useState(null);
  const [refreshState, setRefreshState] = useState({});

  const [isOpenAuditTrail, setAuditTrail] = useState(false);

	const [dashboardFilterConfig, setDashboardFilterConfig] = useState(
    dashboardFilterConfigInputGet()
  );

 useEffect(()=>{
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
 },[])

  useEffect(() => {
    getWidgetApi();
    // var storedServices = JSON.parse(localStorage.getItem("smartsense.services"));
    // var sustainability = storedServices.filter(item => item.name == "Sustainability")
    // props.makeServiceDefault({service_id: sustainability[0].id});
 
    return () => {
      // cleanup
      intervalIds.map((id) => {
        clearInterval(id.split('_')[0]);
      });
      intervalIds = [];
      oHtmlToCanvas = null;
    };
  }, []);

  useEffect(() => {
    let sortedSectionWiseData = {};
    let filteredData = props.widgetsData.find((item)=>TAG_OPTIONS.includes(item.type))
    if(filteredData){
      plantContext.handlePlant(true)
    }else{
      plantContext.handlePlant(false)
    }

		const widgetsData =
      props.widgetsData &&
      props.widgetsData.reduce((memo, _widgetData, index, _widgetsData) => {
        const value = (() => {
          const widthGet = (_width, config) => {
            const width = Object.values(config)
              .map(({ required, percentageWidth }) => ({
                required,
                percentageWidth
              }))
              .reduce((memo, { required, percentageWidth }) => {
                return required
                  ? memo
                  : memo - (percentageWidth * _width) / 100;
              }, _width);

            return width;
          };

          switch (_widgetData.type) {
            case 'Health':
              return _widgetData.config.sensorHealthConfigExtended &&
                _widgetData.config.sensorHealthConfigExtended
                  .configurationType === 'custom' &&
                !_widgetsData.some(
                  ({
                    type,
                    config: {
                      sensorHealthConfigExtended: { configurationType } = {}
                    }
                  }) => {
                    return type === 'Health' && configurationType !== 'custom';
                  }
                )
                ? (() => {
                    WIDGET_OPTIONS = [...WIDGET_OPTIONS, _widgetData.type];

                    const pos =
                      _widgetData.config.sensorHealthConfigExtended.pos;

                    const customConfiguration =
                      _widgetData.config.sensorHealthConfigExtended
                        .customConfiguration;

                    return pos && customConfiguration
                      ? {
                          ..._widgetData,
                          minWidgetWidth: widthGet(
                            pos.minW,
                            customConfiguration
                          ),
                          maxWidgetWidth: pos.maxW,
                          minWidgetHeight: pos.minH,
                          maxWidgetHeight: pos.maxH,
                          width: widthGet(pos.w, customConfiguration),
                          height: pos.h,
                          custom: true
                        }
                      : _widgetData;
                  })()
                : (() => {
                    WIDGET_OPTIONS = WIDGET_OPTIONS.filter(
                      (WIDGET_OPTION) => WIDGET_OPTION !== _widgetData.type
                    );

                    return _widgetData;
                  })();

            case 'GIS Plant':
              return _widgetData.config.parameters.mapShowToggle
                ? (() => {
                    WIDGET_OPTIONS = [...WIDGET_OPTIONS, _widgetData.type];

                    GRADIENT = [...GRADIENT, _widgetData.type];

                    return {
                      ..._widgetData,
                      width: _widgetData.config.parameters.posW,
                      minWidgetWidth: _widgetData.config.parameters.posW,
                      minWidgetHeight: _widgetData.config.parameters.posH
                    };
                  })()
                : (() => {
                    WIDGET_OPTIONS = WIDGET_OPTIONS.filter(
                      (WIDGET_OPTION) => WIDGET_OPTION !== _widgetData.type
                    );

                    GRADIENT = GRADIENT.filter(
                      (_GRADIENT) => _GRADIENT !== _widgetData.type
                    );

                    return _widgetData;
                  })();

            case 'Pie':
              return (() => {
                const otherConfig =
                  _widgetData.config.locationBasedParamSelector[0];

                switch (true) {
                  case otherConfig.graphType === 'YoY Visualization':
                    return {
                      ..._widgetData,
                      width: otherConfig.posW,
                      minWidgetWidth: otherConfig.posW,
                      minWidgetHeight: otherConfig.posH
                    };

                  case otherConfig.graphShowToggle &&
                    !_widgetsData.some(
                      ({ type, config: { locationBasedParamSelector } }) => {
                        const { graphShowToggle } =
                          (locationBasedParamSelector &&
                            locationBasedParamSelector.length &&
                            Object.values(locationBasedParamSelector)[0]) ||
                          {};

                        return type === 'Pie' && !graphShowToggle;
                      }
                    ):
                    return (() => {
                      WIDGET_OPTIONS = [...WIDGET_OPTIONS, _widgetData.type];

                      GRADIENT = [...GRADIENT, _widgetData.type];

                      return {
                        ..._widgetData,
                        width: otherConfig.posW,
                        minWidgetWidth: otherConfig.posW,
                        minWidgetHeight: otherConfig.posH
                      };
                    })();

                  default:
                    return (() => {
                      WIDGET_OPTIONS = WIDGET_OPTIONS.filter(
                        (WIDGET_OPTION) => WIDGET_OPTION !== _widgetData.type
                      );

                      GRADIENT = GRADIENT.filter(
                        (_GRADIENT) => _GRADIENT !== _widgetData.type
                      );

                      return _widgetData;
                    })();
                }
              })();

            case 'Anomaly Detection':
              return _widgetData.config.graphShowToggle &&
                !_widgetsData.some(({ type, config: { graphShowToggle } }) => {
                  return type === 'Anomaly Detection' && !graphShowToggle;
                })
                ? (() => {
                    WIDGET_OPTIONS = [...WIDGET_OPTIONS, _widgetData.type];

                    GRADIENT = [...GRADIENT, _widgetData.type];

                    return {
                      ..._widgetData,
                      width: _widgetData.config.posW,
                      minWidgetWidth: _widgetData.config.posW,
                      minWidgetHeight: _widgetData.config.posH
                    };
                  })()
                : (() => {
                    WIDGET_OPTIONS = WIDGET_OPTIONS.filter(
                      (WIDGET_OPTION) => WIDGET_OPTION !== _widgetData.type
                    );

                    GRADIENT = GRADIENT.filter(
                      (_GRADIENT) => _GRADIENT !== _widgetData.type
                    );

                    return _widgetData;
                  })();

            case 'Energy Consumption Mix':
              return _widgetData.config.graphShowToggle &&
                !_widgetsData.some(({ type, config: { graphShowToggle } }) => {
                  return type === 'Energy Consumption Mix' && !graphShowToggle;
                })
                ? (() => {
                    GRADIENT = [...GRADIENT, _widgetData.type];

                    return {
                      ..._widgetData,
                      width: _widgetData.config.posW,
                      minWidgetWidth: _widgetData.config.posW,
                      minWidgetHeight: _widgetData.config.posH
                    };
                  })()
                : (() => {
                    GRADIENT = GRADIENT.filter(
                      (_GRADIENT) => _GRADIENT !== _widgetData.type
                    );

                    return _widgetData;
                  })();

            case 'Asset Insight & Alert':
              return _widgetData.config.graphShowToggle &&
                !_widgetsData.some(({ type, config: { graphShowToggle } }) => {
                  return type === 'Asset Insight & Alert' && !graphShowToggle;
                })
                ? (() => {
                    WIDGET_OPTIONS = [...WIDGET_OPTIONS, _widgetData.type];

                    GRADIENT = [...GRADIENT, _widgetData.type];

                    return {
                      ..._widgetData,
                      width: _widgetData.config.posW,
                      minWidgetWidth: _widgetData.config.posW,
                      minWidgetHeight: _widgetData.config.posH
                    };
                  })()
                : (() => {
                    WIDGET_OPTIONS = WIDGET_OPTIONS.filter(
                      (WIDGET_OPTION) => WIDGET_OPTION !== _widgetData.type
                    );

                    GRADIENT = GRADIENT.filter(
                      (_GRADIENT) => _GRADIENT !== _widgetData.type
                    );

                    return _widgetData;
                  })();

            case 'Asset Health Count':
              return _widgetData.config.assetHealthCountConfig.numericView
                ? (() => {
                    return {
                      ..._widgetData,
                      width: _widgetData.config.posW,
                      minWidgetWidth: _widgetData.config.posW,
                      maxWidgetWidth: _widgetData.config.posW,
                      minWidgetHeight: _widgetData.config.posH,
                      maxWidgetHeight: _widgetData.config.posH
                    };
                  })()
                : _widgetData;

            case 'Network View':
              return _widgetData.config.networkExpand
                ? (() => {
                    const posH = _widgetData.config.posH;

                    return {
                      ..._widgetData,
                      minWidgetHeight: posH,
                      maxWidgetHeight: posH
                    };
                  })()
                : _widgetData;

            case 'Graph':
              return _widgetData.config.graphConfig.isMiniGraph
                ? {
                    ..._widgetData,
                    width: _widgetData.minWidgetWidth
                  }
                : _widgetData;

            case 'Scorecard':
              return (() => {
                switch (true) {
                  case _widgetData.config.showActualPercentage:
                    return {
                      ..._widgetData,
                      width: 2.7,
                      height: 1.1
                    };

                  case _widgetData.config.locationBasedParamSelector
                    .isComparison:
                    return {
                      ..._widgetData,
                      width: 4,
                      height: 1
                    };

                  default:
                    return _widgetData;
                }
              })();

            default:
              return _widgetData;
          }
        })();

        return [...memo, value];
      }, []);

    // getSectionWise Widget
    let sectionWiseData = _.groupBy(widgetsData, function (widget) {
      return widget.config.section && widget.config.section.length > 0
				? widget.config.section
        : '';
    });

    // getSectionWise sorted Widget
    for (let sectionName in sectionWiseData) {
      sortedSectionWiseData[sectionName] = _.orderBy(
        sectionWiseData[sectionName],
        ['config.resize_position.y', 'config.resize_position.x'],
        ['asc'],
      );
    }

    setWidgetsData(sortedSectionWiseData);
  }, [props.widgetsData]);

  useEffect(() => {
    if (props.deleteWidgetData.success === true) {
      props.resetDeleteWidget();
      getWidgetApi();
      context.notify.success("Widget Deleted");
    }
  }, [props.deleteWidgetData]);

  useEffect(() => {
    if (props.cloneWidgetData.success === true) {
      props.resetCreateWidget();
      getWidgetApi();
      context.notify.success('Widget Cloned');
    }

    if (props.cloneWidgetData.error === true) {
      props.resetCreateWidget();
      context.notify.error(props.cloneWidgetData.data.response.message);
    }
  }, [props.cloneWidgetData]);

  useEffect(() => {
    props.serviceDefault && localStorage.setItem('smartsense.defaultService', JSON.stringify(props.serviceDefault))
  }, [props.serviceDefault])

  const getWidgetApi = (page_no = 1, page_size = PAGE_SIZE) => {
    if ((props.dashboard.id || props.dashboard.dashboard_id) && !props.dashboard.status) {
      props.getAllWidget({
        id: (props.dashboard.id || props.dashboard.dashboard_id),
        page_no,
        page_size
      });
    } else {
      props.getDashboardWidgets({ template_id: props.dashboard.template_id, dashboard_id: props.dashboard.dashboard_id, page_no: 1, page_size: 100 })
    }
  }

  function openModal() {
    setModal(true);
  }

  function closeModal() {
    setModal(false);
  }

  function setWigetSelected(index) {
    selectWidget(index);
  }

  function openAddWidgetConfiguration() {
    props.history.push({
      pathname: `/Dashboard/WidgetBuilder/${props.dashboard.id || props.dashboard.dashboard_id}/${props.allWidgetConfig[selectedWidgetIndex].id}`,
      //state: {path: props.location.pathname}
    });
  }

  function editDashboard() {
    if(props.location.pathname == '/Dashboard') {
      props.history.push({
        pathname: `/Dashboard/EditDashboard/${props.dashboard.id || props.dashboard.dashboard_id}`,
        state: props.dashboard,
      });
    } else {
      props.history.push({
        pathname: `/Templates/EditTemplate/${props.dashboard.id || props.dashboard.dashboard_id}`,
        state: {dashboard: props.dashboard, service: props.dashboard.template_id},
      });
    }
  }

  function addWidget() {
    if (
      props.location &&
      (props.location.pathname.includes('Home') ||
        props.location.pathname.includes('Sustainability') ||
        props.location.pathname.includes('Workplace') ||
        props.location.pathname.includes('Dashboard')) &&
      props.dashboard &&
      props.dashboard.template_id
    ) {
      context.notify.error(
        'Access Denied. Please use the screen design module to perform widget-level operations.'
      );
    } else {
      openModal();
    }
  }

  function getNoWidgetScreen() {
    return (
      <div className="first-add-widget" onClick={addWidget}>
        <div className="first-add-widget-shadow"></div>
        <div className="first-add-widget-container">
          <div className="first-add-widget-container-inner">
            <div className="first-add-widget-icon">
              <span style={{ fontSize: '50px' }}>
                <i className="fa fa-plus" aria-hidden="true"></i>
              </span>
            </div>
            <div className="first-add-widget-text">Add your first widget</div>
          </div>
        </div>
      </div>
    );
  }

  function renderModal() {
    return (
      <WidgetSelector
        allWidgetConfig={props.allWidgetConfig}
        isOpen={isOpen}
        openAddWidgetConfiguration={openAddWidgetConfiguration}
        setWigetSelected={setWigetSelected}
        closeModal={closeModal}
        selectedWidgetIndex={selectedWidgetIndex}
      />
    );
  }

  function openTvmode(widget, widgetIndex) {
    let layout = document.querySelectorAll('#dashboard-container .dashboard-layout')[0];
    let elem = layout.querySelectorAll(`[widget-id="${widget.id}"]`)[0];
    let data = elem.getElementsByClassName("widgetContent")[0]
    let fullscreen = false;
    if (["Energy Consumption Mix"].includes(widget.widget_id.type)) {
      window.addEventListener("fullscreenchange", (event) => {
        if (["Energy Consumption Mix"].includes(widget.widget_id.type)) {
          if (fullscreen) {
            fullscreen = false
            data.id = "widget-normal"
          } else {
            fullscreen = true;
            data.id = "widget-full"
          }
        }
      })
    }


    if (elem) {
      context.fullScreen.open(elem, widget.id);
    }
  }

  function downloadWidget(widget, widgetIndex) {
    let layout = document.querySelectorAll('#dashboard-container .dashboard-layout')[0];
    let elem = layout.querySelectorAll(`[widget-id="${widget.id}"]`)[0];
    let widgetLayout = WIDGET_OPTIONS.includes(widget.widget_id.type)

    if (elem) {
      setTimeout(() => {
        oHtmlToCanvas.downloadPNGImage(elem.querySelectorAll(`.${widgetLayout ? "widgetContent" : "dashoard-widget"}`)[0]);
      });
    }
  }

  function cloneWidget(widget, widgetIndex) {
    let params = { ...widget.config };
    params.widget_name = params.widget_name + ' - Copy';

    // remove resize - position info from previous widget
    delete params.resize_position;

    props.createWidget({
      id: props.dashboard.id || props.dashboard.dashboard_id,
      widget_id: widget.widget_id.id,
      section: '',
      sequence: null,
      config: params,
      // "config": JSON.stringify(params),
      // "showSuccessMsg": true
    });
  }

  function deleteWidget(widget, widgetIndex) {
    context.confirm.show('Are you sure you want to delete widget?', () => {
      props.deleteWidget({
        dashboardId: widget.dashboard_id.id,
        id: widget.id,
      });
    });
  }

  function openWidgetConfiguration(widget) {
    //var wid = props.dashboardData.filter((item) => {
    //return item.id === widget.id;
    //});
    //var final = wid[0];

    const { configuration, view, ...final } = Object.values(widgetsData).reduce(
      (memo, _widgetData) => {
        const value = _widgetData.find(({ id }) => id === widget.id);

        return !memo && value ? value : memo;
      },
      undefined
    );

    props.history.push({
      pathname: `/Dashboard/WidgetBuilder/${
        props.dashboard.id || props.dashboard.dashboard_id
      }/${widget.widget_id.id}?edit_id=${widget.id}`,
      state: { widget: final }
    });
  }

  function manualRefreshWidget(widget) {
    refreshState[widget.id] = {
      count: refreshState[widget.id] ? refreshState[widget.id].count + 1 : 1,
      time: encodeURIComponent(DateTime.today()),
    };

    setRefreshState({ ...refreshState });
  }

  function getAutoRefreshIntervalCallback(widget) {
    let count = 0;
    let lastRefeshTime;

    return () => {
      // avoid first call as if render time offest and execution
      if (count == 0) {
        count = count + 1;
        lastRefeshTime = moment();
        return;
      }

      lastRefeshTime = moment();
      count = count + 1;

      refreshState[widget.id] = {
        count: refreshState[widget.id] ? refreshState[widget.id].count + 1 : 1,
        time: lastRefeshTime.format(),
      };

      setRefreshState({ ...refreshState });
    };
  }

  const getPopoutWidget = (widget) => {
    return (
      <div className='popout-widget'>
        <Suspense fallback={<SkeletonLoading />}>
          <MemoizedWidget
            Widget={widget.view}
            dashboardAppConfig={widget.config}
            widgetId={widget.id}
            dashboardId={props.dashboard.id || props.dashboard.dashboard_id}
            dashboardName={props.dashboard.dashboard_name}
            refresh={{}}
          />
        </Suspense>
      </div>
    );
  }

  function getWidget(widget, i) {
    let config = widget.config;
    let refreshTimeInMilisecond = config.refresh_frequency * 60 * 1000;

    if (refreshTimeInMilisecond) {
      let exists = false;
      // check if interval already exists
      intervalIds.map((id) => {
        if (id.split('_')[1] === widget.id) exists = true;
      });

      if (!exists) {
        let intervalId = setInterval(getAutoRefreshIntervalCallback(widget), refreshTimeInMilisecond);

        // avoid muiltiple intervals for same widget
        intervalIds.push(`${intervalId}_${widget.id}`);
      }
    }

    const getWidgetActions = () => {
      if (
        props.location &&
        (props.location.pathname.includes('Home') ||
          props.location.pathname.includes('Sustainability') ||
          props.location.pathname.includes('Workplace') ||
          props.location.pathname.includes('Dashboard')) &&
        props.dashboard &&
        props.dashboard.template_id
      ) {
        return;
      } else {
        if (widget.widgetActions) return widget.widgetActions;

        return DEFAULT_WIDGET_ACTIONS;
      }
    }

		const widgetNameGet = (
      widget,
      index,
      dashboardFilterConfig,
      dashboardName
    ) => {
      const _widgetNameGet = (widget, index, kpi) => {
        const widgetNameDefinition = {
          Carbon: ['Scope 1 Trend', 'Scope 2 Trend'],
          Energy: ['Electricity Consumption Trend', 'Gas Consumption Trend'],
          Cost: ['Electricity Cost', 'Gas Cost']
        };

        switch (true) {
          case widget.type === 'Pie':
            return `${kpi} Mix`;

          case widget.type === 'Graph' && index === 1:
            return widgetNameDefinition[kpi] && widgetNameDefinition[kpi][0];

          case widget.type === 'Graph':
            return widgetNameDefinition[kpi] && widgetNameDefinition[kpi][1];
        }
      };

      switch (true) {
        case dashboardName === 'Facility Performance' &&
          !!dashboardFilterConfig &&
          !!dashboardFilterConfig.tagConfs[0]:
          return _widgetNameGet(
            widget,
            index,
            dashboardFilterConfig.tagConfs[0].kpi
          );

        case !!dashboardFilterConfig && !!dashboardFilterConfig.tagConfs[0]:
          return `${dashboardFilterConfig.tagConfs[0].prefix} ${widget.config.widget_name}`;

        default:
          return widget.config.widget_name;
      }
    };

    return (
      <WidgetLayout
        widgetName={widget.name}
        widgetActions={getWidgetActions()}
        custom_widgetName={widget.widget_id.type}
        customBgClass={
          props.match.url.includes('Sustainability') &&
          CUSTOM_BG.includes(widget.widget_id.type)
            ? 'widget_bg'
            : ''
        }
        // widgetSubActions={(props.location && props.location.pathname == '/Sustainability') ? SUSTAIN_WIDGET_SUB_ACTIONS : DEFAULT_WIDGET_SUB_ACTIONS}
        widgetSubActions={DEFAULT_WIDGET_SUB_ACTIONS}
        widgetType={widget.type}
        widgetId={widget.id}
        name={widgetNameGet(
          widget,
          i,
          props.dashboardFilterConfig,
          props.dashboard.dashboard_name
        )}
        width={widget.config.width}
        newWidget={WIDGET_OPTIONS.includes(widget.widget_id.type)}
        customHeight={WIDGET_ROW.includes(widget.widget_id.type)}
        description={widget.config.widget_description}
        hideGradient={GRADIENT.includes(widget.widget_id.type)}
        isMiniGraph={
          widget.widget_id.type === 'Graph' &&
          widget.config.graphConfig.isMiniGraph
        }
        editWidget={() => {
          openWidgetConfiguration(widget, i);
        }}
        deleteWidget={() => {
          deleteWidget(widget, i);
        }}
        cloneWidget={() => {
          cloneWidget(widget, i);
        }}
        openTvmode={() => {
          openTvmode(widget, i);
        }}
        refreshWidget={() => {
          manualRefreshWidget(widget, i);
        }}
        downloadWidget={() => {
          downloadWidget(widget, i);
        }}
        widgetAction={({ key }) => {
          switch (key) {
            case 'popout':
              props.openPoputWidget(true);
              props.setPoputWidget(widget);
              break;
          }
        }}
        refreshTime={
          refreshState[widget.id] &&
          DateTime.isValid(refreshState[widget.id].time)
            ? DateTime.formatDefault(refreshState[widget.id].time)
            : DateTime.formatDefault(DateTime.today())
        }
        permissionDenied={() => context.notify.error(NOT_ACCESS_MESSAGE)}
      >
        {props.popupWidget && props.popupWidget.id === widget.id ? (
          'Widget opened in popup'
        ) : (
          <Suspense fallback={<SkeletonLoading />}>
            <MemoizedWidget
              Widget={widget.view}
              dashboardAppConfig={config}
              widgetId={widget.id}
              dashboardConfig={props.dashboard.config}
              serviceId={
                props.match.url.includes('Home')
                  ? 163
                  : props.match.url.includes('Sustainability')
                  ? 79
                  : 0
              }
              dashboardId={props.dashboard.id || props.dashboard.dashboard_id}
              dashboardName={
                props.dashboard.name || props.dashboard.dashboard_name
              }
              dashboardDate={
                props.dashboard.config && props.dashboard.config.nestedDateRange ? props.dashboard.config.nestedDateRange:  props.dashboard.config && props.dashboard.config.showDate
              }
              refresh={{
                count: refreshState[widget.id] && refreshState[widget.id].count
              }}
              refreshTime={
                refreshState[widget.id] &&
                DateTime.isValid(refreshState[widget.id].time)
                  ? DateTime.formatDefault(refreshState[widget.id].time)
                  : DateTime.formatDefault(DateTime.today())
              }
              dashboardFilterConfig={props.dashboardFilterConfig}
              isFullScreen={widget.id == context.fullScreen.widgetId}
              onWidgetUpdate={(__widget) => {
                setWidgetsData((widgetsData) => {
                  return Object.entries(widgetsData).reduce(
                    (memo, [key, value]) => {
                      return {
                        ...memo,
                        [key]: value.reduce((_memo, _widget) => {
                          const match = _widget.id === widget.id;

                          return [
                            ..._memo,
                            match ? { ..._widget, ...__widget } : _widget
                          ];
                        }, [])
                      };
                    },
                    {}
                  );
                });
              }}
            />
          </Suspense>
        )}
      </WidgetLayout>
    );
  }

  function getDashboard(props) {
    var layout = { lg: [] };

    let widgetsElem = [];

    let lastWid = _.maxBy(props.widgetsData, 'config.resize_position.y');
    
    for (let section in widgetsData) {
      let sectioWiseWidgetsData = widgetsData[section];
      let index = sectioWiseWidgetsData.length;
      sectioWiseWidgetsData.map((widget, i) => {
        if (!widget) return;
        let pos = {
          i: widget.id,
          x: 0,
          y: 0,
          w: widget.width ? widget.width : 4,
          h: widget.height ? widget.height : 1,
          minW: 4,
          maxH: 4,
          minH: 2,
          maxW: 12
        };

        if (
          lastWid
          && lastWid.config
          && lastWid.config.resize_position
          && lastWid.config.resize_position.y !== undefined
        ) {
          pos.y = lastWid.config.resize_position.y + 1;
        }

        if (widget.config && widget.config.resize_position) {
          //pos = widget.config.resize_position;
          pos = {
            ...widget.config.resize_position,
            ...(() => {
              switch (true) {
                case widget.type === 'Health' && widget.custom:
                  return (() => {
                    const { w, h } = pos;

                    return { w, h };
                  })();

                case widget.type === 'Graph' &&
                  widget.config.graphConfig.isMiniGraph:
                  return {
                    w: widget.minWidgetWidth,
                    h: widget.minWidgetHeight
                  };

                case widget.type === 'Scorecard' &&
                  widget.config.locationBasedParamSelector.isComparison:
                  return (() => {
                    const { h } = pos;

                    return {
                      h: Math.max(1, h)
                    };
                  })();

                default:
                  return (() => {
                    const { w, h } = widget.config.resize_position;

                    return { w, h };
                  })();
              }
            })()
          };

          pos.i = widget.id;
        }

        // set widget min width from config
        if (widget.minWidgetWidth) {
          pos.minW = widget.minWidgetWidth;
            pos.w = pos.w < widget.minWidgetWidth ? widget.minWidgetWidth : pos.w;
        }

        // set widget min height from config
        if (widget.minWidgetHeight) {
          pos.minH = widget.minWidgetHeight;
            pos.h = pos.h < widget.minWidgetHeight ? widget.minWidgetHeight : pos.h;
        }

        // set widget max width from config
        if (widget.maxWidgetWidth) {
          pos.maxW = widget.maxWidgetWidth;
          pos.w = pos.w > widget.maxWidgetWidth ? widget.maxWidgetWidth : pos.w;
        }

        // set widget max height from config
        if (widget.maxWidgetHeight) {
          pos.maxH = widget.maxWidgetHeight;
          pos.h = pos.h > widget.maxWidgetHeight ? widget.maxWidgetHeight : pos.h;
        }

        layout.lg.push(pos);
          widgetsElem.push(
            <div key={widget.id} widget-id={widget.id} 
            style={widget.type === 'Section'? {zIndex: 1}:{}}
            id={widget.type === 'Factories' ? 0 : index} 
            >
              {getWidget(widget, i)}
            </div>,
          );
          index--;

        //if (i === 0) {
          //let sepPos = { ...pos };
          //sepPos.maxH = 0;
          //sepPos.minH = 0;
          //sepPos.h = 0;
          //sepPos.w = 12;
          //sepPos.i = `${widget.id}_seperator`;
          //layout.lg.push(sepPos);

          //!DISABLE_SECTION.includes(USERNAME) &&
            //section &&
            //widgetsElem.push(
              //<div
                //key={`${widget.id}_seperator`}
                //data-id={widget.id}
                //className='seperator'
                //style={{ border: '10px solid red' }}
              //>
                //<div className='seperator-border'>
                  //<div className='seperator-name'>
                    //{section ? section : 'No Section Name'}
                  //</div>
                //</div>
              //</div>
            //);
        //}
      });
    }
    
    return (
      <div onLoad={adjustSeperators}>
        {
          props && props.factoryBackdrop && <div onClick={() => props.setFactoryBackdrop(false)} style={{ position: "fixed", top: "0", right: "0", bottom: " 0", left: "0", zIndex: "99", backgroundColor: "black", opacity: "0.6" }} />
        }
        <h2 style={{marginBottom: "-0.90rem"}} className='dashboard-head'>{props ? props.dashboard.config && props.dashboard.config.showHeader ? (props.dashboard.name || props.dashboard.dashboard_name) : null : (props.dashboard.name || props.dashboard.dashboard_name)}</h2>
        <ResponsiveGridLayout
          isDraggable={false}
          isResizable={false}
          className="dashboard-layout"
          layouts={layout}
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 12 }}
          rowHeight={DASHBOARD_ROW_HEIGHT}
          width={1200}
          //margin={DISABLE_SECTION.includes(USERNAME) ? [20, 20] : [20, 60]}
          margin={[20, 20]}
          // compactType="horizontal"
          onLayoutChange={(currentLayout, allLayouts) => {
            adjustSeperators();
          }}
        >
          {widgetsElem}
        </ResponsiveGridLayout>
      </div>
    );
  }

  const adjustSeperators = () => {
    let widgets = document.querySelectorAll('.dashboard-layout .widget-container');
    let widgetsPosition = {};

    if (widgets.length > 0) {
      for (let i = 0; i < widgets.length; i++) {
        let wid = widgets[i];
        widgetsPosition[wid.getAttribute('widget-id')] = wid.style.transform;
      }
    }

    let seperatorGroup = document.querySelectorAll('.dashboard-layout .seperator');

    if (widgetsPosition) {
      for (let j = 0; j < seperatorGroup.length; j++) {
        let seperatorGroupElem = seperatorGroup[j];

        if (widgetsPosition[seperatorGroupElem.getAttribute('data-id')]) {
          let pos = getYTranslateValue(widgetsPosition[seperatorGroupElem.getAttribute('data-id')]);
          if (pos) {
            seperatorGroupElem.style.transform = `translate(15px, ${pos})`;
          }
        }
      }
    }
  };

  const getYTranslateValue = (translateVal) => {
    let submatch = '';
    let matches = translateVal.match(/\((.*?)\)/);
    if (matches) {
      submatch = matches[1].split(',')[1];
    }

    return submatch;
  };

  function handleDashboardAction(index, action) {
    if (userContext.permission(action.permission)) {
      switch (action.optionIndex) {
        case 0:
          addWidget();
          break;
        case 1:
          editDashboard();
          break;
        case 3:
          context.confirm.show(
            'Please scroll down to the bottom of the page to download all the widget data as image.',
            () => oHtmlToCanvas.downloadPNGImage(document.getElementById('dashboard-container'))
          );
          break;
        case 4:
          context.confirm.show(
            'Please scroll down to the bottom of the page to download all the widget data as PDF.',
            () => oHtmlToCanvas.downloadPdf(document.getElementById('dashboard-container'))
          );
          break;
        case 2:
          context.confirm.show(`Are you sure you want to delete ${props.dashboard.name || props.dashboard.dashboard_name}`, () => {
            props.deleteDashboard({
              id: props.dashboard.id || props.dashboard.dashboard_id,
              // "showSuccessMsg": true
            });
          });
          break;
        case 5:
          // props.getDashboardConfigHistory({
          //   from_date: encodeURIComponent(moment().subtract(7, 'days').format()),
          //   to_date: encodeURIComponent(moment().format()),
          //   dashboard_id: props.dashboard.id || props.dashboard.dashboard_id,
          // });
          setAuditTrail(true);
          break;
      }
    } else context.notify.error(NOT_ACCESS_MESSAGE)
  }

  function exportDashboardAsImage() {
    let dashboardContainerDiv = document.getElementById('dashboard-container');
    if (dashboardContainerDiv) {
      oHtmlToCanvas.downloadPNGImage(dashboardContainerDiv);
    }
  }

  function getOptionsAtGroup(i) {
    // return OPTIONS.filter(item => userContext.permission(item.permission));
    return OPTIONS
  }

  function renderFloatingActions() {
    return (
      <DropDown
        text={
          <div className="float-button">
            <span className="float-button-icon">
              <i className="fa fa-list" aria-hidden="true"></i>
            </span>
            <span className="float-button-text">Options</span>
          </div>
        }
        icon=""
        getOptionsAtGroup={getOptionsAtGroup}
        value="value"
        tooltip=""
        onClickCallback={(i) => handleDashboardAction(i, getOptionsAtGroup()[i])}
        hideCheckBox={true}
        shouldToggleOnclick={true}
      />
    );
  }

  function brandInfo() {
    return (
      <div className="hidden-element">
        <h4>
          <span className="dashboard-name">
            SMARTSENSE
            <Icon size={35} alt="" src={brandlogo} position={ICON_POSITION.CENTER_LEFT} />
          </span>
        </h4>
      </div>
    );
  }

  const handleDashboardScroll = (e) => {
    if (
      ((e.currentTarget.scrollTop + e.currentTarget.clientHeight) >= (e.currentTarget.scrollHeight - 100))
      &&
      !props.loadingWidgets
    ) {
      const pageNo = Math.ceil(props.widgetsData.length / PAGE_SIZE) + 1;
      if (pageNo > props.totalPages) return;

      // getWidgetApi(pageNo);
    }
  }

  return (
    <Fragment>
      <div id='dashboard-container' onScroll={handleDashboardScroll}>
        {/* {brandInfo()} */}
        {props.isInitialLoaded ? (
          props.widgetsData.length > 0 ? (
            <Fragment>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: '-68px'
                }}
                className='custom-calender'
              >
                {props.dashboard.config &&
                props.dashboard.config.showDate &&
                (typeof props.dashboard.config.showDateToggle === 'boolean'
                  ? !props.dashboard.config.showDateToggle
                  : true) &&
                !props.showDateToggle ? (
                  <WidgetCalenderConfig
                    handleDateRangeChange={dateTimeContext.handleDate}
                    datePreset={dateTimeContext.startDate}
                    startDate={dateTimeContext.startDate}
                    endDate={dateTimeContext.endDate}
                  />
                ) : null}
              </div>
              {getDashboard(props)}
            </Fragment>
          ) : (
            getNoWidgetScreen()
          )
        ) : null}
      </div>
      {renderModal()}
      <DashboardConfigHistory
        showDetails={isOpenAuditTrail}
        onClose={() => setAuditTrail(false)}
        dashboardId={props.dashboard.id || props.dashboard.dashboard_id}
        dashboardConfigHistoryData={props.dashboardConfigHistoryData}
        getDashboardConfigHistory={props.getDashboardConfigHistory}
      />
      {/* <div className="dashboard-floating-actions">{renderFloatingActions()}</div> */}
      <Loader loading={props.loadingWidgets} />
      {/* <WidgetPopout 
        isOpen = {isPoputWidgetOpen} 
        widget = {poputWidget}
        closeModal = {()=>setIsPoputWidgetOpen(false)}
      >
        {poputWidget && getPopoutWidget(poputWidget)}
      </WidgetPopout> */}
    </Fragment>
  );
}

const mapStateToProps = (state, props) => {
  const widgetsData = state.allWidgets.data;
  const dashboardData = state.getDashboardWidgets.data.result ?? [];
  const loadingWidgets = state.allWidgets.loading;
  const factoryBackdrop = state.setFactoryBackdrop.factoryBackdrop;

  const widgetDatas = (() => {
    switch (true) {
      case !props.match.url.includes('Dashboard'):
        return (() => {
          switch (true) {
            case !!props.match.url.includes('Home'):
            case !!props.match.url.includes('Sustainability'):
            case !!props.match.url.includes('Workplace'):
              return (props.dashboard.id || props.dashboard.dashboard_id) &&
                !props.dashboard.status
                ? widgetsData
                : dashboardData;
          }
        })();

      default:
        return props.dashboard.template_id ? dashboardData : widgetsData;
    }
  })();

  let serviceDefault;

  let storedServices = JSON.parse(localStorage.getItem('smartsense.services'));

  let sustainability = storedServices.find(
    (item) => item.name == 'Sustainability'
  );

  if (sustainability && sustainability.id) {
    serviceDefault =
      state.makeServiceDefault.data &&
      state.makeServiceDefault.data[sustainability.id] &&
      state.makeServiceDefault.data[sustainability.id].result;
  }

  let isInitialLoaded;
  if (
    (props.dashboard.id || props.dashboard.dashboard_id) &&
    !props.dashboard.status
  ) {
    isInitialLoaded = state.allWidgets.success !== undefined;
  } else {
    isInitialLoaded = state.getDashboardWidgets.success !== undefined;
  }

  return {
    widgetsData: handleGroupOrContainerWidget(
      mergeDashboardDataWithConfig(widgetDatas)
    ),
    isInitialLoaded,
    loadingWidgets,
    totalPages: state.allWidgets.total_pages,
    dashboardData: widgetsData,
    deleteWidgetData: state.deleteWidget,
    cloneWidgetData: state.createWidget,
    deleteDashboardData: state.deleteDashboard,
    dashboardConfigHistoryData: state.getDashboardConfigHistory,
    popupWidget: state.setPopoutWidget.widget,
    factoryBackdrop,
    serviceDefault: serviceDefault
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllWidget,
    getDashboardWidgets,
    createWidget,
    resetCreateWidget,
    deleteWidget,
    resetDeleteWidget,
    deleteDashboard,
    resetDeleteDashboard,
    getDashboardConfigHistory,
    setPoputWidget,
    openPoputWidget,
    setFactoryBackdrop,
    makeServiceDefault,
    getTemplateWidgets
  })(DashboardContainer),
);