export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'GET_ALL_NOTIFICATIONS_SUCCESS';
export const GET_ALL_NOTIFICATIONS_FAILURE = 'GET_ALL_NOTIFICATIONS_FAILURE';

export const GET_ALL_NOTIFICATIONS_COUNT = 'GET_ALL_NOTIFICATIONS_COUNT';
export const GET_ALL_NOTIFICATIONS_COUNT_SUCCESS = 'GET_ALL_NOTIFICATIONS_COUNT_SUCCESS';
export const GET_ALL_NOTIFICATIONS_COUNT_FAILURE = 'GET_ALL_NOTIFICATIONS_COUNT_FAILURE';

export const GET_NOTIFICATION_SETTING = 'GET_NOTIFICATION_SETTING';
export const GET_NOTIFICATION_SETTING_SUCCESS = 'GET_NOTIFICATION_SETTING_SUCCESS';
export const GET_NOTIFICATION_SETTING_FAILURE = 'GET_NOTIFICATION_SETTING_FAILURE';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_RESET = 'DELETE_NOTIFICATION_RESET';

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_RESET = 'UPDATE_NOTIFICATION_RESET';


export const NOTIFICATION_POPUP = 'NOTIFICATION_POPUP';
// export const OPEN_CLOSE_NOTIFICATION_POPUP = 'OPEN_CLOSE_NOTIFICATION_POPUP';
// export const CLOSE_NOTIFICATION_POPUP = 'CLOSE_NOTIFICATION_POPUP';

export const UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS';
export const UPDATE_NOTIFICATION_STATUS_SUCCESS = 'UPDATE_NOTIFICATION_STATUS_SUCCESS';
export const UPDATE_NOTIFICATION_STATUS_FAILURE = 'UPDATE_NOTIFICATION_STATUS_FAILURE';
export const UPDATE_NOTIFICATION_STATUS_RESET = 'UPDATE_NOTIFICATION_STATUS_RESET';

export const TURN_OFF_NOTIFICATION = 'TURN_OFF_NOTIFICATION';
export const TURN_OFF_NOTIFICATION_SUCCESS = 'TURN_OFF_NOTIFICATION_SUCCESS';
export const TURN_OFF_NOTIFICATION_FAILURE = 'TURN_OFF_NOTIFICATION_FAILURE';
export const TURN_OFF_NOTIFICATION_RESET = 'TURN_OFF_NOTIFICATION_RESET';

export const NOTIFICATION_CATEGORY = 'NOTIFICATION_CATEGORY';
export const NOTIFICATION_CATEGORY_SUCCESS = 'NOTIFICATION_CATEGORY_SUCCESS';
export const NOTIFICATION_CATEGORY_FAILURE = 'NOTIFICATION_CATEGORY_FAILURE';


// notification types
export const NOTIFICATION_TYPES = {
    ALERTS: 'alerts',
    REP0RTS: 'reports',
    INSIGHTS: 'insights',
}

export const NOTIFICATION_CATEGORIES = {
    AlERT_GENERATED: 1,
    AlERT_RESOLVED: 2,
    AlERT_PAUSED: 3,
    AlERT_ACKNOWLEGE: 4,
    AlERT_PRIORITY_CAHNGED: 5,
    AlERT_DELETED: 6,
    REPORT_GENERATED: 7,
    REPORT_ADDED_EMAIL: 8,
    REPORT_REMOVED_EMAIL: 9,
    AlERT_NOT_RESOLVED: 10,
    AlERT_PAUSED_FOR_MORE_THAN_7_DAYS: 11,
    HIGH_PRIORITY_ALERT: 12,
    INSIGHT_GENERATED: 13,
    INSIGHT_IGNORED: 14,
    INSIGHT_RESOLVED: 15,
    INSIGHT_NOT_ACTED_UPON: 16,
    INSIGHT_IGNORED_MIN_25: 17,
}


// const CATEGORIES = [
//     { "id": 15, "name": "Insight-resolved" },
//     { "id": 14, "name": "Insight-ignored" },
//     { "id": 13, "name": "Insight-generated" },
//     { "id": 12, "name": "High-priority-alert" },
//     { "id": 11, "name": "Alerts-paused-for-more-than-7days" },
//     { "id": 10, "name": "Alerts-not-resolved" },
//     { "id": 9, "name": "Report-removed-email" },
//     { "id": 8, "name": "Report-added-email" },
//     { "id": 7, "name": "Report-generated" },
//     { "id": 6, "name": "Alert-deleted" },
//     { "id": 5, "name": "Alert-priority-changed" },
//     { "id": 4, "name": "Alert-acknowledge" },
//     { "id": 3, "name": "Alert-paused" },
//     { "id": 2, "name": "Alert-resolved" },
//     { "id": 1, "name": "Alert-generated" }
// ]