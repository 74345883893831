import React from 'react'
import Switch from "react-switch";

function SwitchToggle(props) {
    return (
        <label className='switch-wrap'>
            <Switch
                onChange={props.onHandleChange}
                checked={props.isChecked}
                onColor={props.switchOnColor}
                disabled={props.disabled}
                className={props.className}
            />
            <span>{props.label}</span>
        </label>
    )
}

export default SwitchToggle;
