/**
 * All reducer for widget
 */
import {
  SENSOR_EVENT_OVERVIEW,
  SENSOR_EVENT_OVERVIEW_FAILURE,
  SENSOR_EVENT_OVERVIEW_SUCCESS,
  EVENT_CONFIGURATION,
  EVENT_CONFIGURATION_FAILURE,
  EVENT_CONFIGURATION_SUCCESS
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined,
};

export function getSensorEventOverview(state = INITIAL_STATE, action) {
  switch (action.type) {
      case SENSOR_EVENT_OVERVIEW:
          return {
              ...state,
              loading: true,
              error: false
          };
      case SENSOR_EVENT_OVERVIEW_SUCCESS:
          return {
              ...state,
              data: action.response,
              error: false,
              loading: false
          };
      case SENSOR_EVENT_OVERVIEW_FAILURE:
          return {
              ...state,
              error: true,
              loading: false
          };
      default:
          return state
  }
}

export function getConfigurationEvent(state = INITIAL_STATE, action) {
    switch (action.type) {
        case EVENT_CONFIGURATION:
            return {
                ...state,
                loading: true,
                error: false
            };
        case EVENT_CONFIGURATION_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false
            };
        case EVENT_CONFIGURATION_FAILURE:
            return {
                ...state,
                error: true,
                loading: false
            };
        default:
            return state
    }
  }