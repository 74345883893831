import React from 'react';
import { Col } from 'reactstrap';

import { CheckBox } from '../../../../../common/components';
import FormulaMaker from './FormulaMaker';
import LocationAndParameterSelect from '../Widgets/CommonControls/LocationAndParameterSelect';

const StandardParameterConfig = (_props) => {
  const { onChange, ...props } = _props;

  const isComputed = !!props.value.isComputed;

  return (
    <div className='w-100'>
      {!props.isComputedVisiblityToggle && (
        <Col className='mb-3'>
          <CheckBox
            label='Is Computed'
            size='sm'
            checked={isComputed}
            checkboxClicked={(isComputed) => {
              onChange({
                ...props.value,
                formula: undefined,
                location: undefined,
                readingtype: undefined,
                isComputed
              });
            }}
          />
        </Col>
      )}

      {isComputed ? (
        <FormulaMaker
          showPartialVisiblityToggle={true}
          plant={props.plant}
          value={props.value}
          onChange={(value, isInValid) => {
            onChange(
              {
                isComputed,
                ...value
              },
              isInValid
            );
          }}
        />
      ) : (
        <div className='d-flex'>
          <LocationAndParameterSelect
            label={['Location', 'Parameter']}
            plant={props.plant}
            value={props.value}
            onChange={(value, isInValid) => {
              onChange(
                {
                  isComputed,
                  ...value
                },
                isInValid
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default StandardParameterConfig;
