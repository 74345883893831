import { GET_ASSETS_DETAIL_DATA, GET_ASSETS_LIST_DATA, GET_ASSET_ACTION_DATA, POST_ASSET_ACTION_DATA, GET_ASSETS_CONDITION_DATA, GET_ASSETS_ALERT_DATA, GET_ASSETS_OPEN_INSIGHT_DATA, GET_ASSETS_HEALTH_INDEX_DATA} from '../constants'
import { POST_ALERT_ACTION_DATA, POST_USER_ACTIVITY_ACTION_DATA} from './constant';

export function getAssetDetailAction(value) {
  return {
    type: GET_ASSETS_DETAIL_DATA,
    payload: value
  }
}

export function getOpenInsightsAction(value) {
	return {
		type: GET_ASSETS_OPEN_INSIGHT_DATA,
		payload: value
	}
}

export function getAssetListAction(value){
	return{
		type: GET_ASSETS_LIST_DATA,
		payload: value
	}
}


// export function getAssetConditionAction(value) {
// 	return {
// 		type: GET_ASSETS_CONDITION_DATA,
// 		payload: value
// 	}
// }


export function getAssetAction(value){
	return{
		type: GET_ASSET_ACTION_DATA,
		payload: value
	}
}


export function postAssetAction(value, assetName){
	return{
		type: POST_ASSET_ACTION_DATA,
		payload: value,
		assetName: assetName
	}
}

export function getAssetAlertAction(value) {
	return {
		type: GET_ASSETS_ALERT_DATA,
		payload: value
	}
}

export function postAlertAction(value) {
	return {
		type: POST_ALERT_ACTION_DATA,
		payload: value,
	}
}

export function postUserActivityAction(value) {
	return {
		type: POST_USER_ACTIVITY_ACTION_DATA,
		payload: value,
	}
}

export function getAssetHeathAction(value) {
	return {
		type: GET_ASSETS_HEALTH_INDEX_DATA,
		payload: value,
	}
}
