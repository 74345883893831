import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import parameterConfsInputGet from '../common/parameterConfsInputGet';
import formulaConfsInputGet from '../common/formulaConfsInputGet';
import parameterConfInputGet from '../common/parameterConfInputGet';
import ParameterConfigMixed from './ParameterConfigMixed';
import collectionGet from '../common/collectionGet';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import formulaConfsOutputGet from '../common/formulaConfsOutputGet';
import { Input, Select, Button } from '../../../../../../../common/components';

const parameterTypeCollection = ['7 - Tiled', '3 - Tiled'];

const cardNameGet = (index) => {
  return `card-0${index}`;
};

const parameterConfsGet = (parameters) => {
  const parameterConfs = parameters.reduce((memo, parameter, index) => {
    const value =
      !parameter.isComputed &&
      (() => {
        const {
          alias,
          threshold,
          locationname,
          locationid,
          paramLabel,
          param
        } = parameter;

        return {
          index,
          alias,
          threshold,
          locParam: {
            location: {
              label: locationname,
              value: locationid
            },
            readingtype: {
              label: paramLabel,
              value: param
            }
          }
        };
      })();

    return value ? [...(memo || []), value] : memo;
  }, null);

  return (
    parameterConfs &&
    parameterConfsInputGet(parameterConfs).reduce((memo, parameterConf) => {
      return [
        ...memo,
        {
          ...parameterConf,
          isComputed: false
        }
      ];
    }, [])
  );
};

const formulaConfsGet = (parameters) => {
  const formulaConfs = parameters.reduce((memo, parameter, index) => {
    const value =
      parameter.isComputed &&
      (() => {
        const {
          alias,
          threshold,
          unit,
          computedFormula: { formula, equation, is_partial }
        } = parameter;

        return {
          index,
          alias,
          threshold,
          unit,
          formula,
          equation,
          is_partial
        };
      })();

    return value ? [...(memo || []), value] : memo;
  }, null);

  return (
    formulaConfs &&
    formulaConfsInputGet(formulaConfs).reduce((memo, formulaConf) => {
      return [
        ...memo,
        {
          ...formulaConf,
          isComputed: true
        }
      ];
    }, [])
  );
};

const _wellnessConfigGet = (_parameters) => {
  const parameters = [
    ...(() => {
      return parameterConfsGet(_parameters) || [];
    })(),
    ...(() => {
      return formulaConfsGet(_parameters) || [];
    })()
  ].sort((a, b) => a.index - b.index);

  return parameters.length
    ? parameters
    : [{ ...parameterConfInputGet(), isComputed: false }];
};

const wellnessConfigGet = (wellnessConfig) => {
  return wellnessConfig.reduce((memo, _wellnessConfig, index) => {
    return [
      ...memo,
      {
        ..._wellnessConfig,
        cardName: _wellnessConfig.cardName || cardNameGet(index + 1),
        parameters: _wellnessConfigGet(_wellnessConfig.parameters)
      }
    ];
  }, []);
};

const __wellnessConfigOutputGet = (_parameter) => {
  const parameter = !_parameter
    ? null
    : !_parameter.isComputed
    ? (() => {
        const _parameterConfs = parameterConfsOutputGet([_parameter]);

        return _parameterConfs
          ? (() => {
              const {
                locParam: {
                  location: { label: locationname, value: locationid },
                  readingtype: { label: paramLabel, value: param }
                },
                ...parameterConf
              } = _parameterConfs[0];

              return {
                ...parameterConf,
                locationname,
                locationid,
                paramLabel,
                param
              };
            })()
          : {};
      })()
    : (() => {
        const _formulaConfs = formulaConfsOutputGet([_parameter]);

        return _formulaConfs
          ? (() => {
              const { formula, equation, is_partial, ...formulaConf } =
                _formulaConfs[0];

              return {
                ...formulaConf,
                computedFormula: {
                  formula,
                  equation,
                  is_partial
                }
              };
            })()
          : {};
      })();

  return parameter;
};

const _wellnessConfigOutputGet = (parameters) => {
  return parameters.reduce((memo, parameter) => {
    return [...memo, __wellnessConfigOutputGet(parameter)];
  }, []);
};

const wellnessConfigOutputGet = (wellnessConfig) => {
  return wellnessConfig.reduce((memo, _wellnessConfig) => {
    return [
      ...memo,
      {
        ..._wellnessConfig,
        cardCount: wellnessConfig.length,
        parameters: _wellnessConfigOutputGet(_wellnessConfig.parameters)
      }
    ];
  }, []);
};

const ParameterConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [wellnessConfig, setWellnessConfig] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams.wellnessConfig &&
        props.widgetParams.wellnessConfig.value;

      setWellnessConfig(wellnessConfigGet(conf || [{ parameters: [] }]));

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    props.appInputParamChanged({
      wellnessConfig: {
        value: object
      }
    });
  };

  const _renderWellnessConfig = (_wellnessConfig, index) => {
    return (
      <div className='content-border-wrap pd-y-10 mg-b-15'>
        <p className='head-label-text mb-0'>{_wellnessConfig.cardName}</p>

        <hr className='horizontal-divider mg-t-10 mg-b-15' />

        <Row>
          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Card Name</label>

              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter Alias'
                value={_wellnessConfig.cardName || ''}
                inputChanged={(cardName) => {
                  const __wellnessConfig = collectionGet(
                    {
                      ..._wellnessConfig,
                      cardName
                    },
                    index,
                    wellnessConfig
                  );

                  setWellnessConfig(__wellnessConfig);

                  appInputParamChangedHandle(
                    wellnessConfigOutputGet(__wellnessConfig)
                  );
                }}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Parameter Type</label>

              <Select
                options={parameterTypeCollection.reduce((memo, value) => {
                  return [
                    ...memo,
                    {
                      label: value,
                      value
                    }
                  ];
                }, [])}
                isMulti={false}
                placeholder='Select Parameter Type'
                value={(() => {
                  const value = _wellnessConfig.parameterType;

                  return (
                    value && {
                      label: value,
                      value
                    }
                  );
                })()}
                onChange={({ value }) => {
                  const __wellnessConfig = collectionGet(
                    {
                      ..._wellnessConfig,
                      parameterType: value
                    },
                    index,
                    wellnessConfig
                  );

                  setWellnessConfig(__wellnessConfig);

                  appInputParamChangedHandle(
                    wellnessConfigOutputGet(__wellnessConfig)
                  );
                }}
              />
            </div>
          </Col>
        </Row>

        <ParameterConfigMixed
          confs={_wellnessConfig.parameters}
          onConfsChange={(parameters) => {
            const __wellnessConfig = collectionGet(
              {
                ..._wellnessConfig,
                parameters
              },
              index,
              wellnessConfig
            );

            setWellnessConfig(__wellnessConfig);

            appInputParamChangedHandle(
              wellnessConfigOutputGet(__wellnessConfig)
            );
          }}
        />
      </div>
    );
  };

  const renderWellnessConfig = () => {
    return (
      <Fragment>
        {wellnessConfig.map((_wellnessConfig, index) => {
          return (
            <Fragment key={index}>
              {_renderWellnessConfig(_wellnessConfig, index)}
            </Fragment>
          );
        })}
      </Fragment>
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        {renderWellnessConfig()}

        <div className='btn-wrap'>
          <button
            className='icon-text-btn mg-t-15'
            onClick={() => {
              setWellnessConfig((wellnessConfig) => {
                return [
                  ...wellnessConfig,
                  {
                    cardName: cardNameGet(wellnessConfig.length + 1),
                    parameters: [
                      {
                        ...parameterConfInputGet(),
                        isComputed: false
                      }
                    ]
                  }
                ];
              });
            }}
            disabled={wellnessConfig.length >= 3}
          >
            <i className='fa fa-plus-circle fa-3x' aria-hidden='true'></i> Add
            Card
          </button>
        </div>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfig;
