export const CREATE_NETWORK_CALCULATED_PARAM_TEMPLATE =
  'CREATE_NETWORK_CALCULATED_PARAM_TEMPLATE';
export const CREATE_NETWORK_CALCULATED_PARAM_TEMPLATE_SUCCESS =
  'CREATE_NETWORK_CALCULATED_PARAM_TEMPLATE_SUCCESS';
export const CREATE_NETWORK_CALCULATED_PARAM_TEMPLATE_FAILURE =
  'CREATE_NETWORK_CALCULATED_PARAM_TEMPLATE_FAILURE';
export const CREATE_NETWORK_CALCULATED_PARAM_TEMPLATE_RESET =
  'CREATE_NETWORK_CALCULATED_PARAM_TEMPLATE_RESET';

export const UPDATE_NETWORK_CALCULATED_PARAM_TEMPLATE =
  'UPDATE_NETWORK_CALCULATED_PARAM_TEMPLATE';
export const UPDATE_NETWORK_CALCULATED_PARAM_TEMPLATE_SUCCESS =
  'UPDATE_NETWORK_CALCULATED_PARAM_TEMPLATE_SUCCESS';
export const UPDATE_NETWORK_CALCULATED_PARAM_TEMPLATE_FAILURE =
  'UPDATE_NETWORK_CALCULATED_PARAM_TEMPLATE_FAILURE';
export const UPDATE_NETWORK_CALCULATED_PARAM_TEMPLATE_RESET =
  'UPDATE_NETWORK_CALCULATED_PARAM_TEMPLATE_RESET';
