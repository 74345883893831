import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import crossIcon from '../../../../assets/images/closeRed.svg';
import checkIcon from '../../../../assets/images/checkGreen.svg';
import IconButton from '@material-ui/core/IconButton';
import Button from '../Button';

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

export default function SimplePopover(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        props.onClose ? props.onClose() : '';
        setAnchorEl(null);
    }

    function handleCheck() {
        props.handleCheck(true);
        setAnchorEl(null);
    }

    function handleClear() {
        props.handleClear();
        setAnchorEl(null);
    }

    function handleSubmit() {
        // props.onSubmit ? props.onSubmit() : '';
        //  return false if popup should not close
        if (props.onSubmit && props.onSubmit() !== false) {
            setAnchorEl(null);
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className='commonpopupContainer'>
            <div aria-describedby={id} onClick={handleClick}>
                {props.getTriggerContent ? props.getTriggerContent() : null}
            </div>
            <Popover
                disablePortal={false}
                className='commonpopup'
                marginThreshold={10}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: props.placement ? props.placement : 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className='popup-action-btns'>
                    <IconButton size='medium' color="inherit" className={classes.closeButton} onClick={props.insightGraph ? handleClear : handleClose}
                                aria-label="Close">
                        <img src={crossIcon} height={15} alt="Image"/>
                    </IconButton>
                    <IconButton size='medium' color="inherit" className={classes.closeButton} onClick={props.insightGraph ? handleCheck : handleClose}
                                aria-label="Close">
                        <img src={checkIcon} height={15} alt="Image"/>
                    </IconButton>
                </div>

                {props.getTooltip ? props.getTooltip() : ''}
                {
                    props.showFooter === true ?
                        <div className='popupfooter'>
                            <Button
                                buttonType='primary'
                                buttonClick={handleSubmit}
                                size='sm'
                                isDisabled={false}
                                innerContent='Submit'
                            />

                            <Button
                                buttonClick={handleClose}
                                size='sm'
                                isDisabled={false}
                                innerContent='Cancel'
                            />
                        </div>
                        :
                        null
                }
            </Popover>
        </div>
    );
}