import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../../constants';
import { httpMethodGetJSON, httpMethodPost } from '../../../../https';
import {
  GET_WORKPLACE_GRAPH_NORMAL,
  GET_WORKPLACE_GRAPH_COMPUTED
} from './constant';

const TIME_SERIES_URL = `${URL}/reading/reading_series/`;

const READING_COMPUTED_URL = `${URL}/reading/reading_computed/`;

export const getWorkplaceGraphNormal = (action$) =>
  action$.pipe(
    ofType(GET_WORKPLACE_GRAPH_NORMAL),
    mergeMap((action) => {
      return httpMethodGetJSON(action, TIME_SERIES_URL);
    })
  );

export const getWorkplaceGraphComputed = (action$) =>
  action$.pipe(
    ofType(GET_WORKPLACE_GRAPH_COMPUTED),
    mergeMap((action) => {
      return httpMethodPost(action, READING_COMPUTED_URL);
    })
  );
