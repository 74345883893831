import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import * as htmlToImage from 'html-to-image';

const EXPORT_FILE_TYPE_PNG = 'image/png';
const EXPORT_FILE_TYPE_JPEG = 'image/jpeg';

export default class Export {
    constructor() {
    }

    // method will return promise with generated image data url
    getUrl(selectorID, filetype = EXPORT_FILE_TYPE_PNG) {
        let domElem = null
        if (selectorID instanceof Element) {
            domElem = selectorID;
        } else {
            domElem = document.getElementById(selectorID);
        }

        if (domElem === null) {
            return new Promise((resolve) => {
                resolve();
            });
        }

        const useWidth = domElem.scrollWidth;
        const useHeight = domElem.scrollHeight;

        window.scrollTo(0, 0);

        return html2canvas(domElem, {
            width: useWidth,
            height: useHeight,
            removeContainer: true
        }).then((canvas) => {
            canvas.font = '600 48px "Font Awesome 5 Free"';
            // resolve(canvasImg);
            // var elem = document.createElement('img');
            // elem.src = canvasImg;
            // var win = window.open();
            // win.document.body.appendChild(elem);
            // document.getElementsByTagName('body')[0].appendChild(canvas)
            return canvas.toDataURL(filetype);
        }).catch((e) => {
            console.log(this.className + ': ' + 'Something went wrong' + e)
        })
    }

    // downloadPNGImage(selectorID = '') {
    //     this.getUrl(selectorID, EXPORT_FILE_TYPE_PNG).then((uri) => {
    //         let name = selectorID instanceof Element ? 'image' : selectorID;
    //         this._downloadURI(uri, `smartsense_${name}.png`);
    //     });
    // }

    // downloadJPEGImage(selectorID = '') {
    //     this.getUrl(selectorID, EXPORT_FILE_TYPE_JPEG).then((uri) => {
    //         let name = selectorID instanceof Element ? 'image' : selectorID;
    //         this._downloadURI(uri, `smartsense_${name}.jpeg`);
    //     });
    // }


    _downloadURI = function (uri, name) {
        let link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link = null;
    }

    //three parameters should pass first selectorID, second one toConvert like jpg or png and third one for format of file like png or jpeg
    downloadPNGImage = (selectorID = '', isServiceTemplate = false) => this.getImageUrl(selectorID, 'toPng', 'png', isServiceTemplate);

    downloadJPEGImage = (selectorID = '') => this.getImageUrl(selectorID, 'toJpeg', 'jpeg');

    downloadSVGImgage = (selectorID = '') => this.getImageUrl(selectorID, 'toSvg', 'svg');

    downloadPdf(selectorID = '') {
        
        // var someimage = document.getElementById('image-container');

        // var myimg = someimage.getElementsByTagName('img')[0];

        // var mysrc = myimg.src;
        selectorID.style.backgroundColor = "#ffffff";
        htmlToImage.toJpeg(selectorID)
            .then((dataUrl) => {
                html2canvas(selectorID).then((canvas) => {
                    // var imgData = canvas.toDataURL('image/png');
                    var imgWidth = 210;
                    var pageHeight = 295;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var doc = new jsPDF('p', 'mm');
                    var position = 10; // give some top padding to first page

                    doc.addImage(dataUrl, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                    while (heightLeft >= 0) {
                        position += heightLeft - imgHeight; // top padding for other pages
                        doc.addPage();
                        doc.addImage(dataUrl, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }
                    doc.save('smartsense.pdf');
                    selectorID.style.backgroundColor = "transparent";
                }).catch((e) => {
                    console.log(this.className + ': ' + 'Something went wrong' + e)
                    selectorID.style.backgroundColor = "transparent";
                })
            })




        // var HTML_Width = $(".canvas_div_pdf").width();
        // var HTML_Height = $(".canvas_div_pdf").height();

        // var HTML_Height = selectorID.style.height;
        // var HTML_Width = selectorID.style.width;
        // var top_left_margin = 15;
        // var PDF_Width = HTML_Width + (top_left_margin * 2);
        // var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
        // var canvas_image_width = HTML_Width;
        // var canvas_image_height = HTML_Height;

        // var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;


        // html2canvas(selectorID, { allowTaint: true }).then((canvas) => {
        //     canvas.getContext('2d');

        //     var imgData = canvas.toDataURL("image/png");
        //     var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
        //     pdf.addImage(imgData, 'PNG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


        //     for (var i = 1; i <= totalPDFPages; i++) {
        //         pdf.addPage(PDF_Width, PDF_Height);
        //         pdf.addImage(imgData, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
        //     }

        //     pdf.save("HTML-Document.pdf");
        // });

        // htmlToImage.toJpeg(selectorID)
        //     .then((dataUrl) => {
        //         // let domElem = null
        //         // if (selectorID instanceof Element) {
        //         //     domElem = selectorID;
        //         // } else {
        //         //     domElem = document.getElementById(selectorID);
        //         // }

        //         // if (domElem === null) {
        //         //     return new Promise((resolve) => {
        //         //         resolve();
        //         //     });
        //         // }

        //         // const useWidth = domElem.scrollWidth;
        //         // const useHeight = domElem.scrollHeight;

        //         // let name = selectorID instanceof Element ? 'dashboard' : selectorID;
        //         // var pdf = new jsPDF('p', 'mm', 'a4');

        //         // var width = pdf.internal.pageSize.getWidth();
        //         // var height = pdf.internal.pageSize.getHeight();
        //         // pdf.addImage(dataUrl, "PNG", 0, 0, width, height);
        //         // pdf.save(`smartsense_${name}.pdf`);
        //     })
        //     .catch(function (error) {
        //         console.error('oops, something went wrong!', error);
        //     });
    }

    getImageUrl(selectedID, toConvert, type, isServiceTemplate) {
        selectedID.style.backgroundColor = isServiceTemplate ? "#14141B" : "#ffffff";
        htmlToImage[toConvert](selectedID)
            .then((dataUrl) => {
                let name = selectedID instanceof Element ? 'image' : selectedID;
                this._downloadURI(dataUrl, `smartsense_${name}.${type}`)
                selectedID.style.backgroundColor = "transparent";
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
                selectedID.style.backgroundColor = "transparent";
            });
    }
}
