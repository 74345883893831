import queryString from 'query-string';

import {
  LIVEDATA_WIDGET_DATA,
  LIVEDATA_WIDGET_DATA_SUCCESS,
  LIVEDATA_WIDGET_DATA_FAILURE,
  RESET_LIVEDATA_WIDGET_DATA
} from './constant';

const INITIAL_STATE = {
  data: {},
  loading: [],
  error: [],
  success: undefined
};

const collectionUpdatedGet = (collection, from, to) => {
  const index = collection.findIndex((_collection) => _collection === from);

  return [...collection.slice(0, index), to, ...collection.slice(index + 1)];
};

export function getLiveData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LIVEDATA_WIDGET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: state.data[action.payload.widgetId]
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: [
            ...state.loading[action.payload.widgetId],
            true
          ]
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: [
            ...state.success[action.payload.widgetId],
            undefined
          ]
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: [
            ...state.error[action.payload.widgetId],
            false
          ]
        }
      };

    case LIVEDATA_WIDGET_DATA_SUCCESS:
      return (() => {
        const res = (() => {
          switch (true) {
            case !!action.param.isLive && !!action.param.isComputed:
              return (() => {
                const { data: value, ...object } = action.response.response;

                return { ...object, value };
              })();

            case !!action.param.isLive:
              return (() => {
                const { [action.param.readingtypeid]: value, ...object } =
                  Object.values(action.response[0])[0];

                return { ...object, value };
              })();

            case !!action.param.isComputed && !!action.param.isSeries:
              return {
                trend: action.response.response.data
              };

            case !!action.param.isComputed:
              return {
                value: action.response.response.data
              };

            case !!action.param.isSeries:
              return {
                unit: action.response.readingtype[0].unit,
                trend: (action.response.reading_series
                  ? action.response.reading_series[0].timeseries
                  : action.response.timeseries
                ).reduce((memo, _trend) => {
                  return [
                    ...memo,
                    {
                      ..._trend,
                      value:
                        _trend[action.response.readingtype[0].readingtypeid]
                    }
                  ];
                }, [])
              };

            default:
              return {
                unit: action.response[0].readingtype.unit,
                ...action.response[0]
              };
          }
        })();

        const data = action.param.refetch
          ? state.data[action.param.widgetId].reduce((memo, _data) => {
              const value =
                _data._index === action.param._index
                  ? { ..._data, ...res, zoomIn: action.param.zoomIn }
                  : _data;

              return [...memo, value];
            }, [])
          : [
              {
                dataType: action.param.dataType,
                section: action.param.section,
                index: action.param.index,
                isLive: action.param.isLive,
                isComputed: action.param.isComputed,
                isSeries: action.param.isSeries,
                alias: action.param.alias,
                unit: action.param.unit,
                ...res
              },
              ...state.data[action.param.widgetId]
            ].reduce((memo, _data, _index) => {
              return [
                ...memo,
                {
                  ..._data,
                  _index
                }
              ];
            }, []);

        return {
          ...state,
          data: {
            ...state.data,
            [action.param.widgetId]: data
          },
          loading: {
            ...state.loading,
            [action.param.widgetId]: collectionUpdatedGet(
              state.loading[action.param.widgetId],
              true,
              false
            )
          },
          success: {
            ...state.success,
            [action.param.widgetId]: collectionUpdatedGet(
              state.success[action.param.widgetId],
              undefined,
              true
            )
          }
        };
      })();

    case LIVEDATA_WIDGET_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: collectionUpdatedGet(
              state.loading[widgetId],
              true,
              false
            )
          },
          success: {
            ...state.success,
            [widgetId]: collectionUpdatedGet(
              state.success[widgetId],
              undefined,
              false
            )
          },
          error: {
            ...state.error,
            [widgetId]: collectionUpdatedGet(state.error[widgetId], false, true)
          }
        };
      })();

    case RESET_LIVEDATA_WIDGET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: []
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: []
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: []
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: []
        }
      };

    default:
      return state;
  }
}
