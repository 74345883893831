import React, { useState, useEffect, Fragment } from 'react';
import { Col } from 'reactstrap';

import useSensorParameterCollection from '../common/useSensorParameterCollection';
import SensorParameterSelect from './SensorParameterSelect';
import isSensorParameterSelectDisabledGet from '../common/isSensorParameterSelectDisabledGet';

const _sensorParameterItemCount = 5;

const sensorParameterItemCountGet = (collectionLength, currentValue) => {
  return Math.max(collectionLength, currentValue, _sensorParameterItemCount);
};

const ComputedSensorParameterCollection = (props) => {
  const [
    sensorCollection,
    sensorOptions,
    parameterCollection,
    parameterOptionsCollection,
    onSensorChangeHandle,
    onParameterChangeHandle
  ] = useSensorParameterCollection(props.formulaConfParameterCollection);

  const [sensorParameterItemCount, setSensorParameterItemCount] = useState(
    _sensorParameterItemCount
  );

  useEffect(() => {
    const _sensorParameterItemCount = sensorParameterItemCountGet(
      props.formulaConfParameterCollection.length,
      sensorParameterItemCount
    );

    setSensorParameterItemCount(_sensorParameterItemCount);
  }, [props.formulaConfParameterCollection, sensorParameterItemCount]);

  const renderSensorParameterItem = (index = 0) => {
    return (
      <div className='sensor-parameter-item'>
        <label className='head-label-text mb-0 font-weight-light flex-basis-20'>
          Val_{index + 1}
        </label>
        <div className='new-input-wrap flex-basis-40'>
          <SensorParameterSelect
            type='sensor'
            index={index}
            options={sensorOptions}
            value={sensorCollection[index] || ''}
            onChange={(sensor) => {
              onSensorChangeHandle(sensor, index);
            }}
            isDisabled={isSensorParameterSelectDisabledGet(
              index,
              parameterCollection
            )}
          />
        </div>
        <div className='new-input-wrap flex-basis-40'>
          <SensorParameterSelect
            type='paramter'
            options={parameterOptionsCollection[index] || []}
            value={parameterCollection[index] || ''}
            onChange={(parameter) => {
              onParameterChangeHandle(
                parameter,
                index,
                props.onFormulaConfParameterCollectionUpdateTrigger
              );
            }}
            isDisabled={isSensorParameterSelectDisabledGet(
              index,
              parameterCollection
            )}
          />
        </div>
      </div>
    );
  };

  return (
    <Col md={6}>
      <div className='sensor-parameter-wrap'>
        <div className='sensor-parameter-head'>
          <label className='head-label-text flex-basis-20'></label>
          <label className='head-label-text flex-basis-40'>
            Sensors/Location
          </label>
          <label className='head-label-text flex-basis-40'>Parameter</label>
        </div>

        <div className='sensor-parameter-body'>
          {Array.from({ length: sensorParameterItemCount })
            .fill()
            .map((_, index) => {
              return (
                <Fragment key={index}>
                  {renderSensorParameterItem(index)}
                </Fragment>
              );
            })}
        </div>
        <div className='sensor_parameter-add-btn'>
          <button
            className='icon-text-btn'
            onClick={() => {
              setSensorParameterItemCount(sensorParameterItemCount + 1);
            }}
          >
            <i className='fa fa-plus-circle' aria-hidden='true'></i> Add New
          </button>
        </div>
      </div>
    </Col>
  );
};

export default ComputedSensorParameterCollection;