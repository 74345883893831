import React from 'react'
import clsx from 'clsx';
import './breadcrumb.scss';


export default class Breadcrumb extends React.Component {
    constructor(props) {
        super(props);
        this.handleCrumbClickAtIndex = this.handleCrumbClickAtIndex.bind(this);
        this.state = {
            active: this.props.active
        }
    }

    handleCrumbClickAtIndex(e) {
        e.preventDefault();
        const clickedIndex = e.target.getAttribute('data-index');

        if (this.state.active !== clickedIndex) {
            this.setState({
                active: parseInt(clickedIndex)
            });
        }

        if (this.props.didSelectCrumbAtIndex) {
            this.props.didSelectCrumbAtIndex(clickedIndex);
        }
    }

    render() {
        let crumbs = [];
        for (let i = 0; i < this.props.noOfCrumbs; i++) {
            crumbs.push(
                <li
                    className={clsx('breadcrumb-item', {active: this.state.active === i})}
                    key={`bread_crumb_item_${i}_${Math.random()}`}>
                    <a onClick={this.handleCrumbClickAtIndex} data-index={i} href="#">
                        {this.props.getCrumbsAtIndex(i)}
                    </a>
                </li>
            );
        }
        return (
            <nav className='SSbreadcrumb'>
                <ol className="breadcrumb">
                    {crumbs}
                </ol>
            </nav>
        )
    }
}
