import queryString from 'query-string';

import * as AppConstants from './constant';

export const getNetworkInsights = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_NETWORK_INSIGHTS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case AppConstants.GET_NETWORK_INSIGHTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            param: action.param
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case AppConstants.GET_NETWORK_INSIGHTS_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: undefined
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    case AppConstants.GET_NETWORK_INSIGHTS_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: false
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    default:
      return state;
  }
};

export const getSystemInsights = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_SYSTEM_INSIGHTS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case AppConstants.GET_SYSTEM_INSIGHTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            param: action.param
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case AppConstants.GET_SYSTEM_INSIGHTS_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: {
              insights: [
                {
                  id: 2,
                  insight_type: 'threshold',
                  effect_on: [
                    {
                      id: 3,
                      name: 'Effect 2',
                      network_insight_definition: 2
                    },
                    {
                      id: 2,
                      name: 'Effect 1',
                      network_insight_definition: 2
                    }
                  ],
                  created_at: '2024-10-10T07:33:07.058925Z',
                  updated_at: '2024-10-10T12:29:14.344887Z',
                  deleted_at: null,
                  name: 'Psb 7 threshold based insight',
                  title: null,
                  description: 'Psb 7 threshold based insight',
                  active: true,
                  impact_details: 'No Impact',
                  cause_details: 'No Caused',
                  recommendation: '<p>Jay Shree Ram</p>',
                  meta: {
                    custom_mode: 'yes',
                    monitor_window: 900,
                    lower_threshold: '@min',
                    upper_threshold: '@max'
                  },
                  is_critical: true,
                  parent_node: true,
                  main_node: true,
                  child_node: true,
                  network_type: 1,
                  algo_id: 10,
                  reading_type: 2002,
                  created_by: 173,
                  updated_by: 173
                },
                {
                  id: 4,
                  insight_type: 'threshold',
                  effect_on: [
                    {
                      id: 5,
                      name: 'asbc',
                      network_insight_definition: 4
                    }
                  ],
                  created_at: '2024-10-10T07:37:36.906151Z',
                  updated_at: '2024-10-10T12:29:58.152767Z',
                  deleted_at: null,
                  name: 'Upper Threshold PSB 7',
                  title: null,
                  description: 'Upper Threshold PSB 7',
                  active: true,
                  impact_details: 'No Impact',
                  cause_details: 'No Caused',
                  recommendation: '<p>sddffde</p>',
                  meta: {
                    custom_mode: 'yes',
                    monitor_window: 900,
                    upper_threshold: '@max'
                  },
                  is_critical: true,
                  parent_node: true,
                  main_node: true,
                  child_node: true,
                  network_type: 1,
                  algo_id: 10,
                  reading_type: 2002,
                  created_by: 173,
                  updated_by: 173
                }
              ]
            }
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    case AppConstants.GET_SYSTEM_INSIGHTS_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: false
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    default:
      return state;
  }
};
