import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { httpMethodGetJSON } from '../../../../https';
import { AIR_POLLUTION_DATA, WEATHER_DATA } from '../widgetConstants';
import { URL } from '../../../../constants';

const AIR_POLLUTION_URL = `${URL}/reading/reading_series/`;
const WEATHER_URL = `${URL}/weather_data/`

export const getAirPollutionData = (action$) =>
  action$.pipe(
    ofType(AIR_POLLUTION_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, AIR_POLLUTION_URL);
    }),
  );

export const getWeatherData = (action$)=>  
  action$.pipe(
    ofType(WEATHER_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, WEATHER_URL);
    }),
  );

export const weatherWidgetEpics = {
  getAirPollutionData,
  getWeatherData
};