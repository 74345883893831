import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon, Table, DateTimeRangePicker } from '../../../../../common/components';
import TableHeaderCell from '../../../../../common/components/Table/ColumnCell';
import { FORMAT } from '../../../constants';
import DateTime from '../../../../../common/utils/dateTimeUtils';
import { getWidgetHistory } from "../../action";
import { ICON_POSITION } from '../../../../../common/components/Icon/constants';
import { getUserName, getUserFNameLName } from '../../../utils';
import { ASSETS } from '../../../constants';
const ChevronDown = `${ASSETS}/Icons/Arrows/Chevron-DownSVG.svg`;

const TABLEHEADER = [
    {
        name: "Widget Name",
        key: "widget_name"
    },
    {
        name: "Widget Id",
        key: "id",
    },
    {
        name: "Change By",
        key: "created_by",
    },
    {
        name: "Change Data & Time",
        key: "modified_at"
    },
    {
        name: "Change Description",
        key: "history_change_reason",
    }

];

const FROM_DATE = moment().subtract(7, 'days').format();
const TO_DATE = moment().format();

function widgetConfigHistory(props) {

    let { dashboardId, editWidgetId } = props;
    let urlIds = {
        dashboardId: dashboardId,
        id: editWidgetId,
    }

    const [history, setConfigHistory] = useState([]);
    const [pageNo, setPerPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState(null);
    const [dateRange, setDateRange] = useState([]);

    useEffect(() => {
        if (dateRange && dateRange.length > 0) {
            loadData(moment(dateRange[0]).format(), moment(dateRange[1]).format())
        } else loadData(FROM_DATE, TO_DATE);
    }, []);

    useEffect(() => {
        setConfigHistory(props.getWidgetConfigHistory.data.result);
        if (dateRange && dateRange.length > 0) {
            const filteredHistory = props.getWidgetConfigHistory.data && props.getWidgetConfigHistory.data.result && props.getWidgetConfigHistory.data.result.filter((item) => (moment(item.modified_at).format("YYYY-MM-DD HH:MM") >= dateRange[0] && moment(item.modified_at).format("YYYY-MM-DD HH:MM") <= dateRange[1]));
            setConfigHistory(filteredHistory);
        }

    }, [props.getWidgetConfigHistory.data, dateRange]);


    function loadData(fromdate, todate) {
        let data = {
            fromdate: encodeURIComponent(fromdate),
            todate: encodeURIComponent(todate)
        }
        props.getWidgetHistory({ urlIds, data });
    }

    const getHeaderAtIndex = (index) => {
        return new TableHeaderCell(TABLEHEADER[index].name, TABLEHEADER[index].key, index, TABLEHEADER[index].width);
    };

    const getTableCellAtIndex = (row, colm, value, key) => {

        let cell = '';
        switch (colm) {

            case 0:
                cell = history[row] && history[row].config && history[row].config.widget_name ? history[row].config.widget_name : '-';
                break;
            case 1:
                cell = value;
                break;
            case 2:
                cell = value ? getUserFNameLName(value) : '-';
                break;
            case 3:
                cell = value ? DateTime.format(value, 'MMM DD, YYYY HH:mm') : 'NA';
                break;
            case 4:
                // cell = history[row] && history[row].widget_id && history[row].widget_id.description ? <span title={history[row].widget_id.description}>{history[row].widget_id.description}</span> : '-';

                cell = value ? (
                  <span
                    data-title={value}
                    onMouseOver={() => {
                      document.getElementById(
                        'root'
                      ).style.height = `${document.body.scrollHeight}px`;
                    }}
                    onMouseLeave={() => {
                      document.getElementById('root').style.height = 'unset';
                    }}
                  >
                    <span>{value.split('.')[0]}</span>
                  </span>
                ) : (
                  '-'
                );
                break;

            default:
                cell = '';
                break;
        }

      return (
        <div
          className='configured-widget-history-table-cell'
          ref={(element) => {
            element &&
              new ResizeObserver(() => {
                const width =
                  element.parentNode.getBoundingClientRect().width - 40 || 0;

                element.style.width = `${width}px`;
              }).observe(element);
          }}
        >
          {cell}
        </div>
      );
    }

    const handlePaginationUp = pageIndex => {
        setPerPage(pageIndex);
        let fromdate = encodeURIComponent(moment(dateRange[0]).format());
        let todate = encodeURIComponent(moment(dateRange[1]).format());

        if (dateRange && Array.isArray(dateRange) && dateRange.length > 0 && searchQuery != null) {
            let data = {
                page_no: pageIndex,
                name: searchQuery,
                fromdate,
                todate,
            }
            props.getWidgetHistory({ urlIds, data });
        } else if (dateRange && Array.isArray(dateRange) && dateRange.length > 0) {
            let data = {
                page_no: pageIndex,
                fromdate,
                todate,
            }
            props.getWidgetHistory({ urlIds, data });

        } else if (searchQuery != null) {
            let data = {
                name: searchQuery,
                page_no: pageIndex,
                fromdate: encodeURIComponent(FROM_DATE),
                todate: encodeURIComponent(TO_DATE)
            }
            props.getWidgetHistory({ urlIds, data });

        } else {
            let data = {
                page_no: pageIndex,
                fromdate: encodeURIComponent(FROM_DATE),
                todate: encodeURIComponent(TO_DATE)
            }
            props.getWidgetHistory({ urlIds, data })
        }
    }

    const handleDateRangeChange = (from_date, to_date, preset) => {
        setDateRange([from_date, to_date, preset]);
        setPerPage(1);
        let fromdate = moment(from_date).format();
        let todate = moment(to_date).format();

        if (searchQuery && searchQuery != null) {
            let data = {
                page_no: 1,
                name: searchQuery,
                fromdate: encodeURIComponent(fromdate),
                todate: encodeURIComponent(todate)
            }
            props.getWidgetHistory({ urlIds, data });
        } else loadData(fromdate, todate);
    }

    const getChartDateRangeSelector = () => {
        const dateLabel = dateRange && dateRange.length && DateTime.isValid(dateRange[0]) && DateTime.isValid(dateRange[1]) ? (
            DateTime.format(dateRange[0], 'MMM DD, YYYY HH:mm') + ' - ' +
            DateTime.format(dateRange[1], 'MMM DD, YYYY HH:mm')
        ) : 'Select Date & Time';
        return (
            <DateTimeRangePicker
                preset="multi"
                onDatesChange={handleDateRangeChange}
                getTrigger={() => (
                    <div className={dateRange && dateRange.length > 0 ? "dateFilterContainer dateFilter" : 'dateFilterContainer'}>
                        <div className="preset"> {dateLabel}</div>
                        <Icon
                            size={20}
                            alt=""
                            src={ChevronDown}
                            position={ICON_POSITION.CENTER_RIGHT}
                        />
                    </div>
                )}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                extraParams={{
                    isOutsideRange: rangeDate => rangeDate.isAfter(moment().add(0, 'days'), 'day'),
                }}
                dateTimeContextUpdateOverrideFlag={true}
            />
        );
    }

    const pages = props.getWidgetConfigHistory && props.getWidgetConfigHistory.data && props.getWidgetConfigHistory.data.total_pages;

    return (
        <div className='configured-widget-history'>
            {
                props.getWidgetConfigHistory.success === undefined ?
                    'Loading...'
                    :
                    <div className="logs">
                        {/* <div className='date float-right'>
                            <div className={dateRange && dateRange.length > 0 ? 'chartdatePicker m-l-46' : 'chartdatePicker m-l-63'}>
                                {getChartDateRangeSelector()}
                            </div>
                        </div> */}

                        <Table
                            showPagination={true}
                            data={history}
                            noOfColms={TABLEHEADER.length}
                            getHeaderAtIndex={getHeaderAtIndex}
                            pageSize={history && history.length}
                            getTableCellAtIndex={getTableCellAtIndex}
                            pageNo={pageNo}
                            getDataForPageIndex={handlePaginationUp}
                            noOfPages={pages}
                            filterTable={searchKey => {
                                let data = {
                                    page_no: 1,
                                    name: searchKey,
                                    fromdate: encodeURIComponent(FROM_DATE),
                                    todate: encodeURIComponent(TO_DATE)
                                }
                                props.getWidgetHistory({ urlIds, data });

                                // props.getWidgetHistory({
                                //     dashboardId: dashboardId,
                                //     id: editWidgetId,
                                //     page_no: 1,
                                //     name: searchKey
                                // })
                                setPerPage(1);
                                setSearchQuery(searchKey)
                            }}
                            filterQuery={searchQuery}
                            className='custom-table-3'
                            hideSearch={true}
                        />
                        <div className={`date ${props.className}`}>
                            <div className={dateRange && dateRange.length > 0 ? 'chartdatePicker ' : 'chartdatePicker '}>
                                {getChartDateRangeSelector()}
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        getWidgetConfigHistory: state.getWidgetConfigHistory
    };
};

export default connect(
    mapStateToProps, {
    getWidgetHistory
}
)(widgetConfigHistory);