import React, { useState, useEffect } from 'react';

import paramTypeCollectionGet from '../common/paramTypeCollectionGet';
import tagConfsInputGet from '../common/tagConfsInputGet';
import parameterConfsInputGet from '../common/parameterConfsInputGet';
import formulaConfsInputGet from '../common/formulaConfsInputGet';
import tagConfsOutputGet from '../common/tagConfsOutputGet';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import formulaConfsOutputGet from '../common/formulaConfsOutputGet';
import _ParameterConfig from './_ParameterConfig';

const paramTypeCollection = paramTypeCollectionGet();

const _confGet = (conf) => {
  const { type, paramType, isParamConfigTypeTag, ..._conf } = conf;

  return Object.values(_conf);
};

const tagConfsGet = (conf) => {
  const tagConfs = conf && _confGet(conf);

  const _tagConfs =
    tagConfs &&
    tagConfs.reduce((memo, tagConf) => {
      const value = tagConf.isTag && tagConf;

      return value ? [...(memo || []), value] : memo;
    }, null);

  return tagConfsInputGet(_tagConfs).reduce((memo, tagConf) => {
    return [
      ...memo,
      {
        ...tagConf,
        isTag: true
      }
    ];
  }, []);
};

const parameterConfsGet = (conf) => {
  const parameterConfs = conf && _confGet(conf);

  const _parameterConfs =
    parameterConfs &&
    parameterConfs.reduce((memo, parameterConf) => {
      const value = parameterConf.isNormal && parameterConf;

      return value ? [...(memo || []), value] : memo;
    }, null);

  return parameterConfsInputGet(_parameterConfs).reduce(
    (memo, parameterConf) => {
      return [
        ...memo,
        {
          ...parameterConf,
          isNormal: true
        }
      ];
    },
    []
  );
};

const formulaConfsGet = (conf) => {
  const formulaConfs = conf && _confGet(conf);

  const _formulaConfs =
    formulaConfs &&
    formulaConfs.reduce((memo, formulaConf) => {
      const value =
        formulaConf.isComputed &&
        (() => {
          const { computedFormula, ..._formulaConf } = formulaConf;

          return {
            ..._formulaConf,
            ...computedFormula
          };
        })();

      return value ? [...(memo || []), value] : memo;
    }, null);

  return formulaConfsInputGet(_formulaConfs).reduce((memo, formulaConf) => {
    return [
      ...memo,
      {
        ...formulaConf,
        isComputed: true
      }
    ];
  }, []);
};

const _tagConfsGet = (tagConfs, offset = 0) => {
  const _tagConfs = tagConfsOutputGet(tagConfs);

  return (
    _tagConfs &&
    _tagConfs.reduce((memo, tagConf, index) => {
      return {
        ...memo,
        [index + offset]: {
          ...tagConf,
          isTag: true
        }
      };
    }, {})
  );
};

const _parameterConfsGet = (parameterConfs, offset = 0) => {
  const _parameterConfs = parameterConfsOutputGet(parameterConfs);

  return (
    _parameterConfs &&
    _parameterConfs.reduce((memo, parameterConf, index) => {
      return {
        ...memo,
        [index + offset]: {
          ...parameterConf,
          isNormal: true
        }
      };
    }, {})
  );
};

const _formulaConfsGet = (formulaConfs, offset = 0) => {
  const _formulaConfs = formulaConfsOutputGet(formulaConfs);

  return (
    _formulaConfs &&
    _formulaConfs.reduce((memo, formulaConf, index) => {
      const { formula, equation, ..._formulaConf } = formulaConf;

      return {
        ...memo,
        [index + offset]: {
          ..._formulaConf,
          computedFormula: {
            formula,
            equation
          },
          isComputed: true
        }
      };
    }, {})
  );
};

const isInValidGet = (value) => {
  return Object.values(value).length < 3;
};

const ParametersConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [isParamConfigTypeTag, setIsParamConfigTypeTag] = useState();

  const [paramType, setParamType] = useState();

  const [tagConfs, setTagConfs] = useState();

  const [parameterConfs, setParameterConfs] = useState();

  const [formulaConfs, setFormulaConfs] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams.digitboxConfig &&
        props.widgetParams.digitboxConfig.value;

      setIsParamConfigTypeTag(conf && conf.isParamConfigTypeTag);

      setParamType((conf && conf.paramType) || paramTypeCollection[0]);

      setTagConfs(tagConfsGet(conf));

      setParameterConfs(parameterConfsGet(conf));

      setFormulaConfs(formulaConfsGet(conf));

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const paramTypeActiveIndex = paramTypeCollection.findIndex(
    (_paramType) => _paramType === paramType
  );

  const appInputParamChangedHandle = (object) => {
    const value = object;

    const isInValid = isInValidGet(value);

    props.appInputParamChanged({
      digitboxConfig: {
        value: {
          isParamConfigTypeTag,
          ...value
        },
        isInValid
      }
    });
  };

  const renderParameterConfig = () => {
    return (
      <_ParameterConfig
        isParamConfigTypeTag={isParamConfigTypeTag}
        paramTypeCollection={paramTypeCollection}
        paramTypeActiveIndex={paramTypeActiveIndex}
        tagConfs={tagConfs}
        parameterConfs={parameterConfs}
        formulaConfs={formulaConfs}
        onIsParamConfigTypeTagChange={(isParamConfigTypeTag) => {
          setIsParamConfigTypeTag(isParamConfigTypeTag);

          appInputParamChangedHandle({
            type: props.type,
            isParamConfigTypeTag,
            paramType,
            ..._tagConfsGet(tagConfs, 0),
            ..._parameterConfsGet(parameterConfs, tagConfs.length),
            ..._formulaConfsGet(
              formulaConfs,
              tagConfs.length + parameterConfs.length
            )
          });
        }}
        onParamTypeChange={(_paramType) => {
          setParamType(_paramType);

          appInputParamChangedHandle({
            type: props.type,
            paramType: _paramType,
            ..._tagConfsGet(tagConfs, 0),
            ..._parameterConfsGet(parameterConfs, tagConfs.length),
            ..._formulaConfsGet(
              formulaConfs,
              tagConfs.length + parameterConfs.length
            )
          });
        }}
        onTagConfsChange={(_tagConfs) => {
          setTagConfs(_tagConfs);

          appInputParamChangedHandle({
            type: props.type,
            paramType,
            ..._tagConfsGet(_tagConfs),
            ..._parameterConfsGet(parameterConfs, _tagConfs.length),
            ..._formulaConfsGet(
              formulaConfs,
              _tagConfs.length + parameterConfs.length
            )
          });
        }}
        onParameterConfsChange={(_parameterConfs) => {
          setParameterConfs(_parameterConfs);

          appInputParamChangedHandle({
            type: props.type,
            paramType,
            ..._tagConfsGet(tagConfs, 0),
            ..._parameterConfsGet(_parameterConfs, tagConfs.length),
            ..._formulaConfsGet(
              formulaConfs,
              tagConfs.length + _parameterConfs.length
            )
          });
        }}
        onFormulaConfsChange={(_formulaConfs) => {
          setFormulaConfs(_formulaConfs);

          appInputParamChangedHandle({
            type: props.type,
            paramType,
            ..._tagConfsGet(tagConfs, 0),
            ..._parameterConfsGet(parameterConfs, tagConfs.length),
            ..._formulaConfsGet(
              _formulaConfs,
              tagConfs.length + parameterConfs.length
            )
          });
        }}
      />
    );
  };

  return (
    <div className='content-border-wrap minh100_42'>
      {renderParameterConfig()}
    </div>
  );
};

export default ParametersConfig;
