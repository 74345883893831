import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import plantSensorConfGet from '../common/plantSensorConfGet';
import plantSensorConfsOutputGet from '../common/plantSensorConfsOutputGet';
import valueGet from '../common/valueGet';
import PlantWiseConfiguration from '../Common/PlantWiseConfiguration';
import SensorWiseConfiguration from './SensorWiseConfiguration';
import {
  Button,
  Input,
  RadioButtons,
  CheckBox
} from '../../../../../../../common/components';

const liveTimePeriodDefaultValue = 40;

const sensorWiseLabelCollection = [
  'Plant Wise Configuration',
  'Sensor Wise Configuration'
];

const plantConfsGet = (plantConfs) => {
  const _plantConfs =
    plantConfs &&
    plantConfs.reduce((memo, plantConf) => {
      return !plantConf.sensorwise ? [...(memo || []), plantConf] : memo;
    }, null);

  return _plantConfs ? _plantConfs : [plantSensorConfGet(0)];
};

const sensorConfsGet = (sensorConfs) => {
  const _sensorConfs =
    sensorConfs &&
    sensorConfs.reduce((memo, sensorConf) => {
      return sensorConf.sensorwise ? [...(memo || []), sensorConf] : memo;
    }, null);

  return _sensorConfs ? _sensorConfs : [plantSensorConfGet(1)];
};

const showSummaryTableHandle = (plantSensorConfs, showSummaryTable) => {
  return (
    plantSensorConfs &&
    plantSensorConfs.reduce((memo, plantConf) => {
      return [
        ...memo,
        {
          ...plantConf,
          showSummaryTable
        }
      ];
    }, [])
  );
};

const DateTimeConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [liveTimePeriod, setLiveTimePeriod] = useState();

  const [sensorWise, setSensorWise] = useState();

  const [showSummaryTable, setShowSummaryTable] = useState();

  const [plantConfs, setPlantConfs] = useState();

  const [sensorConfs, setSensorConfs] = useState();

  const sensorWiseIndex = sensorWise ? 1 : 0;

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setLiveTimePeriod(
        conf.live_timeperiod &&
          conf.live_timeperiod.value &&
          !isNaN(Number(conf.live_timeperiod.value))
          ? conf.live_timeperiod.value
          : liveTimePeriodDefaultValue
      );

      const plantSensorConfs = conf.parameters && conf.parameters.value;

      setSensorWise(
        (plantSensorConfs &&
          plantSensorConfs.find(
            (plantSensorConf) => !!plantSensorConf.sensorwise
          )) ||
          false
      );

      setShowSummaryTable(
        (plantSensorConfs &&
          plantSensorConfs.find(
            ({ showSummaryTable }) => !!showSummaryTable
          )) ||
          false
      );

      setPlantConfs(plantConfsGet(plantSensorConfs));

      setSensorConfs(sensorConfsGet(plantSensorConfs));

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      live_timeperiod: valueGet(liveTimePeriod),
      parameters: valueGet(
        showSummaryTableHandle(
          plantSensorConfsOutputGet([...plantConfs, ...sensorConfs]),
          showSummaryTable
        )
      ),
      ...object
    };

    props.appInputParamChanged(value);
  };

  const renderPlantWiseConfiguration = () => {
    return (
      <PlantWiseConfiguration
        plantConfs={plantConfs}
        onPlantConfsChange={(plantConfs) => {
          setPlantConfs(plantConfs);

          appInputParamChangedHandle({
            parameters: valueGet(
              showSummaryTableHandle(
                plantSensorConfsOutputGet([...plantConfs, ...sensorConfs]),
                showSummaryTable
              )
            )
          });
        }}
      />
    );
  };

  const renderSensorWiseConfiguration = () => {
    return (
      <SensorWiseConfiguration
        sensorConfs={sensorConfs}
        onSensorConfsChange={(sensorConfs) => {
          setSensorConfs(sensorConfs);

          appInputParamChangedHandle({
            parameters: valueGet(
              showSummaryTableHandle(
                plantSensorConfsOutputGet([...plantConfs, ...sensorConfs]),
                showSummaryTable
              )
            )
          });
        }}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col>
            <div className='new-input-wrap'>
              <label className='label-text'>Live Time Period</label>

              <Input
                size='sm'
                label=''
                type='number'
                required={false}
                placeholder='Enter Time Period'
                value={liveTimePeriod || ''}
                inputChanged={(liveTimePeriod) => {
                  setLiveTimePeriod(liveTimePeriod);

                  appInputParamChangedHandle({
                    live_timeperiod: valueGet(liveTimePeriod)
                  });
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className='new-input-wrap mb-0'>
              <RadioButtons
                noOfButtons={2}
                getButtonLableAtIndex={(index) => {
                  return sensorWiseLabelCollection[index];
                }}
                buttonClickAtIndex={(index) => {
                  setSensorWise(!!index);
                }}
                active={sensorWiseIndex}
              />
            </div>

            <div className='new-input-wrap d-flex justify-content-end'>
              <div className='new-custom-checkbox'>
                <CheckBox
                  label={'Show Summary Table'}
                  size='sm'
                  checked={showSummaryTable}
                  checkboxClicked={(showSummaryTable) => {
                    setShowSummaryTable(showSummaryTable);

                    appInputParamChangedHandle({
                      parameters: valueGet(
                        showSummaryTableHandle(
                          plantSensorConfsOutputGet([
                            ...plantConfs,
                            ...sensorConfs
                          ]),
                          showSummaryTable
                        )
                      )
                    });
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>

        {sensorWiseIndex === 0
          ? renderPlantWiseConfiguration()
          : renderSensorWiseConfiguration()}

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default DateTimeConfig;
