export default () => {
  return JSON.parse(localStorage.getItem('smartsense.readingtypes')).reduce(
    (memo, { readingtypename, readingtypeid }) => {
      return [
        ...memo,
        {
          label: readingtypename,
          value: readingtypeid
        }
      ];
    },
    []
  );
};
