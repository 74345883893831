export default () => {
  return {
    alias: '',
    index: 0,
    parameter: {
      locationLabel: null,
      locationid: null,
      readingtypeLabel: null,
      readingtypeid: null
    }
  };
};
