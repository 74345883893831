import tagConfInputGet from './tagConfInputGet';

export default (tagConfs) => {
  return tagConfs
    ? tagConfs.reduce((memo, tagConf, index) => {
        const value = {
          ...tagConf,
          index
        };

        return [...memo, value];
      }, [])
    : [tagConfInputGet()];
};
