import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import Config from 'Config';

import { GET_LOGIN_DATA, GET_LOGIN_DATA_SUCCESS, GET_LOGIN_DATA_FAILURE, ERROR, URL } from '../constants';

const getLoginDataSuccess = (payload) => ({ type: GET_LOGIN_DATA_SUCCESS, payload });

const params = {};

const LOGIN_URL = `${Config.api_url}/../../api/auth/login/`;

// const LOGIN_URL = `https://beta-api.smartsen.se/api/auth/login/`;

export const getLoginDataEpic = (action$) =>
  action$.pipe(
    ofType(GET_LOGIN_DATA),
    mergeMap((action) =>
      ajax.post(LOGIN_URL, action.payload).pipe(
        map((response) => getLoginDataSuccess(response)),
        catchError((error) =>
          of(
            {
              type: GET_LOGIN_DATA_FAILURE,
              payload: [],
              error: true,
            },
            { type: ERROR, payload: error },
          ),
        ),
      ),
    ),
  );
