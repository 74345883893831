import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../constants';
import { httpMethodPost } from '../../../https';
import { CREATE_DYNAMIC_VARIABLE_MAPPING } from './constant';

export const createDynamicVariableMapping = (action$) =>
  action$.pipe(
    ofType(CREATE_DYNAMIC_VARIABLE_MAPPING),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/network/dynamic-variable/mapping/`);
    })
  );
