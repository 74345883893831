export const GET_AVERAGE_DEMAND_DATA = 'GET_AVERAGE_DEMAND_DATA';
export const GET_AVERAGE_DEMAND_DATA_SUCCESS = 'GET_AVERAGE_DEMAND_DATA_SUCCESS';
export const GET_AVERAGE_DEMAND_DATA_FAILURE = 'GET_AVERAGE_DEMAND_DATA_FAILURE';

export const GET_DATE_RANGE_DEMAND_DATA = 'GET_DATE_RANGE_DEMAND_DATA';
export const GET_DATE_RANGE_DEMAND_DATA_SUCCESS = 'GET_DATE_RANGE_DEMAND_DATA_SUCCESS';
export const GET_DATE_RANGE_DEMAND_DATA_FAILURE = 'GET_DATE_RANGE_DEMAND_DATA_FAILURE';

export const GET_DATE_RANGE_DEMAND_FORECAST_DATA = 'GET_DATE_RANGE_DEMAND_FORECAST_DATA';
export const GET_DATE_RANGE_DEMAND_FORECAST_DATA_SUCCESS = 'GET_DATE_RANGE_DEMAND_FORECAST_DATA_SUCCESS';
export const GET_DATE_RANGE_DEMAND_FORECAST_DATA_FAILURE = 'GET_DATE_RANGE_DEMAND_FORECAST_DATA_FAILURE';

export const GET_DATE_RANGE_DEMAND_FORECAST_DIFF_INIT = 'GET_DATE_RANGE_DEMAND_FORECAST_DIFF_INIT';
export const GET_DATE_RANGE_DEMAND_FORECAST_DIFF_DATA_SUCCESS = 'GET_DATE_RANGE_DEMAND_FORECAST_DIFF_DATA_SUCCESS';
export const GET_DATE_RANGE_DEMAND_FORECAST_DIFF_DATA_FAILURE = 'GET_DATE_RANGE_DEMAND_FORECAST_DIFF_DATA_FAILURE';

export const GET_DEMAND_ANALYSIS_CHART = 'GET_DEMAND_ANALYSIS_CHART';
export const GET_DEMAND_ANALYSIS_CHART_SUCCESS = 'GET_DEMAND_ANALYSIS_CHART_SUCCESS';
export const GET_DEMAND_ANALYSIS_CHART_FAILURE = 'GET_DEMAND_ANALYSIS_CHART_FAILURE';

export const GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_INIT = 'GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_INIT';
export const GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_RESULT = 'GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_RESULT';
export const GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_RESULT_FAILURE = 'GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_RESULT_FAILURE';
