export default (plantid) => {
  return JSON.parse(localStorage.getItem('smartsense.sensors')).reduce(
    (memo, { name, sensorid, messageversion, mappedlocationid }) => {
      const location =
        plantid &&
        JSON.parse(localStorage.getItem('smartsense.locationlist')).find(
          ({ locationid }) => {
            return locationid === mappedlocationid;
          }
        );

      switch (true) {
        case !plantid:
        case !!location && location.plant === plantid:
          return [
            ...memo,
            {
              label: name,
              value: sensorid,
              messageversion
            }
          ];

        default:
          return memo;
      }
    },
    []
  );
};
