import {combineReducers} from 'redux'
import {
    getMemberDataReducer,
    getRoleDataReducer,
    createRoleReducer,
    createMemberReducer,
    editRoleReducer,
    editMemberReducer,
    deleteRoleReducer,
    deleteMemberReducer
} from './UserRole/reducer'
import {
    getStatsDataReducer,
    getAssetsDataReducer,
    getTimeLineDataReducer,
    getOldDataReducer,
    getAllAssetsForDropdownReducer,
    getAssetInsightListReducer
} from './AssetInsights/reducer'
import {
    getAssetsDetailsDataReducer,
    getAssetsListDataReducer,
    getAssetsActionDataReducer,
    postAssetsActionDataReducer,
    getAssetsAlertDataReducer,
    postAlertActionDataReducer,
    getAssetsOpenInsightsDataReducer,
    getAssetsHeathDataReducer
} from './AssetDetails/reducer'
import {
    getRefreshDataReducer,
    getMemberProfileDataReducer,
    getReadingDataReducer,
    getComputedReadingDataReducer,
    getFaultReadingDataReducer,
    rightSideDrawerReducer,
    getCalculatedParameterReducer,
} from './Main/reducer'
import {errorReducer, successReducer} from './Toaster/reducer'
import {common} from '../../common/container/reducers';
import {widgetEpics} from './Widgets/reducer';
import {getBillingMeterDataReducer} from './EnterpriseInsights/reducer';
import { dashboardReducer } from "./Dashboard/reducer";
import { widgetsReducer } from './Dashboard/components/Widgets/reducer'
import { alertReducer } from './Alert/reducer';
import { AppExpertReducer } from './AppExpert/reducer'
import { reportReducer } from './Report/reducer';
import { eventReducer } from './Event/reducer';
import { dataUploadReducers } from './DataUpload/reducer';
import { notificationReducer } from './Notification/reducer';
import { userManagementReducer } from './UserManagement/reducer';
import { partnerLoginReducer } from './PartnerLogin/reducer';
import { screenDesignReducer } from './AssetScreenDesign/reducer';
import { assetInsightsReducer } from './AssetInsights/reducer';
import { userProfileReducer } from './UserProfile/reducer';
import { getEnergyData } from './Energy/reducer'
import {ghgEmissionsReducers} from './Co2Emission/reducer'
import { getWhatIfData } from './WhatIf/reducer';
import { getLiveData } from './LiveData/reducer';
import { getReportDownloadData } from './ReportDownload/reducer';
import { weatherReducer } from './Dashboard/components/Widgets/WeatherForecast/reducer';
import {
  getDashboardSidebarAllDashboard,
	getDashboardSidebarDashboardSequence
} from '../../common/components/Slider/reducer';
import { calculatedParameter } from './CalculatedParameter/reducer';

export const rootReducer = combineReducers({
    ...common,
    ...widgetEpics,
    ...dashboardReducer,
    ...widgetsReducer,
    ...alertReducer,
    ...AppExpertReducer,
    ...reportReducer,
    ...eventReducer,
    ...dataUploadReducers,
    ...notificationReducer,
    ...userManagementReducer,
    ...partnerLoginReducer,
    ...screenDesignReducer,
    ...assetInsightsReducer,
    ...userProfileReducer,
    ...ghgEmissionsReducers,
    ...weatherReducer,
    ...calculatedParameter,
    getBillingMeterData: getBillingMeterDataReducer,
    getMemberDetails: getMemberDataReducer,
    getMemberProfileDetails: getMemberProfileDataReducer,
    getRoleDetails: getRoleDataReducer,
    createRole: createRoleReducer,
    createMember: createMemberReducer,
    editRole: editRoleReducer,
    editMember: editMemberReducer,
    deleteRole: deleteRoleReducer,
    deleteMember: deleteMemberReducer,
    getRefreshData: getRefreshDataReducer,
    getStatsData: getStatsDataReducer,
    getAssetsData: getAssetsDataReducer,
    getTimeLineData: getTimeLineDataReducer,
    getAssetsDetailsData: getAssetsDetailsDataReducer,
    getAssetsOpenInsightsData: getAssetsOpenInsightsDataReducer,
    getAssetsListData: getAssetsListDataReducer,
    getAssetsActionData: getAssetsActionDataReducer,
    postAssetsActionData: postAssetsActionDataReducer,
    postAlertActionDataReducer: postAlertActionDataReducer,
    getAssetsAlertData: getAssetsAlertDataReducer,
    getOldData: getOldDataReducer,
    getAllAssetsForDropdown: getAllAssetsForDropdownReducer,
    getReadingData: getReadingDataReducer,
    getFaultSeriesData: getFaultReadingDataReducer,
    getComputedReadingData: getComputedReadingDataReducer,
    getAssetsHeathData: getAssetsHeathDataReducer,
    error: errorReducer,
    success: successReducer,
    rightSideDrawer: rightSideDrawerReducer,
    calculaterParameter: getCalculatedParameterReducer,
    energyData: getEnergyData,
    whatIfData: getWhatIfData,
    liveData: getLiveData,
    downloadReport: getReportDownloadData,
	dashboardSidebarAllDashboard: getDashboardSidebarAllDashboard,
	dashboardSidebarDashboardSequence: getDashboardSidebarDashboardSequence
})