import React, { useRef, useMemo, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { makeServiceDefault } from '../AssetScreenDesign/action';
import {
  getAllWidgetConfig,
  getCustomDashboard
  //getDashboardSequence,
  //addDashboard,
  //createDashboardGlobalResetAction,
  //getAllDashboard,
  //resetAddDashboard,
  //resetSortDashboard,
  //sortAllDashboardState,
  //sortDashboard,
  //getCustomDashboard,
  //getDashboardConfigHistory,
} from '../Dashboard/action';
import { mergeConfig } from '../Dashboard/widgetsConfig';
import { Icon, Button, Tabs } from '../../../common/components/';
import { ASSETS } from '../constants';
import { ICON_POSITION } from '../../../common/components/Icon/constants';
import { DateTimeContext } from '../Main/context';
import DateTime from '../../../common/utils/dateTimeUtils';
//import WidgetCalenderConfig from '../Widgets/CommonControls/calendar';
import MemoizedDashboard from '../Dashboard/MemoizedDashboard';
const leftArrow = `${ASSETS}/UserRole/b-leftSVG.svg`;
const rightArrow = `${ASSETS}/UserRole/b-rightSVG.svg`;
import './Workplace.scss';
//import { getServiceByName } from '../../../common/utils/services';
//import moment from 'moment';
//import { getRefreshAction } from '../actions';
//import _ from 'lodash';
//import { UserContext, DateTimeContext, Context } from '../Main/context';
//import WidgetSelector from '../Dashboard/components/WidgetSelector';
//import DashboardConfigHistory from '../Dashboard/components/DashboardConfigHistory';

//const PAGE_SIZE = 20;

//let sideBarState = false;

//const useQuery = () => {
//return new URLSearchParams(useLocation().search);
//};

//const getActiveDashboardId = () => {
//const query = useQuery().get('id');
//return query;
//};

//const getActiveDashboardIndex = (dashboards = []) => {
//const query = useQuery().get('id');
//const index = dashboards.findIndex(
//({ dashboard_id }) => dashboard_id === query
//);

//return index >= 0 ? index : 0;
//};

const localStorageServiceCollectionGet = () => {
  return (
    localStorage.getItem('smartsense.services') &&
    JSON.parse(localStorage.getItem('smartsense.services'))
  );
};

const localStorageServiceIdGet = (_name) => {
  const localStorageService =
    localStorageServiceCollectionGet() &&
    localStorageServiceCollectionGet().find(({ name }) => name === _name);

  return localStorageService && localStorageService.id;
};

const localStorageServiceDashboardIdGet = () => {
  return localStorageServiceIdGet('Workplace');
};

const Workplace = (_props) => {
  const {
    getAllWidgetConfig,
    makeServiceDefault,
    getCustomDashboard,
    ...props
  } = _props;

  const configDateRangeRef = useRef();

  const sequenceData = useMemo(() => {
    return [
      ...(props.serviceDefaultData || []).map((_serviceDefaultData) => ({
        ..._serviceDefaultData,
        id: _serviceDefaultData.dashboard_id
      })),
      ...(props.customDashboardData || [])
    ];
  }, [props.serviceDefaultData, props.customDashboardData]);

  const location = useLocation();

  const activeTabId = new URLSearchParams(location.search).get('id');

  const dateTimeContext = useContext(DateTimeContext);

  const activeTabIndex = sequenceData.findIndex(({ id }) => id === activeTabId);

  const memberDetails = localStorage.getItem('smartsense.member') && JSON.parse(localStorage.getItem('smartsense.member'));
  let dashboardAlignmentFlag = memberDetails.dashboard_align === "vertical";
  let toCheckDashboardAlign = dashboardAlignmentFlag === false ? `appWrapper dashboard workplace-horizontal-alignment` : `appWrapper dashboard`
 
  //const [sequenceData, setSequenceData] = useState([]);

  //const [showSidebar, setShowSidebar] = useState(false);

  //const activeTabIndex = getActiveDashboardIndex(props.sustainabilityData);
  //const userContext = useContext(UserContext);
  //const context = useContext(Context);
  //const [page_no, setPageNo] = useState(1);
  //const [showpop, setShowPop] = useState(true);
  //const [createDashboardForm, setCreateDashboardForm] = useState(
  //getInputFields()
  //);
  //const [selectedTab, setSelectedTab] = useState(0);
  //const [activeTabId, setActiveTabId] = useState(getActiveDashboardId());
  //const [selectedDash, setSelectedDash] = useState();
  //const [isOpen, setModal] = useState(false);
  //const [data, setData] = useState([]);
  //const retrievedData = localStorage.getItem('smartsense.services');
  //const services = JSON.parse(retrievedData);
  //const filteredService = services.filter(
  //(item) => item.name == 'Sustainability'
  //);

  //function getInputFields() {
  //return [
  //{
  //name: 'dashboard_name',
  //placeholder: 'Enter Dashboard Name',
  //value: '',
  //type: 'text',
  //label: 'Dashboard Name',
  //required: true,
  //width: 8,
  //isInValid: false
  //}
  //];
  //}

  const selectedDash = sequenceData.find(({ id }) => id === activeTabId);

  useEffect(() => {
    const config = selectedDash?.config;

    const currentDay = moment().date();

    const selectedDateRange =
      config?.startOption === '4 Days' &&
      currentDay <= 4 &&
      config?.addException
        ? config?.nestedDateRange
        : config?.dateRange || 'Current Month';

    if (selectedDateRange && configDateRangeRef.current !== selectedDateRange) {
      configDateRangeRef.current = selectedDateRange;
      dateTimeContext.handleDate(
        ...DateTime.dashoardDateRangeGet(selectedDateRange)
      );
    }
  }, [selectedDash]);

  useEffect(() => {
    // route change causes component to unmount and mount
    //  again which causes to load dashabords api again.
    //var storedServices = JSON.parse(
    //localStorage.getItem('smartsense.services')
    //);
    //var sustainability = storedServices.filter(
    //(item) => item.name == 'Sustainability'
    //);
    //props.makeServiceDefault({
    //service_id: sustainability[0] && sustainability[0].id
    //});
    //if (props.dashboardData.length === 0) {
    //props.getAllDashboard({
    //page_no: 1,
    //page_size: PAGE_SIZE
    //});
    //}
    getAllWidgetConfig({
      service_id: localStorageServiceIdGet('My Dashboard')
    });

    makeServiceDefault({
      service_id: localStorageServiceDashboardIdGet()
    });

    getCustomDashboard(localStorageServiceDashboardIdGet());
  }, [getAllWidgetConfig, makeServiceDefault, getCustomDashboard]);

  //useEffect(() => {
  //if (props.dashboardSequenceData && props.dashboardSequenceData.config) {
  //setSequenceData(props.dashboardSequenceData.config);
  //}
  //}, [props.dashboardSequenceData]);

  //useEffect(() => {
  //props.getCustomDashboard(filteredService[0].id);
  //}, []);

  //useEffect(() => {
  //const concernedElement = document.querySelector('.sustainability-selector');
  //const selectorFn = (event) => {
  //if (concernedElement.contains(event.target)) {
  //if (window.location.pathname.includes('Sustainability')) {
  //// const sustainabilitySidebar = document.querySelector(".sustainability-sidebarWrap")
  //toggleSidebar(!sideBarState);
  //}
  //} else {
  //const concernedSideBar = document.querySelector(
  //'.sustainability-sidebarWrap'
  //);
  //if (concernedSideBar && !concernedSideBar.contains(event.target)) {
  //toggleSidebar(false);
  //}
  //}
  //};
  //document.addEventListener('click', selectorFn);
  //return () => document.removeEventListener('click', selectorFn);
  //}, []);

  //function isOverflown() {
  //var elem = document.getElementById('dashboardAppWrapper');
  //if (!elem) return;
  //let tabsContainer = elem.getElementsByClassName('SSTabsHeader');

  //if (tabsContainer && tabsContainer[0]) {
  //return tabsContainer[0].scrollWidth > tabsContainer[0].clientWidth;
  //}
  //}

  //function scrollLeft() {
  //var elem = document.getElementById('dashboardAppWrapper');
  //let tabsContainer = elem.getElementsByClassName('SSTabsHeader');

  //if (tabsContainer && tabsContainer[0]) {
  //tabsContainer[0].scrollLeft -= 200;
  //}
  //}

  //function scrollRight() {
  //var elem = document.getElementById('dashboardAppWrapper');
  //let tabsContainer = elem.getElementsByClassName('SSTabsHeader');

  //if (tabsContainer && tabsContainer[0]) {
  //tabsContainer[0].scrollLeft += 200;
  //}
  //}

  function reloadDashboard() {
    //setPageNo(1);
    //props.getCustomDashboard(filteredService[0].id);
    props.history.push('/Sustainability/');
  }

  // useEffect(() => {
  //   if (props.deleteDashboardData.success === true && showpop) {
  //     // props.resetDeleteDashboard();
  //     // context.notify.success('Dashboard Deleted');
  //     setShowPop(false);
  //     reloadDashboard();
  //   }
  // }, [props.deleteDashboardData]);

  //useEffect(() => {
  //if (
  //(props.sustainabilityData.length || props.getCustomData) &&
  //props.loading
  //) {
  //let customData = props.sustainabilityData;
  //if (props.getCustomData.length) {
  //props.getCustomData.map((data) => {
  //customData.push({
  //...data,
  //dashboard_id: data.id,
  //dashboard_name: data.name
  //});
  //});
  //}
  //setData([...customData]);
  //}
  //}, [props.sustainabilityData, props.getCustomData]);

  //function getEmptyDashboardScreen() {
  //return (
  //<div className='empty-dashboard'>
  //<h1>Create your first dashboard</h1>
  //<div className='empty-dashboard-loading-container'>
  //<div className='empty-dashboard-loading'></div>
  //<div className='empty-dashboard-loading'></div>
  //<div className='empty-dashboard-loading'></div>
  //</div>
  //<div className='create-dashboard-button'>
  //<Button
  //buttonType='primary'
  //buttonClick={createDashboard}
  //size='lg'
  //innerContent='Create Dashboard'
  ///>
  //</div>
  //</div>
  //);
  //}

  const getTabContentAtIndex = (i) => {
    let dashboard = sequenceData[i];
    //setSelectedDash(dashboard);
    return (
      <MemoizedDashboard
        dashboard={dashboard}
        allWidgetConfig={mergeConfig(props.allWidgetConfig.data)}
        reloadDashboard={reloadDashboard}
        showDateToggle={false}
      />
    );
  };

  const getTabHeaderAtIndex = (i) => {
    return (
      <div className='dashboard-tab-header'>
        {sequenceData[i] && sequenceData[i].dashboard_name
          ? sequenceData[i].dashboard_name
          : 'No Name'}
      </div>
    );
  };

  //const sortDashboard = (src, trgt) => {
  //const order = {};
  //props.dashboardData.map((db, i) => {
  //if (i == src) {
  //order[db.id] = Number(trgt);
  //return;
  //}

  //if (i == trgt) {
  //order[db.id] = Number(src);
  //return;
  //}

  //order[db.id] = db.sequence;
  //});

  //props.sortAllDashboardState(order);
  //props.sortDashboard({ order });
  //};

  //function createDashboard() {
  //if (userContext.permission(DASHBOARD.CREATE_DASHBOARD)) {
  //let oldState = [...createDashboardForm];
  //oldState[0].value = `Dashboard ${
  //props.total_record ? props.total_record : ''
  //}`;
  //setCreateDashboardForm(oldState);
  //setModal(true);
  //} else context.notify.error(NOT_ACCESS_MESSAGE);
  //}

  //const toggleSidebar = () => {
  //setShowSidebar(!showSidebar);
  //sideBarState = value;
  //};

  //function reloadSustainability() {
  //setPageNo(1);
  ////props.getCustomDashboard(filteredService[0].id);
  //props.history.push('/Sustainability/');
  //}

  //const tabChange = (id) => {
  //setActiveTabId(id);

  //if (id !== activeTabId) {
  //history.push(`/Sustainability/?id=${id}`);
  //}
  //};

	const dashboardTabsScrollButtonRender = () => {
    return (
      !props.loading && (
        <React.Fragment>
          {sequenceData && sequenceData.length > 0 ? (
            <React.Fragment>
              {/* Tab Scroll Buttons */}
              <div className='dashboard-tabs-scroll-button left-button' xl={6}>
                <Button
                  buttonType='secondory'
                  size='md'
                  //buttonClick={scrollLeft}
                  innerContent={
                    <span>
                      {
                        <Icon
                          size={13}
                          alt=''
                          src={leftArrow}
                          position={ICON_POSITION.CENTER_CENTER}
                        />
                      }
                    </span>
                  }
                />
              </div>
              <div className='dashboard-tabs-scroll-button right-button' xl={6}>
                <Button
                  buttonType='secondory'
                  size='md'
                  //buttonClick={scrollRight}
                  innerContent={
                    <span>
                      {
                        <Icon
                          size={12}
                          alt=''
                          src={rightArrow}
                          position={ICON_POSITION.CENTER_CENTER}
                        />
                      }
                    </span>
                  }
                />
              </div>
              {/* Tab Scroll Buttons */}
            </React.Fragment>
          ) : (
            <p>Loading</p>
          )}
        </React.Fragment>
      )
    );
  };

  return (
    <div
      //className={`appWrapper dashboard ${showSidebar && 'sidebarActive'}`}
      className={toCheckDashboardAlign}
      id='dashboardAppWrapper'
    >
      {/*<div className='sidebarWrap sustainability-sidebarWrap'>
        <Sidebar
          allWidgetConfig={props.allWidgetConfig}
          onShowDashboardSidebarToggle={() => {
            context.onSliderChangeHandle({
              sustainabilitySidebarShow:
                !context.slider.sustainabilitySidebarShow
            });
          }}
          //dropdownData={props.loading && data}
          //sidebarName={'Sustainability'}
          //activeTabIndex={selectedTab}
          //showSidebar={showSidebar}
          //activeTabId={activeTabId}
          //loading={props.loading}
          //setSelectedTab={setSelectedTab}
          //serviceId={
          //getServiceByName('Sustainability') &&
          //getServiceByName('Sustainability').id
          //}
          // getContent={getTabContentAtIndex}
          //pathname={props.history.location.pathname.split('/')}
          //history={props.history}
          //tabChange={tabChange}
          //reloadDashboard={reloadDashboard}
        />
			</div>*/}
      {/* <div className="appWrapper dashboard" id="dashboardAppWrapper"> */}
      {/* <PerformanceOptimize allWidgets={props.widgetConfig} /> */}
      <div className='dashboard-content-wrap'>
        <div
          className={` ${
            //isOverflown() ? 'dashboard-tabs-overflow' : 'overflow-no'
            'overflow-no'
          }`}
        >
          {/*{(() => {
            const selectedDash = sequenceData.find(
              ({ id }) => id === activeTabId
            );

            return selectedDash && selectedDash.config &&
              selectedDash.config.showDate &&
              (typeof selectedDash.config.showDateToggle === 'boolean'
                ? !selectedDash.config.showDateToggle
                : true) ? (
              <WidgetCalenderConfig
                handleDateRangeChange={dateTimeContext.handleDate}
                datePreset={dateTimeContext.startDate}
                startDate={dateTimeContext.startDate}
                endDate={dateTimeContext.endDate}
              />
            ) : null;
          })()}*/}
          {/*{dashboardTabsScrollButtonRender()}*/}

          {!props.loading && (
            <Tabs
              onScroll={() => {
                //let $elem = e.currentTarget;
                //let newScrollLeft = $elem.scrollLeft,
                //width = $elem.offsetWidth + 10,
                //scrollWidth = $elem.scrollWidth;
                //if (width + newScrollLeft > scrollWidth) {
                //let nextPage = page_no + 1;
                //if (nextPage > props.total_pages) return;
                //setPageNo(nextPage);
                //props.getAllDashboard({
                //page_no: nextPage,
                //page_size: PAGE_SIZE
                //});
                //}
              }}
              // tabClickedAtIndex = {(i)=>{
              // let dashboard = data[i];
              // const { dashboard_id } = dashboard;

              // if(i !== activeTabIndex) {
              //     history.push(`/Sustainability/?id=${dashboard_id}`);
              // }
              // }}
              noJustified={true}
              draggable={true}
              // key={activeTabIndex}
              noOfTabs={sequenceData.length}
              size='md'
              getTabContentAtIndex={getTabContentAtIndex}
              //swappedTabsAtIndex={sortDashboard}
              getTabHeaderAtIndex={getTabHeaderAtIndex}
              // activeTabIndex={
              // activeTabIndex > 0 && activeTabIndex < props.sustainabilityData.length ?
              //     parseInt(activeTabIndex) :
              //     0
              // }
              activeTabIndex={activeTabIndex}
              hideHead={true}
            />
          )}

          {props.loading && <p>Loading ....</p>}
        </div>
      </div>
    </div>
    // </div>
  );
};

const mapStateToProps = (state) => {
  // main required apis
  //let loadingDashboard = state.allDashboards.success !== undefined;
  //let loadingCustomDashboard = state.getCustomDashboard.success !== undefined;
  //loadingDashboard && loadingWidgetConfig && loadingCustomDashboard;

  //let storedServices = JSON.parse(localStorage.getItem('smartsense.services'));
  //let sustainability = storedServices.find(
  //(item) => item.name == 'Sustainability'
  //);
  //let sustainabilityData =
  //state.makeServiceDefault.data &&
  //state.makeServiceDefault.data[sustainability && sustainability.id] &&
  //state.makeServiceDefault.data[sustainability.id].result
  //? state.makeServiceDefault.data[sustainability.id].result.sort((a, b) => {
  //return a.sequence - b.sequence;
  //})
  //: [];

  //let dashboardData =
  //state.allDashboards.data && state.allDashboards.data.result
  //? state.allDashboards.data.result.sort((a, b) => {
  //return a.sequence - b.sequence;
  //})
  //: [];
  //let serviceId =
  //getServiceByName('Sustainability') && getServiceByName('Sustainability').id;

  //let dashboardSequenceData =
  //state.getDashboardSequence.data &&
  //state.getDashboardSequence.data[localStorageServiceSustainablityId] &&
  //state.getDashboardSequence.data[localStorageServiceSustainablityId];

  let loadingWidgetConfig = state.allWidgetConfig.loading;
  const dashboardSequenceLoader = state.getDashboardSequence.loading;
  let loading = loadingWidgetConfig || dashboardSequenceLoader;

  return {
    //dashboardData: _.compact(dashboardData),
    //deleteDashboardData: state.deleteDashboard,
    //dashboardConfigHistoryData: state.getDashboardConfigHistory,
    //getCustomData:
    //state.getCustomDashboard.data && state.getCustomDashboard.data.result,
    //sustainabilityData: _.compact(sustainabilityData),
    //total_pages:
    //state.makeServiceDefault.data &&
    //state.makeServiceDefault.data[sustainability.id] &&
    //state.makeServiceDefault.data[sustainability.id].total_pages,
    //total_record:
    //state.makeServiceDefault.data &&
    //state.makeServiceDefault.data[sustainability.id] &&
    //state.makeServiceDefault.data[sustainability.id].total_record,
    //addDashboardData: state.addDashboard,
    //createDashboardGlobalAction: state.createDashboardGlobalAction,
    //authToken: state.login.data,
    //sortDashboardData: state.sortDashboard,
    //dashboardSequenceData: dashboardSequenceData
    loading: loading,
    allWidgetConfig: state.allWidgetConfig,
    widgetConfig: mergeConfig(state.allWidgetConfig.data),
    serviceDefaultData:
      state.makeServiceDefault.data[localStorageServiceDashboardIdGet()] &&
      state.makeServiceDefault.data[localStorageServiceDashboardIdGet()].result,
    customDashboardData:
      state.getCustomDashboard.data[localStorageServiceDashboardIdGet()] &&
      state.getCustomDashboard.data[localStorageServiceDashboardIdGet()].result
  };
};

export default connect(mapStateToProps, {
  getAllWidgetConfig,
  makeServiceDefault,
  getCustomDashboard
  //getDashboardSequence,
  //getAllDashboard,
  //addDashboard,
  //createDashboardGlobalResetAction,
  //getRefreshAction,
  //resetAddDashboard,
  //sortDashboard,
  //resetSortDashboard,
  //sortAllDashboardState,
  //makeServiceDefault,
  //getCustomDashboard,
  //getDashboardConfigHistory
})(Workplace);