import React from 'react';
import { Input } from '../../../../../common/components/';

function NumericInput(props) {

  return(<Input
    value={props.value + ''}
    label={props.label}
    placeholder={props.placeholder}
    type="number"
    width={props.width}
    step={props.step}
    min={props.min}
    hint={props.hint}
    required={props.required}
    inputChanged={(e, isInValid) => {
      props.inputChanged(Number(e), isInValid)
    }}
  />)
}

export default NumericInput;