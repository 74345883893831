export const GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD =
  'GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD';

export const GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD_SUCCESS =
  'GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD_SUCCESS';

export const GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD_FAILURE =
  'GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD_FAILURE';

export const GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE =
  'GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE';

export const GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE_SUCCESS =
  'GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE_SUCCESS';

export const GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE_FAILURE =
  'GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE_FAILURE';