import React, { Suspense, useState, useContext, Fragment, useEffect } from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";
import { useLocation, useParams } from "react-router-dom";
import htmlToCanvas from '../../utils/Export';
import { Context, UserContext } from '../../../admin/container/Main/context';
import WidgetLayout from '../../../admin/container/Dashboard/components/WidgetLayout';
import MemoizedWidget from '../../../admin/container/Dashboard/components/DashboardContainer/MemoizedWidget';
import DateTime from '../../utils/dateTimeUtils';
import { ErrorBoundary, DropDown, SkeletonLoading } from '../../../common/components';
import { DEFAULT_WIDGET_ACTIONS, DEFAULT_WIDGET_SUB_ACTIONS } from '../../../admin/container/Dashboard/widgetsConfig';
import { NOT_ACCESS_MESSAGE } from '../../../admin/container/Main/appSetting';
import { TEMPALTES } from '../../../admin/container/Main/permissionContants';
export const DASHBOARD_ROW_HEIGHT = 125;
export const MIN_DASHBOARD_WIDGET_HEIGHT = 2;
import { withRouter } from 'react-router-dom';
const WIDGET_OPTIONS = ["Energy", "Factories", "GHG Emissions", "What-If Energy", "Energy Consumption Mix", "Parameter Tracking", "Insight-alert-summary", "Alert List", "Live Data", "List View", "Asset History", "Group", "Container", "Asset Health Count", "Asset Detail List", "Asset Insight & Alert", "Asset Event Log", "Weather Forecast", "Workplace Overview", "Workplace Graph", "Project Status", "Network View", "Section", "Cxo Summary", "Smart Info", "Facility", "Scorecard"]
const oHtmlToCanvas = new htmlToCanvas();
const ResponsiveGridLayout = WidthProvider(Responsive);

const localStorageItemGet = (key) => {
  const item = localStorage.getItem(key);

  return item && JSON.parse(item);
};

export const localStorageIsSmeClientGet = () => {
  return ((localStorageItemGet('smartsense.member') || {}).clientid || {})
    .is_sme_client;
};  

const OPTIONS = [
    {
        'key': 'ADD NEW WIDGET',
        'value': <span className='item-container'><i className="fas fa-plus-square"></i>ADD NEW WIDGET</span>,
        optionIndex: 0,
        permission: TEMPALTES.ADD_WIDGET_TO_TEMPLATE
    },
    {
        'key': 'EDIT DASHBOARD',
        'value': <span className='item-container'><i className="fas fa-edit"></i>EDIT DASHBOARD</span>,
        optionIndex: 1,
        permission: TEMPALTES.EDIT_WIDGET_OF_TEMPLATE_BY_ID
    },
];

function SimpleDashboard(props) {
    let { widgetsData, isEdit, isOptions } = props;
    const context = useContext(Context);
    const userContext = useContext(UserContext)
    var [refreshState, setRefreshState] = useState({});

    const [submitFeedbackSuccess, setSubmitFeedbackSuccess] = useState(false);

    useEffect(()=>{
        if(props.location.state && Object.keys(props.location.state).length){
            window.sensorMapping = props.location.state;
        }

    },[props.location.state])
    // create widget context, context keeps widget aware in which context widget is being viewed 
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const widgetContext = {
        assetId: query.get('assetId'),
        assetType: query.get('assetType'),
        assetDetails: props.assetDetails,
    };

    function manualRefreshWidget(widget) {
        refreshState[widget.id] = {
            count: refreshState[widget.id] && (refreshState[widget.id].count) ? refreshState[widget.id].count + 1 : 1,
            time: encodeURIComponent(DateTime.today())
        };
        setRefreshState({ ...refreshState });
    }

    function openTvmode(widget, widgetIndex) {
        let layout = document.querySelectorAll('.simple-dashboard-layout')[0];
        let elem = layout.querySelectorAll(`[widget-id="${widget.id}"]`)[0];

        if (elem) {
            context.fullScreen.open(elem);
        }
    }

    function downloadWidget(widget, widgetIndex) {
        let layout = document.querySelectorAll('.simple-dashboard-layout')[0];
        let elem = layout.querySelectorAll(`[widget-id="${widget.id}"]`)[0];
        let widgetLayout = WIDGET_OPTIONS.includes(widget.widget_id.type)

        if (elem) {
            setTimeout(() => {
                oHtmlToCanvas.downloadPNGImage(elem.querySelectorAll(`.${widgetLayout ?"widgetContent": "dashoard-widget"}`)[0]);
            });
        }
    }

    function cloneWidget(widget, widgetIndex) {
        let params = { ...widget.config };
        params.widget_name = params.widget_name + ' - Copy';

        // remove resize - position info from previous widget
        delete params.resize_position;

        props.cloneWidget({
            "widget_id": widget.widget_id.id,
            "sequence": 1,
            "config": params,
        });
    }

    function deleteWidget(widget, widgetIndex) {
        context.confirm.show('Are you sure you want to delete widget?', () => {
            props.deleteWidget(widget.id);
        });
    }


    function getEditableWidget(widget, i) {
        let config = widget.config;

        return (
            <WidgetLayout
                widgetActions={DEFAULT_WIDGET_ACTIONS}
								widgetSubActions = {DEFAULT_WIDGET_SUB_ACTIONS}
								information={widget.widget_id.description}
                name={widget.config.widget_name}
                params={widget.config.table_widget_params}
                width={widget.config.width}
                widgetId={widget.id}
                description={widget.config.widget_description}
                newWidget={WIDGET_OPTIONS.includes(widget.widget_id.type)}
                editWidget={() => {
                    if (userContext.permission(TEMPALTES.EDIT_WIDGET_OF_TEMPLATE_BY_ID)) {
                        props.editWidget(widget)
                    } else context.notify.error(NOT_ACCESS_MESSAGE)
                }}
                deleteWidget={() => {
                    if (userContext.permission(TEMPALTES.DELETE_WIDGET_OF_TEMPLATE_BY_ID)) {
                        deleteWidget(widget, i)
                    } else context.notify.error(NOT_ACCESS_MESSAGE)
                }}
                cloneWidget={() => {
                    if (userContext.permission(TEMPALTES.ADD_WIDGET_TO_TEMPLATE)) {
                        cloneWidget(widget, i)
                    } else context.notify.error(NOT_ACCESS_MESSAGE)
                }}
                openTvmode={() => { openTvmode(widget, i) }}
                refreshWidget={() => { manualRefreshWidget(widget, i) }}
                downloadWidget={() => { downloadWidget(widget, i) }}
                refreshTime={
                    refreshState[widget.id] && DateTime.isValid(refreshState[widget.id].time) ?
                        DateTime.formatDefault(refreshState[widget.id].time) :
                        DateTime.formatDefault(DateTime.today())
                }
            >
							<Suspense fallback={<SkeletonLoading/>}>
                <MemoizedWidget
                    Widget={widget.view}
                    dashboardAppConfig={config}
                    widgetId={widget.id}
                    dashboardId={props.template_id}
                    refresh={{ count: refreshState[widget.id] && refreshState[widget.id].count }}
                    widgetContext={widgetContext}
                    pathname={props.pathname && (props.pathname[1] == 'Templates' || props.pathname[1] == 'CBM')}
                />
            </Suspense>
            </WidgetLayout>
        )
    }

    function getWidgets(widget, i) {
        let config = widget.config;
        return (
            <React.Fragment>
                {
                    widget && (widget.type === 'List View' || widget.type === 'Insight-alert-summary' || widget.type === 'Live Data' || widget.type === 'Asset History') ? (
                    <WidgetLayout
                    name={widget.config.widget_name}
                    params={widget.config.table_widget_params}
                    width={widget.config.width}
                    widgetId={widget.id}
                    newWidget={WIDGET_OPTIONS.includes(widget.widget_id.type)}
                    description={widget.config.widget_description}
                    refreshWidget={() => { manualRefreshWidget(widget, i) }}
                    refreshTime={
                        refreshState[widget.id] && DateTime.isValid(refreshState[widget.id].time) ?
                            DateTime.formatDefault(refreshState[widget.id].time) :
                            DateTime.formatDefault(DateTime.today())
                    }
                >
											<Suspense fallback={<SkeletonLoading/>}>
                        <MemoizedWidget
                            Widget={widget.view}
                            dashboardAppConfig={config}
                            widgetId={widget.id}
                            dashboardId={props.template_id}
                            refresh={{ count: refreshState[widget.id] && refreshState[widget.id].count }}
                            widgetContext={widgetContext}
                            pathname={props.pathname && (props.pathname[1] == 'Templates' || props.pathname[1] == 'CBM')}
                            submitFeedbackSuccess={submitFeedbackSuccess}
                            onSubmitFeedbackSuccess={(submitFeedbackSuccess) => {
                              setSubmitFeedbackSuccess(submitFeedbackSuccess)
                            }}
                        />
                    </Suspense>
                </WidgetLayout>
                    ) : (
                         <div className={(widget.type == "Container") ? 'dashoard-container-widget' : 'dashoard-widget'} style={{ width: "100%" }} widget-id={widget.id}>
                    <WidgetLayout
                        name={widget.config.widget_name}
                        params={widget.config.table_widget_params}
                        widgetId={widget.id}
                        width={widget.config.width}
                        newWidget={WIDGET_OPTIONS.includes(widget.widget_id.type)}
                        description={widget.config.widget_description}
                        refreshWidget={() => { manualRefreshWidget(widget, i) }}
                        refreshTime={
                            refreshState[widget.id] && DateTime.isValid(refreshState[widget.id].time) ?
                                DateTime.formatDefault(refreshState[widget.id].time) :
                                DateTime.formatDefault(DateTime.today())
                        }
                    >
											<Suspense fallback={<SkeletonLoading/>}>
                            <MemoizedWidget
                                Widget={widget.view}
                                dashboardAppConfig={config}
                                widgetId={widget.id}
                                dashboardId={props.template_id}
                                refresh={{ count: refreshState[widget.id] && refreshState[widget.id].count }}
                                widgetContext={widgetContext}
                                pathname={props.pathname && (props.pathname[1] == 'Templates' || props.pathname[1] == 'CBM')}
                                onSubmitFeedbackSuccess={(submitFeedbackSuccess) => {
                                  setSubmitFeedbackSuccess(submitFeedbackSuccess)
                                }}
                            />
                        </Suspense>
                    </WidgetLayout>
            </div>
                    )
                }
            </React.Fragment>
        )
    }

    function getSimpleDashboard() {
        var layout = { lg: [] };
        let widgetsElem = [];
        let index = widgetsData.length;
        widgetsData.map((widget, i) => {
            if (!widget) return;
            let lastElem = { i: String(widget.id), x: 0, y: Infinity, w: 4, h: 1, minW: 4, maxH: 2 };
            let pos = widget.config && widget.config.resize_position ? { ...widget.config.resize_position, i: String(widget.id) } : lastElem;

            // set widget min width from config
            if (widget.minWidgetWidth) {
                pos.minW = widget.minWidgetWidth;
                pos.w = pos.w < widget.minWidgetWidth ? widget.minWidgetWidth : pos.w
            }

            // set widget min height from config
            if (widget.minWidgetHeight) {
                pos.minH = widget.minWidgetHeight;
                pos.h = pos.h < widget.minWidgetHeight ? widget.minWidgetHeight : pos.h
            }

            // set widget max width from config
            if (widget.maxWidgetWidth) {
                pos.maxW = widget.maxWidgetWidth;
                pos.w = pos.w > widget.maxWidgetWidth ? widget.maxWidgetWidth : pos.w
            }

            // set widget max height from config
            if (widget.maxWidgetHeight) {
                pos.maxH = widget.maxWidgetHeight;
                pos.h = pos.h > widget.maxWidgetHeight ? widget.maxWidgetHeight : pos.h
            }

            layout.lg.push(pos);
            widgetsElem.push(
                <div key={widget.id} widget-id={widget.id} data-id={widget.id}  
                style={widget.type === 'Section'? {zIndex: 1}:{}}
                id={widget.type === 'Factories' ? 0 : index}
                className={"widget-container"}>
                    {isEdit ? getEditableWidget(widget, i) : getWidgets(widget, i)}
                </div>
            );
            index--;

        });

        return (
            <ResponsiveGridLayout
                isDraggable={isEdit ? true : false}
                isResizable={isEdit ? true : false}
                className="simple-dashboard-layout"
                layouts={layout}
                breakpoints={{ lg: 1200 }}
                cols={{ lg: 12 }}
                rowHeight={DASHBOARD_ROW_HEIGHT}
                width={1200}
                margin={[10, 20]}
                onLayoutChange={props.onLayoutChange}
            // compactType='horizontal'
            >
                {
                    widgetsElem
                }
            </ResponsiveGridLayout>
        );
    }

    function getEmptyDashboardScreen() {
        return <div className='empty-dashboard'>
            <h1>Create your template</h1>
            <div className='empty-dashboard-loading-container'>
                <div className='empty-dashboard-loading'></div>
                <div className='empty-dashboard-loading'></div>
                <div className='empty-dashboard-loading'></div>
            </div>
        </div>
    }

    function handleDashboardAction(index, action) {
        if (userContext.permission(action.permission)) {
            switch (action.optionIndex) {
                case 0:
                    props.addWidget();
                    break;
                case 1:
                    props.editDashboard();
                    break;
                case 2:
                    props.setPreviewTemplate();
            }
        } else context.notify.error(NOT_ACCESS_MESSAGE)
    }


    function getOptionsAtGroup(i) {
        // let options = OPTIONS;
        // widgetsData && Array.isArray(widgetsData) && widgetsData.length > 0 ? options.push({
        //     'key': 'PREVIEW DASHBOARD',
        //     'value': <span className='item-container'><i className="fas fa-edit"></i>PREVIEW DASHBOARD</span>
        // }) : options = OPTIONS;
        // let setObj = new Set(); // create key value pair from array of array
        // let result = options.reduce((acc,item)=>{
        //   if(!setObj.has(item.key)){
        //     setObj.add(item.key,item)
        //     acc.push(item)
        //   }
        //   return acc;
        // },[])
        // return result;

        return OPTIONS;
    }

    function renderFloatingActions() {
      return (
        !localStorageIsSmeClientGet() && (
          <DropDown
            text={
              <div className='float-button'>
                <span className='float-button-icon'>
                  <i className='fa fa-list' aria-hidden='true'></i>
                </span>
                <span className='float-button-text'>Options</span>
              </div>
            }
            icon=''
            getOptionsAtGroup={getOptionsAtGroup}
            value='value'
            tooltip=''
            //onClickCallback={handleDashboardAction}
            onClickCallback={(i) =>
              handleDashboardAction(i, getOptionsAtGroup()[i])
            }
            hideCheckBox={true}
            shouldToggleOnclick={true}
          />
        )
      );
    }

    return <Fragment>
        {isOptions && Array.isArray(widgetsData) && widgetsData.length == 0 && getEmptyDashboardScreen()}
        {widgetsData && Array.isArray(widgetsData) && widgetsData.length > 0 ? getSimpleDashboard() : !isOptions && ''}
        {
            isOptions && <div className='dashboard-floating-actions simple'>
                {renderFloatingActions()}
            </div>
        }
    </Fragment>

}


export default withRouter(SimpleDashboard);