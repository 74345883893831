export default (tagConfs) => {
  return (
    tagConfs &&
    tagConfs.reduce((memo, tagConf) => {
      const value = tagConf.tag.value && tagConf;

      return value ? [...(memo || []), value] : memo;
    }, null)
  );
};
