import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';

import './slider.scss';
import { Icon as ImgIcon } from '../../../common/components';
import { ICON_POSITION } from '../../../common/components/Icon/constants';
import DashboardIcon from '../../../../assets/images/sidebar/dashboard.png';
import HomeActiveIcon from '../../../../assets/images/sidebar/homeActiveIcon.svg';
import HomeInactiveIcon from '../../../../assets/images/sidebar/homeInactiveIcon.svg';
import HomeActiveIconLight from '../../../../assets/images/sidebar/homeActiveIconLight.svg';
import HomeInactiveIconLight from '../../../../assets/images/sidebar/homeInactiveIconLight.svg';
import SustainabilityIcon from '../../../../assets/images/sidebar/sustainability.svg';
import SustainabilityIconLight from '../../../../assets/images/sidebar/sustainability-light.svg';
import OperationalExcellenceIcon from '../../../../assets/images/sidebar/operational-excellence.svg';
import OperationalExcellenceIconLight from '../../../../assets/images/sidebar/operational-excellence-light.svg';
import WorkplaceIcon from '../../../../assets/images/sidebar/workplace.svg';
import WorkplaceIconLight from '../../../../assets/images/sidebar/workplace-light.svg';
import MlAdaptiveLearningIcon from '../../../../assets/images/sidebar/ml-adaptive-learning.svg';
import MlAdaptiveLearningIconLight from '../../../../assets/images/sidebar/ml-adaptive-learning-light.svg';
import UpgradeIcon from '../../../../assets/images/sidebar/upgrade.svg';
import Upgrade from '../../../admin/container/Dashboard/components/Upgrade';
//import EsgIcon from '../../../../assets/images/sidebar/esg-inactive.svg';
//import EsgIconLight from '../../../../assets/images/sidebar/esg-active.svg';
import themeTogglerIcon from '../../../../assets/images/theme-toggle.svg';
import helpIcon from '../../../../assets/images/sidebar/help.svg';
import OptionDropDown from '../../../admin/container/Dashboard/components/OptionDropDown';
import Sidebar from './Sidebar';
import AssetScreenDesignOptionDropDown from '../../../admin/container/AssetScreenDesign/OptionDropDown';

const dropdownData = [
  'Alert',
  'Activity',
  'Report',
  'Data Upload',
  'Design',
  'User Management',
  'App Expert'
  // "Calculated Parameter"
];

const servicesOptions = [
  'AssetInsights',
  'NetworkInsight',
  'NetworkMonitor',
  'SystemInsights'
];

const getPath = (text) => {
  let data = {};
  switch (text) {
    case 'Alert':
      data = {
        pathname: 'Alert',
        className: 'alert-dropdown',
        title: 'Alerts',
        add_name: 'New Alert'
      };
      break;
    case 'Activity':
      data = {
        pathname: 'Activity',
        className: 'acivity-dropdown',
        title: 'Activity',
        add_name: 'New Activity'
      };
      break;
    case 'Report':
      data = {
        pathname: 'Report',
        className: 'report-dropdown',
        title: 'Report',
        add_name: 'New Report'
      };
      break;
    case 'Data Upload':
      data = {
        pathname: 'DataUpload',
        className: 'dataUpload-dropdown',
        title: 'Data',
        add_name: 'Upload Manual Data'
      };
      break;
    case 'Design':
      data = {
        pathname: 'Templates',
        className: 'screenDesign-dropdown',
        title: 'Design',
        add_name: 'New Template'
      };
      break;
    case 'User Management':
      data = {
        pathname: 'UserManagement',
        className: 'userManagement-dropdown',
        title: 'Users',
        add_name: 'New User'
      };
      break;
    case 'App Expert':
      data = {
        pathname: 'AppExpert',
        className: 'userManagement-dropdown',
        title: 'AppExpert',
        add_name: 'New User'
      };
      break;
    // case "Calculated Parameter":
    //     data = {pathname:"CalculatedParameter",className:'CalculatedParameter-dropdown', title:'Parameters', add_name: "New Parameter" }
    //     break;
    // default:
    //data = {};
    //break;
  }
  return data;
};

const themeDark = 'theme-dark';

const themeLight = 'theme-light';

const onThemeChange = () => {
  if (document.body.classList.contains(themeDark)) {
    document.body.classList.remove(themeDark);
    document.body.classList.add(themeLight);
    localStorage.setItem('theme', themeLight);

    return themeLight;
  } else {
    document.body.classList.remove(themeLight);
    document.body.classList.add(themeDark);
    localStorage.setItem('theme', themeDark);

    return themeDark;
  }
};

const dashboardRouteMatchPattern = `^/(Dashboard|${servicesOptions.join('|')})`;
const dashboardRouteMatchRegExp = new RegExp(dashboardRouteMatchPattern);

const sustainabilityRouteMatchRegExp = /^\/Sustainability/;

const workplaceRouteMatchRegExp = /^\/Workplace/;

const homeRouteMatchRegExp = /^\/Home/;

const isRouteWithSidebarGet = (pathname) => {
  return [
    dashboardRouteMatchRegExp,
    sustainabilityRouteMatchRegExp,
    workplaceRouteMatchRegExp
  ].reduce((memo, regExp) => {
    const match = pathname.match(regExp);

    return !memo && match ? true : memo;
  }, false);
};

const localStorageItemGet = (key) => {
  const item = localStorage.getItem(key);

  return item && JSON.parse(item);
};

const localStorageMemberGet = () => {
  const key = 'smartsense.member';

  return localStorageItemGet(key);
};

const localStorageMemberPlantsGet = () => {
  const key = 'smartsense.member_plants';

  return localStorageItemGet(key);
};

const memberCollectionGet = () => {
  const member = localStorage.getItem('smartsense.member');
  return member && JSON.parse(member);
};

const serviceCollectionGet = () => {
  return (
    localStorage.getItem('smartsense.services') &&
    JSON.parse(localStorage.getItem('smartsense.services'))
  );
};

const accessFlagGet = (_name) => {
  return (
    serviceCollectionGet() &&
    serviceCollectionGet().find(({ name }) => name === _name)
  );
};

const homeAccessFlagGet = () => {
  return accessFlagGet('Home');
};

const discoveryFlagGet = () => {
  const member = memberCollectionGet();
  return member && member.clientid.is_discovery_client;
};

const operationalExcellenceAccessFlagGet = () => {
  return accessFlagGet('Operational Excellence');
};

const sustainabilityAccessFlagGet = () => {
  return accessFlagGet('Sustainability');
};

const workplaceAccessFlagGet = () => {
  return accessFlagGet('Workplace');
};

const esgAccessFlagGet = () => {
  return accessFlagGet('ESG');
};

const mlAdaptiveLearningFlagGet = () => {
  return accessFlagGet('ML-Adaptive Learning');
};

const sidebarNameGet = () => {
  switch (true) {
    case !!window.location.pathname.match(dashboardRouteMatchRegExp):
      return 'Dashboard';

    case !!window.location.pathname.match(sustainabilityRouteMatchRegExp):
      return 'Sustainability';

    case !!window.location.pathname.match(workplaceRouteMatchRegExp):
      return 'Workplace';

    case !!window.location.pathname.match(homeRouteMatchRegExp):
      return 'Home';

    default:
      return '';
  }
};

const activeMenuGet = () => {
  const sidebarName = sidebarNameGet();

  return sidebarName === 'Dashboard' ? 'Operational Excellence' : sidebarName;
};

const isSubRouteGet = (windowLocationPathName = window.location.pathname) => {
  const widgetBuilderRouteMatchRegExp = /^\/.+\/WidgetBuilder/;

  const editDashboardRouteMatchRegExp = /^\/.+\/EditDashboard/;

  return !!(
    windowLocationPathName.match(widgetBuilderRouteMatchRegExp) ||
    windowLocationPathName.match(editDashboardRouteMatchRegExp)
  );
};

const salesforceAuthHandle = () => {
  const url =
    'https://ecolibrium.my.site.com/ecoservicecase/services/apexrest/CreateCase';

  const { plantid, address } = (localStorageMemberPlantsGet() || [])[0] || {};

  const {
    clientid: { clientid, clientname } = {},
    memberid,
    firstname,
    lastname,
    email,
    phone,
    username
  } = localStorageMemberGet() || {};

  const params = {
    'Customer Name': clientname,
    'Customer Id': clientid.toString(),
    'Facility Id': plantid.toString(),
    'Facility Address': address,
    'Service Opted': username,
    'Contact Person Name': `${firstname} ${lastname}`,
    'Contact Person User Id': memberid.toString(),
    'Contact Person Email Id': email.split(/,/)[0],
    'Contact Person Number': phone
  };

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })
    .then((response) => {
      return response.json();
    })
    .then(({ caseUrl }) => {
      window.open(caseUrl);
    });
};

export default class SliderMenu extends React.Component {
  constructor(props) {
    super(props);

    this.props.onThemeChange(localStorage.getItem('theme') === themeLight);

    this.state = {
      rSelected: this.props.active,
      selectedText: '',
      showDropDown: false,
      activeMenu: activeMenuGet(),
      dropdownPos: 0,
      sidebarName: sidebarNameGet(),
      sidebarRouteTrigger: false,
      sidebarShow: this.props.isDashboardHorizontal
        ? !!activeMenuGet() && !isSubRouteGet()
        : false,
      isDashboardHorizontal: this.props.isDashboardHorizontal,
      windowLocationPathName: window.location.pathname,
      upgrademodalopen: false,
      salesforceLoading: false
    };

    this.onBtnClick = this.onBtnClick.bind(this);
       this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    window.addEventListener('popstate', () => {
      isRouteWithSidebarGet(window.location.pathname) &&
        (() => {
          const sidebarName = sidebarNameGet();

          this.setState({
            activeMenu: activeMenuGet(),
            sidebarName
          });

          const dashboardId = queryString.parse(window.location.search)?.id;

          this.setState({
            redirectionTrigger: {
              sidebarName,
              dashboardId,
              dashboardUrl: window.location.pathname
            }
          });
        })();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    window.location.pathname !== this.state.windowLocationPathName &&
      (() => {
        this.setState({
          windowLocationPathName: window.location.pathname
        });

        this.state.sidebarRouteTrigger &&
          this.setState({ sidebarRouteTrigger: false });

        let sidebarName = sidebarNameGet();

        switch (true) {
          case !!isSubRouteGet():
            return this.sidebarStateHandle('isSubRoute');

          case this.props.isDashboardHorizontal &&
            isRouteWithSidebarGet(window.location.pathname) &&
            !!isSubRouteGet(this.state.windowLocationPathName):
            return this.sidebarStateHandle(
              'isDashboardHorizontal && isRouteWithSidebar && !isSubRoute'
            );

          case !!sidebarName && !this.state.sidebarName:
            return this.sidebarStateHandle('enter');

          case !sidebarName:
            return this.sidebarStateHandle('');

          default:
            return null;
        }
      })();

    nextProps.redirectionTrigger &&
      (nextProps.redirectionTrigger.sidebarName
        ? this.setState({ redirectionTrigger: nextProps.redirectionTrigger })
        : this.state.activeMenu &&
          (() => {
            this.setState({ activeMenu: '' });

            nextProps.onRedirectionSuccess();
          })());
  }

  // static context = useContext(Context)

  homeAccessFlag = homeAccessFlagGet();

  discoveryFlag = discoveryFlagGet();

  sustainabilityAccessFlag = sustainabilityAccessFlagGet();

  workplaceAccessFlag = workplaceAccessFlagGet();

  operationalExcellenceAccessFlag = operationalExcellenceAccessFlagGet();

  esgAccessFlag = esgAccessFlagGet();

  mlAdaptiveLearningFlag = mlAdaptiveLearningFlagGet();

  onBtnClick(rSelected) {
    this.setState({ rSelected });
    this.props.itemClickedAtIndex(rSelected);
  }

  toggleModal = () => {
    this.setState({ upgrademodalopen: !this.state.upgrademodalopen });
  };

  toggleSidebar = (value) => {
    this.setState({ showDropDown: value, rSelected: -1 });
  };

  sidebarStateHandle = (sidebarName) => {
    switch (sidebarName) {
      case 'isSubRoute':
        return this.setState({
          sidebarShow: false
        });

      case 'isDashboardHorizontal && isRouteWithSidebar && !isSubRoute':
        return this.setState({
          sidebarShow: true
        });

      case 'enter':
        return this.setState({
          sidebarName: sidebarNameGet(),
          sidebarRouteTrigger: true,
          sidebarShow: this.props.isDashboardHorizontal ? true : false
        });

      case '':
        return this.setState({
          sidebarName: '',
          sidebarShow: false
        });

      default:
        return this.setState({
          sidebarName,
          sidebarRouteTrigger: this.props.isDashboardHorizontal
            ? true
            : sidebarName !== this.state.sidebarName,
          sidebarShow: (() => {
            switch (true) {
              case !!this.props.isDashboardHorizontal:
                return true;

              case !!this.state.sidebarShow:
                return false;

              default:
                return sidebarName === this.state.sidebarName;
            }
          })()
        });
    }
  };

  getContent = (text) => {
    switch (text) {
      case 'Design':
        return (
          <AssetScreenDesignOptionDropDown
            toggleSidebar={(value) => {
              this.toggleSidebar(value);
            }}
          />
        );

      default:
        return (
          <OptionDropDown
            {...getPath(text)}
            showDropDown={this.state.showDropDown}
            name={text}
            history={this.props.history}
            toggleSidebar={(value) => {
              this.toggleSidebar(value);
            }}
          />
        );
    }
  };

  renderSidebar = () => {
    return (
      <Sidebar
        sidebarName={this.state.sidebarName}
        sidebarRouteTrigger={this.state.sidebarRouteTrigger}
        sidebarShow={this.state.sidebarShow}
        isDashboardHorizontal={this.props.isDashboardHorizontal}
        homeAccessFlag={this.homeAccessFlag}
        upgradeAccessFlag={this.discoveryFlag}
        sustainabilityAccessFlag={this.sustainabilityAccessFlag}
        workplaceAccessFlag={this.workplaceAccessFlag}
        operationalExcellenceAccessFlag={this.operationalExcellenceAccessFlag}
        onSetSidebarShow={(sidebarShow) => this.setState({ sidebarShow })}
        redirectionTrigger={this.state.redirectionTrigger}
        onRedirectionSuccess={({ sidebarName }) => {
          this.setState({
            activeMenu: activeMenuGet(),
            sidebarName,
            redirectionTrigger: undefined
          });

          this.props.onRedirectionSuccess();
        }}
      />
    );
  };

  render() {
    return (
      <div className='sliderHolder'>
        <div className='sidebar-menu-box'>
          <List disablePadding={true} className='sidebar-top-menu'>
                    
           {this.homeAccessFlag? 
            <ListItem
              button
              onClick={() => {
                this.setState({ activeMenu: 'Home' });

                //this.props.history.push('/Home');

                this.sidebarStateHandle('Home');
              }}
            >
              <div data-title='Home' className='home'>
                <ListItemIcon>
                  <div
                    className={
                      this.state.activeMenu === 'Home'
                        ? 'dark-theme-img icon-wrapper'
                        : 'dark-theme-img icon-wrapper icon-inactive'
                    }
                  >
                    <ImgIcon
                      size={30}
                      alt=''
                      src={HomeActiveIcon}
                      position={ICON_POSITION.CENTER_CENTER}
                    />
                    <ImgIcon
                      size={30}
                      alt=''
                      src={HomeInactiveIcon}
                      position={ICON_POSITION.CENTER_CENTER}
                    />
                  </div>

                  <div
                    className={
                      this.state.activeMenu === 'Home'
                        ? 'light-theme-img icon-wrapper'
                        : 'light-theme-img icon-wrapper icon-inactive'
                    }
                  >
                    <ImgIcon
                      size={30}
                      alt=''
                      src={HomeActiveIconLight}
                      position={ICON_POSITION.CENTER_CENTER}
                    />
                    <ImgIcon
                      size={30}
                      alt=''
                      src={HomeInactiveIconLight}
                      position={ICON_POSITION.CENTER_CENTER}
                    />
                  </div>
                </ListItemIcon>

                <p
                  className={
                    this.state.activeMenu === 'Home' ? 'active' : 'inactive'
                  }
                >
                  Home
                </p>
              </div>
            </ListItem>
            : null  
             }

           
            {this.discoveryFlag ? 
               <ListItem
               button
               onClick={() => {
                this.toggleModal()
               }}
             >
               <div data-title='Upgrade' className='home'>
                 <ListItemIcon>
                   <div
                     className={
                      this.state.upgrademodalopen
                         ? 'dark-theme-img icon-wrapper'
                         : 'dark-theme-img icon-wrapper icon-inactive'
                     }
                   >
                     <ImgIcon
                       size={40}
                       alt=''
                       src={UpgradeIcon}
                       position={ICON_POSITION.CENTER_CENTER}
                     />
                     <ImgIcon
                       size={40}
                       alt=''
                       src={UpgradeIcon}
                       position={ICON_POSITION.CENTER_CENTER}
                     />
                   </div>
 
                   <div
                     className={
                         this.state.upgrademodalopen
                         ? 'light-theme-img icon-wrapper'
                         : 'light-theme-img icon-wrapper icon-inactive'
                     }
                   >
                     <ImgIcon
                       size={40}
                       alt=''
                       src={UpgradeIcon}
                       position={ICON_POSITION.CENTER_CENTER}
                     />
                     <ImgIcon
                       size={40}
                       alt=''
                       src={UpgradeIcon}
                       position={ICON_POSITION.CENTER_CENTER}
                     />
                   </div>
                 </ListItemIcon>
                 <p
                   className={
                    this.state.upgrademodalopen ? 'active' : 'inactive'
                   }
                 >
                   Upgrade
                 </p>
               </div>
             <Upgrade isOpen={this.state.upgrademodalopen}  onClose = {this.toggleModal} />
             </ListItem>
            :null }

            { 
            this.operationalExcellenceAccessFlag ?
            <ListItem
              button
              onClick={() => {
                this.operationalExcellenceAccessFlag
                  ? (() => {
                      this.setState({ activeMenu: 'Operational Excellence' });

                      this.sidebarStateHandle('Dashboard');
                    })()
                  : (() => {
                      this.sidebarStateHandle('');

                      this.props.redirectPage('/NoAccess');
                    })();
              }}
            >
              <div
                data-title='Operational Excellence'
                className='operational-excellence'
              >
                <ListItemIcon>
                  <div
                    className={
                      this.state.activeMenu === 'Operational Excellence'
                        ? 'dark-theme-img icon-wrapper'
                        : 'dark-theme-img icon-wrapper icon-inactive'
                    }
                  >
                    <ImgIcon
                      size={30}
                      alt=''
                      src={OperationalExcellenceIcon}
                      position={ICON_POSITION.CENTER_CENTER}
                    />
                  </div>

                  <div
                    className={
                      this.state.activeMenu === 'Operational Excellence'
                        ? 'light-theme-img icon-wrapper'
                        : 'light-theme-img icon-wrapper icon-inactive'
                    }
                  >
                    <ImgIcon
                      size={30}
                      alt=''
                      src={OperationalExcellenceIconLight}
                      position={ICON_POSITION.CENTER_CENTER}
                    />
                  </div>
                </ListItemIcon>

                <p
                  className={
                    this.state.activeMenu === 'Operational Excellence'
                      ? 'active'
                      : 'inactive'
                  }
                >
                  Operational Excellence
                </p>
              </div>
            </ListItem>
            :null
             }

            {
            this.sustainabilityAccessFlag?
            <ListItem
              button
              onClick={() => {
                this.sustainabilityAccessFlag
                  ? (() => {
                      this.setState({ activeMenu: 'Sustainability' });

                      this.sidebarStateHandle('Sustainability');
                    })()
                  : (() => {
                      this.sidebarStateHandle('');

                      this.props.redirectPage('/NoAccess');
                    })();
              }}
            >
              <div
                data-title='Sustainability'
                className='sustainability-selector'
              >
                <ListItemIcon>
                  <div
                    className={
                      this.state.activeMenu === 'Sustainability'
                        ? 'dark-theme-img icon-wrapper'
                        : 'dark-theme-img icon-wrapper icon-inactive'
                    }
                  >
                    <ImgIcon
                      size={30}
                      alt=''
                      src={SustainabilityIcon}
                      position={ICON_POSITION.CENTER_CENTER}
                    />
                  </div>
                  <div
                    className={
                      this.state.activeMenu === 'Sustainability'
                        ? 'light-theme-img icon-wrapper'
                        : 'light-theme-img icon-wrapper icon-inactive'
                    }
                  >
                    <ImgIcon
                      size={30}
                      alt=''
                      src={SustainabilityIconLight}
                      position={ICON_POSITION.CENTER_CENTER}
                    />
                  </div>
                </ListItemIcon>
                <p
                  className={
                    this.state.activeMenu === 'Sustainability'
                      ? 'active'
                      : 'inactive'
                  }
                >
                  Sustainability
                </p>
              </div>
            </ListItem>
             : null
             }

            {
            this.workplaceAccessFlag ?
            <ListItem
              button
              onClick={() => {
                this.workplaceAccessFlag
                  ? (() => {
                      this.setState({ activeMenu: 'Workplace' });

                      this.sidebarStateHandle('Workplace');
                    })()
                  : (() => {
                      this.sidebarStateHandle('');

                      this.props.redirectPage('/NoAccess');
                    })();
              }}
            >
              <div data-title='Workplace' className='workplace-selector'>
                <ListItemIcon>
                  <div
                    className={
                      this.state.activeMenu === 'Workplace'
                        ? 'dark-theme-img icon-wrapper'
                        : 'dark-theme-img icon-wrapper icon-inactive'
                    }
                  >
                    <ImgIcon
                      size={30}
                      alt=''
                      src={WorkplaceIcon}
                      position={ICON_POSITION.CENTER_CENTER}
                    />
                  </div>
                  <div
                    className={
                      this.state.activeMenu === 'Workplace'
                        ? 'light-theme-img icon-wrapper'
                        : 'light-theme-img icon-wrapper icon-inactive'
                    }
                  >
                    <ImgIcon
                      size={30}
                      alt=''
                      src={WorkplaceIconLight}
                      position={ICON_POSITION.CENTER_CENTER}
                    />
                  </div>
                </ListItemIcon>
                <p
                  className={
                    this.state.activeMenu === 'Workplace'
                      ? 'active'
                      : 'inactive'
                  }
                >
                  Workplace
                </p>
              </div>
            </ListItem>
             : null
             }
            {/* commented for time being to show list below */}
            {/* <ListItem
              button
              onClick={() => {
                return this.esgAccessFlag
                  ? !window.location.pathname.includes('ESG') &&
                  (() => {
                    this.setState({ activeMenu: 'ESG' });

                    this.sidebarStateHandle('');

                    fetch('/ESG-backend/auth/login', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        credentials: 'include'
                      },
                      body: JSON.stringify({
                        email: 'yaga@pathetic.cf',
                        password: 'Qwe@123'
                      })
                    }).then(() => {
                      return window.open(
                        '/ESG-frontend/dashboard',
                        '_blank'
                      );
                    });
                  })()
                  : this.props.redirectPage('/NoAccess');
              }}
            >
              <div data-title='ESG' className='workplace-selector'>
                <ListItemIcon>
                  <div
                    className={
                      this.state.activeMenu === 'ESG'
                        ? 'dark-theme-img icon-wrapper'
                        : 'dark-theme-img icon-wrapper icon-inactive'
                    }
                  >
                    <ImgIcon
                      size={30}
                      alt=''
                      src={EsgIcon}
                      position={ICON_POSITION.CENTER_CENTER}
                    />
                  </div>
                  <div
                    className={
                      this.state.activeMenu === 'ESG'
                        ? 'light-theme-img icon-wrapper'
                        : 'light-theme-img icon-wrapper icon-inactive'
                    }
                  >
                    <ImgIcon
                      size={30}
                      alt=''
                      src={EsgIconLight}
                      position={ICON_POSITION.CENTER_CENTER}
                    />
                  </div>
                </ListItemIcon>
                <p
                  className={
                    this.state.activeMenu === 'ESG' ? 'active' : 'inactive'
                  }
                >
                  ESG
                </p>
              </div>
            </ListItem> */}
          </List>

          {this.mlAdaptiveLearningFlag && (
            <List disablePadding={true} className='sidebar-top-menu middle'>
              <ListItem
                button
                onClick={() => {
                  this.setState({ activeMenu: 'MlAdaptiveLearning' });

                  this.sidebarStateHandle('');

                  const cookieString = `user-auth-token=${localStorage
                    .getItem('smartsense.authToken')
                    .replace(/"/g, '')}; expires=${moment()
                    .add(1, 'days')
                    .toDate()
                    .toUTCString()}; domain=ecolibrium.io; path=/;`;

                  document.cookie = cookieString;

                  window.location = 'https://rps.smartsense.ecolibrium.io/';
                }}
              >
                <div data-title='AI' className='workplace-selector'>
                  <ListItemIcon>
                    <div
                      className={
                        this.state.activeMenu === 'MlAdaptiveLearning'
                          ? 'dark-theme-img icon-wrapper'
                          : 'dark-theme-img icon-wrapper icon-inactive'
                      }
                    >
                      <ImgIcon
                        size={30}
                        alt=''
                        src={MlAdaptiveLearningIcon}
                        position={ICON_POSITION.CENTER_CENTER}
                      />
                    </div>

                    <div
                      className={
                        this.state.activeMenu === 'MlAdaptiveLearning'
                          ? 'light-theme-img icon-wrapper'
                          : 'light-theme-img icon-wrapper icon-inactive'
                      }
                    >
                      <ImgIcon
                        size={30}
                        alt=''
                        src={MlAdaptiveLearningIconLight}
                        position={ICON_POSITION.CENTER_CENTER}
                      />
                    </div>
                  </ListItemIcon>
                  <p
                    className={
                      this.state.activeMenu === 'MlAdaptiveLearning'
                        ? 'active'
                        : 'inactive'
                    }
                  >
                    AI
                  </p>
                </div>
              </ListItem>
            </List>
          )}

          <List disablePadding={true} className='other-pages-wrap'>
            {this.props.items.map((text, index) => {
              return (
                <div key={index} className='hide-dropdown'>
                  <ListItem
                    selected={
                      !this.state.activeMenu &&
                      (this.state.rSelected == index ||
                        this.state.selectedText === text)
                    }
                    button
                    key={text}
                    onClick={(e) => {
                      if (dropdownData.includes(text)) {
                        this.setState({
                          rSelected: index,
                          showDropDown: true,
                          selectedText: text,
                          activeMenu: '',
                          dropdownPos: e.clientY
                        });
                      } else {
                        this.onBtnClick(index);
                      }

                      this.setState({
                        activeMenu: ''
                      });

                      !this.props.isDashboardHorizontal &&
                        this.sidebarStateHandle('');
                    }}
                  >
                    <div
                      data-title={
                        this.props.getTitleAtIndex &&
                        this.props.getTitleAtIndex(index)
                      }
                    >
                      <ListItemIcon>
                        <ImgIcon
                          size={30}
                          alt=''
                          src={
                            this.props.getIconAtIndex
                              ? Object.values(
                                  this.props.getIconAtIndex(index)
                                )[0]
                              : DashboardIcon
                          }
                          position={
                            this.state.rSelected == index
                              ? ICON_POSITION.CENTER_CENTER
                              : `${ICON_POSITION.CENTER_CENTER} icon-inactive`
                          }
                        />
                      </ListItemIcon>
                    </div>

                    <ListItemText>
                      <span dangerouslySetInnerHTML={{ __html: text }}></span>
                    </ListItemText>
                  </ListItem>

                  <div
                    className='dropdown-box-data'
                    style={{
                      top: this.state.dropdownPos
                    }}
                  >
                    {this.state.selectedText &&
                      this.state.selectedText === text &&
                      this.state.showDropDown &&
                      this.getContent(this.state.selectedText)}
                  </div>
                </div>
              );
            })}
          </List>
        </div>

        {!this.discoveryFlag ? (
          <div>
            <a
              href='#'
              className='chat-icon-wrap'
              onClick={(event) => {
                event.preventDefault();

                event.stopPropagation();

                this.setState({ salesforceLoading: true });

                salesforceAuthHandle()
                  .then(() => {
                    this.setState({ salesforceLoading: false });
                  })
                  .catch(() => {
                    this.setState({ salesforceLoading: false });
                  });
              }}
            >
              {!this.state.salesforceLoading ? (
                <ImgIcon
                  size={30}
                  alt=''
                  src={helpIcon}
                  position={ICON_POSITION.CENTER_CENTER}
                />
              ) : (
                <CircularProgress size={10} color='primary' />
              )}
            </a>

            <div className='theme-toggler-wrap'>
              <div
                className='toggler-img-wrap'
                onClick={() => {
                  const theme = onThemeChange();

                  this.props.onThemeChange(theme === themeLight);
                }}
              >
                <img src={themeTogglerIcon} />
              </div>
            </div>
          </div>
        ) : null}



        {this.renderSidebar()}
      </div>
    );
  }
}