import { GET_STATS_DATA, GET_STATS_DATA_SUCCESS, GET_STATS_DATA_FAILURE, GET_ASSETS_DATA, GET_ASSETS_DATA_SUCCESS, GET_ASSETS_DATA_FAILURE, GET_TIMELINE_DATA, GET_TIMELINE_DATA_SUCCESS, GET_TIMELINE_DATA_FAILURE, OLD_TIMELINE_DATA } from '../constants'

import * as AppConstants from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined,
  total_pages: null,
  total_record: null,
}


export function getCbmAssets(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.GET_CBM_ASSETS:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }

    case AppConstants.GET_CBM_ASSETS_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        error: false,
        loading: false,
        success: true
      }
    case AppConstants.GET_CBM_ASSETS_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    default:
      return state;
  }
}

export function getAllAssets(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.GET_ALL_ASSETS:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }

    case AppConstants.GET_ALL_ASSETS_SUCCESS:
      if(action.response.result && action.response.result.length){
        action.response.result.map((data)=>data.asset_id = data.id)
      }
      return {
        ...state,
        data: [...action.response.result],
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        error: false,
        loading: false,
        success: true
      }
    case AppConstants.GET_ALL_ASSETS_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        data: [],
        success: false
      }
    default:
      return state;
  }
}

export function getCbmAssetsByAssetName(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.GET_CBM_ASSETS_BY_ASSET_NAME:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }

    case AppConstants.GET_CBM_ASSETS_BY_ASSET_NAME_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        error: false,
        loading: false,
        success: true
      }
    case AppConstants.GET_CBM_ASSETS_BY_ASSET_NAME_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    default:
      return state;
  }
}

export function getSpecificAssetDetailsByAssetName(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }

    case AppConstants.GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME_SUCCESS:
      // TO DO::add via props
      localStorage.setItem('template.specific_asset_names' , JSON.stringify(action.response) )
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      }
    case AppConstants.GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    default:
      return state;
  }
}

export function getAssetOverview(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.GET_ASSET_OVERVIEW:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }

    case AppConstants.GET_ASSET_OVERVIEW_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      }
    case AppConstants.GET_ASSET_OVERVIEW_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    default:
      return state;
  }
}

export function getAllAssetsForDropdownReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AppConstants.GET_ALL_ASSETS_FOR_DROPDOWN:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }

    case AppConstants.GET_ALL_ASSETS_FOR_DROPDOWN_SUCCESS:
      if (action.response.result && action.response.result.length) {
        action.response.result.map((data) => data.asset_id = data.id)
      }
      return {
        ...state,
        data: [...action.response.result],
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        error: false,
        loading: false,
        success: true
      }
    case AppConstants.GET_ALL_ASSETS_FOR_DROPDOWN_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        data: [],
        success: false
      }
    default:
      return state;
  }
}

export function getStatsDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_STATS_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case GET_STATS_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case GET_STATS_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}


export function getAssetsDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ASSETS_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case GET_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case GET_ASSETS_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}




export function getTimeLineDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TIMELINE_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case GET_TIMELINE_DATA_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        error: false,
        loading: false
      }
    case GET_TIMELINE_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}

export function getAssetInsightListReducer(state = {
  data: [],
  loading: false,
  error: false,
  success: undefined
}, action) {
  switch (action.type) {
    case AppConstants.GET_ASSET_INSIGHT_LIST:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: false
      }
    case AppConstants.GET_ASSET_INSIGHT_LIST_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      }
    case AppConstants.GET_ASSET_INSIGHT_LIST_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false,
        success: false
      }
    default:
      return state
  }
}

export function getDetailsByInsightIdReducer(state = {
  data: [],
  loading: false,
  error: false,
  success: undefined
}, action) {
  switch (action.type) {
    case AppConstants.GET_INSIGHT_DETAILS:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: false
      }
    case AppConstants.GET_INSIGHT_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      }
    case AppConstants.GET_INSIGHT_DETAILS_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false,
        success: false
      }
    default:
      return state
  }
}

export function getAllActionsOfAssetReducer(state = {
  data: [],
  loading: false,
  error: false,
  success: undefined
}, action) {
  switch (action.type) {
    case AppConstants.GET_ALL_ACTIONS_FOR_ASSET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: false
      }
    case AppConstants.GET_ALL_ACTIONS_FOR_ASSET_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      }
    case AppConstants.GET_ALL_ACTIONS_FOR_ASSET_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false,
        success: false
      }
    default:
      return state
  }
}

export function createManualInsightReducer(state = {
  data: [],
  loading: false,
  error: false,
  success: undefined
}, action) {
  switch (action.type) {
    case AppConstants.CREATE_MANUAL_INSIGHT:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined
      };

    case AppConstants.CREATE_MANUAL_INSIGHT_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case AppConstants.CREATE_MANUAL_INSIGHT_FAILURE:
      return {
        ...state,
        data: action.payload.response,
        error: true,
        loading:false,
        success: false
      };

    default:
      return state;
  }
}

export const assetInsightsReducer = {
  getCbmAssets,
  getAllAssets,
  getCbmAssetsByAssetName,
  getSpecificAssetDetailsByAssetName,
  getAssetOverview,
  getAllAssetsForDropdownReducer,
  getAssetInsightListReducer,
  getDetailsByInsightIdReducer,
  getAllActionsOfAssetReducer,
  createManualInsightReducer
}

const OLD_INITIAL_STATE = { data: [] }

export function getOldDataReducer(state = OLD_INITIAL_STATE, action) {
  switch (action.type) {
    case OLD_TIMELINE_DATA:
      return {
        ...state,
        data: action.payload
      }
    default:
      return state
  }
}