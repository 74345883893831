import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import axios from 'axios';
import { httpMethodGetJSON, httpMethodPatch, httpMethodDelete, httpMethodPost, httpMethodPut, } from '../https';
import { ajax } from 'rxjs/ajax';
import { create, map } from 'lodash';
import { URL } from '../constants';
import * as AppConstants from './constant';

import {
  getPlantHoliday,
  createPlantHoliday,
  updatePlantHoliday,
  deletePlantHoliday
} from './_components/HolidayList/epic';

import { createUpdateWorkingNonWorking, getWorkingNonWorkingHourDetails } from './_components/WorkingNonWorkingHoursConfiguration/epic';

import { createUpdateProductionNonProductionHour, getProductionNonProductionHourDetails } from './_components/ProductionNonProductionHoursConfiguration/epics';

import { createUpdateShift, getShiftDetails } from './_components/ShiftConfiguration/epic';

import { createDynamicVariableTemplate } from './_components/DynamicVariableTemplateCreation/epic';

import { createDynamicVariableMapping } from './_components/DynamicVariableConfiguration/epic';

import {
  createNetworkCalculatedParamTemplate,
  updateNetworkCalculatedParamTemplate
} from './_components/CalculatedParameterCreation/epic';

export const getTemplateList = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_TEMPLATE_LIST),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/templates/`);
    })
);

export const getCalculatedParameterList = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_CALCULATED_PARAMETER_LIST),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/calcd-params`);
    })
);


export const getAllClientList = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_CLIENT_LIST),
    mergeMap(action => {
        let name = action.payload && action.payload.name
        if (name) {
            return httpMethodGetJSON({ type: AppConstants.GET_ALL_CLIENT_LIST }, `${URL}/service_templates/clients/?name=${name}`);
        } else return httpMethodGetJSON({ type: AppConstants.GET_ALL_CLIENT_LIST }, `${URL}/service_templates/clients/`);
    })
);

export const getAllPlantList = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_PLANT_LIST),
    mergeMap(action => {
        let client_id = action.payload && action.payload.client_id
        if (client_id) {
            return httpMethodGetJSON({ type: AppConstants.GET_ALL_PLANT_LIST }, `${URL}/service_templates/plants/?client_id=${client_id}`);
        } else return httpMethodGetJSON({ type: AppConstants.GET_ALL_PLANT_LIST }, `${URL}/service_templates/plants/`);
    })
);

export const deleteCalculatedParameter = action$ => action$.pipe(
    ofType(AppConstants.DELETE_CALCULATED_PARAMETER),
    mergeMap(action => {
        return httpMethodDelete({ type: AppConstants.DELETE_CALCULATED_PARAMETER, payload: action.payload.data }, `${URL}/calcd-params/${action.payload.cal_param_id}/`);
    })
);

export const createCalculatedParameter = action$ => action$.pipe(
    ofType(AppConstants.CREATE_CALCULATED_PARAMETERS),
    mergeMap(action => {
        return httpMethodPost(action, `${URL}/calcd-params/`);
    })
);

export const updateCalculatedParameter = action$ => action$.pipe(
    ofType(AppConstants.UPDATE_CALCULATED_PARAMETER),
    mergeMap(action => {
        return httpMethodPatch(action, `${URL}/calcd-params/${action.payload.id}/`);
    })
);

export const verifyTargetSensorParameter = action$ => action$.pipe(
    ofType(AppConstants.VERIFY_TARGET_SENSOR_PARAMETER),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/reading/reading_series/`);
    })
);

export const getAssetNameDetails = action$ => action$.pipe(
    ofType(AppConstants.GET_ASSET_NAME_DETAILS),
    mergeMap(action => {
        return httpMethodGetJSON({ type: AppConstants.GET_ASSET_NAME_DETAILS }, `${URL}/asset-cbm/asset-names/${action.payload.asset_name}/templates/`);
    })
);

export const getTemplateDetails = action$ => action$.pipe(
    ofType(AppConstants.GET_TEMPLATE_DETAILS),
    mergeMap(action => {
        return httpMethodGetJSON({ type: AppConstants.GET_TEMPLATE_DETAILS }, `${URL}/templates/${action.payload.template_id}/`);
    })
);

export const updateTemplate = action$ => action$.pipe(
    ofType(AppConstants.UPDATE_TEMPLATE),
    mergeMap(action => {
        return httpMethodPatch(action, `${URL}/templates/${action.payload.id}/`);
    })
);

export const deleteTemplate = action$ => action$.pipe(
    ofType(AppConstants.DELETE_TEMPLATE),
    mergeMap(action => {
        return httpMethodDelete({ type: AppConstants.DELETE_TEMPLATE, payload: action.payload.data }, `${URL}/templates/${action.payload.template_id}/`);
    })
);

export const createTemplate = action$ => action$.pipe(
    ofType(AppConstants.CREATE_TEMPLATES),
    mergeMap(action => {
        return httpMethodPost(action, `${URL}/templates/`);
    })
);

export const getAllAssetList = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_ASSET_LIST),
    mergeMap(action => {
        let name = action.payload && action.payload.name
        if (name) {
            return httpMethodGetJSON({ type: AppConstants.GET_ALL_ASSET_LIST }, `${URL}/asset-cbm/asset-names/client/?name=${name}`);
        } else return httpMethodGetJSON({ type: AppConstants.GET_ALL_ASSET_LIST }, `${URL}/asset-cbm/asset-names/client/`);
    })
);

// export const getMetaDataForm = action$ => action$.pipe(
//     ofType(AppConstants.GET_ALL_METADATA),
//     mergeMap(action => {
//         return httpMethodGetJSON({ type: AppConstants.GET_ALL_METADATA }, `${URL}/partners/clients/${action.payload.id}/plants/`);
//     })
// );



// export const getListOfAvailableDashboard = action$ => action$.pipe(
//     ofType(AppConstants.GET_ALL_DASHBOARD_LIST),
//     mergeMap(action => {
//         return httpMethodGetJSON({ type: AppConstants.GET_ALL_DASHBOARD_LIST }, `${URL}/partners/clients/${action.payload.clientId}/plants/${action.payload.plantId}/users/`);
//     })
// );


export const addTemplateWidget = action$ => action$.pipe(
    ofType(AppConstants.ADD_TEMPLATE_WIDGET),
    mergeMap(action => {
        return httpMethodPost({ type: AppConstants.ADD_TEMPLATE_WIDGET, payload: action.payload.data }, `${URL}/templates/${action.payload.template_id}/widgets/`);
    })
);

export const getTemplateWidgets = action$ => action$.pipe(
    ofType(AppConstants.GET_TEMPLATE_WIDGETS),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/templates/${action.payload.template_id}/widgets/`);
    })
);

export const getWidgetDetailsOfTemplate = action$ => action$.pipe(
    ofType(AppConstants.GET_WIDGET_DETAILS_TEMPLATE),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/templates/${action.payload.template_id}/widgets/${action.payload.widget_id}/`);
    })
);

export const getGroupWidgetDetailsOfTemplate = action$ => action$.pipe(
    ofType(AppConstants.GET_GROUP_WIDGET_DETAILS_TEMPLATE),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/templates/${action.payload.template_id}/widgets/${action.payload.widget_id}/`);
    })
);

export const updateWidgetTemplate = action$ => action$.pipe(
    ofType(AppConstants.UPDATE_WIDGET_TEMPLATE),
    mergeMap(action => {
        return httpMethodPatch({ type: AppConstants.UPDATE_WIDGET_TEMPLATE, payload: action.payload.data }, `${URL}/templates/${action.payload.template_id}/widgets/${action.payload.id}/`);
    })
);

export const deleteWidgetOfTemplate = action$ => action$.pipe(
    ofType(AppConstants.DELETE_WIDGET_OF_TEMPLATE),
    mergeMap(action => {
        return httpMethodDelete({ type: AppConstants.DELETE_WIDGET_OF_TEMPLATE, payload: {} }, `${URL}/templates/${action.payload.template_id}/widgets/${action.payload.widget_id}`);
    })
);

export const getDefaultTemplateAsset = action$ => action$.pipe(
    ofType(AppConstants.GET_DEFAULT_TEMPLATE_OF_ASSET),
    mergeMap(action => {
        return httpMethodGetJSON({ type: AppConstants.GET_DEFAULT_TEMPLATE_OF_ASSET }, `${URL}/asset-cbm/asset-names/${action.payload.asset_name}/templates/`);
    })
);

export const getServiceTemplatePlant = action$ => action$.pipe(
    ofType(AppConstants.GET_SERVICE_TEMPLATE_PLANT),
    mergeMap(action => {
        let ClientId = action.payload && action.payload.ClientId
        return httpMethodGetJSON({ type: AppConstants.GET_SERVICE_TEMPLATE_PLANT }, `${URL}/service_templates/plants/?client_id=${ClientId}`);
    })
);

export const getClientTemplate = action$ => action$.pipe(
    ofType(AppConstants.GET_CLIENT_TEMPLATE),
    mergeMap(() => {
        return httpMethodGetJSON({type: AppConstants.GET_CLIENT_TEMPLATE}, `${URL}/service_templates/clients/`);
    })
);

export const createServiceTemplate = action$ => action$.pipe(
    ofType(AppConstants.CREATE_SERVICE_TEMPLATE),
    mergeMap(action => {
        return httpMethodPost(action, `${URL}/gstemp/`);
    })
);

export const getServiceTemplate = action$ => action$.pipe(
    ofType(AppConstants.GET_SERVICE_TEMPLATE),
    mergeMap((action) => {
        return httpMethodGetJSON(action, `${URL}/gstemp/`);
    })
);

export const deleteServiceTemplate = action$ => action$.pipe(
    ofType(AppConstants.DELETE_SERVICE_TEMPLATE),
    mergeMap(action => {
        return httpMethodDelete(action, `${URL}/gstemp/${action.payload.service_id}/`);
    })
);

export const updateServiceTemplate = action$ => action$.pipe(
    ofType(AppConstants.UPDATE_SERVICE_TEMPLATE),
    mergeMap(action => {
        return httpMethodPatch(action, `${URL}/gstemp/${action.payload.id}/`);
    })
);

export const getWidgetsDetails = action$ => action$.pipe(
    ofType(AppConstants.GET_WIDGETS_DETAILS),
    mergeMap(action => {
        return httpMethodGetJSON({ type: AppConstants.GET_WIDGETS_DETAILS }, `${URL}/widgets/?service_id=${action.payload.service_id}`);
    })
);

export const createDashboardTemplate = action$ => action$.pipe(
    ofType(AppConstants.CREATE_DASHBOARD_TEMPLATE),
    mergeMap(action => {
        return httpMethodPost(action, `${URL}/gstemp/${action.payload.template_id}/dashboards/`);
    })
);

export const getTemplateDashboard = action$ => action$.pipe(
    ofType(AppConstants.GET_DASHBOARD_TEMPLATE),
    mergeMap(action => {
        return httpMethodGetJSON({ type: AppConstants.GET_DASHBOARD_TEMPLATE }, `${URL}/gstemp/${action.payload.template_id}/dashboards/`);
    })
);

export const addDashboardWidget = action$ => action$.pipe(
    ofType(AppConstants.ADD_DASHBOARD_WIDGET),
    mergeMap(action => {
        return httpMethodPost(action, `${URL}/gstemp/${action.payload.template_id}/dashboards/${action.payload.dashboard_id}/widgets/`);
    })
);

// export const getDashboardWidgets = action$ => action$.pipe(
//     ofType(AppConstants.GET_DASHBOARD_WIDGETS),
//     mergeMap(action => {
//         return httpMethodGetJSON({ type: AppConstants.GET_DASHBOARD_WIDGETS }, `${URL}/gstemp/${action.payload.template_id}/dashboards/${action.payload.dashboard_id}/widgets/`);
//     })
// );

// need to create dynamic page size 
// for now it's fixed = 100
export const getDashboardWidgets = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_DASHBOARD_WIDGETS),
    mergeMap((action) => {
      // debugger
      return httpMethodGetJSON(
        { type: AppConstants.GET_DASHBOARD_WIDGETS,  payload: action.payload },
        `${URL}/gstemp/${action.payload.template_id}/dashboards/${action.payload.dashboard_id}/widgets/`
      );
    })
  );

export const deleteDashboardWidget = action$ => action$.pipe(
    ofType(AppConstants.DELETE_DASHBOARD_WIDGET),
    mergeMap(action => {
        return httpMethodDelete(action, `${URL}/gstemp/${action.payload.template_id}/dashboards/${action.payload.dashboard_id}/widgets/${action.payload.widget_id}/`);
    })
);

export const updateDashboardWidget = action$ => action$.pipe(
    ofType(AppConstants.EDIT_DASHBOARD_WIDGET),
    mergeMap(action => {
        return httpMethodPatch(action, `${URL}/gstemp/${action.payload.template_id}/dashboards/${action.payload.dashboard_id}/widgets/${action.payload.id}/`);
    })
);

export const editDashboardOrder = action$ => action$.pipe(
    ofType(AppConstants.EDIT_DASHBOARD_ORDER),
    mergeMap(action => {
        return httpMethodPatch(action, `${URL}/gstemp/${action.payload.template_id}/dashboards/`);
    })
);

export const editWidgetService = action$ => action$.pipe(
    ofType(AppConstants.EDIT_WIDGET_SERVICE),
    mergeMap(action => {
        return httpMethodPatch(action, `${URL}/gstemp/${action.payload.template_id}/dashboards/${action.payload.dashboard_id}/`);
    })
)

export const makeServiceDefault = action$ => action$.pipe(
    ofType(AppConstants.SERVICE_DEFAULT),
    mergeMap(action => {
        return httpMethodGetJSON({ type: AppConstants.SERVICE_DEFAULT, payload:action.payload }, `${URL}/gstemp/service/${action.payload.service_id}/templates`);
    })
);

export const makeServiceDefaultExtended = (action$) =>
  action$.pipe(
    ofType(AppConstants.SERVICE_DEFAULT_EXTENDED),
    mergeMap((action) => {
      return httpMethodGetJSON(
        {
          type: AppConstants.SERVICE_DEFAULT_EXTENDED,
          payload: action.payload,
        },
        `${URL}/gstemp/service/${action.payload.service_id}/templates`
      );
    })
  );

export const getAssetSensorTypes = action$ => action$.pipe(
    ofType(AppConstants.GET_ASSET_SENSOR_TYPES),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/asset-cbm/sensor_reading_types/${action.payload.assetid}`);
    })
)

export const getAllAssetCalculatedList = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_ASSET_CALCULATED_LIST),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/asset-cbm/calculated/templates/`);
    })
);

export const createAssetCalculatedParameter = action$ => action$.pipe(
    ofType(AppConstants.CREATE_ASSET_CALCULATED_PARAMETERS),
    mergeMap(action => {
        return httpMethodPost(action, `${URL}/asset-cbm/calculated/templates/`);
    })
);


export const updateAssetCalculatedParameter = (action$) =>
  action$.pipe(
    ofType(AppConstants.UPDATE_ASSET_CALCULATED_PARAMETER),
    mergeMap((action) => {
      return httpMethodPatch(
        action,
        `${URL}/asset-cbm/calculated/templates/${action.payload.id}/?asset_name_id=${action.payload.asset_name}`
      );
    })
  );

export const getFacilityHoliday = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_FACILITY_HOLIDAY),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/holiday/`);
    })
  );

export const deletePlantAllHoliday = (action$) =>
  action$.pipe(
    ofType(AppConstants.DELETE_PLANT_ALL_HOLIDAY),
    mergeMap((action) => {
      return httpMethodDelete(
        action,
        `${URL}/plant/${action.payload.plant_id}/holiday/`
      );
    })
  );

export const getNetworkType = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_NETWORK_TYPE),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/network_type/`);
    })
  );
}

export const getDynamicVariableTemplate = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_DYNAMIC_VARIABLE_TEMPLATE),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network/dynamic-variable/template/`
      );
    })
  );
};

export const updateDynamicVariableTemplate = (action$) => {
  return action$.pipe(
    ofType(AppConstants.UPDATE_DYNAMIC_VARIABLE_TEMPLATE),
    mergeMap((action) => {
      return httpMethodPut(
        action,
        `${URL}/network/dynamic-variable/template/${action.payload.id}/`
      );
    })
  );
};

export const deleteDynamicVariableTemplate = (action$) => {
  return action$.pipe(
    ofType(AppConstants.DELETE_DYNAMIC_VARIABLE_TEMPLATE),
    mergeMap((action) => {
      return httpMethodDelete(
        action,
        `${URL}/network/dynamic-variable/template/${action.payload.id}/`
      );
    })
  );
}

export const getDynamicVariableMapping = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_DYNAMIC_VARIABLE_MAPPING),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network/dynamic-variable/mapping/`
      );
    })
  );
};

export const updateDynamicVariableMapping = (action$) => {
  return action$.pipe(
    ofType(AppConstants.UPDATE_DYNAMIC_VARIABLE_MAPPING),
    mergeMap((action) => {
      return httpMethodPut(
        action,
        `${URL}/network/dynamic-variable/mapping/${action.payload.id}/`
      );
    })
  );
};

export const deleteDynamicVariableMapping = (action$) => {
  return action$.pipe(
    ofType(AppConstants.DELETE_DYNAMIC_VARIABLE_MAPPING),
    mergeMap((action) => {
      return httpMethodDelete(
        action,
        `${URL}/network/dynamic-variable/mapping/${action.payload.id}/`
      );
    })
  );
};

export const _getAssetDynamicVariables = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_ASSET_DYNAMIC_VARIABLES),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/dynamic_variables/list/${action.payload.assetid}/`
      );
    })
  );

  export const _getNetworkDynamicVariables = (action$) =>
    action$.pipe(
      ofType(AppConstants.GET_NETWORK_DYNAMIC_VARIABLES),
      mergeMap((action) => {
        return httpMethodGetJSON(
          action,
          `${URL}/network/dynamic-variable/template/`
        );
      })
    );
  

export const getAllAssetAlerts = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_ALL_ASSET_ALERT_LIST),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/alert/templates/`);
    })
  );

  export const getAllNetworkAlerts = (action$) =>
    action$.pipe(
      ofType(AppConstants.GET_ALL_NETWORK_ALERT_LIST),
      mergeMap((action) => {
        return httpMethodGetJSON(action, `${URL}/network/alert/templates/`);
      })
    );

export const createAssetAlert = (action$) =>
  action$.pipe(
    ofType(AppConstants.CREATE_ASSET_ALERT),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/asset-cbm/alert/templates/`);
    })
  );

export const updateAssetAlert = (action$) =>
  action$.pipe(
    ofType(AppConstants.UPDATE_ASSET_ALERT),
    mergeMap((action) => {
      return httpMethodPatch(
        action,
        `${URL}/asset-cbm/alert/templates/${action.payload.id}/`
      );
    })
  );

export const deleteAssetAlertTemplate = (action$) =>
  action$.pipe(
    ofType(AppConstants.DELETE_ASSET_ALERT_TEMPLATE),
    mergeMap((action) => {
      return httpMethodDelete(
        {
          type: AppConstants.DELETE_ASSET_ALERT_TEMPLATE,
          payload: action.payload.data
        },
        `${URL}/asset-cbm/alert/templates/${action.payload.asset_alert_id}/`
      );
    })
  );

  export const deleteNetworkAlertTemplate = (action$) =>
    action$.pipe(
      ofType(AppConstants.DELETE_NETWORK_ALERT_TEMPLATE),
      mergeMap((action) => {
        return httpMethodDelete(
          {
            type: AppConstants.DELETE_NETWORK_ALERT_TEMPLATE,
            payload: action.payload.data
          },
          `${URL}/network/alert/templates/${action.payload.network_alert_id}/`
        );
      })
    );

  export const createNetworkAlert = (action$) =>
    action$.pipe(
      ofType(AppConstants.CREATE_NETWORK_ALERT),
      mergeMap((action) => {
        return httpMethodPost(action, `${URL}/network/alert/templates/`);
      })
    );

  export const updateNetworkAlert = (action$) =>
    action$.pipe(
      ofType(AppConstants.UPDATE_NETWORK_ALERT),
      mergeMap((action) => {
        return httpMethodPatch(
          action,
          `${URL}/network/alert/templates/${action.payload.id}/`
        );
      })
    );

export const getAllNetworkList = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_ALL_NETWORK_LIST),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/network/`);
    })
  );

export const createNetworkTemplate = (action$) =>
  action$.pipe(
    ofType(AppConstants.CREATE_NETWORK_TEMPLATES),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/network/`);
    })
  );

export const updateNetwork = (action$) =>
  action$.pipe(
    ofType(AppConstants.UPDATE_NETWORK),
    mergeMap((action) => {
      return httpMethodPut(
        {
          type: AppConstants.UPDATE_NETWORK,
          payload:  { 
            name : action.payload.name,
            description : action.payload.description
          }   
        },
        `${URL}/network/${action.payload.network_id}/`
      );
    })
  );  

export const deleteNetwork = (action$) =>
  action$.pipe(
    ofType(AppConstants.DELETE_NETWORK),
    mergeMap((action) => {
      return httpMethodDelete(
        {
          type: AppConstants.DELETE_NETWORK,
          payload: action.payload.network_id
        },
        `${URL}/network/${action.payload.network_id}/`
      );
    })
  );

export const getNetworkCalculatedParamTemplate = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_NETWORK_CALCULATED_PARAM_TEMPLATE),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network/calculated_param_template/`
      );
    })
  );
};

export const deleteNetworkCalculatedParamTemplate = (action$) => {
  return action$.pipe(
    ofType(AppConstants.DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE),
    mergeMap((action) => {
      const { network_type, id } = action.payload;

      return httpMethodDelete(
        action,
        `${URL}/network_type/${network_type}/calculated_param_template/${id}/`
      );
    })
  );
};

export const getWorkingNonWorkingHoursList = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_WORKING_NON_WORKING_HOURS_LIST),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/working-hour/`
      );
    })
  );
};

export const deleteWorkingNonWorkingHoursList = (action$) => {
  return action$.pipe(
    ofType(AppConstants.DELETE_WORKING_NON_WORKING_HOURS_LIST),
    mergeMap((action) => {
      return httpMethodDelete(
        action,
        `${URL}/working-hour/plant/${action.payload.plant_id}`
      );
    })
  );
};

export const getProductionNonProductionHoursList = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_PRODUCTION_NON_PRODUCTION_HOURS_LIST),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/production-hour/`
      );
    })
  );
};

export const deleteProductionNonProductionHoursList = (action$) => {
  return action$.pipe(
    ofType(AppConstants.DELETE_PRODUCTION_NON_PRODUCTION_HOURS_LIST),
    mergeMap((action) => {
      return httpMethodDelete(
        action,
        `${URL}/production-hour/plant/${action.payload.plant_id}`
      );
    })
  );
};

export const getShiftList = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_SHIFT_LIST),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/shift/`
      );
    })
  );
};

export const deleteShiftList = (action$) => {
  return action$.pipe(
    ofType(AppConstants.DELETE_SHIFT_LIST),
    mergeMap((action) => {
      return httpMethodDelete(
        action,
        `${URL}/shift/plant/${action.payload.plant_id}`
      );
    })
  );
};

export const getNetworkInsightList = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_NETWORK_INSIGHT_LIST),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network_type/${action.payload.network_type}/names/`
      );
    })
  );

export const getDetailsByNetworkInsightId = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_NETWORK_INSIGHT_DETAILS),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network/${action.payload.insight_id}/data/`
      );
    })
  );

export const getNetworkSensorTypes = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_NETWORK_SENSOR_TYPES),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network/${action.payload.network_id}/node_list/`
      );
    })
  );

export const getNetworkReadingTypes = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_NETWORK_READING_TYPES),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network/get_sensor_readingtypes/`
      );
    })
  );

export const getAllActionsOfNetwork = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_ALL_ACTIONS_FOR_NETWORK),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network_type/${action.payload.network_type}/actions`
      );
    })
  );

export const createManualNetworkInsight = (action$) =>
  action$.pipe(
    ofType(AppConstants.CREATE_MANUAL_NETWORK_INSIGHT),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/network/create_manual_insight/`);
    })
  );

export const getNetworkSmeClientSensorLocations = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_NETWORK_SME_CLIENT_SENSOR_LOCATIONS),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network/sme/client_sensor_locations/`
      );
    })
  );
};

export const screenDesignEpics = {
    getTemplateList,
    getAssetNameDetails,
    getTemplateDetails,
    updateTemplate,
    deleteTemplate,
    getAllAssetList,
    createTemplate,
    addTemplateWidget,
    getTemplateWidgets,
    getWidgetDetailsOfTemplate,
    getGroupWidgetDetailsOfTemplate,
    updateWidgetTemplate,
    deleteWidgetOfTemplate,
    getDefaultTemplateAsset,
    getServiceTemplatePlant,
    getClientTemplate,
    createServiceTemplate,
    deleteServiceTemplate,
    updateServiceTemplate,
    getServiceTemplate,
    getWidgetsDetails,
    createDashboardTemplate,
    getTemplateDashboard,
    addDashboardWidget,
    getDashboardWidgets,
    deleteDashboardWidget,
    updateDashboardWidget,
    editDashboardOrder,
    editWidgetService,
    makeServiceDefault,
		makeServiceDefaultExtended,
    getCalculatedParameterList,
    createCalculatedParameter,
    getAllClientList,
    getAllPlantList,
    deleteCalculatedParameter,
    deleteCalculatedParameter,
    updateCalculatedParameter,
    verifyTargetSensorParameter,
    createAssetCalculatedParameter,
    getAssetSensorTypes,
    getAllAssetCalculatedList,
    updateAssetCalculatedParameter,
		getPlantHoliday,
		createPlantHoliday,
		updatePlantHoliday,
		deletePlantHoliday,
		getFacilityHoliday,
		deletePlantAllHoliday,
		getNetworkType,
		createDynamicVariableTemplate,
		getDynamicVariableTemplate,
		updateDynamicVariableTemplate,
		deleteDynamicVariableTemplate,
		createDynamicVariableMapping,
		getDynamicVariableMapping,
		updateDynamicVariableMapping,
		deleteDynamicVariableMapping,
    _getAssetDynamicVariables,
    _getNetworkDynamicVariables,
		getAllAssetAlerts,
    getAllNetworkAlerts,
		createAssetAlert,
    createNetworkAlert,
		updateAssetAlert,
    updateNetworkAlert,
		deleteAssetAlertTemplate,
    deleteNetworkAlertTemplate,
    getAllNetworkList,
    createNetworkTemplate,
		createNetworkCalculatedParamTemplate,
		getNetworkCalculatedParamTemplate,
		updateNetworkCalculatedParamTemplate,
		deleteNetworkCalculatedParamTemplate,
    updateNetwork,
    deleteNetwork,
    getWorkingNonWorkingHoursList,
    deleteWorkingNonWorkingHoursList,
    createUpdateWorkingNonWorking,
    getWorkingNonWorkingHourDetails,
    createUpdateProductionNonProductionHour,
    getProductionNonProductionHourDetails,
    getProductionNonProductionHoursList,
    deleteProductionNonProductionHoursList,
    getShiftList,
    deleteShiftList,
    createUpdateShift,
    getShiftDetails,
    getNetworkInsightList,
    getDetailsByNetworkInsightId,
    getNetworkSensorTypes,
    getNetworkReadingTypes,
    getAllActionsOfNetwork,
    createManualNetworkInsight,
    getNetworkSmeClientSensorLocations
}