import React, {
  useMemo,
  useContext,
  useState,
  useCallback,
  useEffect,
  Fragment
} from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import queryString from 'query-string';

import { getDashboardSidebarAllDashboard } from './action';
import { makeServiceDefaultExtended } from '../../../admin/container/AssetScreenDesign/action';
import {
  getCustomDashboardExtended,
  getDashboardSequenceExtended,
  createDashboardSequence,
  createDashboardSequenceReset,
  createCustomDashboard,
  resetAddDashboard,
  editDashboard,
  resetEditDashboard,
  deleteDashboard,
  resetDeleteDashboard
} from '../../../admin/container/Dashboard/action';
import { Context } from '../../../admin/container/Main/context';
import _Sidebar from './_Sidebar';
import __Sidebar from './__Sidebar';
import SidebarCrud from './SiderbarCrud';
import './dashboardSidebar.scss';

const localStorageServiceCollectionGet = () => {
  return (
    localStorage.getItem('smartsense.services') &&
    JSON.parse(localStorage.getItem('smartsense.services'))
  );
};

const serviceIdGet = (_name) => {
  const localStorageService =
    localStorageServiceCollectionGet() &&
    localStorageServiceCollectionGet().find(({ name }) => name === _name);

  return localStorageService && localStorageService.id;
};

const sidebarNameCollection = [
  'Dashboard',
  'Sustainability',
  'Workplace',
  'Home'
];

const serviceNameCollection = [
  'My Dashboard',
  ...sidebarNameCollection.slice(1),
  'Operational Excellence'
];

const assetInsightsDefinition = {
  name: 'Asset Insights',
  id: 'AssetInsights'
};

const networkDefinitions = [
  {
    key: 'Network Monitoring',
    name: 'Network Monitoring',
    id: 'NetworkMonitor'
  },
  { key: 'Network Insight', name: 'Network Insights', id: 'NetworkInsight' }
];

const namedServiceDashboardIdCollection = [
  assetInsightsDefinition.id,
  ...networkDefinitions.map(({ id }) => id)
];

const PAGE_SIZE = 8;

const Sidebar = (_props) => {
  const {
    getDashboardSidebarAllDashboard,
    makeServiceDefaultExtended,
    getCustomDashboardExtended,
    getDashboardSequenceExtended,
    createDashboardSequence,
    createDashboardSequenceReset,
    resetAddDashboard,
    onSetSidebarShow,
    resetEditDashboard,
    resetDeleteDashboard,
    onRedirectionSuccess,
    ...props
  } = _props;

  const context = useContext(Context);

  const location = useLocation();

  const history = useHistory();

  const [dashboardInitialized, setDashboardInitialized] = useState();

  const [dashboardData, setDashboardData] = useState([]);

  const [sustainabilityInitialized, setSustainabilityInitialized] = useState();

  const [sustainabilityData, setSustainabilityData] = useState([]);

  const [workplaceInitialized, setWorkplaceInitialized] = useState();

  const [workplaceData, setWorkplaceData] = useState([]);

  const [homeInitialized, setHomeInitialized] = useState();

  const [homeData, setHomeData] = useState([]);

  const [networkInitialized, setNetworkInitialized] = useState();

  const [routeInitialized, setRouteInitialized] = useState(false);

  const [pageNo, setPageNo] = useState(1);

  const [selectedId, setSelectedId] = useState();

  const [reorderableShow, setReorderableShow] = useState(false);

  const [reorderableSuccessToastShow, setReorderableSuccessToastShow] =
    useState(false);

  const [showDashboardForm, setShowDashboardForm] = useState(false);

  const [update, setUpdate] = useState(false);

  const [dashboardInput, setDashboardInput] = useState({
    dashboard_name: '',
    showHeader: true,
    refreshTime: 0,
    showDate: false,
    addException: false,
    startOption: '',
    nestedDateRange: ''
  });

  const [showDashboardOption, setShowDashboardOption] = useState(false);

  const [showDashboardSubOption, setShowDashboardSubOption] = useState(false);

  const [dashboardLoadingResetFlag, setDashboardLoadingResetFlag] = useState();

  const [sustainabilityLoadingResetFlag, setSustainabilityLoadingResetFlag] =
    useState();

  const [workplaceLoadingResetFlag, setWorkplaceLoadingResetFlag] = useState();

  const [sidebarName, setSidebarName] = useState(props.sidebarName);

  const [redirectionInitialized, setRedirectionInitialized] = useState();

  const serviceIdActive = useMemo(() => {
    return serviceIdGet(
      sidebarName === sidebarNameCollection[0]
        ? serviceNameCollection[0]
        : sidebarName
    );
  }, [sidebarName]);

  const dashboardLoading = useMemo(() => {
    switch (true) {
      case !!dashboardLoadingResetFlag:
        return true;

      case !!dashboardInitialized:
        return false;

      default:
        return (
          !(
            props.dashboardServiceDefaultSuccess ||
            props.dashboardServiceDefaultError
          ) ||
          !(
            props.allDashboardData.data && props.allDashboardData.data.result
          ) ||
          !(props.dashboardSequenceSuccess || props.dashboardSequenceError)
        );
    }
  }, [
    dashboardLoadingResetFlag,
    dashboardInitialized,
    props.dashboardServiceDefaultSuccess,
    props.dashboardServiceDefaultError,
    props.allDashboardData.data,
    props.dashboardSequenceSuccess,
    props.dashboardSequenceError
  ]);

  const _dashboardData = useMemo(() => {
    const __data = [
      ...(() => {
        return props.allDashboardData.data && props.allDashboardData.data.result
          ? props.allDashboardData.data.result.filter(
              ({ custom_service_name }) => custom_service_name == null
            )
          : [];
      })(),
      ...(() => {
        const exists =
          localStorageServiceCollectionGet() &&
          localStorageServiceCollectionGet().some(
            (item) => item.name == assetInsightsDefinition.name
          );

        return props.allDashboardData.data &&
          props.allDashboardData.data.result &&
          exists
          ? [
              {
                id: assetInsightsDefinition.id,
                name: assetInsightsDefinition.name,
                url: `/${assetInsightsDefinition.id}`
              }
            ]
          : [];
      })(),
      ...(() => {
        const _networkDefinitions = networkDefinitions.filter(({ key }) => {
          return (localStorageServiceCollectionGet() || [])
            .map(({ name }) => name)
            .includes(key);
        });

        const _networkDefinition = _networkDefinitions.find((_, index) => {
          const exists = !index
            ? props.networkMonitorServiceDefaultSuccess
            : props.networkInsightServiceDefaultSuccess;

          return exists;
        });

        const __networkDefinition = [..._networkDefinitions].reverse()[0];
        const networkDefinition = _networkDefinition || __networkDefinition;

        return props.allDashboardData.data &&
          props.allDashboardData.data.result &&
          _networkDefinitions.length
          ? [
              {
                id: networkDefinition.id,
                name: networkDefinitions[1].name,
                url: `/${networkDefinition.id}`
              }
            ]
          : [];
      })(),
      ...(props.dashboardServiceDefaultData || [])
        .reduce((memo, dashboardServiceDefaultData) => {
          return dashboardServiceDefaultData.dashboard_name !== 'Sustainability'
            ? [...memo, dashboardServiceDefaultData]
            : memo;
        }, [])
        .map((_serviceDefaultData) => ({
          ..._serviceDefaultData,
          id: _serviceDefaultData.dashboard_id
        }))
    ].filter(({ custom_service_name }) => custom_service_name == null);

    const _data =
      props.dashboardSequenceSuccess && props.dashboardSequenceData.config
        ? __data.reduce((memo, ___data) => {
            const { sequence } =
              props.dashboardSequenceData.config.find(
                ({ id }) => id === ___data.id
              ) || {};

            return typeof sequence === 'number'
              ? [...memo, { ...___data, sequence }]
              : [...memo, ___data];
          }, [])
        : __data;

    return _data
      .sort((a, b) => a.sequence - b.sequence)
      .reduce((memo, _data, index) => {
        return [
          ...memo,
          {
            ..._data,
            sequence: index
          }
        ];
      }, []);
  }, [
    props.allDashboardData,
    props.dashboardServiceDefaultData,
    props.networkInsightServiceDefaultSuccess,
    props.networkMonitorServiceDefaultSuccess,
    props.dashboardSequenceSuccess,
    props.dashboardSequenceData
  ]);

  const sustainabilityLoading = useMemo(() => {
    switch (true) {
      case !!sustainabilityLoadingResetFlag:
        return true;

      case !!sustainabilityInitialized:
        return false;

      default:
        return (
          !(
            props.sustainabilityCustomDashboardSuccess ||
            props.sustainabilityCustomDashboardError
          ) ||
          !(
            props.sustainabilityServiceDefaultSuccess ||
            props.sustainabilityServiceDefaultError
          ) ||
          !(
            props.sustainabilitySequenceSuccess ||
            props.sustainabilitySequenceError
          )
        );
    }
  }, [
    sustainabilityLoadingResetFlag,
    sustainabilityInitialized,
    props.sustainabilityCustomDashboardSuccess,
    props.sustainabilityCustomDashboardError,
    props.sustainabilityServiceDefaultSuccess,
    props.sustainabilityServiceDefaultError,
    props.sustainabilitySequenceSuccess,
    props.sustainabilitySequenceError
  ]);

  const _sustainabilityData = useMemo(() => {
    const __data = [
      ...(props.sustainabilityServiceDefaultData || [])
        .reduce((memo, _sustainabilityServiceDefaultData) => {
          return _sustainabilityServiceDefaultData.dashboard_name !==
            'Sustainability_Nxtra'
            ? [...memo, _sustainabilityServiceDefaultData]
            : memo;
        }, [])
        .map((_serviceDefaultData) => ({
          ..._serviceDefaultData,
          id: _serviceDefaultData.dashboard_id
        })),
      ...(props.sustainabilityCustomDashboardSuccess
        ? props.sustainabilityCustomDashboardData
        : [])
    ];

    const _data =
      props.sustainabilitySequenceSuccess &&
      props.sustainabilitySequenceData.config
        ? __data.reduce((memo, ___data) => {
            const { sequence } =
              props.sustainabilitySequenceData.config.find(
                ({ id }) => id === ___data.id
              ) || {};

            return typeof sequence === 'number'
              ? [...memo, { ...___data, sequence }]
              : [...memo, ___data];
          }, [])
        : __data;

    return _data
      .sort((a, b) => a.sequence - b.sequence)
      .reduce((memo, _data, index) => {
        return [
          ...memo,
          {
            ..._data,
            sequence: index
          }
        ];
      }, []);
  }, [
    props.sustainabilityServiceDefaultData,
    props.sustainabilityCustomDashboardSuccess,
    props.sustainabilityCustomDashboardData,
    props.sustainabilitySequenceSuccess,
    props.sustainabilitySequenceData
  ]);

  const workplaceLoading = useMemo(() => {
    switch (true) {
      case !!workplaceLoadingResetFlag:
        return true;

      case !!workplaceInitialized:
        return false;

      default:
        return (
          !(
            props.workplaceCustomDashboardSuccess ||
            props.workplaceCustomDashboardError
          ) ||
          !(
            props.workplaceServiceDefaultSuccess ||
            props.workplaceServiceDefaultError
          ) ||
          !(props.workplaceSequenceSuccess || props.workplaceSequenceError)
        );
    }
  }, [
    workplaceLoadingResetFlag,
    workplaceInitialized,
    props.workplaceCustomDashboardSuccess,
    props.workplaceCustomDashboardError,
    props.workplaceServiceDefaultSuccess,
    props.workplaceServiceDefaultError,
    props.workplaceSequenceSuccess,
    props.workplaceSequenceError
  ]);

  const _workplaceData = useMemo(() => {
    const __data = [
      ...(props.workplaceServiceDefaultData || []).map(
        (_serviceDefaultData) => ({
          ..._serviceDefaultData,
          id: _serviceDefaultData.dashboard_id
        })
      ),
      ...(props.workplaceCustomDashboardSuccess
        ? props.workplaceCustomDashboardData
        : [])
    ];

    const _data =
      props.workplaceSequenceSuccess && props.workplaceSequenceData.config
        ? __data.reduce((memo, ___data) => {
            const { sequence } =
              props.workplaceSequenceData.config.find(
                ({ id }) => id === ___data.id
              ) || {};

            return typeof sequence === 'number'
              ? [...memo, { ...___data, sequence }]
              : [...memo, ___data];
          }, [])
        : __data;

    return _data
      .sort((a, b) => a.sequence - b.sequence)
      .reduce((memo, _data, index) => {
        return [
          ...memo,
          {
            ..._data,
            sequence: index
          }
        ];
      }, []);
  }, [
    props.workplaceServiceDefaultData,
    props.workplaceCustomDashboardSuccess,
    props.workplaceCustomDashboardData,
    props.workplaceSequenceSuccess,
    props.workplaceSequenceData
  ]);

  const homeLoading = useMemo(() => {
    return homeInitialized
      ? false
      : !(props.homeCustomDashboardSuccess || props.homeCustomDashboardError) ||
          !(props.homeServiceDefaultSuccess || props.homeServiceDefaultError) ||
          !(props.homeSequenceSuccess || props.homeSequenceError);
  }, [
    homeInitialized,
    props.homeCustomDashboardSuccess,
    props.homeCustomDashboardError,
    props.homeServiceDefaultSuccess,
    props.homeServiceDefaultError,
    props.homeSequenceSuccess,
    props.homeSequenceError
  ]);

  const _homeData = useMemo(() => {
    const __data = [
      ...(props.homeServiceDefaultData || []).map((_serviceDefaultData) => ({
        ..._serviceDefaultData,
        id: _serviceDefaultData.dashboard_id
      })),
      ...(props.homeCustomDashboardSuccess ? props.homeCustomDashboardData : [])
    ];

    const _data =
      props.homeSequenceSuccess && props.homeSequenceData.config
        ? __data.reduce((memo, ___data) => {
            const { sequence } =
              props.homeSequenceData.config.find(
                ({ id }) => id === ___data.id
              ) || {};

            return typeof sequence === 'number'
              ? [...memo, { ...___data, sequence }]
              : [...memo, ___data];
          }, [])
        : __data;

    return _data
      .sort((a, b) => a.sequence - b.sequence)
      .reduce((memo, _data, index) => {
        return [
          ...memo,
          {
            ..._data,
            sequence: index
          }
        ];
      }, []);
  }, [
    props.homeServiceDefaultData,
    props.homeCustomDashboardSuccess,
    props.homeCustomDashboardData,
    props.homeSequenceSuccess,
    props.homeSequenceData
  ]);

  const dataInitialized = useMemo(() => {
    return (
      (props.operationalExcellenceAccessFlag ? dashboardInitialized : true) &&
      (props.sustainabilityAccessFlag ? sustainabilityInitialized : true) &&
      (props.workplaceAccessFlag ? workplaceInitialized : true) &&
      (props.homeAccessFlag ? homeInitialized : true)
    );
  }, [
    props.operationalExcellenceAccessFlag,
    dashboardInitialized,
    props.sustainabilityAccessFlag,
    sustainabilityInitialized,
    props.workplaceAccessFlag,
    workplaceInitialized,
    props.homeAccessFlag,
    homeInitialized
  ]);

  const [loading, data, setData] = useMemo(() => {
    switch (sidebarName) {
      case '':
        return [
          false,
          [],
          () => {
            // eslint-disable-next-line no-empty
          }
        ];

      case sidebarNameCollection[0]:
        return [dashboardLoading, dashboardData, setDashboardData];

      case sidebarNameCollection[1]:
        return [
          sustainabilityLoading,
          sustainabilityData,
          setSustainabilityData
        ];

      case sidebarNameCollection[2]:
        return [workplaceLoading, workplaceData, setWorkplaceData];

      case sidebarNameCollection[3]:
        return [homeLoading, homeData, setHomeData];
    }
  }, [
    sidebarName,
    dashboardLoading,
    dashboardData,
    sustainabilityLoading,
    sustainabilityData,
    workplaceLoading,
    workplaceData,
    homeLoading,
    homeData
  ]);

  const sidebarData = useMemo(() => {
    return reorderableShow
      ? data
      : (() => {
          const startIndex = pageNo * PAGE_SIZE - PAGE_SIZE;

          const endIndex = startIndex + PAGE_SIZE;

          return data.slice(startIndex, endIndex);
        })();
  }, [reorderableShow, data, pageNo]);

  const sequenceCreate = useCallback(
    (dashboardDatas, service_id) => {
      createDashboardSequence({
        config: dashboardDatas.map((item, index) => ({
          ...item,
          sequence: index
        })),
        service_id
      });
    },
    [createDashboardSequence]
  );

  const navigate = useCallback(
    (dashboard) => {
      switch (true) {
        case !dashboard:
          return;

        case !!dashboard.url:
          return history.push(dashboard.url);

        default:
          return history.push(`/${sidebarName}/?id=${dashboard.id}`);
      }
    },
    [sidebarName, history]
  );

  const _setSelectedId = useCallback((selectedId, data) => {
    setPageNo((pageNo) => {
      const index = data.findIndex(({ id }) => id == selectedId);

      const _pageNo = Math.ceil(
        ((index >= 0 ? index : data.length) + 1) / PAGE_SIZE
      );

      return _pageNo !== pageNo ? _pageNo : pageNo;
    });

    setSelectedId(selectedId);
  }, []);

  const routeSwitchTrigger = useCallback(
    (dashboardId, data, dashboardUrl) => {
      const dashboard =
        data.find(({ id }) => id.toString() === dashboardId) ||
        (() => {
          const _id = dashboardUrl.replace(/^\//, '');

          const id =
            _id === networkDefinitions[0].id ? networkDefinitions[1].id : _id;

          return {
            id,
            url: dashboardUrl
          };
        })();

      _setSelectedId(dashboard.id, data);

      navigate(dashboard);
    },
    [_setSelectedId, navigate]
  );

  const dashboardIdFromRouteGet = useCallback(() => {
    return (
      new URLSearchParams(location.search).get('id') ||
      new URLSearchParams(location.search).get('assetId') ||
      (!!location.pathname.match(
        new RegExp(`/${assetInsightsDefinition.id}`)
      ) &&
        assetInsightsDefinition.id) ||
      (!!location.pathname.match(
        new RegExp(networkDefinitions.map(({ id }) => id).join('|'))
      ) &&
        networkDefinitions[1].id)
    );
  }, [location.search, location.pathname]);

  const onDashboardOptionClose = useCallback(() => {
    setShowDashboardOption();

    setShowDashboardSubOption();
  }, []);

  const _onSetSidebarShow = useCallback(() => {
    !props.isDashboardHorizontal && onSetSidebarShow(false);
  }, [props.isDashboardHorizontal, onSetSidebarShow]);

  const onSidebarShowToggle = useCallback(() => {
    onDashboardOptionClose();

    onDashboardFormClose();

    _onSetSidebarShow();
  }, [onDashboardOptionClose, onDashboardFormClose, _onSetSidebarShow]);

  useEffect(() => {
    setSidebarName(props.sidebarName);
  }, [props.sidebarName]);

  useEffect(() => {
    (props.createServiceTemplateSuccess ||
      props.updateServiceTemplateSuccess ||
      props.deleteServiceTemplateSuccess) &&
      (() => {
        setTimeout(() => {
          setDashboardLoadingResetFlag(true);

          setDashboardInitialized(false);

          setSustainabilityLoadingResetFlag(true);

          setSustainabilityInitialized(false);

          setWorkplaceLoadingResetFlag(true);

          setWorkplaceInitialized(false);

          setNetworkInitialized(false);
        }, 1000);
      })();
  }, [
    props.createServiceTemplateSuccess,
    props.updateServiceTemplateSuccess,
    props.deleteServiceTemplateSuccess
  ]);

  useEffect(() => {
    const onClickHandle = (event) => {
      switch (true) {
        case !document.querySelector('.sliderHolder').contains(event.target) &&
          props.sidebarShow:
          return onSidebarShowToggle();

        case document.querySelector('.oe-sidebarWrap .item-options') &&
          !document
            .querySelector('.oe-sidebarWrap .item-options')
            .contains(event.target) &&
          showDashboardOption:
          return onDashboardOptionClose();
      }
    };

    document.addEventListener('click', onClickHandle);

    return () => {
      return document.removeEventListener('click', onClickHandle);
    };
  }, [
    props.isDashboardHorizontal,
    props.sidebarShow,
    showDashboardOption,
    onSidebarShowToggle,
    onDashboardOptionClose
  ]);

  useEffect(() => {
    if (props.deleteDashboardData.success === true) {
      setData((data) => {
        const _id = queryString.parse(
          props.deleteDashboardData.data.request.url.split(/\?/)[1]
        ).id;

        const index = data.findIndex(({ id }) => id === _id);

        return index >= 0
          ? (() => {
              const _data = [...data.slice(0, index), ...data.slice(index + 1)];

              sequenceCreate(_data, serviceIdActive);

              _data.length
                ? (() => {
                    const dashboard = index ? _data[index - 1] : _data[0];

                    _setSelectedId(dashboard.id, _data);

                    navigate(dashboard);
                  })()
                : history.replace('/Dashboard/NoAsset');

              return _data;
            })()
          : data;
      });

      resetDeleteDashboard();

      onSidebarShowToggle();

      context.notify.success('Dashboard Deleted');
    }
  }, [
    props.deleteDashboardData,
    serviceIdActive,
    setData,
    sequenceCreate,
    _setSelectedId,
    navigate,
    history,
    resetDeleteDashboard,
    onSidebarShowToggle,
    context.notify
  ]);

  useEffect(() => {
    if (props.editDashboardData.success) {
      const dashboard = props.editDashboardData.data.response;

      setData((data) => {
        const index = data.findIndex(({ id }) => id === dashboard.id);

        return index >= 0
          ? (() => {
              const _data = [
                ...data.slice(0, index),
                dashboard,
                ...data.slice(index + 1)
              ];

              sequenceCreate(_data, serviceIdActive);

              navigate(dashboard);

              return _data;
            })()
          : data;
      });

      resetEditDashboard();

      onSidebarShowToggle();
    }
  }, [
    props.editDashboardData,
    serviceIdActive,
    setData,
    sequenceCreate,
    resetEditDashboard,
    onSidebarShowToggle,
    navigate
  ]);

  useEffect(() => {
    if (props.createCustomData.success) {
      const dashboard = props.createCustomData.data.response;

      context.notify.success('Dashboard created');

      setData((data) => {
        const index = data.findIndex(({ id }) => id === dashboard.id);

        return index < 0
          ? (() => {
              const _data = [...data, dashboard];

              sequenceCreate(_data, serviceIdActive);

              _setSelectedId(dashboard.id, _data);

              navigate(dashboard);

              return _data;
            })()
          : data;
      });

      resetAddDashboard();

      onSidebarShowToggle();
    }
  }, [
    props.createCustomData,
    serviceIdActive,
    context.notify,
    setData,
    sequenceCreate,
    _setSelectedId,
    navigate,
    resetAddDashboard,
    onSidebarShowToggle
  ]);

  useEffect(() => {
    if (props.createCustomData.error && props.createCustomData.data) {
      let errorMessage = '';

      if (props.createCustomData.data.error) {
        errorMessage = props.createCustomData.data.error;
      }

      if (props.createCustomData.data.message) {
        errorMessage = props.createCustomData.data.message;
      }

      context.notify.error(errorMessage);

      onDashboardFormClose();
    }
  }, [props.createCustomData, context.notify, onDashboardFormClose]);

  useEffect(() => {
    redirectionInitialized &&
      (() => {
        routeSwitchTrigger(
          props.redirectionTrigger.dashboardId,
          data,
          props.redirectionTrigger.dashboardUrl
        );

        setRedirectionInitialized(false);

        onRedirectionSuccess(props.redirectionTrigger);
      })();
  }, [
    redirectionInitialized,
    props.redirectionTrigger,
    data,
    routeSwitchTrigger,
    onRedirectionSuccess
  ]);

  useEffect(() => {
    props.redirectionTrigger &&
      !redirectionInitialized &&
      (() => {
        setSidebarName(props.redirectionTrigger.sidebarName);

        setRedirectionInitialized(true);
      })();
  }, [props.redirectionTrigger, redirectionInitialized]);

  useEffect(() => {
    routeInitialized &&
      sidebarName &&
      props.sidebarRouteTrigger &&
      (() => {
        setReorderableShow((reorderableShow) =>
          reorderableShow ? false : reorderableShow
        );

        data && data[0]
          ? routeSwitchTrigger(data[0].id, data)
          : history.replace('/Dashboard/NoAsset');
      })();
  }, [
    routeInitialized,
    sidebarName,
    props.sidebarRouteTrigger,
    data,
    routeSwitchTrigger,
    history
  ]);

  useEffect(() => {
    const regExp = new RegExp(
      `/(${[
        sidebarNameCollection[3],
        sidebarNameCollection[0],
        sidebarNameCollection[1]
      ].reduce((memo, serviceName) => {
        return `${memo}|${serviceName}`;
      }, '')})$`
    );
    const ssoUser = localStorage.getItem('smartsense.isSsoUser');

    dataInitialized &&
      !routeInitialized &&
      (() => {
        location.pathname.match(regExp) && !location.search
          ? data && data[0]
            ? routeSwitchTrigger(data[0].id, data)
            : history.replace(ssoUser ? `/Dashboard/` : '/Dashboard/NoAsset')
          : _setSelectedId(dashboardIdFromRouteGet(), data);

        setRouteInitialized(true);
      })();
  }, [
    dataInitialized,
    routeInitialized,
    location.pathname,
    location.search,
    history,
    routeSwitchTrigger,
    dashboardIdFromRouteGet,
    _setSelectedId,
    data
  ]);

  useEffect(() => {
    if (
      props.createDashboardSequenceData &&
      props.createDashboardSequenceData.success &&
      props.createDashboardSequenceData.data.service_id == serviceIdActive
    ) {
      createDashboardSequenceReset();

      if (reorderableSuccessToastShow) {
        context.notify.success('Dashboard Sorted');

        setReorderableSuccessToastShow(false);
      }
    }
  }, [
    props.createDashboardSequenceData,
    serviceIdActive,
    createDashboardSequenceReset,
    reorderableSuccessToastShow,
    context.notify
  ]);

  useEffect(() => {
    !homeLoading &&
      !homeInitialized &&
      (() => {
        !_.isEqual(
          _homeData,
          props.homeSequenceSuccess && props.homeSequenceData.config
        ) && sequenceCreate(_homeData, serviceIdGet(serviceNameCollection[3]));

        setHomeInitialized(true);
      })();
  }, [
    homeLoading,
    homeInitialized,
    _homeData,
    props.homeSequenceSuccess,
    props.homeSequenceData,
    sequenceCreate
  ]);

  useEffect(() => {
    !homeInitialized && setHomeData(_homeData);
  }, [homeInitialized, _homeData]);

  useEffect(() => {
    !workplaceLoading &&
      !workplaceInitialized &&
      (() => {
        !_.isEqual(
          _workplaceData,
          props.workplaceSequenceSuccess && props.workplaceSequenceData.config
        ) &&
          sequenceCreate(
            _workplaceData,
            serviceIdGet(serviceNameCollection[2])
          );

        setWorkplaceInitialized(true);
      })();
  }, [
    workplaceLoading,
    workplaceInitialized,
    _workplaceData,
    props.workplaceSequenceSuccess,
    props.workplaceSequenceData,
    sequenceCreate
  ]);

  useEffect(() => {
    !workplaceInitialized &&
      (() => {
        setWorkplaceData(_workplaceData);

        setWorkplaceLoadingResetFlag(false);
      })();
  }, [workplaceInitialized, _workplaceData]);

  useEffect(() => {
    !sustainabilityLoading &&
      !sustainabilityInitialized &&
      (() => {
        !_.isEqual(
          _sustainabilityData,
          props.sustainabilitySequenceSuccess &&
            props.sustainabilitySequenceData.config
        ) &&
          sequenceCreate(
            _sustainabilityData,
            serviceIdGet(serviceNameCollection[1])
          );

        setSustainabilityInitialized(true);
      })();
  }, [
    sustainabilityLoading,
    sustainabilityInitialized,
    _sustainabilityData,
    props.sustainabilitySequenceSuccess,
    props.sustainabilitySequenceData,
    sequenceCreate
  ]);

  useEffect(() => {
    !sustainabilityInitialized &&
      (() => {
        setSustainabilityData(_sustainabilityData);

        setSustainabilityLoadingResetFlag(false);
      })();
  }, [sustainabilityInitialized, _sustainabilityData]);

  useEffect(() => {
    !dashboardLoading &&
      !dashboardInitialized &&
      (() => {
        !_.isEqual(
          _dashboardData,
          props.dashboardSequenceSuccess && props.dashboardSequenceData.config
        ) &&
          sequenceCreate(
            _dashboardData,
            serviceIdGet(serviceNameCollection[0])
          );

        setDashboardInitialized(true);
      })();
  }, [
    dashboardLoading,
    dashboardInitialized,
    _dashboardData,
    props.dashboardSequenceSuccess,
    props.dashboardSequenceData,
    sequenceCreate
  ]);

  useEffect(() => {
    !dashboardInitialized &&
      (() => {
        setDashboardData(_dashboardData);

        setDashboardLoadingResetFlag(false);
      })();
  }, [dashboardInitialized, _dashboardData]);

  useEffect(() => {
    !networkInitialized &&
      (() => {
        networkDefinitions.map(({ name }) => {
          const service_id = serviceIdGet(name);

          service_id &&
            makeServiceDefaultExtended({
              service_id
            });
        });

        setNetworkInitialized(true);
      })();
  }, [networkInitialized, makeServiceDefaultExtended]);

  useEffect(() => {
    const service_id = serviceIdGet(serviceNameCollection[3]);

    props.homeAccessFlag &&
      !homeInitialized &&
      service_id &&
      (() => {
        makeServiceDefaultExtended({
          service_id
        });

        getCustomDashboardExtended({
          service_id,
          page_size: 100,
          page_no: 1
        });

        getDashboardSequenceExtended({
          service_id
        });
      })();
  }, [
    props.homeAccessFlag,
    homeInitialized,
    makeServiceDefaultExtended,
    getCustomDashboardExtended,
    getDashboardSequenceExtended
  ]);

  useEffect(() => {
    const service_id = serviceIdGet(serviceNameCollection[2]);

    props.workplaceAccessFlag &&
      !workplaceInitialized &&
      service_id &&
      (() => {
        makeServiceDefaultExtended({
          service_id,
          page_no: 1,
          page_size: 100
        });

        getCustomDashboardExtended({
          service_id,
          page_size: 100,
          page_no: 1
        });

        getDashboardSequenceExtended({
          service_id
        });
      })();
  }, [
    props.workplaceAccessFlag,
    workplaceInitialized,
    makeServiceDefaultExtended,
    getCustomDashboardExtended,
    getDashboardSequenceExtended
  ]);

  useEffect(() => {
    const service_id = serviceIdGet(serviceNameCollection[1]);

    props.sustainabilityAccessFlag &&
      !sustainabilityInitialized &&
      service_id &&
      (() => {
        makeServiceDefaultExtended({
          service_id,
          page_no: 1,
          page_size: 100
        });

        getCustomDashboardExtended({
          service_id,
          page_size: 100,
          page_no: 1
        });

        getDashboardSequenceExtended({
          service_id
        });
      })();
  }, [
    props.sustainabilityAccessFlag,
    sustainabilityInitialized,
    makeServiceDefaultExtended,
    getCustomDashboardExtended,
    getDashboardSequenceExtended
  ]);

  useEffect(() => {
    const service_id = serviceIdGet(serviceNameCollection[4]);

    props.operationalExcellenceAccessFlag &&
      !dashboardInitialized &&
      service_id &&
      (() => {
        makeServiceDefaultExtended({
          service_id,
          page_no: 1,
          page_size: 100
        });

        getDashboardSidebarAllDashboard({
          page_no: 1,
          page_size: 100
        });

        getDashboardSequenceExtended({
          service_id: serviceIdGet(serviceNameCollection[0])
        });
      })();
  }, [
    props.operationalExcellenceAccessFlag,
    dashboardInitialized,
    getDashboardSidebarAllDashboard,
    getDashboardSequenceExtended,
    makeServiceDefaultExtended
  ]);

  const onDashboardFormClose = useCallback(() => {
    setUpdate(false);

    setDashboardInput({
      dashboard_name: '',
      showHeader: true,
      refreshTime: 0,
      showDate: false,
      addException: false,
      startOption: '',
      nestedDateRange: ''
    });

    setShowDashboardForm(false);
  }, []);

  return (
    localStorage.getItem('smartsense.authToken') && (
      <Fragment>
        <div
          className={`dashboardSidebar ${
            props.sidebarShow ? 'sidebarActive' : 'sidebarInactive'
          } `}
        >
          {!props.isDashboardHorizontal ? (
            <_Sidebar
              sidebarName={sidebarName}
              sidebarShow={props.sidebarShow}
              serviceIdActive={serviceIdActive}
              selectedId={selectedId}
              pageNo={pageNo}
              sidebarData={sidebarData}
              data={data}
              loading={loading}
              reorderableShow={reorderableShow}
              namedServiceDashboardIdCollection={
                namedServiceDashboardIdCollection
              }
              PAGE_SIZE={PAGE_SIZE}
              sidebarNameCollection={sidebarNameCollection}
              showDashboardOption={showDashboardOption}
              showDashboardSubOption={showDashboardSubOption}
              _onSetSidebarShow={_onSetSidebarShow}
              onSetSelectedId={_setSelectedId}
              onSetPageNo={setPageNo}
              onSetData={setData}
              onSetReorderableShow={setReorderableShow}
              onSetReorderableSuccessToastShow={setReorderableSuccessToastShow}
              onNavigate={navigate}
              onSequenceCreate={sequenceCreate}
              onSetShowDashboardForm={setShowDashboardForm}
              onSetUpdate={setUpdate}
              onDashboardFormClose={onDashboardFormClose}
              onSidebarShowToggle={onSidebarShowToggle}
              onDashboardOptionClose={onDashboardOptionClose}
              onSetDashboardInput={setDashboardInput}
              onSetShowDashboardOption={setShowDashboardOption}
              onSetShowDashboardSubOption={setShowDashboardSubOption}
              onDeleteDashboard={props.deleteDashboard}
            />
          ) : (
            <__Sidebar
              sidebarName={sidebarName}
              serviceIdActive={serviceIdActive}
              selectedId={selectedId}
              data={data}
              loading={loading}
              reorderableShow={reorderableShow}
              namedServiceDashboardIdCollection={
                namedServiceDashboardIdCollection
              }
              showDashboardOption={showDashboardOption}
              showDashboardSubOption={showDashboardSubOption}
              createCustomDataSuccess={props.createCustomData.success}
              sidebarRouteTrigger={props.sidebarRouteTrigger}
              _onSetSidebarShow={_onSetSidebarShow}
              onSetSelectedId={_setSelectedId}
              onSetData={setData}
              onSetReorderableShow={setReorderableShow}
              onSetReorderableSuccessToastShow={setReorderableSuccessToastShow}
              onNavigate={navigate}
              onSequenceCreate={sequenceCreate}
              onSetShowDashboardForm={setShowDashboardForm}
              onSetUpdate={setUpdate}
              onSidebarShowToggle={onSidebarShowToggle}
              onDashboardOptionClose={onDashboardOptionClose}
              onSetDashboardInput={setDashboardInput}
              onSetShowDashboardOption={setShowDashboardOption}
              onSetShowDashboardSubOption={setShowDashboardSubOption}
              onDeleteDashboard={props.deleteDashboard}
            />
          )}
        </div>

        <SidebarCrud
          sidebarName={sidebarName}
          serviceIdActive={serviceIdActive}
          data={data}
          sidebarNameCollection={sidebarNameCollection}
          showDashboardForm={showDashboardForm}
          update={update}
          dashboardInput={dashboardInput}
          selectedId={selectedId}
          onDashboardFormClose={onDashboardFormClose}
          onSetDashboardInput={setDashboardInput}
          createCustomDashboard={props.createCustomDashboard}
          editDashboard={props.editDashboard}
        />
      </Fragment>
    )
  );
};

const mapStateToProps = (state) => {
  const _dashboardServiceId = serviceIdGet(serviceNameCollection[0]);

  const dashboardServiceId = serviceIdGet(serviceNameCollection[4]);

  const sustainabilityServiceId = serviceIdGet(sidebarNameCollection[1]);

  const workplaceServiceId = serviceIdGet(serviceNameCollection[2]);

  const homeServiceId = serviceIdGet(serviceNameCollection[3]);

  const [networkMonitorServiceId, networkInsightServiceId] =
    networkDefinitions.map(({ name }) => serviceIdGet(name));

  return {
    allDashboardData: state.dashboardSidebarAllDashboard,
    dashboardServiceDefaultData:
      state.makeServiceDefaultExtended.data[dashboardServiceId] &&
      state.makeServiceDefaultExtended.data[dashboardServiceId].result,
    dashboardServiceDefaultSuccess:
      state.makeServiceDefaultExtended.success &&
      state.makeServiceDefaultExtended.success[dashboardServiceId],
    dashboardServiceDefaultError:
      state.makeServiceDefaultExtended.error &&
      state.makeServiceDefaultExtended.error[dashboardServiceId],
    dashboardSequenceData:
      state.getDashboardSequenceExtended.data &&
      state.getDashboardSequenceExtended.data[_dashboardServiceId],
    dashboardSequenceSuccess:
      state.getDashboardSequenceExtended.success &&
      state.getDashboardSequenceExtended.success[_dashboardServiceId],
    dashboardSequenceError:
      state.getDashboardSequenceExtended.error &&
      state.getDashboardSequenceExtended.error[_dashboardServiceId],
    sustainabilityServiceDefaultData:
      state.makeServiceDefaultExtended.data[sustainabilityServiceId] &&
      state.makeServiceDefaultExtended.data[sustainabilityServiceId].result,
    sustainabilityServiceDefaultSuccess:
      state.makeServiceDefaultExtended.success &&
      state.makeServiceDefaultExtended.success[sustainabilityServiceId],
    sustainabilityServiceDefaultError:
      state.makeServiceDefaultExtended.error &&
      state.makeServiceDefaultExtended.error[sustainabilityServiceId],
    sustainabilityCustomDashboardData:
      state.getCustomDashboardExtended.data[sustainabilityServiceId] &&
      state.getCustomDashboardExtended.data[sustainabilityServiceId].result,
    sustainabilityCustomDashboardSuccess:
      state.getCustomDashboardExtended.success &&
      state.getCustomDashboardExtended.success[sustainabilityServiceId],
    sustainabilityCustomDashboardError:
      state.getCustomDashboardExtended.error &&
      state.getCustomDashboardExtended.error[sustainabilityServiceId],
    sustainabilitySequenceData:
      state.getDashboardSequenceExtended.data &&
      state.getDashboardSequenceExtended.data[sustainabilityServiceId],
    sustainabilitySequenceSuccess:
      state.getDashboardSequenceExtended.success &&
      state.getDashboardSequenceExtended.success[sustainabilityServiceId],
    sustainabilitySequenceError:
      state.getDashboardSequenceExtended.error &&
      state.getDashboardSequenceExtended.error[sustainabilityServiceId],
    workplaceServiceDefaultData:
      state.makeServiceDefaultExtended.data[workplaceServiceId] &&
      state.makeServiceDefaultExtended.data[workplaceServiceId].result,
    workplaceServiceDefaultSuccess:
      state.makeServiceDefaultExtended.success &&
      state.makeServiceDefaultExtended.success[workplaceServiceId],
    workplaceServiceDefaultError:
      state.makeServiceDefaultExtended.error &&
      state.makeServiceDefaultExtended.error[workplaceServiceId],
    workplaceCustomDashboardData:
      state.getCustomDashboardExtended.data[workplaceServiceId] &&
      state.getCustomDashboardExtended.data[workplaceServiceId].result,
    workplaceCustomDashboardSuccess:
      state.getCustomDashboardExtended.success &&
      state.getCustomDashboardExtended.success[workplaceServiceId],
    workplaceCustomDashboardError:
      state.getCustomDashboardExtended.error &&
      state.getCustomDashboardExtended.error[workplaceServiceId],
    workplaceSequenceData:
      state.getDashboardSequenceExtended.data &&
      state.getDashboardSequenceExtended.data[workplaceServiceId],
    workplaceSequenceSuccess:
      state.getDashboardSequenceExtended.success &&
      state.getDashboardSequenceExtended.success[workplaceServiceId],
    workplaceSequenceError:
      state.getDashboardSequenceExtended.error &&
      state.getDashboardSequenceExtended.error[workplaceServiceId],
    homeServiceDefaultData:
      state.makeServiceDefaultExtended.data[homeServiceId] &&
      state.makeServiceDefaultExtended.data[homeServiceId].result,
    homeServiceDefaultSuccess:
      state.makeServiceDefaultExtended.success &&
      state.makeServiceDefaultExtended.success[homeServiceId],
    homeServiceDefaultError:
      state.makeServiceDefaultExtended.error &&
      state.makeServiceDefaultExtended.error[homeServiceId],
    homeCustomDashboardData:
      state.getCustomDashboardExtended.data[homeServiceId] &&
      state.getCustomDashboardExtended.data[homeServiceId].result,
    homeCustomDashboardSuccess:
      state.getCustomDashboardExtended.success &&
      state.getCustomDashboardExtended.success[homeServiceId],
    homeCustomDashboardError:
      state.getCustomDashboardExtended.error &&
      state.getCustomDashboardExtended.error[homeServiceId],
    homeSequenceData:
      state.getDashboardSequenceExtended.data &&
      state.getDashboardSequenceExtended.data[homeServiceId],
    homeSequenceSuccess:
      state.getDashboardSequenceExtended.success &&
      state.getDashboardSequenceExtended.success[homeServiceId],
    homeSequenceError:
      state.getDashboardSequenceExtended.error &&
      state.getDashboardSequenceExtended.error[homeServiceId],
    networkInsightServiceDefaultSuccess:
      state.makeServiceDefaultExtended.success &&
      state.makeServiceDefaultExtended.success[networkInsightServiceId],
    networkMonitorServiceDefaultSuccess:
      state.makeServiceDefaultExtended.success &&
      state.makeServiceDefaultExtended.success[networkMonitorServiceId],
    createDashboardSequenceData: state.createDashboardSequence,
    createCustomData: state.createCustomDashboard,
    deleteDashboardData: state.deleteDashboard,
    editDashboardData: state.editDashboard,
    createServiceTemplateSuccess: state.createServiceTemplate.success,
    updateServiceTemplateSuccess: state.updateServiceTemplate.success,
    deleteServiceTemplateSuccess: state.deleteServiceTemplate.success
  };
};

export default connect(mapStateToProps, {
  getDashboardSidebarAllDashboard,
  makeServiceDefaultExtended,
  getCustomDashboardExtended,
  getDashboardSequenceExtended,
  createDashboardSequence,
  createDashboardSequenceReset,
  createCustomDashboard,
  resetAddDashboard,
  editDashboard,
  resetEditDashboard,
  deleteDashboard,
  resetDeleteDashboard
})(Sidebar);
