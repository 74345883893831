import React, { Fragment } from "react";

function Accounts(props) {
    let { accountType, is_client } = props;
    return (
        <div className={'pt-20 accounts'}>
            <span className='select-partner-title'><i className="fas fa-window-restore"></i>Select the account you want to access</span>
            <div className='partner-login-radio'>
                {
                    is_client && <label className="partner-login-label">My Account
                        <input type="radio" name="radio" checked={accountType.isTrue && accountType.type == 'adminProfile' ? true : false} onChange={(e) => {
                            props.setAccountType({ isTrue: e.target.checked, type: 'adminProfile' });
                            props.setActiveStep('adminProfile')
                        }} />
                        <span className="checkmark"></span>
                    </label>
                }
                <label className="partner-login-label">My Clients Account
                    <input type="radio" name="radio" checked={accountType.isTrue && accountType.type == 'clientProfile' ? true : false} onChange={(e) => {
                        props.setAccountType({ isTrue: e.target.checked, type: 'clientProfile' });
                        props.setActiveStep('clientProfile')
                    }} />
                    <span className="checkmark"></span>
                </label>
            </div>
        </div>
    );
}

export default Accounts;