import React from 'react';

export default class Icon extends React.Component {
  render () {
    return (
      <div className = 'iconconntainer'>
        <img
          className= {'SSicon '+ this.props.position}
          src = {this.props.src}
          alt = {this.props.alt}
          height = {this.props.size}
          width={this.props.width}
        />
      </div>
    )
  }
}
