import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { httpMethodGetJSON, httpMethodPatch, httpMethodDelete, httpMethodPost, } from '../https';
import { URL } from '../constants';
import * as AppConstants from './constant';


export const getAllEvents = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_EVENTS),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/events/config/`);
    })
);

export const getAllEventLogs = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_EVENT_LOGS),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/events/`);
    })
);

export const checkAllEvent = action$ => action$.pipe(
    ofType(AppConstants.CHECK_ALL_EVENT),
    mergeMap(action => {
        return of({ type: AppConstants.CHECK_ALL_EVENT_SUCCESS, payload: action.payload });
    })
);

export const checkEvent = action$ => action$.pipe(
    ofType(AppConstants.CHECK_EVENT),
    mergeMap(action => {
        return of({ type: AppConstants.CHECK_EVENT_SUCCESS, payload: action.payload });
    })
);

export const checkEventLog = action$ => action$.pipe(
    ofType(AppConstants.CHECK_EVENT_LOG),
    mergeMap(action => {
        return of({ type: AppConstants.CHECK_EVENT_LOG_SUCCESS, payload: action.payload });
    })
);


export const deleteEvent = action$ => action$.pipe(
    ofType(AppConstants.DELETE_EVENT),
    mergeMap((action, a) => {
        return axios.delete(`${URL}/events/${action.payload}`, {
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
            },
            // data: action.payload,
        }).then((response) => {
            return of({ type: AppConstants.DELETE_EVENT_SUCCESS, payload: response.data });
        }).catch(error => {
            return of({
                type: AppConstants.DELETE_EVENT_FAILURE,
                error: true,
                payload: error
            })
        })
    })
);

export const createEvent = action$ => action$.pipe(
    ofType(AppConstants.CREATE_EVENT),
    mergeMap(action => {
        return httpMethodPost(action, `${URL}/events/`);
    })
);

export const updateEvent = action$ => action$.pipe(
    ofType(AppConstants.UPDATE_EVENT),
    mergeMap(action => {
        if (action.payload && action.payload.event_id) {
            return httpMethodPatch({ type: AppConstants.UPDATE_EVENT, payload: action.payload.apiParams }, `${URL}/events/${action.payload.event_id}/`);
        } else {
            return httpMethodPatch(action, `${URL}/events/`);
        }
    })
);

export const eventEpics = {
    getAllEvents,
    getAllEventLogs,
    checkAllEvent,
    checkEvent,
    checkEventLog,
    deleteEvent,
    createEvent,
    updateEvent
}