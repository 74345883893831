import React from 'react';


// always wrap card into col for responsiveness
export default (props) => {
  return (
    <div className={
      'Card ' + props.position + ' ' + 
      (props.padd ? 'withpadding' : '') + ' ' +
      (props.noShadow === true ? 'noshadow' : 'shadow')
    }>
        {props.getCardContent()}
    </div>
  );
}


// position same as icon