import React, { useMemo, useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import { Button, Input, Badge } from '../../../../../common/components/';
import WidgetSelector from '../WidgetSelector/';
import WidgetLayout from '../WidgetLayout';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Context } from '../../../Main/context';
import {
  editDashboard,
  deleteDashboard,
  resetDeleteDashboard,
  getAllWidget,
  deleteWidget,
  resetDeleteWidget,
  createWidget,
  resetCreateWidget,
  resetEditDashboard,
  getAllDashboard,
  createDashboardSequence,
  getDashboardSequence
} from '../../action';
import { 
  mergeConfig, 
  mergeDashboardDataWithConfig, 
  DASHBOARD_ROW_HEIGHT, 
  handleGroupOrContainerWidget 
} from '../../widgetsConfig';
import dashboardFilterConfigInputGet from '../WidgetBuilder/ConfigComponents/common/dashboardFilterConfigInputGet';

let WIDGET_OPTIONS = ["Energy", "Factories", "GHG Emissions", "What-If Energy", "Energy Consumption Mix", "Parameter Tracking", "Insight-alert-summary", "Alert List", "Live Data", "List View", "Asset Health Count", "Asset Detail List", "Asset Insight & Alert", "Asset Event Log", "Weather Forecast", "Workplace Overview", "Workplace Graph", "Project Status", "Network View", "Section", "Cxo Summary", "Smart Info", "Scorecard", "Facility", "KPI Tracker", 'Asset Performance']
const GRADIENT = ["Scorecard", "Smart Info", "Health", "KPI Tracker"]

const ResponsiveGridLayout = WidthProvider(Responsive);

//const DISABLE_SECTION = ['chintansoni@ecolibrium.io', 'CF@ecolibrium.io' ];
const USERNAME = localStorage.getItem('username');
var widgetPositionSize = [];
const PAGE_SIZE = 500;


function EditDashboard(props) {
  var routerState = props.location.state;
  const context = useContext(Context);

  var [selectedWidgetIndex, selectWidget] = useState(0);
  var [isOpen, setModal] = useState(false);
  var [dashboardName, setDashboardName] = useState(routerState.name || routerState.dashboard_name);
  var [sectionWiseWidgetsData, setSectionWiseWidgetsData] = useState([]);

  const [nextTick, setNextTick] = useState();

  useEffect(() => {
    nextTick && setNextTick();
  }, [nextTick]);

  useEffect(() => {
    getWidgetApi();
  }, []);

  useEffect(() => {
    adjustSeperators();
  }, [sectionWiseWidgetsData]);

  useEffect(() => {
    let sortedSectionWiseData = {};

		const widgetsData =
      props.widgetsData &&
      props.widgetsData.reduce((memo, _widgetData, index, _widgetsData) => {
        const value = (() => {
          const widthGet = (_width, config) => {
            const width = Object.values(config)
              .map(({ required, percentageWidth }) => ({
                required,
                percentageWidth
              }))
              .reduce((memo, { required, percentageWidth }) => {
                return required
                  ? memo
                  : memo - (percentageWidth * _width) / 100;
              }, _width);

            return width;
          };

          switch (_widgetData.type) {
            case 'Health':
              return _widgetData.config.sensorHealthConfigExtended &&
                _widgetData.config.sensorHealthConfigExtended
                  .configurationType === 'custom' &&
                !_widgetsData.some(
                  ({
                    type,
                    config: {
                      sensorHealthConfigExtended: { configurationType } = {}
                    }
                  }) => {
                    return type === 'Health' && configurationType !== 'custom';
                  }
                )
                ? (() => {
                    WIDGET_OPTIONS = [...WIDGET_OPTIONS, _widgetData.type];

                    const pos =
                      _widgetData.config.sensorHealthConfigExtended.pos;

                    const customConfiguration =
                      _widgetData.config.sensorHealthConfigExtended
                        .customConfiguration;

                    return pos && customConfiguration
                      ? {
                          ..._widgetData,
                          minWidgetWidth: widthGet(
                            pos.minW,
                            customConfiguration
                          ),
                          maxWidgetWidth: pos.maxW,
                          minWidgetHeight: pos.minH,
                          maxWidgetHeight: pos.maxH,
                          width: widthGet(pos.w, customConfiguration),
                          height: pos.h,
                          custom: true
                        }
                      : _widgetData;
                  })()
                : (() => {
                    WIDGET_OPTIONS = WIDGET_OPTIONS.filter(
                      (WIDGET_OPTION) => WIDGET_OPTION !== _widgetData.type
                    );

                    return _widgetData;
                  })();

            case 'GIS Plant':
              return _widgetData.config.parameters.mapShowToggle
                ? (() => {
                    WIDGET_OPTIONS = [...WIDGET_OPTIONS, _widgetData.type];

                    return {
                      ..._widgetData,
                      minWidgetWidth: _widgetData.config.parameters.posW,
                      minWidgetHeight: _widgetData.config.parameters.posH
                    };
                  })()
                : (() => {
                    WIDGET_OPTIONS = WIDGET_OPTIONS.filter(
                      (WIDGET_OPTION) => WIDGET_OPTION !== _widgetData.type
                    );

                    return _widgetData;
                  })();

            case 'Network View':
              return _widgetData.config.networkExpand
                ? (() => {
                    const posH = _widgetData.config.posH;

                    return {
                      ..._widgetData,
                      minWidgetHeight: posH,
                      maxWidgetHeight: posH
                    };
                  })()
                : _widgetData;

            case 'Graph':
              return _widgetData.config.graphConfig.isMiniGraph
                ? {
                    ..._widgetData,
                    width: _widgetData.minWidgetWidth
                  }
                : _widgetData;

            case 'Scorecard':
              return (() => {
                switch (true) {
                  case _widgetData.config.showActualPercentage:
                    return {
                      ..._widgetData,
                      width: 2.7,
                      height: 1.1
                    };

                  case _widgetData.config.locationBasedParamSelector
                    .isComparison:
                    return {
                      ..._widgetData,
                      width: 4,
                      height: 1
                    };

                  default:
                    return _widgetData;
                }
              })();

            default:
              return _widgetData;
          }
        })();

        return [...memo, value];
      }, []);

    // getSectionWise Widget
    let sectionWiseData = _.groupBy(widgetsData, function (widget) {
      return widget.config.section && widget.config.section.length > 0 ? widget.config.section : '';
    });

    // getSectionWise sorted Widget
    for (let sectionName in sectionWiseData) {
      sortedSectionWiseData[sectionName] = _.orderBy(
        sectionWiseData[sectionName],
        ['config.resize_position.y', 'config.resize_position.x'],
        ['asc'],
      );
    }

    setSectionWiseWidgetsData(sortedSectionWiseData);
  }, [props.widgetsData]);

  useEffect(()=>{
    if(!props.dashboardSequenceData){
      props.getDashboardSequence({
        service_id:routerState.serviceId
      })
    }
  },[props.dashboardSequenceData])

  useEffect(() => {
    if (props.editDashboardData.success === true) {
      let dashbaordDatas = props.dashboardSequenceData.config
      let targetIndex = dashbaordDatas.findIndex((dashbaord)=>(dashbaord.id || dashbaord.dashboard_id) == (routerState.id || routerState.dashboard_id));
      let editdash = props.editDashboardData.data.response;
      dashbaordDatas.splice(targetIndex, 1, {...editdash, dashboard_id:editdash.id ,dashboard_name:editdash.name})
      createDashboardOrder(dashbaordDatas)
      props.resetEditDashboard();
      props.getAllDashboard({
        "page_no": 1,
        "page_size": PAGE_SIZE
      });

      context.notify.success('Dashboard Edited');
      props.history.goBack();
    }
  }, [props.editDashboardData]);

  useEffect(() => {
    if (props.deleteWidgetData.success === true) {
      props.resetDeleteWidget();
      getWidgetApi();
      context.notify.success(props.deleteWidgetData.data.response.message);
    }
  }, [props.deleteWidgetData]);

  useEffect(() => {
    if (props.cloneWidgetData.success === true) {
      props.resetCreateWidget();
      getWidgetApi();
      context.notify.success('Widget Cloned');
    }


    if(props.cloneWidgetData.error === true){
      props.resetCreateWidget();
      context.notify.error(props.cloneWidgetData.data.response.message);
    }
  }, [props.cloneWidgetData]);

  useEffect(() => {
    if (props.deleteDashboardData.success === true) {
      props.resetDeleteDashboard();
      context.notify.success('Dashboard Deleted');
      props.history.goBack();
    }
  }, [props.deleteDashboardData]);

  const createDashboardOrder = (dashboardDatas)=>{
    dashboardDatas.map((item, index)=>item.sequence = index)
    props.createDashboardSequence({
      config: dashboardDatas,
      service_id:routerState.serviceId
    })
  }

  const getWidgetApi = (page_no = 1, page_size = PAGE_SIZE) => {
    props.getAllWidget({ 
      id: routerState.id || routerState.dashboard_id,
      page_no,
      page_size
    });
  }

  function openModal() {
    setModal(true);
  }

  function closeModal() {
    setModal(false);
  }

  function setWigetSelected(index) {
    selectWidget(index);
  }

  function openAddWidgetConfiguration() {
    props.history.push(
      `/Dashboard/WidgetBuilder/${routerState.id || routerState.dashboard_id}/${props.allWidgetConfig[selectedWidgetIndex].id}`,
    );
  }

  function addWidget() {
    openModal();
  }

  function getNoWidgetScreen() {
    return (
      <div className="first-add-widget" onClick={addWidget}>
        <div className="first-add-widget-container">
          <div className="first-add-widget-icon">
            <span style={{ fontSize: '50px' }}>
              <i className="fa fa-plus" aria-hidden="true"></i>
            </span>
          </div>
          <div className="first-add-widget-text">Add your first widget</div>
        </div>
      </div>
    );
  }

  function renderModal() {
    return (
      <WidgetSelector
        allWidgetConfig={props.widgetConfig}
        isOpen={isOpen}
        openAddWidgetConfiguration={openAddWidgetConfiguration}
        setWigetSelected={setWigetSelected}
        closeModal={closeModal}
        selectedWidgetIndex={selectedWidgetIndex}
      />
    );
  }

  function getHeader() {
    return (
      <Row className="dashboard-container-header">
        <Col xl={6} className={isOpen ? 'add-new-widget-button modal-open' : 'add-new-widget-button'}>
          <Button buttonType="link" size="md" buttonClick={addWidget} innerContent="+ Add New Widget" />
        </Col>
        <Col className="edit-dashboard-buttons" xl={6}>
          <Button
            buttonType="danger"
            size="md"
            buttonClick={() => {
              context.confirm.show(`Are you sure you want to delete ${routerState.name || routerState.dashboard_name} ?`, () => {
                props.deleteDashboard({
                  id: routerState.id || routerState.dashboard_id,
                  // "showSuccessMsg": true
                });
              });
            }}
            innerContent="Delete Dashboard"
          />
          <Button
            buttonType="secondary"
            size="md"
            buttonClick={() => {
              if (!dashboardName) {
                context.notify.error('Please Enter Valid Dashboard Name');
                return;
              }

              if (dashboardName.length > 20) {
                context.notify.error('Dashboard Name Should Consist Of Less Than 20 Character');
                return;
              }

              if (/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?@_()]/g.test(dashboardName)) {
                context.notify.error('Dashboard Name Should Not Contain Special Characters');
                return;
              }

              let order = {};
              let resize = {};

              let sortedWidgetPositionSize = _.orderBy(widgetPositionSize, ['y', 'x'], ['asc']);

              for (let section in sectionWiseWidgetsData) {
                let widgetsData = sectionWiseWidgetsData[section];
                widgetsData.map((wid) => {
                  let index;
                  var found = sortedWidgetPositionSize.find(function (pos, i) {
                    if (pos.i == wid.id) {
                      index = i;
                      return true;
                    }
                  });

                  if (found) {
                    // order set
                    order[found.i] = index;
                    resize[found.i] = {
                      ...wid.config,
                      ...{ resize_position: found },
                      ...{ section: section },
                    };
                  }
                });
              }

              props.editDashboard({
                id: routerState.id || routerState.dashboard_id,
                name: dashboardName,
                description: '',
                sequence: routerState.sequence,
                widget_order: order,
                widget_resize: resize,
              });
              props.history.goBack();
            }}
            innerContent="Save changes"
          />
          <Button
            buttonType="secondary"
            size="md"
            buttonClick={() => {
              props.history.goBack();
            }}
            innerContent="Cancel"
          />
        </Col>
      </Row>
    );
  }

  function openTvmode(widget, widgetIndex) {
    // select widget as we dont allow to drag and resize in tvmode
    let layout = document.querySelectorAll('#edit-dashboard-container .dashboard-layout .widget')[0];
    let elem = layout.children[widgetIndex];

    if (elem) {
      context.fullScreen.open(elem);
    }
  }

  function openWidgetConfiguration(widget, widgetIndex) {
    var wid = props.dashboardData.filter((item) => {
      return item.id === widget.id;
    });

    var final = wid[0];

    props.history.push({
      pathname: `/Dashboard/WidgetBuilder/${routerState.id || routerState.dashboard_id}/${widget.widget_id.id}?edit_id=${widget.id}`,
      state: { widget: final },
    });
  }

  function cloneWidget(widget, widgetIndex) {
    let params = { ...widget.config };
    params.widget_name = params.widget_name + ' - Copy';

    props.createWidget({
      id: routerState.id || routerState.dashboard_id,
      widget_id: widget.widget_id.id,
      section: '',
      sequence: null,
      config: JSON.stringify(params),
      // "showSuccessMsg": true
    });
  }

  function deleteWidget(widget, widgetIndex) {
    let confirm = window.confirm(`Are you sure you want to delete`);

    if (confirm) {
      props.deleteWidget({
        dashboardId: widget.dashboard_id.id,
        id: widget.id,
      });
    }
  }

	const dashboardFilterConfig = useMemo(() => {
    return dashboardFilterConfigInputGet();
  }, []);

  function getWidget(widget, i) {
    let config = widget.config;

    return (
      <WidgetLayout
        newWidget={WIDGET_OPTIONS.includes(widget.widget_id.type)}
        name={widget.config.widget_name}
        width={widget.config.width}
        description={widget.config.widget_description}
        hideGradient={GRADIENT.includes(widget.widget_id.type)}
        isMiniGraph={
          widget.widget_id.type === 'Graph' &&
          widget.config.graphConfig.isMiniGraph
        }
        editWidget={() => {
          openWidgetConfiguration(widget, i);
        }}
        deleteWidget={() => {
          deleteWidget(widget, i);
        }}
        cloneWidget={() => {
          cloneWidget(widget, i);
        }}
        openTvmode={() => {
          openTvmode(widget, i);
        }}
      >
        <widget.view
          dashboardAppConfig={config}
          widgetId={widget.id}
          dashboardFilterConfig={dashboardFilterConfig}
        />
      </WidgetLayout>
    );
  }

  const adjustSeperators = () => {
    let widgets = document.querySelectorAll('.dashboard-layout .widget-container');
    let seperator = document.querySelectorAll('.dashboard-layout-container .widget-seperator');
    let widgetsPosition = {};

    if (widgets.length > 0) {
      for (let i = 0; i < widgets.length; i++) {
        let wid = widgets[i];
        widgetsPosition[wid.getAttribute('widget-id')] = wid.style.transform;
      }
    }

    // sort widgets before picking first widget

    let seperatorGroup = document.querySelectorAll('.dashboard-layout-container .widget-seperator-name');

    if (widgetsPosition) {
      for (let j = 0; j < seperatorGroup.length; j++) {
        let seperatorGroupElem = seperatorGroup[j];
        if (widgetsPosition[seperatorGroupElem.getAttribute('widget-id')]) {
          let pos = getYTranslateValue(widgetsPosition[seperatorGroupElem.getAttribute('widget-id')]);

          if (pos) {
            seperatorGroupElem.style.transform = `translate(15px, ${pos})`;
          }
        }
      }
    }
  };

  const addNewSeperatorAdjust = () => {
    let seperator = document.querySelectorAll('.dashboard-layout-container .widget-seperator');
    let widgets = document.querySelectorAll('.dashboard-layout .widget-container');
    let widgetsPosition = {};

    if (widgets.length > 0) {
      for (let i = 0; i < widgets.length; i++) {
        let wid = widgets[i];
        widgetsPosition[wid.getAttribute('widget-id')] = wid.style.transform;
      }
    }

    if (widgetsPosition) {
      for (let j = 0; j < seperator.length; j++) {
        let seperatorElem = seperator[j];
        let translateVal = widgetsPosition[seperatorElem.getAttribute('widget-id')];

        if (translateVal) {
          let matches = translateVal.match(/\((.*?)\)/);
          if (matches) {
            var submatch = matches[1].split(',')[1];
          }
          seperatorElem.style.transform = `translate(15px, ${submatch})`;
        }
      }
    }
  };

  const getYTranslateValue = (translateVal) => {
    let submatch = '';
    let matches = translateVal.match(/\((.*?)\)/);
    if (matches) {
      submatch = matches[1].split(',')[1];
    }

    return submatch;
  };

  function getDashboard() {
    var layout = { lg: [] };

    let widgetsElem = [];
    let seperatorsGroup = [];
    let addSection = [];
    let lastWid = _.maxBy(props.widgetsData, 'config.resize_position.y'); 

    for (const sectionName in sectionWiseWidgetsData) {
      let widgetSection = sectionWiseWidgetsData[sectionName];
      let widgetGroupedByRow = _.groupBy(widgetSection, function (widget) {
        if (!widget) return;

        return widget.config && widget.config.resize_position ? widget.config.resize_position.y : '';
      });

      widgetSection.map((widget, i) => {
        if (!widget) return;

        let pos = {
          i: widget.id,
          x: 0,
          y: 0,
          w: widget.width ? widget.width : 4,
          h: widget.height ? widget.height : 1,
          minW: 4,
          maxH: 4,
          minH: 2,
          maxW: 12
        };

        if(
          lastWid 
          && lastWid.config 
          && lastWid.config.resize_position 
          && lastWid.config.resize_position.y !== undefined
        ){
          pos.y = lastWid.config.resize_position.y + 1;
        }

        if (widget.config && widget.config.resize_position) {
          //pos = widget.config.resize_position;
          pos = {
            ...widget.config.resize_position,
            ...(() => {
              switch (true) {
                case widget.type === 'Health' && widget.custom:
                  return (() => {
                    const { w, h } = pos;

                    return { w, h, isResizable: false };
                  })();

                case widget.type === 'Graph' &&
                  widget.config.graphConfig.isMiniGraph:
                  return {
                    w: widget.minWidgetWidth,
                    h: widget.minWidgetHeight,
                    isResizable: false
                  };

                case widget.type === 'Scorecard' &&
                  widget.config.locationBasedParamSelector.isComparison:
                  return (() => {
                    const { h } = pos;

                    return {
                      h: Math.max(1, h)
                    };
                  })();

                default:
                  return (() => {
                    const { w, h } = widget.config.resize_position;

                    return { w, h };
                  })();
              }
            })()
          };

          pos.i = widget.id;
        }

        // set widget min width from config
        if (widget.minWidgetWidth) {
          pos.minW = widget.minWidgetWidth;
          pos.w = pos.w < widget.minWidgetWidth ? widget.minWidgetWidth : pos.w;
        }

        // set widget max width from config
        if (widget.maxWidgetWidth) {
          pos.maxW = widget.maxWidgetWidth;
          pos.w = pos.w > widget.maxWidgetWidth ? widget.maxWidgetWidth : pos.w;
        }

        // set widget min height from config
        if (widget.minWidgetHeight) {
          pos.minH = widget.minWidgetHeight;
          pos.h = pos.h < widget.minWidgetHeight ? widget.minWidgetHeight : pos.h;
        }

        // set widget max height from config
        if (widget.maxWidgetHeight) {
          pos.maxH = widget.maxWidgetHeight;
          pos.h = pos.h > widget.maxWidgetHeight ? widget.maxWidgetHeight : pos.h;
        }

        layout.lg.push(pos);

        widgetsElem.push(
          <div
            key={widget.id}
            className="widget-container edit-mode"
            widget-id={widget.id}
            // onDoubleClick = {()=>{
            // var person = prompt("Please enter your name", '');

            // if (person != null) {
            //   let oldState  = {...sectionWiseWidgetsData};
            //   let oldWid = oldState[sectionName][i];

            //   oldWid.config.resize_position.x = 0;
            //   oldWid.config.resize_position.y = Infinity;

            //   if(Array.isArray(oldState[person])){
            //     oldState[person].push(oldWid);
            //   } else {
            //     oldState[person] = []
            //     oldState[person].push(oldWid);
            //   }

            //   delete oldState[sectionName][i];

            //   setSectionWiseWidgetsData(oldState);
            // };
            // }}
          >
            {/* {sectionName} */}
            {getWidget(widget, i)}
          </div>,
        );
      });

      //for (const widgetRow in widgetGroupedByRow) {
        //if (Object.keys(widgetGroupedByRow).indexOf(widgetRow) === 0) continue;

        //if (
          //widgetGroupedByRow[widgetRow] &&
          //widgetGroupedByRow[widgetRow][0] &&
          //widgetGroupedByRow[widgetRow][0].id
        //) {
          //if(DISABLE_SECTION.includes(USERNAME)) {
            //continue;
          //}

          //addSection.push(
            //<div
              //row={widgetRow.toString()}
              //className={`widget-seperator`}
              //widget-id={widgetGroupedByRow[widgetRow][0].id}
              //onClick={(e) => {
                //var person = prompt('Please add section name', '');

                //if (person != null) {
                  //let oldState = { ...sectionWiseWidgetsData };

                  //let oldWids = oldState[sectionName] || [];

                  //oldWids.map((w, i) => {
                    //if (
                      //w &&
                      //w.config &&
                      //w.config.resize_position &&
                      //w.config.resize_position.y &&
                      //w.config.resize_position.y >= widgetRow
                    //) {
                      //if (Array.isArray(oldState[person])) {
                        //oldState[person].push(w);
                      //} else {
                        //oldState[person] = [];
                        //oldState[person].push(w);
                      //}

                      //delete oldState[sectionName][i];
                    //}
                  //});

                  //setSectionWiseWidgetsData(oldState);
                //}
              //}}
            //>
              //<Badge
                //innerContent={<div className="seperator-addnew-text">Add New Section</div>}
                //type="normal"
                //isPillType={true}
                //size={'lg'}
              ///>
            //</div>,
          //);
        //}
      //}

      // let firstWidgetId = sectionWiseWidgetsData[sectionName][0].id;
      //let sortedWidgets = _.orderBy(widgetSection, ['y', 'x'], ['asc']);

      //!DISABLE_SECTION.includes(USERNAME) && sortedWidgets[0] &&
        //sortedWidgets[0].id &&
        //seperatorsGroup.push(
          //<div
            //className={`${sectionName}_seperator_name widget-seperator-name`}
            //data-id={sectionName}
            //widget-id={sortedWidgets[0].id}
          //>
            //<span className="widget-seperator-name-container" data-id={sectionName}>
              //<Input
                //name=""
                //value={sectionName}
                //label=""
                //type="text"
                //size="md"
                //inputChanged={(val, isInvalid) => {
                  //if (val === sectionName) return;

                  //let oldState = { ...sectionWiseWidgetsData };
                  //oldState[val] = oldState[sectionName];
                  //delete oldState[sectionName];

                  //setSectionWiseWidgetsData(oldState);
                //}}
                //placeholder="Enter Section Name"
                //required={false}
                //width={12}
              ///>
            //</span>
            //<span
              //className="widget-seperator-delete"
              //onClick={() => {
                //context.confirm.show(`Are you sure you want to delete seperator ?`, () => {
                  //let oldState = { ...sectionWiseWidgetsData };
                  //let prevSectionName = '';
                  //let seperators = document.querySelectorAll(
                    //'.dashboard-layout-container .widget-seperator-name',
                  //);

                  //let layoutPos = _.groupBy([...seperators], (sep) => {
                    //return sep.style.transform ? parseInt(getYTranslateValue(sep.style.transform), 10) : '';
                  //});

                  //for (var pos in layoutPos) {
                    //if (layoutPos[pos] && layoutPos[pos][0] && layoutPos[pos][0]) {
                      //if (layoutPos[pos][0].getAttribute('data-id') === sectionName) {
                        //let sepIndex = Object.keys(layoutPos).indexOf(pos) - 1;
                        //if (sepIndex >= 0) {
                          //prevSectionName = layoutPos[Object.keys(layoutPos)[sepIndex]][0].getAttribute(
                            //'data-id',
                          //);
                          //break;
                        //}
                      //}
                    //}
                  //}

                  //if (Array.isArray(oldState[prevSectionName])) {
                    //oldState[prevSectionName] = [...oldState[prevSectionName], ...oldState[sectionName]];
                  //} else {
                    //oldState[prevSectionName] = [];
                    //oldState[prevSectionName] = oldState[sectionName];
                  //}

                  //delete oldState[sectionName];

                  //setSectionWiseWidgetsData(oldState);
                  //addNewSeperatorAdjust();
                  //adjustSeperators();
                //});
                //// context.confirm.show(`Are you sure you want to delete ${sectionName}`, () => {
                //// })
              //}}
            //>
              //Delete
            //</span>
          //</div>,
        //);
    }

    return (
      <div id="dashboard-layout-container" className="dashboard-layout-container" onLoad={adjustSeperators}>
        {addSection}
        {seperatorsGroup}
        <ResponsiveGridLayout
          className="dashboard-layout"
          layouts={layout}
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 12 }}
          rowHeight={DASHBOARD_ROW_HEIGHT}
          width={1200}
          //margin={DISABLE_SECTION.includes(USERNAME) ? [20, 20] : [20, 60]}
          margin={[20, 20]}
          //compactType="horizontal"
          // autoSize = {true}
          // preventCollision = {true}
          onLayoutChange={(currentLayout) => {
            widgetPositionSize = currentLayout;

            localStorage.setItem('dl', JSON.stringify(currentLayout));

            let formattedLayout = _.keyBy(currentLayout, 'i');

            let oldState = { ...sectionWiseWidgetsData };

            for (const sectionName in oldState) {
              let section = oldState[sectionName];

              section.map((wid, i) => {
                oldState[sectionName][i].config.resize_position = {
                  ...formattedLayout[wid.id],
                  ...(wid.type === 'Graph' &&
                    wid.config.graphConfig?.isMiniGraph &&
                    wid.id === formattedLayout[wid.id].i && {
                      w: wid.minWidgetWidth,
                      h: wid.minWidgetHeight,
                      x: (() => {
                        const { x } = Array.from({ length: 5 }).reduce(
                          (memo, _, index) => {
                            const x = index * wid.minWidgetWidth;

                            const delta = Math.abs(
                              formattedLayout[wid.id].x - x
                            );

                            return delta < memo.delta ? { x, delta } : memo;
                          },
                          { value: null, delta: Number.POSITIVE_INFINITY }
                        );

                        return x;
                      })()
                    })
                };
              });
            }

            setNextTick(true);

            setSectionWiseWidgetsData(oldState);

            addNewSeperatorAdjust();

            adjustSeperators();
          }}
          onDragStop={(layout, oldDragItem, l, m, elem) => {
            // remove section update state
            // add new section
            // getall sections find first previous section and add
            let widgetId = l.i;
            let widgetsElemDom = document.querySelectorAll('.dashboard-layout .widget-container');

            let foundSibWidget = {};

            let sortedlayout = _.orderBy(layout, ['y', 'x'], ['asc']);

            sortedlayout.map((wid, i) => {
              if (widgetId == wid.i) {
                foundSibWidget = sortedlayout[i - 1];
              }
            });

            if (!foundSibWidget) return;

            let oldState = { ...sectionWiseWidgetsData };
            let foundSibWidgetConfig = {};
            for (const sectionName in oldState) {
              let section = oldState[sectionName];
              section.map((wid) => {
                if (foundSibWidget.i === wid.id) {
                  foundSibWidgetConfig = wid;
                }
              });
            }

            if (!foundSibWidgetConfig) return;

            for (const sectionName in oldState) {
              let sec = oldState[sectionName];
              sec.map((wid, i) => {
                if (widgetId == wid.id) {
                  let section = foundSibWidgetConfig.config.section || '';
                  wid.config.section = section;
                  oldState[section].push(wid);
                  delete oldState[sectionName][i];
                }
              });
            }
            setSectionWiseWidgetsData(oldState);
            addNewSeperatorAdjust();
            adjustSeperators();
          }}
        >
          {widgetsElem}
        </ResponsiveGridLayout>
      </div>
    );
  }

  return (
    <div className="edit-dashboard-container" id="edit-dashboard-container">
      <Row className="appHeader">
        <Input
          name="dashboard"
          value={dashboardName}
          label=""
          type="text"
          inputChanged={(val, isInvalid) => {
            setDashboardName(val);
          }}
          placeholder="Enter Dashboard Name"
          disabled={false}
          required={true}
          width={3}
        />
      </Row>
      <div className="appBody">
        {getHeader()}
        {props.loaded
          ? Object.keys(sectionWiseWidgetsData).length > 0
            ? getDashboard()
            : getNoWidgetScreen()
          : 'Loading...'}
        {renderModal()}
      </div>
    </div>
  );
}

const mapStateToProps = (state, {location}) => {
  let loaded = state.allWidgets.success !== undefined;
  let widgetsData = state.allWidgets.data;
  let allWidgetConfig = JSON.parse(localStorage.getItem('allWidgetConfig'));
  let dashboardSequenceData;
  if(location.state && location.state.serviceId){
    dashboardSequenceData = state.getDashboardSequence.data && state.getDashboardSequence.data[location.state.serviceId] && state.getDashboardSequence.data[location.state.serviceId]
  }
  return {
    widgetsData: handleGroupOrContainerWidget(mergeDashboardDataWithConfig(widgetsData)),
    dashboardData: widgetsData,
    loaded: loaded,
    editDashboardData: state.editDashboard,
    deleteDashboardData: state.deleteDashboard,
    widgetConfig: mergeConfig(allWidgetConfig),
    deleteWidgetData: state.deleteWidget,
    cloneWidgetData: state.createWidget,
    allWidgetConfig: allWidgetConfig,
    dashboardSequenceData: dashboardSequenceData,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    editDashboard,
    deleteDashboard,
    resetDeleteDashboard,
    getAllWidget,
    createWidget,
    resetCreateWidget,
    deleteWidget,
    resetDeleteWidget,
    resetEditDashboard,
    getAllDashboard,
    createDashboardSequence,
    getDashboardSequence,
  })(EditDashboard),
);