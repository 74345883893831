import {
  SHOW_CONFIRMBOX,
  HIDE_CONFIRMBOX
} from '../constants';

const INITIAL_STATE = {
  content: 'content',
  onOk: ()=>{},
  onCancle: ()=>{}
}

export function showConfirmBoxReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_CONFIRMBOX:
      return {
        open:true,
        ...state,
        ...action.payload
      }
      break
    case HIDE_CONFIRMBOX:
      return INITIAL_STATE
    break;
    default:
      return state
  }
}