import {
    GET_BILLING_METER_DATA,
    GET_BILLING_METER_DATA_SUCCESS,
    GET_BILLING_METER_DATA_FAILURE
} from "./constant";

const INITIAL_STATE = {
    data: [],
    loading: false,
    error: false
};

export function getBillingMeterDataReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_BILLING_METER_DATA:
            return {
                ...state,
                loading: true,
                data: INITIAL_STATE.data,
                error: false
            };
        case GET_BILLING_METER_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_BILLING_METER_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: INITIAL_STATE.data,
                loading: false
            };
        default:
            return state
    }
}
