/**
 * All epics reqiured for widget 
 */
import Config from 'Config'
import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";
import {
  ALERT_GENERATION_OVERVIEW
} from './constant';
import { httpMethodGetJSON } from '../../https';

const URL = Config.api_url;
const GEN_OVERVIEW_URL = `${URL}/alerts/overview-summary/`;

export const getAlertGenOverview = (action$) =>
  action$.pipe(
    ofType(ALERT_GENERATION_OVERVIEW),
    mergeMap((action) => {
      return httpMethodGetJSON(action, GEN_OVERVIEW_URL);
    }),
  );