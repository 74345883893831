import tagFormulaConfInputGet from './tagFormulaConfInputGet';

export default (tagConfs) => {
  return tagConfs
    ? tagConfs.reduce((memo, tagConf, index) => {
        const value = {
          ...tagConf,
          index
        };

        return [...memo, value];
      }, [])
    : [tagFormulaConfInputGet()];
};
