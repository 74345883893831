import { ajax } from 'rxjs/ajax';
// import { useClientContext } from './Main/context';
import axios from 'axios';
import { of, concat } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ERROR, SUCCESS_TOAST, WEATHER_APP_ID } from '../../admin/container/constants';


export const getClientIdFromLocalStorage = () => {
  return localStorage.getItem('asset_client_id');
};

const localStorageItemGet = (key) => {
  const item = localStorage.getItem(key);

  return item && JSON.parse(item);
};

export const localStorageIsSmeClientGet = () => {
  return ((localStorageItemGet('smartsense.member') || {}).clientid || {})
    .is_sme_client;
};

/**
 * To reuse this need to follow some rules like success failure constants
 * 
 * common method to call ajax get method with params
 * 
 * @param {*} action {type: 'ACTION_NAME', payload: {a:'b'}} 
 */
export const httpMethodGetJSON = (action, url = '', header = setHeader()) => {
  let actionSuccessConstant = `${action.type}_SUCCESS`;
  let actionFailureConstant = `${action.type}_FAILURE`;
  let searchQuery = getQueryString(action.payload);

    // Get the current pathname
  const currentPath = window.location.pathname;

  return concat(
    of({
      type: 'API_LOADING',
      payload: ''
    }),
    ajax
      .getJSON(
        `${url}${searchQuery}${
          localStorageIsSmeClientGet() &&
          (currentPath === '/CBM' ||
            currentPath === '/Templates/ServiceDetails')
            ? `${
                searchQuery && searchQuery.length ? '&' : '?'
              }client_id=${getClientIdFromLocalStorage()}`
            : ''
        }`,
        header
      )
      .pipe(
        mergeMap((response) => {
          let actions = [
            { type: actionSuccessConstant, response, param: action.payload },
            { type: 'API_IDLE', payload: '' }
          ];

          if (action.payload && action.payload.showSuccessMsg) {
            actions.push({ type: SUCCESS_TOAST, payload: response.response });
          }

          return of(...actions);
        }),
        catchError((error) =>
          of(
            {
              type: actionFailureConstant,
              payload: error,
              error: true,
              param: action.payload
            },
            { type: 'API_IDLE', payload: '' },
            {
              type: ERROR,
              payload: error
            }
          )
        )
      )
  );
} 


export const httpMethodPost = (action, url = '', header = setHeader()) => {
  let actionSuccessConstant = `${action.type}_SUCCESS`;
  let actionFailureConstant = `${action.type}_FAILURE`;
  let searchQuery = ''

  return concat(
    of({
      type: 'API_LOADING', payload: ''
    }),
    ajax.post(
    `${url}${searchQuery}`,
      // params
    action.payload,
    header
  ).pipe(
    mergeMap(response => {
      let actions = [
        { type: actionSuccessConstant, response, param: action.payload },
        { type: 'API_IDLE', payload: '' }
      ];

      if (action.payload && action.payload.showSuccessMsg) {
        actions.push({ type: SUCCESS_TOAST, payload: response.response})
      }

      return of(...actions)
    }),
    catchError(error => of(
      {
        type: actionFailureConstant,
        payload: error,
        error: true,
        param: action.payload
      },
      { type: 'API_IDLE', payload: ''},
      {
        type: ERROR, payload: error
      }
    ))
  ))
}

export const httpMethodPut = (action, url = '', header = setHeader()) => {
  let actionSuccessConstant = `${action.type}_SUCCESS`;
  let actionFailureConstant = `${action.type}_FAILURE`;
  let searchQuery = ''

  return concat(
    of({
      type: 'API_LOADING', payload: ''
    }),
    ajax.put(
      `${url}${searchQuery}`,
      // params
      action.payload,
      header
    ).pipe(
      mergeMap(response => {
        let actions = [
          { type: actionSuccessConstant, response, param: action.payload },
          { type: 'API_IDLE', payload: '' }
        ];

        if (action.payload && action.payload.showSuccessMsg) {
          actions.push({ type: SUCCESS_TOAST, payload: response.response })
        }

        return of(...actions)
      }),
      catchError(error => of(
        {
          type: actionFailureConstant,
          payload: error,
          error: true
        },
        { type: 'API_IDLE', payload: '' },
        {
          type: ERROR, payload: error
        }
      ))
    ))
}

export const httpMethodPatch = (action, url = '', header = setHeader()) => {
  let actionSuccessConstant = `${action.type}_SUCCESS`;
  let actionFailureConstant = `${action.type}_FAILURE`;
  let searchQuery = ''

  return concat(
    of({
      type: 'API_LOADING', payload: ''
    }),
    ajax.patch(
      `${url}${searchQuery}`,
      // params
      action.payload,
      header
    ).pipe(
      mergeMap(response => {
        let actions = [
          { type: actionSuccessConstant, response, param: action.payload },
          { type: 'API_IDLE', payload: '' }
        ];

        if (action.payload && action.payload.showSuccessMsg) {
          actions.push({ type: SUCCESS_TOAST, payload: response.response })
        }

        return of(...actions)
      }),
      catchError(error => of(
        {
          type: actionFailureConstant,
          // payload: [],
          payload: error,
          error: true
        },
        { type: 'API_IDLE', payload: '' },
        {
          type: ERROR, payload: error
        }
      ))
    ))
}

export const httpMethodDelete = (action, url = '', header = setHeader()) => {
  let actionSuccessConstant = `${action.type}_SUCCESS`;
  let actionFailureConstant = `${action.type}_FAILURE`;
  let searchQuery = getQueryString(action.payload);

  return concat(
    of({
      type: 'API_LOADING', payload: ''
    }),
    ajax.delete(
      `${url}${searchQuery}`,
      header
    ).pipe(
      mergeMap(response => {
        let actions = [
          { type: actionSuccessConstant, response, param: action.payload },
          { type: 'API_IDLE', payload: '' }
        ];

        if (action.payload && action.payload.showSuccessMsg) {
          actions.push({ type: SUCCESS_TOAST, payload: response.response })
        }

        return of(...actions)
    }),
      catchError(error => of(
        {
          type: actionFailureConstant,
          payload: error,
          error: true
        },
        { type: 'API_IDLE', payload: ''},
        {
          type: ERROR, payload: error
        }
      ))
    ))
} 


export const axiosMethodGetJSON = (action, url = '', header = {"Content-Type": "application/json; charset=UTF-8"}) => {
  let actionSuccessConstant = `${action.type}_SUCCESS`;
  let actionFailureConstant = `${action.type}_FAILURE`;
  action.payload.appid = WEATHER_APP_ID
  let searchQuery = getQueryString(action.payload);
  
  return axios.get(`${url}${searchQuery}`, header).then((response)=>{
    return { type: actionSuccessConstant, response, param: action.payload };
  }).catch((error)=>{
    return {type: actionFailureConstant, error: true,payload: error}
  })
} 



/**
 * Utils
 */
const setHeader = () => {
  let token = `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}`;

  return {
    Authorization: token,
    // 'Content-Type': '*',
    'Content-Type': 'application/json;charset=UTF-8',
  }
}


const getQueryString = (payload = {}) => {
  let searchQuery = ''

  Object.keys(payload).forEach((key, i, arr) => {
    if (i === 0) {
      searchQuery = '?';
    }
    searchQuery += (key + '=' + payload[key]);
    if (i !== (arr.length - 1)) {
      searchQuery += '&'
    }
  });

  return searchQuery;
}
