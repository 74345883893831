import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import {
  Button,
  Input,
  CheckBox,
  Select
} from '../../../../../../../common/components';
import infoIcon from '../../../../../../../../assets/images/infoIcon.svg';

const disconnectedSinceOptionCollection = [
  { value: 1, label: '1 Hours' },
  { value: 3, label: '3 Hours' },
  { value: 6, label: '6 Hours' },
  { value: 12, label: '12 Hours' },
  { value: 24, label: '1 Day' }
];

const emailValidGet = (email) => {
  return /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/.test(
    email
  );
};

const emailsValidGet = (emails) => {
  return emails
    ? emails.split(/,/).reduce((memo, email) => {
        return memo ? emailValidGet(email.trim()) : memo;
      }, true)
    : true;
};

const phoneNumberValidGet = (phoneNumber) => {
  return /\d{10}/g.test(phoneNumber);
};

const phoneNumbersValidGet = (phoneNumbers) => {
  return phoneNumbers
    ? phoneNumbers.split(/,/).reduce((memo, phoneNumber) => {
        return memo ? phoneNumberValidGet(phoneNumber.trim()) : memo;
      }, true)
    : true;
};

const OthersConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [sensorHealthConfig, setSensorHealthConfig] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setSensorHealthConfig(
        (conf && conf.sensorHealthConfig && conf.sensorHealthConfig.value) || {}
      );

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      ...sensorHealthConfig,
      ...object
    };

    props.appInputParamChanged({
      sensorHealthConfig: {
        value
      }
    });
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <div className='new-input-wrap'>
          <label className='label-text'>Enable Sensor Health - SMS/Email</label>

          <div className='new-custom-checkbox'>
            <CheckBox
              label={'Enable'}
              size='sm'
              checked={sensorHealthConfig.enableAlert}
              checkboxClicked={(enableAlert) => {
                const _sensorHealthConfig = {
                  ...sensorHealthConfig,
                  enableAlert
                };

                setSensorHealthConfig(_sensorHealthConfig);

                appInputParamChangedHandle(_sensorHealthConfig);
              }}
            />
          </div>
        </div>

        <Row>
          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Disconnected Since (Hrs)</label>

              <Select
                isMulti={false}
                options={disconnectedSinceOptionCollection}
                placeholder='Select Hours'
                value={(() => {
                  const value = sensorHealthConfig.disconnectedSince;

                  return {
                    label: value,
                    value
                  };
                })()}
                onChange={({ value }) => {
                  const _sensorHealthConfig = {
                    ...sensorHealthConfig,
                    disconnectedSince: value
                  };

                  setSensorHealthConfig(_sensorHealthConfig);

                  appInputParamChangedHandle(_sensorHealthConfig);
                }}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Schedule Time</label>

              <Input
                size='sm'
                label=''
                type='time'
                required={false}
                placeholder='Enter Schedule Time'
                value={sensorHealthConfig.schedule || ''}
                inputChanged={(schedule) => {
                  const _sensorHealthConfig = {
                    ...sensorHealthConfig,
                    schedule
                  };

                  setSensorHealthConfig(_sensorHealthConfig);

                  appInputParamChangedHandle(_sensorHealthConfig);
                }}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Email (s)</label>

              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter Email Addresses'
                value={sensorHealthConfig.emails || ''}
                inputChanged={(emails) => {
                  const _sensorHealthConfig = {
                    ...sensorHealthConfig,
                    emails
                  };

                  setSensorHealthConfig(_sensorHealthConfig);

                  appInputParamChangedHandle(_sensorHealthConfig);
                }}
              />

              {!emailsValidGet(sensorHealthConfig.emails) && (
                <span className='error'>Invalid Email(s)</span>
              )}

              {!!sensorHealthConfig.emails &&
                sensorHealthConfig.emails.split(/,/).length > 10 && (
                  <span className='error'>Maximum 10 emails allowed</span>
                )}

              <div className='input-notify'>
                <img src={infoIcon} />

                <p>comma seperated, 10 emails allowed</p>
              </div>
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Contact Number</label>

              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter Contact Number'
                value={sensorHealthConfig.phoneNumber || ''}
                inputChanged={(phoneNumber) => {
                  const _sensorHealthConfig = {
                    ...sensorHealthConfig,
                    phoneNumber
                  };

                  setSensorHealthConfig(_sensorHealthConfig);

                  appInputParamChangedHandle(_sensorHealthConfig);
                }}
              />

              {!phoneNumbersValidGet(sensorHealthConfig.phoneNumber) && (
                <span className='error'>Invalid Phone Number(s)</span>
              )}

              {!!sensorHealthConfig.phoneNumber &&
                sensorHealthConfig.phoneNumber.split(/,/).length > 10 && (
                  <span className='error'>Maximum 10 numbers allowed</span>
                )}

              <div className='input-notify'>
                <img src={infoIcon} />

                <p>comma seperated, 10 contacts allowed</p>
              </div>
            </div>
          </Col>
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default OthersConfig;
