import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, ConfirmBox } from '../../../common/container';
import { getRefreshAction, getMemberProfileDataAction, closeRightSideDrawer, getCalculatedParameterAction, getReadingTypesPreloadAction } from './action';
import { showErrorToast, showSuccessToast, showConfirmBox } from '../actions';
import { getAllNotificationCount, getAllNotificationsBySocket, getAllNotificationCountBySocket, } from '../Notification/action';
import { Loader, Footer, Slider, SliderMenu, ModalBox, BackDrop } from '../../../common/components';
import { APP_SETTINGS, activeAppSettings, activeUserRoles, activeServices, activeUserPermissions } from './appSetting';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';
import io from 'socket.io-client';
import { NOTIFICATION_URL } from '../constants';
import { getUsers } from '../../../common/container/UserSearch/action';
import { clientLogoutReset } from '../PartnerLogin/action';
import { resetLogin } from '../../../common/container/Login/action';
const Toaster = React.lazy(() => import('../Toaster'));
import Routes from './routes';
import { Context, UserContext, DateTimeContext, PlantContext } from './context';
import WidgetPopout from "../Dashboard/components/WidgetPopout";
// export const Context = React.createContext();
// export const UserContext = React.createContext();
// react-grid-layout css
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { makeServiceDefault } from '../AssetScreenDesign/action';
import Config from 'Config';

let token = localStorage.getItem('smartsense.authToken') !== 'undefined' && `${JSON.parse(localStorage.getItem('smartsense.authToken'))}`;
var socket = null;
if (token && token !== null && token !== undefined && token !== 'null') {
  socket = io(NOTIFICATION_URL, { query: { token }, path: '/notification-engine' });
}

const dateRangeGet = () => {
  //var startDateBasedOnRoute =
  //window.location.pathname === '/Sustainability/'
  //? moment().subtract(30, 'days').format()
  //: moment().startOf('month').format();
	var startDateBasedOnRoute = moment().startOf('month').format();
  return {
    startDate: startDateBasedOnRoute,
    endDate: moment().format()
  };
};

const isDashboardHorizontalGet = () => {
  const dashboardAlignCollection = ['horizontal', 'vertical'];

  const key = 'smartsense.admin_member';

  return !dashboardAlignCollection.findIndex(
    (dashboardAlign) =>
      dashboardAlign ===
      (localStorage.getItem(key) &&
        JSON.parse(localStorage.getItem(key))['dashboard_align'])
  );
};

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      madalOpen: false,
      modalOption: {},
      isShowNotification: false,
      ...dateRangeGet(),
      startTime: '00:00',
      endTime: '23:59',
      showPlant: false,
      plantValue: null,
      isThemeLight: false,
      fullScreenWidgetId: undefined,
      clientId: null,
      redirectionTrigger: undefined
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showSuccessNotification = this.showSuccessNotification.bind(this);
    this.showErrorNotification = this.showErrorNotification.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.getPreloadData = this.getPreloadData.bind(this);
    this.openNoticationModal = this.openNoticationModal.bind(this);
    this.setClientId = this.setClientId.bind(this);
  }

  callSocket = () => {
    console.log('working fine..');
    socket.on('connect', () => {
      console.log('connected....');
      // Do needful this after connection setup
    });
    socket.on('disconnect', () => {
      console.log('disconnect');
      // Do needful this after connection break
    });
    socket.on('notifications', (data) => {
      // console.log('notifications', data);
      this.props.getAllNotificationsBySocket({ 'socket': data });
      this.props.getAllNotificationCountBySocket({ 'socket': data });
    });
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('message');
    };
  };

  componentDidMount() {
    let timezone;
    let member =
      localStorage.getItem('smartsense.member') &&
      JSON.parse(localStorage.getItem('smartsense.member'));
    if (member && member.timezone && moment.tz.zone(member.timezone)) {
      timezone = member.timezone;
    }
    if (moment) {
      moment.tz.setDefault(timezone);
    }

    !Config.is_salesforce_integration && this.renderDeskZoho();

    if (
      (localStorage.getItem('smartsense.authToken') !== 'undefined' &&
        JSON.parse(localStorage.getItem('smartsense.authToken'))) ||
      (this.props.authToken && this.props.authToken.sensors)
    ) {
      //this.getPreloadData();
      // this.props.getRefreshAction();
      // this.props.getMemberProfileDataAction();
      // this.props.getAllNotificationCount();
      // this.props.getUsers({});
      this.callSocket();
    } else {
      //Commented for For Handling Re-set Password
      // window.location.href = window.location.origin + '#/login/SmartSense';
    }

    const exitHandler = () => {
      !document.fullscreenElement &&
        this.setState({ fullScreenWidgetId: null });
    };

    document.addEventListener('fullscreenchange', exitHandler, false);
    document.addEventListener('mozfullscreenchange', exitHandler, false);
    document.addEventListener('MSFullscreenChange', exitHandler, false);
    document.addEventListener('webkitfullscreenchange', exitHandler, false);
  }

  renderDeskZoho = () => {
    window.ZohoHCAsap =
      window.ZohoHCAsap ||
      function (a, b) {
        ZohoHCAsap[a] = b;
      };
    (function () {
      var d = document;
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.defer = true;
      s.src =
        'https://desk.zoho.in/portal/api/web/inapp/6768000004489241?orgId=60001197473';
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  };

  componentWillReceiveProps = (nextProps) => {
    let authToken =
      localStorage.getItem('smartsense.superAuthToken') !== 'undefined' &&
      JSON.parse(localStorage.getItem('smartsense.superAuthToken'));

    const _authToken =
      localStorage.getItem('smartsense.authToken') &&
      JSON.parse(localStorage.getItem('smartsense.authToken'));

    if (
      nextProps &&
      nextProps.isClientLogout &&
      nextProps.isClientLogout.success
    ) {
      this.getPreloadData();
      this.props.clientLogoutReset();
    } else if (
      nextProps.loginData &&
      nextProps.loginData.success == true &&
      (authToken || _authToken)
    ) {
      this.getPreloadData();
      this.props.resetLogin();

      let timezone;
      let member =
        localStorage.getItem('smartsense.member') &&
        JSON.parse(localStorage.getItem('smartsense.member'));
      if (member && member.timezone && moment.tz.zone(member.timezone)) {
        timezone = member.timezone;
      }
      if (moment) {
        moment.tz.setDefault(timezone);
      }
    }

    JSON.stringify(nextProps.location) !==
      JSON.stringify(this.props.location) &&
      (() => {
        this.setState(dateRangeGet());
      })();
  };

  getPreloadData() {
    if (this.props.loading) {
      return;
    }
    // var storedServices = JSON.parse(localStorage.getItem("smartsense.services"));
    // var sustainability = storedServices && storedServices.filter(item => item.name == "Sustainability")
    // this.props.makeServiceDefault({service_id: (sustainability && sustainability[0]) && sustainability[0].id});
    //this.props.getRefreshAction();
    // this.props.getMemberProfileDataAction();
    this.props.getAllNotificationCount();
    this.props.getUsers({});
    this.props.getCalculatedParameterAction({
      from_date: encodeURIComponent(moment().subtract(360, 'days').format()),
      to_date: encodeURIComponent(moment().format()),
    });
    // this.props.getReadingTypesPreloadAction({})
  }

  renderHeader() {
    if (this.props.location.pathname !== '/') {
      return <Header pathname={location.pathname.split('/')} />;
    }
  }

  getTitleAtIndex(i) {
    let items = this.getSliderNavItems();
    let itemskey = Object.keys(items)[i];
    return items[itemskey].tooltip;
  }

  getIconAtIndex(i) {
    let items = this.getSliderNavItems();
    let itemskey = Object.keys(items)[i];
    return items[itemskey].icon;
  }
  getIconLightAtIndex(i) {
    let items = this.getSliderNavItems();
    let itemskey = Object.keys(items)[i];
    return items[itemskey].iconLight;
  }

  itemClickedAtIndex(i) {
    let items = this.getSliderNavItems();
    let itemskey = Object.keys(items)[i];
    if (itemskey === 'AssetInsightsVintage') {
      window.open(items[itemskey].url, '_blank');
    } else {
      this.props.history.push(items[itemskey].url);
    }
  }

  redirectPage(url) {
    this.props.history.push(url);
  }

  getSliderNavItems() {
    return activeAppSettings();
  }

  getSliderNavItemsLabels() {
    const items = activeAppSettings();
    return Object.keys(items).map((elem) => items[elem].label);
  }

  renderSilder() {
    if (this.props.location.pathname !== '/') {
      return (
        <Slider
          side='left'
          getInnerComponant={() => {
            return (
              <SliderMenu
                isDashboardHorizontal={isDashboardHorizontalGet()}
                items={this.getSliderNavItemsLabels()}
                getIconAtIndex={(i) => {
                  return this.getIconAtIndex(i);
                }}
                itemClickedAtIndex={(i) => {
                  this.itemClickedAtIndex(i);
                }}
                getTitleAtIndex={(i) => {
                  return this.getTitleAtIndex(i);
                }}
                getIconLightAtIndex={(i) => {
                  return this.getIconLightAtIndex(i);
                }}
                active={-1}
                redirectPage={(url) => this.redirectPage(url)}
                activeTab={location.pathname.split('/')}
                redirectionTrigger={this.state.redirectionTrigger}
                history={this.props.history}
                onThemeChange={(isThemeLight) => {
                  this.setState({ isThemeLight });
                }}
                onRedirectionSuccess={() => {
                  this.setState({ redirectionTrigger: undefined });
                }}
              />
            );
          }}
        />
      );
    }
  }

  showModal(
    header,
    body,
    footer,
    cancleIcon,
    backdrop,
    onCancel = this.hideModal,
    className,
    size
  ) {
    var option = {
      header: header,
      body: body,
      footer: footer,
      cancleIcon: cancleIcon,
      backdrop: backdrop,
      onCancel: onCancel,
      className: className,
      size: size,
    };

    this.setState({ madalOpen: true, modalOption: option });
  }

  hideModal() {
    this.setState({ madalOpen: false });
  }

  setClientId(clientId) {
    this.setState({ clientId });
  }

  fullScreen(elem) {
    if (!elem) return;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  showSuccessNotification(message = '') {
    this.props.showSuccessToast({
      message: message,
    });
  }

  showErrorNotification(message = '') {
    this.props.showErrorToast({
      message: message,
    });
  }

  showConfirm(content = 'tset', onOk = () => {}, onCancle = () => {}) {
    let payload = {
      content,
      onOk,
      onCancle,
    };

    this.props.showConfirmBox(payload);
  }

  openNoticationModal(value) {
    this.setState({ isShowNotification: value });
  }

  handleDate = (_startDate, _endDate) => {
    const startDate = moment(_startDate).format();

    const endDate = moment(_endDate).format();

    const match =
      this.state.startDate === startDate && this.state.endDate === endDate;

    !match &&
      this.setState({
        startDate,
        endDate,
      });
  };

  handleChange = (value) => {
    this.setState({ plantValue: value });
  };

  handlePlant = (value = !this.state.showPlant) => {
    this.setState({ showPlant: value });
  };

  render() {
    let path = location.pathname.split('/');
    let isAdminProfile = localStorage.getItem('adminProfile');
    let isPartnerAccess = false;
    if (
      activeServices &&
      activeServices()['Partner'] &&
      activeServices()['Partner'].url === '/Partner'
    )
      isPartnerAccess = true;

    const isLoginScreen = this.props.location.pathname === '/';


    // let data = JSON.parse(localStorage.getItem('smartsense.services'));
    // let isPartnerAccess = false;
    // if (data && data.length > 0) {
    //   for (let i = 0; i < data.length; i++) {
    //     if (data[i].url === '/Partner') {
    //       isPartnerAccess = true;
    //     }
    //   }
    // }
    let {
      loading,
      error,
      history,
      isOpenRightSideDrawer,
      isClientLogout,
      isNotificationOpen,
    } = this.props;
    const roles = activeUserRoles();
    const services = activeServices();
    return (
      <div
        className={`${
          (isPartnerAccess && isAdminProfile == null) ||
          (isClientLogout && isClientLogout.isSwitchUser)
            ? 'login-wrapper-background'
            : ''
        } ${isLoginScreen ? 'login-screen' : ''}`}
      >
        <Suspense fallback={'Loading...'}>
          {
            <PlantContext.Provider
              value={{
                plantValue: this.state.plantValue,
                handleChange: this.handleChange,
                showPlant: this.state.showPlant,
                handlePlant: this.handlePlant,
              }}
            >
              <DateTimeContext.Provider
                value={{
                  ...this.state,
                  handleDate: this.handleDate,
                }}
              >
                <UserContext.Provider
                  value={{ roles, services, permission: activeUserPermissions }}
                >
                  <Context.Provider
                    value={{
                      modal: {
                        show: this.showModal,
                        hide: this.hideModal,
                      },
                      fullScreen: {
                        widgetId: this.state.fullScreenWidgetId,
                        open: (elem, widgetId) => {
                          this.setState({ fullScreenWidgetId: widgetId });

                          this.fullScreen(elem, widgetId);
                        },
                      },
                      notify: {
                        success: this.showSuccessNotification,
                        error: this.showErrorNotification,
                      },
                      confirm: {
                        show: this.showConfirm,
                      },
                      notification: {
                        show: this.openNoticationModal,
                        isShow: this.state.isShowNotification,
                      },
                      isDashboardHorizontal: isDashboardHorizontalGet(),
                      isThemeLight: this.state.isThemeLight,
                      clientId: this.state.clientId,
                      setClientId: this.setClientId,
                      onRedirectionTrigger: (redirectionTrigger) => {
                        this.setState({ redirectionTrigger });
                      }
                    }}
                  >
                    <div className='navHeader'>{this.renderHeader()}</div>
                    {this.renderSilder()}

                    <Loader loading={loading} error={error}>
                      <div
                        className={
                          path && path[1] == 'Notification'
                            ? 'navContentNotification'
                            : 'navContent'
                        }
                      >
                        {/* render all routes */}
                        <Suspense fallback={<Loader loading />}>
                          <Routes history={history} />
                        </Suspense>

                        <Modal
                          open={this.state.madalOpen}
                          {...this.state.modalOption}
                        />
                        {isNotificationOpen && (
                          <Notification history={history} />
                        )}
                        <Toaster />
                        <Footer />
                        <ConfirmBox />
                        <WidgetPopout />
                        {isOpenRightSideDrawer && (
                          <BackDrop
                            close={() => this.props.closeRightSideDrawer(false)}
                          />
                        )}
                      </div>
                    </Loader>
                  </Context.Provider>
                </UserContext.Provider>
              </DateTimeContext.Provider>
            </PlantContext.Provider>
          }
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // getRefreshData: state.getRefreshData,
    loading: state.getRefreshData.loading,
    error: state.getRefreshData.error,
    authToken: state.login.data,
    isNotificationOpen: state.notificationPopUp.isNotificationOpen,
    isOpenRightSideDrawer: state.rightSideDrawer.isOpenRightSideDrawer,
    isClientLogout: state.clientLogout,
    loginData: state.login,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    //getRefreshAction,
    getMemberProfileDataAction,
    showErrorToast,
    showSuccessToast,
    showConfirmBox,
    getAllNotificationCount,
    getAllNotificationsBySocket,
    getAllNotificationCountBySocket,
    getUsers,
    closeRightSideDrawer,
    getCalculatedParameterAction,
    clientLogoutReset,
    resetLogin,
    getReadingTypesPreloadAction,
    makeServiceDefault
  })(Main),
);

class Modal extends React.Component {
  static defaultProps = {
    open: false,
    className: '',
    cancleIcon: false,
    header: <React.Fragment />,
    body: <React.Fragment />,
    footer: <React.Fragment />,
    backdrop: true,
    size: 'md',
    onCancel: function () { },
  };

  static propTypes = {
    open: PropTypes.bool,
    className: PropTypes.string,
    cancleIcon: PropTypes.bool,
    header: PropTypes.element,
    body: PropTypes.element,
    footer: PropTypes.element,
    backdrop: PropTypes.bool,
    size: PropTypes.string,
    onCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    return {};
  }

  addCloseButton = function () {
    return (
      <span className="main-cancle-icon" onClick={this.props.onCancel}>
        <i className="fa fa-times-circle" aria-hidden="true"></i>
      </span>
    );
  };

  render() {
    return (
      <ModalBox
        open={this.props.open}
        className={`main-modal ${this.props.className}`}
        backdrop={this.props.backdrop}
        size={this.props.size}
      >
        <div className="main-modal-header">
          {this.props.header ? <div className="main-modal-header-text">{this.props.header}</div> : null}
          {this.props.cancleIcon ? this.addCloseButton() : null}
        </div>

        <div className="main-modal-body">{this.props.body}</div>

        {/* Footer */}
        {this.props.footer ? <div className="main-modal-footer">{this.props.footer}</div> : null}
      </ModalBox>
    );
  }
}