import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import parameterConfsInputGet from '../common/parameterConfsInputGet';
import useSensorParameterCollection from '../common/useSensorParameterCollection';
import SensorParameterSelect from '../Common/SensorParameterSelect';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import valueGet from '../common/valueGet';
import { Button, Input, Select } from '../../../../../../../common/components';
import widgetConfigParamGet from '../common/widgetConfigParamGet';

const parameterConfsGet = (value) => {
  const parameterConfs = value && [{ locParam: value }];

  return parameterConfsInputGet(parameterConfs);
};

const _parameterConfsGet = (parameterConfs) => {
  const _parameterConfs = parameterConfsOutputGet(parameterConfs);

  return {
    locParam: valueGet(
      _parameterConfs &&
        (() => {
          const { locParam } = _parameterConfs[0];

          return locParam;
        })()
    )
  };
};

const ParameterConfig = (props) => {
  const dataFrequencyCollection = [
    ' ',
    ...widgetConfigParamGet(props.widgetConfig, 'dataFrequency').options
  ];

  const [initialized, setInitialized] = useState(false);

  const [min, setMin] = useState();

  const [max, setMax] = useState();

  const [dataFrequency, setDataFrequency] = useState();

  const [parameterConfs, setParameterConfs] = useState();

  const [
    sensorCollection,
    sensorOptions,
    parameterCollection,
    parameterOptionsCollection,
    onSensorChangeHandle,
    onParameterChangeHandle
  ] = useSensorParameterCollection(parameterConfs || []);

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setMin(conf && conf.min && conf.min.value);

      setMax(conf && conf.max && conf.max.value);

      setDataFrequency(conf && conf.dataFrequency && conf.dataFrequency.value);

      setParameterConfs(
        parameterConfsGet(conf && conf.locParam && conf.locParam.value)
      );

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      min: valueGet(min),
      max: valueGet(max),
      dataFrequency: valueGet(dataFrequency),
      ..._parameterConfsGet(parameterConfs),
      ...object
    };

    props.appInputParamChanged(value);
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <p className='head-label-text mg-b-15'>Graph Filters</p>

        <Row>
          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Min Value</label>

              <Input
                size='sm'
                label=''
                type='input'
                required={false}
                placeholder='Enter Min Value'
                value={min || ''}
                inputChanged={(_min) => {
                  setMin(_min);

                  appInputParamChangedHandle({
                    min: valueGet(_min)
                  });
                }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Max Value</label>

              <Input
                size='sm'
                label=''
                type='input'
                required={false}
                placeholder='Enter Max Value'
                value={max || ''}
                inputChanged={(_max) => {
                  setMax(_max);

                  appInputParamChangedHandle({
                    max: valueGet(_max)
                  });
                }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Data Frequency</label>

              <Select
                options={dataFrequencyCollection.reduce((memo, value) => {
                  return [
                    ...memo,
                    {
                      label: value,
                      value
                    }
                  ];
                }, [])}
                isMulti={false}
                placeholder='Select Data Frequency'
                value={(() => {
                  const value = dataFrequency;

                  return {
                    label: value,
                    value
                  };
                })()}
                onChange={({ value }) => {
                  setDataFrequency(value);

                  appInputParamChangedHandle({
                    dataFrequency: valueGet(value)
                  });
                }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Location *</label>

              <SensorParameterSelect
                type='sensor'
                options={sensorOptions}
                value={sensorCollection[0] || ''}
                onChange={(sensor) => {
                  onSensorChangeHandle(sensor, 0);
                }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Parameter *</label>

              <SensorParameterSelect
                type='parameter'
                options={parameterOptionsCollection[0] || []}
                value={parameterCollection[0] || ''}
                onChange={(parameter) => {
                  onParameterChangeHandle(parameter, 0, (parameterConf) => {
                    const parameterConfs = [parameterConf];

                    setParameterConfs(parameterConfs);

                    appInputParamChangedHandle(
                      _parameterConfsGet(parameterConfs)
                    );
                  });
                }}
              />
            </div>
          </Col>
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfig;
