import React, { useState, useEffect } from 'react';
import SelectDropDown from '../../../../../common/components/Select';
import { Label } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllAssetList } from '../../action';
import { getReadingtypeNameById } from '../../../utils';

function AssetType(props) {
  var [metaParam, setMetaParam] = useState([]);
  var [metaParamOpt, setMetaParamOpt] = useState([]);

  let metaParamOption = localStorage.getItem('template.asset_names') !== 'undefined' && JSON.parse(localStorage.getItem('template.asset_names'));
  let path = location.pathname.split('/');

  useEffect(() => {
    if (!props.isLoaded && Array.isArray(props.allAssets)) {
      props.getAllAssetList({});
    }
  }, []);

  useEffect(() => {
    if (
      path.indexOf('Templates') === 1 &&
      metaParamOption &&
      metaParamOption.asset_name &&
      metaParamOption.asset_name.parameters
    ) {
      setMetaParamOpt(metaParamOption.asset_name.parameters);
    } else {
      if (Array.isArray(props.allAssets)) {
        let prm = [];
        props.allAssets.map((elem) => {
          prm = [...prm, ...elem.parameters];
        });
        setMetaParamOpt(prm);
      }
    }
  }, [props.allAssets]);

  useEffect(() => {
    if (props.value) {
      setMetaParam(props.value)
    }
  }, [props.value]);

  const groupOptions = () => {
    let opt = metaParamOpt.map(a=>{return {...a, 'label': a.name, 'value': a.id}});
    let arr = [];

    arr.push({
        label: "META PARAMS",
        options: opt,
    })

    let el = _.groupBy(props.allAssets, (elem)=>{
      return elem && elem.id;
    });

    Object.keys(el).map(item=>{
      let e = [];
      const getOpt = () => {
        let asset = el[item][0];

        Array.isArray(asset && asset.sensor_types) && 
        asset.sensor_types.map((a)=>{
          Array.isArray(a.reading_types) && 
          a.reading_types.map((reading)=>{
            e.push({
              'label': getReadingtypeNameById(reading) || `No Label - ${reading}`, 
              'value': reading,
              'is_reading_type': true,
              'sensor_type_id': a.id
            });
          })
        })

        return e;
      }

      let op = !props.getallassetoverwriteflag && getOpt();    
      if(op.length > 0){
        arr.push({
          label: item,
          options: op,
        })
      }
    });

    return arr;
  }

  return <Col className="select-dropdown custom-select-parameter" xl={props.width ? props.width : 12} >
    <div>
      <Label for={props.name} className='label'>{props.label}
        {props.required === true ? <span className='text-danger'> * </span> : null}
      </Label>
      <SelectDropDown
        name='asset-type'
        value={metaParam}
        onChange={(e) => {
          setMetaParam(e);
          props.onChange(e, false);
        }}
        options={groupOptions()}
        placeholder='Select Asset Params'
        isMulti={props.isMulti}
      />
    </div>
  </Col>

}


const mapStateToProps = (state) => {
  return {
    allAssets: state.getAllAssetList.data,
    isLoaded: state.getAllAssetList.success
  };
};

AssetType.defaultProps = {
  appParams: {},
  appInputParamChanged: function () { },
  isMulti: true
}

AssetType.propTypes = {
  appParams: PropTypes.object,
  appInputParamChanged: PropTypes.func,
  isMulti: PropTypes.bool
}

export default connect(mapStateToProps, {
  getAllAssetList
})(AssetType);