import React, { useState, useEffect, Fragment, useContext } from 'react';
import clsx from 'clsx';
import ButtonGroup from '../ButtonGroup';
import Button from '../Button';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import leftArrow from '../../../../assets/images/Icons/Arrows/Chevron-LeftSVG.svg';
import rightArrow from '../../../../assets/images/Icons/Arrows/Chevron-RightSVG.svg';
import { PRESETS } from './DateTimePresets';
import PropTypes from 'prop-types';
import { DateTimeContext } from '../../../admin/container/Main/context';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const presets = PRESETS;

function DateTimeRangePicker(props) {
    const dateTimeContext = useContext(DateTimeContext);
    let initialStartDate = null;
    let initialEndDate = null;
    let initialActionType = presets.length - 1;
    const extraParams = props['extraParams'] || {};

    // useEffect(()=>{
    //     if ( document.getElementsByClassName("rt-resizer")){
    //         let list = document.getElementsByClassName("rt-resizer");
    //         // for(let i=0; i<list.length; i++){
    //         //     i.classList.toggle('rt-resizer')
    //         // }
    //         // document.getElementsByClassName("rt-resizer").classList.toggle('MyClass');
    //     }
    // },[]);

    // calender itself handles validation and start end date same for single datepicker;
    if (props.preset === 'multi') {
        if (props.startDate) {
            initialStartDate = moment(props.startDate).isValid() ? moment(props.startDate) : null;
        }

        if (props.endDate) {
            initialEndDate = moment(props.endDate).isValid() ? moment(props.endDate) : null;
        }

        initialActionType =
            props.preSelectedPreset &&
                presets.length > props.preSelectedPreset &&
                props.preSelectedPreset >= 0
                ? props.preSelectedPreset
                : presets.length - 1;
        // should not go for single date set if valid start or end date is not provided
    } else if (props.startDate || props.endDate) {
        let validDate = props.startDate ? props.startDate : props.endDate;

        initialStartDate = moment(validDate).isValid() ? moment(validDate) : null;

        // make date range as single day
        initialEndDate = initialStartDate;
    }

    const classes = useStyles();
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [startTime, setStartTime] = useState('00:00');
    const [endTime, setEndTime] = useState('23:59');
    const [focusedInput, setFocusedInput] = useState(null);
    const [validation, setValidation] = useState({
        startDate: true,
        endDate: true,
    });

    const [activeActionType, setactiveActionType] = useState(parseInt(initialActionType));

    const handleClose = () => {
        //setEndDate(null);
        //setStartDate(null);
        // setStartTime('00:00');
        // setEndTime('00:00');

        setFocusedInput(null);
        props.onClose ? props.onClose() : '';
        // props.onFocusDatesChange && props.onFocusDatesChange(moment().subtract(15, 'days').format(), moment().format())
    };

    const handleStartTime = (e) => {
        setStartTime(!e.target.value ? '00:00' : e.target.value);
        getOnChangeDates(startDate, endDate, e.target.value, '00:00', activeActionType)
    };

    const handleEndTime = (e) => {
        setEndTime(!e.target.value ? '00:00' : e.target.value);
        getOnChangeDates(startDate, endDate, '00:00', e.target.value, activeActionType);
        // props.setCurrentTime && props.setCurrentTime(e.target.value)
    };

    const getOnChangeDates = (from_date, to_date, start_time, end_time, preset) => {
        let start = moment(from_date).format('YYYY-MM-DD HH:mm').split(' ');
        let end = moment(to_date).format('YYYY-MM-DD HH:mm').split(' ');
        let fromDate = start[0] + ' ' + start_time;
        let toDate = end[0] + ' ' + end_time;
        // let toDate = end[0] + ' ' + props.isCurrentTime == moment().format('HH:mm') ? moment().format('HH:mm') : end_time;

        props.onFocusDatesChange && props.onFocusDatesChange(fromDate, toDate, preset)
    }
    /**
     * true if valid else false
     */
    const performValidation = () => {
        let newValidation = { ...validation };
        let isValid = true;

        if (!startDate) {
            isValid = false;
            newValidation['startDate'] = false;
        } else {
            newValidation['startDate'] = true;
        }

        if (!endDate) {
            isValid = false;
            newValidation['endDate'] = false;
        } else {
            newValidation['endDate'] = true;
        }

        setValidation(newValidation);
        return isValid;
    };

    const handleSubmit = () => {
        let start = moment(startDate).format('YYYY-MM-DD HH:mm').split(' ');
        let end = moment(endDate).format('YYYY-MM-DD HH:mm').split(' ');

        if (performValidation() === false) {
            return;
        }

        let from_date = start[0] + ' ' + startTime;
        // let to_date = end[0] + ' ' + props.isCurrentTime == moment().format('HH:mm') ? moment().format('HH:mm') : endTime;
        let to_date = end[0] + ' ' + endTime;

        props.onDatesChange(from_date, to_date, activeActionType);
        setFocusedInput(null);
        props.onClose ? props.onClose() : '';

        !props.dateTimeContextUpdateOverrideFlag &&
          dateTimeContext.handleDate(from_date, to_date);
    };

    const handleSingleSubmit = () => {
        let start = moment(startDate).format('YYYY-MM-DD HH:mm').split(' ');
        let from_date = start[0] + ' ' + startTime;

        if (performValidation() === false) {
            return;
        }
        props.onDateChange(from_date);
        setFocusedInput(null);
        props.onClose ? props.onClose() : '';
    };

    const renderMultiDatePresets = () => {
        let starttime = startTime.split(':');
        let endtime = endTime.split(':');
        // let endtime = props.isCurrentTime == moment().format('HH:mm') ? moment().format('HH:mm').split(':') : endTime.split(':');
        let fromstr;
        let tostr;

        if (startDate) {
            fromstr = startDate.set({ hours: starttime[0], minutes: starttime[1] });
        }

        if (endDate) {
            tostr = endDate.set({ hours: endtime[0], minutes: endtime[1] });
        }
        return (
            <Fragment>
                <div className="calenderHeader">
                    <div className="start">
                        From
                        <span className="startDate">
                            {props.hideTime === true
                                ? fromstr
                                    ? startDate.format('MMM DD, YYYY')
                                    : 'Please select date'
                                : fromstr
                                    ? startDate.format('MMM DD, YYYY HH:mm')
                                    : 'Please select date'}
                        </span>
                    </div>
                    <div className="end">
                        To
                        <span className="endDate">
                            {props.hideTime === true
                                ? tostr
                                    ? endDate.format('MMM DD, YYYY')
                                    : 'Please select date'
                                : tostr
                                    ? endDate.format('MMM DD, YYYY HH:mm')
                                    : 'Please select date'}
                        </span>
                    </div>
                </div>
                {/* click to stopPropagation */}
                <div className="calenderInfoLeft" onClick={(e) => { e.stopPropagation() }}>
                    <div className="msg">Select date & time</div>
                    <ButtonGroup
                        vertical={true}
                        noOfButtons={7}
                        buttonType="link"
                        type="radio"
                        size="lg"
                        getButtonLableAtIndex={(i) => {
                            return presets[i];
                        }}
                        buttonClickAtIndex={(i) => {
                            // we will find one day less when date subtract
                            // moment will subtract days from current date with time which causes it look like
                            // 1 extra day has been selected.
                            // having one less day not causes any other thing as time has been handled separately
                            switch (i) {
                                case 0:
                                    setEndDate(moment());
                                    setStartDate(moment());
                                    getOnChangeDates(moment(), moment(), startTime, endTime, i)
                                    // props.onFocusDatesChange && props.onFocusDatesChange(moment(), moment(), i);
                                    break;
                                case 1:
                                    setEndDate(moment());
                                    setStartDate(moment().subtract(6, 'days'));
                                    getOnChangeDates(moment().subtract(6, 'days'), moment(), startTime, endTime, i)
                                    // props.onFocusDatesChange && props.onFocusDatesChange(moment().subtract(6, 'days'), moment(), i);
                                    break;
                                case 2:
                                    setEndDate(moment());
                                    setStartDate(moment().subtract(14, 'days'));
                                    getOnChangeDates(moment().subtract(14, 'days'), moment(), startTime, endTime, i)
                                    // props.onFocusDatesChange && props.onFocusDatesChange(moment().subtract(14, 'days'), moment(), i);
                                    break;
                                case 3:
                                    setEndDate(moment());
                                    setStartDate(moment().subtract(29, 'days'));
                                    getOnChangeDates(moment().subtract(29, 'days'), moment(), startTime, endTime, i)
                                    // props.onFocusDatesChange && props.onFocusDatesChange(moment().subtract(29, 'days'), moment(), i);
                                    break;
                                case 4:
                                    const startOfMonth = moment().startOf('month');
                                    const endOfMonth = moment().endOf('month');
                                    setEndDate(endOfMonth);
                                    setStartDate(startOfMonth);
                                    getOnChangeDates(endOfMonth, startOfMonth, startTime, endTime, i)
                                    // props.onFocusDatesChange && props.onFocusDatesChange(endOfMonth, startOfMonth, i);
                                    break;
                                case 5:
                                    const startOfYear = moment().startOf('year');
                                    const endOfYear = moment().endOf('year');
                                    setEndDate(endOfYear);
                                    setStartDate(startOfYear);
                                    getOnChangeDates(endOfYear, startOfYear, startTime, endTime, i)
                                    // props.onFocusDatesChange && props.onFocusDatesChange(endOfYear, startOfYear, i);
                                    break;
                                default:
                                    setEndDate(null);
                                    setStartDate(null);
                                    // getOnChangeDates(moment(), moment(), startTime, endTime, i)
                                    // props.onFocusDatesChange && props.onFocusDatesChange(null, null);
                                    break;
                            }
                            setactiveActionType(i);
                        }}
                        active={activeActionType}
                    ></ButtonGroup>
                    <div
                        className='reset-dates'
                        onClick={(e) => {
                            e.stopPropagation();
                            setEndDate(null);
                            setStartDate(null);
                        }}>
                        Reset Dates
                    </div>
                </div>
                <div className="calenderInfoBottom" onClick={(e) => { e.stopPropagation() }}>
                    <div className="timepicker">
                        {props.hideTime === true ? null : (
                            <Fragment>
                                <TextField
                                    id="time"
                                    label=""
                                    type="time"
                                    onChange={handleStartTime}
                                    value={startTime}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                                <TextField
                                    id="time"
                                    label=""
                                    type="time"
                                    onChange={handleEndTime}
                                    value={endTime}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                            </Fragment>
                        )}
                    </div>
                    <div className="invalid">
                        <span className="invalidmsg">
                            {validation.startDate === false ? 'Invalid start date' : ''}
                        </span>
                        <span className="invalidmsg">
                            {validation.endDate === false ? 'Invalid end date' : ''}
                        </span>
                        {/* {
                            props.isDisable && props.countDateError && <span className="invalidmsg">
                                {props.countDateError}
                            </span>
                        } */}
                    </div>
                    <div className="buttons">
                        <Button
                            size="md"
                            buttonType="secondary"
                            buttonClick={handleClose}
                            innerContent="Cancel"
                        />
                        <Button
                            size="md"
                            buttonType="primary"
                            buttonClick={handleSubmit}
                            innerContent={props.isSendEmail ? 'Send Mail' : "Apply"}
                            isDisabled={props.isDisable ? true : false}
                        />
                    </div>
                </div>
            </Fragment>
        );
    };

    const renderSingleDatePresets = () => {
        return (
            <Fragment>
                {props.hideTime === true ? null : (
                    <TextField
                        id="time"
                        label=""
                        type="time"
                        onChange={handleStartTime}
                        value={startTime}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    />
                )}
                <div className="calenderInfoBottomSingle">
                    <div className="invalid">
                        <span className="invalidmsg">
                            {validation.startDate === false ? 'Invalid date selected' : ''}
                        </span>
                    </div>
                </div>
                <div className="buttons">
                    <Button size="md" buttonType="secondary" buttonClick={handleClose} innerContent='Cancel' />
                    {/* Cancel */}
                    {/* </Button> */}
                    <Button size="md" buttonType="primary" buttonClick={handleSingleSubmit} innerContent='Apply' />
                    {/* Apply */}
                    {/* </Button> */}
                </div>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div className={clsx('calendermodel', focusedInput ? 'show' : 'hide')} />
            <div
                className={clsx(
                    'calenderwrapper',
                    props.preset === 'multi' ? 'multi' : 'single',
                    focusedInput ? 'show' : 'hide',
                )}
                // click to stopPropagation 
                onClick={(e) => { e.stopPropagation() }}
            >
                <DateRangePicker
                    onClose={handleClose}
                    isOutsideRange={() => {
                        return false;
                    }}
                    hideKeyboardShortcutsPanel={true}
                    renderCalendarInfo={
                        props.preset === 'multi' ? renderMultiDatePresets : renderSingleDatePresets
                    }
                    calendarInfoPosition="bottom"
                    showDefaultInputIcon={false}
                    showClearDates={false}
                    daySize={30}
                    anchorDirection={props.anchorDirection}
                    keepOpenOnDateSelect={true}
                    customInputIcon={props.getTrigger()}
                    small={true}
                    withPortal={props.withPortal}
                    orientation="horizontal"
                    withFullScreenPortal={false}
                    numberOfMonths={props.preset === 'multi' ? 2 : 1}
                    startDate={startDate} // momentPropTypes.momentObj or null,
                    startDateId="date_time_start_date_picker" // PropTypes.string.isRequired,
                    endDate={endDate} // momentPropTypes.momentObj or null,
                    endDateId="date_time_end_date_picker" // PropTypes.string.isRequired,
                    // navPrev={<img height={22} alt="" src={leftArrow} />}
                    // navNext={<img height={22} alt="" src={rightArrow} />}
                    navPrev={<i class="fa fa-angle-left" aria-hidden="true"></i>}
                    navNext={<i class="fa fa-angle-right" aria-hidden="true"></i>}
                    onDatesChange={({ startDate, endDate }) => {
                        if (props.preset === 'multi') {
                            // single data will not have presets
                            let customIndex = PRESETS.indexOf('Custom');
                            setactiveActionType(customIndex);

                            setEndDate(endDate);
                            setStartDate(startDate);
                            getOnChangeDates(startDate, endDate, startTime, endTime, customIndex)
                            !props.dateTimeContextUpdateOverrideFlag &&
                              dateTimeContext.handleDate(startDate, endDate);
                        } else {
                            setStartDate(startDate);
                            setEndDate(startDate);

                            !props.dateTimeContextUpdateOverrideFlag &&
                              dateTimeContext.handleDate(startDate, startDate);
                        }
                    }} // PropTypes.func.isRequired,
                    focusedInput={focusedInput}
                    minimumNights={0}
                    onFocusChange={(currentFocus) => {
                        if (props.preset === 'multi') {
                            setFocusedInput(currentFocus);
                        } else {
                            setFocusedInput('startDate');
                        }
                        props.onOpen ? props.onOpen() : '';
                    }}
                    {...extraParams}
                />
            </div>
        </Fragment>
    );
}

DateTimeRangePicker.propTypes = {
    anchorDirection: PropTypes.string,
    withPortal: PropTypes.bool
};

DateTimeRangePicker.defaultProps = {
    anchorDirection: 'right',
    withPortal: false
};

export default React.memo(DateTimeRangePicker)