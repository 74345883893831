import React, { Fragment } from 'react';

import useFormulaConfigComputed from '../common/useFormulaConfigComputed';
import _formulaConfsGet from '../common/_formulaConfsGet';
import ParameterConfigComputedCore from '../Common/ParameterConfigComputedCore';
import { CheckBox } from '../../../../../../../common/components';

const ParameterConfigComputed = (props) => {
  const [
    initialized,
    formulaConfActiveIndex,
    formulaConfParameterCollections,
    formulaConfPointerCollections,
    onFormulaConfParameterCollectionUpdateHandle,
    onFormulaConfPointerCollectionUpdateHandle,
    onFormulaConfResetHandle
  ] = useFormulaConfigComputed(props.formulaConfs, props.onFormulaConfsChange);

  const renderParameterConfigComputedCore = () => {
    return (
      <ParameterConfigComputedCore
        formulaConfActiveIndex={formulaConfActiveIndex}
        formulaConfParameterCollections={formulaConfParameterCollections}
        formulaConfPointerCollections={formulaConfPointerCollections}
        formulaConfs={props.formulaConfs}
        onFormulaConfParameterCollectionUpdateTrigger={
          onFormulaConfParameterCollectionUpdateHandle
        }
        onFormulaConfPointerCollectionUpdateTrigger={
          onFormulaConfPointerCollectionUpdateHandle
        }
        onFormulaConfCreateTrigger={() => {
          return null;
        }}
        onFormulaConfResetTrigger={onFormulaConfResetHandle}
      />
    );
  };

  const renderFn = () => {
    return (
      <Fragment>
        <div className='new-input-wrap d-flex justify-content-end'>
          <div className='new-custom-checkbox'>
            <CheckBox
              label={'Show Partial Calculation Result'}
              size='sm'
              checked={props.formulaConfs[0].is_partial}
              checkboxClicked={(is_partial) => {
                props.onFormulaConfsChange(
                  _formulaConfsGet(
                    {
                      ...props.formulaConfs[formulaConfActiveIndex],
                      is_partial
                    },
                    0,
                    props.formulaConfs
                  )
                );
              }}
            />
          </div>
        </div>

        {renderParameterConfigComputedCore()}
      </Fragment>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfigComputed;
