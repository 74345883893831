/**
 * All reducer for widget
 */
import {
  ALERT_MONITORING_DATA,
  ALERT_MONITORING_DATA_FAILURE,
  ALERT_MONITORING_DATA_SUCCESS,
  ALERTS_FOR_MONITORING,
  ALERTS_FOR_MONITORING_FAILURE,
  ALERTS_FOR_MONITORING_SUCCESS
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined,
};

export function getAlertMonitoringData(state = INITIAL_STATE, action) {
  switch (action.type) {
      case ALERT_MONITORING_DATA:
          return {
              ...state,
              loading: true,
              error: false
          };
      case ALERT_MONITORING_DATA_SUCCESS:
          return {
              ...state,
              data: action.response,
              error: false,
              loading: false
          };
      case ALERT_MONITORING_DATA_FAILURE:
          return {
              ...state,
              error: true,
              loading: false
          };
      default:
          return state
  }
}

export function getAlertForMonitoring(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ALERTS_FOR_MONITORING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case ALERTS_FOR_MONITORING_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false
            };
        case ALERTS_FOR_MONITORING_FAILURE:
            return {
                ...state,
                error: true,
                loading: false
            };
        default:
            return state
    }
  }