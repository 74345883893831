import { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';

const plantOptionCollectionGet = () => {
  return JSON.parse(localStorage.getItem('smartsense.member_plants')).reduce(
    (memo, { name, plantid, clientid }) => {
      return [
        ...memo,
        {
          label: name,
          value: plantid,
          clientid
        }
      ];
    },
    []
  );
};

const usePlantCollection = (plantConfCollection) => {
  const plantCollectionGet = useCallback((plantConfCollection) => {
    return plantConfCollection.reduce((memo, plantConf) => {
      const value =
        plantConf &&
        plantConf.plantid &&
        plantOptionCollectionGet().reduce((_memo, plantOption) => {
          return !_memo && plantOption.value === plantConf.plantid
            ? plantOption
            : _memo;
        }, null);

      return value ? [...memo, value] : [...memo, null];
    }, []);
  }, []);

  const [plantCollection, setPlantCollection] = useState([]);

  useEffect(() => {
    const plantCollection = plantCollectionGet(plantConfCollection);

    setPlantCollection(plantCollection);
  }, [plantConfCollection, plantCollectionGet]);

  return [plantCollection, setPlantCollection, plantOptionCollectionGet()];
};

const sensorOptionCollectionGet = (plantid) => {
  return JSON.parse(localStorage.getItem('smartsense.sensors')).reduce(
    (memo, { name, sensorid, messageversion, mappedlocationid }) => {
      const location =
        plantid &&
        JSON.parse(localStorage.getItem('smartsense.locationlist')).find(
          ({ locationid }) => {
            return locationid === mappedlocationid;
          }
        );

      switch (true) {
        case !plantid:
        case !!location && location.plant === plantid:
          return [
            ...memo,
            {
              label: name,
              value: sensorid,
              messageversion
            }
          ];

        default:
          return memo;
      }
    },
    []
  );
};

const useSensorCollection = (plantConfCollection) => {
  const sensorCollectionGet = useCallback((plantConfCollection) => {
    return plantConfCollection.reduce((memo, plantConf) => {
      const sensorOptionCollection =
        plantConf &&
        plantConf.sensors &&
        plantConf.sensors[0] &&
        plantConf.sensors[0].sensorid &&
        sensorOptionCollectionGet(plantConf.plantid);

      const value =
        sensorOptionCollection &&
        sensorOptionCollection.find(({ value }) => {
          return value === plantConf.sensors[0].sensorid;
        });

      return value ? [...memo, value] : [...memo, null];
    }, []);
  }, []);

  const [sensorCollection, setSensorCollection] = useState([]);

  useEffect(() => {
    const sensorCollection = sensorCollectionGet(plantConfCollection);

    setSensorCollection(sensorCollection);
  }, [plantConfCollection, sensorCollectionGet]);

  return [sensorCollection, setSensorCollection];
};

const useSensorOptionCollections = (plantConfCollection) => {
  const sensorOptionCollectionsGet = useCallback((plantConfCollection) => {
    return plantConfCollection.reduce((memo, plantConf) => {
      const sensorOptionCollection =
        plantConf && sensorOptionCollectionGet(plantConf.plantid);

      return sensorOptionCollection
        ? [...memo, sensorOptionCollection]
        : [...memo, null];
    }, []);
  }, []);

  const [sensorOptionCollections, setSensorOptionCollections] = useState([[]]);

  useEffect(() => {
    const sensorOptionCollections =
      sensorOptionCollectionsGet(plantConfCollection);

    setSensorOptionCollections(sensorOptionCollections);
  }, [plantConfCollection, sensorOptionCollectionsGet]);

  return [sensorOptionCollections, setSensorOptionCollections];
};

const sensorOptionGet = (plantid, sensorid) => {
  return (
    sensorOptionCollectionGet(plantid).find(({ value }) => {
      return value === sensorid;
    }) || null
  );
};

const parameterOptionCollectionGet = _.memoize(
  (plantid, sensorid) => {
    return JSON.parse(localStorage.getItem('smartsense.readingtypes')).reduce(
      (memo, { readingtypename, readingtypeid }) => {
        const value = (() => {
          const messageversion = (() => {
            const sensor = sensorOptionGet(plantid, sensorid);

            return sensor && sensor.messageversion;
          })();

          const readingtypeidCollection = JSON.parse(
            localStorage.getItem('smartsense.version_readingtype_mapping')
          )[messageversion];

          return (
            readingtypeidCollection &&
            readingtypeidCollection.includes(readingtypeid) && {
              label: readingtypename,
              value: readingtypeid
            }
          );
        })();

        return value ? [...memo, value] : memo;
      },
      []
    );
  },
  (plantid, sensorid) => JSON.stringify([plantid, sensorid])
);

const useParameterCollection = (plantConfCollection) => {
  const parameterCollectionGet = useCallback((plantConfCollection) => {
    return plantConfCollection.reduce((memo, plantConf) => {
      const parameterOptionCollection =
        plantConf &&
        plantConf.sensors &&
        plantConf.sensors[0] &&
        plantConf.sensors[0].readingtypeid &&
        parameterOptionCollectionGet(
          plantConf.plantid,
          plantConf.sensors[0].sensorid
        );

      const readingtypeid =
        (plantConf.sensors[0].readingtypeid &&
          plantConf.sensors[0].readingtypeid[0] &&
          plantConf.sensors[0].readingtypeid[0].rt) ||
        plantConf.sensors[0].readingtypeid;

      const value =
        parameterOptionCollection &&
        parameterOptionCollection.find(({ value }) => {
          return value === readingtypeid;
        });

      return value ? [...memo, value] : [...memo, null];
    }, []);
  }, []);

  const [parameterCollection, setParameterCollection] = useState([]);

  useEffect(() => {
    const parameterCollection = parameterCollectionGet(plantConfCollection);

    setParameterCollection(parameterCollection);
  }, [plantConfCollection, parameterCollectionGet]);

  return [parameterCollection, setParameterCollection];
};

const useParameterOptionCollections = (plantConfCollection) => {
  const parameterOptionCollectionsGet = useCallback((plantConfCollection) => {
    return plantConfCollection.reduce((memo, plantConf) => {
      const parameterOptionCollection =
        plantConf &&
        plantConf.sensors[0] &&
        plantConf.sensors[0].sensorid &&
        parameterOptionCollectionGet(
          plantConf.plantid,
          plantConf.sensors[0].sensorid
        );

      return parameterOptionCollection
        ? [...memo, parameterOptionCollection]
        : [...memo, null];
    }, []);
  }, []);

  const [parameterOptionCollections, setParameterOptionCollections] = useState([
    []
  ]);

  useEffect(() => {
    const parameterOptionCollections =
      parameterOptionCollectionsGet(plantConfCollection);

    setParameterOptionCollections(parameterOptionCollections);
  }, [plantConfCollection, parameterOptionCollectionsGet]);

  return [parameterOptionCollections, setParameterOptionCollections];
};

export default (plantConfCollection) => {
  const [plantCollection, setPlantCollection, plantOptionCollection] =
    usePlantCollection(plantConfCollection);

  const [sensorCollection, setSensorCollection] =
    useSensorCollection(plantConfCollection);

  const [sensorOptionCollections, setSensorOptionCollections] =
    useSensorOptionCollections(plantConfCollection);

  const [parameterCollection, setParameterCollection] =
    useParameterCollection(plantConfCollection);

  const [parameterOptionCollections, setParameterOptionCollections] =
    useParameterOptionCollections(plantConfCollection);

  const onPlantChangeHandle = (plantOption, index) => {
    const _plantCollection = [
      ...plantCollection.slice(0, index),
      plantOption,
      ...plantCollection.slice(index + 1)
    ];

    const _sensorCollection = [
      ...sensorCollection.slice(0, index),
      null,
      ...sensorCollection.slice(index + 1)
    ];

    const _sensorOptionCollections = [
      ...sensorOptionCollections.slice(0, index),
      sensorOptionCollectionGet(plantOption.value),
      ...sensorOptionCollections.slice(index + 1)
    ];

    const _parameterCollection = [
      ...parameterCollection.slice(0, index),
      null,
      ...parameterCollection.slice(index + 1)
    ];

    const _parameterOptionCollections = [
      ...parameterOptionCollections.slice(0, index),
      parameterOptionCollectionGet(plantOption.value, null),
      ...parameterOptionCollections.slice(index + 1)
    ];

    setPlantCollection(_plantCollection);

    setSensorCollection(_sensorCollection);

    setSensorOptionCollections(_sensorOptionCollections);

    setParameterCollection(_parameterCollection);

    setParameterOptionCollections(_parameterOptionCollections);
  };

  const onSensorChangeHandle = (sensorOption, index) => {
    const _sensorCollection = [
      ...sensorCollection.slice(0, index),
      sensorOption,
      ...sensorCollection.slice(index + 1)
    ];

    const _parameterCollection = [
      ...parameterCollection.slice(0, index),
      null,
      ...parameterCollection.slice(index + 1)
    ];

    const _parameterOptionCollections = [
      ...parameterOptionCollections.slice(0, index),
      parameterOptionCollectionGet(
        plantCollection[index] && plantCollection[index].value,
        sensorOption.value
      ),
      ...parameterOptionCollections.slice(index + 1)
    ];

    setSensorCollection(_sensorCollection);

    setParameterCollection(_parameterCollection);

    setParameterOptionCollections(_parameterOptionCollections);
  };

  const onParameterChangeHandle = (parameterOption, index, callback) => {
    return callback([
      ...plantConfCollection.slice(0, index),
      {
        ...plantConfCollection[index],
        plantid: plantCollection[index] && plantCollection[index].value,
        sensors: [
          {
            ...(() => {
              return plantCollection[index]
                ? {
                    readingtypeid: [
                      {
                        ...((plantConfCollection[index] &&
                          plantConfCollection[index].sensors[0]
                            .readingtypeid[0]) ||
                          {}),
                        rt: parameterOption.value
                      }
                    ]
                  }
                : {
                    ...(plantConfCollection[index] &&
                      plantConfCollection[index].sensors[0]),
                    readingtypeid: parameterOption.value
                  };
            })(),
            sensorid: sensorCollection[index].value
          }
        ],
        sensorwise: !plantCollection[index]
      },
      ...plantConfCollection.slice(index + 1)
    ]);
  };

  return [
    plantCollection,
    plantOptionCollection,
    sensorCollection,
    sensorOptionCollections,
    parameterCollection,
    parameterOptionCollections,
    onPlantChangeHandle,
    onSensorChangeHandle,
    onParameterChangeHandle,
    sensorOptionCollectionGet()
  ];
};
