import React, { useMemo, useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeServiceDefault } from '../AssetScreenDesign/action';
import { getAllWidgetConfig, getCustomDashboard } from '../Dashboard/action';
import { mergeConfig } from '../Dashboard/widgetsConfig';
import { Tabs } from '../../../common/components/';
import { DateTimeContext } from '../Main/context';
import WidgetCalenderConfig from '../Widgets/CommonControls/calendar';
import MemoizedDashboard from '../Dashboard/MemoizedDashboard';
import './Home.scss';
import DashboardFilterConfig from '../Dashboard/components/WidgetBuilder/ConfigComponents/Common/DashboardFilterConfig';
import dashboardFilterConfigInputGet from '../Dashboard/components/WidgetBuilder/ConfigComponents/common/dashboardFilterConfigInputGet';

const localStorageServiceCollectionGet = () => {
  return (
    localStorage.getItem('smartsense.services') &&
    JSON.parse(localStorage.getItem('smartsense.services'))
  );
};

const localStorageServiceIdGet = (_name) => {
  const localStorageService =
    localStorageServiceCollectionGet() &&
    localStorageServiceCollectionGet().find(({ name }) => name === _name);

  return localStorageService && localStorageService.id;
};

const localStorageServiceDashboardIdGet = () => {
  return localStorageServiceIdGet('Home');
};

const Home = (_props) => {
  const {
    getAllWidgetConfig,
    makeServiceDefault,
    getCustomDashboard,
    ...props
  } = _props;

  const sequenceData = useMemo(() => {
    return [
      ...(props.serviceDefaultData || []).map((_serviceDefaultData) => ({
        ..._serviceDefaultData,
        id: _serviceDefaultData.dashboard_id
      })),
      ...(props.customDashboardData || [])
    ];
  }, [props.serviceDefaultData, props.customDashboardData]);

  const location = useLocation();

  let currentRouteDashboard = location.pathname === '/Dashboard/';

  const memberDetails =
    localStorage.getItem('smartsense.member') &&
    JSON.parse(localStorage.getItem('smartsense.member'));

  let dashboardAlignmentFlag = memberDetails.dashboard_align === 'vertical';

  let toCheckDashboardAlign =
    dashboardAlignmentFlag === false
      ? `dashboard-container otherThanDashboard`
      : `dashboard-container`;

  const activeTabId = new URLSearchParams(location.search).get('id');

  const dateTimeContext = useContext(DateTimeContext);

  const activeTabIndex = sequenceData.findIndex(({ id }) => id === activeTabId);

  const [
    dashboardFilterConfigInitialized,
    setDashboardFilterConfigInitialized
  ] = useState();

  const [dashboardFilterConfig, setDashboardFilterConfig] = useState(
    dashboardFilterConfigInputGet()
  );

  useEffect(() => {
    getAllWidgetConfig({
      service_id: localStorageServiceIdGet('My Dashboard')
    });

    makeServiceDefault({
      service_id: localStorageServiceDashboardIdGet()
    });

    getCustomDashboard(localStorageServiceDashboardIdGet());
  }, [getAllWidgetConfig, makeServiceDefault, getCustomDashboard]);

  function reloadDashboard() {
    props.history.push('/Home/');
  }

  const getTabContentAtIndex = (i) => {
    let dashboard = sequenceData[i];
    return (
      <MemoizedDashboard
        dashboard={dashboard}
        allWidgetConfig={mergeConfig(props.allWidgetConfig.data)}
        reloadDashboard={reloadDashboard}
        dashboardFilterConfig={dashboardFilterConfig}
        showDateToggle={true}
      />
    );
  };

  const getTabHeaderAtIndex = (i) => {
    return (
      <div className='dashboard-tab-header'>
        {sequenceData[i] && sequenceData[i].dashboard_name
          ? sequenceData[i].dashboard_name
          : 'No Name'}
      </div>
    );
  };

  const selectedDash = sequenceData.find(({ id }) => id === activeTabId);

  const dashboardFilterConfigFlag =
    selectedDash &&
    (() => {
      const { plantFilter, tagFilter } = selectedDash.config || {};

      return plantFilter || tagFilter;
    })();

  return (
    <div
      id='dashboardAppWrapper'
      className={
        currentRouteDashboard === true
          ? `dashboard-container`
          : toCheckDashboardAlign
      }
    >
      <div className='dashboard-content-wrap'>
        <div className={` ${'overflow-no'}`}>
          {selectedDash && selectedDash.template_id && (
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
              className='widgets-calendar custom-calender'
            >
              {dashboardFilterConfigFlag ? (
                <DashboardFilterConfig
                  templateDashboard={sequenceData[activeTabIndex]}
                  dashboardFilterConfig={dashboardFilterConfig}
                  setDashboardFilterConfigInitialized={
                    setDashboardFilterConfigInitialized
                  }
                  setDashboardFilterConfig={setDashboardFilterConfig}
                />
              ) : (
                <div></div>
              )}

              {selectedDash && selectedDash.config.showDate ? (
                <WidgetCalenderConfig
                  handleDateRangeChange={dateTimeContext.handleDate}
                  datePreset={dateTimeContext.startDate}
                  startDate={dateTimeContext.startDate}
                  endDate={dateTimeContext.endDate}
                />
              ) : null}
            </div>
          )}

          {!props.loading &&
            (!dashboardFilterConfigFlag ||
              (dashboardFilterConfigFlag &&
                dashboardFilterConfigInitialized)) && (
              <Tabs
                onScroll={() => {
                  // eslint-disable-next-line no-empty
                }}
                noJustified={true}
                draggable={true}
                noOfTabs={sequenceData.length}
                size='md'
                getTabContentAtIndex={getTabContentAtIndex}
                getTabHeaderAtIndex={getTabHeaderAtIndex}
                activeTabIndex={activeTabIndex}
                hideHead={true}
              />
            )}

          {props.loading && <p>Loading ....</p>}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  let loadingWidgetConfig = state.allWidgetConfig.loading;
  const dashboardSequenceLoader = state.getDashboardSequence.loading;
  let loading = loadingWidgetConfig || dashboardSequenceLoader;

  return {
    loading: loading,
    allWidgetConfig: state.allWidgetConfig,
    widgetConfig: mergeConfig(state.allWidgetConfig.data),
    serviceDefaultData:
      state.makeServiceDefault.data[localStorageServiceDashboardIdGet()] &&
      state.makeServiceDefault.data[localStorageServiceDashboardIdGet()].result,
    customDashboardData:
      state.getCustomDashboard.data[localStorageServiceDashboardIdGet()] &&
      state.getCustomDashboard.data[localStorageServiceDashboardIdGet()].result
  };
};

export default connect(mapStateToProps, {
  getAllWidgetConfig,
  makeServiceDefault,
  getCustomDashboard
})(Home);