import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import parameterConfsInputGet from '../common/parameterConfsInputGet';
import parameterConfInputGet from '../common/parameterConfInputGet';
import formulaConfsInputGet from '../common/formulaConfsInputGet';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import formulaConfsOutputGet from '../common/formulaConfsOutputGet';
import LiveData from './LiveData';
import Table from './Table';
import { Button, RadioButtons } from '../../../../../../../common/components';

const typeCollection = ['Live Data', 'Table'];

const dataTypeCollection = ['Live', 'Aggregated'];

const tableConfigCollection = ['Regular', 'Global'];

const parameterConfsGet = (parameters) => {
  const parameterConfs = parameters.reduce((memo, parameter, index) => {
    const value = !parameter.isComputed && {
      index,
      globalThreshold: parameter.globalThreshold,
      locParam: {
        location: parameter.location,
        readingtype: parameter.parameter
      }
    };

    return value ? [...(memo || []), value] : memo;
  }, null);

  return (
    parameterConfs &&
    parameterConfsInputGet(parameterConfs).reduce((memo, parameterConf) => {
      return [
        ...memo,
        {
          ...parameterConf,
          isComputed: false
        }
      ];
    }, [])
  );
};

const formulaConfsGet = (parameters) => {
  const formulaConfs = parameters.reduce((memo, parameter, index) => {
    const value =
      parameter.isComputed &&
      (() => {
        const { formula, equation, is_partial } = parameter.computedFormula;

        return {
          index,
          globalThreshold: parameter.globalThreshold,
          formula: [{ formula }],
          equation,
          is_partial
        };
      })();

    return value ? [...(memo || []), value] : memo;
  }, null);

  return (
    formulaConfs &&
    formulaConfsInputGet(formulaConfs).reduce((memo, formulaConf) => {
      return [
        ...memo,
        {
          ...formulaConf,
          isComputed: true
        }
      ];
    }, [])
  );
};

const parametersGet = (_parameters) => {
  const parameters = [
    ...(() => {
      return parameterConfsGet(_parameters) || [];
    })(),
    ...(() => {
      return formulaConfsGet(_parameters) || [];
    })()
  ].sort((a, b) => a.index - b.index);

  return parameters.length
    ? (() => {
        return parameters.reduce((memo, parameter, index) => {
          const { alias, col, row, style, threshold, unit } =
            _parameters[index];

          return [
            ...memo,
            {
              ...parameter,
              alias,
              col,
              row,
              style,
              threshold,
              unit
            }
          ];
        }, []);
      })()
    : [{ ...parameterConfInputGet(), isComputed: false }];
};

const __parametersGet = (_parameter) => {
  const parameter = !_parameter
    ? null
    : !_parameter.isComputed
    ? (() => {
        const _parameterConfs = parameterConfsOutputGet([_parameter]);

        return _parameterConfs
          ? (() => {
              const {
                locParam: { location, readingtype: parameter },
                ...parameterConf
              } = _parameterConfs[0];

              return {
                ...parameterConf,
                location,
                parameter
              };
            })()
          : {};
      })()
    : (() => {
        const _formulaConfs = formulaConfsOutputGet([_parameter]);

        return _formulaConfs
          ? (() => {
              const { formula, equation, is_partial, ...formulaConf } =
                _formulaConfs[0];

              return {
                ...formulaConf,
                computedFormula: {
                  ...formula[0],
                  equation,
                  is_partial
                }
              };
            })()
          : {};
      })();

  return parameter;
};

const _parametersGet = (parameters) => {
  return parameters.reduce((memo, parameter) => {
    return [...memo, __parametersGet(parameter)];
  }, []);
};

const paramsOutputGet = (
  paramsLiveData,
  tableConfig,
  tableLayoutRow,
  tableLayoutColumn,
  dataTypeActiveIndex,
  parameters,
  typeActiveIndex
) => {
  return {
    params: typeActiveIndex
      ? {
          tableConfig,
          tableLayout: [tableLayoutRow, tableLayoutColumn],
          isDataTypeLive: !dataTypeActiveIndex,
          parameters: _parametersGet(parameters)
        }
      : paramsLiveData
  };
};

const DateTimeConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [type, setType] = useState(0);

  const [paramsLiveData, setParamsLiveData] = useState();

  const [dateRange, setDateRange] = useState();

  const [dataType, setDataType] = useState();

  const [tableConfig, setTableConfig] = useState();

  const [tableLayoutRow, setTableLayoutRow] = useState();

  const [tableLayoutColumn, setTableLayoutColumn] = useState();

  const [parameters, setParameters] = useState();

  const [threshold, setThreshold] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams.table_widget_params &&
        props.widgetParams.table_widget_params.value;

      setType(
        (conf && conf.type && typeCollection[conf.type]) || typeCollection[0]
      );

      setParamsLiveData((conf && conf.params && conf.params.length) || []);

      setDateRange((conf && conf.dateRange) || {});

      setDataType(
        conf && conf.params && !conf.params.isDataTypeLive
          ? dataTypeCollection[1]
          : dataTypeCollection[0]
      );

      setTableConfig(
        (conf && conf.params && conf.params.tableConfig) ||
          tableConfigCollection[0]
      );

      setTableLayoutRow(
        (conf &&
          conf.params &&
          conf.params.tableLayout &&
          conf.params.tableLayout[0]) || [{ value: 'Row', style: null }]
      );

      setTableLayoutColumn(
        (conf &&
          conf.params &&
          conf.params.tableLayout &&
          conf.params.tableLayout[1]) || [{ value: 'Column', style: null }]
      );

      setParameters(
        parametersGet(
          (conf &&
            conf.params &&
            conf.params.parameters &&
            conf.params.parameters) ||
            []
        )
      );

      setThreshold(conf.threshold || []);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const typeActiveIndex = typeCollection.findIndex((_type) => _type === type);

  const dataTypeActiveIndex = dataTypeCollection.findIndex(
    (_dataType) => _dataType === dataType
  );

  const appInputParamChangedHandle = (object) => {
    const value = {
      type: typeActiveIndex,
      ...paramsOutputGet(
        paramsLiveData,
        tableConfig,
        tableLayoutRow,
        tableLayoutColumn,
        dataTypeActiveIndex,
        parameters,
        typeActiveIndex
      ),
      ...(() => {
        return typeActiveIndex ? { dateRange } : {};
      })(),
      threshold,
      ...object
    };

    props.appInputParamChanged({
      table_widget_params: {
        value
      }
    });
  };

  const renderLiveData = () => {
    return (
      <LiveData
        paramsLiveData={paramsLiveData}
        onParamsLiveDataChange={(paramsLiveData) => {
          setParamsLiveData(paramsLiveData);

          appInputParamChangedHandle(
            paramsOutputGet(
              paramsLiveData,
              tableConfig,
              tableLayoutRow,
              tableLayoutColumn,
              dataTypeActiveIndex,
              parameters,
              typeActiveIndex
            )
          );
        }}
      />
    );
  };

  const renderTable = () => {
    return (
      <Table
        dateRange={dateRange}
        dataTypeCollection={dataTypeCollection}
        dataTypeActiveIndex={dataTypeActiveIndex}
        tableConfigCollection={tableConfigCollection}
        tableConfig={tableConfig}
        tableLayoutRow={tableLayoutRow}
        tableLayoutColumn={tableLayoutColumn}
        confs={parameters}
        threshold={threshold}
        onDateRangeChange={(dateRange) => {
          setDateRange(dateRange);

          appInputParamChangedHandle({ dateRange });
        }}
        onDataTypeActiveIndexChange={(dataTypeActiveIndex) => {
          setDataType(dataTypeCollection[dataTypeActiveIndex]);

          appInputParamChangedHandle(
            paramsOutputGet(
              paramsLiveData,
              tableConfig,
              tableLayoutRow,
              tableLayoutColumn,
              dataTypeActiveIndex,
              parameters,
              typeActiveIndex
            )
          );
        }}
        onTableConfigChange={(tableConfig) => {
          const _parameters = parameters.reduce((memo, parameter) => {
            return [
              ...memo,
              {
                ...parameter,
                globalThreshold:
                  tableConfig === tableConfigCollection[0] ? false : true
              }
            ];
          }, []);

          setTableConfig(tableConfig);

          setParameters(_parameters);

          appInputParamChangedHandle(
            paramsOutputGet(
              paramsLiveData,
              tableConfig,
              tableLayoutRow,
              tableLayoutColumn,
              dataTypeActiveIndex,
              _parameters,
              typeActiveIndex
            )
          );
        }}
        onTableLayoutRowChange={(tableLayoutRow) => {
          setTableLayoutRow(tableLayoutRow);

          appInputParamChangedHandle(
            paramsOutputGet(
              paramsLiveData,
              tableConfig,
              tableLayoutRow,
              tableLayoutColumn,
              dataTypeActiveIndex,
              parameters,
              typeActiveIndex
            )
          );
        }}
        onTableLayoutColumnChange={(tableLayoutColumn) => {
          setTableLayoutColumn(tableLayoutColumn);

          appInputParamChangedHandle(
            paramsOutputGet(
              paramsLiveData,
              tableConfig,
              tableLayoutRow,
              tableLayoutColumn,
              dataTypeActiveIndex,
              parameters,
              typeActiveIndex
            )
          );
        }}
        onConfsChange={(parameters) => {
          setParameters(parameters);

          appInputParamChangedHandle(
            paramsOutputGet(
              paramsLiveData,
              tableConfig,
              tableLayoutRow,
              tableLayoutColumn,
              dataTypeActiveIndex,
              parameters,
              typeActiveIndex
            )
          );
        }}
        onThresholdChange={(threshold) => {
          setThreshold(threshold);

          appInputParamChangedHandle({ threshold });
        }}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={12}>
            <div className='new-input-wrap'>
              <RadioButtons
                noOfButtons={2}
                getButtonLableAtIndex={(index) => {
                  return typeCollection[index];
                }}
                buttonClickAtIndex={(index) => {
                  setType(typeCollection[index]);
                }}
                active={typeActiveIndex}
              />
            </div>
          </Col>
        </Row>

        <div className='content-border-wrap minh100_42'>
          {typeActiveIndex === 0 ? renderLiveData() : renderTable()}
        </div>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default DateTimeConfig;
