import React, { useState, useEffect } from 'react';
import SelectDropDown from '../../../../../common/components/Select';
import { Label } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllAssetList } from '../../action';
import { getReadingtypeNameById } from '../../../utils';
import { getSystemMetaFields } from '../../../AssetScreenDesign/action';
import queryString from 'query-string';


function AssetType(props) {

  var [metaParam, setMetaParam] = useState([]);
  var [metaParamOpt, setMetaParamOpt] = useState([]);
  const templateAssetNames = localStorage.getItem('template.asset_names');

  const isSystemTemplateRoute =
    (!!location.search && !!queryString.parse(location.search).system_id);
  
  const system_id = queryString.parse(location.search).system_id;

  let metaParamOption =
    templateAssetNames && templateAssetNames !== 'undefined'
      ? JSON.parse(templateAssetNames)
      : null;

  const path = location.pathname.split('/');

  useEffect(() => {
    if (!props.isLoaded && Array.isArray(props.allAssets)) {
      props.getAllAssetList({});
    }
  }, []);

  useEffect(() => {
    if (isSystemTemplateRoute) {
        props.getSystemMetaFields({
          system_id: system_id
        })
    }
  }, [props.isSystemListView]);


  useEffect(() => {
    const { success, data } = props.systemsMetaDataFields;

    success &&
      (() => {
        if (props.isSystemListView && isSystemTemplateRoute) {
          setMetaParamOpt(data.system_meta_fields || []);
        }
        if (!props.isSystemListView && isSystemTemplateRoute) {
          setMetaParamOpt(data.asset_meta_fields || []);
        }
      })();
  }, [
    props.systemsMetaDataFields
  ]);

  useEffect(() => {
    if (!props.isSystemListView && !isSystemTemplateRoute) {
      if (
        path.indexOf('Templates') === 1 &&
        metaParamOption?.asset_name?.parameters
      ) {
        setMetaParamOpt(metaParamOption.asset_name.parameters);
      } else if (Array.isArray(props.allAssets)) {
        const prm = props.allAssets.reduce((acc, elem) => {
          return acc.concat(elem.parameters || []);
        }, []);
        setMetaParamOpt(prm);
      }
    } else if (metaParamOption) {
      setMetaParamOpt(metaParamOption);
    }
  }, [props.allAssets]);

  useEffect(() => {
    if (props.value) {
      setMetaParam(props.value)
    }
  }, [props.value]);

  const groupOptions = () => {
    let arr = [];

    if (Array.isArray(metaParamOpt) && metaParamOpt.length > 0) {
      let opt = metaParamOpt.map((a) => {
        return {
          label: a?.name || a?.display_name || 'Default Label',
          value: a?.id || 'default_value'
        };
      });

      arr.push({
        label: 'META PARAMS',
        options: opt
      });
    }
    let el = _.groupBy(props.allAssets, (elem) => {
      return elem && elem.id;
    });

    Object.keys(el).map((item) => {
      let e = [];
      const getOpt = () => {
        let asset = el[item][0];

        Array.isArray(asset && asset.sensor_types) &&
          asset.sensor_types.map((a) => {
            Array.isArray(a.reading_types) &&
              a.reading_types.map((reading) => {
                e.push({
                  label:
                    getReadingtypeNameById(reading) || `No Label - ${reading}`,
                  value: reading,
                  is_reading_type: true,
                  sensor_type_id: a.id
                });
              });
          });

        return e;
      };

      let op = !props.getallassetoverwriteflag && getOpt();
      if (op.length > 0) {
        arr.push({
          label: item,
          options: op
        });
      }
    });

    return arr;
  }; 

  return <Col className="select-dropdown custom-select-parameter" xl={props.width ? props.width : 12} >
    <div>
      <Label for={props.name} className='label'>{props.label}
        {props.required === true ? <span className='text-danger'> * </span> : null}
      </Label>
      <SelectDropDown
        name= {!props.isSystemListView ? 'asset-type': 'system-type'}
        value={metaParam}
        onChange={(e) => {
          setMetaParam(e);
          props.onChange(e, false);
        }}
        options={groupOptions()}
        placeholder={!props.isSystemListView ? 'Select Asset Params': 'Select system Params'}
        isMulti={props.isMulti}
      />
    </div>
  </Col>

}


const mapStateToProps = (state) => {
  return {
    allAssets: state.getAllAssetList.data,
    isLoaded: state.getAllAssetList.success,
    systemsMetaDataFields: state.systemsMetaDataFields
  };
};

AssetType.defaultProps = {
  appParams: {},
  appInputParamChanged: function () { },
  isMulti: true
}

AssetType.propTypes = {
  appParams: PropTypes.object,
  appInputParamChanged: PropTypes.func,
  isMulti: PropTypes.bool
}

export default connect(mapStateToProps, {
  getAllAssetList,
  getSystemMetaFields
})(AssetType);