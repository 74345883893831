import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import useFormulaConfigComputed from '../common/useFormulaConfigComputed';
import _formulaConfsGet from '../common/_formulaConfsGet';
import {
  Input,
  CheckBox,
  Select
} from '../../../../../../../common/components';
import ParameterConfigComputedCore from '../Common/ParameterConfigComputedCore';
import ParameterConfigComputedCollection from '../Common/ParameterConfigComputedCollection';

const graphTypeCollection = ['Bar Graph', 'Line Graph', 'Area Graph'];

const ParameterConfigComputed = (props) => {
  const [
    initialized,
    formulaConfActiveIndex,
    formulaConfParameterCollections,
    formulaConfPointerCollections,
    onFormulaConfParameterCollectionUpdateHandle,
    onFormulaConfPointerCollectionUpdateHandle,
    onFormulaConfResetHandle,
    onFormulaConfActiveIndexChangeHandle,
    onFormulaConfCreateHandle,
    onFormulaConfDeleteHandle
  ] = useFormulaConfigComputed(props.formulaConfs, props.onFormulaConfsChange);

  const renderParameterConfigComputedCore = () => {
    return (
      <ParameterConfigComputedCore
        formulaConfActiveIndex={formulaConfActiveIndex}
        formulaConfParameterCollections={formulaConfParameterCollections}
        formulaConfPointerCollections={formulaConfPointerCollections}
        formulaConfs={props.formulaConfs}
        onFormulaConfParameterCollectionUpdateTrigger={
          onFormulaConfParameterCollectionUpdateHandle
        }
        onFormulaConfPointerCollectionUpdateTrigger={
          onFormulaConfPointerCollectionUpdateHandle
        }
        onFormulaConfCreateTrigger={onFormulaConfCreateHandle}
        onFormulaConfResetTrigger={onFormulaConfResetHandle}
      />
    );
  };

  const renderParameterConfigComputedCollection = () => {
    return (
      <ParameterConfigComputedCollection
        formulaConfActiveIndex={formulaConfActiveIndex}
        formulaConfPointerCollections={formulaConfPointerCollections}
        formulaConfParameterCollections={formulaConfParameterCollections}
        formulaConfs={props.formulaConfs}
        onFormulaConfActiveIndexChangeTrigger={
          onFormulaConfActiveIndexChangeHandle
        }
        onFormulaConfDeleteTrigger={onFormulaConfDeleteHandle}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='content-border-wrap pd-y-10 mg-b-15'>
        <div className='d-flex align-items-center justify-content-between'>
          <p className='head-label-text mb-0'>{`Parameter ${
            formulaConfActiveIndex + 1
          }`}</p>

          <div className='new-input-wrap mb-0'>
            <div className='new-custom-checkbox'>
              <CheckBox
                label={'Show Partial Calculation Result'}
                size='sm'
                checked={props.formulaConfs[formulaConfActiveIndex].showPartial}
                checkboxClicked={(showPartial) => {
                  props.onFormulaConfsChange(
                    _formulaConfsGet(
                      {
                        ...props.formulaConfs[formulaConfActiveIndex],
                        showPartial
                      },
                      formulaConfActiveIndex,
                      props.formulaConfs
                    )
                  );
                }}
              />
            </div>
          </div>
        </div>

        <hr className='horizontal-divider mg-t-10 mg-b-15' />

        <Row className='alias-data-wrap'>
          <Col xs={12}>
            <Row>
              <Col md={2}>
                <div className='new-input-wrap'>
                  <div className='d-flex justify-content-between'>
                    <label className='label-text'>Alias</label>
                  </div>
                  <Input
                    size='sm'
                    label=''
                    type='text'
                    required={false}
                    placeholder='Enter Alias'
                    value={
                      props.formulaConfs[formulaConfActiveIndex].alias || ''
                    }
                    inputChanged={(alias) => {
                      props.onFormulaConfsChange(
                        _formulaConfsGet(
                          {
                            ...props.formulaConfs[formulaConfActiveIndex],
                            alias
                          },
                          formulaConfActiveIndex,
                          props.formulaConfs
                        )
                      );
                    }}
                  />
                </div>
              </Col>

              <Col md={2}>
                <div className='new-input-wrap'>
                  <div className='d-flex justify-content-between'>
                    <label className='label-text'>Unit</label>

                    <label className='label-text font-weight-light'>
                      Show As
                    </label>
                  </div>

                  <Input
                    size='sm'
                    label=''
                    type='text'
                    required={false}
                    placeholder='Enter Unit'
                    value={
                      props.formulaConfs[formulaConfActiveIndex].unit || ''
                    }
                    inputChanged={(unit) => {
                      props.onFormulaConfsChange(
                        _formulaConfsGet(
                          {
                            ...props.formulaConfs[formulaConfActiveIndex],
                            unit
                          },
                          formulaConfActiveIndex,
                          props.formulaConfs
                        )
                      );
                    }}
                  />
                </div>
              </Col>

              <Col md={2}>
                <div className='new-input-wrap'>
                  <label className='label-text'>Graph Type</label>

                  <Select
                    options={graphTypeCollection.reduce(
                      (memo, value) => [...memo, { label: value, value }],
                      []
                    )}
                    isMulti={false}
                    placeholder='Select Graph Type'
                    value={(() => {
                      const value =
                        props.formulaConfs[formulaConfActiveIndex].graphType ||
                        '';

                      return {
                        label: value,
                        value
                      };
                    })()}
                    onChange={({ value }) => {
                      props.onFormulaConfsChange(
                        _formulaConfsGet(
                          {
                            ...props.formulaConfs[formulaConfActiveIndex],
                            graphType: value
                          },
                          formulaConfActiveIndex,
                          props.formulaConfs
                        )
                      );
                    }}
                  />
                </div>
              </Col>

              <Col md={2}>
                <div className='new-input-wrap'>
                  <label className='label-text'>Line Label</label>
                  <Input
                    size='sm'
                    label=''
                    type='text'
                    required={false}
                    placeholder='Enter Line Label'
                    value={
                      props.formulaConfs[formulaConfActiveIndex].lineLabel || ''
                    }
                    inputChanged={(lineLabel) => {
                      props.onFormulaConfsChange(
                        _formulaConfsGet(
                          {
                            ...props.formulaConfs[formulaConfActiveIndex],
                            lineLabel
                          },
                          formulaConfActiveIndex,
                          props.formulaConfs
                        )
                      );
                    }}
                  />
                </div>
              </Col>

              <Col md={2}>
                <div className='new-input-wrap'>
                  <label className='label-text'>Line Value</label>
                  <Input
                    size='sm'
                    label=''
                    type='text'
                    required={false}
                    placeholder='Enter Line Value'
                    value={
                      props.formulaConfs[formulaConfActiveIndex].lineValue || ''
                    }
                    inputChanged={(lineValue) => {
                      props.onFormulaConfsChange(
                        _formulaConfsGet(
                          {
                            ...props.formulaConfs[formulaConfActiveIndex],
                            lineValue
                          },
                          formulaConfActiveIndex,
                          props.formulaConfs
                        )
                      );
                    }}
                  />
                </div>
              </Col>

              <Col md={2}>
                <div className='new-input-wrap'>
                  <label className='label-text'>Style</label>
                  <Input
                    size='sm'
                    label=''
                    type='color'
                    required={false}
                    placeholder='Select Style'
                    value={
                      props.formulaConfs[formulaConfActiveIndex].color || ''
                    }
                    inputChanged={(color) => {
                      props.onFormulaConfsChange(
                        _formulaConfsGet(
                          {
                            ...props.formulaConfs[formulaConfActiveIndex],
                            color
                          },
                          formulaConfActiveIndex,
                          props.formulaConfs
                        )
                      );
                    }}
                  />
                </div>
              </Col>
            </Row>

            {renderParameterConfigComputedCore()}
          </Col>
        </Row>

        {renderParameterConfigComputedCollection()}
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfigComputed;
