import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators'
import Config from 'Config'
import { httpMethodGetJSON, httpMethodPost } from '../https';
import {
    GE_WIDGET_TEMPLATE
} from './constant';
export const URL = Config.api_url;

export const getGHGEmissionsTemplate = action$ => action$.pipe(
    ofType(GE_WIDGET_TEMPLATE),
    mergeMap(action => {
        if (action.payload.isComputed === true) {
            return httpMethodPost(action, `${URL}/reading/reading_computed_data/`);
            // return httpMethodPost(action, `${URL}/reading_computed_data/`);
          }
        return httpMethodGetJSON(action, `${URL}/reading/reading_data/`);
    })
);

export const ghgEmissionsEpics = {
    getGHGEmissionsTemplate
}