import queryString from 'query-string';

import {
  GET_ASSET_CBM_INSIGHT_OCCURRENCE,
  GET_ASSET_CBM_INSIGHT_OCCURRENCE_SUCCESS,
  GET_ASSET_CBM_INSIGHT_OCCURRENCE_FAILURE,
  GET_NETWORK_INSIGHT_OCCURRENCE,
  GET_NETWORK_INSIGHT_OCCURRENCE_SUCCESS,
  GET_NETWORK_INSIGHT_OCCURRENCE_FAILURE
} from './constant';

export const getAssetCbmInsightOccurrence = (
  state = {
    data: {},
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_ASSET_CBM_INSIGHT_OCCURRENCE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: action.payload.subId
            ? state.data[action.payload.widgetId]
            : {}
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: action.payload.subId
            ? state.error[action.payload.widgetId]
            : false
        }
      };

    case GET_ASSET_CBM_INSIGHT_OCCURRENCE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...(state.data[action.param.widgetId] || {}),
            [action.param.subId]: { ...action.param, ...action.response }
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case GET_ASSET_CBM_INSIGHT_OCCURRENCE_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export const getNetworkInsightsOccurence = (
  state = {
    data: {},
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_NETWORK_INSIGHT_OCCURRENCE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: action.payload.subId
            ? state.data[action.payload.widgetId]
            : {}
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: action.payload.subId
            ? state.error[action.payload.widgetId]
            : false
        }
      };

    case GET_NETWORK_INSIGHT_OCCURRENCE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...(state.data[action.param.widgetId] || {}),
            [action.param.subId]: { ...action.param, ...action.response }
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case GET_NETWORK_INSIGHT_OCCURRENCE_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};
