import {
  ERROR,
  ERROR_CANCEL,
  SUCCESS,
  SUCCESS_CANCEL,
  DELETE_MEMBER_SUCCESS,
  CREATE_MEMBER_SUCCESS,
  EDIT_MEMBER_SUCCESS,
  EDIT_ROLE_SUCCESS,
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE_SUCCESS,
  GET_LOGIN_DATA_SUCCESS,
  POST_ASSET_ACTION_DATA_SUCCESS,
  POST_ALERT_ACTION_DATA_SUCCESS,
  POST_USER_ACTIVITY_ACTION_DATA_SUCCESS
} from '../constants'
import { ERROR_TOAST, SUCCESS_TOAST } from './constant';


const STATE = {
  message: '',
  type:'',
  loggedOut: false
}

export function errorReducer (state = STATE, action) {
  switch (action.type) {
    case ERROR_TOAST:

      return {
        type: 'Other',
        message: action.payload.message ||  "Please Try Again !!!",
        loggedOut: false
      };
    break;
    case ERROR_CANCEL:

      return STATE;
    case ERROR:
      if (action.payload.status === 401) {
        localStorage.clear();
        window.location.href = window.location.origin + '#/login/SmartSense';
        // window.location.reload();
        console.error("Auth Error", action.payload.xhr)
      }
      else if(action.payload.status === 403){
        console.error("Forbidden", action.payload.xhr)
      } 
      else if(action.payload.status === 400){
        console.error("Forbidden", action.payload.xhr)
      }

    console.error(`API ERROR: ${action.payload.message}`);
    return STATE;

   default:
    return state;
  }
}

const INITIAL = {
  message: '',
  type: ''
}

export function successReducer (state = INITIAL, action) {
  switch (action.type) {
    case SUCCESS_CANCEL:
      return INITIAL;
    case SUCCESS_TOAST:
      return {
        message: action.payload.message ? action.payload.message : "Action Done" ,
        type: 'submission'
      }
    break;
    case EDIT_MEMBER_SUCCESS:
      return {
        message: "User Edited Succesfully" ,
        type: 'edit_member'
      }


    case CREATE_MEMBER_SUCCESS:
      return {
        message:  "New User Created Succesfully" ,
        type: 'create_member'
      }
   
    case DELETE_MEMBER_SUCCESS:
      return {
        message:  "User Deleted Successfully",
        type: 'delete_member'
      }


      // roles
    case EDIT_ROLE_SUCCESS :
      return {
        message: "Role Edited Successfully" ,
        type: 'edit_role'
      };

    case CREATE_ROLE_SUCCESS:
      return {
        message: "New Role Created Successfully" ,
        type: 'create_role'
      }

    case DELETE_ROLE_SUCCESS :
      return {
        message: "Role deleted Successfully" ,
        type: 'delete_role'
      };

    case GET_LOGIN_DATA_SUCCESS:
      return{
        message: "User Logged In",
        type: "login"
      }
    case POST_ASSET_ACTION_DATA_SUCCESS:
      return{
        message: 'Action Submitted Successfully',
        type: "submission insight"
      }
    case POST_ALERT_ACTION_DATA_SUCCESS:
      return {
        message: 'Alert action Submitted Successfully',
        type: "submission alert"
      }
    case POST_USER_ACTIVITY_ACTION_DATA_SUCCESS:
      return {
        message: 'User activity added Successfully',
        type: "submission useractivity"
      }
    default:
      return state
  }
}