/**
 * All epics reqiured for widget 
 */
import Config from 'Config'
import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";
import {
  ALERT_STATUS_DATA
} from './constant';
import { httpMethodGetJSON } from '../../https';

const URL = Config.api_url;
const STATUS_URL = `${URL}/alerts/status-summary/`;

export const getAlertStatusData = (action$) =>
  action$.pipe(
    ofType(ALERT_STATUS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, STATUS_URL);
    }),
  );