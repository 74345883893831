/**
 * All reducer for widget
 */
import {
  EVENT_ACTIVITY_SUMMARY,
  EVENT_ACTIVITY_SUMMARY_FAILURE,
  EVENT_ACTIVITY_SUMMARY_SUCCESS
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined,
};

export function getEventActivitySummary(state = INITIAL_STATE, action) {
  switch (action.type) {
      case EVENT_ACTIVITY_SUMMARY:
          return {
              ...state,
              loading: true,
              error: false
          };
      case EVENT_ACTIVITY_SUMMARY_SUCCESS:
          return {
              ...state,
              data: action.response,
              error: false,
              loading: false
          };
      case EVENT_ACTIVITY_SUMMARY_FAILURE:
          return {
              ...state,
              error: true,
              loading: false
          };
      default:
          return state
  }
}