import React, { useState, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import parameterConfInputGet from '../common/parameterConfInputGet';
import collectionGet from '../common/collectionGet';
import _ParameterConfig from './_ParameterConfig';
import ParameterMixedCollection from '../Common/ParameterMixedCollection';
import {
  Select,
  Input,
  CheckBox
} from '../../../../../../../common/components';

const scopeCollection = [
  'Scope 1',
  'Scope 1 - Target',
  'Scope 2',
  'Scope 2 - Target',
  'Scope 3',
  'Scope 3 - Target',
  'Specific Energy consumption',
  'Specific Energy consumption - Target',
  'Specific CO2 Emission',
  'Specific CO2 Emission - Target',
  'Energy Consumption per million revenue',
  'Energy Consumption per million revenue - Target',
  'CO2 Consumption per million revenue',
  'CO2 Consumption per million revenue - Target',
  'Total GHG Emission',
  'Energy Consumed'
];

const costingTypeCollection = ['Total', 'Minimum', 'Maximum'];

const dataFrequencyCollection = ['15 min', '30 min', '60 min', '1 Day'];

const currencyCollection = [
  '₹ (Indian Rupees)',
  'AED (United Arab Emirates Dirham)',
  'Rp (Indonesian Rupiah)',
  'MYR (Malaysian Ringgit)',
  'S$ (Singapore dollar)',
  '₫ (Vietnamese dong)',
  '$ (US Dollar)',
  '€ (Europe - Euro)',
  'A$ (Australian dollar)'
];

const numberSystemCollection = ['Lakhs and Crores', 'Millions and Billions'];

const ParameterConfigMixed = (props) => {
  const [confCount, setConfCount] = useState(props.confs.length || 1);

  const [confActiveIndex, setConfActiveIndex] = useState(confCount - 1);

  const _renderParameterConfig = (conf, index) => {
    return (
      <_ParameterConfig
        conf={conf}
        onConfChange={(conf) => {
          props.onConfsChange(collectionGet(conf, index, props.confs));
        }}
        onParameterConfsChange={(conf) => {
          const _conf = {
            ...conf,
            index,
            isComputed: false
          };

          props.onConfsChange(collectionGet(_conf, index, props.confs));
        }}
        onFormulaConfsChange={(conf) => {
          const _conf = {
            ...conf,
            index,
            isComputed: true
          };

          props.onConfsChange(collectionGet(_conf, index, props.confs));
        }}
      />
    );
  };

  const renderParameterConfig = (conf, index) => {
    return (
      <Fragment key={index}>
        <p className='head-label-text mg-b-10'>Parameter {index + 1}</p>

        <div className='new-input-wrap'>
          <div className='new-custom-checkbox'>
            <CheckBox
              label={'Show detail summary'}
              size='sm'
              checked={conf.showOnToolTip}
              checkboxClicked={(showOnToolTip) => {
                props.onConfsChange(
                  collectionGet(
                    {
                      ...conf,
                      showOnToolTip
                    },
                    index,
                    props.confs
                  )
                );
              }}
            />
          </div>
        </div>

        <Row>
          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Scope</label>

              <Select
                options={scopeCollection.reduce(
                  (memo, value) => [...memo, { label: value, value }],
                  []
                )}
                isMulti={false}
                placeholder='Select Scope'
                value={(() => {
                  const value = conf.scope;

                  return (
                    value && {
                      label: value,
                      value
                    }
                  );
                })()}
                onChange={({ value }) => {
                  props.onConfsChange(
                    collectionGet(
                      {
                        ...conf,
                        scope: value
                      },
                      index,
                      props.confs
                    )
                  );
                }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Saturation Level</label>

              <Input
                size='sm'
                label=''
                type='number'
                required={false}
                placeholder='Enter Saturation Level'
                value={conf.saturationLevel || ''}
                inputChanged={(saturationLevel) => {
                  saturationLevel !== conf.saturationLevel &&
                    props.onConfsChange(
                      collectionGet(
                        {
                          ...conf,
                          saturationLevel
                        },
                        index,
                        props.confs
                      )
                    );
                }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Off Track</label>

              <Input
                size='sm'
                label=''
                type='number'
                required={false}
                placeholder='Enter Off Track'
                value={conf.offTrack || ''}
                inputChanged={(offTrack) => {
                  offTrack !== conf.offTrack &&
                    props.onConfsChange(
                      collectionGet(
                        {
                          ...conf,
                          offTrack
                        },
                        index,
                        props.confs
                      )
                    );
                }}
              />
            </div>
          </Col>
        </Row>

        {(conf.scope === scopeCollection[scopeCollection.length - 1] ||
          conf.scope === scopeCollection[scopeCollection.length - 2]) && (
          <Row>
            <Col md={4}>
              <div className='new-input-wrap'>
                <label className='label-text'>Costing Type</label>

                <Select
                  options={(conf.isComputed
                    ? costingTypeCollection.slice(0, 1)
                    : costingTypeCollection
                  ).reduce(
                    (memo, value) => [...memo, { label: value, value }],
                    []
                  )}
                  isMulti={false}
                  placeholder='Select Costing Type'
                  value={(() => {
                    const value = conf.cost && conf.cost.costingType;

                    return (
                      value && {
                        label: value,
                        value
                      }
                    );
                  })()}
                  onChange={({ value }) => {
                    props.onConfsChange(
                      collectionGet(
                        {
                          ...conf,
                          cost: {
                            ...conf.cost,
                            costingType: value
                          }
                        },
                        index,
                        props.confs
                      )
                    );
                  }}
                />
              </div>
            </Col>

            {conf.cost && conf.cost.costingType !== costingTypeCollection[0] && (
              <Col md={4}>
                <div className='new-input-wrap'>
                  <label className='label-text'>Data Frequency</label>

                  <Select
                    options={dataFrequencyCollection.reduce(
                      (memo, value) => [...memo, { label: value, value }],
                      []
                    )}
                    isMulti={false}
                    placeholder='Select Data Frequency'
                    value={(() => {
                      const value = conf.cost && conf.cost.dataFrequency;

                      return (
                        value && {
                          label: value,
                          value
                        }
                      );
                    })()}
                    onChange={({ value }) => {
                      props.onConfsChange(
                        collectionGet(
                          {
                            ...conf,
                            cost: {
                              ...conf.cost,
                              dataFrequency: value
                            }
                          },
                          index,
                          props.confs
                        )
                      );
                    }}
                  />
                </div>
              </Col>
            )}

            <Col md={4}>
              <div className='new-input-wrap'>
                <label className='label-text'>Currency</label>

                <Select
                  options={currencyCollection.reduce(
                    (memo, value) => [...memo, { label: value, value }],
                    []
                  )}
                  isMulti={false}
                  placeholder='Select Currency'
                  value={(() => {
                    const value = conf.cost && conf.cost.currency;

                    return (
                      value && {
                        label: value,
                        value
                      }
                    );
                  })()}
                  onChange={({ value }) => {
                    props.onConfsChange(
                      collectionGet(
                        {
                          ...conf,
                          cost: {
                            ...conf.cost,
                            currency: value
                          }
                        },
                        index,
                        props.confs
                      )
                    );
                  }}
                />
              </div>
            </Col>

            <Col md={4}>
              <div className='new-input-wrap'>
                <label className='label-text'>Number System</label>

                <Select
                  options={numberSystemCollection.reduce(
                    (memo, value) => [...memo, { label: value, value }],
                    []
                  )}
                  isMulti={false}
                  placeholder='Select Number System'
                  value={(() => {
                    const value = conf.cost && conf.cost.numbersystem;

                    return (
                      value && {
                        label: value,
                        value
                      }
                    );
                  })()}
                  onChange={({ value }) => {
                    props.onConfsChange(
                      collectionGet(
                        {
                          ...conf,
                          cost: {
                            ...conf.cost,
                            numbersystem: value
                          }
                        },
                        index,
                        props.confs
                      )
                    );
                  }}
                />
              </div>
            </Col>

            <Col md={4}>
              <div className='new-input-wrap'>
                <label className='label-text'>Unit Rate</label>

                <Input
                  size='sm'
                  label=''
                  type='number'
                  required={false}
                  placeholder='Enter Unit Rate'
                  value={(conf.cost && conf.cost.rate) || ''}
                  inputChanged={(rate) => {
                    rate !== conf.cost.rate &&
                      props.onConfsChange(
                        collectionGet(
                          {
                            ...conf,
                            cost: {
                              ...conf.cost,
                              rate
                            }
                          },
                          index,
                          props.confs
                        )
                      );
                  }}
                />
              </div>
            </Col>
          </Row>
        )}

        {_renderParameterConfig(conf, index)}
      </Fragment>
    );
  };

  const renderParameterMixedCollection = () => {
    return (
      <ParameterMixedCollection
        confs={props.confs}
        onConfActiveIndexChangeTrigger={(index) => {
          setConfActiveIndex(index);
        }}
        onConfDeleteTrigger={(index) => {
          const _confActiveIndex = (() => {
            switch (true) {
              case index < confActiveIndex:
                return confActiveIndex - 1;

              case index > confActiveIndex:
                return confActiveIndex;

              case index === confActiveIndex && !!index:
                return confActiveIndex - 1;

              default:
                return 0;
            }
          })();

          setConfActiveIndex(_confActiveIndex);

          const confs = collectionGet(null, index, props.confs);

          props.onConfsChange(
            confs.length
              ? confs
              : [
                  {
                    ...parameterConfInputGet(),
                    isComputed: false
                  }
                ]
          );
        }}
      />
    );
  };

  return (
    <Fragment>
      {renderParameterConfig(
        props.confs[confActiveIndex] || {},
        confActiveIndex
      )}

      <Fragment>
        <div className='btn-wrap'>
          <button
            className='icon-text-btn mg-t-15'
            onClick={() => {
              setConfActiveIndex(confCount);

              setConfCount((confCount) => ++confCount);

              props.onConfsChange([
                ...props.confs,
                { ...parameterConfInputGet(), isComputed: false }
              ]);
            }}
          >
            <i className='fa fa-plus-circle' aria-hidden='true'></i> Add
            Parameter
          </button>
        </div>

        <br />

        {renderParameterMixedCollection()}
      </Fragment>
    </Fragment>
  );
};

export default ParameterConfigMixed;
