import React, { useContext, useState, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ModalBody } from 'reactstrap';

import { Context } from '../../../admin/container/Main/context';
import htmlToCanvas from '../../../common/utils/Export';
import {
  getAllWidgetConfigExtended,
  getDashboardConfigHistory
} from '../../../admin/container/Dashboard/action';
import { ModalBox } from '../../../common/components/';
import WidgetSelector from '../../../admin/container/Dashboard/components/WidgetSelector/';
import DashboardConfigHistory from '../../../admin/container/Dashboard/components/DashboardConfigHistory';

const DashboardOption = (props) => {
  const context = useContext(Context);

  const oHtmlToCanvas = new htmlToCanvas();

  const [isOpenModalDialog, setIsOpenModalDialog] = useState(false);

  const history = useHistory();

  const [isWidgetSelectorOpen, setIsWidgetSelectorOpen] = useState(false);

  const [selectedWidgetIndex, setSelectedWidgetIndex] = useState(0);

  const [isOpenAuditTrail, setAuditTrail] = useState(false);

  const allWidgetConfig = useMemo(() => {
    return (
      props.allWidgetConfigExtended.data &&
      props.allWidgetConfigExtended.data[props.serviceIdActive]
    );
  }, [props.allWidgetConfigExtended, props.serviceIdActive]);

  return (
    <Fragment>
      <div
        className={`dropdown-menu ${
          props.selectedId === props.dashboard.id &&
          props.showDashboardOption &&
          'show'
        } `}
      >
        <button
          className='menu-item'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            setIsWidgetSelectorOpen(true);

            props.onSidebarShowToggle();

            !allWidgetConfig
              ? props.getAllWidgetConfigExtended({
                  service_id: props.serviceIdActive
                })
              : setIsWidgetSelectorOpen(true);
          }}
        >
          Add Widgets
        </button>

        <button
          className='menu-item'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            history.push({
              pathname: `/Dashboard/EditDashboard/${props.dashboard.id}`,
              state: {
                ...props.dashboard,
                serviceId: props.serviceIdActive
              }
            });

            props.onSidebarShowToggle();
          }}
        >
          Edit Dashboard
        </button>

        <button
          className='menu-item'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            context.confirm.show(
              `Are you sure you want to delete ${props.dashboard.name}`,
              () => {
                props.onDeleteDashboard({
                  id: props.dashboard.id
                });
              }
            );
          }}
        >
          Delete Dashboard
        </button>

        <button
          className={`menu-item ${
            props.showDashboardSubOption && 'active-text'
          }`}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            props.onSetShowDashboardSubOption(!props.showDashboardSubOption);
          }}
        >
          Download
          <i className='fa fa-angle-right' aria-hidden='true'></i>
        </button>

        <button
          className='menu-item'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            setAuditTrail(true);

            props.onDashboardOptionClose();

            props.onSidebarShowToggle();
          }}
        >
          Audit Trail
        </button>

        <button
          className='menu-item'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            props.onSetUpdate(true);

            props.onSetDashboardInput({
              dashboard_name: props.dashboard.name,
              ...(() => {
                return props.dashboard.config || {};
              })()
            });

            props.onSetShowDashboardForm(true);

            props.onDashboardOptionClose();

            props._onSetSidebarShow(false);
          }}
        >
          Settings
        </button>
      </div>

      <div
        className={`dropdown-menu sub-drop ${
          props.selectedId === props.dashboard.id &&
          props.showDashboardOption &&
          props.showDashboardSubOption &&
          'show'
        } `}
      >
        <div
          className='menu-item'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            context.confirm.show(
              'Please scroll down to the bottom of the page to download all the widget data as image.',
              () => {
                window.scrollTo(0, document.body.scrollHeight);

                setIsOpenModalDialog(true);

                setTimeout(() => {
                  oHtmlToCanvas.downloadPNGImage(
                    document.getElementById('dashboard-container')
                  );

                  setIsOpenModalDialog(false);
                }, 4000);
              }
            );

            props.onDashboardOptionClose();
          }}
        >
          <i className='fa fa-image'>
            <span className='sub-text'>Image</span>
          </i>
        </div>

        <div
          className='menu-item'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            context.confirm.show(
              'Please scroll down to the bottom of the page to download all the widget data as PDF.',
              () => {
                window.scrollTo(0, document.body.scrollHeight);

                setIsOpenModalDialog(true);

                setTimeout(() => {
                  oHtmlToCanvas.downloadPdf(
                    document.getElementById('dashboard-container')
                  );

                  setIsOpenModalDialog(false);
                }, 4000);
              }
            );

            props.onDashboardOptionClose();
          }}
        >
          <i className='fa fa-file'>
            <span className='sub-text'>pdf</span>
          </i>
        </div>
      </div>

      <ModalBox open={isOpenModalDialog} size='sm' className='modal-card'>
        <ModalBody>
          <div>Loading widgets....</div>
        </ModalBody>
      </ModalBox>

      {isWidgetSelectorOpen && allWidgetConfig && (
        <WidgetSelector
          allWidgetConfig={allWidgetConfig}
          isOpen={isWidgetSelectorOpen}
          openAddWidgetConfiguration={() => {
            setIsWidgetSelectorOpen(false);

            history.push(
              `/Dashboard/WidgetBuilder/${props.selectedId}/${allWidgetConfig[selectedWidgetIndex].id}`
            );
          }}
          setWigetSelected={setSelectedWidgetIndex}
          closeModal={() => {
            setIsWidgetSelectorOpen(false);
          }}
          selectedWidgetIndex={selectedWidgetIndex}
        />
      )}

      <DashboardConfigHistory
        showDetails={isOpenAuditTrail}
        onClose={() => setAuditTrail(false)}
        dashboardId={props.selectedId}
        dashboardConfigHistoryData={props.dashboardConfigHistoryData}
        getDashboardConfigHistory={props.getDashboardConfigHistory}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    allWidgetConfigExtended: state.allWidgetConfigExtended,
    dashboardConfigHistoryData: state.getDashboardConfigHistory
  };
};

export default connect(mapStateToProps, {
  getAllWidgetConfigExtended,
  getDashboardConfigHistory
})(DashboardOption);
