import * as AppConstants from './constant';

export function getAllReports(value) {
    return {
        type: AppConstants.GET_ALL_REPORTS,
        payload: value
    }
}

export function getAllReportLogs(value) {
    return {
        type: AppConstants.GET_ALL_REPORT_LOGS,
        payload: value
    }
}

export function checkAllReport(checkedFlag, value) {
    return {
        type: AppConstants.CHECK_ALL_REPORT,
        payload: { checkedFlag, value }
    }
}

export function checkReport(isChecked, data) {
    return {
        type: AppConstants.CHECK_REPORT,
        payload: { isChecked, data }
    }
}

export function checkReportLog(isChecked, data) {
    return {
        type: AppConstants.CHECK_REPORT_LOG,
        payload: { isChecked, data }
    }
}

export function deleteReport(value) {
    return {
        type: AppConstants.DELETE_REPORT,
        payload: value
    }
}

export function resetDeleteReport(value) {
    return {
        type: AppConstants.DELETE_REPORT_RESET,
        payload: value
    }
}


export function createReport(value) {
    return {
        type: AppConstants.CREATE_REPORT,
        payload: value
    }
}

export function resetCreateReport(value) {
    return {
        type: AppConstants.CREATE_REPORT_RESET,
        payload: value
    }
}

export function updateReport(value) {
    return {
        type: AppConstants.UPDATE_REPORT,
        payload: value
    }
}

export function resetUpdateReport(value) {
    return {
        type: AppConstants.UPDATE_REPORT_RESET,
        payload: value
    }
}

export function downloadReport(value) {
    return {
        type: AppConstants.DOWNLOAD_REPORT_BY_ID,
        payload: value
    }
}

// export function standardReportDownload(value) {
//     return {
//         type: AppConstants.DOWNLOAD_REPORT_CUSTOM,
//         payload: value
//     }
// }

export function resetDownloadReport() {
    return {
        type: AppConstants.DOWNLOAD_REPORT_BY_ID_RESET,
    }
}

// export function resetUpdateReportStandard() {
//     return {
//         type: AppConstants.DOWNLOAD_REPORT_CUSTOM_RESET,
//     }
// }

