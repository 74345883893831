import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import parameterConfsInputGet from '../common/parameterConfsInputGet';
import useSensorParameterCollection from '../common/useSensorParameterCollection';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import valueGet from '../common/valueGet';
import SensorParameterSelect from '../Common/SensorParameterSelect';
import { Button, CheckBox } from '../../../../../../../common/components';

const parameterConfsGet = (value) => {
  const parameterConfs = value && [{ locParam: value }];

  return parameterConfsInputGet(parameterConfs);
};

const _parameterConfsGet = (parameterConfs) => {
  const _parameterConfs = parameterConfsOutputGet(parameterConfs);

  return {
    locParam: valueGet(
      _parameterConfs &&
        (() => {
          const { locParam } = _parameterConfs[0];

          return locParam;
        })()
    )
  };
};

const ParameterConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [parameterConfs, setParameterConfs] = useState();

  const [showSummary, setShowSummary] = useState();

  const [
    sensorCollection,
    sensorOptions,
    parameterCollection,
    parameterOptionsCollection,
    onSensorChangeHandle,
    onParameterChangeHandle
  ] = useSensorParameterCollection(parameterConfs || []);

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setParameterConfs(
        parameterConfsGet(conf && conf.locParam && conf.locParam.value)
      );

      setShowSummary(
        (conf && conf.showSummary && conf.showSummary.value) || false
      );

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      ..._parameterConfsGet(parameterConfs),
      showSummary: valueGet(showSummary),
      ...object
    };

    props.appInputParamChanged(value);
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Location *</label>

              <SensorParameterSelect
                type='sensor'
                options={sensorOptions}
                value={sensorCollection[0] || ''}
                onChange={(sensor) => {
                  onSensorChangeHandle(sensor, 0);
                }}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Parameter*</label>

              <SensorParameterSelect
                type='parameter'
                options={parameterOptionsCollection[0] || []}
                value={parameterCollection[0] || ''}
                onChange={(parameter) => {
                  onParameterChangeHandle(parameter, 0, (parameterConf) => {
                    const parameterConfs = [parameterConf];

                    setParameterConfs(parameterConfs);

                    appInputParamChangedHandle(
                      _parameterConfsGet(parameterConfs)
                    );
                  });
                }}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className='new-custom-checkbox'>
              <CheckBox
                label={'Show Summary'}
                size='sm'
                checked={showSummary}
                checkboxClicked={(_showSummary) => {
                  setShowSummary(_showSummary);

                  appInputParamChangedHandle({
                    showSummary: valueGet(_showSummary)
                  });
                }}
              />
            </div>
          </Col>
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfig;
