export const GET_STATS_DATA = 'GET_STATS_DATA'
export const GET_STATS_DATA_SUCCESS = 'GET_STATS_DATA_SUCCESS'
export const GET_STATS_DATA_FAILURE = 'GET_STATS_DATA_FAILURE'
export const GET_ASSETS_DATA = 'GET_ASSETS_DATA'
export const GET_ASSETS_DATA_SUCCESS = 'GET_ASSETS_DATA_SUCCESS'
export const GET_ASSETS_DATA_FAILURE = 'GET_ASSETS_DATA_FAILURE'
export const GET_TIMELINE_DATA = 'GET_TIMELINE_DATA'
export const GET_TIMELINE_DATA_SUCCESS = 'GET_TIMELINE_DATA_SUCCESS'
export const GET_TIMELINE_DATA_FAILURE = 'GET_TIMELINE_DATA_FAILURE'
export const OLD_TIMELINE_DATA = 'OLD_TIMELINE_DATA'


export const GET_CBM_ASSETS = 'GET_CBM_ASSETS';
export const GET_CBM_ASSETS_SUCCESS = 'GET_CBM_ASSETS_SUCCESS';
export const GET_CBM_ASSETS_FAILURE = 'GET_CBM_ASSETS_FAILURE';

export const GET_ALL_ASSETS = 'GET_ALL_ASSETS';
export const GET_ALL_ASSETS_SUCCESS = 'GET_ALL_ASSETS_SUCCESS';
export const GET_ALL_ASSETS_FAILURE = 'GET_ALL_ASSETS_FAILURE';

export const GET_CBM_ASSETS_BY_ASSET_NAME = 'GET_CBM_ASSETS_BY_ASSET_NAME';
export const GET_CBM_ASSETS_BY_ASSET_NAME_SUCCESS = 'GET_CBM_ASSETS_BY_ASSET_NAME_SUCCESS';
export const GET_CBM_ASSETS_BY_ASSET_NAME_FAILURE = 'GET_CBM_ASSETS_BY_ASSET_NAME_FAILURE';

export const GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME = 'GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME';
export const GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME_SUCCESS = 'GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME_SUCCESS';
export const GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME_FAILURE = 'GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME_FAILURE';

export const GET_ASSET_OVERVIEW = 'GET_ASSET_OVERVIEW';
export const GET_ASSET_OVERVIEW_SUCCESS = 'GET_ASSET_OVERVIEW_SUCCESS';
export const GET_ASSET_OVERVIEW_FAILURE = 'GET_ASSET_OVERVIEW_FAILURE';


export const GET_ASSET_NAME_DETAILS = 'GET_ASSET_NAME_DETAILS';
export const GET_ASSET_NAME_DETAILS_SUCCESS = 'GET_ASSET_NAME_DETAILS_SUCCESS';
export const GET_ASSET_NAME_DETAILS_FAILURE = 'GET_ASSET_NAME_DETAILS_FAILURE';

export const GET_DEFAULT_TEMPLATE_OF_ASSET = 'GET_DEFAULT_TEMPLATE_OF_ASSET';
export const GET_DEFAULT_TEMPLATE_OF_ASSET_SUCCESS = 'GET_DEFAULT_TEMPLATE_OF_ASSET_SUCCESS';
export const GET_DEFAULT_TEMPLATE_OF_ASSET_FAILURE = 'GET_DEFAULT_TEMPLATE_OF_ASSET_FAILURE';

export const GET_ALL_ASSETS_FOR_DROPDOWN = 'GET_ALL_ASSETS_FOR_DROPDOWN';
export const GET_ALL_ASSETS_FOR_DROPDOWN_SUCCESS = 'GET_ALL_ASSETS_FOR_DROPDOWN_SUCCESS';
export const GET_ALL_ASSETS_FOR_DROPDOWN_FAILURE = 'GET_ALL_ASSETS_FOR_DROPDOWN_FAILURE';

export const GET_ASSET_INSIGHT_LIST = 'GET_ASSET_INSIGHT_LIST';
export const GET_ASSET_INSIGHT_LIST_SUCCESS = 'GET_ASSET_INSIGHT_LIST_SUCCESS';
export const GET_ASSET_INSIGHT_LIST_FAILURE = 'GET_ASSET_INSIGHT_LIST_FAILURE';

export const GET_INSIGHT_DETAILS = 'GET_INSIGHT_DETAILS';
export const GET_INSIGHT_DETAILS_SUCCESS = 'GET_INSIGHT_DETAILS_SUCCESS';
export const GET_INSIGHT_DETAILS_FAILURE = 'GET_INSIGHT_DETAILS_FAILURE';


export const GET_ALL_ACTIONS_FOR_ASSET = 'GET_ALL_ACTIONS_FOR_ASSET';
export const GET_ALL_ACTIONS_FOR_ASSET_SUCCESS = 'GET_ALL_ACTIONS_FOR_ASSET_SUCCESS';
export const GET_ALL_ACTIONS_FOR_ASSET_FAILURE = 'GET_ALL_ACTIONS_FOR_ASSET_FAILURE';

export const CREATE_MANUAL_INSIGHT = "CREATE_MANUAL_INSIGHT";
export const CREATE_MANUAL_INSIGHT_SUCCESS = 'CREATE_MANUAL_INSIGHT_SUCCESS';
export const CREATE_MANUAL_INSIGHT_FAILURE = 'CREATE_MANUAL_INSIGHT_FAILURE';