import * as AppConstants from './constant';

const INITIAL_STATE = {
    data: [],
    loading: false,
    error: false,
    success: undefined,
    message: null,
    total_pages: null,
    total_record: null,
}

export function getLoginLogoutDetails(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_LOGIN_LOGOUT_DETAILS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_LOGIN_LOGOUT_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.response.result,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_LOGIN_LOGOUT_DETAILS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }
        default:
            return state;
    }
}

export function changeUserPassword(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.CHANGE_PASSWORD:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true,
                message: action.response && action.response.response && action.response.response.message
            }

        case AppConstants.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
                message: action.payload && action.payload.response && action.payload.response.message
            }
        case AppConstants.CHANGE_PASSWORD_RESET:
            return {
                ...state,
                error: false,
                success: undefined,
                message: null,
                loading: false,
            }
        default:
            return state;
    }
}

export function editUserProfile(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.EDIT_USER_PROFILE:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.EDIT_USER_PROFILE_SUCCESS:
            return {
                ...state,
                data: [],
                message: action.response && action.response.response && action.response.response.success,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.EDIT_USER_PROFILE_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }
        case AppConstants.EDIT_USER_PROFILE_RESET:
            return {
                ...state,
                error: false,
                success: undefined,
                message: null,
                loading: false,
            }
        default:
            return state;
    }
}

export const userProfileReducer = {
    getLoginLogoutDetails,
    changeUserPassword,
    editUserProfile
}