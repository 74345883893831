import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) - 20,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) - 20,
        },
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

// should be use for only vertical left slider

export default function Slider(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    function handleDrawerToggle(e) {
        setOpen(!open);
    }

    return (
        <React.Fragment>
            <div className="commonslider">
                <Drawer
                    onMouseEnter={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    open={open}
                >
                    {/* <List disablePadding={true}>
                        <ListItem onClick={handleDrawerToggle} button key="menu">
                            <ListItemIcon>
                                <Icon className={open ? 'fa fa-times' : 'fas fa-bars'} fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Menu" />
                        </ListItem>
                    </List> */}
                    {props.getInnerComponant()}
                </Drawer>
            </div>
            <div className={open ? 'modalopen' : 'modalclose'} onClick={handleDrawerToggle} />
        </React.Fragment>
    );
}
