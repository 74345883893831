import formulaConfIdGet from '../common/formulaConfIdGet';

const equationGet = (formula) => {
  return formula.reduce((memo, _formula) => {
    const _equation = (() => {
      switch (true) {
        case _formula.type === 'parameter' &&
          !!_formula.parameter.readingtypeid:
          return `${_formula.parameter.locationLabel} _ ${_formula.parameter.readingtypeLabel}`;

        case _formula.type === 'operator' && !!_formula.value:
          return _formula.value;

        default:
          return '';
      }
    })();

    return `${memo} ${_equation}`.trim();
  }, '');
};

export default (formulaCollection, formulaConf, idResetFlag = false) => {
  return {
    ...formulaConf,
    formula: [
      {
        ...formulaConf.formula[0],
        ...(() => {
          return idResetFlag ? { id: formulaConfIdGet() } : {};
        })(),
        formula: formulaCollection
      }
    ],
    equation: equationGet(formulaCollection)
  };
};
