import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { httpMethodPost, httpMethodGetJSON } from '../https';
import { LIVEDATA_WIDGET_DATA } from './constant';
import { URL } from '../constants';

const READING_LIVE_URL = `${URL}/reading/reading_live/`;
const READING_COMPUTED_LIVE_URL = `${URL}/reading/reading_computed_live/`;
const READING_COMPUTED_SERIES_URL = `${URL}/reading/reading_computed/`;
const READING_COMPUTED_DATA_URL = `${URL}/reading/reading_computed_data/`;
const READING_SERIES_URL = `${URL}/reading/reading_series/`;
const READING_DATA_URL = `${URL}/reading/reading_data/`;

export const getLiveData = (action$) =>
  action$.pipe(
    ofType(LIVEDATA_WIDGET_DATA),
    mergeMap((action) => {
      switch (true) {
        case !!action.payload.isLive && !!action.payload.isComputed:
          return httpMethodPost(action, READING_COMPUTED_LIVE_URL);

        case !!action.payload.isLive:
          return httpMethodGetJSON(action, READING_LIVE_URL);

        case !!action.payload.isComputed && !!action.payload.isSeries:
          return httpMethodPost(action, READING_COMPUTED_SERIES_URL);

        case !!action.payload.isComputed:
          return httpMethodPost(action, READING_COMPUTED_DATA_URL);

        case !!action.payload.isSeries:
          return httpMethodGetJSON(action, READING_SERIES_URL);

        default:
          return httpMethodGetJSON(action, READING_DATA_URL);
      }
    })
  );
