import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

import './index.scss';

const ReactSelectAsyncPaginate = (props) => {
  return (
    <div className='ReactSelectAsyncPaginate'>
      <AsyncPaginate
        isMulti={props.isMulti}
        placeholder={props.placeholder}
        classNamePrefix='selectionbox_prefix'
        styles={{
          control: (provided) => {
            return {
              ...provided,
            };
          },
          input: (provided) => ({
            ...provided,
            height: 20,
            display: 'flex',
            alignItems: 'center'
          })
        }}
        menuPlacement={props.menuPlacement || 'auto'}
        maxMenuHeight={props.maxMenuHeight}
        value={props.value}
        loadOptions={props.loadOptions}
        onChange={props.onChange}
        isOptionDisabled={props.isOptionDisabled}
        cacheUniqs={props.cacheUniqs}
      />
    </div>
  );
};

export default ReactSelectAsyncPaginate;
