import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import EnhancedVisibility from '../../../../../../assets/images/Upgrade/EnhancedVisibility.svg';
import ProactiveAnomaly from '../../../../../../assets/images/Upgrade/ProactiveAnomaly.svg';
import ComprehensiveESG from '../../../../../../assets/images/Upgrade/ComprehensiveEsg.svg';
import BDD from '../../../../../../assets/images/Upgrade/JosephMcauliffe.png';
import GearIcon from '../../../../../../assets/images/Upgrade/UpgradeGear.svg';
import UpgradeButton from './UpgradeButton';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Context } from '../../../Main/context';
import { discoveryUpgradeEmail } from '../../action';
import { connect } from 'react-redux';
import { ModalBox } from '../../../../../common/components';
import './index.scss';

const memberDataGet = () => {
  return (
    localStorage.getItem('smartsense.member') &&
    JSON.parse(localStorage.getItem('smartsense.member'))
  );
};

const Upgrade = (props) => {
  const context = useContext(Context);
  let flagValue = memberDataGet().clientid.discovery_upgrade_email_sent_status
  const [upgradeEmailFlag,setUpgradeEmailFlag] =  useState(flagValue)

  const features = [
    {
      heading: 'Enhanced Visibility through Digital Twin Technology',
      subheading:
        'Digitalise your portfolio to gain instant visibility into the performance, efficiency, sustainability of your facilities and assets.',
      image: EnhancedVisibility
    },
    {
      heading: 'Proactive Anomaly Detection and Actionable Insights',
      subheading:
        'Uncover ways to save on carbon emissions, save costs, simplify maintenance, improve efficiency and performance with automated AI/ML suggestions.',
      image: ProactiveAnomaly
    },
    {
      heading: 'Comprehensive ESG and Maintenance Reporting',
      subheading:
        'Generate maintenance reports and work orders for optimal facility asset performance. Export environmental data for ESG reporting.',
      image: ComprehensiveESG
    }
  ];

  useEffect(() => {
    const { success, data, error } = props.upgrade;
    if (success) {
      let memberData = memberDataGet();
      memberData.clientid.discovery_upgrade_email_sent_status = true;
      let updatedMemberData = JSON.stringify(memberData);
      localStorage.setItem('smartsense.member', updatedMemberData);
      setUpgradeEmailFlag(true)
      context.notify.success(data.response);
    } else if (error) {
      context.notify.error(`Failed to upgrade email!!\n${data.response}`);
    }
  }, [props.upgrade]);


  const onUpgradeClickCallback = useCallback(() => {
    props.discoveryUpgradeEmail({});
  }, [props.discoveryUpgradeEmail]);

  return (
    <ModalBox
      className='upgrade-modal'
      open={props.isOpen}
      size='sm'
      onClose={props.onClose}
    >
      <ModalHeader style={{ padding: '0px'}} toggle={props.onClose}>
      <Box style={{display:'flex', flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start'}}> 
        <Typography
          style={{
            fontFamily: 'montserrat',
            fontSize: '24px',
            textAlign: 'left'
          }}
        >
          SmartSense Insights
        </Typography>
        <Typography style={{fontSize:'12px',lineHeight:'16px'}} >
          TM
        </Typography>
        </Box>
      </ModalHeader>

      <ModalBody
        style={{
          padding: '0px'
        }}
      >
        <Box sx={{ paddingY: '5px' }}>
          <p
            style={{
              fontFamily: 'montserrat',
              fontSize: '16px',
              lineHeight: '24px',
              textAlign: 'left'
            }}
          >
            Unlock advanced features to drive operational efficiency and reach net-zero targets.
          </p>
        </Box>

        <Box>
          {features.map((item, index) => {
            return (
              <Grid
                key={index}
                container
                spacing={2}
                style={{ marginBlock: '10px' }}
              >
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: '0px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'flex-start'
                  }}
                >
                  <img
                    style={{ marginTop: '10px' }}
                    alt=''
                    width='40px'
                    height='40px'
                    src={item.image}
                  />
                </Grid>

                <Grid item xs={11}>
                  <p
                    style={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      marginBlock: '5px',
                      textAlign: 'left'
                    }}
                  >
                    {item.heading}
                  </p>

                  <p
                    style={{
                      fontSize: '12px',
                      lineHeight: '18px',
                      marginBlock: '5px',
                      textAlign: 'left'
                    }}
                  >
                    {item.subheading}
                  </p>
                </Grid>
              </Grid>
            );
          })}
        </Box>

        <Box style={{ paddingBottom: '24px' }}>
          <Typography
            style={{
              textAlign: 'left',
              fontFamily: 'montserrat',
              fontSize: '16px',
              lineHeight: '24px'
            }}
          >
            Reach out to our business development team today and upgrade with confidence:
          </Typography>
        </Box>
      </ModalBody>

      <ModalFooter style={{ padding: '0px' }}>
        <Box style={{ marginBlock: '10px', width: '100%' }}>
          <Grid container>
            <Grid item xs={upgradeEmailFlag?6:9}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}
              >
                <img alt='' width='48px' height='48px' src={BDD} />
                <div style={{ marginLeft: '10px' }}>
                  <p
                    style={{
                      fontSize: '16px',
                      lineHeight: '20px',
                      textAlign: 'left'
                    }}
                  >
                    Joseph Mcauliffe
                  </p>
                  <p
                    style={{
                      fontSize: '12px',
                      lineHeight: '18px',
                      textAlign: 'left'
                    }}
                  >
                    Business Development Director
                  </p>
                </div>
              </Box>
            </Grid>
            <UpgradeButton upgradeEmailFlag={upgradeEmailFlag} onUpgradeClickCallback={onUpgradeClickCallback} upgrade={props.upgrade} />
          </Grid>
        </Box>
      </ModalFooter>
    </ModalBox>
  );
};

const mapStateToProps = (state) => {
  return {
    upgrade: state.discoveryUpgradeEmail
  };
};

export default connect(mapStateToProps, {
  discoveryUpgradeEmail
})(Upgrade);
