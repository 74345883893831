export const GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA =
  'GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA';
export const GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA_SUCCESS =
  'GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA_SUCCESS';
export const GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA_FAILURE =
  'GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA_FAILURE';

export const GET_ASSET_DETAIL_LIST_ASSETS_DATA =
  'GET_ASSET_DETAIL_LIST_ASSETS_DATA';
export const GET_ASSET_DETAIL_LIST_ASSETS_DATA_SUCCESS =
  'GET_ASSET_DETAIL_LIST_ASSETS_DATA_SUCCESS';
export const GET_ASSET_DETAIL_LIST_ASSETS_DATA_FAILURE =
  'GET_ASSET_DETAIL_LIST_ASSETS_DATA_FAILURE';

export const GET_ASSET_CBM_ASSET_NAMES_MEMBER =
  'GET_ASSET_CBM_ASSET_NAMES_MEMBER';
export const GET_ASSET_CBM_ASSET_NAMES_MEMBER_SUCCESS =
  'GET_ASSET_CBM_ASSET_NAMES_MEMBER_SUCCESS';
export const GET_ASSET_CBM_ASSET_NAMES_MEMBER_FAILURE =
  'GET_ASSET_CBM_ASSET_NAMES_MEMBER_FAILURE';

export const GET_SYSTEM_DETAIL_LIST = 'GET_SYSTEM_DETAIL_LIST';
export const GET_SYSTEM_DETAIL_LIST_SUCCESS = 'GET_SYSTEM_DETAIL_LIST_SUCCESS';
export const GET_SYSTEM_DETAIL_LIST_FAILURE = 'GET_SYSTEM_DETAIL_LIST_FAILURE';
