import React from 'react';
import copyrightImgDark from '../../../../assets/images/copyright-img-dark.svg';
import copyrightImgLight from '../../../../assets/images/copyright-img.svg';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    const d = new Date();
    this.currentYear = d.getFullYear();
  }  
  render () {
    return (
      <div className="footer">
        {/* <img src={copyrightImgDark} /> */}
        <img src={copyrightImgDark} className='dark-theme-footer-img' />
        <img src={copyrightImgLight} className='light-theme-footer-img'/>
        <p>
        {this.currentYear}©Ecolibrium Inc.
        </p>
      </div>
    )
  }
}
