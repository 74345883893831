import {
    getLiveConsumptionDataReducer,
    getEnergyForecastReducer,
    getTotalConsumptionDataReducer,
    getDateRangeEnergyConsumptionDataReducer,
    getDateRangeEnergyForeCastDataReducer,
    getRangeEnergyConsumptionTimeSeriesReducer,
    getDateRangeEnergyConsumptionDiffDataReducer,
    getDateRangeEnergyForeCastDiffDataReducer
} from './Energyconsumption/reducer';
import {
    getAverageDemandReducer,
    getDemandDateRangeReducer,
    getDemandAnalysisChartReducer,
    getDemandDateRangeDifferenceReducer,
    getDemandDateRangeForeCastReducer,
    getDemandDateRangeForecastDifferenceReducer
} from './DemandAnalysis/reducer';
import {
    getDayDemandAnalysisReducer,
    getDayDemandDifferenceAnalysisReducer
} from './DaysAnalysis/reducer';
import {
    getShiftAnalysisDataReducer,
    getShiftAnalysisSeriesReducer,
    getShiftAnalysisDateRangeReducer,
    getShiftAnalysisDifferenceReducer,
    getShiftAnalysisDataDifferenceReducer
} from './ShiftAnalysis/reducer';
import {
    getAlertTypeSummary
} from './AlertTypeSummary/reducer';

import { getAlertGenSummary } from "./AlertGenerationSummary/reducer";
import { getAlertConfigCount } from './AlertConfigurationCount/reducer';
import { getAlertTrend } from './AlertTrend/reducer';
import { getAlertGenOverview } from './AlertGenerationOverview/reducer';
import { getAlertStatusData } from './AlertStatus/reducer';
import { getAlertMonitoringData, getAlertForMonitoring } from './AlertMonitoring/reducer';
import { getEventActivitySummary } from './EventActivitySummary/reducer';
import { getSensorEventOverview, getConfigurationEvent } from './SensorEventOverview/reducer';

export const widgetEpics = {
    liveEnergyConsumption: getLiveConsumptionDataReducer,
    energyForecastData: getEnergyForecastReducer,
    totalEnergyConsumption: getTotalConsumptionDataReducer,
    dateRangeEnergyConsumption: getDateRangeEnergyConsumptionDataReducer,
    dateRangeEnergyForeCast: getDateRangeEnergyForeCastDataReducer,
    dateRangeEnergyConsumptionDifference: getDateRangeEnergyConsumptionDiffDataReducer,
    dateRangeEnergyForeCastDifference: getDateRangeEnergyForeCastDiffDataReducer,
    energyTimeSeries: getRangeEnergyConsumptionTimeSeriesReducer,
    averageDemandAnalysis: getAverageDemandReducer,
    demandDateRangeAnalysis: getDemandDateRangeReducer,
    demandDateRangedDifferenceAnalysis: getDemandDateRangeDifferenceReducer,
    demandDateRangeForeCast: getDemandDateRangeForeCastReducer,
    demandDateRangeForeCastDiff: getDemandDateRangeForecastDifferenceReducer,
    demandAnalysisChart: getDemandAnalysisChartReducer,
    dayDemandAnalysis: getDayDemandAnalysisReducer,
    dayDiffDemandAnalysis: getDayDemandDifferenceAnalysisReducer,
    shiftAnalysis: getShiftAnalysisDataReducer,
    shiftSeriesAnalysis: getShiftAnalysisSeriesReducer,
    shiftDateRangeAnalysis: getShiftAnalysisDateRangeReducer,
    shiftDateRangeDiffAnalysis: getShiftAnalysisDifferenceReducer,
    shiftDiffAnalysis: getShiftAnalysisDataDifferenceReducer,
    alertTypeSummary: getAlertTypeSummary,
    alertGenSummary: getAlertGenSummary,
    alertConfigCount: getAlertConfigCount,
    alertTrend: getAlertTrend,
    alertGenOverview: getAlertGenOverview,
    alertStatusData: getAlertStatusData,
    alertMonitoringData: getAlertMonitoringData,
    monitoringAllAlerts: getAlertForMonitoring,
    eventActivitySummary: getEventActivitySummary,
    sensorEventOverview: getSensorEventOverview,
    eventConfiguration: getConfigurationEvent,
};
