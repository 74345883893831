import React from 'react';
import clsx from 'clsx';
import { Row, Col } from 'reactstrap';
import { DropDown, ErrorBoundary, InviewWrapper } from '../../../../../common/components';
import DateTime from '../../../../../common/utils/dateTimeUtils'
import PropTypes from 'prop-types';
import { activeUserPermissions } from '../../../Main/appSetting';
import './widget.scss';
import { red } from '@material-ui/core/colors';
import _Dropdown from './_Dropdown';
import moment from 'moment';

import infoIconLight from '../../../../../../assets/images/info-iconLight.svg';
import infoIconDark from '../../../../../../assets/images/info-iconDark.svg';


export default class WidgetLayout extends React.Component {
  static defaultProps = {
    widgetId: null,
    name: "No Title",
    params: {},
    width: "100%",
    description: "No description available",
    refreshTime: '',
    widgetActions: [],
    editWidget: function () { },
    deleteWidget: function () { },
    cloneWidget: function () { },
    openTvmode: function () { },
    widgetAction: function () { },
    refreshWidget: function () { },
    downloadWidget: function () { },
  }

  static propTypes = {
    widgetId: PropTypes.string,
    name: PropTypes.string,
    params: PropTypes.object,
    width: PropTypes.string,
    description: PropTypes.string,
    refreshTime: PropTypes.string,
    editWidget: PropTypes.func,
    deleteWidget: PropTypes.func,
    cloneWidget: PropTypes.func,
    openTvmode: PropTypes.func,
    widgetAction: PropTypes.func,
    refreshWidget: PropTypes.func,
    downloadWidget: PropTypes.func,
    widgetActions: PropTypes.array,
  }

  constructor(props) {
    super(props);

    this.state = {};
    this.widgetAction = this.widgetAction.bind(this);
  }

  widgetAction(action) {
    if (action.key == "refresh") {
      return this.props.refreshWidget();
    } else if (action.key == "information"){
       
    }
    else {
      if (activeUserPermissions(action.permission)) {
        switch (action.key) {
          case 'edit':
            this.props.editWidget()
            break;
          case 'delete':
            this.props.deleteWidget()
            break;
          case 'clone':
            this.props.cloneWidget()
            break;
          case 'tvmode':
            this.props.openTvmode()
            break;
          case 'download':
            this.props.downloadWidget()
            break;
          default:
            this.props.widgetAction(action)
            break;
        }
      } else this.props.permissionDenied()

    }

  }

  // getBorder() {
  //   let widgetParams = this.props.params;
  //   if (widgetParams && widgetParams.params) {
  //     let sensorDetails = widgetParams.params[1];
  //     if (sensorDetails && sensorDetails.length > 0) {
  //       let isSensorType = sensorDetails.some(item => (item.sensortypeid == "1001"))
  //       if (isSensorType) {
  //         return "solid 1px orange";
  //       }
  //       let isNoSensorType = sensorDetails.some(item => (item.sensortypeid == "" || item.sensortypeid == null || item.sensortypeid == undefined))
  //       if (isNoSensorType) {
  //         return "solid 1px red";
  //       }
  //     }
  //   }
  // }

  render() {
    return (
      <React.Fragment>
        {this.props.newWidget ? (
          <div
            className={
              this.props.hideGradient
                ? 'widgetContent'
                : 'widgetContent widgetContentGradient'
            }
          >
            <InviewWrapper>
              <ErrorBoundary>{this.props.children}</ErrorBoundary>
              <div className='widget-edit-layout'>
                {this.props.widgetActions.length > 0 && this.props.children ? (
                  <span
                    className={`widget-arrow-dropdown widget-dropdown-0 ${
                      this.props.widgetType === 'Section' ? 'section' : ''
                    }`}
                  >
                    <_Dropdown
                      icon='fas fa-ellipsis-h i-align'
                      widgetActions={this.props.widgetActions}
                      widgetSubActions={this.props.widgetSubActions}
                      information={this.props.information}
                      onWidgetAction={(widgetAction) => {
                        return this.widgetAction(widgetAction);
                      }}
                    />
                  </span>
                ) : null}
              </div>
            </InviewWrapper>
          </div>
        ) : (
          <div
            className='dashoard-widget'
            style={{ width: this.props.width }}
            widget-id={this.props.widgetId}
          >
            <div className={`widgetHeader ${this.props.customBgClass}`}>
              <div className={`widget-label ${this.props.custom_widgetName === 'GIS Plant' ? 'widgetCustomFontClass ' : ''}`}>{this.props.name}</div>
              {/* <div className='float-right'> */}
              <div className='widgetArrow'>
                {(() => {
                  const commonContent = [
                    <img key="light" className="icon-img light-img" src={infoIconLight} alt="smartsense" />,
                    <img key="dark" className="icon-img dark-img" src={infoIconDark} alt="smartsense" />
                  ];

                  const tooltipContent =
                    this.props.description === 'No description available'
                      ? `Last Updated: ${moment(this.props.refreshTime).format('MMM DD, YYYY HH:mm')}`
                      : `${this.props.description}\nLast Updated: ${moment(this.props.refreshTime).format('MMM DD, YYYY HH:mm')}`;

                  return (
                    <span className='widget-arrow-description' data-title={tooltipContent}>
                      {commonContent}
                    </span>
                  );
                })()}
                {/* title={`${this.props.description}\nLast Updated: ${DateTime.format(this.props.refreshTime, 'MMMM Do YYYY, h:mm:ss a')}`} */}
                {/* <span className='widget-arrow-description' data-title={`${this.props.description}\nLast Updated: ${moment(this.props.refreshTime).format('MMM DD, YYYY HH:mm')}`}> */}
                  {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                  {/* <img src={infoIcon} /> */}
                  {/* <img className="icon-img light-img" src={infoIconLight} alt="smartsense" />
                  <img className="icon-img dark-img" src={infoIconDark} alt="smartsense" />
                </span> */}
                {/* <span className='widget-refresh'>
                  <i className="fas fa-sync-alt" aria-hidden="true"></i>
                </span> */}
                {this.props.widgetActions.length > 0 ? (
                  <span className='widget-arrow-dropdown'>
                    <_Dropdown
                      widgetActions={this.props.widgetActions}
                      widgetSubActions={this.props.widgetSubActions}
                      information={this.props.information}
                      onWidgetAction={(widgetAction) => {
                        return this.widgetAction(widgetAction);
                      }}
                    />
                  </span>
                ) : null}
              </div>
              {/* </div> */}
            </div>
            <div
              className={`widgetContent ${this.props.customBgClass} ${
                this.props.isMiniGraph ? 'isMiniGraph' : ''
              }`}
            >
              <InviewWrapper>
                <ErrorBoundary>{this.props.children}</ErrorBoundary>
              </InviewWrapper>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}