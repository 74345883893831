import React, { useState, useRef } from 'react';
import Popup from 'reactjs-popup';

import moreIcon from '../../../../../../assets/images/more-icon.svg';

const ClickOutHandler = require('react-onclickout');
const _Dropdown = (props) => {
  const [menuLevel0Show, setMenuLevel0Show] = useState(false);
  const [menuLevel1Show, setMenuLevel1Show] = useState(false);

  const informationRef = useRef();
  return (
    <ClickOutHandler onClickOut={()=>{
      // this.selectorRef.current.mouseLeave();
      if(menuLevel0Show)return setMenuLevel0Show(false)
    }}>
    <div className='_Dropdown dropdown'>
      <a
        href={null}
        className='trigger level-0'
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          setMenuLevel0Show(!menuLevel0Show);
        }}
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        {/* <i className={`${props.icon || 'fas fa-ellipsis-v'}`}></i> */}
        <img src={moreIcon} />
      </a>
       
      <div
        className={`dropdown-menu level-0 ${menuLevel0Show ? '_show' : ''}`}
        onMouseLeave={() => {
          return setMenuLevel0Show(false);
        }}
      >
        {props.widgetActions.map((widgetAction) => {
          switch (widgetAction.key) {
            case 'action':
              return (
                <div
                  className='dropdown-submenu'
                  onMouseLeave={() => {
                    return setMenuLevel1Show(false);
                  }}
                >
                  <a
                    href={null}
                    className='dropdown-item'
                    onMouseEnter={() => {
                      return setMenuLevel1Show(true);
                    }}
                  >
                    <div className='trigger level-1'>
                      <div>{widgetAction.value}</div>
                      <div>
                        <i className='fa fa-caret-left'></i>
                      </div>
                    </div>
                  </a>

                  <div
                    className={`dropdown-menu level-1 ${
                      menuLevel1Show ? '_show' : ''
                    }`}
                  >
                    {props.widgetSubActions &&
                      props.widgetSubActions.map((widgetSubAction, index) => {
                        return (
                          <a
                            key={index}
                            href={null}
                            className='dropdown-item'
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();

                              setMenuLevel1Show(false);

                              setMenuLevel0Show(false);

                              props.onWidgetAction(widgetSubAction);
                            }}
                            onMouseDown={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                          >
                            {widgetSubAction.value}
                          </a>
                        );
                      })}
                  </div>
                </div>
              );

            case 'information':
              return (
                <Popup
                  ref={informationRef}
                  className='information'
                  trigger={() => (
                    <a
                      href={null}
                      className='dropdown-item'
                      onMouseEnter={() => {
                        informationRef.current.open();
                      }}
                      onMouseLeave={() => {
                        informationRef.current.close();
                      }}
                    >
                      {widgetAction.value}
                    </a>
                  )}
                  position='left center'
                  closeOnDocumentClick
                >
                  <span>{props.information || 'No description'}</span>
                </Popup>
              );

            default:
              return (
                <a
                  href={null}
                  className='dropdown-item'
                  onClick={() => {
                    setMenuLevel0Show(false);

                    props.onWidgetAction(widgetAction);
                  }}
                >
                  {widgetAction.value}
                </a>
              );
          }
        })}
      </div>
    </div>
    </ClickOutHandler>
  );
};

export default _Dropdown;