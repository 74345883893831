export const DU_GENERATE_TEMPLATE = 'DU_GENERATE_TEMPLATE';
export const DU_GENERATE_TEMPLATE_SUCCESS = 'DU_GENERATE_TEMPLATE_SUCCESS';
export const DU_GENERATE_TEMPLATE_FAILURE = 'DU_GENERATE_TEMPLATE_FAILURE';

export const DU_SAVE_TEMPLATE = 'DU_SAVE_TEMPLATE';
export const DU_SAVE_TEMPLATE_SUCCESS = 'DU_SAVE_TEMPLATE_SUCCESS';
export const DU_SAVE_TEMPLATE_FAILURE = 'DU_SAVE_TEMPLATE_FAILURE';
export const DU_SAVE_TEMPLATE_RESET = "DU_SAVE_TEMPLATE_RESET";

export const DU_UPDATE_TEMPLATE = 'DU_UPDATE_TEMPLATE';
export const DU_UPDATE_TEMPLATE_SUCCESS = 'DU_UPDATE_TEMPLATE_SUCCESS';
export const DU_UPDATE_TEMPLATE_FAILURE = 'DU_UPDATE_TEMPLATE_FAILURE';
export const DU_UPDATE_TEMPLATE_RESET = 'DU_UPDATE_TEMPLATE_RESET';

export const DU_GET_ALL_TEMPLATE = 'DU_GET_ALL_TEMPLATE';
export const DU_GET_ALL_TEMPLATE_SUCCESS = 'DU_GET_ALL_TEMPLATE_SUCCESS';
export const DU_GET_ALL_TEMPLATE_FAILURE = 'DU_GET_ALL_TEMPLATE_FAILURE';

export const DU_GET_TEMPLATE_HISTORY = 'DU_GET_TEMPLATE_HISTORY';
export const DU_GET_TEMPLATE_HISTORY_SUCCESS = 'DU_GET_TEMPLATE_HISTORY_SUCCESS';
export const DU_GET_TEMPLATE_HISTORY_FAILURE = 'DU_GET_TEMPLATE_HISTORY_FAILURE';

export const CREATE_UPLOAD_RESET_GLOBAL_ACTION = 'CREATE_UPLOAD_RESET_GLOBAL_ACTION';
export const CREATE_UPLOAD_GLOBAL_ACTION = 'CREATE_UPLOAD_GLOBAL_ACTION';

export const UPLOAD_DATA_FILE = 'UPLOAD_DATA_FILE';
export const UPLOAD_DATA_FILE_SUCCESS = 'UPLOAD_DATA_FILE_SUCCESS';
export const UPLOAD_DATA_FILE_FAILURE = 'UPLOAD_DATA_FILE_FAILURE';
export const UPLOAD_DATA_FILE_RESET = 'UPLOAD_DATA_FILE_RESET';

export const DU_GET_ALL_UPLOADED_DATA_FILE = 'DU_GET_ALL_UPLOADED_DATA_FILE';
export const DU_GET_ALL_UPLOADED_DATA_FILE_SUCCESS = 'DU_GET_ALL_UPLOADED_DATA_FILE_SUCCESS';
export const DU_GET_ALL_UPLOADED_DATA_FILE_FAILURE = 'DU_GET_ALL_UPLOADED_DATA_FILE_FAILURE';

export const CHECK_ALL_TEMPLATES = 'CHECK_ALL_TEMPLATES';
export const CHECK_ALL_TEMPLATES_SUCCESS = 'CHECK_ALL_TEMPLATES_SUCCESS';
export const CHECK_ALL_TEMPLATES_FAILURE = 'CHECK_ALL_TEMPLATES_FAILURE';