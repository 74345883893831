import React, { useContext } from 'react';
import { DashboardContainer } from './components';
import { useLocation } from 'react-router-dom'

import { Context } from '../Main/context';
import dashboardFilterConfigInputGet from './components/WidgetBuilder/ConfigComponents/common/dashboardFilterConfigInputGet';

const MemoizedDashboard = (props) => {
  const context = useContext(Context);
  const location = useLocation();
  let currentRouteDashboard = location.pathname === "/Dashboard/";
  const memberDetails = localStorage.getItem('smartsense.member') && JSON.parse(localStorage.getItem('smartsense.member'));
  let dashboardAlignmentFlag = memberDetails.dashboard_align === "vertical";
  let toCheckDashboardAlign = dashboardAlignmentFlag === false ? `dashboard-container` : `dashboard-container`

  return (
    <div
      className={currentRouteDashboard === true ? `dashboard-container` : toCheckDashboardAlign}
    >
      <DashboardContainer
        {...props}
        dashboardFilterConfig={
          props.dashboardFilterConfig || dashboardFilterConfigInputGet()
        }
      />
    </div>
  );
};

export default React.memo(MemoizedDashboard);
