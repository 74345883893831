import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../../constants';
import { httpMethodGetJSON } from '../../../../https';
import * as AppConstants from './constant';

export const getNetworkInsights = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_NETWORK_INSIGHTS),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/network-insights/`);
    })
  );
};

export const getSystemInsights = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_SYSTEM_INSIGHTS),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/system-cbm/insight/`);
    })
  );
};

export const getSystemAssetInsights = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_SYSTEM_ASSET_INSIGHTS),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/system-cbm/system/assets/insights/${action.payload.system_id}/`
      );
    })
  );
};

export const getSystemAlerts = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_SYSTEM_ALERTS),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/system-cbm/system/assets/alerts/${action.payload.system_id}/`
      );
    })
  );
};
