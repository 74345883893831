import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import parameterConfsInputGet from '../common/parameterConfsInputGet';
import formulaConfsInputGet from '../common/formulaConfsInputGet';
import parameterConfInputGet from '../common/parameterConfInputGet';
import collectionGet from '../common/collectionGet';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import formulaConfsOutputGet from '../common/formulaConfsOutputGet';
import {
  Select,
  Button,
  RadioButtons
} from '../../../../../../../common/components';
import ParameterConfigMixed from './ParameterConfigMixed';

const dataTypeCollection = ['Value Vs Trend', "KPI's"];

const sectionCollection = ['Indicators', 'Correlation'];

const indicatorDataTypeCollection = ['Live', 'Aggregated'];

const graphTypeCollection = ['Bar Graph', 'Line Graph', 'Area Graph'];

const parameterConfsGet = (parameters, dataType, section) => {
  const parameterConfs = parameters.reduce((memo, parameter, index) => {
    const value =
      !parameter.isComputed &&
      (() => {
        const {
          alias,
          graphType,
          threshold,
          unit,
          location: { label: locationLabel, value: locationValue } = {},
          readingtype: { label: readingtypeLabel, value: readingtypeValue } = {}
        } = (() => {
          switch (dataType) {
            case dataTypeCollection[0]:
              return parameter;

            case dataTypeCollection[1]:
              return (() => {
                switch (section) {
                  case sectionCollection[0]:
                    return {
                      alias: parameter.alias,
                      threshold: parameter.threshold,
                      unit: parameter.unit,
                      location: parameter.location,
                      readingtype: parameter.parameter
                    };

                  case sectionCollection[1]:
                    return (() => {
                      const { alias, locParam } = parameter;

                      return {
                        alias,
                        ...locParam
                      };
                    })();
                }
              })();
          }
        })();

        return {
          index,
          alias,
          graphType,
          threshold,
          unit,
          locParam: {
            location: {
              label: locationLabel,
              value: locationValue
            },
            readingtype: {
              label: readingtypeLabel,
              value: readingtypeValue
            }
          }
        };
      })();

    return value ? [...(memo || []), value] : memo;
  }, null);

  return (
    parameterConfs &&
    parameterConfsInputGet(parameterConfs).reduce((memo, parameterConf) => {
      return [
        ...memo,
        {
          ...parameterConf,
          isComputed: false
        }
      ];
    }, [])
  );
};

const formulaConfsGet = (parameters) => {
  const formulaConfs = parameters.reduce((memo, parameter, index) => {
    const value =
      parameter.isComputed &&
      (() => {
        const {
          alias,
          graphType,
          threshold,
          unit,
          computedFormula: { equation, formula, is_partial }
        } = parameter;

        return {
          index,
          alias,
          graphType,
          threshold,
          unit,
          formula,
          equation,
          is_partial
        };
      })();

    return value ? [...(memo || []), value] : memo;
  }, null);

  return (
    formulaConfs &&
    formulaConfsInputGet(formulaConfs).reduce((memo, formulaConf) => {
      return [
        ...memo,
        {
          ...formulaConf,
          isComputed: true
        }
      ];
    }, [])
  );
};

const _locationBasedParamSelectorGet = (_locationBasedParamSelector) => {
  switch (_locationBasedParamSelector.dataType.value) {
    case dataTypeCollection[0]:
      return {
        ..._locationBasedParamSelector,
        value: {
          ..._locationBasedParamSelector.value,
          trend: [
            ...(parameterConfsGet(
              [
                {
                  alias: _locationBasedParamSelector.value.alias,
                  graphType: _locationBasedParamSelector.value.graphType,
                  threshold: _locationBasedParamSelector.value.threshold,
                  ..._locationBasedParamSelector.value.trend
                }
              ],
              _locationBasedParamSelector.dataType.value
            ) || []),
            ...(formulaConfsGet([
              {
                graphType: _locationBasedParamSelector.value.graphType,
                threshold: _locationBasedParamSelector.value.threshold,
                ..._locationBasedParamSelector.value.trend
              }
            ]) || [])
          ]
        }
      };

    case dataTypeCollection[1]:
      return (() => {
        switch (_locationBasedParamSelector.section.value) {
          case sectionCollection[0]:
            return {
              ..._locationBasedParamSelector,
              value: {
                ..._locationBasedParamSelector.value,
                params: {
                  ..._locationBasedParamSelector.value.params,
                  parameters: [
                    ...(parameterConfsGet(
                      _locationBasedParamSelector.value.params.parameters,
                      _locationBasedParamSelector.dataType.value,
                      _locationBasedParamSelector.section.value
                    ) || []),
                    ...(formulaConfsGet(
                      _locationBasedParamSelector.value.params.parameters
                    ) || [])
                  ]
                }
              }
            };

          case sectionCollection[1]:
            return {
              ..._locationBasedParamSelector,
              value: [
                ...(parameterConfsGet(
                  _locationBasedParamSelector.value,
                  _locationBasedParamSelector.dataType.value,
                  _locationBasedParamSelector.section.value
                ) || []),
                ...(formulaConfsGet(_locationBasedParamSelector.value) || [])
              ]
            };
        }
      })();
  }
};

const locationBasedParamSelectorGet = (locationBasedParamSelector) => {
  return locationBasedParamSelector.reduce(
    (memo, _locationBasedParamSelector) => {
      const value = _locationBasedParamSelectorGet(_locationBasedParamSelector);

      return [...memo, value];
    },
    []
  );
};

const _parameterConfsOutputGet = (parameterConfs, dataType, section) => {
  return parameterConfs.reduce((memo, parameterConf) => {
    const value =
      !parameterConf.isComputed &&
      (() => {
        const parameter = parameterConfsOutputGet([parameterConf]);

        return (
          parameter &&
          (() => {
            switch (dataType) {
              case dataTypeCollection[0]:
                return parameter[0].locParam;

              case dataTypeCollection[1]:
                return (() => {
                  switch (section) {
                    case sectionCollection[0]:
                      return (() => {
                        const {
                          isComputed,
                          alias,
                          threshold,
                          unit,
                          locParam: { location, readingtype }
                        } = parameter[0];

                        return {
                          isComputed,
                          alias,
                          threshold,
                          unit,
                          location,
                          parameter: readingtype
                        };
                      })();

                    case sectionCollection[1]:
                      return (() => {
                        const { isComputed, alias, locParam } = parameter[0];

                        return {
                          isComputed,
                          alias,
                          locParam
                        };
                      })();
                  }
                })();
            }
          })()
        );
      })();

    return value ? [...(memo || []), value] : memo;
  }, null);
};

const _formulaConfsOutputGet = (formulaConfs) => {
  return formulaConfs.reduce((memo, formulaConf) => {
    const value =
      formulaConf.isComputed &&
      (() => {
        const _formula = formulaConfsOutputGet([formulaConf]);

        return (
          _formula &&
          (() => {
            const {
              isComputed,
              alias,
              threshold,
              unit,
              formula,
              equation,
              is_partial
            } = _formula[0];

            return {
              isComputed,
              alias,
              threshold,
              unit,
              computedFormula: {
                formula: formula[0].formula,
                equation,
                is_partial
              }
            };
          })()
        );
      })();

    return value ? [...(memo || []), value] : memo;
  }, null);
};

const _outputGet = (_locationBasedParamSelector) => {
  switch (_locationBasedParamSelector.dataType.value) {
    case dataTypeCollection[0]:
      return {
        ..._locationBasedParamSelector,
        value: {
          ...(() => {
            const { isComputed, alias, graphType, threshold } =
              (_locationBasedParamSelector.value &&
                _locationBasedParamSelector.value.trend[0]) ||
              {};

            return !isComputed
              ? {
                  isComputed,
                  alias,
                  graphType,
                  threshold
                }
              : {
                  graphType,
                  threshold
                };
          })(),
          trend: [
            ...(_parameterConfsOutputGet(
              (_locationBasedParamSelector.value &&
                _locationBasedParamSelector.value.trend) ||
                [],
              _locationBasedParamSelector.dataType.value
            ) || []),
            ...(_formulaConfsOutputGet(
              (_locationBasedParamSelector.value &&
                _locationBasedParamSelector.value.trend) ||
                []
            ) || [])
          ][0]
        }
      };

    case dataTypeCollection[1]:
      return (() => {
        switch (_locationBasedParamSelector.section.value) {
          case sectionCollection[0]:
            return {
              ..._locationBasedParamSelector,
              value: {
                params: {
                  ...((_locationBasedParamSelector.value &&
                    _locationBasedParamSelector.value.params) ||
                    []),
                  parameters: [
                    ...(_parameterConfsOutputGet(
                      (_locationBasedParamSelector.value &&
                        _locationBasedParamSelector.value.params &&
                        _locationBasedParamSelector.value.params.parameters) ||
                        [],
                      _locationBasedParamSelector.dataType.value,
                      _locationBasedParamSelector.section.value
                    ) || []),
                    ...(_formulaConfsOutputGet(
                      (_locationBasedParamSelector.value &&
                        _locationBasedParamSelector.value.params &&
                        _locationBasedParamSelector.value.params.parameters) ||
                        []
                    ) || [])
                  ]
                }
              }
            };

          case sectionCollection[1]:
            return {
              ..._locationBasedParamSelector,
              value: [
                ...(_parameterConfsOutputGet(
                  (_locationBasedParamSelector.value &&
                    _locationBasedParamSelector.value) ||
                    [],
                  _locationBasedParamSelector.dataType.value,
                  _locationBasedParamSelector.section.value
                ) || []),
                ...(_formulaConfsOutputGet(
                  (_locationBasedParamSelector.value &&
                    _locationBasedParamSelector.value) ||
                    []
                ) || [])
              ]
            };
        }
      })();
  }
};

const outputGet = (locationBasedParamSelector) => {
  return locationBasedParamSelector.reduce(
    (memo, _locationBasedParamSelector) => {
      const value = _outputGet(_locationBasedParamSelector);

      return [...memo, value];
    },
    []
  );
};

const locationBasedParamSelectorInitializedGet = () => {
  return {
    dataType: (() => {
      const value = dataTypeCollection[0];

      return {
        label: value,
        value
      };
    })(),
    value: {
      trend: [
        {
          ...parameterConfInputGet(),
          isComputed: false
        }
      ]
    }
  };
};

const ParameterConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [locationBasedParamSelector, setLocationBasedParamSelector] =
    useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setLocationBasedParamSelector(
        conf.locationBasedParamSelector && conf.locationBasedParamSelector.value
          ? locationBasedParamSelectorGet(conf.locationBasedParamSelector.value)
          : [locationBasedParamSelectorInitializedGet()]
      );

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      locationBasedParamSelector: {
        value: object.reduce((memo, _object) => {
          const value =
            _object.dataType.value === dataTypeCollection[1] &&
            _object.section.value === sectionCollection[0]
              ? {
                  ..._object,
                  value: {
                    ..._object.value,
                    params: {
                      ..._object.value.params,
                      tableConfig: 'Standard',
                      tableLayout: [[], []]
                    }
                  }
                }
              : _object;

          return [...memo, value];
        }, [])
      }
    };

    props.appInputParamChanged(value);
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        {locationBasedParamSelector.map(
          (_locationBasedParamSelector, index) => {
            return (
              <div key={index} className='content-border-wrap pd-y-10 mg-b-15'>
                <p className='head-label-text mb-0'>
                  {_locationBasedParamSelector.dataType.label}{' '}
                  {_locationBasedParamSelector.section &&
                    ` - ${_locationBasedParamSelector.section.label} `}
                  Configuration
                </p>
                <hr className='horizontal-divider mg-t-10 mg-b-15' />

                <Row>
                  <Col md={6}>
                    <div className='new-input-wrap'>
                      <label className='label-text'>
                        Configuration Data-Type
                      </label>

                      <Select
                        options={dataTypeCollection.reduce(
                          (memo, value) => [...memo, { label: value, value }],
                          []
                        )}
                        isMulti={false}
                        placeholder='Select Data-Type'
                        value={_locationBasedParamSelector.dataType}
                        onChange={(dataType) => {
                          const __locationBasedParamSelector = collectionGet(
                            {
                              ..._locationBasedParamSelector,
                              dataType,
                              ...(() => {
                                return dataType.value === dataTypeCollection[0]
                                  ? { section: undefined, value: undefined }
                                  : {
                                      section: (() => {
                                        const value = sectionCollection[0];

                                        return {
                                          label: value,
                                          value
                                        };
                                      })(),
                                      value: undefined
                                    };
                              })()
                            },
                            index,
                            locationBasedParamSelector
                          );

                          setLocationBasedParamSelector(
                            __locationBasedParamSelector
                          );

                          appInputParamChangedHandle(
                            outputGet(__locationBasedParamSelector)
                          );
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    {_locationBasedParamSelector.section && (
                      <div className='new-input-wrap'>
                        <label className='label-text'>
                          Section Requirement
                        </label>

                        <Select
                          options={sectionCollection.reduce(
                            (memo, value) => [...memo, { label: value, value }],
                            []
                          )}
                          isMulti={false}
                          placeholder='Select Section'
                          value={_locationBasedParamSelector.section}
                          onChange={(section) => {
                            const __locationBasedParamSelector = collectionGet(
                              {
                                ..._locationBasedParamSelector,
                                section,
                                value: undefined
                              },
                              index,
                              locationBasedParamSelector
                            );

                            setLocationBasedParamSelector(
                              __locationBasedParamSelector
                            );

                            appInputParamChangedHandle(
                              outputGet(__locationBasedParamSelector)
                            );
                          }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>

                {_locationBasedParamSelector.dataType.value ===
                  dataTypeCollection[1] &&
                  _locationBasedParamSelector.section.value ===
                    sectionCollection[0] && (
                    <div className='new-input-wrap'>
                      <label className='label-text'>Data Type</label>

                      <div className='radio-buttons-wrap'>
                        <RadioButtons
                          noOfButtons={indicatorDataTypeCollection.length}
                          getButtonLableAtIndex={(index) => {
                            return indicatorDataTypeCollection[index];
                          }}
                          buttonClickAtIndex={(_isDataTypeLive) => {
                            const isDataTypeLive = !_isDataTypeLive;

                            const __locationBasedParamSelector = collectionGet(
                              {
                                ..._locationBasedParamSelector,
                                value: {
                                  ..._locationBasedParamSelector.value,
                                  params: {
                                    ...((_locationBasedParamSelector.value &&
                                      _locationBasedParamSelector.value
                                        .params) ||
                                      {}),
                                    isDataTypeLive
                                  }
                                }
                              },
                              index,
                              locationBasedParamSelector
                            );

                            setLocationBasedParamSelector(
                              __locationBasedParamSelector
                            );

                            appInputParamChangedHandle(
                              outputGet(__locationBasedParamSelector)
                            );
                          }}
                          active={
                            _locationBasedParamSelector.value &&
                            _locationBasedParamSelector.value.params &&
                            _locationBasedParamSelector.value.params
                              .isDataTypeLive
                              ? 0
                              : 1
                          }
                        />
                      </div>
                    </div>
                  )}

                <ParameterConfigMixed
                  dataTypeCollection={dataTypeCollection}
                  dataType={_locationBasedParamSelector.dataType.value}
                  sectionCollection={sectionCollection}
                  section={
                    _locationBasedParamSelector.section &&
                    _locationBasedParamSelector.section.value
                  }
                  graphTypeCollection={graphTypeCollection}
                  isDataTypeLive={
                    _locationBasedParamSelector.value &&
                    _locationBasedParamSelector.value.params &&
                    _locationBasedParamSelector.value.params.isDataTypeLive
                  }
                  confs={(() => {
                    switch (_locationBasedParamSelector.dataType.value) {
                      case dataTypeCollection[0]:
                        return (
                          (_locationBasedParamSelector.value &&
                            _locationBasedParamSelector.value.trend) ||
                          []
                        );

                      case dataTypeCollection[1]:
                        return (() => {
                          switch (_locationBasedParamSelector.section.value) {
                            case sectionCollection[0]:
                              return (
                                (_locationBasedParamSelector.value &&
                                  _locationBasedParamSelector.value.params &&
                                  _locationBasedParamSelector.value.params
                                    .parameters) ||
                                []
                              );

                            case sectionCollection[1]:
                              return _locationBasedParamSelector.value || [];
                          }
                        })();
                    }
                  })()}
                  onConfsChange={(parameters) => {
                    const value = (() => {
                      switch (_locationBasedParamSelector.dataType.value) {
                        case dataTypeCollection[0]:
                          return {
                            ..._locationBasedParamSelector,
                            value: {
                              ...((_locationBasedParamSelector.value &&
                                _locationBasedParamSelector.value) ||
                                {}),
                              trend: parameters
                            }
                          };

                        case dataTypeCollection[1]:
                          return (() => {
                            switch (_locationBasedParamSelector.section.value) {
                              case sectionCollection[0]:
                                return {
                                  ..._locationBasedParamSelector,
                                  value: {
                                    params: {
                                      ...((_locationBasedParamSelector.value &&
                                        _locationBasedParamSelector.value
                                          .params) ||
                                        {}),
                                      parameters
                                    }
                                  }
                                };

                              case sectionCollection[1]:
                                return {
                                  ..._locationBasedParamSelector,
                                  value: parameters
                                };
                            }
                          })();
                      }
                    })();

                    const __locationBasedParamSelector = collectionGet(
                      value,
                      index,
                      locationBasedParamSelector
                    );

                    setLocationBasedParamSelector(__locationBasedParamSelector);

                    appInputParamChangedHandle(
                      outputGet(__locationBasedParamSelector)
                    );
                  }}
                />
              </div>
            );
          }
        )}

        <div className='btn-wrap'>
          <button
            className='icon-text-btn mg-t-15'
            onClick={() => {
              const _locationBasedParamSelector = [
                ...locationBasedParamSelector,
                locationBasedParamSelectorInitializedGet()
              ];

              return setLocationBasedParamSelector(_locationBasedParamSelector);
            }}
          >
            <i className='fa fa-plus-circle fa-3x' aria-hidden='true'></i> Add
            Configuration Data-Type
          </button>
        </div>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfig;
