import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../constants';
import {
  httpMethodDelete,
  httpMethodGetJSON,
  httpMethodPost,
  httpMethodPut
} from '../../../https';
import {
  GET_PLANT_HOLIDAY,
  CREATE_PLANT_HOLIDAY,
  UPDATE_PLANT_HOLIDAY,
  DELETE_PLANT_HOLIDAY
} from './constant';

export const getPlantHoliday = (action$) =>
  action$.pipe(
    ofType(GET_PLANT_HOLIDAY),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/plant/${action.payload.plant_id}/holiday/`
      );
    })
  );

export const createPlantHoliday = (action$) =>
  action$.pipe(
    ofType(CREATE_PLANT_HOLIDAY),
    mergeMap((action) => {
      return httpMethodPost(
        action,
        `${URL}/plant/${action.payload.plant_id}/holiday/`
      );
    })
  );

export const updatePlantHoliday = (action$) =>
  action$.pipe(
    ofType(UPDATE_PLANT_HOLIDAY),
    mergeMap((action) => {
      return httpMethodPut(
        action,
        `${URL}/plant/${action.payload.plant_id}/holiday/${action.payload.id}/`
      );
    })
  );

export const deletePlantHoliday = (action$) =>
  action$.pipe(
    ofType(DELETE_PLANT_HOLIDAY),
    mergeMap((action) => {
      return httpMethodDelete(
        action,
        `${URL}/plant/${action.payload.plant_id}/holiday/${action.payload.id}/`
      );
    })
  );
