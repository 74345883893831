import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SelectDropDown from '../../../../../common/components/Select';
import { Button } from '../../../../../common/components';
import { Label, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { getTemplateWidgets } from "../../action";
import { parse } from 'query-string';
import { withRouter, useLocation } from 'react-router-dom';

const PAGE_SIZE = 200;

function ContainerWidgetSelect(props) {
  var [widgetList, setWidgetList] = useState(['']);
  const location = useLocation();
  let assetLocationId = parse(location.search).assetId;

  useEffect(() => {
    props.getTemplateWidgets({ 
      template_id: props.dashobardId,
      "page_no": 1,
      "page_size": PAGE_SIZE
    });
  }, []);

  useEffect(() => {
    if(Array.isArray(props.value) && props.value.length > 0){
      setWidgetList(props.value)
    }
  }, [props.value]);

  useEffect(() => {
  }, [props.appParams]);

  const editWidgetConfiguration = (widget) => {
    setTimeout(()=>{
      props.history.go(0);
    }, 0);
    props.history.push({
      pathname: `/Templates/WidgetBuilder/${props.dashobardId}/${widget.widget_id.id}?assetId=${assetLocationId}&edit_id=${widget.id}`,
      state: { widget: widget }
    });
  }

  return <Col className="select-dropdown custom-select-parameter" xl={props.width ? props.width : 12} >
    <div className='add-widget-btn'>
      <Button 
        buttonType='primary' 
        size='sm'
        buttonClick={()=>{
          let oldWidgetList = [...widgetList];
          oldWidgetList.push('')
          setWidgetList([...oldWidgetList]);
        }}
        innerContent='Add Widget'
        className='buttonFill ml-0'
      />
    </div>
    {
      widgetList.map((item, i)=>{
        return <Row className='mb-3'>
          {
            <span className="text-danger delete" onClick={() => { 
              let oldWidgetList = [...widgetList];
              oldWidgetList.splice(i, 1);
              setWidgetList([...oldWidgetList]);
              props.onChange([...oldWidgetList], 'groupWidgets')
            }}>
            Delete
          </span>
          }
          <span className="text-edit edit" onClick={() => {
              let a = props.widgetsData.filter((elem)=>{
                return elem.id === item;
              })[0];
              editWidgetConfiguration(a);
            }}>
            Edit
          </span>
          <Col xl={12} className='select-container'>
            <Label for={props.name} className='label'>Select Widget
              {props.required === true ? <span className='text-danger'> * </span> : null}
            </Label>
            <SelectDropDown
              name='widget'
              value={
                props.widgetsData.filter((elem)=>{
                  return elem.id === item;
                }).map((el) => {
                  el.label = el.config && el.config.widget_name ? el.config.widget_name : '';
                  el.value = el.id;
                  return el
                })[0]}
              onChange={(e) => {
                let oldWidgetList = [...widgetList];
                // oldWidgetList[i] = {};
                oldWidgetList[i] = e.id;
                setWidgetList([...oldWidgetList]);
                // getWidgetsForDashboard(e.id);
                props.onChange([...oldWidgetList], oldWidgetList===0, 'groupWidgets')
              }}
              options={props.widgetsData.filter((w)=>{
                return  !widgetList.includes(w.id);
              }).map((a)=>{
                a.label = a.config && a.config.widget_name ? a.config.widget_name : '';
                a.value = a.id;
                return a;
              })}
              placeholder={props.placeholder}
              isMulti={false}
              menuPlacement='top'
            />
          </Col>
        </Row>
      })
    }
  </Col>

}


const mapStateToProps = (state) => {
  let widgetsData = state.getTemplateWidgets.data;

  let ignoredWidgets = ['Group'];

  // remove group type widgets
  let filterdWidgetsData = widgetsData.filter((wid)=>{
    let type = wid.widget_id && wid.widget_id.type
    return ignoredWidgets.includes(type);
  })

  return {
    widgetsData: filterdWidgetsData,
  };
};

ContainerWidgetSelect.defaultProps = {
  appParams: {},
  appInputParamChanged: function () {}
}

ContainerWidgetSelect.propTypes = {
  appParams: PropTypes.object,
  appInputParamChanged: PropTypes.func
}

export default withRouter(connect(mapStateToProps, {
  getTemplateWidgets
})(ContainerWidgetSelect));