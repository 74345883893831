import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  useMemo,
  Suspense
} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import {
  Row,
  Col,
  Card,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import _ from 'lodash';

import {
  Icon,
  Button,
  Tabs,
  Input,
  CheckBox,
  Loader
} from '../../../../../common/components/';
import {
  mergeConfig,
  defaultParamsConfig,
  paramSelector,
  basicLocationSelector,
  locationSelector,
  filler,
  formulaMaker,
  genericConfig,
  locationBasedParamSelector,
  customCheckbox,
  customRadio,
  locationAndParamSelect,
  sensorSelector,
  numericInput,
  readingtypeSelect,
  groupWidgetSelect,
  containerWidgetSelect,
  assetSensorParam,
  assetInsightParam,
  textEditorConfig
} from '../../widgetsConfig';
import { INPUT_TYPES } from '../../../../../common/components/Input/inputTypes';
import DefaultWidgetParams from './defaultParam';
import WidgetSelector from '../WidgetSelector';
import WidgetLayout from '../WidgetLayout';
import ParameterSelector from './parameterSelector';
import BasicLocationSelector from './basicLocationSelector';
import LocationBasedParamSelector from './locationBasedParamSelector';
import LocationSelector from '../../../../../common/components/LocationSelector';
import { Context, UserContext } from '../../../Main/context';
import ButtonGroup from '../../../../../common/components/ButtonGroup';
import NumericInput from './numericInput';
import WidgetSelect from './WidgetSelect';
import ContainerWidgetSelect from './ContainerWidgetSelect';
import {
  getAllWidgetConfig,
  createWidget,
  resetCreateWidget,
  editWidget,
  resetEditWidget,
  deleteWidget,
  resetDeleteWidget,
  deleteWidgetOfTemplate,
  createHealthData,
  updateHealthData,
  createHealthDataReset
} from '../../action';
import Filler from '../../../../../common/components/Filler';
import { FormulaMaker } from './FormulaMaker';
import LocationAndParameterSelect from '../Widgets/CommonControls/LocationAndParameterSelect';
import SensorSelector from './SensorSelector';
import ReadingTypeSelect from '../Widgets/CommonControls/ReadingTypeSelect';
import WidgetConfigHistory from '../WidgetConfigHistory';
import { resetUpdateDashboardWidget } from '../../action';
import {
  addTemplateWidget,
  updateWidgetTemplate,
  resetAddDashboardWidget,
  resetUpdateWidgetTemplate,
  resetAddWidgetTemplate,
  resetDeleteDashboardWidget,
  addDashboardWidget,
  updateDashboardWidget,
  updateDashboardWidgetReset,
  deleteDashboardWidget,
  createSystemTemplateWidget,
  createSystemTemplateWidgetReset,
  updateSystemTemplateWidget,
  updateSystemTemplateWidgetReset,
  deleteSystemTemplateWidget
} from '../../../AssetScreenDesign/action';
import AssetSensorParams from './AssetSensorParams';
import InsightParamSelector from './InsightParamSelector';
import TextEditorWidgetConfig from './TextEditorWidgetConfig';
import { getServiceByName } from '../../../../../common/utils/services';
import { EDITOR, VIEWER, NOT_ACCESS_MESSAGE } from '../../../Main/appSetting';
import dashboardFilterConfigInputGet from './ConfigComponents/common/dashboardFilterConfigInputGet';
import GenericConfig from './GenericConfig';

const TABS = ['Parameters', 'Audit Trail'];
// const TABS = ['Parameters'];

const HOURS = {
  '1 Hours': 1,
  '3 Hours': 3,
  '6 Hours': 6,
  '12 Hours': 12,
  '1 Day': 24
};

function WidgetBuilder(_props) {
  const {
    createSystemTemplateWidgetReset,
    updateSystemTemplateWidgetReset,
    ...props
  } = _props;

  const context = useContext(Context);
  const userContext = useContext(UserContext);
  const isTemplate = pathname && pathname[1] == 'Templates';

  let pathname = props.location.pathname.split('/');
  let editMode = false;
  var routeEditWidgetState = {};

  const asset_type =
    parse(props.location.pathname).assetType ||
    parse(props.location.search).assetType ||
    'motor';

  if (location.search) {
    editMode = parse(location.search).edit_id;
    routeEditWidgetState = props.location.state && props.location.state.widget;
  }

  function getEditParams() {
    if (editMode) {
      let editParams = {};
      Object.keys(routeEditWidgetState?.config)?.map((paramName, i) => {
        editParams[paramName] = {
          isInValid: false,
          value: routeEditWidgetState.config[paramName]
        };
      });

      return editParams;
    }

    return {};
  }

  const isInlineWidgetBuilder = () => {
    return props.inline && props.inline.isInline;
  };

  const getWidgetKey = () => {
    if (isInlineWidgetBuilder()) {
      return props.inline.widgetId;
    }

    return location.pathname.split('/')[4] || 0;
  };

  const getDashboardKey = () => {
    if (isInlineWidgetBuilder()) {
      return props.inline.dashboardId;
    }

    return location.pathname.split('/')[3] || 0;
  };

  const getWidgetDetails = (id) => {
    let allWidgetConfig = JSON.parse(localStorage.getItem('allWidgetConfig'));
    if (Array.isArray(allWidgetConfig) && allWidgetConfig.length) {
      return allWidgetConfig.find((item) => item.id == id);
    }
    return null;
  };

  const getTemplateKey = () => {
    if (isInlineWidgetBuilder()) {
      return props.inline.dashboardId;
    }

    return editMode
      ? location.pathname.split('/')[5] || 0
      : location.pathname.split('/')[8] || 0;
  };

  const widgetKey = getWidgetKey();
  const dashboardKey = getDashboardKey();
  const templateKey = getTemplateKey();

  const _widgetParamsRef = useRef({});

  const [selectedWidgetIndex, selectWidget] = useState(0);
  const [isOpen, setModal] = useState(false);
  const [previewCount, setPreviewCount] = useState(null);
  const [previewCountConfig, setPreviewCountConfig] = useState(null);
  const [configView, setConfigView] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  // for factory widget(id: 35) formdata is handled locally
  const [updateFormCount, setUpdateFormCount] = useState(0);
  const [periodError, setPeriodError] = useState('');

  // state update causes reload of component
  const [widgetParams, setWidgetParams] = useState({});
  //var widgetConfig = props.widgetConfig.filter(
  //(wid) => wid && wid.id == widgetKey
  //)[0];

  const widgetConfig = useMemo(() => {
    const _widgetConfig = props.widgetConfig.find(({ id } = {}) => {
      return id == widgetKey;
    });

    switch (_widgetConfig && _widgetConfig.type) {
      case 'Energy Consumption Mix':
        return (() => {
          return widgetParams.graphShowToggle &&
            widgetParams.graphShowToggle.value
            ? {
                ..._widgetConfig,
                widgetParams: _widgetConfig.widgetParams.reduce(
                  (memo, widgetParam) => {
                    const match = [
                      'Widget level Notify',
                      'progress',
                      'notification'
                    ].includes(widgetParam.name);

                    return !match ? [...memo, widgetParam] : memo;
                  },
                  []
                )
              }
            : _widgetConfig;
        })();

      default:
        return _widgetConfig;
    }
  }, [props.widgetConfig, widgetKey, widgetParams.graphShowToggle]);

  const getServiceId = () => {
    const retrievedData = localStorage.getItem('smartsense.services');
    const services = JSON.parse(retrievedData);
    const filteredService = services.filter(
      (item) => item.name == (props.location.state && props.location.state.path)
    );
    return pathname && pathname[1] == 'Templates'
      ? getServiceByName('Asset Insights') &&
          getServiceByName('Asset Insights').id
      : props.location.state &&
        props.location.state.path &&
        !props.location.state.path.startsWith('/')
      ? filteredService[0].id
      : 1;
  };

  useEffect(() => {
    props.healthConfigData &&
      props.healthConfigData.data &&
      (() => {
        props.createHealthDataReset();

        const params = Object.entries(widgetParams || {}).reduce(
          (memo, [key, { value }]) => {
            return {
              ...memo,
              [key]:
                key === 'sensorHealthConfig'
                  ? {
                      ...value,
                      healthConfigData: props.healthConfigData.data.id
                    }
                  : value
            };
          },
          {}
        );

        editMode
          ? props.editWidget({
              id: routeEditWidgetState.id,
              dashboard_id: dashboardKey,
              widget_id: widgetKey,
              section: '',
              sequence: null,
              config: params
            })
          : props.createWidget({
              id: dashboardKey,
              widget_id: widgetKey,
              section: '',
              sequence: null,
              config: params
            });
      })();
  }, [props.healthConfigData]);

  useEffect(() => {
    // will go in loop
    if (!isInlineWidgetBuilder()) {
      //props.getAllWidgetConfig({ service_id: getServiceId() });
    }
    setWidgetParams(getEditParams());

    // reset parameters
  }, [activeTab, props.location]); // activeTab to load the widget params on tab change

  useEffect(() => {
    if (updateFormCount) saveWidget();
  }, [widgetParams]);

  useEffect(() => {
    if (editMode) return;

    setWidgetParams((widgetParams) => {
      let oldWidgetParams = { ...widgetParams };

      widgetConfig &&
        widgetConfig.widgetParams &&
        widgetConfig.widgetParams.map((item) => {
          if (!item.name) return;

          oldWidgetParams[item.name] = {
            value: item.value,
            isInValid: item.isDefaultInvalid === false ? false : true
          };
        });

      return oldWidgetParams;
    });
  }, [widgetConfig]);

  useEffect(() => {
    if (props.createWidgetData.success === true) {
      props.resetCreateWidget();
      context.notify.success('Widget Added');
      props.history.goBack();
    }

    if (props.createWidgetData.error === true) {
      props.resetCreateWidget();
      context.notify.error(
        props.createWidgetData.data &&
          props.createWidgetData.data.response &&
          props.createWidgetData.data.response.message
      );
    }
  }, [props.createWidgetData]);

  useEffect(() => {
    if (props.addDashboardData.success === true) {
      props.resetCreateWidget();
      props.resetAddDashboardWidget();
      context.notify.success('Widget Added');
      props.history.goBack();
    }

    if (props.addDashboardData.error === true) {
      props.resetCreateWidget();
      context.notify.error(
        props.addDashboardData.data &&
          props.addDashboardData.data.response &&
          props.addDashboardData.data.response.message
      );
    }
  }, [props.addDashboardData]);

  useEffect(() => {
    if (props.editWidgetData.success === true) {
      props.resetEditWidget();
      props.resetUpdateDashboardWidget();
      context.notify.success('Widget Updated');
      props.history.goBack();
    }

    if (props.editWidgetData.error === true) {
      props.resetEditWidget();
      context.notify.error(
        props.editWidgetData.data &&
          props.editWidgetData.data.response &&
          props.editWidgetData.data.response.message
      );
      props.resetUpdateDashboardWidget();
      context.notify.error(props.editWidgetData.data && props.editWidgetData.data.response && props.editWidgetData.data.response.message);
    }
  }, [props.editWidgetData]);

  useEffect(() => {
    if (props.updateWidgetData.success === true) {
      props.resetEditWidget();
      props.resetUpdateDashboardWidget();
      context.notify.success('Widget Updated');
      props.history.goBack();
    }

    if (props.updateWidgetData.error === true) {
      props.resetEditWidget();
      context.notify.error(
        props.updateWidgetData.data &&
          props.updateWidgetData.data.response &&
          props.updateWidgetData.data.response.message
      );
      props.resetUpdateDashboardWidget();
      context.notify.error(props.updateWidgetData.data && props.updateWidgetData.data.response && props.updateWidgetData.data.response.message);
    }
  }, [props.updateWidgetData]);

  useEffect(() => {
    if (props.deleteWidgetData.success === true) {
      props.resetDeleteWidget();
      //context.notify.success("Widget Deleted");
      //props.history.goBack();
    }
  }, [props.deleteWidgetData]);

  useEffect(() => {
    if (props.deleteDashboardData.success === true) {
      props.resetDeleteWidget();
      props.resetDeleteDashboardWidget();
      //context.notify.success("Widget Deleted");
      //props.history.goBack();
    }
  }, [props.deleteDashboardData]);

  useEffect(() => {
    const { success, error } = props.updateSystemTemplateWidgetData;

    success &&
      (() => {
        updateSystemTemplateWidgetReset();

        context.notify.success('Template Widget Updated');

        props.history.goBack();
      })();

    error &&
      (() => {
        context.notify.error('Template Widget Update Failed');

        updateSystemTemplateWidgetReset();
      })();
  }, [
    props.updateSystemTemplateWidgetData,
    context.notify,
    props.history,
    updateSystemTemplateWidgetReset
  ]);

  useEffect(() => {
    const { success, error } = props.createSystemTemplateWidgetData;

    success &&
      (() => {
        context.notify.success('Template Widget Added');

        window.location.reload();

        props.history.goBack();

        createSystemTemplateWidgetReset();
      })();

    error &&
      (() => {
        context.notify.error('Template Widget Add Failed');

        createSystemTemplateWidgetReset();
      })();
  }, [
    props.createSystemTemplateWidgetData,
    context.notify,
    props.history,
    createSystemTemplateWidgetReset
  ]);

  //Template Design Hooks goes here.... //
  useEffect(() => {
    if (props.addedTemplateWidget.success === true) {
      context.notify.success('Template Widget Added');
      props.resetAddWidgetTemplate();
      window.location.reload();
      props.history.goBack();
    }

    if (props.addedTemplateWidget.error === true) {
      props.resetAddWidgetTemplate();
      context.notify.error('Template Widget Add Failed');
    }
  }, [props.addedTemplateWidget]);

  useEffect(() => {
    if (props.updateTemplateWidget.success === true) {
      props.resetUpdateWidgetTemplate();
      context.notify.success('Template Widget Updated');
      props.history.goBack();
    }

    if (props.updateTemplateWidget.error === true) {
      props.resetUpdateWidgetTemplate();
      context.notify.error('Template Widget Update Failed');
    }
  }, [props.updateTemplateWidget]);

  useEffect(() => {
    if (props.deleteTemplateWidget.success === true) {
      props.history.goBack();
    }
  }, [props.deleteTemplateWidget]);

  useEffect(() => {
    if (previewCountConfig) {
      setPreviewCount(previewCount + 1);
    }
  }, [previewCountConfig]);

  widgetConfig &&
    widgetConfig.configuration
      .then((component) => {
        setConfigView(component.default);
      })
      .catch(() => {
        setConfigView(false);
      });

  function changeWidget() {
    setModal(true);
  }

  function closeModal() {
    setModal(false);
  }

  function setWigetSelected(index) {
    selectWidget(index);
  }

  function openAddWidgetConfiguration() {
    props.history.push(`${props.widgetConfig[selectedWidgetIndex].id}`);
    props.history.go(0);
  }

  function isWidgetConfigurationValid() {
    let isAppParamsValid = [];
    let isDefaultParamsValid = [];
    let inValidParamNames = [];

    // defaultParams
    isDefaultParamsValid = defaultParamsConfig.map((defaultParam) => {
      if (defaultParam.required === true) {
        if (!widgetParams[defaultParam.name]) {
          inValidParamNames.push(defaultParam.label);

          return false;
        }
        if (widgetParams[defaultParam.name].isInValid === false) {
          return true;
        }

        inValidParamNames.push(defaultParam.label);
        return false;
      }

      return true;
    });

    // app specific params validation
    if (widgetConfig?.widgetParams) {
      isAppParamsValid = widgetConfig.widgetParams.map((param) => {
        if (param.required === true) {
          if (!widgetParams[param.name]) {
            inValidParamNames.push(param.label);

            return false
          }
          if (widgetParams[param.name].isInValid === false) {
            return true
          }

          const errorMessage = widgetParams[param.name].errorMessage;
          inValidParamNames.push(
            !inValidParamNames.length && errorMessage
              ? errorMessage
              : param.label
          );
          return false;
        }

        return true;
      })
    }

    if ([...isDefaultParamsValid, ...isAppParamsValid].indexOf(false) !== -1) {

      return [false, inValidParamNames];

    } else {

      return [true, []]
    }
  }

  function saveWidget() {
    let valid = isWidgetConfigurationValid();

    if (valid[0]) {
      let params = {};

      Object.keys(widgetParams).map((paramName, i) => {
        params[paramName] = widgetParams[paramName].value;
      });
      let widgetData = getWidgetDetails(widgetKey);
      if (
        widgetData.type === 'Health' &&
        params &&
        params.sensorHealthConfig &&
        params.sensorHealthConfig.enableAlert &&
        !editMode
      ) {
        let payload = {
          disconnectedsincehours: HOURS[
            params.sensorHealthConfig.disconnectedSince
          ]
            ? HOURS[params.sensorHealthConfig.disconnectedSince]
            : 0,

          schedule_time: params.sensorHealthConfig.schedule,

          emailto: params.sensorHealthConfig.emails,

          phoneNumber: params.sensorHealthConfig.phoneNumber
        };

        props.createHealthData(payload);

        return;
      }
      if (
        widgetData.type === 'Health' &&
        params &&
        params.sensorHealthConfig &&
        params.sensorHealthConfig.enableAlert &&
        editMode
      ) {
        let payload = {
          disconnectedsincehours: HOURS[
            params.sensorHealthConfig.disconnectedSince
          ]
            ? HOURS[params.sensorHealthConfig.disconnectedSince]
            : 0,

          schedule_time: params.sensorHealthConfig.schedule,

          emailto: params.sensorHealthConfig.emails,

          phoneNumber: params.sensorHealthConfig.phoneNumber,
          healthConfigData: params.sensorHealthConfig.healthConfigData
        };
        // props.createHealthData(payload)
        props.updateHealthData(payload);

        return;
      }

      switch (true) {
        case !!pathname && pathname[1] == 'Templates':
          return (() => {
            switch (true) {
              case !!editMode:
                return props.updateWidgetTemplate({
                  data: {
                    id: routeEditWidgetState.id,
                    widget_id: parseInt(widgetKey),
                    sequence: 1,
                    config: params
                  },
                  template_id: dashboardKey,
                  id: routeEditWidgetState.id,
                  widget_id: widgetKey
                });

              default:
                return props.addTemplateWidget({
                  data: {
                    widget_id: widgetKey,
                    sequence: 1,
                    config: params
                  },
                  template_id: pathname[3]
                });
            }
          })();

        case !!pathname && pathname[1] == 'System':
          switch (true) {
            case !!editMode:
              return props.updateSystemTemplateWidget({
                template_id: dashboardKey,
                id: routeEditWidgetState.id,
                config: params,
                sequence: 1
              });

            default:
              return props.createSystemTemplateWidget({
                template_id: dashboardKey,
                widget_id: widgetKey,
                config: params,
                sequence: 1
              });
          }

        default:
          return (() => {
            switch (true) {
              case !!editMode:
                return (() => {
                  switch (true) {
                    case templateKey != 0:
                      return props.updateDashboardWidget({
                        id: routeEditWidgetState.id,
                        template_id: templateKey,
                        dashboard_id: dashboardKey,
                        widget_id: widgetKey,
                        section: '',
                        sequence: null,
                        config: params
                      });

                    default:
                      return props.editWidget({
                        id: routeEditWidgetState.id,
                        dashboard_id: dashboardKey,
                        widget_id: widgetKey,
                        section: '',
                        sequence: null,
                        config: params
                      });
                  }
                })();

              default:
                return (() => {
                  switch (true) {
                    case templateKey != 0:
                      return props.addDashboardWidget({
                        template_id: templateKey,
                        dashboard_id: dashboardKey,
                        widget_id: widgetKey,
                        section: '',
                        sequence: null,
                        config: params
                      });

                    default:
                      return props.createWidget({
                        id: dashboardKey,
                        widget_id: widgetKey,
                        section: '',
                        sequence: null,
                        config: params
                      });
                  }
                })();
            }
          })();
      }
    } else {
      context.notify.error(`Invalid Configuration - ${valid[1].join(', ')}`);
    }
  }

  function cancleWigetConfiguration() {
    let pathname = props.location.pathname.split('/');
    if (pathname && pathname[1] == 'Dashboard') {
      //props.history.push(`/Dashboard/`);
      props.history.goBack();
    } else {
      props.history.goBack();
      // props.history.push(`/Templates/TemplateDetails?assetId=${pathname[7]}&assetName=${pathname[6]}&templateName=${pathname[5]}&templateId=${pathname[3]}`);
    }
  }
  function getHeader() {
    return (
      <div className='dashboard-widget-builder-header'>
        <Row>
          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={isOpen ? 'modal-open' : 'add-new-widget-button'}
          >
            <div className='appTitle'>
              {editMode ? (
                <span>
                  <i className='fa fa-edit'></i>
                  <span className='editTitle'>
                    {`Edit Widget - `}
                    <span>
                      {widgetConfig.name}
                    </span>
                  </span>
                </span>
              ) : (
                <span>
                  {`Add new widget - `}{' '}
                  <span>
                    {widgetConfig.name}
                  </span>
                </span>
              )}
            </div>
          </Col>
          <Col
            className='widget-builder-header-buttons'
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
          >
            <div className='save-changes'>
              <Button
                loading={
                  isTemplate
                    ? editMode
                      ? props.addedTemplateWidget.loading
                      : props.updateTemplateWidget.loading
                    : editMode
                    ? templateKey !== 0
                      ? props.updateWidgetData.loading
                      : props.editWidgetData.loading
                    : props.createWidgetData.loading
                    ? props.createWidgetData.loading
                    : props.addDashboardData.loading
                }
                buttonType='primary'
                size='md'
                buttonClick={() => {
                  if (
                    [
                      'Factories',
                      'Insight-alert-summary',
                      'Parameter Tracking',
                      'Alert List'
                    ].includes(widgetConfig.type)
                  ) {
                    // for factory widget(id: 35) formdata is handled locally
                    setUpdateFormCount(updateFormCount + 1);
                  } else {
                    saveWidget();
                  }
                }}
                innerContent={editMode ? 'Save Changes' : 'Add Widget'}
              />
            </div>
            {editMode ? (
              <div className='delete-widget'>
                <Button
                  loading={
                    isTemplate
                      ? props.deleteTemplateWidget.loading
                      : props.deleteWidgetData.loading
                  }
                  buttonType='secondory'
                  size='md'
                  buttonClick={() => {
                    context.confirm.show(
                      `Are you sure you want to delete widget?`,
                      () => {
                        (() => {
                          switch (true) {
                            case pathname && pathname[1] == 'Templates':
                              return props.deleteWidgetOfTemplate({
                                template_id: dashboardKey,
                                widget_id: routeEditWidgetState.id
                              });

                            case pathname && pathname[1] == 'System':
                              return props.deleteSystemTemplateWidget({
                                template_id: dashboardKey,
                                widget_id: routeEditWidgetState.id
                              });

                            default:
                              return (() => {
                                switch (true) {
                                  case templateKey !== 0:
                                    return props.deleteDashboardWidget({
                                      template_id: templateKey,
                                      dashboard_id: dashboardKey,
                                      widget_id: routeEditWidgetState.id
                                    });

                                  default:
                                    return props.deleteWidget({
                                      dashboardId: dashboardKey,
                                      id: routeEditWidgetState.id
                                    });
                                }
                              })();
                          }
                        })();

                        props.history.goBack();
                      }
                    );
                  }}
                  innerContent={
                    <span>
                      <i className='fa fa-trash' aria-hidden='true'></i> Delete
                      Widget
                    </span>
                  }
                />
              </div>
            ) : null}
            <div className='cancle'>
              <Button
                buttonType='secondory'
                size='md'
                buttonClick={cancleWigetConfiguration}
                innerContent='X Cancel'
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  function getWidget(widget) {
    return (
      <WidgetLayout
        name={widget.name}
        description={widget.description}
        notAccessToasterMsg={() => context.notify.error(NOT_ACCESS_MESSAGE)}
        isViewer={userContext && userContext.roles && userContext.roles[VIEWER]}
      >
        {previewCount > 0 ? (
          isWidgetConfigurationValid()[0] ? (
            <widget.view
              appParams={widgetParams}
              preview={{ count: previewCount }}
              isPreview={true}
              dashboardId={dashboardKey}
              pathname={
                pathname && (pathname[1] == 'Templates' || pathname[1] == 'CBM')
              }
              widgetId={[33, 32, 34, 31, 35, 36, 37, 38, 28, 29, 30].includes(
                widget.id
              )}
              dashboardFilterConfig={dashboardFilterConfigInputGet()}
            />
          ) : (
            `Invalid Configuration - ${isWidgetConfigurationValid()[1].join(
              ', '
            )}`
          )
        ) : (
          'Click on refresh preview to update preview'
        )}
      </WidgetLayout>
    );
  }

  function defaultInputChanged(value, isInValid, name) {
    let oldWidgetParams = { ...widgetParams };
    oldWidgetParams[name] = { value, isInValid };
    setWidgetParams(oldWidgetParams);
  }

  function appInputChanged(value, isInValid, name, errorMessage) {
    //let oldWidgetParams = { ...widgetParams };
    //oldWidgetParams[name] = {
    //value,
    //isInValid: isInValid === true ? true : false,
    //errorMessage
    //};

    setWidgetParams((widgetParams) => {
      _widgetParamsRef.current = widgetParams;

      const _widgetParams = name.trim()
        ? {
            ...widgetParams,
            [name]: {
              value,
              isInValid,
              errorMessage
            }
          }
        : {
            ...widgetParams,
            ...value
          };

      if (
        ['Energy Consumption Mix'].includes(widgetConfig.type) &&
        !_widgetParams.progress
      ) {
        _widgetParams.progress = { value: 'Yes', isInValid: null };
      }

      if (
        ['Energy Consumption Mix'].includes(widgetConfig.type) &&
        !_widgetParams.notification
      ) {
        _widgetParams.notification = { value: 'Yes', isInValid: null };
      }

      return _widgetParams;
    });
  }

  function getPreview() {
    return (
      <Suspense fallback={'Loading...'}>
        <div className='widget-builder-preview'>{getWidget(widgetConfig)}</div>
      </Suspense>
    );
  }

  const periodValidator = (period, from, value, param, isInValid) => {
    let isFrom = param.name == 'from';
    if (period == 'Week' && from > 4) {
      appInputChanged(value, isFrom ? true : isInValid, param.name);
      setPeriodError('value should be less than or equal to 4');
    } else if (period == 'Month' && from > 1) {
      appInputChanged(value, isFrom ? true : isInValid, param.name);
      setPeriodError('value should be only 1');
    } else if (from > 31) {
      appInputChanged(value, isFrom ? true : isInValid, param.name);
      setPeriodError('value should be less than or equal to 31');
    } else {
      appInputChanged(value, isInValid, param.name);
      setPeriodError('');
    }
  };

  function getInputFields(param, index) {
    const scorecardPeriodCurrentMonth =
      widgetConfig.type === 'Scorecard' &&
      widgetParams.period &&
      widgetParams.period.value === 'Current Month' &&
      param.name === 'from';

   const graphPeriodMonth =
      widgetConfig.type === 'Graph' &&
      widgetParams.period &&
      widgetParams.period.value === 'Month' &&
      param.name === 'period';

    const graphDataFrequencyDay =
      widgetConfig.type === 'Graph' &&
      widgetParams.dataFrequency &&
      widgetParams.dataFrequency.value === '1 Day' &&
      param.name === 'dataFrequency';

    const anomalyDetectionShowPeriodFlag =
      widgetConfig.type === 'Anomaly Detection' &&
      widgetParams.showPeriod &&
      widgetParams.showPeriod.value &&
      param.name === 'period';

    return (
      <Input
        key={index}
        name={param.name}
        value={(() => {
          switch (true) {
            case !!scorecardPeriodCurrentMonth:
              return 0;

            case !!graphPeriodMonth:
              return param.options[2];

            case !!graphDataFrequencyDay:
              return param.options[4];

            default:
              return param.value;
          }
        })()}
        label={param.label}
        type={param.type}
        inputChanged={(value, isInValid) => {
          if (widgetConfig.type === 'Heatmap') {
            let period = widgetParams && widgetParams.period;
            let from = widgetParams && widgetParams.from;
            if (param.name == 'from') {
              periodValidator(
                period && period.value,
                value,
                value,
                param,
                isInValid
              );
            } else if (param.name == 'period') {
              periodValidator(
                value,
                from && from.value,
                value,
                param,
                isInValid
              );
            } else {
              periodValidator(null, null, value, param, isInValid);
            }
          } else {
            appInputChanged(value, isInValid, param.name);
          }
        }}
        placeholder={param.placeholder}
        disabled={scorecardPeriodCurrentMonth}
        required={param.required}
        width={param.width}
        min={param.min}
        max={param.max}
        options={
          !anomalyDetectionShowPeriodFlag
            ? param.options
            : param.options.slice(0, -1)
        }
        step={param.step}
        hideEmptyOption={param.hideEmptyOption}
        hint={param.hint}
        errorMessage={
          param.name == 'from' && widgetConfig.type === 'Heatmap'
            ? periodError
            : ''
        }
      />
    );
  }

  const updateWidgetParams = (param) => {
    const allParams = [
      'show_asset_history',
      'show_network_history',
      'show_system_history'
    ];

    allParams.forEach((paramName) => {
      if (paramName !== param.name && widgetParams[paramName]) {
        widgetParams[paramName].value = false;
      }
    });

    if (widgetParams[param.name]) {
      widgetParams[param.name].value = true;
    }
  };

  const checkIfCheckBoxReset = (param) => {
    if (param.name === 'system_list_view') {
      appInputChanged([], false, 'assetSensorParam');
    }
  };

  function getInputFieldForType(param, index) {
    // check all supported html input types by common input component
    if (
      Object.values(INPUT_TYPES).indexOf(param.type) !== -1 &&
      !param.isTemplateCbm
    ) {
      return getInputFields(param, index);
    } else if (param.isTemplateCbm === (pathname && pathname[1])) {
      return getInputFields(param, index);
    }

    switch (param.type) {
      case paramSelector:
        return (
          <ParameterSelector
            onChange={(value, isInValid) => {
              appInputChanged(value, isInValid, param.name);
            }}
            name={param.name}
            label={param.label}
            required={param.required}
            value={param.value}
            placeholder={param.placeholder}
            isMulti={false}
            width={param.width}
            appParams={widgetParams}
          />
        );
      case basicLocationSelector:
        return (
          <BasicLocationSelector
            onChange={(value, isInValid) => {
              appInputChanged(value, isInValid, param.name);
            }}
            name={param.name}
            label={param.label}
            required={param.required}
            value={param.value}
            placeholder={param.placeholder}
            width={param.width}
            isMulti={false}
            appParams={widgetParams}
          />
        );
      case locationSelector:
        return (
          <LocationSelector
            onChange={(value) => {
              appInputChanged(value, null, param.name);
            }}
            value={param.value}
            name={param.name}
          />
        );
      case filler:
        return (
          <Filler
            key={index}
            className={param.className}
            text={param.value}
            width={param.width}
          />
        );
      case formulaMaker:
        return (
          <FormulaMaker
            onChange={(value, isInValid) => {
              appInputChanged(
                value,
                isInValid,
                param.name,
                isInValid && 'Invalid formula'
              );
            }}
            value={param.value}
            name={param.name}
          />
        );

      case genericConfig:
        return (
          <GenericConfig
            paramConfigVisiblityToggle={true}
            unitVisiblityToggle={true}
            value={(() => {
              const value = param.value;

              return !_.isBoolean(value.isComputed)
                ? {
                    ...param.value,
                    isComputed: true
                  }
                : value;
            })()}
            name={param.name}
            resetTriggerFlag={
              widgetConfig.type === 'Dial' &&
              _widgetParamsRef.current &&
              _widgetParamsRef.current.parameterRadio &&
              _widgetParamsRef.current.parameterRadio.value !==
                widgetParams.parameterRadio.value
            }
            onChange={(value, isInValid) => {
              appInputChanged(value, isInValid, param.name, isInValid && ' ');
            }}
          />
        );

      case locationBasedParamSelector:
        return (
          <LocationBasedParamSelector
            appInputParamChanged={appInputChanged}
            value={param.value}
            name={param.name}
            appParams={widgetParams}
            updateFormCount={updateFormCount}
          />
        );
      case locationBasedParamSelector:
        return (
          <LocationBasedParamSelector
            appInputParamChanged={appInputChanged}
            value={param.value}
            name={param.name}
            appParams={widgetParams}
            updateFormCount={updateFormCount}
          />
        );
      case customCheckbox:
        return (
          <Col
            xl={param.width || 12}
            lg={param.width || 12}
            md={param.width || 12}
            sm={param.width || 12}
            xs={param.width || 12}
          >
            <CheckBox
              checkboxClicked={(isChecked) => {
                appInputChanged(isChecked, null, param.name);
                updateWidgetParams(param);
                checkIfCheckBoxReset(param);
              }}
              label={param.label}
              size='lg'
              checked={param.value}
            />
          </Col>
        );
      case customRadio:
        return (
          <Col
            xl={param.width || 12}
            lg={param.width || 12}
            md={param.width || 12}
            sm={param.width || 12}
            xs={param.width || 12}
          >
            <ButtonGroup
              noOfButtons={param.label.length}
              buttonType='link'
              type='radio'
              active={param.label.indexOf(param.value)}
              getButtonLableAtIndex={(i) => {
                return param.label[i];
              }}
              buttonClickAtIndex={(i) => {
                appInputChanged(param.label[i], null, param.name);
              }}
            ></ButtonGroup>
          </Col>
        );
      case locationAndParamSelect:
        return (
          <LocationAndParameterSelect
            label={param.label}
            value={param.value}
            required={param.required}
            onChange={(v, isInvalid) =>
              appInputChanged(v, isInvalid, param.name)
            }
          />
        );
      case sensorSelector:
        return (
          <SensorSelector
            label={param.label}
            value={param.value}
            onChange={(v, isInvalid) =>
              appInputChanged(v, isInvalid, param.name)
            }
            width={param.width}
          />
        );
      case numericInput:
        return (
          <NumericInput
            label={param.label}
            value={param.value}
            width={param.width}
            inputChanged={(value, isInValid) => {
              appInputChanged(value, isInValid, param.name);
            }}
            placeholder={param.placeholder}
            min={param.min}
            step={param.step}
            hint={param.hint}
            required={param.required}
          />
        );
      case readingtypeSelect:
        return (
          <ReadingTypeSelect
            sensorids={
              widgetParams.sensor ? widgetParams.sensor.value : widgetParams
            }
            label={param.label}
            value={param.value}
            width={param.width}
            onChange={(value, isInValid) => {
              appInputChanged(value, isInValid, param.name);
            }}
            placeholder={param.placeholder}
          />
        );
      case groupWidgetSelect:
        return (
          <WidgetSelect
            {...{
              ...param,
              ...[
                'systemInsights',
                'systemAssetInsights',
                'networkInsights',
                'systemAlerts',
                'showAllInsights'
              ].reduce((memo, key) => {
                return {
                  ...memo,
                  [key]: widgetParams?.[key]?.value
                };
              }, {})
            }}
            dashobardId={dashboardKey}
            onChange={(value, isInValid, paramName) => {
              appInputChanged(value, isInValid, paramName || param.name);
            }}
          />
        );
      case containerWidgetSelect:
        return (
          <ContainerWidgetSelect
            {...param}
            dashobardId={dashboardKey}
            onChange={(value, isInValid) => {
              appInputChanged(value, isInValid, param.name);
            }}
          />
        );
      case assetSensorParam:
        return (
          <AssetSensorParams
            label={param.label}
            value={param.value}
            width={param.width}
            onChange={(value, isInValid) => {
              appInputChanged(value, isInValid, param.name);
            }}
            placeholder={param.placeholder}
          />
        );
      case assetInsightParam:
        return (
          <InsightParamSelector
            asset_type={asset_type}
            label={param.label}
            value={param.value}
            width={param.width}
            onChange={(value, isInValid) => {
              appInputChanged(value, isInValid, param.name);
            }}
            placeholder={param.placeholder}
          />
        );
      case textEditorConfig:
        return (
          <TextEditorWidgetConfig
            label={param.label}
            value={param.value}
            width={param.width}
            onChange={(value, isInValid) => {
              appInputChanged(value, isInValid, param.name);
            }}
            placeholder={param.placeholder}
          />
        );
    }

    return false;
  }

  function getTabContentAtIndex(i) {
    switch (i) {
      case 0:
        let editDefaultParams = {};
        if (editMode) {
          editDefaultParams = {
            widget_name: widgetParams.widget_name
              ? widgetParams.widget_name.value
              : '',
            widget_description: widgetParams.widget_description
              ? widgetParams.widget_description.value
              : '',
            refresh_frequency: widgetParams.refresh_frequency
              ? widgetParams.refresh_frequency.value
              : ''
          };
        }

        if (configView === false)
          return 'Error while loading configuration file';
        if (configView === null) return 'Loading...';

        return (
          <Suspense fallback='Loading...'>
            <div className='widget-builder-params-container'>
              <DefaultWidgetParams
                {...editDefaultParams}
                defaultInputChanged={defaultInputChanged}
              />
              <hr />
              {
                <Row
                  className={
                    'widget-app-config ' +
                    (widgetConfig.className ? widgetConfig.className : '')
                  }
                >
                  {widgetConfig.widgetParams &&
                    widgetConfig.widgetParams.map((param, index) => {
                      const WidgetConfig = configView[param.componentName];

                      if (!WidgetConfig) {
                        if (editMode) {
                          param.value = widgetParams[param.name]
                            ? widgetParams[param.name].value
                            : '';
                        }

                        let commonInputField = getInputFieldForType(
                          {
                            ...param,
                            ...(widgetParams[param.name] || {})
                          },
                          index
                        );
                        if (commonInputField) {
                          return commonInputField;
                        } else {
                          console.error(
                            `can't find component for ${WidgetConfig}`
                          );

                          return null;
                        }
                      }

                      return (
                        <WidgetConfig
                          updateFormCount={updateFormCount}
                          key={index}
                          appParams={widgetParams}
                          appInputParamChanged={(
                            val,
                            isInValid,
                            paramName,
                            errorMessage
                          ) =>
                            appInputChanged(
                              val,
                              isInValid,
                              paramName ? paramName : param.name,
                              errorMessage
                            )
                          }
                          preview={{ count: previewCountConfig }}
                          activeTab={activeTab}
                          required={param.required}
                        />
                      );
                    })}
                </Row>
              }
            </div>
          </Suspense>
        );

      case 1:
        return (
          <WidgetConfigHistory
            dashboardId={dashboardKey}
            editWidgetId={routeEditWidgetState.id}
            className='vintage'
          />
        );
    }
  }

  function getParams() {
    return (
      <Tabs
        noJustified={true}
        noOfTabs={editMode ? TABS.length : 1}
        size='sm'
        getTabContentAtIndex={getTabContentAtIndex}
        getTabHeaderAtIndex={(i) => {
          return (
            <div className='widget-builder-header'>
              {!editMode && i == 0 ? TABS[0] : editMode && TABS[i]}
            </div>
          );
        }}
        vertical={false}
        tabClickedAtIndex={(index) => {
          setActiveTab(index);
        }}
        activeTabIndex={activeTab}
      />
    );
  }

  function getWidgetConfigurationSection() {
    return (
      <div className='widget-builder-configuration'>
        <Row>
          <Col className='widget-builder-configuration-preview' xl={4} lg={4}>
            <div className='preview-widget'>
              <span className='preview'>Preview</span>
              <div
                className='widget-preview-header-refresh'
                onClick={() => {
                  setPreviewCountConfig(previewCountConfig + 1);
                  // if(previewCountConfig == 0 || previewCountConfig == null) {
                  // setPreviewCountConfig(previewCountConfig + 1);
                  // }
                }}
              >
                <span>
                  <i className='fas fa-sync-alt'></i> Refresh Preview
                </span>
              </div>
            </div>
            {getPreview()}
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className='preview-message'>
                <span>
                  <i className='fa fa-info-circle' aria-hidden='true'></i> Click
                  on refresh preview to update preview
                </span>
              </div>
            </Col>
          </Col>
          {/* <Card> */}
          <Col className='widget-builder-configuration-params' xl={8} lg={8}>
            {getParams()}
          </Col>
          {/* </Card> */}
        </Row>
      </div>
    );
  }

  function renderModal() {
    return (
      <WidgetSelector
        allWidgetConfig={props.widgetConfig}
        isOpen={isOpen}
        openAddWidgetConfiguration={openAddWidgetConfiguration}
        setWigetSelected={setWigetSelected}
        closeModal={closeModal}
        selectedWidgetIndex={selectedWidgetIndex}
      />
    );
  }

  if (
    editMode &&
    props.loaded &&
    props.widgetConfig.length > 0 &&
    !widgetConfig
  ) {
    const { widget_id } = routeEditWidgetState;
    return (
      <pre>{`Access not provided ${JSON.stringify(widget_id, null, 2)}`}</pre>
    );
  }

  return props.loaded && props.widgetConfig.length > 0 && widgetConfig ? (
    <div className='widget-builder-box'>
      <div className='appWrapper dashboard-widget-builder'>
        <div className='appHeader'>
          {/* <div className="appTitle">{editMode ? <span><i className="fa fa-edit"></i> <span className='editTitle'>
              {`Edit Widget - ${widgetConfig.name}`} </span></span> : 'Add new widget'}</div> */}
          {isInlineWidgetBuilder() ? <props.inline.header /> : getHeader()}
        </div>
        <div className='appBody'>{getWidgetConfigurationSection()}</div>
        {renderModal()}
      </div>
    </div>
  ) : (
    <Loader loading={true} error={false} />
  );
}

const mapStateToProps = (state) => {
  let loaded = state.allWidgetConfig.success !== undefined;
  return {
    loaded: loaded,
    widgetConfig: mergeConfig(state.allWidgetConfig.data),
    createWidgetData: state.createWidget,
    editWidgetData: state.editWidget,
    updateWidgetData: state.updateDashboardWidget,
    deleteWidgetData: state.deleteWidget,
    addedTemplateWidget: state.addTemplateWidget,
    updateTemplateWidget: state.updateWidgetTemplate,
    deleteTemplateWidget: state.deleteWidgetOfTemplate,
    addDashboardData: state.addDashboardWidget,
    deleteDashboardData: state.deleteDashboardWidget,
    healthConfigData: state.healthConfigData.data,
    createSystemTemplateWidgetData: state.createSystemTemplateWidget,
    updateSystemTemplateWidgetData: state.updateSystemTemplateWidget,
    deleteSystemTemplateWidgetData: state.deleteSystemTemplateWidget
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllWidgetConfig,
    createWidget,
    resetCreateWidget,
    editWidget,
    resetEditWidget,
    resetUpdateDashboardWidget,
    resetDeleteDashboardWidget,
    resetAddDashboardWidget,
    deleteWidget,
    resetDeleteWidget,
    addTemplateWidget,
    updateWidgetTemplate,
    resetUpdateWidgetTemplate,
    deleteWidgetOfTemplate,
    resetAddWidgetTemplate,
    addDashboardWidget,
    updateDashboardWidget,
    deleteDashboardWidget,
    createHealthData,
    updateHealthData,
    createHealthDataReset,
    createSystemTemplateWidget,
    createSystemTemplateWidgetReset,
    updateSystemTemplateWidget,
    updateSystemTemplateWidgetReset,
    deleteSystemTemplateWidget
  })(WidgetBuilder)
);