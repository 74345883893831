import { ofType } from 'redux-observable';
import { mergeMap,map, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax';
import {of} from 'rxjs'
import { parse } from 'query-string'
import Services from '../../../common/utils/services';

import {
  GET_MEMBER_DATA,
  GET_MEMBER_DATA_FAILURE,
  GET_MEMBER_DATA_SUCCESS,
  GET_ROLE_DATA,
  GET_ROLE_DATA_SUCCESS,
  GET_ROLE_DATA_FAILURE,
  CREATE_ROLE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  CREATE_MEMBER,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAILURE,
  EDIT_ROLE,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,
 
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
 
  EDIT_MEMBER,
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_FAILURE,
  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,

  URL,
  ERROR,
  Token
} from '../constants'


// const headers = {Authorization: `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}` }

const accessToken = localStorage.getItem('smartsense.authToken') !== 'undefined' && JSON.parse(localStorage.getItem('smartsense.authToken'))

const getMemberDataSuccess = payload => ({ type: GET_MEMBER_DATA_SUCCESS, payload })

export const getMemberDataEpic = action$ => action$.pipe(
  ofType(GET_MEMBER_DATA),
  mergeMap(action => ajax.getJSON(`${URL}/api/role_and_user_mgmt/member/?request_service=${Services.getInstance().getServiceIdByName("User and Role Management")}`, {Authorization: `token:${accessToken}` }).pipe(
    map(response => getMemberDataSuccess(response)),
    catchError(error => of(
       {type: GET_MEMBER_DATA_FAILURE,payload: []},
       {type: ERROR, payload: error}
    ))
  ))
);


const getRoleDataSuccess = payload => ({ type: GET_ROLE_DATA_SUCCESS, payload })

export const getRoleDataEpic = action$ => action$.pipe(
  ofType(GET_ROLE_DATA),
  mergeMap(action => ajax.getJSON(`${URL}/api/role_and_user_mgmt/member_role/?request_service=${Services.getInstance().getServiceIdByName("User and Role Management")}`, {Authorization: `token:${accessToken}` }).pipe(
    map(response => getRoleDataSuccess(response)),
    catchError(error => of( 
       {type: GET_ROLE_DATA_FAILURE,payload: []},
       {type: ERROR, payload: error}))
    ))
);


const createRoleSuccess = payload => ({ type: CREATE_ROLE_SUCCESS, payload })

export const createRoleEpic = action$ => action$.pipe(
  ofType(CREATE_ROLE),
  mergeMap(action => ajax.post(`${URL}/api/role_and_user_mgmt/member_role/`, action.payload, {Authorization: `token:${accessToken}` }).pipe(
    map(response => createRoleSuccess(response)),
    catchError(error => of( 
       {type: CREATE_ROLE_FAILURE,payload: []},
       {type: ERROR, payload: error}))
  
  ))
);


const createMemberSuccess = payload => ({ type: CREATE_MEMBER_SUCCESS, payload })


export const createMemberEpic = action$ => action$.pipe(
  ofType(CREATE_MEMBER),
  mergeMap(action => ajax.post(`${URL}/api/role_and_user_mgmt/member/`, action.payload, {Authorization: `token:${accessToken}` }).pipe(
    map(response => createMemberSuccess(response)),
    catchError(error => of( 
       {type: CREATE_MEMBER_FAILURE, payload: []},
       {type: ERROR, payload: error}))
    ))
);


const editRoleSuccess = payload => ({ type: EDIT_ROLE_SUCCESS, payload })

export const editRoleEpic = action$ => action$.pipe(
  ofType(EDIT_ROLE),
  mergeMap(action => ajax.put(`${URL}/api/role_and_user_mgmt/member_role/`, action.payload, {Authorization: `token:${accessToken}` }).pipe(
    map(response => editRoleSuccess(response)),
    catchError(error => of( 
       {type: EDIT_ROLE_FAILURE, payload: []},
       {type: ERROR, payload: error}
       ))
  
  ))
);


const deleteRoleSuccess = payload => ({ type: DELETE_ROLE_SUCCESS, payload })

export const deleteRoleEpic = action$ => action$.pipe(
  ofType(DELETE_ROLE),
  mergeMap(action => ajax.delete(`${URL}/api/role_and_user_mgmt/member_role/${action.payload.id}?request_service=${Services.getInstance().getServiceIdByName("User and Role Management")}`, {Authorization: `token: ${accessToken}` }).pipe(
    map(response => deleteRoleSuccess(response)),
    catchError(error => of( 
       {type: DELETE_ROLE_FAILURE, payload: []},
       {type: ERROR, payload: error}))
    ))
);


const editMemberSuccess = payload => ({ type: EDIT_MEMBER_SUCCESS, payload })

export const editMemberEpic = action$ => action$.pipe(
  ofType(EDIT_MEMBER),
  mergeMap(action => ajax.put(`${URL}/api/role_and_user_mgmt/member/${action.id}`, action.payload, {Authorization: `token: ${accessToken}` }).pipe(
    map(response => editMemberSuccess(response)),
    catchError(error => of( 
       {type: EDIT_MEMBER_FAILURE,payload: []},
       {type: ERROR, payload: error}))
    ))
);


const deleteMemberSuccess = payload => ({ type: DELETE_MEMBER_SUCCESS, payload })

export const deleteMemberEpic = action$ => action$.pipe(
  ofType(DELETE_MEMBER),
  mergeMap(action => ajax.delete(`${URL}/api/role_and_user_mgmt/member/${action.payload.id}?request_service=${Services.getInstance().getServiceIdByName("User and Role Management")}`, {Authorization: `token: ${accessToken}` }).pipe(
    map(response => deleteMemberSuccess(response)),
    catchError(error => of( 
       {type: DELETE_MEMBER_FAILURE,payload: []},
       {type: ERROR, payload: error}))
    
  ))
);

