export const GET_ALL_REPORTS = 'GET_ALL_REPORTS';
export const GET_ALL_REPORTS_SUCCESS = 'GET_ALL_REPORTS_SUCCESS';
export const GET_ALL_REPORTS_FAILURE = 'GET_ALL_REPORTS_FAILURE';

export const GET_ALL_REPORT_LOGS = 'GET_ALL_REPORT_LOGS';
export const GET_ALL_REPORT_LOGS_SUCCESS = 'GET_ALL_REPORT_LOGS_SUCCESS';
export const GET_ALL_REPORT_LOGS_FAILURE = 'GET_ALL_REPORT_LOGS_FAILURE';

export const CHECK_ALL_REPORT = 'CHECK_ALL_REPORT';
export const CHECK_ALL_REPORT_SUCCESS = 'CHECK_ALL_REPORT_SUCCESS';
export const CHECK_ALL_REPORT_FAILURE = 'CHECK_ALL_REPORT_FAILURE';

export const CHECK_REPORT = 'CHECK_REPORT';
export const CHECK_REPORT_SUCCESS = 'CHECK_REPORT_SUCCESS';

export const CHECK_REPORT_LOG = 'CHECK_REPORT_LOG';
export const CHECK_REPORT_LOG_SUCCESS = 'CHECK_REPORT_LOG_SUCCESS';

export const GET_ALL_REPORT_BY_ID = 'GET_ALL_REPORT_BY_ID';
export const GET_ALL_REPORT_BY_ID_SUCCESS = 'GET_ALL_REPORT_BY_ID_SUCCESS';
export const GET_ALL_REPORT_BY_ID_FAILURE = 'GET_ALL_REPORT_BY_ID_FAILURE';

export const DELETE_REPORT = 'DELETE_REPORT';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_FAILURE = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_RESET = 'DELETE_REPORT_RESET';

export const CREATE_REPORT = 'CREATE_REPORT';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_FAILURE = 'CREATE_REPORT_FAILURE';
export const CREATE_REPORT_RESET = 'CREATE_REPORT_RESET';

export const UPDATE_REPORT = 'UPDATE_REPORT';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_FAILURE = 'UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_RESET = 'UPDATE_REPORT_RESET';

export const DOWNLOAD_REPORT_BY_ID = 'DOWNLOAD_REPORT_BY_ID';
export const DOWNLOAD_REPORT_BY_ID_SUCCESS = 'DOWNLOAD_REPORT_BY_ID_SUCCESS';
export const DOWNLOAD_REPORT_BY_ID_FAILURE = 'DOWNLOAD_REPORT_BY_ID_FAILURE';
export const DOWNLOAD_REPORT_BY_ID_RESET = 'DOWNLOAD_REPORT_BY_ID_RESET';

export const DOWNLOAD_REPORT_CUSTOM = 'DOWNLOAD_REPORT_CUSTOM';
export const DOWNLOAD_REPORT_CUSTOM_SUCCESS = 'DOWNLOAD_REPORT_CUSTOM_SUCCESS';
export const DOWNLOAD_REPORT_CUSTOM_FAILURE = 'DOWNLOAD_REPORT_CUSTOM_FAILURE';
export const DOWNLOAD_REPORT_CUSTOM_RESET = 'DOWNLOAD_REPORT_CUSTOM_RESET';