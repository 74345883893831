/**
 * All epics reqiured for widget 
 */
import Config from 'Config'
import {ofType} from "redux-observable";
import {mergeMap} from "rxjs/operators";
import {
  GET_ALERT_TYPE_SUMMARY
} from './constant';
import { httpMethodGetJSON } from '../../https';

const URL = Config.api_url;
const SUMMARY_URL = `${URL}/alerts/summary/`;

export const getAlertTypeSummary = (action$) =>
  action$.pipe(
    ofType(GET_ALERT_TYPE_SUMMARY),
    mergeMap((action) => {
      return httpMethodGetJSON(action, SUMMARY_URL);
    }),
  );