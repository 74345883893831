import React, { Component } from 'react';
import { Button, ButtonGroup as RSButtonGroup } from 'reactstrap';

class CustomButtonGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cSelected: Array.isArray(this.props.active) ? this.props.active : [],
            rSelected: this.props.active,
        };

        this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
        this.onCheckboxBtnClick = this.onCheckboxBtnClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.type === 'radio' && this.props.active !== nextProps.active) {
            this.setState({ rSelected: nextProps.active });
        } else {
            this.setState({ cSelected: Array.isArray(nextProps.active) ? nextProps.active : [] });
        }
    }

    onRadioBtnClick(rSelected) {
        this.setState({ rSelected });
        this.props.buttonClickAtIndex(rSelected);
    }

    onCheckboxBtnClick(selected) {
        const index = this.state.cSelected.indexOf(selected);
        if (index < 0) {
            this.state.cSelected.push(selected);
        } else {
            this.state.cSelected.splice(index, 1);
        }
        this.setState({ cSelected: [...this.state.cSelected] });
        this.props.buttonClickAtIndex(this.state.cSelected);
    }

    buttonClicked(i) {
        if (this.props.type === 'radio') {
            this.onRadioBtnClick(i);
        } else {
            this.onCheckboxBtnClick(i);
        }
    }

    render() {
        let buttons = [];
        let len = this.props.noOfButtons;

        for (let i = 0; i < len.length; i++) {
            buttons.push(
                <Button
                    color={this.props.buttonType}
                    onClick={() => {
                        this.buttonClicked(len[i]);
                    }}
                    size={this.props.size ? this.props.size : 'sm'}
                    className={`commonButtonGroup ${this.state.rSelected.label === len[i].label ? "active" : ""}`}
                    // disabled={this.props.loading && this.state.rSelected !== i}
                    // active={this.state.rSelected === i}
                    key={i}
                    active = {this.props.type !== 'radio' ? this.state.cSelected.includes(len[i]) :  this.state.rSelected.label === len[i].label }
                >   
                    <div id={`${this.state.rSelected.label === len[i].label ? "active" : ""}`}>
                        {this.props.getButtonLableAtIndex ? this.props.getButtonLableAtIndex(i) : i}
                    </div>
                </Button>,
            );
        }

        return (
            <RSButtonGroup vertical={this.props.vertical ? this.props.vertical : false}>
                {buttons}
            </RSButtonGroup>
        );
    }
}
export default CustomButtonGroup;