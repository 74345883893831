import React, { Component, useS } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware } from 'redux';
import { rootEpic } from './container/epics';
import { rootReducer } from './container/reducers';
import Main from './container/Main';
import { Provider } from 'react-redux';
import '../../assets/styles/main.scss';

const epicMiddleware = createEpicMiddleware();
export const store = createStore(rootReducer, applyMiddleware(epicMiddleware));
epicMiddleware.run(rootEpic);

class RootComponent extends Component {
    componentDidMount() {
        if (localStorage.getItem("theme")) {
            const theme = localStorage.getItem("theme");
            if (theme == "theme-light") {
                document.body.classList.remove("theme-dark")
                document.body.classList.add(theme)
            } else {
                document.body.classList.remove("theme-light")
                document.body.classList.add(theme)
            }
        }
    }
    // state = {
    //     darkTheme: true,
    // }
    // onThemeChange = (e) => {
    //     if (e.target.checked){
    //         document.body.classList.remove('theme-dark');
    //         document.body.classList.add('theme-light');
    //     }else{
    //         document.body.classList.remove('theme-light');
    //         document.body.classList.add('theme-dark');
    //     }
    // }
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <div>
                        {/* <label className="switch">
                            <input type="checkbox" onChange={this.onThemeChange} />
                            <span className="slider"></span>
                        </label> */}
                        <Main />
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default RootComponent;