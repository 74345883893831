import React, { Fragment } from 'react';

import { CheckBox } from '../../../../../../../common/components';

import useFormulaConfigComputed from '../common/useFormulaConfigComputed';
import ParameterConfigComputedCore from '../Common/ParameterConfigComputedCore';

const ParameterConfigComputed = (props) => {
  const [
    initialized,
    formulaConfActiveIndex,
    formulaConfParameterCollections,
    formulaConfPointerCollections,
    onFormulaConfParameterCollectionUpdateHandle,
    onFormulaConfPointerCollectionUpdateHandle,
    onFormulaConfResetHandle
  ] = useFormulaConfigComputed(props.formulaConfs, props.onFormulaConfsChange);

  const renderParameterConfigComputedCore = () => {
    return (
      <ParameterConfigComputedCore
        formulaConfActiveIndex={formulaConfActiveIndex}
        formulaConfParameterCollections={formulaConfParameterCollections}
        formulaConfPointerCollections={formulaConfPointerCollections}
        formulaConfs={props.formulaConfs}
        onFormulaConfParameterCollectionUpdateTrigger={
          onFormulaConfParameterCollectionUpdateHandle
        }
        onFormulaConfPointerCollectionUpdateTrigger={
          onFormulaConfPointerCollectionUpdateHandle
        }
        onFormulaConfCreateTrigger={() => {
          return null;
        }}
        onFormulaConfResetTrigger={onFormulaConfResetHandle}
      />
    );
  };

  const renderFn = () => {
    return (
      <Fragment>
        <div className='new-input-wrap row-checkbox-right'>
          <div className='new-custom-checkbox'>
            <CheckBox
              label={'Show Partial Calculation Result'}
              size='sm'
              checked={props.formulaConfs[0].is_partial}
              checkboxClicked={(is_partial) => {
                props.onFormulaConfsChange([
                  {
                    ...props.formulaConfs[0],
                    is_partial
                  }
                ]);
              }}
            />
          </div>
        </div>

        {renderParameterConfigComputedCore()}
      </Fragment>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfigComputed;
