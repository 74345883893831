const plantSensorConfOutputValidGet = (plantSensorConf) => {
  return plantSensorConf.sensorwise
    ? !!plantSensorConf.sensors[0].readingtypeid
    : !!plantSensorConf.sensors[0].readingtypeid[0].rt;
};

export default (plantSensorConfs) => {
  return plantSensorConfs.reduce((memo, plantSensorConf) => {
    return plantSensorConfOutputValidGet(plantSensorConf)
      ? [...(memo || []), plantSensorConf]
      : memo;
  }, null);
};
