export const INPUT_TYPES = {
  TEXT: 'text',
  EMAIL:'email',
  PASSWORD:'password',
  URL:'url',
  NUMBER:'number',
  DATE_TIME:'datetime',
  DATE:'date',
  TIME:'time',
  COLOR:'color',
  SEARCH:'search',
  SELECT:'select',
  SELECTVALUE:'selectvalue',
  SELECT_MULTI:'selectMulti',
  TEXTAREA:'textarea',
  FILE:'file',
  RADIO: 'radio',
  CHECKBOX:'checkbox'
}