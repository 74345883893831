import parameterConfInputGet from './parameterConfInputGet';

export default (parameterConfs) => {
  return parameterConfs
    ? parameterConfs.reduce(
        (
          memo,
          {
            alias,
            locParam: {
              location: { label: locationLabel, value: locationid } = {},
              readingtype: {
                label: readingtypeLabel,
                value: readingtypeid
              } = {}
            } = {},
            ...rest
          },
          index
        ) => {
          const value = {
            ...(() => {
              return alias ? { alias } : {};
            })(),
            index,
            parameter: {
              locationLabel,
              locationid,
              readingtypeLabel,
              readingtypeid
            },
            ...rest
          };

          return [...memo, value];
        },
        []
      )
    : [parameterConfInputGet()];
};
