import React, { Fragment, useEffect, useState } from 'react';

import paramTypeCollectionGet from '../common/paramTypeCollectionGet';
import parameterConfsInputGet from '../common/parameterConfsInputGet';
import formulaConfsInputGet from '../common/formulaConfsInputGet';
import ParameterConfigNormal from './ParameterConfigNormal';
import ParameterConfigComputed from './ParameterConfigComputed';
import { RadioButtons } from '../../../../../../../common/components';

const paramTypeCollection = paramTypeCollectionGet();

const paramTypeGet = (conf) => {
  return conf && conf.isComputed
    ? paramTypeCollection[1]
    : paramTypeCollection[0];
};

const parameterConfsGet = (conf) => {
  const parameterConfs = conf && !conf.isComputed && [conf];

  return parameterConfsInputGet(parameterConfs).reduce(
    (memo, parameterConf) => {
      return [
        ...memo,
        {
          ...parameterConf,
          isComputed: false
        }
      ];
    },
    []
  );
};

const formulaConfsGet = (conf) => {
  const formulaConfs = conf && conf.isComputed && [conf];

  return formulaConfsInputGet(formulaConfs).reduce((memo, formulaConf) => {
    return [
      ...memo,
      {
        ...formulaConf,
        isComputed: true
      }
    ];
  }, []);
};

const ParameterConfig = (props) => {
  const parameterConfs = parameterConfsGet(props.conf);

  const formulaConfs = formulaConfsGet(props.conf);

  const [paramType, setParamType] = useState(paramTypeGet(props.conf));

  const paramTypeActiveIndex = paramTypeCollection.findIndex(
    (_paramType) => _paramType === paramType
  );

  useEffect(() => {
    setParamType(paramTypeGet(props.conf));
  }, [props.conf]);

  const renderParameterConfigNormal = () => {
    return (
      <ParameterConfigNormal
        parameterConfs={parameterConfs}
        onParameterConfCollectionUpdateTrigger={props.onParameterConfsChange}
      />
    );
  };

  const renderParameterConfigComputed = () => {
    return (
      <ParameterConfigComputed
        formulaConfs={formulaConfs}
        onFormulaConfsChange={(formulaConfs) => {
          props.onFormulaConfsChange(formulaConfs[0]);
        }}
      />
    );
  };

  return (
    <Fragment>
      <div className='new-input-wrap mb-2'>
        <RadioButtons
          noOfButtons={2}
          getButtonLableAtIndex={(index) => {
            return paramTypeCollection[index];
          }}
          buttonClickAtIndex={(index) => {
            const paramType = paramTypeCollection[index];

            setParamType(paramType);
          }}
          active={paramTypeActiveIndex}
        />
      </div>

      {!paramTypeActiveIndex
        ? renderParameterConfigNormal()
        : renderParameterConfigComputed()}
    </Fragment>
  );
};

export default ParameterConfig;
