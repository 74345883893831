import React from 'react';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'
import Pagination from './pagination'
import moment from 'moment';
import  Loader from '../Loader';
import "./table.scss"
const ReactTableFixedColumns = withFixedColumns(ReactTable);
export default class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            pageNo: this.props.pageNo
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.data !== prevProps.data) {
            this.setState({
                data: this.props.data
            });
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.data !== nextProps.data) {
            return { data: nextProps.data };
        }

				if (prevState.pageNo !== nextProps.pageNo) {
					return { pageNo: nextProps.pageNo };
				}

        return prevState;
    }

    render() {
        let noOfColms = this.props.noOfColms;
        let columns = [];
        for (let i = 0; i < noOfColms; i++) {
            let headerCell = this.props.getHeaderAtIndex(i);
            let col = {
                'accessor': headerCell.getdataKey(),
                'Header': <div>{headerCell.getlabel()}</div>,
                width: headerCell.getColWidth(),
                id: i,
                Cell: info => {
                    let colIndex = info.column.colIndex;
                    let rowIndex = info.row._index;
                    const { getTableCellAtIndex } = this.props;
                    return (
                        typeof getTableCellAtIndex === 'function'
                            ? getTableCellAtIndex(rowIndex, colIndex, info.value, info.column.id)
                            : <div data-tableTitle={info.value}>{info.value}</div>
                    );
                },
                colIndex: i
            }
            if(headerCell.fixed){
                col.fixed = headerCell.fixed;
            }
            if(headerCell.disableSortBy){
                col.sortable = false
            }
            columns.push(col);
        }

        return (
            <div className={this.props.className + (typeof this.props.onClickNavigation === ' function' ? ' clickable' : ' notClickable')} >
                <div>
                    {this.props.data && Array.isArray(this.props.data) &&
                    <ReactTableFixedColumns
                        data={this.state.data}                     
                        activePage={this.state.pageNo}
                        pages={this.props.noOfPages ? parseInt(this.props.noOfPages) : 1}
                        columns={columns}
                        noDataText="No Data Available"
                        defaultPageSize={10}
                        pageSize={this.props.pageSize}
                        className="-striped -highlight common-table"
                        showPageJump={true}
                        showPagination={this.props.showPagination !== false}
                        showPaginationTop={true}
                        showPaginationBottom={false}
                        showPageSizeOptions={false}
                        filterable={false}
                        minRows={0}
                        PaginationComponent={Pagination}
                        filterTable={this.props.filterTable}
                        filterableColumns={[]}
                        filterQuery={this.props.filterQuery ? this.props.filterQuery : ''}
                        placeholder={this.props.placeholder ? this.props.placeholder : 'Search'}
                        hideSearch={this.props.hideSearch? this.props.hideSearch:false}
                        getTrProps={(state, rowInfo, colInfo) => {
                            if (this.props.trProperties) {
                                return this.props.trProperties(state, rowInfo, colInfo);
                            }
                            let todayDate = moment().format()
                            return {
                                onClick: () => {
                                    this.props.onClickNavigation ?
                                        this.props.onClickNavigation(rowInfo.original) :
                                        false;
                                },
                                // style: {
                                //     background: this.props.isAlertTable && rowInfo.original.is_paused && todayDate < moment(rowInfo.original.paused_duration).format() && rowInfo.original.enabled ? '#fff8e5' : this.props.isAlertTable && rowInfo.original.enabled ? '#DCEFEE' : this.props.isAlertTable ? '#FFF0F3' : this.props.allAlertLogs && rowInfo.original.alert_status == "acknowledge" ? "#e6faff" : this.props.allAlertLogs && rowInfo.original.alert_status == "resolved" ? "#dcefee" : this.props.allAlertLogs && rowInfo.original.alert_status == "generated" ? "#fff8e5" : this.props.allAlertLogs && rowInfo.original.alert_status == "ignore" ? "#ffedf0" : null,
                                // },
                            }
                        }}
                        onPageChange={function (iPageNo) {
                            let pageData = this.props.getDataForPageIndex(iPageNo + 1);
                            this.setState({
                                'data': pageData,
                                'pageNo': (iPageNo + 1)
                            });
                        }.bind(this)}
                    /> }
                </div>
            </div>
        );
    };
}