export const GET_NETWORK_DETAILS_DATA = 'GET_NETWORK_DETAILS_DATA';
export const GET_NETWORK_DETAILS_DATA_SUCCESS =
  'GET_NETWORK_DETAILS_DATA_SUCCESS';
export const GET_NETWORK_DETAILS_DATA_FAILURE =
  'GET_NETWORK_DETAILS_DATA_FAILURE';
export const GET_NETWORK_DETAILS_DATA_RESET = 'GET_NETWORK_DETAILS_DATA_RESET';

export const DELETE_NETWORK_NODE = 'DELETE_NETWORK_NODE';
export const DELETE_NETWORK_NODE_SUCCESS = 'DELETE_NETWORK_NODE_SUCCESS';
export const DELETE_NETWORK_NODE_FAILURE = 'DELETE_NETWORK_NODE_FAILURE';
export const DELETE_NETWORK_NODE_RESET = 'DELETE_NETWORK_NODE_RESET';

export const UPDATE_NETWORK_NODE = 'UPDATE_NETWORK_NODE';
export const UPDATE_NETWORK_NODE_SUCCESS = 'UPDATE_NETWORK_NODE_SUCCESS';
export const UPDATE_NETWORK_NODE_FAILURE = 'UPDATE_NETWORK_NODE_FAILURE';
export const UPDATE_NETWORK_NODE_RESET = 'UPDATE_NETWORK_NODE_RESET';

export const GET_NETWORK_PARAMETERS = 'GET_NETWORK_PARAMETERS';
export const GET_NETWORK_PARAMETERS_SUCCESS = 'GET_NETWORK_PARAMETERS_SUCCESS';
export const GET_NETWORK_PARAMETERS_FAILURE = 'GET_NETWORK_PARAMETERS_FAILURE';

export const GET_NODE_PARAMETERS = 'GET_NODE_PARAMETERS';
export const GET_NODE_PARAMETERS_SUCCESS = 'GET_NODE_PARAMETERS_SUCCESS';
export const GET_NODE_PARAMETERS_FAILURE = 'GET_NODE_PARAMETERS_FAILURE';
export const GET_NODE_PARAMETERS_RESET = 'GET_NODE_PARAMETERS_RESET';

export const GET_NODE_CONFIG = 'GET_NODE_CONFIG';
export const GET_NODE_CONFIG_SUCCESS = 'GET_NODE_CONFIG_SUCCESS';
export const GET_NODE_CONFIG_FAILURE = 'GET_NODE_CONFIG_FAILURE';
export const GET_NODE_CONFIG_RESET = 'GET_NODE_CONFIG_RESET';

export const CREATE_NODE_PARAMETERS = 'CREATE_NODE_PARAMETERS';
export const CREATE_NODE_PARAMETERS_SUCCESS = 'CREATE_NODE_PARAMETERS_SUCCESS';
export const CREATE_NODE_PARAMETERS_FAILURE = 'CREATE_NODE_PARAMETERS_FAILURE';
export const CREATE_NODE_PARAMETERS_RESET = 'CREATE_NODE_PARAMETERS_RESET';

export const CREATE_NODE_CONFIG = 'CREATE_NODE_CONFIG';
export const CREATE_NODE_CONFIG_SUCCESS = 'CREATE_NODE_CONFIG_SUCCESS';
export const CREATE_NODE_CONFIG_FAILURE = 'CREATE_NODE_CONFIG_FAILURE';
export const CREATE_NODE_CONFIG_RESET = 'CREATE_NODE_CONFIG_RESET';

export const SET_NETWORK_MAIN_NODE = 'SET_NETWORK_MAIN_NODE';
export const SET_NETWORK_MAIN_NODE_SUCCESS = 'SET_NETWORK_MAIN_NODE_SUCCESS';
export const SET_NETWORK_MAIN_NODE_FAILURE = 'SET_NETWORK_MAIN_NODE_FAILURE';
export const SET_NETWORK_MAIN_NODE_RESET = 'SET_NETWORK_MAIN_NODE_RESET';

export const GET_NODE_SUBTREE = 'GET_NODE_SUBTREE';
export const GET_NODE_SUBTREE_SUCCESS = 'GET_NODE_SUBTREE_SUCCESS';
export const GET_NODE_SUBTREE_FAILURE = 'GET_NODE_SUBTREE_FAILURE';
export const GET_NODE_SUBTREE_RESET = 'GET_NODE_SUBTREE_RESET';

export const GET_NETWORK_NODE_LOCATION_CONFIG =
  'GET_NETWORK_NODE_LOCATION_CONFIG';
export const GET_NETWORK_NODE_LOCATION_CONFIG_SUCCESS =
  'GET_NETWORK_NODE_LOCATION_CONFIG_SUCCESS';
export const GET_NETWORK_NODE_LOCATION_CONFIG_FAILURE =
  'GET_NETWORK_NODE_LOCATION_CONFIG_FAILURE';
export const GET_NETWORK_NODE_LOCATION_CONFIG_RESET =
  'GET_NETWORK_NODE_LOCATION_CONFIG_RESET';

export const UPDATE_NETWORK_NODE_LOCATION_CONFIG =
  'UPDATE_NETWORK_NODE_LOCATION_CONFIG';
export const UPDATE_NETWORK_NODE_LOCATION_CONFIG_SUCCESS =
  'UPDATE_NETWORK_NODE_LOCATION_CONFIG_SUCCESS';
export const UPDATE_NETWORK_NODE_LOCATION_CONFIG_FAILURE =
  'UPDATE_NETWORK_NODE_LOCATION_CONFIG_FAILURE';
export const UPDATE_NETWORK_NODE_LOCATION_CONFIG_RESET =
  'UPDATE_NETWORK_NODE_LOCATION_CONFIG_RESET';

export const GET_NETWORK_ASSET_CBM_ASSET = 'GET_NETWORK_ASSET_CBM_ASSET';
export const GET_NETWORK_ASSET_CBM_ASSET_SUCCESS =
  'GET_NETWORK_ASSET_CBM_ASSET_SUCCESS';
export const GET_NETWORK_ASSET_CBM_ASSET_FAILURE =
  'GET_NETWORK_ASSET_CBM_ASSET_FAILURE';
export const GET_NETWORK_ASSET_CBM_ASSET_RESET =
  'GET_NETWORK_ASSET_CBM_ASSET_RESET';

export const GET_NETWORK_ASSET_META = 'GET_NETWORK_ASSET_META';
export const GET_NETWORK_ASSET_META_SUCCESS = 'GET_NETWORK_ASSET_META_SUCCESS';
export const GET_NETWORK_ASSET_META_FAILURE = 'GET_NETWORK_ASSET_META_FAILURE';
export const GET_NETWORK_ASSET_META_RESET = 'GET_NETWORK_ASSET_META_RESET';

export const GET_NETWORK_INSIGHT_IMPACTED_NODES =
  'GET_NETWORK_INSIGHT_IMPACTED_NODES';
export const GET_NETWORK_INSIGHT_IMPACTED_NODES_SUCCESS =
  'GET_NETWORK_INSIGHT_IMPACTED_NODES_SUCCESS';
export const GET_NETWORK_INSIGHT_IMPACTED_NODES_FAILURE =
  'GET_NETWORK_INSIGHT_IMPACTED_NODES_FAILURE';
export const GET_NETWORK_INSIGHT_IMPACTED_NODES_RESET =
  'GET_NETWORK_INSIGHT_IMPACTED_NODES_RESET';
