import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import valueGet from '../common/valueGet';
import { Button, Input, Select } from '../../../../../../../common/components';
import widgetConfigParamGet from '../common/widgetConfigParamGet';

const thresholdGet = (threshold) => {
  const _threshold =
    threshold &&
    threshold.reduce((memo, _threshold) => {
      const value = Object.keys(_threshold).length && _threshold;

      return value ? [...(memo || []), value] : memo;
    }, null);

  return {
    threshold: valueGet(_threshold)
  };
};

const OthersConfig = (props) => {
  const dialTypeCollection = widgetConfigParamGet(
    props.widgetConfig,
    'dialType'
  ).options;

  const [initialized, setInitialized] = useState(false);

  const [lowerLimit, setLowerLimit] = useState();

  const [upperLimit, setUpperLimit] = useState();

  const [dialType, setDialType] = useState();

  const [thresholdCount, setThresholdCount] = useState();

  const [threshold, setThreshold] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setLowerLimit(conf && conf.lowerLimit && conf.lowerLimit.value);

      setUpperLimit(conf && conf.upperLimit && conf.upperLimit.value);

      setDialType(
        (conf && conf.dialType && conf.dialType.value) || dialTypeCollection[0]
      );

      setThresholdCount(
        (conf &&
          conf.threshold &&
          conf.threshold.value &&
          conf.threshold.value.length) ||
          1
      );

      setThreshold((conf && conf.threshold && conf.threshold.value) || [{}]);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams, dialTypeCollection]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      lowerLimit: valueGet(lowerLimit),
      upperLimit: valueGet(upperLimit),
      dialType: valueGet(dialType),
      ...thresholdGet(threshold),
      ...object
    };

    props.appInputParamChanged(value);
  };

  const renderThresholdSolidGauge = () => {
    return (
      <Row className='input-button-row'>
        <Col md={4}>
          {Array.from({ length: thresholdCount })
            .fill()
            .reduce((memo, _, index) => {
              const value = (threshold && threshold[index]) || {};

              return [...memo, value];
            }, [])
            .map((_threshold, index) => {
              return (
                <Fragment key={index}>
                  <div className='new-input-wrap'>
                    <label className='label-text'>Threshold {index + 1}</label>

                    <Input
                      size='sm'
                      label=''
                      type='input'
                      required={false}
                      placeholder='Enter Threshold Value '
                      value={_threshold.value || ''}
                      inputChanged={(value) => {
                        const _threshold = [
                          ...threshold.slice(0, index),
                          { value },
                          ...threshold.slice(index + 1)
                        ];

                        setThreshold(_threshold);

                        appInputParamChangedHandle(thresholdGet(_threshold));
                      }}
                    />
                  </div>
                </Fragment>
              );
            })}
        </Col>

        <Col md={4}>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Add Threshold'
            className='buttonFill'
            buttonClick={() => {
              setThresholdCount((thresholdCount) => {
                return thresholdCount + 1;
              });
            }}
          />
        </Col>
      </Row>
    );
  };

  const renderThresholdSpeedometer = () => {
    return (
      <Row className='input-button-row'>
        <Col md={9}>
          {Array.from({ length: thresholdCount })
            .fill()
            .reduce((memo, _, index) => {
              const value = (threshold && threshold[index]) || {};

              return [...memo, value];
            }, [])
            .map((_threshold, index) => {
              return (
                <Row key={index}>
                  <Col md={4} className='new-input-wrap'>
                    <label className='label-text'>From</label>

                    <Input
                      size='sm'
                      label=''
                      type='input'
                      required={false}
                      placeholder='Enter From Value '
                      value={_threshold.from || ''}
                      inputChanged={(from) => {
                        const __threshold = [
                          ...threshold.slice(0, index),
                          {
                            ..._threshold,
                            from
                          },
                          ...threshold.slice(index + 1)
                        ];

                        setThreshold(__threshold);

                        appInputParamChangedHandle(thresholdGet(__threshold));
                      }}
                    />
                  </Col>

                  <Col md={4} className='new-input-wrap'>
                    <label className='label-text'>To</label>

                    <Input
                      size='sm'
                      label=''
                      type='input'
                      required={false}
                      placeholder='Enter To Value '
                      value={_threshold.to || ''}
                      inputChanged={(to) => {
                        const __threshold = [
                          ...threshold.slice(0, index),
                          {
                            ..._threshold,
                            to
                          },
                          ...threshold.slice(index + 1)
                        ];

                        setThreshold(__threshold);

                        appInputParamChangedHandle(thresholdGet(__threshold));
                      }}
                    />
                  </Col>

                  <Col md={4} className='new-input-wrap'>
                    <label className='label-text'>Color</label>

                    <Input
                      size='sm'
                      label=''
                      type='color'
                      required={false}
                      placeholder='Enter Color Value '
                      value={_threshold.color || ''}
                      inputChanged={(color) => {
                        const __threshold = [
                          ...threshold.slice(0, index),
                          {
                            ..._threshold,
                            color
                          },
                          ...threshold.slice(index + 1)
                        ];

                        setThreshold(__threshold);

                        appInputParamChangedHandle(thresholdGet(__threshold));
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
        </Col>

        <Col md={3}>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Add Threshold'
            className='buttonFill'
            buttonClick={() => {
              setThresholdCount((thresholdCount) => {
                return thresholdCount + 1;
              });
            }}
          />
        </Col>
      </Row>
    );
  };

  const renderThreshold = () => {
    return dialType === 'Solid Gauge'
      ? renderThresholdSolidGauge()
      : renderThresholdSpeedometer();
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <div className='dial-option-wrap mg-b-30'>
          <p className='head-label-text mg-b-15'>Dial Option</p>
          <Row>
            <Col md={4}>
              <div className='new-input-wrap'>
                <label className='label-text'>Lower Limit</label>

                <Input
                  size='sm'
                  label=''
                  type='input'
                  required={false}
                  placeholder='Enter Lower Limit'
                  value={lowerLimit || ''}
                  inputChanged={(_lowerLimit) => {
                    setLowerLimit(_lowerLimit);

                    appInputParamChangedHandle({
                      lowerLimit: valueGet(lowerLimit)
                    });
                  }}
                />
              </div>
            </Col>

            <Col md={4}>
              <div className='new-input-wrap'>
                <label className='label-text'>Upper Limit</label>

                <Input
                  size='sm'
                  label=''
                  type='input'
                  required={false}
                  placeholder='Enter Upper Limit'
                  value={upperLimit || ''}
                  inputChanged={(_upperLimit) => {
                    setUpperLimit(_upperLimit);

                    appInputParamChangedHandle({
                      upperLimit: valueGet(upperLimit)
                    });
                  }}
                />
              </div>
            </Col>

            <Col md={4}>
              <div className='new-input-wrap'>
                <label className='label-text'>Dial Type</label>

                <Select
                  options={dialTypeCollection.reduce((memo, value) => {
                    return [
                      ...memo,
                      {
                        label: value,
                        value
                      }
                    ];
                  }, [])}
                  isMulti={false}
                  placeholder='Enter Dial Type'
                  value={(() => {
                    const value = dialType;

                    return {
                      label: value,
                      value
                    };
                  })()}
                  onChange={({ value }) => {
                    setDialType(value);

                    setThresholdCount(1);

                    const threshold = [{}];

                    setThreshold(threshold);

                    appInputParamChangedHandle({
                      dialType: valueGet(value),
                      threshold
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className='threshold-wrap'>
          <p className='head-label-text mg-b-15'>Threshold</p>

          {renderThreshold()}
        </div>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />
          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default OthersConfig;
