export const GET_ASSET_CBM_TOP_CONTRIBUTORS = 'GET_ASSET_CBM_TOP_CONTRIBUTORS';
export const GET_ASSET_CBM_TOP_CONTRIBUTORS_SUCCESS =
  'GET_ASSET_CBM_TOP_CONTRIBUTORS_SUCCESS';
export const GET_ASSET_CBM_TOP_CONTRIBUTORS_FAILURE =
  'GET_ASSET_CBM_TOP_CONTRIBUTORS_FAILURE';

export const GET_INSIGHTS_BY_PLANT_ID = 'GET_INSIGHTS_BY_PLANT_ID';
export const GET_INSIGHTS_BY_PLANT_ID_SUCCESS =
  'GET_INSIGHTS_BY_PLANT_ID_SUCCESS';
export const GET_INSIGHTS_BY_PLANT_ID_FAILURE =
  'GET_INSIGHTS_BY_PLANT_ID_FAILURE';

export const GET_SYSTEM_TOP_CONTRIBUTORS = 'GET_SYSTEM_TOP_CONTRIBUTORS';
export const GET_SYSTEM_TOP_CONTRIBUTORS_SUCCESS =
  'GET_SYSTEM_TOP_CONTRIBUTORS_SUCCESS';
export const GET_SYSTEM_TOP_CONTRIBUTORS_FAILURE =
  'GET_SYSTEM_TOP_CONTRIBUTORS_FAILURE';
