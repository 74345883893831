import React, { Fragment } from 'react';

import { RadioButtons } from '../../../../../../../common/components';
import LocationParameterConfig from './LocationParameterConfig';
import TagParameterConfig from '../Common/TagParameterConfig';

const _ParameterConfig = (props) => {
  return (
    <Fragment>
      <div className='new-input-wrap'>
        <RadioButtons
          noOfButtons={2}
          getButtonLableAtIndex={(index) => {
            return !index ? 'standard' : 'tag';
          }}
          buttonClickAtIndex={(index) => {
            props.onIsParamConfigTypeTagChange(!!index);
          }}
          active={!props.isParamConfigTypeTag ? 0 : 1}
        />
      </div>

      <hr className='horizontal-divider mx-0 mg-t-10' />

      {!props.isParamConfigTypeTag ? (
        <LocationParameterConfig {...props} />
      ) : (
        <TagParameterConfig {...props} />
      )}
    </Fragment>
  );
};

export default _ParameterConfig;
