import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { httpMethodGetJSON } from '../../../../https';
import { URL } from '../../../../constants';
import {
  GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA,
  GET_ASSET_DETAIL_LIST_ASSETS_DATA,
  GET_ASSET_CBM_ASSET_NAMES_MEMBER,
  GET_SYSTEM_DETAIL_LIST
} from './constant';

export const getAssetDetailListCbmAssetData = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/asset-names/stats?page_size=100&page_no=1`
      );
    })
  );

export const getAssetDetailListAssetsData = (action$) => {
  return action$.pipe(
    ofType(GET_ASSET_DETAIL_LIST_ASSETS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/asset-names/${action.payload.asset_name}/assets/?${action.payload.searchQuery}`
      );
    })
  );
};

export const getAssetCbmAssetNamesMember = (action$) => {
  return action$.pipe(
    ofType(GET_ASSET_CBM_ASSET_NAMES_MEMBER),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/asset-names/member/`);
    })
  );
};

export const getSystemDetailList = (action$) => {
  return action$.pipe(
    ofType(GET_SYSTEM_DETAIL_LIST),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/system-cbm/system-names/systems`
      );
    })
  );
};