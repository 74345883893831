import * as AppConstants from './constant';

export const getSystemAssetsStatus = (
  state = {
    data: undefined,
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_SYSTEM_ASSETS_STATUS:
      return {
        ...state,
        loading: true
      };

    case AppConstants.GET_SYSTEM_ASSETS_STATUS_SUCCESS:
      return {
        ...state,
        data: action.response,
        loading: false,
        success: true
      };

    case AppConstants.GET_SYSTEM_ASSETS_STATUS_FAILURE:
      return {
        ...state,
        data: undefined,
        loading: false,
        success: false,
        error: true
      };

    case AppConstants.GET_SYSTEM_ASSETS_STATUS_RESET:
      return {
        ...state,
        data: undefined,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
};
