import React from 'react';
import { Row, Col } from 'reactstrap';

import useSensorParameterCollection from '../common/useSensorParameterCollection';
import SensorParameterSelect from '../Common/SensorParameterSelect';

const ParameterConfigNormal = (props) => {
  const [
    sensorCollection,
    sensorOptions,
    parameterCollection,
    parameterOptionsCollection,
    onSensorChangeHandle,
    onParameterChangeHandle
  ] = useSensorParameterCollection(props.parameterConfs);

  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Location *</label>

              <SensorParameterSelect
                type='sensor'
                options={sensorOptions}
                value={sensorCollection[0] || ''}
                onChange={(sensor) => {
                  onSensorChangeHandle(sensor, 0);
                }}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Parameter *</label>

              <SensorParameterSelect
                type='parameter'
                options={parameterOptionsCollection[0] || []}
                value={parameterCollection[0] || ''}
                onChange={(parameter) => {
                  onParameterChangeHandle(
                    parameter,
                    0,
                    props.onParameterConfCollectionUpdateTrigger
                  );
                }}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ParameterConfigNormal;
