import tagConfOutputGet from './tagConfOutputGet';

export default (conf) => {
  return {
    ...conf,
    formula: conf.formula.reduce((memo, _formula) => {
      const formulaGet = (formula) => {
        return formula.reduce((memo, _formula) => {
          const value = (() => {
            switch (_formula.type) {
              case 'parameter':
                return tagConfOutputGet(_formula.parameter);

              case 'formula':
                return {
                  ..._formula,
                  formula: formulaGet(_formula.formula)
                };

              default:
                return _formula;
            }
          })();

          return [...memo, value];
        }, []);
      };

      const value = (() => {
        switch (_formula.type) {
          case 'formula':
            return formulaGet(_formula.formula);

          case 'parameter':
            return [tagConfOutputGet(_formula.parameter)];

          default:
            return [_formula];
        }
      })();

      return [...memo, ...value];
    }, [])
  };
};
