import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../constants';
import {
  httpMethodDelete,
  httpMethodGetJSON,
  httpMethodPost,
  httpMethodPut
} from '../../../https';

import { CREATE_UPDATE_WORKING_NON_WORKING, GET_WORKING_NON_WORKING_HOUR_DETAILS } from "./constant";

export const createUpdateWorkingNonWorking = (action$) =>
  action$.pipe(
    ofType(CREATE_UPDATE_WORKING_NON_WORKING),
    mergeMap((action) => {
      return httpMethodPut(
        action,
        `${URL}/working-hour/plant/${action.payload.plant_id}/`
      );
    })
  );

  
export const getWorkingNonWorkingHourDetails = (action$) =>
  action$.pipe(
    ofType(GET_WORKING_NON_WORKING_HOUR_DETAILS),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/working-hour/plant/${action.payload.plant_id}/`
      );
    })
  );
