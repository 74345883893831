import moment from "moment";

export const getCBMAssetData =  (typeId) => {
    //if(isTemplateWidgetBuilder()) return;
    if(!typeId) return;
    const id = getAssetId();
    let data = JSON.parse(localStorage.getItem('template.asset_sensor_type_mapping'))
    let mapping = window.sensorMapping && window.sensorMapping[id] && window.sensorMapping[id].length && window.sensorMapping[id] || data || [];
    const f = Array.isArray(mapping) && mapping.filter((m) => {
        return m.assetId == id && m.type_id == typeId;
    });

    return f && f[0] ? f[0].sensor_id: undefined;
}

export const isTemplateWidgetBuilder = () => {
    const url = window.location.pathname;
    return url.indexOf('Templates/WidgetBuilder') >= 0;
}

const getAssetId = () => {
    const url = new URLSearchParams(window.location.search)
    return url && url.get('assetId');
}

export const getCBMContextSensorId = (typeId, sensorId, isCBM) => {
    if(isCBM && typeId){
        return getCBMAssetData(typeId) ? getCBMAssetData(typeId) : sensorId;
    }

    return sensorId;
}

export function getRequestBody(formula, equation, is_partial = false, pathname) {
    function flattenParams(arr) {
        let params = [];

        arr.map((elem) => {
            if (elem.type == "formula") {
                params.push(...flattenParams(elem.formula))
            }
            if (elem.type == "parameter") {
                let overRideSensorId;
                if(pathname){
                    overRideSensorId = getCBMAssetData(elem.parameter.sensor_type_id);
                    elem.parameter['sensorid'] = overRideSensorId;
                    params.push(elem);
                    //delete elem.parameter.locationid;
                    return;
                }

                if (pathname && elem.parameter && elem.parameter.sensorid && elem.parameter.locationid) {
                    overRideSensorId = getCBMAssetData(elem.parameter.sensor_type_id);
                    elem.parameter['sensorid'] = overRideSensorId;
                    //delete elem.parameter.locationid;
                }
                if (pathname && elem.parameter && !elem.parameter.sensorid) {
                    overRideSensorId = getCBMAssetData(elem.parameter.sensor_type_id);
                    elem.parameter['sensorid'] = overRideSensorId;
                    //delete elem.parameter.locationid
                }
                if (pathname && elem.parameter && !elem.parameter.sensorid) {
                    overRideSensorId = getCBMAssetData(elem.parameter.sensor_type_id);
                    elem.parameter['sensorid'] = overRideSensorId;
                    //delete elem.parameter.locationid
                }
                if(elem.parameter && elem.parameter.tag){
                    delete elem.parameter.tag
                }

                params.push(elem)
            }

            return elem;
        });

        return params.map((p, i) => {
            p.alphabet = alphabets[i];
            return p
        })
    }

    if (formula) {
        flattenParams(formula);
        let parameters = getParameters(formula, 'a', {}, pathname);

        let req = {
            computed_parameter: {
                is_partial,
                formula: formula,
                parameters,
                formulaText: equation.trim(),
                formulaToParse: getFormulaToParse(formula, parameters),
            },
        };

        return req;
    }
    return {};
}

export function getParameters(formula, char, p = {}, pathname) {
    formula &&
        formula.forEach((itm, index) => {
            if (itm.type === 'parameter') {
                if(pathname && itm.parameter.sensor_type_id){
                    itm.parameter['sensorid'] = getCBMAssetData(itm.parameter.sensor_type_id);
                }
                if (pathname && itm.parameter && itm.parameter.sensorid && itm.parameter.locationid) {
                    itm.parameter['sensorid'] = itm.parameter.locationid;
                    //delete itm.parameter.locationid
                }
                if (pathname && itm.parameter && !itm.parameter.sensorid) {
                    itm.parameter['sensorid'] = itm.parameter.locationid;
                    //delete itm.parameter.locationid
                }

                if (itm.alphabet == undefined) {
                    p['df.a'] = JSON.parse(JSON.stringify(itm.parameter));
                } else p['df.' + itm.alphabet] = JSON.parse(JSON.stringify(itm.parameter));

                if (itm.alphabet == undefined) {
                    delete p['df.a'].readingtypeLabel;
                    delete p['df.a'].locationLabel;
                } else {
                    delete p['df.' + itm.alphabet].readingtypeLabel;
                    delete p['df.' + itm.alphabet].locationLabel;
                }
                char = String.fromCharCode(char.charCodeAt() + 1);
            } else if (itm.type === 'formula') {

                let pr = getParameters(itm.formula, char, p, pathname);
                pr &&
                    Object.keys(pr).forEach((key) => {
                        p[key] = pr[key];
                    });
            }
        });
    return p;
}

const alphabets =  Array.from(Array(200).keys()).map((i) => "p"+i);

export function getFormulaToParse(formula, parameters) {
    let toParse = '';
    formula &&
        formula.forEach((item) => {
            switch (item.type) {
                case 'parameter':
                    toParse += Object.keys(parameters).find(
                        (key) =>
                            item.parameter.locationid == parameters[key].locationid &&
                            item.parameter.sensorid == parameters[key].sensorid &&
                            item.parameter.readingtypeid == parameters[key].readingtypeid,
                    );
                    break;
                case 'constant':
                    toParse += item.value;
                    break;
                case 'operator':
                    toParse += ' ' + item.value + ' ';
                    break;
                case 'formula':
                    toParse +=
                        '( ' + getFormulaToParse(item.formula, parameters) + ' ) ';
                    break;
            }
        });
    return toParse;
}

export function convertUnit(number, unit, decimal_l = 2) {
    var sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E'];
    if (number == null || typeof number == 'undefined') {
        return {
            value: '-',
            unit: unit,
            number: number,
        };
    }
    if (number == 0) {
        return {
            value: parseFloat(number).toFixed(decimal_l),
            unit: unit,
            number: number,
        };
    }
    if (unit.charAt(0).toLowerCase() == 'k') {
        unit = unit.substr(1);
        number = number * 1000;
    } else {
        return {
            value: parseFloat(number).toFixed(decimal_l),
            unit: unit,
            number: number,
        };
    }
    var sign = Math.sign(number);
    var n = Math.abs(number);

    var i = parseInt(Math.floor(Math.log(n) / Math.log(1000)));
    if (i > -1) {
        if (typeof sizes[i] != 'undefined') {
            return {
                value: sign * (n / Math.pow(1000, i)).toFixed(decimal_l),
                unit: sizes[i] + '' + unit,
                number: number,
            };
        } else {
            return {
                value: sign * (n / Math.pow(1000, i)).toFixed(decimal_l),
                unit: 'e+' + i * 3 + ' ' + unit,
                number: number,
            };
        }
    } else {
        return {
            value: sign * n.toFixed(decimal_l),
            unit: unit,
            number: number,
        };
    }
}

export function getDataFrequency(df) {
    if (df === "15 min") {
        return 15;
    } else if (df === "30 min") {
        return 30;
    } else if (df === "60 min") {
        return 60;
    } else if (df === "1 day") {
        return 1440;
    } else if (df === "Unaggregated") {
        return -1;
    } else {
        return 1440;
    }
}

export function getReadingtypeDecimalLimit(id, value) {
  if (!id) return (value && Number(value.toFixed(2))) || value;

  const res =
    localStorage.getItem('smartsense.readingtypes') &&
    Object.values(
      JSON.parse(localStorage.getItem('smartsense.readingtypes'))
    ).filter((loc) => loc.readingtypeid == id)[0];

  return Number(value && value.toFixed(res && res.decimal_limit)) || value;
}

export const numberFormatter = (num) => {
  // debugger
  let member =
    localStorage.getItem('smartsense.member') &&
    JSON.parse(localStorage.getItem('smartsense.member'));
  // We are taking country number format from preload API
  const formatter = new Intl.NumberFormat(
    member && member.number_format && member.number_format.country
      ? member.number_format.country
      : 'en-US'
  );

  return num && (num < 5 ? num : formatter.format(num));
};

export function getRequestBodyForCalParameter(formula, equation, is_partial = false, pathname) {
    function flattenParams(arr) {
        let params = [];

        arr.map((elem) => {
            if (elem.type == "formula") {
                params.push(...flattenParams(elem.formula))
            }
            if (elem.type == "parameter") {
                let overRideSensorId;
                if(pathname){
                    overRideSensorId = getCBMAssetData(elem.parameter.sensor_type_id);
                    elem.parameter['sensorid'] = overRideSensorId;
                    params.push(elem);
                    return;
                }

                if (pathname && elem.parameter && elem.parameter.sensorid && elem.parameter.locationid) {
                    overRideSensorId = getCBMAssetData(elem.parameter.sensor_type_id);
                    elem.parameter['sensorid'] = overRideSensorId;
                    delete elem.parameter.locationid;
                }
                if (pathname && elem.parameter && !elem.parameter.sensorid) {
                    overRideSensorId = getCBMAssetData(elem.parameter.sensor_type_id);
                    elem.parameter['sensorid'] = overRideSensorId;
                    delete elem.parameter.locationid
                }
                if (pathname && elem.parameter && !elem.parameter.sensorid) {
                    overRideSensorId = getCBMAssetData(elem.parameter.sensor_type_id);
                    elem.parameter['sensorid'] = overRideSensorId;
                    delete elem.parameter.locationid
                }

                params.push(elem)
            }

            return elem;
        });

        return params.map((p, i) => {
            p.alphabet = alphabets[i];
            return p
        })
    }

    if (formula) {
        flattenParams(formula);
        let parameters = getCalParameterParameters(formula, 'a', [], pathname);

        let req = {
            computed_parameter: {
                is_partial,
                formula: formula,
                parameters,
                formulaText: equation.trim(),
                formulaToParse: getFormulaToParseForCalParameter(formula, parameters),
            },
        };

        return req;
    }
    return {};
}

// Get required calculated parameters 
export function getCalParameterParameters(formula, char, p = [], pathname) {
    formula &&
        formula.forEach((itm, index) => {
            if (itm.type === 'parameter') {
                
                if (pathname && itm.parameter && itm.parameter.sensorid && itm.parameter.locationid) {
                    itm.parameter['sensorid'] = itm.parameter.locationid;
                    delete itm.parameter.locationid
                }
                if (pathname && itm.parameter && !itm.parameter.sensorid) {
                    itm.parameter['sensorid'] = itm.parameter.locationid;
                    delete itm.parameter.locationid
                }

                if (itm.alphabet == undefined) {
                    // p['df.a'] = JSON.parse(JSON.stringify(itm.parameter));
                    p.push({
                        type : itm.type,
                        'df.a' : JSON.parse(JSON.stringify(itm.parameter))
                    })
                } else {
                   
                    p.push({
                        type : itm.type,
                        [`df.${itm.alphabet}`] : JSON.parse(JSON.stringify(itm.parameter))
                    })
                    // p['df.' + itm.alphabet] = JSON.parse(JSON.stringify(itm.parameter));
                }
                if (itm.alphabet == undefined) {
                    p.forEach(each => {
                        if(each.hasOwnProperty('df.a')){
                            delete each['df.a']['readingtypeLabel'];
                            delete each['df.a']['locationLabel'];
                        }
                    });
                } else {
                    p.forEach(each => {
                        if(each.hasOwnProperty('df.' + itm.alphabet)){
                            delete each['df.' + itm.alphabet]['readingtypeLabel'];
                            delete each['df.' + itm.alphabet]['locationLabel'];
                            // each['df.' + itm.alphabet]['sensorid'] = each['df.' + itm.alphabet]['locationid']
                        }
                    });
                }
                char = String.fromCharCode(char.charCodeAt() + 1);
            } else if (itm.type === 'formula') {

                let pr = getCalParameterParameters(itm.formula, char, p, pathname);
                pr &&
                    Object.keys(pr).forEach((key) => {
                        p[key] = pr[key];
                    });
            }
        });
    return p;
}

// Get sensor_id by location_id
export function getSensorId(locationid) {
  const SENSORS =
    localStorage.getItem('smartsense.sensors') !== 'undefined'
      ? JSON.parse(localStorage.getItem('smartsense.sensors'))
      : [];

  if (SENSORS && Array.isArray(SENSORS) && SENSORS.length > 0) {
    const sensor = SENSORS.find((s) => s.mappedlocationid === locationid);
    return sensor ? sensor.sensorid : '';
  }
  return '';
}

export function getFormulaToParseForCalParameter(formula, parameters) {
    let toParse = '';
    formula &&
        formula.forEach((item) => {
            switch (item.type) {
                case 'parameter':
                    for (const parameter of parameters) {
                        const expectedKey = Object.keys(parameter).find(
                            (key) =>{
                                    return key !== "type" && item.parameter.locationid == parameter[key].locationid &&
                                    item.parameter.sensorid  == parameter[key].sensorid &&
                                    item.parameter.readingtypeid == parameter[key].readingtypeid;
                            }
                        );
                        if(expectedKey){
                            toParse += expectedKey;
                            break;
                        }
                    }
                    break;
                case 'constant':
                    toParse += item.value;
                    break;
                case 'operator':
                    toParse += ' ' + item.value + ' ';
                    break;
                case 'formula':
                    toParse +=
                        '( ' + getFormulaToParse(item.formula, parameters) + ' ) ';
                    break;
            }
        });
    return toParse;
}

export const openInsightGroupCollectionGet = (
  _openInsightCollection,
  dateRange,
  dateFormatString
) => {
  const openInsightGroupCollection = _openInsightCollection
    .reduce((memo, openInsight) => {
      const exists = memo.some(({ id }) => {
        return id === openInsight.id;
      });

      return !exists ? [...memo, openInsight] : memo;
    }, [])
    .reduce((memo, openInsight) => {
      const match = openInsight.time_stamp;

      return match
        ? [
            ...memo,
            {
              ...openInsight,
              start_date: match,
              end_date: match,
              meta: {
                ...openInsight.meta,
                start_date: match,
                end_date: match
              }
            }
          ]
        : [...memo, openInsight];
    }, [])
    .reduce((memo, openInsight) => {
      const match = (() => {
        const { start_date, end_date } = openInsight.meta || {};

        return !(start_date && end_date);
      })();

      return match
        ? [
            ...memo,
            ...(() => {
              const { start_date, end_date } = openInsight;

              return {
                ...openInsight,
                meta: {
                  ...openInsight.meta,
                  start_date,
                  end_date
                }
              };
            })()
          ]
        : [...memo, openInsight];
    }, [])
    .sort((a, b) => {
      return moment(a.meta.start_date).diff(moment(b.meta.start_date));
    })
    .reduce((memo, openInsight) => {
      const { start_date, end_date } = openInsight.meta;

      const value =
        moment(start_date).format(dateFormatString) !==
        moment(end_date).format(dateFormatString)
          ? Array.from({
              length:
                moment
                  .duration(
                    moment(end_date)
                      .startOf('days')
                      .diff(moment(start_date).startOf('days'))
                  )
                  .asDays() + 1
            }).reduce((_memo, _, index, collection) => {
              return [
                ..._memo,
                {
                  ...openInsight,
                  meta: {
                    ...openInsight.meta,
                    ...(() => {
                      switch (true) {
                        case !index:
                          return {
                            end_date: moment(start_date)
                              .endOf('days')
                              .utc()
                              .format()
                          };

                        case index === collection.length - 1:
                          return {
                            start_date: moment(end_date)
                              .startOf('days')
                              .utc()
                              .format()
                          };

                        default:
                          return (() => {
                            const date = moment(start_date).add(index, 'days');

                            return {
                              start_date: date.startOf('days'),
                              end_date: date.endOf('days')
                            };
                          })();
                      }
                    })()
                  }
                }
              ];
            }, [])
          : [openInsight];

      return [...memo, ...value];
    }, [])
    .reduce((memo, openInsight) => {
      const key = moment(openInsight.meta.start_date).format(dateFormatString);

      const match = dateRange.includes(key);

      return match
        ? {
            ...memo,
            [key]: {
              _collection: [
                ...((memo[key] && memo[key]._collection) || []),
                openInsight
              ],
              ...(() => {
                const collection = [
                  ...((memo[key] && memo[key].collection.slice(0, -1)) || []),
                  ...(() => {
                    const _memo =
                      memo[key] &&
                      memo[key].collection[memo[key].collection.length - 1];

                    const _startDate = openInsight.meta.start_date;

                    const _endDate = openInsight.meta.end_date;

                    const startDate = moment(_startDate);

                    const endDate = moment(_endDate);

                    const match =
                      _memo &&
                      (startDate.isBetween(
                        moment(_memo.start_date),
                        moment(_memo.end_date)
                      ) ||
                        startDate.isSame(moment(_memo.start_date)) ||
                        startDate.isSame(moment(_memo.end_date)));

                    return match
                      ? [
                          {
                            ..._memo,
                            end_date: moment
                              .max(moment(_memo.end_date), endDate)
                              .format(),
                            _collection: [
                              ...(_memo._collection || []),
                              openInsight
                            ]
                          }
                        ]
                      : [
                          ...(_memo ? [_memo] : []),
                          {
                            start_date: startDate.format(),
                            end_date: endDate.format(),
                            _collection: [openInsight]
                          }
                        ];
                  })()
                ];

                const totalRedlineMinutes = Math.floor(
                  collection.reduce((memo, { start_date, end_date }) => {
                    return (
                      memo + moment(end_date).diff(moment(start_date), 'm')
                    );
                  }, 0)
                );

                const totalRedlineMinutesPercentage = Math.min(
                  Math.ceil(
                    (totalRedlineMinutes /
                      (moment().format(dateFormatString) === key
                        ? moment()
                        : moment(collection[0].start_date).endOf('day')
                      ).diff(
                        moment(collection[0].start_date).startOf('day'),
                        'minute'
                      )) *
                      100
                  ),
                  100
                );

                return {
                  collection,
                  occurences: collection.length,
                  totalRedlineMinutes,
                  totalRedlineMinutesPercentage
                };
              })()
            }
          }
        : memo;
    }, {});

  const _openInsightGroupCollection = {
    data: openInsightGroupCollection,
    ...Object.values(openInsightGroupCollection).reduce(
      (memo, { _collection, occurences, totalRedlineMinutes, collection }) => {
        return {
          eventData: [
            ...memo.eventData,
            {
              ..._collection[0],
              occurences,
              collection
            }
          ],
          totalOccurences: memo.totalOccurences + occurences,
          ...(() => {
            const curTotaRedlinelHrs = totalRedlineMinutes / 60;

            const totalRedlineHours =
              Math.round(
                (memo.totalRedlineHours + curTotaRedlinelHrs + Number.EPSILON) *
                  100
              ) / 100; // Rounding to 2 decimal places

            const totalDays = ++memo.totalDays;

            const totalRedlineHoursPercentage = Math.ceil(
              (totalRedlineHours * 100) / (totalDays * 24)
            );

            return {
              totalRedlineHours,
              totalDays,
              totalRedlineHoursPercentage
            };
          })()
        };
      },
      {
        eventData: [],
        totalOccurences: 0,
        totalRedlineHours: 0,
        totalDays: 0
      }
    )
  };

  return _openInsightGroupCollection;
};