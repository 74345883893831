import {
  GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD,
  GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE
} from './constant';

export function getDashboardSidebarAllDashboard(value) {
  return {
    type: GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD,
    payload: value
  };
}

export const getDashboardSidebarDashboardSequence = (value) => {
  return {
    type: GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE,
    payload: value
  };
};