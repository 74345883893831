import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import {
  GET_ASSET_CBM_INSIGHT_OCCURRENCE,
  GET_NETWORK_INSIGHT_OCCURRENCE
} from './constant';
import { httpMethodGetJSON } from '../../../../https';
import { URL } from '../../../../constants';

export const getAssetCbmInsightOccurrence = (action$) => {
  return action$.pipe(
    ofType(GET_ASSET_CBM_INSIGHT_OCCURRENCE),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/insight-occurrence/`);
    })
  );
};


export const getNetworkInsightsOccurence = (action$) => {
  return action$.pipe(
    ofType(GET_NETWORK_INSIGHT_OCCURRENCE),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/network-insights/node_insight_count/${action.payload.node}/`);
    })
  );
}
