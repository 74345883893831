import React, { useEffect, useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import Loader from "../Loader";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import FactoryToolTip from "./FactoryToolTip";
const API_KEY = "AIzaSyC8MJ0ZbPh59Xa47rf_yaAJy_4z_oA4yVc";
import closeIcon from "../../../../assets/images/Icons/close.svg";
export default class GoogleMap1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      markers: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.config &&
      Array.isArray(nextProps.config.locations) &&
      nextProps.config.locations.length > 0
    ) {
      let markersWithOffset = {};
      let newMarkersWithOffset = [];
      let fixedLocationValue = nextProps.config.fixedLocationValue;

      newMarkersWithOffset = nextProps.config.locations.map((mark) => {
        if (markersWithOffset[`${mark.lat}_${mark.lng}`]) {
          // add offset then divide by 10 to make diffrence in fraction with actual
          mark.lat = fixedLocationValue
            ? mark.lat
            : mark.lat + parseFloat(Math.random().toFixed(3)) / 10;
          mark.lng = fixedLocationValue
            ? mark.lng
            : mark.lng + parseFloat(Math.random().toFixed(3)) / 10;

          markersWithOffset[`${mark.lat}_${mark.lng}`] = mark;
          return mark;
        }

        markersWithOffset[`${mark.lat}_${mark.lng}`] = mark;
        return mark;
      });

      prevState.markers = newMarkersWithOffset;
    } else {
      prevState.markers = [];
    }

    return prevState;
  }

  render() {
    return (
      <div className="google-map">
        <MapContainer
          config={this.props.config}
          markerClick={this.props.markerClick}
          onCloseTooltip={this.props.onCloseTooltip}
          zoom={this.props.zoom}
          locMarkers={this.state.markers}
          showDetailSummary={this.props.showDetailSummary}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?sensor=false&key=${API_KEY}`}
          loadingElement={
            <div style={{ height: `100%` }}>
              {/* <Loader height = '100%' loading = {true} /> */}
              Loading...
            </div>
          }
          containerElement={<div style={{ height: `100%`, width: `100%` }} />}
          mapElement={<div style={{ height: `100%`, width: `100%` }} />}
          factoryInfoWindowData={this.props.factoryInfoWindowData}
        />
      </div>
    );
  }
}

const MapContainer = withScriptjs(
  withGoogleMap((props) => {
    const [isOpen, setIsOpen] = useState(-1);
    const [isDarkTheme, setIsDarkTheme] = useState(
      localStorage.getItem("theme")
        ? localStorage.getItem("theme") === "theme-dark"
        : true
    );
    const themeColor = {
      "water":{
        dark: "#0A152C" ,
        light: "#F9FBFF"
      },
      "landscape":{
        dark: "#7786a5",
        light: "#d1e1ff"
      },
      "labels.text.fill":{
        dark: "#FFFFFF" ,
        light: "#124AB8",
      }
    }
    const [options, setOptions] = useState(props.config.mapOptions);

    const markers = props.locMarkers.map((mark, index) => (
      <MarkerContainer
        key={mark.uid}
        mark={mark}
        index={index}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        markerClick={props.markerClick}
        onCloseTooltip={props.onCloseTooltip}
        showDetailSummary={props.showDetailSummary}
        factoryInfoWindowData={props.factoryInfoWindowData}
      />
    ));

    const onClickHandle = (event) => {
      if (
        document.querySelector(".theme-toggler-wrap").contains(event.target)
      ) {
        setIsDarkTheme((preState) => !preState);
      }
    };

    useEffect(() => {
      document.addEventListener("click", onClickHandle);
      return () => document.removeEventListener("click", onClickHandle);
    }, []);

    useEffect(()=>{
      if(options && options.styles && options.styles.length ){
        let originalOptions = options;
        originalOptions.styles =  options.styles.map((item)=>{
          let key = isDarkTheme ? "dark":"light";
          if(["landscape", "water"].includes(item.featureType)){
            item.stylers[0].color = themeColor[item.featureType][key]
          }else if(["labels.text.fill"].includes(item.elementType)){
            item.stylers[0].color = themeColor[item.elementType][key]
          }
          return item
        })
        setOptions({...originalOptions})
      }

    },[isDarkTheme])

    return (
      <GoogleMap
        options={options}
        // defaultCenter={center}
        defaultCenter={{
          lat: props.locMarkers.length > 0 ? props.locMarkers[0].lat : 10.72833,
          lng: props.locMarkers.length > 0 ? props.locMarkers[0].lng : 121.525,
        }}
        defaultZoom={props.locMarkers && props.locMarkers.length == 1 ? 6 : props.zoom || 5}
        ref={(mapRef) => {
          mapRef &&
            props.locMarkers.length > 1 &&
            (() => {
              const bounds = new window.google.maps.LatLngBounds();

              props.locMarkers.map(({ lat, lng }) => {
                bounds.extend(new window.google.maps.LatLng(lat, lng));
              });

              mapRef.fitBounds(bounds);
            })();
        }}
      >
        <MarkerClusterer
          onClick={(markerClusterer) => {}}
          // imagePath="./path/to/clusters/m"
          averageCenter
          enableRetinaIcons
          gridSize={0}
        >
          {markers}
        </MarkerClusterer>
      </GoogleMap>
    );
  })
);

class MarkerContainer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Marker
        clickable={true}
        onClick={(e) => {
          this.props.markerClick && this.props.markerClick(this.props.mark);
          this.setState({ isOpen: this.props.setIsOpen(this.props.index) });
        }}
        position={{
          lat: this.props.mark.lat,
          lng: this.props.mark.lng,
        }}
        icon={this.props.mark.icon}
      >
        {this.props.isOpen === this.props.index &&
          this.props.mark.infoWindowContent &&
          this.props.mark.infoWindowContent.content && (
            <InfoWindow
              onCloseClick={() => {
                this.setState({ isOpen: !this.state.isOpen });
              }}
            >
              <div>
                <button
                  className="info-closeIcon"
                  onClick={() => {
                    this.props.onCloseTooltip && this.props.onCloseTooltip();
                    this.props.setIsOpen(-1);
                  }}
                >
                  <img src={closeIcon} />
                </button>
                <div class="info-window">
                  {/* {
                this.props.mark.factoryWindowContent && <FactoryToolTip showFactoryDetails={this.props.mark.factoryWindowContent.showFactoryDetails} />
              } */}

                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.props.mark.infoWindowContent.content,
                    }}
                  ></p>
                </div>
              </div>
            </InfoWindow>
          )}
        {this.props.isOpen === this.props.index && this.props.mark.factoryWindowContent && (
          <InfoWindow
          onCloseClick={() => {
            this.setState({ isOpen: !this.state.isOpen });
          }}
        >
          <div>
            <button
              className="info-closeIcon"
              onClick={() => {
                this.props.onCloseTooltip && this.props.onCloseTooltip();
                this.props.setIsOpen(-1);
              }}
            >
              <img src={closeIcon} alt="Close" />
            </button>
            <div className="info-window" style={{minWidth:'150px', width: '200px'}}>
              <FactoryToolTip
                lat={this.props.mark.lat}
                long={this.props.mark.lng}
                location={this.props.mark.location}
                widgetId={this.props.mark.widgetId}
                showDetailSummary={this.props.showDetailSummary}
                showFactoryDetails={
                  this.props.mark.factoryWindowContent.showFactoryDetails
                }
                locationId={this.props.mark.factoryWindowContent.locationId}
                factoryInfoWindowData={this.props.factoryInfoWindowData}
              />
            </div>
          </div>
        </InfoWindow>
        )}
      </Marker>
    );
  }
}