import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import { httpMethodGetJSON, httpMethodPatch, httpMethodDelete, httpMethodPost, } from '../https';
import axios from 'axios';
import { URL } from '../constants';
import { CREATE_ALERT, DELETE_ALERT, GET_ALERT_HISTORY_BY_ID, GET_ALERT_LOG_ALL, GET_ALERT_LOG_BY_ID, GET_ALL_ALERTS, UPDATE_ALERT, UPDATE_ALERT_LOG, CHECK_ALL_ALERT, CHECK_ALL_ALERT_SUCCESS, CHECK_ALERT, CHECK_ALERT_SUCCESS, DELETE_ALERT_SUCCESS, DELETE_ALERT_FAILURE, GET_ALERT_HISTORY_LOGS_BY_ID, GET_GENERATED_ALERT_SUMMARY, CHECK_ALERT_LOG, CHECK_ALERT_LOG_SUCCESS, UPDATE_ALERT_LOG_STATUS, DOWNLOAD_ALERT_LOGS, DOWNLOAD_ALERT_LOGS_FAILURE, DOWNLOAD_ALERT_LOGS_SUCCESS, GET_ALL_ALERTS_SUCCESS, GET_ALL_ALERTS_FAILURE, DELETE_ALERT_RESET, CHECK_ALL_ALERT_LOG_SUCCESS, CHECK_ALL_ALERT_LOG } from './constant';

export const getAllAlerts = action$ => action$.pipe(
  ofType(GET_ALL_ALERTS),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/alerts/`);
  })
);

export const checkAllAlert = action$ => action$.pipe(
  ofType(CHECK_ALL_ALERT),
  mergeMap(action => {
    return of({ type: CHECK_ALL_ALERT_SUCCESS, payload: action.payload });
  })
);

export const checkAlert = action$ => action$.pipe(
  ofType(CHECK_ALERT),
  mergeMap(action => {
    return of({ type: CHECK_ALERT_SUCCESS, payload: action.payload });
  })
);

export const getAlertLogAll = action$ => action$.pipe(
  ofType(GET_ALERT_LOG_ALL),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/alert-logs/`);
  })
);

export const checkAlertLog = action$ => action$.pipe(
  ofType(CHECK_ALERT_LOG),
  mergeMap(action => {
    return of({ type: CHECK_ALERT_LOG_SUCCESS, payload: action.payload });
  })
);

export const checkAllAlertLog = action$ => action$.pipe(
  ofType(CHECK_ALL_ALERT_LOG),
  mergeMap(action => {
    return of({ type: CHECK_ALL_ALERT_LOG_SUCCESS, payload: action.payload });
  })
);

export const getAlertLogById = action$ => action$.pipe(
  ofType(GET_ALERT_LOG_BY_ID),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/alert-logs/`);
  })
);

export const updateAlertLog = action$ => action$.pipe(
  ofType(UPDATE_ALERT_LOG),
  mergeMap(action => {
    return httpMethodPatch(action, `${URL}/alert-logs/${action.payload.id}`);
  })
);

export const updateAlertLogStatus = action$ => action$.pipe(
  ofType(UPDATE_ALERT_LOG_STATUS),
  mergeMap(action => {
    return httpMethodPatch(action, `${URL}/alert-logs/`);
  })
);

export const getAlertHistoryById = action$ => action$.pipe(
  ofType(GET_ALERT_HISTORY_BY_ID),
  mergeMap(action => {
    if (action.payload.alertHistorySearch) {
      return httpMethodGetJSON({ type: GET_ALERT_HISTORY_BY_ID, payload: { page_no: action.payload.page_no, name: action.payload.name } }, `${URL}/alerts/${action.payload.alert_id}/history/`);
    } else return httpMethodGetJSON({ type: GET_ALERT_HISTORY_BY_ID, payload: { page_no: action.payload.page_no, fromdate: action.payload.fromdate, todate: action.payload.todate } }, `${URL}/alerts/${action.payload.alert_id}/history/`);
  })
);

export const getAlertHistoryLogsById = action$ => action$.pipe(
  ofType(GET_ALERT_HISTORY_LOGS_BY_ID),
  mergeMap(action => {
    return httpMethodGetJSON({ type: GET_ALERT_HISTORY_LOGS_BY_ID }, `${URL}/alert-logs/${action.payload.id}/history/`);
  })
);

export const createAlert = action$ => action$.pipe(
  ofType(CREATE_ALERT),
  mergeMap(action => {
    return httpMethodPost(action, `${URL}/alerts/`);
  })
);

export const updateAlert = action$ => action$.pipe(
  ofType(UPDATE_ALERT),
  mergeMap(action => {
    if (action.payload && action.payload.alert_id) {
      return httpMethodPatch({ type: UPDATE_ALERT, payload: action.payload.apiParams }, `${URL}/alerts/${action.payload.alert_id}/`);
    } else {
      return httpMethodPatch(action, `${URL}/alerts/`);
    }
    // return httpMethodPatch(action, `${URL}/alerts/${action.payload.alert_id}`);
    // return httpMethodPatch(action, `${URL}/alerts/${action.payload.id}`);
  })
);

export const deleteAlert = action$ => action$.pipe(
  ofType(DELETE_ALERT),
  mergeMap((action, a) => {
    return axios.delete(`${URL}/alerts/`, {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
      },
      data: action.payload,
    }).then((response) => {
      return { type: DELETE_ALERT_SUCCESS, payload: response.data };
    }).catch(error => {
      return of({
        type: DELETE_ALERT_FAILURE,
        error: true,
        payload: error
      })
    })

  })
);

export const getGeneratedAlertSummary = action$ => action$.pipe(
  ofType(GET_GENERATED_ALERT_SUMMARY),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/alerts/status-summary/`);
  })
);

// export const downloadAlertLogs = action$ => action$.pipe(
//   ofType(DOWNLOAD_ALERT_LOGS),
//   mergeMap((action, a) => {
//     let searchQuery = getQueryString(action.payload);
//     let url = `${URL}/alert-logs/download/${searchQuery}`;
//     let token = `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}`;
//     axios
//       .request({
//         url,
//         method: "GET",
//         responseType: 'blob', 
//         headers: {
//           Authorization: token,
//         }
//       })
//       .then(({ data }) => {
//         const downloadUrl = window.URL.createObjectURL(new Blob([data]));
//         const link = document.createElement('a');
//         link.href = downloadUrl;
//         link.setAttribute('download', `smartsense.xlsx`);
//         //any other extension
//         document.body.appendChild(link);
//         link.click();
//         link.remove();
//       });


//     // let searchQuery = getQueryString(action.payload);
//     // return fetch(`${URL}/alert-logs/download/${searchQuery}`, {
//     //   headers: {
//     //     "Content-Type": "application/json; charset=UTF-8",
//     //     Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
//     //   },
//     // })
//     //   .then((response) => response.blob())
//     //   .then((blob) => {
//     //     debugger
//     //     // Create blob link to download
//     //     const url = window.URL.createObjectURL(
//     //       new Blob([blob]),
//     //     );
//     //     const link = document.createElement('a');
//     //     link.href = url;
//     //     link.setAttribute(
//     //       'download',
//     //       `smartsense.xlsx`,
//     //     );

//     //     // Append to html link element page
//     //     document.body.appendChild(link);

//     //     // Start download
//     //     link.click();

//     //     // Clean up and remove the link
//     //     link.parentNode.removeChild(link);

//     //     // var newBlob = new Blob([response.data], {type: 'application/excel'})
//     //     //   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//     //     //       window.navigator.msSaveOrOpenBlob(newBlob);
//     //     //       return;
//     //     //   }
//     //     // const url = window.URL.createObjectURL(new Blob([response.data],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
//     //     //   // var data = window.URL.createObjectURL(response.data);
//     //     //   var link = document.createElement('a');
//     //     //   link.href = url;
//     //     //   link.download = 'sample.xlsx';
//     //     //   setTimeout(function(){
//     //     //       link.click();
//     //     //       // Firefox, necessary to delay revoking the ObjectURL
//     //     //       window.URL.revokeObjectURL(url);
//     //     //   }, 100);
//     //     return { type: DOWNLOAD_ALERT_LOGS_SUCCESS, payload: response.data };
//     //   }).catch(error => {
//     //     return of({
//     //       type: DOWNLOAD_ALERT_LOGS_FAILURE,
//     //       error: true,
//     //       payload: error
//     //     })
//     //   })
//   })


// );

export const downloadAlertLogs = action$ => action$.pipe(
  ofType(DOWNLOAD_ALERT_LOGS),
  mergeMap(action => {
    if (action.payload && action.payload.alertId) {
      return httpMethodGetJSON({ type: DOWNLOAD_ALERT_LOGS, payload: action.payload.data }, `${URL}/alerts/${action.payload.alertId}/history/download/`);
    } else return httpMethodGetJSON(action, `${URL}/alert-logs/download/`);


  })
);

// export const getResetAllAlertData = action$ => action$.pipe(
//   ofType(DELETE_ALERT_RESET),
//   mergeMap((action, a) => {
//     return { type: DELETE_ALERT_SUCCESS, payload: action.payload };
//     //   return axios.get(`${URL}/alerts/`, {
//     //     headers: {
//     //       "Content-Type": "application/json; charset=UTF-8",
//     //       Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
//     //     },
//     //     data: action.payload,
//     //   }).then((response) => {
//     //     return { type: GET_ALL_ALERTS_SUCCESS, response: response.data };
//     //   }).catch(error => {
//     //     return of({
//     //       type: GET_ALL_ALERTS_FAILURE,
//     //       error: true,
//     //       payload: error
//     //     })
//     //   })

//   })
// );

export const alertEpics = {
  getAllAlerts,
  checkAllAlert,
  checkAlert,
  getAlertLogAll,
  getAlertLogById,
  getAlertHistoryById,
  createAlert,
  updateAlert,
  deleteAlert,
  updateAlertLog,

  getAlertHistoryLogsById,
  getGeneratedAlertSummary,

  checkAlertLog,
  updateAlertLogStatus,
  downloadAlertLogs,
  // getResetAllAlertData
}


const getQueryString = (payload = {}) => {
  let searchQuery = ''

  Object.keys(payload).forEach((key, i, arr) => {
    if (i === 0) {
      searchQuery = '?';
    }
    searchQuery += (key + '=' + payload[key]);
    if (i !== (arr.length - 1)) {
      searchQuery += '&'
    }
  });

  return searchQuery;
}