import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import {
  RadioButtons,
  CheckBox,
  Button
} from '../../../../../../../common/components';
import valueGet from '../common/valueGet';

const timelineTypeCollection = ['Alerts', 'Activities', 'Insights'];

const _timelineType = timelineTypeCollection[0];

const alertTypeCollection = ['user', 'system'];

const alertPriorityLevelCollection = ['high', 'medium', 'low'];

const alertStatusCollection = [
  'acknowledged',
  'resolved',
  'ignored',
  'generated'
];

const _alertConfiguration = {
  type: alertTypeCollection,
  priorityLevel: alertPriorityLevelCollection,
  status: alertStatusCollection
};

const activityTypeCollection = ['planned', 'unplanned'];

const _activityConfiguration = {
  type: activityTypeCollection
};

const insightStatusCollection = ['critical', 'non-critical'];

const _insightConfiguration = {
  status: insightStatusCollection
};

const wordCapitalizedGet = (word) => {
  return `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`;
};

const OthersConfig = (props) => {
  const [initialized, setInitialized] = useState();

  const [timelineType, setTimelineType] = useState();

  const [alertConfiguration, setAlertConfiguration] = useState();

  const [activityConfiguration, setActivityConfiguration] = useState();

  const [insightConfiguration, setInsightConfiguration] = useState();

  useEffect(() => {
    const conf = props.widgetParams;

    if (!initialized && props.widgetParams) {
      setTimelineType(conf.timelineType.value);

      setAlertConfiguration(conf.alertConfiguration.value);

      setActivityConfiguration(conf.activityConfiguration.value);

      setInsightConfiguration(conf.insightConfiguration.value);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const renderAlertConfiguration = () => {
    return (
      <div className='content-border-wrap pd-y-10 mg-b-15'>
        <div className='new-input-wrap'>
          <div>
            <label className='label-text'>Type</label>
          </div>

          <Row className='new-custom-checkbox'>
            {alertTypeCollection.map((type, index) => {
              return (
                <Col md={1} key={index}>
                  <CheckBox
                    size='sm'
                    label={wordCapitalizedGet(type)}
                    checkboxClicked={(active) => {
                      const _alertConfiguration = {
                        ...alertConfiguration,
                        type: active
                          ? [...alertConfiguration.type, type]
                          : alertConfiguration.type.filter(
                              (_type) => _type !== type
                            )
                      };

                      setAlertConfiguration(_alertConfiguration);

                      props.appInputParamChanged({
                        alertConfiguration: valueGet(_alertConfiguration)
                      });
                    }}
                    checked={(() => {
                      return alertConfiguration.type.find(
                        (_type) => _type === type
                      );
                    })()}
                  />
                </Col>
              );
            })}
          </Row>
        </div>

        <div className='new-input-wrap'>
          <div className='label-text'>
            <label>Priority Level</label>
          </div>

          <Row className='new-custom-checkbox'>
            {alertPriorityLevelCollection.map((priorityLevel, index) => {
              return (
                <Col md={1} key={index}>
                  <CheckBox
                    size='sm'
                    label={wordCapitalizedGet(priorityLevel)}
                    checkboxClicked={(active) => {
                      const _alertConfiguration = {
                        ...alertConfiguration,
                        priorityLevel: active
                          ? [...alertConfiguration.priorityLevel, priorityLevel]
                          : alertConfiguration.priorityLevel.filter(
                              (_priorityLevel) =>
                                _priorityLevel !== priorityLevel
                            )
                      };

                      setAlertConfiguration(_alertConfiguration);

                      props.appInputParamChanged({
                        alertConfiguration: valueGet(_alertConfiguration)
                      });
                    }}
                    checked={(() => {
                      return alertConfiguration.priorityLevel.find(
                        (_priorityLevel) => _priorityLevel === priorityLevel
                      );
                    })()}
                  />
                </Col>
              );
            })}
          </Row>
        </div>

        <div className='new-input-wrap'>
          <div>
            <label className='label-text'>Status</label>
          </div>

          <Row className='new-custom-checkbox'>
            {alertStatusCollection.map((status, index) => {
              return (
                <Col md={1} key={index}>
                  <CheckBox
                    size='sm'
                    label={wordCapitalizedGet(status)}
                    checkboxClicked={(active) => {
                      const _alertConfiguration = {
                        ...alertConfiguration,
                        status: active
                          ? [...alertConfiguration.status, status]
                          : alertConfiguration.status.filter(
                              (_status) => _status !== status
                            )
                      };

                      setAlertConfiguration(_alertConfiguration);

                      props.appInputParamChanged({
                        alertConfiguration: valueGet(_alertConfiguration)
                      });
                    }}
                    checked={(() => {
                      return alertConfiguration.status.find(
                        (_status) => _status === status
                      );
                    })()}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  };
  const renderActivityConfiguration = () => {
    return (
      <div className='content-border-wrap pd-y-10 mg-b-15'>
        <div className='new-input-wrap'>
          <div>
            <label className='label-text'>Type</label>
          </div>

          <Row className='new-custom-checkbox'>
            {activityTypeCollection.map((type, index) => {
              return (
                <Col md={1} key={index}>
                  <CheckBox
                    size='sm'
                    label={wordCapitalizedGet(type)}
                    checkboxClicked={(active) => {
                      const _activityConfiguration = {
                        ...activityConfiguration,
                        type: active
                          ? [...activityConfiguration.type, type]
                          : activityConfiguration.type.filter(
                              (_type) => _type !== type
                            )
                      };

                      setActivityConfiguration(_activityConfiguration);

                      props.appInputParamChanged({
                        activityConfiguration: valueGet(_activityConfiguration)
                      });
                    }}
                    checked={(() => {
                      return activityConfiguration.type.find(
                        (_type) => _type === type
                      );
                    })()}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  };

  const renderInsightConfiguration = () => {
    return (
      <div className='content-border-wrap pd-y-10 mg-b-15'>
        <div className='new-input-wrap'>
          <div>
            <label className='label-text'>Status</label>
          </div>

          <Row className='new-custom-checkbox'>
            {insightStatusCollection.map((status, index) => {
              return (
                <Col md={1} key={index}>
                  <CheckBox
                    size='sm'
                    label={wordCapitalizedGet(status)}
                    checkboxClicked={(active) => {
                      const _insightConfiguration = {
                        ...insightConfiguration,
                        status: active
                          ? [...insightConfiguration.status, status]
                          : insightConfiguration.status.filter(
                              (_status) => _status !== status
                            )
                      };

                      setInsightConfiguration(_insightConfiguration);

                      props.appInputParamChanged({
                        insightConfiguration: valueGet(_insightConfiguration)
                      });
                    }}
                    checked={(() => {
                      return insightConfiguration.status.find(
                        (_status) => _status === status
                      );
                    })()}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={12}>
            <div className='new-input-wrap'>
              <label className='label-text'>Timeline Type</label>

              <div className='radio-buttons-wrap'>
                <RadioButtons
                  noOfButtons={timelineTypeCollection.length}
                  getButtonLableAtIndex={(index) => {
                    return timelineTypeCollection[index];
                  }}
                  buttonClickAtIndex={(index) => {
                    const timelineType = timelineTypeCollection[index];

                    setTimelineType(timelineType);

                    props.appInputParamChanged({
                      timelineType: valueGet(timelineType)
                    });
                  }}
                  active={timelineTypeCollection.findIndex(
                    (_timelineType) => _timelineType === timelineType
                  )}
                />
              </div>
            </div>
          </Col>

          <Col md={12} key={timelineType}>
            {(() => {
              switch (timelineType) {
                case timelineTypeCollection[0]:
                  return renderAlertConfiguration();

                case timelineTypeCollection[1]:
                  return renderActivityConfiguration();

                case timelineTypeCollection[2]:
                  return renderInsightConfiguration();
              }
            })()}
          </Col>
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default OthersConfig;
