import React, { useRef, useState, useEffect } from 'react';
import { Input, Label, FormFeedback, CustomInput, Col } from 'reactstrap';
import { useLocation } from 'react-router';
import { isFormValid } from '../../utils/formValidation';
import { INPUT_TYPES } from './inputTypes'
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../Button';
import { unset } from 'lodash';

// const COLORS = ['#F6BE4C', '#EF8751', '#05C094', '#4949CF', '#59AE47', '#FF4949', '#A0A0FF', '#FFD2D2', '#E0A2A2', '#F05656', '#52CEBF'];
const STANDARDCOLORS = [{name: 'Primary Colors', colors: ['#071D49', '#0D3179', '#124AB8', '#4681F6', '#8BB2FF', '#D1E1FF']}, {name: 'System Colors',  colors: ['#FC0080', '#51B284', '#D82C60', '#F6BE4C']}, {name: 'Scope1', colors: ['#95CAEE', '#59E4F5', '#5C9EFF', '#72FFE6']}, {name: 'Scope2', colors: ['#9C7CC2', '#B18CFF', '#AE4CDD']}, {name: 'Scope3', colors: ['#EC598E', '#FF9AD7', '#F978B6', '#FF778F']}, {name: 'Data Visualization', colors: ['#EB3948', '#F66C41']} ];

const GENERALCOLORS = ['#59E4F5', '#4DCCF2', '#46BFF1', '#3DABEE', '#359DEC', '#2B89E9', '#247BE8', '#1C6BE5', '#145DE4', '#0A4AE1', 
                '#72FFE6', '#60EBDC', '#52DAD4', '#44CBCC', '#39BEC5', '#2DB0BE', '#20A1B7', '#1594B0', '#0886A9', '#0380A5', 
                '#5C9EFF', '#4A93FF', '#4384E6', '#3B76CC', '#3467B3', '#254A80', '#1E3B66', '#162C4C', '#0F1D33', '#070F19', 
                '#B18CFF', '#B996F0', '#C1A1DF', '#CAACCD', '#D3B7BC', '#DCC2A9', '#E4CD98', '#EDD886', '#F5E276', '#FEEE63', 
                '#AE4CDD', '#AC5ADC', '#AA6BDB', '#A77BDA', '#A58BD8', '#A29CD7', '#A0ABD5', '#9EBCD4', '#9BCCD3', '#99DCD1', 
                '#FF9AD7', '#FA8BD2', '#F37ACA', '#EC69C3', '#E557BC', '#DF46B5', '#D936AE', '#D223A7', '#CB14A1', '#C4019A', 
                '#F978B6', '#E96CB8', '#D75FBA', '#C451BB', '#B244BD', '#A037BE', '#8E29BF', '#7D1DC1', '#6C10C2', '#5A02C4', 
                '#FF778F', '#F88596', '#F0949D', '#E8A3A3', '#DFB2AA', '#D7C1B2', '#CED1B9', '#C7DFBF', '#BFEEC6', '#B6FECE', 
                '#FEF2E5', '#FBDFBE', '#F9CA93', '#F7B568', '#F5A547', '#F39527', '#F18D23', '#EF821D', '#ED7817', '#EA670E', 
                '#FAE6EC', '#F3C0CF', '#EC96B0', '#E46B90', '#DE4C78', '#D82C60', '#D42758', '#CE214E', '#C81B44', '#BF1033'];


function InputField(props) {
  const loc = window.location.pathname;
  const pathname = loc.split('/');

  const [inputValue, setInputValue] = useState('');
  const [isInValid, setIsInValid] = useState(false);
  const inputRef = useRef(null);
  const location = useLocation();

  const serviceId = (() => {
    if (location && location.state){
    return JSON.parse(localStorage.getItem('smartsense.services')).filter(
      ({ name }) => {
        return name === location.state.path;
      }
    )[0] && JSON.parse(localStorage.getItem('smartsense.services')).filter(
      ({ name }) => {
        return name === location.state.path;
      }
    )[0].id;
    }
  })();


  useEffect(() => {
    if (
      props.value &&
      props.value.length > 0
      // &&
      // props.value != inputValue
    ) {
      setInputValue(props.value);
      isValidChange(props.value);
      setColor(props.value);
    } else {
      setInputValue(props.value);
      setColor(props.value);
    }

  }, [props.value]);

  const onChange = (e) => {
    setInputValue(e.target.value);
    isValidChange(e.target.value);
  }

  const isValidChange = (value) => {
    let field = {};
    field[props.type] = value;

    if (props.required !== true) {
      props.inputChanged && props.inputChanged(value, null);
      return;
    }

    if (props.required === true && isFormValid(field, handleInvalidation)) {
      // TO DO : Find better way to do this in formValidation utils
       
      if (
        props.type == INPUT_TYPES.NUMBER &&
        value > props.max ||
        value < props.min
      ) {
        setIsInValid(true);
        // second param is isInValid
        props.inputChanged && props.inputChanged(value, true);
      } else {
        setIsInValid(false);
        // second param is isInValid
        props.inputChanged && props.inputChanged(value, false);
      }
    } else {
      setIsInValid(true);

      props.inputChanged && props.inputChanged(value, true);
    }
  }

  const handleInvalidation = (a) => {
    setIsInValid(true);
  }

  const keyPress = (e) => {
    if (e.keyCode == 13 || e.keyCode == 9) {
      setInputValue(e.target.value);
      props.onKeyDown(e.target.value);
    }
  }

  const [color, setColor] = useState('');

  const [colorCollectionShow, setColorCollectionShow] = useState(false);

  const [colorCollectionType, setColorCollectionType] = useState('Standard');

  const colorCollectionRender = (colors=null) => {
    return (
      <div className='d-flex flex-wrap individual-color'>
        {colors ?
                 colors.map((color) => (
                  <a
                    href='#'
                    key={color}
                    className='color-icon'
                    style={{ backgroundColor: color }}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
        
                      setColor(color);
                      setColorCollectionShow(false);
                      event.target.value = color;
                      onChange(event);
                    }}
                  ></a>         
                  
                )):
                <React.Fragment>
                 {colorCollectionType === 'Standard' &&
                     
                     STANDARDCOLORS.map((colorSet) => (
                    <div key={colorSet.name} className='d-flex flex-wrap flex-row color-group-items'>
                      <p>{colorSet.name}</p>           
                       {(colorSet.colors).map((color) => (
                      <div
                      href='#'
                      key={color}
                      className='color-icon'
                      style={{ backgroundColor: color }}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
          
                        setColor(color);
                        setColorCollectionShow(false);
                        event.target.value = color;
                        onChange(event);
                      }}
                    ></div>
                    ))}
                    </div>
                    
                  ))}
                  {colorCollectionType === 'General' &&
                               
                   GENERALCOLORS.map((color) => (
                    <a
                      href='#'
                      key={color}
                      className='color-icon'
                      style={{ backgroundColor: color }}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
          
                        setColor(color);
                        setColorCollectionShow(false);
                        event.target.value = color;
                        onChange(event);
                      }}
                    ></a>         
                    
                  ))}
                </React.Fragment>
        }

      </div>
    );
  };

  const onAwayClick = () => {
    setColorCollectionShow(false);
  }

  return (
    <Col 
      xl={props.width ? props.width : 12} 
      className={classnames('custom-padding ', { 'inline-input ': props.inline } ) + (props.className)}
      // style={{ marginLeft: 15 }}
    >
      {/* {
        props.type === INPUT_TYPES.COLOR ? 
          <datalist id="presetColors">
          {
            STANDARDCOLORS.map((color)=>{
              return <option value={color}/>
            })
          }
          </datalist>
        :
        null
      } */}
      <div className={'inputField ' + (props.required && isInValid ? 'invalid' : 'valid')}>
        {props.label && <Label for={props.name} className='label'>{props.label}
          {props.required === true ? <span className='text-danger'> * </span> : null}
        </Label>}
        {props.type != INPUT_TYPES.COLOR &&
        <Input
          bsSize={props.size}
          disabled={props.disabled}
          autoComplete="off"
          invalid={props.required && isInValid}
          ref={inputRef}
          name={props.name}
          step={props.step}
          value={inputValue}
          type={props.type === 'selectvalue' ? 'select' : props.type}
          onChange={onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyUp={props.onKeyUp}
          onKeyDown={keyPress}
          placeholder={props.placeholder}
          required={props.required}
          min={props.min}
          max={props.max}
          maxLength={props.maxLength}
          autoFocus={props.autoFocus}
          list={props.type == INPUT_TYPES.COLOR ? "presetColors" : undefined}
          maxLength={props.maxLength}
        >
          {
            props.type === INPUT_TYPES.SELECT
              ?
              <React.Fragment>
                <option value="" disabled={true}>{props.placeholder}</option>
                {/* {Array.isArray(props.options) && props.options.length > 0 && props.required !== true ? <option disabled={false} selected={true}>{props.placeholder}</option> : null} */}
                {
                  Array.isArray(props.options) ? props.options.map((opt, i) => {
                    if (i === 0) {
                      return (
                        <React.Fragment key={i}>
                          {!props.hideEmptyOption && <option key={i - 1} ></option>}
                          <option key={i} name={opt} value={opt}>{opt}</option>
                        </React.Fragment>
                      );

                    }
                    return (<option key={i} name={opt} value={opt}>{opt}</option>);
                  })
                    :
                    (
                      <React.Fragment>
                        {/* <option disabled={true} selected> {props.placeholder}</option> */}
                        <option disabled={true}> No option available </option>
                      </React.Fragment>
                    )
                }
              </React.Fragment>
              : null
          }
          {
            props.type === INPUT_TYPES.SELECTVALUE && <React.Fragment>
                {Array.isArray(props.optionsValues) && props.optionsValues.length > 0 && props.required !== true ? <option disabled={false} selected={true}>{props.placeholder}</option> : null}
                {
                  Array.isArray(props.optionsValues) ? props.optionsValues.map((opt, i) => {
                    if (i === 0) {
                      return (
                        <React.Fragment key={i}>
                          {!props.hideEmptyOption && <option key={i - 1} ></option>}
                          <option key={i} name={opt.name} value={opt.value}>{opt.name}</option>
                        </React.Fragment>
                      );

                    }
                    return (<option key={i} name={opt.name} value={opt.value}>{opt.name}</option>);
                  })
                    :
                    (
                      <React.Fragment>
                        <option disabled={true} selected> {props.placeholder}</option>
                        <option disabled={true}> No option available </option>
                      </React.Fragment>
                    )
                }
              </React.Fragment>
          }
        </Input>
        }
        {props.type === INPUT_TYPES.COLOR &&
        <div style={{ position: 'relative' }}>
        <Input
          type='color'
          value={color}
          onFocus={props.onFocus}
          // onBlur={onAwayClick}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
  
            setColorCollectionShow((colorCollectionShow) => !colorCollectionShow);
          }}
        />
        {colorCollectionShow && (
          <div
            className='d-flex flex-column color-pallette'
          >
            <div className={'d-flex btn-main ' + (colorCollectionType === 'Standard' ? 'btn-standard' : 'btn-general')}>
              {!props.colorList && <Button
                buttonClick={() => setColorCollectionType('Standard')}
                innerContent='Standard'
                className='color-btn standard'
              />}        
              {( (pathname && pathname[1] == 'Templates') || (serviceId && serviceId != 1)) &&        
              <Button
                buttonClick={() => setColorCollectionType('General')}
                innerContent='General'
                className='color-btn general'
              />             
              }   
            </div>  
            {colorCollectionRender(props.colorList ? props.colorList : null)}
          </div>
        )}
      </div>
        }
        {props.errorMessage && <span className='error-message text-danger'>{props.errorMessage}</span>}
        {props.hint && <span className="input-hint">{props.hint}</span>}
        <FormFeedback>{`This field is required and expects valid input`}</FormFeedback>
        {props.isValidAlias && <span className='text-danger' style={{ fontSize:'12px' }}>{"Alias name should be unique"}</span>}
      </div>
    </Col>
  );
}

InputField.defaultProps = {
  onFocus: function () { },
  onBlur: function () { },
  onKeyDown: function () { },
  onKeyUp: function () { },
  disabled: false
}

InputField.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  disabled: PropTypes.bool
}

export default InputField;