import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { LoginComponent } from './components';
import { loginAction, resetLogin } from './action';
import { withRouter } from 'react-router-dom';
import loginScreen from '../../../../assets/images/login-sidebar-img.png';
import CIIImage from '../../../../assets/images/login/CII.png';
import energey_analitics from '../../../../assets/images/login/energey_analitics.png';
import frostImage from '../../../../assets/images/login/frost.png';
import UNFCCCImage from '../../../../assets/images/login/UNFCCC.png';
import WFFImage from '../../../../assets/images/login/WFF.png';
import { Col, Row } from 'reactstrap';
import { Context } from '../../../admin/container/Main/context';
import axios from 'axios';
import Config from 'Config';
import { useLocation } from 'react-router-dom';
const LOGIN_URL = `${Config.api_url}/auth/login/`;
const VERIFY_OTP_URL = `${Config.api_url}/auth/verify_otp/`;
const RESEND_OTP_URL = `${Config.api_url}/auth/resend_otp/`;
const FORGOT_PASSWORD_URL = `${Config.api_url}/users/forget_password/`;
const RESET_PASSWORD_URL = `${Config.api_url}/users/reset_password/`;
const SSO_LOGIN_API = `${Config.api_url}/sso/url/`
const PRELOAD_URL_API = `${Config.api_url}/users/preload/`

// class Login extends Component {
//     constructor(props) {
//         super(props);
//         this.logIn = this.logIn.bind(this);
//     }

//     // componentDidUpdate(prevProps) {
//     //     if (
//     //         this.props.loginData !== prevProps.loginData &&
//     //         Object.keys(this.props.loginData.data).length > 0
//     //     ) {
//     //         let data = JSON.parse(localStorage.getItem('smartsense.member_service_permissions'));
//     //         let flag = false;
//     //         for (var i = 0; i < data.length; i++) {
//     //             if (data[i].service_name === 'My Dashboard') {
//     //                 flag = true;
//     //             }
//     //         }
//     //         if (flag) {
//     //             this.props.history.push('/Dashboard');
//     //             this.props.history.go(0)
//     //         } else {
//     //             this.props.history.push('/Permissions');
//     //         }
//     //     }
//     // }

//     // logIn(value) {
//     //     this.props.loginAction(value);
//     // }

//     // render() {
//     //     return (
//     //         <Row className='login-wrapper'>
//     //             <Col xl = {6}>
//     //                 <div className = 'login-left'>
//     //                     <div className = 'login-left-text'>
//     //                         <div>Empowers Enterprises with</div> 
//     //                         <div className = 'bold'>IoT powered</div> 
//     //                         <div>Asset Intelligence for enhancing</div> 
//     //                         <div className = 'bold'>Productivity!</div>
//     //                     </div>
//     //                     <img className = 'login-left-img' src={loginScreen} />
//     //                 </div>
//     //             </Col>
//     //             <Col xl = {6}>
//     //                 <div className="login float-right">
//     //                     <LoginComponent forgot={this.forgot} login={this.logIn} />
//     //                 </div>
//     //             </Col>
//     //             <div className = 'login-footer'>
//     //                 <div className = 'login-footer-item'>
//     //                    <div className = 'login-footer-item-img'>
//     //                        <img src ={energey_analitics} style={{marginTop: '22px'}}></img>
//     //                    </div>
//     //                    <div className = 'login-footer-item-text'>
//     //                         International Ashden Award
//     //                         for powering businesses with 
//     //                         Energy Analytics 2017
//     //                    </div>
//     //                 </div>

//     //                 <div className = 'login-footer-item'>
//     //                    <div className = 'login-footer-item-img'>
//     //                        <img src = {UNFCCCImage} style={{marginTop: '12px'}}></img>
//     //                    </div>
//     //                    <div className = 'login-footer-item-text'>
//     //                         UNFCCC 
//     //                         United Nations Climate Change
//     //                         Lighthouse Activity
//     //                    </div>
//     //                 </div>


//     //                 <div className = 'login-footer-item'>
//     //                    <div className = 'login-footer-item-img'>
//     //                        <img src = {frostImage} style={{marginTop: '12px'}}></img>
//     //                    </div>
//     //                    <div className = 'login-footer-item-text'>
//     //                         Frost & Sullivan  
//     //                         Energy Management Best 
//     //                         Practices - 2016
//     //                    </div>
//     //                 </div>

//     //                 <div className = 'login-footer-item'>
//     //                    <div className = 'login-footer-item-img'>
//     //                        <img src = {CIIImage} style={{marginTop: '7px'}}></img>
//     //                    </div>
//     //                    <div className = 'login-footer-item-text'>
//     //                         CII   
//     //                         Most Innovative Energy 
//     //                         Efficiency Product
//     //                    </div>
//     //                 </div>

//     //                 <div className = 'login-footer-item'>
//     //                    <div className = 'login-footer-item-img'>
//     //                        <img src = {WFFImage} style={{marginTop: '5px'}}></img>
//     //                    </div>
//     //                    <div className = 'login-footer-item-text'>
//     //                         WWF    
//     //                         Global Climate Solver 
//     //                         Award
//     //                    </div>
//     //                 </div>
//     //             </div>
//     //         </Row>

//     //     );
//     // }
// }

// const mapStateToProps = (state) => {
//     return {
//         loginData: state.login,
//     };
// };

// export default withRouter(connect(mapStateToProps, { loginAction })(Login));


function Login(props) {

    const context = useContext(Context);
    const [isLogin, setLogin] = useState(false);
    const [isTwoFactorAuth, setTwoFactorAuth] = useState(false);
    const [isForgotPassword, setisForgotPassword] = useState(false);
    const [isSsoLogin, setIsSsoLogin] = useState(false);
		const [resetToken, setResetToken] = useState(
      props.location.hash.includes('token=') &&
        props.location.hash.split('token=')[1]
    );
    const[error,setError]= useState("");
    const [loading, setLoading] = useState(false);



    const location = useLocation()

    function getAuthToken() {
      const hash = location.hash;
      const tokenMatch = hash.match(/auth_token=([^&]*)/);

      const authToken = tokenMatch ? tokenMatch[1] : null;
      return {
        hasAuthToken: !!authToken,
        authToken
      };
    }

    const { authToken, hasAuthToken } = getAuthToken();


    const getPreloadData = async () => {
      setLoading(true); // Start loader
      try {
        const response = await axios.get(PRELOAD_URL_API, {
          headers: {
            Authorization: `${authToken}`
          }
        });
  
        const data = response.data;
  
        if (data) {
          const {
            member,
            plants,
            member_locations,
            locations,
            member_service_permissions,
            services,
            member_default_plant,
            reading_types,
            msg_version_readingtypes,
            sensors,
            decode_alarm_mapping,
            service_config,
            roles,
            asset_categories
          } = data;
  
          const storageData = {
            admin_member: member,
            member_plants: plants,
            member_locations,
            locationlist: locations,
            member_service_permissions,
            member,
            member_default_plant,
            readingtypes: reading_types,
            version_readingtype_mapping: msg_version_readingtypes,
            roles,
            sensors,
            services,
            decode_alarm_mapping,
            service_config,
            asset_categories,
            authToken: authToken,
            isSsoUser: true
          };
  
          Object.entries(storageData).forEach(([key, value]) => {
            localStorage.setItem(`smartsense.${key}`, JSON.stringify(value));
          });

        }
  
      } catch (error) {
        let { status, data } = error.response;
        console.log('error message', status, data);
      } finally {
        setLoading(false); 
        window.location.reload();
      }
    };

    useEffect(() => {
      if (hasAuthToken) {
        getPreloadData();
      }
    }, [hasAuthToken]);

    useEffect(() => {
        if (
            props.loginData &&
            Object.keys(props.loginData.data).length > 0
        ) {
            let data = JSON.parse(localStorage.getItem('smartsense.services'));
            let flag = false;
            let home = false;
            for (var i = 0; i < data.length; i++) {
                if (data[i].url === '/home') {
                    home = true
                }
                if (data[i].url === '/Partner') {
                    flag = true;
                }
            }
            if (home) {
                props.history.push('/Home');
                props.history.go(0)
            }
            else if (flag) {
                // props.history.push('/PartnerLogin'); Partner login page redirection
                props.history.push('/Home');
                props.history.go(0)
            }
            else {
                localStorage.setItem('adminProfile', true);
                props.history.push('/Home');
                props.history.go(0)
            }

            // let data = JSON.parse(localStorage.getItem('smartsense.member_service_permissions'));
            // let flag = false;
            // for (var i = 0; i < data.length; i++) {
            //     if (data[i].service_name === 'My Dashboard') {
            //         flag = true;
            //     }
            // }
            // if (flag) { 
            // props.history.push('/Dashboard');
            // props.history.go(0)
            // } else {
            //     props.history.push('/Permissions');
            // }
        }
        // if (props.loginData && props.loginData.error) {
        //     context.notify.error(props.loginData.message);
        //     props.resetLogin();
        // }
    }, [props.loginData]);

    const login = data => {
        setLogin(true)
        return axios.post(LOGIN_URL, data).then(response => {
            props.loginAction(response.data);
            // localStorage.setItem('client_user_name', data.username)
            setLogin(false)
        }).catch(error => {
            let { status, data } = error.response;
            if (status === 403 && data && data.message) {
                context.notify.error(data.message);
                setError(data.message) // set the Error message from backend
            }
            if (status === 401 && data && data.message) {
                context.notify.error("Credentials entered are invalid");
                setError(data.message) // set the Error message from backend
            } else if (status === 401) {
                context.notify.error('Please check username or password');
                setError("Please check username or password") // set the Error message from backend
            }
            setLogin(false)
        })
    }

    const forgotPassword = data => {
        return axios.post(FORGOT_PASSWORD_URL, data).then(response => {
            context.notify.success('ResetPassword link shared successfully to your email');
            setisForgotPassword(false);
        }).catch(error => {
            let { status, data } = error.response;
            if (status === 400 && data && data.message) context.notify.error(data.message.split(',')[0])
            if (status === 403 && data && data.message) context.notify.error(data.message)
            if (status === 401 && data && data.message) context.notify.error(data.message)
            else if (status === 401) context.notify.error('Please check the password')
        })
    }

    const ssoLogin = (data) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(data.username)) {
        context.notify.error('Please enter a valid email ID');
        return;
      }

      const url = `${SSO_LOGIN_API}?username=${data.username}`;
      setTimeout(() => {
        window.location.href = url;
      }, 100);
    };

    const resetPassword = data => {
        if (!(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})").test(data.newPassword))) {
            context.notify.error(`Password must contain 8 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special character`);
        } else if (data.newPassword !== data.newPasswordConfirmation) {
            context.notify.error(`Password and confirm password should be same`);
        } else {
            return axios.put(RESET_PASSWORD_URL, data).then(response => {
                context.notify.error('Password Reset Successfully');
                window.location.href = window.location.origin + '#/login/SmartSense';
            }).catch(error => {
                let { status, data } = error.response;
                if (status === 403 && data && data.message) context.notify.error(data.message)
                if (status === 401 && data && data.message) context.notify.error(data.message)
                else if (status === 401) context.notify.error('Please check username')
            })
        }
    }

    const verifyOTP = (data) => {
        return axios.post(VERIFY_OTP_URL, data).then(response => {
            props.loginAction(response.data);
            getData(response.data)
        }).catch(error => {
            const { status, data } = error && error.response;
            if (data && data.message) context.notify.error(data.message);
            if (status === 401) context.notify.error('Your OTP might have expired, please login again');
        })
    }

    const resendOTP = (data) => {
        return axios.post(RESEND_OTP_URL, data).then(response => {
            getData(response.data.data)
        }).catch(error => {
            const { data } = error && error.response;
            if (data && data.message) context.notify.error(data.message);
        })
    }

    // useEffect(()=>{
    //     let { success, error, message } = props.loginData;
    //     if(){
    //         context.notify.error(message)
    //         props.resetLogin()
    //     }
    // },[props.loginData])

    return (
      <div className='_Login-container'>
        <LoginComponent
          preloadLoading={loading}
          hasAuthToken={hasAuthToken}
          loginData={props.loginData}
          isLogin={isLogin}
          login={(value) => login(value)}
          isForgotPassword={isForgotPassword}
          setisForgotPassword={(e) => setisForgotPassword(e)}
          forgotPassword={forgotPassword}
          isResetPassword={resetToken}
          resetPassword={resetPassword}
          setResetToken={setResetToken}
          isSsoLogin={isSsoLogin}
          ssoLogin={ssoLogin}
          setIsSsoLogin={(e) => setIsSsoLogin(e)}
        />
      </div>
    );
}


const mapStateToProps = (state) => {
    return {
        loginData: state.login,
    };
};


export default withRouter(connect(
    mapStateToProps, {
    loginAction,
    resetLogin,
})(Login));