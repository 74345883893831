import React, { useState, useEffect, Fragment } from 'react';

import parameterConfsInputGet from '../common/parameterConfsInputGet';
import parameterConfInputGet from '../common/parameterConfInputGet';
import formulaConfsInputGet from '../common/formulaConfsInputGet';
import ParameterConfigMixed from './ParameterConfigMixed';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import formulaConfsOutputGet from '../common/formulaConfsOutputGet';
import { Button } from '../../../../../../../common/components';

const parameterConfsGet = (locationBasedParamSelector) => {
  const parameterConfs = locationBasedParamSelector.reduce(
    (memo, _locationBasedParamSelector, index) => {
      const value =
        !_locationBasedParamSelector.isComputed &&
        (() => {
          const { alias, locationname, locationid, paramLabel, param } =
            _locationBasedParamSelector;

          return {
            index,
            alias,
            locParam: {
              location: {
                label: locationname,
                value: locationid
              },
              readingtype: {
                label: paramLabel,
                value: param
              }
            }
          };
        })();

      return value ? [...(memo || []), value] : memo;
    },
    null
  );

  return (
    parameterConfs &&
    parameterConfsInputGet(parameterConfs).reduce((memo, parameterConf) => {
      return [
        ...memo,
        {
          ...parameterConf,
          isComputed: false
        }
      ];
    }, [])
  );
};

const formulaConfsGet = (locationBasedParamSelector) => {
  const formulaConfs = locationBasedParamSelector.reduce(
    (memo, _locationBasedParamSelector, index) => {
      const value =
        _locationBasedParamSelector.isComputed &&
        (() => {
          const {
            alias,
            unit,
            computedFormula: { formula, equation, is_partial }
          } = _locationBasedParamSelector;

          return {
            index,
            alias,
            unit,
            formula,
            equation,
            is_partial
          };
        })();

      return value ? [...(memo || []), value] : memo;
    },
    null
  );

  return (
    formulaConfs &&
    formulaConfsInputGet(formulaConfs).reduce((memo, formulaConf) => {
      return [
        ...memo,
        {
          ...formulaConf,
          isComputed: true
        }
      ];
    }, [])
  );
};

const locationBasedParamSelectorGet = (__locationBasedParamSelector) => {
  const locationBasedParamSelector = [
    ...(() => {
      return parameterConfsGet(__locationBasedParamSelector) || [];
    })(),
    ...(() => {
      return formulaConfsGet(__locationBasedParamSelector) || [];
    })()
  ].sort((a, b) => a.index - b.index);

  return locationBasedParamSelector.length
    ? locationBasedParamSelector
    : [{ ...parameterConfInputGet(), isComputed: false }];
};

const __locationBasedParamSelectorGet = (_locationBasedParamSelector) => {
  const locationBasedParamSelector = !_locationBasedParamSelector
    ? null
    : !_locationBasedParamSelector.isComputed
    ? (() => {
        const _parameterConfs = parameterConfsOutputGet([
          _locationBasedParamSelector
        ]);

        return _parameterConfs
          ? (() => {
              const {
                locParam: {
                  location: { label: locationname, value: locationid },
                  readingtype: { label: paramLabel, value: param }
                },
                ...parameterConf
              } = _parameterConfs[0];

              return {
                ...parameterConf,
                locationname,
                locationid,
                paramLabel,
                param
              };
            })()
          : {};
      })()
    : (() => {
        const _formulaConfs = formulaConfsOutputGet([
          _locationBasedParamSelector
        ]);

        return _formulaConfs
          ? (() => {
              const { formula, equation, is_partial, ...formulaConf } =
                _formulaConfs[0];

              return {
                ...formulaConf,
                computedFormula: {
                  formula,
                  equation,
                  is_partial
                }
              };
            })()
          : {};
      })();

  return locationBasedParamSelector;
};

const _locationBasedParamSelectorGet = (locationBasedParamSelector) => {
  return locationBasedParamSelector.reduce(
    (memo, _locationBasedParamSelector) => {
      return [
        ...memo,
        __locationBasedParamSelectorGet(_locationBasedParamSelector)
      ];
    },
    []
  );
};

const ParameterConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [locationBasedParamSelector, setLocationBasedParamSelector] =
    useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams.locationBasedParamSelector &&
        props.widgetParams.locationBasedParamSelector &&
        props.widgetParams.locationBasedParamSelector.value;

      setLocationBasedParamSelector(locationBasedParamSelectorGet(conf || []));

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    props.appInputParamChanged({
      locationBasedParamSelector: {
        value: _locationBasedParamSelectorGet(object)
      }
    });
  };

  const renderParameterConfig = () => {
    return (
      <ParameterConfigMixed
        confs={locationBasedParamSelector}
        onConfsChange={(locationBasedParamSelector) => {
          setLocationBasedParamSelector(locationBasedParamSelector);

          appInputParamChangedHandle(locationBasedParamSelector);
        }}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        {renderParameterConfig()}

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfig;
