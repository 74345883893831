import React from "react";
import moment from "moment";
import DateTime from "../../../common/utils/dateTimeUtils";

export const LAST_MONTH = 'lastMonth';
export const BEFORE_TWO_MONTH = 'beforeTwoMonths';
export const BEFORE_THREE_MONTH = 'beforeThreeMonths';
export const LAST_YEAR_SAME_MONTH = 'lastYearSameMonths';

export const DATE_RANGE_LIST = [LAST_YEAR_SAME_MONTH, BEFORE_THREE_MONTH, BEFORE_TWO_MONTH, LAST_MONTH];

export const buildDateRange = startDate => {
    const result = {};
    const rangeData = [
        {
            key: LAST_MONTH,
            difference: [1, 'months']
        },
        {
            key: BEFORE_TWO_MONTH,
            difference: [2, 'months']
        },
        {
            key: BEFORE_THREE_MONTH,
            difference: [3, 'months']
        },
        {
            key: LAST_YEAR_SAME_MONTH,
            difference: [1, 'years']
        }
    ];

    for (let i = 0; i < rangeData.length; i++) {
        const currentDate = startDate.clone();
        const rangeVal = rangeData[i];
        const {difference} = rangeVal;
        const dateExtract = currentDate.clone().subtract(difference[0], difference[1]);
        result[rangeVal['key']] = [
            dateExtract.clone().startOf('month'),
            dateExtract.clone().endOf('month'),
        ]
    }
    return result;
};

export const buildTitleAndValues = (key, value, primaryData, newData) => {
    const currentValue = primaryData[value];
    const colKey = `key_${key}`;
    let titleName = '';
    if ('from_date' in currentValue.data) {
        const writeFormat = 'MMM YYYY';
        const momentInstance = moment(currentValue.data['from_date'], 'YYYY-MM-DD');
        titleName = momentInstance.format('MMM YYYY');
        switch (value) {
            case 'lastMonth':
                titleName = `Last Month (${momentInstance.format(writeFormat)})`;
                break;
            case 'lastYearSameMonths':
                titleName = `Last year same month (${momentInstance.format(writeFormat)})`;
                break;
        }
    }
    return [currentValue, newData[value], colKey, titleName];
};

export const updateInterestFields = (props, state) => {
    const interestedField = ['grain', 'startDate', 'endDate', 'location'];
    const updateState = {};
    for (let i = 0; i < interestedField.length; i++) {
        const fieldName = interestedField[i];
        if (state.hasOwnProperty(fieldName)) {
            const propsData = props[fieldName];
            const stateData = state[fieldName];
            if (stateData !== propsData) {
                updateState[fieldName] = propsData;
            }
        }
    }
    return updateState;
};

export const buildHolidays = (start, end, dayNames = null) => {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    if (dayNames === null) {
        dayNames = ['sun'];
    }

    if (moment.isMoment(start) && moment.isMoment(end)) {
        start = start.toDate();
        end = end.toDate();
    } else if (typeof (start) === 'string' && typeof (end) === 'string') {
        start = moment(start, dateFormat).toDate();
        end = moment(start, dateFormat).toDate();
    }
    const result = [];
    const days = {sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6};
    for (let i = 0; i < dayNames.length; i++) {
        const dayName = dayNames[i].toLowerCase().substr(0, 3);
        const day = days[dayName];
        // Copy start date
        const current = new Date(start);
        // Shift to next of required days
        current.setDate(current.getDate() + (day - current.getDay() + 7) % 7);
        // While less than end date, add dates to result array
        while (current < end) {
            result.push(moment(new Date(+current)));
            current.setDate(current.getDate() + 7);
        }
    }
    return result;
};

const RADIAN = Math.PI / 180;
export const buildCustomizedLabel = (
    {
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }
) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) + 4;
    const y = cy + radius * Math.sin(-midAngle * RADIAN) + 3;

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export const buildBasicTick = (
    grain,
    dayFormat = 'LT, LL',
    hourFormat = 'MMM DD, YYYY'
) => tickItem => {
    const grainData = grain['data'] || 1440;
    if (grainData < 1440) {
        return DateTime.format(tickItem, dayFormat);
    }
    return DateTime.format(tickItem, hourFormat);
}
