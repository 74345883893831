import React from 'react'
import ReactTooltip from 'react-tooltip';

export default class ToolTip extends React.Component {
  render () {
    return (
      <ReactTooltip
        id = {this.props.id}
        place  = {this.props.place || 'top'}
        type   = 'light'
        effect = "solid"
        multiline = {true}
        border   = {true}
        className = 'apptooltip'
      >
      </ReactTooltip>
    )
  }
}
