import React from 'react';
import clsx from 'clsx';
import { Row, Col, Collapse } from 'reactstrap';
import { ErrorBoundary, InviewWrapper } from '../../../common/components';
import PropTypes from 'prop-types';
import DateTime from '../../../common/utils/dateTimeUtils';
import infoIconLight from '../../../../assets/images/info-iconLight.svg';
import infoIconDark from '../../../../assets/images/info-iconDark.svg';

export default class Widget extends React.Component {
    static defaultProps = {
        refreshTime: '',
        refreshWidget: function() {},
    }

    static propTypes = {
        refreshTime: PropTypes.string,
        refreshWidget: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ isOpen: !this.state.isOpen })
    };

    render() {
        const { isOpen } = this.state;
        return (
            <Col
                className='padding-zero'
                xl={this.props.size ? this.props.size : 12}
                lg={this.props.size ? this.props.size : 12}
                md={this.props.size ? this.props.size : 12}
                sm={this.props.size ? this.props.size : 12}
                xs={this.props.size ? this.props.size : 12}
            >
                <div className="bg-shadow-box"></div>
                <Row className='widget'>
                    <Col className='widgetHeader' xl={12} lg={12} md={12} sm={12} xs={12} onClick={this.toggle}>
                        {this.props.name}
                        
                            <div className='float-right'>
                                <div className='widgetArrow'>
                                    {
                                        !(this.props.disableCollapse && this.props.disableCollapse === true) ?
                                        <i className={clsx('fas', isOpen ? 'fa-chevron-down' : 'fa-chevron-right')} />: ''
                                    }
                                </div>
                                {
                                    this.props.refreshWidget ?
                                        <React.Fragment>
                                            <span className = 'widget-refresh' onClick = {this.props.refreshWidget}>
                                                <i className="fas fa-sync-alt" aria-hidden="true"></i>
                                            </span>
                                            <span 
                                                className = 'widget-arrow-description' 
                                                data-title = {`Last Updated: ${DateTime.format(this.props.refreshTime, 'MMM DD, YYYY HH:mm')}`}
                                            >
                                                {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                                {/* <img src={infoIconLight} /> */}
                                                <img className="icon-img light-img" src={infoIconLight} alt="smartsense" />
                                                <img className="icon-img dark-img" src={infoIconDark} alt="smartsense" />
                                            </span>
                                        </React.Fragment>
                                    :
                                    ''
                                }
                            </div>
                        
                    </Col>
                    {
                        !this.props.disableCollapse || this.props.disableCollapse == false ?
                            <Collapse className='collapseContainer' isOpen={isOpen}>
                                <Col className='widgetContent' xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <InviewWrapper>
                                        <ErrorBoundary>
                                            {this.props.children}
                                        </ErrorBoundary>
                                    </InviewWrapper>
                                </Col>
                            </Collapse>
                            :
                            <Col className='widgetContent' xl={12} lg={12} md={12} sm={12} xs={12}>
                                <InviewWrapper>
                                    <ErrorBoundary>
                                        {this.props.children}
                                    </ErrorBoundary>
                                </InviewWrapper>
                            </Col>
                    }

                </Row>
            </Col>
        )
    }
}