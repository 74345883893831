import { useContext } from 'react';
import { Context } from '../../../admin/container/Main/context';

export default (data, onSetData) => {
  const context = useContext(Context);

  const onDrop = (ev) => {
    ev.preventDefault();

    const srcIndex = ev.dataTransfer.getData('draggedIndex');

    const tgtIndex = ev.currentTarget.getAttribute('tabIndex');

    let sourceDashbaord = data[srcIndex];

    if ((sourceDashbaord.endpoint || sourceDashbaord.url) && tgtIndex == 0) {
      context.notify.error(
        `${sourceDashbaord.name} can't be taken as a first option.`
      );
    } else {
      let sortedData = (() => {
        const temp = data[srcIndex];
        data[srcIndex] = data[tgtIndex];
        data[tgtIndex] = temp;
        return data;
      })();

      onSetData([...sortedData]);
    }

    if (ev.currentTarget.className == 'nav-item') {
      ev.currentTarget.style.border = '';
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';

    if (event.currentTarget.className == 'nav-item') {
      event.currentTarget.style.border = '1px dashed #4C74F2';
    }

    return false;
  };

  const onDragEnd = (event) => {
    event.target.style.opacity = '1';
    event.target.style.border = '';
    event.target.style.borderRadius = '';
  };

  const onDragEnter = (event) => {
    if (event.currentTarget.className == 'nav-item') {
      event.currentTarget.style.border = '1px dashed #4C74F2';
    }
  };

  const onDragLeave = (event) => {
    if (
      event.currentTarget.className == 'nav-item' &&
      event.target.className != 'nav-item'
    ) {
      event.currentTarget.style.border = '';
    }
  };

  const onDragStart = (event) => {
    const i = event.target.getAttribute('tabIndex');
    event.dataTransfer.setData('draggedIndex', i);
    event.target.style.opacity = '0.4';
    event.target.style.border = '1px dashed #000';
    event.target.style.borderRadius = '2px';
  };

  return {
    onDrop,
    onDragOver,
    onDragEnd,
    onDragEnter,
    onDragLeave,
    onDragStart
  };
};
