import Ecolytics, { EventNames } from '@Ecolibrium-Energy/ecolytics';
import {
  GET_LOGIN_DATA,
  GET_LOGIN_DATA_SUCCESS,
  GET_LOGIN_DATA_FAILURE,
  GET_LOGIN_DATA_RESET
} from '../constants'


const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined,
  clientSucess: null
}

export function getLoginDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LOGIN_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }
    case GET_LOGIN_DATA_SUCCESS:

      // localStorage.setItem("logindata", JSON.stringify(action.payload.data))

      // let a = action.payload;
      let a = null
      if (action.param && action.param.user_id) {
        a = action.response.response;
        let adminToken = JSON.parse(localStorage.getItem('smartsense.authToken'));
        // let roles = JSON.parse(localStorage.getItem('smartsense.roles'));
        localStorage.setItem("smartsense.superAuthToken", JSON.stringify(adminToken));
        // localStorage.setItem("smartsense.adminRoles", JSON.stringify(roles));
      } else if (action.response && action.response.user_id) {
        a = action.response.response;
      } else {
        a = action.payload;
        localStorage.setItem("smartsense.admin_member", JSON.stringify(a.member))
      }

      localStorage.setItem("smartsense.authToken", JSON.stringify(a.token));

      localStorage.setItem("smartsense.member_plants", JSON.stringify(a.plants))
      localStorage.setItem("smartsense.member_locations", JSON.stringify(a.member_locations))
      localStorage.setItem("smartsense.locationlist", JSON.stringify(a.locations))

      localStorage.setItem("smartsense.member_service_permissions", JSON.stringify(a.member_service_permissions))

      localStorage.setItem("smartsense.member", JSON.stringify(a.member))

      localStorage.setItem("smartsense.readingtypes", JSON.stringify(a.reading_types))
      localStorage.setItem("smartsense.version_readingtype_mapping", JSON.stringify(a.msg_version_readingtypes));
      localStorage.setItem("smartsense.sensors", JSON.stringify(a.sensors))
      localStorage.setItem("smartsense.decode_alarm_mapping", JSON.stringify(a.decode_alarm_mapping))
      localStorage.setItem("smartsense.service_config", JSON.stringify(a.service_config))
      localStorage.setItem("smartsense.services", JSON.stringify(a.services))
      localStorage.setItem("smartsense.roles", JSON.stringify(a.roles))

      a.asset_image &&
        localStorage.setItem('smartsense.asset_image', a.asset_image);

      localStorage.setItem(
        'member_default_plant',
        JSON.stringify(a.member_default_plant)
      );

      a.asset_categories &&
        localStorage.setItem(
          'smartsense.asset_categories',
          JSON.stringify(a.asset_categories)
        );

      //log in successfull
      Ecolytics.registerUser(a.member);
      Ecolytics.track(EventNames.LOGIN);

      return {
        ...state,
        data: action.payload,
        clientSucess: action.param && action.param.user_id,
        error: false,
        loading: false,
        success: true
      }
    case GET_LOGIN_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false,
        success: false
      }

    case GET_LOGIN_DATA_RESET:
      return {
        ...state,
        error: false,
        data: [],
        loading: false,
        success: false,
        clientSucess: null,
      }
    default:
      return state
  }
}