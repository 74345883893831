import {
  GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD,
  GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD_SUCCESS,
  GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD_FAILURE,
  GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE,
  GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE_SUCCESS,
  GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE_FAILURE
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined
};

export const getDashboardSidebarAllDashboard = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD:
      return {
        ...state,
        loading: true
      };

    case GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };

    default:
      return state;
  }
};

export const getDashboardSidebarDashboardSequence = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE:
      return {
        ...state,
        loading: true,
        data: {
          ...state.data,
          [action.payload.service_id]: {}
        }
      };

    case GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.service_id]: action.response
        },
        error: false,
        loading: false,
        success: true
      };

    case GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        data: {},
        success: false
      };

    default:
      return state;
  }
};