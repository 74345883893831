import {
    getLiveConsumptionDataEpic,
    getEnergyForecastDataEpic,
    getTotalConsumptionDataEpic,
    getDateRangeEnergyConsumptionDataEpic,
    getDateRangeEnergyForeCastDataEpic,
    getDateRangeEnergyConsumptionTimeSeriesEpic,
    getDateRangeEnergyConsumptionDataDiffEpic,
    getDateRangeEnergyForeCastDataDiffEpic
} from './Energyconsumption/epic';
import {
    getDemandDataEpic,
    getDemandDateRangeEpic,
    getDemandAnalysisChartEpic,
    getDemandDifferenceDateRangeEpic,
    getDemandDateRangeForecastEpic,
    getDateRangeDemandForeCastDataDiffEpic
} from './DemandAnalysis/epic';
import {
    getDayDemandAnalysisDataEpic,
    getDayDemandAnalysisDifferenceDataEpic
} from './DaysAnalysis/epic';
import {
    getShiftAnalysisDataEpic,
    getShiftAnalysisDataDifferenceEpic,
    getShiftAnalysisSeriesEpic,
    getShiftAnalysisDateRangeEpic,
    getShiftAnalysisDateRangeDiffEpic
} from './ShiftAnalysis/epic';
import { getAlertTypeSummary } from './AlertTypeSummary/epic';
import { getAlertGenSummary } from './AlertGenerationSummary/epic';
import { getAlertConfigCount } from './AlertConfigurationCount/epic';
import { getAlertTrend } from './AlertTrend/epic';
import { getAlertGenOverview } from './AlertGenerationOverview/epic';
import { getAlertStatusData } from './AlertStatus/epic';
import { getAlertMonitoringData, getAllAlertForMonitoring } from './AlertMonitoring/epic';
import { getEventActivitySummary } from './EventActivitySummary/epic';
import { getSensorEventOverview, getConfigurationEvent } from './SensorEventOverview/epic';

export const widgetEpics = {
    getLiveConsumptionDataEpic,
    getEnergyForecastDataEpic,
    getTotalConsumptionDataEpic,
    getDateRangeEnergyConsumptionDataEpic,
    getDateRangeEnergyForeCastDataEpic,
    getDateRangeEnergyConsumptionTimeSeriesEpic,
    getDateRangeEnergyConsumptionDataDiffEpic,
    getDateRangeEnergyForeCastDataDiffEpic,
    getDemandDataEpic,
    getDemandDateRangeEpic,
    getDemandAnalysisChartEpic,
    getDayDemandAnalysisDataEpic,
    getDemandDateRangeForecastEpic,
    getDateRangeDemandForeCastDataDiffEpic,
    getShiftAnalysisDataEpic,
    getShiftAnalysisSeriesEpic,
    getShiftAnalysisDateRangeEpic,
    getDemandDifferenceDateRangeEpic,
    getDayDemandAnalysisDifferenceDataEpic,
    getShiftAnalysisDateRangeDiffEpic,
    getShiftAnalysisDataDifferenceEpic,
    getAlertTypeSummary,
    getAlertGenSummary,
    getAlertConfigCount,
    getAlertTrend,
    getAlertGenOverview,
    getAlertStatusData,
    getAlertMonitoringData,
    getAllAlertForMonitoring,
    getEventActivitySummary,
    getSensorEventOverview,
    getConfigurationEvent
};
