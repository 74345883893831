import formulaCompare from './formulaCompare';

const formulaConfParameterCollectionGet = (formulaCollection) => {
  const formulaParameterCollection = formulaCollection
    .filter(({ type }) => type === 'parameter')
    .reduce((memo, formula) => {
      const exists = memo.some((_formula) => {
        return formulaCompare(_formula, formula);
      });

      return exists
        ? memo
        : [
            ...memo,
            {
              index: memo.length,
              ...formula,
              parameter: { ...formula.parameter }
            }
          ];
    }, []);

  return formulaParameterCollection;
};

export default (formulaConfs) => {
  return formulaConfs.reduce((memo, formulaConf) => {
    return [
      ...memo,
      formulaConfParameterCollectionGet(formulaConf.formula[0].formula)
    ];
  }, []);
};