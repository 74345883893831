import { useState, useEffect } from 'react';

import formulaConfParameterCollectionsGet from './formulaConfParameterCollectionsGet';
import formulaConfPointerCollectionsGet from './formulaConfPointerCollectionsGet';
import formulaConfInputGet from '../common/formulaConfInputGet';
import formulaConfsInputGet from '../common/formulaConfsInputGet';
import _formulaCollectionGet from './_formulaCollectionGet';
import _formulaConfGet from './_formulaConfGet';
import _formulaConfsGet from './_formulaConfsGet';

export default (formulaConfs, onFormulaConfsChange) => {
  const [initialized, setInitialized] = useState(false);

  const [formulaConfActiveIndex, setFormulaConfActiveIndex] = useState(-1);

  const [formulaConfParameterCollections, setFormulaConfParameterCollections] =
    useState();

  const [formulaConfPointerCollections, setFormulaConfPointerCollections] =
    useState();

  useEffect(() => {
    if (!initialized && formulaConfs) {
      const formulaConfActiveIndex = formulaConfs.length - 1;

      const formulaConfParameterCollections =
        formulaConfParameterCollectionsGet(formulaConfs);

      const formulaConfPointerCollections = formulaConfPointerCollectionsGet(
        formulaConfs,
        formulaConfParameterCollections
      );

      setFormulaConfPointerCollections(formulaConfPointerCollections);

      setFormulaConfParameterCollections(formulaConfParameterCollections);

      setFormulaConfActiveIndex(formulaConfActiveIndex);

      setInitialized(true);
    }
  }, [initialized, formulaConfs]);

  const onFormulaConfActiveIndexChangeHandle = (_formulaConfActiveIndex) => {
    setFormulaConfActiveIndex(_formulaConfActiveIndex);
  };

  const onFormulaConfParameterCollectionUpdateHandle = (
    formula,
    formulaParameterIndex
  ) => {
    const _formulaConfParameterCollections =
      formulaConfParameterCollections.reduce(
        (memo, _formulaConfParameterCollection, index) => {
          const formulaConfParameterCollection =
            index === formulaConfActiveIndex
              ? [
                  ..._formulaConfParameterCollection.slice(
                    0,
                    formulaParameterIndex
                  ),
                  formula,
                  ..._formulaConfParameterCollection.slice(
                    formulaParameterIndex + 1
                  )
                ]
              : _formulaConfParameterCollection;

          return [...memo, formulaConfParameterCollection];
        },
        []
      );

    const formulaCollection = _formulaCollectionGet(
      formulaConfPointerCollections[formulaConfActiveIndex],
      _formulaConfParameterCollections[formulaConfActiveIndex]
    );

    const _formulaConf = formulaConfs[formulaConfActiveIndex];

    const formulaConf = _formulaConfGet(formulaCollection, _formulaConf);

    const _formulaConfs = _formulaConfsGet(
      formulaConf,
      formulaConfActiveIndex,
      formulaConfs
    );

    setFormulaConfParameterCollections(_formulaConfParameterCollections);

    onFormulaConfsChange(_formulaConfs);
  };

  const onFormulaConfPointerCollectionUpdateHandle = (
    formulaConfPointerCollection
  ) => {
    const _formulaConfPointerCollections = formulaConfPointerCollections.reduce(
      (memo, _formulaConfPointerCollection, index) => {
        const value =
          index === formulaConfActiveIndex
            ? formulaConfPointerCollection
            : _formulaConfPointerCollection;

        return [...memo, value];
      },
      []
    );

    const formulaConfParameterCollection =
      formulaConfParameterCollections[formulaConfActiveIndex];

    const formulaCollection = _formulaCollectionGet(
      _formulaConfPointerCollections[formulaConfActiveIndex],
      formulaConfParameterCollection
    );

    const _formulaConf = formulaConfs[formulaConfActiveIndex];

    const formulaConf = _formulaConfGet(formulaCollection, _formulaConf);

    const _formulaConfs = _formulaConfsGet(
      formulaConf,
      formulaConfActiveIndex,
      formulaConfs
    );

    setFormulaConfPointerCollections(_formulaConfPointerCollections);

    onFormulaConfsChange(_formulaConfs);
  };

  const onFormulaConfCreateHandle = () => {
    const _formulaConfs = [...formulaConfs, formulaConfInputGet()];

    const _formulaConfActiveIndex = _formulaConfs.length - 1;

    const _formulaConfParameterCollections =
      formulaConfParameterCollectionsGet(_formulaConfs);

    const _formulaConfPointerCollections = formulaConfPointerCollectionsGet(
      _formulaConfs,
      _formulaConfParameterCollections
    );

    setFormulaConfPointerCollections(_formulaConfPointerCollections);

    setFormulaConfParameterCollections(_formulaConfParameterCollections);

    setFormulaConfActiveIndex(_formulaConfActiveIndex);

    onFormulaConfsChange(_formulaConfs);
  };

  const onFormulaConfResetHandle = () => {
    const _formulaConf = formulaConfs[formulaConfActiveIndex];

    const formulaCollection = [];

    const formulaConf = _formulaConfGet(formulaCollection, _formulaConf, true);

    const _formulaConfs = _formulaConfsGet(
      formulaConf,
      formulaConfActiveIndex,
      formulaConfs
    );

    const _formulaConfParameterCollections =
      formulaConfParameterCollections.reduce(
        (memo, _formulaConfParameterCollection, index) => {
          const formulaConfParameterCollection =
            index === formulaConfActiveIndex
              ? []
              : _formulaConfParameterCollection;

          return [...memo, formulaConfParameterCollection];
        },
        []
      );

    const _formulaConfPointerCollections = formulaConfPointerCollectionsGet(
      _formulaConfs,
      _formulaConfParameterCollections
    );

    setFormulaConfPointerCollections(_formulaConfPointerCollections);

    setFormulaConfParameterCollections(_formulaConfParameterCollections);

    onFormulaConfsChange(_formulaConfs);
  };

  const onFormulaConfDeleteHandle = (index) => {
    const _formulaConfs = formulaConfsInputGet(
      (() => {
        const value = [
          ...formulaConfs.slice(0, index),
          ...formulaConfs.slice(index + 1)
        ];

        return value.length ? value : null;
      })()
    );

    const _formulaConfActiveIndex = (() => {
      switch (true) {
        case index !== formulaConfActiveIndex && index < formulaConfActiveIndex:
          return formulaConfActiveIndex - 1;

        case index !== formulaConfActiveIndex && index > formulaConfActiveIndex:
          return formulaConfActiveIndex;

        case index === formulaConfActiveIndex && !!index:
          return formulaConfActiveIndex - 1;

        default:
          return 0;
      }
    })();

    const _formulaConfParameterCollections =
      formulaConfParameterCollectionsGet(_formulaConfs);

    const _formulaConfPointerCollections = formulaConfPointerCollectionsGet(
      _formulaConfs,
      _formulaConfParameterCollections
    );

    setFormulaConfPointerCollections(_formulaConfPointerCollections);

    setFormulaConfParameterCollections(_formulaConfParameterCollections);

    _formulaConfActiveIndex >= 0 &&
      setFormulaConfActiveIndex(_formulaConfActiveIndex);

    onFormulaConfsChange(_formulaConfs);
  };

  return [
    initialized,
    formulaConfActiveIndex,
    formulaConfParameterCollections,
    formulaConfPointerCollections,
    onFormulaConfParameterCollectionUpdateHandle,
    onFormulaConfPointerCollectionUpdateHandle,
    onFormulaConfResetHandle,
    onFormulaConfActiveIndexChangeHandle,
    onFormulaConfCreateHandle,
    onFormulaConfDeleteHandle
  ];
};