import React from 'react'
import { Col, Row } from 'reactstrap'
import leftArrow from '../../../../assets/images/Alert/arrow.svg';

function sideDrawer(props) {
    let drawerClasses = 'side-drawer'
    if (props.show) {
        drawerClasses = `side-drawer open`
    }

    return <div className={drawerClasses}>
        <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                <h4>
                    <div className="heading" onClick={props.onBack}>
                        {props.notificationDetail && <img src={leftArrow} />}
                        <span>{props.heading}</span>
                    </div>
                    <span className="close-drawer-icon float-right" onClick={props.close}><i className="fas fa-times"></i></span>
                </h4>
            </Col>
        </Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className={props.className}>
            {props.children}
        </Col>
    </div>
}

export default sideDrawer;

