import React, { Component } from 'react';
import { Tabs, Input, CheckBox } from '../';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import List from '../List';
import Treeview from '../Treeview';
// import ReactDrawer from 'react-drawer';
import { generateTree, convertTreeToList, flatten } from '../../../admin/container/utils'


const TABS = ["Flat View", "Tree View"];

class ListView extends Component {

  render() {
    return (<Col xl={12} lg={12} md={12} sm={12} xs={12} className="list-view">
      <div className='drawercontainer' >
        <Row>
          <Col sm={12}>
            <div className='searchBar'>
              <Input
                className='text'
                name='search'
                placeholder='Search for location'
                onChange={this.props.onSearch}
                onKeyUp={this.props.onSearch}
                inputChanged={this.props.onSearch}
              />
              <div className="searchIcon icon"><i className="fa fa-search fa-lg icon-img" aria-hidden="true" /></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <List
              count={this.props.data.length}
              getListElement={i => {
                let item = this.props.data[i];
                return (
                  <CheckBox
                    checkboxClicked={isChecked => { this.props.onChange && this.props.onChange(isChecked, item); }}
                    checked={this.props.selectedLocations.findIndex(x => x == item.id) > -1}
                    label={item.label}
                    size='lg'
                  />
                )
              }
              }
            />
          </Col>
        </Row>
      </div>
    </Col>);
  }
}

class LocationSelector extends Component {
  constructor(props) {
    super(props);

    this.plantWiseLocations = {};
    this.loggedInUserLocations = [];

    const plantData = []
    let member_plants = JSON.parse(localStorage.getItem('smartsense.member_plants'));
    let member_locations = JSON.parse(localStorage.getItem('smartsense.locationlist'));
    member_locations = Array.from(new Set(member_locations));
    member_plants && member_plants.map((plant, i) => {
      const Locations = []
      member_locations.map((location, j) => {
        if (plant.plantid === location.plant) {
          location.plantName = plant.name;
          Locations.push(location)
        }
      })

      let data = generateTree(Locations);
      var tree = data[0];
      this.plantWiseLocations[plant.plantid] = data[1];
      this.loggedInUserLocations = this.loggedInUserLocations.concat(data[1]);
      plantData.push({
        plantName: plant.name,
        plantId: plant.plantid,
        children: tree
      })
    });
    let locationData = flatten(member_locations);
    locationData = Array.from(new Set(locationData));


    let treeData = [];
    plantData.forEach(plant => {
      let d = { id: plant.plantId, label: plant.plantName, isCollapse: false };
      d.children = plant.children ? this.getChildren(plant.children) : [];
      d.isChecked = false;
      treeData.push(d);
    });
    this.state = {
      open: false,
      position: "right",
      selectedLocations: [],
      plantData,
      locationData,
      treeData,
      locationsObject: [],
      noOverlay: false,
      listData: locationData.map(i => { return { id: i.locationid, label: i.name } }),
      filteredListData: locationData.map(i => { return { id: i.locationid, label: i.name } }),
      filteredTreeData: treeData,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if(Array.isArray(props.selectedLocations)){
      state.selectedLocations = props.selectedLocations;
    }

    return state;
  }

  onChangeListItem = (isChecked, item) => {
    let selected = this.state.selectedLocations;
    if (isChecked) {
      selected.push(item.id);
    } else {
      selected.splice(selected.indexOf(item.id), 1);
    }
    //let locationsObject = this.refreshLocationObject(selected);
    this.setState({ selectedLocations: selected, /*locationsObject*/ });
    this.onParentChange(selected);
  }

  onLocationChangeInTree = (isChecked, items) => {
    let selected = this.state.selectedLocations;
    items.forEach(l => {
      if (isChecked) {
        selected.push(l)
      } else {
        selected.splice(selected.indexOf(l), 1);
      }
    });
    //let locationsObject = this.refreshLocationObject(selected);
    this.setState({ selectedLocations: selected, /*locationsObject*/ });
    this.onParentChange(selected);
  }

  onParentChange = selected => {
    let selectedLocObj = [];
    this.state.locationData.forEach(loc => {
      if (selected && selected.includes(loc.locationid)) {
        selectedLocObj.push(loc);
      }
    });
    this.props.onChange(selectedLocObj);
  }

  /*refreshLocationObject = selectedLocations => {
    let locationsObject = [...new Set(selectedLocations)].map(lid => {
      let loc = this.state.locationData.filter(l => l.locationid == lid)[0];
      return loc ? {locationid: loc.locationid, name:loc.name, matric:""} : null;
    });
    locationsObject = locationsObject.filter(i => i!=null);
    return locationsObject;
  }*/

  onDrawerClose = () => {
    this.setState({ open: false });
  }

  toggleDrawer = () => {
    if (!this.state.open === false) this.hideSearchMode();
    this.setState({ open: !this.state.open });
  }

  onSearch = (text, searchView) => {
    if (searchView === "list") {
      let filteredListData = this.state.listData.filter(d => d.label.toLowerCase().indexOf(text.toLowerCase()) > -1);
      this.setState({ filteredListData });
    } else if (searchView === "tree") {
      let filteredTreeData = JSON.parse(JSON.stringify(this.state.treeData));
      filteredTreeData = text.trim() == "" ? filteredTreeData : this.filterNested(filteredTreeData, text);
      this.setState({ filteredTreeData })
    } else {
      console.warn("Invalid search view " + searchView);
    }
  }

  filterNested = (list, text) => {
    var result = [];
    list.forEach(function iter(a) {
      a.label.toLowerCase().indexOf(text) > -1 && result.push(a);
      Array.isArray(a.children) && a.children.forEach(iter);
    });
    return result;
  }

  toggleTreeCollapse = item => {
    let filteredTreeData = this.state.filteredTreeData;
    this.findAndReplace(filteredTreeData, item);
    this.setState({ filteredTreeData });
  }

  findAndReplace = (treeData, item) => {
    treeData.forEach(data => {
      if (data.id === item.id) {
        data.isCollapse = !data.isCollapse;
      } else if (data.children && data.children.length > 0) {
        this.findAndReplace(data.children, item);
      }
    });
  }

  getChildren = locations => {
    let list = [];
    locations.forEach(loc => {
      let d = { id: loc.locationid, label: loc.name, isCollapse: true };
      d.children = loc.children ? this.getChildren(loc.children) : [];
      list.push(d);
    });
    return list;
  }

  onChangeTreeItem = (isChecked, item) => {
    let itemList = flatten([item]);
    itemList.push(item);
    this.onLocationChangeInTree(isChecked, itemList.map(i => i.id));
  }

  getTabContentAtIndex = index => {
    switch (index) {
      case 0:
        return (
          <Row>
            <React.Fragment>
              <ListView
                data={this.state.filteredListData}
                selectedLocations={this.state.selectedLocations}
                onChange={this.onChangeListItem}
                onSearch={text => this.onSearch(text, "list")}
              />
            </React.Fragment>
          </Row>
        );
        break;
      case 1:
        return (
          <Row>
            <React.Fragment>
              <Treeview
                data={this.state.filteredTreeData}
                //selectedPlants={this.props.selectedPlants}
                selectedLocations={this.state.selectedLocations}
                onChange={this.onChangeTreeItem}
                toggleCollapse={this.toggleTreeCollapse}
                enableCheckbox={true}
                onSearch={text => this.onSearch(text, "tree")}
              />
            </React.Fragment>
          </Row>
        );
        break;
        return (
          <React.Fragment>
            <div className='comingSoon'>Coming Soon...</div>
          </React.Fragment>
        )
    }
  }

  render() {
    return (
      <div className="location-selector-container">
        <div className='selectLocation'>
          <a className='selectLocationLink' onClick={this.toggleDrawer}>Add Plant / Location</a>
        </div>
        {/* <ReactDrawer
          open={this.state.open}
          position={this.state.position}
          onClose={this.onDrawerClose}
          noOverlay={this.state.noOverlay}
        >
          <i onClick={this.closeDrawer} className="icono-cross"></i>
          <div style={{ margin: "8rem 3rem 3rem 3rem" }}>
            <h3 className="RightDrawerHeading">{this.props.label}</h3>
            <Row className="drawercontainer">
              <Col className='' xl={12} lg={12} md={12} sm={12} xs={12}>
                <Tabs
                  noJustified={true}
                  noOfTabs={TABS.length}
                  size='lg'
                  getTabContentAtIndex={this.getTabContentAtIndex}
                  getTabHeaderAtIndex={(i) => { return <div className='widget-builder-header'>{TABS[i]}</div> }}
                  vertical={false}
                  activeTabIndex={0}
                  tabClickedAtIndex={index => { this.setState({ filteredListData: this.state.listData, filteredTreeData: this.state.treeData }) }}
                />
              </Col>
            </Row>
          </div>
        </ReactDrawer> */}
      </div>);
  }
}

export default LocationSelector;