import * as AppConstants from './constant';

export function getCalculatedParameterList(value) {
    return {
        type: AppConstants.GET_ALL_CALCULATED_PARAMETER_LIST,
        payload: value
    }
}

export function isOpenCalculatedParameterModal(value) {
    return {
        type: AppConstants.IS_OPEN_CALCULATED_PARAMETER_MODAL,
        payload: value
    }
}

export function checkAllCalculatedParameters(checkedFlag, value) {
    return {
        type: AppConstants.CHECK_ALL_CALCULATED_PARAMETERS,
        payload: { checkedFlag, value }
    }
}

export function checkCalculatedParameter(isChecked, data) {
    return {
        type: AppConstants.CHECK_CALCULATED_PARAMETER,
        payload: { isChecked, data }
    }
}

export function getAllClientList(value) {
    return {
        type: AppConstants.GET_ALL_CLIENT_LIST,
        payload: value
    }
}

export function getAllPlantList(value) {
    return {
        type: AppConstants.GET_ALL_PLANT_LIST,
        payload: value
    }
}

export function deleteCalculatedParameter(value) {
    return {
        type: AppConstants.DELETE_CALCULATED_PARAMETER,
        payload: value
    }
}

export function resetDeletedCalParameter(value) {
    return {
        type: AppConstants.DELETE_CALCULATED_PARAMETER_RESET,
        payload: value
    }
}

export function createCalculatedParameter(value) {
    return {
        type: AppConstants.CREATE_CALCULATED_PARAMETERS,
        payload: value
    }
}

export function resetCreateCalculatedParameter(value) {
    return {
        type: AppConstants.CREATE_CALCULATED_PARAMETER_RESET,
        payload: value
    }
}

export function resetCheckdataCalculatedParameter(value) {
    return {
        type: AppConstants.CHECKDATA_CALCULATED_PARAMETER_RESET,
        payload: value
    }
}

export function updateCalculatedParameter(value) {
    return {
        type: AppConstants.UPDATE_CALCULATED_PARAMETER,
        payload: value
    }
}

export function resetUpdateCalculatedParameter(value) {
    return {
        type: AppConstants.UPDATE_CALCULATED_PARAMETER_RESET,
        payload: value
    }
}

export function verifyTargetSensorParameter(value) {
    return {
        type: AppConstants.VERIFY_TARGET_SENSOR_PARAMETER,
        payload: value
    }
}