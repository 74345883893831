export const DASHBOARD = {
    EDIT_DASHBOARD: 'Edit Dashboard',
    CREATE_DASHBOARD: 'Create Dashboard',
    DOWNLOAD_DASHBOARD_AS_PDF: 'Download Dashboard as PDF',
    DOWNLOAD_DASHBOARD_AS_IMAGE: 'Download Dashboard as Image',
    DOWNLOAD_WIDGET: 'Download widget',
    POPOUT_MODE_WIDGET: 'Popout Mode widget',
    TV_MODE: 'TV Mode widget',
    GET_CALCULATED_PARAM_ACTIVITY_LOG: 'Get Calculated Param Activity Log',
    DELETE_CALCULATED_PARAM_BY_ID: 'Delete Calculated Param by Id',
    EDIT_CALCULATED_PARAM_BY_ID: 'Edit Calculated Param by Id',
    GET_CALCULATED_PARAM_BY_ID: 'Get Calculated Param by Id',
    GET_ALL_CALCULATED_PARAMS: 'Get All Calculated Params',
    CREATE_CALCULATE_PARAMS: 'Create Calculated Params',
    GET_DEO_SUMMARY_DATA: 'Get Geo Summary Data',
    GET_WIDGET_ACTIVITY_LOGS: 'Get Widget activity logs',
    DELETE_WIDGET_OF_DASHBOARD_BY_ID: 'Delete Widget of Dashboard by Id',
    EDIT_WIDGET_OF_DASHBOARD_BY_ID: 'Edit Widget of Dashboard by Id',
    GET_WIDGET_OF_DASHBOARD_BY_ID: 'Get Widget of Dashboard by Id',
    ADD_WIDGET_TO_DASHBOARD_BY_ID: 'Add Widget to dashbord by Id',
    GET_WIDGETS_OF_DASHBOARD_BY_ID: 'Get Widgets of dashboard by Id',
    GET_LIBRARY_WIDGET_BY_ID: 'Get Library Widget by Id',
    GET_LIBRARY_WIDGETS: 'Get Library Widgets',
    GET_DASHBOARD_ACTIVITY_LOGS: 'Get Dashboard activity logs',
    DELETE_DASHBOARD_BY_ID: 'Delete Dashboard by Id',
    EDIT_DASHBOARD_BY_ID: 'Edit Dashboard by Id',
    GET_DASHBOARD_BY_ID: 'Get Dashboard by Id',
    GET_ALL_DASHBOARDS: 'Get All Dashboards',
}

export const DATA_UPLOAD = {
    DOWNLOAD_UPLOAD_TEMPLATE: "Download Upload Template",
    GET_UPLOAD_TEMPLATE_ACTIVITY_LOGS: "Get Upload Template Activity Logs",
    DELETE_UPLOAD_TEMPLATE_BY_ID: "Delete Upload Template by Id",
    EDIT_UPLOAD_TEMPLATE_BY_ID: "Delete Upload Template by Id",
    GET_UPLOAD_TEMPLATE_BY_ID: "Get Upload Template by Id",
    GET_ALL_UPLOAD_TEMPLATES: "Get All Upload Templates",
    CREATE_UPLOAD_TEMPLATE: "Create Upload Template",
    GET_UPLOADED_FILE_LOG_BY_ID: "Get Uploaded file log by Id",
    GET_ALL_UPLOADED_FILES_LOGS: "Get all Uploaded files logs",
    UPLOAD_DATA_FILE: "Upload Data File"
}

export const IAM_USER = {
    GET_WIDGETS_OF_DASHBOARD_BY_USER_ID: "Get Widgets of dashboard by User Id",
    GET_DASHBOARDS_BY_USER_ID: "Get Dashboards by User Id",
    DELETE_USER_DETAILS_BY_ID: "Delete User Details by Id",
    EDIT_USER_DETAILS_BY_ID: "Edit User Details by Id",
    GET_USER_DETAILS_BY_ID: "Get User Details by Id",
    GET_ALL_USERS: "Get All Users",
    CREATE_NEW_USER: "Create New User",
    GET_ROLE_DETAILS_BY_ID: "Get Role detail by Id",
    GET_ALL_ROLES: "Get All Roles",
}

export const EVENT = {
    // GET_ALL_EVENT_CONFIGS: "Get All Event Configs",
    DOWNLOAD_EVENTS_LOG: "Download Events Log",
    // DELETE_EVENT_BY_ID: "Delete Event by Id",
    EDIT_EVENT_BY_ID: "Edit Event by Id",
    // GET_EVETN_BY_ID: "Get Event by Id",
    // GET_ALL_EVENTS: "Get All Events",
    CREATE_EVENT: "Create Event",
}

export const ALERT = {
    DOWNLOAD_GENERATED_ALERT_STATUS_LOGS: "Download Generated Alert Status logs",
    DONWLOAD_ALERT_OCCURANCE_LOGS: "Download Alert Occurance Logs",
    DOWNLOAD_GENERATED_ALERT_LOGS: "Download Generated Alert logs",
    // GET_GENERATED_ALERT_LOGS: "Get Generated Alert logs",
    DELETE_ALERT_BY_ID: "Delete Alert by Id",
    EDIT_ALERT_BY_ID: "Edit Alert by Id",
    // GET_ALERT_BY_ID: "Get Alert by Id",
    // GET_ALERTS: "Get Alerts",
    DELETE_ALERT: "Delete Alert",
    EDIT_ALERT: "Edit Alert",
    CREATE_ALERT: "Create Alert",
    // GET_ALERT_OCCURANCE_ACTIVITY_LOGS: "Get Alert Occurance Activity Logs",
    // GET_ALERT_OCCURANCE_BY_ID: "Get Alert Occurance Log by Id",
    // GET_ALERT_OCCURANCE_LOGS: "Get Alert Occurance Logs",
    // GET_ALERT_OVERVIEW_SUMMARY: "Get Alert Overview Summary",
    // GET_ALERT_MONITORING_SUMMARY: "Get Alert Monitoring Summary",
    // GET_ALERT_TREND_SUMMARY: "Get Alert Trend Summary",
    // GET_ALERT_STATUS_SUMMARY: "Get Alert Status summary",
    // GET_ALERTS_SUMMARY: "Get Alerts Summary",
}

export const TEMPALTES = {
    GET_ASSET_NAME_DETAIL_BY_ID: "Get Asset Name Detail by Id",
    GET_ALL_ASSET_NAMES:  "Get All Asset Names",
    DELETE_WIDGET_OF_TEMPLATE_BY_ID: "Delete widget of Template by Id",
    EDIT_WIDGET_OF_TEMPLATE_BY_ID: "Edit widget of Template by Id",
    GET_WIDGET_OF_TEMPLATE_BY_ID: "Get widget of Template by Id",
    GET_WIDGETS_OF_TEMPLATE_BY_ID: "Get widgets of Template by Id",
    ADD_WIDGET_TO_TEMPLATE: "Add widget to Template",
    DELETE_TEMPLATE_FOR_SERVICE_BY_ID: "Delete Template for Service by Id",
    EDIT_TEMPLATE_FOR_SERVICE_BY_ID: "Edit Template for Service by Id",
    GET_TEMPLATE_FOR_SERVICE_BY_ID: "Get Template for Service by Id",
    GET_TEMPLATE_FOR_SERVICE:  "Get Templates for Service",
    CREATE_TEMPLATE_FOR_SERVICE: "Create Template for Service"
}

export const REPORT = {
    // GENERATE_STANDARD_REPORT: "Generate Standard Report",
    // GET_GENERATED_REPORT_LOGS: "Get Generated Report logs",
    DOWNLOAD_REPORT_BY_ID: "Download Report by Id",
    // DELETE_REPORT_BY_ID: "Delete Report by Id",
    EDIT_REPORT_BY_IB: "Edit Report by Id",
    // GET_REPORT_BY_IB: "Get Report by Id",
    DELETE_REPORTS: "Delete Reports",
    CREATE_REPORT: "Create Report",
    // GET_ALL_REPORTS: "Get All Reports",
}

export const NOTIFICATION = {
    EDIT_USER_NOTIFICATION_PREFERENCE_BY_ID: "Edit User Notification Preference by Id",
    EDIT_NOTFICATION_BY_ID: "Edit Notification by Id",
    GET_NOTIFICATION_BY_ID: "Get Notification by Id",
    GET_ALL_NOTIFICATIONS: "Get All Notifications",
    GET_USER_NOTIFICATION_PREFERENCE_BY_ID: "Get User Notification Preference by Id",
    GET_USER_NOTIFICATION_PREFERENCES: "Get User Notification Preferences",
    GET_ALL_NOTIFICATION_CATEGORY: "Get All Notification Category",
}

export const PARTNER = {
    LOGOUT_USER_OF_CLIENT: "Logout User of Client",
    LOGIN_USER_OF_CLIENT: "Login User of Client",
    GET_ALL_USER_OF_CLIENT_BY_PLANT_ID: "Get All User of Client by Plant Id",
    GET_ALL_LOCATIONS_OF_CLIENT: "Get All Locations of Client",
    GET_ALL_PLANT_OF_CLIENT: "Get All Plant of Client",
    GET_CLIENT_BY_ID: "Get Client by Id",
    GET_ALL_CLIENTS: "Get All Clients"
}

export const ASSET_INSIGHT = {
    GET_ANOMALY_FOR_ASSET_INSIGHTS: "Get Anomaly for Asset Insights",
    CREATE_ALERT_ACTION_FOR_INSIGHT_ALERTS: "Create Alert Action for Insight Alerts",
    GET_ALL_GENERATED_ALERTS_FOR_ASSET_INSIGHTS: "Get All Generated Alerts for Asset Insights",
    GET_ALL_ASSETS_INSIGHT_EVENTS: "Get All Assets Insght Events",
    GET_ALL_ACTIVITY_ON_OPEN_INSIGHT: "Get all Activity on Open Insight",
    CREATE_ACTIVITY_ON_OPEN_INSIGHT: "Create Activity on Open Insight",
    GET_ALL_ACTION_ON_OPEN_INSIGHT: "Get all Action on Open Insight",
    CREATE_ACTION_ON_OPNE_INSIHGT: "Create Action on Open Insight",
    GET_ALL_ASSETS_INSGIHT_OPEN_STATUS: "Get All Assets Insight Open status",
    GET_ALL_ASSETS_INSIGHT_ASSET_BY_ID: "Get All Assets Insight Asset by Id",
    GET_ALL_ASSETS_INSIGHT_LIST_OF_ASSETS: "Get All Assets Insight List of Assets",
    GET_ALL_ASSETS_INSIGHT_TIMELINE: "Get All Assets Insight Timeline",
    GET_ALL_ASSETS_INSIGHT_STATS: "Get All Assets Insight Stats",
    DELETE_FEEDBACK_ACTIVITY_ONINSIGHT_BY_ID: "Delete Feedback Activity on Inisight by Id",
    GET_FEEDBACK_ACTIVITY_ONINSIGHT_BY_ID: "Get Feedback Activity on Inisight by Id",
    EDIT_FEEDBACK_ACTIVITY_ONINSIGHT_BY_ID: "Edit Feedback Activity on Inisight by Id",
    ADD_FEEDBACK_ACTIVITY_ONINSIGHT_BY_ID: "Add Feedback Activity on Inisight",
    GET_ALL_INSIGHT_FEEDBACKS_OF_USER: "Get All Insight Feedbacks of user",
    GET_CONFIGURED_INSIGHT_BY_CONFIG_ID: "Get Configured Insight by Config Id",
    GET_CONFIGURED_INSIGHTS_BY_ASSETNAME_ID: "Get Configured Insights by AssetName Id",
    GET_ASSET_INSIGHTS_DATA: "Get Asset Insights Data",
    GET_DEFAULT_ASSET_TEMPLATE_DETAILS: "Get Default Asset Template Details",
    GET_ASSET_DETAILS_BY_ASSETID: "Get Asset Details by AssetId",
    GET_ASSETS_DETAILS_BY_ASSETNAME_ID: "Get Assets Details by AssetName Id",
    GET_ASSETS_OVERVIEW_DETAILS: "Get Assets Overview Details",
    GET_ASSETS_AND_ITS_STATS: "Get Assets and its Stats",
}

export const CALCULATED_PARAMETER = {
    CREATE_CALCULATED_PARAMETER: "Create Calculated Parameter",
    EDIT_CAL_PARAMETER_BY_ID: "Edit Calculated Parameter by Id",
    DELETE_CAL_PARAMETER_BY_ID: "Delete Calculated Parameter by Id",
}

export const ASSET_CALCULATED_PARAMETER = {
    CREATE_ASSET_CALCULATED_PARAMETER: "Create Asset Calculated Parameter",
    EDIT_ASSET_CAL_PARAMETER_BY_ID: "Edit Asset Calculated Parameter by Id",
}