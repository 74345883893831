import {
    GET_REFRESH_DATA,
    GET_REFRESH_DATA_SUCCESS,
    GET_REFRESH_DATA_FAILURE,
    GET_READING_DATA,
    GET_READING_DATA_SUCCESS,
    GET_READING_DATA_FAILURE,
    GET_COMPUTED_READING_DATA,
    GET_COMPUTED_READING_DATA_SUCCESS,
    GET_COMPUTED_READING_DATA_FAILURE,
    GET_COMPUTED_READING_DATA_SUCCESS_TREND,
    GET_FAULT_READING_DATA,
    GET_FAULT_READING_DATA_FAILURE,
    GET_FAULT_READING_DATA_SUCCESS,
    GET_FAULT_READING_DATA_SUCCESS_TREND,
    GET_MEMBER_PROFILE_DATA,
    GET_MEMBER_PROFILE_DATA_SUCCESS,
    GET_MEMBER_PROFILE_DATA_FAILURE,
    CLOSE_RIGHT_SIDE_DRAWER, 
    OPEN_RIGHT_SIDE_DRAWER,
    GET_COMPUTED_PARAMETER,
    GET_COMPUTED_PARAMETER_FAILURE,
    GET_COMPUTED_PARAMETER_SUCCESS,
    GET_READING_TYPES_PRELOAD,
    GET_READING_TYPES_PRELOAD_SUCCESS,
    GET_READING_TYPES_PRELOAD_FAILURE,
    GET_CUSTOM_DASHBOARD,
    GET_CUSTOM_DASHBOARD_SUCCESS,
    GET_CUSTOM_DASHBOARD_FAILURE,
    CREATE_CUSTOM_DASHBOARD,
    CREATE_CUSTOM_DASHBOARD_SUCCESS,
    CREATE_CUSTOM_DASHBOARD_FAILURE
} from '../constants'

const INITIAL_STATE = {
    data: [],
    loading: false,
    error: false,
    isOpenRightSideDrawer: false,
    success: undefined
};

export function getRefreshDataReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_REFRESH_DATA:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: false
            }
        case GET_REFRESH_DATA_SUCCESS:
            // localStorage.removeItem("logindata")
            let a = action.payload;

            // localStorage.setItem("smartsense.member_plants", JSON.stringify(a.member_plants))
            // localStorage.setItem("smartsense.locationlist", JSON.stringify(a.locations))

            // localStorage.setItem("smartsense.member_service_permissions", JSON.stringify(a.member_service_permissions))

            // localStorage.setItem("smartsense.member", JSON.stringify(a.member))
            // localStorage.setItem("smartsense.readingtypes", JSON.stringify(a.readingtypes))
            // localStorage.setItem("smartsense.version_readingtype_mapping", JSON.stringify(a.version_readingtype_mapping));
            // localStorage.setItem("smartsense.sensors", JSON.stringify(a.sensors))
            // localStorage.setItem("smartsense.decode_alarm_mapping", JSON.stringify(a.decode_alarm_mapping))
            // // localStorage.setItem("smartsense.member_plants", JSON.stringify(a.member_plants))
            // localStorage.setItem("smartsense.service_config", JSON.stringify(a.service_config))

						localStorage.setItem("smartsense.admin_member", JSON.stringify(a.member))

            localStorage.setItem("smartsense.member_plants", JSON.stringify(a.plants))
            localStorage.setItem("smartsense.member_locations", JSON.stringify(a.member_locations))
            localStorage.setItem("smartsense.locationlist", JSON.stringify(a.locations))
      
            localStorage.setItem("smartsense.member_service_permissions", JSON.stringify(a.member_service_permissions))
            localStorage.setItem("smartsense.member", JSON.stringify(a.member))
      
            localStorage.setItem("smartsense.readingtypes", JSON.stringify(a.reading_types))
            localStorage.setItem("smartsense.version_readingtype_mapping", JSON.stringify(a.msg_version_readingtypes));
            localStorage.setItem("smartsense.sensors", JSON.stringify(a.sensors))
            localStorage.setItem("smartsense.decode_alarm_mapping", JSON.stringify(a.decode_alarm_mapping))
            localStorage.setItem("smartsense.service_config", JSON.stringify(a.service_config))

            return {
                ...state,
                data: action.payload,
                isTokenValid:true,
                error: false,
                loading: false,
                success: true
            }
        case GET_REFRESH_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                loading: false,
                success: false
            }
        default:
            return state
    }
}

export const getMemberProfileDataReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_MEMBER_PROFILE_DATA:
            return {
                ...state,
                loading: true,
                data: [],
                error: false
            };
        case GET_MEMBER_PROFILE_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                loading: false
            };
        case GET_MEMBER_PROFILE_DATA_SUCCESS:
            const { payload } = action;
            localStorage.setItem("smartsense.member_profile", JSON.stringify({
                holidays: payload.plantholidays,
                shift: payload.plantshifts
            }));
            return {
                ...state,
                data: payload,
                error: false,
                loading: false
            };
        default:
            return state;
    }
};

export function getReadingDataReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_READING_DATA:
            return {
                ...state,
                loading: true,
                data: {},
                error: false
            }
        case GET_READING_DATA_SUCCESS:
            let newData = {};
            if (action.payload && action.payload[0].multipleGraph === true) {
                let hashedData = { ...state.data.data };
                hashedData[JSON.stringify(action.payload[0]).trim()] = action.payload[1];

                newData = {
                    data: hashedData,
                    multipleGraph: true
                }
            } else {
                newData = {
                    data: action.payload[1] || [],
                    multipleGraph: false
                }
            }

            return {
                ...state,
                data: newData,
                error: false,
                loading: false
            }
        case GET_READING_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: {},
                loading: false
            }
        default:
            return state
    }
}


export function getComputedReadingDataReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_COMPUTED_READING_DATA:
            return {
                ...state,
                loading: true,
                data: [],
                error: false
            }
        case GET_COMPUTED_READING_DATA_SUCCESS:
            let prevState = { ...state };

            return {
                data: [action.payload || [], prevState.data[1] || []],
                error: false,
                loading: false
            }
        case GET_COMPUTED_READING_DATA_SUCCESS_TREND:
            let prevState1 = { ...state };

            return {
                data: [prevState1.data[0] || [], action.payload || []],
                error: false,
                loading: false
            }
        case GET_COMPUTED_READING_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                loading: false
            }
        default:
            return state
    }
}

export function getFaultReadingDataReducer(state = INITIAL_STATE, action) {

    switch (action.type) {
        case GET_FAULT_READING_DATA:
            return {
                ...state,
                loading: true,
                data: [],
                error: false
            }
        case GET_FAULT_READING_DATA_SUCCESS:
            let prevState = { ...state };

            let reading_id = action.payload[0].readingtype[0].readingtypeid;
            let dataToPush = {};

            dataToPush[reading_id] = action.payload[0].reading_series[0]["timeseries"];

            return {
                ...state,
                data: [dataToPush || [], prevState.data[1] || []],
                error: false,
                loading: false
            }
        case GET_FAULT_READING_DATA_SUCCESS_TREND:
            let prevState1 = { ...state };

            let reading_id1 = action.payload[0].readingtype[0].readingtypeid;
            let dataToPush1 = {};

            dataToPush1[reading_id1] = action.payload[0].reading_series[0]["timeseries"];


            return {
                ...state,
                data: [prevState1.data[0] || [], dataToPush1 || []],
                error: false,
                loading: false
            }
        case GET_FAULT_READING_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                loading: false
            }
        default:
            return state
    }
}

export const getCalculatedParameterReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_COMPUTED_PARAMETER:
            return {
                ...state,
                loading: true,
                data: [],
                error: false
            };
        case GET_COMPUTED_PARAMETER_SUCCESS:
            localStorage.setItem("smartsense.computed_parameter_list", JSON.stringify(action.payload.result))
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_COMPUTED_PARAMETER_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                loading: false
            };
        default:
            return state;
    }
};

export function rightSideDrawerReducer(state = INITIAL_STATE, action) {

    switch (action.type) {
        case OPEN_RIGHT_SIDE_DRAWER:
            return {
                ...state,
                isOpenRightSideDrawer: true
            }
        case CLOSE_RIGHT_SIDE_DRAWER:
            return {
                ...state,
                isOpenRightSideDrawer: false
            }
        default:
            return state
    }
}

// export const getReadingTypesPreloadReducer = (state = INITIAL_STATE, action) => {
//     switch (action.type) {
//         case GET_READING_TYPES_PRELOAD:
//             return {
//                 ...state,
//                 loading: true,
//                 data: [],
//                 error: false
//             };
//         case GET_READING_TYPES_PRELOAD_SUCCESS:
//             localStorage.setItem("smartsense.readingtypes", JSON.stringify(action.payload))
//             return {
//                 ...state,
//                 data: action.payload,
//                 error: false,
//                 loading: false
//             };
//         case GET_READING_TYPES_PRELOAD_FAILURE:
//             return {
//                 ...state,
//                 error: true,
//                 data: [],
//                 loading: false
//             };
//         default:
//             return state;
//     }
// };
