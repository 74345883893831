
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const TextEditor = (props) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(()=> {
    const contentBlock = htmlToDraft(props.initialStateHtml);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState)
    }
  },[props.initialStateHtml]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    props.onEditorChange(htmlContent, editorState);
  };

  return (
    <div>
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap"
          rel="stylesheet"
        />
      </head>
      <Editor
        editorState={editorState}
        toolbarClassName="text-editor-toolbar"
        wrapperClassName="text-editor-wrapper"
        editorClassName="text-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history']
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
          embedded: {
            inDropdown: true,
            embedCallback: ()=>{
              // add link validation
            },
            defaultSize: {
              height: "100%",
              width: "100%",
            },
          },
          fontFamily: {
            options: ['Montserrat'],
          },
          image: {
            inDropdown: true,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: ()=>{
              // add link validation
            },
            previewImage: false,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: '100%',
              width: '100%',
            },
          },
        }}
      />
    </div>
  );
}


TextEditor.defaultProps = {
  onEditorChange: () => {},
  initialStateHtml: ''
}

TextEditor.propTypes = {
  onEditorChange: PropTypes.func,
  initialStateHtml: PropTypes.string
}

export default TextEditor;