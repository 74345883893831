export const GET_ALL_DASHBOARD = 'GET_ALL_DASHBOARD';
export const GET_ALL_DASHBOARD_SUCCESS = 'GET_ALL_DASHBOARD_SUCCESS';
export const GET_ALL_DASHBOARD_FAILURE = 'GET_ALL_DASHBOARD_FAILURE';
export const SORT_ALL_DASHBOARD = 'SORT_ALL_DASHBOARD';

export const GET_SINGLE_DASHBOARD = 'GET_SINGLE_DASHBOARD';
export const GET_SINGLE_DASHBOARD_SUCCESS = 'GET_SINGLE_DASHBOARD_SUCCESS';
export const GET_SINGLE_DASHBOARD_FAILURE = 'GET_SINGLE_DASHBOARD_FAILURE';

export const ADD_DASHBOARD = 'ADD_DASHBOARD';
export const ADD_DASHBOARD_SUCCESS = 'ADD_DASHBOARD_SUCCESS';
export const ADD_DASHBOARD_FAILURE = 'ADD_DASHBOARD_FAILURE';
export const ADD_DASHBOARD_RESET = 'ADD_DASHBOARD_RESET';

export const EDIT_DASHBOARD = 'EDIT_DASHBOARD';
export const EDIT_DASHBOARD_SUCCESS = 'EDIT_DASHBOARD_SUCCESS';
export const EDIT_DASHBOARD_FAILURE = 'EDIT_DASHBOARD_FAILURE';
export const EDIT_DASHBOARD_RESET = 'EDIT_DASHBOARD_RESET';

export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_FAILURE = 'DELETE_DASHBOARD_FAILURE';
export const DELETE_DASHBOARD_RESET = 'DELETE_DASHBOARD_RESET';

export const GET_ALL_WIDGET = 'GET_ALL_WIDGET';
export const GET_ALL_WIDGET_SUCCESS = 'GET_ALL_WIDGET_SUCCESS';
export const GET_ALL_WIDGET_FAILURE = 'GET_ALL_WIDGET_FAILURE';


export const GET_ALL_WIDGET_CONFIG = 'GET_ALL_WIDGET_CONFIG';
export const GET_ALL_WIDGET_CONFIG_SUCCESS = 'GET_ALL_WIDGET_CONFIG_SUCCESS';
export const GET_ALL_WIDGET_CONFIG_FAILURE = 'GET_ALL_WIDGET_CONFIG_FAILURE';

export const GET_ALL_WIDGET_CONFIG_EXTENDED = 'GET_ALL_WIDGET_CONFIG_EXTENDED';
export const GET_ALL_WIDGET_CONFIG_EXTENDED_SUCCESS =
  'GET_ALL_WIDGET_CONFIG_EXTENDED_SUCCESS';
export const GET_ALL_WIDGET_CONFIG_EXTENDED_FAILURE =
  'GET_ALL_WIDGET_CONFIG_EXTENDED_FAILURE';

export const GET_WIDGET_CONFIG_BY_ID = 'GET_WIDGET_CONFIG_BY_ID';
export const GET_WIDGET_CONFIG_BY_ID_SUCCESS = 'GET_WIDGET_CONFIG_BY_ID_SUCCESS';
export const GET_WIDGET_CONFIG_BY_ID_FAILURE = 'GET_WIDGET_CONFIG_BY_ID_FAILURE';

export const GET_WIDGET_CONFIG = 'GET_WIDGET_CONFIG';
export const GET_WIDGET_CONFIG_SUCCESS = 'GET_WIDGET_CONFIG_SUCCESS';
export const GET_WIDGET_CONFIG_FAILURE = 'GET_WIDGET_CONFIG_FAILURE';


export const CREATE_WIDGET = 'CREATE_WIDGET';
export const CREATE_WIDGET_SUCCESS = 'CREATE_WIDGET_SUCCESS';
export const CREATE_WIDGET_FAILURE = 'CREATE_WIDGET_FAILURE';
export const CREATE_WIDGET_RESET = 'CREATE_WIDGET_RESET';

export const EDIT_WIDGET = 'EDIT_WIDGET';
export const EDIT_WIDGET_SUCCESS = 'EDIT_WIDGET_SUCCESS';
export const EDIT_WIDGET_FAILURE = 'EDIT_WIDGET_FAILURE';
export const EDIT_WIDGET_RESET = 'EDIT_WIDGET_RESET';

export const DELETE_WIDGET = 'DELETE_WIDGET';
export const DELETE_WIDGET_SUCCESS = 'DELETE_WIDGET_SUCCESS';
export const DELETE_WIDGET_FAILURE = 'DELETE_WIDGET_FAILURE';
export const DELETE_WIDGET_RESET = 'DELETE_WIDGET_RESET';

export const CREATE_DASHBOARD_GLOBAL_ACTION = 'CREATE_DASHBOARD_GLOBAL_ACTION';
export const CREATE_DASHBOARD_GLOBAL_ACTION_RESET = 'CREATE_DASHBOARD_GLOBAL_ACTION_RESET';

export const GET_WIDGET_HISTORY = 'GET_WIDGET_HISTORY';
export const GET_WIDGET_HISTORY_SUCCESS = 'GET_WIDGET_HISTORY_SUCCESS';
export const GET_WIDGET_HISTORY_FAILURE = 'GET_WIDGET_HISTORY_FAILURE';

export const GET_DASHBOARD_CONFIG_HISTORY = 'GET_DASHBOARD_CONFIG_HISTORY';
export const GET_DASHBOARD_CONFIG_HISTORY_SUCCESS = 'GET_DASHBOARD_CONFIG_HISTORY_SUCCESS';
export const GET_DASHBOARD_CONFIG_HISTORY_FAILURE = 'GET_DASHBOARD_CONFIG_HISTORY_FAILURE';

export const SORT_DASHBOARD = 'SORT_DASHBOARD';
export const SORT_DASHBOARD_SUCCESS = 'SORT_DASHBOARD_SUCCESS';
export const SORT_DASHBOARD_FAILURE = 'SORT_DASHBOARD_FAILURE';
export const SORT_DASHBOARD_RESET = 'SORT_DASHBOARD_RESET';

export const SET_POPOUT_WIDGET = 'SET_POPOUT_WIDGET';
export const OPEN_POPOUT_WIDGET = 'OPEN_POPOUT_WIDGET';
export const CLOSE_POPOUT_WIDGET = 'CLOSE_POPOUT_WIDGET';

export const SET_FACTORY_BACKDROP = 'SET_FACTORY_BACKDROP'

export const GET_CUSTOM_DASHBOARD = 'GET_CUSTOM_DASHBOARD';
export const GET_CUSTOM_DASHBOARD_SUCCESS = 'GET_CUSTOM_DASHBOARD_SUCCESS';
export const GET_CUSTOM_DASHBOARD_FAILURE = 'GET_CUSTOM_DASHBOARD_FAILURE';

export const GET_CUSTOM_DASHBOARD_EXTENDED = 'GET_CUSTOM_DASHBOARD_EXTENDED';
export const GET_CUSTOM_DASHBOARD_EXTENDED_SUCCESS = 'GET_CUSTOM_DASHBOARD_EXTENDED_SUCCESS';
export const GET_CUSTOM_DASHBOARD_EXTENDED_FAILURE = 'GET_CUSTOM_DASHBOARD_EXTENDED_FAILURE';

export const CREATE_CUSTOM_DASHBOARD = 'CREATE_CUSTOM_DASHBOARD';
export const CREATE_CUSTOM_DASHBOARD_SUCCESS = 'CREATE_CUSTOM_DASHBOARD_SUCCESS';
export const CREATE_CUSTOM_DASHBOARD_FAILURE = 'CREATE_CUSTOM_DASHBOARD_FAILURE';

export const CREATE_DASHBOARD_SEQUENCE = 'CREATE_DASHBOARD_SEQUENCE';
export const CREATE_DASHBOARD_SEQUENCE_SUCCESS = 'CREATE_DASHBOARD_SEQUENCE_SUCCESS';
export const CREATE_DASHBOARD_SEQUENCE_FAILURE = 'CREATE_DASHBOARD_SEQUENCE_FAILURE';
export const CREATE_DASHBOARD_SEQUENCE_RESET = 'CREATE_DASHBOARD_SEQUENCE_RESET';

export const GET_DASHBOARD_SEQUENCE = 'GET_DASHBOARD_SEQUENCE';
export const GET_DASHBOARD_SEQUENCE_SUCCESS = 'GET_DASHBOARD_SEQUENCE_SUCCESS';
export const GET_DASHBOARD_SEQUENCE_FAILURE = 'GET_DASHBOARD_SEQUENCE_FAILURE';

export const GET_DASHBOARD_SEQUENCE_EXTENDED = 'GET_DASHBOARD_SEQUENCE_EXTENDED';
export const GET_DASHBOARD_SEQUENCE_EXTENDED_SUCCESS = 'GET_DASHBOARD_SEQUENCE_EXTENDED_SUCCESS';
export const GET_DASHBOARD_SEQUENCE_EXTENDED_FAILURE = 'GET_DASHBOARD_SEQUENCE_EXTENDED_FAILURE';

export const CREATE_HEALTH_DATA = 'CREATE_HEALTH_DATA';
export const CREATE_HEALTH_DATA_SUCCESS = 'CREATE_HEALTH_DATA_SUCCESS';
export const CREATE_HEALTH_DATA_FAILURE = 'CREATE_HEALTH_DATA_FAILURE';
export const CREATE_HEALTH_DATA_RESET = 'CREATE_HEALTH_DATA_RESET';

export const UPDATE_HEALTH_DATA = 'UPDATE_HEALTH_DATA';
export const UPDATE_HEALTH_DATA_SUCCESS = 'UPDATE_HEALTH_DATA_SUCCESS';
export const UPDATE_HEALTH_DATA_FAILURE = 'UPDATE_HEALTH_DATA_FAILURE';

export const DISCOVERY_UPGRADE_EMAIL = 'DISCOVERY_UPGRADE_EMAIL';
export const DISCOVERY_UPGRADE_EMAIL_SUCCESS = 'DISCOVERY_UPGRADE_EMAIL_SUCCESS';
export const DISCOVERY_UPGRADE_EMAIL_FAILURE = 'DISCOVERY_UPGRADE_EMAIL_FAILURE';

export const GET_ASSET_DYNAMIC_VARIABLES = 'GET_ASSET_DYNAMIC_VARIABLES';
export const GET_ASSET_DYNAMIC_VARIABLES_SUCCESS =
  'GET_ASSET_DYNAMIC_VARIABLES_SUCCESS';
export const GET_ASSET_DYNAMIC_VARIABLES_FAILURE =
  'GET_ASSET_DYNAMIC_VARIABLES_FAILURE';