import { GET_MEMBER_DATA, GET_MEMBER_DATA_SUCCESS, GET_MEMBER_DATA_FAILURE ,GET_ROLE_DATA,
  GET_ROLE_DATA_SUCCESS,
  GET_ROLE_DATA_FAILURE,
  CREATE_ROLE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  CREATE_MEMBER,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAILURE,
  EDIT_ROLE,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  EDIT_MEMBER,
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_FAILURE,
  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE
} from '../constants'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false
}

export function getMemberDataReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MEMBER_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case GET_MEMBER_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case GET_MEMBER_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}


export function getRoleDataReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ROLE_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case GET_ROLE_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case GET_ROLE_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}


export function createRoleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_ROLE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case CREATE_ROLE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}

export function editRoleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EDIT_ROLE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case EDIT_ROLE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case EDIT_ROLE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}


export function createMemberReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_MEMBER:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        data: action.payload.response,
        error: false,
        loading: false
      }
    case CREATE_MEMBER_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}

export function editMemberReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EDIT_MEMBER:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case EDIT_MEMBER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false
      }
    case EDIT_MEMBER_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}


export function deleteMemberReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_MEMBER:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        data: action.payload.response,
        error: false,
        loading: false
      }
    case DELETE_MEMBER_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}

export function deleteRoleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_ROLE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        data: action.payload.response,
        error: false,
        loading: false
      }
    case DELETE_ROLE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}

