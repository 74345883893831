import * as AppConstants from './constant';
import moment from 'moment';


const INITIAL_STATE = {
    data: [],
    settings: [],
    unread: 0,
    loading: false,
    error: false,
    success: undefined,
    total_pages: null,
    total_record: null,
    isNotificationOpen: false,
    // recentData: [],
    // oldestData: [],
    // isRecent: false,
    // isOldest: false,
}

export function getAllNotifications(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_NOTIFICATIONS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_NOTIFICATIONS_SUCCESS:

            let recentData = [];
            let oldestData = [];
            let isRecent = false;
            let isOldest = false;
            let updatedRecentData = [];
            let updatedOldData = [];
            let mainData = []
            let data = action.response && action.response.result;
            if (data) {
                data && data.length > 0 && Array.isArray(data) && data.map(noti => {
                    if (moment().isSame(noti.created_at, 'day')) {
                        recentData.push(noti);
                        isRecent = true
                    } else {
                        oldestData.push(noti);
                        isOldest = true;
                    }
                })
            } else if (action.payload && action.payload.socket) {
                let mainStateData = state.data;
                // mainStateData = [{ ...action.payload.socket }, ...mainStateData]
                mainStateData.unshift(action.payload.socket);
                mainData = mainStateData;

                if ((action.payload && action.payload.socket && action.payload.socket.created_at) || (action.payload && action.payload.socket && action.payload.socket.content && action.payload.socket.content.createdAt)) {
                    if (moment().isSame(action.payload.socket.created_at, 'day')) {
                        let stateData = state.recentData;
                        // stateData = [{ ...action.payload.socket }, ...stateData]
                        stateData && stateData.unshift(action.payload.socket);
                        updatedRecentData = stateData;
                        isRecent = true
                    } else if (moment().isSame(action.payload.socket.content.createdAt, 'day')) {
                        let stateData = state.recentData;
                        stateData && stateData.unshift(action.payload.socket);
                        updatedRecentData = stateData;
                        isRecent = true
                    } else {
                        let stateData = state.oldestData;
                        // stateData = [{ ...action.payload.socket }, ...stateData]
                        stateData && stateData.unshift(action.payload.socket);
                        updatedOldData = stateData;
                    }
                } else {
                    let stateData = state.oldestData;
                    // stateData = [{ ...action.payload.socket }, ...stateData]
                    stateData && stateData.unshift(action.payload.socket);
                    updatedOldData = stateData;
                }
            }
            return {
                ...state,
                // recentData,
                // oldestData,
                // isRecent,
                // isOldest,
                // total_pages: action.response.total_pages,
                // total_record: action.response.total_record,
                // unread: action.response.unread,
                data: data ? action.response && action.response.result : mainData,
                recentData: data ? recentData : updatedRecentData,
                oldestData: data ? oldestData : updatedOldData,
                isRecent: data ? isRecent : state.isRecent,
                isOldest: data ? isOldest : state.isOldest,
                total_pages: action.response && action.response.total_pages ? action.response.total_pages : state.total_pages,
                total_record: action.response && action.response.total_record ? action.response.total_record : action.payload && action.payload.socket && state.total_record + 1,
                unread: action.response && action.response.unread ? action.response.unread : action.payload && action.payload.socket && state.unread + 1,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false
            }

        // case AppConstants.GET_NOTIFICATION_SETTING_SUCCESS:
        //     // state.data && Array.isArray(state.data) && state.data.length > 0 && state.data.map(notify => {
        //     //     action.response && Array.isArray(action.response) && action.response.length > 0 && action.response.map(setting => {
        //     //         if (notify.category_id == setting.category_id) {
        //     //             notify['isNotificationSetting'] = true;
        //     //             // userSettingData.push(notify);
        //     //         }
        //     //     })
        //     // })

        //     return {
        //         ...state,
        //         settings: action.response,
        //         // data: state.data,
        //         // total_pages: state.total_pages,
        //         // total_record: state.total_record,
        //         // unread: action.response.unread,
        //         error: false,
        //         loading: false,
        //         success: true
        //     }

        // case AppConstants.GET_NOTIFICATION_SETTING_FAILURE:
        //     return {
        //         ...state,
        //         error: true,
        //         settings: [],
        //         success: false
        //     }
        default:
            return state;
    }
}

export function getAllNotificationCount(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_NOTIFICATIONS_COUNT:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_NOTIFICATIONS_COUNT_SUCCESS:
            let unreadCount = 0;
            let total_record = 0;
            let data = action.response;
            if (data && (data.unread || data.total_record)) {
                unreadCount = data.unread
                total_record = data.total_record
            } else if (action.payload && action.payload.socket) {
                let unread = state.unread;
                let total = state.total_record;
                unreadCount = unread + 1;
                total_record = total + 1;
            }

            return {
                ...state,
                // data: action.response.result,
                // total_pages: action.response.total_pages,
                total_record: total_record,
                unread: unreadCount,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_NOTIFICATIONS_COUNT_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }

        default:
            return state;
    }
}

export function getNotificationSettings(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_NOTIFICATION_SETTING:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_NOTIFICATION_SETTING_SUCCESS:
            return {
                ...state,
                data: action.response.result,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_NOTIFICATION_SETTING_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        default:
            return state;
    }
}

export function deleteNotification(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.DELETE_NOTIFICATION:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.DELETE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.DELETE_NOTIFICATION_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.DELETE_NOTIFICATION_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state
    }
}

export function updateNotification(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.UPDATE_NOTIFICATION:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.UPDATE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.UPDATE_NOTIFICATION_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.UPDATE_NOTIFICATION_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state
    }
}

export function notificationPopUp(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.NOTIFICATION_POPUP:
            return {
                ...state,
                isNotificationOpen: action.payload,
            }
        default:
            return state;
    }
}

export function updateNotificationStatus(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.UPDATE_NOTIFICATION_STATUS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.UPDATE_NOTIFICATION_STATUS_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.UPDATE_NOTIFICATION_STATUS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.UPDATE_NOTIFICATION_STATUS_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state
    }
}

export function turnOffNotification(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.TURN_OFF_NOTIFICATION:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.TURN_OFF_NOTIFICATION_SUCCESS:
            return {
                ...state,
                data: action.response.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.TURN_OFF_NOTIFICATION_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }

        case AppConstants.TURN_OFF_NOTIFICATION_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        default:
            return state;
    }
}

export function notificationCategory(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.NOTIFICATION_CATEGORY:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.NOTIFICATION_CATEGORY_SUCCESS:
            return {
                ...state,
                data: action.response.result,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.NOTIFICATION_CATEGORY_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false
            }

        default:
            return state;
    }
}

export const notificationReducer = {
    getAllNotifications,
    deleteNotification,
    updateNotification,
    notificationPopUp,
    updateNotificationStatus,
    turnOffNotification,
    notificationCategory,
    getAllNotificationCount,
    getNotificationSettings
}