import React from 'react';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';

// size sm/default

export default class CheckBox extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            checked: this.props.checked
        };
    }

    static getDerivedStateFromProps(props, state) {
        return { checked: props.checked }
    }


    toggle(e) {
        let checkedState = !this.state.checked;

        this.setState({
            checked: checkedState
        });

        this.props.checkboxClicked(checkedState, e);
    }

    render() {
        return (
          <Label
            className={`checkBoxContainer container ${this.props.size} ${this.props.className}`}
          >
            <Input
              type='checkbox'
              name={this.props.name}
              checked={this.state.checked}
              disabled={this.props.disabled}
              onChange={this.toggle}
              className={this.props.size}
            />
            <span className='checkmark' />
            <span className='text'>{this.props.label}</span>
          </Label>
        );
    }
}