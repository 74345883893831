import * as AppConstants from './constant';
import * as LoginConstants from '../../../common/container/Login/constant';

export function getAllClients(value) {
    return {
        type: AppConstants.GET_ALL_CLIENTS,
        payload: value
    }
}

export function getAllClientsBySwitchUser(value) {
    return{
        type: AppConstants.GET_ALL_CLIENTS_SUCCESS,
        response: value
    }
}

export function getAllPlants(value) {
    return {
        type: AppConstants.GET_ALL_PLANTS,
        payload: value
    }
}

export function getAllPlantsBySwitchUser(value) {
    return{
        type: AppConstants.GET_ALL_PLANTS_SUCCESS,
        response: value
    }
}

export function getAllUsersForSpecificPlant(value) {
    return {
        type: AppConstants.GET_ALL_USERS_FOR_PLANT,
        payload: value
    }
}

export function getAllUsersForSpecificPlantBySwitchUser(value) {
    return {
        type: AppConstants.GET_ALL_USERS_FOR_PLANT_SUCCESS,
        response: value
    }
}

export function getClientDashboard(value) {
    return {
        type: AppConstants.GET_CLIENT_DASHBOARD,
        payload: value
    }
}

export function getClientDashboardBySwitchUser(value) {
    return {
        type: LoginConstants.GET_LOGIN_DATA_SUCCESS,
        response: value
    }
}

export function clientLogout(value) {
    return {
        type: AppConstants.CLIENT_LOGOUT_SUCCESS,
        payload: value
    }
}

export function clientLogoutReset(value) {
    return {
        type: AppConstants.CLIENT_LOGOUT_RESET,
        payload: value
    }
}

export function switchUser(value) {
    return {
        type: AppConstants.PARTNER_MODEL,
        payload: value
    }
}