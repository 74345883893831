import {ofType} from 'redux-observable';
import {mergeMap, map, catchError} from 'rxjs/operators'
import {ajax} from 'rxjs/ajax';
import {of} from 'rxjs'
import moment from 'moment';
import _ from 'lodash';
import qs from "query-string";
import {
    AssetUrl,
    ERROR,
    ROUND_PRECISION,
    getLocalItem,
    getPercentageChange,
    handleValueUnit
} from '../../constants';
import {
    GET_SHIFT_ANALYSIS_DATA,
    GET_SHIFT_ANALYSIS_DATA_SUCCESS,
    GET_SHIFT_ANALYSIS_DATA_FAILURE,
    GET_SHIFT_ANALYSIS_DATA_DIFF_SUCCESS,
    GET_SHIFT_ANALYSIS_DATA_DIFF_FAILURE,
    GET_SHIFT_ANALYSIS_SERIES,
    GET_SHIFT_ANALYSIS_SERIES_SUCCESS,
    GET_SHIFT_ANALYSIS_SERIES_FAILURE,
    GET_SHIFT_ANALYSIS_DATE_RANGE_DATA,
    GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_SUCCESS,
    GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_FAILURE,
    GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_SUCCESS,
    GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_FAILURE
} from './constant';

const buildShiftData = (payload, request_type = 'value', difference = false) => {
    const currentPayload = _.assign({}, payload); // Clone it so as it avoid any side effects
    const memberInfo = getLocalItem('smartsense.member', {});
    let shift = getLocalItem('smartsense.member_profile', {});
    if (Object.keys(shift).length > 0) {
        shift = shift['shift'].map(row => {
            return {
                start_time: row['starttime'],
                end_time: row['endtime'],
            }
        });
    }
    if (difference) {
        const dateFormat = 'YYYY-MM-DD';
        const {from_date, to_date} = currentPayload;
        currentPayload['from_date'] = moment(from_date, dateFormat).subtract(1, 'month').startOf('day').format();
        currentPayload['to_date'] = moment(to_date, dateFormat).subtract(1, 'month').endOf('day').format();
    }
    const result = {
        fromdate: currentPayload.from_date,
        todate: currentPayload.to_date,
        memberId: memberInfo.memberid,
        readingtypeid: currentPayload.reading_type_id,
        data_format: request_type === 'value' ? 'value_with_slots' : 'series_with_slots',
        timeslots: JSON.stringify(shift)
    };
    if (currentPayload.grain !== undefined) {
        result['grain'] = currentPayload.grain;
    }
    if (currentPayload.location !== null) {
        result['locationid'] = currentPayload.location;
    }
    return result;
};

const extractDataFromResponse = (response, readingTypeId) => {
    const {parameters, timeseries} = response;
    const unit = parameters.readingtype.unit;
    let name;
    const result = {
        values: [],
        names: []
    };
    _.forEach(timeseries, (shift, idx) => {
        const {timeslot} = shift;
        name = `Shift ${idx + 1}`;
        const actualValue = _.sumBy(shift['data'], 'value');
        result['values'].push({
            name,
            actualValue,
            ...handleValueUnit(actualValue, readingTypeId, 1000, unit)
        });
        result['names'].push({
            name,
            className: `shift${idx + 1}`,
            shift: `${timeslot['start_time']} - ${timeslot['end_time']}`
        });
    });
    return result;
};

const extractDifferenceDataFromResponse = (response, payload, readingTypeId) => {
    const result = extractDataFromResponse(response, readingTypeId);
    const {names, values} = result;
    return {
        ...payload,
        names,
        values: payload['values'].map((val, idx) => {
            return {
                ...val,
                ...getPercentageChange(values[idx].actualValue, val.actualValue)
            }
        })
    };
};

const getShiftAnalysisDataSuccess = payload => ({type: GET_SHIFT_ANALYSIS_DATA_SUCCESS, payload});
const getShiftAnalysisDataDifferenceSuccess = payload => ({type: GET_SHIFT_ANALYSIS_DATA_DIFF_SUCCESS, payload});
const getShiftAnalysisSeriesSuccess = payload => ({type: GET_SHIFT_ANALYSIS_SERIES_SUCCESS, payload});
const getShiftAnalysisDateRangeSuccess = payload => ({type: GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_SUCCESS, payload});
const getShiftAnalysisDateRangeDiffSuccess = payload => ({type: GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_SUCCESS, payload});

export const getShiftAnalysisDataEpic = action$ => {
    return action$.pipe(
        ofType(GET_SHIFT_ANALYSIS_DATA),
        mergeMap(action => {
            const {payload} = action;
            const urlParams = buildShiftData(payload);
            return ajax.getJSON(
                `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
                {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
            ).pipe(
                map(
                    response => {
                        const result = extractDataFromResponse(response, payload.reading_type_id);
                        return getShiftAnalysisDataSuccess({
                            ...result,
                            ...payload
                        });
                    }
                ),
                catchError(
                    error => of(
                        {type: GET_SHIFT_ANALYSIS_DATA_FAILURE, payload: []},
                        {type: ERROR, payload: error}
                    )
                )
            );
        })
    );
};

export const getShiftAnalysisSeriesEpic = action$ => {
    return action$.pipe(
        ofType(GET_SHIFT_ANALYSIS_SERIES),
        mergeMap(action => {
            const {payload} = action;
            const urlParams = buildShiftData(payload, 'series');
            return ajax.getJSON(
                `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
                {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
            ).pipe(
                map(
                    response => {
                        const {parameters, timeseries} = response;
                        const unit = parameters.readingtype.unit;
                        let name;
                        const barConst = [];
                        const result = {
                            series: [],
                            shift: [],
                            charts: {
                                pieData: [],
                                barData: []
                            },
                            ...payload
                        };
                        _.forEach(timeseries, (shift, idx) => {
                            const {data} = shift;
                            name = `Shift ${idx + 1}`;
                            const dataMapped = data.map(seriesData => {
                                const date = moment(seriesData['timeStamp'] * 1000);
                                const sdData = {
                                    name,
                                    date: date,
                                    dateInstance: date.startOf('day'),
                                    value: seriesData['value'],
                                    timeStamp: seriesData['timeStamp'],
                                    unit
                                };
                                barConst.push({
                                    ...sdData,
                                    [`shift_${idx}_value`]: sdData['value']
                                });
                                return sdData;
                            });
                            result['series'] = result['series'].concat(dataMapped);
                            result['charts']['pieData'].push({
                                name,
                                value: _.round(_.sumBy(dataMapped, 'value'), ROUND_PRECISION),
                                unit
                            });
                            const actualValue = _.meanBy(dataMapped, 'value');
                            result['shift'].push({
                                name,
                                actualValue,
                                ...handleValueUnit(actualValue, payload.reading_type_id, 1000, unit)
                            });
                        });
                        result['charts']['barData'] = _.map(
                            _.groupBy(barConst, 'date'),
                            itemNew => _.reduce(itemNew, function (result, value) {
                                return {
                                    ...result,
                                    ...value,
                                    name: 'Shift'
                                };
                            }, {})
                        );
                        return getShiftAnalysisSeriesSuccess(result);
                    }
                ),
                catchError(
                    error => of(
                        {type: GET_SHIFT_ANALYSIS_SERIES_FAILURE, payload: []},
                        {type: ERROR, payload: error}
                    )
                )
            );
        })
    );
};

export const getShiftAnalysisDateRangeEpic = action$ => {
    return action$.pipe(
        ofType(GET_SHIFT_ANALYSIS_DATE_RANGE_DATA),
        mergeMap(action => {
            const {payload} = action;
            const urlParams = buildShiftData(payload);
            return ajax.getJSON(
                `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
                {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
            ).pipe(
                map(
                    response => getShiftAnalysisDateRangeSuccess({
                        ...extractDataFromResponse(response, payload.reading_type_id),
                        ...payload
                    })
                ),
                catchError(
                    error => of(
                        {type: GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_FAILURE, payload: payload},
                        {type: ERROR, payload: error}
                    )
                )
            );
        })
    );
};

export const getShiftAnalysisDateRangeDiffEpic = action$ => {
    return action$.pipe(
        ofType(GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_SUCCESS),
        mergeMap(action => {
            const {payload} = action;
            const urlParams = buildShiftData(payload, 'value', true);
            return ajax.getJSON(
                `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
                {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
            ).pipe(
                map(
                    response => getShiftAnalysisDateRangeDiffSuccess(
                        extractDifferenceDataFromResponse(response, payload, payload.reading_type_id)
                    )
                ),
                catchError(error => of(
                    {type: GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_FAILURE, payload: payload},
                    {type: ERROR, payload: error}
                ))
            );
        })
    );
};

export const getShiftAnalysisDataDifferenceEpic = action$ => {
    return action$.pipe(
        ofType(GET_SHIFT_ANALYSIS_DATA_SUCCESS),
        mergeMap(action => {
            const {payload} = action;
            const urlParams = buildShiftData(payload, 'value', true);
            return ajax.getJSON(
                `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
                {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
            ).pipe(
                map(
                    response => getShiftAnalysisDataDifferenceSuccess(
                        extractDifferenceDataFromResponse(response, payload, payload.reading_type_id)
                    )
                ),
                catchError(error => of(
                    {type: GET_SHIFT_ANALYSIS_DATA_DIFF_FAILURE, payload: []},
                    {type: ERROR, payload: error}
                ))
            );
        })
    );
};
