import React, { Fragment,useState, useEffect, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes, { func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ModalBox , Button } from '../../components/'
import { hideConfirmBox} from "./action";
import { ModalBody, ModalFooter} from 'reactstrap';


function ConfirmBox(props) {
  var [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(props.showConfirmBox.open) {
      setIsOpen(true)
    }
  }, [props.showConfirmBox.open]);

  return (
    <ModalBox open ={isOpen} className = {"smallmodalBox confirm-box"}>
      <ModalBody>
        {props.showConfirmBox.content}
      </ModalBody>
      <ModalFooter>
        <Button
            buttonType="primary"
            size="md"
            buttonClick={()=> {
              setIsOpen(false);
              props.hideConfirmBox();
              props.showConfirmBox.onOk();
            }}
            innerContent="Ok"
        />
        <Button
            buttonType="secondary"
            size="md"
            buttonClick={() => {
              setIsOpen(false);
              props.hideConfirmBox();
              props.showConfirmBox.onCancle();
            }}
            innerContent="Cancel"
        />
      </ModalFooter>
    </ModalBox>
  )
}

const mapStateToProps = (state) => {
  return {
    showConfirmBox: state.showConfirmBox
  };
};

ConfirmBox.defaultProps = {}

ConfirmBox.propTypes = {
}

export default withRouter(connect(
  mapStateToProps, { 
    hideConfirmBox 
  }
)(ConfirmBox));