export default class HeaderCell {
    constructor(label, dataKey, index, width, fixed=false, disableSortBy=false) {
        this.index = index;
        this.lable = label;
        this.dataKey = dataKey;
        this.width = width;
        this.fixed = fixed;
        this.disableSortBy = disableSortBy;
    }

    getlabel() {
        return this.lable;
    }

    getdataKey() {
        return this.dataKey;
    }

    getColWidth() {
        return this.width;
    }
}