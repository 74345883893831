import React from 'react';
import { Row, Col } from 'reactstrap';
import { Button, Input } from '../../../../../../../common/components';
import alertImg from '../../../../../../../../assets/images/alertIcon.svg';

const MetaConfig = (props) => {
  const widgetName =
    props.widgetParams &&
    props.widgetParams.widget_name &&
    props.widgetParams.widget_name.value;

  const refreshFrequency =
    props.widgetParams &&
    props.widgetParams.refresh_frequency &&
    props.widgetParams.refresh_frequency.value;

  const widgetDescription =
    props.widgetParams &&
    props.widgetParams.widget_description &&
    props.widgetParams.widget_description.value;

  return (
    <div className='config-form-wrapper'>
      <Row>
        <Col md={6}>
          <div className='new-input-wrap'>
            <label className='label-text'>Widget Name</label>
            <Input
              size='sm'
              label=''
              type='input'
              required={false}
              placeholder='Enter Widget Name'
              value={widgetName || ''}
              inputChanged={(_widgetName) => {
                _widgetName !== widgetName &&
                  props.appInputParamChanged({
                    widget_name: {
                      value: _widgetName
                    }
                  });
              }}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className='new-input-wrap'>
            <label className='label-text'>Auto Refresh Time (mins)</label>
            <Input
              size='sm'
              label=''
              type='input'
              required={false}
              placeholder='Enter Auto Refresh Frequency'
              value={refreshFrequency || ''}
              inputChanged={(_refreshFrequency) => {
                _refreshFrequency !== refreshFrequency;
                props.appInputParamChanged({
                  refresh_frequency: {
                    value: _refreshFrequency
                  }
                });
              }}
            />
            <div className='input-notify'>
              <img src={alertImg} />
              <p>Keep blank/zero for no refresh.</p>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className='new-input-wrap'>
            <label className='label-text'>Widget Description</label>
            <textarea
              className='form-control rounded-0'
              rows='5'
              placeholder='This description will appear on click of the (i)icon on the the widget'
              value={widgetDescription}
              onChange={(event) => {
                props.appInputParamChanged({
                  widget_description: {
                    value: event.target.value
                  }
                });
              }}
            ></textarea>
          </div>
        </Col>
      </Row>
      <div className='button-wrapper'>
        <Button
          buttonType='primary'
          size='md'
          innerContent='Next'
          className='buttonFill'
          buttonClick={() => {
            props.onTabChangeTrigger(1);
          }}
        />
      </div>
    </div>
  );
};

export default MetaConfig;
