import React, { useState, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import parameterConfInputGet from '../common/parameterConfInputGet';
import collectionGet from '../common/collectionGet';
import ParameterConfig from './ParameterConfig';
import ParameterMixedCollection from '../Common/ParameterMixedCollection';
import { Input, CheckBox } from '../../../../../../../common/components';

const ParameterConfigMixed = (props) => {
  const [confCount, setConfCount] = useState(props.confs.length || 1);

  const [confActiveIndex, setConfActiveIndex] = useState(confCount - 1);

  const _renderParameterConfig = (conf, index) => {
    return (
      <ParameterConfig
        conf={conf}
        onConfChange={(conf) => {
          props.onConfsChange(collectionGet(conf, index, props.confs));
        }}
        onParameterConfsChange={(conf) => {
          const _conf = {
            ...conf,
            index,
            isComputed: false
          };

          props.onConfsChange(collectionGet(_conf, index, props.confs));
        }}
        onFormulaConfsChange={(conf) => {
          const _conf = {
            ...(() => {
              const { alias, unit, ..._conf } = conf;

              return _conf;
            })(),
            index,
            isComputed: true
          };

          props.onConfsChange(collectionGet(_conf, index, props.confs));
        }}
      />
    );
  };

  const renderParameterConfig = (conf, index, confActiveFlag) => {
    return (
      confActiveFlag && (
        <div>
          <p className='head-label-text mg-b-10'>Parameter {index + 1}</p>

          <Row>
            <Col md={2}>
              <div className='new-input-wrap'>
                <label className='label-text'>Alias</label>

                <Input
                  size='sm'
                  label=''
                  type='text'
                  required={false}
                  placeholder='Enter Alias'
                  value={conf.alias || ''}
                  inputChanged={(alias) => {
                    alias !== conf.alias &&
                      props.onConfsChange(
                        collectionGet(
                          {
                            ...conf,
                            alias
                          },
                          index,
                          props.confs
                        )
                      );
                  }}
                />
              </div>
            </Col>

            <Col md={2}>
              <div className='new-input-wrap'>
                <label className='label-text'>Unit</label>

                <Input
                  size='sm'
                  label=''
                  type='text'
                  required={false}
                  placeholder='Enter Unit'
                  value={conf.unit || ''}
                  inputChanged={(unit) => {
                    unit !== conf.unit &&
                      props.onConfsChange(
                        collectionGet(
                          {
                            ...conf,
                            unit
                          },
                          index,
                          props.confs
                        )
                      );
                  }}
                />
              </div>
            </Col>

            {props.tableConfig === props.tableConfigCollection[0] && (
              <Col md={2}>
                <div className='new-input-wrap'>
                  <label className='label-text'>Threshold</label>

                  <Input
                    size='sm'
                    label=''
                    type='number'
                    required={false}
                    placeholder='Enter Threshold'
                    value={conf.threshold || ''}
                    inputChanged={(threshold) => {
                      threshold !== conf.threshold &&
                        props.onConfsChange(
                          collectionGet(
                            {
                              ...conf,
                              threshold
                            },
                            index,
                            props.confs
                          )
                        );
                    }}
                  />
                </div>
              </Col>
            )}

            <Col md={2}>
              <div className='new-input-wrap'>
                <label className='label-text'>Row</label>

                <Input
                  size='sm'
                  label=''
                  type='number'
                  required={false}
                  placeholder='Enter Row'
                  value={conf.row || ''}
                  inputChanged={(row) => {
                    row !== conf.row &&
                      props.onConfsChange(
                        collectionGet(
                          {
                            ...conf,
                            row
                          },
                          index,
                          props.confs
                        )
                      );
                  }}
                />
              </div>
            </Col>

            <Col md={2}>
              <div className='new-input-wrap'>
                <label className='label-text'>Column</label>

                <Input
                  size='sm'
                  label=''
                  type='number'
                  required={false}
                  placeholder='Enter Column'
                  value={conf.col || ''}
                  inputChanged={(col) => {
                    col !== conf.col &&
                      props.onConfsChange(
                        collectionGet(
                          {
                            ...conf,
                            col
                          },
                          index,
                          props.confs
                        )
                      );
                  }}
                />
              </div>
            </Col>

            <Col md={2}>
              <div className='new-input-wrap'>
                <label className='label-text'>Style</label>

                <Input
                  size='sm'
                  label=''
                  type='text'
                  required={false}
                  placeholder='Enter Style'
                  value={conf.style || ''}
                  inputChanged={(style) => {
                    style !== conf.style &&
                      props.onConfsChange(
                        collectionGet(
                          {
                            ...conf,
                            style
                          },
                          index,
                          props.confs
                        )
                      );
                  }}
                />
              </div>
            </Col>

            {props.tableConfig === props.tableConfigCollection[1] && (
              <Col md={2}>
                <div className='new-input-wrap'>
                  <label className='label-text'> </label>

                  <div className='new-custom-checkbox mt-3'>
                    <CheckBox
                      label={'Apply Global Threshold'}
                      size='sm'
                      checked={conf.globalThreshold}
                      checkboxClicked={(globalThreshold) => {
                        props.onConfsChange(
                          collectionGet(
                            {
                              ...conf,
                              globalThreshold
                            },
                            index,
                            props.confs
                          )
                        );
                      }}
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>

          {_renderParameterConfig(conf, index)}
        </div>
      )
    );
  };

  const renderParameterMixedCollection = () => {
    return (
      <ParameterMixedCollection
        confs={props.confs}
        onConfActiveIndexChangeTrigger={(index) => {
          setConfActiveIndex(index);
        }}
        onConfDeleteTrigger={(index) => {
          const _confActiveIndex = (() => {
            switch (true) {
              case index < confActiveIndex:
                return confActiveIndex - 1;

              case index > confActiveIndex:
                return confActiveIndex;

              case index === confActiveIndex && !!index:
                return confActiveIndex - 1;

              default:
                return 0;
            }
          })();

          setConfActiveIndex(_confActiveIndex);

          const confs = collectionGet(null, index, props.confs);

          props.onConfsChange(
            confs.length
              ? confs
              : [
                  {
                    ...parameterConfInputGet(),
                    isComputed: false
                  }
                ]
          );
        }}
      />
    );
  };

  return (
    <div className='content-border-wrap mx-0'>
      {Array.from({ length: confCount })
        .fill()
        .reduce((memo, _, index) => {
          const value = props.confs[index] || {};

          return [...memo, value];
        }, [])
        .map((conf, index) => (
          <Fragment key={index}>
            {renderParameterConfig(conf, index, index === confActiveIndex)}
          </Fragment>
        ))}

      <div className='btn-wrap'>
        <button
          className='icon-text-btn mg-t-15'
          onClick={() => {
            setConfActiveIndex(confCount);

            setConfCount((confCount) => ++confCount);

            props.onConfsChange([
              ...props.confs,
              {
                ...parameterConfInputGet(),
                isComputed: false,
                globalThreshold:
                  props.tableConfig === props.tableConfigCollection[0]
                    ? false
                    : true
              }
            ]);
          }}
        >
          <i className='fa fa-plus-circle' aria-hidden='true'></i> Add Parameter
        </button>
      </div>

      <br />

      <div md={12}>{renderParameterMixedCollection()}</div>
    </div>
  );
};

export default ParameterConfigMixed;
