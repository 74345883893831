import {ofType} from "redux-observable";
import {catchError, map, mergeMap} from "rxjs/operators";
import {ajax} from "rxjs/ajax";
import {of} from "rxjs";
import isArray from "lodash/isArray";
import meanBy from "lodash/meanBy";
import sortBy from "lodash/sortBy";
import qs from 'query-string';
import moment from "moment";
import {
    AssetUrl,
    ERROR,
    getLocalItem,
    handleValueUnit,
    getPercentageChange,
    handleNumber
} from "../../constants";
import {
    GET_DAY_DEMAND_DATA,
    GET_DAY_DEMAND_DATA_SUCCESS,
    GET_DAY_DEMAND_DATA_FAILURE,
    GET_DAY_DEMAND_DIFFERENCE_DATA_SUCCESS,
    GET_DAY_DEMAND_DIFFERENCE_DATA_FAILURE
} from "./constant";
import {buildHolidays} from "../utils";

const getDayValue = (valueSeries, unit, readingTypeId) => {
    let value = 'N/A';
    let actualValue = 0;
    if (valueSeries.length > 0) {
        actualValue = valueSeries[0]['value'];
        return {
            ...handleValueUnit(actualValue, readingTypeId, 1000, unit),
            actualValue
        };
    }
    return {
        value,
        actualValue,
        unit
    };
};

const getAverage = (valueSeries, unit, readingTypeId) => {
    let value = 'N/A';
    let actualValue = 0;
    if (isArray(valueSeries) && valueSeries.length > 0) {
        actualValue = meanBy(valueSeries, 'value');
        if (actualValue === undefined) {
            actualValue = 0;
        }
        return {
            ...handleValueUnit(actualValue, readingTypeId, 1000, unit),
            actualValue
        };
    }
    return {
        value,
        actualValue,
        unit
    };
};

const getSimpleAverage = valueSeries => {
    if (isArray(valueSeries) && valueSeries.length > 0) {
        return handleNumber(meanBy(valueSeries, 'value'));
    }
    return 'N/A';
}

const handleSeriesData = (valueSeries, unit, name = 'Working Day') => {
    if (valueSeries.length > 0) {
        return valueSeries.map(row => {
            const {value, timeStamp} = row;
            const date = moment(timeStamp * 1000);
            return {
                name,
                date,
                dateStr: date.format('MMM-DD-YY'),
                value: value,
                timeStamp: timeStamp,
                unit: unit,
                x: timeStamp * 1000,
                y: value
            };
        });
    }
    return [];
};

const getDayDemandDataSuccess = payload => ({type: GET_DAY_DEMAND_DATA_SUCCESS, payload});
const getDayDemandDifferenceDataSuccess = payload => ({type: GET_DAY_DEMAND_DIFFERENCE_DATA_SUCCESS, payload});

export const getDayDemandAnalysisDataEpic = action$ => action$.pipe(
    ofType(GET_DAY_DEMAND_DATA),
    mergeMap(action => {
        const {payload} = action;
        const memberInfo = getLocalItem('smartsense.member', {});
        let holidays = getLocalItem('smartsense.member_profile', []);
        if (Object.keys(holidays).length > 0) {
            holidays = holidays['holidays'].map(row => row['date']);
        }
        const {reading_type_id} = payload;
        const urlParams = {
            fromdate: payload.from_date,
            todate: payload.to_date,
            memberId: memberInfo.memberid,
            readingtypeid: reading_type_id,
            grain: payload.grain,
            data_format: 'value_with_slots',
            holiday_list: JSON.stringify([...holidays, ...payload.dateRanges])
        };
        if (payload.location !== null) {
            urlParams['locationid'] = payload.location;
        }
        return ajax.getJSON(
            `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
            {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
        ).pipe(
            map(
                response => {
                    const {timeseries, parameters} = response;
                    const unit = parameters.readingtype.unit;
                    const values = [
                        getDayValue(timeseries.wd_value, unit, reading_type_id),
                        getDayValue(timeseries.nwd_value, unit, reading_type_id)
                    ];
                    const series = [
                        handleSeriesData(timeseries.wd_series, unit),
                        handleSeriesData(timeseries.nwd_series, unit, 'Non Working Day')
                    ];
                    return getDayDemandDataSuccess({
                        working_days: timeseries.working_days,
                        non_working_days: timeseries.non_working_days,
                        working_day_value: values[0],
                        non_working_day_value: values[1],
                        working_day_series: series[0],
                        non_working_day_series: series[1],
                        unit,
                        chart: {
                            bar: sortBy(series[0].concat(series[1]), 'timeStamp'),
                            pie: [
                                {name: 'Working Day', value: values[0].actualValue},
                                {name: 'Non Working Day', value: values[1].actualValue}
                            ]
                        },
                        average: {
                            working_day: getAverage(series[0], unit, reading_type_id),
                            non_working_day: getAverage(series[1], unit, reading_type_id),
                        },
                        inputData: payload
                    });
                }
            ),
            catchError(
                error => of(
                    {type: GET_DAY_DEMAND_DATA_FAILURE, payload: []},
                    {type: ERROR, payload: error}
                )
            )
        );
    })
);

export const getDayDemandAnalysisDifferenceDataEpic = action$ => {
    return action$.pipe(
        ofType(GET_DAY_DEMAND_DATA_SUCCESS),
        mergeMap(action => {
            const {payload} = action;
            const {inputData} = payload;
            const dateFormat = 'YYYY-MM-DD';
            const targetDateFormat = 'YYYY-MM-DD HH:mm:ss';
            const memberInfo = getLocalItem('smartsense.member', {});
            let holidays = getLocalItem('smartsense.member_profile', {});
            if (Object.keys(holidays).length > 0) {
                holidays = holidays['holidays'].map(row => row['date']);
            } else {
                holidays = [];
            }
            const dates = [
                moment(inputData.from_date, dateFormat)
                    .subtract(1, 'month')
                    .startOf('day'),
                moment(inputData.to_date, dateFormat)
                    .subtract(1, 'month')
                    .endOf('day'),
            ];
            const dateRanges = holidays.concat(buildHolidays(dates[0], dates[1]).map(dt => dt.format(dateFormat)));
            const {reading_type_id} = inputData;
            const urlParams = {
                fromdate: dates[0].format(targetDateFormat),
                todate: dates[1].format(targetDateFormat),
                memberId: memberInfo.memberid,
                readingtypeid: reading_type_id,
                grain: inputData.grain,
                data_format: 'value_with_slots',
                holiday_list: JSON.stringify(dateRanges)
            };
            if (payload.location !== null) {
                urlParams['locationid'] = payload.location;
            }
            return ajax.getJSON(
                `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
                {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
            ).pipe(
                map(
                    response => {
                        const {timeseries, parameters} = response;
                        const unit = parameters.readingtype.unit;
                        const series = [
                            handleSeriesData(timeseries.wd_series, unit),
                            handleSeriesData(timeseries.nwd_series, unit, 'Non Working Day')
                        ];
                        return getDayDemandDifferenceDataSuccess({
                            working_day: getPercentageChange(
                                payload.average.working_day.actualValue,
                                getSimpleAverage(series[0])
                            ),
                            non_working_day: getPercentageChange(
                                payload.average.non_working_day.actualValue,
                                getSimpleAverage(series[1])
                            )
                        });
                    }
                ),
                catchError(
                    error => of(
                        {type: GET_DAY_DEMAND_DIFFERENCE_DATA_FAILURE, payload: []},
                        {type: ERROR, payload: error}
                    )
                )
            );
        })
    )
};

