export default (
  formulaConfPointerCollection,
  formulaConfParameterCollection
) => {
  return formulaConfPointerCollection.reduce((memo, formulaConfPointer) => {
    const value =
      formulaConfPointer.type === 'parameter'
        ? {
            alphabet: `p${formulaConfPointer.index}`,
            type: formulaConfPointer.type,
            parameter: {
              ...formulaConfParameterCollection[formulaConfPointer.value]
                .parameter
            }
          }
        : {
            type: formulaConfPointer.type,
            value: formulaConfPointer.value
          };

    return [...memo, value];
  }, []);
};
