import React from 'react';
import { Row, Col, ModalBody, ModalFooter} from 'reactstrap';
import classnames from 'classnames';
import { Button, ModalBox} from '../../../../../common/components';

const WidgetSelector = (props) => {
  const widgets = props.allWidgetConfig;

  const closeModal = () => {
    props.closeModal();
  }

  const setWigetSelected = (index) => {
    props.setWigetSelected(index);
  }

  const openAddWidgetConfiguration = () => {
    props.openAddWidgetConfiguration();
  }

  const addWidgetModalFooter = () => (
    <div className = 'add-widget-modal-footer'>
      <Button
        buttonType = 'secondary'
        size = 'md'
        buttonClick = {closeModal}
        innerContent = "Cancel"
      />
      <Button
        buttonType = 'primary'
        size = 'md'
        buttonClick = {openAddWidgetConfiguration}
        innerContent = "Add"
      />
    </div>
  )

  const addWidgetModalBody = () => {
    return (
      <div className = 'add-widget-modal-body'>
        <Row>
          {
            widgets.map((widget, i) => {
              if (!widget) return;

              // if (widget.type === 'Network View') return;

              return(
                <Col 
                  key={i}
                  className={classnames({ selected: props.selectedWidgetIndex == i })}
                  onClick={setWigetSelected.bind(this, i)} 
                  xl={3} lg={3} md={12} sm={12} xs={12}
                >
                  <div className = 'add-widget-app'>
                    <div className= 'add-widget-app-icon'>
                      <i className={widget.meta.icon ? `fas ${widget.meta.icon}` : "fa fa-exclamation-circle"} aria-hidden="true"></i>
                    </div>
                    <div className= 'add-widget-app-name'>
                      {widget.name}
                    </div>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </div>
    )
  }

  const renderModal = () => {
    return (
      <ModalBox
        open ={props.isOpen}
        size='sm'
        onClose = {closeModal}
        className = 'add-widget-modal custom-modal'
      >
        <div className = 'add-widget-title'>
          <h3>Widget Library</h3>
        </div>
        <ModalBody>
        {addWidgetModalBody()}
        </ModalBody>
        <ModalFooter>
        {addWidgetModalFooter()}
        </ModalFooter>
      </ModalBox>
    )
  }

  return (
    <div className="dashboard-modal">
      {renderModal()}
    </div>
  );
}

export default WidgetSelector;