import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE 
} from '../constants';



const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false
}

export function userSearchReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        loading: true,
        data: [],
        error: false
      }
    case GET_USERS_SUCCESS:
      localStorage.setItem("smartsense.users", JSON.stringify(action.response))

      return {
        ...state,
        data: action.response,
        error: false,
        loading: false
      }
    case GET_USERS_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false
      }
    default:
      return state
  }
}