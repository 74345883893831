import queryString from 'query-string';

import {
  GET_GIS_VIEW_READING_DATA,
  GET_GIS_VIEW_READING_DATA_SUCCESS,
  GET_GIS_VIEW_READING_DATA_FAILURE,
  GET_KPI_VIEW_DATA,
  GET_KPI_VIEW_DATA_SUCCESS,
  GET_KPI_VIEW_DATA_FAILURE,
  GET_KPI_PLANT_DATA,
  GET_KPI_PLANT_DATA_SUCCESS,
  GET_KPI_PLANT_DATA_FAILURE,
  GET_KPI_VIEW_READING_DATA,
  GET_KPI_VIEW_READING_DATA_SUCCESS,
  GET_KPI_VIEW_READING_DATA_FAILURE,
  GET_KPI_REDIRECTION_DASHBOARD,
  GET_KPI_REDIRECTION_DASHBOARD_SUCCESS,
  GET_KPI_REDIRECTION_DASHBOARD_FAILURE,
  GET_KPI_REDIRECTION_DASHBOARD_RESET
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  errorMessage: undefined,
  success: undefined
};

export const getGisViewReadingData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_GIS_VIEW_READING_DATA:
      return {
        ...state,
        loading: true,
        data: {
          ...state.data,
          [action.payload.widgetId]: INITIAL_STATE.data
        },
        error: false,
        success: undefined
      };

    case GET_GIS_VIEW_READING_DATA_SUCCESS:

      let oldData = state.data[action.param.widgetId] ? state.data[action.param.widgetId] : []
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: [
            ...oldData,
            { ...action.response[0], index: action.param.index }
          ].sort((a, b) => a.index - b.index)
        },
        error: false,
        loading: false,
        success: true
      };

    case GET_GIS_VIEW_READING_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };

    default:
      return state;
  }
};


export function getKpiViewData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_KPI_VIEW_DATA:
      let newData1 = {...state.data};
      if(!newData1[action.payload.widgetId]){
        newData1[action.payload.widgetId] = {
          data:{},
          loader:[true]
        };
      }else{
        newData1[action.payload.widgetId].loader.push(true);
      }

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: newData1,
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_KPI_VIEW_DATA_SUCCESS:
      let newData = {...state.data};
      let parameter_ids =
        typeof action.param.keyId === 'number'
          ? action.param.keyId
          : action.param.parameter_ids;

      if (action.param.isComputed) {
        let d = !isNaN(action.response.response.data)
          ? Number(action.response.response.data.toFixed(2))
          : undefined;
        let res = {
          value: d,
          isComputed: action.param.isComputed
        };
        newData[action.param.widgetId].data[action.param.plantId] = {
          ...newData[action.param.widgetId].data[action.param.plantId],
          [parameter_ids]: res
        };
      } else {
        let resp = [...action.response];
        newData[action.param.widgetId].data[action.param.plantId] = {
          ...newData[action.param.widgetId].data[action.param.plantId],
          [parameter_ids]: {
            ...resp[0],
            index: action.param.index,
            alias: action.param.alias
          }
        };
      }

      newData[action.param.widgetId].loader = newData[
        action.param.widgetId
      ].loader.slice(0, -1);

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: newData,
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case GET_KPI_VIEW_DATA_FAILURE:
      let newDataFail = {...state.data};
      newDataFail[action.param.widgetId].loader.pop();
      newDataFail[action.param.widgetId].data = {};
      return (() => {
        return {
          ...state,
          data:newDataFail,
          loading: {
            ...(state.loading || {}),
            [action.param.widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [action.param.widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [action.param.widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}


export function getKpiPlantData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_KPI_PLANT_DATA:
      let newData1 = {...state.data};

      newData1[action.payload.widgetId] = [];

      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: newData1,
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_KPI_PLANT_DATA_SUCCESS:
      let newData = {...state.data};
      newData[action.param.widgetId] = action.response;
      return {
        ...state,
        data: newData,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };
    case GET_KPI_PLANT_DATA_FAILURE:
      return {
        ...state,
        data:{},
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: false
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: true
        }
      };
    default:
      return state;
  }
}

export function getKpiViewReadingData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_KPI_VIEW_READING_DATA:
      let newData1 = {...state.data};
      if(!newData1[action.payload.widgetId]){
        newData1[action.payload.widgetId] = {
          data:{},
          loader:[true]
        };
      }else{
        newData1[action.payload.widgetId].loader.push(true);
      }


      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: newData1,
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_KPI_VIEW_READING_DATA_SUCCESS:
      let newData = {...state.data};
      newData[action.param.widgetId].data[action.param.index] = action.response && action.response[0];

      newData[action.param.widgetId].loader = newData[
        action.param.widgetId
      ].loader.slice(0, -1);

      return {
        ...state,
        data: newData,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };
    case GET_KPI_VIEW_READING_DATA_FAILURE:
      let newDataFail = {...state.data};
      newDataFail[action.param.widgetId].loader.pop();
      newDataFail[action.param.widgetId].data = {};
      return {
        ...state,
        data:newDataFail,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: false
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: true
        }
      };
    default:
      return state;
  }
}

export const getKpiRedirectionDashboard = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case GET_KPI_REDIRECTION_DASHBOARD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_KPI_REDIRECTION_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...(action.response.result?.[0] ?? {}),
            ...action.param
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case GET_KPI_REDIRECTION_DASHBOARD_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          data: {
            ...state.data,
            [action.param.widgetId]: {
              ...action.param
            }
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    case GET_KPI_REDIRECTION_DASHBOARD_RESET:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.widgetId]: false
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    default:
      return state;
  }
};