import {
  GET_REFRESH_DATA, GET_MEMBER_PROFILE_DATA, GET_READING_DATA, GET_COMPUTED_READING_DATA, GET_FAULT_READING_DATA, GET_COMPUTED_PARAMETER, CLOSE_RIGHT_SIDE_DRAWER, OPEN_RIGHT_SIDE_DRAWER, GET_READING_TYPES_PRELOAD, GET_CUSTOM_DASHBOARD, CREATE_CUSTOM_DASHBOARD
} from '../constants'

export function getRefreshAction() {
  return {
    type: GET_REFRESH_DATA,
  }
}

export function getMemberProfileDataAction() {
  return {
    type: GET_MEMBER_PROFILE_DATA
  }
}

export function getReadingDataAction(value) {
  return {
    type: GET_READING_DATA,
    payload: value
  }
}

export function getFaultReadingDataAction(value) {
  return {
    type: GET_FAULT_READING_DATA,
    payload: value
  }
}



export function getComputedReadingDataAction(value) {
  return {
    type: GET_COMPUTED_READING_DATA,
    payload: value
  }
}

export function openRightSideDrawer() {
  return {
    type: OPEN_RIGHT_SIDE_DRAWER
  }
}

export function closeRightSideDrawer() {
  return {
    type: CLOSE_RIGHT_SIDE_DRAWER
  }
}
export function getCalculatedParameterAction(value) {
  return {
    type: GET_COMPUTED_PARAMETER,
    payload: value
  }
}

export function getReadingTypesPreloadAction(value) {
  return {
    type: GET_READING_TYPES_PRELOAD,
    payload: value
  }
}