import React, { useState, useCallback, useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoading = () => {
  const getThemeLightFlag = () => {
    return localStorage.getItem('theme') === 'theme-light';
  };

  const [themeLightFlag, setThemeLightFlag] = useState(getThemeLightFlag());

  const onStorageChangeHandle = useCallback(() => {
    return setThemeLightFlag(getThemeLightFlag());
  }, []);

  useEffect(() => {
    window.addEventListener('storage', onStorageChangeHandle);

    return () => {
      return window.removeEventListener('storage', onStorageChangeHandle);
    };
  }, [onStorageChangeHandle]);

  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      <SkeletonTheme
        baseColor={themeLightFlag ? '#f9f9fd' : '#0A152C'}
        highlightColor={themeLightFlag ? '#d7d8ff' : '#071D49'}
      >
        <Skeleton style={{ height: '100%' }} />
      </SkeletonTheme>
    </div>
  );
};

export default SkeletonLoading;
