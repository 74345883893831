import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import DropDownSVG from '../../../../assets/images/Icons/Arrows/Chevron-DownSVG.svg';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    }
}));

export default function SimpleExpansionPanel(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState([0])

	useEffect(() => {
		setExpanded((expanded) => {
			return props.expanded &&
				props.expanded.length &&
				!props.expanded.every(
					(_expanded, index) => expanded === expanded[index]
				)
				? props.expanded
				: expanded;
		});
	}, [props.expanded]);

    const handleChange = panel => {
        if (props.multipleOpen !== true) {
            // TO DO if flush array and set current as open
            let a = [panel]
            props.panelClickedAtIndex(a)
            setExpanded(a)


        } else {
            if (expanded.indexOf(panel) === -1) {
                let a = [].concat(expanded, [panel])
                setExpanded(a);
            } else {
                const a = [...expanded];
                let index = a.indexOf(panel);
                a.splice(index, 1);
                setExpanded(a);
            }
        }
    };


    const panels = [];
    let len = props.noOfpanels ? props.noOfpanels : 3;

    for (let i = 0; i < len; i++) {
        panels.push(
            <ExpansionPanel square expanded={(expanded.indexOf(i) !== -1)} onChange={
                () => {
                    handleChange(i)
                }
            }>
                <ExpansionPanelSummary
                    expandIcon={
                        <img src={DropDownSVG} height={20} width={20} alt="DropDown"/>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {props.getAccordianHeaderAtIndex ? props.getAccordianHeaderAtIndex(i) : null}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {expanded.indexOf(i) === 0 ? (props.getAccordianContentAtIndex ? props.getAccordianContentAtIndex(i) : 'No Data') : null}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    return (
        <div className={'accordianWrapper ' + classes.root}>
            {panels}
        </div>
    );
}