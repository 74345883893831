export const GET_ASSET_CATEGORY_FOR_SME = 'GET_ASSET_CATEGORY_FOR_SME';
export const GET_ASSET_CATEGORY_FOR_SME_SUCCESS =
  'GET_ASSET_CATEGORY_FOR_SME_SUCCESS';
export const GET_ASSET_CATEGORY_FOR_SME_FAILURE =
  'GET_ASSET_CATEGORY_FOR_SME_FAILURE';

export const GET_ASSETS_FOR_ASSET_CATEGORY = 'GET_ASSETS_FOR_ASSET_CATEGORY';
export const GET_ASSETS_FOR_ASSET_CATEGORY_SUCCESS =
  'GET_ASSETS_FOR_ASSET_CATEGORY_SUCCESS';
export const GET_ASSETS_FOR_ASSET_CATEGORY_FAILURE =
  'GET_ASSETS_FOR_ASSET_CATEGORY_FAILURE';

export const GET_NETWORKS_FOR_NETWORK_CATEGORY =
  'GET_NETWORKS_FOR_NETWORK_CATEGORY';
export const GET_NETWORKS_FOR_NETWORK_CATEGORY_SUCCESS =
  'GET_NETWORKS_FOR_NETWORK_CATEGORY_SUCCESS';
export const GET_NETWORKS_FOR_NETWORK_CATEGORY_FAILURE =
  'GET_NETWORKS_FOR_NETWORK_CATEGORY_FAILURE';

export const GET_TEMPLATE_DETAILS_FOR_NETWORK = 'GET_TEMPLATE_DETAILS_FOR_NETWORK';
export const GET_TEMPLATE_DETAILS_FOR_NETWORK_SUCCESS =
  'GET_TEMPLATE_DETAILS_FOR_NETWORK_SUCCESS';
export const GET_TEMPLATE_DETAILS_FOR_NETWORK_FAILURE =
  'GET_TEMPLATE_DETAILS_FOR_NETWORK_FAILURE';