import { 
  GET_MEMBER_DATA, 
  GET_ROLE_DATA, 
  CREATE_ROLE , 
  CREATE_MEMBER, 
  EDIT_ROLE, 
  EDIT_ROLE_PERMISSION, 
  DELETE_ROLE, 
  ASSIGN_ROLE, 
  REMOVE_ROLE, 
  EDIT_MEMBER, 
  DELETE_MEMBER, 
  DELETE_LOCATION
} from '../constants'

export function getMemberAction() {

  return {
    type: GET_MEMBER_DATA
  }
}


export function getRoleAction() {

  return {
    type: GET_ROLE_DATA
  }
}

export function createRoleAction(value) {

 return {
    type: CREATE_ROLE,
    payload: value
  }
}

export function createMemberAction(value) {

  return {
    type: CREATE_MEMBER,
    payload: value
  }
}

export function editRoleAction(value) {

  return {
    type: EDIT_ROLE,
    payload: value
  }
}



export function deleteRoleAction(value) {

  return {
    type: DELETE_ROLE,
    payload: value
  }
}



export function editMemberAction(value, id){

  return {
    type: EDIT_MEMBER,
    payload: value,
    id: id
  }
}

export function deleteMemberAction(value){

  return {
    type: DELETE_MEMBER,
    payload: value
  }
}

