import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import { Input, Select } from '../../../../../../../common/components';

const _DateTimeConfig = (props) => {
  return (
    <Fragment>
      <Row>
        <Col md={4}>
          <div className='new-input-wrap'>
            <label className='label-text'>Period</label>

            <Select
              isMulti={false}
              placeholder='Select Period'
              options={props.periodOptionCollection.reduce(
                (memo, value) => [...memo, { label: value, value }],
                []
              )}
              value={(() => {
                const value = props.period;

                return (
                  value && {
                    label: value,
                    value
                  }
                );
              })()}
              onChange={({ value }) => {
                props.appInputParamChanged({
                  period: value
                });
              }}
            />
          </div>
        </Col>

        <Col md={4}>
          <div className='new-input-wrap'>
            <label className='label-text'>From</label>

            <Input
              size='sm'
              label=''
              type='number'
              required={false}
              placeholder='0'
              value={props.from}
              inputChanged={(_from) => {
                _from !== props.from &&
                  props.appInputParamChanged({
                    from: _from
                  });
              }}
            />
          </div>
        </Col>

        <Col md={4}>
          <div className='new-input-wrap'>
            <label className='label-text'>To</label>

            <Input
              size='sm'
              label=''
              type='number'
              required={false}
              placeholder='0'
              value={props.to}
              inputChanged={(_to) => {
                _to !== props.to &&
                  props.appInputParamChanged({
                    to: _to
                  });
              }}
            />
          </div>
        </Col>

        <Col md={6}>
          <div className='new-input-wrap'>
            <label className='label-text'>Start Time</label>

            <Input
              size='sm'
              label=''
              type='time'
              required={false}
              placeholder='Start Time'
              className='time-input'
              value={props.startTime || ''}
              inputChanged={(_startTime) => {
                _startTime !== props.startTime &&
                  props.appInputParamChanged({
                    startTime: _startTime
                  });
              }}
            />
          </div>
        </Col>

        <Col md={6}>
          <div className='new-input-wrap'>
            <label className='label-text'>End Time</label>

            <Input
              size='sm'
              label=''
              type='time'
              required={false}
              placeholder='End Time'
              className='time-input'
              value={props.endTime || ''}
              inputChanged={(_endTime) => {
                _endTime !== props.endTime &&
                  props.appInputParamChanged({
                    endTime: _endTime
                  });
              }}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className='help-block'>
            <h5>Help</h5>

            <p>
              To select time period for which the user wants to see the data
              works according to the
              <br />
              <b>formula = (Start - End) * Frequency.</b>
              <br />
              Selecting 0 is equivalent of selecting current day/week/month.{' '}
              <br />
              Eg : If you select frequency as week and start as 2 and end as 1.
              The time period visible will be the 2 weeks before the current
              week.
            </p>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default _DateTimeConfig;
