export default () => {
  return {
    alias: '',
    index: 0,
    plant: {
      label: null,
      value: { plantId: null }
    },
    tag: {
      label: null,
      value: null
    },
    isTag: true,
    isComputed: false,
    equation: ''
  };
};
