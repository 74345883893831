export const GET_REFRESH_DATA= 'GET_REFRESH_DATA';
export const GET_REFRESH_DATA_SUCCESS = 'GET_REFRESH_DATA_SUCCESS';
export const GET_REFRESH_DATA_FAILURE = 'GET_REFRESH_DATA_FAILURE';

export const GET_MEMBER_PROFILE_DATA= 'GET_MEMBER_PROFILE_DATA';
export const GET_MEMBER_PROFILE_DATA_SUCCESS = 'GET_MEMBER_PROFILE_DATA_SUCCESS';
export const GET_MEMBER_PROFILE_DATA_FAILURE = 'GET_MEMBER_PROFILE_DATA_FAILURE';

export const GET_READING_DATA= 'GET_READING_DATA';
export const GET_READING_DATA_SUCCESS = 'GET_READING_DATA_SUCCESS';
export const GET_READING_DATA_FAILURE = 'GET_READING_DATA_FAILURE';

export const GET_FAULT_READING_DATA = 'GET_FAULT_READING_DATA';
export const GET_FAULT_READING_DATA_SUCCESS = 'GET_FAULT_READING_DATA_SUCCESS';
export const GET_FAULT_READING_DATA_FAILURE = 'GET_FAULT_READING_DATA_FAILURE';
export const GET_FAULT_READING_DATA_SUCCESS_TREND = 'GET_FAULT_READING_DATA_SUCCESS_TREND';

export const GET_COMPUTED_READING_DATA = 'GET_COMPUTED_READING_DATA';
export const GET_COMPUTED_READING_DATA_SUCCESS = 'GET_COMPUTED_READING_DATA_SUCCESS';
export const GET_COMPUTED_READING_DATA_SUCCESS_TREND = 'GET_COMPUTED_READING_DATA_SUCCESS_TREND';
export const GET_COMPUTED_READING_DATA_FAILURE = 'GET_COMPUTED_READING_DATA_FAILURE';

export const OPEN_RIGHT_SIDE_DRAWER = 'OPEN_RIGHT_SIDE_DRAWER';
export const CLOSE_RIGHT_SIDE_DRAWER = 'CLOSE_RIGHT_SIDE_DRAWER';
export const GET_COMPUTED_PARAMETER= 'GET_COMPUTED_PARAMETER';
export const GET_COMPUTED_PARAMETER_SUCCESS = 'GET_COMPUTED_PARAMETER_SUCCESS';
export const GET_COMPUTED_PARAMETER_FAILURE = 'GET_COMPUTED_PARAMETER_FAILURE';

export const GET_READING_TYPES_PRELOAD= 'GET_READING_TYPES_PRELOAD';
export const GET_READING_TYPES_PRELOAD_SUCCESS = 'GET_READING_TYPES_PRELOAD_SUCCESS';
export const GET_READING_TYPES_PRELOAD_FAILURE = 'GET_READING_TYPES_PRELOAD_FAILURE';