import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import useSensorParameterCollection from '../common/useSensorParameterCollection';
import SensorParameterSelect from '../Common/SensorParameterSelect';
import { Input, Select } from '../../../../../../../common/components';

const ParameterConfigNormal = (props) => {
  const [
    sensorCollection,
    sensorOptions,
    parameterCollection,
    parameterOptionsCollection,
    onSensorChangeHandle,
    onParameterChangeHandle
  ] = useSensorParameterCollection(props.parameterConfs);

  const colMd =
    props.dataType === props.dataTypeCollection[0] ||
    (props.dataType === props.dataTypeCollection[1] &&
      props.section === props.sectionCollection[0])
      ? 4
      : 6;

  return (
    <Fragment>
      <Row>
        <Col md={colMd}>
          <div className='new-input-wrap'>
            <label className='label-text'>Alias</label>

            <Input
              size='sm'
              label=''
              type='text'
              required={false}
              placeholder='Enter Alias'
              value={props.parameterConfs[0].alias || ''}
              inputChanged={(alias) => {
                alias !== props.parameterConfs[0].alias &&
                  props.onParameterConfCollectionUpdateTrigger({
                    ...props.parameterConfs[0],
                    alias
                  });
              }}
            />
          </div>
        </Col>

        {props.dataType === props.dataTypeCollection[0] && (
          <Col md={colMd}>
            <div className='new-input-wrap'>
              <label className='label-text'>Graph Type</label>

              <Select
                options={props.graphTypeCollection.reduce(
                  (memo, value) => [...memo, { label: value, value }],
                  []
                )}
                isMulti={false}
                placeholder='Select Graph Type'
                value={(() => {
                  const value = props.parameterConfs[0].graphType || '';

                  return {
                    label: value,
                    value
                  };
                })()}
                onChange={({ value: graphType }) => {
                  graphType !== props.parameterConfs[0].graphType &&
                    props.onParameterConfCollectionUpdateTrigger({
                      ...props.parameterConfs[0],
                      graphType
                    });
                }}
              />
            </div>
          </Col>
        )}

        {!(
          props.dataType === props.dataTypeCollection[1] &&
          props.section === props.sectionCollection[1]
        ) && (
          <Col md={colMd}>
            <div className='new-input-wrap'>
              <label className='label-text'>Threshold</label>

              <Input
                size='sm'
                label=''
                type='number'
                required={false}
                placeholder='Enter Threshold'
                value={props.parameterConfs[0].threshold || ''}
                inputChanged={(threshold) => {
                  threshold !== props.parameterConfs[0].threshold &&
                    props.onParameterConfCollectionUpdateTrigger({
                      ...props.parameterConfs[0],
                      threshold
                    });
                }}
              />
            </div>
          </Col>
        )}

        {props.dataType === props.dataTypeCollection[1] &&
          props.section === props.sectionCollection[0] && (
            <Col md={colMd}>
              <div className='new-input-wrap'>
                <div className='d-flex justify-content-between p-0 mb-1'>
                  <label className='label-text'>Unit</label>

                  <label className='label-text font-weight-light'>
                    Show As
                  </label>
                </div>

                <Input
                  size='sm'
                  label=''
                  type='text'
                  required={false}
                  placeholder='Enter Unit'
                  value={props.parameterConfs[0].unit || ''}
                  inputChanged={(unit) => {
                    unit !== props.parameterConfs[0].unit &&
                      props.onParameterConfCollectionUpdateTrigger({
                        ...props.parameterConfs[0],
                        unit
                      });
                  }}
                />
              </div>
            </Col>
          )}
      </Row>

      <Row>
        <Col md={6}>
          <div className='new-input-wrap'>
            <label className='label-text'>Location *</label>

            <SensorParameterSelect
              type='sensor'
              options={sensorOptions}
              value={sensorCollection[0] || ''}
              onChange={(sensor) => {
                onSensorChangeHandle(sensor, 0);
              }}
            />
          </div>
        </Col>

        <Col md={6}>
          <div className='new-input-wrap'>
            <label className='label-text'>Parameter *</label>

            <SensorParameterSelect
              type='parameter'
              options={parameterOptionsCollection[0] || []}
              value={parameterCollection[0] || ''}
              onChange={(parameter) => {
                onParameterChangeHandle(
                  parameter,
                  0,
                  props.onParameterConfCollectionUpdateTrigger
                );
              }}
            />
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ParameterConfigNormal;
