import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import DateTime from "../../../common/utils/dateTimeUtils";
import { Loading } from "../../../common/components";
import { getReportDownloadData } from "./action";
import { checkReport, downloadReport, resetDownloadReport } from "../Report/action";
import { URL } from "../constants";
import axios from "axios";
import { Context } from "../Main/context";
import "./ReportDownload.scss"
import Ecolytics, { WidgetTypes } from "@Ecolibrium-Energy/ecolytics";

const ReportDownload = (props) => {

    const context = useContext(Context);
    const ArrayList = [moment().format(), moment().subtract(1, 'month').format(), moment().subtract(2, 'month').format(), moment().subtract(3, 'month').format(),
    moment().subtract(4, 'month').format(), moment().subtract(5, 'month').format(), moment().subtract(6, 'month').format(), moment().subtract(7, 'month').format(),
    moment().subtract(8, 'month').format(), moment().subtract(9, 'month').format(), moment().subtract(10, 'month').format(), moment().subtract(11, 'month').format()];

    var [dateRange, setDateRange] = useState({
        "from_date": moment().subtract(365, 'days').format(),
        "to_date": moment().add(2, 'hours').format(),
        "datePreset": 0
    });

    const getData = (assetId) => {
        props.getReportDownloadData({ id: assetId, widgetId: props.isPreview ? 'preview' : props.widgetId });
    }

    useEffect(() => {
        Ecolytics.trackWidgetView(WidgetTypes.REPORT_DOWNLOAD, props.dashboardAppConfig && props.dashboardAppConfig.widget_name);
        if (props.dashboardAppConfig && props.dashboardAppConfig.locationBasedParamSelector && !props.isPreview) {
            let c = props.dashboardAppConfig;
            let fromdate = DateTime.getStartDate(c.period, c.from, c.startTime);
            let todate = DateTime.getEndDate(c.period, c.to, c.endTime);
            setDateRange({ ...dateRange, fromdate, todate });
            getData(props.widgetContext.assetId);
        }
    }, []);

    useEffect(() => {
    }, [props.widgetData]);

    useEffect(() => {
        if (props.preview && props.preview.count) {
            let pathname = window.location.pathname.split('/');
            let pathParam = pathname.find(element => {
                if (element.toLowerCase().includes('assetid')) {
                    return true;
                }
            });
            let assetKey = pathParam.split('&');
            let assetParam = assetKey.find(element => {
                if (element.toLowerCase().includes('assetid=')) {
                    return true;
                }
            });
            let assetId = assetParam.slice(8);
            getData(assetId);
        }
    }, [props.preview && props.preview.count]);

    useEffect(() => {
        if (props.refresh && props.refresh.count) {
            let c = props.dashboardAppConfig;
            let fromdate = DateTime.getStartDate(c.period, c.from, c.startTime);
            let todate = DateTime.getEndDate(c.period, c.to, c.endTime);
            setDateRange({ ...dateRange, fromdate, todate });
            getData(props.widgetContext.assetId);
        }
    }, [props.refresh && props.refresh.count]);
    
    const downloadStandardReport = (item) => {
        let data = {
            aggregation: false,
            end_date: moment(item).endOf('month').format(),
            grain: 15,
            reading_types: props.widgetData[0] && props.widgetData[0].config[0].readingtype_ids,
            report_format: props.widgetData[0] && props.widgetData[0].ReportFormat == "xlsx" ? "excel" : "csv",
            report_groups: [],
            request_service: 5,
            sensor_ids: [{ sensorid: props.widgetData[0] && props.widgetData[0].config[0].sensor_ids[0], name: "sensor 1" }],
            start_date: moment(item).startOf('month').format(),
            url: "report-download"
        };
        let url = `${URL}/standard-reports/download/`;
        let token = `token:${JSON.parse(
            localStorage.getItem("smartsense.authToken")
        )}`;
        axios
            .request({
                url,
                method: "POST",
                responseType: "blob", //important
                data,
                headers: {
                    Authorization: token,
                },
            })
            .then(({ data }) => {
                context.notify.success(
                    "Thank you for using Smartsense Insights. Your report has been downloaded."
                );
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = downloadUrl;
                if (data.report_format == "csv") {
                    link.setAttribute("download", `smartsense.csv`);
                } else link.setAttribute("download", `smartsense.xls`);
                //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => {
                context.notify.error("There is no report enabled for this Asset. Please contact to Ecolibrium Support team.");
            });
    };

    if (props.loading) return <Loading />;
    if (props.error) return 'Error Occurred';

    return (
        <div className="tab_content active" >
            <div className="tab_block">
                <div className="content_panel download">
                    <div className="table-responsive">
                        <table>
                            <tr >
                                <th style={{width:"50%"}}>Month</th>
                                
                                <th>Download</th>
                            </tr>
                            {ArrayList && ArrayList.length && ArrayList.map((item, i) => {
                                return (
                                    <tr >
                                        <td style={{width:"50%"}}>
                                            <span style={{width:"100%",display:"flex",alignSelf:"flex-start"}}>{moment(item).format('MMM YYYY')}</span></td>
                                        <td><a className="cursor" onClick={() => {
                                            downloadStandardReport(item);
                                        }}>Download Report</a></td>
                                    </tr>)
                            })}
                        </table>
                    </div>
                </div>
            </div>
        </div>

    );
};

const mapStateToProps = (state, ownPr) => {
    return getWidgetStateFromReducer(state.downloadReport, ownPr)
};

const getWidgetStateFromReducer = (widgetState, ownPr) => {
    let data = [];
    let loading = false;
    let error = false;
    if (ownPr.isPreview && widgetState.data["preview"]) {
        data = widgetState.data["preview"].data || [];
        loading = widgetState.data["preview"].loading;
        error = widgetState.data["preview"].error;
    }
    else if (widgetState.data[ownPr.widgetId]) {
        data = widgetState.data[ownPr.widgetId].data || [];
        loading = widgetState.data[ownPr.widgetId].loading;
        error = widgetState.data[ownPr.widgetId].error;
    }
    return {
        widgetData: data,
        allReports: widgetState.getAllReports,
        loading,
        error
    }
}

ReportDownload.defaultProps = {
    appParams: {},
    preview: {},
    dashboardAppConfig: {},
    isPreview: false,
    widgetId: 0,
    refresh: {}
}

ReportDownload.propTypes = {
    // current state of widgetBuilder form
    appParams: PropTypes.object,
    // user clicked on preview refresh
    preview: PropTypes.object,
    // app config stored in dashobard
    dashboardAppConfig: PropTypes.object,
    isPreview: PropTypes.bool,
    widgetId: PropTypes.string,
    // refresh widget it can be {MANUAL/AUTO}
    refresh: PropTypes.object,
}

export default connect(mapStateToProps, {
    getReportDownloadData,
    checkReport,
    downloadReport,
    resetDownloadReport
})(ReportDownload);
