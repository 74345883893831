import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import useFormulaConfigComputed from '../common/useFormulaConfigComputed';
import ParameterConfigComputedCore from '../Common/ParameterConfigComputedCore';
import { Input, CheckBox } from '../../../../../../../common/components';

const ParameterConfigComputed = (props) => {
  const [
    initialized,
    formulaConfActiveIndex,
    formulaConfParameterCollections,
    formulaConfPointerCollections,
    onFormulaConfParameterCollectionUpdateHandle,
    onFormulaConfPointerCollectionUpdateHandle,
    onFormulaConfResetHandle
  ] = useFormulaConfigComputed(props.formulaConfs, props.onFormulaConfsChange);

  const renderParameterConfigComputedCore = () => {
    return (
      <ParameterConfigComputedCore
        formulaConfActiveIndex={formulaConfActiveIndex}
        formulaConfParameterCollections={formulaConfParameterCollections}
        formulaConfPointerCollections={formulaConfPointerCollections}
        formulaConfs={props.formulaConfs}
        onFormulaConfParameterCollectionUpdateTrigger={
          onFormulaConfParameterCollectionUpdateHandle
        }
        onFormulaConfPointerCollectionUpdateTrigger={
          onFormulaConfPointerCollectionUpdateHandle
        }
        onFormulaConfCreateTrigger={() => {
          return null;
        }}
        onFormulaConfResetTrigger={onFormulaConfResetHandle}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='content-border-wrap mg-b-15'>
        <Row>
          <Col md={3}>
            <div className='new-input-wrap'>
              <label className='label-text'>Alias</label>
              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter Alias'
                value={props.formulaConf.alias || ''}
                inputChanged={(alias) => {
                  props.onFormulaConfChangeTrigger({ alias });
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className='new-input-wrap'>
              <label className='label-text'>Unit</label>
              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter Unit'
                value={props.formulaConf.unit || ''}
                inputChanged={(unit) => {
                  props.onFormulaConfChangeTrigger({ unit });
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className='new-input-wrap'>
              <label className='label-text'>Line Label</label>
              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter Line Label'
                value={props.formulaConf.label}
                inputChanged={(label) => {
                  props.onFormulaConfChangeTrigger({ label });
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className='new-input-wrap'>
              <label className='label-text'>Line Value</label>
              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter Line Value'
                value={props.formulaConf.lineValue || ''}
                inputChanged={(lineValue) => {
                  props.onFormulaConfChangeTrigger({ lineValue });
                }}
              />
            </div>
          </Col>
        </Row>
        <div className='new-input-wrap d-flex justify-content-end'>
          <div className='new-custom-checkbox'>
            <CheckBox
              label={'Show Partial Calculation Result'}
              size='sm'
              checked={props.formulaConf.showPartial}
              checkboxClicked={(showPartial) => {
                props.onFormulaConfChangeTrigger({ showPartial });
              }}
            />
          </div>
        </div>

        {renderParameterConfigComputedCore()}
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfigComputed;
