import React, { useCallback, useEffect, useMemo, useState } from 'react';

import tagConfsInputGet from '../common/tagConfsInputGet';
import usePlantTagCollection from '../common/usePlantTagCollection';
import tagConfInputGet from '../common/tagConfInputGet';
import tagGet from '../common/tagGet';
import dashboardFilterConfigInputMeta from '../common/dashboardFilterConfigInputMeta';
import { Select } from '../../../../../../../common/components';

const localStorageItemGet = (key) => {
  const item = localStorage.getItem(key);

  return item && JSON.parse(item);
};

const discoveryFlagGet = () => {
  const key = 'smartsense.member';

  return (localStorageItemGet(key) || { clientid: {} }).clientid
    .is_discovery_client;
};

const serviceCollectionGet = () => {
  const key = 'smartsense.services';

  return localStorageItemGet(key);
};

const allPlantFlagGet = (serviceId) => {
  return (
    !discoveryFlagGet() &&
    (serviceId
      ? serviceCollectionGet()
          .filter(
            ({ name }) =>
              !['Network Insight', 'Network Monitoring'].includes(name)
          )
          .map(({ id }) => id)
          .includes(serviceId)
      : true)
  );
};

const FilterConfig = (_props) => {
  const {
    setDashboardFilterConfig,
    setDashboardFilterConfigInitialized,
    ...props
  } = _props;

  const _dashboardFilterConfig = useMemo(() => {
    return props.templateDashboard && props.templateDashboard.config;
  }, [props.templateDashboard]);

  const [
    plantCollection,
    plantOptions,
    ,
    tagOptionsCollection,
    onPlantChangeHandle,
    ,
    tagOptionsLoaded
  ] = usePlantTagCollection(
    useMemo(() => {
      return tagConfsInputGet();
    }, []),
    allPlantFlagGet(props.serviceId)
  );

  const [initialized, setInitialized] = useState(false);

  const [metaCollection, setMetaCollection] = useState();

  const [tagConfs, setTagConfs] = useState();

  const [_tagConfs, _setTagConfs] = useState();

  const onTagChangeHandle = useCallback(
    (value) => {
      const _tagGet = (tagConf, plant) => {
        return plant.value.plantId &&
          plant.value.plantId !== tagConf.plant.value.plantId
          ? tagGet(tagConf, tagOptionsCollection)
          : tagConf.tag;
      };

      const tagConfs = props.dashboardFilterConfig.tagConfs.reduce(
        (memo, tagConf, index) => {
          const plant = tagConf.plant;

          const {
            meta = {
              unitPrefix: '',
              unitSuffix: '',
              ...metaCollection[value.index]
            },
            alias,
            tag
          } = (() => {
            switch (true) {
              case !value:
                return {
                  meta: dashboardFilterConfigInputMeta,
                  ...tagConfInputGet()
                };

              case !!index:
                return {
                  ..._tagConfs[value.index],
                  tag: _tagGet(_tagConfs[value.index], plant)
                };

              default:
                return {
                  ...value,
                  tag: _tagGet(value, plant)
                };
            }
          })();

          return [
            ...memo,
            {
              ...tagConf,
              ...meta,
              alias,
              plant,
              tag
            }
          ];
        },
        []
      );

      setDashboardFilterConfig({
        tagConfs
      });
    },
    [
      _tagConfs,
      metaCollection,
      props.dashboardFilterConfig,
      tagOptionsCollection,
      setDashboardFilterConfig
    ]
  );

  useEffect(() => {
    initialized &&
      setDashboardFilterConfig((dashboardFilterConfig) => {
        setDashboardFilterConfigInitialized(
          (dashboardFilterConfigInitialized) => {
            return !dashboardFilterConfigInitialized
              ? true
              : dashboardFilterConfigInitialized;
          }
        );

        switch (true) {
          case !plantCollection[0].value.plantId &&
            !!dashboardFilterConfig.tagConfs[0].plant.value.plantId:
            return {
              ...dashboardFilterConfig,
              tagConfs: dashboardFilterConfig.tagConfs.reduce(
                (memo, tagConf) => {
                  return [
                    ...memo,
                    {
                      ...tagConf,
                      plant: tagConfInputGet().plant
                    }
                  ];
                },
                []
              )
            };

          case !!tagOptionsLoaded:
            return (() => {
              const tagConfs = dashboardFilterConfig.tagConfs.reduce(
                (memo, tagConf) => {
                  const plant = plantCollection[0];

                  const tag = (() => {
                    switch (true) {
                      case !!tagConf.tag.value:
                        return tagGet(tagConf, tagOptionsCollection);

                      default:
                        return tagConf.tag;
                    }
                  })();

                  return [
                    ...memo,
                    {
                      ...tagConf,
                      plant,
                      tag
                    }
                  ];
                },
                []
              );

              return { ...dashboardFilterConfig, tagConfs };
            })();

          default:
            return dashboardFilterConfig;
        }
      });
  }, [
    initialized,
    plantCollection,
    tagOptionsCollection,
    tagOptionsLoaded,
    metaCollection,
    setDashboardFilterConfigInitialized,
    setDashboardFilterConfig
  ]);

  useEffect(() => {
    !initialized &&
      tagOptionsLoaded &&
      plantCollection.length &&
      plantCollection[0].value.plantId === plantOptions[0].value.plantId &&
      _dashboardFilterConfig &&
      (() => {
        _dashboardFilterConfig.tagConfs &&
          onTagChangeHandle(_dashboardFilterConfig.tagConfs[0]);

        setInitialized(true);
      })();
  }, [
    initialized,
    tagOptionsLoaded,
    plantCollection,
    plantOptions,
    tagOptionsCollection,
    _dashboardFilterConfig,
    onTagChangeHandle
  ]);

  useEffect(() => {
    !initialized &&
      tagOptionsLoaded &&
      _dashboardFilterConfig &&
      (() => {
        setMetaCollection(_dashboardFilterConfig.metas);

        setTagConfs(_dashboardFilterConfig.tagConfs);

        _setTagConfs(_dashboardFilterConfig._tagConfs);

        onPlantChangeHandle(plantOptions[0], 0);
      })();
  }, [
    initialized,
    tagOptionsLoaded,
    _dashboardFilterConfig,
    plantOptions,
    onPlantChangeHandle
  ]);
  const getRowStyles = (position) => ({
    width: position === 'end' ? '100%' : 'auto',
    justifyContent: position === 'end' ? 'flex-end' : 'flex-start',
    display: 'flex'
  });

  return (
    <div style={{ ...getRowStyles(props.position) }}>
      {_dashboardFilterConfig && _dashboardFilterConfig.plantFilter && (
        <div
          className='new-input-wrap selection-box-wrapper'
          style={{ minWidth: '160px' }}
        >
          <Select
            placeholder=''
            options={plantOptions}
            value={(() => {
              switch (true) {
                case !!props.dashboardFilterConfig &&
                  !!props.dashboardFilterConfig.tagConfs[0].plant.value.plantId:
                  return props.dashboardFilterConfig.tagConfs[0].plant;
              }
            })()}
            onChange={(plant) => {
              onPlantChangeHandle(
                plant.value ? plant : tagConfInputGet().plant,
                0
              );
            }}
          />
        </div>
      )}

      {_dashboardFilterConfig && _dashboardFilterConfig.tagFilter && (
        <div
          className='new-input-wrap selection-box-wrapper'
          style={{ minWidth: '160px', marginRight: '100px' }}
        >
          <Select
            placeholder=''
            options={[
              ...((tagConfs &&
                tagConfs.reduce((memo, tagConf, index) => {
                  return [
                    ...memo,
                    {
                      label: metaCollection[index].kpi,
                      value: tagConf
                    }
                  ];
                }, [])) ||
                [])
            ]}
            value={(() => {
              switch (true) {
                case !!props.dashboardFilterConfig &&
                  !!props.dashboardFilterConfig.tagConfs[0].tag.value:
                  return {
                    label: props.dashboardFilterConfig.tagConfs[0].kpi,
                    value: props.dashboardFilterConfig.tagConfs[0]
                  };
              }
            })()}
            onChange={({ value }) => {
              onTagChangeHandle(value);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FilterConfig;