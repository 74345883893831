import React from "react";
import noAccessIconDark from '../../../../assets/images/no-access.svg';
import noAccessIconLight from '../../../../assets/images/no-access-light.svg';

const NoAccess = () => {
  return (
    <div className="no-access-wrapper">
      <img src={noAccessIconDark} className='dark-img' />
      <img src={noAccessIconLight} className='light-img' />
      <h3>
      It appears that you are not authorized to access this module. We'd appreciate if you could reach out to SmartSense relationship manager.
      </h3>
    </div>
  );
};

export default NoAccess;
