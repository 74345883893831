import React, { Fragment, useEffect, useState } from 'react';

import widgetConfigParamGet from '../common/widgetConfigParamGet';
import { Button } from '../../../../../../../common/components';
import _DateTimeConfig from './_DateTimeConfig';

const periodOptionCollection = ['Day', 'Week', 'Month'];

const DateTimeConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const [key, value] = Object.entries(object)[0];

    props.appInputParamChanged({
      [key]: {
        value
      }
    });
  };

  const renderDateTimeConfig = () => {
    return (
      <_DateTimeConfig
        periodOptionCollection={
          (widgetConfigParamGet(props.widgetConfig, 'period') &&
            widgetConfigParamGet(props.widgetConfig, 'period').options) ||
          periodOptionCollection
        }
        period={props.widgetParams.period.value}
        from={props.widgetParams.from.value}
        to={props.widgetParams.to.value}
        startTime={props.widgetParams.startTime.value}
        endTime={props.widgetParams.endTime.value}
        appInputParamChanged={appInputParamChangedHandle}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper dateTime-config-wrapper'>
        {renderDateTimeConfig()}

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default DateTimeConfig;
