import React, { Component, Fragment, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Icon, Button, Tabs, ModalBox, Input, Badge, Select } from '../../../common/components/';
import { APP_SETTINGS, getAppSettingsForUrl } from '../../../admin/container/Main/appSetting';
import { createDashboardGlobalAction } from '../../../admin/container/Dashboard/action';
import { createUploadGlobalAction } from '../../../admin/container/DataUpload/action';
import { ellipsisText } from '../../../admin/container/utils';
// import brandlogo from '../../../../assets/images/Brand/Logo-ColorSVG.svg';
import brandlogo from '../../../../assets/images/Brand/__smartsense-logo.svg';
import brandlogoDark from '../../../../assets/images/Brand/_smartsense-logo.svg';
import notificationIcon from '../../../../assets/images/notification-icon2.svg';
import dummyLogo from '../../../../assets/images/dummy-logo.png';
import { isNotificationPopUp, getAllNotificationCount } from '../../../admin/container/Notification/action'
import SortNotification from '../../../admin/container/Notification/index';
import PartnerLogin from '../../../admin/container/PartnerLogin';
import { clientLogout, switchUser } from '../../../admin/container/PartnerLogin/action'
import axios from 'axios';
import { URL } from '../../../admin/container/constants';
import { getRefreshAction, getMemberProfileDataAction } from '../../../admin/container/Main/action';
import { getUsers } from '../UserSearch/action';
import { isOpenScreenDesignModal } from '../../../admin/container/AssetScreenDesign/action';
import { Context, UserContext, PlantContext } from '../../../admin/container/Main/context';
import { EDITOR, VIEWER, NOT_ACCESS_MESSAGE } from '../../../admin/container/Main/appSetting';
import { DASHBOARD, ALERT, REPORT, EVENT, DATA_UPLOAD, IAM_USER, TEMPALTES, PARTNER, CALCULATED_PARAMETER } from '../../../admin/container/Main/permissionContants';
import { isOpenCalculatedParameterModal } from '../../../admin/container/CalculatedParameter/action';
// import SlideDrawer from '../../components/SideDrawer';
// import BackDrop from '../../components/SideDrawer/BackDrop';
// import UserPermissionHOC from '../../../admin/container/Main/UserPermissionHOC';
import bgImage from '../../../../assets/images/header-bg.svg';
import Ecolytics, { EventNames } from '@Ecolibrium-Energy/ecolytics';
import { ReactComponent as BrandLogoDark } from '../../../../assets/images/Brand/_smartsense-logo.svg';
import { ReactComponent as BrandLogoLight } from '../../../../assets/images/Brand/__smartsense-logo.svg';
import './header.scss';

const CREATE_OPTIONS = [
    {
        path: 'Alert',
        route: '/Alert/AlertBuilder',
        permission: ALERT.CREATE_ALERT,
    },
    {
        path: 'Report',
        route: '/Report/ReportBuilder',
        permission: REPORT.CREATE_REPORT,
    },
    {
        path: 'Activity',
        route: '/Activity/ActivityBuilder',
        permission: EVENT.CREATE_EVENT,
    },
    {
        path: 'Dashboard',
        route: '/Dashboard',
        permission: DASHBOARD.CREATE_DASHBOARD,
    },
    {
        path: 'DataUpload',
        route: '/DataUpload',
        permission: DATA_UPLOAD.UPLOAD_DATA_FILE,
    },
    {
        path: 'UserManagement',
        route: '/UserManagement/UserBuilder',
        permission: IAM_USER.CREATE_NEW_USER,
    },
    {
        path: 'Templates',
        permission: TEMPALTES.CREATE_TEMPLATE_FOR_SERVICE,
    },
    {
        path: 'CalculatedParameter',
        permission: CALCULATED_PARAMETER.CREATE_CALCULATED_PARAMETER,
    },
]

const PLANT_DEFAULT ={label:"All", value:0}

const Header = (props) => {

    const context = useContext(Context);
    const userContext = useContext(UserContext);
    const plantContext = useContext(PlantContext);

    const [member, setMember] = useState(null);
    const [plantList, setPlantList] = useState([]);
    const [showPlant, setShowPlant] = useState(false);

    let memberDetails = localStorage.getItem('smartsense.admin_member') && JSON.parse(localStorage.getItem('smartsense.admin_member'));
    let { pathname, isNotificationOpen } = props;
    let app = APP_SETTINGS[pathname && pathname[1]] || {};
    let editMode = false;;
    if (location && location.search) {
        editMode = location.search.split("=")[1].split("?")[0];
    }
    let showCreateButton = true;
    if (pathname && pathname[1] == 'AssetInsights') showCreateButton = false;
    if (pathname && pathname[1] == 'Notification') showCreateButton = false;
    if (pathname && pathname[1] == 'UserProfile') showCreateButton = false;
    if (pathname && pathname[1] == 'Templates' && pathname[2] == 'WidgetBuilder') showCreateButton = false;
    let { unread, total_record } = props.getNotificationUnreadCount;
    let isAdminProfile = localStorage.getItem('adminProfile');

    useEffect(() => {
        let member = localStorage.getItem('smartsense.member') && JSON.parse(localStorage.getItem('smartsense.member'));
        setMember(member)
    }, [props.getRefreshData]);

    useEffect(()=>{
        let plantData = JSON.parse(
            localStorage.getItem("smartsense.member_plants")
          ).map((item) =>{
            item.label = item.name,
            item.value = item.plantid
            return item
          });
          setPlantList([PLANT_DEFAULT,...plantData])
    },[])


    useEffect(()=>{
    if(plantContext.showPlant){
        setShowPlant(true)
    }else{
        setShowPlant(false)
    }
    },[plantContext])

    const logout = () => {
        // const LOGIN_URL = `${Config.api_url}/../../api/auth/logout/`;
        // localStorage.clear();
        // window.location.href = window.location.origin + '#/login/SmartSense';
        const LOGIN_URL = `${URL}/auth/logout/`;
        return axios.delete(LOGIN_URL, {
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
            },
        }).then(response => {
            localStorage.clear();
            Ecolytics.track(EventNames.LOGOUT)
            Ecolytics.unregisterUser();
            window.location.href = window.location.origin + '#/login/SmartSense';
        }).catch(error => console.log(error.response))
    }

    const clientLogout = () => {
        if ((userContext.permission(PARTNER.LOGOUT_USER_OF_CLIENT) || isAdminProfile === 'false')) {
            let data = localStorage.getItem('smartsense.client_logout_data') && JSON.parse(localStorage.getItem('smartsense.client_logout_data'));

            return axios.delete(`${URL}/partners/clients/${data.clientId}/users/logout/`, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
                },
                user_id: data.memberId
            }).then((response) => {
                let adminToken = JSON.parse(localStorage.getItem('smartsense.superAuthToken'))
                localStorage.setItem("smartsense.authToken", JSON.stringify(adminToken));
                // localStorage.setItem("smartsense.superAuthToken", JSON.stringify(adminToken));
                localStorage.setItem('adminProfile', true);
                // localStorage.removeItem("smartsense.superAuthToken");
                localStorage.removeItem("smartsense.client_logout_data");
                props.getRefreshAction();
                props.getMemberProfileDataAction();
                props.getAllNotificationCount();
                props.getUsers({});
                props.history.push('/Dashboard')
                window.location.reload();
                return props.clientLogout(response.data)
            }
            ).catch(error => console.log(error.response))
        } else context.notify.error(NOT_ACCESS_MESSAGE)
    }

    const enderUrl = () => {
        window.location.href = window.location.origin;
    }

    // const create = (properties) => {

    //     if (userContext.roles && userContext.roles[VIEWER]) {
    //         context.notify.error(NOT_ACCESS_MESSAGE)
    //     } else {
    //         let { pathname, history } = props;
    //         if (pathname && pathname[1] == 'Alert') {
    //             history.push('/Alert/AlertBuilder');
    //         }
    //         if (pathname && pathname[1] == 'Report') {
    //             history.push('/Report/ReportBuilder');
    //         }

    //         if (pathname && pathname[1] == 'Activity') {
    //             history.push('/Activity/ActivityBuilder');
    //         }

    //         if (pathname && pathname[1] == 'Dashboard') {
    //             // TO DO: handle for all screens via action
    //             // history.push(APP_SETTINGS.Dashboard.url);
    //             history.push('/Dashboard');
    //             props.createDashboardGlobalAction({});
    //         }

    //         if (pathname && pathname[1] == 'DataUpload') {
    //             // TO DO: handle for all screens via action
    //             // props.history.push(APP_SETTINGS['DataUpload'].url);
    //             history.push('/DataUpload');
    //             props.createUploadGlobalAction({});
    //         }

    //         if (pathname && pathname[1] == 'UserManagement') {
    //             history.push('/UserManagement/UserBuilder');
    //         }

    //         if (pathname && (pathname[1] == 'Templates' || (pathname[1] == 'Templates' && pathname[2] == 'TemplateDetails'))) {
    //             props.isOpenScreenDesignModal(true);
    //         }
    //     }
    // }

    // backdropClickHandler = () => {
    //     this.setState({
    //         drawerOpen: false
    //     })
    // }

    const create = () => {
        if (userContext.permission(getCreateOption().permission)) {
            let { route, path } = getCreateOption();
            if (path == 'Dashboard') props.createDashboardGlobalAction({});
            if (path == 'DataUpload') props.createUploadGlobalAction({});
            if (path == 'Templates' || (path == 'Templates' && pathname[2] == 'TemplateDetails')) {
                props.isOpenScreenDesignModal(true);
            } else props.history.push(route);
        } else context.notify.error(NOT_ACCESS_MESSAGE)
    }

    // const create = () => {
    //     if (!userContext.permission(getCreateOption().permission)) {
    //         let { route, path } = getCreateOption();
    //         if (path == 'Dashboard') props.createDashboardGlobalAction({});
    //         if (path == 'DataUpload') props.createUploadGlobalAction({});
    //         if (path == 'Templates' || (path == 'Templates' && pathname[2] == 'TemplateDetails')) {
    //             props.isOpenScreenDesignModal(true);
    //         }else if (path == 'CalculatedParameter') {
    //             props.isOpenCalculatedParameterModal(true);
    //         } else props.history.push(route);
    //     } else context.notify.error(NOT_ACCESS_MESSAGE)
    // }

    const getCreateOption = () => {
        let { pathname } = props;
        return CREATE_OPTIONS.filter(item => item.path == pathname[1])[0]
    }

    const getHeaderButtonLabel = (path) => {
        const settings = getAppSettingsForUrl(`/${path}`) || {};
        return <span dangerouslySetInnerHTML={{ __html: settings.label || path }} />
    }

    // const createButton = () => {
    //     return showCreateButton && <Button
    //         buttonType='primary'
    //         buttonClick={create}
    //         size='md'
    //         innerContent='+'
    //         isDisabled={(props.isSwitchUser) ? true : false}
    //     // isDisabled={(props.isSwitchUser) ? true : userContext.permission(getCreateOption().permission) ? true : false}
    //     />
    // }

    // const EnhancedButton = UserPermissionHOC(createButton)
    const renderName = (member) => {
        return member &&
            member.firstname &&
            member.lastname
            ? <span className='userName' data-title={`${member.firstname} ${member.lastname}`}>{ellipsisText(`${member.firstname} ${member.lastname}`, 16)}</span>
            : member &&
                member.email &&
                member.email.length > 0 ? <span title={member.email}>{ellipsisText(member.email, 16)}</span> : 'Menu'
    }

    return (
        <Fragment>
            <Navbar className="fixed-top HeaderContainer navbar-bg" color="primary" light expand="md">
                <img className="bg-img light-theme-bg-img" src={bgImage} alt="smartsense" />
                <img className="bg-img dark-theme-bg-img" src={bgImage} alt="smartsense" />
                <div className="UserRole-heading">
                  <BrandLogoLight className='logo light-theme-img'/>

                  <BrandLogoDark className='logo dark-theme-img'/>
                    {/* SMARTSENSE */}
                </div>
                {/* {
                    editMode !== 'true' && <Nav className={isAdminProfile == 'false' ? "ml-auto module-heading" : 'ml-auto'} navbar>
                        <NavItem>
                            <NavLink>
                                <span className='title-icon' style={{ fontSize: '16px' }}>
                                    <i style={{ marginTop: '3px' }} className={app.icon ? app.icon : 'fas fa-desktop'}></i>
                                </span>
                                <div className="appTitle">
                                    <div className='appTitle-lable'>
                                        {
                                            isAdminProfile == 'false' && member ?
                                                `${member.firstname} ${member.lastname}'s ${pathname && pathname[1]}`
                                                :
                                                getHeaderButtonLabel(pathname && pathname[1])
                                        }
                                    </div>
                                    <Button
                                        buttonType='primary'
                                        buttonClick={create}
                                        size='md'
                                        innerContent='+'
                                        isDisabled={(props.isSwitchUser) ? true : !showCreateButton ? true : false}
                                    />
                                </div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                } */}
                {/* {showPlant && <div style={{width:"20%"}}>
                <Select
                  name="Plant"
                  value={plantContext.plantValue ? plantContext.plantValue :PLANT_DEFAULT}
                  onChange={plantContext.handleChange}
                  options={plantList}
                  placeholder="Select Plant"
                  isMulti={false}
                />
                </div>} */}

                <div className={total_record > 0 ? "notification-wrapper cursor" : 'notification-wrapper'} >
                    <span className={unread != 0 ? 'notification-icon' : 'notification-icon static-icon'} onClick={(e) => {
                        e.stopPropagation();
                        // pathname && pathname[1] !== 'Notification' && total_record > 0 && props.isNotificationPopUp(!isNotificationOpen)
                        pathname && pathname[1] !== 'Notification' && total_record > 0 && context.notification.show(!context.notification.isShow)
                    }}>
                        {/* <i className={unread != 0 ? "fas fa-bell" : 'fas fa-bell'}>
                        </i> */}
                        <img src={notificationIcon} />
                        {unread != 0 && <Badge
                            type='important'
                            importance={1}
                            isPillType={true}
                            innerContent={unread > 99 ? "99+" : unread}
                        />
                        }
                    </span>
                    {context.notification.isShow && <SortNotification history={props.history} />}
                </div>
                <Nav className="ml-auto user-nav-wrap" navbar>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            {
                                memberDetails && memberDetails.clientid && memberDetails.clientid.logo ?
                                    <img
                                        className="customberlogo"
                                        src={memberDetails.clientid.logo}
                                        alt=""
                                        height={56}
                                    /> : <img
                                        className="customberlogo"
                                        src={dummyLogo}
                                        alt=""
                                        height={56}
                                    />
                            }
                            {isAdminProfile == 'false' ? renderName(memberDetails) : renderName(member)}
                        </DropdownToggle>
                        {
                            localStorage.getItem('partner_login') == 'true' ? <Fragment>
                                {
                                    !props.isSwitchUser && pathname && pathname[1] !== 'PartnerLogin' && <DropdownMenu right>
                                        <DropdownItem onClick={() => props.history.push('/UserProfile')}>
                                            <span className='user-option'><i className="fas fa-random"></i> My Profile</span>
                                        </DropdownItem>
                                        <DropdownItem onClick={() => props.switchUser(true)}>
                                            <span className='user-option'><i className="fas fa-random"></i> Switch To Client</span>
                                        </DropdownItem>
                                        {
                                            isAdminProfile == 'false' && memberDetails && memberDetails.clientid && memberDetails.clientid.is_client && <DropdownItem onClick={() => clientLogout()}>
                                                <span className='user-option'><i className="fas fa-toggle-on"></i>Switch To My Account</span>
                                            </DropdownItem>
                                        }
                                        <DropdownItem onClick={logout}>
                                            <span className='user-option'><i className="fas fa-sign-out-alt"></i>Logout</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                }
                            </Fragment> : <Fragment>
                                {pathname && pathname[1] !== 'PartnerLogin' && <DropdownMenu right>
                                    <DropdownItem onClick={() => props.history.push('/UserProfile')}>
                                        <span className='user-option'><i className="fas fa-random"></i> My Profile</span>
                                    </DropdownItem>
                                    <DropdownItem onClick={logout}>
                                        <span className='user-option'><i className="fas fa-sign-out-alt"></i>Logout</span>
                                    </DropdownItem>
                                </DropdownMenu>}
                            </Fragment>
                        }

                    </UncontrolledDropdown>
                </Nav>
            </Navbar>
            {props.isSwitchUser && <PartnerLogin
                history={props.history}
            />}
        </Fragment>
    );
}

// class Header extends Component {
//     constructor(props) {
//         super(props);
//         this.logout = this.logout.bind(this);
//         this.renderUrl = this.renderUrl.bind(this);
//         this.path = location.pathname.split('/');

//     }

//     logout() {
//         // const LOGIN_URL = `${Config.api_url}/../../api/auth/logout/`;
//         const LOGIN_URL = `${URL}/auth/logout/`;
//         return axios.delete(LOGIN_URL, {
//             headers: {
//                 "Content-Type": "application/json; charset=UTF-8",
//                 Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
//             },
//         }).then(response => {
//             localStorage.clear();
//             window.location.href = window.location.origin + '#/login/SmartSense';
//         }).catch(error => console.log(error.response))
//     }

//     clientLogout() {
//         let data = localStorage.getItem('smartsense.client_logout_data') && JSON.parse(localStorage.getItem('smartsense.client_logout_data'));

//         return axios.delete(`${URL}/partners/clients/${data.clientId}/users/logout/`, {
//             headers: {
//                 "Content-Type": "application/json; charset=UTF-8",
//                 Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
//             },
//             user_id: data.memberId
//         }).then((response) => {
//             let adminToken = JSON.parse(localStorage.getItem('smartsense.superAuthToken'))
//             localStorage.setItem("smartsense.authToken", JSON.stringify(adminToken));
//             // localStorage.setItem("smartsense.superAuthToken", JSON.stringify(adminToken));
//             localStorage.setItem('adminProfile', true);
//             // localStorage.removeItem("smartsense.superAuthToken");
//             localStorage.removeItem("smartsense.client_logout_data");
//             this.props.getRefreshAction();
//             this.props.getMemberProfileDataAction();
//             this.props.getAllNotificationCount();
//             this.props.getUsers({});
//             window.location.reload();
//             return this.props.clientLogout(response.data)
//         }
//         ).catch(error => console.log(error.response))
//     }

//     renderUrl() {
//         window.location.href = window.location.origin;
//     }

//     create = () => {

//         let data = <UserContext.Consumer>
//             {(roles) => {
//                 return roles
//             }}
//         </UserContext.Consumer>


//         // let { pathname } = this.props;
//         // if (pathname && pathname[1] == 'Alert') {
//         //     this.props.history.push('/Alert/AlertBuilder');
//         // }

//         // if (pathname && pathname[1] == 'Report') {
//         //     this.props.history.push('/Report/ReportBuilder');
//         // }

//         // if (pathname && pathname[1] == 'Activity') {
//         //     this.props.history.push('/Activity/ActivityBuilder');
//         // }

//         // if (pathname && pathname[1] == 'Dashboard') {
//         //     // TO DO: handle for all screens via action
//         //     // this.props.history.push(APP_SETTINGS.Dashboard.url);
//         //     this.props.history.push('/Dashboard');
//         //     this.props.createDashboardGlobalAction({});
//         // }

//         // if (pathname && pathname[1] == 'DataUpload') {
//         //     // TO DO: handle for all screens via action
//         //     // this.props.history.push(APP_SETTINGS['DataUpload'].url);
//         //     this.props.history.push('/DataUpload');
//         //     this.props.createUploadGlobalAction({});
//         // }

//         // if (pathname && pathname[1] == 'UserManagement') {
//         //     this.props.history.push('/UserManagement/UserBuilder');
//         // }

//         // if (pathname && (pathname[1] == 'Templates' || (pathname[1] == 'Templates' && pathname[2] == 'TemplateDetails'))) {
//         //     this.props.isOpenScreenDesignModal(true);
//         // }
//     }

//     // backdropClickHandler = () => {
//     //     this.setState({
//     //         drawerOpen: false
//     //     })
//     // }


//     getHeaderButtonLabel = (path) => {
//         const settings = getAppSettingsForUrl(`/${path}`) || {};
//         return <span dangerouslySetInnerHTML={{ __html: settings.label || path }} />
//     }

//     render() {

//         // let backdrop;
//         // if (this.state.drawerOpen) {
//         //     backdrop = <BackDrop close={this.backdropClickHandler} />;
//         // }

//         // let memberDetails = this.props.getRefreshData.data.member;
//         let memberDetails = localStorage.getItem('smartsense.admin_member') && JSON.parse(localStorage.getItem('smartsense.admin_member'));
//         let { pathname, isNotificationOpen } = this.props;
//         let app = APP_SETTINGS[pathname && pathname[1]] || {};
//         let editMode = false;;
//         if (location && location.search) {
//             editMode = location.search.split("=")[1].split("?")[0];
//         }
//         let showCreateButton = true;
//         if (pathname && pathname[1] == 'AssetInsights') showCreateButton = false;
//         if (pathname && pathname[1] == 'Notification') showCreateButton = false;
//         let { unread, total_record } = this.props.getNotificationUnreadCount;
//         let isAdminProfile = localStorage.getItem('adminProfile');
//         let member = localStorage.getItem('smartsense.member') && JSON.parse(localStorage.getItem('smartsense.member'));

//         return (
//             <Fragment>
//                 <Navbar className="fixed-top HeaderContainer" color="primary" light expand="md">
//                     {/* <a class="navbar-brand" href="#">
//                     <div className="UserRole-heading">
//                         <img
//                             className="logo"
//                             src={brandlogo}
//                             alt="smartsense"
//                             height={32}
//                             onClick={() => {
//                                 this.props.history.push(
//                                     APP_SETTINGS.Dashboard ? APP_SETTINGS.Dashboard.url : '',
//                                 );
//                                 window.location.reload();
//                             }}
//                         />
//                         <span>SMARTSENSE</span>
//                     </div>
//                 </a> */}
//                     <div className="UserRole-heading">
//                         <img
//                             className="logo"
//                             src={brandlogo}
//                             alt="smartsense"
//                             height={32}
//                             onClick={() => {
//                                 this.props.history.push(
//                                     APP_SETTINGS.Dashboard ? APP_SETTINGS.Dashboard.url : '',
//                                 );
//                                 window.location.reload();
//                             }}
//                         />
//                         SMARTSENSE
//                     </div>
//                     {
//                         !editMode && <Nav className={isAdminProfile == 'false' ? "ml-auto module-heading" : 'ml-auto'} navbar>
//                             <NavItem>
//                                 <NavLink>
//                                     <span className='title-icon' style={{ fontSize: '16px' }}>
//                                         {/* {app.icon} */}
//                                         <i style={{ marginTop: '3px' }} className={app.icon ? app.icon : 'fas fa-desktop'}></i>
//                                     </span>
//                                     {/* <Icon size={35} alt="" src={TitleIcon} position={ICON_POSITION.CENTER_LEFT} /> */}
//                                     <div className="appTitle">
//                                         <div className='appTitle-lable'>
//                                             {
//                                                 isAdminProfile == 'false' ?
//                                                     `${member.firstname} ${member.lastname}'s ${pathname && pathname[1]}`
//                                                     :
//                                                     this.getHeaderButtonLabel(pathname && pathname[1])
//                                             }
//                                         </div>
//                                         {
//                                             showCreateButton && <Button
//                                                 buttonType='primary'
//                                                 buttonClick={this.create}
//                                                 size='md'
//                                                 innerContent='+'
//                                                 isDisabled={this.props.isSwitchUser ? true : false}
//                                             />
//                                         }
//                                     </div>
//                                 </NavLink>
//                             </NavItem>
//                         </Nav>
//                     }

//                     <div className={total_record > 0 ? "notification-wrapper cursor" : 'notification-wrapper'} >
//                         <span className={unread != 0 ? 'notification-icon' : 'static-icon'} onClick={(e) => {
//                             e.stopPropagation();
//                             pathname && pathname[1] !== 'Notification' && total_record > 0 && this.props.isNotificationPopUp(!isNotificationOpen)
//                         }}>
//                             <i className={unread != 0 ? "fas fa-bell rotate-icon" : 'fas fa-bell'}>
//                             </i>
//                             {unread != 0 && <Badge
//                                 type='important'
//                                 importance={1}
//                                 isPillType={true}
//                                 innerContent={unread}
//                             />
//                             }
//                         </span>
//                         {isNotificationOpen && <Notification history={this.props.history} />}
//                     </div>
//                     <Nav className="ml-auto" navbar>
//                         <UncontrolledDropdown nav inNavbar>
//                             <DropdownToggle nav caret>
//                                 {/* {memberDetails ? (
//                                 !memberDetails.weblogo ? (
//                                     ''
//                                 ) : (
//                                         <img
//                                             className="customberlogo"
//                                             src={'https://smartsen.se/assets/images/' + memberDetails.weblogo}
//                                             alt=""
//                                             height={56}
//                                         />
//                                     )
//                             ) : (
//                                     ''
//                                 )} */}

//                                 {
//                                     memberDetails && memberDetails.clientid && memberDetails.clientid.logo ?
//                                         <img
//                                             className="customberlogo"
//                                             src={memberDetails.clientid.logo}
//                                             // src={'https://smartsen.se/assets/images/' + memberDetails.weblogo}
//                                             alt=""
//                                             height={56}
//                                         /> : <img
//                                             className="customberlogo"
//                                             src={dummyLogo}
//                                             alt=""
//                                             height={56}
//                                         />
//                                 }

//                                 {memberDetails &&
//                                     memberDetails.firstname &&
//                                     memberDetails.lastname
//                                     ? <span title={`${memberDetails.firstname} ${memberDetails.lastname}`}>{ellipsisText(`${memberDetails.firstname} ${memberDetails.lastname}`, 16)}</span>
//                                     : memberDetails &&
//                                         memberDetails.email &&
//                                         memberDetails.email.length > 0 ? <span title={memberDetails.email}>{ellipsisText(memberDetails.email, 16)}</span> : 'Menu'}
//                             </DropdownToggle>
//                             {
//                                 localStorage.getItem('partner_login') == 'true' ? <Fragment>
//                                     {
//                                         !this.props.isSwitchUser && pathname && pathname[1] !== 'PartnerLogin' && <DropdownMenu right>
//                                             {/* <DropdownItem onClick={() => this.props.history.push('/UserProfile')}>
//                                                 <span className='user-option'><i className="fas fa-random"></i> MY PROFILE</span>
//                                             </DropdownItem> */}
//                                             <DropdownItem onClick={() => this.props.switchUser(true)}>
//                                                 <span className='user-option'><i className="fas fa-random"></i> SWITCH TO CLIENT</span>
//                                             </DropdownItem>
//                                             {
//                                                 isAdminProfile == 'false' && <DropdownItem onClick={() => this.clientLogout()}>
//                                                     <span className='user-option'><i className="fas fa-toggle-on"></i>SWITCH TO MY PROFILE</span>
//                                                 </DropdownItem>
//                                             }
//                                             <DropdownItem onClick={this.logout}>
//                                                 <span className='user-option'><i className="fas fa-sign-out-alt"></i>LOGOUT</span>
//                                             </DropdownItem>
//                                         </DropdownMenu>
//                                     }
//                                 </Fragment> : <Fragment>
//                                     {pathname && pathname[1] !== 'PartnerLogin' && <DropdownMenu right>
//                                         <DropdownItem onClick={() => this.props.history.push('/UserProfile')}>
//                                             <span className='user-option'><i className="fas fa-random"></i> MY PROFILE</span>
//                                         </DropdownItem>
//                                         <DropdownItem onClick={this.logout}>
//                                             <span className='user-option'><i className="fas fa-sign-out-alt"></i>LOGOUT</span>
//                                         </DropdownItem>
//                                     </DropdownMenu>}
//                                 </Fragment>
//                             }

//                         </UncontrolledDropdown>
//                     </Nav>
//                 </Navbar>
//                 {this.props.isSwitchUser && <PartnerLogin
//                     history={this.props.history}
//                 />}
//             </Fragment>
//         );
//     }
// }

const mapStateToProps = (state) => {
    return {
        getRefreshData: state.getRefreshData,
        isNotificationOpen: state.notificationPopUp.isNotificationOpen,
        getNotificationUnreadCount: state.getAllNotificationCount,
        isSwitchUser: state.clientLogout.isSwitchUser,
    };
};

export default withRouter(connect(mapStateToProps, {
    createDashboardGlobalAction,
    createUploadGlobalAction,
    isNotificationPopUp,
    clientLogout,
    getRefreshAction,
    getMemberProfileDataAction,
    getUsers,
    getAllNotificationCount,
    switchUser,
    isOpenScreenDesignModal,
    isOpenCalculatedParameterModal
})(Header));