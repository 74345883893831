import React, { useState, useEffect } from 'react';
import Col from 'reactstrap/lib/Col';
import Label from 'reactstrap/lib/Label';
import SelectDropDown from '../../../../../../common/components/Select';
import {
  getLocationsAndParameters,
  getGroupedCalculatedParametersList,
  inValidatePreloadCache,
  getReadingtypeNameById,
  getLocationsAndParametersNameById
} from '../../../../utils';
import { getCBMAssetData } from '../utils';

const _getGroupedCalculatedParametersList = (plant, sensorOptionCollection) => {
  return sensorOptionCollection || getGroupedCalculatedParametersList(plant);
};

const typesGet = (_value, sensorOptionCollection) => {
  const types =
    _value &&
    (sensorOptionCollection
      ? sensorOptionCollection
          .find(({ value }) => value == _value)
          .reading_types.map(({ name, id }) => ({
            label: name,
            value: id
          }))
      : (getLocationsAndParameters().find(({ value }) => value == _value) || {})
          .types);

  return types;
};

function LocationAndParameterSelect(props) {
  var [filteredReadingTypes, setFilteredReadingTypes] = useState([]);
  var [locParam, setLocParam] = useState({ location: {}, readingtype: {} });

  useEffect(() => {
    if (props.value && !props.isPreview) {
      setLocParam(props.value);
      if (props.value && props.value.location && props.value.location.value) {
        const types = typesGet(
          props.value.location.value,
          props.sensorOptionCollection
        );

        setFilteredReadingTypes(types);
      }
    }
    inValidatePreloadCache();
  }, [props.value]);

  useEffect(() => {
    if (props.directImport) {
      props.value && setLocParam(props.value);
      if (props.value && props.value.location && props.value.location.value) {
        let a =
          getLocationsAndParameters() &&
          getLocationsAndParameters().find(
            (l) => l.value == props.value.location.value
          );
        if (a && a.types) {
          let types = a.types;
          setFilteredReadingTypes(types);
        }
      }
    }
  }, [props.value]);

  const { location, readingtype } = locParam;

  return (
    <React.Fragment>
      <Col
        className='select-dropdown custom-select-parameter'
        xl={props.width || 6}
        lg={props.width || 6}
        md={props.width || 6}
        sm={props.width || 6}
        xs={props.width || 6}
      >
        <Label for='location' className='label'>
          {Array.isArray(props.label) && props.label.length > 0
            ? props.label[0]
            : ''}
          {props.required === true ? (
            <span className='text-danger'> * </span>
          ) : null}
        </Label>
        <SelectDropDown
          name='location'
          value={{
            label:
              getLocationsAndParametersNameById(location && location.value) ||
              (location && location.label) ||
              '',
            value:
              (location && location.value) ||
              getCBMAssetData(location && location.sensor_type_id) ||
              ''
          }}
          onChange={(e) => {
            const types = typesGet(e.value, props.sensorOptionCollection);

            setFilteredReadingTypes(types);

            let lp = { ...locParam, location: e, readingtype: {} };

            setLocParam(lp);

            props.onChange(lp, false);
          }}
          options={_getGroupedCalculatedParametersList(
            props.plant,
            props.sensorOptionCollection
          )}
          placeholder='Select Location'
        />
      </Col>
      <Col
        className='select-dropdown custom-select-parameter'
        xl={props.width || 6}
        lg={props.width || 6}
        md={props.width || 6}
        sm={props.width || 6}
        xs={props.width || 6}
      >
        <Label for={props.name} className='label'>
          {Array.isArray(props.label) && props.label.length > 1
            ? props.label[1]
            : ''}
          {props.required === true ? (
            <span className='text-danger'> * </span>
          ) : null}
        </Label>
        <SelectDropDown
          name='readingtypeid'
          value={{
            label:
              getReadingtypeNameById(readingtype && readingtype.value) || '',
            value: readingtype && readingtype.value
          }}
          onChange={(e) => {
            let val = JSON.parse(
              JSON.stringify({ ...locParam, readingtype: e })
            );

            setLocParam(val);

            delete val.location.types;

            props.onChange(val, false);
          }}
          options={filteredReadingTypes}
          placeholder='Select Parameter'
          isMulti={props.multipleReadingType ? true : false}
        />
      </Col>
    </React.Fragment>
  );
}

export default LocationAndParameterSelect;
