import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

class Loader extends Component {

// getLoaderText(){
//   let items = [
//     "What is the Net Zero - Balance between the amount of greenhouse gas produced and the amount removed from the atmosphere",
//     "India pledges to turn to Net Zero by the 2070 year",
//     "Australia pledged to turn to Net Zero by the 2050 year",
//     "The UK pledged to turn to Net Zero by 2050 year",
//     "EU pledge to turn to Net Zero by 2050 year",
//     "The USA pledged to turn to Net Zero by 2050 year",
//     "China pledged to turn to Net Zero by 2060 year",
//     "India committed to reducing its carbon intensity by 45 per cent by 2030",
//     "Bhutan and Suriname are Carbon Negative countries as they absorb more CO2 than produced",
//     "Tasmania was below Net Zero between 2014 to 2018",
//     "Bhutan absorbs roughly 7 million tonnes of CO2 annually and only produces around 2 million tonnes"
//     ];

//   return items[Math.floor(Math.random()*items.length)];
// }

  render () {
    if (this.props.loading) {
      return (
        <div className='text-center loader-wrap' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: this.props.height || '60vh' }}>
        <CircularProgress
          className='circularProgress'
          size={30}
          color='primary'
        />
      </div>
        // <div className = 'loader' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: this.props.height || '240px' }}>
        //   <div className='spinner'>
        //     <div className='round-loader' />
        //     <span>
        //       <p> {this.getLoaderText()} </p>
        //     {/* <div className='bounce1' />
        //     <div className='bounce2' />
        //     <div className='bounce3' /> */}
        //     </span>   
        //   </div>
        // </div>
      )
    }  else {
      return (
        <div>
          {this.props.children}
        </div>
      )
    }
  }
}

export default Loader
