import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { httpMethodGetJSON } from '../../../../https';
import { URL } from '../../../../constants';
import {
  GET_USECASE_SUMMARY_CBM_ASSETS_DATA,
  GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA,
  GET_USECASE_SUMMARY_ASSETS_DATA,
  GET_USECASE_SUMMARY_ALERT_LOGS_DATA,
  GET_USECASE_SUMMARY_INSIGHT_DATA,
  GET_USECASE_SUMMARY_TAG_DATA,
  GET_USECASE_SUMMARY_IMPACT_DATA
} from './constant';

const getCbmAssetsByAssetNameApi = (endpoint) =>
  `${URL}/asset-cbm/asset-names/${endpoint}/assets/`;

export const getUsecaseSummaryCbmAssetData = (action$) =>
  action$.pipe(
    ofType(GET_USECASE_SUMMARY_CBM_ASSETS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/asset-names/stats`);
    })
  );

export const getUsecaseSummaryAllAssetsListData = (action$) => {
  return action$.pipe(
    ofType(GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/assets/all-assets-list/`
      );
    })
  );
};

export const getUsecaseSummaryAssetsData = (action$) => {
  return action$.pipe(
    ofType(GET_USECASE_SUMMARY_ASSETS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        getCbmAssetsByAssetNameApi(action.payload.endpoint)
      );
    })
  );
};

export const getUsecaseSummaryAlertLogData = (action$) => {
  return action$.pipe(
    ofType(GET_USECASE_SUMMARY_ALERT_LOGS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/alert-logs/`);
    })
  );
};

export const getUsecaseSummaryInsightData = (action$) => {
  return action$.pipe(
    ofType(GET_USECASE_SUMMARY_INSIGHT_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/assets/insights/`);
    })
  );
};

export const getUsecaseSummaryTagData = (action$) =>
  action$.pipe(
    ofType(GET_USECASE_SUMMARY_TAG_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/reading/reading_series/`);
    })
  );

export const getUsecaseSummaryImpactData = (action$) =>
  action$.pipe(
    ofType(GET_USECASE_SUMMARY_IMPACT_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/impact/category/${action.payload.category}/values/`
      );
    })
  );
