import React, { Component } from 'react';

/**
 * Error boundry for error handeling in component
 * works with class componant render and legacy methods, partialy with hooks
 * i.e not works with async methods or event handeling
 * https://reactjs.org/docs/hooks-faq.html //From Classes to Hooks
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <b>Something went wrong !!!!</b>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;