import React, { useState, useEffect } from 'react';
import SelectDropDown from '../../../../../common/components/Select';
import { Label } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function ParameterSelector(props) {

  var [selectedParam, setSelectedParam] = useState("");

  useEffect(() => {
    let opt = getParametOptions()
    if(props.appParams.parameter && props.appParams.parameter.value){
      let prm = opt.filter(p => p.value === props.appParams.parameter.value);
      setSelectedParam(prm);
    }
  }, [props.appParams]);


  const getParametOptions = ()=>{
    let options = JSON.parse(localStorage.getItem('smartsense.readingtypes'));
    options = options ? Object.values(options).map(rt => { return { value: rt.readingtypeid, label: rt.readingtypename } }) : [];
    return options;
  }

  return <Col className="select-dropdown custom-select-parameter" xl={props.width ? props.width : 12} >
    <div>
      <Label for={props.name} className='label'>{props.label}
        {props.required === true ? <span className='text-danger'> * </span> : null}
      </Label>
      <SelectDropDown
        name={props.name}
        value={selectedParam}
        onChange={(e) => {
          props.onChange(e.value, false, props.name)}}
        options={getParametOptions()}
        placeholder={props.placeholder}
        isMulti={props.isMulti}
      />
    </div>
  </Col>

}


const mapStateToProps = (state) => {
  return {};
};

ParameterSelector.defaultProps = {
  appParams: {},
  appInputParamChanged: function () {}
}

ParameterSelector.propTypes = {
  appParams: PropTypes.object,
  appInputParamChanged: PropTypes.func
}

export default connect(mapStateToProps, {})(ParameterSelector);