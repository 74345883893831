import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Row, Col } from 'reactstrap';

import { Context } from '../../../../../Main/context';
import valueGet from '../common/valueGet';
import {
  RadioButtons,
  Input,
  Button
} from '../../../../../../../common/components';
import AssetSensorParams from '../../../WidgetBuilder/AssetSensorParams';

const metaParameterTypeCollection = ['Asset', 'Manual'];

const manualMetaParameterGet = (
  _manualMetaParameter,
  index,
  assetSensorParam
) => {
  return [
    ...assetSensorParam.slice(0, index),
    {
      id: _manualMetaParameter.label,
      name: _manualMetaParameter.label,
      type: _manualMetaParameter.value,
      ..._manualMetaParameter
    },
    ...assetSensorParam.slice(index + 1)
  ];
};

const ParameterConfig = (_props) => {
  const { appInputParamChanged, ...props } = _props;

  const location = useLocation();

  const context = useContext(Context);

  const isAssetTemplateRoute =
    !!location.search && !!queryString.parse(location.search).assetType;

  const [initialized, setInitialized] = useState(false);

  const [manualMetaParameter, setManualMetaParameter] = useState();

  const [assetSensorParam, setAssetSensorParam] = useState();

  const [manualMetaParameterCount, setManualMetaParameterCount] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      const manualMetaParameter =
        conf.manualMetaParameter && conf.manualMetaParameter.value;

      setManualMetaParameter(manualMetaParameter);

      setAssetSensorParam(conf.assetSensorParam && conf.assetSensorParam.value);

      setManualMetaParameterCount(
        (manualMetaParameter &&
          conf.assetSensorParam &&
          conf.assetSensorParam.value.length) ||
          1
      );

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={12}>
            <div className='new-input-wrap'>
              <label className='label-text'>Filter Type</label>
              <div className='radio-buttons-wrap'>
                <RadioButtons
                  noOfButtons={metaParameterTypeCollection.length}
                  getButtonLableAtIndex={(index) => {
                    return metaParameterTypeCollection[index];
                  }}
                  buttonClickAtIndex={(_manualMetaParameter) => {
                    !isAssetTemplateRoute && !_manualMetaParameter
                      ? context.notify.error(
                          'Asset is not available on this route'
                        )
                      : (() => {
                          const manualMetaParameter = !!_manualMetaParameter;

                          setManualMetaParameter(manualMetaParameter);

                          appInputParamChanged({
                            manualMetaParameter: valueGet(manualMetaParameter)
                          });

                          const assetSensorParam = [];

                          setAssetSensorParam(assetSensorParam);

                          appInputParamChanged({
                            assetSensorParam: valueGet(assetSensorParam)
                          });
                        })();
                  }}
                  active={!manualMetaParameter ? 0 : 1}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          {manualMetaParameter ? (
            <Col md={6}>
              <Row>
                <Col>
                  <label>Value</label>
                </Col>

                <Col>
                  <label>Alias / Name Fields</label>
                </Col>
              </Row>

              {Array.from({ length: manualMetaParameterCount })
                .fill()
                .reduce((memo, _, index) => {
                  const value = assetSensorParam[index] || {};

                  return [...memo, value];
                }, [])
                .map((__assetSensorParam, index) => {
                  return (
                    <div className='new-input-wrap' key={index}>
                      <Row>
                        <Col>
                          <Input
                            size='sm'
                            label=''
                            type='text'
                            required={false}
                            placeholder='Enter Alias / Name Fields'
                            value={__assetSensorParam.label || ''}
                            inputChanged={(label) => {
                              const _assetSensorParam = manualMetaParameterGet(
                                { ...__assetSensorParam, label },
                                index,
                                assetSensorParam
                              );

                              setAssetSensorParam(_assetSensorParam);

                              appInputParamChanged({
                                assetSensorParam: valueGet(_assetSensorParam)
                              });
                            }}
                          />
                        </Col>

                        <Col>
                          <Input
                            size='sm'
                            label=''
                            type='text'
                            required={false}
                            placeholder='Enter Value'
                            value={__assetSensorParam.value || ''}
                            inputChanged={(value) => {
                              const _assetSensorParam = manualMetaParameterGet(
                                { ...__assetSensorParam, value },
                                index,
                                assetSensorParam
                              );

                              setAssetSensorParam(_assetSensorParam);

                              appInputParamChanged({
                                assetSensorParam: valueGet(_assetSensorParam)
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  );
                })}

              <br />

              <div>
                <button
                  className='icon-text-btn'
                  onClick={() =>
                    setManualMetaParameterCount(
                      (manualMetaParameterCount) => ++manualMetaParameterCount
                    )
                  }
                >
                  <i className='fa fa-plus-circle' aria-hidden='true'></i> Add
                  New
                </button>
              </div>
            </Col>
          ) : (
            <AssetSensorParams
              label='Asset Meta Params'
              value={assetSensorParam}
              onChange={(assetSensorParam) => {
                setAssetSensorParam(assetSensorParam);

                appInputParamChanged({
                  assetSensorParam: valueGet(assetSensorParam)
                });
              }}
            />
          )}
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return initialized && <Fragment>{renderFn()}</Fragment>;
};

export default ParameterConfig;
