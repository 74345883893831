import React, {Component} from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Collapse } from 'reactstrap';
import {CheckBox, Input} from '../'

export default class Treeview extends Component {

  render() {
    return(
    <div className={"tree-view"}>  
      <div  className='drawercontainer' >
        <Row>
          <Col sm= {12}>
            <div className = 'searchBar'>
              <Input 
                className   = 'text' 
                name        = 'search'
                placeholder = 'Search for location' 
                onChange    = {this.props.onSearch}
                onKeyUp     = {this.props.onSearch}
                inputChanged= {this.props.onSearch}
              />
              <div className="searchIcon icon"><i className="fa fa-search fa-lg" aria-hidden="true" /></div>
            </div>
          </Col>
        </Row>
        <div className="tree-view">
          <Col  xl={12} lg={12} md={12} sm={12} xs={12}>
            { this.renderNested(this.props.data) }
          </Col>
        </div>
      </div>
    </div>
    );
  }

  renderNested = items => {
    return items.map(item => {
      let hasChild = item.children && item.children.length > 0;
      let className = this.props.itemClass ? this.props.itemClass : "";
      return  <Row key={item.id}>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className={"tree-item " + className}>
                    {
                      this.props.enableCheckbox ?
                      <CheckBox
                        checkboxClicked={isChecked => { this.props.onChange && this.props.onChange(isChecked, item); }}
                        checked={this.props.selectedLocations.includes(item.id)}
                        label={item.label}
                        size='lg'
                      />
                      :
                      <div className='label'>{item.label}</div>
                    }
                    { hasChild && <i onClick={() => this.props.toggleCollapse(item)} className={"fa fa-lg icon-img " + (item.isCollapse ? "fa-caret-down" : "fa-caret-up")} /> }     
                  </div>
                  {
                    hasChild && 
                    <Collapse isOpen={!item.isCollapse } className='treeview-collapse'>
                      {this.renderNested(item.children)}
                    </Collapse>
                  }
                </Col>
              </Row>
    })
  }

  isChecked = item => {
    let index = Object.keys(this.props.selectedLocations).findIndex(x => x == item.id);
    return index > -1;
  }

}