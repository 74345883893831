export const GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA =
  'GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA';

export const GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA_SUCCESS =
  'GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA_SUCCESS';

export const GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA_FAILURE =
  'GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA_FAILURE';

export const GET_INSIGHT_TIMELINE_ACTIVITY_DATA =
  'GET_INSIGHT_TIMELINE_ACTIVITY_DATA';

export const GET_INSIGHT_TIMELINE_ACTIVITY_DATA_SUCCESS =
  'GET_INSIGHT_TIMELINE_ACTIVITY_DATA_SUCCESS';

export const GET_INSIGHT_TIMELINE_ACTIVITY_DATA_FAILURE =
  'GET_INSIGHT_TIMELINE_ACTIVITY_DATA_FAILURE';

export const GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA =
  'GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA';

export const GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA_SUCCESS =
  'GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA_SUCCESS';

export const GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA_FAILURE =
  'GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA_FAILURE';

export const GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA =
  'GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA';

export const GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA_SUCCESS =
  'GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA_SUCCESS';

export const GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA_FAILURE =
  'GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA_FAILURE';

export const GET_INSIGHT_TIMELINE_INSIGHT_DATA =
  'GET_INSIGHT_TIMELINE_INSIGHT_DATA';

export const GET_INSIGHT_TIMELINE_INSIGHT_DATA_SUCCESS =
  'GET_INSIGHT_TIMELINE_INSIGHT_DATA_SUCCESS';

export const GET_INSIGHT_TIMELINE_INSIGHT_DATA_FAILURE =
  'GET_INSIGHT_TIMELINE_INSIGHT_DATA_FAILURE';

export const GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA = 'GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA';
export const GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA_SUCCESS = 'GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA_SUCCESS';
export const GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA_FAILURE = 'GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA_FAILURE';