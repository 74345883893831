import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import paramTypeCollectionGet from '../common/paramTypeCollectionGet';
import parameterConfsInputGet from '../common/parameterConfsInputGet';
import formulaConfsInputGet from '../common/formulaConfsInputGet';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import formulaConfsOutputGet from '../common/formulaConfsOutputGet';
import _ParameterConfig from './_ParameterConfig';
import {
  Button,
  Input,
  CheckBox
} from '../../../../../../../common/components';
import infoIcon from '../../../../../../../../assets/images/infoIcon.svg';

const paramTypeCollection = paramTypeCollectionGet();

const ParameterConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [isStacked, setIsStacked] = useState();

  const [minVal, setMinVal] = useState();

  const [maxVal, setMaxVal] = useState();

  const [paramType, setParamType] = useState();

  const [parameterConfs, setParameterConfs] = useState();

  const [formulaConfs, setFormulaConfs] = useState();

  const paramTypeActiveIndex = paramTypeCollection.findIndex(
    (_paramType) => _paramType === paramType
  );

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams.graphConfig && props.widgetParams.graphConfig.value;

      setIsStacked((conf && conf.isStacked) || false);

      setMinVal(conf && conf.minVal);

      setMaxVal(conf && conf.maxVal);

      setParamType((conf && conf.paramType) || paramTypeCollection[0]);

      setParameterConfs(parameterConfsInputGet(conf && conf.parameters));

      setFormulaConfs(formulaConfsInputGet(conf && conf.formulaConfs));

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      graphConfig: {
        value: {
          isStacked,
          minVal,
          maxVal,
          paramType,
          parameters: parameterConfsOutputGet(parameterConfs),
          formulaConfs: formulaConfsOutputGet(formulaConfs),
          ...object
        }
      }
    };

    props.appInputParamChanged(value);
  };

  const renderParameterConfig = () => {
    return (
      <_ParameterConfig
        paramTypeCollection={paramTypeCollection}
        paramTypeActiveIndex={paramTypeActiveIndex}
        parameterConfs={parameterConfs}
        formulaConfs={formulaConfs}
        onParamTypeChange={(_paramType) => {
          setParamType(_paramType);

          appInputParamChangedHandle({ paramType: _paramType });
        }}
        onParameterConfsChange={(_parameterConfs) => {
          setParameterConfs(_parameterConfs);

          appInputParamChangedHandle({
            parameters: parameterConfsOutputGet(_parameterConfs)
          });
        }}
        onFormulaConfsChange={(_formulaConfs) => {
          setFormulaConfs(_formulaConfs);

          appInputParamChangedHandle({
            formulaConfs: formulaConfsOutputGet(_formulaConfs)
          });
        }}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <p className='head-label-text'>Graph Data Filters</p>

        <div className='new-input-wrap mb-0'>
          <div className='new-custom-checkbox'>
            <CheckBox
              label={'Stacked'}
              size='sm'
              checked={isStacked}
              checkboxClicked={(_isStacked) => {
                setIsStacked(_isStacked);

                appInputParamChangedHandle({ isStacked: _isStacked });
              }}
            />
          </div>

          <div className='input-notify'>
            <img src={infoIcon} />

            <p>If Stacked is not selected than operlapping graph will appear</p>
          </div>
        </div>

        <br />

        <Row>
          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Min Value</label>

              <Input
                size='sm'
                label=''
                type='number'
                required={false}
                placeholder='Enter Min Value'
                value={minVal || ''}
                inputChanged={(_minVal) => {
                  setMinVal(_minVal);

                  appInputParamChangedHandle({ minVal: _minVal });
                }}
              />

              <div className='input-notify'>
                <img src={infoIcon} />

                <p>
                  If the Line/Area graph is selected, than above filters will
                  not be applicable
                </p>
              </div>
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Max Value</label>

              <Input
                size='sm'
                label=''
                type='number'
                required={false}
                placeholder='Enter Max Value'
                value={maxVal || ''}
                inputChanged={(_maxVal) => {
                  setMaxVal(_maxVal);

                  appInputParamChangedHandle({ maxVal: _maxVal });
                }}
              />
            </div>
          </Col>
        </Row>

        <hr className='horizontal-divider mx-0 mg-t-10' />

        {renderParameterConfig()}

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfig;
