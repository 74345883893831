import React, {Component} from 'react';

class RadioButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rSelected: this.props.active
        };
        this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    }

    onRadioBtnClick(rSelected) {
        this.setState({rSelected});
        this.props.buttonClickAtIndex(rSelected);
    }

    componentDidUpdate(){
        if(this.state.rSelected != this.props.active){
            this.setState({rSelected:this.props.active})
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.active !== nextProps.active) {
            this.setState({ rSelected: nextProps.active });
        }
    }

    render() {
        let buttons = [];
        let len = this.props.noOfButtons;

        for (let i = 0; i < len; i++) {
            buttons.push(
                <div
                  key={`radio_button-${i}`}
                  className={`radioButtons ${this.props.className}`}
                >
                    <label className="container">
                        <input
                            type="radio"
                            disabled={this.props.disabled}
                            checked={this.state.rSelected === i}
                            value={this.state.rSelected}
                            //name={this.props.getButtonLableAtIndex(i)}
                            onChange={() => {
                                this.onRadioBtnClick(i)
                            }}
                        />
                        <span className="checkmark"/>
                        <span className='radio-text'>{this.props.getButtonLableAtIndex(i)}</span>
                    </label>
                </div>
            )
        }

        return (buttons);
    }
}

export default RadioButton;