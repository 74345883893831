import * as React from 'react'
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

const InviewWrapper = (props) => {
  const { children } = props;
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <Wrapper ref={ref}>
      { inView === true ? children : 'Loading files...' }
    </Wrapper>
  )
}

InviewWrapper.defaultProps = {
  children: false
}

//InviewWrapper.propTypes = {
  //children: React.ReactNode
//}

const Wrapper = React.forwardRef(
  ({ style, ...props }, ref) => {
    return <div ref={ref} style={{
      height: props.children && props.children.props && props.children.props.children && props.children.props.children.props 
      && props.children.props.children.props.isPreview && props.children.props.children.props.widgetId ? 'max-content' : '100%'
      , width: props.children && props.children.props && props.children.props.children && props.children.props.children.props
       && props.children.props.children.props.isPreview && props.children.props.children.props.widgetId ? 'max-content' : '100%'
    }} {...props} />;
  }
);

export default InviewWrapper;