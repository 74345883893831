import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import { Context } from '../../../admin/container/Main/context';
import { ModalBox, Select, CheckBox } from '../../../common/components/';

const SidebarCrud = (props) => {
  const context = useContext(Context);

  const startMonthOptions = ['4 Days'].map((option) => ({
    label: option,
    value: option
  }));

  const nestedDateRangeOptions = [
    'Last 30 Days',
    'Last 7 Days',
    'Last Month'
  ].map((option) => ({ label: option, value: option }));

  useEffect(() => {
    const { dashboardInput, onSetDashboardInput } = props;

    if (dashboardInput?.dateRange !== 'Current Month') {
      onSetDashboardInput({
        ...dashboardInput,
        addException: false,
        startOption: '',
        nestedDateRange: ''
      });
    }
  }, [props?.dashboardInput?.dateRange, props?.dashboardInput?.addException]);

  return (
    <ModalBox
      open={props.showDashboardForm}
      size='sm'
      onClose={props.onDashboardFormClose}
      className='create-dashboard-modal'
    >
      <div className='add-dashboard-section'>
        <div className='heading-block'>
          <p>{props.update ? 'Update' : 'Create'} Dashboard</p>
        </div>

        <div className='main-content'>
          <div className='inner-content'>
            <div className='top-block'>
              <div className='dashboard-name'>
                <div className='header-name-toggle head-title'>
                  <h4>Dashboard Name</h4>

                  <div className='action-btn checkbox'>
                    <label className='action-btn-checkbox'>
                      Show as Header
                      <input
                        type='checkbox'
                        id='header-toggle'
                        checked={props.dashboardInput.showHeader}
                        value={props.dashboardInput.showHeader}
                        name='showHeader'
                        onChange={(event) => {
                          props.onSetDashboardInput({
                            ...props.dashboardInput,
                            showHeader: event.target.checked
                          });
                        }}
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                </div>

                <input
                  disabled={props.update}
                  type='text'
                  id='dashboard-name'
                  placeholder='Enter Dashboard Name'
                  className='form-control'
                  value={props.dashboardInput.dashboard_name}
                  name='dashboard_name'
                  onChange={(event) => {
                    props.onSetDashboardInput({
                      ...props.dashboardInput,
                      dashboard_name: event.target.value
                    });
                  }}
                />
              </div>
            </div>

            <div className='bottom-block'>
              <div className='header-name-toggle head-title'>
                <h4>Show Date Picker</h4>
              </div>

              <div className='action-block'>
                <div className='action-btn radio'>
                  <label className='action-btn-radio'>
                    Widget Level Date Picker
                    <input
                      type='radio'
                      name='showDate'
                      className='check-with-label'
                      value='local'
                      checked={!props.dashboardInput.showDate}
                      onChange={(event) => {
                        props.onSetDashboardInput({
                          ...props.dashboardInput,
                          showDate: !event.target.checked
                        });
                      }}
                    />
                    <span className='checkmark'></span>
                  </label>

                  <label className='action-btn-radio'>
                    Unified Date Picker
                    <input
                      type='radio'
                      name='showDate'
                      className='check-with-label'
                      value='global'
                      checked={props.dashboardInput.showDate}
                      onChange={(event) => {
                        props.onSetDashboardInput({
                          ...props.dashboardInput,
                          showDate: event.target.checked,
                          showDateToggle: false
                        });
                      }}
                    />
                    <span className='checkmark'></span>
                  </label>
                </div>
              </div>
            </div>

            {props.dashboardInput.showDate === true && (
              <div className='_bottom-block'>
                <div className='header-name-toggle head-title'>
                  <h4>Show Date Picker On Dashboard</h4>
                </div>

                <Row className='action-block'>
                  <Col md={5}>
                    {(() => {
                      const options = [
                        'Today',
                        'Current Week',
                        'Last Week',
                        'Current Month',
                        'Last Month',
                        'Current Qtr.',
                        'Last 6 Month',
                        'Last 9 Month',
                        'Current Year',
                        'Current FY year'
                      ].map((option) => ({ label: option, value: option }));

                      return (
                        <React.Fragment>
                          <Select
                            placeholder='Select Date Range'
                            menuPlacement='top'
                            value={(() => {
                              const value = props.dashboardInput.dateRange;
                              // ||
                              // options[3].value;

                              return value
                                ? {
                                    label: value,
                                    value
                                  }
                                : null;
                            })()}
                            options={options}
                            onChange={({ value }) => {
                              props.onSetDashboardInput({
                                ...props.dashboardInput,
                                dateRange: value
                              });
                            }}
                          />

                          {props.dashboardInput.dateRange ===
                            'Current Month' && (
                            <CheckBox
                              label='Add exception'
                              checked={props.dashboardInput.addException}
                              checkboxClicked={(addException) => {
                                props.onSetDashboardInput({
                                  ...props.dashboardInput,
                                  addException
                                });
                              }}
                            />
                          )}
                        </React.Fragment>
                      );
                    })()}
                  </Col>

                  <Col>
                    <CheckBox
                      label='Hide'
                      checked={props.dashboardInput.showDateToggle}
                      checkboxClicked={(showDateToggle) => {
                        props.onSetDashboardInput({
                          ...props.dashboardInput,
                          showDateToggle
                        });
                      }}
                    />
                  </Col>
                </Row>

                {props.dashboardInput.addException === true && (
                  <Row>
                    <Col>
                      <Select
                        placeholder='Select Start of month'
                        menuPlacement='top'
                        value={(() => {
                          const value = props.dashboardInput.startOption;
                          return value
                            ? {
                                label: value,
                                value
                              }
                            : null;
                        })()}
                        options={startMonthOptions}
                        onChange={({ value }) => {
                          props.onSetDashboardInput({
                            ...props.dashboardInput,
                            startOption: value
                          });
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        placeholder='Select Date Range'
                        menuPlacement='top'
                        value={(() => {
                          const value = props.dashboardInput.nestedDateRange;
                          return value
                            ? {
                                label: value,
                                value
                              }
                            : null;
                        })()}
                        options={nestedDateRangeOptions}
                        onChange={({ value }) => {
                          props.onSetDashboardInput({
                            ...props.dashboardInput,
                            nestedDateRange: value
                          });
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            )}

            <div className='note-block'>
              <p>
                Note: By default, Date range will show current month&apos;s
                data.
              </p>
            </div>
          </div>

          <div className='footer-block'>
            <button
              type='button'
              className='btn btn-secondary cancel-button'
              onClick={props.onDashboardFormClose}
            >
              Cancel
            </button>

            <button
              type='button'
              className='btn btn-primary add-button'
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                if (
                  props.dashboardInput.dashboard_name &&
                  props.dashboardInput.dashboard_name.length > 30
                ) {
                  context.notify.error(
                    'Dashboard Name Should Consist Of Less Than 30 Character'
                  );
                  return;
                }

                if (
                  // eslint-disable-next-line no-useless-escape
                  /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?@_()]/g.test(
                    props.dashboardInput.dashboard_name
                  )
                ) {
                  context.notify.error(
                    'Dashboard Name Should Not Contain Special Characters'
                  );
                  return;
                }

                const { dashboard_name, refreshTime, ...config } =
                  props.dashboardInput;

                const payload = {
                  ...(props.update && { id: props.selectedId }),
                  name: dashboard_name,
                  description: '',
                  sequence: props.data.length,
                  config,
                  ...(!props.update &&
                    (() => {
                      return props.sidebarNameCollection
                        .slice(1)
                        .find(
                          (sidebarName) => sidebarName === props.sidebarName
                        )
                        ? {
                            custom_service_id: props.serviceIdActive
                          }
                        : {};
                    })())
                };

                (props.update
                  ? props.editDashboard
                  : props.createCustomDashboard)(payload);
              }}
            >
              {props.update ? 'Update' : 'Create'}
            </button>
          </div>
        </div>
      </div>
    </ModalBox>
  );
};

export default SidebarCrud;
