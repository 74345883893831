import {
    GET_DAY_DEMAND_DATA,
    GET_DAY_DEMAND_DATA_SUCCESS,
    GET_DAY_DEMAND_DATA_FAILURE,
    GET_DAY_DEMAND_DIFFERENCE_DATA_INIT,
    GET_DAY_DEMAND_DIFFERENCE_DATA_SUCCESS,
    GET_DAY_DEMAND_DIFFERENCE_DATA_FAILURE
} from "./constant";

const DAY_INITIAL_STATE = {
    data: {
        working_days: 0,
        non_working_days: 0,
        working_day_value: {
            value: 0,
            unit: ''
        },
        non_working_day_value: {
            value: 0,
            unit: ''
        },
        working_day_series: [],
        non_working_day_series: [],
        unit: '',
        chart: {
            bar: [],
            pie: []
        },
        average: {
            working_day: {
                value: 0,
                unit: ''
            },
            non_working_day: {
                value: 0,
                unit: ''
            },
        }
    },
    loading: false,
    error: false
};

const DAY_DIFF_INITIAL_STATE = {
    data: {
        working_day: {
            value: 0,
            trend: ''
        },
        non_working_day: {
            value: 0,
            trend: ''
        }
    },
    loading: false,
    error: false
};

export function getDayDemandAnalysisReducer(state = DAY_INITIAL_STATE, action) {
    switch (action.type) {
        case GET_DAY_DEMAND_DATA:
            return {
                ...state,
                loading: true,
                error: false
            };
        case GET_DAY_DEMAND_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_DAY_DEMAND_DATA_FAILURE:
            return {
                ...state,
                error: true,
                loading: false
            };
        default:
            return state
    }
}

export function getDayDemandDifferenceAnalysisReducer(state = DAY_DIFF_INITIAL_STATE, action) {
    switch (action.type) {
        case GET_DAY_DEMAND_DIFFERENCE_DATA_INIT:
            return {
                ...state,
                loading: true,
                error: false
            };
        case GET_DAY_DEMAND_DIFFERENCE_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_DAY_DEMAND_DIFFERENCE_DATA_FAILURE:
            return {
                ...state,
                error: true,
                loading: false
            };
        default:
            return state
    }
}
