import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../../constants';
import { httpMethodGetJSON, httpMethodPost } from '../../../../https';
import * as AppConstants from './constant';

export const getNetworkInsightsOccurrence = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_NETWORK_INSIGHTS_OCCURRENCE),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/network-insights/occurrence/`);
    })
  );
};

export const getNetworkInsightsActions = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_NETWORK_INSIGHTS_ACTIONS),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/network-insights/${action.payload.network_insight_id}/actions/`
      );
    })
  );
};

export const createNetworkInsightsAction = (action$) => {
  return action$.pipe(
    ofType(AppConstants.CREATE_NETWORK_INSIGHTS_ACTION),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/network-insights/action/`);
    })
  );
};

export const getSystemInsightsOccurrence = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_SYSTEM_INSIGHTS_OCCURRENCE),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/system-insights/occurrence/`);
    })
  );
};
