import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../constants';
import {
  httpMethodDelete,
  httpMethodGetJSON,
  httpMethodPost,
  httpMethodPut
} from '../../../https';

import { CREATE_UPDATE_SHIFT, GET_SHIFT_DETAILS } from "./constant";

export const createUpdateShift = (action$) =>
  action$.pipe(
    ofType(CREATE_UPDATE_SHIFT),
    mergeMap((action) => {
      return httpMethodPut(
        action,
        `${URL}/shift/plant/${action.payload.plant_id}/`
      );
    })
  );
  
export const getShiftDetails = (action$) =>
  action$.pipe(
    ofType(GET_SHIFT_DETAILS),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/shift/plant/${action.payload.plant_id}/`
      );
    })
  );
