import React, { Fragment } from "react";

class EmailSelector extends React.Component {
    state = {
        items: [],
        value: "",
        error: null
    };

    componentDidMount = () => {
        let { selected } = this.props;
        if (selected) {
            this.setState({ items: selected });
        }
    }

    handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            var value = this.state.value.trim();
            if (value && this.isValid(value)) {
                this.setState({
                    items: [...this.state.items, this.state.value],
                    value: ""
                }, () => this.props.handleChanged(this.state.items));
            }
        }
    };

    handleChange = evt => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleDelete = item => {
        this.setState({
            items: this.state.items.filter(i => i !== item)
        }, () => this.props.handleChanged(this.state.items));
    };

    handlePaste = evt => {
        evt.preventDefault();
        var paste = evt.clipboardData.getData("text");
        let getCurrentValue = this.state.value + paste;
        this.setState({
            value: getCurrentValue,
            error: null
        });
        // var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
        // if (paste && this.isValid(paste)) {
        //     if (emails) {
        //         var toBeAdded = emails.filter(email => !this.isInList(email));
        //         this.setState({
        //             items: [...this.state.items, ...toBeAdded],
        //             error: null
        //         }, () => this.props.handleChanged(this.state.items));
        //     }
        // }
    };

    isValid(email) {
        let error = null;

        if (this.isInList(email)) {
            error = `${email} has already been added.`;
        }

        if (!this.isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            this.setState({ error });

            return false;
        }

        return true;
    }

    isInList(email) {
        return this.state.items.includes(email);
    }

    isEmail(email) {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    }

    render() {
        let { isDisable, label } = this.props;
        return (
            <Fragment>
                <div className="email-selector">
                    <input
                        className={"input " + (this.state.error && " has-error")}
                        value={this.state.value}
                        placeholder={label}
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleChange}
                        onPaste={this.handlePaste}
                        disabled={isDisable ? true : false}

                    />
                    {this.state.items.map(item => (
                        <div className="tag-item" key={item}>
                            {item}
                            <butoon
                                type="button"
                                className="button"
                                onClick={() => this.handleDelete(item)}
                            >
                                &times;
                        </butoon>
                        </div>
                    ))}
                    {this.state.error && <p className="error">{this.state.error}</p>}
                </div>
            </Fragment>

        );
    }
}

export default EmailSelector;

