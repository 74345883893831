export const GET_PLANT_HOLIDAY = 'GET_PLANT_HOLIDAY';
export const GET_PLANT_HOLIDAY_SUCCESS = 'GET_PLANT_HOLIDAY_SUCCESS';
export const GET_PLANT_HOLIDAY_FAILURE = 'GET_PLANT_HOLIDAY_FAILURE';

export const CREATE_PLANT_HOLIDAY = 'CREATE_PLANT_HOLIDAY';
export const CREATE_PLANT_HOLIDAY_SUCCESS = 'CREATE_PLANT_HOLIDAY_SUCCESS';
export const CREATE_PLANT_HOLIDAY_FAILURE = 'CREATE_PLANT_HOLIDAY_FAILURE';
export const CREATE_PLANT_HOLIDAY_RESET = 'CREATE_PLANT_HOLIDAY_RESET';

export const UPDATE_PLANT_HOLIDAY = 'UPDATE_PLANT_HOLIDAY';
export const UPDATE_PLANT_HOLIDAY_SUCCESS = 'UPDATE_PLANT_HOLIDAY_SUCCESS';
export const UPDATE_PLANT_HOLIDAY_FAILURE = 'UPDATE_PLANT_HOLIDAY_FAILURE';
export const UPDATE_PLANT_HOLIDAY_RESET = 'UPDATE_PLANT_HOLIDAY_RESET';

export const DELETE_PLANT_HOLIDAY = 'DELETE_PLANT_HOLIDAY';
export const DELETE_PLANT_HOLIDAY_SUCCESS = 'DELETE_PLANT_HOLIDAY_SUCCESS';
export const DELETE_PLANT_HOLIDAY_FAILURE = 'DELETE_PLANT_HOLIDAY_FAILURE';
export const DELETE_PLANT_HOLIDAY_RESET = 'DELETE_PLANT_HOLIDAY_RESET';
