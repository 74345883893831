import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import _formulaConfsGet from '../common/_formulaConfsGet';
import {
  Input,
  CheckBox,
  Select
} from '../../../../../../../common/components';

import useFormulaConfigComputed from '../common/useFormulaConfigComputed';
import ParameterConfigComputedCore from '../Common/ParameterConfigComputedCore';

const ParameterConfigComputed = (props) => {
  const [
    initialized,
    formulaConfActiveIndex,
    formulaConfParameterCollections,
    formulaConfPointerCollections,
    onFormulaConfParameterCollectionUpdateHandle,
    onFormulaConfPointerCollectionUpdateHandle,
    onFormulaConfResetHandle
  ] = useFormulaConfigComputed(props.formulaConfs, props.onFormulaConfsChange);

  const renderParameterConfigComputedCore = () => {
    return (
      <ParameterConfigComputedCore
        formulaConfActiveIndex={formulaConfActiveIndex}
        formulaConfParameterCollections={formulaConfParameterCollections}
        formulaConfPointerCollections={formulaConfPointerCollections}
        formulaConfs={props.formulaConfs}
        onFormulaConfParameterCollectionUpdateTrigger={
          onFormulaConfParameterCollectionUpdateHandle
        }
        onFormulaConfPointerCollectionUpdateTrigger={
          onFormulaConfPointerCollectionUpdateHandle
        }
        onFormulaConfCreateTrigger={() => {
          return null;
        }}
        onFormulaConfResetTrigger={onFormulaConfResetHandle}
      />
    );
  };

  const [colMd, _colMd] = (() => {
    switch (props.dataType) {
      case props.dataTypeCollection[0]:
        return [3, 12];

      default:
        return [];
    }
  })();

  const renderFn = () => {
    return (
      <Fragment>
        <Row>
          <Col md={colMd}>
            <div className='new-input-wrap'>
              <label className='label-text'>Alias</label>

              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter Alias'
                value={props.formulaConfs[formulaConfActiveIndex].alias || ''}
                inputChanged={(alias) => {
                  alias !== props.formulaConfs[formulaConfActiveIndex].alias &&
                    props.onFormulaConfsChange(
                      _formulaConfsGet(
                        {
                          ...props.formulaConfs[formulaConfActiveIndex],
                          alias
                        },
                        formulaConfActiveIndex,
                        props.formulaConfs
                      )
                    );
                }}
              />
            </div>
          </Col>

          {props.dataType === props.dataTypeCollection[0] && (
            <Col md={colMd}>
              <div className='new-input-wrap'>
                <label className='label-text'>Graph Type</label>

                <Select
                  options={props.graphTypeCollection.reduce(
                    (memo, value) => [...memo, { label: value, value }],
                    []
                  )}
                  isMulti={false}
                  placeholder='Select Graph Type'
                  value={(() => {
                    const value =
                      props.formulaConfs[formulaConfActiveIndex].graphType ||
                      '';

                    return {
                      label: value,
                      value
                    };
                  })()}
                  onChange={({ value: graphType }) => {
                    graphType !==
                      props.formulaConfs[formulaConfActiveIndex].graphType &&
                      props.onFormulaConfsChange(
                        _formulaConfsGet(
                          {
                            ...props.formulaConfs[formulaConfActiveIndex],
                            graphType
                          },
                          formulaConfActiveIndex,
                          props.formulaConfs
                        )
                      );
                  }}
                />
              </div>
            </Col>
          )}

          {!(
            props.dataType === props.dataTypeCollection[1] &&
            props.section === props.sectionCollection[1]
          ) && (
            <Col md={colMd}>
              <div className='new-input-wrap'>
                <label className='label-text'>Threshold</label>

                <Input
                  size='sm'
                  label=''
                  type='number'
                  required={false}
                  placeholder='Enter Threshold'
                  value={
                    props.formulaConfs[formulaConfActiveIndex].threshold || ''
                  }
                  inputChanged={(threshold) => {
                    threshold !==
                      props.formulaConfs[formulaConfActiveIndex].threshold &&
                      props.onFormulaConfsChange(
                        _formulaConfsGet(
                          {
                            ...props.formulaConfs[formulaConfActiveIndex],
                            threshold
                          },
                          formulaConfActiveIndex,
                          props.formulaConfs
                        )
                      );
                  }}
                />
              </div>
            </Col>
          )}

          <Col md={colMd}>
            <div className='new-input-wrap'>
              <div className='d-flex justify-content-between p-0 mb-1'>
                <label className='label-text'>Unit</label>

                <label className='label-text font-weight-light'>Show As</label>
              </div>

              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter Unit'
                value={props.formulaConfs[formulaConfActiveIndex].unit || ''}
                inputChanged={(unit) => {
                  unit !== props.formulaConfs[formulaConfActiveIndex].unit &&
                    props.onFormulaConfsChange(
                      _formulaConfsGet(
                        {
                          ...props.formulaConfs[formulaConfActiveIndex],
                          unit
                        },
                        formulaConfActiveIndex,
                        props.formulaConfs
                      )
                    );
                }}
              />
            </div>
          </Col>

          <Col md={_colMd}>
            <div className='new-input-wrap row-checkbox-right'>
              <div className='new-custom-checkbox'>
                <CheckBox
                  label={'Show Partial Calculation Result'}
                  size='sm'
                  checked={
                    props.formulaConfs[formulaConfActiveIndex].is_partial
                  }
                  checkboxClicked={(is_partial) => {
                    props.onFormulaConfsChange(
                      _formulaConfsGet(
                        {
                          ...props.formulaConfs[formulaConfActiveIndex],
                          is_partial
                        },
                        formulaConfActiveIndex,
                        props.formulaConfs
                      )
                    );
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>

        {renderParameterConfigComputedCore()}
      </Fragment>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfigComputed;
