export default (item, index, collection, mutate = true) => {
  return index < 0
    ? [...collection, item]
    : [
        ...collection.slice(0, index),
        ...(() => {
          return item
            ? Array.isArray(item)
              ? [item]
              : [{ ...(mutate ? collection[index] : {}), ...item }]
            : [];
        })(),
        ...collection.slice(index + 1)
      ];
};
