import * as AppConstants from './constant';

const INITIAL_STATE = {
    data: [],
    loading: false,
    error: false,
    success: undefined,
    message: null,
    total_pages: null,
    total_record: null,
    isSwitchUser: false
}

export function getAllClients(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_CLIENTS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_CLIENTS_SUCCESS:
            return {
                ...state,
                data: action.response.result,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_CLIENTS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }


        default:
            return state;
    }
}

export function getAllPlants(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'API_LOADING':
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null,
            }
        case AppConstants.GET_ALL_PLANTS_SUCCESS:
            return {
                ...state,
                data: action.response.result,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                error: false,
                loading: false,
                success: true,
                message: null,
            }
        case AppConstants.GET_ALL_PLANTS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                message: action.payload.response.message,
                loading: false,
            }
        default:
            return state;
    }
}

export function getAllUsersForSpecificPlant(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'API_LOADING':
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null,
            }
        case AppConstants.GET_ALL_USERS_FOR_PLANT_SUCCESS:
            return {
                ...state,
                data: action.response.result,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                error: false,
                loading: false,
                success: true,
                message: null,
            }
        case AppConstants.GET_ALL_USERS_FOR_PLANT_SUCCESS:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                message: null,
                loading: false,
            }
        default:
            return state
    }
}

export function clientLogout(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.CLIENT_LOGOUT:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null,
            }
        case AppConstants.CLIENT_LOGOUT_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true,
                message: action.response.response.message,
            }
        case AppConstants.CLIENT_LOGOUT_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }
        case AppConstants.CLIENT_LOGOUT_RESET:
            return {
                ...state,
                error: false,
                data: [],
                success: false,
                loading: false,
            }

        case AppConstants.PARTNER_MODEL:
            return {
                ...state,
                isSwitchUser: action.payload
            }
        default:
            return state;
    }
}

export const partnerLoginReducer = {
    getAllClients,
    getAllPlants,
    clientLogout,
    getAllUsersForSpecificPlant
}