import React, { Fragment } from 'react';

import formulaConfsInputGet from '../common/formulaConfsInputGet';
import formulaConfParameterCollectionsGet from '../common/formulaConfParameterCollectionsGet';
import formulaConfPointerCollectionsGet from '../common/formulaConfPointerCollectionsGet';
import tagFormulaConfOutputGet from '../common/tagFormulaConfOutputGet';
import { Table as ReactTable } from '../../../../../../../common/components';
import TableHeaderCell from '../../../../../../../common/components/Table/ColumnCell';

const tagConfOutputGet = (parameter) => {
  return {
    type: 'parameter',
    parameter: parameter.tag.value
      ? {
          locationLabel: parameter.plant.label,
          locationid: parameter.plant.value.plantId,
          readingtypeLabel: parameter.tag.label,
          readingtypeid: parameter.tag.value.parameterId,
          is_calculated: true
        }
      : {}
  };
};

const confsGet = (confs) => {
  const _confs =
    confs &&
    confs.reduce((memo, conf) => {
      const value = (() => {
        switch (true) {
          case conf.isTag && !conf.isComputed:
            return {
              index: conf.index,
              isTag: conf.isTag,
              equation: '',
              formula: [
                {
                  formula: [tagConfOutputGet(conf)]
                }
              ]
            };

          case conf.isTag && conf.isComputed:
            return tagFormulaConfOutputGet(conf);

          case conf.isNormal:
            return {
              index: conf.index,
              isComputed: conf.isComputed,
              equation: '',
              formula: [
                {
                  formula: [
                    {
                      type: 'parameter',
                      parameter: conf.parameter || {}
                    }
                  ]
                }
              ]
            };

          default:
            return conf;
        }
      })();

      return value ? [...memo, value] : memo;
    }, []);

  return formulaConfsInputGet(_confs);
};

const formulaConfCollectionTableDefinition = [
  {
    name: 'Source Type',
    key: 'source_type',
    width: 150
  },
  {
    name: 'Sub Source Type',
    key: 'sub_source_type',
    width: 150
  },
  {
    name: 'Category',
    key: 'category',
    width: 150
  },
  {
    name: 'Parameter',
    key: 'parameter'
    // width: 200
  },
  {
    name: 'Formula',
    key: 'formula',
    width: 150
  },
  {
    name: '',
    key: 'action',
    width: 120
  }
];

const getTableHeaderAtIndex = (index) => {
  return new TableHeaderCell(
    formulaConfCollectionTableDefinition[index].name,
    formulaConfCollectionTableDefinition[index].key,
    index,
    formulaConfCollectionTableDefinition[index].width
  );
};

const formulaConfCollectionDataGet = (
  formulaConfPointerCollections,
  formulaConfParameterCollections,
  confs,
  onConfActiveIndexChangeTrigger,
  onConfDeleteTrigger
) => {
  return (
    formulaConfPointerCollections &&
    formulaConfPointerCollections
      .reduce((memo, formulaConfPointerCollection, index) => {
        return [
          ...memo,
          formulaConfPointerCollection.reduce((_memo, pointer) => {
            return [
              ..._memo,
              {
                ...pointer,
                formula: (() => {
                  switch (pointer.type) {
                    case 'operator':
                    case 'constant':
                      return pointer;

                    case 'parameter':
                      return (
                        formulaConfParameterCollections[index] &&
                        formulaConfParameterCollections[index][pointer.value]
                      );
                  }
                })()
              }
            ];
          }, [])
        ];
      }, [])
      .reduce((memo, formulaConfPointerCollection, index) => {
        const _data = formulaConfCollectionTableDefinition.reduce(
          (_memo, { key }, _index) => {
            return {
              ..._memo,
              [key]: (() => {
                switch (_index) {
                  case 0:
                    return formulaConfPointerCollection.reduce(
                      (memo, { type, formula: { parameter } = {} }) => {
                        return !memo && type === 'parameter'
                          ? parameter.locationLabel
                          : memo;
                      },
                      null
                    );

                  case 1:
                    return formulaConfPointerCollection
                      .slice(
                        formulaConfPointerCollection.findIndex(({ type }) => {
                          return type === 'parameter';
                        }) + 1
                      )
                      .reduce((memo, { type, formula: { parameter } = {} }) => {
                        return !memo && type === 'parameter'
                          ? parameter.locationLabel
                          : memo;
                      }, null);

                  case 2:
                    return '';

                  case 3:
                    return formulaConfPointerCollection
                      .reduce((memo, { type, formula: { parameter } = {} }) => {
                        return type === 'parameter'
                          ? [...memo, parameter.readingtypeLabel || '']
                          : memo;
                      }, [])
                      .reduce((memo, readingtypeLabel) => {
                        return `${memo}${memo ? ', ' : ''}${readingtypeLabel}`;
                      }, '');

                  case 4:
                    return confs[index].isComputed
                      ? formulaConfPointerCollection.reduce((memo, pointer) => {
                          const value = (() => {
                            switch (pointer.type) {
                              case 'parameter':
                                return `val_${pointer.value + 1}`;

                              case 'operator':
                              case 'constant':
                                return pointer.value;
                            }
                          })();

                          return `${memo} ${value}`;
                        }, '')
                      : '';

                  case 5:
                    return (
                      <div className='table-action-wrap'>
                        <button className='icon-button'>
                          <i className='fa fa-clone' aria-hidden='true'></i>
                        </button>

                        <button
                          className='icon-button'
                          onClick={() => {
                            onConfActiveIndexChangeTrigger(index);
                          }}
                        >
                          <i
                            className='fa fa-pencil-square-o'
                            aria-hidden='true'
                          ></i>
                        </button>

                        <button
                          className='icon-button'
                          onClick={() => {
                            onConfDeleteTrigger(index);
                          }}
                        >
                          <i className='fa fa-trash' aria-hidden='true'></i>
                        </button>
                      </div>
                    );

                  default:
                    return key;
                }
              })()
            };
          },
          {}
        );

        return [...memo, _data];
      }, [])
  );
};

const ParameterCollection = (props) => {
  const confs = confsGet(props.confs);

  const formulaConfParameterCollections =
    formulaConfParameterCollectionsGet(confs);

  const formulaConfPointerCollections = formulaConfPointerCollectionsGet(
    confs,
    formulaConfParameterCollections
  );

  const formulaConfCollectionData = formulaConfCollectionDataGet(
    formulaConfPointerCollections,
    formulaConfParameterCollections,
    confs,
    props.onConfActiveIndexChangeTrigger,
    props.onConfDeleteTrigger
  );

  const renderParameterCollection = () => {
    return (
      <div className='custom-table-3'>
        <ReactTable
          showPagination={false}
          pageSize={12}
          data={formulaConfCollectionData}
          noOfColms={formulaConfCollectionTableDefinition.length}
          getHeaderAtIndex={getTableHeaderAtIndex}
        />
      </div>
    );
  };

  return <Fragment>{renderParameterCollection()}</Fragment>;
};

export default ParameterCollection;
