import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../../constants';

import { httpMethodGetJSON, httpMethodPost } from '../../../../https';
import {
  GET_ALERT_CONNECTION,
  GET_INSIGHT_CONNECTION,
  GET_ACTIVITY_CONNECTION,
  ADD_USER_ACTIVITY,
  GET_EVENT_CONFIGURATION,
  GET_ASSET_HISTORY_TIMELINE_DATA,
  GET_NETWORK_HISTORY_TIMELINE_DATA,
  GET_ASSET_HISTORY_TIMELINE_LOG,
  GET_ASSET_SENSOR,
  EXPORT_TIMELINE_LOG
} from './constant';

export const getAlertConnection = (action$) => {
  return action$.pipe(
    ofType(GET_ALERT_CONNECTION),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/alert-logs`);
    })
  );
};

export const getInsightConnection = (action$) => {
  return action$.pipe(
    ofType(GET_INSIGHT_CONNECTION),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/assets/feedbacks`);
    })
  );
};

export const getActivityConnection = (action$) => {
  return action$.pipe(
    ofType(GET_ACTIVITY_CONNECTION),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/events`);
    })
  );
};

export const getEventConfiguration = (action$) => {
  return action$.pipe(
    ofType(GET_EVENT_CONFIGURATION),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/events/config/`);
    })
  );
};

export const addUserActivity = (action$) =>
  action$.pipe(
    ofType(ADD_USER_ACTIVITY),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/events/`);
    })
  );

export const getAssetHistoryTimelineData = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_HISTORY_TIMELINE_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/assets/history/`);
    })
  );

export const getNetworkHistoryTimelineData = (action$) => 
  action$.pipe(
    ofType(GET_NETWORK_HISTORY_TIMELINE_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/network-insights/history/`);
    })
  );

export const getAssetHistoryTimelineLogData = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_HISTORY_TIMELINE_LOG),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/assets/timelines/`);
    })
  );

export const getAssetSensor = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_SENSOR),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/events/config/`);
    })
  );