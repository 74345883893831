import { 
    CREATE_UPDATE_SHIFT,
    CREATE_UPDATE_SHIFT_SUCCESS,
    CREATE_UPDATE_SHIFT_FAILURE,
    SHIFT_RESET,
    GET_SHIFT_DETAILS,
    GET_SHIFT_DETAILS_SUCCESS,
    GET_SHIFT_DETAILS_FAILURE,
 } from "./constant";

export const createUpdateShift = (
    state = {
      data: null,
      loading: false,
      success: undefined,
      error: false
    },
    action
  ) => {
    switch (action.type) {
      case CREATE_UPDATE_SHIFT:
        return {
          ...state,
          loading: true
        };
  
      case CREATE_UPDATE_SHIFT_SUCCESS:
        return {
          ...state,
          data: action.response,
          loading: false,
          success: true
        };
  
      case CREATE_UPDATE_SHIFT_FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload.response
        };

      case SHIFT_RESET:
        return {
          ...state,
          loading: false,
          success: undefined,
          error:false
        };
  
      default:
        return state;
    }
  };

export const getShiftDetails =(
    state = {
      data: null,
      loading: false,
      success: undefined,
      error: false
    },
    action
  ) => {
    switch (action.type) {
      case GET_SHIFT_DETAILS:
        return {
          ...state,
          loading: true
        };

      case GET_SHIFT_DETAILS_SUCCESS:
        return {
          ...state,
          data: action.response,
          loading: false,
          success: true
        };

      case GET_SHIFT_DETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          error: true
        };

      default:
        return state;
    }
  };
    