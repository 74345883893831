import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import widgetConfigParamGet from '../common/widgetConfigParamGet';
import parameterConfsInputGet from '../common/parameterConfsInputGet';
import _ParameterConfig from './_ParameterConfig';
import { Select } from '../../../../../../../common/components';
import valueGet from '../common/valueGet';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import { Button } from '../../../../../../../common/components';

const locParamGet = (_locParam) => {
  const locParam = _locParam && [{ locParam: _locParam }];

  return parameterConfsInputGet(locParam);
};

const _locParamGet = (__locParam) => {
  const _locParam = parameterConfsOutputGet(__locParam);

  return (
    _locParam &&
    (() => {
      const { locParam } = _locParam[0];

      return {
        locParam: valueGet(locParam)
      };
    })()
  );
};

const ParameterConfig = (props) => {
  const periodCollection = widgetConfigParamGet(
    props.widgetConfig,
    'period'
  ).options;

  const dataFrequencyCollection = widgetConfigParamGet(
    props.widgetConfig,
    'dataFrequency'
  ).options;

  const [initialized, setInitialized] = useState(false);

  const [period, setPeriod] = useState();

  const [dataFrequency, setDataFrequency] = useState();

  const [locParam, setLocParam] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setPeriod(conf && conf.period && conf.period.value);

      setDataFrequency(conf && conf.dataFrequency && conf.dataFrequency.value);

      setLocParam(locParamGet(conf && conf.locParam && conf.locParam.value));

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      period: valueGet(period),
      dataFrequency: valueGet(dataFrequency),
      ..._locParamGet(locParam),
      ...object
    };

    props.appInputParamChanged(value);
  };

  const renderParameterConfig = () => {
    return (
      <_ParameterConfig
        locParam={locParam}
        onParameterConfCollectionUpdateTrigger={(_locParam) => {
          const locParam = [_locParam];

          setLocParam(locParam);

          appInputParamChangedHandle(_locParamGet(locParam));
        }}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Period</label>

              <Select
                isMulti={false}
                placeholder='Select Period'
                options={periodCollection.reduce(
                  (memo, value) => [
                    ...memo,
                    {
                      label: value,
                      value
                    }
                  ],
                  []
                )}
                value={(() => {
                  const value = period;

                  return {
                    label: value,
                    value
                  };
                })()}
                onChange={({ value }) => {
                  setPeriod(value);

                  appInputParamChangedHandle({
                    period: valueGet(value)
                  });
                }}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Data Frequency</label>

              <Select
                isMulti={false}
                placeholder='Select Data Frequency'
                options={dataFrequencyCollection.reduce(
                  (memo, value) => [
                    ...memo,
                    {
                      label: value,
                      value
                    }
                  ],
                  []
                )}
                value={(() => {
                  const value = dataFrequency;

                  return {
                    label: value,
                    value
                  };
                })()}
                onChange={({ value }) => {
                  setDataFrequency(value);

                  appInputParamChangedHandle({
                    dataFrequency: valueGet(value)
                  });
                }}
              />
            </div>
          </Col>
        </Row>

        {renderParameterConfig()}

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfig;
