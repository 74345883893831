import * as AppConstants from './constant';

export function getAllNotifications(value) {
    return {
        type: AppConstants.GET_ALL_NOTIFICATIONS,
        payload: value
    }
}

export function getAllNotificationCount() {
    return {
        type: AppConstants.GET_ALL_NOTIFICATIONS_COUNT,
    }
}

export function getAllNotificationsBySocket(value) {
    return {
        type: AppConstants.GET_ALL_NOTIFICATIONS_SUCCESS,
        payload: value
    }
}

export function getAllNotificationCountBySocket(value) {
    return {
        type: AppConstants.GET_ALL_NOTIFICATIONS_COUNT_SUCCESS,
        payload: value
    }
}

export function getNotificationSettings(value) {
    return {
        type: AppConstants.GET_NOTIFICATION_SETTING,
        payload: value
    }
}

export function deleteNotification(value) {
    return {
        type: AppConstants.DELETE_NOTIFICATION,
        payload: value
    }
}

export function resetDeleteNotification(value) {
    return {
        type: AppConstants.DELETE_NOTIFICATION_RESET,
        payload: value
    }
}

export function updateNotification(value) {
    return {
        type: AppConstants.UPDATE_NOTIFICATION,
        payload: value
    }
}

export function resetNotificationUpdate(value) {
    return {
        type: AppConstants.UPDATE_NOTIFICATION_RESET,
        payload: value
    }
}

export function isNotificationPopUp(value) {
    return {
        type: AppConstants.NOTIFICATION_POPUP,
        payload: value
    }
}

export function updateNotificationStatus(value) {
    return {
        type: AppConstants.UPDATE_NOTIFICATION_STATUS,
        payload: value
    }
}

export function updateNotificationStatusReset() {
    return {
        type: AppConstants.UPDATE_NOTIFICATION_STATUS_RESET,
    }
}

export function turnOffNotification(value) {
    return {
        type: AppConstants.TURN_OFF_NOTIFICATION,
        payload: value
    }
}

export function updateTurnOffNotificationReset(value) {
    return {
        type: AppConstants.TURN_OFF_NOTIFICATION_RESET,
        payload: value
    }
}

export function notificationCategory(value) {
    return {
        type: AppConstants.NOTIFICATION_CATEGORY,
        payload: value
    }
}