export default (value, passNullFlag) => {
  switch (true) {
    case !passNullFlag &&
      (value === undefined ||
        value === null ||
        (typeof value === 'string' && !value.trim())):
      return null;

    default:
      return { value };
  }
};
