import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import { RadioButtons } from '../../../../../../../common/components';
import ParameterConfigNormal from './ParameterConfigNormal';
import ParameterConfigComputed from './ParameterConfigComputed';

const _ParameterConfig = (props) => {
  const renderParameterConfigNormal = () => {
    return (
      <ParameterConfigNormal
        parameterConfs={props.parameterConfs}
        onParameterConfCollectionUpdateTrigger={(_parameterConf) => {
          props.onParameterConfsChange([_parameterConf]);
        }}
      />
    );
  };

  const renderParameterConfigComputed = () => {
    return (
      <ParameterConfigComputed
        formulaConfs={props.formulaConfs}
        onFormulaConfsChange={props.onFormulaConfsChange}
      />
    );
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <div className='new-input-wrap'>
            <RadioButtons
              noOfButtons={2}
              getButtonLableAtIndex={(index) => {
                return props.paramTypeCollection[index];
              }}
              buttonClickAtIndex={(index) => {
                props.onParamTypeChange(props.paramTypeCollection[index]);
              }}
              active={props.paramTypeActiveIndex}
            />
          </div>
        </Col>
      </Row>
      {props.paramTypeActiveIndex === 0
        ? renderParameterConfigNormal()
        : renderParameterConfigComputed()}
    </Fragment>
  );
};

export default _ParameterConfig;