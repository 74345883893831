import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { httpMethodGetJSON, httpMethodPatch, httpMethodDelete, httpMethodPost, } from '../https';
import { URL } from '../constants';
import * as AppConstants from './constant';


export const getAllNotifications = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_NOTIFICATIONS),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/notifications/`);
    })
);

export const getAllNotificationCount = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_NOTIFICATIONS_COUNT),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/notifications/`);
    })
);

export const getNotificationSettings = action$ => action$.pipe(
    ofType(AppConstants.GET_NOTIFICATION_SETTING),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/notifications/preferences/`);
    })
);

export const deleteNotification = action$ => action$.pipe(
    ofType(AppConstants.DELETE_NOTIFICATION),
    mergeMap((action, a) => {
        return axios.delete(`${URL}/notifications/${action.payload}`, {
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
            },
            // data: action.payload,
        }).then((response) => {
            return of({ type: AppConstants.DELETE_NOTIFICATION_SUCCESS, payload: response.data });
        }).catch(error => {
            return of({
                type: AppConstants.DELETE_NOTIFICATION_FAILURE,
                error: true,
                payload: error
            })
        })
    })
);

export const updateNotification = action$ => action$.pipe(
    ofType(AppConstants.UPDATE_NOTIFICATION),
    mergeMap(action => {
        if (action.payload && action.payload.id) {
            return httpMethodPatch({ type: AppConstants.UPDATE_NOTIFICATION, payload: action.payload.params }, `${URL}/notifications/${action.payload.id}/`);
        } else {
            return httpMethodPatch(action, `${URL}/notifications/`);
        }
    })
);

export const updateNotificationStatus = action$ => action$.pipe(
    ofType(AppConstants.UPDATE_NOTIFICATION_STATUS),
    mergeMap(action => {
        return httpMethodPatch({ type: AppConstants.UPDATE_NOTIFICATION_STATUS, payload: { status: action.payload.status } }, `${URL}/notifications/${action.payload.id}/`);
    })
);

export const turnOffNotification = action$ => action$.pipe(
    ofType(AppConstants.TURN_OFF_NOTIFICATION),
    mergeMap(action => {
        return httpMethodPatch({
            type: AppConstants.TURN_OFF_NOTIFICATION,
            payload: { enabled: action.payload.enabled }
        },
            `${URL}/notifications/preferences/${action.payload.category_id}/`);
    })
);

export const notificationCategory = action$ => action$.pipe(
    ofType(AppConstants.NOTIFICATION_CATEGORY),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/notifications/categories/`);
    })
);


export const notificationEpics = {
    getAllNotifications,
    deleteNotification,
    updateNotification,
    updateNotificationStatus,
    turnOffNotification,
    getNotificationSettings,
    notificationCategory,
    getAllNotificationCount
}