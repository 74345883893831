import React, { useCallback, useEffect } from 'react';

import tagConfsInputGet from './ConfigComponents/common/tagConfsInputGet';
import { RadioButtons } from '../../../../../common/components';
import TagParameterConfig from './ConfigComponents/Common/TagParameterConfig';
import StandardParameterConfig from './StandardParameterConfig';

const tagConfsGet = (conf) => {
  const _tagConfs = conf && conf.tagConfs;

  return tagConfsInputGet(_tagConfs).reduce((memo, tagConf) => {
    return [
      ...memo,
      {
        ...tagConf,
        isTag: true
      }
    ];
  }, []);
};

const confValidGet = (conf) => {
  const parameterConfValidGet = ({
    isComputed,
    locParam: { readingtype: { value } = {} } = {},
    computedFormula: { formula = [{}] } = {}
  } = {}) => {
    return !isComputed
      ? !!value
      : formula.reduce((memo, { type, parameter, value, formula }) => {
          const valid = (() => {
            switch (type) {
              case 'parameter':
                return !!parameter.readingtypeid;

              case 'operator':
              case 'constant':
                return !!value;

              case 'formula':
                return parameterConfValidGet({
                  isComputed: true,
                  computedFormula: { formula }
                });
            }
          })();

          return memo && !valid ? false : memo;
        }, true);
  };

  const tagConfValidGet = ({
    isComputed,
    tag: { value } = {},
    formula = []
  } = {}) => {
    return !isComputed
      ? !!value
      : formula.reduce((memo, { type, parameter, value, formula }) => {
          const valid = (() => {
            switch (type) {
              case 'parameter':
                return !!parameter.tag.value;

              case 'operator':
              case 'constant':
                return !!value;

              case 'formula':
                return tagConfValidGet({ isComputed: true, formula });
            }
          })();

          return memo && !valid ? false : memo;
        }, true);
  };

  return !(conf && conf.isTag)
    ? parameterConfValidGet(conf)
    : tagConfValidGet(conf);
};

const validGet = (value) => {
  const {
    isParamConfigTypeTag,
    isComputed,
    location,
    readingtype,
    formula,
    tagConfs
  } = value;

  return (
    isParamConfigTypeTag
      ? tagConfs
      : !isComputed
      ? [{ locParam: { location, readingtype } }]
      : [{ isComputed, computedFormula: { formula } }]
  ).reduce((memo, value) => {
    const valid = confValidGet(value);

    return memo && !valid ? false : memo;
  }, true);
};

const GenericConfig = (_props) => {
  const { onChange, onResetSuccess, ...props } = _props;

  const _onChange = useCallback(
    (value) => {
      const valid = validGet(value);

      onChange(value, !valid);
    },
    [onChange]
  );

  useEffect(() => {
    props.resetTriggerFlag &&
      (() => {
        _onChange({});
      })();
  }, [props.resetTriggerFlag, _onChange]);

  return (
    <div className='w-100'>
      {!props.paramConfigVisiblityToggle && (
        <div className='new-input-wrap p-0 m-0'>
          <RadioButtons
            noOfButtons={2}
            getButtonLableAtIndex={(index) => {
              return !index ? 'standard' : 'tag';
            }}
            buttonClickAtIndex={(index) => {
              const isParamConfigTypeTag = !!index;

              _onChange({
                ...props.value,
                isParamConfigTypeTag,
                isComputed: false,
                tagConfs: (props.value.tagConfs || tagConfsInputGet()).map(
                  (tagConf) => ({
                    ...tagConf,
                    isComputed: false
                  })
                )
              });
            }}
            active={!props.value.isParamConfigTypeTag ? 0 : 1}
            className='p-0 m-0'
          />
        </div>
      )}

      {!props.value.isParamConfigTypeTag ? (
        <StandardParameterConfig
          isComputedVisiblityToggle={props.isComputedVisiblityToggle}
          plant={props.plant}
          value={props.value}
          onChange={(value) => {
            _onChange(value);
          }}
        />
      ) : (
        <TagParameterConfig
          inputFieldColumnWidth={props.inputFieldColumnWidth}
          aliasVisiblityToggle={props.aliasVisiblityToggle}
          unitVisiblityToggle={props.unitVisiblityToggle}
          colorVisiblityToggle={props.colorVisiblityToggle}
          blankVisiblityToggle={props.blankVisiblityToggle}
          isComputedVisiblityToggle={props.isComputedVisiblityToggle}
          tagConfCount={1}
          plant={props.plant}
          tagConfs={props.value.tagConfs || tagConfsGet()}
          onTagConfsChange={(tagConfs) => {
            const value = {
              ...props.value,
              tagConfs
            };

            _onChange(value);
          }}
        />
      )}
    </div>
  );
};

export default GenericConfig;
