import {
    AIR_POLLUTION_DATA, AIR_POLLUTION_DATA_SUCCESS, AIR_POLLUTION_DATA_FAILURE,
    WEATHER_DATA, WEATHER_DATA_SUCCESS, WEATHER_DATA_FAILURE,
} from "../widgetConstants";

const INITIAL_STATE = {
    data: {},
    loading: false,
    error: false,
    errorMessage: undefined,
    success: undefined,
};


export function getAirPollutionData(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AIR_POLLUTION_DATA:
            let newState = {...state.data};
            return {
                ...state,
                loading: true,
                data: newState,
                error: false,
                success: undefined
            };
        case AIR_POLLUTION_DATA_SUCCESS:
            let oldState = {...state.data};
            oldState[action.param.readingtypeid] = action.response;
            return {
                ...state,
                data: oldState,
                error: false,
                loading: false,
                success: true
            };
        case AIR_POLLUTION_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: {},
                success: false,
                loading: false,
            };
        default:
            return state;
    }
}

export function getWeatherData(state = INITIAL_STATE, action) {
    switch (action.type) {
        case WEATHER_DATA:
            let newState = {...state.data};
            return {
                ...state,
                loading: true,
                data: newState,
                error: false,
                success: undefined
            };
        case WEATHER_DATA_SUCCESS:
            let oldState = {...state.data};
            oldState[action.param.parent_reading_type] = action.response;
            return {
                ...state,
                data: oldState,
                error: false,
                loading: false,
                success: true
            };
        case WEATHER_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: {},
                success: false,
                loading: false,
            };
        default:
            return state;
    }
}




export const weatherReducer = {
    getAirPollutionData: getAirPollutionData,
    getWeatherData: getWeatherData,
};