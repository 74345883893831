import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../constants';
import {
  httpMethodDelete,
  httpMethodGetJSON,
  httpMethodPost,
  httpMethodPut
} from '../../../https';

import { CREATE_UPDATE_PRODUCTION_NON_PRODUCTION_HOUR, GET_PRODUCTION_NON_PRODUCTION_HOUR_DETAILS } from "./constant";

export const createUpdateProductionNonProductionHour = (action$) =>
  action$.pipe(
    ofType(CREATE_UPDATE_PRODUCTION_NON_PRODUCTION_HOUR),
    mergeMap((action) => {
      return httpMethodPut(
        action,
        `${URL}/production-hour/plant/${action.payload.plant_id}/`
      );
    })
  );

  
export const getProductionNonProductionHourDetails = (action$) =>
  action$.pipe(
    ofType(GET_PRODUCTION_NON_PRODUCTION_HOUR_DETAILS),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/production-hour/plant/${action.payload.plant_id}/`
      );
    })
  );
