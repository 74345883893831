// import { GET_LOGIN_DATA, GET_LOGIN_DATA_RESET } from '../constants'
import { GET_LOGIN_DATA_SUCCESS, GET_LOGIN_DATA_RESET } from './constant'

export function loginAction(value) {
  return {
    type: GET_LOGIN_DATA_SUCCESS,
    payload: value
  }
}

export function resetLogin() {
  return {
    type: GET_LOGIN_DATA_RESET
  }
}

