import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import formulaConfsInputGet from '../common/formulaConfsInputGet';
import formulaConfsOutputGet from '../common/formulaConfsOutputGet';
import valueGet from '../common/valueGet';
import _ParameterConfigComputed from './ParameterConfigComputed';
import {
  Input,
  CheckBox,
  Button,
  RadioButtons
} from '../../../../../../../common/components';
import widgetConfigParamGet from '../common/widgetConfigParamGet';

const formulaConfsGet = (value) => {
  const formulaConfs = value && [value];

  return formulaConfsInputGet(formulaConfs).reduce((memo, formulaConf) => {
    return [
      ...memo,
      {
        ...formulaConf,
        isComputed: true
      }
    ];
  }, []);
};

const _formulaConfsGet = (formulaConfs) => {
  const _formulaConfs = formulaConfsOutputGet(formulaConfs);

  return {
    formula: valueGet(_formulaConfs && _formulaConfs[0])
  };
};

const ParametersConfig = (props) => {
  const parameterTypeCollection = widgetConfigParamGet(
    props.widgetConfig,
    'parameterRadio'
  ).label;

  const [initialized, setInitialized] = useState(false);

  const [parameterTypeActive, setParameterTypeActive] = useState();

  const [unit, setUnit] = useState();

  const [showPartial, setShowPartial] = useState();

  const [formulaConfs, setFormulaConfs] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setParameterTypeActive(
        (conf && conf.parameterRadio && conf.parameterRadio.value) ||
          parameterTypeCollection[0]
      );

      setUnit(conf && conf.unit && conf.unit.value);

      setShowPartial(
        (conf && conf.showPartial && conf.showPartial.value) || false
      );

      setFormulaConfs(
        formulaConfsGet(conf && conf.formula && conf.formula.value)
      );

      setInitialized(true);
    }
  }, [initialized, props.widgetParams, parameterTypeCollection]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      parameterRadio: valueGet(parameterTypeActive),
      unit: valueGet(unit),
      showPartial: valueGet(showPartial),
      ..._formulaConfsGet(formulaConfs),
      ...object
    };

    props.appInputParamChanged(value);
  };

  const renderParameterConfigComputed = () => {
    return (
      <_ParameterConfigComputed
        formulaConfs={formulaConfs}
        onFormulaConfsChange={(_formulaConfs) => {
          setFormulaConfs(_formulaConfs);

          appInputParamChangedHandle(_formulaConfsGet(_formulaConfs));
        }}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper dateTime-config-wrapper'>
        <Row>
          <Col md={12}>
            <div className='new-input-wrap'>
              <RadioButtons
                noOfButtons={2}
                getButtonLableAtIndex={(index) => {
                  return parameterTypeCollection[index];
                }}
                buttonClickAtIndex={(index) => {
                  const _parameterTypeActive = parameterTypeCollection[index];

                  setParameterTypeActive(_parameterTypeActive);

                  appInputParamChangedHandle({
                    parameterRadio: valueGet(_parameterTypeActive)
                  });
                }}
                active={parameterTypeCollection.findIndex(
                  (parameterType) => parameterType === parameterTypeActive
                )}
              />
            </div>
          </Col>
        </Row>

        <div className='content-border-wrap'>
          <Row className='alias-data-wrap'>
            <Col xs={12}>
              <Row>
                <Col md={8}>
                  <div className='new-input-wrap'>
                    <label className='label-text'>Unit</label>
                    <Input
                      size='sm'
                      label=''
                      type='text'
                      required={false}
                      placeholder='Enter Unit'
                      value={unit || ''}
                      inputChanged={(_unit) => {
                        setUnit(_unit);

                        appInputParamChangedHandle({
                          unit: valueGet(_unit)
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className='new-input-wrap row-checkbox-right'>
                    <div className='new-custom-checkbox'>
                      <CheckBox
                        label={'Show Partial Calculation Result'}
                        size='sm'
                        checked={showPartial}
                        checkboxClicked={(_showPartial) => {
                          setShowPartial(_showPartial);

                          appInputParamChangedHandle({
                            showPartial: valueGet(_showPartial)
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              {renderParameterConfigComputed()}
            </Col>
          </Row>
        </div>
        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />
          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParametersConfig;
