export const GET_GIS_VIEW_READING_DATA = "GET_GIS_VIEW_READING_DATA";
export const GET_GIS_VIEW_READING_DATA_SUCCESS =
  "GET_GIS_VIEW_READING_DATA_SUCCESS";
export const GET_GIS_VIEW_READING_DATA_FAILURE =
  "GET_GIS_VIEW_READING_DATA_FAILURE";

export const GET_KPI_VIEW_DATA="GET_KPI_VIEW_DATA";
export const GET_KPI_VIEW_DATA_SUCCESS="GET_KPI_VIEW_DATA_SUCCESS";
export const GET_KPI_VIEW_DATA_FAILURE="GET_KPI_VIEW_DATA_FAILURE";

export const GET_KPI_PLANT_DATA="GET_KPI_PLANT_DATA";
export const GET_KPI_PLANT_DATA_SUCCESS="GET_KPI_PLANT_DATA_SUCCESS";
export const GET_KPI_PLANT_DATA_FAILURE="GET_KPI_PLANT_DATA_FAILURE";

export const GET_KPI_VIEW_READING_DATA = "GET_KPI_VIEW_READING_DATA";
export const GET_KPI_VIEW_READING_DATA_SUCCESS =  "GET_KPI_VIEW_READING_DATA_SUCCESS";
export const GET_KPI_VIEW_READING_DATA_FAILURE =  "GET_KPI_VIEW_READING_DATA_FAILURE";

export const GET_KPI_REDIRECTION_DASHBOARD = 'GET_KPI_REDIRECTION_DASHBOARD';
export const GET_KPI_REDIRECTION_DASHBOARD_SUCCESS =
  'GET_KPI_REDIRECTION_DASHBOARD_SUCCESS';
export const GET_KPI_REDIRECTION_DASHBOARD_FAILURE =
  'GET_KPI_REDIRECTION_DASHBOARD_FAILURE';
export const GET_KPI_REDIRECTION_DASHBOARD_RESET =
  'GET_KPI_REDIRECTION_DASHBOARD_RESET';