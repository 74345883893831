import React, { Fragment } from 'react';

import ParameterConfigNormal from './ParameterConfigNormal';
import ParameterConfigComputed from './ParameterConfigComputed';

const _ParameterConfig = (props) => {
  const renderParameterConfigNormal = () => {
    return (
      <ParameterConfigNormal
        parameterConfs={props.parameterConfs}
        onParameterConfCollectionUpdateTrigger={(_parameterConf) => {
          props.onParameterConfsChange([_parameterConf]);
        }}
      />
    );
  };

  const renderParameterConfigComputed = () => {
    return (
      <ParameterConfigComputed
        formulaConfs={props.formulaConfs}
        onFormulaConfsChange={props.onFormulaConfsChange}
      />
    );
  };

  return (
    <Fragment>
      {props.paramTypeActiveIndex === 0
        ? renderParameterConfigNormal()
        : renderParameterConfigComputed()}
    </Fragment>
  );
};

export default _ParameterConfig;
