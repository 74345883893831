import React from 'react'
import Button from 'reactstrap/lib/Button';
import Spinner from 'reactstrap/lib/Spinner';

export default (props) => {
    return (
        <Button
            color={props.buttonType}
            onClick={props.buttonClick}
            size={props.size ? props.size : 'sm'}
            className={`${props.className} commonButton`}
            disabled={props.loading ? true : props.isDisabled}
        >
            {props.loading ? <Spinner size = 'md' color="primary" /> : props.innerContent}
        </Button>
    );
};
