import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core';
import GearIcon from '../../../../../../assets/images/Upgrade/UpgradeGear.svg';

const  UpgradeButton =({upgradeEmailFlag,onUpgradeClickCallback,upgrade})=>{
  return (
    <Grid item xs={upgradeEmailFlag?6:3}>
    {upgradeEmailFlag?
      <Typography style={{fontSize:'16px',lineHeight:'16px',color:'#44CBCC'}}>We have received your request and we will be in contact shortly</Typography> :
    <button
      onClick={onUpgradeClickCallback}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        overflow: 'hidden',
        borderRadius: '8px',
        padding: '10px',
        border: 'none',
      }}
      disabled={upgrade.loading}
    >
      <p
        style={{
          fontSize: '14px',
          marginInline: '10px',
          color: 'black'
        }}
      >
        I want to Upgrade
      </p>
      <img alt='' width='20px' height='20px' src={GearIcon} />
    </button>
    }
  </Grid>
  )
}

export default UpgradeButton