/**
 * All reducer for widget
 */
import {
  GET_ALERT_TYPE_SUMMARY,
  GET_ALERT_TYPE_SUMMARY_FAILURE,
  GET_ALERT_TYPE_SUMMARY_SUCCESS
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined,
};

export function getAlertTypeSummary(state = INITIAL_STATE, action) {
  switch (action.type) {
      case GET_ALERT_TYPE_SUMMARY:
          return {
              ...state,
              loading: true,
              error: false
          };
      case GET_ALERT_TYPE_SUMMARY_SUCCESS:
          return {
              ...state,
              data: action.response,
              error: false,
              loading: false
          };
      case GET_ALERT_TYPE_SUMMARY_FAILURE:
          return {
              ...state,
              error: true,
              loading: false
          };
      default:
          return state
  }
}