import {
  GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA,
  GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA_SUCCESS,
  GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA_FAILURE,
  GET_ASSET_HEALTH_COUNT_ASSETS_DATA,
  GET_ASSET_HEALTH_COUNT_ASSETS_DATA_SUCCESS,
  GET_ASSET_HEALTH_COUNT_ASSETS_DATA_FAILURE
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  errorMessage: undefined,
  success: undefined
};

export const getAssetHealthCountCbmAssetData = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        success: undefined,
        error: false
      };

    case GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.result,
        success: true,
        error: false,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record
      };

    case GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };
    default:
      return state;
  }
};

export const getAssetHealthCountAssetsData = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_ASSET_HEALTH_COUNT_ASSETS_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case GET_ASSET_HEALTH_COUNT_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        error: false,
        loading: false,
        success: true
      };

    case GET_ASSET_HEALTH_COUNT_ASSETS_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };

    default:
      return state;
  }
};
