import * as AppConstants from './constant';

const INITIAL_STATE = {
    data: [],
    loading: false,
    error: false,
    success: undefined,
    total_pages: null,
    total_record: null,
    checkedData: [],
    isReportChecked: false,
    isAllReportChecked: false,
    message: null
}

export function getAllReports(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_REPORTS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_REPORTS_SUCCESS:
            action.response && action.response.result && Array.isArray(action.response.result) && action.response.result.map((alert, index) => {
                alert['isReportChecked'] = false
            })
            return {
                ...state,
                data: action.response.result,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                checkedData: [],
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_REPORTS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }

        case AppConstants.CHECK_ALL_REPORT_SUCCESS:
            let list = state.data;
            if (action.payload.value === "checkAll") {
                list && Array.isArray(list) && list.map(item => {
                    item.isReportChecked = action.payload.checkedFlag;
                });
            }
            return {
                ...state,
                isAllReportChecked: action.payload.checkedFlag,
                data: list,
                checkedData: [...list],
                loading: false,
            }

        case AppConstants.CHECK_REPORT_SUCCESS:
            let data = state.data;
            data && Array.isArray(data) && data.map(item => {
                if (action.payload && action.payload.data && (item.ReportId == action.payload.data.ReportId)) {
                    item.isReportChecked = action.payload.isChecked;
                }
            });
            if (!action.payload.isChecked) {
                const indx = state.checkedData.findIndex(v => v.ReportId === action.payload.data.ReportId);
                state.checkedData.splice(indx, indx >= 0 ? 1 : 0);
            }

            return {
                ...state,
                data,
                checkedData: action.payload.isChecked ? state.checkedData.concat(action.payload.data) : state.checkedData
            }

        default:
            return state;
    }
}

export function getAllReportLogs(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_REPORT_LOGS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.GET_ALL_REPORT_LOGS_SUCCESS:
            return {
                ...state,
                data: action.response.result,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                checkedData: [],
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.GET_ALL_REPORT_LOGS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }

        case AppConstants.CHECK_REPORT_LOG_SUCCESS:
            let data = state.data;
            data && Array.isArray(data) && data.map(item => {
                if (action.payload && action.payload.data && (item.id == action.payload.data.id)) {
                    item.isAlertLogChecked = action.payload.isChecked;
                }
            });
            if (!action.payload.isChecked) {
                const indx = state.checkedData.findIndex(v => v.id === action.payload.data.id);
                state.checkedData.splice(indx, indx >= 0 ? 1 : 0);
            }

            return {
                ...state,
                data,
                checkedData: action.payload.isChecked ? state.checkedData.concat(action.payload.data) : state.checkedData
            }

        default:
            return state;
    }
}

export function deleteReport(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.DELETE_REPORT:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.DELETE_REPORT_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.DELETE_REPORT_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.DELETE_REPORT_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state
    }
}

export function createReport(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.CREATE_REPORT:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null,
                isReport: action.payload
            }
        case AppConstants.CREATE_REPORT_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true,
                message: null,
                isReport: null
            }
        case AppConstants.CREATE_REPORT_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                message: action.payload.response.message,
                loading: false,
            }
        case AppConstants.CREATE_REPORT_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null,
                loading: false,
            }
        // case 'ERROR':
        //     return {
        //         ...state,
        //         error: true,
        //         data: [],
        //         success: false,
        //         message: action.payload.response && action.payload.response.message,
        //         loading: false,
        //     }
        default:
            return state;
    }
}

export function updateReport(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.UPDATE_REPORT:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null,
                isReport: action.payload
            }
        case AppConstants.UPDATE_REPORT_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true,
                message: null,
                isReport: null
            }
        case AppConstants.UPDATE_REPORT_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                message: null,
                loading: false,
            }
        case AppConstants.UPDATE_REPORT_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null,
                loading: false,
            }
        // case 'ERROR':
        //     return {
        //         ...state,
        //         error: true,
        //         data: [],
        //         success: false,
        //         message: action.payload.response && action.payload.response.message,
        //         loading: false,
        //     }
        default:
            return state
    }
}

export function downloadSingleReport(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.DOWNLOAD_REPORT_BY_ID:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null
            }
        case AppConstants.DOWNLOAD_REPORT_BY_ID_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true,
                message: null,
                data: action.response.response
            }
        case AppConstants.DOWNLOAD_REPORT_BY_ID_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                message: null
            }
        case AppConstants.DOWNLOAD_REPORT_BY_ID_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null
            }
        default:
            return state
    }
}

// export function standardReport(state = INITIAL_STATE, action) {
//     switch (action.type) {
//         case AppConstants.DOWNLOAD_REPORT_CUSTOM:
//             return {
//                 ...state,
//                 loading: true,
//                 data: [],
//                 error: false,
//                 success: undefined,
//                 message: null
//             }
//         case AppConstants.DOWNLOAD_REPORT_CUSTOM_SUCCESS:
//             return {
//                 ...state,
//                 error: false,
//                 loading: false,
//                 data: action.payload,
//                 success: true,
//                 message: null
//             }
//         case AppConstants.DOWNLOAD_REPORT_CUSTOM_FAILURE:
//             return {
//                 ...state,
//                 error: true,
//                 data: [],
//                 success: false,
//                 message: null
//             }
//         case AppConstants.DOWNLOAD_REPORT_CUSTOM_RESET:
//             return {
//                 ...state,
//                 loading: true,
//                 data: [],
//                 error: false,
//                 success: undefined,
//                 message: null
//             }
//         case 'ERROR':
//             return {
//                 ...state,
//                 error: true,
//                 data: [],
//                 success: false,
//                 message: action.payload.response && action.payload.response.message
//             }
//         default:
//             return state
//     }
// }

export const reportReducer = {
    getAllReports,
    getAllReportLogs,
    deleteReport,
    createReport,
    updateReport,
    downloadSingleReport,
    // standardReport
}