import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { getWidgetListFromTemplateId } from '../../../Widgets/action';
import { getAllWidget } from '../../../../action';
import {
  CheckBox,
  Input,
  Button
} from '../../../../../../../common/components';

const summaryTypeCollection = ['insights', 'alerts'];

const routeTypeTemplateMatchString = 'Templates';

const OthersConfig = (_props) => {
  const { getWidgetListFromTemplateId, getAllWidget, ...props } = _props;

  const location = useLocation().pathname;

  const [routeType, , routeId] = location.split('/').slice(1);

  const widgetList =
    routeType === routeTypeTemplateMatchString
      ? props.widgetListTemplate
      : props.widgetListDashboard;

  const [initialized, setInitialized] = useState(false);

  const [config, setConfig] = useState();

  const [widget, setWidget] = useState();

  const [redirect, setRedirect] = useState();

  useEffect(() => {
    routeType === routeTypeTemplateMatchString
      ? getWidgetListFromTemplateId({
          templateId: routeId,
          page_no: 1,
          page_size: 100
        })
      : getAllWidget({
          id: routeId,
          page_no: 1,
          page_size: 100
        });
  }, [routeType, routeId, getWidgetListFromTemplateId, getAllWidget]);

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams['Insight-alert-summary'] &&
        props.widgetParams['Insight-alert-summary'].value;

      setConfig(
        conf
          ? conf.config
          : {
              insights: true,
              alerts: true
            }
      );

      setWidget(
        conf
          ? conf.widget
          : {
              alerts: null,
              alertsSub: null,
              insights: null,
              insightsSub: null
            }
      );

      setRedirect(
        conf
          ? conf.redirect
          : {
              insights: false,
              alerts: false
            }
      );

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      'Insight-alert-summary': {
        value: {
          config,
          widget,
          redirect,
          ...object
        }
      }
    };

    props.appInputParamChanged(value);
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          {summaryTypeCollection.map((summaryType) => {
            const title = [
              ...summaryType.slice(0, 1).toUpperCase(),
              ...summaryType.slice(1)
            ].join('');

            const isContainer =
              widget[summaryType] &&
              JSON.parse(widget[summaryType]).name === 'Container';

            return (
              <Col key={summaryType} md={12}>
                <Row>
                  <Col>
                    <div className='new-input-wrap mb-3'>
                      <div className='new-custom-checkbox'>
                        <CheckBox
                          label={title}
                          size='sm'
                          checked={config[summaryType]}
                          checkboxClicked={(value) => {
                            const _config = {
                              ...config,
                              [summaryType]: value
                            };

                            setConfig(_config);

                            appInputParamChangedHandle({ config: _config });
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  {isContainer && (
                    <Col>
                      <div className='new-input-wrap mb-3'>
                        <div className='new-custom-checkbox'>
                          <CheckBox
                            label='Redirection'
                            size='sm'
                            checked={redirect[summaryType]}
                            checkboxClicked={(value) => {
                              const _redirect = {
                                ...redirect,
                                [summaryType]: value
                              };

                              setRedirect(_redirect);

                              appInputParamChangedHandle({
                                redirect: _redirect
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>

                <Row>
                  <Col md={6}>
                    <div className='new-input-wrap'>
                      <label className='label-text'>{`Widget(${title})`}</label>

                      <Input
                        placeholder='Select a widget'
                        type='selectvalue'
                        value={widget[summaryType]}
                        optionsValues={widgetList.map(
                          ({
                            id,
                            widget_id: { name },
                            config: { container_widget_select }
                          }) => {
                            return {
                              name,
                              value: JSON.stringify({
                                id,
                                name,
                                options: container_widget_select
                              })
                            };
                          }
                        )}
                        inputChanged={(value) => {
                          const _widget = {
                            ...widget,
                            [summaryType]: value,
                            [`${summaryType}Sub`]: null
                          };
                          setWidget(_widget);

                          appInputParamChangedHandle({ widget: _widget });
                        }}
                      />
                    </div>
                  </Col>

                  {isContainer && (
                    <Col>
                      <div className='new-input-wrap'>
                        <label className='label-text'>
                          Widget(Container or Group)
                        </label>

                        <Input
                          placeholder='Select a widget'
                          type='selectvalue'
                          value={widget[`${summaryType}Sub`]}
                          optionsValues={widgetList
                            .filter(
                              ({ id }) =>
                                widget[summaryType] &&
                                JSON.parse(
                                  widget[summaryType]
                                ).options.includes(id)
                            )
                            .map(({ id, config: { widget_name } }) => ({
                              name: widget_name,
                              value: id
                            }))}
                          inputChanged={(value) => {
                            const _widget = {
                              ...widget,
                              [`${summaryType}Sub`]: value
                            };
                            setWidget(_widget);

                            appInputParamChangedHandle({ widget: _widget });
                          }}
                        />
                      </div>
                    </Col>
                  )}
                </Row>

                <br />
              </Col>
            );
          })}
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return initialized && <Fragment>{renderFn('insights')}</Fragment>;
};

const mapStateToProps = (state) => {
  return {
    widgetListTemplate:
      (state.widgetListFromTemplateId &&
        state.widgetListFromTemplateId.data &&
        state.widgetListFromTemplateId.data.result) ||
      [],
    widgetListDashboard: state.allWidgets.data || []
  };
};

export default connect(mapStateToProps, {
  getWidgetListFromTemplateId,
  getAllWidget
})(OthersConfig);
