import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import axios from 'axios';
import { httpMethodGetJSON, httpMethodPatch, httpMethodDelete, httpMethodPost, } from '../https';
import { ajax } from 'rxjs/ajax';
import { map } from 'lodash';
import { URL } from '../constants';
import * as AppConstants from './constant';


export const getCalculatedParameterList = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_CALCULATED_PARAMETER_LIST),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/calcd-params`);
    })
);


export const getAllClientList = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_CLIENT_LIST),
    mergeMap(action => {
        let name = action.payload && action.payload.name
        if (name) {
            return httpMethodGetJSON({ type: AppConstants.GET_ALL_CLIENT_LIST }, `${URL}/service_templates/clients/?name=${name}`);
        } else return httpMethodGetJSON({ type: AppConstants.GET_ALL_CLIENT_LIST }, `${URL}/service_templates/clients/`);
    })
);

export const getAllPlantList = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_PLANT_LIST),
    mergeMap(action => {
        let client_id = action.payload && action.payload.client_id
        if (client_id) {
            return httpMethodGetJSON({ type: AppConstants.GET_ALL_PLANT_LIST }, `${URL}/service_templates/plants/?client_id=${client_id}`);
        } else return httpMethodGetJSON({ type: AppConstants.GET_ALL_PLANT_LIST }, `${URL}/service_templates/plants/`);
    })
);

export const deleteCalculatedParameter = action$ => action$.pipe(
    ofType(AppConstants.DELETE_CALCULATED_PARAMETER),
    mergeMap(action => {
        return httpMethodDelete({ type: AppConstants.DELETE_CALCULATED_PARAMETER, payload: action.payload.data }, `${URL}/calcd-params/${action.payload.cal_param_id}/`);
    })
);

export const createCalculatedParameter = action$ => action$.pipe(
    ofType(AppConstants.CREATE_CALCULATED_PARAMETERS),
    mergeMap(action => {
        return httpMethodPost(action, `${URL}/calcd-params/`);
    })
);

export const updateCalculatedParameter = action$ => action$.pipe(
    ofType(AppConstants.UPDATE_CALCULATED_PARAMETER),
    mergeMap(action => {
        return httpMethodPatch(action, `${URL}/calcd-params/${action.payload.id}/`);
    })
);

export const verifyTargetSensorParameter = action$ => action$.pipe(
    ofType(AppConstants.VERIFY_TARGET_SENSOR_PARAMETER),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/reading/reading_series/`);
    })
);

export const calculatedParameterEpics = {
    getCalculatedParameterList,
    getAllClientList,
    getAllPlantList,
    deleteCalculatedParameter,
    createCalculatedParameter,
    updateCalculatedParameter,
    verifyTargetSensorParameter
}
