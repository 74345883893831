export default (parameterConfs) => {
  return parameterConfs
    .reduce(
      (
        memo,
        {
          alias,
          parameter: {
            locationLabel,
            locationid,
            readingtypeLabel,
            readingtypeid
          } = {},
          ...rest
        }
      ) => {
        return [
          ...memo,
          {
            alias,
            locParam: {
              location: {
                label: locationLabel,
                value: locationid
              },
              readingtype: {
                label: readingtypeLabel,
                value: readingtypeid
              }
            },
            ...rest
          }
        ];
      },
      []
    )
    .reduce((memo, parameterConf) => {
      const value = parameterConf.locParam.readingtype.value && parameterConf;

      return value ? [...(memo || []), value] : memo;
    }, null);
};
