import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";
import { httpMethodPost, httpMethodGetJSON } from "../https";
import { ENERGY_WIDGET_DATA } from "./constant";
import { URL } from "../constants";


const READING_DATA_URL = `${URL}/reading/reading_data/`;
const READING_COMPUTED_DATA_URL = `${URL}/reading/reading_computed_data/`;

export const getEnergyData = (action$) =>
    action$.pipe(
        ofType(ENERGY_WIDGET_DATA),
        mergeMap((action) => {
            if (action.payload.isComputed === true) {
                return httpMethodPost(action, READING_COMPUTED_DATA_URL);
            }
            return httpMethodGetJSON(action, `${READING_DATA_URL}`);
        }),
    );