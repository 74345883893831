import React, { useState, useContext, useEffect } from "react";
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parse } from "query-string";
import { Context, UserContext } from "../../../Main/context";
import { createCustomDashboard, createDashboardGlobalAction, resetAddDashboard } from "../../action";
import {resetUpdateTemplate,resetUpdateService} from "../../../AssetScreenDesign/action"
import { createUploadGlobalAction } from "../../../DataUpload/action";
import { isOpenScreenDesignModal } from "../../../AssetScreenDesign/action";
import {isOpenCalculatedParameterModal} from "../../../CalculatedParameter/action";
import { DASHBOARD, ALERT, REPORT, EVENT, DATA_UPLOAD, IAM_USER, TEMPALTES, CALCULATED_PARAMETER } from "../../../Main/permissionContants";
import "./OptionDropDown.scss";

const CREATE_OPTIONS = [
    {
        path: 'Alert',
        route: '/Alert/AlertBuilder',
        permission: ALERT.CREATE_ALERT,
    },
    {
        path: 'Report',
        route: '/Report/ReportBuilder',
        permission: REPORT.CREATE_REPORT,
    },
    {
        path: 'Activity',
        route: '/Activity/ActivityBuilder',
        permission: EVENT.CREATE_EVENT,
    },
    {
        path: 'Dashboard',
        route: '/Dashboard',
        permission: DASHBOARD.CREATE_DASHBOARD,
    },
    {
        path: 'DataUpload',
        route: '/DataUpload',
        permission: DATA_UPLOAD.UPLOAD_DATA_FILE,
    },
    {
        path: 'UserManagement',
        route: '/UserManagement/UserBuilder',
        permission: IAM_USER.CREATE_NEW_USER,
    },
    {
        path: 'Templates',
        permission: TEMPALTES.CREATE_TEMPLATE_FOR_SERVICE,
    },
    {
      path: 'CalculatedParameter',
      permission: CALCULATED_PARAMETER.CREATE_CALCULATED_PARAMETER,
    },
]


const OptionDropDown = (props) => {
    const { toggleSidebar, dropdownData, getContent, sidebarName, activeTabIndex, pathname, name, showDropDown } = props;
    const [selectedTab, setSelectedTab] = useState("");
    const [options, setOptions] = useState([]);
    const userContext = useContext(UserContext);
    const context = useContext(Context);
    const { search, ...rest } = useLocation();
    const parseData = parse(search)
    const templateTabs = ['Templates', 'Services', 'Parameters', 'AssetCalParameters', 'Networks'];


    useEffect(() => {
        if(parseData && parseData.tab){
            let data = parseData.tab.replaceAll('-', ' ')
            let options = getOptions(name);
            if(name === "Design"){
                options = templateTabs;
            }
            let active = options.findIndex((str)=>str===data)
            setSelectedTab(active)
          }
        //Dashboard sidebar outside event handler
        const concernedElement = document.querySelector(".other-pages-wrap");
        document.addEventListener("click", (event) => {
            if (concernedElement.contains(event.target)) {
                // console.log("Clicked Inside");
            } else {
                toggleSidebar(!showDropDown);
            }
        });
    }, []);

    useEffect(()=>{
        if(name){
            setOptions(getOptions(name))
        }
    },[name])

    const getOptions = (path)=>{
        let options = [];
        switch(path){
          case "Alert":
            options = [{label:'Summary', name:'Summary'}, {label:'List', name:'Alert List'}, {label:'Trigger Logs', name:'Trigger Logs'}]
            break;
          case "Activity":
            options = [{label:'Summary', name:'Activity Summary'}, {label:'Logs', name:'Activity Logs'}]
            break;  
          case "Report":
            options = [{label:'List', name:'Report List'}, {label:'Logs', name:'Report Logs'}]
            break;  
          case "Data Upload":
            options = [{label:'Template List', name:'Template List'}, {label:'Logs', name:'Data Logs'}, {label:'Generate file for Manual Data Upload', name:'Generate file for Manual Data Upload'}]
            break; 
          case "Design":
            options = [{label:'Asset Templates', name:'Asset Templates'}, {label:'Service Templates', name:'Service Templates'}, {label:'Calculated Parameters', name:'Calculated Parameters Templates'} ,{label:'Asset Calculated Parameter', name:'ACParameters'},{label:'Network Templates', name:'Network Templates'} ]
            break;   
          case "User Management":
            options = [{label:'List', name:'Users List'}]
            break;
          case "App Expert":
            options = [{label:'App List', name:'App List'}, /* {label:'Insight Logs', name:'Insight Logs' }*/]
            break;
          // case "Calculated Parameter":
          //   options = [{label:'List', name:'Calculated Parameter'}]
          //   break;
          default:
            options = [];
            break;    
        }
        return options;
    }


    const create = () => {
      if (!userContext.permission(getCreateOption().permission)) {
        let { route, path } = getCreateOption();
        if (path == 'CalculatedParameter' ) {              
          props.isOpenCalculatedParameterModal(true);
        }
        else props.history.push(route);
      }
        else if (userContext.permission(getCreateOption().permission)) {
            let { route, path } = getCreateOption();
            if (path == 'Dashboard') props.createDashboardGlobalAction({});
            if (path == 'DataUpload') props.createUploadGlobalAction({});
            if (path == 'Templates' ) {
                props.resetUpdateTemplate();
                props.resetUpdateService();
                props.isOpenScreenDesignModal(true);

              (rest.pathname == '/Templates' && search == '?tab=Services')
                ? props.history.push({ pathname: rest.pathname, search })
                : props.history.push({ pathname: '/Templates', search: '' })

            } else props.history.push(route);
        } else context.notify.error(NOT_ACCESS_MESSAGE)
        toggleSidebar(false);
    }

    const getCreateOption = () => {
        let { pathname } = props;
        return CREATE_OPTIONS.filter(item => item.path == pathname)[0]
    }

    return (
      <div
        className={`${props.className}`}
        style={props.style}
      >
        <div class={`dropdown-menu show`}>
          <div className="dropdown-title">
            <span>{props.title}</span>
            <i
              class="fa fa-question-circle"
              style={{ cursor: "pointer" }}
              aria-hidden="true"
            ></i>
          </div>
          {options &&
            options.length &&
            options.map((data, index) => (
              <button
                className={`menu-item ${
                  selectedTab === index && "active-text"
                }`}
                onClick={() => {
                  toggleSidebar(false);
                  // if(data.name.includes("Calculated Parameters") && templateTabs[index] == "Parameters"){
                  //   props.history.push(`/Templates?tab=Calculated-Parameter`);
                  // }
                  props.history.push(
                    `/${pathname}?tab=${
                      data.name.includes("Templates")
                        ? templateTabs[index].replaceAll(" ", "-")
                        : data.name.replaceAll(" ", "-")
                    }`
                  );
                }}
              >
                {data.label}
              </button>
            ))}
          <button className='menu-item add-btn-wrap' onClick={create}>
            {props.pathname != 'AppExpert' && (
              <div className='add-btn'>
                <i class='fa fa-plus' aria-hidden='true'></i>
                {props.add_name == 'Upload Manual Data'
                  ? props.add_name
                  : `Add ${props.add_name}`}
              </div>
            )}
          </button>
        </div>
      </div>
    );
};

export default connect(null, {
    createDashboardGlobalAction,
    createUploadGlobalAction,
    isOpenScreenDesignModal,
    createCustomDashboard,
    resetAddDashboard,
    resetUpdateTemplate,
    resetUpdateService,
    isOpenCalculatedParameterModal
})(OptionDropDown);