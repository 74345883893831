import formulaConfIdGet from './formulaConfIdGet';

export default () => {
  return {
    alias: '',
    unit: '',
    showPartial: false,
    formula: [
      {
        id: formulaConfIdGet(),
        type: 'formula',
        alias: '',
        formula: []
      }
    ],
    equation: ''
  };
};
