import React, { Fragment, useState, useEffect } from 'react';
import { Route, Switch, Redirect, Router, useHistory, useLocation } from 'react-router-dom';
import { Login, Permissions } from '../../../common/container';
import { connect } from 'react-redux';
const CBMAssetDetails = React.lazy(() => import('../AssetInsights/CBMAssetDetails'));
const UserProfile = React.lazy(() => import('../UserProfile'));
const AllNotifications = React.lazy(() => import('../Notification/components/AllNotifications/'));
const Notification = React.lazy(() => import('../Notification/'));
const PartnerLogin = React.lazy(() => import('../PartnerLogin'));

const LOGIN_ROUTES = ["/"];

import { getActiveConfigRoutes } from './appSetting';
import NoAccess from '../NoAccess';

const routes = (props) => {
    //const { isTokenValid, loading } = props.getRefreshData
    // const isTokenValid = props.getRefreshData.isTokenValid;
    const location = useLocation()
    const [acitveRoutes, setActiveRoutes] = useState([]);
    const path = location.pathname.split('/');
    const isRouteAvail = acitveRoutes && acitveRoutes.some(item => item.url === `/${path[1]}`)
    const isAdminProfile = localStorage.getItem('adminProfile');
    const data = JSON.parse(localStorage.getItem('smartsense.services'));
    

    useEffect(() => {
        setActiveRoutes(getActiveConfigRoutes())
    }, [props.loginData])

    return (
        <Switch>
            <Route path={LOGIN_ROUTES} exact render={() => {
                let home = false;
                let operationalExcellence = false;
                if (localStorage.getItem('smartsense.authToken')) {
                    for (var i = 0; i < (data && data.length); i++) {
                        if(data[i].url == '/home') {
                            home = true;
                        }
                        if (data[i].url == "/operational Excellence") {
                            operationalExcellence = true;
                        }
                    }
                    // for (var i = 0; i < (data && data.length); i++) {
                    //     if (data[i].url == "/operational Excellence") {
                    //         operationalExcellence = true;
                    //     }
                    // }
            
                    // props.history.push(!operationalExcellence ? '/Sustainability' : '/Dashboard');
                    // debugger
                    if(home ){
                        props.history.push(home ? '/Home' : '/Dashboard');
                    } else if (!operationalExcellence) {
                        props.history.push(!operationalExcellence ? '/Sustainability' : '/Dashboard');
                    } else {
                        props.history.push('/Dashboard');
                    }
                    return;
                }
                return <Login />;
            }}
            />
            <Route path="/NoAccess" component={NoAccess} />
            <Route path="/CBM" component={CBMAssetDetails} />
            <Route path="/UserProfile" component={UserProfile} />
            <Route path='/PartnerLogin' component={PartnerLogin} />
            <Route path="/Notification" component={() => <AllNotifications history={props.history} />} />
            {
                acitveRoutes.map((route, index) => {
                    return (
                        <Route
                            path={route.url}
                            key={index}
                            render={({ match: { url } }) => (
                                <Fragment>
                                    <Route path={`${url}`} component={() => <route.name history={props.history} />} exact />
                                    {
                                        route && route.components && route.components.length > 0 && route.components.map((child, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    <Route key={i} path={`${url}${child.url}`} component={child.name} />
                                                    {
                                                        child && child.components && child.components.length > 0 && child.components.map((subChild, idx) => {
                                                            return <Route key={idx} path={`${url}${child.url}${subChild.url}`} component={subChild.name} />
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </Fragment>
                            )}
                        />
                    )
                })
            }
            {/* {
                isAdminProfile !== 'false' && <Fragment>
                    <Route path="/404" component={Permissions} />
                    <Redirect from='*' to='/404' />
                </Fragment>
            } */}

            {/* <Route
                path='/Alert'
                render={({ match: { url } }) => (
                    <Fragment>
                        <Route path={`${url}`} component={ConfiguredAlerts} exact />
                        <Route path={`${url}/Dashboard`} component={AlertDashboardContainer} />
                        <Route path={`${url}/Logs`} component={AllAlertLogs} />
                        {
                            path.indexOf('AlertLogHistory') === 3 && path.indexOf('AlertDetails') === 2 ? <Route path={`${url}/AlertDetails/AlertLogHistory`} component={LogHistory} /> : <Route path={`${url}/AlertDetails`} component={AlertLogs} />
                        }
                        <Route path={`${url}/AlertBuilder`} component={AlertBuilder} />
                        <Route path={`${url}/List`} component={ConfiguredAlerts} />
                    </Fragment>
                )}
            />
            <Route
                path='/Dashboard'
                render={({ match: { url } }) => (
                    <Fragment>
                        <Route path={`${url}`} component={Dashboard} exact />
                        <Route path={`${url}/WidgetBuilder`} component={WidgetBuilder} />
                        <Route path={`${url}/EditDashboard`} component={EditDashboard} />
                    </Fragment>
                )}
            />
            <Route
                path="/AssetInsightsVintage"
                render={({ match: { url } }) => (
                    <Fragment>
                        <Route path={`${url}`} component={VintageAssetInsights} exact />
                        <Route path={`${url}/AssetDetails`} component={VintageAssetDetails} />
                    </Fragment>
                )}
            />
            <Route
                path="/AssetInsights"
                render={({ match: { url } }) => (
                    <Fragment>
                        <Route path={`${url}`} component={AssetInsights} exact />
                    </Fragment>
                )}
            />
            <Route path="/CBM" component={CBMAssetDetails} />
            <Route
                path='/Report'
                render={({ match: { url } }) => (
                    <Fragment>
                        <Route path={`${url}`} component={ConfiguredReports} exact />
                        <Route path={`${url}/ReportBuilder`} component={ReportBuilder} />
                    </Fragment>
                )}
            />
            <Route
                path="/Activity"
                render={({ match: { url } }) => (
                    <Fragment>
                        <Route path={`${url}`} component={ConfiguredEvent} exact />
                        <Route path={`${url}/ActivityBuilder`} component={EventBuilder} />
                    </Fragment>
                )}
            />
            <Route
                path="/Notification"
                render={({ location }) => {
                    // let path = location.pathname.split('/');
                    // if (path.indexOf('Notification') === 2 && path.indexOf('Notification') === 1) {
                    //   return <EventBuilder />
                    // }
                    return <AllNotifications history={props.history} />;
                }}
            />
            <Route
                path="/UserManagement"
                render={({ match: { url } }) => (
                    <Fragment>
                        <Route path={`${url}`} component={() => <UserManagement history={props.history} />} exact />
                        <Route path={`${url}/UserBuilder`} component={UserBuilder} />
                    </Fragment>
                )}
            />
            <Route
                path="/Templates"
                render={({ match: { url } }) => (
                    <Fragment>
                        <Route path={`${url}`} component={AssetScreenDesign} exact />
                        <Route path={`${url}/TemplateDetails`} component={TemplateContainer} />
                        <Route path={`${url}/WidgetBuilder`} component={WidgetBuilder} />
                    </Fragment>
                )}
            />
            <Route path='/PartnerLogin' component={PartnerLogin} />
            <Route
                path="/UserProfile"
                render={({ match: { url } }) => (
                    <Fragment>
                        <Route path={`${url}`} component={UserProfile} exact />
                    </Fragment>
                )}
            /> */}

        </Switch>
    )
}

const mapStateToProps = (state) => {
    return {
        getRefreshData: state.getRefreshData,
        loginData: state.login
    };
};

export default connect(mapStateToProps)(routes);