let instance = null;

export const getServiceByName = (name = '') => {
    // let services = localStorage.getItem('smartsense.member_service_permissions') !== 'undefined' && JSON.parse(localStorage.getItem('smartsense.member_service_permissions'));
    let services = localStorage.getItem('smartsense.services') !== 'undefined' && JSON.parse(localStorage.getItem('smartsense.services'));

    if (services === null || name.length === 0 || !name) return services;

    for (let i = 0; i < services.length; i++) {
        if (services[i].name === name) {

            return services[i];
        }
    }

    return false;
}


/**
 * ServicesPermission
 * responsible for handling services and permission related task
 * singleton class to avoid multiple cache reads
 */
class ServicesPermission {
    constructor() {
        // if (!instance) {
        //   instance = this;
        // }

        this.time = new Date();

        // this.services = localStorage.getItem('smartsense.member_service_permissions') && JSON.parse(localStorage.getItem('smartsense.member_service_permissions'));
        this.services = localStorage.getItem('smartsense.services') && JSON.parse(localStorage.getItem('smartsense.services'));



        return instance;
    }

    getServiceByName(name = '') {
        let services = localStorage.getItem('smartsense.services') && JSON.parse(localStorage.getItem('smartsense.services'))
        if (services === null || name.length === 0 || !name) return services;

        for (let i = 0; i < services.length; i++) {
            // if (services[i].service_name === name) {
            if (services[i].name === name) {
                return services[i];
            }
        }

        return false;
    }

    getServiceIdByName(name = '') {
        // let services = localStorage.getItem('smartsense.member_service_permissions') && JSON.parse(localStorage.getItem('smartsense.member_service_permissions'))
        let services = localStorage.getItem('smartsense.services') && JSON.parse(localStorage.getItem('smartsense.services'))
        if (name.length === 0 || !name) return 0;

        for (let i = 0; i < services.length; i++) {
            if (services[i].name === name) {

                return services[i].service_id;
            }
        }

        return 0;
    }


    static getInstance() {
        // if (!instance) {
        //   instance = new ServicesPermission();
        // }
        instance = new ServicesPermission();
        return instance;
    }
}

export default ServicesPermission;