import _tagFormulaConfInputGet from './_tagFormulaConfInputGet';

export default () => {
  return {
    alias: '',
    unit: '',
    showPartial: false,
    formula: [_tagFormulaConfInputGet()],
    equation: '',
    isTag: true,
    isComputed: true
  };
};
