import React from 'react';
import { memoize as _memoize } from 'lodash';
const DummyPiechart = React.lazy(() => import('./components/Widgets/DummyPiechart'));
const DummyPieChartConfiguration = import('./components/Widgets/DummyPiechart/configuration');
const Piechart = React.lazy(() => import('./components/Widgets/Piechart'));
const PieChartConfiguration = import('./components/Widgets/Piechart/configuration');
const Overview = React.lazy(() => import('./components/Widgets/Overview'));
const OverviewConfiguration = import('./components/Widgets/Overview/configuration');
const Dial = React.lazy(() => import('./components/Widgets/Dial'));
const DialConfiguration = import('./components/Widgets/Dial/configuration');
const Heatmap = React.lazy(() => import('./components/Widgets/Heatmap'));
const HeatmapConfig = import('./components/Widgets/Heatmap/configuration');
const AssetInsight = React.lazy(() => import('./components/Widgets/AssetInsight'));
const AssetInsightConfig = import('./components/Widgets/AssetInsight/configuration');
const RunHours = React.lazy(() => import('./components/Widgets/RunHours'));
const RunHoursConfig = import('./components/Widgets/RunHours/configuration');
const SensorHealth = React.lazy(() => import('./components/Widgets/SensorHealth'));
const SensorHealthConfig = import('./components/Widgets/SensorHealth/configuration');
const CycleDistribution = React.lazy(() => import('./components/Widgets/CycleDistribution'));
const CycleDistributionConfig = import('./components/Widgets/CycleDistribution/configuration');
const AlertAndAlarm = React.lazy(() => import('./components/Widgets/AlertAndAlarm'));
const AlertAndAlarmConfig = import('./components/Widgets/AlertAndAlarm/configuration');
const Digitbox = React.lazy(() => import('./components/Widgets/Digitbox'));
const DigitboxConfig = import('./components/Widgets/Digitbox/configuration');
const GISView = React.lazy(() => import('./components/Widgets/GISView'));
const ViewConfig = import('./components/Widgets/GISView/configuration');
const Graph = React.lazy(() => import('./components/Widgets/Graph'));
const GraphConfig = import('./components/Widgets/Graph/configuration');
const WorkplaceGraph = React.lazy(() => import('./components/Widgets/WorkplaceGraph'));
const WorkplaceGraphConfig = import('./components/Widgets/WorkplaceGraph/configuration');
const ParameterPerformance = React.lazy(() => import('./components/Widgets/ParameterPerformance'));
const ParameterPerformanceConfig = import('./components/Widgets/ParameterPerformance/configuration');
const Anomaly = React.lazy(() => import('./components/Widgets/Anomaly'));
const AnomalyConfig = import('./components/Widgets/Anomaly/configuration');
const Table = React.lazy(() => import('./components/Widgets/Table'));
const TableConfiguration = import('./components/Widgets/Table/configuration/');
const TreeBuilderView = React.lazy(() => import('./components/Widgets/TreeBuilder'));
const TreeBuilderConfig = import('./components/Widgets/TreeBuilder/configuration');
const ImageUploadView = React.lazy(() => import('./components/Widgets/ImageUpload'));
const ImageUploadConfig = import('./components/Widgets/ImageUpload/configuration/index.js');
const GroupWidget = React.lazy(() => import('./components/Widgets/GroupWidget'));
const GroupWidgetConfig = import('./components/Widgets/GroupWidget/configuration');
const ContainerWidget = React.lazy(() => import('./components/Widgets/ContainerWidget'));
const ContainerWidgetConfig = import('./components/Widgets/ContainerWidget/configuration');
const ListViewWidget = React.lazy(() => import('./components/Widgets/ListView'));
const ListViewWidgetConfig = import('./components/Widgets/ListView/configuration');
const AssetHistoryWidget = React.lazy(() => import('./components/Widgets/AssetHistory'));
const AssetHistoryConfig = import('./components/Widgets/AssetHistory/configuration');
const ParameterSummary = React.lazy(() => import('./components/Widgets/ParameterSummary'));
const ParameterSummaryConfig = import('./components/Widgets/ParameterSummary/configuration');
const InsightGraph = React.lazy(() => import('./components/Widgets/InsightGraph'));
const InsightGraphConfig = import('./components/Widgets/InsightGraph/configuration');
const SmartInfo = React.lazy(() => import('./components/Widgets/SmartInfo'));
const SmartInfoConfig = import('./components/Widgets/SmartInfo/configuration');
const Factory = React.lazy(() => import('./components/Widgets/Factory'))
const FactoryConfiguration = import('./components/Widgets/Factory/configuration')
const Insights = React.lazy(() => import('./components/Widgets/Insights'))
const InsightsConfiguration = import('./components/Widgets/Insights/configuration')
const Alerts = React.lazy(() => import('./components/Widgets/Alerts'))
const SectionConfiguration = import('./components/Widgets/Section/configuration')
const Section = React.lazy(() => import('./components/Widgets/Section'))
const AlertsConfiguration = import('./components/Widgets/Alerts/configuration')
const Wellness = React.lazy(() => import('./components/Widgets/Wellness'))
const WellnessConfiguration = import('./components/Widgets/Wellness/configuration')
const Energy = React.lazy(() => import('../Energy'));
const EnergyChartConfiguration = import('../Energy/configuration')
const Co2Emission = React.lazy(() => import('../Co2Emission'));
const GHGEmissionsConfiguration = import('./components/Widgets/GHGEmissions/configuration');
const EnergyConsumption = React.lazy(() =>import('./components/Widgets/EnergyConsumptionMix'));
const EnergyConsumptionConfiguration = import('./components/Widgets/EnergyConsumptionMix/configuration');
const WhatIf = React.lazy(() => import('../WhatIf'));
const WhatIfConfiguration = import('../WhatIf/configuration');
const LiveData = React.lazy(() => import('../LiveData'));
const LiveDataConfiguration = import('../LiveData/configuration');
const ReportDownload = React.lazy(() => import('../ReportDownload'));
const ReportDownloadConfiguration = import('../ReportDownload/configuration');
const Analyse = React.lazy(() => import('./components/Widgets/Analyse'));
const AnalyseConfig = import('./components/Widgets/Analyse/configuration');
const Workplace = React.lazy(() => import('./components/Widgets/WorkplaceOverview'));
const WorkplaceConfig = import('./components/Widgets/WorkplaceOverview/configuration');
const WeatherForecast = React.lazy(() => import('./components/Widgets/WeatherForecast'));
const WeatherForecastConfig = import('./components/Widgets/WeatherForecast/configuration');
// For facility
const Facility = React.lazy(() => import('./components/Widgets/Facility'))
const FacilityConfig = import('./components/Widgets/Facility/Configuration');
const UsecaseSummary = React.lazy(() =>
  import('./components/Widgets/UsecaseSummary')
);
const UsecaseSummaryConfiguration = import(
  './components/Widgets/UsecaseSummary/configuration'
);
const CxoSummaryConfig = import('./components/Widgets/CxoSummary/configuration');
const CxoSummary = React.lazy(()=>import("./components/Widgets/CxoSummary"))
import { DASHBOARD } from './../Main/permissionContants'

const AssetHealthCount = React.lazy(() =>
  import('./components/Widgets/AssetHealthCount')
);
const AssetHealthCountConfiguration = import(
  './components/Widgets/AssetHealthCount/configuration'
);
const AssetDetailList = React.lazy(() =>
  import('./components/Widgets/AssetDetailList')
);
const AssetDetailListConfiguration = import(
  './components/Widgets/AssetDetailList/configuration'
);
const AssetInsightAlert = React.lazy(() =>
  import('./components/Widgets/AssetInsightAlert')
);
const AssetInsightAlertConfiguration = import(
  './components/Widgets/AssetInsightAlert/configuration'
);
const AssetEventLog = React.lazy(() =>
  import('./components/Widgets/AssetEventLog')
);
const AssetEventLogConfiguration = import(
  './components/Widgets/AssetEventLog/configuration'
);

const _3DViewConfiguration = import('./components/Widgets/3DView/configuration');
const _3DView = React.lazy(() => import('./components/Widgets/3DView'))

// Project status imports
const ProjectStatus = React.lazy(() => import('./components/Widgets/ProjectStatus'));
const ProjectStatusConfiguration = import('./components/Widgets/ProjectStatus/Configuration');
	
//Network view imports
const NetworkView = React.lazy(()=>import('./components/Widgets/NetworkView'));
const NetworkViewConfig = import('./components/Widgets/NetworkView/configuration');

//ScoreCard imports
const ScoreCardConfig = import("./components/Widgets/Scorecard/configuration");
const ScoreCardView = React.lazy(()=>import("./components/Widgets/Scorecard"));

const KpiTrackerConfiguration = import(
  './components/Widgets/KpiTracker/configuration'
);
const KpiTracker = React.lazy(() => import('./components/Widgets/KpiTracker'));

const AssetTopContributorsConfiguration = import(
  './components/Widgets/AssetTopContributors/configuration'
);
const AssetTopContributors = React.lazy(() =>
  import('./components/Widgets/AssetTopContributors')
);

export const paramSelector = "parameterSelector";
export const readingtypeSelect = "readingtypeSelect";
export const basicLocationSelector = "basicLocationSelector";
export const locationSelector = "locationSelector";
export const filler = "filler";
export const formulaMaker = "formulaMaker";
export const genericConfig = 'genericConfig';
export const locationBasedParamSelector = "locationBasedParamSelector";
export const customCheckbox = "customCheckbox";
export const customRadio = "customRadio";
export const locationAndParamSelect = 'locationAndParamSelect';
export const sensorSelector = 'sensorSelector';
export const numericInput = 'numericInput';
export const groupWidgetSelect = 'groupWidgetSelect';
export const containerWidgetSelect = 'containerWidgetSelect';
export const assetSensorParam = 'assetSensorParam';
export const assetInsightParam = 'assetInsightParam';
export const textEditorConfig = 'textEditorConfig';
export const templateParam = 'Templates';

export const widgets = () => [
  {
    name: "Pie Chart",
    type: "Pie1",
    view: DummyPiechart,
    configuration: DummyPieChartConfiguration,
    minWidgetHeight: 2.28,
    widgetParams: [
      {
        name: "widget_content2",
        placeholder: "Enter Widget Content 2",
        value: '',
        type: 'text',
        label: 'Widget Content 2 FROM WIDGET BUILDER',
        required: true,
        width: 6,
        className: 'widget-content2',
      },
      {
        name: "widget_content",
        placeholder: "Enter Widget Content",
        value: '',
        type: 'text',
        label: 'Widget Content',
        required: true,
        className: 'widget-content',
        componentName: 'PieChartConfig'
      }]
  },
  {
    name: "Pie Chart New",
    type: "Pie",
    view: Piechart,
    configuration: PieChartConfiguration,
    className: 'pie-widget-config',
    minWidgetHeight: 2.28,
    maxWidgetHeight: 3,
    minWidgetWidth: 1.6,
    widgetParams: [
      {
        name: "locationBasedParamSelector",
        placeholder: "",
        value: '',
        // type: locationBasedParamSelector,
        label: 'Location-Parameter',
        required: true,
        width: 12,
        componentName: 'PieChartConfig'
      },
    ]
  },
  {
    name: 'Overview',
    type: 'Overview',
    view: Overview,
    minWidgetWidth: 12,
    minWidgetHeight: 2.28,
    maxWidgetWidth: 12,
    maxWidgetHeight: 4,
    configuration: OverviewConfiguration,
    widgetParams: [
      {
        name: 'locParam',
        label: 'Overview Config',
        width: 12,
        required: true,
        componentName: 'OverviewConfiguration'
      }
    ]
  },
  {
    name: "Digitbox",
    type: "Digitbox",
    view: Digitbox,
    configuration: DigitboxConfig,
    className: 'digitboxWidget',
    minWidgetHeight: 2.28,
    widgetParams: [
      {
        name: "dateandtime",
        value: "<div><span><i className='far fa-calendar-alt'></i></span>Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header'
      },
      {
        name: "dateAndTimeGuide",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br /><b>formula = (Start - End) * Frequency.</b><br />Selecting 0 is equivalent of selecting current day/week/month.<br />Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week.</div>'
      },
      {
        name: "period",
        placeholder: "Select Period",
        type: 'select',
        value: 'Day',
        isDefaultInvalid: false,
        options: ["Day", "Week", "Month"],
        label: 'Period',
        required: false,
        hideEmptyOption: true,
        width: 6,
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        type: 'number',
        label: 'From',
        required: false,
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        type: 'number',
        label: 'To',
        required: false,
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        required: false,
        label: 'End Time',
        width: 6,
      },
      {
        name: "dbtypeheader",
        value: '<div>Digitbox Type Configuration<div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "digitboxConfig",
        width: 12,
        componentName: 'DigitboxConfig',
        label: "Digitbox config",
        required: true
      },
    ]
  },
  {
    name: "Anomaly Detection",
    type: "Anomaly Detection",
    view: Anomaly,
    configuration: AnomalyConfig,
    minWidgetWidth: 12,
    minWidgetHeight: 2.28,
    maxWidgetWidth: 12,
    maxWidgetHeight: 4,
    widgetParams: [
      {
        name: '',
        label: 'Parameter',
        width: 12,
        required: true,
        isDefaultInvalid: false,
        componentName: 'AnomalyConfig',
			},
      {
        name: "sensor",
        required: false,
        label: 'Sensor',
      },
      {
        name: "parameter",
        required: false,
        label: 'Parameter',
      },
      {
        name: "",
        value: '<div><div className="hr"></div></div>',
        required: false,
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "",
        value: '<div><div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "showParameters",
        label: 'Should Show Parameters',
        value: true
      },
      {
        name: "",
        value: '<div><div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "showPositive",
        label: 'Should Show Positive',
        value: true
      },
      {
        name: "",
        value: '<div><div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "showNegative",
        label: 'Should Show Negative',
        value: true
      },
      {
        name: "",
        value: '<div><div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "showForecast",
        label: 'Should Show Forecast',
        value: true
      },
      {
        name: "",
        value: '<div><div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "showPopup",
        label: 'Should Show Popup',
        value: true
      },
    ]
  },
  {
    name: "Widget 360",
    type: "Asset360",
    view: Overview,
    configuration: OverviewConfiguration,
    minWidgetHeight: 2.28,
    widgetParams: [
      {
        name: "widget_content2",
        placeholder: "Enter Widget Content 2",
        value: '',
        type: 'text',
        label: 'Widget Content 2 FROM WIDGET BUILDER',
        required: true,
        width: 6,
        className: 'widget-content2',
      },
    ]
  },
  {
    name: "HeatMap",
    type: "Heatmap",
    view: Heatmap,
    configuration: HeatmapConfig,
    minWidgetWidth: 12,
    minWidgetHeight: 2.28,
    maxWidgetWidth: 12,
    maxWidgetHeight: 4,
    className: "heatmapWidget",
    widgetParams: [
      {
        name: "dateandtimeLabel",
        value: "<div><span><i className='far fa-calendar-alt'></i></span>Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "filler_date_note",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br/><b>formula = (Start - End) * Frequency.</b><br/>Selecting 0 is equivalent of selecting current day/week/month.<br/>Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week."</div>'
      },
      {
        name: "period",
        placeholder: "Select Period",
        type: 'select',
        options: ["Day", "Week", "Month"],
        label: 'Period',
        required: true,
        width: 6,
        value: 'Day',
        isDefaultInvalid: false
      },
      {
        name: "filler1",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: 0,
        type: 'number',
        label: 'From',
        required: true,
        width: 6,
        isDefaultInvalid: false
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: 0,
        type: 'number',
        label: 'To',
        required: true,
        isDefaultInvalid: false,
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '00:00',
        type: 'time',
        label: 'Start Time',
        required: true,
        width: 6,
        isDefaultInvalid: false
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '23:59',
        type: 'time',
        label: 'End Time',
        required: true,
        width: 6,
        isDefaultInvalid: false
      },
      {
        name: "graphFilters",
        width: 12,
        componentName: 'HeatmapConfig'
      },
      {
        name: "dataFrequency",
        placeholder: "Data Frequency",
        value: '15 min',
        isDefaultInvalid: false,
        type: 'select',
        label: 'Data Frequency',
        required: true,
        width: 6,
        options: ["15 min", "30 min"]
      },
      {
        name: "filler2",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "locParam",
        label: ['Location', 'Parameter'],
        value: '',
        type: locationAndParamSelect,
        required: true,
      },
    ]
  },
  {
    name: "Run Hours",
    type: "OnOff",
    view: RunHours,
    configuration: RunHoursConfig,
    className: 'runhours',
    minWidgetHeight: 2.28,
    widgetParams: [
      {
        name: "dateandtime",
        // value: '<div>Date &amp; Time<div className="hr"></div></div>',
        value: "<div><span><i className='far fa-calendar-alt'></i></span>Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "dateAndTimeGuide",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br /><b>formula = (Start - End) * Frequency.</b><br />Selecting 0 is equivalent of selecting current day/week/month.<br />Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week.</div>'
      },
      {
        name: "period",
        placeholder: "Select Period",
        type: 'select',
        value: 'Day',
        isDefaultInvalid: false,
        options: ["Day", "Week", "Month"],
        label: 'Period',
        required: false,
        hideEmptyOption: true,
        width: 6,
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        type: 'number',
        label: 'From',
        required: false,
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        type: 'number',
        label: 'To',
        required: false,
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        required: false,
        label: 'End Time',
        width: 6,
      },
      {
        name: "dataFrequency",
        placeholder: "Data Frequency",
        value: '15 min',
        isDefaultInvalid: false,
        type: 'select',
        label: 'Data Frequency',
        required: true,
        width: 6,
        options: ["Unaggregated", "15 min", "30 min", "60 min", "1 Day"]
      },
      {
        name: "filler2",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "runhourConfig",
        width: 12,
        componentName: 'RunHoursConfig'
      },
    ]
  },
  {
    name: "Sensor Health",
    type: "Health",
    view: SensorHealth,
    configuration: SensorHealthConfig,
    minWidgetWidth: 4,
    minWidgetHeight: 2.28,
    maxWidgetWidth: 4,
    maxWidgetHeight: 2.28,
    widgetParams: [
      {
        name: "sensorHealthFiller",
        value: '<div>Enable Sensor Health - SMS/Email:<div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "sensorHealthConfig",
        width: 12,
        required: false,
				isDefaultInvalid: false,
        componentName: 'SensorHealthConfig'
      },
			{
				name: 'sensorHealthConfigExtended',
				width: 12,
				required: true,
				isDefaultInvalid: false,
				componentName: 'SensorHealthConfigExtended',
			}
    ]
  },
  {
    name: "Graph",
    type: "Graph",
    view: Graph,
    configuration: GraphConfig,
    className: 'graph',
    minWidgetHeight: 2.28,
    minWidgetWidth: 2.4,
    maxWidgetWidth: 12,
    widgetParams: [
      {
        name: "graphConfig",
        label: 'Graph Config',
        width: 12,
        required: true,
        componentName: 'GraphConfig'
      },
    ]
  },
  {
    name: "Workplace Graph",
    type: "Workplace Graph",
    view: WorkplaceGraph,
    configuration: WorkplaceGraphConfig,
    className: 'graph',
    minWidgetHeight: 3,
    minWidgetWidth: 6,
    widgetParams: [
      {
        name: "dateandtime",
        // value: '<div>Date &amp; Time<div className="hr"></div></div>',
        value: "<div><span><i className='far fa-calendar-alt'></i></span>Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "dateAndTimeGuide",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br /><b>formula = (Start - End) * Frequency.</b><br />Selecting 0 is equivalent of selecting current day/week/month.<br />Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week.</div>'
      },
      {
        name: "period",
        placeholder: "Select Period",
        type: 'select',
        value: 'Day',
        isDefaultInvalid: false,
        options: ["Day", "Week", "Month"],
        label: 'Period',
        required: true,
        width: 6,
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        type: 'number',
        label: 'From',
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        type: 'number',
        label: 'To',
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        required: false,
        label: 'End Time',
        width: 6,
      },
      {
        name: "dataFrequency",
        placeholder: "Data Frequency",
        value: '15 min',
        isDefaultInvalid: false,
        type: 'select',
        label: 'Data Frequency',
        required: true,
        width: 6,
        options: ["Unaggregated", "15 min", "30 min", "60 min", "1 Day"]
      },
      {
        name: "filler2",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "graphConfig",
        width: 12,
        componentName: 'WorkplaceGraphConfig',
				required: true,
				label: 'graphConfig'
      },
    ]
  },
  // {
  //   name: "GISView",
  //   type: "GIS View",
  //   view: Overview,
  //   configuration: OverviewConfiguration,
  //   widgetParams: [
  //     {
  //       name: "widget_content2",
  //       placeholder: "Enter Widget Content 2",
  //       value: '',
  //       type: 'text',
  //       label: 'Widget Content 2 FROM WIDGET BUILDER',
  //       required: true,
  //       width: 6,
  //       className: 'widget-content2',
  //     },
  //   ]
  // },
  {
    name: "Dial",
    type: "Dial",
    view: Dial,
    configuration: DialConfiguration,
    className: "dialWidget",
    minWidgetHeight: 2.28,
    minWidgetWidth: 1.6,
    widgetParams: [
      {
        name: "dateandtime",
        // value: '<div>Date &amp; Time<div className="hr"></div></div>',
        value: "<div><span><i className='far fa-calendar-alt'></i></span>Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "dateTimeGuidefiller",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br/><b>formula = (Start - End) * Frequency.</b><br/>Selecting 0 is equivalent of selecting current day/week/month.<br/>Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week."</div>'
      },
      {
        name: "period",
        placeholder: "Select Period",
        type: 'select',
        value: 'Day',
        options: ["Day", "Week", "Month"],
        label: 'Period',
        required: false,
        width: 6,
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        type: 'number',
        label: 'From',
        required: false,
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        type: 'number',
        label: 'To',
        required: false,
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'End Time',
        required: false,
        width: 6,
      },
      {
        name: 'dialOptionFiller',
        value: '<div>Dial Option <div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "lowerLimit",
        placeholder: "Lower limit",
        value: '',
        type: 'number',
        label: 'Lower Limit',
        required: true,
        width: 6,
      },
      {
        name: "upperLimit",
        placeholder: "Upper Limit",
        value: '',
        type: 'number',
        label: 'Upper Limit',
        required: true,
        width: 6,
      },
      {
        name: "dialType",
        placeholder: "Dial Type",
        value: 'Solid Gauge',
        isDefaultInvalid: false,
        type: 'select',
        options: ["Solid Gauge", "Speedometer"],
        label: 'Dial Type',
        required: true,
        width: 6,
      },
      {
        name: "dataFrequency",
        placeholder: "Data Frequency",
        value: '15 min',
        isDefaultInvalid: false,
        type: 'select',
        label: 'Data Frequency',
        required: false,
        width: 6,
        options: ["Unaggregated", "15 min", "30 min", "60 min", "1 Day"],
        isTemplateCbm: templateParam
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        value: '<div>Threshold<div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "threshold",
        value: '',
        type: 'text',
        label: 'Threshold',
        required: true,
        width: 12,
        componentName: 'Threshold'
      },
      {
        name: "paramOption",
        value: '<div>Parameter Options<div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "parameterRadio",
        placeholder: "Parameters",
        type: customRadio,
        value: "Aggregated",
        label: ['Aggregated', "Live"],
        width: 12,
      },
      {
        value: '<div><div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "unit",
        placeholder: "Unit",
        value: '',
        type: 'text',
        label: 'Unit',
        required: true,
        width: 6,
      },
      {
        name: "filler",
				value: '',
        type: filler,
        width: 12,
      },
      {
        name: "formula",
        value: '',
        type: 'genericConfig',
        required: true,
        width: 12,
      },
    ]
  },
  {
    name: "Asset Insight",
    type: "Insight",
    view: AssetInsight,
    configuration: AssetInsightConfig,
    minWidgetHeight: 2.28,
    widgetParams: [
      {
        name: "assetType",
        value: 'Motor Insight',
        isDefaultInvalid: false,
        type: 'select',
        label: 'Asset Type',
        required: true,
        width: 6,
        options: ['Motor Insight', 'Transformer Insight', 'Chiller Insight'],
      },
    ]
  },
  {
    name: "Alerts And Alarms",
    type: "AlertAlarm",
    view: AlertAndAlarm,
    configuration: AlertAndAlarmConfig,
    minWidgetHeight: 2.28,
    widgetParams: [
      {
        name: "alertAlarmConfig",
        width: 12,
        componentName: 'AlertAndAlarmConfig'
      },
    ]
  },
  {
    name: "Cycle Distribution",
    type: "Cycle Distribution",
    view: CycleDistribution,
    configuration: CycleDistributionConfig,
    className: 'cycleDistributionWidget',
    minWidgetWidth: 12,
    minWidgetHeight: 2.28,
    maxWidgetWidth: 12,
    maxWidgetHeight: 4,
    widgetParams: [
      {
        name: "dateandtime",
        // value: '<div>Date &amp; Time<div className="hr"></div></div>',
        value: "<div><span><i className='far fa-calendar-alt'></i></span>Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "filler-1",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br/><b>formula = (Start - End) * Frequency.</b><br/>Selecting 0 is equivalent of selecting current day/week/month.<br/>Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week."</div>'
      },
      {
        name: "period",
        placeholder: "Select Period",
        type: 'select',
        value: 'Day',
        isDefaultInvalid: false,
        options: ["Day", "Week", "Month"],
        label: 'Period',
        required: false,
        width: 6,
      },
      {
        name: "filler-2",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        isDefaultInvalid: false,
        type: 'number',
        label: 'From',
        required: false,
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        isDefaultInvalid: false,
        type: 'number',
        label: 'To',
        required: false,
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'End Time',
        required: false,
        width: 6,
      },
      {
        value: '<div>Parameter<div className="hr"></div></div>',
        type: filler,
        name: 'filler-3',
        width: 12,
        className: 'header',
      },
      {
        name: "showSummary",
        placeholder: "Show Summary",
        type: customCheckbox,
        label: 'Show Summary',
        width: 12,
      },
      {
        name: "locParam",
        label: ['Location', 'Parameter'],
        value: '',
        type: locationAndParamSelect,
        required: true,
      },
      {
        name: "totalConsumption",
        placeholder: "Select Consumption",
        value: '',
        isDefaultInvalid: true,
        type: 'select',
        options: ["KWh", "None"],
        label: 'Total consumption',
        required: true,
        width: 6,
      },
      {
        name: "threshold",
        placeholder: "Enter Threshold",
        value: '0',
        isDefaultInvalid: false,
        type: 'number',
        label: 'Threshold',
        required: true,
        width: 6,
      },
    ]
  },
  {
    name: "Parameter Performance",
    type: "Parameter Performance",
    view: ParameterPerformance,
    configuration: ParameterPerformanceConfig,
    className: 'parameter-performance',
    minWidgetWidth: 12,
    minWidgetHeight: 2.28,
    maxWidgetWidth: 12,
    maxWidgetHeight: 4,
    widgetParams: [
      {
        name: "liveDataTP",
        value: '<div>Live Data Time Period<div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "live_timeperiod",
        placeholder: "Enter time period",
        value: 10,
        type: numericInput,
        label: 'Time period (mins)',
        hint: '(Sensor will be shown as "Inactive" after above configured value)',
        required: true,
        isDefaultInvalid: false,
        width: 12,
        className: 'widget-content2',
      },
      {
        name: "plantConfigFiller",
        value: '<div>Plant Configuration<div className="hr"></div></div>',
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "parameters",
        required: true,
        isDefaultInvalid: true,
        label: 'Plant Config',
        width: 12,
        componentName: 'PlantConfig'
      },
    ]
  },
  {
    name: "GIS View",
    type: "GIS Plant",
    view: GISView,
    configuration: ViewConfig,
    className: 'gis-view',
    minWidgetWidth: 12,
    minWidgetHeight: 3.5,
    widgetParams: [
      {
        name: "showAlias",
        type: customCheckbox,
        value: true,
        label: 'Should Show Alias',
        width: 3,
        className: 'widget-content2',
      },
      {
        name: "showArrow",
        type: customCheckbox,
        value: true,
        label: 'Should Show Arrow',
        width: 3,
        className: 'widget-content2',
      },
      {
        name: "dateandtime",
        // value: '<div>Date &amp; Time<div className="hr"></div></div>',
        value: "<div><span><i className='far fa-calendar-alt'></i></span>Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "dateAndTimeGuide",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br /><b>formula = (Start - End) * Frequency.</b><br />Selecting 0 is equivalent of selecting current day/week/month.<br />Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week.</div>'
      },
      {
        name: "period",
        placeholder: "Select Period",
        type: 'select',
        value: 'Day',
        isDefaultInvalid: false,
        options: ["Day", "Week", "last 1 month","last 3 months", "last 6 months", "last 9 months","Year"],
        label: 'Period',
        required: true,
        width: 6,
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        type: 'number',
        label: 'From',
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        type: 'number',
        label: 'To',
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        required: false,
        label: 'End Time',
        width: 6,
      },
      {
        name: "dataFrequency",
        placeholder: "Data Frequency",
        value: '15 min',
        isDefaultInvalid: false,
        type: 'select',
        label: 'Data Frequency',
        required: true,
        width: 6,
        options: ["Unaggregated", "15 min", "30 min", "60 min", "1 day", "1 month"]
      },
      {
        name: "filler2",
        value: '',
        type: filler,
        width: 6,
      },
      //{
        //name: "live_timeperiod",
        //value: 40,
        //componentName: 'LiveTimePeriod'
      //},
      {
        name: "parameters",
        width: 12,
        componentName: 'ViewConfig',
        required: true,
        label: 'parameters'
      },
      {
        name: 'live_timeperiod',
        value: 0
      }
    ],
    widgetActions: DEFAULT_WIDGET_ACTIONS.filter((action) => action.key !== 'download')
  },
  {
    name: "Table",
    type: "Table",
    view: Table,
    configuration: TableConfiguration,
    className: 'table-widget',
    minWidgetWidth: 4,
    minWidgetHeight: 2.28,
    widgetParams: [
      {
        name: "table_widget_params",
        type: 'text',
        label: 'table-widget-params',
        required: true,
        className: 'table-widget-params',
        componentName: 'TableConfig'
      }
    ]
  },
  {
    name: "Tree Builder",
    type: "TreeBuilder",
    view: TreeBuilderView,
    configuration: TreeBuilderConfig,
    className: 'tree-builder-widget',
    minWidgetWidth: 12,
    minWidgetHeight: 2.28,
    widgetParams: [
      {
        name: "tree",
        label: 'Select Tree',
        required: true,
        className: 'tree',
        componentName: 'TreeBuilderConfig'
      }
    ]
  },
  {
    name: "Image Upload",
    type: "ImageUpload",
    view: ImageUploadView,
    configuration: ImageUploadConfig,
    minWidgetHeight: 2.28,
    maxWidgetHeight: 4.56,
    widgetParams: [
      {
        name: "datatype",
        value: '',
        label: 'Data Type',
        required: false,
        componentName: 'ImageUploadDataType'
      },
      {
        name: "url",
        value: '',
        label: 'Widget Content',
        required: false,
        componentName: 'UploadImageConfig'
      },
      {
        name: "parameter",
        value: '',
        label: 'Widget Content',
        required: false,
        componentName: 'ImageUploadParamConfig'
      }
    ]
  },
  {
    name: "Group Widget",
    type: "Group",
    view: GroupWidget,
    configuration: GroupWidgetConfig,
    minWidgetWidth: 12,
    minWidgetHeight: 4.28,
    widgetParams: [
      {
        name: "gropu_widget_select",
        value: [],
        type: groupWidgetSelect,
        label: 'Select Widget',
        required: false,
        limit: 5,
        width: 12,
        className: 'widget-content2',
      }
    ]
  },
  {
    name: "Container Widget",
    type: "Container",
    view: ContainerWidget,
    configuration: ContainerWidgetConfig,
    minWidgetWidth: 12,
    minWidgetHeight: 5.28,
    widgetParams: [
      {
        name: "container_widget_select",
        value: [],
        type: containerWidgetSelect,
        label: 'Select Widget',
        required: false,
        limit: 5,
        width: 12,
        className: 'widget-content2',
      }
    ]
  },
  {
    name: "List Widget",
    type: "List View",
    view: ListViewWidget,
    configuration: ListViewWidgetConfig,
    minWidgetWidth: 3,
    maxWidgetWidth: 4,
    minWidgetHeight: 3,
    maxWidgetHeight: 3.7,
    // minWidgetHeight: 3,
    // maxWidgetHeight: 3,
    widgetParams: [
      {
        name: 'filterType',
        value: '',
        isDefaultInvalid: false,
        label: 'Filter Type',
        required: true,
        componentName: 'FilterTypeConfig'
      },
      {
        name: 'url',
        value: { filterType: 0 },
        isDefaultInvalid: false,
        required: true,
        label: 'Custom Image',
        componentName: 'ImageUploadConfig'
      },
      {
        name: "show_asset_image",
        type: customCheckbox,
        label: 'Should Show Image',
        width: 12,
        className: 'widget-content2',
      },
      {
        name: 'manualMetaParameter',
        value: true,
        isDefaultInvalid: false,
        label: 'Manual Meta Parameter',
        required: true,
        componentName: 'MetaParameterTypeConfig'
      },
      {
        name: 'assetSensorParam',
				value: [],
        isDefaultInvalid: false,
				label: 'Meta Params',
        required: false,
        componentName: 'AssetMetaParameterConfig'
      }
    ]
  },
  {
    name: "Asset History",
    type: "Asset History",
    view: AssetHistoryWidget,
    configuration: AssetHistoryConfig,
    minWidgetWidth: 12,
    maxWidgetWidth: 12,
    minWidgetHeight: 4.5,
    maxWidgetHeight: 4.5,
    widgetParams: [
      {
        name: "asset_history_widget_conf",
        value: [],
        label: 'Select Widget',
        required: false,
        className: 'widget-content2',
        componentName: 'AssetHistoryConfig'
      },
      {
        name: "show_asset_history",
        type: customCheckbox,
        value: true,
        label: 'Show Asset History',
        width: 6,
        className: 'widget-content2'
      },
      {
        name: "show_network_history",
        type: customCheckbox,
        value: false,
        label: 'Show Network History',
        width: 6,
        className: 'widget-content2'
      }
    ]
  },
  {
    name: "Parameter Summary",
    type: "Parameter Summary",
    view: ParameterSummary,
    configuration: ParameterSummaryConfig,
    minWidgetHeight: 2.28,
    widgetParams: [
      {
        name: "parameter_summary_conf",
        value: [],
        label: 'Select Widget',
        required: true,
        className: 'widget-content2',
        componentName: 'ParameterSummaryConfig'
      }
    ]
  },
  {
    name: "Insight Graph",
    type: "Insight Graph",
    view: InsightGraph,
    configuration: InsightGraphConfig,
    maxWidgetWidth: 12,
    minWidgetWidth: 12,
    minWidgetHeight: 4,
    minWidgetHeight: 4,
    widgetParams: [
      {
        name: "assetInsightParams",
        label: 'Select Asset Insight Paramameter',
        value: [],
        type: assetInsightParam,
        // required: true,
      },
    ]
  },
  {
    name: "Smart Info",
    type: "Smart Info",
    view: SmartInfo,
    configuration: SmartInfoConfig,
    minWidgetHeight: 1.4,
    widgetParams: [
      {
        name: "textEditor",
        label: 'Create View Using Text Editor',
        value: '',
        type: textEditorConfig,
      },
    ]
  },
  {
    name: "Energy",
    type: "Energy",
    view: Energy,
    configuration: EnergyChartConfiguration,
    className: 'Energy',
    // minWidgetHeight: 2.7,
    // maxWidgetHeight: 2.7,
    // minWidgetWidth: 2.7,
    // maxWidgetWidth: 2.7,
    minWidgetHeight: 3.2,
    maxWidgetHeight: 8,
    minWidgetWidth: 3.2,
    //maxWidgetWidth: 3.2,
    widgetParams: [
      {
        name: "locationBasedParamSelector",
        placeholder: "",
        value: '',
        // type: locationBasedParamSelector,
        label: 'Location-Parameter',
        required: true,
        width: 12,
        componentName: 'EnergyChartConfig'
      },

    ]
  },
  {
    name: "GHG Emissions",
    type: "GHG Emissions",
    view: Co2Emission,
    configuration: GHGEmissionsConfiguration,
    className: 'pie-widget-config',
    minWidgetHeight: 3.2,
    maxWidgetHeight: 8,
    minWidgetWidth: 3.2,
    // maxWidgetWidth: 3.2,
    widgetParams: [
      {
        name: "locationBasedParamSelector",
        placeholder: "",
        value: '',
        // type: locationBasedParamSelector,
        label: 'Location-Parameter',
        required: true,
        width: 12,
        componentName: 'GHGEmissionsConfig'
      },
    ]
  },
  {
    name: "Energy Consumption Mix",
    type: "Energy Consumption Mix",
    view: EnergyConsumption,
    configuration: EnergyConsumptionConfiguration,
    className: 'pie-widget-config',
    minWidgetHeight: 3.2,
    minWidgetWidth: 3,
    // maxWidgetWidth: 3,
    widgetParams: [
      {
        name: "",
        placeholder: "",
        value: '',
        // type: locationBasedParamSelector,
        label: 'Location-Parameter',
        required: true,
        width: 12,
        componentName: 'EnergyConsumptionConfig'
      },
      {
        name: "Widget level Notify",
        value: "<div><b> Widget Level Notify</b><div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "progress",
        placeholder: "Widget progress",
        value: 'Yes',
        isDefaultInvalid: false,
        type: 'select',
        options: ["Yes", "No"],
        defaultValue: "Yes",
        label: 'Widget level progress',
        required: false,
        hideEmptyOption: true,
        width: 6,
      },
      {
        name: "notification",
        placeholder: "widget notification",
        value: 'Yes',
        isDefaultInvalid: false,
        type: 'select',
        options: ["Yes", "No"],
        label: 'widget level notification',
        defaultValue: "Yes",
        required: false,
        hideEmptyOption: true,
        width: 6,
      }
    ]
  },
  {
    name: "What-If Energy",
    type: "What-If Energy",
    view: WhatIf,
    configuration: WhatIfConfiguration,
    className: 'WhatIf',
    minWidgetWidth: 6,
    minWidgetHeight: 4,
    maxWidgetHeight: 4,
    widgetParams: [
      {
        name: "currency",
        placeholder: "Select Currency type",
        type: 'select',
        value: '$',
        isDefaultInvalid: false,
        options: ['₹',
          'AED',
          'Rp',
          'MYR',
          'S$',
          '₫',
          '$',
          '€',
          "£",
          'A$'],
        label: 'Currency Type',
        required: false,
        hideEmptyOption: true,
        width: 3,
      },
      {
        name: "locationBasedParamSelector",
        placeholder: "",
        value: '',
        label: 'Location-Parameter',
        required: true,
        width: 12,
        componentName: 'WhatIfConfiguration'
      },
    ]
  },
  {
    name: "Factory",
    type: "Factories",
    view: Factory,
    configuration: FactoryConfiguration,
    className: 'pie-widget-config',
    minWidgetHeight: 4,
    maxWidgetHeight: 4,
    minWidgetWidth: 6,
    maxWidgetWidth: 6,
    widgetParams: [
      {
        name: "locationBasedParamSelector",
        placeholder: "",
        value: '',
        // type: locationBasedParamSelector,
        label: 'Location-Parameter',
        required: false,
        width: 12,
        componentName: 'FactoryConfiguration'
      },
			{
				name: 'showDetailSummary',
				value: true
			},
    ]
  },
  {
    name: "Parameter Tracking",
    type: "Parameter Tracking",
    view: Wellness,
    configuration: WellnessConfiguration,
    className: 'wellness-widget-config',
    minWidgetHeight: 3,
    maxWidgetHeight: 3,
    minWidgetWidth: 2.3,
    maxWidgetWidth: 3,
    widgetParams: [
      {
        name: "wellnessConfig",
        placeholder: "",
        value: '',
        label: 'Wellness-Configuration',
        required: false,
        width: 12,
        componentName: 'WellnessConfiguration'
      },
    ]
  },
  {
    name: "Live Data",
    type: "Live Data",
    view: LiveData,
    configuration: LiveDataConfiguration,
    className: 'Live Data',
    minWidgetWidth: 5,
    minWidgetHeight: 3,
    maxWidgetHeight: 3.7,
    widgetParams: [
      {
        name: "dateandtime",
        value: "<div><span><i className='far fa-calendar-alt'></i></span>Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header'
      },
      {
        name: "dateAndTimeGuide",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br /><b>formula = (Start - End) * Frequency.</b><br />Selecting 0 is equivalent of selecting current day/week/month.<br />Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week.</div>'
      },
      {
        name: "period",
        placeholder: "Select Period",
        type: 'select',
        value: 'Day',
        isDefaultInvalid: false,
        options: ["Day", "Week", "Month"],
        label: 'Period',
        required: false,
        hideEmptyOption: true,
        width: 6,
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        type: 'number',
        label: 'From',
        required: false,
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        type: 'number',
        label: 'To',
        required: false,
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        required: false,
        label: 'End Time',
        width: 6,
      },
      {
        name: "locationBasedParamSelector",
        placeholder: "",
        value: '',
        label: 'Location-Parameter',
        required: true,
        width: 12,
        componentName: 'LiveDataConfiguration'
      },
    ]
  },
  {
    name: "Insight-alert-summary",
    type: "Insight-alert-summary",
    view: Insights,
    configuration: InsightsConfiguration,
    className: 'insights-config',
    minWidgetHeight: 3.7,
    maxWidgetHeight: 3.7,
    minWidgetWidth: 4,
    maxWidgetWidth: 4,
    widgetParams: [
      {
        name: "Insight-alert-summary",
        placeholder: "",
        value: '',
        label: 'Insights-Config',
        required: false,
        width: 12,
        componentName: 'InsightsConfiguration'
      },
      {
        name: "dateandtime",
        value: "<div><span><i className='far fa-calendar-alt'></i></span>Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header'
      },
      {
        name: "dateAndTimeGuide",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br /><b>formula = (Start - End) * Frequency.</b><br />Selecting 0 is equivalent of selecting current day/week/month.<br />Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week.</div>'
      },
      {
        name: "period",
        placeholder: "Select Period",
        type: 'select',
        value: 'Day',
        isDefaultInvalid: false,
        options: ["Day", "Week", "Month"],
        label: 'Period',
        required: false,
        hideEmptyOption: true,
        width: 6,
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        type: 'number',
        label: 'From',
        required: false,
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        type: 'number',
        label: 'To',
        required: false,
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        required: false,
        label: 'End Time',
        width: 6,
      },
    ]
  },
  {
    name: "Alert List",
		type: "Alert List",
    view: Alerts,
    configuration: AlertsConfiguration,
    className: 'alerts-config',
    minWidgetHeight: 6,
    maxWidgetHeight: 6,
    minWidgetWidth: 12,
    maxWidgetWidth: 12,
    widgetParams: [
      {
        name: "Alerts-Widget",
        placeholder: "",
        value: '',
        label: 'Alerts-Config',
        required: false,
        width: 12,
        componentName: 'AlertsConfiguration'
      },
      {
        name: "dateandtime",
        value: "<div><span><i className='far fa-calendar-alt'></i></span>Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header'
      },
      {
        name: "dateAndTimeGuide",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br /><b>formula = (Start - End) * Frequency.</b><br />Selecting 0 is equivalent of selecting current day/week/month.<br />Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week.</div>'
      },
      {
        name: "period",
        placeholder: "Select Period",
        type: 'select',
        value: 'Day',
        isDefaultInvalid: false,
        options: ["Day", "Week", "Month"],
        label: 'Period',
        required: false,
        hideEmptyOption: true,
        width: 6,
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        type: 'number',
        label: 'From',
        required: false,
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        type: 'number',
        label: 'To',
        required: false,
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        required: false,
        label: 'End Time',
        width: 6,
      }
    ]
  },
  {
    name: "KPI Tracker",
    type: "KPI Tracker",
    view: KpiTracker,
    configuration: KpiTrackerConfiguration,
    className: 'kpi-tracker-config',
    minWidgetHeight: 1.05,
    maxWidgetHeight: 1.05,
    minWidgetWidth: 4,
    maxWidgetWidth: 4,
    widgetParams: [
      {
        name: "dontShowInfoIcon",
        placeholder: "Do Not Show Info Icon",
        type: customCheckbox,
        label: 'Do not show info icon',
        width: 6,
        value: false
      },
      {
        name: 'kpiTrackerConfig',
        placeholder: '',
        value: '',
        label: 'KPI Tracker Configuration',
        required: true,
        width: 12,
        componentName: 'KpiTrackerConfiguration'
      }
    ]
  },
  {
    name: "Use Case Summary",
    type: "Usecase Summary",
    view: UsecaseSummary,
    configuration: UsecaseSummaryConfiguration,
    className: 'section-config',
    minWidgetHeight: 3.5,
    maxWidgetHeight: 3.5,
    minWidgetWidth: 12,
    maxWidgetWidth: 12,
    widgetParams: [
      {
        name: "dateandtime",
        value: "<div><span><i className='far fa-calendar-alt'></i></span> Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "dateAndTimeGuide",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br /><b>formula = (Start - End) * Frequency.</b><br />Selecting 0 is equivalent of selecting current day/week/month.<br />Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week.</div>'
      },
      {
        name: "period",
        placeholder: "Period",
        value: 'Day',
        isDefaultInvalid: false,
        type: 'select',
        options: ["Day", "Week", "Month"],
        label: 'Period',
        required: false,
        hideEmptyOption: true,
        width: 6,
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        isDefaultInvalid: false,
        type: 'number',
        label: 'From',
        required: false,
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        isDefaultInvalid: false,
        type: 'number',
        label: 'To',
        required: false,
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '00:00',
        isDefaultInvalid: false,
        type: 'time',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '23:59',
        isDefaultInvalid: false,
        type: 'time',
        label: 'End Time',
        required: false,
        width: 6,
      },
      {
        name: 'usecaseSummaryConfig',
        placeholder: '',
        value: '',
        label: 'Usecase Summary',
        required: false,
        width: 12,
        componentName: 'UsecaseSummaryConfiguration'
      },
      {
        name: 'usecaseSummaryConfig',
				value: {
					enableUsecaseSummary: true,
					plantCollection: null,
					currency: null
				},
				required: true,
				label: 'Plant, Currency'
      }
		]
  },  
  {
    name: "Report Download",
    type: "Report Download",
    view: ReportDownload,
    configuration: ReportDownloadConfiguration,
    className: 'ReportDownload',
    minWidgetWidth: 7,
    minWidgetHeight: 4.2,
    widgetParams: [
      {
        name: "dateandtime",
        value: "<div><span><i className='far fa-calendar-alt'></i></span>Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header'
      },
      {
        name: "dateAndTimeGuide",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br /><b>formula = (Start - End) * Frequency.</b><br />Selecting 0 is equivalent of selecting current day/week/month.<br />Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week.</div>'
      },
      {
        name: "period",
        placeholder: "Select Period",
        type: 'select',
        value: 'Day',
        isDefaultInvalid: false,
        options: ["Day", "Week", "Month"],
        label: 'Period',
        required: false,
        hideEmptyOption: true,
        width: 6,
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        type: 'number',
        label: 'From',
        required: false,
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        type: 'number',
        label: 'To',
        required: false,
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        required: false,
        label: 'End Time',
        width: 6,
      },
      {
        name: "locationBasedParamSelector",
        placeholder: "",
        value: '',
        label: 'Location-Parameter',
        required: false,
        width: 12,
        componentName: 'ReportDownloadConfiguration'
      },
    ]
  },
  {
    name: "Analyse",
    type: "Analyze",
    configuration: AnalyseConfig,
    view: Analyse,
    minWidgetHeight: 4,
    minWidgetWidth: 12,
    widgetParams: []    
  },
  {
    name: "Weather Forecast",
    type: "Weather Forecast",
    view: WeatherForecast,
    minWidgetWidth: 6,
    minWidgetHeight: 3,
    maxWidgetWidth: 12,
    maxWidgetHeight: 4,
    configuration: WeatherForecastConfig,
    className:"weather-widget-config",
    widgetParams: [
      {
        name: "locationBasedParamSelector",
        placeholder: "",
        value: '',
        label: 'Location-Parameter',
        required: true,
        width: 12,
        componentName: 'WeatherForecastConfig'
      }
    ]
  },
  {
    name: "Asset Health Count",
    type: "Asset Health Count",
    view: AssetHealthCount,
    configuration: AssetHealthCountConfiguration,
    className: 'section-config',
    minWidgetHeight: 2.25,
    maxWidgetHeight: 2.25,
    minWidgetWidth: 4,
    maxWidgetWidth: 6,
    widgetParams: [
      {
        name: 'assetHealthCountConfig',
        isDefaultInvalid: false,
				label: 'assetHealthCountConfig”',
        required: false,
        componentName: 'AssetHealthCountConfiguration'
      },
      {
        name: 'assetHealthCountConfig',
				value: {
					showUnhealthyAssetCount: true,
					showGraphDuringVisualization: true,
          allInsightContribution: true
				}
      }
		]
  },  
  {
    name: "Asset Detail List",
    type: "Asset Detail List",
    view: AssetDetailList,
    configuration: AssetDetailListConfiguration,
    className: 'section-config',
    minWidgetWidth: 9,
    maxWidgetWidth: 9,
    minWidgetHeight: 3,
    maxWidgetHeight: 3,
    widgetParams: [
      {
        name: 'assetDetailListConfig',
        isDefaultInvalid: false,
        label: 'assetDetailListConfig”',
        required: false,
        componentName: 'AssetDetailListConfiguration'
      }
		]
  },  
  {
    name: "Asset Insight & Alert",
    type: "Asset Insight & Alert",
    view: AssetInsightAlert,
    configuration: AssetInsightAlertConfiguration,
    className: 'section-config',
    minWidgetHeight: 2.25,
    maxWidgetHeight: 2.25,
    minWidgetWidth: 5,
    maxWidgetWidth: 5,
    widgetParams: [
      {
        name: "dateandtime",
        value: "<div><span><i className='far fa-calendar-alt'></i></span>Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header'
      },
      {
        name: "dateAndTimeGuide",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br /><b>formula = (Start - End) * Frequency.</b><br />Selecting 0 is equivalent of selecting current day/week/month.<br />Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week.</div>'
      },
      {
        name: "period",
        placeholder: "Select Period",
        type: 'select',
        value: 'Day',
        isDefaultInvalid: false,
        options: ["Day", "Week", "Month"],
        label: 'Period',
        required: false,
        hideEmptyOption: true,
        width: 6,
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        type: 'number',
        label: 'From',
        required: false,
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        type: 'number',
        label: 'To',
        required: false,
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '',
        type: 'text',
        required: false,
        label: 'End Time',
        width: 6,
      },
      {
        name: "assetInsightAlertConfig",
        value: '',
        label: 'assetInsightAlertConfig',
        required: false,
        width: 12,
        componentName: 'AssetInsightAlertConfiguration'
      },
      {
        name: 'plant',
        label: 'plant',
        required: true,
        isDefaultInvalid: false
      },
      {
        name: 'configurationCollection',
        label: 'configurationCollection',
        required: true,
        isDefaultInvalid: false
      }
    ]
  },  
  {
    name: "Asset Event Log",
    type: "Asset Event Log",
    view: AssetEventLog,
    configuration: AssetEventLogConfiguration,
    className: 'section-config',
    minWidgetHeight: 5.25,
    maxWidgetHeight: 5.25,
    minWidgetWidth: 3,
    maxWidgetWidth: 3,
    widgetParams: [
      {
        name: "assetEventLogConfig",
        placeholder: "",
        value: '',
        label: 'Asset Event Log',
        required: false,
        width: 12,
        componentName: 'AssetEventLogConfiguration'
      },
			{
				name: 'timelineType',
				value: 'Alerts'
			},
			{
				name: 'alertConfiguration',
				value: {
					type: [
							'user',
							'system'
					],
					status: [
							'acknowledged',
							'resolved',
							'ignored',
							'generated'
					],
					priorityLevel: [
							'high',
							'medium',
							'low'
					]
				}
			},
			{
				name: 'insightConfiguration',
				value: {
					status: [
						'critical',
						'non-critical'
					]
				}
			},
			{
				name: 'activityConfiguration',
				value: {
					type: [
						"planned",
						"unplanned"
					]
				}
			}
		]
  },  
  {
    name: 'Asset Top Contributors',
    type: 'Asset Top Contributors',
    view: AssetTopContributors,
    configuration: AssetTopContributorsConfiguration,
    className: 'section-config',
    minWidgetHeight: 2.25,
    maxWidgetHeight: 2.25,
    minWidgetWidth: 6,
    maxWidgetWidth: 8,
    widgetParams: [
      {
        name: 'assetTopContributorsConfig',
        label: 'AssetTopContributorsConfig”',
        isDefaultInvalid: false,
        required: true,
        value: {},
        componentName: 'AssetTopContributorsConfiguration'
      }
		]
  },
  {
        name: "Workplace Overview",
        type: "Workplace Overview",
        view: Workplace,
        configuration: WorkplaceConfig,
        minWidgetHeight: 3,
        minWidgetWidth: 6,
        widgetParams: [
          {
            name: "locationBasedParamSelector",
            placeholder: "",
            value: '',
            label: 'Location-Parameter',
            required: true,
            width: 12,
            componentName: 'WorkplaceConfig'
          }
        ]
      },
  {
    name: "3D View",
    type: "3D View",
    view: _3DView,
    configuration: _3DViewConfiguration,
    className: '3d-view-config',
    minWidgetHeight: 5,
    maxWidgetHeight: 5,
    minWidgetWidth: 12,
    maxWidgetWidth: 12,
    widgetParams: []
  },
  {
    name: "Cxo Summary",
    type: "Cxo Summary",
    view: CxoSummary,
    minWidgetWidth: 12,
    minWidgetHeight: 4.2,
    maxWidgetWidth: 12,
    maxWidgetHeight: 12,
    configuration: CxoSummaryConfig,
    className:"cxo-summary-config",
    widgetParams: [
      {
        name: "locationBasedParamSelector",
        placeholder: "",
        value: '',
        label: 'Location-Parameter',
        required: false,
        width: 12,
        componentName: 'CxoSummaryConfig'
      }
    ]
  }, 
  
  {
    name: "Project Status",
    type: "Project Status",
    view: ProjectStatus,
    configuration: ProjectStatusConfiguration, 
    minWidgetWidth: 12,
    minWidgetHeight: 3,
    widgetParams: [
      {
        name: "OnTrack",
        placeholder: "Enter On Track",
        value: '',
        type: 'text',
        label: 'On Track',
        required: false,
        width: 4
      },
      {
        name: "OffTrack",
        placeholder: "Enter Off Track",
        value: '',
        type: 'text',
        label: 'Off Track',
        required: false,
        width: 4
      },
      {
        name: "Closed",
        placeholder: "Enter Closed",
        value: '',
        type: 'text',
        label: 'Closed',
        required: false,
        width: 4
      },
      {
        name: 'ProjectStatusConfiguration',
        isDefaultInvalid: false,
				label: 'ProjectStatusConfig',
        required: false,
        width: 12,
        componentName: 'ProjectStatusConfig'
      }
		]
  },

  {
    name: "Network View",
    type: "Network View",
    view: NetworkView,
    configuration: NetworkViewConfig, 
    minWidgetWidth: 12,
    minWidgetHeight: 2.5,
    maxWidgetHeight: 2.5,
    widgetParams: [
      {
        name: "",
        value: '',
        type: filler,
        width: 12,
        className: 'divider',
      },
      {
        name: "",
        value: '',
        type: filler,
        width: 12,
        className: 'divider',
      },
      {
        name: "networkviewConfig",
        width: 12,
        label: "NetworkView config",
        required: true,
        componentName: 'NetworkViewConfig',
      },
		]
  },
  {
    name: "Scorecard",
    type: "Scorecard",
    view: ScoreCardView,
    configuration: ScoreCardConfig,
    className: 'scorecard-widget-config',
    minWidgetHeight: 1,
    minWidgetWidth: 2,
    maxWidgetWidth: 4,
    maxWidgetHeight: 1.1,
    width:2,
    height:1,
    widgetParams: [
      {
        name: "dontShowInfoIcon",
        placeholder: "Do Not Show Info Icon",
        type: customCheckbox,
        label: 'Do not show info icon',
        width: 6,
        value: false
      },
      {
        name: "isDiscoveryBackground",
        placeholder: "Is Discovery Background",
        type: customCheckbox,
        label: 'Is Discovery Background',
        width: 6,
        value: false
      },
      {
        name: "dateandtime",
        value: "<div><span><i className='far fa-calendar-alt'></i></span> Date &amp; Time<div className='hr'></div></div>",
        type: filler,
        width: 12,
        className: 'header',
      },
      {
        name: "dateAndTimeGuide",
        type: filler,
        width: 12,
        className: "dateAndTimeGuide",
        value: '<div>To select time period for which the user wants to see the data works according to the <br /><b>formula = (Start - End) * Frequency.</b><br />Selecting 0 is equivalent of selecting current day/week/month.<br />Eg : If you select frequency as week and start as 2 and end as 1. The time period visible will be the 2 weeks before the current week.</div>'
      },
      {
        name: "period",
        placeholder: "Period",
        value: 'Day',
        isDefaultInvalid: false,
        type: 'select',
        options: ["Day", "Week", "Month", "Current Month"],
        label: 'Period',
        required: false,
        hideEmptyOption: true,
        width: 6,
      },
      {
        name: "filler",
        value: '',
        type: filler,
        width: 6,
      },
      {
        name: "from",
        placeholder: "Enter From",
        value: '0',
        isDefaultInvalid: false,
        type: 'number',
        label: 'From',
        required: false,
        width: 6,
      },
      {
        name: "to",
        placeholder: "Enter To",
        value: '0',
        isDefaultInvalid: false,
        type: 'number',
        label: 'To',
        required: false,
        width: 6,
      },
      {
        name: "startTime",
        placeholder: "(hour):(minute)",
        value: '00:00',
        isDefaultInvalid: false,
        type: 'time',
        label: 'Start Time',
        required: false,
        width: 6,
      },
      {
        name: "endTime",
        placeholder: "(hour):(minute)",
        value: '23:59',
        isDefaultInvalid: false,
        type: 'time',
        label: 'End Time',
        required: false,
        width: 6,
      },
      {
        name: "",
        value: '',
        type: filler,
        width: 12,
        className: 'divider',
      },
      {
        name: '',
        placeholder: '',
        value: '',
        label: 'Location-Parameter',
        required: true,
        width: 12,
        componentName: 'ScoreCardConfig',
        isDefaultInvalid: false
      }
    ]
  },
  {
    name: "Facility",
    type: "Facility",
    view: Facility,
    configuration: FacilityConfig,
    minWidgetWidth: 12,
    minWidgetHeight: 4.5,
    widgetParams: [
      {
        name: 'facilityConfig',
        isDefaultInvalid: false,
        label: 'FacilityConfig',
        required: false,
        width: 12,
        componentName: 'FacilityConfig'
      }
    ]
  }
]

export const mergeConfig = _memoize(allWidgetConfig => {
  // merge config
  let widgetConfig = allWidgetConfig.map((arg) => {
    let item;
    widgets().forEach((wid) => {
      if (wid.type === arg.type) {
        item = { ...wid, ...arg }
      }
    })
    return item
  });

  return widgetConfig
});

export const mergeDashboardDataWithConfig = _memoize(allWidgetConfig => {

  // merge config
  let widgetConfig = allWidgetConfig != undefined && allWidgetConfig.map(arg => {
    let item;
    widgets().forEach((wid) => {
      if (wid.type === (arg.widget_id && arg.widget_id.type)) {
        item = { ...wid, ...arg }
      }
    })
    return item
  });

  return widgetConfig
});

export const handleGroupOrContainerWidget = _memoize(widgtes => {
  let wid = [...widgtes];
  let groupedOrContainerWidIds = [];
  wid.map((w) => {
    if (!w || !w.type) return;

    if (w.type === 'Group') {
      w.config.gropu_widget_select && groupedOrContainerWidIds.push(...w.config.gropu_widget_select);
    }

    if (w.type === "Container") {
      w.config.container_widget_select && groupedOrContainerWidIds.push(...w.config.container_widget_select);
    }
  })

  let filtredWids = wid.filter((w) => {
    if (!w || !w.type) return;

    return !groupedOrContainerWidIds.includes(w.id)
  })

  return filtredWids;
});

export const defaultParamsConfig = [
  {
    name: "widget_name",
    placeholder: "Enter Widget Name",
    value: '',
    type: 'text',
    label: 'Widget Name',
    required: true,
    width: 6
  },
  {
    name: "refresh_frequency",
    placeholder: "Enter Refresh Frequency",
    value: "10",
    type: 'number',
    label: 'Auto Refresh Time (mins)',
    required: false,
    width: 6,
    step: '.05',
    min: 0,
    hint: 'Keep blank/zero for no refresh.'
  },
  {
    name: "widget_description",
    placeholder: "This description will appear on click of the (I) icon on the widget",
    value: '',
    type: 'textarea',
    label: 'Widget Description',
    required: false,
    width: 6
  },
];

export const DASHBOARD_ROW_HEIGHT = 125;
export const MIN_DASHBOARD_WIDGET_HEIGHT = 2;

//export const DEFAULT_WIDGET_ACTIONS = [
  //{ key: "edit", value: "Edit", permission: DASHBOARD.EDIT_WIDGET_OF_DASHBOARD_BY_ID },
  //{ key: "delete", value: "Delete", permission: DASHBOARD.DELETE_WIDGET_OF_DASHBOARD_BY_ID },
  //{ key: "clone", value: "Clone", permission: DASHBOARD.ADD_WIDGET_TO_DASHBOARD_BY_ID },
  //{ key: "tvmode", value: "TV Mode", permission: DASHBOARD.TV_MODE },
  //{ key: "download", value: "Download", permission: DASHBOARD.DOWNLOAD_WIDGET },
  //{ key: "refresh", value: "Refresh"},
  //{ key: "information", value: "Information"},
//];

export const DEFAULT_WIDGET_ACTIONS = [
  { key: 'action', value: 'Action' },
  // { key: 'information', value: 'Information' },
  { key: 'refresh', value: 'Refresh' }
];

export const DEFAULT_WIDGET_SUB_ACTIONS = [
  {
    key: 'edit',
    value: 'Edit',
    permission: DASHBOARD.EDIT_WIDGET_OF_DASHBOARD_BY_ID
  },
  {
    key: 'delete',
    value: 'Delete',
    permission: DASHBOARD.DELETE_WIDGET_OF_DASHBOARD_BY_ID
  },
  {
    key: 'clone',
    value: 'Clone',
    permission: DASHBOARD.ADD_WIDGET_TO_DASHBOARD_BY_ID
  },
  { key: 'tvmode', value: 'TV Mode', permission: DASHBOARD.TV_MODE },
  // { key: 'download', value: 'Download', permission: DASHBOARD.DOWNLOAD_WIDGET }
];

export const SUSTAIN_WIDGET_SUB_ACTIONS = [
	 { key: 'tvmode', value: 'TV Mode', permission: DASHBOARD.TV_MODE },
  // { key: 'download', value: 'Download', permission: DASHBOARD.DOWNLOAD_WIDGET }
];