import React, { useState, Fragment } from 'react';
import { Col } from 'reactstrap';

import tagConfInputGet from '../common/tagConfInputGet';
import tagConfsInputGet from '../common/tagConfsInputGet';
import tagFormulaConfInputGet from '../common/tagFormulaConfInputGet';
import tagFormulaConfsInputGet from '../common/tagFormulaConfsInputGet';
import collectionGet from '../common/collectionGet';
import ParameterMixedCollectionExtended from './ParameterMixedCollectionExtended';
import TagParameterConfigNormal from './TagParameterConfigNormal';
import TagFormulaConfig from './TagFormulaConfig';
import { CheckBox } from '../../../../../../../common/components';

const tagParameterConfsGet = (conf) => {
  const tagParameterConfs = conf && !conf.isComputed && [conf];

  return tagConfsInputGet(tagParameterConfs).reduce(
    (memo, tagParameterConf) => {
      return [...memo, tagParameterConf];
    },
    []
  );
};

const tagFormulaConfsGet = (conf) => {
  const tagFormulaConfs = conf && conf.isComputed && [conf];

  return tagFormulaConfsInputGet(tagFormulaConfs).reduce(
    (memo, tagFormulaConf) => {
      return [...memo, tagFormulaConf];
    },
    []
  );
};

const TagParameterConfig = (props) => {
  const [tagConfActiveIndex, setTagConfActiveIndex] = useState(
    props.tagConfs.length - 1
  );

  const renderPlantTagItem = (tagConfActiveIndex) => {
    const tagConf = props.tagConfs[tagConfActiveIndex];

    return (
      <Fragment>
        {!props.isComputedVisiblityToggle && (
          <Col className='mb-3'>
            <CheckBox
              label='Is Computed'
              size='sm'
              checked={tagConf.isComputed}
              checkboxClicked={(isComputed) => {
                props.onTagConfsChange(
                  collectionGet(
                    {
                      ...props.tagConfs[tagConfActiveIndex],
                      ...(isComputed
                        ? tagFormulaConfInputGet()
                        : tagConfInputGet())
                    },
                    tagConfActiveIndex,
                    props.tagConfs,
                    false
                  )
                );
              }}
            />
          </Col>
        )}

        {props.tagConfs[tagConfActiveIndex].isComputed ? (
          <TagFormulaConfig
            inputFieldColumnWidth={props.inputFieldColumnWidth}
            formulaAliasVisiblityToggle={props.aliasVisiblityToggle}
            unitVisiblityToggle={props.unitVisiblityToggle}
            colorVisiblityToggle={props.colorVisiblityToggle}
            blankVisiblityToggle={props.blankVisiblityToggle}
            plant={props.plant}
            tagFormulaConfs={tagFormulaConfsGet(tagConf)}
            onTagFormulaConfsChange={(tagFormulaConf) => {
              props.onTagConfsChange(
                collectionGet(
                  tagFormulaConf,
                  tagConfActiveIndex,
                  props.tagConfs,
                  false
                )
              );
            }}
          />
        ) : (
          <TagParameterConfigNormal
            inputFieldColumnWidth={props.inputFieldColumnWidth}
            aliasVisiblityToggle={props.aliasVisiblityToggle}
            unitVisiblityToggle={props.unitVisiblityToggle}
            colorVisiblityToggle={props.colorVisiblityToggle}
            blankVisiblityToggle={props.blankVisiblityToggle}
            plant={props.plant}
            tagParameterConfs={tagParameterConfsGet(tagConf)}
            onParameterTagConfsChange={(tagParameterConf) => {
              props.onTagConfsChange(
                collectionGet(
                  tagParameterConf,
                  tagConfActiveIndex,
                  props.tagConfs
                )
              );
            }}
          />
        )}
      </Fragment>
    );
  };

  const renderParameterMixedCollectionExtended = () => {
    return (
      <ParameterMixedCollectionExtended
        confs={props.tagConfs}
        onConfActiveIndexChangeTrigger={(index) => {
          setTagConfActiveIndex(index);
        }}
        onConfDeleteTrigger={(index) => {
          const _tagConfActiveIndex = (() => {
            switch (true) {
              case index < tagConfActiveIndex:
                return tagConfActiveIndex - 1;

              case index > tagConfActiveIndex:
                return tagConfActiveIndex;

              case index === tagConfActiveIndex && !!index:
                return tagConfActiveIndex - 1;

              default:
                return 0;
            }
          })();

          setTagConfActiveIndex(_tagConfActiveIndex);

          const tagConfs = collectionGet(null, index, props.tagConfs);

          props.onTagConfsChange(
            tagConfs.length ? tagConfs : [tagConfInputGet()]
          );
        }}
      />
    );
  };

  return (
    <div className='config-form-wrapper'>
      {renderPlantTagItem(tagConfActiveIndex)}

      {props.tagConfs.length < props.tagConfCount && (
        <Fragment>
          <button
            className='icon-text-btn'
            onClick={() => {
              setTagConfActiveIndex(props.tagConfs.length);

              props.onTagConfsChange([...props.tagConfs, tagConfInputGet()]);
            }}
          >
            <i className='fa fa-plus-circle' aria-hidden='true'></i> Add New Row
          </button>

          <br />
        </Fragment>
      )}

      {props.tagConfCount > 1 && renderParameterMixedCollectionExtended()}
    </div>
  );
};

export default TagParameterConfig;
