import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import axios from 'axios';
import { httpMethodGetJSON, httpMethodPatch, httpMethodDelete, httpMethodPost, } from '../https';
import { ajax } from 'rxjs/ajax';
import { map } from 'lodash';
import { URL } from '../constants';
import * as AppConstants from './constant';
import * as LoginConstants from '../../../common/container/Login/constant';

export const getAllClients = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_CLIENTS),
    mergeMap(action => {
        if (action.payload.isSwitchUser == false) {
            return httpMethodGetJSON({ type: AppConstants.GET_ALL_CLIENTS }, `${URL}/partners/clients/?client_name=${action.payload.client_name}`);
        } else return httpMethodGetJSON({ type: AppConstants.GET_ALL_CLIENTS }, `${URL}/partners/clients/?${action.payload.client_name}`);

    })
);

export const getAllPlants = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_PLANTS),
    mergeMap(action => {
        if (action.payload.isSwitchUser == false) {
            return httpMethodGetJSON({ type: AppConstants.GET_ALL_PLANTS }, `${URL}/partners/clients/${action.payload.id}/plants/?name=${action.payload.name}`);
        } else return httpMethodGetJSON({ type: AppConstants.GET_ALL_PLANTS }, `${URL}/partners/clients/${action.payload.id}/plants/`);
    })
);

export const getAllUsersForSpecificPlant = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_USERS_FOR_PLANT),
    mergeMap(action => {
        if (action.payload.isSwitchUser == false) {
            return httpMethodGetJSON({ type: AppConstants.GET_ALL_USERS_FOR_PLANT }, `${URL}/partners/clients/${action.payload.clientId}/plants/${action.payload.plantId}/users/?name=${action.payload.name}`);
        } else return httpMethodGetJSON({ type: AppConstants.GET_ALL_USERS_FOR_PLANT }, `${URL}/partners/clients/${action.payload.clientId}/plants/${action.payload.plantId}/users/`);
    })
);

export const getClientDashboard = action$ => action$.pipe(
    ofType(AppConstants.GET_CLIENT_DASHBOARD),
    mergeMap(action => {
        return httpMethodPost({ type: LoginConstants.GET_LOGIN_DATA, payload: { user_id: action.payload.memberId } }, `${URL}/partners/clients/${action.payload.clientId}/users/login/`);
    })
);

// export const clientLogout = action$ => action$.pipe(
//     ofType(AppConstants.CLIENT_LOGOUT),
//     mergeMap(action => {
//         let data = localStorage.getItem('smartsense.client_logout_data') && JSON.parse(localStorage.getItem('smartsense.client_logout_data'))
//         localStorage.setItem('adminProfile', true);
//         localStorage.removeItem("smartsense.superAuthToken");
//         localStorage.removeItem("smartsense.client_logout_data");
//         return axios.delete(`${URL}/partners/clients/${data.clientId}/users/logout/`, {
//             headers: {
//                 "Content-Type": "application/json; charset=UTF-8",
//                 Authorization: `token: ${action.payload.token}`,
//             },
//             user_id: data.memberId
//         }).then((response) => {
//             return of({ type: AppConstants.CLIENT_LOGOUT_SUCCESS, payload: response.data });
//         }).catch(error => {
//             return of({
//                 type: AppConstants.CLIENT_LOGOUT_FAILURE,
//                 error: true,
//                 payload: error
//             })
//         })


//         // return httpMethodDelete({ type: AppConstants.CLIENT_LOGOUT, payload: { user_id: data.memberId } }, `${URL}/partners/clients/${data.clientId}/users/logout/`);
//     })
// );


// export const clientLogout = action$ => action$.pipe(
//     ofType(AppConstants.CLIENT_LOGOUT),
//     mergeMap((action, a) => {

//         // localStorage.setItem('adminProfile', true);
//         // localStorage.removeItem("smartsense.superAuthToken");
//         // localStorage.removeItem("smartsense.client_logout_data");
//         return axios.delete(`${URL}/partners/clients/${action.payload.data.clientId}/users/logout/`, { user_id: action.payload.data.memberId }, {
//             headers: {
//                 "Content-Type": "application/json; charset=UTF-8",
//                 Authorization: `token: ${action.payload.token}`,
//             },
//         }).then((response) => of({ type: AppConstants.CLIENT_LOGOUT_SUCCESS, payload: response.data })
//         ).catch(error => of({
//             type: AppConstants.CLIENT_LOGOUT_FAILURE,
//             payload: error
//         }))
//     })
// );

export const partnerLoginEpics = {
    getAllClients,
    getAllPlants,
    getAllUsersForSpecificPlant,
    getClientDashboard,
    // clientLogout
}