import formulaCompare from './formulaCompare';

const formulaConfPointerCollectionGet = (
  formulaCollection,
  formulaConfParameterCollection
) => {
  const formulaConfPointerCollection = formulaCollection.reduce(
    (memo, formula, index) => {
      const value = (() => {
        switch (formula.type) {
          case 'operator':
          case 'constant':
            return formula.value;

          case 'parameter':
            return formulaConfParameterCollection.findIndex((_formula) => {
              return (
                _formula.type === 'parameter' &&
                formulaCompare(_formula, formula)
              );
            });
        }
      })();

      const pointer = {
        type: formula.type,
        index,
        value
      };

      return [...memo, pointer];
    },
    []
  );

  return formulaConfPointerCollection;
};

export default (formulaConfs, formulaConfParameterCollections) => {
  return formulaConfs.reduce((memo, formulaConf, index) => {
    const formulaConfPointerCollection = formulaConfPointerCollectionGet(
      formulaConf.formula[0].formula,
      formulaConfParameterCollections[index]
    );

    return [...memo, formulaConfPointerCollection];
  }, []);
};