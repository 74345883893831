import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import "./factory.scss";
import redLocation from "../../../../assets/images/Maps/LocationDrop/redLocation.svg";
import orangeLocation from "../../../../assets/images/Maps/LocationDrop/orangeLocation.svg";
import greenLocation from "../../../../assets/images/Maps/LocationDrop/greenLocation.svg";
import nextArrow from "../../../../assets/images/Maps/Markers/nextArrow.svg";
import arrowBlue from "../../../../assets/images/Maps/Markers/arrow-blue.svg";

const FactoryTooltip = ({
  showFactoryDetails,
  lat,
  long,
  location,
  locationId,
  widgetId,
  showDetailSummary,
	factoryInfoWindowData
}) => {
    const dataLoad = factoryInfoWindowData.data[widgetId];

    const infoWindowData =
      factoryInfoWindowData &&
      factoryInfoWindowData.data &&
      factoryInfoWindowData.data[widgetId] &&
      factoryInfoWindowData.data[widgetId][locationId];

  const [totalSavings, setTotalSavings] = useState(0);
  const [totalEmissions, setTotalEmissions] = useState(0);
  const [sec, setSec] = useState(0);
  const [performance, setPerformance] = useState(0);

  let rowCount = 0;
  infoWindowData &&
    Object.values(infoWindowData).forEach((scope) => {
      if (scope.showOnToolTip) rowCount++;
    });

  const loadData = () => {
    if (infoWindowData) {
      const scope1value =
        infoWindowData["Scope 1"] && infoWindowData["Scope 1"].value;
      const scope2value =
        infoWindowData["Scope 2"] && infoWindowData["Scope 2"].value;
      const scope3value =
        infoWindowData["Scope 3"] && infoWindowData["Scope 3"].value;
      const scope1Targetvalue =
        infoWindowData["Scope 1 - Target"] &&
        infoWindowData["Scope 1 - Target"].value;
      const scope2Targetvalue =
        infoWindowData["Scope 2 - Target"] &&
        infoWindowData["Scope 2 - Target"].value;
      const scope3Targetvalue =
        infoWindowData["Scope 3 - Target"] &&
        infoWindowData["Scope 3 - Target"].value;

      let totalGhgEmissions =
        scope1value &&
        scope2value &&
        scope3value &&
        scope1value + scope2value + scope3value;
      setTotalEmissions(totalGhgEmissions && totalGhgEmissions.toFixed());

      const specificEnergyConsumptionValue =
        infoWindowData["Specific Energy consumption"] &&
        infoWindowData["Specific Energy consumption"].value;
      const specificEnergyConsumptionTargetValue =
        infoWindowData["Specific Energy consumption - Target"] &&
        infoWindowData["Specific Energy consumption - Target"].value;

      const sumOfScopes =
        scope1value &&
        scope2value &&
        scope3value &&
        scope1value + scope2value + scope3value;
      const sumOfTargets =
        scope1Targetvalue &&
        scope2Targetvalue &&
        scope3Targetvalue &&
        scope1Targetvalue + scope2Targetvalue + scope3Targetvalue;

      if (sumOfScopes && sumOfTargets) {
        setTotalSavings((sumOfTargets - sumOfScopes).toFixed());
      }

      if (specificEnergyConsumptionValue) {
        setSec(specificEnergyConsumptionValue.toFixed(2));
      }

      if (
        specificEnergyConsumptionValue &&
        specificEnergyConsumptionTargetValue
      ) {
        const secPerformance =
          specificEnergyConsumptionValue / specificEnergyConsumptionTargetValue;
        const mtPerformance = sumOfScopes / sumOfTargets;
        setPerformance(
          mtPerformance > secPerformance ? mtPerformance : secPerformance
        );
      }
    }
  };

  useEffect(loadData, [dataLoad]);

  return (
    <Fragment>
      <div className="factory-tooltip">
        <div className={`factory-location row-count-${rowCount}`}>
          <img
            src={
              performance >= 0.91
                ? redLocation
                : performance >= 0.81
                ? orangeLocation
                : greenLocation
            }
            alt={""}
            height={18}
          />
          <span>{location ? location : ""}</span>
        </div>
        {/* <div className="factory-mt">{totalSavings ? `${totalSavings} MT` : ""}</div> */}
        <div className="factory-sec">
          <div className="factory-mt">
            {totalEmissions ? `${totalEmissions} MT` : ""}
          </div>
          {/* {sec ? `SEC - ${sec}` : ""} */}
          {infoWindowData &&
            Object.values(infoWindowData).map((scope) => {
              if (scope.showOnToolTip)
                return (
                  <div>{`${scope.alias ? scope.alias : ""} ${
                    scope.value ? scope.value : ""
                  }`}</div>
                );
            })}
            {showDetailSummary &&
          <div
            onClick={() => showFactoryDetails(lat, long, location)}
            className="details"
          >
            <span>
              <img src={arrowBlue} alt={""} height={18} />
            </span>
          </div>}
        </div>
      </div>
    </Fragment>
  );
};

export default FactoryTooltip;