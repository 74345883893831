import React from 'react';
import { Row, Col } from 'reactstrap';

import useSensorParameterCollection from '../common/useSensorParameterCollection';
import SensorParameterSelect from '../Common/SensorParameterSelect';
import { Input } from '../../../../../../../common/components';

const ParameterConfigNormal = (props) => {
  const [
    sensorCollection,
    sensorOptions,
    parameterCollection,
    parameterOptionsCollection,
    onSensorChangeHandle,
    onParameterChangeHandle
  ] = useSensorParameterCollection(props.parameterConfs);

  return (
    <div>
      <div className='content-border-wrap pd-y-10 mg-b-15'>
        <Row>
          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Alias</label>

              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter Alias'
                value={props.parameter.alias || ''}
                inputChanged={(alias) => {
                  alias !== props.parameter.alias &&
                    props.onParameterChangeTrigger({ alias });
                }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Location</label>

              <SensorParameterSelect
                type='sensor'
                options={sensorOptions}
                value={sensorCollection[0] || ''}
                onChange={(sensor) => {
                  onSensorChangeHandle(sensor, 0);
                }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Parameters</label>

              <SensorParameterSelect
                type='parameter'
                options={parameterOptionsCollection[0] || []}
                value={parameterCollection[0] || ''}
                onChange={(parameter) => {
                  onParameterChangeHandle(
                    parameter,
                    0,
                    props.onParameterConfCollectionUpdateTrigger
                  );
                }}
              />
            </div>
          </Col>
        </Row>

        <p className='head-label-text'>Threshold Line</p>

        <Row>
          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Label</label>

              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter Label'
                value={props.parameter.label || ''}
                inputChanged={(label) => {
                  label !== props.parameter.label &&
                    props.onParameterChangeTrigger({ label });
                }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Value</label>

              <Input
                size='sm'
                label=''
                type='number'
                required={false}
                placeholder='Enter Value'
                value={props.parameter.value || ''}
                inputChanged={(value) => {
                  value !== props.parameter.value &&
                    props.onParameterChangeTrigger({ value });
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ParameterConfigNormal;
