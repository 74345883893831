import {
  GET_ASSET_CBM_TOP_CONTRIBUTORS,
  GET_ASSET_CBM_TOP_CONTRIBUTORS_SUCCESS,
  GET_ASSET_CBM_TOP_CONTRIBUTORS_FAILURE,
  GET_INSIGHTS_BY_PLANT_ID,
  GET_INSIGHTS_BY_PLANT_ID_SUCCESS,
  GET_INSIGHTS_BY_PLANT_ID_FAILURE,
  GET_SYSTEM_TOP_CONTRIBUTORS,
  GET_SYSTEM_TOP_CONTRIBUTORS_SUCCESS,
  GET_SYSTEM_TOP_CONTRIBUTORS_FAILURE
} from './constant';

export const getAssetCbmTopContributors = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_ASSET_CBM_TOP_CONTRIBUTORS:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case GET_ASSET_CBM_TOP_CONTRIBUTORS_SUCCESS:
      return {
        ...state,
        data: action.response,
        loading: false,
        success: true
      };

    case GET_ASSET_CBM_TOP_CONTRIBUTORS_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export const getInsightsByPlantId = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_INSIGHTS_BY_PLANT_ID:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case GET_INSIGHTS_BY_PLANT_ID_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };

    case GET_INSIGHTS_BY_PLANT_ID_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };

    default:
      return state;
  }
};

export const getSystemTopContributors = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_SYSTEM_TOP_CONTRIBUTORS:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case GET_SYSTEM_TOP_CONTRIBUTORS_SUCCESS:
      return {
        ...state,
        data: action.response,
        loading: false,
        success: true
      };

    case GET_SYSTEM_TOP_CONTRIBUTORS_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};