import {
  GET_ASSET_CATEGORY_FOR_SME,
  GET_ASSET_CATEGORY_FOR_SME_SUCCESS,
  GET_ASSET_CATEGORY_FOR_SME_FAILURE,
  GET_ASSETS_FOR_ASSET_CATEGORY,
  GET_ASSETS_FOR_ASSET_CATEGORY_SUCCESS,
  GET_ASSETS_FOR_ASSET_CATEGORY_FAILURE,
  GET_NETWORKS_FOR_NETWORK_CATEGORY,
  GET_NETWORKS_FOR_NETWORK_CATEGORY_SUCCESS,
  GET_NETWORKS_FOR_NETWORK_CATEGORY_FAILURE,
  GET_TEMPLATE_DETAILS_FOR_NETWORK,
  GET_TEMPLATE_DETAILS_FOR_NETWORK_SUCCESS,
  GET_TEMPLATE_DETAILS_FOR_NETWORK_FAILURE,
} from './constant';

export function getAssetCategoryForSmeReducer(
  state = {
    data: [],
    loading: false,
    error: false,
    success: undefined,
  },
  action
) {
  switch (action && action.type) {
    case GET_ASSET_CATEGORY_FOR_SME:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined,
      };

    case GET_ASSET_CATEGORY_FOR_SME_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
      };

    case GET_ASSET_CATEGORY_FOR_SME_FAILURE:
      return {
        ...state,
        data: action.payload.response,
        error: true,
        loading: false,
        success: false,
      };

    default:
      return state;
  }
}

export function getAssetsForAssetCategoryReducer(
  state = {
    data: [],
    loading: false,
    error: false,
    success: undefined,
  },
  action
) {
  switch (action && action.type) {
    case GET_ASSETS_FOR_ASSET_CATEGORY:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined,
      };

    case GET_ASSETS_FOR_ASSET_CATEGORY_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
      };

    case GET_ASSETS_FOR_ASSET_CATEGORY_FAILURE:
      return {
        ...state,
        data: action.payload.response.message,
        error: true,
        loading: false,
        success: false,
      };

    default:
      return state;
  }
}

export function getNetworksForNetworkCategoryReducer(
  state = {
    data: [],
    loading: false,
    error: false,
    success: undefined,
  },
  action
) {
  switch (action && action.type) {
    case GET_NETWORKS_FOR_NETWORK_CATEGORY:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined,
      };

    case GET_NETWORKS_FOR_NETWORK_CATEGORY_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
      };

    case GET_NETWORKS_FOR_NETWORK_CATEGORY_FAILURE:
      return {
        ...state,
        data: action.payload.response,
        error: true,
        loading: false,
        success: false,
      };

    default:
      return state;
  }
}

export function getTemplateDetailsForNetworkReducer(
  state = {
    data: [],
    loading: false,
    error: false,
    success: undefined,
  },
  action
) {
  switch (action && action.type) {
    case GET_TEMPLATE_DETAILS_FOR_NETWORK:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: undefined,
      };

    case GET_TEMPLATE_DETAILS_FOR_NETWORK_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
      };

    case GET_TEMPLATE_DETAILS_FOR_NETWORK_FAILURE:
      return {
        ...state,
        data: action.payload.response,
        error: true,
        loading: false,
        success: false,
      };

    default:
      return state;
  }
}

export const AppExpertReducer = {
  getAssetCategoryForSmeReducer,
  getAssetsForAssetCategoryReducer,
  getNetworksForNetworkCategoryReducer,
  getTemplateDetailsForNetworkReducer
};
