export default (type) => {
  return {
    plantid: null,
    sensors: [
      {
        sensorid: null,
        ...(() => {
          return type
            ? {
                min: null,
                max: null,
                readingtypeid: null
              }
            : {
                readingtypeid: [
                  {
                    rt: null,
                    min: null,
                    max: null
                  }
                ]
              };
        })()
      }
    ],
    sensorwise: !!type,
    showSummaryTable: false
  };
};
