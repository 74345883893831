import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../../constants';
import { httpMethodGetJSON, httpMethodPost } from '../../../../https';
import {
  GET_GIS_VIEW_READING_DATA,
  GET_KPI_VIEW_DATA,
  GET_KPI_PLANT_DATA,
  GET_KPI_VIEW_READING_DATA,
  GET_KPI_REDIRECTION_DASHBOARD
} from './constant';

const READING_DATA_URL = `${URL}/reading/reading_data/`;
const READING_COMPUTED_DATA_URL = `${URL}/reading/reading_computed_data/`;
const FACILITY_URL = `${URL}/plants/`;

export const getGisViewReadingData = (action$) =>
  action$.pipe(
    ofType(GET_GIS_VIEW_READING_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, READING_DATA_URL);
    })
  );

export const getKpiViewData = (action$) =>
action$.pipe(
  ofType(GET_KPI_VIEW_DATA),
  mergeMap((action) => {
    if (action.payload.isComputed === true) {
      return httpMethodPost(action, READING_COMPUTED_DATA_URL);
    }
    return httpMethodGetJSON(action, READING_DATA_URL);
  })
);

export const getKpiPlantData = (action$) =>
  action$.pipe(
    ofType(GET_KPI_PLANT_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, FACILITY_URL);
    })
  );

export const getKpiViewReadingData =(action$) =>
  action$.pipe(
    ofType(GET_KPI_VIEW_READING_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, READING_DATA_URL);
    })
  );

export const getKpiRedirectionDashboard = (action$) => {
  return action$.pipe(
    ofType(GET_KPI_REDIRECTION_DASHBOARD),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/gstemp/service/${action.payload.serviceId}/templates/`
      );
    })
  );
};