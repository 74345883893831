export const ICON_POSITION = {


  // CONSTANT naming convention is as follows
  // V_H : 'classname'
  // V first part in name represents vertical aligment
  // H second part in name represents Horizontal aligment


  /**
   * Set the position to `'top-left'`
   */
  TOP_LEFT: 'top-left',

  /**
   * Set the position to `'top-right'`
   */
  TOP_RIGHT: 'top-right',

  /**
   * Set the position to `'top-center'`
   */
  TOP_CENTER: 'top-center',

  /**
   * Set the position to `'bottom-left'`
   */
  BOTTOM_LEFT: 'bottom-left',

  /**
   * Set the position to `'bottom-right'`
   */
  BOTTOM_RIGHT: 'bottom-right',

  /**
   * Set the position to `'bottom-center'`
   */
  BOTTOM_CENTER: 'bottom-center',


  CENTER_LEFT: 'center-left',
  CENTER_RIGHT: 'center-right',
  CENTER_CENTER: 'center-center'

}