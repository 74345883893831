import {ofType} from "redux-observable";
import {catchError, map, mergeMap} from "rxjs/operators";
import {ajax} from "rxjs/ajax";
import {throwError, concat, of} from "rxjs";
import isArray from 'lodash/isArray';
import {
    AssetUrl,
    ERROR,
    getLocalItem
} from "../constants";
import {
    GET_BILLING_METER_DATA,
    GET_BILLING_METER_DATA_SUCCESS,
    GET_BILLING_METER_DATA_FAILURE
} from './constant';

export const getBillingMeterDataSuccess = payload => ({type: GET_BILLING_METER_DATA_SUCCESS, payload});

export const getBillingMeterDataEpic = action$ => {
    return action$.pipe(
        ofType(GET_BILLING_METER_DATA),
        mergeMap(() => {
            return ajax.getJSON(
                `${AssetUrl}/api/getallbillinglocations/`,
                {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
            ).pipe(
                map(response => getBillingMeterDataSuccess(response.map(row => {
                    return {
                        locationid: row['LocationId'],
                        name: row['LocationName']
                    }
                }))),
                catchError(
                    error => of(
                        {type: GET_BILLING_METER_DATA_FAILURE, payload: []},
                        {type: ERROR, payload: error}
                    )
                )
            );
        })
    );
};
