export const GET_LIVE_CONSUMPTION_DATA = 'GET_LIVE_CONSUMPTION_DATA';
export const GET_LIVE_CONSUMPTION_DATA_SUCCESS = 'GET_LIVE_CONSUMPTION_DATA_SUCCESS';
export const GET_LIVE_CONSUMPTION_DATA_FAILURE = 'GET_LIVE_CONSUMPTION_DATA_FAILURE';

export const GET_ENERGY_FORECAST_DATA = 'GET_ENERGY_FORECAST_DATA';
export const GET_ENERGY_FORECAST_DATA_SUCCESS = 'GET_ENERGY_FORECAST_DATA_SUCCESS';
export const GET_ENERGY_FORECAST_DATA_FAILURE = 'GET_ENERGY_FORECAST_DATA_FAILURE';

export const GET_TOTAL_CONSUMPTION_DATA = 'GET_TOTAL_CONSUMPTION_DATA';
export const GET_TOTAL_CONSUMPTION_DATA_SUCCESS = 'GET_TOTAL_CONSUMPTION_DATA_SUCCESS';
export const GET_TOTAL_CONSUMPTION_DATA_FAILURE = 'GET_TOTAL_CONSUMPTION_DATA_FAILURE';

export const GET_DATERANGE_ENERGY_CONSUMPTION_DATA = 'GET_DATERANGE_ENERGY_CONSUMPTION_DATA';
export const GET_DATERANGE_ENERGY_CONSUMPTION_DATA_SUCCESS = 'GET_DATERANGE_ENERGY_CONSUMPTION_DATA_SUCCESS';
export const GET_DATERANGE_ENERGY_CONSUMPTION_DATA_FAILURE = 'GET_DATERANGE_ENERGY_CONSUMPTION_DATA_FAILURE';

export const GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_INIT = 'GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_INIT';
export const GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_SUCCESS = 'GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_SUCCESS';
export const GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_FAILURE = 'GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_FAILURE';

export const GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA = 'GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA';
export const GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_SUCCESS = 'GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_SUCCESS';
export const GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_FAILURE = 'GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_FAILURE';

export const GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_INIT = 'GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_INIT';
export const GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_SUCCESS = 'GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_SUCCESS';
export const GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_FAILURE = 'GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_FAILURE';

export const GET_RANGE_ENERGY_CONSUMPTION_CHART = 'GET_RANGE_ENERGY_CONSUMPTION_CHART';
export const GET_RANGE_ENERGY_CONSUMPTION_CHART_SUCCESS = 'GET_RANGE_ENERGY_CONSUMPTION_CHART_SUCCESS';
export const GET_RANGE_ENERGY_CONSUMPTION_CHART_FAILURE = 'GET_RANGE_ENERGY_CONSUMPTION_CHART_FAILURE';
