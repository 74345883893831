import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../constants';
import { httpMethodPost } from '../../../https';
import { CREATE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE } from './constant';

export const createSystemDynamicVariableTemplate = (action$) =>
  action$.pipe(
    ofType(CREATE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE),
    mergeMap((action) => {
      return httpMethodPost(
        action,
        `${URL}/system-cbm/system/dynamic_variable_template/`
      );
    })
  );
