import React, { Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import useReorder from './useReorder';
import { LoadingPlain } from '../../../common/components/';
import DashboardOption from './DashboardOption';
import ReorderTrigger from './ReorderTrigger';
import CreateCustomTrigger from './CreateCustomTrigger';

const _Sidebar = (props) => {
  const reorder = useReorder(props.data, props.onSetData);

  return (
    <Fragment>
      <div className='_sidebarWrap-vertical vertical-sidebarWrap'>
        <button className='hideSidebar'>
          <i
            className='fa fa-angle-double-left'
            aria-hidden='true'
            onClick={() => {
              props.onSidebarShowToggle();
            }}
          ></i>
        </button>

        <div className='dashboard-aside-wrap'>
          <div className='aside-top'>
            <div className='head'>
              <h2>
                <span>{props.sidebarName}</span>

                <ReorderTrigger
                  reorderableShow={props.reorderableShow}
                  onSetReorderableShow={props.onSetReorderableShow}
                />
              </h2>
            </div>

            <div className='dashboard-list'>
              {props.sidebarData &&
                props.sidebarData.length > 0 &&
                props.sidebarData.map((dashboard, index) => {
                  return (
                    <div
                      key={index}
                      tabIndex={index}
                      className={`dashboard-list-litem ${
                        props.selectedId &&
                        props.selectedId == dashboard.id &&
                        '_active'
                      }`}
                      onClick={() => {
                        props.onSetSelectedId(dashboard.id, props.data);

                        props.onSidebarShowToggle();

                        props.onNavigate(dashboard);
                      }}
                      draggable={props.reorderableShow}
                      {...reorder}
                    >
                      <p>
                        {!props.loading ? (
                          dashboard.name ||
                          dashboard.dashboard_name || <span>&nbsp;</span>
                        ) : (
                          <LoadingPlain />
                        )}
                      </p>

                      {!dashboard.status && (
                        <div className='item-options'>
                          <div className='option-wrap dropdown-box'>
                            {props.selectedId === dashboard.id &&
                              !props.namedServiceDashboardIdCollection.includes(
                                props.selectedId
                              ) && (
                                <Fragment>
                                  <a
                                    href='#'
                                    className='option-toggle'
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();

                                      props.onSetShowDashboardOption(
                                        !props.showDashboardOption
                                      );

                                      props.onSetShowDashboardSubOption(false);
                                    }}
                                  >
                                    <i
                                      className='fas fa-ellipsis-v'
                                      aria-hidden='true'
                                    ></i>
                                  </a>

                                  <DashboardOption
                                    dashboard={dashboard}
                                    selectedId={props.selectedId}
                                    serviceIdActive={props.serviceIdActive}
                                    showDashboardOption={
                                      props.showDashboardOption
                                    }
                                    showDashboardSubOption={
                                      props.showDashboardSubOption
                                    }
                                    _onSetSidebarShow={props._onSetSidebarShow}
                                    onSidebarShowToggle={
                                      props.onSidebarShowToggle
                                    }
                                    onSetShowDashboardForm={
                                      props.onSetShowDashboardForm
                                    }
                                    onSetUpdate={props.onSetUpdate}
                                    onSetDashboardInput={
                                      props.onSetDashboardInput
                                    }
                                    onSetShowDashboardSubOption={
                                      props.onSetShowDashboardSubOption
                                    }
                                    onDashboardOptionClose={
                                      props.onDashboardOptionClose
                                    }
                                    onDeleteDashboard={props.onDeleteDashboard}
                                  />
                                </Fragment>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>

          <div className='aside-bottom'>
            {props.reorderableShow ? (
              <div
                style={{ display: 'flex' }}
                className='justify-content-between pt-2'
              >
                <button
                  className='add-button'
                  onClick={() => {
                    props.onSetReorderableShow(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className='add-button'
                  onClick={() => {
                    props.onSequenceCreate(props.data, props.serviceIdActive);

                    props.onSetReorderableShow(false);

                    props.onSetReorderableSuccessToastShow(true);
                  }}
                >
                  Save
                </button>
              </div>
            ) : (
              <div className='pagination-2'>
                <CreateCustomTrigger
                  onDashboardOptionClose={props.onDashboardOptionClose}
                  _onSetSidebarShow={props._onSetSidebarShow}
                  onSetShowDashboardForm={props.onSetShowDashboardForm}
                />

                <div className='pagination-box'>
                  <button
                    className='pagination-btn prev-btn'
                    onClick={() => {
                      let page = props.pageNo - 1;

                      props.onSetPageNo(page);
                    }}
                    disabled={props.pageNo === 1}
                  >
                    <i className='fa fa-caret-left' aria-hidden='true'></i>
                  </button>

                  <p>
                    {' '}
                    {props.pageNo} of{' '}
                    {!props.loading ? (
                      Math.ceil(props.data.length / props.PAGE_SIZE) || 1
                    ) : (
                      <span className='mx-1'>
                        <CircularProgress size={10} color='primary' />
                      </span>
                    )}
                  </p>

                  <button
                    className='pagination-btn prev-btn'
                    onClick={() => {
                      let page = props.pageNo + 1;

                      props.onSetPageNo(page);
                    }}
                    disabled={
                      props.pageNo ===
                      Math.ceil(props.data.length / props.PAGE_SIZE)
                    }
                  >
                    <i className='fa fa-caret-right' aria-hidden='true'></i>
                  </button>
                </div>
              </div>
            )}

            <div className='how-works'>
              <div className='bg-image'>
                <h3>How Dashboard Works ?</h3>
                <button className='know-more'>Coming Soon</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default _Sidebar;
