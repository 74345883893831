import * as AppConstants from './constant';

const INITIAL_STATE = {
    data: [],
    loading: false,
    error: false,
    success: undefined,
    total_pages: null,
    total_record: null,
    checkedData: [],
    isEventChecked: false
}

export function getAllEvents(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_EVENTS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_EVENTS_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_EVENTS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }

        case AppConstants.CHECK_ALL_REPORT_SUCCESS:
            let list = state.data;
            if (action.payload.value === "checkAll") {
                list && Array.isArray(list) && list.map(item => {
                    item.isEventChecked = action.payload.checkedFlag;
                });
            }
            return {
                ...state,
                isEventChecked: action.payload.checkedFlag,
                data: list,
                checkedData: []
            }

        case AppConstants.CHECK_REPORT_SUCCESS:
            let data = state.data;
            data && Array.isArray(data) && data.map(item => {
                if (action.payload && action.payload.data && (item.ReportId == action.payload.data.ReportId)) {
                    item.isEventChecked = action.payload.isChecked;
                }
            });
            if (!action.payload.isChecked) {
                const indx = state.checkedData.findIndex(v => v.ReportId === action.payload.data.ReportId);
                state.checkedData.splice(indx, indx >= 0 ? 1 : 0);
            }

            return {
                ...state,
                data,
                checkedData: action.payload.isChecked ? state.checkedData.concat(action.payload.data) : state.checkedData
            }

        default:
            return state;
    }
}

export function getAllEventLogs(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_EVENT_LOGS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.GET_ALL_EVENT_LOGS_SUCCESS:
            action.response && action.response.result && Array.isArray(action.response.result) && action.response.result.map((logs, index) => {
                logs['isEventLogChecked'] = false
            })
            return {
                ...state,
                data: action.response.result,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record, 
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.GET_ALL_EVENT_LOGS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }

        case AppConstants.CHECK_EVENT_LOG_SUCCESS:
            let data = state.data;
            data && Array.isArray(data) && data.map(item => {
                if (action.payload && action.payload.data && (item.id == action.payload.data.id)) {
                    item.isAlertLogChecked = action.payload.isChecked;
                }
            });
            if (!action.payload.isChecked) {
                const indx = state.checkedData.findIndex(v => v.id === action.payload.data.id);
                state.checkedData.splice(indx, indx >= 0 ? 1 : 0);
            }

            return {
                ...state,
                data,
                checkedData: action.payload.isChecked ? state.checkedData.concat(action.payload.data) : state.checkedData
            }

        default:
            return state;
    }
}

export function deleteEvent(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.DELETE_EVENT:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.DELETE_EVENT_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.DELETE_EVENT_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.DELETE_EVENT_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state
    }
}

export function createEvent(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.CREATE_EVENT:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.CREATE_EVENT_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.CREATE_EVENT_FAILURE:
            return {
                ...state,
                error: true,
                data: action.payload.response.message,
                loading:false,
                success: false
            }
        case AppConstants.CREATE_EVENT_RESET:
            return {
                ...state,
                //loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function updateEvent(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.UPDATE_EVENT:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.UPDATE_EVENT_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.UPDATE_EVENT_FAILURE:
            return {
                ...state,
                error: true,
                data: action.payload.response.message,
								loading: false,
                success: false
            }
        case AppConstants.UPDATE_EVENT_RESET:
            return {
                ...state,
                //loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state
    }
}

export const eventReducer = {
    getAllEvents,
    getAllEventLogs,
    deleteEvent,
    createEvent,
    updateEvent
}