import {
  GET_INSIGHT_ALERT_SUMMARY_DATA,
  GET_INSIGHT_ALERT_SUMMARY_DATA_SUCCESS,
  GET_INSIGHT_ALERT_SUMMARY_DATA_FAILURE,
  GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA,
  GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA_SUCCESS,
  GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA_FAILURE,
  GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA,
  GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA_SUCCESS,
  GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA_FAILURE,
  GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA_RESET
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  errorMessage: undefined,
  success: undefined
};

const summaryTypeCollection = ['Insights', 'Alerts'];

export const getInsightAlertSummaryData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INSIGHT_ALERT_SUMMARY_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: {
            ...state.data[action.payload.widgetId]
          }
        },
        loading: true,
        error: false,
        success: undefined
      };

    case GET_INSIGHT_ALERT_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.assetId]:
              action.param.type === summaryTypeCollection[0]
                ? {
                  count: action.response.open_critical_events,
                  graph: action.response.insight_data.reduce(
                    (memo, _graph) => {
                      const { date, open_critical_events: count } = _graph;

                      return [
                        ...memo,
                        {
                          date,
                          count
                        }
                      ];
                    },
                    []
                  )
                }
                : {
                  count: action.response.alert_count,
                  graph: action.response.alert_graph.reduce(
                    (memo, _graph) => {
                      const { date, alerts: count } = _graph;

                      return [
                        ...memo,
                        {
                          date,
                          count
                        }
                      ];
                    },
                    []
                  )
                }
          }
        },
        error: false,
        loading: false,
        success: true
      };

    case GET_INSIGHT_ALERT_SUMMARY_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
        loading: false
      };

    default:
      return state;
  }
};


export const getInsightAlertSummaryCbmAssetData = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        success: undefined,
        error: false
      };

    case GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.result,
        success: true,
        error: false,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record
      };

    case GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };
    default:
      return state;
  }
};

export const getInsightAlertSummaryAssetsMinData = (
  state = {
    data: undefined,
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: true,
        error: false,
        success: undefined
      };

    case GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: [...action.response.result]
        },
        error: false,
        loading: false,
        success: true
      };

    case GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: state.data,
        success: false,
        loading: false
      };

    case GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        success: undefined
      };

    default:
      return state;
  }
};