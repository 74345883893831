import {
  GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA,
  GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA_SUCCESS,
  GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA_FAILURE,
  GET_ASSET_DETAIL_LIST_ASSETS_DATA,
  GET_ASSET_DETAIL_LIST_ASSETS_DATA_SUCCESS,
  GET_ASSET_DETAIL_LIST_ASSETS_DATA_FAILURE,
  GET_ASSET_CBM_ASSET_NAMES_MEMBER,
  GET_ASSET_CBM_ASSET_NAMES_MEMBER_SUCCESS,
  GET_ASSET_CBM_ASSET_NAMES_MEMBER_FAILURE,
  GET_SYSTEM_DETAIL_LIST,
  GET_SYSTEM_DETAIL_LIST_SUCCESS,
  GET_SYSTEM_DETAIL_LIST_FAILURE
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  errorMessage: undefined,
  success: undefined
};

export const getAssetDetailListCbmAssetData = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        error: false,
        loading: false,
        success: true
      };
    case GET_ASSET_DETAIL_LIST_CBM_ASSETS_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    default:
      return state;
  }
};

export const getAssetDetailListAssetsData = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_ASSET_DETAIL_LIST_ASSETS_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };

    case GET_ASSET_DETAIL_LIST_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        tabActiveIndex: action.param.tabActiveIndex,
        error: false,
        loading: false,
        success: true
      };
    case GET_ASSET_DETAIL_LIST_ASSETS_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    default:
      return state;
  }
};

export const getAssetCbmAssetNamesMember = (
  state = {
    data: [],
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_ASSET_CBM_ASSET_NAMES_MEMBER:
      return {
        ...state,
        data: [],
        loading: true,
        success: undefined,
        error: false
      };

    case GET_ASSET_CBM_ASSET_NAMES_MEMBER_SUCCESS:
      return {
        ...state,
        data: action.response,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case GET_ASSET_CBM_ASSET_NAMES_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};

export const getSystemDetailList = (
  state = {
    data: undefined,
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_SYSTEM_DETAIL_LIST:
      return {
        ...state,
        loading: true,
        success: undefined,
        error: false
      };

    case GET_SYSTEM_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        data: action.response,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        loading: false,
        success: true
      };

    case GET_SYSTEM_DETAIL_LIST_FAILURE:
      return {
        ...state,
        data: undefined,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};
