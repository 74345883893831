import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import valueGet from '../common/valueGet';
import { Select, Button } from '../../../../../../../common/components';

const cutOffCollection = [0, 12, 24, 48, 96];

const OthersConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [cutOff, setCutOff] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setCutOff((conf.cutOff && conf.cutOff.value) || cutOffCollection[0]);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={1}>
            <div className='new-input-wrap'>
              <label className='label-text'>CutOff Period</label>

              <Select
                options={cutOffCollection.reduce(
                  (memo, value) => [...memo, { label: value, value }],
                  []
                )}
                isMulti={false}
                placeholder='Select CutOff Period'
                value={(() => {
                  const value = cutOff;

                  return {
                    label: value,
                    value
                  };
                })()}
                onChange={({ value }) => {
                  setCutOff(value);

                  props.appInputParamChanged({ cutOff: valueGet(value) });
                }}
              />
            </div>
          </Col>
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default OthersConfig;
