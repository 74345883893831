import React, { useState, useEffect } from 'react';
import SelectDropDown from '../../../../../common/components/Select';
import { Label } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function BasicLocationSelector(props) {

  var [locations, setLocations] = useState([]);
  var [selectedLoc, setSelectedLoc] = useState("");

  useEffect(() => {
    if(props.appParams.location && props.appParams.location.value){
      let loc = locations.filter(l => l.value === props.appParams.location.value);
      setSelectedLoc(loc);
    }
  }, [props.appParams]);

  useEffect(() => {
    let locations = JSON.parse(localStorage.getItem('smartsense.locationlist'));
    locations = locations ? Object.values(locations).map(loc => { return { value: loc.locationid, label: loc.name } }) : [];
    setLocations(locations);
  }, []);

  return <Col className="select-dropdown custom-select-parameter" xl={props.width ? props.width : 12} >
    <div>
      <Label for={props.name} className='label'>{props.label}
        {props.required === true ? <span className='text-danger'> * </span> : null}
      </Label>
      <SelectDropDown
        name={props.name}
        value={selectedLoc}
        onChange={(e) => { props.onChange(e.value, false, props.name) }}
        options={locations}
        placeholder={props.placeholder}
        isMulti={props.isMulti}
      />
    </div>
  </Col>

}

const mapStateToProps = (state) => {
  return {};
};

BasicLocationSelector.defaultProps = {
  appParams: {},
  appInputParamChanged: function () {}
}

BasicLocationSelector.propTypes = {
  appParams: PropTypes.object,
  appInputParamChanged: PropTypes.func
}

export default connect(mapStateToProps, {})(BasicLocationSelector);