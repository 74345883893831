import React, { useState, useEffect, Fragment, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import { ModalBox, Button } from '../../../../../common/components/';
import MemoizedWidget from "../DashboardContainer/MemoizedWidget";
import { closePoputWidget, setPoputWidget } from '../../action';

const WidgetPopout = (props) => {
  const [isMinimized, setIsMinimized] = useState(false);

  if(!props.widget) return null;

  const minimize = () => setIsMinimized(!isMinimized);

  const getPopoutWidget = (widget) => {
    return (
      <div className = 'popout-widget'>
        <Suspense fallback={<div>Loading Files...</div>}>
          <MemoizedWidget
            Widget={widget.view}
            dashboardAppConfig={widget.config}
            widgetId={widget.id}
            dashboardId={props.widget.dashboard_id.id}
            refresh={{}}
          />
        </Suspense>
      </div>
    )
  }

  const close = () => {
    props.closePoputWidget();
    props.setPoputWidget();
  }

  return (
    <div className = {`popout-widget-modal ${props.isOpen ? 'show' : ''} ${isMinimized ? ' minimize' : ''}`}>
      <Row className = 'popout-widget-modal-header' onClick={minimize}>
        <Col xl = {10}>
          <h3>{props.widget.config.widget_name}</h3>
        </Col>
        <Col xl = {2}>
          <span className="popout-widget-modal-minimize" onClick={minimize}>
            {
              isMinimized ? 
              <i className="fa fa-plus" aria-hidden="true"/>
              :
              <i className="fa fa-minus" aria-hidden="true"/>
            }
          </span>
          <span className="popout-widget-modal-close" onClick={close}>
            <i className="fa fa-window-close" aria-hidden="true"/>
          </span>
        </Col>
      </Row>
      <div className = 'popout-widget-modal-body'>
        {props.widget && getPopoutWidget(props.widget)}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.isPopoutWidgetOpen.isOpen,
    widget: state.setPopoutWidget.widget
  }
}

export default withRouter(connect(mapStateToProps, {
  closePoputWidget,
  setPoputWidget
})(WidgetPopout));