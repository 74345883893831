import { REPORT_DOWNLOAD_WIDGET_DATA, REPORT_DOWNLOAD_WIDGET_DATA_SUCCESS, REPORT_DOWNLOAD_WIDGET_DATA_FAILURE } from "./constant";

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined,
};

const WIDGET_INITIAL_STATE = {
  data: { ...INITIAL_STATE, data: {} },
  loading: false,
  error: false,
  success: undefined,
};

export function getReportDownloadData(state = WIDGET_INITIAL_STATE, action) {
  switch (action.type) {
    case REPORT_DOWNLOAD_WIDGET_DATA:
      let prevData = state.data;
      prevData[action.payload.widgetId] = { ...INITIAL_STATE, loading: true };
      return {
        ...state,
        loading: true,
        data: prevData,
        error: false,
        success: undefined,
      };
    case REPORT_DOWNLOAD_WIDGET_DATA_SUCCESS:
      let newData = state.data;
      let res = action.response;
      newData[action.param.widgetId] = {
        ...newData[action.param.widgetId],
        data: [...state.data[action.param.widgetId].data, ...res.result],
        loading: false,
        success: true,
        error: false
      };

      return {
        ...state,
        data: newData,
        error: false,
        loading: false,
        success: true,
      };
    case REPORT_DOWNLOAD_WIDGET_DATA_FAILURE:
      let errorData = state.data;
      errorData[action.payload.widgetId] = { ...INITIAL_STATE, error: true };

      return {
        ...state,
        error: true,
        data: errorData,
        success: false,
      };
    default:
      return state;
  }
}
