import queryString from 'query-string';

import {
  GE_WIDGET_TEMPLATE,
  GE_WIDGET_TEMPLATE_SUCCESS,
  GE_WIDGET_TEMPLATE_FAILURE
} from './constant';

export function getGHGEmissionsTemplate(
  state = {
    data: {},
    loading: false,
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case GE_WIDGET_TEMPLATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: state.data[action.payload.widgetId] || {}
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: true
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    case GE_WIDGET_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.index]: action.param.isComputed
              ? { ...action.param, value: action.response.response.data }
              : { ...action.param, ...action.response[0] }
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case GE_WIDGET_TEMPLATE_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}

export const ghgEmissionsReducers = {
  ghEmissionsTemplateResp: getGHGEmissionsTemplate
};
