import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import {
  GET_INSIGHT_ALERT_SUMMARY_DATA,
  GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA,
  GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA,
} from './constant';
import { httpMethodGetJSON } from '../../../../https';

import { URL } from '../../../../constants';

const getAlertsApi = (assetId) => `${URL}/alerts/asset/${assetId}/alert-logs/`;
const getInsightsApi = (assetId) =>
  `${URL}/asset-cbm/assets/${assetId}/overview/`;

const summaryTypeCollection = ['Insights', 'Alerts'];

export const getInsightAlertSummaryData = (action$) => {
  return action$.pipe(
    ofType(GET_INSIGHT_ALERT_SUMMARY_DATA),
    mergeMap((action) => {
      switch (action.payload.type) {
        case summaryTypeCollection[1]:
          return httpMethodGetJSON(
            action,
            getAlertsApi(action.payload.assetId)
          );

        default:
          return httpMethodGetJSON(
            action,
            getInsightsApi(action.payload.assetId)
          );
      }
    })
  );
};

export const getInsightAlertSummaryCbmAssetData = (action$) =>
  action$.pipe(
    ofType(GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/asset-names/stats?page_size=100&page_no=1`
      );
    })
  );

export const getInsightAlertSummaryAssetsMinData = (action$) => {
  return action$.pipe(
    ofType(GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA),
    mergeMap((action) => httpMethodGetJSON(action, `${URL}/asset-cbm/asset-names/assets-min-info`))
  );
};