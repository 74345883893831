import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';



export default class List extends React.Component {

  render () {
    const items = [];
    let len = this.props.count;

    for (let i=0; i < len; i++) {
        items.push(<ListGroupItem className = 'listItem' key = {Math.random()}> {
          this.props.getListElement(i)
        } 
      </ListGroupItem>
    );
    }

    return (
      <ListGroup className = 'list'>
        {items}
      </ListGroup>
    );
  }
}
