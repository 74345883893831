import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';

import { getTemplateWidgets } from '../../action';
import { getSystemTemplateWidgets } from '../../../AssetScreenDesign/action';
import SelectDropDown from '../../../../../common/components/Select';
import { Button } from '../../../../../common/components';
import { Label, Col, Row } from 'reactstrap';

const PAGE_SIZE = 200;

function ContainerWidgetSelect(_props) {
  const { getTemplateWidgets, getSystemTemplateWidgets, ...props } = _props;

  var [widgetList, setWidgetList] = useState(['']);

  const location = useLocation();

  const {
    assetId: assetLocationId,
    system_id,
    system_type_id,
    template_id
  } = parse(location.search);

  const widgetsData = useMemo(() => {
    switch (true) {
      case location.state?.systemFlag && !!props._widgetsData:
        return props._widgetsData;

      case !!props.widgetsData:
        return props.widgetsData;

      default:
        return [];
    }
  }, [location.state, props.widgetsData, props._widgetsData]);

  useEffect(() => {
    switch (true) {
      case location.state?.systemFlag:
        return getSystemTemplateWidgets({ system_id: template_id });

      default:
        return getTemplateWidgets({
          template_id: props.dashobardId,
          page_no: 1,
          page_size: PAGE_SIZE
        });
    }
  }, [
    location.state,
    template_id,
    props.dashobardId,
    getTemplateWidgets,
    getSystemTemplateWidgets
  ]);

  useEffect(() => {
    if (Array.isArray(props.value) && props.value.length > 0) {
      setWidgetList(props.value);
    }
  }, [props.value]);

  const editWidgetConfiguration = (widget) => {
    setTimeout(() => {
      props.history.go(0);
    }, 0);

    switch (true) {
      case location.state.systemFlag:
        return props.history.push({
          pathname: `/System/WidgetBuilder/${template_id}/${widget.widget_id.id}`,
          search: stringify({
            edit_id: widget.id,
            system_id,
            system_type_id,
            template_id
          }),
          state: {
            ...location.state,
            widget: widget
          }
        });

      default:
        return props.history.push({
          pathname: `/Templates/WidgetBuilder/${props.dashobardId}/${widget.widget_id.id}`,
          search: stringify({
            assetId: assetLocationId,
            edit_id: widget.id
          }),
          state: {
            ...location.state,
            widget: widget
          }
        });
    }
  };

  return (
    <Col
      className='select-dropdown custom-select-parameter'
      xl={props.width ? props.width : 12}
    >
      <div className='add-widget-btn'>
        <Button
          buttonType='primary'
          size='sm'
          buttonClick={() => {
            let oldWidgetList = [...widgetList];

            oldWidgetList.push('');

            setWidgetList([...oldWidgetList]);
          }}
          innerContent='Add Widget'
          className='buttonFill ml-0'
        />
      </div>

      {widgetList.map((item, index) => {
        return (
          <Row key={index} className='mb-3'>
            {
              <span
                className='text-danger delete'
                onClick={() => {
                  let oldWidgetList = [...widgetList];

                  oldWidgetList.splice(index, 1);

                  setWidgetList([...oldWidgetList]);

                  props.onChange([...oldWidgetList]);
                }}
              >
                Delete
              </span>
            }

            <span
              className='text-edit edit'
              onClick={() => {
                let a = widgetsData.filter((elem) => {
                  return elem.id === item;
                })[0];

                editWidgetConfiguration(a);
              }}
            >
              Edit
            </span>

            <Col xl={12} className='select-container'>
              <Label for={props.name} className='label'>
                Select Widget
                {props.required === true ? (
                  <span className='text-danger'> * </span>
                ) : null}
              </Label>

              <SelectDropDown
                name='widget'
                value={
                  widgetsData
                    .filter((elem) => {
                      return elem.id === item;
                    })
                    .map((el) => {
                      el.label =
                        el.config && el.config.widget_name
                          ? el.config.widget_name
                          : '';

                      el.value = el.id;

                      return el;
                    })[0]
                }
                onChange={(e) => {
                  let oldWidgetList = [...widgetList];

                  oldWidgetList[index] = e.id;

                  setWidgetList([...oldWidgetList]);

                  props.onChange([...oldWidgetList], oldWidgetList === 0);
                }}
                options={widgetsData
                  .filter((w) => {
                    return !widgetList.includes(w.id);
                  })
                  .map((a) => {
                    a.label =
                      a.config && a.config.widget_name
                        ? a.config.widget_name
                        : '';
                    a.value = a.id;
                    return a;
                  })}
                placeholder={props.placeholder}
                isMulti={false}
                menuPlacement='top'
              />
            </Col>
          </Row>
        );
      })}
    </Col>
  );
}

const filterdWidgetsDataGet = (widgetsData) => {
  return widgetsData?.filter((wid) => {
    let type = wid.widget_id?.type;

    return ['Group'].includes(type);
  });
};

const mapStateToProps = (state) => {
  return {
    widgetsData: filterdWidgetsDataGet(state.getTemplateWidgets.data),
    _widgetsData: filterdWidgetsDataGet(state.systemTemplateWidgets.data)
  };
};

ContainerWidgetSelect.defaultProps = {
  appParams: {},
  appInputParamChanged: function () {
    // eslint-disable-next-line no-empty
  }
};

ContainerWidgetSelect.propTypes = {
  appParams: PropTypes.object,
  appInputParamChanged: PropTypes.func
};

export default withRouter(
  connect(mapStateToProps, {
    getTemplateWidgets,
    getSystemTemplateWidgets
  })(ContainerWidgetSelect)
);
