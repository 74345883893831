import moment from 'moment';
import { memoize as _memoize } from 'lodash';


export const isPartnerLogin = localStorage.getItem('partner_login');

export const dropdownData = (data) => {
    const array = [];
    data.map((item, i) => {
        array.push({ value: item.id, label: item.name });
    });
    return array;
};

export const getFormattedUserlocation = (users) => {
    let formattedloc = {};

    if (!users) return formattedloc;

    users.map((user, i) => {
        let locs = [];
        let plants = [];
        let fromatted = [];

        user.locations.map((loc, i) => {
            locs.push({ id: loc.locationid, name: loc.name });
        });

        user.plants.map((plant, i) => {
            plants.push({ id: plant.plantid, name: plant.name });
        });

        fromatted = [locs, plants];
        formattedloc[user.memberid] = fromatted;
    });

    return formattedloc;
};

export const generateTree = (arr) => {
    var tree = [],
        mappedArr = {},
        arrElem,
        mappedElem;
    let stateMappedlocatiaons = [];
    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
        arrElem = arr[i];
        mappedArr[arrElem.locationid] = arrElem;
        mappedArr[arrElem.locationid]['children'] = [];

        let stateLocObj = {
            id: arrElem.locationid,
            name: arrElem.name,
        };

        stateMappedlocatiaons.push(stateLocObj);
    }

    for (var id in mappedArr) {
        if (mappedArr.hasOwnProperty(id)) {
            mappedElem = mappedArr[id];
            // If the element is not at the root level, add it to its parent array of children.
            if (mappedElem.parentlocationid) {
                if (mappedArr[mappedElem['parentlocationid']]) {
                    mappedElem.parentLocationName = mappedArr[mappedElem.parentlocationid].name;
                    mappedArr[mappedElem['parentlocationid']]['children'].push(mappedElem);
                } else {
                    tree.push(mappedElem);
                }
            }
            // If the element is at the root level, add it to first level elements array.
            else {
                tree.push(mappedElem);
            }
        }
    }

    return [tree, stateMappedlocatiaons];
};

export const convertTreeToList = (root) => {
    var stack = [],
        array = [];
    stack.push(root);
    while (stack.length !== 0) {
        var node = stack.pop();
        array.push(node);
        for (var i = node.children.length - 1; i >= 0; i--) {
            stack.push(node.children[i]);
        }
    }
    return array;
};

export const graphConfig = {
    motor: [
        {
            'Imbalance Analysis': {
                graphs: [
                    {
                        title: 'Voltage Imb',
                        type: 'line',
                        readingtypeid: [5039],
                        colors: ['#4C74F2'],
                        referenceLines: [2],
                    },
                    {
                        title: 'Current Imb',
                        readingtypeid: [5040],
                        type: 'line',
                        colors: ['#9c27b0'],
                        referenceLines: [10],
                    },
                ],
            },
        },
        {
            'Voltage Analysis': {
                graphs: [
                    {
                        title: 'Voltage Analysis',
                        readingtypeid: [2013, 2014, 2015],
                        type: 'line',
                        colors: ['#4C74F2', '#CC9600', '#5E0019'],
                    },
                ],
            },
        },
        {
            'Current Analysis': {
                graphs: [
                    {
                        title: 'Current Analysis',
                        readingtypeid: [2019, 2020, 2021],
                        type: 'line',
                        colors: ['#4C74F2', '#CC9600', '#5E0019'],
                    },
                ],
            },
        },
        {
            'Loading Analysis': {
                graphs: [
                    {
                        title: 'Loading Analysis',
                        readingtypeid: [2002],
                        type: 'line',
                        colors: ['#5E0019'],
                        grain: 15
                    },
                    {
                        title: 'Loading Analysis',
                        readingtypeid: [5031, 5032, 5033, 5034],
                        type: 'bar',
                        colors: ['#4C74F2', '#CC9600', '#5E0019', '#113FF4'],
                        grain: 1440 * 7
                    },
                ],
            },
        },
        {
            'PF Analysis': {
                graphs: [
                    {
                        title: 'PF_Analysis',
                        readingtypeid: [2048, 2049, 2050],
                        type: 'line',
                        colors: ['#4C74F2', '#CC9600', '#5E0019'],
                    },
                ],
            },
        },
    ],
    tx: [
        {
            'Imbalance Analysis': {
                graphs: [
                    {
                        title: 'Voltage Imb',
                        type: 'line',
                        readingtypeid: [5039],
                        colors: ['#4C74F2'],
                    },
                    { title: 'Current Imb', readingtypeid: [5040], type: 'line', colors: ['#9c27b0'] },
                ],
            },
        },
        {
            'Voltage Analysis': {
                graphs: [
                    {
                        title: 'HT',
                        readingtypeid: [2013, 2014, 2015],
                        type: 'line',
                        colors: ['#4C74F2', '#CC9600', '#5E0019'],
                    },
                    {
                        title: 'LT',
                        readingtypeid: [2013, 2014, 2015],
                        type: 'line',
                        colors: ['#4C74F2', '#CC9600', '#5E0019'],
                    },
                ],
            },
        },
        {
            'Current Analysis': {
                graphs: [
                    {
                        title: 'HT',
                        readingtypeid: [2019, 2020, 2021],
                        type: 'line',
                        colors: ['#4C74F2', '#CC9600', '#5E0019'],
                    },
                    {
                        title: 'LT',
                        readingtypeid: [2019, 2020, 2021],
                        type: 'line',
                        colors: ['#4C74F2', '#CC9600', '#5E0019'],
                    },
                ],
            },
        },
        {
            'Tap Change Operation': {
                graphs: [
                    {
                        title: '',
                        readingtypeid: [2012],
                        type: 'scatter',
                        colors: ['#4C74F2'],
                    },
                ],
            },
        },
        {
            'Loss vs Loading': {
                graphs: [
                    {
                        title: '',
                        readingtypeid: [5053, 5049],
                        type: 'line',
                        colors: ['#4C74F2', '#5E0019'],
                    },
                ],
            },
        },
        {
            'Loading Analysis': {
                graphs: [
                    {
                        title: 'Loading Analysis',
                        readingtypeid: [5053],
                        type: 'line',
                        colors: ['#4C74F2'],
                        grain: 15
                    },
                    {
                        title: 'Loading Analysis',
                        readingtypeid: [5031, 5032, 5033, 5034],
                        type: 'bar',
                        colors: ['#4C74F2', '#CC9600', '#5E0019', '#113FF4'],
                        grain: 1440 * 7
                    },
                ],
            },
        },
        {
            'THD Analysis': {
                graphs: [
                    {
                        title: 'Voltage THD',
                        readingtypeid: [2046],
                        type: 'line',
                        colors: ['#CC9600'],
                    },
                    { title: 'Current THD', readingtypeid: [2047], type: 'line', colors: ['#113FF4'] },
                ],
            },
        },
        {
            'KVAR Analysis': {
                graphs: [
                    {
                        title: 'KVAR Analysis',
                        readingtypeid: [2007],
                        type: 'line',
                        colors: ['#4C74F2'],
                    },
                ],
            },
        },
    ],
    chiller: [
        {
            Compressor: [
                {
                    Pressure: {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6044, 6013],
                                colors: ['#4C74F2', '#CC9600'],
                            },
                        ],
                    },
                },
                {
                    Temperature: {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6015, 6046],
                                colors: ['#4C74F2', '#CC9600'],
                            },
                        ],
                    },
                },
            ],
        },
        {
            Performance: [
                {
                    KW: {
                        graphs: [{ title: '', type: 'area', readingtypeid: [6063], colors: ['#4C74F2'] }],
                    },
                },
                {
                    COP: {
                        graphs: [{ title: '', type: 'area', readingtypeid: [6138], colors: ['#CC9600'] }],
                    },
                },
                {
                    'iKW/TR': {
                        graphs: [{ title: '', type: 'area', readingtypeid: [6139], colors: ['#4C74F2'] }],
                    },
                },
                {
                    'Chiller Capacity [TR]': {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6134],
                                colors: ['#4C74F2'],
                            },
                        ],
                    },
                },
            ],
        },
        {
            Condenser: [
                {
                    'Condenser Water Temperature': {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6009, 6010],
                                colors: ['#4C74F2'],
                            },
                        ],
                    },
                },
                {
                    'Condenser Approach': {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6131],
                                colors: ['#4C74F2'],
                            },
                        ],
                    },
                },
                {
                    'Condenser Water Flow Rate': {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6137],
                                colors: ['#4C74F2'],
                            },
                        ],
                    },
                },
                {
                    'Condenser Water Delta T': {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6141],
                                colors: ['#4C74F2'],
                            },
                        ],
                    },
                },
            ],
        },
        {
            Evaporator: [
                {
                    'Evaporator Approach': {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6133],
                                colors: ['#4C74F2'],
                            },
                        ],
                    },
                },
                {
                    'Chilled Water Delta T': {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6140],
                                colors: ['#4C74F2'],
                            },
                        ],
                    },
                },
                {
                    'Evaporator Water Flow Rate': {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6136],
                                colors: ['#4C74F2'],
                            },
                        ],
                    },
                },
                {
                    'Chilled Water Temperature': {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6001, 6002],
                                colors: ['#4C74F2'],
                            },
                        ],
                    },
                },
            ],
        },
        {
            'Cooling Tower': [
                {
                    'Cooling Tower Approach': {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6135],
                                colors: ['#4C74F2'],
                            },
                        ],
                    },
                },
                {
                    'Cooling Tower Effectiveness': {
                        graphs: [
                            {
                                title: '',
                                type: 'area',
                                readingtypeid: [6144],
                                colors: ['#4C74F2'],
                            },
                        ],
                    },
                },
            ],
        },
    ],
    ct: [
        {
            'Water Inlet Temperature': {
                graphs: [
                    {
                        title: 'Water Inlet Temperature Data',
                        type: 'line',
                        reading_names: ['Supply Air Temperature', 'Return Air Temperature'],
                        readingtypeid: [6369, 6370, 6350, 6351],
                        colors: ['#4C74F2', '#9c27b0', '#2c27b0', '#CC9600'],
                        references: [[], [18, 34]],
                    },
                ],
            },
        },
        {
            Range: {
                graphs: [
                    {
                        title: 'Range',
                        type: 'line',
                        reading_names: ['Range'],
                        readingtypeid: [6373],
                        colors: ['#4C74F2'],
                        references: [[5]],
                    },
                ],
            },
        },
        {
            'CT Approach': {
                graphs: [
                    {
                        title: 'CT Approach',
                        type: 'line',
                        reading_names: ['CT Approach'],
                        readingtypeid: [6372],
                        colors: ['#4C74F2'],
                        references: [[8]],
                    },
                ],
            },
        },
        {
            'CT Effectiveness': {
                graphs: [
                    {
                        title: 'CT Effectiveness',
                        type: 'line',
                        readingtypeid: [6144],
                        reading_names: ['CT Effectiveness'],
                        colors: ['#4C74F2'],
                        references: [[45, 70]],
                    },
                ],
            },
        },
        {
            'Voltage Imbalance': {
                graphs: [
                    {
                        title: 'Voltage Imbalance',
                        type: 'line',
                        readingtypeid: [5039],
                        reading_names: ['Voltage Imbalance'],
                        colors: ['#4C74F2'],
                        references: [[45, 70]],
                    },
                ],
            },
        },
        {
            'Current Imbalance': {
                graphs: [
                    {
                        title: 'Current Imbalance',
                        type: 'line',
                        readingtypeid: [5040],
                        reading_names: ['Current Imbalance'],
                        colors: ['#4C74F2'],
                        references: [[10]],
                    },
                ],
            },
        },
        {
            'Percentage Loading': {
                graphs: [
                    {
                        title: 'Percentage Loading',
                        type: 'line',
                        readingtypeid: [5053],
                        reading_names: ['Percentage Loading'],
                        colors: ['#4C74F2'],
                        references: [[3, 1.15]],
                    },
                ],
            },
        },
        {
            '3 Phase Voltage': {
                graphs: [
                    {
                        title: '3 Phase Voltage',
                        type: 'line',
                        readingtypeid: [2012, 2013, 2014, 2015],
                        colors: ['#4C74F2', '#9c27b0', '#2c27b0', '#CC9600'],
                    },
                ],
            },
        },
        {
            '3 Phase Current': {
                graphs: [
                    {
                        title: '3 Phase Current',
                        type: 'line',
                        readingtypeid: [2004, 2019, 2020, 2021],
                        colors: ['#4C74F2', '#9c27b0', '#2c27b0', '#CC9600'],
                    },
                ],
            },
        },
    ],
    ahu: [
        {
            'Air Temperature': {
                graphs: [
                    {
                        title: 'Air Temperature',
                        type: 'line',
                        reading_names: [
                            'Supply Air Temperature',
                            'Return Air Temperature',
                            'Return Air Temperature SetPoint',
                        ],
                        readingtypeid: [6336, 6337, 6335],
                        colors: ['#2c27b0', '#CC9600', '#4C74F2'],
                        references: [[14], [25]],
                    },
                ],
            },
        },
        {
            'Air Humidity': {
                graphs: [
                    {
                        title: 'Air Humidity',
                        type: 'line',
                        reading_names: ['Return Air Relative Humidity', 'CO2'],
                        readingtypeid: [6343, 6339],
                        colors: ['#2c27b0', '#CC9600'],
                        references: [[50, 60], [405]],
                    },
                ],
            },
        },
        {
            'Filter Status': {
                graphs: [
                    {
                        title: 'Filter Status',
                        type: 'bar',
                        reading_names: ['Filter Status', 'AHU Fan Run Status'],
                        readingtypeid: [6701, 6700],
                        colors: ['#4C74F2', '#9c27b0'],
                    },
                ],
            },
        },
        {
            'CHW Valve': {
                graphs: [
                    {
                        title: 'CHW Valve Opening and Feedback',
                        type: 'line',
                        reading_names: ['CHW Valve Opening', 'CHW Valve Feedback'],
                        readingtypeid: [6342, 6341],
                        colors: ['#4C74F2', '#9c27b0'],
                        references: [[10, 100]],
                    },
                ],
            },
        },
        {
            'Variable Air Volume Temperature': {
                graphs: [
                    {
                        title: 'Variable Air Volume Temperature',
                        type: 'line',
                        readingtypeid: [6348],
                        reading_names: ['Variable Air Volume Temperature'],
                        colors: ['#4C74F2'],
                        references: [[22, 25]],
                    },
                ],
            },
        },
        {
            'Performance Index': {
                graphs: [
                    {
                        title: 'Performance Index',
                        readingtypeid: [6368],
                        type: 'line',
                        colors: ['#4C74F2'],
                        reading_names: ['Performance Index'],
                        references: [[0.95, 1.05]],
                    },
                ],
            },
        },
    ],
};

export const flatten = (input) => {
    const stack = [...input];
    const res = [];
    while (stack.length) {
        // pop value from stack
        const next = stack.pop();
        if (next.children && next.children.length > 0) {
            // push back array items, won't modify the original input
            stack.push(...next.children);
        } else {
            res.push(next);
        }
    }
    // reverse to restore input order
    return res.reverse();
}


// Elipse the text
export function ellipsisText(text, limit) {
    var defaultLimit = 86;
    if (!limit) limit = defaultLimit;

    if (text && text.length > limit) {
        return text.substring(0, limit) + "...";
    }
    return text;
}

export function ConvertToLocalUsingMoment(dateTime, dateTimeFormat) {
    let local = moment(dateTime).tz('UTC').format(dateTimeFormat)
    // let gmtDateTime = moment.utc(dateTime);
    // let local = gmtDateTime.local().format(dateTimeFormat);
    return local;
}

export function capitalizeFirstLetter(string) {
    return string && string.charAt(0).toUpperCase() + string.slice(1);
}

export function getQueryString(payload = {}) {
    let searchQuery = ''

    Object.keys(payload).forEach((key, i, arr) => {
        if (i === 0) {
            searchQuery = '?';
        }
        searchQuery += (key + '=' + payload[key]);
        if (i !== (arr.length - 1)) {
            searchQuery += '&'
        }
    });

    return searchQuery;
}

export function getUserName(userId) {
    let username;
    getMembers() && getMembers().length > 0 && getMembers().map(user => {
        if (user.memberid == userId) {
            username = user.username;
        }
    });
    return username;
}

export function getUserFNameLName(userId) {
    let username;
    getMembers() && getMembers().length > 0 && getMembers().map(user => {
        if (user.memberid == userId) {
            username = `${user.firstname ? user.firstname : ""} ${user.lastname ? user.lastname : ""}`;
        }
    });
    return username;
}

export function getUserEmails(userIds) {
    let userEmailList = [];
    userIds && userIds.length > 0 && userIds.map(userid => {
        getMembers() && getMembers().length > 0 && getMembers().map(user => {
            if (user.memberid == userid) {
                userEmailList.push(user.email)
            }
        });
    })

    return userEmailList;
}

export function getMembers() {
    return JSON.parse(localStorage.getItem('smartsense.users')) || []
}

export const getLocationsAndParametersNameById = (id) => {
    if(!id) return '';

    const list = _.groupBy(getLocationsAndParameters(), 'value');
    return list && list[id] && list[id][0] && list[id][0].label;
}

export const getPreloadReadingTypes = _memoize(() => {
  const readingTypes =
    localStorage.getItem('smartsense.readingtypes') !== 'undefined'
      ? Object.values(
          JSON.parse(localStorage.getItem('smartsense.readingtypes'))
        ).map((rt) => {
          return { value: rt.readingtypeid, label: rt.readingtypename, parentreadingtypeid:rt.parentreadingtypeid };
        })
      : [];
  return readingTypes;
});

export const getPreloadCalculatedParameters = _memoize(() => {
  const calculatedList =
    localStorage.getItem('smartsense.computed_parameter_list') !==
      'undefined' &&
    JSON.parse(localStorage.getItem('smartsense.computed_parameter_list'));
  return (
    calculatedList &&
    calculatedList.map((rt) => {
      return { value: rt.id, label: rt.name, is_calculated: true };
    })
  );
});

export const getLocationsAndParameters = _memoize((plant) => {
  let metaParamOption =
    localStorage.getItem('template.asset_names') !== 'undefined' &&
    JSON.parse(localStorage.getItem('template.asset_names'));

  const versionParamMapping = JSON.parse(
    localStorage.getItem('smartsense.version_readingtype_mapping')
  );
  const preloadCalculatedParameters = getPreloadCalculatedParameters();

  const preloadReadingTypes = getPreloadReadingTypes();

  let path = location.pathname.split('/');

  let sensor_types =
    metaParamOption && metaParamOption.sensor_types
      ? metaParamOption.sensor_types
      : metaParamOption &&
        metaParamOption.asset_name &&
        metaParamOption.asset_name.sensor_types
      ? metaParamOption.asset_name.sensor_types
      : [];

  if (
    path.indexOf('Templates') === 1 &&
    sensor_types &&
    ![
      'CalculatedParameter',
      'CalculatedParameterTemplateCreation'
    ].reduce((memo, pathFragment) => {
      const match = path[2] === pathFragment;

      return match && !memo ? match : memo;
    }, false)
  ) {
    let specific_asset_names =
      localStorage.getItem('template.specific_asset_names') !== 'undefined' &&
      JSON.parse(localStorage.getItem('template.specific_asset_names'));

    let sensorsIdsTypeWise = _.groupBy(
      specific_asset_names && specific_asset_names.sensors,
      'type_id'
    );

    let locations = [];

    getPreloadSensors &&
      getPreloadSensors().map((sensor) => {
        sensor_types
          ? Object.values(sensor_types).map((loc) => {
              let sensorId =
                sensorsIdsTypeWise &&
                sensorsIdsTypeWise[loc.id] &&
                sensorsIdsTypeWise[loc.id][0] &&
                sensorsIdsTypeWise[loc.id][0].sensor_id
                  ? sensorsIdsTypeWise[loc.id][0].sensor_id
                  : undefined;

              let sensor_type_id =
                sensorsIdsTypeWise &&
                sensorsIdsTypeWise[loc.id] &&
                sensorsIdsTypeWise[loc.id][0] &&
                sensorsIdsTypeWise[loc.id][0].type_id
                  ? sensorsIdsTypeWise[loc.id][0].type_id
                  : undefined;

              if (sensor.sensorid == sensorId) {
                locations.push({
                  value: sensorId,
                  label: sensor.name,
                  reading_types: loc.reading_types,
                  types: [],
                  sensor_type_id
                });
                return {
                  value: sensorId,
                  label: sensor.name,
                  reading_types: loc.reading_types,
                  types: [],
                  sensor_type_id
                };
              }
            })
          : [];
      });

    let locParamMap =
      locations &&
      locations.map((loc) => {
        let checkComputedData =
          preloadCalculatedParameters &&
          preloadCalculatedParameters.find((sen) => sen.value === loc.value);
        var updatedTypes;
        if (checkComputedData == undefined) {
          updatedTypes =
            preloadCalculatedParameters && preloadCalculatedParameters;
        }
        if (!updatedTypes) {
          updatedTypes = [];
        }
        let updatedColletion =
          preloadReadingTypes &&
          preloadReadingTypes.filter(
            (t) => loc.reading_types && loc.reading_types.includes(t.value)
          );
        let updatedReadingTypeData = [...updatedColletion, ...updatedTypes];
        return { ...loc, types: updatedReadingTypeData };
      });
    return locParamMap;
  } else {
    const locParamMap =
      getPreloadLocations &&
      getPreloadLocations(plant).map((loc) => {
        let msgVer = loc && loc.messageversions;
        let readingTypeIds = [];

        Array.isArray(msgVer) &&
          msgVer.map((ver) => {
            if (!Array.isArray(versionParamMapping[ver])) return;
            readingTypeIds.push(...versionParamMapping[ver]);
          });

        const readingTypes = preloadReadingTypes;
        let filterReadingTypes = readingTypes.filter(
          (t) => readingTypeIds && readingTypeIds.includes(t.value)
        );
        let updatedReadingTypeData = [...filterReadingTypes];
        return { ...loc, types: Array.from(new Set(updatedReadingTypeData)) };
      });

    return locParamMap;
  }
});

export const getSeletedSensorReadingtype = (sensorid)=>{
    return getLocationsAndParameters().find((item)=>item.value == sensorid) || {types:[]}
}

export const getGroupedCalculatedParametersList = _memoize((plant) => {
  let arr = [];
  const DEFALUT_PARENT_NAME = 'Main Locations';
  let el = _.groupBy(getLocationsAndParameters(plant), (elem) => {
    return elem.parentlocationid ? elem.parentlocationid : DEFALUT_PARENT_NAME;
  });

  Object.keys(el).map((item) => {
    const loc = getLocationNameById(item);
    const label = isPartnerLogin
      ? item === DEFALUT_PARENT_NAME
        ? DEFALUT_PARENT_NAME
        : loc.label && `(${loc.clientname}) - ${loc.label}`
      : item === DEFALUT_PARENT_NAME
      ? DEFALUT_PARENT_NAME
      : loc.label;
    arr.push({
      label: label,
      options: el[item]
    });
  });

  return arr;
});

export function getLocationNameById(id) {
    if (!id) return {};

    let res = localStorage.getItem('smartsense.locationlist') ?
        Object.values(JSON.parse(localStorage.getItem('smartsense.locationlist')))
            .filter(loc => loc.locationid == id)[0]
        : {};

    return res || {}
}

const smartsenseReadingtypesGet = _.memoize(() => {
  return (
    (localStorage.getItem('smartsense.readingtypes') &&
      Object.values(
        JSON.parse(localStorage.getItem('smartsense.readingtypes'))
      )) ||
    []
  );
});

export function getReadingtypeNameById(id) {
  if (!id) return '';

  let res =
    smartsenseReadingtypesGet().filter((loc) => loc.readingtypeid == id)[0] ||
    {};

  return res && res.readingtypename;
}

export function getReadingtypeUnitById(id) {
    if (!id) return '';

    let res = localStorage.getItem('smartsense.readingtypes')
      ? Object.values(
          JSON.parse(localStorage.getItem('smartsense.readingtypes'))
        ).filter((loc) => loc.readingtypeid == id)[0]
      : {};

    return res && res.unit;
}

export function getReadingtypeById(id) {
    if (!id) return '';

    let res = localStorage.getItem('smartsense.readingtypes') ?
        Object.values(JSON.parse(localStorage.getItem('smartsense.readingtypes')))
            .filter(loc => loc.readingtypeid == id)[0]
        : {};

    return res;
}

export function getPreloadSensors() {
    return localStorage.getItem('smartsense.sensors') !== 'undefined' && JSON.parse(localStorage.getItem('smartsense.sensors'))
}

export const getPreloadLocations = _memoize(
  ({ value: { plantId } = {} } = {}) => {
    return localStorage.getItem('smartsense.locationlist')
      ? Object.values(
          JSON.parse(localStorage.getItem('smartsense.locationlist'))
        )
          .map((loc) => {
            return {
              value: loc.locationid,
              label: isPartnerLogin
                ? `(${loc.locationid}) - ${loc.name})`
                : loc.name,
              virtual: loc.virtual,
              parentlocationid: loc.parentlocationid,
              messageversions: messageVersionsByLocationId(loc),
              plant: loc.plant
            };
          })
          .reduce((memo, loc) => {
            const match =
              plantId === undefined ||
              plantId === 'All Plant' ||
              plantId === loc.plant;

            return match ? [...memo, loc] : memo;
          }, [])
      : [];
  }
);

export const getLocationSensorMapping = _memoize(() => {
    let locationSensorsMapping = {};
    const sensorlist = getPreloadSensors();
 
    for (var i = 0, len = sensorlist.length; i < len; i++) {
        var locationid = sensorlist[i]['mappedlocationid'];

        if (!locationSensorsMapping.hasOwnProperty(locationid)) {
            locationSensorsMapping[locationid] = [];
        }
        locationSensorsMapping[locationid].push(sensorlist[i].sensorid);
    }

    return locationSensorsMapping;
});


export const messageVersionsByLocationId = _memoize((loc) => {
    let versions = [], location = loc;
    const sensorlist = _.groupBy(
        localStorage.getItem('smartsense.sensors') ? 
        Object.values(JSON.parse(localStorage.getItem('smartsense.sensors'))) 
        : 
        []
        , 'sensorid');

    if (location) {
        if (!location.virtual) {
            var sensorids = getLocationSensorMapping()[location.locationid] || [];
            for (var i = 0; i < sensorids.length; i++) {

                versions.push(sensorlist[sensorids[i]][0] && sensorlist[sensorids[i]][0].messageversion);
            }
        } 
        else {
            let locTree = getLocationTree();
            locTree
            && Array.isArray(locTree[location.locationid])
            && locTree[location.locationid].forEach((child_location) => {
                versions = versions.concat(messageVersionsByLocationId(child_location));
            })
        }
    }
    return versions;
});

export const getLocationTree = _memoize(() => {
    let locationParentMapping = {};
    const locationlist = localStorage.getItem('smartsense.locationlist') ? 
        JSON.parse(localStorage.getItem('smartsense.locationlist')) 
        : 
        [];

    for (var i = 0, len = locationlist.length; i < len; i++) {
        var parent = locationlist[i]['parentlocationid'] || 0;
        if (!locationParentMapping.hasOwnProperty(parent)) {
            locationParentMapping[parent] = [];
        }
        locationParentMapping[parent].push(locationlist[i]);
    }

    return locationParentMapping;
});

export const inValidatePreloadCache = () => {
    getGroupedCalculatedParametersList && 
        getGroupedCalculatedParametersList.cache &&
        getGroupedCalculatedParametersList.cache.clear();

    getLocationsAndParameters && 
        getLocationsAndParameters.cache &&
        getLocationsAndParameters.cache.clear();
}