import { combineEpics } from 'redux-observable';
import {getMemberDataEpic, getRoleDataEpic, createRoleEpic, createMemberEpic, editRoleEpic, deleteRoleEpic, deleteMemberEpic, editMemberEpic} from './UserRole/epic'
import {getStatsDataEpic, getAssetDataEpic, getTimelineDataEpic, getAllAssetsForDropdown} from './AssetInsights/epic'
import { getAssetDetailsDataEpic, getAssetListDataEpic, getAssetActionDataEpic, postAssetActionDataEpic, getAssetAlertDataEpic, postAlertActionDataEpic, postUserActivityActionDataEpic, getAssetOpenInsightsDataEpic, getHealthIndexDataEpic} from './AssetDetails/epic'
import {getLoginDataEpic, getUsersEpic} from '../../common/container/epics'
import {getRefreshDataEpic, getMemberProfileEpic, getReadingDataEpic, getComputedReadingDataEpic, getFaultReadingDataEpic, getCalculatedParameterEpic, getReadingTypesPreloadEpic} from './Main/epic'
import {getBillingMeterDataEpic} from './EnterpriseInsights/epic';
import { widgetEpics } from './Widgets/epic';
import { dashboardEpics } from "./Dashboard/epic";
import { dashboardWidgetEpics } from "./Dashboard/components/Widgets/epic";
import { alertEpics } from "./Alert/epic";
import { appExpertEpics } from './AppExpert/epic';
import { reportEpics } from "./Report/epic";
import { eventEpics } from './Event/epic';
import { dataUploadEpics } from './DataUpload/epic';
import { notificationEpics } from './Notification/epic';
import { userEpics } from './UserManagement/epic';
import { partnerLoginEpics } from './PartnerLogin/epic';
import { screenDesignEpics } from './AssetScreenDesign/epic';
import { assetInsightsEpics } from './AssetInsights/epic';
import { userProfileEpics } from './UserProfile/epic';
import { getEnergyData } from './Energy/epic';
import { ghgEmissionsEpics } from './Co2Emission/epic';
import { getWhatIfData } from './WhatIf/epic';
import { getLiveData } from './LiveData/epic';
import { getReportDownloadData } from './ReportDownload/epic';
import { weatherWidgetEpics } from './Dashboard/components/Widgets/WeatherForecast/epic';
import {
  getDashboardSidebarAllDashboard,
	getDashboardSidebarDashboardSequence
} from '../../common/components/Slider/epic';
import { calculatedParameterEpics } from './CalculatedParameter/epic';

let epics = {
	getMemberDataEpic,
	getMemberProfileEpic,
	getLoginDataEpic,
	getRoleDataEpic,
	createRoleEpic,
	createMemberEpic,
	editRoleEpic,
	deleteRoleEpic,
	deleteMemberEpic,
	editMemberEpic,
	getRefreshDataEpic,
	getStatsDataEpic,
	getAssetDataEpic,
	getAllAssetsForDropdown,
	getTimelineDataEpic,
	getAssetDetailsDataEpic,
	getAssetListDataEpic,
	getAssetActionDataEpic,
	postAssetActionDataEpic,
	getAssetAlertDataEpic,
	getReadingDataEpic,
	getFaultReadingDataEpic,
	getCalculatedParameterEpic,
	getReadingTypesPreloadEpic,
	getComputedReadingDataEpic,
	postAlertActionDataEpic,
	postUserActivityActionDataEpic,
	getAssetOpenInsightsDataEpic,
	getHealthIndexDataEpic,
	getBillingMeterDataEpic,
	getUsersEpic,
	getEnergyData,
	getWhatIfData,
	getLiveData,
	getReportDownloadData,
	...widgetEpics,
	// dashboard epics
	...dashboardEpics,
	...dashboardWidgetEpics,
	...alertEpics,
	...appExpertEpics,
	...reportEpics,
	...eventEpics,
	...dataUploadEpics,
	...notificationEpics,
	...userEpics,
	...partnerLoginEpics,
	...screenDesignEpics,
	...assetInsightsEpics,
	...userProfileEpics,
	...ghgEmissionsEpics,
	...weatherWidgetEpics,
	...calculatedParameterEpics,
	getDashboardSidebarAllDashboard,
	getDashboardSidebarDashboardSequence
};

export const rootEpic = combineEpics(
	...Object.values(epics)
);