export const GET_NETWORK_INSIGHTS = 'GET_NETWORK_INSIGHTS';
export const GET_NETWORK_INSIGHTS_SUCCESS = 'GET_NETWORK_INSIGHTS_SUCCESS';
export const GET_NETWORK_INSIGHTS_FAILURE = 'GET_NETWORK_INSIGHTS_FAILURE';
export const GET_NETWORK_INSIGHTS_RESET = 'GET_NETWORK_INSIGHTS_RESET';

export const GET_SYSTEM_INSIGHTS = 'GET_SYSTEM_INSIGHTS';
export const GET_SYSTEM_INSIGHTS_SUCCESS = 'GET_SYSTEM_INSIGHTS_SUCCESS';
export const GET_SYSTEM_INSIGHTS_FAILURE = 'GET_SYSTEM_INSIGHTS_FAILURE';
export const GET_SYSTEM_INSIGHTS_RESET = 'GET_SYSTEM_INSIGHTS_RESET';

export const GET_SYSTEM_ASSET_INSIGHTS = 'GET_SYSTEM_ASSET_INSIGHTS';
export const GET_SYSTEM_ASSET_INSIGHTS_SUCCESS =
  'GET_SYSTEM_ASSET_INSIGHTS_SUCCESS';
export const GET_SYSTEM_ASSET_INSIGHTS_FAILURE =
  'GET_SYSTEM_ASSET_INSIGHTS_FAILURE';
export const GET_SYSTEM_ASSET_INSIGHTS_RESET =
  'GET_SYSTEM_ASSET_INSIGHTS_RESET';

export const GET_SYSTEM_ALERTS = 'GET_SYSTEM_ALERTS';
export const GET_SYSTEM_ALERTS_SUCCESS = 'GET_SYSTEM_ALERTS_SUCCESS';
export const GET_SYSTEM_ALERTS_FAILURE = 'GET_SYSTEM_ALERTS_FAILURE';
export const GET_SYSTEM_ALERTS_RESET = 'GET_SYSTEM_ALERTS_RESET';
