import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../constants';
import { httpMethodPost } from '../../../https';
import { CREATE_DYNAMIC_VARIABLE_TEMPLATE } from './constant';

export const createDynamicVariableTemplate = (action$) =>
  action$.pipe(
    ofType(CREATE_DYNAMIC_VARIABLE_TEMPLATE),
    mergeMap((action) => {
      return httpMethodPost(
        action,
        `${URL}/network/dynamic-variable/template/`
      );
    })
  );
