import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import ReactClickout from 'react-onclickout';

import definition from './definition.json';
import { isOpenScreenDesignModal as screenDesignModalShowChange } from '../action';
import locationSearchTabGet from '../utility/locationSearchTabGet';
import './index.scss';

const OptionCollection = ({ index, optionCollection, onChange }) => {
  return (
    <div
      ref={(element) => {
        element &&
          (() => {
            element &&
              (() => {
                const marginLeft = (() => {
                  let _element = element;

                  let previousSiblings = [];

                  while ((_element = _element.previousSibling)) {
                    previousSiblings = [...previousSiblings, _element];
                  }

                  return previousSiblings;
                })().reduce((memo, previousSibling) => {
                  return memo + previousSibling.getBoundingClientRect().width;
                }, 0);

                Object.assign(element.style, {
                  marginLeft: `${marginLeft + 15 * index}px`
                });
              })();
          })();
      }}
      className='OptionCollection'
      style={{
        marginTop: `-${index * 20}px`
      }}
    >
      <div className='_container'>
        {optionCollection.map(({ text, optionCollection }, _index) => {
          return (
            <a
              key={index}
              href='#'
              onClick={(event) => {
                event.preventDefault();

                event.stopPropagation();

                return onChange(_index, optionCollection);
              }}
            >
              {text}

              {optionCollection && <i className='fa fa-chevron-right'></i>}
            </a>
          );
        })}
      </div>

      <div
        className='__container'
        ref={(element) => {
          element &&
            new ResizeObserver(
              ([
                {
                  contentRect: { width, height }
                }
              ]) => {
                Object.assign(element.style, {
                  marginTop: !index ? '-70px' : 0,
                  width: `${width - 10}px`,
                  height: `${height + (index ? 0 : 100)}px`
                });
              }
            ).observe(element.parentNode);
        }}
      ></div>
    </div>
  );
};

const indexCollectionGet = (_index, index, indexCollection) => {
  return [
    ...Array.from({ length: index }).reduce((memo, _, __index) => {
      return [...memo, indexCollection[__index]];
    }, []),
    _index
  ];
};

const fragmentCollectionGet = (indexCollection, optionCollections) => {
  return indexCollection.reduce((memo, _index, index) => {
    return [...memo, optionCollections[index][_index].text];
  }, []);
};

const ScreenDesignOptionDropDown = (props) => {
  const location = useLocation();

  const history = useHistory();

  const [optionCollections, setOptionCollections] = useState([definition]);

  const [indexCollection, setIndexCollection] = useState([]);

  const [resizeObserver, setResizeObserver] = useState();

  useEffect(() => {
    return () => {
      resizeObserver && resizeObserver._.disconnect();
    };
  }, [resizeObserver]);

  return (
    <ReactClickout
      onClickOut={() => {
        props.toggleSidebar(false);
      }}
    >
      <div className='ScreenDesignOptionDropDown'>
        <div className='_header'>Design</div>

        <div
          className='_body'
          ref={(element) => {
            element &&
              !resizeObserver &&
              (() => {
                new ResizeObserver(([{ target }], _) => {
                  const { width, height } = target.getBoundingClientRect();

                  setResizeObserver({
                    width,
                    height,
                    _
                  });
                }).observe(element.firstChild);
              })();
          }}
        >
          {optionCollections.map((optionCollection, index) => {
            return (
              <OptionCollection
                key={index}
                index={index}
                optionCollection={optionCollection}
                onChange={(_index, optionCollection) => {
                  props.screenDesignModalShowChange(false);

                  switch (true) {
                    case !!optionCollection:
                      return (() => {
                        setIndexCollection((indexCollection) => {
                          return indexCollectionGet(
                            _index,
                            index,
                            indexCollection
                          );
                        });

                        setOptionCollections((optionCollections) => {
                          return [
                            ...optionCollections.slice(0, index + 1),
                            optionCollection
                          ];
                        });
                      })();

                    default:
                      return (() => {
                        props.toggleSidebar(false);

                        history.push({
                          pathname: '/Templates',
                          search: `tab=${fragmentCollectionGet(
                            indexCollectionGet(_index, index, indexCollection),
                            optionCollections
                          ).join('--')}`
                        });
                      })();
                  }
                }}
              />
            );
          })}

          <div
            className='_OptionCollection'
            style={(() => {
              const { width, height } = resizeObserver || {};

              return {
                width,
                height
              };
            })()}
          ></div>

          <div className='_footer'>
            <a
              href='#'
              className='border'
              onClick={(event) => {
                event.preventDefault();

                event.stopPropagation();

                props.toggleSidebar(false);

                props.screenDesignModalShowChange(true);

                !location.pathname.match(/^\/Templates$/) &&
                  history.push({
                    pathname: '/Templates',
                    search: `tab=${locationSearchTabGet(
                      'DashboardTemplateCreation'
                    )}`
                  });
              }}
            >
              + &nbsp; Add New Template
            </a>
          </div>
        </div>
      </div>
    </ReactClickout>
  );
};

const mapStateToProps = () => {
  // eslint-disable-next-line no-empty
};

export default connect(mapStateToProps, { screenDesignModalShowChange })(
  ScreenDesignOptionDropDown
);
