import React from 'react';
import ReactDOM from 'react-dom';
import 'rxjs';
import 'react-hot-loader/patch';
// import { AppContainer } from 'react-hot-loader';
import RootComponent from './root.js';
import Ecolytics from '@Ecolibrium-Energy/ecolytics';
import { env, mixpanel_key } from "Config";

Ecolytics.init(mixpanel_key, env === 'production');

ReactDOM.render(<RootComponent />, document.getElementById('root'));

if (module.hot) {
    module.hot.accept('./root.js', () => {
        ReactDOM.render( <RootComponent />,
            // <AppContainer>
            //     <RootComponent />,
            // </AppContainer>,
            document.getElementById('root'),
        );
    });
}
ReactDOM.render(<RootComponent />, document.getElementById('root'));
