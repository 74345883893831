import React from 'react';

const CreateCustomTrigger = (props) => {
  return (
    <div className='_dashboard-aside-wrap'>
      <button
        className='add-wrap _add-wrap'
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          props.onSetShowDashboardForm(true);

          props.onDashboardOptionClose();

          props._onSetSidebarShow(false);
        }}
      >
        <span className='add-icon _add-icon'>+</span>
        {!props.isDashboardHorizontal && 'Add Dashboard'}
      </button>
    </div>
  );
};

export default CreateCustomTrigger;
