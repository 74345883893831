import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  Entity,
  Modifier,
  RichUtils
} from 'draft-js';
import _ from 'lodash';

const operators = [
  {
    tag: <span>+</span>,
    value: '+'
  },
  {
    tag: <span>-</span>,
    value: '-'
  },
  {
    tag: <span>*</span>,
    value: '*'
  },
  {
    tag: <span>/</span>,
    value: '/'
  },
  {
    tag: (
      <span>
        x<sup>y</sup>
      </span>
    ),
    value: '**'
  },
  {
    tag: <span>AND</span>,
    value: 'and'
  },
  {
    tag: <span>OR</span>,
    value: 'or'
  },
  {
    tag: <span>&gt;</span>,
    value: '>'
  },
  {
    tag: <span>&lt;</span>,
    value: '<'
  },
  {
    tag: <span>==</span>,
    value: '=='
  },
  {
    tag: <span>!=</span>,
    value: '!='
  },
  {
    tag: <span>(</span>,
    value: '('
  },
  {
    tag: <span>)</span>,
    value: ')'
  }
];

const mutability = 'IMMUTABLE';

const equationInvalidStyle = 'UNDERLINE-STYLED';

const parameterCollectionShowInitializeString = '@';

const ParameterCollection = (props) => {
  const positionGet = () => {
    return props.position
      ? {
          top: props.position.top + 20,
          left: props.position.left
        }
      : { top: 0, left: 0 };
  };

  return (
    <div
      className='ParameterCollection'
      style={{
        position: 'fixed',
        ...positionGet(props.position)
      }}
    >
      <ul>
        {props.formulaConfParameterCollection.map((formulaConfParameter) => {
          return (
            <li key={formulaConfParameter.index}>
              <div
                href='#'
                onMouseDown={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  return props.onFormulaCollectionParameterUpdateTrigger({
                    type: formulaConfParameter.type,
                    value: formulaConfParameter.index
                  });
                }}
              >{`val_${formulaConfParameter.index + 1}`}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const entityMapGet = (data) => {
  return {
    type: data.type,
    mutability,
    data
  };
};

const __editorStateGet = (
  formulaConfPointerCollection,
  formulaConfParameterCollection
) => {
  return formulaConfPointerCollection.reduce(
    (memo, { index, type, value }) => {
      const text =
        type === 'parameter'
          ? `val_${formulaConfParameterCollection[value].index + 1}`
          : value;

      const offset = memo.text.length;

      const length = text.length;

      const entityRange = {
        offset,
        length,
        key: index
      };

      const entityMap = entityMapGet({
        type,
        value,
        index
      });

      return {
        text: `${memo.text}${text} `,
        entityRanges: [...memo.entityRanges, entityRange],
        entityMap: {
          ...memo.entityMap,
          [index]: entityMap
        }
      };
    },
    {
      text: '',
      entityRanges: [],
      entityMap: {}
    }
  );
};

const _editorStateGet = (
  formulaConfPointerCollection,
  formulaConfParameterCollection
) => {
  const { text, entityRanges, entityMap } = __editorStateGet(
    formulaConfPointerCollection,
    formulaConfParameterCollection
  );

  const editorStateRaw = {
    blocks: [
      {
        text,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges,
        data: {}
      }
    ],
    entityMap
  };

  const editorState = EditorState.createWithContent(
    convertFromRaw(editorStateRaw)
  );

  return editorState;
};

const selectionRangeGet = () => {
  const selection = window.getSelection();

  return selection.rangeCount ? selection.getRangeAt(0) : null;
};

const triggerRangeGet = (parameterCollectionShowInitializeString) => {
  const range = selectionRangeGet();

  const text =
    range && range.startContainer.textContent.substring(0, range.startOffset);

  if (!text || /\s+$/.test(text)) {
    return null;
  }

  const start = text.lastIndexOf(parameterCollectionShowInitializeString);

  if (start < 0) {
    return null;
  }

  const end = range.startOffset;

  return {
    start,
    end,
    text: text.substring(start)
  };
};

const caretCoordinatesGet = () => {
  const range = selectionRangeGet();

  if (range) {
    const { left, top } = range.getBoundingClientRect();

    return { left, top };
  }

  return null;
};

const equationEval = (equation) => {
  try {
    eval(equation);

    return true;
  } catch (error) {
    return false;
  }
};

const insertRangeGet = (editorState) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const anchorKey = selection.getAnchorKey();
  const end = selection.getAnchorOffset();
  const block = content.getBlockForKey(anchorKey);
  const text = block.getText();
  const start = text
    .substring(0, end)
    .lastIndexOf(parameterCollectionShowInitializeString);

  return {
    start,
    end
  };
};

const formulaConfPointerCollectionGet = (editorState) => {
  const entityMapCollection = Object.values(
    convertToRaw(editorState.getCurrentContent()).entityMap
  );

  return entityMapCollection.reduce((memo, { data }, index) => {
    return [
      ...memo,
      {
        type: data.type,
        index,
        value: data.value
      }
    ];
  }, []);
};

const equationInvalidGet = (editorState) => {
  const entityMapCollection = Object.values(
    convertToRaw(editorState.getCurrentContent()).entityMap
  );

  const equation = entityMapCollection.reduce(
    (memo, { data: { type, value: _value } }) => {
      const value = type === 'parameter' ? `${_value + 1}` : `${_value}`;
      return `${memo} ${value} `;
    },
    ''
  );

  const _equation = editorState
    .getCurrentContent()
    .getPlainText()
    .replace(/val_/g, '')
    .split(/\s+/)
    .reduce((memo, fragment) => {
      return `${memo} ${fragment.trim()} `;
    }, '');

  const equationInvalid = (() => {
    switch (true) {
      case equation.trim() !== _equation.trim():
        return true;
      case !equationEval(equation):
        return true;
      default:
        return false;
    }
  })();

  return equationInvalid;
};

const editorStateInlineStyledGet = (equationInvalid, editorState) => {
  const content = editorState.getCurrentContent();

  const _selectionState = editorState.getSelection();

  const anchorKey = _selectionState.getAnchorKey();

  const block = content.getBlockForKey(anchorKey);

  const text = block.getText();

  const inlineStyle = convertToRaw(content).blocks[0].inlineStyleRanges[0];

  if (
    (equationInvalid && inlineStyle && inlineStyle.length === text.length) ||
    (!equationInvalid && !inlineStyle)
  ) {
    return editorState;
  }

  const selectionState = _selectionState.merge({
    anchorOffset: 0,
    focusOffset: text.length
  });

  let _editorState = EditorState.forceSelection(editorState, selectionState);

  _editorState = RichUtils.toggleInlineStyle(
    _editorState,
    equationInvalidStyle
  );

  _editorState = EditorState.forceSelection(_editorState, _selectionState);

  return _editorState;
};

const FormulaText = (_props) => {
  const { onFormulaConfPointerCollectionUpdateTrigger, ...props } = _props;

  const editorStateGet = useCallback(
    (formulaConfPointerCollection, formulaConfParameterCollection) => {
      let editorState = _editorStateGet(
        formulaConfPointerCollection,
        formulaConfParameterCollection
      );

      if (selectionStateRef.current) {
        const selectionState = editorState
          .getSelection()
          .merge(selectionStateRef.current);

        editorState = EditorState.forceSelection(editorState, selectionState);
      }

      return editorState;
    },
    []
  );

  const selectionStateRef = useRef();

  const [editorState, setEditorState] = useState(
    editorStateGet(
      props.formulaConfPointerCollection,
      props.formulaConfParameterCollection
    )
  );

  const [parameterCollectionShow, setParameterCollectionShow] = useState(null);

  const [formulaConfPointerCollectionKey, setFormulaConfPointerCollectionKey] =
    useState(props.formulaConfPointerCollectionKey);

  const parameterCollectionShowUpdate = useCallback(
    (parameterCollectionShow) => {
      const triggerRange = triggerRangeGet(
        parameterCollectionShowInitializeString
      );

      const _parameterCollectionShow = !triggerRange
        ? null
        : { position: caretCoordinatesGet() };

      !_.isEqual(_parameterCollectionShow, parameterCollectionShow) &&
        setParameterCollectionShow(_parameterCollectionShow);
    },
    []
  );

  const editorStateInlineStyleUpdate = useCallback((editorState) => {
    const _editorState = editorStateInlineStyledGet(
      equationInvalidGet(editorState),
      editorState
    );

    setEditorState(_editorState);
  }, []);

  const onEditorStateUpdateHandle = useCallback(
    (editorState, parameterCollectionShow) => {
      parameterCollectionShowUpdate(parameterCollectionShow);

      editorStateInlineStyleUpdate(editorState);
    },
    [parameterCollectionShowUpdate, editorStateInlineStyleUpdate]
  );

  useEffect(() => {
    onEditorStateUpdateHandle(editorState, parameterCollectionShow);
  }, [editorState, parameterCollectionShow, onEditorStateUpdateHandle]);

  useEffect(() => {
    setFormulaConfPointerCollectionKey(props.formulaConfPointerCollectionKey);
  }, [props.formulaConfPointerCollectionKey]);

  useEffect(() => {
    formulaConfPointerCollectionKey !== props.formulaConfPointerCollectionKey &&
      setEditorState(
        editorStateGet(
          props.formulaConfPointerCollection,
          props.formulaConfParameterCollection
        )
      );
  }, [
    formulaConfPointerCollectionKey,
    props.formulaConfPointerCollectionKey,
    props.formulaConfPointerCollection,
    props.formulaConfParameterCollection,
    editorStateGet
  ]);

  const formulaConfPointerCollectionUpdateTrigger = (
    editorState,
    formulaConfPointerCollection,
    formulaConfPointerCollectionKeyValid
  ) => {
    const _formulaConfPointerCollection =
      formulaConfPointerCollectionGet(editorState);

    const isEqual = _.isEqual(
      _formulaConfPointerCollection,
      formulaConfPointerCollection
    );

    const equationInvalid = equationInvalidGet(editorState);

    setEditorState(editorState);

    selectionStateRef.current = null;

    if (!isEqual && !equationInvalid && formulaConfPointerCollectionKeyValid) {
      const { anchorOffset, focusOffset } = editorState.getSelection();

      selectionStateRef.current = { anchorOffset, focusOffset };

      onFormulaConfPointerCollectionUpdateTrigger(
        _formulaConfPointerCollection
      );
    }
  };

  return (
    <div className='FormulaText'>
      <ul className='operators'>
        {operators.map((operator, index) => {
          return (
            <li key={index}>
              <a
                href='#'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  const data = {
                    type: 'operator',
                    value: operator.value
                  };

                  const contentState = editorState.getCurrentContent();

                  const currentSelection = editorState.getSelection();

                  const entityMap = entityMapGet(data);

                  const entity = Entity.create(
                    entityMap.type,
                    entityMap.mutability,
                    entityMap.data
                  );

                  const text = operator.value;

                  let newContentState = Modifier.insertText(
                    contentState,
                    currentSelection.merge({
                      anchorOffset: currentSelection.anchorOffset,
                      focusOffset: currentSelection.focusOffset
                    }),
                    text,
                    null,
                    entity
                  );

                  newContentState = Modifier.insertText(
                    newContentState,
                    currentSelection.merge({
                      anchorOffset: currentSelection.anchorOffset + text.length,
                      focusOffset: currentSelection.focusOffset + text.length
                    }),
                    ' ',
                    null,
                    null
                  );

                  let newEditorState = EditorState.push(
                    editorState,
                    newContentState,
                    'insert-operator'
                  );

                  newEditorState = EditorState.forceSelection(
                    newEditorState,
                    newContentState.getSelectionAfter()
                  );

                  formulaConfPointerCollectionUpdateTrigger(
                    newEditorState,
                    props.formulaConfPointerCollection,
                    formulaConfPointerCollectionKey ===
                      props.formulaConfPointerCollectionKey
                  );
                }}
              >
                {operator.tag}
              </a>
            </li>
          );
        })}
      </ul>

      <Editor
        editorState={editorState}
        customStyleMap={{
          [equationInvalidStyle]: {
            textDecoration: 'underline red'
          }
        }}
        onChange={setEditorState}
      />

      <ParameterCollection
        {...parameterCollectionShow}
        formulaConfParameterCollection={props.formulaConfParameterCollection}
        onFormulaCollectionParameterUpdateTrigger={(data) => {
          const { start, end } = insertRangeGet(editorState);

          const contentState = editorState.getCurrentContent();

          const currentSelection = editorState.getSelection().merge({
            anchorOffset: start,
            focusOffset: end
          });

          const entityMap = entityMapGet(data);

          const entity = Entity.create(
            entityMap.type,
            entityMap.mutability,
            entityMap.data
          );

          const text = `val_${data.value + 1}`;

          let newContentState = Modifier.replaceText(
            contentState,
            currentSelection.merge({
              anchorOffset: currentSelection.anchorOffset,
              focusOffset: currentSelection.focusOffset
            }),
            text,
            null,
            entity
          );

          newContentState = Modifier.replaceText(
            newContentState,
            currentSelection.merge({
              anchorOffset: currentSelection.anchorOffset + text.length,
              focusOffset: currentSelection.focusOffset + text.length
            }),
            ' ',
            null,
            null
          );

          let newEditorState = EditorState.push(
            editorState,
            newContentState,
            'insert-parameter'
          );

          newEditorState = EditorState.forceSelection(
            newEditorState,
            newContentState.getSelectionAfter()
          );

          formulaConfPointerCollectionUpdateTrigger(
            newEditorState,
            props.formulaConfPointerCollection,
            formulaConfPointerCollectionKey ===
              props.formulaConfPointerCollectionKey
          );
        }}
      />
    </div>
  );
};

export default FormulaText;
