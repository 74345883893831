import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Label from 'reactstrap/lib/Label';
import Col from 'reactstrap/lib/Col';
import { TextEditor } from '../../../../../common/components';

function TextEditorConfig(props) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(props.value)
  }, []);

  return <Col xl={props.width ? props.width : 12}>
    <Label for={props.name} className='label'>{props.label}
      {props.required === true ? <span className='text-danger'> * </span> : null}
    </Label>
    <TextEditor
      initialStateHtml={value}
      onEditorChange={(html, editorState)=>{
        props.onChange(html);
      }}
    />
  </Col>
}


const mapStateToProps = (state) => {
  return {};
};

TextEditorConfig.defaultProps = {
  appParams: {},
  appInputParamChanged: function () {}
}

TextEditorConfig.propTypes = {
  appParams: PropTypes.object,
  appInputParamChanged: PropTypes.func
}

export default connect(mapStateToProps, {})(TextEditorConfig);