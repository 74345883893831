export const GET_USECASE_SUMMARY_CBM_ASSETS_DATA =
  'GET_USECASE_SUMMARY_CBM_ASSETS_DATA';

export const GET_USECASE_SUMMARY_CBM_ASSETS_DATA_SUCCESS =
  'GET_USECASE_SUMMARY_CBM_ASSETS_DATA_SUCCESS';

export const GET_USECASE_SUMMARY_CBM_ASSETS_DATA_FAILURE =
  'GET_USECASE_SUMMARY_CBM_ASSETS_DATA_FAILURE';

export const GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA =
  'GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA';

export const GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA_SUCCESS =
  'GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA_SUCCESS';

export const GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA_FAILURE =
  'GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA_FAILURE';

export const GET_USECASE_SUMMARY_ASSETS_DATA =
  'GET_USECASE_SUMMARY_ASSETS_DATA';

export const GET_USECASE_SUMMARY_ASSETS_DATA_SUCCESS =
  'GET_USECASE_SUMMARY_ASSETS_DATA_SUCCESS';

export const GET_USECASE_SUMMARY_ASSETS_DATA_FAILURE =
  'GET_USECASE_SUMMARY_ASSETS_DATA_FAILURE';

export const GET_USECASE_SUMMARY_ALERT_LOGS_DATA =
  'GET_USECASE_SUMMARY_ALERT_LOGS_DATA';

export const GET_USECASE_SUMMARY_ALERT_LOGS_DATA_SUCCESS =
  'GET_USECASE_SUMMARY_ALERT_LOGS_DATA_SUCCESS';

export const GET_USECASE_SUMMARY_ALERT_LOGS_DATA_FAILURE =
  'GET_USECASE_SUMMARY_ALERT_LOGS_DATA_FAILURE';

export const GET_USECASE_SUMMARY_INSIGHT_DATA =
  'GET_USECASE_SUMMARY_INSIGHT_DATA';

export const GET_USECASE_SUMMARY_INSIGHT_DATA_SUCCESS =
  'GET_USECASE_SUMMARY_INSIGHT_DATA_SUCCESS';

export const GET_USECASE_SUMMARY_INSIGHT_DATA_FAILURE =
  'GET_USECASE_SUMMARY_INSIGHT_DATA_FAILURE';

export const GET_USECASE_SUMMARY_TAG_DATA = 'GET_USECASE_SUMMARY_TAG_DATA';
export const GET_USECASE_SUMMARY_TAG_DATA_SUCCESS =
  'GET_USECASE_SUMMARY_TAG_DATA_SUCCESS';
export const GET_USECASE_SUMMARY_TAG_DATA_FAILURE =
  'GET_USECASE_SUMMARY_TAG_DATA_FAILURE';

export const GET_USECASE_SUMMARY_IMPACT_DATA =
  'GET_USECASE_SUMMARY_IMPACT_DATA';
export const GET_USECASE_SUMMARY_IMPACT_DATA_SUCCESS =
  'GET_USECASE_SUMMARY_IMPACT_DATA_SUCCESS';
export const GET_USECASE_SUMMARY_IMPACT_DATA_FAILURE =
  'GET_USECASE_SUMMARY_IMPACT_DATA_FAILURE';
