import React from 'react';
import { Row, Col } from 'reactstrap';

import useSensorParameterCollection from '../common/useSensorParameterCollection';
import SensorParameterSelect from '../Common/SensorParameterSelect';
import { Input } from '../../../../../../../common/components';

const ParameterConfigNormal = (props) => {
  const [
    sensorCollection,
    sensorOptions,
    parameterCollection,
    parameterOptionsCollection,
    onSensorChangeHandle,
    onParameterChangeHandle
  ] = useSensorParameterCollection(props.parameterConfs);

  return (
    <Row>
      <Col md={4}>
        <div className='new-input-wrap'>
          <label className='label-text'>Alias</label>

          <Input
            size='sm'
            label=''
            type='text'
            required={false}
            placeholder='Enter Alias'
            value={props.parameterConfs[0].alias || ''}
            inputChanged={(alias) => {
              props.onParameterConfCollectionUpdateTrigger({
                ...props.parameterConfs[0],
                alias
              });
            }}
          />
        </div>
      </Col>

      <Col md={4}>
        <div className='new-input-wrap'>
          <label className='label-text'>Location *</label>

          <SensorParameterSelect
            type='sensor'
            options={sensorOptions}
            value={sensorCollection[0] || ''}
            onChange={(sensor) => {
              onSensorChangeHandle(sensor, 0);
            }}
          />
        </div>
      </Col>

      <Col md={4}>
        <div className='new-input-wrap'>
          <label className='label-text'>Parameter *</label>

          <SensorParameterSelect
            type='parameter'
            options={parameterOptionsCollection[0] || []}
            value={parameterCollection[0] || ''}
            onChange={(parameter) => {
              onParameterChangeHandle(
                parameter,
                0,
                props.onParameterConfCollectionUpdateTrigger
              );
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ParameterConfigNormal;
