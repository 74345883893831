import {GET_STATS_DATA, GET_ASSETS_DATA, GET_TIMELINE_DATA, OLD_TIMELINE_DATA} from './constant';

import * as AppConstants from './constant';


export const getStatsAction = value => ({
    type: GET_STATS_DATA,
    payload: value
});

export const getAssetsAction = value => ({
    type: GET_ASSETS_DATA,
    payload: value
});

export const getTimelineAction = (value, prevValue) => ({
    type: GET_TIMELINE_DATA,
    payload: value,
    prevValue: prevValue
});

export const cacheDataAction = value => ({
    type: OLD_TIMELINE_DATA,
    payload: value
});


export const getCbmAssets = value => ({
    type: AppConstants.GET_CBM_ASSETS,
    payload: value
});

export const getAllAssets = value => ({
    type: AppConstants.GET_ALL_ASSETS,
    payload: value
});

export const getCbmAssetsByAssetName = value => ({
    type: AppConstants.GET_CBM_ASSETS_BY_ASSET_NAME,
    payload: value
});

export const getSpecificAssetDetailsByAssetName = value => ({
    type: AppConstants.GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME,
    payload: value
});

export const getAssetOverview = value => ({
    type: AppConstants.GET_ASSET_OVERVIEW,
    payload: value
});

export function getAssetNameDetails(value) {
    return {
        type: AppConstants.GET_ASSET_NAME_DETAILS,
        payload: value
    }
}

export function getDefaultTemplateForAsset(value) {
    return {
        type: AppConstants.GET_DEFAULT_TEMPLATE_OF_ASSET,
        payload: value
    }
}


export const getAllAssetsForDropdown = value => ({
    type: AppConstants.GET_ALL_ASSETS_FOR_DROPDOWN,
    payload: value
});

export function getAssetInsightList(value) {
    return{
        type: AppConstants.GET_ASSET_INSIGHT_LIST,
        payload: value
    }   
};

export function getDetailsByInsightId(value) {
    return{
        type: AppConstants.GET_INSIGHT_DETAILS,
        payload: value
    }   
};

export function getAllActionsOfAsset(value) {
    return{
        type: AppConstants.GET_ALL_ACTIONS_FOR_ASSET,
        payload: value
    }   
};

export function createManualInsight(value) {
    return {
      type: AppConstants.CREATE_MANUAL_INSIGHT,
      payload: value
    }
}  