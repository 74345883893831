import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Button from '../Button';
import Icon from '../Icon';
import { ICON_POSITION } from '../Icon/constants';
import leftArrow from '../../../../assets/images/Icons/Arrows/Chevron-LeftSVG.svg';
import rightArrow from '../../../../assets/images/Icons/Arrows/Chevron-RightSVG.svg';

const ButtonScroller = (props) => {
  const scrollDiv = useRef(null);
  const step = props.stop ? props.stop : 100;

  useEffect(() => {
    props.scrollLeft &&
      !scrollDiv.current.scrollLeft &&
      (() => {
        new ResizeObserver(([{ target }], resizeObserver) => {
          target.scrollWidth >= props.scrollLeft &&
            target.scrollTo({
              left: props.scrollLeft,
              behavior: 'smooth'
            });

          resizeObserver.unobserve(target);
        }).observe(scrollDiv.current);
      })();
  }, [props.scrollLeft]);

  useEffect(() => {
    props.scrollToFlag &&
      props.activeObject &&
      (() => {
        new ResizeObserver(([{ target }], resizeObserver) => {
          const groupBoundingClientReactLeft =
            target.childNodes[0].getBoundingClientRect().left;

          const activeBoundingClientReactLeft =
            target.childNodes[0].childNodes[
              props.activeObject.value
            ].getBoundingClientRect().left;

          !(
            activeBoundingClientReactLeft >=
              groupBoundingClientReactLeft + target.scrollLeft &&
            activeBoundingClientReactLeft <=
              groupBoundingClientReactLeft +
                target.scrollLeft +
                target.clientWidth
          ) &&
            target.scrollTo({
              left: activeBoundingClientReactLeft - groupBoundingClientReactLeft
            });

          resizeObserver.unobserve(target);
        }).observe(scrollDiv.current);
      })();
  }, [props.scrollToFlag, props.activeObject]);

  const leftScroll = () => {
    scrollDiv.current.scrollLeft -= step;
  };

  const rightScroll = () => {
    scrollDiv.current.scrollLeft += step;
  };

  const handleScroll = (e) => {
    const $elem = e.currentTarget;
    const newScrollLeft = $elem.scrollLeft;
    const width = $elem.offsetWidth + 10;
    const scrollWidth = $elem.scrollWidth;

    props.onScroll(newScrollLeft);

    if (width + newScrollLeft > scrollWidth) {
      props.onScrollEnd(e);
    }
  };

  return (
    <React.Fragment>
      <div ref={scrollDiv} className='buttonScroller' onScroll={handleScroll}>
        {props.getScrollContent()}
      </div>

      {props.eventCount > 3 ? (
        props.customButton ? (
          <div className='pagination-block'>
            <a className='prev-btn' onClick={leftScroll}></a>
            <a className='next-btn' onClick={rightScroll}></a>
          </div>
        ) : (
          <div className='scrollbuttons'>
            <Button
              buttonType='secondary'
              buttonClick={leftScroll}
              size='sm'
              innerContent={
                <Icon
                  size={24}
                  alt=''
                  src={leftArrow}
                  position={ICON_POSITION.CENTER_CENTER}
                />
              }
            />
            <Button
              buttonType='secondary'
              buttonClick={rightScroll}
              size='sm'
              innerContent={
                <Icon
                  size={24}
                  alt=''
                  src={rightArrow}
                  position={ICON_POSITION.CENTER_CENTER}
                />
              }
            />
          </div>
        )
      ) : null}
    </React.Fragment>
  );
};

ButtonScroller.defaultProps = {
  onScrollEnd: () => {
    // eslint-disable-next-line no-empty
  }
};

ButtonScroller.propTypes = {
  onScrollEnd: PropTypes.func
};

export default ButtonScroller;
