import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { uploadImageConfiguration } from '../../../Widgets/action';
import { Button } from '../../../../../../../common/components';
import imagePlaceholder from '../../../../../../../../assets/images/image_placeholder.svg';

const OthersConfig = (_props) => {
  const { appInputParamChanged, ...props } = _props;

  const [initialized, setInitialized] = useState(false);

  const [url, setUrl] = useState();

  const [file, setFile] = useState();

  const [error, setError] = useState();

  const appInputParamChangedHandle = useCallback(
    (url, uploadImage, appInputParamChanged) => {
      switch (true) {
        case url:
          return null;

        case uploadImage.error:
          return setError(true);

        case uploadImage.success:
          setError(false);

          setUrl(uploadImage.data);

          appInputParamChanged({
            url: {
              value: uploadImage.data
            }
          });
      }
    },
    []
  );

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setUrl((conf.url && conf.url.value) || {});

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  useEffect(() => {
    appInputParamChangedHandle(url, props.uploadImage, appInputParamChanged);
  }, [
    url,
    props.uploadImage,
    appInputParamChanged,
    appInputParamChangedHandle
  ]);

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={7}>
            <p className='head-label-text'>Upload Image</p>

            <div className='fileInput-name-wrap'>
              <div className='new-input-wrap'>
                <div>
                  <label className='file-input-button'>
                    Choose File
                    <input
                      type='file'
                      accept='image/*'
                      onChange={(event) => {
                        setUrl(null);

                        setFile(event.target.files[0]);
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className='filename-uploadBtn'>
                <p>{file && file.name ? file.name : 'No file Chosen'}</p>
                <button
                  className='icon-button'
                  onClick={() => {
                    props.uploadImageConfiguration({ file });
                  }}
                >
                  <i className='fa fa-upload' aria-hidden='true'></i>
                </button>
              </div>
            </div>
          </Col>

          <Col md={5}>
            <div className='img-holder text-right'>
              {error ? (
                <span>Image Upload Failed</span>
              ) : (
                <img src={url && url.url ? url.url : imagePlaceholder} alt='' />
              )}
            </div>
          </Col>
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

const mapStateToProps = (state) => {
  return {
    uploadImage: state.uploadImageConfiguration
  };
};

export default connect(mapStateToProps, {
  uploadImageConfiguration
})(OthersConfig);
