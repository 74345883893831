import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import { Button, Input } from '../../../../../../../common/components';
import valueGet from '../common/valueGet';

const OthersConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [liveTimePeriod, setLiveTimePeriod] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setLiveTimePeriod(
        conf.live_timeperiod &&
          conf.live_timeperiod.value &&
          !isNaN(Number(conf.live_timeperiod.value)) &&
          conf.live_timeperiod.value
      );

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      live_timeperiod: valueGet(liveTimePeriod),
      ...object
    };

    props.appInputParamChanged(value);
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <p className='head-label-text mg-b-15'>Live Data Time Period</p>

        <Row>
          <Col>
            <div className='new-input-wrap'>
              <label className='label-text'>Min Value</label>

              <Input
                size='sm'
                label=''
                type='number'
                required={false}
                placeholder='Enter Min Value'
                value={liveTimePeriod || ''}
                inputChanged={(liveTimePeriod) => {
                  setLiveTimePeriod(liveTimePeriod);

                  appInputParamChangedHandle({
                    live_timeperiod: valueGet(liveTimePeriod)
                  });
                }}
              />
            </div>
          </Col>
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default OthersConfig;
