import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const POSITION_TYPES = [
    'top left',
    'top center',
    'top right',
    'right top',
    'right center',
    'right bottom',
    'bottom left',
    'bottom center',
    'bottom right',
    'left top',
    'left center',
    'left bottom',
    'center center',
];


export default (props) => {
    return (
        <Popup
            trigger={open => (
                props.icon
            )}
            position={props.position}
            open={props.open}
            on={props.on}
            closeOnDocumentClick
            // on={['hover', 'focus']}
            // arrow={position !== 'center center'}
        >
            {props.children}
        </Popup>
    );
} 