import React, { Fragment, useState } from 'react';
import { Row, Col } from 'reactstrap';

import collectionGet from '../common/collectionGet';
import _DateTimeConfig from '../DateTimeConfig/_DateTimeConfig';
import ParameterConfigMixed from './ParameterConfigMixed';
import {
  Input,
  RadioButtons,
  Button
} from '../../../../../../../common/components';

const Table = (props) => {
  const [tableLayoutRowCount, setTableLayoutRowCount] = useState(
    props.tableLayoutRow.length
  );

  const [tableLayoutColumnCount, setTableLayoutColumnCount] = useState(
    props.tableLayoutColumn.length
  );

  const [thresholdCount, setThresholdCount] = useState(props.threshold.length);

  const _renderTableLayoutRow = (_tableLayoutRow, index) => {
    return (
      <Row className='content-border-wrap mx-0'>
        <Col md={6}>
          <div className='new-input-wrap mg-b-10'>
            <label className='label-text'>Label</label>

            <Input
              size='sm'
              label=''
              type='text'
              required={false}
              placeholder='Enter Label'
              value={_tableLayoutRow.value || ''}
              inputChanged={(value) => {
                value !== _tableLayoutRow.value &&
                  props.onTableLayoutRowChange(
                    collectionGet(
                      { ..._tableLayoutRow, value },
                      index,
                      props.tableLayoutRow
                    )
                  );
              }}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className='new-input-wrap mg-b-10'>
            <label className='label-text'>Style</label>

            <Input
              size='sm'
              label=''
              type='text'
              required={false}
              placeholder='Enter Style'
              value={_tableLayoutRow.style || ''}
              inputChanged={(style) => {
                props.onTableLayoutRowChange(
                  collectionGet(
                    { ..._tableLayoutRow, style },
                    index,
                    props.tableLayoutRow
                  )
                );
              }}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const renderTableLayoutRow = () => {
    return (
      <Col md={6} className='vertical-col-divider'>
        <p className='head-label-text mg-b-10'>Rows</p>

        {Array.from({ length: tableLayoutRowCount })
          .fill()
          .reduce((memo, _, index) => {
            const value = props.tableLayoutRow[index];

            return [...memo, value];
          }, [])
          .map((_tableLayoutRow, index) => (
            <Fragment key={index}>
              {_renderTableLayoutRow(_tableLayoutRow, index)}
            </Fragment>
          ))}

        <button
          className='icon-text-btn mg-t-15'
          onClick={() => {
            setTableLayoutRowCount(
              (tableLayoutRowCount) => ++tableLayoutRowCount
            );

            props.onTableLayoutRowChange([
              ...props.tableLayoutRow,
              { value: 'Row' }
            ]);
          }}
        >
          <i className='fa fa-plus-circle' aria-hidden='true'></i>
          Add Row
        </button>
      </Col>
    );
  };

  const _renderTableLayoutColumn = (_tableLayoutColumn, index) => {
    return (
      <Row className='content-border-wrap mx-0'>
        <Col md={6}>
          <div className='new-input-wrap mg-b-10'>
            <label className='label-text'>Label</label>

            <Input
              size='sm'
              label=''
              type='text'
              required={false}
              placeholder='Enter Label'
              value={_tableLayoutColumn.value || ''}
              inputChanged={(value) => {
                value !== _tableLayoutColumn.value &&
                  props.onTableLayoutColumnChange(
                    collectionGet(
                      { ..._tableLayoutColumn, value },
                      index,
                      props.tableLayoutColumn
                    )
                  );
              }}
            />
          </div>
        </Col>

        <Col md={6}>
          <div className='new-input-wrap mg-b-10'>
            <label className='label-text'>Style</label>

            <Input
              size='sm'
              label=''
              type='text'
              required={false}
              placeholder='Enter Style'
              value={_tableLayoutColumn.style || ''}
              inputChanged={(style) => {
                props.onTableLayoutColumnChange(
                  collectionGet(
                    { ..._tableLayoutColumn, style },
                    index,
                    props.tableLayoutColumn
                  )
                );
              }}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const renderTableLayoutColumn = () => {
    return (
      <Col md={6}>
        <p className='head-label-text mg-b-10'>Column</p>

        {Array.from({ length: tableLayoutColumnCount })
          .fill()
          .reduce((memo, _, index) => {
            const value = props.tableLayoutColumn[index];

            return [...memo, value];
          }, [])
          .map((_tableLayoutColumn, index) => (
            <Fragment key={index}>
              {_renderTableLayoutColumn(_tableLayoutColumn, index)}
            </Fragment>
          ))}

        <button
          className='icon-text-btn mg-t-15'
          onClick={() => {
            setTableLayoutColumnCount(
              (tableLayoutColumnCount) => ++tableLayoutColumnCount
            );

            props.onTableLayoutColumnChange([
              ...props.tableLayoutColumn,
              { value: 'Column', style: null }
            ]);
          }}
        >
          <i className='fa fa-plus-circle' aria-hidden='true'></i> Add Column
        </button>
      </Col>
    );
  };

  const renderParameters = () => {
    return (
      <div className='vertical-col-divider data-wrapper'>
        <p className='head-label-text mg-b-10'>Parameters</p>

        <ParameterConfigMixed
          tableConfigCollection={props.tableConfigCollection}
          tableConfig={props.tableConfig}
          confs={props.confs}
          onConfsChange={props.onConfsChange}
        />
      </div>
    );
  };

  const renderThreshold = () => {
    return (
      <Row className='content-border-wrap input-button-row mx-1'>
        <Col md={9}>
          {Array.from({ length: thresholdCount + 1 })
            .fill()
            .reduce((memo, _, index) => {
              const value = (props.threshold && props.threshold[index]) || {};

              return [...memo, value];
            }, [])
            .map((_threshold, index) => {
              return (
                <Row key={index}>
                  <Col md={3} className='new-input-wrap'>
                    <label className='label-text'>From</label>

                    <Input
                      size='sm'
                      label=''
                      type='input'
                      required={false}
                      placeholder='Enter From Value '
                      value={_threshold.from || ''}
                      inputChanged={(from) => {
                        from !== _threshold.from &&
                          props.onThresholdChange(
                            collectionGet(
                              {
                                ..._threshold,
                                from
                              },
                              index,
                              props.threshold
                            )
                          );
                      }}
                    />
                  </Col>

                  <Col md={3} className='new-input-wrap'>
                    <label className='label-text'>To</label>

                    <Input
                      size='sm'
                      label=''
                      type='input'
                      required={false}
                      placeholder='Enter To Value '
                      value={_threshold.to || ''}
                      inputChanged={(to) => {
                        to !== _threshold.to &&
                          props.onThresholdChange(
                            collectionGet(
                              {
                                ..._threshold,
                                to
                              },
                              index,
                              props.threshold
                            )
                          );
                      }}
                    />
                  </Col>

                  <Col md={3} className='new-input-wrap'>
                    <label className='label-text'>Color</label>

                    <Input
                      size='sm'
                      label=''
                      type='color'
                      required={false}
                      placeholder='Enter Color Value '
                      value={_threshold.color || ''}
                      inputChanged={(color) => {
                        color !== _threshold.color &&
                          props.onThresholdChange(
                            collectionGet(
                              {
                                ..._threshold,
                                color
                              },
                              index,
                              props.threshold
                            )
                          );
                      }}
                    />
                  </Col>

                  <Col md={3} className='new-input-wrap'>
                    <label className='label-text'>&nbsp;</label>
                    <div>
                      {index === thresholdCount ? (
                        <Button
                          buttonType='primary'
                          size='sm'
                          innerContent='Add Threshold'
                          className='buttonFill'
                          buttonClick={() => {
                            setThresholdCount((thresholdCount) => {
                              return thresholdCount + 1;
                            });
                          }}
                        />
                      ) : (
                        <Button
                          buttonType='primary'
                          size='sm'
                          innerContent='Delete'
                          className='buttonFill'
                          buttonClick={() => {
                            setThresholdCount((thresholdCount) => {
                              return thresholdCount - 1;
                            });

                            props.onThresholdChange(
                              collectionGet(null, index, props.threshold)
                            );
                          }}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              );
            })}
        </Col>
      </Row>
    );
  };

  return (
    <Fragment>
      <_DateTimeConfig
        periodOptionCollection={['Day', 'Week', 'Month']}
        period={props.dateRange.period}
        from={props.dateRange.from}
        to={props.dateRange.to}
        startTime={props.dateRange.startTime}
        endTime={props.dateRange.endTime}
        appInputParamChanged={(object) => {
          props.onDateRangeChange({ ...props.dateRange, ...object });
        }}
      />

      <hr className='horizontal-divider' />

      <Row className='content-border-wrap mx-0'>
        {renderTableLayoutRow()}

        {renderTableLayoutColumn()}
      </Row>

      <p className='head-label-text mg-t-20 mg-b-15'>Data Type</p>

      <Row>
        <Col md={12}>
          <div className='new-input-wrap'>
            <RadioButtons
              noOfButtons={2}
              getButtonLableAtIndex={(index) => props.dataTypeCollection[index]}
              buttonClickAtIndex={(index) => {
                props.onDataTypeActiveIndexChange(index);
              }}
              active={props.dataTypeActiveIndex}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div className='new-input-wrap'>
            <RadioButtons
              noOfButtons={props.tableConfigCollection.length}
              getButtonLableAtIndex={(index) =>
                props.tableConfigCollection[index]
              }
              buttonClickAtIndex={(index) => {
                props.onTableConfigChange(props.tableConfigCollection[index]);
              }}
              active={props.tableConfigCollection.findIndex(
                (tableConfig) => tableConfig === props.tableConfig
              )}
            />
          </div>
        </Col>
      </Row>

      {renderParameters()}

      <br />
      <br />

      {props.tableConfig === props.tableConfigCollection[1] &&
        renderThreshold()}
    </Fragment>
  );
};

export default Table;
