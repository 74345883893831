import React from 'react';
import Sustainability from "../Sustainability";
import Workplace from '../Workplace';

const Dashboard = React.lazy(() => import('../Dashboard'));
const WidgetBuilder = React.lazy(() => import('../Dashboard/components/WidgetBuilder'));
const EditDashboard = React.lazy(() => import('../Dashboard/components/EditDashboard'));
const DashboardNoAsset = React.lazy(() => import('../Dashboard/components/NoAsset'))
const UserRole = React.lazy(() => import('../UserRole'));
const AssetInsights = React.lazy(() => import('../AssetInsights'));
const NetworkInsights = React.lazy(() => import('../NetworkInsights'));
const NetworkMonitor = React.lazy(() => import('../NetworkMonitor'));
const CBMAssetDetails = React.lazy(() => import('../AssetInsights/CBMAssetDetails'));
const EnterpriseInsights = React.lazy(() => import('../EnterpriseInsights'));
const DataUpload = React.lazy(() => import('../DataUpload'));
const AppExpert = React.lazy(() => import('../AppExpert'))
const AlertDashboardContainer = React.lazy(() => import('../Alert/components/AlertDashboardContainer'));
const ConfiguredAlerts = React.lazy(() => import('../Alert/components/ConfiguredAlerts'));
const AlertBuilder = React.lazy(() => import('../Alert/components/AlertBuilder'));
const AlertLogs = React.lazy(() => import('../Alert/components/AlertLogs'));
const AllAlertLogs = React.lazy(() => import('../Alert/components/AllAlertLogs'));
const LogHistory = React.lazy(() => import('../Alert/components/AlertLogs/components/LogHistory'));
const ConfiguredReports = React.lazy(() => import('../Report/components/ConfiguredReport'));
const ReportBuilder = React.lazy(() => import('../Report/components/ReportBuilder'));
const ConfiguredEvent = React.lazy(() => import('../Event/components/ConfiguredEvent'));
const EventBuilder = React.lazy(() => import('../Event/components/EventBuilder'));
const UserManagement = React.lazy(() => import('../UserManagement'));
const UserBuilder = React.lazy(() => import('../UserManagement/components/UserBuilder'));
const AssetScreenDesign = React.lazy(() => import('../AssetScreenDesign'));
const TemplateContainer = React.lazy(() => import('../AssetScreenDesign/components/TemplateContainer'));
const ServiceDetails = React.lazy(() => import('../AssetScreenDesign/components/ServiceTemplates'));
const SustainabilityDetails = React.lazy(() => import('../SustainabilityTemplates'))
const VintageAssetInsights = React.lazy(() => import('../VintageAssetInsights'));
const VintageAssetDetails = React.lazy(() => import('../VintageAssetDetails'));
const EditTemplate = React.lazy(() => import('../AssetScreenDesign/EditTemplate'));

import DashboardIcon from '../../../../assets/images/sidebar/dashboard.png';
import AlertIcon from '../../../../assets/images/sidebar/alert.svg';
import ActivityIcon from '../../../../assets/images/sidebar/activity.svg';
import ReportIcon from '../../../../assets/images/sidebar/report.svg';
import DataUploadIcon from '../../../../assets/images/sidebar/data-upload.svg';
import AppExpertIcon from '../../../../assets/images/sidebar/app-expert.svg';
import UserManagementIcon from '../../../../assets/images/sidebar/user-management.svg';
import ScreenDesignIcon from '../../../../assets/images/sidebar/screen-design.svg';
import AssetInsightsIcon from '../../../../assets/images/sidebar/asset-insights.svg';
import Home from '../Home';
//import CalculatedParameterIcon from '../../../../assets/images/sidebar/calculated-parameter.svg';
//const CalculatedParameter = React.lazy(() => import('../CalculatedParameter'));
const CalculatedParameter = React.lazy(() =>
  import('../AssetScreenDesign/_components/CalculatedParameter')
);
const CalculatedParameterTemplateCreation = React.lazy(() =>
  import('../AssetScreenDesign/_components/CalculatedParameterTemplateCreation')
);
const WorkingNonWorkingHoursConfiguration = React.lazy(() =>
  import('../AssetScreenDesign/_components/WorkingNonWorkingHoursConfiguration')
);

const ProductionNonProductionHoursConfiguration = React.lazy(() =>
  import('../AssetScreenDesign/_components/ProductionNonProductionHoursConfiguration')
);

const ShiftConfiguration = React.lazy(() =>
  import('../AssetScreenDesign/_components/ShiftConfiguration')
);

const HolidayList = React.lazy(() =>
  import('../AssetScreenDesign/_components/HolidayList')
);
const DynamicVariableTemplateCreation = React.lazy(() =>
  import('../AssetScreenDesign/_components/DynamicVariableTemplateCreation')
);
const DynamicVariableConfiguration = React.lazy(() =>
  import('../AssetScreenDesign/_components/DynamicVariableConfiguration')
);
const AlertTemplateCreation = React.lazy(() =>
  import('../AssetScreenDesign/_components/AlertTemplateCreation')
);
const NetworkAlertTemplateCreation = React.lazy(() =>
  import('../AssetScreenDesign/_components/NetworkAlertTemplateCreation')
);
const CalculatedParameterCreation = React.lazy(() =>
  import('../AssetScreenDesign/_components/CalculatedParameterCreation')
);
const CreateNetworkContainer = React.lazy(() =>
  import('../AssetScreenDesign/components/NetworkTemplate')
)

export const ADMIN = 'Admin';
export const EDITOR = 'Editor';
export const VIEWER = 'Viewer';
export const NOT_ACCESS_MESSAGE = 'You are not authorized';

const localStorageItemGet = (key) => {
  const item = localStorage.getItem(key);

  return item && JSON.parse(item);
};

export const localStorageIsSmeClientGet = () => {
  return ((localStorageItemGet('smartsense.member') || {}).clientid || {})
    .is_sme_client;
};

export const APP_SETTINGS = {
  'My Dashboard': {
    icon: {DashboardIcon},
    iconLight: 'test',
    url: '/Dashboard',
    tooltip: 'Dashboard',
    isAuthUrl: 'My Dashboard',
    name: Dashboard,
    components: [
      { url: '/WidgetBuilder', name: WidgetBuilder },
      { url: '/EditDashboard', name: EditDashboard },
			{ url: '/NoAsset', name: DashboardNoAsset }
    ]
  },
  Alert: {
    icon: {AlertIcon},
    iconLight: 'test',
    url: '/Alert',
    tooltip: 'Alert',
    isAuthUrl: 'Alert',
    name: ConfiguredAlerts,
    components: [
      { url: '/Dashboard', name: AlertDashboardContainer },
      { url: '/Logs', name: AllAlertLogs },
      { url: '/AlertBuilder', name: AlertBuilder },
      { url: '/List', name: ConfiguredAlerts },
      {
        url: '/AlertDetails',
        name: AlertLogs,
        components: [
          { url: '/AlertLogHistory', name: LogHistory }
        ]
      },
    ]
  },
  Activity: {
    icon: {ActivityIcon},
    iconLight: 'test',
    url: '/Activity',
    tooltip: 'Activity',
    isAuthUrl: 'Activity',
    name: ConfiguredEvent,
    components: [
      { url: '/ActivityBuilder', name: EventBuilder },
    ]
  },
  Report: {
    icon: {ReportIcon},
    iconLight: 'test',
    url: '/Report',
    tooltip: 'Report',
    isAuthUrl: 'Reports',
    name: ConfiguredReports,
    components: [
      { url: '/ReportBuilder', name: ReportBuilder },
    ]
  },
  DataUpload: {
    icon: {DataUploadIcon},
    iconLight: 'test',
    url: '/DataUpload',
    tooltip: 'Data Upload',
    isAuthUrl: 'Data Upload',
    name: DataUpload
  },
  AppExpert: {
    icon: {AppExpertIcon},
    iconLight: 'test',
    url: '/AppExpert',
    tooltip: 'App Expert',
    isAuthUrl: 'App Expert',
    name: AppExpert
  },
  AssetInsights: {
    icon: {AssetInsightsIcon},
    iconLight: 'test',
    url: '/AssetInsights',
    tooltip: 'Asset Insights',
    isAuthUrl: 'Asset Insights',
    name: AssetInsights,
    components: [
      { url: '/CBM', name: CBMAssetDetails }
    ]
  },
  NetworkMonitor: {
    icon: {AssetInsightsIcon},
    iconLight: 'test',
    url: '/NetworkMonitor',
    tooltip: 'Network Monitoring',
    isAuthUrl: 'Network Monitoring',
    name: NetworkMonitor,
  },
  NetworkInsights: {
    icon: {AssetInsightsIcon},
    iconLight: 'test',
    url: '/NetworkInsight',
    tooltip: 'Network Insights',
    isAuthUrl: 'Network Insights',
    name: NetworkInsights,
  },
  AssetInsightsVintage: {
    icon: {AssetInsightsIcon},
    iconLight: 'test',
    url: '/AssetInsightsVintage',
    tooltip: 'AssetInsights Classic',
    // label: '<span>AssetInsights<sup style="color:red">Classic</sup></span>',
    isAuthUrl: 'Asset Insights Vintage',
    name: VintageAssetInsights,
    components: [
      { url: '/AssetDetails', name: VintageAssetDetails },
    ]
  },
  // UserManagement: {
  //   icon: 'fa fa-user',
  //   url: '/UserManagement',
  //   tooltip: 'User Management',
  //   isAuthUrl: 'User and Role Management',
  //   name: UserManagement,
  //   components: [
  //     { url: '/UserBuilder', name: UserBuilder },
  //   ]
  // },
  Templates: {
    icon: {ScreenDesignIcon},
    iconLight: 'test',
    url: '/Templates',
    tooltip: 'Design',
    isAuthUrl: 'Template Design',
    name: AssetScreenDesign,
    components: [
      { url: '/TemplateDetails', name: TemplateContainer },
      { url: '/WidgetBuilder', name: WidgetBuilder },
      { url: '/ServiceDetails', name: ServiceDetails },
      { url: '/EditTemplate', name: EditTemplate },
      { url: '/CalculatedParameter', name: CalculatedParameter },
      {
        url: '/CalculatedParameterTemplateCreation',
        name: CalculatedParameterTemplateCreation
      },
      { 
        url: '/WorkingNonWorkingHoursConfiguration', 
        name: WorkingNonWorkingHoursConfiguration 
      },
      {
        url: '/ProductionNonProductionHoursConfiguration',
        name: ProductionNonProductionHoursConfiguration
      },
      { url: '/ShiftConfiguration',name: ShiftConfiguration},
      { url: '/HolidayList', name: HolidayList },
      {
        url: '/DynamicVariableTemplateCreation',
        name: DynamicVariableTemplateCreation
      },
      {
        url: '/DynamicVariableConfiguration',
        name: DynamicVariableConfiguration
      },
      {
        url: '/CalculatedParameterCreation',
        name: CalculatedParameterCreation
      },
      {
        url: '/AlertTemplateCreation',
        name: AlertTemplateCreation
      },
      {
        url: '/NetworkAlertTemplateCreation',
        name: NetworkAlertTemplateCreation
      },
      {
        url: '/CreateEditNetwork',
        name: CreateNetworkContainer
      }
    ]
  },
  // CalculatedParameter: {
  //   icon: {CalculatedParameterIcon},
  //   url: '/CalculatedParameter',
  //   tooltip: 'Calculated Parameter',
  //   isAuthUrl: 'Template Design',
  //   name: CalculatedParameter,
  //   components: [
  //     { url: '/CalculatedParameterCreation', name: FormulaCreationContainer },
  //   ]
  // },
  User: {
    icon: {DashboardIcon},
    url: '/UserRole',
    tooltip: 'UserRole',
    name: UserRole
  },
  'Workplace': {
    icon: {DashboardIcon},
    url: '/Workplace',
    tooltip: 'Workplace',
    name: Workplace,
  },
  'EnterPrise Insights': {
    icon: 'fa fa-industry',
    url: '/EnterpriseInsights',
    tooltip: 'Enterprise Insights',
    name: EnterpriseInsights
  },
  'Sustainability': {
    icon: {DashboardIcon},
    iconLight: 'test',
    url: '/Sustainability',
    tooltip: 'Sustainability',
    name: Sustainability,
    // label: '<span>Sustainability</span>',
    components: [
      { url: '/SustainabilityDetails', name: SustainabilityDetails },
    ]
  },
  'Home': {
    icon: {DashboardIcon},
    iconLight: 'test',
    url: '/Home',
    tooltip: 'Home',
    name: Home,
    // label: '<span>Sustainability</span>',
    components: [
      { url: '/Home', name: SustainabilityDetails },
    ]
  },
  // Partner: {
  //   icon: 'fa fa-industry',
  //   url: '/PartnerLogin',
  //   tooltip: 'Partner Login',
  //   name: PartnerLogin
  // },
};


export const activeAppSettings = () => {
  let defaultService;
  const service_config =
    localStorage.getItem('smartsense.services') &&
    JSON.parse(localStorage.getItem('smartsense.services'));
  if (JSON.parse(localStorage.getItem('smartsense.defaultService'))) {
    defaultService = JSON.parse(
      localStorage.getItem('smartsense.defaultService')
    );
  }

  const servicesTooltip = [
    'Dashboard',
    'Asset Insights',
    'Sustainability',
    'Workplace',
    'Home',
    'Network Insights',
    'Network Monitoring'
  ];

  if (localStorageIsSmeClientGet()) {
    servicesTooltip.push('Design');
  }

  let appSettings = {};
  Object.keys(APP_SETTINGS).map((key) => {
    service_config &&
      [...service_config, { name: 'ESG' }].map((service) => {
        if (APP_SETTINGS[key].isAuthUrl == service.name) {
          if (!servicesTooltip.includes(APP_SETTINGS[key].tooltip)) {
            Object.assign(appSettings, {
              [key]: {
                icon: APP_SETTINGS[key].icon,
                iconLight: APP_SETTINGS[key].iconLight,
                url: APP_SETTINGS[key].url,
                tooltip: APP_SETTINGS[key].tooltip,
                label: APP_SETTINGS[key].label || APP_SETTINGS[key].tooltip,
                component: APP_SETTINGS[key].component
              }
            });
          }
        }
      });
  });

  if (userRoles && userRoles() && userRoles()[0] && userRoles()[0].name == ADMIN) {
    Object.assign(appSettings, {
      UserManagement: {
        icon: {UserManagementIcon},
        iconLight: 'test',
        url: '/UserManagement',
        tooltip: 'User Management',
        label: 'User Management',
        isAuthUrl: 'User and Role Management',
        name: UserManagement,
        components: [
          { url: '/UserBuilder', name: UserBuilder },
        ]
      }
    })
  }

  // Object.assign(appSettings, {
  //   'Sustainability': {
  //     icon: {DashboardIcon},
  //     url: `/Sustainability/`,
  //     tooltip: 'Sustainability',
  //     name: Sustainability,
  //     // label: '<span>Sustainability</span>'
  //   },
  // });

  return appSettings;
};

export const getActiveConfigRoutes = () => {
  const service_config = localStorage.getItem('smartsense.services') && JSON.parse(localStorage.getItem('smartsense.services'));
  let routes = []
  Object.keys(APP_SETTINGS).map(key => {
    service_config &&
			service_config.map(service => {
        if (APP_SETTINGS[key].isAuthUrl == service.name) routes.push(APP_SETTINGS[key]);
      });
  });
  if (userRoles && userRoles() && userRoles()[0] && userRoles()[0].name == ADMIN) {
    routes.push({
      icon: {UserManagementIcon},
      iconLight: 'test',
      url: '/UserManagement',
      tooltip: 'User Management',
      isAuthUrl: 'User and Role Management',
      name: UserManagement,
      components: [
        { url: '/UserBuilder', name: UserBuilder },
      ]
    })
  }

  routes.push(APP_SETTINGS['Workplace']);
  routes.push(APP_SETTINGS['Sustainability']);
  routes.push(APP_SETTINGS['Home']);
  return routes;
}

export const getAppSettingsForUrl = (url) => {
  return APP_SETTINGS[Object.keys(APP_SETTINGS).filter((elem) => APP_SETTINGS[elem].url === url)[0]];
};

// temp
export const activeUserRoles = () => {
  let roles = {}
  const userRole = userRoles()
  // userRoles && userRoles().map(item => roles[item.name] = true)
  userRole && userRole.map(item => roles[item.name] = { id: item.id, name: item.name, permissions: item.permissions });
  return roles;
}

export const activeServices = () => {
  const service_config = localStorage.getItem('smartsense.services') && JSON.parse(localStorage.getItem('smartsense.services'));
  let services = {}
  service_config && service_config.map(service => {
    services[service.name] = { id: service.id, name: service.name, url: service.url }
  });
  return services;
}

export const activeUserPermissions = (name) => {
  // let userRoles = localStorage.getItem('smartsense.roles') && JSON.parse(localStorage.getItem('smartsense.roles'));
  return userRoles && userRoles().map(item => {
    return item && item.permissions && item.permissions.some(permn => permn.name === name)
  })[0];
}


export const userRoles = () => {
  // let isAdminProfile = localStorage.getItem('adminProfile');
  let data = []
  // if (isAdminProfile == 'false') {
  //   data = localStorage.getItem('smartsense.adminRoles') && JSON.parse(localStorage.getItem('smartsense.adminRoles'))
  //   return data;
  // } else {
  data = localStorage.getItem('smartsense.roles') && JSON.parse(localStorage.getItem('smartsense.roles'))
  return data;
  // }
}