import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import DropDownSVG from '../../../../assets/images/Icons/Arrows/Chevron-DownSVG.svg';
import UpSVG from '../../../../assets/images/Icons/Arrows/Chevron-RightSVG.svg';
import PropTypes from 'prop-types';

/**
 * Example Format Data
 */
const EXAMPLE_DATA = {
  id: 'root',
  name: 'Parent',
  children: [
    {
      id: '1',
      name: 'Child - 1',
    },
    {
      id: '3',
      name: 'Child - 3',
      children: [
        {
          id: '4',
          name: 'Child - 4',
        },
      ],
    },
  ],
};

const useStyles = makeStyles({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
  },
});

function TreeBuilder(props) {
  const classes = useStyles();
  const getItem = (nodes) => { return <div className = 'treebuilder-item'>{props.getItemForNodeId(nodes)}</div>}

  const renderTree = (nodes) => {
    if (nodes.length > 0) return;

    let itemContent = getItem(nodes);

    return (
      <TreeItem 
        key={nodes.id} 
        nodeId={nodes.id} 
        label={itemContent} 
        onLabelClick = {() => {
          props.labelClickedForNodeId(nodes.id)
        }}
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    )
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<img src= {DropDownSVG}></img>}
      defaultExpanded={['root']}
      defaultExpandIcon={<img src= {UpSVG}></img>}
      onNodeSelect = {()=>{ }}
    >
      {renderTree(props.data)}
    </TreeView>
  );
}

TreeBuilder.defaultProps = {
  data: EXAMPLE_DATA,
  getItemForNodeId: function(n) {return n.name},
  labelClickedForNodeId: function() {},
}

TreeBuilder.propTypes = {
  data: PropTypes.array,
  getItemForNodeId: PropTypes.func,
  labelClickedForNodeId: PropTypes.func,
}

export default TreeBuilder;