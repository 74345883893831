
// util function to perform vaildation on form fields

export const isFormValid = (oFormFields, fInvalidationHandler) => {
  let oInValidFields = Object.create(null);

  for (var sInputFieldName in oFormFields) {
    let sFieldValue = oFormFields[sInputFieldName].toString();

    switch (sInputFieldName) {
      case 'firstname':
      case 'username':
      case 'name':
      case 'description':
      case 'permissions':
      case 'select':
      case 'text':
        if (sFieldValue.trim().length === 0) {
          oInValidFields[sInputFieldName] = true;
        }
        break;

      case 'email':
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(sFieldValue))) {
          oInValidFields[sInputFieldName] = true;
        }
        break;

      case 'password':
        // TO DO pswd regex from modang
        let oPasswordPattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

        if (!oPasswordPattern.test(sFieldValue)) {
          oInValidFields[sInputFieldName] = true;
        }
        break;

      case 'phonenumber':
        if (!(/^\d{10}$/.test(sFieldValue))) {
          oInValidFields[sInputFieldName] = true;
        }
        break;
    }
  };
  if (Object.keys(oInValidFields).length && typeof fInvalidationHandler === 'function') {
    fInvalidationHandler(oInValidFields);
    return false;
  }

  return true;
}

// Check if the input is empty or NULL
export function isEmptyOrNull(state, error, propName, errorMessage) {
  //Check for empty OR NULL/Undefined  
  if (state[propName] === '' || state[propName] == null || state[propName].length == 0 || state[propName] === "") {
    //Set Error Object from localized resource file
    error[propName] = errorMessage;
    return true;
  }
  return false;
}

// Check email is valid or not
export function isEmailValid(state, error, propName, errorMessage) {
  var validateEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // var validateEmail = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/
  // let emailSplittedArray = propName && state[propName] && state[propName].trim().split('@');
  //   if (emailSplittedArray.length == 2 && emailSplittedArray[0].length < 2 || !validateEmail.test(state[propName] && state[propName].trim())) {

  if (propName && state[propName] && !validateEmail.test(state[propName] && state[propName].trim())) {
    error[propName] = errorMessage;
    let focusError = document.getElementById(propName);
    if (focusError !== null) {
      focusError.focus();
    }
    return true;
  }
  return false;

}

//Check email string
export function isEmailCorrect(email) {
  let emailSplittedArray = email && email.split('@');
  if (emailSplittedArray.length == 2 && emailSplittedArray[0].length < 3) {
    return false;
  }
  var validateEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var isValid = validateEmail.test(email);
  return isValid;
}

// Check mobile string
export function isMobileCorrect(state, error, propName, errorMessage) {
  const pattern = new RegExp(/^[0-9\b]+$/);
  if (!pattern.test(state[propName])) {
    error[propName] = errorMessage;
    return true;
  } else {
    return false;
  }
}

// Check mobile is valid or not
export function isMobileValid(state, error, propName, errorMessage, tabKeys) {
  var validateMobile = /^(\+\d{1,3}[- ]?)?\d{6,15}$/;
  if (!validateMobile.test(state[propName])) {
    tabKeys ? error[tabKeys] ? error[tabKeys][propName] = errorMessage : error[tabKeys] = { [propName]: errorMessage } : error[propName] = errorMessage;
    let focusError = document.getElementById(propName);
    if (focusError !== null) {
      focusError.focus();
    }
  }
}

export function isStringValid(state, error, propName, errorMessage) {
  var validateString = /[!$%^&*()+|~=`{}\[\]:\/;?,.@#]/;
  if (validateString.test(state[propName])) {
    error[propName] = errorMessage;
    return true;
  } else {
    return false;
  }
}

export function isInRange(state, error, propName, errorMessage, max) {
  if (Number(state[propName]) > Number(max)) {
    error[propName] = T.translate(errorMessage);
    return true
  }
  return false;
}