import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators'
import Config from 'Config'
import { httpMethodGetJSON, httpMethodPatch, httpMethodDelete, httpMethodPost } from '../https';
import {
  DU_GET_ALL_TEMPLATE,
  DU_SAVE_TEMPLATE,
  DU_GENERATE_TEMPLATE,
  UPLOAD_DATA_FILE,
  DU_GET_ALL_UPLOADED_DATA_FILE,
  DU_UPDATE_TEMPLATE,
  DU_GET_TEMPLATE_HISTORY,
  CHECK_ALL_TEMPLATES,
  CHECK_ALL_TEMPLATES_SUCCESS,
} from './constant';
import { of } from 'rxjs';

export const URL = Config.api_url;

export const getAllTemplate = action$ => action$.pipe(
  ofType(DU_GET_ALL_TEMPLATE),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/data-uploads/templates/`);
  })
);

export const getTemplateHistory = action$ => action$.pipe(
  ofType(DU_GET_TEMPLATE_HISTORY),
  mergeMap(action => {
    let id = action.payload.id;
    delete action.payload.id;
    return httpMethodGetJSON(action, `${URL}/data-uploads/templates/${id}/history`);
  })
);

export const saveTemplate = action$ => action$.pipe(
  ofType(DU_SAVE_TEMPLATE),
  mergeMap(action => {
    return httpMethodPost(action, `${URL}/data-uploads/templates/`);
  })
);

export const updateDataTemplate = action$ => action$.pipe(
  ofType(DU_UPDATE_TEMPLATE),
  mergeMap(action => {
    let id = action.payload.id;
    delete action.payload.id;
    return httpMethodPatch(action, `${URL}/data-uploads/templates/${id}/`);
  })
);

export const generateTemplate = action$ => action$.pipe(
  ofType(DU_GENERATE_TEMPLATE),
  mergeMap(action => {
    return httpMethodPost(action, `${URL}/data-uploads/templates/downloads/`);
  })
);

export const uploadDataFile = (action$) =>
  action$.pipe(
    ofType(UPLOAD_DATA_FILE),
    mergeMap((action) => {
      let token = `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}`;
      const formData = new FormData();
      formData.append('file', action.payload.file);
      formData.append('email', JSON.stringify(action.payload.email));
      let param = {};
      param.payload = formData;
      param.type = action.type;
      return httpMethodPost(
        param, 
        `${URL}/data-uploads/`,
        { Authorization: token, }
      );
  }),
);

export const getAllUploadedDataFiles = action$ => action$.pipe(
  ofType(DU_GET_ALL_UPLOADED_DATA_FILE),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/data-uploads/`);
  })
);

export const checkAllTemplates = action$ => action$.pipe(
  ofType(CHECK_ALL_TEMPLATES),
  mergeMap(action => {
      return of({ type: CHECK_ALL_TEMPLATES_SUCCESS, payload: action.payload });
  })
);

export const dataUploadEpics = {
  getAllTemplate,
  saveTemplate,
  updateDataTemplate,
  generateTemplate,
  uploadDataFile,
  getAllUploadedDataFiles,
  getTemplateHistory,
  checkAllTemplates,
}