import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import Config from 'Config';

import { GET_USERS } from './constant';
import { httpMethodGetJSON } from '../../../../src/admin/container/https';

const API_URL = `${Config.api_url}/users`;

export const getUsersEpic = action$ => action$.pipe(
  ofType(GET_USERS),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${API_URL}/`);
  })
);