import React, { useState, Fragment } from 'react';

import parameterConfInputGet from '../common/parameterConfInputGet';
import collectionGet from '../common/collectionGet';
import _ParameterConfig from './_ParameterConfig';
import ParameterMixedCollection from '../Common/ParameterMixedCollection';

const ParameterConfigMixed = (props) => {
  const [confCount, setConfCount] = useState(props.confs.length || 1);

  const [confActiveIndex, setConfActiveIndex] = useState(confCount - 1);

  const _renderParameterConfig = (conf, index) => {
    return (
      <_ParameterConfig
        conf={conf}
        onConfChange={(conf) => {
          props.onConfsChange(collectionGet(conf, index, props.confs));
        }}
        onParameterConfsChange={(conf) => {
          const _conf = {
            ...conf,
            index,
            isComputed: false
          };

          props.onConfsChange(collectionGet(_conf, index, props.confs));
        }}
        onFormulaConfsChange={(conf) => {
          const _conf = {
            ...conf,
            index,
            isComputed: true
          };

          props.onConfsChange(collectionGet(_conf, index, props.confs));
        }}
      />
    );
  };

  const renderParameterConfig = (conf, index) => {
    return (
      <Fragment key={index}>
        <p className='head-label-text mg-b-10'>Parameter {index + 1}</p>

        {_renderParameterConfig(conf, index)}
      </Fragment>
    );
  };

  const renderParameterMixedCollection = () => {
    return (
      <ParameterMixedCollection
        confs={props.confs}
        onConfActiveIndexChangeTrigger={(index) => {
          setConfActiveIndex(index);
        }}
        onConfDeleteTrigger={(index) => {
          const _confActiveIndex = (() => {
            switch (true) {
              case index < confActiveIndex:
                return confActiveIndex - 1;

              case index > confActiveIndex:
                return confActiveIndex;

              case index === confActiveIndex && !!index:
                return confActiveIndex - 1;

              default:
                return 0;
            }
          })();

          setConfActiveIndex(_confActiveIndex);

          const confs = collectionGet(null, index, props.confs);

          props.onConfsChange(
            confs.length
              ? confs
              : [
                  {
                    ...parameterConfInputGet(),
                    isComputed: false
                  }
                ]
          );
        }}
      />
    );
  };

  return (
    <Fragment>
      {renderParameterConfig(
        props.confs[confActiveIndex] || {},
        confActiveIndex
      )}

      <div className='btn-wrap'>
        <button
          className='icon-text-btn mg-t-15'
          onClick={() => {
            setConfActiveIndex(confCount);

            setConfCount((confCount) => ++confCount);

            props.onConfsChange([
              ...props.confs,
              { ...parameterConfInputGet(), isComputed: false }
            ]);
          }}
        >
          <i className='fa fa-plus-circle' aria-hidden='true'></i> Add Parameter
        </button>
      </div>

      <br />

      {renderParameterMixedCollection()}
    </Fragment>
  );
};

export default ParameterConfigMixed;
