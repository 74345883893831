import React from "react";
import {Col, Row, Input} from 'reactstrap';
import PropTypes from "prop-types";
import Button from '../Button';
import Icon from '../Icon';
import {ICON_POSITION} from '../Icon/constants';
import '../Button/button.scss';
// import leftArrow from '../../../../assets/images/Icons/Arrows/Chevron-LeftSVG.svg';
// import rightArrow from '../../../../assets/images/Icons/Arrows/Chevron-RightSVG.svg';
import search from '../../../../assets/images/Icons/Actions/SearchSVG.svg';
import leftArrow from '../../../../assets/images/UserRole/b-leftSVG.svg';
import rightArrow from '../../../../assets/images/UserRole/b-rightSVG.svg';
import "./table.scss"

const defaultButton = props => <button {...props}>{props.children}</button>;

export default class Pagination extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            q: this.props.filterQuery ? this.props.filterQuery : '',
            hideSearch:this.props.hideSearch?this.props.hideSearch:false
        }

        this.changePage = this.changePage.bind(this);

        this.handleFilter = this.handleFilter.bind(this);
        this.timeout = null;
    }

    static propTypes = {
        pages: PropTypes.number,
        page: PropTypes.number,
        PageButtonComponent: PropTypes.any,
        onPageChange: PropTypes.func,
        previousText: PropTypes.string,
        nextText: PropTypes.string
    };

    componentDidUpdate(prevProps) {
        if (this.props.filterQuery !== prevProps.filterQuery) {
            this.setState({
                q: this.props.filterQuery
            });
        }
    }


    changePage(page) {
        this.props.onPageChange(page - 1);
    }

    handleFilter(event) {
        let val = event.target.value;
        clearTimeout(this.timeout);

        this.setState({
            q: val
        });
        if (event.type === 'keyup' && event.key === 'Enter') {
            this.props.filterTable(val, this.props.filterableColumns);
        } else {
            this.timeout = setTimeout(function (val) {
                this.props.filterTable(val, this.props.filterableColumns);
            }.bind(this, val), 1000);
        }
    }

    render() {
        const {PageButtonComponent = defaultButton} = this.props;
        const activePage = parseInt(this.props.activePage) < 1 ? 1 : parseInt(this.props.activePage);
        const filterQuery = this.props.filterQuery;

        return (
            <div className="Table__pagination">
                <Row>
                    <Col sm={6} className='tableFilter'>
                        {!this.state.hideSearch &&<Input
                            bsSize='sm'
                            name='TableSearchkey'
                            placeholder={this.props.placeholder ?  'Search by Name' : this.props.placeholder}
                            onChange={this.handleFilter}
                            onKeyUp={this.handleFilter}
                            value={this.state.q}
                            autoComplete="off"
                        />}
                        <Icon
                            size={22}
                            alt=''
                            src={search}
                            position={ICON_POSITION.CENTER_RIGHT}
                        />
                    </Col>

                    <Col sm={6} className='tablepaging'>
                        <div className="tableButtonWrapper float-right">
                            <Button
                                color='secondory'
                                buttonClick={() => {
                                    if (activePage === 1) return;
                                    this.changePage(activePage - 1);
                                }}
                                size={'sm'}
                                isDisabled={activePage === 1 || activePage === 1}
                                innerContent={
                                    (
                                        <div className="table-arrow-left">
                                            <Icon
                                                size={16}
                                                alt=''
                                                src={leftArrow}
                                                position={ICON_POSITION.CENTER_CENTER}
                                            />
                                        </div>
                                    )
                                }
                            />
                            <div className='pageInfo'> {activePage} of {this.props.pages}</div>
                            <Button
                                color='secondory'
                                buttonClick={() => {
                                    if (activePage === this.props.pages) return;
                                    this.changePage(parseInt(activePage) + 1);
                                }}
                                size={'sm'}
                                isDisabled={activePage === this.props.pages}
                                innerContent={
                                    (
                                        <div className="table-arrow-right">
                                            <Icon
                                                size={16}
                                                alt=''
                                                src={rightArrow}
                                                position={ICON_POSITION.CENTER_CENTER}
                                            />
                                        </div>
                                    )
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
