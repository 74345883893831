import React, { Fragment } from 'react';
import { Row, Col, Label } from 'reactstrap';

import tagConfsInputGet from '../common/tagConfsInputGet';
import _tagFormulaConfInputGet from '../common/_tagFormulaConfInputGet';
import tagFormulaConfInputGet from '../common/tagFormulaConfInputGet';
import collectionGet from '../common/collectionGet';
import TagParameterConfigNormal from './TagParameterConfigNormal';
import {
  Select,
  Button,
  CheckBox,
  Input
} from '../../../../../../../common/components';

const operations = [
  { value: '+', label: 'Add' },
  { value: '-', label: 'Substract' },
  { value: '*', label: 'Multiply' },
  { value: '/', label: 'Divide' },
  { value: '**', label: 'Power' },
  { value: 'and', label: 'And' },
  { value: 'or', label: 'Or' },
  { value: '>', label: '>' },
  { value: '<', label: '<' },
  { value: '==', label: '==' },
  { value: '!=', label: '!=' }
];

const tagParameterConfsGet = (conf) => {
  const tagParameterConfs = conf && [conf];

  return tagConfsInputGet(tagParameterConfs).reduce(
    (memo, tagParameterConf) => {
      return [...memo, tagParameterConf];
    },
    []
  );
};

const Operand = ({
  inputFieldColumnWidth,
  aliasVisiblityToggle,
  blankVisiblityToggle,
  allPlantFlag,
  plant,
  formulaDisable,
  showDelete,
  formula: { id, type, parameter, value, formula },
  onFormulaChange,
  onFormulaDelete
}) => {
  const onFormulaTypeChange = (event) => {
    const formula = {
      id,
      type: event.target.value,
      ...(() => {
        switch (event.target.value) {
          case 'parameter':
            return _tagFormulaConfInputGet();

          case 'constant':
            return { value: '' };

          case 'formula':
            return tagFormulaConfInputGet();
        }
      })()
    };

    onFormulaChange(formula);
  };

  return (
    <div className='operand'>
      <Col md={12} className='header'>
        <label className='radio-inline'>
          <input
            name={id}
            value='parameter'
            type='radio'
            className='radio-inline'
            checked={type === 'parameter'}
            onChange={onFormulaTypeChange}
          />
          <span>Parameter</span>
        </label>

        <label className='radio-inline'>
          <input
            name={id}
            value='constant'
            type='radio'
            checked={type === 'constant'}
            onChange={onFormulaTypeChange}
          />
          <span>Constant Value</span>
        </label>

        {!formulaDisable && (
          <label className='radio-inline'>
            <input
              name={id}
              value='formula'
              type='radio'
              checked={type === 'formula'}
              onChange={onFormulaTypeChange}
            />
            <span>Formula</span>
          </label>
        )}

        {showDelete && (
          <span className='text-danger delete' onClick={onFormulaDelete}>
            Delete
          </span>
        )}
      </Col>
      {(() => {
        const _id = { id, type };

        switch (type) {
          case 'parameter':
            return (
              <TagParameterConfigNormal
                inputFieldColumnWidth={inputFieldColumnWidth}
                aliasVisiblityToggle={aliasVisiblityToggle}
                unitVisiblityToggle={true}
                blankVisiblityToggle={blankVisiblityToggle}
                allPlantFlag={allPlantFlag}
                plant={plant}
                tagParameterConfs={tagParameterConfsGet(parameter)}
                onParameterTagConfsChange={(tagParameterConf) => {
                  onFormulaChange({
                    ..._id,
                    parameter: tagParameterConf
                  });
                }}
              />
            );

          case 'constant':
            return (
              <Col md={6} className='inputField select-dropdown'>
                <Label>Value</Label>

                <Input
                  value={value}
                  placeholder='value'
                  type='text'
                  inputChanged={(_value) => {
                    _value !== value &&
                      onFormulaChange({
                        ..._id,
                        value: _value
                      });
                  }}
                />
              </Col>
            );

          case 'formula':
            return (
              <Formula
                key={0}
                formulaDisable={false}
                inputFieldColumnWidth={inputFieldColumnWidth}
                aliasVisiblityToggle={aliasVisiblityToggle}
                unitVisiblityToggle={true}
                allPlantFlag={allPlantFlag}
                plant={plant}
                formulaCollection={formula}
                onFormulaCollectionChange={(formulaCollection) => {
                  onFormulaChange({
                    ..._id,
                    formula: formulaCollection
                  });
                }}
              />
            );
        }
      })()}
    </div>
  );
};

const Formula = (props) => {
  return (
    <div className='width100'>
      <Col className='formulaMaker' md={12}>
        {props.formulaCollection.map((formula, index) => {
          return (
            <Fragment key={index}>
              {formula.type === 'operator' ? (
                <div className='operator'>
                  <Select
                    onChange={({ value }) => {
                      const formulaCollection = collectionGet(
                        {
                          ...formula,
                          value
                        },
                        index,
                        props.formulaCollection,
                        false
                      );

                      props.onFormulaCollectionChange(formulaCollection);
                    }}
                    value={operations.find(
                      ({ value }) => value === formula.value
                    )}
                    name='operator'
                    options={operations}
                  />
                </div>
              ) : (
                <div>
                  <Operand
                    inputFieldColumnWidth={6}
                    aliasVisiblityToggle={true}
                    blankVisiblityToggle={props.blankVisiblityToggle}
                    allPlantFlag={props.allPlantFlag}
                    plant={props.plant}
                    formulaDisable={props.formulaDisable}
                    showDelete={index !== 0}
                    formula={formula}
                    onFormulaChange={(formula) => {
                      const formulaCollection = collectionGet(
                        formula,
                        index,
                        props.formulaCollection,
                        false
                      );

                      props.onFormulaCollectionChange(formulaCollection);
                    }}
                    onFormulaDelete={() => {
                      const formulaCollection = [
                        ...props.formulaCollection.slice(0, index - 1),
                        ...props.formulaCollection.slice(index + 1)
                      ];

                      props.onFormulaCollectionChange(formulaCollection);
                    }}
                  />
                </div>
              )}
            </Fragment>
          );
        })}
        <Col md={3}>
          <Button
            buttonType='secondary'
            size='sm'
            buttonClick={() => {
              const formulaCollection = [
                ...props.formulaCollection,
                { type: 'operator', value: '+' },
                _tagFormulaConfInputGet()
              ];

              props.onFormulaCollectionChange(formulaCollection);
            }}
            innerContent='+ Add Operand'
          />
        </Col>
      </Col>
    </div>
  );
};

const TagParameterConfigComputed = (props) => {
  const equationGet = (formulaCollection) => {
    return formulaCollection.reduce(
      (memo, { type, parameter, value, formula }) => {
        const _value = (() => {
          switch (type) {
            case 'parameter':
              return parameter
                ? `${parameter.plant.label} ${parameter.tag.label}`
                : '';

            case 'constant':
              return value;

            case 'formula':
              return equationGet(formula);

            case 'operator':
              return value;
          }
        })();

        return `${memo} ${_value}`.trim();
      },
      ''
    );
  };

  return (
    <Fragment>
      <Row>
        {!props.formulaAliasVisiblityToggle && (
          <Col md={3}>
            <Input
              type='text'
              label='Alias'
              placeholder='Enter Alias'
              required={false}
              value={props.tagFormulaConfs[0].alias || ''}
              inputChanged={(alias) => {
                props.onTagFormulaConfsChange({
                  ...props.tagFormulaConfs[0],
                  alias
                });
              }}
            />
          </Col>
        )}

        {!props.unitVisiblityToggle && (
          <Fragment>
            <Col md={3}>
              <Input
                type='text'
                label='Unit Prefix'
                placeholder='Enter Unit Prefix'
                required={false}
                value={props.tagFormulaConfs[0].unitPrefix || ''}
                inputChanged={(unitPrefix) => {
                  props.onTagFormulaConfsChange({
                    ...props.tagFormulaConfs[0],
                    unitPrefix
                  });
                }}
              />
            </Col>

            <Col md={3}>
              <Input
                type='text'
                label='Unit Suffix'
                placeholder='Enter Unit Suffix'
                required={false}
                value={props.tagFormulaConfs[0].unitSuffix || ''}
                inputChanged={(unitSuffix) => {
                  props.onTagFormulaConfsChange({
                    ...props.tagFormulaConfs[0],
                    unitSuffix
                  });
                }}
              />
            </Col>
          </Fragment>
        )}

        <Col className='d-flex justify-content-end align-items-end'>
          <div>
            <CheckBox
              label='Show Partial Calculation Result'
              checked={props.tagFormulaConfs[0].showPartial}
              checkboxClicked={(showPartial) => {
                props.onTagFormulaConfsChange({
                  ...props.tagFormulaConfs[0],
                  showPartial
                });
              }}
            />
          </div>
        </Col>
      </Row>

      <br />

      {props.colorVisiblityToggle && (
        <Fragment>
          <Row>
            <Col md={3}>
              <Input
                type='color'
                label='Color'
                placeholder='Chart Color'
                value={props.tagFormulaConfs[0].color || ''}
                inputChanged={(color) => {
                  color !== props.tagFormulaConfs[0].color &&
                    props.onTagFormulaConfsChange({
                      ...props.tagFormulaConfs[0],
                      color
                    });
                }}
              />
            </Col>
          </Row>

          <br />
        </Fragment>
      )}

      {!!props.Component && (
        <Fragment>
          {props.Component(
            props.tagFormulaConfs,
            props.onTagFormulaConfsChange
          )}
          <br />
        </Fragment>
      )}

      {[
        <Formula
          key={0}
          allPlantFlag={props.allPlantFlag}
          plant={props.plant}
          formulaDisable={false}
          formulaCollection={props.tagFormulaConfs[0].formula}
          onFormulaCollectionChange={(formulaCollection) => {
            props.onTagFormulaConfsChange({
              ...props.tagFormulaConfs[0],
              formula: formulaCollection,
              equation: equationGet(formulaCollection)
            });
          }}
        />
      ]}
    </Fragment>
  );
};

export default TagParameterConfigComputed;
