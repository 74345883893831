export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_USER_RESET = 'CREATE_USER_RESET';

export const CHECK_ALL_USER_SUCCESS = 'CHECK_ALL_USER_SUCCESS';
export const CHECK_USER_SUCCESS = 'CHECK_USER_SUCCESS';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const DELETE_USER_RESET = 'DELETE_USER_RESET';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE';

export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const GET_ALL_ROLES_FAILURE = 'GET_ALL_ROLES_FAILURE';

export const GET_ALL_MEMBERS = 'GET_ALL_MEMBERS';
export const GET_ALL_MEMBERS_SUCCESS = 'GET_ALL_MEMBERS_SUCCESS';
export const GET_ALL_MEMBERS_FAILURE = 'GET_ALL_MEMBERS_FAILURE';

export const GET_DASHBOARD_BY_USER_ID = 'GET_DASHBOARD_BY_USER_ID';
export const GET_DASHBOARD_BY_USER_ID_SUCCESS = 'GET_DASHBOARD_BY_USER_ID_SUCCESS';
export const GET_DASHBOARD_BY_USER_ID_FAILURE = 'GET_DASHBOARD_BY_USER_ID_FAILURE';

export const GET_WIDGETS_BY_DASHBOARD_AND_USER_ID = 'GET_WIDGETS_BY_DASHBOARD_AND_USER_ID';
export const GET_WIDGETS_BY_DASHBOARD_AND_USER_ID_SUCCESS = 'GET_WIDGETS_BY_DASHBOARD_AND_USER_ID_SUCCESS';
export const GET_WIDGETS_BY_DASHBOARD_AND_USER_ID_FAILURE = 'GET_WIDGETS_BY_DASHBOARD_AND_USER_ID_FAILURE';