import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { URL } from '../../../constants';
import { httpMethodPost, httpMethodPut } from '../../../https';
import {
  CREATE_NETWORK_CALCULATED_PARAM_TEMPLATE,
  UPDATE_NETWORK_CALCULATED_PARAM_TEMPLATE
} from './constant';

export const createNetworkCalculatedParamTemplate = (action$) => {
  return action$.pipe(
    ofType(CREATE_NETWORK_CALCULATED_PARAM_TEMPLATE),
    mergeMap((action) => {
      const { network_type } = action.payload;

      return httpMethodPost(
        action,
        `${URL}/network_type/${network_type}/calculated_param_template/`
      );
    })
  );
};

export const updateNetworkCalculatedParamTemplate = (action$) => {
  return action$.pipe(
    ofType(UPDATE_NETWORK_CALCULATED_PARAM_TEMPLATE),
    mergeMap((action) => {
      const { network_type, id } = action.payload;

      return httpMethodPut(
        action,
        `${URL}/network_type/${network_type}/calculated_param_template/${id}/`
      );
    })
  );
};
