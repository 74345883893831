import { actions } from 'react-table';
import {
  CHECK_ALERT_SUCCESS,
  CHECK_ALL_ALERT_SUCCESS,
  GET_ALL_ALERTS_RESET,
  CREATE_ALERT,
  CREATE_ALERT_FAILURE,
  CREATE_ALERT_RESET,
  CREATE_ALERT_SUCCESS,
  DELETE_ALERT,
  DELETE_ALERT_FAILURE,
  DELETE_ALERT_RESET,
  DELETE_ALERT_SUCCESS,
  GET_ALERT_HISTORY_BY_ID,
  GET_ALERT_HISTORY_BY_ID_FAILURE,
  GET_ALERT_HISTORY_BY_ID_SUCCESS,
  GET_ALERT_LOG_ALL,
  GET_ALERT_LOG_ALL_FAILURE,
  GET_ALERT_LOG_ALL_SUCCESS,
  GET_ALERT_LOG_BY_ID,
  GET_ALERT_LOG_BY_ID_FAILURE,
  GET_ALERT_LOG_BY_ID_SUCCESS,
  GET_ALL_ALERTS,
  GET_ALL_ALERTS_FAILURE,
  GET_ALL_ALERTS_SUCCESS,
  UPDATE_ALERT,
  UPDATE_ALERT_FAILURE,
  UPDATE_ALERT_LOG,
  UPDATE_ALERT_LOG_FAILURE,
  UPDATE_ALERT_LOG_RESET,
  UPDATE_ALERT_LOG_SUCCESS,
  UPDATE_ALERT_RESET,
  UPDATE_ALERT_SUCCESS,
  GET_ALERT_HISTORY_LOGS_BY_ID,
  GET_ALERT_HISTORY_LOGS_BY_ID_SUCCESS,
  GET_ALERT_HISTORY_LOGS_BY_ID_FAILURE,
  GET_GENERATED_ALERT_SUMMARY,
  GET_GENERATED_ALERT_SUMMARY_SUCCESS,
  GET_GENERATED_ALERT_SUMMARY_FAILURE,
  CHECK_ALERT_LOG_SUCCESS,
  CHECK_ALL_ALERT_LOG,
  CHECK_ALL_ALERT_LOG_SUCCESS,
  UPDATE_ALERT_LOG_STATUS,
  UPDATE_ALERT_LOG_STATUS_SUCCESS,
  UPDATE_ALERT_LOG_STATUS_FAILURE,
  DOWNLOAD_ALERT_LOGS,
  DOWNLOAD_ALERT_LOGS_SUCCESS,
  DOWNLOAD_ALERT_LOGS_FAILURE,
  MAIN_ALERT_REDIRECTION,
  MAIN_ALERT_REDIRECTION_RESET,
  DOWNLOAD_ALERT_LOGS_RESET
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined,
  isAllAlertChecked: false,
  isAllAlertLogChecked: false,
  checkedData: [],
  generatedAlertCount: [],
  total_pages: null,
  total_record: null,
  message: null,
  alert_active_tab: 0
}


export function getAllAlerts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_ALERTS:
      return {
        ...state,
        loading: true,
        data: [],
        checkedData: [],
        error: false,
        success: undefined
      }
    case GET_ALL_ALERTS_SUCCESS:
      action.response && action.response.result && Array.isArray(action.response.result) && action.response.result.map((alert, index) => {
        alert['isAlertChecked'] = false;
        if (alert.alert_category && alert.alert_category == "simple") {
          if (alert && alert.config && alert.config.condition == 0) alert.config.expression = "=";
          if (alert && alert.config && alert.config.condition == 1) alert.config.expression = ">";
          if (alert && alert.config && alert.config.condition == 2) alert.config.expression = "<";
        }
      })
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        checkedData: [],
        error: false,
        loading: false,
        success: true
      }
    case GET_ALL_ALERTS_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }

    case GET_ALL_ALERTS_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
        message: null
      }

    case CHECK_ALL_ALERT_SUCCESS:
      let list = state.data;
      if (action.payload.value === "checkAll") {
        list && Array.isArray(list) && list.map(item => {
          item.isAlertChecked = action.payload.checkedFlag;
        });
      }
      return {
        ...state,
        isAllAlertChecked: action.payload.checkedFlag,
        data: list,
        checkedData: []
      }

    case CHECK_ALERT_SUCCESS:
      let data = state.data;

      data && Array.isArray(data) && data.map(item => {
        if (action.payload && action.payload.data && (item.alert_id == action.payload.data.alert_id)) {
          item.isAlertChecked = action.payload.isChecked;
        }
      });
      if (!action.payload.isChecked) {
        const indx = state.checkedData.findIndex(v => v.alert_id === action.payload.data.alert_id);
        state.checkedData.splice(indx, indx >= 0 ? 1 : 0);
      }

      return {
        ...state,
        data,
        checkedData: action.payload.isChecked ? state.checkedData.concat(action.payload.data) : state.checkedData
      }
    case DELETE_ALERT_RESET:
      action.payload && action.payload.length > 0 && action.payload.map((item, index) => {
        for (let i = 0; i < state.data.length; i++) {
          if (state.data[i].alert_id === item) {
            state.data.splice(i, 1);
            state.checkedData.splice(i, 1);
          }
        }
      })

      return {
        ...state,
        data: state.data,
        checkedData: []
      }
    default:
      return state;
  }
}

export function getAlertLogAll(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALERT_LOG_ALL:
      return {
        ...state,
        loading: true,
        data: [],
        checkedData: [],
        error: false,
        success: undefined
      }
    case GET_ALERT_LOG_ALL_SUCCESS:
      action.response && action.response.result && Array.isArray(action.response.result) && action.response.result.map((alert, index) => {
        alert['isAlertLogChecked'] = false;
      })
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        error: false,
        loading: false,
        success: true
      }
    case GET_ALERT_LOG_ALL_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }

    case CHECK_ALL_ALERT_LOG:
      let list = state.data;
      if (action.payload.value === "checkAll") {
        list && Array.isArray(list) && list.map(item => {
          item.isAlertLogChecked = action.payload.checkedFlag;
        });
      }
      return {
        ...state,
        isAllAlertLogChecked: action.payload.checkedFlag,
        data: list,
        checkedData: []
      }

    case CHECK_ALL_ALERT_LOG_SUCCESS:  
    let lists = state.data;
      if (action.payload.value === "checkAll") {
        lists && Array.isArray(lists) && lists.map(item => {
          item.isAlertLogChecked = action.payload.checkedFlag;
        });
      }
      return {
        ...state,
        isAllAlertLogChecked: action.payload.checkedFlag,
        data: lists,
        checkedData: []
      }

    case CHECK_ALERT_LOG_SUCCESS:
      let data = state.data;
      data && Array.isArray(data) && data.map(item => {
        if (action.payload && action.payload.data && (item.id == action.payload.data.id)) {
          item.isAlertLogChecked = action.payload.isChecked;
        }
      });
      if (!action.payload.isChecked) {
        const indx = state.checkedData.findIndex(v => v.id === action.payload.data.id);
        state.checkedData.splice(indx, indx >= 0 ? 1 : 0);
      }

      return {
        ...state,
        data,
        checkedData: action.payload.isChecked ? state.checkedData.concat(action.payload.data) : state.checkedData
      }
    default:
      return state
  }
}

export function getAlertLogById(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALERT_LOG_BY_ID:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }
    case GET_ALERT_LOG_BY_ID_SUCCESS:
      action.response && action.response.result && Array.isArray(action.response.result) && action.response.result.map((alert, index) => {
        alert['isAlertChecked'] = false
      })
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        error: false,
        loading: false,
        success: true
      }
    case GET_ALERT_LOG_BY_ID_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    default:
      return state
  }
}

export function updateAlertLog(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_ALERT_LOG:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }
    case UPDATE_ALERT_LOG_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      }
    case UPDATE_ALERT_LOG_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    case UPDATE_ALERT_LOG_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }

    case UPDATE_ALERT_LOG_STATUS:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }
    case UPDATE_ALERT_LOG_STATUS_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      }
    case UPDATE_ALERT_LOG_STATUS_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    default:
      return state
  }
}

export function getAlertHistoryById(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALERT_HISTORY_BY_ID:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }
    case GET_ALERT_HISTORY_BY_ID_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        error: false,
        loading: false,
        success: true
      }
    case GET_ALERT_HISTORY_BY_ID_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    default:
      return state
  }
}

export function getAlertHistoryLogsById(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALERT_HISTORY_LOGS_BY_ID:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }
    case GET_ALERT_HISTORY_LOGS_BY_ID_SUCCESS:
      return {
        ...state,
        data: action.response.result,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        error: false,
        loading: false,
        success: true
      }
    case GET_ALERT_HISTORY_LOGS_BY_ID_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    default:
      return state
  }
}

export function createAlert(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_ALERT:
      return {
        ...state,
        loading: true,
        data: action.payload,
        error: false,
        success: undefined,
        message: null,
        isAlert: action.payload
      }
    case CREATE_ALERT_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true,
        message: null,
        isAlert: null
      }
    case CREATE_ALERT_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false,
        success: false,
        message: null
      }
    case CREATE_ALERT_RESET:
      return {
        ...state,
        loading: false,
        data: [],
        error: false,
        success: undefined,
        message: null
      }
    case 'ERROR':
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        message: action.payload.response && action.payload.response.message,
      }
    default:
      return state
  }
}

export function updateAlert(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_ALERT:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
        message: null,
        isAlert: action.payload
      }
    case UPDATE_ALERT_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: null,
        isAlert: null
      }
    case UPDATE_ALERT_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        message: null
      }
    case UPDATE_ALERT_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
        message: null
      }
    case 'ERROR':
      return {
        ...state,
        error: true,
        data: [],
        success: false,
        message: action.payload.response && action.payload.response.message
      }
    default:
      return state
  }
}

export function deleteAlert(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_ALERT:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }
    case DELETE_ALERT_SUCCESS:

      return {
        ...state,
        error: false,
        loading: false,
        success: true
      }
    case DELETE_ALERT_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    case DELETE_ALERT_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }
    default:
      return state
  }
}

export function generatedAlertSummary(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_GENERATED_ALERT_SUMMARY:
      return {
        ...state,
        loading: true,
        success: undefined
      }
    case GET_GENERATED_ALERT_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        generatedAlertCount: action.response && action.response.alert_summary
      }
    case GET_GENERATED_ALERT_SUMMARY_FAILURE:
      return {
        ...state,
        error: true,
        success: false
      }
    default:
      return state
  }
}

export function downloadAlertLogs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DOWNLOAD_ALERT_LOGS:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }
    case DOWNLOAD_ALERT_LOGS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true
      }
    case DOWNLOAD_ALERT_LOGS_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    case DOWNLOAD_ALERT_LOGS_RESET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }
    default:
      return state
  }
}


export const alertReducer = {
  getAllAlerts,
  getAlertLogAll,
  getAlertLogById,
  getAlertHistoryById,
  createAlert,
  updateAlert,
  deleteAlert,
  updateAlertLog,
  downloadAlertLogs,
  getAlertHistoryLogsById,
  generatedAlertSummary,
}