import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import valueGet from '../common/valueGet';
import InsightParamSelector from '../../InsightParamSelector';
import { Button } from '../../../../../../../common/components';

const ParameterConfig = (props) => {
  const location = useLocation();

  const asset_type = queryString.parse(location.search).assetType || 'motor';

  const [assetInsightParams, setAssetInsightParams] = useState();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams.assetInsightParams &&
        props.widgetParams.assetInsightParams.value;

      conf && setAssetInsightParams(conf);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <div className='new-input-wrap'>
          <InsightParamSelector
            asset_type={asset_type}
            label='Select Asset Insight Paramameter'
            value={assetInsightParams}
            width={''}
            onChange={(assetInsightParams) => {
              setAssetInsightParams(assetInsightParams);

              props.appInputParamChanged({
                assetInsightParams: valueGet(assetInsightParams)
              });
            }}
            placeholder='Select Asset Params'
          />
        </div>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfig;
