export default (parameter) => {
  return {
    type: 'parameter',
    parameter: parameter.tag.value
      ? {
          // locationLabel: parameter.plant.label,
          // locationid: parameter.plant.value.plantId,
          // readingtypeLabel: parameter.tag.label,
          readingtypeid: parameter.tag.value.parameterId,
          is_calculated: true
        }
      : {}
  };
};
