import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { GET_SYSTEM_INSIGHTS_TREND } from './constant';
import { httpMethodGetJSON } from '../../../../https';
import { URL } from '../../../../constants';

export const getSystemInsightsTrend = (action$) => {
  return action$.pipe(
    ofType(GET_SYSTEM_INSIGHTS_TREND),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/system-cbm/system-names/insight-trend/`
      );
    })
  );
};
