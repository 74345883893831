import queryString from 'query-string';

import {
  GET_WORKPLACE_GRAPH_NORMAL,
  GET_WORKPLACE_GRAPH_NORMAL_SUCCESS,
  GET_WORKPLACE_GRAPH_NORMAL_FAILURE,
  GET_WORKPLACE_GRAPH_COMPUTED,
  GET_WORKPLACE_GRAPH_COMPUTED_SUCCESS,
  GET_WORKPLACE_GRAPH_COMPUTED_FAILURE
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined
};

export const getWorkplaceGraphNormal = (
  state = { ...INITIAL_STATE, data: {} },
  action
) => {
  switch (action.type) {
    case GET_WORKPLACE_GRAPH_NORMAL:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: {
            ...(state.data[action.payload.widgetId] || {})
          }
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_WORKPLACE_GRAPH_NORMAL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.subId]: {
              ...action.response,
              subId: action.param.subId
            }
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_WORKPLACE_GRAPH_NORMAL_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export const getWorkplaceGraphComputed = (
  state = { ...INITIAL_STATE, data: {} },
  action
) => {
  switch (action.type) {
    case GET_WORKPLACE_GRAPH_COMPUTED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: {
            ...(state.data[action.payload.widgetId] || {})
          }
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_WORKPLACE_GRAPH_COMPUTED_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.subId]: {
              ...action.response.response,
              subId: action.param.subId
            }
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_WORKPLACE_GRAPH_COMPUTED_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};
