import {
  DU_SAVE_TEMPLATE,
  DU_SAVE_TEMPLATE_SUCCESS,
  DU_SAVE_TEMPLATE_FAILURE,
  DU_SAVE_TEMPLATE_RESET,
  DU_UPDATE_TEMPLATE,
  DU_UPDATE_TEMPLATE_SUCCESS,
  DU_UPDATE_TEMPLATE_FAILURE,
  DU_GENERATE_TEMPLATE,
  DU_GENERATE_TEMPLATE_SUCCESS,
  DU_GENERATE_TEMPLATE_FAILURE,
  DU_GET_ALL_TEMPLATE,
  DU_GET_ALL_TEMPLATE_SUCCESS,
  DU_GET_ALL_TEMPLATE_FAILURE,
  DU_GET_TEMPLATE_HISTORY,
  DU_GET_TEMPLATE_HISTORY_SUCCESS,
  DU_GET_TEMPLATE_HISTORY_FAILURE,
  CREATE_UPLOAD_GLOBAL_ACTION,
  CREATE_UPLOAD_RESET_GLOBAL_ACTION,
  UPLOAD_DATA_FILE,
  UPLOAD_DATA_FILE_SUCCESS,
  UPLOAD_DATA_FILE_FAILURE,
  UPLOAD_DATA_FILE_RESET,
  DU_GET_ALL_UPLOADED_DATA_FILE,
  DU_GET_ALL_UPLOADED_DATA_FILE_SUCCESS,
  DU_GET_ALL_UPLOADED_DATA_FILE_FAILURE,
  DU_UPDATE_TEMPLATE_RESET,
  CHECK_ALL_TEMPLATES_SUCCESS,
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  isAllTemplatesChecked: false,
  error: false,
  success: undefined,
};

export function getAllTemplate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DU_GET_ALL_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }

    case DU_GET_ALL_TEMPLATE_SUCCESS:
      action.response && action.response.result && Array.isArray(action.response.result) && action.response.result.map((alert, index) => {
        alert['isTemplatesChecked'] = false
      })
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      }

    case DU_GET_ALL_TEMPLATE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    case CHECK_ALL_TEMPLATES_SUCCESS:
      let list = state.data;
      if (action.payload.value === "checkAll") {
        list && Array.isArray(list) && list.map(item => {
          item.isTemplatesChecked = action.payload.checkedFlag;
        });
      }
      return {
        ...state,
        isAllTemplatesChecked: action.payload.checkedFlag,
        data: list,
        checkedData: [],
        loading: false,
      }
    default:
      return state
  }
}

export function getTemplateHistory(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DU_GET_TEMPLATE_HISTORY:
      return {
        ...state,
        loading: true,
        data: {},
        error: false,
        success: undefined
      }

    case DU_GET_TEMPLATE_HISTORY_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      }

    case DU_GET_TEMPLATE_HISTORY_FAILURE:
      return {
        ...state,
        error: true,
        data: {},
        success: false
      }
    default:
      return state
  }
}

export function saveTemplate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DU_SAVE_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }

    case DU_SAVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      }

    case DU_SAVE_TEMPLATE_FAILURE:
      return {
        ...state,
        data: action.payload,
        error: true,
        success: false,
        loading: false,
      }
    case DU_SAVE_TEMPLATE_RESET:
      return {
        ...state,
        data: [],
        error: false,
        success: undefined,
        loading: false,
      }
    default:
      return state
  }
}

export function updateTemplate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DU_UPDATE_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }

    case DU_UPDATE_TEMPLATE_SUCCESS:
      let resp = { data: { ...action.response.response }, status: action.response.status }
      return {
        ...state,
        data: resp,
        error: false,
        loading: false,
        success: true
      }

    case DU_UPDATE_TEMPLATE_FAILURE:
      return {
        ...state,
        data: action.payload,
        error: true,
        success: false,
        loading: false,
      }
    case DU_UPDATE_TEMPLATE_RESET:
      return {
        ...state,
        data: action.payload,
        error: false,
        success: undefined,
        loading: false,
      }
    default:
      return state
  }
}

export function generateTemplate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DU_GENERATE_TEMPLATE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }

    case DU_GENERATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      }

    case DU_GENERATE_TEMPLATE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    default:
      return state
  }
}


export function uploadDataFile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPLOAD_DATA_FILE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined,
      };
    case UPLOAD_DATA_FILE_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true,
      };
    case UPLOAD_DATA_FILE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false,
      };
    case UPLOAD_DATA_FILE_RESET:
      return {
        ...state,
        data: [],
        error: false,
        success: undefined,
        loading: false,
      }
    default:
      return state;
  }
}

export function createUploadGlobalAction(state = { isModalOpen: false }, action) {
  switch (action.type) {
    case CREATE_UPLOAD_GLOBAL_ACTION:
      return {
        isModalOpen: true
      }
    case CREATE_UPLOAD_RESET_GLOBAL_ACTION:
      return {
        isModalOpen: false
      }
    default:
      return state
  }
}

export function getAllUploadedDataFiles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DU_GET_ALL_UPLOADED_DATA_FILE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }

    case DU_GET_ALL_UPLOADED_DATA_FILE_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      }

    case DU_GET_ALL_UPLOADED_DATA_FILE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      }
    default:
      return state
  }
}

export const dataUploadReducers = {
  duAllTemplateResp: getAllTemplate,
  duGenerateTemplateResp: generateTemplate,
  duSaveTemplateResp: saveTemplate,
  duUpdateTemplateResp: updateTemplate,
  createUploadGlobalAction: createUploadGlobalAction,
  uploadDataFile: uploadDataFile,
  duUploadedDataFiles: getAllUploadedDataFiles,
  duTemplateHistoryResp: getTemplateHistory
};