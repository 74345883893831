import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import CommonSearch from "../CommonSearch";

function ClientPlants(props) {
    let { plants, clientPlantsType } = props;
    return (
        <div className={props.isSearchBarOpen ? 'accounts' : 'pt-20 accounts'}>
            <CommonSearch
                setSearchBar={props.setSearchBar}
                title="Select the Plant you want to access"
                isSearchBarOpen={props.isSearchBarOpen}
                // setSearchValue={props.setSearchValue}
                setSearchValue={(value) => {
                    props.setSearchValue(value)
                    props.setActiveSearch('clientPlant')
                }}
                searchValue={props.searchValue}
            />
            <div className='partner-login-radio'>
                {
                    plants.loading == true ? 'Loading...' :
                        plants.data && Array.isArray(plants.data) && plants.data.length > 0 ? plants.data.map((item, index) => {
                            return <label className="partner-login-label" key={index}>
                                {item.name}
                                <input type="radio" name="radio" checked={item.plantid == clientPlantsType.plantid ? true : false} onChange={() => {
                                    props.setClientPlantType({
                                        plantid: item.plantid,
                                        clientid: item.clientid,
                                        type: 'clientPlant'
                                    })
                                    props.setActiveStep('clientPlant');
                                }} />
                                <span className="checkmark"></span>
                            </label>
                        }) : <span>Plant's not found!</span>
                }
            </div>
        </div>
    );
}

export default ClientPlants;