import {
  DU_GENERATE_TEMPLATE,
  DU_GET_ALL_TEMPLATE,
  DU_SAVE_TEMPLATE,
  CREATE_UPLOAD_GLOBAL_ACTION,
  CREATE_UPLOAD_RESET_GLOBAL_ACTION,
  UPLOAD_DATA_FILE,
  UPLOAD_DATA_FILE_RESET,
  DU_GET_ALL_UPLOADED_DATA_FILE,
  DU_UPDATE_TEMPLATE,
  DU_GET_TEMPLATE_HISTORY,
  DU_SAVE_TEMPLATE_RESET,
  DU_UPDATE_TEMPLATE_RESET,
  CHECK_ALL_TEMPLATES,
} from './constant';

export function generateTemplate(value) {
  return {
    type: DU_GENERATE_TEMPLATE,
    payload: value
  }
}

export function getAllTemplate(value) {
  return {
    type: DU_GET_ALL_TEMPLATE,
    payload: value
  }
}

export function getTemplateHistory(value) {
  return {
    type: DU_GET_TEMPLATE_HISTORY,
    payload: value
  }
}

export function saveTemplate(value) {
  return {
    type: DU_SAVE_TEMPLATE,
    payload: value
  }
}

export function updateTemplate(value) {
  return {
    type: DU_UPDATE_TEMPLATE,
    payload: value
  }
}

export function updateTemplateReset(value) {
  return {
    type: DU_UPDATE_TEMPLATE_RESET,
  }
}

export function createUploadGlobalAction(value) {
  return {
    type: CREATE_UPLOAD_GLOBAL_ACTION,
    payload: value
  }
}

export function createUploadResetGlobalAction(value) {
  return {
    type: CREATE_UPLOAD_RESET_GLOBAL_ACTION,
    payload: value
  }
}

export function uploadDataFile(value) {
  return {
    type: UPLOAD_DATA_FILE,
    payload: value
  }
}

export function uploadDataFileReset(value){
  return{
    type:UPLOAD_DATA_FILE_RESET,
    payload:value
  }
}

export function getAllUploadedFiles(value) {
  return {
    type: DU_GET_ALL_UPLOADED_DATA_FILE,
    payload: value
  }
}

export function resetStore() {
  return {
    type: DU_SAVE_TEMPLATE_RESET,
    payload: {}
  }
}

export function checkAllTemplates(checkedFlag, value) {
  return {
      type: CHECK_ALL_TEMPLATES,
      payload: { checkedFlag, value }
  }
}