import React from 'react';
import Col from 'reactstrap/lib/Col';

function Filler(props) {
  return (
    <Col xl={props.width || 12} lg={props.width || 12} md={props.width || 12} sm={props.width || 12} xs={props.width || 12}>
      <div style={{width:"100%", height:"90%"}} className={ "filler " + props.className}
      dangerouslySetInnerHTML={{__html: props.text}}
      >
        
      </div>
    </Col>
    )
}

export default Filler;