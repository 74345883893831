import { ERROR_CANCEL, SUCCESS_CANCEL , SUCCESS_TOAST, ERROR_TOAST} from '../constants'

export function errorCancel () {
  return { type: ERROR_CANCEL}
}

export function successCancel () {
  return {
    type: SUCCESS_CANCEL
  }
}

export function showSuccessToast(value) {
  return {
    type: SUCCESS_TOAST,
    payload: value
  }
}

export function showErrorToast(value) {
  return {
    type: ERROR_TOAST,
    payload: value
  }
}