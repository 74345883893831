import React, { useEffect, Fragment } from 'react';

import usePlantTagCollection from '../common/usePlantTagCollection';
import { Select } from '../../../../../../../common/components';

const TagParameterSelect = (props) => {
  const [
    ,
    ,
    tagCollection,
    tagOptionsCollection,
    onPlantChangeHandle,
    onTagChangeHandle,
    tagOptionsLoaded
  ] = usePlantTagCollection(props.tagParameterConfs);

  useEffect(() => {
    tagOptionsLoaded && props.plant && onPlantChangeHandle(props.plant, 0);
  }, [tagOptionsLoaded, props.plant, onPlantChangeHandle]);

  return (
    <Fragment>
      <div className='d-flex justify-content-between'>
        <label className='label-text'>
          Tag <span className='text-danger'>*</span>
        </label>
      </div>

      <Select
        isMulti={false}
        placeholder='Select Tag'
        options={
          tagOptionsCollection[0] && [
            ...(props.blankVisiblityToggle ? [{ label: ' ', value: {} }] : []),
            ...tagOptionsCollection[0]
          ]
        }
        value={(() => {
          const option = tagCollection[0];

          const value = option && option.value;

          return value && option;
        })()}
        onChange={(tag) => {
          onTagChangeHandle(tag, 0, (tagConf) => {
            props.onChange({
              ...props.tagParameterConfs[0],
              ...tagConf
            });
          });
        }}
        isDisabled={false}
      />
    </Fragment>
  );
};

export default TagParameterSelect;
