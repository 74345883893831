import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { httpMethodGetJSON, httpMethodPut, httpMethodDelete, httpMethodPost, } from '../https';
import { URL } from '../constants';
import * as AppConstants from './constant';


export const getLoginLogoutDetails = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_LOGIN_LOGOUT_DETAILS),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/users/loginactivities/`);
    })
);

export const changeUserPassword = action$ => action$.pipe(
    ofType(AppConstants.CHANGE_PASSWORD),
    mergeMap(action => {
        return httpMethodPut(action, `${URL}/users/change_password/`);
    })
);


export const editUserProfile = (action$) =>
    action$.pipe(
        ofType(AppConstants.EDIT_USER_PROFILE),
        mergeMap((action) => {
            let token = `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}`;
            let { phone, splitName, profileImage, type, countryOptionValue, deliveryOptionValue, dashboardLayoutOptionValue } = action.payload;
            const formData = new FormData();
            formData.append('profile_image', profileImage);
            formData.append('first_name', splitName[0]);
            formData.append('last_name', splitName[1]);
            formData.append('phone', phone);
            formData.append('number_format', countryOptionValue)
            formData.append('notification_mode', deliveryOptionValue)
            formData.append('dashboard_align', dashboardLayoutOptionValue)
            let param = {};
            param.payload = formData;
            param.type = AppConstants.EDIT_USER_PROFILE;
            return httpMethodPost(
                param,
                `${URL}/users/user_profile/`,
                { Authorization: token, }
            );
        }),
    );

// export const editUserProfile = action$ => action$.pipe(
//     ofType(AppConstants.EDIT_USER_PROFILE),
//     mergeMap((action, a) => {
//         return axios({
//             method: "post",
//             url: `${URL}/users/user_profile/`,
//             data: action.payload,
//             headers: {
//                 "Content-Type": "multipart/form-data",
//                 'Authorization': `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`
//             },
//         }).then((response) => {
//             return { type: AppConstants.EDIT_USER_PROFILE_SUCCESS, payload: response.data };
//         }).catch(error => {
//             return of({
//                 type: AppConstants.EDIT_USER_PROFILE_FAILURE,
//                 error: true,
//                 payload: error
//             })
//         })


//         // return axios.post(`${URL}/users/user_profile/`, {
//         //     headers: {
//         //         "Content-Type": "application/json; charset=UTF-8",
//         //         Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
//         //     },
//         //     data: action.payload,
//         // }).then((response) => {
//         //     return { type: AppConstants.EDIT_USER_PROFILE_SUCCESS, payload: response.data };
//         // }).catch(error => {
//         //     return of({
//         //         type: AppConstants.EDIT_USER_PROFILE_FAILURE,
//         //         error: true,
//         //         payload: error
//         //     })
//         // })

//     })
// );
// export const editUserProfile = action$ => action$.pipe(
//     ofType(AppConstants.EDIT_USER_PROFILE),
//     mergeMap(action => {
//         return httpMethodPost(action, `${URL}/users/user_profile/`);
//     })
// );


export const userProfileEpics = {
    getLoginLogoutDetails,
    changeUserPassword,
    editUserProfile,
}