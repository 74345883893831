import { DELETE_ALERT, DELETE_ALERT_RESET, GET_ALL_ALERTS_RESET, GET_ALERT_HISTORY_BY_ID, GET_ALERT_LOG_BY_ID, GET_ALL_ALERTS, UPDATE_ALERT, UPDATE_ALERT_RESET, GET_ALERT_LOG_ALL, UPDATE_ALERT_LOG, UPDATE_ALERT_LOG_RESET, CREATE_ALERT, CREATE_ALERT_RESET, CHECK_ALL_ALERT, CHECK_ALERT, GET_ALERT_HISTORY_LOGS_BY_ID, GET_REPEAT_EVERY, GET_GENERATED_ALERT_SUMMARY, CHECK_ALERT_LOG, UPDATE_ALERT_LOG_STATUS, DOWNLOAD_ALERT_LOGS, MAIN_ALERT_REDIRECTION, MAIN_ALERT_REDIRECTION_RESET, DOWNLOAD_ALERT_LOGS_RESET, CHECK_ALL_ALERT_LOG  } from './constant';

export function getAllAlerts(value) {
  return {
    type: GET_ALL_ALERTS,
    payload: value
  }
}
export function resetAllAlerts() {
  return {
    type: GET_ALL_ALERTS_RESET,
  }
}


export function checkAllAlert(checkedFlag, value) {
  return {
    type: CHECK_ALL_ALERT,
    payload: { checkedFlag, value }
  }
}

export function checkAlert(isChecked, data) {
  return {
    type: CHECK_ALERT,
    payload: { isChecked, data }
  }
}

export function checkAlertLog(isChecked, data) {
  return {
    type: CHECK_ALERT_LOG,
    payload: { isChecked, data }
  }
}

export function checkAllAlertLog(checkedFlag, value) {
  return {
    type: CHECK_ALL_ALERT_LOG,
    payload: { checkedFlag, value }
  }
}

export function getAllAlertLog(value) {
  return {
    type: GET_ALERT_LOG_ALL,
    payload: value
  }
}

export function getAlertLogById(value) {
  return {
    type: GET_ALERT_LOG_BY_ID,
    payload: value
  }
}

export function updateAlertLog(value) {
  return {
    type: UPDATE_ALERT_LOG,
    payload: value
  }
}

export function updateAlertLogStatus(value) {
  return {
    type: UPDATE_ALERT_LOG_STATUS,
    payload: value
  }
}

export function getAllNotificationCount() {
  return {
      type: AppConstants.GET_ALL_NOTIFICATIONS_COUNT,
  }
}

export function resetAlertLogUpdate(value) {
  return {
    type: UPDATE_ALERT_LOG_RESET,
    payload: value
  }
}

export function getAlertHistoryById(value) {
  return {
    type: GET_ALERT_HISTORY_BY_ID,
    payload: value
  }
}

export function getAlertHistoryLogsById(value) {
  return {
    type: GET_ALERT_HISTORY_LOGS_BY_ID,
    payload: value
  }
}


export function createAlert(value) {
  return {
    type: CREATE_ALERT,
    payload: value
  }
}

export function resetCreateAlert(value) {
  return {
    type: CREATE_ALERT_RESET,
    payload: value
  }
}

export function updateAlert(value) {
  return {
    type: UPDATE_ALERT,
    payload: value
  }
}

export function resetAlertUpdate(value) {
  return {
    type: UPDATE_ALERT_RESET,
    payload: value
  }
}

export function deleteAlert(value) {
  return {
    type: DELETE_ALERT,
    payload: value
  }
}

export function resetDeleteAlert(value) {
  return {
    type: DELETE_ALERT_RESET,
    payload: value
  }
}

export function repeatEvery(value) {
  return {
    type: GET_REPEAT_EVERY,
    payload: value
  }
}

export function getGeneratedAlertSummary(data) {
  return {
    type: GET_GENERATED_ALERT_SUMMARY,
    payload: data
  }
}

export function downloadAlertLogs(value) {
  return {
    type: DOWNLOAD_ALERT_LOGS,
    payload: value
  }
}

export function downloadAlertLogsReset() {
  return {
    type: DOWNLOAD_ALERT_LOGS_RESET,
  }
}

