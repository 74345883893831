import {ofType} from "redux-observable";
import {catchError, map, mergeMap} from "rxjs/operators";
import {ajax} from "rxjs/ajax";
import {of} from "rxjs";
import {minBy, maxBy, meanBy, round} from "lodash";
import moment from "moment";
import qs from "query-string";
import {
    AssetUrl,
    ERROR,
    getLocalItem,
    getPercentageChange,
    handleValueUnit
} from "../../constants";
import {
    GET_AVERAGE_DEMAND_DATA,
    GET_AVERAGE_DEMAND_DATA_FAILURE,
    GET_AVERAGE_DEMAND_DATA_SUCCESS,
    GET_DATE_RANGE_DEMAND_DATA,
    GET_DATE_RANGE_DEMAND_DATA_SUCCESS,
    GET_DATE_RANGE_DEMAND_DATA_FAILURE,
    GET_DEMAND_ANALYSIS_CHART,
    GET_DEMAND_ANALYSIS_CHART_SUCCESS,
    GET_DEMAND_ANALYSIS_CHART_FAILURE,
    GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_RESULT,
    GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_RESULT_FAILURE,
    GET_DATE_RANGE_DEMAND_FORECAST_DATA,
    GET_DATE_RANGE_DEMAND_FORECAST_DATA_SUCCESS,
    GET_DATE_RANGE_DEMAND_FORECAST_DATA_FAILURE,
    GET_DATE_RANGE_DEMAND_FORECAST_DIFF_DATA_SUCCESS,
    GET_DATE_RANGE_DEMAND_FORECAST_DIFF_DATA_FAILURE,
} from "./constant";

const getDemandDataSuccess = payload => ({type: GET_AVERAGE_DEMAND_DATA_SUCCESS, payload});
const getDemandDateRangeSuccess = payload => ({type: GET_DATE_RANGE_DEMAND_DATA_SUCCESS, payload});
const getDemandDateRangeDifferenceSuccess = payload => ({type: GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_RESULT, payload});
const getDemandAnalysisChart = payload => ({type: GET_DEMAND_ANALYSIS_CHART_SUCCESS, payload});

const getDemandDateRangeForecastSuccess = payload => ({type: GET_DATE_RANGE_DEMAND_FORECAST_DATA_SUCCESS, payload});
const getDemandDateRangeForecastDiffSuccess = payload => ({
    type: GET_DATE_RANGE_DEMAND_FORECAST_DIFF_DATA_SUCCESS,
    payload
});

const handleReadingDate = (readingValue, unit, readingTypeId = 2008) => {
    if (readingValue !== undefined) {
        return {
            ...handleValueUnit(
                readingValue['value'],
                readingTypeId,
                1000,
                unit
            ),
            eventDate: moment(readingValue['timeStamp'] * 1000)
        }
    }
    return {
        value: 'N/A',
        unit,
        eventDate: ''
    };
};

export const getDemandDataEpic = action$ => {
    return action$.pipe(
        ofType(GET_AVERAGE_DEMAND_DATA),
        mergeMap(action => {
            const {payload} = action;
            const memberInfo = getLocalItem('smartsense.member', {});
            const urlParams = {
                fromdate: payload.from_date,
                todate: payload.to_date,
                readingtypeid: payload.reading_type_id,
                grain: payload.grain,
                memberId: memberInfo.memberid
            };
            if (payload.location !== null) {
                urlParams['locationid'] = payload.location;
            }
            return ajax.getJSON(
                `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
                {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
            ).pipe(
                map(
                    response => {
                        const unit = response.parameters.readingtype.unit;
                        const {timeseries} = response;
                        const result = {
                            average: handleValueUnit(
                                meanBy(timeseries, 'value'),
                                payload.reading_type_id,
                                1000,
                                unit
                            ),
                            minimumValue: handleReadingDate(
                                minBy(timeseries, 'value'),
                                unit
                            ),
                            maximumValue: handleReadingDate(
                                maxBy(timeseries, 'value'),
                                unit
                            ),
                            unit
                        };
                        return getDemandDataSuccess(result);
                    }
                ),
                catchError(
                    error => of(
                        {type: GET_AVERAGE_DEMAND_DATA_FAILURE, payload: []},
                        {type: ERROR, payload: error}
                    )
                )
            );
        })
    );
};

export const getDemandDateRangeEpic = action$ => {
    return action$.pipe(
        ofType(GET_DATE_RANGE_DEMAND_DATA),
        mergeMap(action => {
            const {payload} = action;
            const memberInfo = getLocalItem('smartsense.member', {});
            const urlParams = {
                fromdate: payload.from_date,
                todate: payload.to_date,
                readingtypeid: payload.reading_type_id,
                memberId: memberInfo.memberid,
                data_format: 'value'
            };
            if (payload.location !== null) {
                urlParams['locationid'] = payload.location;
            }
            return ajax.getJSON(
                `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
                {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
            ).pipe(
                map(
                    response => {
                        const actualValue = response.timeseries[payload.reading_type_id];
                        return getDemandDateRangeSuccess({
                            ...handleValueUnit(
                                actualValue,
                                payload.reading_type_id,
                                1000,
                                response.parameters.readingtype.unit
                            ),
                            actualValue,
                            ...payload
                        });
                    }
                ),
                catchError(
                    error => of(
                        {type: GET_DATE_RANGE_DEMAND_DATA_FAILURE, payload: payload},
                        {type: ERROR, payload: error}
                    )
                )
            );
        })
    );
};

export const getDemandDifferenceDateRangeEpic = action$ => {
    return action$.pipe(
        ofType(GET_DATE_RANGE_DEMAND_DATA_SUCCESS),
        mergeMap(action => {
            const {payload} = action;
            const dateFormat = 'YYYY-MM-DD';
            const memberInfo = getLocalItem('smartsense.member', {});
            const dates = [
                moment(payload.from_date, dateFormat)
                    .subtract(1, 'month')
                    .startOf('day')
                    .format(),
                moment(payload.to_date, dateFormat)
                    .subtract(1, 'month')
                    .startOf('day')
                    .format()
            ];
            const urlParams = {
                fromdate: dates[0],
                todate: dates[1],
                readingtypeid: payload.reading_type_id,
                memberId: memberInfo.memberid,
                data_format: 'value'
            };
            if (payload.location !== null) {
                urlParams['locationid'] = payload.location;
            }
            return ajax.getJSON(
                `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
                {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
            ).pipe(
                map(
                    response => getDemandDateRangeDifferenceSuccess({
                        ...payload,
                        ...getPercentageChange(
                            payload.actualValue,
                            response.timeseries[payload.reading_type_id]
                        ),
                    })
                ),
                catchError(
                    error => of(
                        {type: GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_RESULT_FAILURE, payload: payload},
                        {type: ERROR, payload: error}
                    )
                )
            );
        })
    );
};

export const getDemandDateRangeForecastEpic = action$ => action$.pipe(
    ofType(GET_DATE_RANGE_DEMAND_FORECAST_DATA),
    mergeMap(action => {
        const {payload} = action;
        const memberInfo = getLocalItem('smartsense.member', {});
        const {forecast_month, reading_type_id} = payload;
        const urlParams = {
            memberId: memberInfo.memberid,
            readingtypeid: reading_type_id,
            forecast_month
        };
        if (payload.location !== null) {
            urlParams['locationid'] = payload.location;
        }
        return ajax.getJSON(
            `${AssetUrl}/api/insights/enterprise/forecast/?${qs.stringify(urlParams)}`,
            {Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`}
        ).pipe(
            map(
                response => {
                    const actualValue = response.value;
                    return getDemandDateRangeForecastSuccess({
                        ...handleValueUnit(
                            actualValue,
                            reading_type_id,
                            1000,
                            response.readingtype.unit
                        ),
                        actualValue,
                        from_date: forecast_month,
                        ...payload
                    });
                }
            ),
            catchError(
                error => of(
                    {type: GET_DATE_RANGE_DEMAND_FORECAST_DATA_FAILURE, payload: payload},
                    {type: ERROR, payload: error}
                )
            )
        );
    })
);

export const getDateRangeDemandForeCastDataDiffEpic = action$ => action$.pipe(
    ofType(GET_DATE_RANGE_DEMAND_FORECAST_DATA_SUCCESS),
    mergeMap(action => {
        const {payload} = action;
        const memberInfo = getLocalItem('smartsense.member', {});
        const {reading_type_id} = payload;
        const dateFormat = 'YYYY-MM-DD';
        let previousMonth = moment(payload.forecast_month, dateFormat).subtract(1, 'month');
        const urlParams = {
            memberId: memberInfo.memberid,
            readingtypeid: reading_type_id,
            forecast_month: previousMonth.format(dateFormat)
        };
        if (payload.location !== null) {
            urlParams['locationid'] = payload.location;
        }
        return ajax.getJSON(
            `${AssetUrl}/api/insights/enterprise/forecast/?${qs.stringify(urlParams)}`,
            {
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`
            }
        ).pipe(
            map(
                response => getDemandDateRangeForecastDiffSuccess({
                    ...payload,
                    ...getPercentageChange(payload.actualValue, response.value),
                })
            ),
            catchError(
                error => of(
                    {type: GET_DATE_RANGE_DEMAND_FORECAST_DIFF_DATA_FAILURE, payload: payload},
                    {type: ERROR, payload: error}
                )
            )
        );
    })
);

export const getDemandAnalysisChartEpic = action$ => action$.pipe(
    ofType(GET_DEMAND_ANALYSIS_CHART),
    mergeMap(action => {
        const {payload} = action;
        const memberInfo = getLocalItem('smartsense.member', {});
        const urlParams = {
            fromdate: payload.from_date,
            todate: payload.to_date,
            grain: payload.grain,
            readingtypeid: payload.reading_type_id,
            memberId: memberInfo.memberid
        };
        if (payload.location !== null) {
            urlParams['locationid'] = payload.location;
        }
        return ajax.getJSON(
            `${AssetUrl}/api/insights/enterprise/timeseries/?${qs.stringify(urlParams)}`,
            {Authorization: `token: ${getLocalItem('smartsense.authToken')}`}
        ).pipe(
            map(getDemandAnalysisChart),
            catchError(
                error => of(
                    {type: GET_DEMAND_ANALYSIS_CHART_FAILURE, payload: payload},
                    {type: ERROR, payload: error}
                )
            )
        );
    })
);