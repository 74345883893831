import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { httpMethodGetJSON } from '../../../../https';
import { URL } from '../../../../constants';

import * as AppConstants from './constant';

export const getSystemAssetsStatus = (action$) => {
  return action$.pipe(
    ofType(AppConstants.GET_SYSTEM_ASSETS_STATUS),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/system-cbm/system/assets/status/${action.payload.system_id}/`
      );
    })
  );
};
