import React, { useMemo, useCallback, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getServiceByName } from '../../../../../common/utils/services';
import { getAllWidgetConfig } from '../../action';
import { mergeConfig } from '../../widgetsConfig';
import WidgetBuilderVintage from './Vintage';
import WidgetBuilderNew from './New';

const widgetTypeCollection = [
  //'Graph',
  //'Digitbox'
  //'Dial',
  //'Cycle Distribution',
  //'OnOff',
  //'ImageUpload',
  //'Heatmap',
  //'Insight',
  //'Health',
  //'GIS Plant',
  //'Parameter Performance',
  //'Table',
  //'Pie',
  //'Overview',
  //'Parameter Tracking',
  //'Anomaly Detection',
  //'Section',
  //'Analyze',
  //'List View',
  //'Insight-alert-summary',
  //'Report Download',
  //'Asset History',
  //'Alert List',
  //'Smart Info',
  //'Insight Graph',
  //'Group',
  //'Container',
  //'Live Data'
  //'Asset Health Count',
  //'Asset Insight & Alert',
  //'Asset Event Log',
  //'Asset Detail List',
  //'Factories'
];

const WidgetConfig = (_props) => {
  const {
    getAllWidgetConfig,
    resetAddWidgetTemplate,
    resetUpdateWidgetTemplate,
    resetEditWidget,
    resetCreateWidget,
    ...props
  } = _props;

  const locationPathnameFragments = useMemo(() => {
    return props.location.pathname.split(/\?/)[0].split(/\//);
  }, [props.location.pathname]);

  const widgetKey = (() => {
    return locationPathnameFragments[4];
  })();

  const widgetConfig = props.widgetConfig.filter(
    (wid) => wid && wid.id == widgetKey
  )[0];

  const widgetConfigNewFlag =
    widgetConfig &&
    widgetTypeCollection.some((type) => type === widgetConfig.type);

  const getServiceId = useCallback((locationPathnameFragments, location) => {
    return (() => {
      switch (true) {
        case locationPathnameFragments[1] === 'Templates':
        case locationPathnameFragments[1] === 'System':
          return getServiceByName('Asset Insights').id;

        case !!location.state && !!location.state.path:
          return JSON.parse(localStorage.getItem('smartsense.services')).filter(
            ({ name }) => {
              return name === location.state.path;
            }
          )[0].id;

        default:
          return 1;
      }
    })();
  }, []);

  useEffect(() => {
    getAllWidgetConfig({
      service_id: getServiceId(locationPathnameFragments, props.location)
    });
  }, [
    locationPathnameFragments,
    props.location,
    getServiceId,
    getAllWidgetConfig
  ]);

  return (
    <Fragment>
      {widgetConfigNewFlag ? (
        <WidgetBuilderNew widgetConfig={props.widgetConfig} />
      ) : (
        <WidgetBuilderVintage widgetConfig={props.widgetConfig} />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    widgetConfig: mergeConfig(state.allWidgetConfig.data)
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllWidgetConfig
  })(WidgetConfig)
);
