import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes, { func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Input } from '../../components/'
import { getUsers } from "./action";
const ClickOutHandler = require('react-onclickout');


function UserSearch(props) {
  const USERS = JSON.parse(localStorage.getItem('smartsense.users')) || [];
  var [searchValue, setSearchValue] = useState('');
  var [selected, setSelected] = useState([]);
  var [suggVisible, setSuggVisible] = useState(false);
  // var [suggestion, setSuggestion] = useState(USERS ? USERS : []);
  var [suggestion, setSuggestion] = useState([]);
  let [selectedOptions, setSelectedOptions] = useState([]);
  let [searchOptions, setSearchOptions] = useState([]);
  useEffect(() => {
    if (USERS.length === 0 || !localStorage.getItem('smartsense.users')) {
      props.getUsers({})
    }
  }, []);

  useEffect(() => {
    setSearchValue(props.searchValue)
  }, [props.searchValue]);

  useEffect(() => {
    getSuggestions();
    setSearchValue('');
  }, [selected, selectedOptions]);

  useEffect(() => {
    if (Array.isArray(props.selected)) {
      setSelected(props.selected);
    }
  }, [props.selected]);

  useEffect(() => {
    if (suggVisible) {
      let data = []
      props.selected.map((sel, i) => {
        USERS && USERS.map((item) => {
          if (sel === item.memberid) {
            data.push(item)
          }
        })
      })
      if (data && data.length > 0) setSelectedOptions(data);
      getSuggestions();
    }

  }, [props.selected, selected, suggVisible]);

  const getSuggestions = () => {
    if (Array.isArray(USERS)) {
      if (selectedOptions && Array.isArray(selectedOptions) && selectedOptions.length > 0) {
        var ids = new Set(selectedOptions.map(d => d.memberid));
        let options = [...USERS.filter(d => !ids.has(d.memberid))];
        setSuggestion(options);
        setSearchOptions(options);
      } else {
        setSuggestion(USERS);
        setSearchOptions(USERS);
      }
    }
  }

  // const setSugg = (value) => {
  //   const inputValue = value.trim().toLowerCase();
  //   const inputLength = inputValue.length;

  //   let sugg = inputLength === 0 ? USERS : USERS.filter(user =>
  //     user.firstname.toLowerCase().includes(inputValue)
  //   );

  //   setSuggestion(sugg);
  // }

  const setSugg = (value) => {
    const inputValue = value && value.trim() && value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let sugg = inputLength === 0 ? searchOptions : searchOptions.filter(user =>
      (user.firstname + ' ' + user.lastname).toLowerCase().includes(inputValue.toLowerCase())
    );
    setSuggestion(sugg);
  }

  return (
    <ClickOutHandler onClickOut={()=>{
       setSuggVisible(false);
    }}>
    <div className='user-search'>
      <Input
        size={props.size}
        name='user_search'
        value={searchValue}
        label={props.label}
        type='text'
        inputChanged={(value, isInValid) => {
          setSearchValue(value);
          setSugg(value)
        }}
        placeholder={props.label?props.label:"Search User"}
        onFocus={() => {
          setSuggVisible(true)
        }}
        // onBlur={() => {
        //   setTimeout(() => {
        //     setSuggVisible(false)
        //   }, 200)
        // }}
        disabled={props.isDisabled}
        width={props.width ? props.width : null}
      />
      {
        <div className='user-search-suggestion'>
          {
            suggVisible ?
              <div className='user-suggestion-contaner'>
                <ul>
                  {
                    suggestion.length > 0 ?
                      suggestion.map((item, i) => {
                        return <li onClick={(e) => {
                          if (props.isMulti) {
                            setSelected([item.memberid, ...selected]);
                            setSelectedOptions([item, ...selectedOptions]);
                            props.onSuggestionSelection([item.memberid, ...selected])
                          } else {
                            setSelected([item.memberid]);
                            setSelectedOptions([item]);
                            props.onSuggestionSelection([item.memberid])
                          }
                          setSuggVisible(false);
                        }}>{item.firstname + " " + item.lastname}</li>
                      })
                      :
                      <li>No suggestion</li>
                  }
                </ul>
              </div>
              :
              null
          }
        </div>
      }
      {
        <div className='user-search-selection'>
          {
            selected.map((sel, i) => {
              return <div className='user-search-selection-item' key={sel}>
                <span className='badge'>
                  {
                    USERS.map((user) => {
                      if (sel === user.memberid) {
                        return user.firstname + " " + user.lastname;
                      }

                      return null
                    })
                  }
                </span>
                <span style={{ fontSize: "10px", cursor: "pointer" }} onClick={() => {
                  let old = [...selected];
                  let oldOption = [...selectedOptions];
                  old.splice(i, 1);
                  oldOption.splice(i, 1);
                  setSelected(old);
                  setSelectedOptions(oldOption);
                  props.onSuggestionSelection(old);
                }}>
                  <i className="fas fa-remove">&#xf00d;</i>
                </span>
              </div>
            })
          }
        </div>
      }
    </div>
    </ClickOutHandler>
  )
}

const mapStateToProps = (state) => {
  return {
    users: state.userSearch
  };
};

UserSearch.defaultProps = {
  searchValue: "",
  selected: [],
  isMulti: true,
  onSuggestionSelection: function () { },
  label: '',
  size: 'md'
}

UserSearch.propTypes = {
  searchValue: PropTypes.string,
  selected: PropTypes.array,
  isMulti: PropTypes.bool,
  onSuggestionSelection: PropTypes.func,
  label: PropTypes.string,
  size: PropTypes.string,
}

export default withRouter(connect(
  mapStateToProps, { getUsers }
)(UserSearch));