import definition from '../OptionDropDown/definition.json';

const indexCollectionGet = (
  _component,
  _optionCollection,
  indexCollection = []
) => {
  return _optionCollection.reduce(
    (memo, { component, optionCollection }, index) => {
      const value = (() => {
        switch (true) {
          case !!optionCollection:
            return indexCollectionGet(_component, optionCollection, [
              ...indexCollection,
              index
            ]);

          case component === _component:
            return [...indexCollection, index];
        }
      })();

      return !memo && value ? value : memo;
    },
    null
  );
};

export default (component) => {
  const indexCollection = indexCollectionGet(component, definition);

  const { fragmentCollection } = indexCollection.reduce(
    (memo, index) => {
      const option = memo.optionCollection[index];

      return index >= 0
        ? {
            fragmentCollection: [...memo.fragmentCollection, option.text],
            optionCollection: option.optionCollection
          }
        : memo;
    },
    {
      fragmentCollection: [],
      optionCollection: definition
    }
  );

  return fragmentCollection.join('--');
};
