import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import parameterConfInputGet from '../common/parameterConfInputGet';
import parameterConfsInputGet from '../common/parameterConfsInputGet';
import formulaConfsInputGet from '../common/formulaConfsInputGet';
import ParameterConfigMixed from './ParameterConfigMixed';
import collectionGet from '../common/collectionGet';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import formulaConfsOutputGet from '../common/formulaConfsOutputGet';
import {
  CheckBox,
  Input,
  Button
} from '../../../../../../../common/components';
import valueGet from '../common/valueGet';

const parameterConfsGet = (parameters) => {
  const parameterConfs = parameters.reduce((memo, parameter) => {
    const value =
      !parameter.isComputed &&
      (() => {
        const { locParam, rest } = (() => {
          const {
            locationname: locationLabel,
            locationid,
            paramLabel: readingtypeLabel,
            param: readingtypeid,
            ...rest
          } = parameter;

          return {
            locParam: {
              location: {
                label: locationLabel,
                value: locationid
              },
              readingtype: {
                label: readingtypeLabel,
                value: readingtypeid
              }
            },
            rest
          };
        })();

        return {
          locParam,
          ...rest
        };
      })();

    return value ? [...(memo || []), value] : memo;
  }, null);

  return (
    parameterConfs &&
    parameterConfsInputGet(parameterConfs).reduce((memo, parameterConf) => {
      return [
        ...memo,
        {
          ...parameterConf,
          isComputed: false
        }
      ];
    }, [])
  );
};

const formulaConfsGet = (parameters) => {
  const formulaConfs = parameters.reduce((memo, parameter) => {
    const value =
      parameter.isComputed &&
      (() => {
        const { formula, equation, is_partial, rest } = (() => {
          const {
            computedFormula: { formula, equation, is_partial },
            isComputed,
            ...rest
          } = parameter;

          return {
            formula,
            equation,
            is_partial,
            rest
          };
        })();

        return {
          formula,
          equation,
          is_partial,
          ...rest
        };
      })();

    return value ? [...(memo || []), value] : memo;
  }, null);

  return (
    formulaConfs &&
    formulaConfsInputGet(formulaConfs).reduce((memo, formulaConf) => {
      return [
        ...memo,
        {
          ...formulaConf,
          isComputed: true
        }
      ];
    }, [])
  );
};

const _locationBasedParamSelectorGet = (_locationBasedParamSelector) => {
  return [
    ...(parameterConfsGet(_locationBasedParamSelector) || []),
    ...(formulaConfsGet(_locationBasedParamSelector) || [])
  ];
};

const locationBasedParamSelectorGet = (locationBasedParamSelector) => {
  return locationBasedParamSelector.reduce(
    (memo, _locationBasedParamSelector) => {
      return [
        ...memo,
        {
          ..._locationBasedParamSelector,
          parameters: _locationBasedParamSelectorGet(
            _locationBasedParamSelector.parameters
          )
        }
      ];
    },
    []
  );
};

const _parameterConfsOutputGet = (parameterConfs) => {
  return parameterConfs.reduce((memo, parameterConf) => {
    const value =
      !parameterConf.isComputed &&
      (() => {
        const parameter = parameterConfsOutputGet([parameterConf]);

        const {
          locParam: {
            location: { label: locationname, value: locationid },
            readingtype: { label: paramLabel, value: param }
          },
          ...rest
        } = parameter[0];

        return {
          locationname,
          locationid,
          paramLabel,
          param,
          ...rest
        };
      })();

    return value ? [...(memo || []), value] : memo;
  }, null);
};

const _formulaConfsOutputGet = (formulaConfs) => {
  return formulaConfs.reduce((memo, formulaConf) => {
    const value =
      formulaConf.isComputed &&
      (() => {
        const _formula = formulaConfsOutputGet([formulaConf]);
        return (
          _formula &&
          (() => {
            const {
              isComputed,
              alias,
              unit,
              formula,
              equation,
              is_partial,
              ...rest
            } = _formula[0];

            return {
              isComputed,
              alias,
              unit,
              computedFormula: {
                formula: formula[0].formula,
                equation,
                is_partial
              },
              ...rest
            };
          })()
        );
      })();

    return value ? [...(memo || []), value] : memo;
  }, null);
};

const _outputGet = (confs) => {
  return [
    ...(_parameterConfsOutputGet(confs) || []),
    ...(_formulaConfsOutputGet(confs) || [])
  ];
};

const outputGet = (locationBasedParamSelector) => {
  return locationBasedParamSelector.reduce(
    (memo, _locationBasedParamSelector) => {
      return [
        ...memo,
        {
          ..._locationBasedParamSelector,
          parameters: _outputGet(_locationBasedParamSelector.parameters)
        }
      ];
    },
    []
  );
};

const locationBasedParamSelectorInitializedGet = () => {
  return {
    parameters: [parameterConfInputGet()]
  };
};

const ParameterConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [showDetailSummary, setShowDetailSummary] = useState();

  const [locationBasedParamSelector, setLocationBasedParamSelector] =
    useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setShowDetailSummary(conf.showDetailSummary);

      setLocationBasedParamSelector(
        conf.locationBasedParamSelector.value
          ? locationBasedParamSelectorGet(conf.locationBasedParamSelector.value)
          : [locationBasedParamSelectorInitializedGet()]
      );

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <div className='new-input-wrap'>
          <div className='new-custom-checkbox'>
            <CheckBox
              label={'Show detail summary'}
              size='sm'
              checked={showDetailSummary}
              checkboxClicked={(showDetailSummary) => {
                setShowDetailSummary(showDetailSummary);

                props.appInputParamChanged({
                  showDetailSummary: valueGet(showDetailSummary)
                });
              }}
            />
          </div>
        </div>

        {locationBasedParamSelector.map(
          (_locationBasedParamSelector, index) => {
            return (
              <div key={index} className='content-border-wrap pd-y-10 mg-b-15'>
                <p className='head-label-text mb-0'>
                  {`Factory: ${index + 1} - Configuration`}
                </p>

                <hr className='horizontal-divider mg-t-10 mg-b-15' />

                <Row>
                  <Col md={4}>
                    <div className='new-input-wrap'>
                      <label className='label-text'>Location</label>

                      <Input
                        size='sm'
                        label=''
                        type='text'
                        required={false}
                        placeholder='Enter Location'
                        value={_locationBasedParamSelector.location || ''}
                        inputChanged={(location) => {
                          location !== _locationBasedParamSelector.location &&
                            (() => {
                              const __locationBasedParamSelector =
                                collectionGet(
                                  {
                                    ..._locationBasedParamSelector,
                                    location
                                  },
                                  index,
                                  locationBasedParamSelector
                                );

                              setLocationBasedParamSelector(
                                __locationBasedParamSelector
                              );

                              props.appInputParamChanged({
                                locationBasedParamSelector: valueGet(
                                  outputGet(__locationBasedParamSelector)
                                )
                              });
                            })();
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='new-input-wrap'>
                      <label className='label-text'>Latitude</label>

                      <Input
                        size='sm'
                        label=''
                        type='text'
                        required={false}
                        placeholder='Enter Latitude'
                        value={_locationBasedParamSelector.lat || ''}
                        inputChanged={(lat) => {
                          lat !== _locationBasedParamSelector.lat &&
                            (() => {
                              const __locationBasedParamSelector =
                                collectionGet(
                                  {
                                    ..._locationBasedParamSelector,
                                    lat
                                  },
                                  index,
                                  locationBasedParamSelector
                                );

                              setLocationBasedParamSelector(
                                __locationBasedParamSelector
                              );

                              props.appInputParamChanged({
                                locationBasedParamSelector: valueGet(
                                  outputGet(__locationBasedParamSelector)
                                )
                              });
                            })();
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='new-input-wrap'>
                      <label className='label-text'>Longitude</label>

                      <Input
                        size='sm'
                        label=''
                        type='text'
                        required={false}
                        placeholder='Enter Longitude'
                        value={_locationBasedParamSelector.long || ''}
                        inputChanged={(long) => {
                          long !== _locationBasedParamSelector.long &&
                            (() => {
                              const __locationBasedParamSelector =
                                collectionGet(
                                  {
                                    ..._locationBasedParamSelector,
                                    long
                                  },
                                  index,
                                  locationBasedParamSelector
                                );

                              setLocationBasedParamSelector(
                                __locationBasedParamSelector
                              );

                              props.appInputParamChanged({
                                locationBasedParamSelector: valueGet(
                                  outputGet(__locationBasedParamSelector)
                                )
                              });
                            })();
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='new-input-wrap'>
                      <label className='label-text'>Plant Name</label>

                      <Input
                        size='sm'
                        label=''
                        type='text'
                        required={false}
                        placeholder='Enter Plant Name'
                        value={_locationBasedParamSelector.plantName || ''}
                        inputChanged={(plantName) => {
                          plantName !== _locationBasedParamSelector.plantName &&
                            (() => {
                              const __locationBasedParamSelector =
                                collectionGet(
                                  {
                                    ..._locationBasedParamSelector,
                                    plantName
                                  },
                                  index,
                                  locationBasedParamSelector
                                );

                              setLocationBasedParamSelector(
                                __locationBasedParamSelector
                              );

                              props.appInputParamChanged({
                                locationBasedParamSelector: valueGet(
                                  outputGet(__locationBasedParamSelector)
                                )
                              });
                            })();
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='new-input-wrap'>
                      <label className='label-text'>Country Name</label>

                      <Input
                        size='sm'
                        label=''
                        type='text'
                        required={false}
                        placeholder='Enter Country Name'
                        value={_locationBasedParamSelector.countryName || ''}
                        inputChanged={(countryName) => {
                          countryName !==
                            _locationBasedParamSelector.countryName &&
                            (() => {
                              const __locationBasedParamSelector =
                                collectionGet(
                                  {
                                    ..._locationBasedParamSelector,
                                    countryName
                                  },
                                  index,
                                  locationBasedParamSelector
                                );

                              setLocationBasedParamSelector(
                                __locationBasedParamSelector
                              );

                              props.appInputParamChanged({
                                locationBasedParamSelector: valueGet(
                                  outputGet(__locationBasedParamSelector)
                                )
                              });
                            })();
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <hr className='horizontal-divider mg-t-10 mg-b-15' />

                <ParameterConfigMixed
                  confs={_locationBasedParamSelector.parameters}
                  onConfsChange={(parameters) => {
                    const __locationBasedParamSelector = collectionGet(
                      {
                        ..._locationBasedParamSelector,
                        parameters
                      },
                      index,
                      locationBasedParamSelector
                    );

                    setLocationBasedParamSelector(__locationBasedParamSelector);

                    props.appInputParamChanged({
                      locationBasedParamSelector: valueGet(
                        outputGet(__locationBasedParamSelector)
                      )
                    });
                  }}
                />
              </div>
            );
          }
        )}

        <div className='btn-wrap'>
          <button
            className='icon-text-btn mg-t-15'
            onClick={() => {
              const _locationBasedParamSelector = [
                ...locationBasedParamSelector,
                locationBasedParamSelectorInitializedGet()
              ];

              return setLocationBasedParamSelector(_locationBasedParamSelector);
            }}
          >
            <i className='fa fa-plus-circle fa-3x' aria-hidden='true'></i> Add
            Configuration Data-Type
          </button>
        </div>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfig;
