import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { httpMethodGetJSON, httpMethodPatch, httpMethodDelete, httpMethodPost } from '../https';

import { URL } from '../constants';
import {
  GET_ALL_DASHBOARD,
  GET_SINGLE_DASHBOARD,
  GET_ALL_WIDGET,
  EDIT_DASHBOARD,
  DELETE_DASHBOARD,
  ADD_DASHBOARD,
  GET_ALL_WIDGET_CONFIG,
  GET_ALL_WIDGET_CONFIG_EXTENDED,
  GET_WIDGET_CONFIG,
  CREATE_WIDGET,
  EDIT_WIDGET,
  DELETE_WIDGET,
  GET_WIDGET_HISTORY,
  GET_DASHBOARD_CONFIG_HISTORY,
  GET_WIDGET_CONFIG_BY_ID, 
  SORT_DASHBOARD,
  CREATE_CUSTOM_DASHBOARD,
  GET_CUSTOM_DASHBOARD,
	GET_CUSTOM_DASHBOARD_EXTENDED,
  CREATE_DASHBOARD_SEQUENCE,
  GET_DASHBOARD_SEQUENCE,
	GET_DASHBOARD_SEQUENCE_EXTENDED,
  CREATE_HEALTH_DATA,
  UPDATE_HEALTH_DATA,
  DISCOVERY_UPGRADE_EMAIL,
  GET_ASSET_DYNAMIC_VARIABLES
} from './constant';

export const getAllDashboard = (action$) =>
  action$.pipe(
    ofType(GET_ALL_DASHBOARD),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/dashboards/`);
    }),
  );

export const getSingleDashboard = (action$) =>
  action$.pipe(
    ofType(GET_SINGLE_DASHBOARD),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/dashboards/${action.payload.id}/`);
    }),
  );

export const addDashboard = (action$) =>
  action$.pipe(
    ofType(ADD_DASHBOARD),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/dashboards/`);
    }),
  );

export const editDashboard = (action$) =>
  action$.pipe(
    ofType(EDIT_DASHBOARD),
    mergeMap((action) => {
      return httpMethodPatch(action, `${URL}/dashboards/${action.payload.id}/`);
    }),
  );

export const deleteDashboard = (action$) =>
  action$.pipe(
    ofType(DELETE_DASHBOARD),
    mergeMap((action) => {
      return httpMethodDelete(action, `${URL}/dashboards/${action.payload.id}/`);
    }),
  );

export const sortDashboard = (action$) =>
  action$.pipe(
    ofType(SORT_DASHBOARD),
    mergeMap((action) => {
      return httpMethodPatch(action, `${URL}/dashboards/`);
    }),
  );

export const createDashboardSequence = (action$) =>
  action$.pipe(
    ofType(CREATE_DASHBOARD_SEQUENCE),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/service/${action.payload.service_id}/ordering/`);
    }),
  );

export const getDashboardSequence = (action$) =>
  action$.pipe(
    ofType(GET_DASHBOARD_SEQUENCE),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/service/${action.payload.service_id}/ordering/`);
    }),
  );

export const getDashboardSequenceExtended = (action$) =>
  action$.pipe(
    ofType(GET_DASHBOARD_SEQUENCE_EXTENDED),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/service/${action.payload.service_id}/ordering/`
      );
    })
  );

export const getAllWidget = (action$) =>
  action$.pipe(
    ofType(GET_ALL_WIDGET),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/dashboards/${action.payload.id}/widgets/`);
    }),
  );

export const getAllWidgetConfig = (action$) =>
  action$.pipe(
    ofType(GET_ALL_WIDGET_CONFIG),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/widgets/`);
    }),
  );

export const getAllWidgetConfigExtended = (action$) =>
  action$.pipe(
    ofType(GET_ALL_WIDGET_CONFIG_EXTENDED),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/widgets/`);
    })
  );

export const getWidgetConfigById = action$ => action$.pipe(
  ofType(GET_WIDGET_CONFIG_BY_ID),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/dashboards/${action.payload.dashboardId}/widgets/${action.payload.widgetId}/`);
  })
);


export const getWidgetConfig = action$ => action$.pipe(
  ofType(GET_WIDGET_CONFIG),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/widgets/${action.payload.id}`);
  })
);

export const createWidget = (action$) =>
  action$.pipe(
    ofType(CREATE_WIDGET),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/dashboards/${action.payload.id}/widgets/`);
    }),
  );

export const editWidget = (action$) =>
  action$.pipe(
    ofType(EDIT_WIDGET),
    mergeMap((action) => {
      return httpMethodPatch(
        action,
        `${URL}/dashboards/${action.payload.dashboard_id}/widgets/${action.payload.id}/`,
      );
    }),
  );

export const deleteWidget = (action$) =>
  action$.pipe(
    ofType(DELETE_WIDGET),
    mergeMap((action) => {
      return httpMethodDelete(
        action,
        `${URL}/dashboards/${action.payload.dashboardId}/widgets/${action.payload.id}`,
      );
    }),
  );

export const getWidgetHistory = (action$) =>
  action$.pipe(
    ofType(GET_WIDGET_HISTORY),
    mergeMap((action) => {
      let { dashboardId, id } = action.payload.urlIds;
      return httpMethodGetJSON(
        { type: GET_WIDGET_HISTORY, payload: action.payload.data },
        `${URL}/dashboards/${dashboardId}/widgets/${id}/history/`,
      );
    }),
  );

export const getDashboardConfigHistory = (action$) =>
  action$.pipe(
    ofType(GET_DASHBOARD_CONFIG_HISTORY),
    mergeMap((action) => {
      // let { dashboardId, id } = action.payload.urlIds;
      // return httpMethodGetJSON({ type: GET_DASHBOARD_CONFIG_HISTORY, payload: action.payload.data }, `${URL}/dashboards/history/`);
      return httpMethodGetJSON(action, `${URL}/dashboards/history/`);
    }),
  );

  export const getCustomDashboard = action$ => action$.pipe(
    ofType(GET_CUSTOM_DASHBOARD),
    mergeMap(action => {
      return httpMethodGetJSON(action, `${URL}/dashboards/?service_id=${action.payload}&page_size=100&page_no=1`)
    })
);

  export const getCustomDashboardExtended = (action$) =>
    action$.pipe(
      ofType(GET_CUSTOM_DASHBOARD_EXTENDED),
      mergeMap((action) => {
        return httpMethodGetJSON(action, `${URL}/dashboards/`);
      })
    );

export const createCustomDashboard = action$ => action$.pipe(
    ofType(CREATE_CUSTOM_DASHBOARD),
    mergeMap(action => {
        return httpMethodPost(action, `${URL}/dashboards/`);
    })
);

export const createHealthData = (action$) =>
  action$.pipe(
    ofType(CREATE_HEALTH_DATA),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/sensor/health/config/`);
    }),
  );

export const discoveryUpgradeEmail = (action$) =>
  action$.pipe(
    ofType(DISCOVERY_UPGRADE_EMAIL),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/discovery_upgrade_email/`);
    }),
  );

export const editHealthData = (action$) =>
  action$.pipe(
    ofType(UPDATE_HEALTH_DATA),
    mergeMap((action) => {
      return httpMethodPatch(
        action,
        `${URL}/sensor/health/config/${action.payload.healthConfigData}/`
      );
    }),
  );

export const getAssetDynamicVariables = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_DYNAMIC_VARIABLES),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/dynamic_variables/${action.payload.assetId}/`
      );
    })
  );

export const dashboardEpics = {
  getAllDashboard,
  getSingleDashboard,
  getAllWidget,
  addDashboard,
  editDashboard,
  deleteDashboard,
  getAllWidgetConfig,
  getAllWidgetConfigExtended,
  getWidgetConfig,
  createWidget,
  editWidget,
  deleteWidget,
  getWidgetHistory,
  getDashboardConfigHistory,
  getWidgetConfigById,
  sortDashboard,
  createDashboardSequence,
  getDashboardSequence,
	getDashboardSequenceExtended,
  createCustomDashboard,
  getCustomDashboard,
	getCustomDashboardExtended,
  createHealthData,
  editHealthData,
  discoveryUpgradeEmail,
  getAssetDynamicVariables
}