import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import {
  RadioButtons,
  CheckBox,
  Button
} from '../../../../../../../common/components';
import valueGet from '../common/valueGet';

const summaryTypeCollection = ['Insights', 'Alerts'];

const OthersConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [summaryType, setSummaryType] = useState();

  const [countShow, setCountShow] = useState();

  const [graphShow, setGraphShow] = useState();

  const [datePickerShow, setDatePickerShow] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setSummaryType(conf.summaryType.value);

      setCountShow(conf.countShow.value);

      setGraphShow(conf.graphShow.value);

      setDatePickerShow(conf.datePickerShow.value);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <div className='new-input-wrap'>
          <label className='label-text'>Summary Type</label>

          <div className='radio-buttons-wrap'>
            <RadioButtons
              noOfButtons={summaryTypeCollection.length}
              getButtonLableAtIndex={(index) => {
                return summaryTypeCollection[index];
              }}
              buttonClickAtIndex={(index) => {
                const summaryType = summaryTypeCollection[index];

                setSummaryType(summaryType);

                props.appInputParamChanged({
                  summaryType: valueGet(summaryType)
                });
              }}
              active={summaryTypeCollection.findIndex(
                (_summaryType) => _summaryType === summaryType
              )}
            />
          </div>
        </div>

        <Row className='content-border-wrap'>
          <Col md={12}>
            <div className='new-input-wrap'>
              <div className='new-custom-checkbox'>
                <CheckBox
                  label={'Show count'}
                  size='sm'
                  checked={countShow}
                  checkboxClicked={(countShow) => {
                    setCountShow(countShow);

                    props.appInputParamChanged({
                      countShow: valueGet(countShow)
                    });
                  }}
                />
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className='new-input-wrap'>
              <div className='new-custom-checkbox'>
                <CheckBox
                  label={'Show graph'}
                  size='sm'
                  checked={graphShow}
                  checkboxClicked={(graphShow) => {
                    setGraphShow(graphShow);

                    props.appInputParamChanged({
                      graphShow: valueGet(graphShow)
                    });
                  }}
                />
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className='new-input-wrap'>
              <div className='new-custom-checkbox'>
                <CheckBox
                  label={'Show date picker'}
                  size='sm'
                  checked={datePickerShow}
                  checkboxClicked={(datePickerShow) => {
                    setDatePickerShow(datePickerShow);

                    props.appInputParamChanged({
                      datePickerShow: valueGet(datePickerShow)
                    });
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default OthersConfig;
