import queryString from 'query-string';

import {
  GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA,
  GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA_SUCCESS,
  GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA_FAILURE,
  GET_INSIGHT_TIMELINE_ACTIVITY_DATA,
  GET_INSIGHT_TIMELINE_ACTIVITY_DATA_SUCCESS,
  GET_INSIGHT_TIMELINE_ACTIVITY_DATA_FAILURE,
  GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA,
  GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA_SUCCESS,
  GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA_FAILURE,
  GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA,
  GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA_SUCCESS,
  GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA_FAILURE,
  GET_INSIGHT_TIMELINE_INSIGHT_DATA,
  GET_INSIGHT_TIMELINE_INSIGHT_DATA_SUCCESS,
  GET_INSIGHT_TIMELINE_INSIGHT_DATA_FAILURE,
  GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA,
  GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA_SUCCESS,
  GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA_FAILURE,
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  errorMessage: undefined,
  success: undefined
};

const collectionGet = (collection, _index, _collection) => {
  const index = _index - 1;

  return index === _collection.length
    ? [..._collection, collection]
    : _collection;
};

export const getInsightTimelineAssetHistoryData = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA:
      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: {
          ...state.data,
          [action.payload.widgetId]:
            action.payload.page_no > 1
              ? state.data[action.payload.widgetId]
              : { result: null }
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            result: collectionGet(
              action.response.result,
              action.param.page_no,
              state.data[action.param.widgetId].result || []
            )
          }
        },
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export const getInsightTimelineActivityData = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_INSIGHT_TIMELINE_ACTIVITY_DATA:
      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: {
          ...state.data,
          [action.payload.widgetId]:
            action.payload.page_no > 1
              ? state.data[action.payload.widgetId]
              : { result: null }
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_INSIGHT_TIMELINE_ACTIVITY_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            result: collectionGet(
              action.response.result,
              action.param.page_no,
              state.data[action.param.widgetId].result || []
            )
          }
        },
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case GET_INSIGHT_TIMELINE_ACTIVITY_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export function getInsightTimelineCbmAssetData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        success: undefined,
        error: false
      };

    case GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.result,
        success: true,
        error: false,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record
      };

    case GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };
    default:
      return state;
  }
}

export const getInsightTimelineAlertLogData = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA:
      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: {
          ...state.data,
          [action.payload.widgetId]:
            action.payload.page_no > 1
              ? state.data[action.payload.widgetId]
              : { result: null }
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            result: collectionGet(
              action.response.result,
              action.param.page_no,
              state.data[action.param.widgetId].result || []
            )
          }
        },
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export const getInsightTimelineInsightData = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_INSIGHT_TIMELINE_INSIGHT_DATA:
      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: {
          ...state.data,
          [action.payload.widgetId]:
            action.payload.page_no > 1
              ? state.data[action.payload.widgetId]
              : { result: null }
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_INSIGHT_TIMELINE_INSIGHT_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            result: collectionGet(
              action.response.result,
              action.param.page_no,
              state.data[action.param.widgetId].result || []
            )
          }
        },
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case GET_INSIGHT_TIMELINE_INSIGHT_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};


export const getInsightTimelineAssetsMinData = (state = {
  data: [],
  loading: false,
  error: false,
  errorMessage: undefined,
  success: undefined
}, action) => {
  switch (action.type) {
    case GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA:
      return {
        ...state,
        loading: true,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        success: undefined,
        error: false
      };

    case GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.param.widgetId]: [...action.response.result]
        },
        success: true,
        error: false
      };

    case GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };

    default:
      return state;
  }
};