import queryString from 'query-string';

import * as AppConstants from './constant';

export const getNetworkInsightsOccurrence = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_NETWORK_INSIGHTS_OCCURRENCE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case AppConstants.GET_NETWORK_INSIGHTS_OCCURRENCE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            param: action.param
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case AppConstants.GET_NETWORK_INSIGHTS_OCCURRENCE_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: undefined
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    case AppConstants.GET_NETWORK_INSIGHTS_OCCURRENCE_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: false
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    default:
      return state;
  }
};

export const getNetworkInsightsActions = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_NETWORK_INSIGHTS_ACTIONS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case AppConstants.GET_NETWORK_INSIGHTS_ACTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            param: action.param
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case AppConstants.GET_NETWORK_INSIGHTS_ACTIONS_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: undefined
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    case AppConstants.GET_NETWORK_INSIGHTS_ACTIONS_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: false
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    default:
      return state;
  }
};

export const createNetworkInsightsAction = (
  state = {
    data: null,
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case AppConstants.CREATE_NETWORK_INSIGHTS_ACTION:
      return {
        ...state,
        loading: true
      };

    case AppConstants.CREATE_NETWORK_INSIGHTS_ACTION_SUCCESS:
      return {
        ...state,
        data: action.response,
        loading: false,
        success: true
      };

    case AppConstants.CREATE_NETWORK_INSIGHTS_ACTION_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response
      };

    case AppConstants.CREATE_NETWORK_INSIGHTS_ACTION_RESET:
      return {
        ...state,
        loading: false,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
};

export const getSystemInsightsOccurrence = (
  state = {
    data: {},
    loading: {},
    success: {},
    error: {}
  },
  action
) => {
  switch (action.type) {
    case AppConstants.GET_SYSTEM_INSIGHTS_OCCURRENCE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case AppConstants.GET_SYSTEM_INSIGHTS_OCCURRENCE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            param: action.param
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case AppConstants.GET_SYSTEM_INSIGHTS_OCCURRENCE_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: {
              count: 8,
              next: null,
              previous: null,
              results: [
                {
                  id: 1706,
                  created_at: '2024-10-25T03:00:09Z',
                  updated_at: '2024-10-25T03:00:09Z',
                  deleted_at: null,
                  start_date: '2024-10-24T20:01:00Z',
                  end_date: '2024-10-25T02:47:00Z',
                  status: null,
                  meta: {
                    end_date: '2024-10-25T02:47:00Z',
                    start_date: '2024-10-24T20:01:00Z',
                    lower_threshold: 45,
                    upper_threshold: 230,
                    breach_direction: 'upper'
                  },
                  is_open: true,
                  type: null,
                  sme_notes: null,
                  network_id: 10,
                  network_insight_definition_id: 2,
                  sensor_id: 33256
                },
                {
                  id: 1678,
                  created_at: '2024-10-25T00:00:09Z',
                  updated_at: '2024-10-25T00:00:09Z',
                  deleted_at: null,
                  start_date: '2024-10-24T17:03:00Z',
                  end_date: '2024-10-24T23:55:00Z',
                  status: null,
                  meta: {
                    end_date: '2024-10-24T23:55:00Z',
                    start_date: '2024-10-24T17:03:00Z',
                    lower_threshold: 45,
                    upper_threshold: 230,
                    breach_direction: 'upper'
                  },
                  is_open: true,
                  type: null,
                  sme_notes: null,
                  network_id: 10,
                  network_insight_definition_id: 2,
                  sensor_id: 33256
                }
              ]
            }
          },
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    case AppConstants.GET_SYSTEM_INSIGHTS_OCCURRENCE_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: undefined
        },
        loading: {
          ...state.loading,
          [action.payload.widgetId]: false
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    default:
      return state;
  }
};
