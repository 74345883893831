import React, { useRef, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import { Button, ErrorBoundary } from '../../../../../../../common/components';

const PreviewConfig = (props) => {
  const previewCount = useRef(1);

  useEffect(() => {
    previewCount.current = previewCount.current + 1;
  }, [props.widgetParams]);

  const renderPreview = () => {
    //let pos = { i: widget.id, x: 0, y: 0, w: 4, h: 1, minW: 4, maxH: 4, minH: 2, maxW: 12 };

    const widthFactor = 12;

    const heightFactor = 141;

    return (
      <div
        style={{
          width: `${(4 * 100) / widthFactor}%`,
          height: `${(1 * 100) / widthFactor}%`,
          minWidth: `${
            ((props.widgetConfig.minWidgetWidth || 4) * 100) / widthFactor
          }%`,
          maxWidth: `${
            ((props.widgetConfig.maxWidgetWidth || 12) * 100) / widthFactor
          }%`,
          minHeight: `${
            (props.widgetConfig.minWidgetHeight || 2) * heightFactor
          }px`,
          maxHeight: `${
            (props.widgetConfig.maxWidgetHeight || 4) * heightFactor
          }px`
        }}
      >
        <ErrorBoundary>
          <props.widgetConfig.view
            appParams={props.widgetParams}
            preview={{ count: previewCount.current }}
            isPreview={true}
            widgetId={props.widgetKey}
            dashboardId={props.dashboardKey}
            pathname={props.pathname}
          />
        </ErrorBoundary>
      </div>
    );
  };

  return (
    <div className='PreviewConfig config-form-wrapper'>
      <Row className='w-100 h-100'>
        <Col className='w-100 h-100 d-flex justify-content-center align-items-center'>
          {(() => {
            switch (true) {
              case !props.isActiveTab:
                return null;

              case !props.widgetConfig:
                return 'Loading...';

              case !!props.isWidgetConfigurationInValid:
                return `Invalid Configuration - ${props.isWidgetConfigurationInValid.join(
                  ', '
                )}`;

              default:
                return renderPreview();
            }
          })()}
        </Col>
      </Row>

      <div className='button-wrapper'>
        <Button
          buttonType='primary'
          size='md'
          innerContent='Back'
          className='buttonBorder'
          buttonClick={() => {
            props.onTabChangeTrigger(-1);
          }}
        />
        <Button
          buttonType='primary'
          size='md'
          innerContent='Add Widget'
          className='buttonFill'
          loading={props.loading}
          buttonClick={() => {
            props.onAddWidget();
          }}
        />
      </div>
    </div>
  );
};

export default PreviewConfig;
