import React from 'react';

import TableHeaderCell from '../../../../../../../common/components/Table/ColumnCell';
import { Table as ReactTable } from '../../../../../../../common/components';

const formulaConfCollectionTableDefinition = [
  {
    name: 'Source Type',
    key: 'source_type',
    width: 150
  },
  {
    name: 'Sub Source Type',
    key: 'sub_source_type',
    width: 150
  },
  {
    name: 'Category',
    key: 'category',
    width: 150
  },
  {
    name: 'Parameter',
    key: 'parameter'
    // width: 200
  },
  {
    name: 'Formula',
    key: 'formula',
    width: 150
  },
  {
    name: '',
    key: 'action',
    width: 120
  }
];

const ParameterConfigComputedCollection = (props) => {
  const getTableHeaderAtIndex = (index) => {
    return new TableHeaderCell(
      formulaConfCollectionTableDefinition[index].name,
      formulaConfCollectionTableDefinition[index].key,
      index,
      formulaConfCollectionTableDefinition[index].width
    );
  };

  const formulaConfCollectionDataGet = () => {
    return (
      props.formulaConfPointerCollections &&
      props.formulaConfPointerCollections
        .reduce((memo, formulaConfPointerCollection, index) => {
          return [
            ...memo,
            formulaConfPointerCollection.reduce((_memo, pointer) => {
              return [
                ..._memo,
                {
                  ...pointer,
                  formula: (() => {
                    switch (pointer.type) {
                      case 'operator':
                        return pointer;

                      case 'parameter':
                        return (
                          props.formulaConfParameterCollections[index] &&
                          props.formulaConfParameterCollections[index][
                            pointer.value
                          ]
                        );
                    }
                  })()
                }
              ];
            }, [])
          ];
        }, [])
        .reduce((memo, formulaConfPointerCollection, index) => {
          const _data = formulaConfCollectionTableDefinition.reduce(
            (_memo, { key }, _index) => {
              return {
                ..._memo,
                [key]: (() => {
                  switch (_index) {
                    case 0:
                      return formulaConfPointerCollection.reduce(
                        (memo, { type, formula: { parameter } }) => {
                          return !memo && type === 'parameter'
                            ? parameter.locationLabel
                            : memo;
                        },
                        null
                      );

                    case 1:
                      return formulaConfPointerCollection
                        .slice(
                          formulaConfPointerCollection.findIndex(({ type }) => {
                            return type === 'parameter';
                          }) + 1
                        )
                        .reduce((memo, { type, formula: { parameter } }) => {
                          return !memo && type === 'parameter'
                            ? parameter.locationLabel
                            : memo;
                        }, null);

                    case 2:
                      return '';

                    case 3:
                      return formulaConfPointerCollection
                        .reduce((memo, { type, formula: { parameter } }) => {
                          return type === 'parameter'
                            ? [...memo, parameter.readingtypeLabel]
                            : memo;
                        }, [])
                        .reduce((memo, readingtypeLabel) => {
                          return `${memo}${
                            memo ? ', ' : ''
                          }${readingtypeLabel}`;
                        }, '');

                    case 4:
                      return formulaConfPointerCollection.reduce(
                        (memo, pointer) => {
                          const value = (() => {
                            switch (pointer.type) {
                              case 'parameter':
                                return `val_${pointer.value + 1}`;

                              case 'operator':
                                return pointer.value;
                            }
                          })();

                          return `${memo} ${value}`;
                        },
                        ''
                      );

                    case 5:
                      return (
                        <div className='table-action-wrap'>
                          <button className='icon-button'>
                            <i className='fa fa-clone' aria-hidden='true'></i>
                          </button>

                          <button
                            className='icon-button'
                            onClick={() => {
                              props.onFormulaConfActiveIndexChangeTrigger(
                                index
                              );
                            }}
                          >
                            <i
                              className='fa fa-pencil-square-o'
                              aria-hidden='true'
                            ></i>
                          </button>

                          <button
                            className='icon-button'
                            onClick={() => {
                              props.onFormulaConfDeleteTrigger(index);
                            }}
                          >
                            <i className='fa fa-trash' aria-hidden='true'></i>
                          </button>
                        </div>
                      );

                    default:
                      return key;
                  }
                })()
              };
            },
            {}
          );

          return [...memo, _data];
        }, [])
    );
  };

  return (
    <div className='custom-table-3 '>
      <ReactTable
        showPagination={false}
        data={formulaConfCollectionDataGet()}
        noOfColms={formulaConfCollectionTableDefinition.length}
        getHeaderAtIndex={getTableHeaderAtIndex}
      />
    </div>
  );
};

export default ParameterConfigComputedCollection;
