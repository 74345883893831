import React, { useState, useEffect, Fragment } from 'react';

import valueGet from '../common/valueGet';
import TextEditorWidgetConfig from '../../TextEditorWidgetConfig';
import { Button } from '../../../../../../../common/components';

const OthersConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [textEditor, setTextEditor] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        (props.widgetParams.textEditor &&
          props.widgetParams.textEditor.value) ||
        ' ';

      setTextEditor(conf);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const renderFn = () => {
    return (
      textEditor && (
        <div className='config-form-wrapper'>
          <div className='new-input-wrap'>
            <TextEditorWidgetConfig
              label='Create View Using Text Editor'
              placeholder={''}
              value={textEditor || ''}
              onChange={(textEditor) => {
                setTextEditor(textEditor);

                props.appInputParamChanged({
                  textEditor: valueGet(textEditor)
                });
              }}
            />
          </div>

          <div className='button-wrapper'>
            <Button
              buttonType='primary'
              size='md'
              innerContent='Back'
              className='buttonBorder'
              buttonClick={() => {
                props.onTabChangeTrigger(-1);
              }}
            />

            <Button
              buttonType='primary'
              size='md'
              innerContent='Next'
              className='buttonFill'
              buttonClick={() => {
                props.onTabChangeTrigger(1);
              }}
            />
          </div>
        </div>
      )
    );
  };

  return <Fragment>{renderFn()}</Fragment>;
};

export default OthersConfig;
