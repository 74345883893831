/**
 * All reducer for widget
 */
import {
  ALERT_GENERATION_OVERVIEW,
  ALERT_GENERATION_OVERVIEW_FAILURE,
  ALERT_GENERATION_OVERVIEW_SUCCESS
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined,
};

export function getAlertGenOverview(state = INITIAL_STATE, action) {
  switch (action.type) {
      case ALERT_GENERATION_OVERVIEW:
          return {
              ...state,
              loading: true,
              error: false
          };
      case ALERT_GENERATION_OVERVIEW_SUCCESS:
          return {
              ...state,
              data: action.response,
              error: false,
              loading: false
          };
      case ALERT_GENERATION_OVERVIEW_FAILURE:
          return {
              ...state,
              error: true,
              loading: false
          };
      default:
          return state
  }
}