import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Label from 'reactstrap/lib/Label';

import SelectDropDown from '../../../../../../common/components/Select';
import {
  getLocationsAndParameters,
  getGroupedCalculatedParametersList,
  inValidatePreloadCache,
  getReadingtypeNameById,
  getLocationsAndParametersNameById
} from '../../../../utils';
import { getCBMAssetData } from '../utils';

const _getGroupedCalculatedParametersList = (
  plant,
  sensor_id,
  sensorOptionCollection
) => {
  const fn = () => getGroupedCalculatedParametersList(plant, sensor_id);

  switch (true) {
    case !!sensor_id:
      return fn();

    case !!sensorOptionCollection:
      return sensorOptionCollection;

    default:
      return fn();
  }
};

const typesGet = (_value, sensorOptionCollection, sensor_id) => {
  const types =
    _value &&
    (sensorOptionCollection
      ? sensorOptionCollection
          .find(({ value }) => value == _value)
          .reading_types.map(({ name, id }) => ({
            label: name,
            value: id
          }))
      : (
          getLocationsAndParameters(undefined, sensor_id).find(
            ({ value }) => value == _value
          ) || {}
        ).types);

  return types;
};

function LocationAndParameterSelect(props) {
  const _location = useLocation();

  const { sensor_id } = _location.state || {};

  const [initialized, setInitialized] = useState();

  var [filteredReadingTypes, setFilteredReadingTypes] = useState([]);

  var [locParam, setLocParam] = useState({ location: {}, readingtype: {} });

  useEffect(() => {
    if (props.value && !props.isPreview) {
      setLocParam(props.value);
      if (props.value && props.value.location && props.value.location.value) {
        const types = typesGet(
          props.value.location.value,
          props.sensorOptionCollection,
          sensor_id
        );

        setFilteredReadingTypes(types);
      }
    }
  }, [props.value]);

  useEffect(() => {
    if (props.directImport) {
      props.value && setLocParam(props.value);
      if (props.value && props.value.location && props.value.location.value) {
        let a =
          getLocationsAndParameters() &&
          getLocationsAndParameters().find(
            (l) => l.value == props.value.location.value
          );
        if (a && a.types) {
          let types = a.types;
          setFilteredReadingTypes(types);
        }
      }
    }
  }, [props.value]);

  useEffect(() => {
    !initialized &&
      (() => {
        inValidatePreloadCache();

        setInitialized(true);
      })();
  }, [initialized]);

  const { location, readingtype } = locParam;

  if (!initialized) return <Fragment></Fragment>;

  const options = _getGroupedCalculatedParametersList(
    props.plant,
    sensor_id,
    props.sensorOptionCollection
  );

  return (
    <React.Fragment>
      <Col
        className='select-dropdown custom-select-parameter'
        xl={props.width || 6}
        lg={props.width || 6}
        md={props.width || 6}
        sm={props.width || 6}
        xs={props.width || 6}
      >
        <Label for='location' className='label'>
          {Array.isArray(props.label) && props.label.length > 0
            ? props.label[0]
            : ''}
          {props.required === true ? (
            <span className='text-danger'> * </span>
          ) : null}
        </Label>
        <SelectDropDown
          name='location'
          value={{
            label:
              getLocationsAndParametersNameById(location && location.value) ||
              (location && location.label) ||
              '',
            value:
              (location && location.value) ||
              getCBMAssetData(location && location.sensor_type_id) ||
              ''
          }}
          onChange={(e) => {
            const types = typesGet(
              e.value,
              props.sensorOptionCollection,
              sensor_id
            );

            setFilteredReadingTypes(types);

            let lp = { ...locParam, location: e, readingtype: {} };

            setLocParam(lp);

            props.onChange(lp, false);
          }}
          options={options}
          placeholder='Select Location'
        />
      </Col>
      <Col
        className='select-dropdown custom-select-parameter'
        xl={props.width || 6}
        lg={props.width || 6}
        md={props.width || 6}
        sm={props.width || 6}
        xs={props.width || 6}
      >
        <Label for={props.name} className='label'>
          {Array.isArray(props.label) && props.label.length > 1
            ? props.label[1]
            : ''}
          {props.required === true ? (
            <span className='text-danger'> * </span>
          ) : null}
        </Label>
        <SelectDropDown
          name='readingtypeid'
          value={{
            label:
              getReadingtypeNameById(readingtype && readingtype.value) || '',
            value: readingtype && readingtype.value
          }}
          onChange={(e) => {
            let val = JSON.parse(
              JSON.stringify({ ...locParam, readingtype: e })
            );

            setLocParam(val);

            delete val.location.types;

            props.onChange(val, false);
          }}
          options={filteredReadingTypes}
          placeholder='Select Parameter'
          isMulti={props.multipleReadingType ? true : false}
        />
      </Col>
    </React.Fragment>
  );
}

export default LocationAndParameterSelect;