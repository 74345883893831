import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import paramTypeCollectionGet from '../common/paramTypeCollectionGet';
import parameterConfsInputGet from '../common/parameterConfsInputGet';
import formulaConfsInputGet from '../common/formulaConfsInputGet';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import formulaConfsOutputGet from '../common/formulaConfsOutputGet';
import {
  Button,
  Input,
  Select,
  RadioButtons
} from '../../../../../../../common/components';
import _ParameterConfig from './_ParameterConfig';

const paramTypeCollection = paramTypeCollectionGet();

const fontWeightCollection = [
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900'
];

const parameterConfsGet = (param) => {
  const parameterConfs =
    param &&
    param.parameter &&
    (() => {
      const { location, parameter } = param;

      return [{ locParam: { location, readingtype: parameter } }];
    })();

  return parameterConfsInputGet(parameterConfs);
};

const formulaConfsGet = (param) => {
  const formulaConfs =
    param &&
    param.computedFormula &&
    param.computedFormula.formula[0] &&
    param.computedFormula.formula[0].formula &&
    (() => {
      const { formula, equation, is_partial } = param.computedFormula;

      return [
        {
          formula,
          equation,
          is_partial
        }
      ];
    })();

  return formulaConfsInputGet(formulaConfs);
};

const isComputedGet = (paramType) => {
  return paramType === paramTypeCollection[1];
};

const _parameterConfsGet = (parameterConfs) => {
  const _parameterConfs = parameterConfsOutputGet(parameterConfs);

  return (
    _parameterConfs &&
    (() => {
      const { location, readingtype: parameter } = _parameterConfs[0].locParam;

      return { location, parameter };
    })()
  );
};

const _formulaConfsGet = (formulaConfs) => {
  const _formulaConfs = formulaConfsOutputGet(formulaConfs);

  return (
    _formulaConfs &&
    (() => {
      const { formula, equation, is_partial } = _formulaConfs[0];

      return {
        computedFormula: {
          formula,
          equation,
          is_partial
        },
        parameter: ''
      };
    })()
  );
};

const outputGet = (parameterConfs, formulaConfs, paramType) => {
  return paramType === paramTypeCollection[0]
    ? _parameterConfsGet(parameterConfs)
    : _formulaConfsGet(formulaConfs);
};

const paramsGet = (params, parameterConfs, formulaConfs, paramType) => {
  return {
    params: [
      {
        ...params,
        ...outputGet(parameterConfs, formulaConfs, paramType)
      }
    ]
  };
};

const ParameterConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [paramType, setParamType] = useState();

  const [params, setParams] = useState();

  const [parameterConfs, setParameterConfs] = useState();

  const [formulaConfs, setFormulaConfs] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setParamType(
        conf.parameter &&
          conf.parameter.value &&
          conf.parameter.value.isComputed
          ? paramTypeCollection[1]
          : paramTypeCollection[0]
      );

      setParams(
        (conf.parameter &&
          conf.parameter.value &&
          conf.parameter.value.params &&
          conf.parameter.value.params[0] &&
          (() => {
            const { location, parameter, computedFormula, ...params } =
              conf.parameter.value.params[0];

            return params;
          })()) || {
          xPosition: '0',
          yPosition: '0',
          fontSize: '12',
          fontColor: '12',
          fontWeight: '500'
        }
      );

      setParameterConfs(
        parameterConfsGet(
          conf.parameter &&
            conf.parameter.value &&
            conf.parameter.value.params[0]
        )
      );

      setFormulaConfs(
        formulaConfsGet(
          conf.parameter &&
            conf.parameter.value &&
            conf.parameter.value.params[0]
        )
      );

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const paramTypeActiveIndex = paramTypeCollection.findIndex(
    (_paramType) => _paramType === paramType
  );

  const appInputParamChangedHandle = (object) => {
    const [key, _value] = Object.entries(object)[0];

    const value = (() => {
      switch (key) {
        case 'isComputed':
          return {
            ...paramsGet(
              params,
              parameterConfs,
              formulaConfs,
              _value ? paramTypeCollection[1] : paramTypeCollection[0]
            ),
            ...object
          };

        default:
          return {
            isComputed: isComputedGet(paramType),
            ...object
          };
      }
    })();

    return props.appInputParamChanged({
      parameter: {
        value
      }
    });
  };

  const renderParameterConfig = () => {
    return (
      <_ParameterConfig
        paramTypeCollection={paramTypeCollection}
        paramTypeActiveIndex={paramTypeActiveIndex}
        parameterConfs={parameterConfs}
        onParameterConfsChange={(_parameterConfs) => {
          setParameterConfs(_parameterConfs);

          appInputParamChangedHandle(
            paramsGet(params, _parameterConfs, formulaConfs, paramType)
          );
        }}
        formulaConfs={formulaConfs}
        onFormulaConfsChange={(_formulaConfs) => {
          setFormulaConfs(_formulaConfs);

          appInputParamChangedHandle(
            paramsGet(params, parameterConfs, _formulaConfs, paramType)
          );
        }}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={12}>
            <div className='new-input-wrap'>
              <RadioButtons
                noOfButtons={2}
                getButtonLableAtIndex={(index) => {
                  return paramTypeCollection[index];
                }}
                buttonClickAtIndex={(index) => {
                  const _paramType = paramTypeCollection[index];

                  setParamType(_paramType);

                  appInputParamChangedHandle({
                    isComputed: isComputedGet(_paramType)
                  });
                }}
                active={paramTypeActiveIndex}
              />
            </div>
          </Col>
        </Row>

        <div className='content-border-wrap minh100_42'>
          <Row>
            <Col md={3}>
              <div className='new-input-wrap'>
                <label className='label-text'>Alias</label>

                <Input
                  size='sm'
                  label=''
                  type='text'
                  required={false}
                  placeholder='Enter Alias'
                  value={params.alias || ''}
                  inputChanged={(alias) => {
                    const _params = {
                      ...params,
                      alias
                    };

                    setParams(_params);

                    appInputParamChangedHandle(
                      paramsGet(
                        _params,
                        parameterConfs,
                        formulaConfs,
                        paramType
                      )
                    );
                  }}
                />
              </div>
            </Col>

            <Col md={3}>
              <div className='new-input-wrap'>
                <label className='label-text'>Unit</label>

                <Input
                  size='sm'
                  label=''
                  type='text'
                  required={false}
                  placeholder='Enter Unit'
                  value={params.unit || ''}
                  inputChanged={(unit) => {
                    const _params = {
                      ...params,
                      unit
                    };

                    setParams(_params);

                    appInputParamChangedHandle(
                      paramsGet(
                        _params,
                        parameterConfs,
                        formulaConfs,
                        paramType
                      )
                    );
                  }}
                />
              </div>
            </Col>

            <Col md={3}>
              <div className='new-input-wrap'>
                <label className='label-text'>Threshold</label>

                <Input
                  size='sm'
                  label=''
                  type='number'
                  required={false}
                  placeholder='Enter Threshold'
                  value={params.threshold || ''}
                  inputChanged={(threshold) => {
                    const _params = {
                      ...params,
                      threshold
                    };

                    setParams(_params);

                    appInputParamChangedHandle(
                      paramsGet(
                        _params,
                        parameterConfs,
                        formulaConfs,
                        paramType
                      )
                    );
                  }}
                />
              </div>
            </Col>

            <Col md={3}>
              <div className='new-input-wrap'>
                <label className='label-text'>Threshold Colour</label>

                <Input
                  size='sm'
                  label=''
                  type='color'
                  required={false}
                  placeholder='Enter Threshold Colour'
                  value={params.thresholdColor || ''}
                  inputChanged={(thresholdColor) => {
                    const _params = {
                      ...params,
                      thresholdColor
                    };

                    setParams(_params);

                    appInputParamChangedHandle(
                      paramsGet(
                        _params,
                        parameterConfs,
                        formulaConfs,
                        paramType
                      )
                    );
                  }}
                />
              </div>
            </Col>

            <Col md={4}>
              <label className='head-label-text'>Positions</label>

              <div className='new-input-wrap'>
                <label className='label-text'>X Position</label>

                <Input
                  size='sm'
                  label=''
                  type='number'
                  required={false}
                  placeholder='Enter Position'
                  value={params.xPosition || ''}
                  inputChanged={(xPosition) => {
                    const _params = {
                      ...params,
                      xPosition
                    };

                    setParams(_params);

                    appInputParamChangedHandle(
                      paramsGet(
                        _params,
                        parameterConfs,
                        formulaConfs,
                        paramType
                      )
                    );
                  }}
                />
              </div>

              <div className='new-input-wrap'>
                <label className='label-text'>Y Position</label>

                <Input
                  size='sm'
                  label=''
                  type='number'
                  required={false}
                  placeholder='Enter Position'
                  value={params.yPosition || ''}
                  inputChanged={(yPosition) => {
                    const _params = {
                      ...params,
                      yPosition
                    };

                    setParams(_params);

                    appInputParamChangedHandle(
                      paramsGet(
                        _params,
                        parameterConfs,
                        formulaConfs,
                        paramType
                      )
                    );
                  }}
                />
              </div>
            </Col>

            <Col md={8}>
              <label className='head-label-text'>Appearance</label>

              <Row>
                <Col md={6}>
                  <div className='new-input-wrap'>
                    <label className='label-text'>Font Size</label>

                    <Input
                      size='sm'
                      label=''
                      type='text'
                      required={false}
                      placeholder='Enter Font Size'
                      value={params.fontSize || ''}
                      inputChanged={(fontSize) => {
                        const _params = {
                          ...params,
                          fontSize
                        };

                        setParams(_params);

                        appInputParamChangedHandle(
                          paramsGet(
                            _params,
                            parameterConfs,
                            formulaConfs,
                            paramType
                          )
                        );
                      }}
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div className='new-input-wrap'>
                    <label className='label-text'>Font Colour</label>

                    <Input
                      size='sm'
                      label=''
                      type='color'
                      required={false}
                      placeholder='Enter Font Colour'
                      value={params.fontColor || ''}
                      inputChanged={(fontColor) => {
                        const _params = {
                          ...params,
                          fontColor
                        };

                        setParams(_params);

                        appInputParamChangedHandle(
                          paramsGet(
                            _params,
                            parameterConfs,
                            formulaConfs,
                            paramType
                          )
                        );
                      }}
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div className='new-input-wrap'>
                    <label className='label-text'>Font Weight</label>

                    <Select
                      options={fontWeightCollection.reduce(
                        (memo, value) => [
                          ...memo,
                          {
                            label: value,
                            value
                          }
                        ],
                        []
                      )}
                      isMulti={false}
                      placeholder='Select Font Weight'
                      value={(() => {
                        const value = params.fontWeight;

                        return {
                          label: value,
                          value
                        };
                      })()}
                      onChange={({ value }) => {
                        const _params = {
                          ...params,
                          fontWeight: value
                        };

                        setParams(_params);

                        appInputParamChangedHandle(
                          paramsGet(
                            _params,
                            parameterConfs,
                            formulaConfs,
                            paramType
                          )
                        );
                      }}
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div className='new-input-wrap'>
                    <label className='label-text'>Border Colour</label>

                    <Input
                      size='sm'
                      label=''
                      type='color'
                      required={false}
                      placeholder='Enter Border Colour'
                      value={params.borderColor || ''}
                      inputChanged={(borderColor) => {
                        const _params = {
                          ...params,
                          borderColor
                        };

                        setParams(_params);

                        appInputParamChangedHandle(
                          paramsGet(
                            _params,
                            parameterConfs,
                            formulaConfs,
                            paramType
                          )
                        );
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          {renderParameterConfig()}
        </div>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfig;
