import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  Fragment
} from 'react';
import { Row, Col, ModalHeader, ModalBody } from 'reactstrap';

import {
  Table,
  TableHeaderCell,
  ModalBox,
  Loader
} from '../../../../../common/components/';
import moment from 'moment';
import { getUserFNameLName } from '../../../utils';
import CalenderConfig from '../Widgets/CommonControls/calendar';

const page_size = 5;

const _dateRange = [
  moment().subtract(1, 'isoWeek').startOf('days').format(),
  moment().endOf('days').format()
];

const _pageNo = 1;

const TABLEHEADER = [
  {
    name: 'Dashboard Name',
    key: 'name'
  },
  {
    name: 'Dashboard Id',
    key: 'dashboard_id'
  },
  {
    name: 'Changed By',
    key: 'member_id'
  },
  {
    name: 'Change Data & Time',
    key: 'modified_at'
  },
  {
    name: 'Change Description',
    key: 'history_change_reason'
  }
];

const getHeaderAtIndex = (index) => {
  return new TableHeaderCell(
    TABLEHEADER[index].name,
    TABLEHEADER[index].key,
    index,
    TABLEHEADER[index].width
  );
};

const getTableCellAtIndex = (row, colm, value) => {
  const cell = (() => {
    switch (colm) {
      case 0:
        return value ? value : '-';

      case 1:
        return value ? value : '-';

      case 2:
        return value ? getUserFNameLName(value) : '-';

      case 3:
        return value ? moment(value).format('MMM DD, YYYY HH:mm') : '-';

      case 4:
        return value ? (
          <span data-title={value}>
            <span>{value}</span>
          </span>
        ) : (
          '-'
        );

      default:
        return '';
    }
  })();

  return <div className='configured-widget-history-table-cell'>{cell}</div>;
};

const DashboardConfigHistory = (_props) => {
  const { getDashboardConfigHistory, ...props } = _props;

  const dashboardId = props.dashboardId;

  const [dateRange, setDateRange] = useState(_dateRange);

  const [pageNo, setPageNo] = useState(_pageNo);

  const [search, setSearch] = useState(null);

  const { data, loading } = useMemo(() => {
    return props.dashboardConfigHistoryData;
  }, [props.dashboardConfigHistoryData]);

  const loadData = useCallback(
    (object) => {
      getDashboardConfigHistory({
        dashboard_id: dashboardId,
        from_date: encodeURIComponent(dateRange[0]),
        to_date: encodeURIComponent(dateRange[1]),
        page_no: pageNo,
        ...(search && { search }),
        page_size,
        ...object
      });
    },
    [dateRange, dashboardId, pageNo, search, getDashboardConfigHistory]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Fragment>
      <ModalBox
        size='md'
        open={props.showDetails}
        className='smallmodalBox custom-modal audit-trail-modal'
        onClose={props.onClose}
      >
        <ModalHeader>
          <h3>Dashboard Configuration History</h3>

          <button className='closeBtn' onClick={props.onClose}>
            <i className='fa fa-times' aria-hidden='true'></i>
          </button>
        </ModalHeader>

        <ModalBody>
          {loading ? (
            <Loader loading={true} />
          ) : (
            <Fragment>
              <div className='controls'>
                <Row>
                  <Col>
                    <CalenderConfig
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                      datePreset={dateRange.datePreset}
                      handleDateRangeChange={(from_date, to_date) => {
                        setDateRange([
                          moment(from_date).format(),
                          moment(to_date).format()
                        ]);
                      }}
                    />
                  </Col>
                </Row>
              </div>

              <div className='custom-table-3'>
                <Table
                  showPagination={true}
                  pageSize={page_size}
                  pageNo={pageNo}
                  noOfPages={props.dashboardConfigHistoryData.data.total_pages}
                  noOfColms={TABLEHEADER.length}
                  data={data.result}
                  getHeaderAtIndex={getHeaderAtIndex}
                  getTableCellAtIndex={getTableCellAtIndex}
                  getDataForPageIndex={(page_no) => {
                    setPageNo(page_no);
                  }}
                  filterQuery={search}
                  placeholder='Search'
                  filterTable={(search) => {
                    setSearch(search);

                    setPageNo(1);
                  }}
                />
              </div>
            </Fragment>
          )}

          <div className='date'></div>
        </ModalBody>
      </ModalBox>
    </Fragment>
  );
};

export default DashboardConfigHistory;
