import React, { useState, useEffect } from 'react';
import SelectDropDown from '../../../../../common/components/Select';
import { Label } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// const sensorList = JSON.parse(localStorage.getItem('smartsense.sensors')) 
// ? Object.values(JSON.parse(localStorage.getItem('smartsense.sensors'))).map(rt => { return { value: rt.sensorid, label: rt.name, sensortypeid: rt.sensortypeid } }) : [];

function ParameterSelector(props) {
  var [selectedSensor, setSelectedSensor] = useState("");
  const [sensorList, setSensorList] = useState(JSON.parse(localStorage.getItem('smartsense.sensors')) ? Object.values(JSON.parse(localStorage.getItem('smartsense.sensors'))).map(rt => { return { value: rt.sensorid, label: rt.name, sensortypeid: rt.sensortypeid } }) : []);

  useEffect(() => {
    if (props.value) {
      let sensor = sensorList.filter(p => p.value === props.value);
      setSelectedSensor(sensor);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.directImport && props.value) {
      let sensor = sensorList.filter(p => p.value === props.value);
      setSelectedSensor(sensor);
    }
  }, [props.value]);

  useEffect(() => {
    if(props.appParams.sensor && props.appParams.sensor.value){
      let prm = sensorList.filter(p => p.value === props.appParams.sensor.value);
      setSelectedSensor(prm);
    }
  }, [props.appParams]);

  return <Col className="select-dropdown custom-select-parameter" xl={props.width ? props.width : 12} >
    <div>
      <Label for={props.name} className='label'>{props.label}
        {props.required === true ? <span className='text-danger'> * </span> : null}
      </Label>
      <SelectDropDown
        name={props.name}
        value={selectedSensor}
        onChange={(e) => {
          setSelectedSensor(sensorList.find(s => s.value === e.value));
          props.onChange(e.value, false, props.name)}}
        options={sensorList}
        placeholder={props.placeholder}
        isMulti={props.isMulti}
      />
    </div>
  </Col>

}


const mapStateToProps = (state) => {
  return {};
};

ParameterSelector.defaultProps = {
  appParams: {},
  appInputParamChanged: function () {}
}

ParameterSelector.propTypes = {
  appParams: PropTypes.object,
  appInputParamChanged: PropTypes.func
}

export default connect(mapStateToProps, {})(ParameterSelector);