import * as AppConstants from './constant';

const INITIAL_STATE = {
    data: [],
    loading: false,
    error: false,
    success: undefined,
    message: null,
    total_pages: null,
    total_record: null,
    checkedData: [],
    isUserChecked: false,
    isAllUserChecked: false,
}

export function getAllUsers(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_USERS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_USERS_SUCCESS:
            action.response && action.response.result && Array.isArray(action.response.result) && action.response.result.map((alert, index) => {
                alert['isUserChecked'] = false
            })
            return {
                ...state,
                data: action.response.result,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                checkedData: [],
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_USERS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }

        case AppConstants.CHECK_ALL_USER_SUCCESS:
            let list = state.data;
            if (action.payload.value === "checkAll") {
                list && Array.isArray(list) && list.map(item => {
                    item.isUserChecked = action.payload.checkedFlag;
                });
            }
            return {
                ...state,
                isAllUserChecked: action.payload.checkedFlag,
                data: list,
                checkedData: [],
                loading: false,
            }

        case AppConstants.CHECK_USER_SUCCESS:
            let data = state.data;
            data && Array.isArray(data) && data.map(item => {
                if (action.payload && action.payload.data && (item.memberid == action.payload.data.memberid)) {
                    item.isUserChecked = action.payload.isChecked;
                }
            });
            if (!action.payload.isChecked) {
                const indx = state.checkedData.findIndex(v => v.memberid === action.payload.data.memberid);
                state.checkedData.splice(indx, indx >= 0 ? 1 : 0);
            }

            return {
                ...state,
                data,
                checkedData: action.payload.isChecked ? state.checkedData.concat(action.payload.data) : state.checkedData
            }

        default:
            return state;
    }
}

export function getUserDetails(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_USER_DETAILS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                error: false,
                data: action.response,
                loading: false,
                success: true
            }
        case AppConstants.GET_USER_DETAILS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        default:
            return state
    }
}

export function createUser(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.CREATE_USER:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null,
            }
        case AppConstants.CREATE_USER_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true,
                message: null,
            }
        case AppConstants.CREATE_USER_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                message: action.payload.response.message,
                loading: false,
            }
        case AppConstants.CREATE_USER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null,
                loading: false,
            }
        default:
            return state;
    }
}

export function updateUser(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.UPDATE_USER:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null,
                isReport: action.payload
            }
        case AppConstants.UPDATE_USER_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true,
                message: null,
                isReport: null
            }
        case AppConstants.UPDATE_USER_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                message: action.payload && action.payload.response && action.payload.response.message ? action.payload.response.message: '',
                loading: false,
            }
        case AppConstants.UPDATE_USER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined,
                message: null,
                loading: false,
            }
        default:
            return state
    }
}

export function deleteUser(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.DELETE_USER:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.DELETE_USER_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.DELETE_USER_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.DELETE_USER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state
    }
}

export function getAllRoles(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_ROLES:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.GET_ALL_ROLES_SUCCESS:
            return {
                ...state,
                error: false,
                data: action.response.result,
                loading: false,
                success: true
            }
        case AppConstants.GET_ALL_ROLES_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        default:
            return state
    }
}

export function getDashboardsByUserId(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_DASHBOARD_BY_USER_ID:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.GET_DASHBOARD_BY_USER_ID_SUCCESS:
            return {
                ...state,
                error: false,
                [action.param] : action.response,
                data: action.response,
                loading: false,
                success: true
            }
        case AppConstants.GET_DASHBOARD_BY_USER_ID_SUCCESS:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        default:
            return state
    }
}

export function getWidgetsByDashboardAndUserId(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_WIDGETS_BY_DASHBOARD_AND_USER_ID:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.GET_WIDGETS_BY_DASHBOARD_AND_USER_ID_SUCCESS:
            return {
                ...state,
                error: false,
                [action.param] : action.response,
                data: action.response,
                loading: false,
                success: true
            }
        case AppConstants.GET_WIDGETS_BY_DASHBOARD_AND_USER_ID_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        default:
            return state
    }
}


export const userManagementReducer = {
    getAllUsers,
    getUserDetails,
    createUser,
    updateUser,
    deleteUser,
    getAllRoles,
    getDashboardsByUserId,
    getWidgetsByDashboardAndUserId
}