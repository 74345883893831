import React, { Fragment, useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import {  Row, Col ,} from 'reactstrap';
import { Input} from '../../../../../common/components/';
import PropTypes from 'prop-types';
import { defaultParamsConfig }  from "../../widgetsConfig";

function DefaultWidgetParams(props) {

  function getInputFields () {
    let params = [...defaultParamsConfig];

    params.map((param) => {
      param.value = props[param.name];
    });

    return params;
  }

  return (
    <div className="widget-builder-default">
      <h4>
        <span><i className='fas fa-calendar'></i></span>
        <span className='meta-name'>Meta</span>
      </h4>
      <Row>
      {
        getInputFields().map((field, i) => {
          return (
            <Input
              key={i}
              name={field.name}
              value={field.value}
              label ={field.label}
              type={field.type}
              inputChanged={(value, isInValid) => { 
                props.defaultInputChanged(value, isInValid, field.name)
              }}
              placeholder={field.placeholder}
              disabled={false}
              required={field.required}
              width ={field.width}
              step={field.step}
              min= {field.min}
              hint= {field.hint}
            />
          )
        })
      }
      </Row>
    </div>
  );
}


DefaultWidgetParams.defaultProps = {
  name: "",
  description: "",
  refreshTime : "1",
  defaultInputChanged: function () {}
}

DefaultWidgetParams.propTypes = {
  widget_name: PropTypes.string,
  widget_description: PropTypes.string,
  refresh_frequency : PropTypes.string,
  defaultInputChanged: PropTypes.func
}

export default DefaultWidgetParams;