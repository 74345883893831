import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { connect } from 'react-redux';
const ClickOutHandler = require('react-onclickout');
import { turnOffNotification, getNotificationSettings, getAllNotificationCount, updateNotificationStatus } from '../../action';

function customPopOver(props) {
    let { settings, item } = props;
    const [isOpen, clickOutSide] = useState(false);
    const [preferenceSettings, setSettings] = useState([]);

    useEffect(() => {
        clickOutSide(true);
    }, [props.data]);

    useEffect(() => {
        setSettings(settings.data);
    }, [settings, preferenceSettings])

    const turnOff = (preference, enabled) => {
        return <Col sm={12} >
            <div className='actions' onClick={() => {
                props.turnOffNotification({
                    category_id: item.category_id,
                    enabled: !enabled
                });
                props.getNotificationSettings()
                if (preference) {
                    preference.enabled = !enabled;
                }
                props.closePopup(false);
                clickOutSide(false);
            }}>
                <h5>
                    <span className='pop-icon'>
                        <i className={enabled ? 'fas fa-bell-slash' : "fas fa-bell"}></i>
                    </span>
                    <span className="first-message">{enabled ? `TURN OFF` : `TURN ON`}</span>
                </h5>
                <p className='action-message'><span>{enabled ? `Turn off notification for this alert` : 'Turn on notification for this alert'}</span></p>
            </div>
        </Col>
    }

    return <ClickOutHandler onClickOut={() => {
        props.closePopup(false);
        clickOutSide(false)
    }}>
        <div className={`notification-actions ${props.left}`}>
            <Container>
                <Row>
                    {
                        // !item.status && 
                        <Col sm={12}>
                            <div className='actions' onClick={() => {
                                props.onReadStatus();
                                props.closePopup(false);
                                clickOutSide(false);
                                // props.updateNotificationStatus({ status: !item.status, id: item.id });
                                // item.status = !item.status;
                                // props.getAllNotificationCount({});
                                props.turnOffNotification({
                                    category_id: item.category_id,
                                    enabled: true
                                });

                            }}>
                                <h5>
                                    <span className='pop-icon'>
                                        <i className="far fa-envelope-open"></i>
                                    </span>
                                    <span className="first-message">{!item.status ? `MARK AS READ` : 'MARK AS UNREAD'}</span>
                                </h5>
                                <p className='action-message'><span>{!item.status ? 'Mark this notification as read' : 'Mark this notification as un-read'}</span></p>
                            </div>
                        </Col>
                    }
                    {
                        preferenceSettings && preferenceSettings.length > 0 ? preferenceSettings.map(preference => {
                            if (preference.category_id == item.category_id && preference.enabled == true) {
                                return turnOff(preference, true);
                            } else if (preference.category_id == item.category_id && preference.enabled == false) {
                                // return null;
                                return turnOff(false);
                            }
                        }) : settings && preferenceSettings && preferenceSettings.length == 0 && turnOff(true)

                    }

                    {/* <Col sm={12}>
                        <div className='actions'>
                            <h5>
                                <span className='icon'>
                                    <i className="fas fa-trash-alt"></i>
                                </span>
                                <span className="first-message">DELETE</span>
                            </h5>
                            <p className='action-message'><span>Delete this notification</span></p>
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </div>
    </ClickOutHandler>
}

const mapStateToProps = state => {
    return {
        settings: state.getNotificationSettings
    };
};

export default connect(
    mapStateToProps, {
    turnOffNotification,
    // getAllNotificationCount,
    // updateNotificationStatus,
    getNotificationSettings
}
)(customPopOver);