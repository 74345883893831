export const GET_SHIFT_ANALYSIS_DATA = 'GET_SHIFT_ANALYSIS_DATA';
export const GET_SHIFT_ANALYSIS_DATA_SUCCESS = 'GET_SHIFT_ANALYSIS_DATA_SUCCESS';
export const GET_SHIFT_ANALYSIS_DATA_FAILURE = 'GET_SHIFT_ANALYSIS_DATA_FAILURE';

export const GET_SHIFT_ANALYSIS_DATA_DIFF_INIT = 'GET_SHIFT_ANALYSIS_DATA_DIFF_INIT';
export const GET_SHIFT_ANALYSIS_DATA_DIFF_SUCCESS = 'GET_SHIFT_ANALYSIS_DATA_DIFF_SUCCESS';
export const GET_SHIFT_ANALYSIS_DATA_DIFF_FAILURE = 'GET_SHIFT_ANALYSIS_DATA_DIFF_FAILURE';

export const GET_SHIFT_ANALYSIS_SERIES = 'GET_SHIFT_ANALYSIS_SERIES';
export const GET_SHIFT_ANALYSIS_SERIES_SUCCESS = 'GET_SHIFT_ANALYSIS_SERIES_SUCCESS';
export const GET_SHIFT_ANALYSIS_SERIES_FAILURE = 'GET_SHIFT_ANALYSIS_SERIES_FAILURE';

export const GET_SHIFT_ANALYSIS_DATE_RANGE_DATA = 'GET_SHIFT_ANALYSIS_DATE_RANGE_DATA';
export const GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_SUCCESS = 'GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_SUCCESS';
export const GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_FAILURE = 'GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_FAILURE';

export const GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_INIT = 'GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_INIT';
export const GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_SUCCESS = 'GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_SUCCESS';
export const GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_FAILURE = 'GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_FAILURE';
