import React, { useState, useEffect } from 'react';
import { Input } from '..'
const ClickOutHandler = require('react-onclickout');


function SelectSearch(props) {
    const OPTIONS = props.options || [];
    // const [OPTIONS, setOptions] = useState([])
    var [searchValue, setSearchValue] = useState('');
    var [selected, setSelected] = useState([]);
    var [suggVisible, setSuggVisible] = useState(false);
    var [suggestion, setSuggestion] = useState([]);
    let [selectedOptions, setSelectedOptions] = useState([]);
    let [searchOptions, setSearchOptions] = useState([]);

    useEffect(() => {
        if (Array.isArray(props.selected)) {
            setSelected(props.selected);
        }
    }, [props.selected]);

    // useEffect(() => {
    //     if (props.options && props.options.length > 0) setOptions(props.options)
    // }, [props.options])

    useEffect(() => {
        getSuggestions();
        setSearchValue('');
    }, [selectedOptions, props.options]);


    const setSugg = (value) => {
        const inputValue = value && value.trim() && value.trim().toLowerCase();
        const inputLength = inputValue.length;
        // let sugg = inputLength === 0 ? OPTIONS : OPTIONS.filter(item =>
        //     item.label.toLowerCase().includes(inputValue)
        // );
        let sugg = inputLength === 0 ? searchOptions : searchOptions.filter(item =>
            item.label.toLowerCase().includes(inputValue)
        );
        setSuggestion(sugg);
    }

    const getSuggestions = () => {
        if (Array.isArray(props.options)) {
            if (selectedOptions && Array.isArray(selectedOptions) && selectedOptions.length > 0 && selected.length > 0) {
                const ids = new Set(selectedOptions.map(d => d.value));
                let options = [...props.options.filter(d => !ids.has(d.value))]
                setSuggestion(options);
                setSearchOptions(options);
            } else {
                setSuggestion(props.options);
                setSearchOptions(props.options);
            }
        }
    }

    return (
        <ClickOutHandler onClickOut={()=>{
            setSuggVisible(false);
         }}>
        <div className='user-search'>
            <Input
                size={props.size}
                name='user_search'
                value={searchValue}
                label={props.label}
                type='text'
                // onBlur={() => {
                //   setTimeout(() => {
                //     setSuggVisible(false)
                //   }, 200)
                // }}
                inputChanged={(value, isInValid) => {
                    setSearchValue(value);
                    setSugg(value);
                }}
                placeholder={props.placeholder}
                onFocus={() => {
                    setSuggVisible(true);
                }}
                disabled={props.isDisabled}
                width={props.width ? props.width : ''}
            />
            {
                <div className='user-search-suggestion'>
                    {
                        suggVisible ?
                            <div className='user-suggestion-contaner'>
                                <ul>
                                    {
                                        suggestion.length > 0 ?
                                            suggestion.map((item, i) => {
                                                return <li onClick={(e) => {
                                                    if (props.isMulti) {
                                                        setSelected([item.value, ...selected]);
                                                        setSelectedOptions([item, ...selectedOptions]);
                                                        props.onSuggestionSelection([item.value, ...selected]);
                                                    } else {
                                                        setSelected([item.value]);
                                                        setSelectedOptions([item]);
                                                        props.onSuggestionSelection([item.value]);
                                                    }
                                                    setSuggVisible(false);
                                                }}>{item.label}</li>
                                            })
                                            :
                                            <li>No suggestion</li>
                                    }
                                </ul>
                            </div>
                            :
                            null
                    }
                </div>
            }
            {
                <div className='user-search-selection'>
                    {
                        selected.map((sel, i) => {
                            if (OPTIONS.some(opt => opt.value === sel)) {
                                return <div className='user-search-selection-item'>
                                    <span className='badge'>
                                        {
                                            OPTIONS.map((user) => {
                                                if (sel === user.value) {
                                                    return user.label;
                                                }

                                                return null
                                            })
                                        }
                                    </span>
                                    <span style={{ fontSize: "14px", cursor: "pointer", objectFit: 'contain', color: '#113ff4', margin: '2px 0 1.5px 38.4px' }} onClick={() => {
                                        let old = [...selected]
                                        old.splice(i, 1);
                                        setSelected(old);
                                        props.onSuggestionSelection(old);
                                        let oldOption = [...selectedOptions];
                                        oldOption.splice(i, 1);
                                        setSelectedOptions(oldOption);
                                    }}>
                                        <i className="fas fa-remove">&#xf00d;</i>
                                    </span>
                                </div>
                            }
                        })
                    }
                </div>
            }
        </div>
        </ClickOutHandler>
    )
}


export default SelectSearch;