export const GET_ALERT_CONNECTION = 'GET_ALERT_CONNECTION';
export const GET_ALERT_CONNECTION_FAILURE = 'GET_ALERT_CONNECTION_FAILURE';
export const GET_ALERT_CONNECTION_SUCCESS = 'GET_ALERT_CONNECTION_SUCCESS';

export const GET_INSIGHT_CONNECTION = 'GET_INSIGHT_CONNECTION';
export const GET_INSIGHT_CONNECTION_FAILURE = 'GET_INSIGHT_CONNECTION_FAILURE';
export const GET_INSIGHT_CONNECTION_SUCCESS = 'GET_INSIGHT_CONNECTION_SUCCESS';

export const GET_ACTIVITY_CONNECTION = 'GET_ACTIVITY_CONNECTION';
export const GET_ACTIVITY_CONNECTION_FAILURE =
  'GET_ACTIVITY_CONNECTION_FAILURE';
export const GET_ACTIVITY_CONNECTION_SUCCESS =
  'GET_ACTIVITY_CONNECTION_SUCCESS';

export const GET_EVENT_CONFIGURATION = 'GET_EVENT_CONFIGURATION';
export const GET_EVENT_CONFIGURATION_FAILURE =
  'GET_EVENT_CONFIGURATION_FAILURE';
export const GET_EVENT_CONFIGURATION_SUCCESS =
  'GET_EVENT_CONFIGURATION_SUCCESS';

export const ADD_USER_ACTIVITY = 'ADD_USER_ACTIVITY';
export const ADD_USER_ACTIVITY_FAILURE = 'ADD_USER_ACTIVITY_FAILURE';
export const ADD_USER_ACTIVITY_SUCCESS = 'ADD_USER_ACTIVITY_SUCCESS';
export const ADD_USER_ACTIVITY_RESET = 'ADD_USER_ACTIVITY_RESET';

export const GET_ASSET_HISTORY_TIMELINE_DATA =
  'GET_ASSET_HISTORY_TIMELINE_DATA';
export const GET_ASSET_HISTORY_TIMELINE_DATA_SUCCESS =
  'GET_ASSET_HISTORY_TIMELINE_DATA_SUCCESS';
export const GET_ASSET_HISTORY_TIMELINE_DATA_FAILURE =
  'GET_ASSET_HISTORY_TIMELINE_DATA_FAILURE';


export const GET_NETWORK_HISTORY_TIMELINE_DATA =
  'GET_NETWORK_HISTORY_TIMELINE_DATA';
export const GET_NETWORK_HISTORY_TIMELINE_DATA_SUCCESS =
  'GET_NETWORK_HISTORY_TIMELINE_DATA_SUCCESS';
export const GET_NETWORK_HISTORY_TIMELINE_DATA_FAILURE =
  'GET_NETWORK_HISTORY_TIMELINE_DATA_FAILURE'; 

export const GET_SYSTEM_HISTORY_TIMELINE_DATA =
  'GET_SYSTEM_HISTORY_TIMELINE_DATA';
export const GET_SYSTEM_HISTORY_TIMELINE_DATA_SUCCESS =
  'GET_SYSTEM_HISTORY_TIMELINE_DATA_SUCCESS';
export const GET_SYSTEM_HISTORY_TIMELINE_DATA_FAILURE =
  'GET_SYSTEM_HISTORY_TIMELINE_DATA_FAILURE';


export const GET_ASSET_HISTORY_TIMELINE_LOG =
  'GET_ASSET_HISTORY_TIMELINE_LOG';
export const GET_ASSET_HISTORY_TIMELINE_LOG_SUCCESS =
  'GET_ASSET_HISTORY_TIMELINE_LOG_SUCCESS';
export const GET_ASSET_HISTORY_TIMELINE_LOG_FAILURE =
  'GET_ASSET_HISTORY_TIMELINE_LOG_FAILURE';

export const GET_SYSTEM_HISTORY_TIMELINE_LOG =
  'GET_SYSTEM_HISTORY_TIMELINE_LOG';
export const GET_SYSTEM_HISTORY_TIMELINE_LOG_SUCCESS =
  'GET_SYSTEM_HISTORY_TIMELINE_LOG_SUCCESS';
export const GET_SYSTEM_HISTORY_TIMELINE_LOG_FAILURE =
  'GET_SYSTEM_HISTORY_TIMELINE_LOG_FAILURE';

export const GET_ASSET_SENSOR =
  'GET_ASSET_SENSOR';
export const GET_ASSET_SENSOR_SUCCESS =
  'GET_ASSET_SENSOR_SUCCESS';
export const GET_ASSET_SENSOR_FAILURE =
  'GET_ASSET_SENSOR_FAILURE';

export const EXPORT_TIMELINE_LOG =
  'EXPORT_TIMELINE_LOG';
export const EXPORT_TIMELINE_LOG_SUCCESS =
  'EXPORT_TIMELINE_LOG_SUCCESS';
export const EXPORT_TIMELINE_LOG_FAILURE =
  'EXPORT_TIMELINE_LOG_FAILURE';