import React, { Fragment, useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import CustomPopOver from '../../CustomPopOver';
import NotifyActions from '../../NotificationActions';
import { NOTIFICATION_TYPES, NOTIFICATION_CATEGORIES } from '../../../constant';
import { capitalizeFirstLetter } from '../../../../utils';
import { PopOver, Badge } from '../../../../../../common/components';
import { turnOffNotification, getNotificationSettings, } from '../../../action';
import { Context, UserContext } from '../../../../Main/context';
import { NOT_ACCESS_MESSAGE } from '../../../../Main/appSetting';
import { NOTIFICATION } from '../../../../Main/permissionContants';

import AlertIcon from '../../../../../../../assets/images/sidebar/alert.svg';

function commonNotification(props) {
    let { priorityLevel, hyperLinkMsg, icon, item, redirectLink, selectedId, isOpenAction } = props.data;
    const context = useContext(Context);
    const userContext = useContext(UserContext);
    const [preferenceSettings, setSettings] = useState([]);

    useEffect(() => {
        setSettings(props.settings.data);
    }, [props.settings]);

    // useEffect(() => {
    // props.getNotificationSettings();
    // }, []);

    const turnOff = (preference, enabled) => {
        if (preference == '') {
            return props.categories && props.categories.map(catItem => {
                if (catItem.id === item.category_id) {
                    return <span className='turn-off-notification' title={catItem.enabled ? `Turn off notification for this alert` : 'Turn on notification for this alert'}
                        onClick={() => {
                            if (userContext.permission(NOTIFICATION.EDIT_USER_NOTIFICATION_PREFERENCE_BY_ID)) {
                                props.turnOffNotification({
                                    category_id: item.category_id,
                                    enabled: !catItem.enabled
                                });
                            } else context.notify.error(NOT_ACCESS_MESSAGE)
                            // props.setActionOpen(false);
                        }}
                    > <i className={catItem.enabled ? 'fas fa-bell-slash' : "fas fa-bell"}></i></span>
                }
            })
        } else {
            return <span className='turn-off-notification' title={enabled ? `Turn off notification for this alert` : 'Turn on notification for this alert'}
                onClick={() => {
                    if (userContext.permission(NOTIFICATION.EDIT_USER_NOTIFICATION_PREFERENCE_BY_ID)) {
                        props.turnOffNotification({
                            category_id: item.category_id,
                            // enabled: !item.status
                            enabled: !enabled
                        });
                    } else context.notify.error(NOT_ACCESS_MESSAGE)
                    props.getNotificationSettings();
                    if (preference) {
                        preference.enabled = !enabled;
                    }
                    props.setActionOpen(false);
                }}
            > <i className={item.status ? 'fas fa-bell-slash' : "fas fa-bell"}></i></span>
        }

    }

    const checkPreferenceCategory = () => {
        return Array.isArray(preferenceSettings) && preferenceSettings.some(function (preference) {
            return (preference.category_id !== item.category_id)
        })
    }

    return <Fragment>
        <div className='notification-item-wrap'>
            {/* <h5 className="time-stamp">{moment().from(moment(item.created_at))}</h5> */}
            <div className='notification-item'>
                <div className='notification-item-left-container'>
                    <span className={item.category_id == NOTIFICATION_CATEGORIES.AlERT_DELETED ? 'icon' : !item.status ? 'icon' : 'icon'}>
                        {/* <i className={icon}></i> */}
                        <img src={AlertIcon} alt='' />
                    </span>
                </div>
                <div className='notification-item-right-container'>
                    <span className={item.category_id == NOTIFICATION_CATEGORIES.AlERT_DELETED ? 'notification-msg' : 'notification-msg cursor'} onClick={() => {
                        props.history.push({
                            pathname: redirectLink,
                        });
                        props.isNotificationPopUp();
                    }}>
                        {/* <span className={priorityLevel == 'high' ? 'priority-high' : priorityLevel == 'medium' ? 'priority-medium' : priorityLevel == 'low' ? 'priority-low' : null}>{capitalizeFirstLetter(priorityLevel)}</span> */}
                        <div className='notification-badge'>
                            <Badge
                                type={priorityLevel == 'high' ? 'superImportant' : priorityLevel == 'medium' ? 'important' : priorityLevel == 'normal'}
                                importance={1}
                                isPillType={true}
                                innerContent={capitalizeFirstLetter(priorityLevel)}
                            />
                        </div>
                        <div className='notificaton-text'>{hyperLinkMsg}</div>
                    </span>
                    <span className="dropdown notification-item-options" onClick={() => {
                        props.setSelectedID();
                        props.setActionOpen(true);
                    }}>
                        {item.id === selectedId && isOpenAction ? <PopOver
                            position={'left center'}
                            icon={<i className='fas fa-ellipsis-h'></i>}
                            open={isOpenAction}
                        >
                            <span className='mark-as-read' title={!item.status ? 'Mark this notification as read' : 'Mark this notification as un-read'}
                                onClick={() => {
                                    if (userContext.permission(NOTIFICATION.EDIT_USER_NOTIFICATION_PREFERENCE_BY_ID)) {
                                        props.onReadStatus();
                                        props.setActionOpen(false);
                                        props.turnOffNotification({
                                            category_id: item.category_id,
                                            enabled: true
                                        });
                                    } else context.notify.error(NOT_ACCESS_MESSAGE)
                                }}
                            > <i className={!item.status ? 'far fa-envelope-open' : 'fas fa-envelope'}></i></span>
                            {checkPreferenceCategory() === true && turnOff('', true)}
                            {
                                // props.categories && props.categories.map(catItem => {
                                preferenceSettings && preferenceSettings.length > 0 && preferenceSettings.map(preference => {
                                    if (preference.category_id == item.category_id && preference.enabled == true && checkPreferenceCategory() === false) {
                                        return turnOff(preference, true);
                                    } else if (preference.category_id == item.category_id && preference.enabled == false && checkPreferenceCategory() === false) {
                                        return turnOff(preference, false);
                                    }
                                    // else return turnOff(preference, true);
                                })
                                // })

                                // : turnOff(null, true)

                                // }) : props.settings && preferenceSettings && preferenceSettings.length == 0 && turnOff(null, true)
                            }
                            {/* {
                                let chekc = preferenceSettings.some(function (preference) {
                                    return (preference.category_id !== item.category_id)
                                    // if (preference.type == item.category_id) {
                                //     return turnOff(preference, true);
                                // } else if (preference.category_id !== item.category_id) {
                                //     return turnOff(preference, true);
                                // }
                            })
                            } */}

                        </PopOver> : <i className='fas fa-ellipsis-h'></i>}
                    </span>
                    {
                        item.type == NOTIFICATION_TYPES.ALERTS && !item.status && item.category_id == NOTIFICATION_CATEGORIES.AlERT_GENERATED ? <NotifyActions
                            data={item}
                            notificationStatus={() => props.notificationStatus()}
                            setNotificationId={props.setNotificationId}
                            notificationId={props.notificationId}
                        /> : null
                    }
                </div>

                {/* {item.id === selectedId && isOpenAction && <CustomPopOver
                    left={'mt-99rem'}
                    closePopup={props.setActionOpen}
                    onReadStatus={props.onReadStatus}
                    item={item}
                />} */}

            </div>
        </div>
    </Fragment>
}

const mapStateToProps = state => {
    return {
        settings: state.getNotificationSettings
    };
};

export default connect(
    mapStateToProps, {
    turnOffNotification,
    getNotificationSettings
}
)(commonNotification);
