import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import {
  GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD,
  GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE
} from './constant';
import { httpMethodGetJSON } from '../../../admin/container/https';
import { URL } from '../../../admin/container/constants';

export const getDashboardSidebarAllDashboard = (action$) =>
  action$.pipe(
    ofType(GET_DASHBOARD_SIDEBAR_ALL_DASHBOARD),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/dashboards/`);
    })
  );

export const getDashboardSidebarDashboardSequence = (action$) =>
  action$.pipe(
    ofType(GET_DASHBOARD_SIDEBAR_DASHBOARD_SEQUENCE),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/service/${action.payload.service_id}/ordering/`
      );
    })
  );