import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import valueGet from '../common/valueGet';
import WidgetSelect from '../../WidgetSelect';
import { Button } from '../../../../../../../common/components';

const OthersConfig = (props) => {
  const location = useLocation();

  const dashboardKey = location.pathname.split('/')[3] || 0;

  const [initialized, setInitialized] = useState(false);

  const [groupWidgetSelect, setGroupWidgetSelect] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams.gropu_widget_select &&
        props.widgetParams.gropu_widget_select.value;

      conf && setGroupWidgetSelect(conf);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <div className='new-input-wrap'>
          <WidgetSelect
            dashobardId={dashboardKey}
            value={groupWidgetSelect}
            onChange={(groupWidgetSelect) => {
              setGroupWidgetSelect(groupWidgetSelect);

              props.appInputParamChanged({
                gropu_widget_select: valueGet(groupWidgetSelect)
              });
            }}
          />
        </div>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default OthersConfig;
