import { useCallback, useState, useEffect } from 'react';

import {
  getGroupedCalculatedParametersList,
  getLocationsAndParameters
} from '../../../../../utils';

const useSensorCollection = (
  parameterConfCollection,
  sensorOptionCollection
) => {
  const _getGroupedCalculatedParametersList = () => {
    return getGroupedCalculatedParametersList().reduce(
      (memo, { label, options }, index) => {
        return [
          ...memo,
          {
            label: label || `GROUP-${index + 1}`,
            options: options.reduce((_memo, option) => {
              const exists = _memo.find(({ value }) => {
                return value === option.value;
              });

              return exists ? _memo : [..._memo, option];
            }, [])
          }
        ];
      },
      []
    );
  };

  const sensorCollectionGet = useCallback(
    (parameterConfCollection, sensorOptionCollection) => {
      return parameterConfCollection.reduce((memo, formula) => {
        const value =
          formula &&
          formula.parameter &&
          (sensorOptionCollection
            ? sensorOptionCollection.find(
                ({ value }) => value === formula.parameter.locationid
              )
            : _getGroupedCalculatedParametersList().reduce(
                (memo, { options }) => {
                  return !memo
                    ? options.find(({ value }) => {
                        return value === formula.parameter.locationid;
                      })
                    : memo;
                },
                null
              ));

        return value ? [...memo, { ...value, formula }] : [...memo, null];
      }, []);
    },
    []
  );

  const [sensorCollection, setSensorCollection] = useState([]);

  useEffect(() => {
    const sensorCollection = sensorCollectionGet(
      parameterConfCollection,
      sensorOptionCollection
    );

    setSensorCollection(sensorCollection);
  }, [parameterConfCollection, sensorOptionCollection, sensorCollectionGet]);

  return [
    sensorCollection,
    setSensorCollection,
    sensorOptionCollection || _getGroupedCalculatedParametersList()
  ];
};

const parameterCollectionByLocationidGet = (
  locationid,
  sensorOptionCollection
) => {
  const location = (sensorOptionCollection || getLocationsAndParameters()).find(
    ({ value } = {}) => value == locationid
  );

  return location && location.types;
};

const useParameterCollection = (
  parameterConfCollection,
  sensorOptionCollection
) => {
  const parameterCollectionGet = useCallback(
    (parameterConfCollection, sensorOptionCollection) => {
      return parameterConfCollection.reduce((memo, formula) => {
        const parameterOptionsCollection =
          formula &&
          formula.parameter &&
          parameterCollectionByLocationidGet(
            formula.parameter.locationid,
            sensorOptionCollection
          );

        const value =
          parameterOptionsCollection &&
          parameterOptionsCollection.find(({ value }) => {
            return value === formula.parameter.readingtypeid;
          });

        return value ? [...memo, value] : [...memo, null];
      }, []);
    },
    []
  );

  const [parameterCollection, setParameterCollection] = useState([]);

  useEffect(() => {
    const parameterCollection = parameterCollectionGet(
      parameterConfCollection,
      sensorOptionCollection
    );

    setParameterCollection(parameterCollection);
  }, [parameterConfCollection, sensorOptionCollection, parameterCollectionGet]);

  return [parameterCollection, setParameterCollection];
};

const useParameterOptionsCollection = (
  parameterConfCollection,
  sensorOptionCollection
) => {
  const parameterOptionsCollectionGet = useCallback(
    (parameterConfCollection, sensorOptionCollection) => {
      return parameterConfCollection.reduce((memo, formula) => {
        const parameterOptions =
          formula &&
          formula.parameter &&
          parameterCollectionByLocationidGet(
            formula.parameter.locationid,
            sensorOptionCollection
          );

        return parameterOptions ? [...memo, parameterOptions] : [...memo, null];
      }, []);
    },
    []
  );

  const [parameterOptionsCollection, setParameterOptionsCollection] = useState([
    []
  ]);

  useEffect(() => {
    const parameterOptionsCollection = parameterOptionsCollectionGet(
      parameterConfCollection,
      sensorOptionCollection
    );

    setParameterOptionsCollection(parameterOptionsCollection);
  }, [
    parameterConfCollection,
    sensorOptionCollection,
    parameterOptionsCollectionGet
  ]);

  return [parameterOptionsCollection, setParameterOptionsCollection];
};

export default (parameterConfCollection, sensorOptionCollection) => {
  const [sensorCollection, setSensorCollection, sensorOptions] =
    useSensorCollection(parameterConfCollection, sensorOptionCollection);

  const [parameterCollection, setParameterCollection] = useParameterCollection(
    parameterConfCollection,
    sensorOptionCollection
  );

  const [parameterOptionsCollection, setParameterOptionsCollection] =
    useParameterOptionsCollection(
      parameterConfCollection,
      sensorOptionCollection
    );

  const onSensorChangeHandle = (sensor, index, callback) => {
    const _sensorCollection = [
      ...sensorCollection.slice(0, index),
      sensor,
      ...sensorCollection.slice(index + 1)
    ];

    const _parameterCollection = [
      ...parameterCollection.slice(0, index),
      null,
      ...parameterCollection.slice(index + 1)
    ];

    const parameterOptions = (
      parameterCollectionByLocationidGet(
        sensor.value,
        sensorOptionCollection
      ) || []
    ).reduce((memo, parameterOption) => {
      return [...memo, parameterOption];
    }, []);

    const _parameterOptionsCollection = [
      ...parameterOptionsCollection.slice(0, index),
      parameterOptions,
      ...parameterOptionsCollection.slice(index + 1)
    ];

    setSensorCollection(_sensorCollection);

    setParameterCollection(_parameterCollection);

    setParameterOptionsCollection(_parameterOptionsCollection);

    const { label, value } = sensor;

    callback &&
      callback(
        {
          type: 'parameter',
          index,
          parameter: {
            locationid: value,
            sensor_type_id: value,
            locationLabel: label
          }
        },
        index
      );
  };

  const onParameterChangeHandle = (parameter = {}, index, callback) => {
    const { value: locationid, label: locationLabel } =
      sensorCollection[index] || {};

    const value = {
      type: 'parameter',
      index,
      parameter: {
        locationLabel,
        locationid,
        sensor_type_id: locationid,
        readingtypeLabel: parameter.label,
        readingtypeid: parameter.value
      }
    };

    callback(value, index);
  };

  return [
    sensorCollection,
    sensorOptions,
    parameterCollection,
    parameterOptionsCollection,
    onSensorChangeHandle,
    onParameterChangeHandle
  ];
};
