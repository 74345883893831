import React, { Fragment, useEffect, useState } from 'react';

import _DateTimeConfig from '../DateTimeConfig/_DateTimeConfig';
import { Button, RadioButtons } from '../../../../../../../common/components';

const actionTypeCollection = ['Live', 'Aggregated'];

const DateTimeConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [dataTypeActiveIndex, setDataTypeActiveIndex] = useState();

  const [dateRule, setDateRule] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setDataTypeActiveIndex(
        (conf.datatype && conf.datatype.value.datatype) || 0
      );

      setDateRule((conf.datatype && conf.datatype.value.dateRule) || {});

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (value) => {
    return props.appInputParamChanged({
      datatype: {
        value
      }
    });
  };

  const renderDateTimeConfig = () => {
    return (
      <_DateTimeConfig
        periodOptionCollection={['Day', 'Week', 'Month']}
        period={dateRule.period}
        from={dateRule.from}
        to={dateRule.to}
        startTime={dateRule.startTime}
        endTime={dateRule.endTime}
        appInputParamChanged={(object) => {
          const _dateRule = {
            ...dateRule,
            ...object
          };

          setDateRule(_dateRule);

          appInputParamChangedHandle({
            datatype: dataTypeActiveIndex,
            dateRule: _dateRule
          });
        }}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <p className='head-label-text'>Data Type</p>

        <div className='new-input-wrap'>
          <RadioButtons
            noOfButtons={2}
            getButtonLableAtIndex={(index) => {
              return actionTypeCollection[index];
            }}
            buttonClickAtIndex={(_dataTypeActiveIndex) => {
              setDataTypeActiveIndex(_dataTypeActiveIndex);

              appInputParamChangedHandle({
                datatype: _dataTypeActiveIndex,
                ...(() => {
                  return _dataTypeActiveIndex ? { dateRule } : {};
                })()
              });
            }}
            active={dataTypeActiveIndex}
          />
        </div>

        {dataTypeActiveIndex === 1 && (
          <div className='content-border-wrap minh100_42'>
            {renderDateTimeConfig()}
          </div>
        )}

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default DateTimeConfig;
