import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { httpMethodGetJSON } from '../../../../https';
import { URL } from '../../../../constants';

import {
  GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA,
  GET_ASSET_HEALTH_COUNT_ASSETS_DATA
} from './constant';

export const getAssetHealthCountCbmAssetData = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/asset-names/stats?page_size=100&page_no=1`
      );
    })
  );

export const getAssetHealthCountAssetsData = (action$) =>
  action$.pipe(
    ofType(GET_ASSET_HEALTH_COUNT_ASSETS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(
        { type: GET_ASSET_HEALTH_COUNT_ASSETS_DATA },
        `${URL}/asset-cbm/asset-names/${action.payload.asset_name}/assets/?${action.payload.searchQuery}`
      );
    })
  );
