import React, { useState, Fragment, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import widgetConfigParamGet from '../common/widgetConfigParamGet';
import parameterConfsInputGet from '../common/parameterConfsInputGet';
import sensorOptionCollectionGet from '../common/sensorOptionCollectionGet';
import parameterOptionCollectionGet from '../common/parameterOptionCollectionGet';
import valueGet from '../common/valueGet';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import { Select, Input, Button } from '../../../../../../../common/components';

const parameterConfsGet = (conf) => {
  const parameterConfs =
    conf &&
    (() => {
      const {
        sensor: { value: locationid },
        parameter: {
          value: { label: readingtypeLabel, value: readingtypeid }
        }
      } = conf;

      return [
        {
          locParam: {
            location: {
              value: locationid
            },
            readingtype: {
              label: readingtypeLabel,
              value: readingtypeid
            }
          }
        }
      ];
    })();

  return parameterConfsInputGet(parameterConfs);
};

const _parameterConfsGet = (parameterConfs) => {
  const _parameterConfs = parameterConfsOutputGet(parameterConfs);

  return (
    _parameterConfs &&
    (() => {
      const {
        locParam: {
          location: { value: locationid },
          readingtype: { label: readingtypeLabel, value: readingtypeid }
        }
      } = _parameterConfs[0];

      return {
        sensor: valueGet(locationid),
        parameter: valueGet({
          label: readingtypeLabel,
          value: readingtypeid
        })
      };
    })()
  );
};

const ParameterConfig = (props) => {
  const periodOptionCollection = widgetConfigParamGet(
    props.widgetConfig,
    'period'
  ).options;

  const dataFrequencyOptionCollection = widgetConfigParamGet(
    props.widgetConfig,
    'dataFrequency'
  ).options;

  const [initialized, setInitialized] = useState(false);

  const [period, setPeriod] = useState();

  const [dataFrequency, setDataFrequency] = useState();

  const [parameterConfs, setParameterConfs] = useState();

  const [thresholdPercent, setThresholdPercent] = useState();

  const [trainingWeeks, setTrainingWeeks] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setPeriod(conf.period && conf.period.value);

      setDataFrequency(conf.dataFrequency && conf.dataFrequency.value);

      setParameterConfs(
        parameterConfsGet(
          conf.sensor &&
            conf.sensor.value &&
            conf.parameter &&
            conf.parameter.value &&
            conf
        )
      );

      setThresholdPercent(conf.thresholdPercent && conf.thresholdPercent.value);

      setTrainingWeeks(conf.trainingWeeks && conf.trainingWeeks.value);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      period: valueGet(period),
      dataFrequency: valueGet(dataFrequency),
      thresholdPercent: valueGet(thresholdPercent),
      trainingWeeks: valueGet(trainingWeeks),
      ...object
    };

    props.appInputParamChanged(value);
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Period</label>

              <Select
                options={periodOptionCollection.reduce((memo, value) => {
                  return [
                    ...memo,
                    {
                      label: value,
                      value
                    }
                  ];
                }, [])}
                isMulti={false}
                placeholder='Select Period'
                value={(() => {
                  const value = period;

                  return (
                    period && {
                      label: value,
                      value
                    }
                  );
                })()}
                onChange={({ value }) => {
                  setPeriod(value);

                  appInputParamChangedHandle({
                    period: valueGet(value)
                  });
                }}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Data Frequency</label>

              <Select
                options={dataFrequencyOptionCollection.reduce((memo, value) => {
                  return [
                    ...memo,
                    {
                      label: value,
                      value
                    }
                  ];
                }, [])}
                isMulti={false}
                placeholder='Select Data Frequency'
                value={(() => {
                  const value = dataFrequency;

                  return (
                    period && {
                      label: value,
                      value
                    }
                  );
                })()}
                onChange={({ value }) => {
                  setDataFrequency(value);

                  appInputParamChangedHandle({
                    dataFrequency: valueGet(value)
                  });
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Location *</label>

              <Select
                options={sensorOptionCollectionGet()}
                isMulti={false}
                placeholder='Select Sensors'
                value={
                  parameterConfs[0].parameter.locationid &&
                  sensorOptionCollectionGet().find(
                    ({ value }) =>
                      value === parameterConfs[0].parameter.locationid
                  )
                }
                onChange={({ label, value }) => {
                  const _parameterConfs = [
                    {
                      ...parameterConfs[0],
                      parameter: {
                        locationLabel: label,
                        locationid: value,
                        readingtypeLabel: null,
                        readingtypeid: null
                      }
                    }
                  ];

                  setParameterConfs(_parameterConfs);

                  appInputParamChangedHandle(
                    _parameterConfsGet(_parameterConfs)
                  );
                }}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Parameter *</label>

              <Select
                options={parameterOptionCollectionGet()}
                isMulti={false}
                placeholder='Select Parameters'
                value={
                  parameterConfs[0].parameter.readingtypeid &&
                  parameterOptionCollectionGet().find(
                    ({ value }) =>
                      value === parameterConfs[0].parameter.readingtypeid
                  )
                }
                onChange={({ label, value }) => {
                  const _parameterConfs = [
                    {
                      ...parameterConfs[0],
                      parameter: {
                        ...parameterConfs[0].parameter,
                        readingtypeLabel: label,
                        readingtypeid: value
                      }
                    }
                  ];

                  setParameterConfs(_parameterConfs);

                  appInputParamChangedHandle(
                    _parameterConfsGet(_parameterConfs)
                  );
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Threshold Percent</label>

              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter From'
                value={thresholdPercent || ''}
                inputChanged={(thresholdPercent) => {
                  setThresholdPercent(thresholdPercent);
                  appInputParamChangedHandle({
                    thresholdPercent: valueGet(thresholdPercent)
                  });
                }}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Training Weeks</label>

              <Input
                size='sm'
                label=''
                type='number'
                required={true}
                {...(() => {
                  const { min, max } = props.widgetConfig.widgetParams.find(
                    ({ name }) => name === 'trainingWeeks'
                  );

                  return { min, max };
                })()}
                placeholder='Enter From'
                value={trainingWeeks || ''}
                inputChanged={(trainingWeeks, isInValid) => {
                  setTrainingWeeks(trainingWeeks);

                  appInputParamChangedHandle({
                    trainingWeeks: {
                      ...valueGet(trainingWeeks),
                      isInValid
                    }
                  });
                }}
              />
            </div>
          </Col>
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfig;
