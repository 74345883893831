/**
 * All epics reqiured for widget 
 */
import Config from 'Config'
import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";
import {
  ALERT_MONITORING_DATA,
  ALERTS_FOR_MONITORING
} from './constant';
import { httpMethodGetJSON } from '../../https';

const URL = Config.api_url;
const MONITOR_URL = `${URL}/alerts/monitoring-summary/`;
const ALL_ALERTS_URL = `${URL}/alerts/`;

export const getAlertMonitoringData = (action$) =>
  action$.pipe(
    ofType(ALERT_MONITORING_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, MONITOR_URL);
    }),
  );

export const getAllAlertForMonitoring = (action$) =>
  action$.pipe(
    ofType(ALERTS_FOR_MONITORING),
    mergeMap((action) => {
      return httpMethodGetJSON(action, ALL_ALERTS_URL);
    }),
  );