import React from "react";
import CommonSearch from "../CommonSearch";

function ClientProfile(props) {
    let { users, clientData } = props;
    return (
        <div className={props.isSearchBarOpen ? 'accounts' : 'pt-20 accounts'}>
            <CommonSearch
                setSearchBar={props.setSearchBar}
                title="Select the Client’s profile you want to access"
                isSearchBarOpen={props.isSearchBarOpen}
                // setSearchValue={props.setSearchValue}
                setSearchValue={(value) => {
                    props.setSearchValue(value)
                    props.setActiveSearch('clientDashboard')
                }}
                searchValue={props.searchValue}
            />
            <div className='partner-login-radio'>
                {
                    users.loading == true ? 'Loading...' :
                        users.data && Array.isArray(users.data) && users.data.length > 0 ? users.data.map((item, index) => {
                            return <label className="partner-login-label" key={index} title={item.username}>
                                {`${item.firstname} ${item.lastname}`}
                                <input type="radio" name="radio" checked={item.memberid == clientData.memberid ? true : false} onChange={() => {
                                    props.setClientData({
                                        memberid: item.memberid,
                                        clientid: item.clientid,
                                        type: 'clientDashboard'
                                    })
                                    props.setActiveStep('clientDashboard');
                                }} />
                                <span className="checkmark"></span>
                            </label>
                        }) : <span>Client names not found!</span>
                }
            </div>
        </div>
    );
}

export default ClientProfile;