import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import rightArrow from '../../../../assets/images/UserRole/DropDown-Blue.png';
import Icon from '../Icon';
import { ICON_POSITION } from '../Icon/constants';

const TAB_SIZE = ['sm', 'md', 'lg', 'normal'];

export default class Tabs extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: this.props.activeTabIndex ? this.props.activeTabIndex : 0,
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }

        if (this.props.tabClickedAtIndex) {
            this.props.tabClickedAtIndex(tab);
        }
    }

    static getDerivedStateFromProps(props, state) {
       return { activeTab: props.activeTabIndex}
    }

    onDragStart = (ev) => {
        const i = ev.target.getAttribute('tab-index');
        ev.dataTransfer.setData("draggedIndex", i);
        ev.target.style.opacity = '0.4';
        ev.target.style.border = '1px dashed #000';
        ev.target.style.borderRadius = '2px';
    }

    onDragEnd = (ev) => {
        ev.target.style.opacity = '1';
        ev.target.style.border = '';
        ev.target.style.borderRadius = '';
    }

    drop = (ev) => {
        ev.preventDefault();
        const srcIndex = ev.dataTransfer.getData("draggedIndex");
        const tgtIndex = ev.currentTarget.getAttribute('tab-index');
        this.props.swappedTabsAtIndex && this.props.swappedTabsAtIndex(srcIndex, tgtIndex)
        if ( ev.currentTarget.className == "nav-item" ) {
            ev.currentTarget.style.border = "";
        }
    }

    allowDrop = (ev) => {
        ev.preventDefault();
        ev.dataTransfer.dropEffect = 'move';

        if ( ev.currentTarget.className == "nav-item" ) {
            ev.currentTarget.style.border = "1px dashed #4C74F2";
        }

        return false;
    }

    onDragEnter = (ev) => {
        if ( ev.currentTarget.className == "nav-item" ) {
            ev.currentTarget.style.border = "1px dashed #4C74F2";
        }
    }

    onDragLeave = (ev) => {
        if ( ev.currentTarget.className == "nav-item" && ev.target.className != "nav-item") {
            ev.currentTarget.style.border = "";
        }
    }


    render() {
        const tabHeaders = [];
        const tabContent = [];
        let len = this.props.noOfTabs;

        for (let i = 0; i < len; i++) {
            tabHeaders.push(
                <NavItem 
                    key={i} 
                    tab-index={i} 
                    onDrop = {this.drop} 
                    onDragOver={this.allowDrop}
                    onDragEnd={this.onDragEnd}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                >
                    <NavLink
                        className={classnames({ active: this.state.activeTab == i })}
                        onClick={() => {
                            this.toggle(i);
                        }}
                    >
                        <div 
                            tab-index={i} 
                            draggable = {this.props.draggable}
                            onDragStart={this.onDragStart}
                        >
                            {this.props.getTabHeaderAtIndex ? this.props.getTabHeaderAtIndex(i) : 'Tab ' + i}
                            {this.props.vertical ? (
                                <div className="navIcons">
                                    <Icon
                                        size={15}
                                        alt="text"
                                        src={rightArrow}
                                        position={ICON_POSITION.CENTER_RIGHT}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </NavLink>
                </NavItem>,
            );

            tabContent.push(
                <TabPane key={i} tabId={i}>
                    {this.state.activeTab === i
                        ? this.props.getTabContentAtIndex
                            ? this.props.getTabContentAtIndex(i)
                            : ''
                        : null}
                </TabPane>,
            );
        }

        return (
            <React.Fragment>
                {this.props && this.props.hideHead ? null:
                <Nav
                    vertical={!!this.props.vertical}
                    className={'SSTabsHeader scrollable ' + (TAB_SIZE.indexOf(this.props.size) !== -1 ? this.props.size : 'normal')}
                    tabs
                    justified={this.props.noJustified !== true}
                    onScroll = {this.props.onScroll}
                >
                    {tabHeaders}
                </Nav>}

                <TabContent activeTab={this.state.activeTab} className={`SSTabsContent ${this.props && this.props.hideHead && "h-100"}`}>
                    {tabContent}
                </TabContent>
            </React.Fragment>
        );
    }
}
