export const GET_INSIGHT_ALERT_SUMMARY_DATA = 'GET_INSIGHT_ALERT_SUMMARY_DATA';

export const GET_INSIGHT_ALERT_SUMMARY_DATA_SUCCESS =
  'GET_INSIGHT_ALERT_SUMMARY_DATA_SUCCESS';

export const GET_INSIGHT_ALERT_SUMMARY_DATA_FAILURE =
  'GET_INSIGHT_ALERT_SUMMARY_DATA_FAILURE';

export const GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA =
  'GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA';

export const GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA_SUCCESS =
  'GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA_SUCCESS';

export const GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA_FAILURE =
  'GET_INSIGHT_ALERT_SUMMARY_CBM_ASSETS_DATA_FAILURE';

export const GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA =
  'GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA';
export const GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA_SUCCESS =
  'GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA_SUCCESS';
export const GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA_FAILURE =
  'GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA_FAILURE';
export const GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA_RESET =
  'GET_INSIGHT_ALERT_SUMMARY_ASSETS_MIN_DATA_RESET';