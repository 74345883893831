import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import valueGet from '../common/valueGet';
import { Button, Input, Select } from '../../../../../../../common/components';
import widgetConfigParamGet from '../common/widgetConfigParamGet';

const OthersConfig = (_props) => {
  const { appInputParamChanged, ...props } = _props;

  const totalConsumptionCollection = widgetConfigParamGet(
    props.widgetConfig,
    'totalConsumption'
  ).options;

  const [initialized, setInitialized] = useState(false);

  const [totalConsumption, setTotalConsumption] = useState();

  const [threshold, setThreshold] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setTotalConsumption(
        conf && conf.totalConsumption && conf.totalConsumption.value
      );

      setThreshold(conf && conf.threshold && conf.threshold.value);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      totalConsumption: valueGet(totalConsumption),
      threshold: valueGet(threshold),
      ...object
    };

    appInputParamChanged(value);
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Total Consumption</label>
              <Select
                options={totalConsumptionCollection.reduce((memo, value) => {
                  return [
                    ...memo,
                    {
                      label: value,
                      value
                    }
                  ];
                }, [])}
                isMulti={false}
                placeholder='Select Total Consumption'
                value={(() => {
                  const value = totalConsumption;

                  return {
                    label: value,
                    value
                  };
                })()}
                onChange={({ value }) => {
                  setTotalConsumption(value);

                  appInputParamChangedHandle({
                    totalConsumption: valueGet(value)
                  });
                }}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Threshold</label>
              <Input
                size='sm'
                label=''
                type='input'
                required={false}
                placeholder='Enter Threshold'
                value={threshold}
                inputChanged={(_threshold) => {
                  setThreshold(_threshold);

                  appInputParamChangedHandle({
                    threshold: valueGet(_threshold)
                  });
                }}
              />
            </div>
          </Col>
        </Row>
        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />
          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default OthersConfig;
