import {
    GET_LIVE_CONSUMPTION_DATA,
    GET_LIVE_CONSUMPTION_DATA_SUCCESS,
    GET_LIVE_CONSUMPTION_DATA_FAILURE,

    GET_ENERGY_FORECAST_DATA,
    GET_ENERGY_FORECAST_DATA_SUCCESS,
    GET_ENERGY_FORECAST_DATA_FAILURE,

    GET_TOTAL_CONSUMPTION_DATA,
    GET_TOTAL_CONSUMPTION_DATA_SUCCESS,
    GET_TOTAL_CONSUMPTION_DATA_FAILURE,

    GET_DATERANGE_ENERGY_CONSUMPTION_DATA,
    GET_DATERANGE_ENERGY_CONSUMPTION_DATA_SUCCESS,
    GET_DATERANGE_ENERGY_CONSUMPTION_DATA_FAILURE,

    GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA,
    GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_SUCCESS,
    GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_FAILURE,

    GET_RANGE_ENERGY_CONSUMPTION_CHART,
    GET_RANGE_ENERGY_CONSUMPTION_CHART_SUCCESS,
    GET_RANGE_ENERGY_CONSUMPTION_CHART_FAILURE,

    GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_INIT,
    GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_SUCCESS,
    GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_FAILURE,

    GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_INIT,
    GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_SUCCESS,
    GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_FAILURE
} from './constant';
import {DATE_RANGE_LIST} from "../utils";

const INITIAL_STATE = {
    data: [],
    loading: false,
    error: false
};

const VALUE_INITIAL_STATE = {
    data: {
        value: null,
        unit: null
    },
    loading: false,
    error: false
};


const DATE_TREND_INITIAL_STATE = {};
for (let i = 0; i < DATE_RANGE_LIST.length; i++) {
    const dateRange = DATE_RANGE_LIST[i];
    DATE_TREND_INITIAL_STATE[dateRange] = {
        data: {
            value: null,
            unit: null
        },
        loading: false,
        error: false
    };
}

const DATE_DIFF_TREND_INITIAL_STATE = {};
for (let i = 0; i < DATE_RANGE_LIST.length; i++) {
    const dateRange = DATE_RANGE_LIST[i];
    DATE_DIFF_TREND_INITIAL_STATE[dateRange] = {
        data: {
            value: 0,
            trend: ''
        },
        loading: false,
        error: false
    };
}

export function getLiveConsumptionDataReducer(state = VALUE_INITIAL_STATE, action) {
    switch (action.type) {
        case GET_LIVE_CONSUMPTION_DATA:
            return {
                ...state,
                loading: true,
                data: VALUE_INITIAL_STATE.data,
                error: false
            };
        case GET_LIVE_CONSUMPTION_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_LIVE_CONSUMPTION_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: VALUE_INITIAL_STATE.data,
                loading: false
            };
        default:
            return state
    }
}

export function getEnergyForecastReducer(state = VALUE_INITIAL_STATE, action) {
    switch (action.type) {
        case GET_ENERGY_FORECAST_DATA:
            return {
                ...state,
                loading: true,
                data: VALUE_INITIAL_STATE.data,
                error: false
            };
        case GET_ENERGY_FORECAST_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_ENERGY_FORECAST_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: VALUE_INITIAL_STATE.data,
                loading: false
            };
        default:
            return state
    }
}

export function getTotalConsumptionDataReducer(state = VALUE_INITIAL_STATE, action) {
    switch (action.type) {
        case GET_TOTAL_CONSUMPTION_DATA:
            return {
                ...state,
                loading: true,
                data: VALUE_INITIAL_STATE.data,
                error: false
            };
        case GET_TOTAL_CONSUMPTION_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_TOTAL_CONSUMPTION_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: VALUE_INITIAL_STATE.data,
                loading: false
            };
        default:
            return state
    }
}

export function getDateRangeEnergyConsumptionDataReducer(state = DATE_TREND_INITIAL_STATE, action) {
    const {payload} = action;
    let currentValue;
    switch (action.type) {
        case GET_DATERANGE_ENERGY_CONSUMPTION_DATA:
            currentValue = {
                [payload.request_key]: {
                    loading: true,
                    data: DATE_TREND_INITIAL_STATE[payload.request_key].data,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATERANGE_ENERGY_CONSUMPTION_DATA_SUCCESS:
            currentValue = {
                [payload.request_key]: {
                    loading: false,
                    data: payload,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATERANGE_ENERGY_CONSUMPTION_DATA_FAILURE:
            currentValue = {
                [payload.request_key]: {
                    error: true,
                    data: DATE_TREND_INITIAL_STATE[payload.request_key].data,
                    loading: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        default:
            return state
    }
}

export function getDateRangeEnergyForeCastDataReducer(state = DATE_TREND_INITIAL_STATE, action) {
    const {payload} = action;
    let currentValue;
    switch (action.type) {
        case GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA:
            currentValue = {
                [payload.request_key]: {
                    loading: true,
                    data: DATE_TREND_INITIAL_STATE[payload.request_key].data,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_SUCCESS:
            currentValue = {
                [payload.request_key]: {
                    loading: false,
                    data: payload,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_ENERGY_FORECAST_ENERGY_DATA_FAILURE:
            currentValue = {
                [payload.request_key]: {
                    error: true,
                    data: DATE_TREND_INITIAL_STATE[payload.request_key].data,
                    loading: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        default:
            return state
    }
}

export const getRangeEnergyConsumptionTimeSeriesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_RANGE_ENERGY_CONSUMPTION_CHART:
            return {
                ...state,
                loading: true,
                data: [],
                error: false
            };
        case GET_RANGE_ENERGY_CONSUMPTION_CHART_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_RANGE_ENERGY_CONSUMPTION_CHART_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                loading: false
            };
        default:
            return state;
    }
};

export function getDateRangeEnergyConsumptionDiffDataReducer(state = DATE_DIFF_TREND_INITIAL_STATE, action) {
    const {payload} = action;
    let currentValue;
    switch (action.type) {
        case GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_INIT:
            currentValue = {
                [payload.request_key]: {
                    loading: true,
                    data: DATE_DIFF_TREND_INITIAL_STATE[payload.request_key].data,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_SUCCESS:
            currentValue = {
                [payload.request_key]: {
                    loading: false,
                    data: payload,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_ENERGY_CONSUMPTION_DIFF_DATA_FAILURE:
            currentValue = {
                [payload.request_key]: {
                    error: true,
                    data: DATE_DIFF_TREND_INITIAL_STATE[payload.request_key].data,
                    loading: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        default:
            return state
    }
}

export function getDateRangeEnergyForeCastDiffDataReducer(state = DATE_DIFF_TREND_INITIAL_STATE, action) {
    const {payload} = action;
    let currentValue;
    switch (action.type) {
        case GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_INIT:
            currentValue = {
                [payload.request_key]: {
                    loading: true,
                    data: DATE_DIFF_TREND_INITIAL_STATE[payload.request_key].data,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_SUCCESS:
            currentValue = {
                [payload.request_key]: {
                    loading: false,
                    data: payload,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_ENERGY_FORECAST_DIFF_DATA_FAILURE:
            currentValue = {
                [payload.request_key]: {
                    error: true,
                    data: DATE_DIFF_TREND_INITIAL_STATE[payload.request_key].data,
                    loading: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        default:
            return state
    }
}

