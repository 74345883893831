export const GET_ALL_ALERTS = 'GET_ALL_ALERTS';
export const GET_ALL_ALERTS_SUCCESS = 'GET_ALL_ALERTS_SUCCESS';
export const GET_ALL_ALERTS_FAILURE = 'GET_ALL_ALERTS_FAILURE';
export const GET_ALL_ALERTS_RESET = 'GET_ALL_ALERTS_RESET';

export const CHECK_ALL_ALERT = 'CHECK_ALL_ALERT';
export const CHECK_ALL_ALERT_SUCCESS = 'CHECK_ALL_ALERT_SUCCESS';
export const CHECK_ALL_ALERT_FAILURE = 'CHECK_ALL_ALERT_FAILURE';

export const CHECK_ALL_ALERT_LOG = 'CHECK_ALL_ALERT_LOG';
export const CHECK_ALL_ALERT_LOG_SUCCESS = 'CHECK_ALL_ALERT_LOG_SUCCESS';

export const CHECK_ALERT_LOG = 'CHECK_ALERT_LOG';
export const CHECK_ALERT_LOG_SUCCESS = 'CHECK_ALERT_LOG_SUCCESS';

export const CHECK_ALERT = 'CHECK_ALERT';
export const CHECK_ALERT_SUCCESS = 'CHECK_ALERT_SUCCESS';
export const CHECK_ALERT_FAILURE = 'CHECK_ALERT_FAILURE';

export const GET_ALERT_LOG_ALL = 'GET_ALERT_LOG_ALL';
export const GET_ALERT_LOG_ALL_SUCCESS = 'GET_ALERT_LOG_ALL_SUCCESS';
export const GET_ALERT_LOG_ALL_FAILURE = 'GET_ALERT_LOG_ALL_FAILURE';

export const GET_ALERT_LOG_BY_ID = 'GET_ALERT_LOG_BY_ID';
export const GET_ALERT_LOG_BY_ID_SUCCESS = 'GET_ALERT_LOG_BY_ID_SUCCESS';
export const GET_ALERT_LOG_BY_ID_FAILURE = 'GET_ALERT_LOG_BY_ID_FAILURE';

export const UPDATE_ALERT_LOG = 'UPDATE_ALERT_LOG';
export const UPDATE_ALERT_LOG_SUCCESS = 'UPDATE_ALERT_LOG_SUCCESS';
export const UPDATE_ALERT_LOG_FAILURE = 'UPDATE_ALERT_LOG_FAILURE';
export const UPDATE_ALERT_LOG_RESET = 'UPDATE_ALERT_LOG_RESET';

export const UPDATE_ALERT_LOG_STATUS = 'UPDATE_ALERT_LOG_STATUS';
export const UPDATE_ALERT_LOG_STATUS_SUCCESS = 'UPDATE_ALERT_LOG_STATUS_SUCCESS';
export const UPDATE_ALERT_LOG_STATUS_FAILURE = 'UPDATE_ALERT_LOG_STATUS_FAILURE';
export const UPDATE_ALERT_LOG_STATUS_RESET = 'UPDATE_ALERT_LOG_STATUS_RESET';

export const GET_ALERT_HISTORY_BY_ID = 'GET_ALERT_HISTORY_BY_ID';
export const GET_ALERT_HISTORY_BY_ID_SUCCESS = 'GET_ALERT_HISTORY_BY_ID_SUCCESS';
export const GET_ALERT_HISTORY_BY_ID_FAILURE = 'GET_ALERT_HISTORY_BY_ID_FAILURE';

export const GET_ALERT_HISTORY_LOGS_BY_ID = 'GET_ALERT_HISTORY_LOGS_BY_ID';
export const GET_ALERT_HISTORY_LOGS_BY_ID_SUCCESS = 'GET_ALERT_HISTORY_LOGS_BY_ID_SUCCESS';
export const GET_ALERT_HISTORY_LOGS_BY_ID_FAILURE = 'GET_ALERT_HISTORY_LOGS_BY_ID_FAILURE';

export const CREATE_ALERT = 'CREATE_ALERT';
export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS';
export const CREATE_ALERT_FAILURE = 'CREATE_ALERT_FAILURE';
export const CREATE_ALERT_RESET = 'CREATE_ALERT_RESET';

export const UPDATE_ALERT = 'UPDATE_ALERT';
export const UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS';
export const UPDATE_ALERT_FAILURE = 'UPDATE_ALERT_SUCCESS';
export const UPDATE_ALERT_RESET = 'UPDATE_ALERT_RESET';

export const DELETE_ALERT = 'DELETE_ALERT';
export const DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS';
export const DELETE_ALERT_FAILURE = 'DELETE_ALERT_FAILURE';
export const DELETE_ALERT_RESET = 'DELETE_ALERT_RESET';

export const GET_GENERATED_ALERT_SUMMARY = 'GET_GENERATED_ALERT_SUMMARY';
export const GET_GENERATED_ALERT_SUMMARY_SUCCESS = 'GET_GENERATED_ALERT_SUMMARY_SUCCESS';
export const GET_GENERATED_ALERT_SUMMARY_FAILURE = 'GET_GENERATED_ALERT_SUMMARY_FAILURE';

export const DOWNLOAD_ALERT_LOGS = 'DOWNLOAD_ALERT_LOGS';
export const DOWNLOAD_ALERT_LOGS_SUCCESS = 'DOWNLOAD_ALERT_LOGS_SUCCESS';
export const DOWNLOAD_ALERT_LOGS_FAILURE = 'DOWNLOAD_ALERT_LOGS_FAILURE';
export const DOWNLOAD_ALERT_LOGS_RESET = ' DOWNLOAD_ALERT_LOGS_RESET';

export const GET_REPEAT_EVERY = 'GET_REPEAT_EVERY';