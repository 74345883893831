import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import axios from 'axios';
import { httpMethodGetJSON, httpMethodPatch, httpMethodDelete, httpMethodPost, } from '../https';
import { ajax } from 'rxjs/ajax';
import { map } from 'lodash';
import { URL } from '../constants';
import * as AppConstants from './constant';


export const getAllReports = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_REPORTS),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/reports/`);
    })
);

export const getAllReportLogs = action$ => action$.pipe(
    ofType(AppConstants.GET_ALL_REPORT_LOGS),
    mergeMap(action => {
        return httpMethodGetJSON(action, `${URL}/reports/logs/`);
    })
);

export const checkAllReport = action$ => action$.pipe(
    ofType(AppConstants.CHECK_ALL_REPORT),
    mergeMap(action => {
        return of({ type: AppConstants.CHECK_ALL_REPORT_SUCCESS, payload: action.payload });
    })
);

export const checkReport = action$ => action$.pipe(
    ofType(AppConstants.CHECK_REPORT),
    mergeMap(action => {
        return of({ type: AppConstants.CHECK_REPORT_SUCCESS, payload: action.payload });
    })
);

export const checkReportLog = action$ => action$.pipe(
    ofType(AppConstants.CHECK_REPORT_LOG),
    mergeMap(action => {
        return of({ type: AppConstants.CHECK_REPORT_LOG_SUCCESS, payload: action.payload });
    })
);


// export const deleteReport = action$ => action$.pipe(
//     ofType(AppConstants.DELETE_REPORT),
//     mergeMap((action, a) => {
//         return axios.delete(`${URL}/alerts/${action.payload.ReportId}`, {
//             headers: {
//                 "Content-Type": "application/json; charset=UTF-8",
//                 Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
//             },
//             // data: action.payload,
//         }).then((response) => {
//             return of({ type: AppConstants.DELETE_REPORT_SUCCESS, payload: response.data });
//         }).catch(error => {
//             return of({
//                 type: AppConstants.DELETE_REPORT_FAILURE,
//                 error: true,
//                 payload: error
//             })
//         })
//     })
// );

export const deleteReport = action$ => action$.pipe(
    ofType(AppConstants.DELETE_REPORT),
    mergeMap((action, a) => {
        return axios.delete(`${URL}/reports/`, {
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
            },
            data: action.payload,
        }).then((response) => {
            return { type: AppConstants.DELETE_REPORT_SUCCESS, payload: response.data };
        }).catch(error => {
            return {
                type: AppConstants.DELETE_REPORT_FAILURE,
                error: true,
                payload: error
            }
        })

    })
);

export const createReport = action$ => action$.pipe(
    ofType(AppConstants.CREATE_REPORT),
    mergeMap(action => {
        return httpMethodPost(action, `${URL}/reports/`);
    })

    // ofType(AppConstants.CREATE_REPORT),
    // mergeMap((action, a) => {
    //     let data = action.payload
    //     return axios.post(`${URL}/reports/`, data, {
    //         headers: {
    //             "Content-Type": "application/json; charset=UTF-8",
    //             Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
    //         }
    //     }).then((response) => {
    //         return of({ type: AppConstants.CREATE_REPORT_SUCCESS, payload: response.data });
    //     }).catch(error => {
    //         return of({
    //             type: AppConstants.CREATE_REPORT_FAILURE,
    //             error: true,
    //             payload: error
    //         })
    //     })

    // })
);

export const updateReport = action$ => action$.pipe(
    ofType(AppConstants.UPDATE_REPORT),
    // mergeMap(action => {
    //     return httpMethodPatch(action, `${URL}/reports/`);
    // })
    mergeMap(action => {
        return httpMethodPatch(action, `${URL}/reports/${action.payload.ReportId}/`);
    })
);

export const downloadReport = action$ => action$.pipe(
    ofType(AppConstants.DOWNLOAD_REPORT_BY_ID),
    mergeMap((action, a) => {
        return httpMethodPost({ type: AppConstants.DOWNLOAD_REPORT_BY_ID, payload: action.payload.apiParams }, `${URL}/reports/${action.payload.ReportId}/download/`);
        // let searchQuery = getQueryString(action.payload.apiParams);
        // return fetch(`${URL}/reports/${action.payload.ReportId}/download/${searchQuery}`, {
        //     headers: {
        //         "Content-Type": "application/json; charset=UTF-8",
        //         Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
        //     },
        // }).then((response) => {
        //     response.blob();
        //     return { type: AppConstants.DOWNLOAD_REPORT_BY_ID_SUCCESS };
        // }).then((blob) => {
        //     // Create blob link to download
        //     const url = window.URL.createObjectURL(
        //         new Blob([blob]),
        //     );
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute(
        //         'download',
        //         `smartsenseReport.${action.payload.apiParams.report_format}`,
        //     );
        //     document.body.appendChild(link);
        //     link.click();
        //     link.parentNode.removeChild(link);
        //     return { type: AppConstants.DOWNLOAD_REPORT_BY_ID_SUCCESS };
        // }).catch(error => {
        //     return {
        //         type: AppConstants.DOWNLOAD_REPORT_BY_ID_FAILURE,
        //         error: true,
        //         payload: error
        //     }
        // })
    })
);

export const standardReportDownload = action$ => action$.pipe(
    ofType(AppConstants.DOWNLOAD_REPORT_CUSTOM),
    mergeMap((action, a) => {
        let data = action.payload
        return axios.post(`${URL}/standard-reports/download/`, data, {
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
            }
        }).then((response) => {
            if (data.url == "report-data") {
                return { type: AppConstants.DOWNLOAD_REPORT_CUSTOM_SUCCESS, payload: response.data };
            } else {
                response.blob();
                return { type: AppConstants.DOWNLOAD_REPORT_CUSTOM_SUCCESS, payload: response.data };
            }

        }).then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                // `smartsenseReport.${data.report_format}`,
                `smartsenseReport.csv`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return { type: AppConstants.DOWNLOAD_REPORT_CUSTOM_SUCCESS };
        }).catch(error => {
            return {
                type: AppConstants.DOWNLOAD_REPORT_CUSTOM_FAILURE,
                error: true,
                payload: error
            }
        })
        // return fetch(`${URL}/standard-reports/download/`, {
        //     headers: {
        //         "Content-Type": "application/json; charset=UTF-8",
        //         Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
        //     },
        // }).then((response) => {
        //     response.blob();
        //     return { type: AppConstants.DOWNLOAD_REPORT_CUSTOM_SUCCESS };
        // }).then((blob) => {
        //     // Create blob link to download
        //     downloadReport(blob);
        //     return { type: AppConstants.DOWNLOAD_REPORT_CUSTOM_SUCCESS };
        // }).catch(error => {
        //     return {
        //         type: AppConstants.DOWNLOAD_REPORT_CUSTOM_FAILURE,
        //         error: true,
        //         payload: error
        //     }
        // })
    })
);

const getQueryString = (payload = {}) => {
    let searchQuery = ''
    Object.keys(payload).forEach((key, i, arr) => {
        if (i === 0) {
            searchQuery = '?';
        }
        searchQuery += (key + '=' + payload[key]);
        if (i !== (arr.length - 1)) {
            searchQuery += '&'
        }
    });

    return searchQuery;
}

export const reportEpics = {
    getAllReports,
    getAllReportLogs,
    checkAllReport,
    checkReport,
    checkReportLog,
    deleteReport,
    createReport,
    updateReport,
    downloadReport,
    // standardReportDownload
}