export const GET_ALL_CALCULATED_PARAMETER_LIST = 'GET_ALL_CALCULATED_PARAMETER_LIST';
export const GET_ALL_CALCULATED_PARAMETER_LIST_SUCCESS = 'GET_ALL_CALCULATED_PARAMETER_LIST_SUCCESS';
export const GET_ALL_CALCULATED_PARAMETER_LIST_FAILURE = 'GET_ALL_CALCULATED_PARAMETER_LIST_FAILURE';

export const CHECK_CALCULATED_PARAMETER = 'CHECK_CALCULATED_PARAMETER';
export const CHECK_ALL_CALCULATED_PARAMETERS = 'CHECK_ALL_CALCULATED_PARAMETERS';

export const CREATE_CALCULATED_PARAMETERS = 'CREATE_CALCULATED_PARAMETERS';
export const CREATE_CALCULATED_PARAMETERS_SUCCESS = 'CREATE_CALCULATED_PARAMETERS_SUCCESS';
export const CREATE_CALCULATED_PARAMETERS_FAILURE = 'CREATE_CALCULATED_PARAMETERS_FAILURE';
export const CREATE_CALCULATED_PARAMETER_RESET = 'CREATE_CALCULATED_PARAMETER_RESET';
export const CHECKDATA_CALCULATED_PARAMETER_RESET = 'CHECKDATA_CALCULATED_PARAMETER_RESET';

export const UPDATE_CALCULATED_PARAMETER = 'UPDATE_CALCULATED_PARAMETER';
export const UPDATE_CALCULATED_PARAMETER_SUCCESS = 'UPDATE_CALCULATED_PARAMETER_SUCCESS';
export const UPDATE_CALCULATED_PARAMETER_FAILURE = 'UPDATE_CALCULATED_PARAMETER_FAILURE';
export const UPDATE_CALCULATED_PARAMETER_RESET = 'UPDATE_CALCULATED_PARAMETER_RESET';

export const DELETE_CALCULATED_PARAMETER = 'DELETE_CALCULATED_PARAMETER';
export const DELETE_CALCULATED_PARAMETER_SUCCESS = 'DELETE_CALCULATED_PARAMETER_SUCCESS';
export const DELETE_CALCULATED_PARAMETER_FAILURE = 'DELETE_CALCULATED_PARAMETER_FAILURE';
export const DELETE_CALCULATED_PARAMETER_RESET = 'DELETE_CALCULATED_PARAMETER_RESET';

export const GET_ALL_CLIENT_LIST = 'GET_ALL_CLIENT_LIST';
export const GET_ALL_CLIENT_LIST_SUCCESS = 'GET_ALL_CLIENT_LIST_SUCCESS';
export const GET_ALL_CLIENT_LIST_FAILURE = 'GET_ALL_CLIENT_LIST_FAILURE';

export const GET_ALL_PLANT_LIST = 'GET_ALL_PLANT_LIST';
export const GET_ALL_PLANT_LIST_SUCCESS = 'GET_ALL_PLANT_LIST_SUCCESS';
export const GET_ALL_PLANT_LIST_FAILURE = 'GET_ALL_PLANT_LIST_FAILURE';


export const IS_OPEN_CALCULATED_PARAMETER_MODAL = 'IS_OPEN_CALCULATED_PARAMETER_MODAL';

export const VERIFY_TARGET_SENSOR_PARAMETER = 'VERIFY_TARGET_SENSOR_PARAMETER';
export const VERIFY_TARGET_SENSOR_PARAMETER_SUCCESS = 'VERIFY_TARGET_SENSOR_PARAMETER_SUCCESS';
export const VERIFY_TARGET_SENSOR_PARAMETER_FAILURE = 'VERIFY_TARGET_SENSOR_PARAMETER_FAILURE';
export const VERIFY_TARGET_SENSOR_PARAMETER_RESET = 'VERIFY_TARGET_SENSOR_PARAMETER_RESET';

