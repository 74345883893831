import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Button, RadioButtons } from '../../../../../../../common/components';

import Digibox1_ParametersConfig from '../Digitbox1/ParameterConfig';
import Digibox2_ParametersConfig from '../Digitbox2/ParameterConfig';
import Digibox3_ParametersConfig from '../Digitbox3/ParameterConfig';
import Digibox4_ParametersConfig from '../Digitbox4/ParameterConfig';

const digitboxTypeCollection = [
  'Digitbox 1',
  'Digitbox 2',
  'Digitbox 3',
  'Digitbox 4'
];

const DigitboxParameter = (props) => {
  const [initialized, setInitialized] = useState();

  const [digitboxTypeActive, setDigitboxTypeActive] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams.digitboxConfig;

      setDigitboxTypeActive(
        (conf && conf.value && conf.value.type) || digitboxTypeCollection[0]
      );

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const digitboxTypeActiveIndex = digitboxTypeCollection.findIndex((type) => {
    return type === digitboxTypeActive;
  });

  const appInputParamChangedHandle = (value) => {
    props.appInputParamChanged(value);
  };

  const digitboxPropsGet = (digitboxTypeIndex) => {
    const widgetParams =
      digitboxTypeCollection[digitboxTypeIndex] ===
      (props.widgetParams.digitboxConfig.value &&
        props.widgetParams.digitboxConfig.value.type)
        ? {
            ...props.widgetParams,
            digitboxConfig: {
              value: props.widgetParams.digitboxConfig.value
            }
          }
        : {
            ...props.widgetParams,
            digitboxConfig: { value: null }
          };

    return {
      widgetParams,
      type: digitboxTypeActive,
      appInputParamChanged: appInputParamChangedHandle
    };
  };

  const renderDigitbox = () => {
    switch (digitboxTypeActiveIndex) {
      case 0:
        return <Digibox1_ParametersConfig {...digitboxPropsGet(0)} />;

      case 1:
        return <Digibox2_ParametersConfig {...digitboxPropsGet(1)} />;

      case 2:
        return <Digibox3_ParametersConfig {...digitboxPropsGet(2)} />;

      case 3:
        return <Digibox4_ParametersConfig {...digitboxPropsGet(3)} />;
    }
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper dateTime-config-wrapper'>
        <Row>
          <Col md={12}>
            <div className='new-input-wrap'>
              <label className='label-text'>Digitbox Type Configuration</label>
              <div className='radio-buttons-wrap'>
                <RadioButtons
                  noOfButtons={4}
                  getButtonLableAtIndex={(index) => {
                    return digitboxTypeCollection[index];
                  }}
                  buttonClickAtIndex={(index) => {
                    setDigitboxTypeActive(digitboxTypeCollection[index]);
                  }}
                  active={digitboxTypeActiveIndex}
                />
              </div>
            </div>
          </Col>
        </Row>

        {renderDigitbox()}

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default DigitboxParameter;
