import React, { Fragment, useState } from 'react';
import { Row, Col } from 'reactstrap';

import usePlantSensorParameterCollection from '../common/usePlantSensorParameterCollection';
import plantSensorConfGet from '../common/plantSensorConfGet';
import { Input, Select } from '../../../../../../../common/components';
import collectionGet from '../common/collectionGet';

const SensorWiseConfiguration = (props) => {
  const [sensorConfCount, setSensorConfCount] = useState(
    props.sensorConfs.length || 1
  );

  const [
    ,
    ,
    sensorCollection,
    ,
    parameterCollection,
    parameterOptionCollections,
    ,
    onSensorChangeHandle,
    onParameterChangeHandle,
    sensorOptionCollection
  ] = usePlantSensorParameterCollection(props.sensorConfs);

  const renderSensorConf = (sensorConf, index, sensorConfs) => {
    return (
      <Row>
        <Col>
          <div className='new-input-wrap'>
            <label className='label-text'>Sensor</label>

            <Select
              options={sensorOptionCollection}
              isMulti={false}
              placeholder='Select Sensor'
              value={sensorCollection[index] || ''}
              onChange={(sensorOption) => {
                onSensorChangeHandle(sensorOption, index);
              }}
            />
          </div>
        </Col>

        <Col>
          <div className='new-input-wrap'>
            <label className='label-text'>Parameter</label>

            <Select
              options={parameterOptionCollections[index] || []}
              isMulti={false}
              placeholder='Select Parameter'
              value={parameterCollection[index] || ''}
              onChange={(parameterOption) => {
                onParameterChangeHandle(
                  parameterOption,
                  index,
                  (sensorConfs) => {
                    props.onSensorConfsChange(sensorConfs);
                  }
                );
              }}
            />
          </div>
        </Col>

        <Col>
          <div className='new-input-wrap'>
            <label className='label-text'>Max Value</label>

            <Input
              size='sm'
              label=''
              type='number'
              required={false}
              placeholder='Enter Max Value'
              value={sensorConf.sensors[0].max || ''}
              inputChanged={(max) => {
                const _sensorConfs = collectionGet(
                  {
                    ...sensorConf,
                    sensors: [
                      {
                        ...sensorConf.sensors[0],
                        max
                      }
                    ]
                  },
                  index,
                  sensorConfs
                );

                props.onSensorConfsChange(_sensorConfs);
              }}
            />
          </div>
        </Col>

        <Col>
          <div className='new-input-wrap'>
            <label className='label-text'>Min Value</label>

            <Input
              size='sm'
              label=''
              type='number'
              required={false}
              placeholder='Enter Min Value'
              value={sensorConf.sensors[0].min || ''}
              inputChanged={(min) => {
                const _sensorConfs = collectionGet(
                  {
                    ...sensorConf,
                    sensors: [
                      {
                        ...sensorConf.sensors[0],
                        min
                      }
                    ]
                  },
                  index,
                  sensorConfs
                );

                props.onSensorConfsChange(_sensorConfs);
              }}
            />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {Array.from({ length: sensorConfCount })
        .fill()
        .reduce((memo, _, index) => {
          const value =
            (props.sensorConfs && props.sensorConfs[index]) ||
            plantSensorConfGet(1);

          return [...memo, value];
        }, [])
        .map((sensorConf, index) => {
          return (
            <Fragment key={index}>
              {renderSensorConf(sensorConf, index, props.sensorConfs)}
            </Fragment>
          );
        })}

      <button
        className='icon-text-btn'
        onClick={() => {
          setSensorConfCount((sensorConfCount) => ++sensorConfCount);
        }}
      >
        <i className='fa fa-plus-circle' aria-hidden='true'></i>
        Add New Row
      </button>
    </div>
  );
};

export default SensorWiseConfiguration;
