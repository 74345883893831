import React from 'react';
import { Row, Col } from 'reactstrap';

import { Select, CheckBox } from '../../../../../../../common/components';
import sensorOptionCollectionGet from '../common/sensorOptionCollectionGet';
import parameterOptionCollectionGet from '../common/parameterOptionCollectionGet';

const LiveData = (props) => {
  return (
    <div>
      <p className='head-label-text mg-b-20'>Parameters & Sensors</p>

      <Row>
        <Col md={6}>
          <div className='new-input-wrap'>
            <div className='d-flex justify-content-between'>
              <label className='label-text'>Parameters</label>
            </div>

            <Select
              options={parameterOptionCollectionGet()}
              isMulti={false}
              placeholder='Select Parameters'
              value={
                props.paramsLiveData &&
                props.paramsLiveData[0] &&
                props.paramsLiveData[0][0] &&
                props.paramsLiveData[0][0].value &&
                parameterOptionCollectionGet().find(
                  ({ value }) => value === props.paramsLiveData[0][0].value
                )
              }
              onChange={({ label, value }) => {
                props.onParamsLiveDataChange([
                  [
                    {
                      label,
                      value
                    }
                  ],
                  ...props.paramsLiveData.slice(1)
                ]);
              }}
            />
          </div>
        </Col>

        <Col md={6}>
          <div className='new-input-wrap'>
            <div className='d-flex justify-content-between'>
              <label className='label-text'>Sensors *</label>

              <div className='new-input-wrap mg-b-0'>
                <div className='new-custom-checkbox inline-checkbox'>
                  <CheckBox
                    label={'Select All Sensors'}
                    size='sm'
                    checked={props.paramsLiveData && props.paramsLiveData[2]}
                    checkboxClicked={(checked) => {
                      props.onParamsLiveDataChange([
                        ...props.paramsLiveData.slice(0, 2),
                        checked
                      ]);
                    }}
                  />
                </div>
              </div>
            </div>

            <Select
              options={sensorOptionCollectionGet()}
              isMulti={false}
              placeholder='Select Sensors'
              value={
                props.paramsLiveData &&
                props.paramsLiveData[1] &&
                props.paramsLiveData[1][0] &&
                props.paramsLiveData[1][0].value &&
                sensorOptionCollectionGet().find(
                  ({ value }) => value === props.paramsLiveData[1][0].value
                )
              }
              onChange={({ label, value }) => {
                props.onParamsLiveDataChange([
                  props.paramsLiveData[0],
                  [
                    {
                      label,
                      value
                    }
                  ],
                  props.paramsLiveData[2]
                ]);
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LiveData;
