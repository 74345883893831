import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { httpMethodGetJSON } from '../../../../https';
import { URL } from '../../../../constants';
import {
  GET_ASSET_CBM_TOP_CONTRIBUTORS,
  GET_INSIGHTS_BY_PLANT_ID
} from './constant';

export const getAssetCbmTopContributors = (action$) => {
  return action$.pipe(
    ofType(GET_ASSET_CBM_TOP_CONTRIBUTORS),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/top-contributors/`);
    })
  );
};

export const getInsightsByPlantId = (action$) =>
  action$.pipe(
    ofType(GET_INSIGHTS_BY_PLANT_ID),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/plant-insights/`);
    })
  );
