import tagConfInputGet from './tagConfInputGet';

export default (tagConf, tagOptionsCollection) => {
  return (
    (tagConf.tag.value &&
      tagOptionsCollection[0].find(({ value: { tagId } = {} } = {}) => {
        return tagId === tagConf.tag.value.tagId;
      })) ||
    tagConfInputGet().tag
  );
};
