/**
 * All epics reqiured for widget 
 */
import Config from 'Config'
import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";
import {
  SENSOR_EVENT_OVERVIEW,
  EVENT_CONFIGURATION
} from './constant';
import { httpMethodGetJSON } from '../../https';

const URL = Config.api_url;
const MONITOR_URL = `${URL}/events/`;
const EVENT_CONFIG_URL = `${URL}/events/config/`;

export const getSensorEventOverview = (action$) =>
  action$.pipe(
    ofType(SENSOR_EVENT_OVERVIEW),
    mergeMap((action) => {
      return httpMethodGetJSON(action, MONITOR_URL);
    }),
  );

export const getConfigurationEvent = (action$) =>
  action$.pipe(
    ofType(EVENT_CONFIGURATION),
    mergeMap((action) => {
      return httpMethodGetJSON(action, EVENT_CONFIG_URL);
    }),
  );