import React, { useState, useEffect } from 'react';
import Col from 'reactstrap/lib/Col';
import Label from 'reactstrap/lib/Label';
import SelectDropDown from '../../../../../../common/components/Select';
import { getLocationsAndParameters } from '../../../../utils';


function ReadingTypeSelect(props) {

  let data = JSON.parse(localStorage.getItem('smartsense.sensors'));

  let foundSensor = data.find(sensor => sensor.sensorid === props.sensorids)

  let readingTypes = foundSensor ? getLocationsAndParameters() && 
  getLocationsAndParameters().find(l => l.value === foundSensor.mappedlocationid) || {types: []} :
  localStorage.getItem('smartsense.readingtypes') ? Object.values(JSON.parse(localStorage.getItem('smartsense.readingtypes'))).map(rt => { return { value: rt.readingtypeid, label: rt.readingtypename } }) : [];

  var [selectedParam, setSelectedParam] = useState({});
  // var [readingTypes, setReadingType] = useState();
  //var [paramValue, setParamValue] = useState("");

  useEffect(() => {
    if (props.value) {
      //setParamValue(props.value);
      let prm = (foundSensor ? readingTypes.types : readingTypes).filter(
        (p) => p.value === props.value.value
      );
      setSelectedParam(prm);
    }
  }, [props.appParams, props.value]);

  //useEffect(() => {
    //if (props.value) {
      ////setParamValue(props.value);
      //let prm = readingTypes.types.filter(p => p.value === props.value.value);
      //setSelectedParam(prm);
    //}
  //}, [props.value]);

  //useEffect(() => {
  //    let prm = readingTypes.filter(p => p.value === paramValue);
  //    setSelectedParam(prm);
  //}, [paramValue]);
  
  return <Col className="select-dropdown custom-size custom-select-parameter" xl={props.width ? props.width : 12} >
    <div>
      {/* <Label for={props.name} className='label'>{props.label}
        {props.required === true ? <span className='text-danger'> * </span> : null}
      </Label> */}
      <SelectDropDown
        name={props.name}
        value={selectedParam}
        onChange={(e) => {
          //if (props.isMulti) {
          props.onChange(e, false, props.name);
          setSelectedParam(e);
          //} else {
          //  props.onChange(e, false, props.name);
          //}
        }}
        options={foundSensor ? readingTypes.types : readingTypes}
        // options={readingTypes.types}
        placeholder={props.placeholder}
        isMulti={props.isMulti}
      />
    </div>
  </Col>

}

export default ReadingTypeSelect;