import queryString from 'query-string';

import {
  ENERGY_WIDGET_DATA,
  ENERGY_WIDGET_DATA_SUCCESS,
  ENERGY_WIDGET_DATA_FAILURE
} from './constant';

const INITIAL_STATE = {
  data: null,
  success: undefined,
  loading: false,
  error: false
};

export function getEnergyData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ENERGY_WIDGET_DATA:
      return {
        ...state,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]:
            (action.payload.index &&
              state.data &&
              state.data[action.payload.widgetId]) ||
            {}
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]:
            (action.payload.index &&
              state.error &&
              state.error[action.payload.widgetId]) ||
            false
        }
      };

    case ENERGY_WIDGET_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...state.data[action.param.widgetId],
            [action.param.index]: {
              ...action.param,
              ...(() => {
                switch (true) {
                  case !!action.param.isComputed:
                    return {
                      value: action.response.response.data
                    };

                  default:
                    return action.response[0];
                }
              })()
            }
          }
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        }
      };

    case ENERGY_WIDGET_DATA_FAILURE:
      return (() => {
        const widgetId = (() => {
          const url = queryString.parse(
            action.payload.request.url.replace(/.*\/\?/, '')
          );

          const body =
            action.payload.request.body &&
            JSON.parse(action.payload.request.body);

          return body ? body.widgetId : url.widgetId;
        })();

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
}
