import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import { httpMethodGetJSON } from '../../../../https';
import { URL } from '../../../../constants';

import {
  GET_INSIGHT_TIMELINE_ACTIVITY_DATA,
  GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA,
  GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA,
  GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA,
  GET_INSIGHT_TIMELINE_INSIGHT_DATA,
  GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA
} from './constant';


export const getInsightTimelineAssetHistoryData = (action$) =>
  action$.pipe(
    ofType(GET_INSIGHT_TIMELINE_ASSET_HISTORY_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/assets/timelines/`);
    })
  );

export const getInsightTimelineActivityData = (action$) => {
  return action$.pipe(
    ofType(GET_INSIGHT_TIMELINE_ACTIVITY_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/events`);
    })
  );
};

export const getInsightTimelineCbmAssetData = (action$) =>
  action$.pipe(
    ofType(GET_INSIGHT_TIMELINE_CBM_ASSETS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/asset-names/stats?page_size=100&page_no=1`
      );
    })
  );

export const getInsightTimelineAlertLogData = (action$) => {
  return action$.pipe(
    ofType(GET_INSIGHT_TIMELINE_ALERT_LOGS_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/alert-logs/`);
    })
  );
};

export const getInsightTimelineInsightData = (action$) => {
  return action$.pipe(
    ofType(GET_INSIGHT_TIMELINE_INSIGHT_DATA),
    mergeMap((action) => {
      return httpMethodGetJSON(action, `${URL}/asset-cbm/assets/insights/`);
    })
  );
};

export const getInsightTimelineAssetsMinData = (action$) => {
  return action$.pipe(
    ofType(GET_INSIGHT_TIMELINE_ASSETS_MIN_DATA),
    mergeMap((action) => httpMethodGetJSON(action, `${URL}/asset-cbm/asset-names/assets-min-info`))
  );
}