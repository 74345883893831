import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import CheckBox from '../CheckBox';

export default class DropDown extends React.Component {
    constructor(props) {
        super(props);

        let groupActiveKey = [];

        if (Array.isArray(this.props.active) && Array.isArray(this.props.active[0])) {
            this.props.active.forEach((elem) => {
                groupActiveKey.push(elem.join('_'));
            });
        }

        this.state = {
            selected: groupActiveKey,
            mulSelected: groupActiveKey,
        };

        this.onInputChange = this.onInputChange.bind(this);
    }

    onInputChange(e, index, groupIndex) {
        if (this.props.isMultipleSelection) {
            let selectedItems = this.state.mulSelected;

            let changedItem = groupIndex + '_' + index;
            let index1 = selectedItems.indexOf(changedItem);
            if (index1 < 0) {
                selectedItems.push(changedItem);
            } else {
                selectedItems.splice(index, 1);
            }

            this.setState({
                mulSelected: selectedItems,
            });
        } else {
            // single option in current group
            let selection = this.state.selected;

            if (selection.indexOf(groupIndex + '_' + index) !== -1) {
                selection = [];
            } else {
                selection[groupIndex] = groupIndex + '_' + index;
            }

            this.setState({
                selected: selection,
            });
        }

        this.props.onClickCallback(index, groupIndex);
    }

    render() {
        let id = Math.random().toString();

        let items = [];

        let noOfGroups = this.props.noOfGroups ? this.props.noOfGroups : 1;

        for (let i = 0; i < noOfGroups; i++) {
            let gropHeader = this.props.getGroupHeaderAtIndex ? this.props.getGroupHeaderAtIndex(i) : '';

            if (gropHeader) {
                items.push(
                    <DropdownItem key={i} className="groupHeader" onClick={(e) => this.onInputChange(e, i)}>
                        <b>{gropHeader}</b>
                    </DropdownItem>,
                );

                items.push(<DropdownItem key={i + 'divider'} divider />);
            }
            // options
            let options = this.props.getOptionsAtGroup ? this.props.getOptionsAtGroup(i) : [];

            options.map((a, j) => {
                items.push(
                    <DropdownItem
                        key={i + '_' + j}
                        onClick={(e) => this.onInputChange(e, j, i)}
                        toggle={this.props.shouldToggleOnclick}
                        active={
                            this.props.isMultipleSelection
                                ? this.state.mulSelected.includes(i + '_' + j)
                                : this.state.selected.includes(i + '_' + j)
                        }
                    >
                        {this.props.hideCheckBox ? (
                            a[this.props.value]
                        ) : (
                            <CheckBox
                                checkboxClicked={() => {}}
                                checked={
                                    this.props.isMultipleSelection
                                        ? this.state.mulSelected.includes(i + '_' + j)
                                        : this.state.selected.includes(i + '_' + j)
                                }
                                label={a[this.props.value]}
                                size="sm"
                            />
                        )}
                    </DropdownItem>,
                );
            });
        }

        return (
            <React.Fragment>
                <UncontrolledDropdown>
                    <DropdownToggle nav data-tip={this.props.tooltip} data-for={id}>
                        <span className="dropdowntext">{this.props.text}</span>
                        <i className={this.props.icon} aria-hidden="true" />
                    </DropdownToggle>
                    <DropdownMenu right={this.props.right !== false}>{items}</DropdownMenu>
                </UncontrolledDropdown>
                {/* <ToolTip id = {id} place = "bottom" type = "light" effect="solid"/> */}
            </React.Fragment>
        );
    }
}
