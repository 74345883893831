import React from 'react';
import clsx from 'clsx';
import PropTypes from "prop-types";

const propTypes = {
    type: PropTypes.string,
    isPillType: PropTypes.bool,
    innerContent: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
    className: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

const defaultProps = {
    innerContent: '',
    type: 'secondary',
    isPillType: false,
    className: ''
};

const Badge = ({type, isPillType, importance, innerContent, className}) => {
    const classSplit = className.split(' ').filter((value, index, self) => {
        return value.trim().length > 0 && self.indexOf(value) === index;
    });

    // basic bootstrap types like primary sec... will be supprted
    // but if user passes smartsense type like below then it will over ride all bootstrap typess
    const badgeTypes = [
        'normal',
        'important',
        'superImportant',
    ];
    return (
        <span className={clsx('SSBadges', 'badge', ...classSplit, {
            'badge-pill': isPillType,
            [`${type}`]: type.length > 0,
            [badgeTypes[importance]]: badgeTypes[importance]
        })}>
            {innerContent}
        </span>
    );
}

Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;

export default Badge;
