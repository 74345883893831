export const GET_ASSET_CBM_INSIGHT_OCCURRENCE =
  'GET_ASSET_CBM_INSIGHT_OCCURRENCE';
export const GET_ASSET_CBM_INSIGHT_OCCURRENCE_SUCCESS =
  'GET_ASSET_CBM_INSIGHT_OCCURRENCE_SUCCESS';
export const GET_ASSET_CBM_INSIGHT_OCCURRENCE_FAILURE =
  'GET_ASSET_CBM_INSIGHT_OCCURRENCE_FAILURE';

export const GET_NETWORK_INSIGHT_OCCURRENCE = 'GET_NETWORK_INSIGHT_OCCURRENCE';
export const GET_NETWORK_INSIGHT_OCCURRENCE_SUCCESS =
  'GET_NETWORK_INSIGHT_OCCURRENCE_SUCCESS';
export const GET_NETWORK_INSIGHT_OCCURRENCE_FAILURE =
  'GET_NETWORK_INSIGHT_OCCURRENCE_FAILURE';
