import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    GET_ASSETS_DETAIL_DATA,
    GET_ASSETS_DETAIL_DATA_SUCCESS,
    GET_ASSETS_DETAIL_DATA_FAILURE,
    GET_ASSETS_LIST_DATA,
    GET_ASSETS_LIST_DATA_SUCCESS,
    GET_ASSETS_LIST_DATA_FAILURE,
    GET_ASSET_ACTION_DATA,
    GET_ASSET_ACTION_DATA_SUCCESS,
    GET_ASSET_ACTION_DATA_FAILURE,
    POST_ASSET_ACTION_DATA,
    POST_ASSET_ACTION_DATA_SUCCESS,
    POST_ASSET_ACTION_DATA_FAILURE,
    GET_ASSETS_ALERT_DATA,
    GET_ASSETS_ALERT_DATA_SUCCESS,
    GET_ASSETS_ALERT_DATA_FAILURE,
    POST_ALERT_ACTION_DATA,
    POST_ALERT_ACTION_DATA_SUCCESS,
    POST_ALERT_ACTION_DATA_FAILURE,
    POST_USER_ACTIVITY_ACTION_DATA,
    POST_USER_ACTIVITY_ACTION_DATA_SUCCESS,
    POST_USER_ACTIVITY_ACTION_DATA_FAILURE,
    GET_ASSETS_OPEN_INSIGHT_DATA,
    GET_ASSETS_OPEN_INSIGHT_DATA_SUCCESS,
    GET_ASSETS_OPEN_INSIGHT_DATA_FAILURE,
    GET_ASSETS_HEALTH_INDEX_DATA,
    GET_ASSETS_HEALTH_INDEX_DATA_SUCCESS,
    GET_ASSETS_HEALTH_INDEX_DATA_FAILURE,
    AssetUrl,
    ERROR,
} from '../constants';

const getAssetDetailsDataSuccess = (payload) => ({ type: GET_ASSETS_DETAIL_DATA_SUCCESS, payload });
const getAssetListDataSuccess = (payload) => ({ type: GET_ASSETS_LIST_DATA_SUCCESS, payload });
const getAssetActionDataSuccess = (payload) => ({ type: GET_ASSET_ACTION_DATA_SUCCESS, payload });
const postAssetActionDataSuccess = (payload) => ({ type: POST_ASSET_ACTION_DATA_SUCCESS, payload });
const postAlertActionDataSuccess = (payload) => ({ type: POST_ALERT_ACTION_DATA_SUCCESS, payload });
const postUserActivityActionDataSuccess = (payload) => ({
    type: POST_USER_ACTIVITY_ACTION_DATA_SUCCESS,
    payload,
});
const getAssetOpenInsightsDataSuccess = (payload) => ({
    type: GET_ASSETS_OPEN_INSIGHT_DATA_SUCCESS,
    payload,
});
const getHealthIndexDataSuccess = (payload) => ({ type: GET_ASSETS_HEALTH_INDEX_DATA_SUCCESS, payload });

export const getAssetDetailsDataEpic = (action$) =>
    action$.pipe(
        ofType(GET_ASSETS_DETAIL_DATA),
        mergeMap((action) =>
            ajax
                .getJSON(
                    `${AssetUrl}/api/v2/asset_insights/${action.payload.assetName}/${
                        action.payload.assetId
                    }/?from_date=${encodeURIComponent(
                        action.payload.from_date,
                    )}&to_date=${encodeURIComponent(action.payload.to_date)}`,
                    { Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}` },
                )
                .pipe(
                    map((response) => getAssetDetailsDataSuccess(response)),
                    catchError((error) =>
                        of(
                            { type: GET_ASSETS_DETAIL_DATA_FAILURE, payload: [] },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );

export const getAssetListDataEpic = (action$) =>
    action$.pipe(
        ofType(GET_ASSETS_LIST_DATA),
        mergeMap((action) =>
            ajax
                .getJSON(
                    `${AssetUrl}/api/v2/asset_insights/timeline/?page_no=${action.payload.pageNo}&page_size=${
                        action.payload.pageSize
                    }&asset_type=${action.payload.assetName}&event_type=${
                        action.payload.eventType
                    }&asset_id=${action.payload.assetId}&from_date=${encodeURIComponent(
                        action.payload.from_date,
                    )}&to_date=${encodeURIComponent(action.payload.to_date)}&search${
                        action.payload.search && action.payload.search.length > 0
                            ? '=' + encodeURIComponent(action.payload.search)
                            : ''
                    }`,
                    { Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}` },
                )
                .pipe(
                    map((response) => getAssetListDataSuccess(response)),
                    catchError((error) =>
                        of(
                            { type: GET_ASSETS_LIST_DATA_FAILURE, payload: [] },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );

export const getAssetActionDataEpic = (action$) =>
    action$.pipe(
        ofType(GET_ASSET_ACTION_DATA),
        mergeMap((action) =>
            ajax
                .getJSON(`${AssetUrl}/api/v2/asset_insights/actions/${action.payload.assetName}`, {
                    Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
                })
                .pipe(
                    map((response) => getAssetActionDataSuccess(response)),
                    catchError((error) =>
                        of(
                            { type: GET_ASSET_ACTION_DATA_FAILURE, payload: [] },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );

export const postAssetActionDataEpic = (action$) =>
    action$.pipe(
        ofType(POST_ASSET_ACTION_DATA),
        mergeMap((action) =>
            ajax
                .post(
                    `${AssetUrl}/api/v2/asset_insights/actions/${action.payload.assetName}/`,
                    action.payload,
                    { Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}` },
                )
                .pipe(
                    map((response) => postAssetActionDataSuccess(response)),
                    catchError((error) =>
                        of(
                            { type: POST_ASSET_ACTION_DATA_FAILURE, payload: [] },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );

const getAssetAlertDataSuccess = (payload) => ({ type: GET_ASSETS_ALERT_DATA_SUCCESS, payload });

export const getAssetAlertDataEpic = (action$) =>
    action$.pipe(
        ofType(GET_ASSETS_ALERT_DATA),
        mergeMap((action) =>
            ajax
                .getJSON(
                    `${AssetUrl}/api/v2/asset_insights/gen_alert/${
                        action.payload.assetName
                    }/?from_date=${encodeURIComponent(
                        action.payload.from_date,
                    )}&to_date=${encodeURIComponent(action.payload.to_date)}&asset_id=${
                        action.payload.assetId
                    }&page_no=${action.payload.pageNo}&page_size=${
                        action.payload.pageSize
                    }&search=${encodeURIComponent(action.payload.q)}`,
                    { Authorization: `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}` },
                )
                .pipe(
                    map((response) => getAssetAlertDataSuccess(response)),
                    catchError((error) =>
                        of(
                            { type: GET_ASSETS_ALERT_DATA_FAILURE, payload: [] },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );

export const postAlertActionDataEpic = (action$) => {
    return action$.pipe(
        ofType(POST_ALERT_ACTION_DATA),
        mergeMap((action) =>
            ajax
                .put(
                    `${AssetUrl}/api/v2/asset_insights/gen_alert/${action.payload.assetName}/`,
                    action.payload,
                    { Authorization: `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}` },
                )
                .pipe(
                    map((response) => postAlertActionDataSuccess(response)),
                    catchError((error) =>
                        of(
                            { type: POST_ALERT_ACTION_DATA_FAILURE, payload: [] },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );
};

export const postUserActivityActionDataEpic = (action$) => {
    return action$.pipe(
        ofType(POST_USER_ACTIVITY_ACTION_DATA),
        mergeMap((action) =>
            ajax
                .post(`${AssetUrl}/api/v2/asset_insights/activity_log/`, action.payload, {
                    Authorization: `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
                })
                .pipe(
                    map((response) => postUserActivityActionDataSuccess(response)),
                    catchError((error) =>
                        of(
                            { type: POST_USER_ACTIVITY_ACTION_DATA_FAILURE, payload: [] },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );
};

export const getAssetOpenInsightsDataEpic = (action$) =>
    action$.pipe(
        ofType(GET_ASSETS_OPEN_INSIGHT_DATA),
        mergeMap((action) =>
            ajax
                .getJSON(
                    `${AssetUrl}/api/v2/asset_insights/open_insight/${action.payload.assetName}/${
                        action.payload.assetId
                    }/?from_date=${encodeURIComponent(
                        action.payload.from_date,
                    )}&to_date=${encodeURIComponent(action.payload.to_date)}`,
                    { Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}` },
                )
                .pipe(
                    map((response) => getAssetOpenInsightsDataSuccess(response)),
                    catchError((error) =>
                        of(
                            { type: GET_ASSETS_OPEN_INSIGHT_DATA_FAILURE, payload: [] },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );

export const getHealthIndexDataEpic = (action$) =>
    action$.pipe(
        ofType(GET_ASSETS_HEALTH_INDEX_DATA),
        mergeMap((action) =>
            ajax
                .getJSON(
                    `${AssetUrl}/api/v2/reading/reading_series/?agg_date_offset=0&fromdate=${encodeURIComponent(
                        action.payload.from_date,
                    )}&todate=${encodeURIComponent(action.payload.to_date)}&readingtypeid=${
                        action.payload.readingtypeid
                    }&memberId=${action.payload.memberId}&sensorid=${action.payload.sensorId}&grain=${
                        action.payload.grain
                    }`,
                    { Authorization: `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}` },
                )
                .pipe(
                    map((response) => getHealthIndexDataSuccess(response)),
                    catchError((error) =>
                        of(
                            { type: GET_ASSETS_HEALTH_INDEX_DATA_FAILURE, payload: [] },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );
