import React, { Fragment, useState } from 'react';
import { Row, Col } from 'reactstrap';

import usePlantSensorParameterCollection from '../common/usePlantSensorParameterCollection';
import plantSensorConfGet from '../common/plantSensorConfGet';
import collectionGet from '../common/collectionGet';
import { Input, Select } from '../../../../../../../common/components';

const PlantWiseConfiguration = (props) => {
  const [plantConfCount, setPlantConfCount] = useState(
    props.plantConfs.length || 1
  );

  const [
    plantCollection,
    plantOptionCollection,
    sensorCollection,
    sensorOptionCollections,
    parameterCollection,
    parameterOptionCollections,
    onPlantChangeHandle,
    onSensorChangeHandle,
    onParameterChangeHandle
  ] = usePlantSensorParameterCollection(props.plantConfs);

  const renderPlantConf = (plantConf, index, plantConfs) => {
    return (
      <Row>
        <Col>
          <div className='new-input-wrap'>
            <label className='label-text'>Plant</label>

            <Select
              options={plantOptionCollection}
              isMulti={false}
              placeholder='Select Plant'
              value={plantCollection[index] || ''}
              onChange={(plantOption) => {
                onPlantChangeHandle(plantOption, index);
              }}
            />
          </div>
        </Col>

        <Col>
          <div className='new-input-wrap'>
            <label className='label-text'>Sensor</label>

            <Select
              options={sensorOptionCollections[index] || []}
              isMulti={false}
              placeholder='Select Sensor'
              value={sensorCollection[index] || ''}
              onChange={(sensorOption) => {
                onSensorChangeHandle(sensorOption, index);
              }}
            />
          </div>
        </Col>

        <Col>
          <div className='new-input-wrap'>
            <label className='label-text'>Parameter</label>

            <Select
              options={parameterOptionCollections[index] || []}
              isMulti={false}
              placeholder='Select Parameter'
              value={parameterCollection[index] || ''}
              onChange={(parameterOption) => {
                onParameterChangeHandle(
                  parameterOption,
                  index,
                  (plantConfCollection) => {
                    props.onPlantConfsChange(plantConfCollection);
                  }
                );
              }}
            />
          </div>
        </Col>

        <Col>
          <div className='new-input-wrap'>
            <label className='label-text'>Max Value</label>

            <Input
              size='sm'
              label=''
              type='number'
              required={false}
              placeholder='Enter Max Value'
              value={plantConf.sensors[0].readingtypeid[0].max || ''}
              inputChanged={(max) => {
                const _plantConf = collectionGet(
                  {
                    ...plantConf,
                    sensors: [
                      {
                        ...plantConf.sensors[0],
                        readingtypeid: [
                          {
                            ...plantConf.sensors[0].readingtypeid[0],
                            max
                          }
                        ]
                      }
                    ]
                  },
                  index,
                  plantConfs
                );

                props.onPlantConfsChange(_plantConf);
              }}
            />
          </div>
        </Col>

        <Col>
          <div className='new-input-wrap'>
            <label className='label-text'>Min Value</label>

            <Input
              size='sm'
              label=''
              type='number'
              required={false}
              placeholder='Enter Min Value'
              value={plantConf.sensors[0].readingtypeid[0].min || ''}
              inputChanged={(min) => {
                const _plantConf = collectionGet(
                  {
                    ...plantConf,
                    sensors: [
                      {
                        ...plantConf.sensors[0],
                        readingtypeid: [
                          {
                            ...plantConf.sensors[0].readingtypeid[0],
                            min
                          }
                        ]
                      }
                    ]
                  },
                  index,
                  plantConfs
                );

                props.onPlantConfsChange(_plantConf);
              }}
            />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {Array.from({ length: plantConfCount })
        .fill()
        .reduce((memo, _, index) => {
          const value =
            (props.plantConfs && props.plantConfs[index]) ||
            plantSensorConfGet(0);

          return [...memo, value];
        }, [])
        .map((plantConf, index) => {
          return (
            <Fragment key={index}>
              {renderPlantConf(plantConf, index, props.plantConfs)}
            </Fragment>
          );
        })}

      <button
        className='icon-text-btn'
        onClick={() => {
          setPlantConfCount((plantConfCount) => ++plantConfCount);
        }}
      >
        <i className='fa fa-plus-circle' aria-hidden='true'></i>
        Add New Row
      </button>
    </div>
  );
};

export default PlantWiseConfiguration;
