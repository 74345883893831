import React from 'react';

import { Select } from '../../../../../../../../common/components';
import './index.scss';

const SensorParameterSelect = (props) => {
  return (
    <div className='SensorParameterSelect'>
      <Select
        isMulti={false}
        hideHover={true}
        placeholder={`Select ${props.type
          .split('')
          .reduce((memo, letter, index) => {
            const value = !index ? letter.toUpperCase() : letter;

            return [...memo, value];
          }, [])
          .join('')}`}
        options={props.options}
        value={props.value}
        onChange={props.onChange}
        isDisabled={props.isDisabled}
        menuPortalTarget={props.menuPortalTarget}
      />
    </div>
  );
};

export default SensorParameterSelect;