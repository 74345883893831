import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { uploadImageConfiguration } from '../../../Widgets/action';
import valueGet from '../common/valueGet';
import {
  RadioButtons,
  Button,
  CheckBox
} from '../../../../../../../common/components';
import imagePlaceholder from '../../../../../../../../assets/images/image_placeholder.svg';

const filterTypeCollection = ['Show Default Image', 'Custom Image'];

const OthersConfig = (_props) => {
  const { appInputParamChanged, ...props } = _props;

  const [initialized, setInitialized] = useState(false);

  var [filterType, setFilterType] = useState();

  const [url, setUrl] = useState();

  const [file, setFile] = useState();

  const [error, setError] = useState();

  const [showAssetImage, setShowAssetImage] = useState();

  const appInputParamChangedHandle = useCallback(
    (url, uploadImage, appInputParamChanged) => {
      switch (true) {
        case url:
          return null;

        case uploadImage.error:
          return setError(true);

        case uploadImage.success:
          setError(false);

          setUrl(uploadImage.data);

          appInputParamChanged({
            url: valueGet(uploadImage.data)
          });
      }
    },
    []
  );

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      setFilterType(
        (conf.filterType &&
          conf.filterType.value &&
          conf.filterType.value.filterType) ||
          0
      );

      setUrl(conf.url && conf.url.value);

      setShowAssetImage(conf.show_asset_image && conf.show_asset_image.value);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  useEffect(() => {
    appInputParamChangedHandle(url, props.uploadImage, appInputParamChanged);
  }, [
    url,
    props.uploadImage,
    appInputParamChanged,
    appInputParamChangedHandle
  ]);

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={12}>
            <div className='new-input-wrap'>
              <div className='new-custom-checkbox'>
                <CheckBox
                  label={'Should Show Image'}
                  size='sm'
                  checked={showAssetImage}
                  checkboxClicked={(showAssetImage) => {
                    setShowAssetImage(showAssetImage);

                    appInputParamChanged({
                      show_asset_image: valueGet(showAssetImage)
                    });
                  }}
                />
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className='new-input-wrap'>
              <label className='label-text'>Filter Type</label>
              <div className='radio-buttons-wrap'>
                <RadioButtons
                  noOfButtons={filterTypeCollection.length}
                  getButtonLableAtIndex={(index) => {
                    return filterTypeCollection[index];
                  }}
                  buttonClickAtIndex={(filterType) => {
                    setFilterType(filterType);

                    appInputParamChanged({
                      filterType: valueGet({ filterType })
                    });
                  }}
                  active={filterType}
                />
              </div>
            </div>
          </Col>

          {!!filterType && (
            <Col md={12}>
              <Row>
                <Col md={7}>
                  <p className='head-label-text'>Upload Image</p>

                  <div className='fileInput-name-wrap'>
                    <div className='new-input-wrap'>
                      <div>
                        <label className='file-input-button'>
                          Choose File
                          <input
                            type='file'
                            accept='image/*'
                            onChange={(event) => {
                              setUrl(null);

                              setFile(event.target.files[0]);
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='filename-uploadBtn'>
                      <p>{file && file.name ? file.name : 'No file Chosen'}</p>
                      <button
                        className='icon-button'
                        onClick={() => {
                          props.uploadImageConfiguration({ file });
                        }}
                      >
                        <i className='fa fa-upload' aria-hidden='true'></i>
                      </button>
                    </div>
                  </div>
                </Col>

                <Col md={5}>
                  <div className='img-holder text-right'>
                    {error ? (
                      <span>Image Upload Failed</span>
                    ) : (
                      <img
                        src={url && url.url ? url.url : imagePlaceholder}
                        style={{
                          maxHeight: '240px',
                          objectFit: 'conver'
                        }}
                        alt=''
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return initialized && <Fragment>{renderFn()}</Fragment>;
};

const mapStateToProps = (state) => {
  return {
    uploadImage: state.uploadImageConfiguration
  };
};

export default connect(mapStateToProps, {
  uploadImageConfiguration
})(OthersConfig);
