import React, { useRef, useState, useEffect, Fragment } from 'react';

import useReorder from './useReorder';
import { LoadingPlain } from '../../../common/components/';
import DashboardOption from './DashboardOption';
//import SidebarHorizontalOption from './SidebarHorizontalOption';

const __Sidebar = (props) => {
  const reorder = useReorder(props.data, props.onSetData);

  const dashboardListRef = useRef();

  const [selectedId, setSelectedId] = useState();

  const [dashboardOptionDefinition, setDashboardOptionDefinition] = useState({
    show: false,
    top: 0,
    left: 0
  });

  const [horizontalScrollLeftShow, setHorizontalScrollLeftShow] =
    useState(false);

  const [horizontalScrollRightShow, setHorizontalScrollRightShow] =
    useState(false);

  //const [sidebarOptionDefinition, setSidebarOptionDefinition] = useState({
  //show: false
  //});

  //useEffect(() => {
  //setSidebarOptionDefinition((sidebarOptionDefinition) => {
  //return props.sidebarRouteTrigger && sidebarOptionDefinition.show
  //? { ...sidebarOptionDefinition, show: false }
  //: sidebarOptionDefinition;
  //});
  //}, [props.sidebarRouteTrigger]);

  useEffect(() => {
    const dashboardListRefCurrent = dashboardListRef.current;

    props.data &&
      new ResizeObserver(() => {
        setHorizontalScrollRightShow(
          dashboardListRefCurrent.scrollWidth >
            dashboardListRefCurrent.clientWidth
        );
      }).observe(dashboardListRefCurrent);

    const onScrollHandle = () => {
      setHorizontalScrollLeftShow(!!dashboardListRefCurrent.scrollLeft);
    };

    dashboardListRefCurrent &&
      dashboardListRefCurrent.addEventListener('scroll', onScrollHandle);

    return () => {
      dashboardListRefCurrent.removeEventListener('scroll', onScrollHandle);
    };
  }, [props.data]);

  useEffect(() => {
    props.sidebarName &&
      props.data &&
      props.selectedId &&
      props.selectedId !== selectedId &&
      (() => {
        const dashboardListRefCurrent = dashboardListRef.current;

        const index =
          props.selectedId && props.data.length
            ? props.data.findIndex(
                ({ id }) => id.toString() === props.selectedId
              )
            : -1;

        index >= 0 &&
          dashboardListRefCurrent.scrollTo({
            left:
              dashboardListRefCurrent.childNodes[index].getBoundingClientRect()
                .left -
              dashboardListRefCurrent.childNodes[0].getBoundingClientRect()
                .left,
            behavior: 'smooth'
          });
      })();
  }, [props.sidebarName, props.data, props.selectedId, selectedId]);

  return (
    <div className='_sidebarWrap-horizontal'>
      <div className='dashboard-list-container'>
        <div className='top-bar-left'>
          <div ref={dashboardListRef} className='dashboard-list'>
            {props.data &&
              props.data.length > 0 &&
              props.data.map((dashboard, index) => {
                return (
                  <div
                    key={index}
                    tabIndex={index}
                    className={`dashboard-list-item ${
                      props.selectedId &&
                      props.selectedId == dashboard.id &&
                      '_active'
                    }`}
                    onClick={() => {
                      setSelectedId(dashboard.id);

                      setDashboardOptionDefinition({ show: false });

                      props.onSetSelectedId(dashboard.id, props.data);

                      props.onNavigate(dashboard);
                    }}
                    draggable={props.reorderableShow}
                    {...reorder}
                  >
                    <p>
                      {!props.loading ? (
                        dashboard.name ||
                        dashboard.dashboard_name || <span>&nbsp;</span>
                      ) : (
                        <LoadingPlain />
                      )}
                    </p>

                    {!dashboard.status && (
                      <div className='item-options'>
                        <div className='option-wrap'>
                          {props.selectedId == dashboard.id &&
                            !props.namedServiceDashboardIdCollection.includes(
                              props.selectedId
                            ) && (
                              <Fragment>
                                <a
                                  href='#'
                                  className='option-toggle'
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();

                                    setDashboardOptionDefinition({
                                      show: true,
                                      left:
                                        event.currentTarget.getBoundingClientRect()
                                          .right + 25
                                    });

                                    props.onSetShowDashboardOption(
                                      !props.showDashboardOption
                                    );

                                    props.onSetShowDashboardSubOption(false);
                                  }}
                                >
                                  <i
                                    className='fas fa-ellipsis-v'
                                    aria-hidden='true'
                                  ></i>
                                </a>

                                <div
                                  className={`dropdown-wrapper ${
                                    dashboardOptionDefinition.show ? 'show' : ''
                                  }`}
                                  style={{
                                    left: dashboardOptionDefinition.left
                                  }}
                                >
                                  <DashboardOption
                                    dashboard={dashboard}
                                    selectedId={props.selectedId}
                                    serviceIdActive={props.serviceIdActive}
                                    showDashboardOption={
                                      props.showDashboardOption
                                    }
                                    showDashboardSubOption={
                                      props.showDashboardSubOption
                                    }
                                    _onSetSidebarShow={props._onSetSidebarShow}
                                    onSidebarShowToggle={
                                      props.onSidebarShowToggle
                                    }
                                    onSetShowDashboardForm={
                                      props.onSetShowDashboardForm
                                    }
                                    onSetUpdate={props.onSetUpdate}
                                    onSetDashboardInput={
                                      props.onSetDashboardInput
                                    }
                                    onSetShowDashboardSubOption={
                                      props.onSetShowDashboardSubOption
                                    }
                                    onDashboardOptionClose={
                                      props.onDashboardOptionClose
                                    }
                                    onDeleteDashboard={props.onDeleteDashboard}
                                  />
                                </div>
                              </Fragment>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <div className='top-bar-right'>
          <div
            className={`sidebar-horizontal-scroll-left ${
              horizontalScrollLeftShow ? 'show' : ''
            }`}
          >
            <a
              href='#'
              data-title='Previous'
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                dashboardListRef.current.scrollBy({
                  left: -200,
                  behavior: 'smooth'
                });
              }}
            >
              <i className='fa fa-chevron-left'></i>
            </a>
          </div>
          <div
            className={`sidebar-horizontal-scroll-right ${
              horizontalScrollRightShow ? 'show' : ''
            }`}
          >
            <a
              href='#'
              data-title='Next'
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                dashboardListRef.current.scrollBy({
                  left: 200,
                  behavior: 'smooth'
                });
              }}
            >
              <i className='fa fa-chevron-right'></i>
            </a>
          </div>
          {/* <div className='sidebar-option-trigger'>
            <a
              href='#'
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                setSidebarOptionDefinition((sidebarOptionDefinition) => ({
                  ...sidebarOptionDefinition,
                  show: !sidebarOptionDefinition.show
                }));
              }}
            >
              <i className='fas fa-ellipsis-v'></i>
            </a>

            <div
              className={`dropdown-wrapper ${
                sidebarOptionDefinition.show ? 'show' : ''
              }`}
            >
              <SidebarHorizontalOption
                serviceIdActive={props.serviceIdActive}
                data={props.data}
                reorderableShow={props.reorderableShow}
                isDashboardHorizontal={true}
                onDashboardOptionClose={() => {
                  setSidebarOptionDefinition({ show: false });

                  props.onDashboardOptionClose();
                }}
                onSetShowDashboardForm={props.onSetShowDashboardForm}
                onSetReorderableShow={(reorderableShow) => {
                  setSidebarOptionDefinition({ show: reorderableShow });

                  props.onSetReorderableShow(reorderableShow);
                }}
                onSequenceCreate={props.onSequenceCreate}
                onSetReorderableSuccessToastShow={
                  props.onSetReorderableSuccessToastShow
                }
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default __Sidebar;
