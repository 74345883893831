import queryString from 'query-string';

import {
  GET_ALL_DASHBOARD,
  GET_ALL_DASHBOARD_SUCCESS,
  GET_ALL_DASHBOARD_FAILURE,
  GET_SINGLE_DASHBOARD,
  GET_SINGLE_DASHBOARD_SUCCESS,
  GET_SINGLE_DASHBOARD_FAILURE,
  GET_ALL_WIDGET,
  GET_ALL_WIDGET_SUCCESS,
  GET_ALL_WIDGET_FAILURE,
  EDIT_DASHBOARD,
  EDIT_DASHBOARD_SUCCESS,
  EDIT_DASHBOARD_FAILURE,
  EDIT_DASHBOARD_RESET,
  DELETE_DASHBOARD,
  DELETE_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_FAILURE,
  ADD_DASHBOARD,
  ADD_DASHBOARD_SUCCESS,
  ADD_DASHBOARD_FAILURE,
  DELETE_DASHBOARD_RESET,
  GET_ALL_WIDGET_CONFIG,
  GET_ALL_WIDGET_CONFIG_SUCCESS,
  GET_ALL_WIDGET_CONFIG_FAILURE,
  GET_ALL_WIDGET_CONFIG_EXTENDED,
  GET_ALL_WIDGET_CONFIG_EXTENDED_SUCCESS,
  GET_ALL_WIDGET_CONFIG_EXTENDED_FAILURE,
  GET_WIDGET_CONFIG,
  GET_WIDGET_CONFIG_SUCCESS,
  GET_WIDGET_CONFIG_FAILURE,
  CREATE_WIDGET,
  CREATE_WIDGET_SUCCESS,
  CREATE_WIDGET_FAILURE,
  EDIT_WIDGET,
  EDIT_WIDGET_SUCCESS,
  EDIT_WIDGET_FAILURE,
  DELETE_WIDGET,
  DELETE_WIDGET_SUCCESS,
  DELETE_WIDGET_FAILURE,
  DELETE_WIDGET_RESET,
  EDIT_WIDGET_RESET,
  CREATE_WIDGET_RESET,
  CREATE_DASHBOARD_GLOBAL_ACTION,
  CREATE_DASHBOARD_GLOBAL_ACTION_RESET,
  GET_WIDGET_HISTORY,
  GET_WIDGET_HISTORY_SUCCESS,
  GET_WIDGET_HISTORY_FAILURE,
  GET_DASHBOARD_CONFIG_HISTORY,
  GET_DASHBOARD_CONFIG_HISTORY_SUCCESS,
  GET_DASHBOARD_CONFIG_HISTORY_FAILURE,
  GET_WIDGET_CONFIG_BY_ID,
  GET_WIDGET_CONFIG_BY_ID_SUCCESS,
  GET_WIDGET_CONFIG_BY_ID_FAILURE,
  ADD_DASHBOARD_RESET,
  SET_POPOUT_WIDGET,
  OPEN_POPOUT_WIDGET,
  CLOSE_POPOUT_WIDGET,
  SORT_DASHBOARD,
  SORT_DASHBOARD_SUCCESS,
  SORT_DASHBOARD_FAILURE,
  SORT_DASHBOARD_RESET,
  SORT_ALL_DASHBOARD,
  SET_FACTORY_BACKDROP,
  GET_CUSTOM_DASHBOARD,
  GET_CUSTOM_DASHBOARD_SUCCESS,
  GET_CUSTOM_DASHBOARD_FAILURE,
  GET_CUSTOM_DASHBOARD_EXTENDED,
  GET_CUSTOM_DASHBOARD_EXTENDED_SUCCESS,
  GET_CUSTOM_DASHBOARD_EXTENDED_FAILURE,
  CREATE_CUSTOM_DASHBOARD,
  CREATE_CUSTOM_DASHBOARD_SUCCESS,
  CREATE_CUSTOM_DASHBOARD_FAILURE,
  CREATE_DASHBOARD_SEQUENCE,
  CREATE_DASHBOARD_SEQUENCE_SUCCESS,
  CREATE_DASHBOARD_SEQUENCE_FAILURE,
  CREATE_DASHBOARD_SEQUENCE_RESET,
  GET_DASHBOARD_SEQUENCE,
  GET_DASHBOARD_SEQUENCE_SUCCESS,
  GET_DASHBOARD_SEQUENCE_FAILURE,
  GET_DASHBOARD_SEQUENCE_EXTENDED,
  GET_DASHBOARD_SEQUENCE_EXTENDED_SUCCESS,
  GET_DASHBOARD_SEQUENCE_EXTENDED_FAILURE,
  CREATE_HEALTH_DATA,
  CREATE_HEALTH_DATA_SUCCESS,
  CREATE_HEALTH_DATA_FAILURE,
  UPDATE_HEALTH_DATA,
  UPDATE_HEALTH_DATA_SUCCESS,
  UPDATE_HEALTH_DATA_FAILURE,
  CREATE_HEALTH_DATA_RESET,
  DISCOVERY_UPGRADE_EMAIL,
  DISCOVERY_UPGRADE_EMAIL_SUCCESS,
  DISCOVERY_UPGRADE_EMAIL_FAILURE,
  GET_ASSET_DYNAMIC_VARIABLES,
  GET_ASSET_DYNAMIC_VARIABLES_SUCCESS,
  GET_ASSET_DYNAMIC_VARIABLES_FAILURE
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  success: undefined
};

export function getAllDashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_DASHBOARD:
      return {
        ...state,
        loading: true,
        data: { ...state.data },
        error: false,
        success: undefined
      };
    case GET_ALL_DASHBOARD_SUCCESS:
      let data;
      if (action.response.total_record === action.response.result.length) {
        data = action.response.result;
      } else {
        data = state.data.result ? state.data.result : [];

        if (
          action.response &&
          action.response.result &&
          action.response.result.length
        ) {
          action.response.result.map((item) => {
            data.push(item);
          });
        }
      }

      return {
        ...state,
        data: {
          result: data,
          total_pages: action.response.total_pages,
          total_record: action.response.total_record
        },
        error: false,
        loading: false,
        success: true
      };
    case GET_ALL_DASHBOARD_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    case SORT_ALL_DASHBOARD:
      const { payload } = action;
      state.data.result.map((db) => {
        if (!db) return;
        db.sequence = payload[db.id];
        return db;
      });
      return {
        ...state
      };
    default:
      return state;
  }
}

export function getSingleDashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SINGLE_DASHBOARD:
      return {
        ...state,
        loading: true,
        data: { ...state.data },
        error: false,
        success: undefined
      };
    case GET_SINGLE_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: action.response,
        loading: false,
        error: false,
        success: true
      };
    case GET_ALL_DASHBOARD_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    default:
      return state;
  }
}

export function addDashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_DASHBOARD:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case ADD_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true
      };
    case ADD_DASHBOARD_FAILURE:
      return {
        ...state,
        error: true,
        data: action.payload,
        success: false
      };
    case ADD_DASHBOARD_RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}

export function editDashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EDIT_DASHBOARD:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case EDIT_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case EDIT_DASHBOARD_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    case EDIT_DASHBOARD_RESET:
      return {
        ...state,
        success: undefined,
        data: []
      };
    default:
      return state;
  }
}

export function deleteDashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_DASHBOARD:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case DELETE_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case DELETE_DASHBOARD_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    case DELETE_DASHBOARD_RESET:
      return {
        ...state,
        success: undefined
      };
    default:
      return state;
  }
}

export function sortDashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SORT_DASHBOARD:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case SORT_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case SORT_DASHBOARD_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    case SORT_DASHBOARD_RESET:
      return {
        ...state,
        success: undefined,
        error: false
      };
    default:
      return state;
  }
}

export function createDashboardSequence(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_DASHBOARD_SEQUENCE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case CREATE_DASHBOARD_SEQUENCE_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true
      };
    case CREATE_DASHBOARD_SEQUENCE_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    case CREATE_DASHBOARD_SEQUENCE_RESET:
      return {
        ...state,
        success: undefined
      };
    default:
      return state;
  }
}

export function getDashboardSequence(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DASHBOARD_SEQUENCE:
      let initalData = state.data;
      initalData[action.payload.service_id] = {};
      return {
        ...state,
        loading: true,
        data: initalData,
        error: false,
        success: undefined
      };
    case GET_DASHBOARD_SEQUENCE_SUCCESS:
      let oldData = state.data;
      oldData[action.param.service_id] = action.response;
      return {
        ...state,
        data: oldData,
        error: false,
        loading: false,
        success: true
      };
    case GET_DASHBOARD_SEQUENCE_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        data: {},
        success: false
      };
    default:
      return state;
  }
}

export const getDashboardSequenceExtended = (
  state = {
    data: {},
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_DASHBOARD_SEQUENCE_EXTENDED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.service_id]: null
        },
        loading: {
          ...state.loading,
          [action.payload.service_id]: true
        },
        success: {
          ...state.success,
          [action.payload.service_id]: undefined
        },
        error: {
          ...state.error,
          [action.payload.service_id]: false
        }
      };

    case GET_DASHBOARD_SEQUENCE_EXTENDED_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.service_id]: action.response
        },
        loading: {
          ...state.loading,
          [action.param.service_id]: false
        },
        success: {
          ...state.success,
          [action.param.service_id]: true
        },
        error: {
          ...state.error,
          [action.param.service_id]: false
        }
      };

    case GET_DASHBOARD_SEQUENCE_EXTENDED_FAILURE:
      return (() => {
        const { service_id } = queryString.parse(
          action.payload.request.url.replace(/.*\?/, '')
        );

        return {
          ...state,
          loading: {
            ...state.loading,
            [service_id]: false
          },
          success: {
            ...state.success,
            [service_id]: false
          },
          error: {
            ...state.error,
            [service_id]: true
          }
        };
      })();

    default:
      return state;
  }
};

export function getAllWidgets(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_WIDGET:
      let data = action.payload.page_no === 1 ? [] : state.data;

      return {
        ...state,
        loading: true,
        data,
        error: false,
        success: undefined
      };
    case GET_ALL_WIDGET_SUCCESS:
      let datas = [...state.data, ...action.response.result];
      const uniqueIds = [];
      const unique = datas.filter((element) => {
        const isDuplicate = uniqueIds.includes(element.id);
        if (!isDuplicate) {
          uniqueIds.push(element.id);
          return true;
        }
        return false;
      });
      return {
        ...state,
        data: unique,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record,
        error: false,
        loading: false,
        success: true
      };
    case GET_ALL_WIDGET_FAILURE:
      return {
        ...state,
        error: true,
        success: false,
        loading: false
      };
    default:
      return state;
  }
}

export function getAllWidgetConfig(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_WIDGET_CONFIG:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case GET_ALL_WIDGET_CONFIG_SUCCESS:
      let widget = action.response;
      localStorage.setItem('allWidgetConfig', JSON.stringify(widget));
      return {
        ...state,
        data: widget,
        error: false,
        loading: false,
        success: true
      };
    case GET_ALL_WIDGET_CONFIG_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    default:
      return state;
  }
}

const getAllWidgetConfigExtended = (
  state = {
    data: null,
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_ALL_WIDGET_CONFIG_EXTENDED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.service_id]: null
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.service_id]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.service_id]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.service_id]: false
        }
      };

    case GET_ALL_WIDGET_CONFIG_EXTENDED_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.service_id]: action.response
        },
        loading: {
          ...state.loading,
          [action.param.service_id]: false
        },
        success: {
          ...state.success,
          [action.param.service_id]: true
        },
        error: {
          ...state.error,
          [action.param.service_id]: false
        }
      };

    case GET_ALL_WIDGET_CONFIG_EXTENDED_FAILURE:
      return (() => {
        const { service_id } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...state.loading,
            [service_id]: false
          },
          success: {
            ...state.success,
            [action.param.service_id]: false
          },
          error: {
            ...state.error,
            [service_id]: true
          }
        };
      })();

    default:
      return state;
  }
};

export function getWidgetConfigById(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_WIDGET_CONFIG_BY_ID:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case GET_WIDGET_CONFIG_BY_ID_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case GET_WIDGET_CONFIG_BY_ID_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    default:
      return state;
  }
}

export function getWidgetConfig(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_WIDGET_CONFIG:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case GET_WIDGET_CONFIG_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case GET_WIDGET_CONFIG_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    default:
      return state;
  }
}

export function createWidget(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_WIDGET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case CREATE_WIDGET_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true
      };
    case CREATE_WIDGET_FAILURE:
      return {
        ...state,
        error: true,
        data: action.payload,
        success: false
      };
    case CREATE_WIDGET_RESET:
      return {
        ...state,
        error: false,
        success: undefined
      }
    default:
      return state;
  }
}

export function editWidget(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EDIT_WIDGET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case EDIT_WIDGET_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case EDIT_WIDGET_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        data: [],
        success: false
      };
    case EDIT_WIDGET_RESET:
      return {
        ...state,
        error: false,
        data: [],
        loading: false,
        success: undefined
      }
    default:
      return state;
  }
}

export function deleteWidget(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_WIDGET:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case DELETE_WIDGET_SUCCESS:
      return {
        ...state,
        data: {
          param: action.param.id,
          response: action.response.response
        },
        error: false,
        loading: false,
        success: true
      };
    case DELETE_WIDGET_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    case DELETE_WIDGET_RESET:
      return {
        ...state,
        success: undefined,
        data: []
      };
    default:
      return state;
  }
}

export function createDashboardGlobalAction(
  state = { isModalOpen: false },
  action
) {
  switch (action.type) {
    case CREATE_DASHBOARD_GLOBAL_ACTION:
      return {
        isModalOpen: true
      };
      break;
    case CREATE_DASHBOARD_GLOBAL_ACTION_RESET:
      return {
        isModalOpen: false
      };
      break;
    default:
      return state;
  }
}

export function getWidgetConfigHistory(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_WIDGET_HISTORY:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case GET_WIDGET_HISTORY_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case GET_WIDGET_HISTORY_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    default:
      return state;
  }
}

export function getDashboardConfigHistory(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DASHBOARD_CONFIG_HISTORY:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case GET_DASHBOARD_CONFIG_HISTORY_SUCCESS:
      return {
        ...state,
        data: action.response,
        error: false,
        loading: false,
        success: true
      };
    case GET_DASHBOARD_CONFIG_HISTORY_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        success: false
      };
    default:
      return state;
  }
}

export function setPopoutWidget(state = {}, action) {
  switch (action.type) {
    case SET_POPOUT_WIDGET:
      return {
        widget: action.payload
      };
    default:
      return state;
  }
}

export function isPopoutWidgetOpen(state = {}, action) {
  switch (action.type) {
    case OPEN_POPOUT_WIDGET:
      return {
        isOpen: true
      };
    case CLOSE_POPOUT_WIDGET:
      return {
        isOpen: false
      };
    default:
      return state;
  }
}

export function setFactoryBackdrop(state = { factoryBackdrop: false }, action) {
  switch (action.type) {
    case SET_FACTORY_BACKDROP:
      return {
        factoryBackdrop: action.payload
      };
    default:
      return state;
  }
}

export function getCustomDashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CUSTOM_DASHBOARD:
      return {
        ...state,
        loading: true,
        data: state.data,
        error: false,
        success: undefined
      };
    case GET_CUSTOM_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param]: action.response
        },
        loading: false,
        error: false,
        success: true
      };
    case GET_CUSTOM_DASHBOARD_FAILURE:
      return {
        ...state,
        error: true,
        data: [],
        loading: false,
        success: false
      };
    default:
      return state;
  }
}

export const getCustomDashboardExtended = (
  state = {
    data: {},
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_CUSTOM_DASHBOARD_EXTENDED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.service_id]: null
        },
        loading: {
          ...state.loading,
          [action.payload.service_id]: true
        },
        success: {
          ...state.success,
          [action.payload.service_id]: undefined
        },
        error: {
          ...state.error,
          [action.payload.service_id]: false
        }
      };

    case GET_CUSTOM_DASHBOARD_EXTENDED_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.service_id]: action.response
        },
        loading: {
          ...state.loading,
          [action.param.service_id]: false
        },
        success: {
          ...state.success,
          [action.param.service_id]: true
        },
        error: {
          ...state.error,
          [action.param.service_id]: false
        }
      };

    case GET_CUSTOM_DASHBOARD_EXTENDED_FAILURE:
      return (() => {
        const { service_id } = queryString.parse(
          action.payload.request.url.replace(/.*\?/, '')
        );

        return {
          ...state,
          loading: {
            ...state.loading,
            [service_id]: false
          },
          success: {
            ...state.success,
            [service_id]: false
          },
          error: {
            ...state.error,
            [service_id]: true
          }
        };
      })();

    default:
      return state;
  }
};

export function createCustomDashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_CUSTOM_DASHBOARD:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      };
    case CREATE_CUSTOM_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: action.response,
        loading: false,
        error: false,
        success: true
      };
    case CREATE_CUSTOM_DASHBOARD_FAILURE:
      return {
        ...state,
        error: true,
        data: action.payload.response,
        loading: false
      };
    case ADD_DASHBOARD_RESET:
      return {
        ...state,
        success: undefined
      };
    default:
      return state;
  }
}

export function createHealthData(
  state = {
    loading: false,
    data: {},
    success: undefined,
    error: false
  },
  action
) {
  switch (action.type) {
    case CREATE_HEALTH_DATA:
      return {
        ...state,
        loading: true,
        error: false,
        success: undefined
      };
    case CREATE_HEALTH_DATA_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true
      };
    case CREATE_HEALTH_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: action.payload,
        success: false
      };
    case UPDATE_HEALTH_DATA:
      return {
        ...state,
        loading: true,
        error: false,
        success: undefined
      };
    case UPDATE_HEALTH_DATA_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true
      };
    case UPDATE_HEALTH_DATA_FAILURE:
      return {
        ...state,
        error: true,
        data: action.payload,
        success: false
      };
    case CREATE_HEALTH_DATA_RESET:
      return {
        ...state,
        data: {},
        error: false,
        success: false
      };
    default:
      return state;
  }
}

export function discoveryUpgradeEmail(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DISCOVERY_UPGRADE_EMAIL:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
        success: undefined
      }
    case DISCOVERY_UPGRADE_EMAIL_SUCCESS:
      return {
        ...state,
        data: action.response.response,
        error: false,
        loading: false,
        success: true
      }
    case DISCOVERY_UPGRADE_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: action.payload,
        success: false
      }
    default:
      return state
  }
}

export const getAssetDynamicVariables = (
  state = {
    data: {},
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_ASSET_DYNAMIC_VARIABLES:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.assetId]: null
        },
        loading: {
          ...state.loading,
          [action.payload.assetId]: true
        },
        success: {
          ...state.success,
          [action.payload.assetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.assetId]: false
        }
      };

    case GET_ASSET_DYNAMIC_VARIABLES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.assetId]: action.response
        },
        loading: {
          ...state.loading,
          [action.param.assetId]: false
        },
        success: {
          ...state.success,
          [action.param.assetId]: true
        },
        error: {
          ...state.error,
          [action.param.assetId]: false
        }
      };

    case GET_ASSET_DYNAMIC_VARIABLES_FAILURE:
      return (() => {
        const { assetId } = queryString.parse(
          action.payload.request.url.replace(/.*\?/, '')
        );

        return {
          ...state,
          loading: {
            ...state.loading,
            [assetId]: false
          },
          success: {
            ...state.success,
            [assetId]: false
          },
          error: {
            ...state.error,
            [assetId]: true
          }
        };
      })();

    default:
      return state;
  }
};



export const dashboardReducer = {
  allDashboards: getAllDashboard,
  allWidgets: getAllWidgets,
  addDashboard: addDashboard,
  editDashboard: editDashboard,
  deleteDashboard: deleteDashboard,
  allWidgetConfig: getAllWidgetConfig,
  allWidgetConfigExtended: getAllWidgetConfigExtended,
  widgetConfig: getWidgetConfig,
  createWidget: createWidget,
  editWidget: editWidget,
  deleteWidget: deleteWidget,
  createDashboardGlobalAction: createDashboardGlobalAction,
  getWidgetConfigHistory: getWidgetConfigHistory,
  getDashboardConfigHistory: getDashboardConfigHistory,
  getWidgetConfigById: getWidgetConfigById,
  setPopoutWidget: setPopoutWidget,
  isPopoutWidgetOpen: isPopoutWidgetOpen,
  sortDashboard: sortDashboard,
  createDashboardSequence: createDashboardSequence,
  getDashboardSequence: getDashboardSequence,
  getDashboardSequenceExtended: getDashboardSequenceExtended,
  setFactoryBackdrop,
  createCustomDashboard,
  getCustomDashboard,
  getCustomDashboardExtended,
  getSingleDashboard,
  healthConfigData: createHealthData,
  discoveryUpgradeEmail,
  assetDynamicVariables: getAssetDynamicVariables
};