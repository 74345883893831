/**
 * All epics reqiured for widget 
 */
import Config from 'Config'
import {ofType} from "redux-observable";
import {mergeMap} from "rxjs/operators";
import {
  ALERT_CONFIGURATION_COUNT
} from './constant';
import { httpMethodGetJSON } from '../../https';

const URL = Config.api_url;
const CONFIG_COUNT_URL = `${URL}/alerts/summary/`;

export const getAlertConfigCount = (action$) =>
  action$.pipe(
    ofType(ALERT_CONFIGURATION_COUNT),
    mergeMap((action) => {
      return httpMethodGetJSON(action, CONFIG_COUNT_URL);
    }),
  );