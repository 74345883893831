import React, { useRef } from 'react';
import SelectDropDown from 'react-select';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { Creatable as CreatableSelectDropDown, components } from 'react-select';
import ClickOutHandler from 'react-onclickout';
import './select.scss';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <Label className='_checkBoxContainer'>
          <input
            type='checkbox'
            checked={props.isSelected}
            onChange={() => null}
          />

          <div
            className={`_checkmark ${props.isSelected ? 'checked' : ''}`}
          ></div>

          <span className='text'>{props.label}</span>
        </Label>
      </components.Option>
    </div>
  );
};

const _SelectDropDown = (props) => {
  const selectorRef = useRef();

  return (
    <ClickOutHandler
      onClickOut={() => {
        selectorRef.current.onMenuClose();
      }}
    >
      <div
        className={`selectionbox-container ${
          props._isMulti ? '_isMulti' : ''
        } w-100`}
        {...(!props.hideHover && {
          'data-title': Array.isArray(props.value)
            ? props.value[0] && props.value[0].label
            : props.value && props.value.label
        })}
      >
        <SelectDropDown
          ref={selectorRef}
          placeholder={props.placeholder}
          classNamePrefix='selectionbox_prefix'
          className='selectionbox'
          title={props.value}
          isMulti={props.isMulti}
          _isMulti={props._isMulti}
          isDisabled={props.isDisabled}
          allowSelectAll={props.allowSelectAll}
          components={
            props._isMulti
              ? {
                  Option
                }
              : {}
          }
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            border: '1px solid #9B9B9B',
            colors: {
              ...theme.colors,
              primary25: 'WhiteSmoke',
              primary: 'Gainsboro'
            }
          })}
          hideSelectedOptions={props.hideSelectedOptions}
          menuPlacement={props.menuPlacement || 'auto'}
          menuPortalTarget={props.menuPortalTarget}
          defaultValue={props.defaultValue}
          value={props.value || []}
          options={
            props._isMulti
              ? props.options
                  .reduce((memo, option) => {
                    const matchIndex = (props.value || []).findIndex(
                      ({ value }) => {
                        return (
                          JSON.stringify(value) === JSON.stringify(option.value)
                        );
                      }
                    );

                    return [...(memo || []), { ...option, matchIndex }];
                  }, [])

                  .sort((a, b) => {
                    return b.matchIndex - a.matchIndex;
                  })
              : props.options
          }
          onChange={props.onChange}
        />
      </div>
    </ClickOutHandler>
  );
};

export class CreatableSelect extends React.Component {
  static defaultProps = {
    placeholder: '',
    onChange: function () {
      // eslint-disable-next-line no-empty
    },
    options: {},
    isMulti: false
  };

  static propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.object,
    isMulti: PropTypes.bool
  };

  render() {
    return (
      <CreatableSelectDropDown
        classNamePrefix='createble_selectionbox_prefix'
        isMulti={this.props.isMulti}
        value={this.props.value}
        onChange={this.props.onChange}
        options={this.props.options}
        className='selectionbox'
        menuPlacement='auto'
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          border: '1px solid #9B9B9B',
          colors: {
            ...theme.colors,
            primary25: 'WhiteSmoke',
            primary: 'Gainsboro'
          }
        })}
      />
    );
  }
}

export default _SelectDropDown;
