export const OVERVIEW_WIDGET_DATA = "OVERVIEW_WIDGET_DATA";
export const OVERVIEW_WIDGET_DATA_SUCCESS = "OVERVIEW_WIDGET_DATA_SUCCESS";
export const OVERVIEW_WIDGET_DATA_FAILURE = "OVERVIEW_WIDGET_DATA_FAILURE";
export const OVERVIEW_READING_DATA = "OVERVIEW_READING_DATA";
export const OVERVIEW_READING_DATA_SUCCESS = "OVERVIEW_READING_DATA_SUCCESS";
export const OVERVIEW_READING_DATA_FAILURE = "OVERVIEW_READING_DATA_FAILURE";
export const OVERVIEW_READING_LIVE = "OVERVIEW_READING_LIVE";
export const OVERVIEW_READING_LIVE_SUCCESS = "OVERVIEW_READING_LIVE_SUCCESS";
export const OVERVIEW_READING_LIVE_FAILURE = "OVERVIEW_READING_LIVE_FAILURE";

export const DIAL_LIVE_WIDGET_DATA = "DIAL_LIVE_WIDGET_DATA";
export const DIAL_LIVE_WIDGET_DATA_SUCCESS = "DIAL_LIVE_WIDGET_DATA_SUCCESS";
export const DIAL_LIVE_WIDGET_DATA_FAILURE = "DIAL_LIVE_WIDGET_DATA_FAILURE";

export const DIAL_COMPUTED_WIDGET_DATA = "DIAL_COMPUTED_WIDGET_DATA";
export const DIAL_COMPUTED_WIDGET_DATA_SUCCESS = "DIAL_COMPUTED_WIDGET_DATA_SUCCESS";
export const DIAL_COMPUTED_WIDGET_DATA_FAILURE = "DIAL_COMPUTED_WIDGET_DATA_FAILURE";

export const HEALTH_WIDGET_DATA = "HEALTH_WIDGET_DATA";
export const HEALTH_WIDGET_DATA_SUCCESS = "HEALTH_WIDGET_DATA_SUCCESS";
export const HEALTH_WIDGET_DATA_FAILURE = "HEALTH_WIDGET_DATA_FAILURE";

export const INSIGHT_WIDGET_DATA = "INSIGHT_WIDGET_DATA";
export const INSIGHT_WIDGET_DATA_SUCCESS = "INSIGHT_WIDGET_DATA_SUCCESS";
export const INSIGHT_WIDGET_DATA_FAILURE = "INSIGHT_WIDGET_DATA_FAILURE";

export const CYCLE_DISTRIBUTION_WIDGET_DATA = "CYCLE_DISTRIBUTION_WIDGET_DATA";
export const CYCLE_DISTRIBUTION_WIDGET_DATA_SUCCESS = "CYCLE_DISTRIBUTION_WIDGET_DATA_SUCCESS";
export const CYCLE_DISTRIBUTION_WIDGET_DATA_FAILURE = "CYCLE_DISTRIBUTION_WIDGET_DATA_FAILURE";

export const ANOMALY_WIDGET_DATA = "ANOMALY_WIDGET_DATA";
export const ANOMALY_WIDGET_DATA_SUCCESS = "ANOMALY_WIDGET_DATA_SUCCESS";
export const ANOMALY_WIDGET_DATA_FAILURE = "ANOMALY_WIDGET_DATA_FAILURE";

export const GET_ANOMALY_IMPACT_DATA = 'GET_ANOMALY_IMPACT_DATA';
export const GET_ANOMALY_IMPACT_DATA_SUCCESS = 'GET_ANOMALY_IMPACT_DATA_SUCCESS';
export const GET_ANOMALY_IMPACT_DATA_FAILURE = 'GET_ANOMALY_IMPACT_DATA_FAILURE';

export const HEATMAP_WIDGET_DATA = "HEATMAP_WIDGET_DATA";
export const HEATMAP_WIDGET_DATA_SUCCESS = "HEATMAP_WIDGET_DATA_SUCCESS";
export const HEATMAP_WIDGET_DATA_FAILURE = "HEATMAP_WIDGET_DATA_FAILURE";

export const HEATMAP_GRAPH_DATA = "HEATMAP_GRAPH_DATA";
export const HEATMAP_GRAPH_DATA_SUCCESS = "HEATMAP_GRAPH_DATA_SUCCESS";
export const HEATMAP_GRAPH_DATA_FAILURE = "HEATMAP_GRAPH_DATA_FAILURE";
export const HEATMAP_GRAPH_DATA_RESET = "HEATMAP_GRAPH_DATA_RESET";

export const PIECHART_WIDGET_DATA = "PIECHART_WIDGET_DATA";
export const PIECHART_WIDGET_DATA_SUCCESS = "PIECHART_WIDGET_DATA_SUCCESS";
export const PIECHART_WIDGET_DATA_FAILURE = "PIECHART_WIDGET_DATA_FAILURE";
export const PIECHART_WIDGET_DATA_RESET = "PIECHART_WIDGET_DATA_RESET";

export const ARCH_CHART_WIDGET_DATA = 'GET_ARCH_CHART_WIDGET_DATA';
export const ARCH_CHART_WIDGET_DATA_SUCCESS = 'GET_ARCH_CHART_WIDGET_DATA_SUCCESS';
export const ARCH_CHART_WIDGET_DATA_FAILURE = 'GET_ARCH_CHART_WIDGET_DATA_FAILURE';

export const DIGITBOX1_WIDGET_DATA = "DIGITBOX1_WIDGET_DATA";
export const DIGITBOX1_WIDGET_DATA_SUCCESS = "DIGITBOX1_WIDGET_DATA_SUCCESS";
export const DIGITBOX1_WIDGET_DATA_FAILURE = "DIGITBOX1_WIDGET_DATA_FAILURE";

export const DIGITBOX2_WIDGET_DATA = "DIGITBOX2_WIDGET_DATA";
export const DIGITBOX2_WIDGET_DATA_SUCCESS = "DIGITBOX2_WIDGET_DATA_SUCCESS";
export const DIGITBOX2_WIDGET_DATA_FAILURE = "DIGITBOX2_WIDGET_DATA_FAILURE";

export const DIGITBOX2_LIVE_WIDGET_DATA = "DIGITBOX2_LIVE_WIDGET_DATA";
export const DIGITBOX2_LIVE_WIDGET_DATA_SUCCESS = "DIGITBOX2_LIVE_WIDGET_DATA_SUCCESS";
export const DIGITBOX2_LIVE_WIDGET_DATA_FAILURE = "DIGITBOX2_LIVE_WIDGET_DATA_FAILURE";

export const DIGITBOX2_TREND_WIDGET_DATA = "DIGITBOX2_TREND_WIDGET_DATA";
export const DIGITBOX2_TREND_WIDGET_DATA_SUCCESS = "DIGITBOX2_TREND_WIDGET_DATA_SUCCESS";
export const DIGITBOX2_TREND_WIDGET_DATA_FAILURE = "DIGITBOX2_TREND_WIDGET_DATA_FAILURE";

export const DIGITBOX2_COST_WIDGET_DATA = "DIGITBOX2_COST_WIDGET_DATA";
export const DIGITBOX2_COST_WIDGET_DATA_SUCCESS = "DIGITBOX2_COST_WIDGET_DATA_SUCCESS";
export const DIGITBOX2_COST_WIDGET_DATA_FAILURE = "DIGITBOX2_COST_WIDGET_DATA_FAILURE";

export const DIGITBOX2_COST_SERIES_WIDGET_DATA = "DIGITBOX2_COST_SERIES_WIDGET_DATA";
export const DIGITBOX2_COST_SERIES_WIDGET_DATA_SUCCESS = "DIGITBOX2_COST_SERIES_WIDGET_DATA_SUCCESS";
export const DIGITBOX2_COST_SERIES_WIDGET_DATA_FAILURE = "DIGITBOX2_COST_SERIES_WIDGET_DATA_FAILURE";

export const DIGITBOX3_WIDGET_DATA = "DIGITBOX3_WIDGET_DATA";
export const DIGITBOX3_WIDGET_DATA_SUCCESS = "DIGITBOX3_WIDGET_DATA_SUCCESS";
export const DIGITBOX3_WIDGET_DATA_FAILURE = "DIGITBOX3_WIDGET_DATA_FAILURE";

export const DIGITBOX3_SERIES_WIDGET_DATA = "DIGITBOX3_SERIES_WIDGET_DATA";
export const DIGITBOX3_SERIES_WIDGET_DATA_SUCCESS = "DIGITBOX3_SERIES_WIDGET_DATA_SUCCESS";
export const DIGITBOX3_SERIES_WIDGET_DATA_FAILURE = "DIGITBOX3_SERIES_WIDGET_DATA_FAILURE";

export const DIGITBOX4_WIDGET_DATA = "DIGITBOX4_WIDGET_DATA";
export const DIGITBOX4_WIDGET_DATA_SUCCESS = "DIGITBOX4_WIDGET_DATA_SUCCESS";
export const DIGITBOX4_WIDGET_DATA_FAILURE = "DIGITBOX4_WIDGET_DATA_FAILURE";

export const PARAM_PERF_WIDGET_DATA = "PARAM_PERF_WIDGET_DATA";
export const PARAM_PERF_WIDGET_DATA_SUCCESS = "PARAM_PERF_WIDGET_DATA_SUCCESS";
export const PARAM_PERF_WIDGET_DATA_FAILURE = "PARAM_PERF_WIDGET_DATA_FAILURE";

export const PARAM_PERF_SERIES_DATA = "PARAM_PERF_SERIES_DATA";
export const PARAM_PERF_SERIES_DATA_SUCCESS = "PARAM_PERF_SERIES_DATA_SUCCESS";
export const PARAM_PERF_SERIES_DATA_FAILURE = "PARAM_PERF_SERIES_DATA_FAILURE";

export const RUN_HOUR_SERIES_DATA = "RUN_HOUR_SERIES_DATA";
export const RUN_HOUR_SERIES_DATA_SUCCESS = "RUN_HOUR_SERIES_DATA_SUCCESS";
export const RUN_HOUR_SERIES_DATA_FAILURE = "RUN_HOUR_SERIES_DATA_FAILURE";

export const RUN_HOUR_COMPUTED_DATA = "RUN_HOUR_COMPUTED_DATA";
export const RUN_HOUR_COMPUTED_DATA_SUCCESS = "RUN_HOUR_COMPUTED_DATA_SUCCESS";
export const RUN_HOUR_COMPUTED_DATA_FAILURE = "RUN_HOUR_COMPUTED_DATA_FAILURE";

export const GRAPH_COMPUTED_DATA = "GRAPH_COMPUTED_DATA";
export const GRAPH_COMPUTED_DATA_SUCCESS = "GRAPH_COMPUTED_DATA_SUCCESS";
export const GRAPH_COMPUTED_DATA_FAILURE = "GRAPH_COMPUTED_DATA_FAILURE";

export const GRAPH_NORMAL_DATA = "GRAPH_NORMAL_DATA";
export const GRAPH_NORMAL_DATA_SUCCESS = "GRAPH_NORMAL_DATA_SUCCESS";
export const GRAPH_NORMAL_DATA_FAILURE = "GRAPH_NORMAL_DATA_FAILURE";

export const GRAPH_READING_SERIES = "GRAPH_READING_SERIES";
export const GRAPH_READING_SERIES_SUCCESS = "GRAPH_READING_SERIES_SUCCESS";
export const GRAPH_READING_SERIES_FAILURE = "GRAPH_READING_SERIES_FAILURE";

export const GRAPH_PARAMETER_DATA = "GRAPH_PARAMETER_DATA";
export const GRAPH_PARAMETER_DATA_SUCCESS = "GRAPH_PARAMETER_DATA_SUCCESS";
export const GRAPH_PARAMETER_DATA_FAILURE = "GRAPH_PARAMETER_DATA_FAILURE"

export const ALERT_LOG_DATA = "ALERT_LOG_DATA";
export const ALERT_LOG_DATA_SUCCESS = "ALERT_LOG_DATA_SUCCESS";
export const ALERT_LOG_DATA_FAILURE = "ALERT_LOG_DATA_FAILURE";

export const ALARM_DATA = "ALARM_DATA";
export const ALARM_DATA_SUCCESS = "ALARM_DATA_SUCCESS";
export const ALARM_DATA_FAILURE = "ALARM_DATA_FAILURE";

export const LIVETABLE_WIDGET_DATA = "LIVETABLE_WIDGET_DATA";
export const LIVETABLE_WIDGET_DATA_SUCCESS = "LIVETABLE_WIDGET_DATA_SUCCESS";
export const LIVETABLE_WIDGET_DATA_FAILURE = "LIVETABLE_WIDGET_DATA_FAILURE";

export const TABLE_WIDGET_DATA = "TABLE_WIDGET_DATA";
export const TABLE_WIDGET_DATA_SUCCESS = "TABLE_WIDGET_DATA_SUCCESS";
export const TABLE_WIDGET_DATA_FAILURE = "TABLE_WIDGET_DATA_FAILURE";

export const GET_ALL_TREES = "GET_ALL_TREES";
export const GET_ALL_TREES_SUCCESS = "GET_ALL_TREES_SUCCESS";
export const GET_ALL_TREES_FAILURE = "GET_ALL_TREES_FAILURE";

export const GET_TREE_DATA = "GET_TREE_DATA";
export const GET_TREE_DATA_SUCCESS = "GET_TREE_DATA_SUCCESS";
export const GET_TREE_DATA_FAILURE = "GET_TREE_DATA_FAILURE";

export const CREATE_TREE_DATA = "CREATE_TREE_DATA";
export const CREATE_TREE_DATA_SUCCESS = "CREATE_TREE_DATA_SUCCESS";
export const CREATE_TREE_DATA_FAILURE = "CREATE_TREE_DATA_FAILURE";

export const CREATE_NODE_DATA = "CREATE_NODE_DATA";
export const CREATE_NODE_DATA_SUCCESS = "CREATE_NODE_DATA_SUCCESS";
export const CREATE_NODE_DATA_FAILURE = "CREATE_NODE_DATA_FAILURE";
export const CREATE_NODE_DATA_RESET = "CREATE_NODE_DATA_RESET";

export const EDIT_NODE_DATA = "EDIT_NODE_DATA";
export const EDIT_NODE_DATA_SUCCESS = "EDIT_NODE_DATA_SUCCESS";
export const EDIT_NODE_DATA_FAILURE = "EDIT_NODE_DATA_FAILURE";
export const EDIT_NODE_DATA_RESET = "EDIT_NODE_DATA_RESET";

export const DELETE_NODE = "DELETE_NODE";
export const DELETE_NODE_SUCCESS = "DELETE_NODE_SUCCESS";
export const DELETE_NODE_FAILURE = "DELETE_NODE_FAILURE";

export const GET_TREE_SENSOR_HEALTH_DATA = "GET_TREE_SENSOR_HEALTH_DATA";
export const GET_TREE_SENSOR_HEALTH_DATA_SUCCESS = "GET_TREE_SENSOR_HEALTH_DATA_SUCCESS";
export const GET_TREE_SENSOR_HEALTH_DATA_FAILURE = "GET_TREE_SENSOR_HEALTH_DATA_FAILURE";

export const GET_GIS_SENSOR_WISE_DATA = "GET_GIS_SENSOR_WISE_DATA";
export const GET_GIS_SENSOR_WISE_DATA_SUCCESS = "GET_GIS_SENSOR_WISE_DATA_SUCCESS";
export const GET_GIS_SENSOR_WISE_DATA_FAILURE = "GET_GIS_SENSOR_WISE_DATA_FAILURE";

export const GET_FACTORY_DATA = "GET_FACTORY_DATA";
export const GET_FACTORY_DATA_SUCCESS = "GET_FACTORY_DATA_SUCCESS";
export const GET_FACTORY_DATA_FAILURE = "GET_FACTORY_DATA_FAILURE";

export const GET_FACTORY_INFOWINDOW_DATA = "GET_FACTORY_INFOWINDOW_DATA";
export const GET_FACTORY_INFOWINDOW_DATA_SUCCESS = "GET_FACTORY_INFOWINDOW_DATA_SUCCESS";
export const GET_FACTORY_INFOWINDOW_DATA_FAILURE = "GET_FACTORY_INFOWINDOW_DATA_FAILURE";

export const GET_ALERTS_WIDGET_DATA = "GET_ALERTS_WIDGET_DATA";
export const GET_ALERTS_WIDGET_DATA_SUCCESS = "GET_ALERTS_WIDGET_DATA_SUCCESS";
export const GET_ALERTS_WIDGET_DATA_FAILURE = "GET_ALERTS_WIDGET_DATA_FAILURE";

export const GET_ALERTS_WIDGET_TABLEDATA = "GET_ALERTS_WIDGET_TABLEDATA";
export const GET_ALERTS_WIDGET_TABLEDATA_SUCCESS = "GET_ALERTS_WIDGET_TABLEDATA_SUCCESS";
export const GET_ALERTS_WIDGET_TABLEDATA_FAILURE = "GET_ALERTS_WIDGET_TABLEDATA_FAILURE";

export const GET_INSIGHTS_ALERTS_DATA = "GET_INSIGHTS_ALERTS_DATA";
export const GET_INSIGHTS_ALERTS_DATA_SUCCESS = "GET_INSIGHTS_ALERTS_DATA_SUCCESS";
export const GET_INSIGHTS_ALERTS_DATA_FAILURE = "GET_INSIGHTS_ALERTS_DATA_FAILURE";
export const ADD_SELECTED_INSIGHTS_ALERT = "ADD_SELECTED_INSIGHTS_ALERT";
export const ADD_SELECTED_INSIGHTS_ALERT_SUCCESS = "ADD_SELECTED_INSIGHTS_ALERT_SUCCESS";

export const OPEN_CRITICAL_EVENT = "OPEN_CRITICAL_EVENT";
export const OPEN_CRITICAL_EVENT_SUCCESS = "OPEN_CRITICAL_EVENT_SUCCESS";

export const GET_WELLNESS_DATA = "GET_WELLNESS_DATA";
export const GET_WELLNESS_DATA_SUCCESS = "GET_WELLNESS_DATA_SUCCESS";
export const GET_WELLNESS_DATA_FAILURE = "GET_WELLNESS_DATA_FAILURE";

export const UPLOAD_IMAGE_CONFIGURATION = "UPLOAD_IMAGE_CONFIGURATION";
export const UPLOAD_IMAGE_CONFIGURATION_SUCCESS = "UPLOAD_IMAGE_CONFIGURATION_SUCCESS";
export const UPLOAD_IMAGE_CONFIGURATION_FAILURE = "UPLOAD_IMAGE_CONFIGURATION_FAILURE";

export const GET_UPLOAD_IMAGE_WIDGET_DATA = "GET_UPLOAD_IMAGE_WIDGET_DATA";
export const GET_UPLOAD_IMAGE_WIDGET_DATA_SUCCESS = "GET_UPLOAD_IMAGE_WIDGET_DATA_SUCCESS";
export const GET_UPLOAD_IMAGE_WIDGET_DATA_FAILURE = "GET_UPLOAD_IMAGE_WIDGET_DATA_FAILURE";

export const GET_LIST_VIEW_PARAM_DATA = "GET_LIST_VIEW_PARAM_DATA";
export const GET_LIST_VIEW_PARAM_DATA_SUCCESS = "GET_LIST_VIEW_PARAM_DATA_SUCCESS";
export const GET_LIST_VIEW_PARAM_DATA_FAILURE = "GET_LIST_VIEW_PARAM_DATA_FAILURE";

export const GET_PARAM_SUMMARY_DATA = "GET_PARAM_SUMMARY_DATA";
export const GET_PARAM_SUMMARY_DATA_SUCCESS = "GET_PARAM_SUMMARY_DATA_SUCCESS";
export const GET_PARAM_SUMMARY_DATA_FAILURE = "GET_PARAM_SUMMARY_DATA_FAILURE";

export const GET_PARAM_META_DATA = "GET_PARAM_META_DATA";
export const GET_PARAM_META_DATA_SUCCESS = "GET_PARAM_META_DATA_SUCCESS";
export const GET_PARAM_META_DATA_FAILURE = "GET_PARAM_META_DATA_FAILURE";


export const GET_ASSET_HISTORY = "GET_ASSET_HISTORY";
export const GET_ASSET_HISTORY_SUCCESS = "GET_ASSET_HISTORY_SUCCESS";
export const GET_ASSET_HISTORY_FAILURE = "GET_ASSET_HISTORY_FAILURE";

export const GET_ASSET_INSIGHT_PARAMS = 'GET_ASSET_INSIGHT_PARAMS';
export const GET_ASSET_INSIGHT_PARAMS_SUCCESS = 'GET_ASSET_INSIGHT_PARAMS_SUCCESS';
export const GET_ASSET_INSIGHT_PARAMS_FAILURE = 'GET_ASSET_INSIGHT_PARAMS_FAILURE';

export const GET_ASSET_OPEN_INSIGHTS = 'GET_ASSET_OPEN_INSIGHTS';
export const GET_ASSET_OPEN_INSIGHTS_SUCCESS = 'GET_ASSET_OPEN_INSIGHTS_SUCCESS';
export const GET_ASSET_OPEN_INSIGHTS_FAILURE = 'GET_ASSET_OPEN_INSIGHTS_FAILURE';

export const GET_ASSET_INSIGHTS = 'GET_ASSET_INSIGHTS';
export const GET_ASSET_INSIGHTS_SUCCESS = 'GET_ASSET_INSIGHTS_SUCCESS';
export const GET_ASSET_INSIGHTS_FAILURE = 'GET_ASSET_INSIGHTS_FAILURE';

export const GET_ASSET_INSIGHTS_CONFIG = 'GET_ASSET_INSIGHTS_CONFIG';
export const GET_ASSET_INSIGHTS_CONFIG_SUCCESS = 'GET_ASSET_INSIGHTS_CONFIG_SUCCESS';
export const GET_ASSET_INSIGHTS_CONFIG_FAILURE = 'GET_ASSET_INSIGHTS_CONFIG_FAILURE';

export const SUBMIT_ASSET_FEEDBACK = 'SUBMIT_ASSET_FEEDBACK';
export const SUBMIT_ASSET_FEEDBACK_SUCCESS = 'SUBMIT_ASSET_FEEDBACK_SUCCESS';
export const SUBMIT_ASSET_FEEDBACK_FAILURE = 'SUBMIT_ASSET_FEEDBACK_FAILURE';
export const SUBMIT_ASSET_FEEDBACK_RESET = 'SUBMIT_ASSET_FEEDBACK_RESET';

export const GET_INSIGHT_GRAPH_DATA = 'GET_INSIGHT_GRAPH_DATA';
export const GET_INSIGHT_GRAPH_DATA_SUCCESS = 'GET_INSIGHT_GRAPH_DATA_SUCCESS';
export const GET_INSIGHT_GRAPH_DATA_FAILURE = 'GET_INSIGHT_GRAPH_DATA_FAILURE';
export const GET_INSIGHT_GRAPH_DATA_RESET = 'GET_INSIGHT_GRAPH_DATA_RESET';

export const INFLUNCERS_DATA = "INFLUNCERS_DATA";
export const INFLUNCERS_DATA_SUCCESS = 'INFLUNCERS_DATA_SUCCESS';
export const INFLUNCERS_DATA_FAILURE = 'INFLUNCERS_DATA_FAILURE';
export const INFLUNCERS_DATA_RESET = 'INFLUNCERS_DATA_RESET';

export const CHANGE_ALERTS_WIDGET_MODAL = 'CHANGE_ALERTS_WIDGET_MODAL';
export const CHANGE_ALERTS_GRAPH_MODAL = 'CHANGE_ALERTS_GRAPH_MODAL';

export const SELECT_ALERTS_LIST = 'SELECT_ALERTS_LIST';

export const GET_ALERTS_WIDGET_GRAPH_DATA = 'GET_ALERTS_WIDGET_GRAPH_DATA';
export const GET_ALERTS_WIDGET_GRAPH_DATA_SUCCESS = 'GET_ALERTS_WIDGET_GRAPH_DATA_SUCCESS';
export const GET_ALERTS_WIDGET_GRAPH_DATA_FAILURE = 'GET_ALERTS_WIDGET_GRAPH_DATA_FAILURE';

export const UPDATE_ALERTS_WIDGET_STATUS = 'UPDATE_ALERTS_WIDGET_STATUS';
export const UPDATE_ALERTS_WIDGET_STATUS_SUCCESS = 'UPDATE_ALERTS_WIDGET_STATUS_SUCCESS';
export const UPDATE_ALERTS_WIDGET_STATUS_FAILURE = 'UPDATE_ALERTS_WIDGET_STATUS_FAILURE';
export const GET_ASSET_INSIGHT_ACTION = 'GET_ASSET_INSIGHT_ACTION';
export const GET_ASSET_INSIGHT_ACTION_SUCCESS = 'GET_ASSET_INSIGHT_ACTION_SUCCESS';
export const GET_ASSET_INSIGHT_ACTION_FAILURE = 'GET_ASSET_INSIGHT_ACTION_FAILURE';
export const GET_ASSET_INSIGHT_ACTION_RESET = 'GET_ASSET_INSIGHT_ACTION_RESET';

export const GET_WIDGET_LIST_FROM_TEMPLATE = 'GET_WIDGET_LIST_FROM_TEMPLATE'
export const GET_WIDGET_LIST_FROM_TEMPLATE_SUCCESS = 'GET_WIDGET_LIST_FROM_TEMPLATE_SUCCESS'
export const GET_WIDGET_LIST_FROM_TEMPLATE_FAILURE = 'GET_WIDGET_LIST_FROM_TEMPLATE_FAILURE'

export const ANALYZE_DATA = "ANALYZE_DATA";
export const ANALYZE_DATA_SUCCESS = "ANALYZE_DATA_SUCCESS";
export const ANALYZE_DATA_FAILURE = "ANALYZE_DATA_FAILURE";

export const AIR_POLLUTION_DATA = "AIR_POLLUTION_DATA";
export const AIR_POLLUTION_DATA_SUCCESS = "AIR_POLLUTION_DATA_SUCCESS";
export const AIR_POLLUTION_DATA_FAILURE = "AIR_POLLUTION_DATA_FAILURE";

export const WEATHER_DATA = "WEATHER_DATA"
export const WEATHER_DATA_SUCCESS = "WEATHER_DATA_SUCCESS"
export const WEATHER_DATA_FAILURE = "WEATHER_DATA_FAILURE"

export const GET_TAG_DATA = "GET_TAG_DATA"
export const GET_TAG_DATA_SUCCESS = "GET_TAG_DATA_SUCCESS"
export const GET_TAG_DATA_FAILURE = "GET_TAG_DATA_FAILURE"

export const GET_CXO_SUMMARY = "GET_CXO_SUMMARY"
export const GET_CXO_SUMMARY_SUCCESS = "GET_CXO_SUMMARY_SUCCESS"
export const GET_CXO_SUMMARY_FAILURE = "GET_CXO_SUMMARY_FAILURE"

export const GET_TAG_TIMESERIES_DATA = 'GET_TAG_TIMESERIES_DATA';
export const GET_TAG_TIMESERIES_DATA_SUCCESS =
  'GET_TAG_TIMESERIES_DATA_SUCCESS';
export const GET_TAG_TIMESERIES_DATA_FAILURE =
  'GET_TAG_TIMESERIES_DATA_FAILURE';

export const SCORECARD_WIDGET_DATA = 'SCORECARD_WIDGET_DATA';
export const SCORECARD_WIDGET_DATA_SUCCESS = 'SCORECARD_WIDGET_DATA_SUCCESS';
export const SCORECARD_WIDGET_DATA_FAILURE = 'SCORECARD_WIDGET_DATA_FAILURE';
export const SCORECARD_WIDGET_DATA_RESET = 'SCORECARD_WIDGET_DATA_RESET';

export const GET_TAG_TIMESERIES_COMPUTED_DATA = 'GET_TAG_TIMESERIES_COMPUTED_DATA';
export const GET_TAG_TIMESERIES_COMPUTED_DATA_SUCCESS =
  'GET_TAG_TIMESERIES_COMPUTED_DATA_SUCCESS';
export const GET_TAG_TIMESERIES_COMPUTED_DATA_FAILURE =
  'GET_TAG_TIMESERIES_COMPUTED_DATA_FAILURE';

export const GET_FACILITY_DATA = "GET_FACILITY_DATA"
export const GET_FACILITY_DATA_SUCCESS = "GET_FACILITY_DATA_SUCCESS"
export const GET_FACILITY_DATA_FAILURE = "GET_FACILITY_DATA_FAILURE"

export const GET_INSIGHT_IMPACT_CONFIG = "GET_INSIGHT_IMPACT_CONFIG"; 
export const GET_INSIGHT_IMPACT_CONFIG_SUCCESS = "GET_INSIGHT_IMPACT_CONFIG_SUCCESS"; 
export const GET_INSIGHT_IMPACT_CONFIG_FAILURE = "GET_INSIGHT_IMPACT_CONFIG_FAILURE";

export const UPDATE_INSIGHT_IMPACT_CONFIG = "UPDATE_INSIGHT_IMPACT_CONFIG"; 
export const UPDATE_INSIGHT_IMPACT_CONFIG_SUCCESS = "UPDATE_INSIGHT_IMPACT_CONFIG_SUCCESS"; 
export const UPDATE_INSIGHT_IMPACT_CONFIG_FAILURE = "UPDATE_INSIGHT_IMPACT_CONFIG_FAILURE";

export const GET_TAG_READING_DATA = 'GET_TAG_READING_DATA'
export const GET_TAG_READING_DATA_SUCCESS = 'GET_TAG_READING_DATA_SUCCESS'
export const GET_TAG_READING_DATA_FAILURE = 'GET_TAG_READING_DATA_FAILURE'

export const GET_CONFIGURED_ALERT_COUNT = 'GET_CONFIGURED_ALERT_COUNT'
export const GET_CONFIGURED_ALERT_COUNT_SUCCESS = 'GET_CONFIGURED_ALERT_COUNT_SUCCESS'
export const GET_CONFIGURED_ALERT_COUNT_FAILURE = 'GET_CONFIGURED_ALERT_COUNT_FAILURE'

export const GET_ALL_INSIGHT_FOR_GROUP = 'GET_ALL_INSIGHT_FOR_GROUP';
export const GET_ALL_INSIGHT_FOR_GROUP_SUCCESS =
  'GET_ALL_INSIGHT_FOR_GROUP_SUCCESS';
export const GET_ALL_INSIGHT_FOR_GROUP_FAILURE =
  'GET_ALL_INSIGHT_FOR_GROUP_FAILURE';
export const GET_ALL_INSIGHT_FOR_GROUP_RESET =
  'GET_ALL_INSIGHT_FOR_GROUP_RESET';