import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import paramTypeCollectionGet from '../common/paramTypeCollectionGet';
import parameterConfsInputGet from '../common/parameterConfsInputGet';
import formulaConfsOutputGet from '../common/formulaConfsOutputGet';
import _ParameterConfig from './_ParameterConfig';
import {
  Button,
  Input,
  RadioButtons
} from '../../../../../../../common/components';
import formulaConfsInputGet from '../common/formulaConfsInputGet';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';

const runhourTypeCollection = ['Show On-Off Status', 'Set Custom Ranges'];

const paramTypeCollection = paramTypeCollectionGet();

const parameterConfsGet = (value) => {
  const parameterConfs = [{ locParam: value }];

  return parameterConfsInputGet(parameterConfs);
};

const formulaConfsGet = (value) => {
  return formulaConfsInputGet(
    value &&
      (() => {
        const { formula, equation } = value;

        return [
          {
            formula,
            equation
          }
        ];
      })()
  );
};

const conditionsGet = (object, index, conditions) => {
  return [
    ...conditions.slice(0, index),
    {
      ...conditions[index],
      ...object
    },
    ...conditions.slice(index + 1)
  ];
};

const parameterGet = (parameter, parameterConfs) => {
  const _parameterConfs = parameterConfsOutputGet(parameterConfs);

  return (
    _parameterConfs &&
    (() => {
      const { locParam } = _parameterConfs[0];

      return {
        ...parameter,
        locParam
      };
    })()
  );
};

const formulaConfGet = (formulaConf, formulaConfs) => {
  const _formulaConfs = formulaConfsOutputGet(formulaConfs);

  return (
    _formulaConfs &&
    (() => {
      const { formula, equation } = _formulaConfs[0];

      return {
        ...formulaConf,
        formula,
        equation
      };
    })()
  );
};

const isInValidGet = (value) => {
  switch (true) {
    case value.paramType === paramTypeCollection[0] && !value.parameter:
    case value.paramType === paramTypeCollection[1] && !value.formulaConf:
      return true;

    default:
      return false;
  }
};

const OtherConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [runhourType, setRunhourType] = useState();

  const [paramType, setParamType] = useState();

  const [parameter, setParameter] = useState();

  const [parameterConfs, setParameterConfs] = useState();

  const [formulaConf, setFormulaConf] = useState();

  const [formulaConfs, setFormulaConfs] = useState();

  const [conditions, setConditions] = useState();

  const [conditionCount, setConditionCount] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams &&
        props.widgetParams.runhourConfig &&
        props.widgetParams.runhourConfig.value;

      setRunhourType((conf && conf.runhourType) || runhourTypeCollection[0]);

      setParamType((conf && conf.paramType) || paramTypeCollection[0]);

      setParameter(
        (conf &&
          conf.parameter &&
          (() => {
            const { alias, label, value } = conf.parameter;

            return {
              alias,
              label,
              value
            };
          })()) ||
          {}
      );

      setParameterConfs(
        parameterConfsGet(conf && conf.parameter && conf.parameter.locParam)
      );

      setFormulaConf(
        (conf &&
          conf.formulaConf &&
          (() => {
            const { alias, unit, label, lineValue, showPartial } =
              conf.formulaConf;

            return {
              alias,
              unit,
              label,
              lineValue,
              showPartial
            };
          })()) ||
          {}
      );

      setFormulaConfs(formulaConfsGet(conf && conf.formulaConf));

      setConditions((conf && conf.conditions) || [{}]);

      setConditionCount(
        (conf && conf.conditions && conf.conditions.length) || 1
      );

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const runhourTypeActiveIndex = runhourTypeCollection.findIndex(
    (_runhourType) => {
      return _runhourType === runhourType;
    }
  );

  const paramTypeActiveIndex = paramTypeCollection.findIndex((_paramType) => {
    return _paramType === paramType;
  });

  const appInputParamChangedHandle = (object) => {
    const outputGet = (
      parameter,
      parameterConfs,
      formulaConf,
      formulaConfs,
      paramType
    ) => {
      return paramType === paramTypeCollection[0]
        ? { parameter: parameterGet(parameter, parameterConfs) }
        : { formulaConf: formulaConfGet(formulaConf, formulaConfs) };
    };

    const value = {
      runhourType,
      paramType,
      ...outputGet(
        parameter,
        parameterConfs,
        formulaConf,
        formulaConfs,
        object.paramType || paramType
      ),
      conditions,
      ...object
    };

    const isInValid = isInValidGet(value);

    props.appInputParamChanged({
      runhourConfig: {
        value,
        isInValid
      }
    });
  };

  const renderParameterConfig = () => {
    return (
      <_ParameterConfig
        paramTypeCollection={paramTypeCollection}
        paramTypeActiveIndex={paramTypeActiveIndex}
        parameter={parameter}
        parameterConfs={parameterConfs}
        formulaConf={formulaConf}
        formulaConfs={formulaConfs}
        onParamTypeChange={(_paramType) => {
          setParamType(_paramType);

          appInputParamChangedHandle({ paramType: _paramType });
        }}
        onParameterChangeTrigger={(object) => {
          const _parameter = {
            ...parameter,
            ...object
          };

          setParameter(_parameter);

          appInputParamChangedHandle({
            parameter: parameterGet(_parameter, parameterConfs)
          });
        }}
        onParameterConfsChange={(_parameterConfs) => {
          setParameterConfs(_parameterConfs);

          appInputParamChangedHandle({
            parameter: parameterGet(parameter, _parameterConfs)
          });
        }}
        onFormulaConfChangeTrigger={(object) => {
          const _formulaConf = {
            ...formulaConf,
            ...object
          };

          setFormulaConf(_formulaConf);

          appInputParamChangedHandle({
            formulaConf: formulaConfGet(_formulaConf, formulaConfs)
          });
        }}
        onFormulaConfsChange={(_formulaConfs) => {
          setFormulaConfs(_formulaConfs);

          appInputParamChangedHandle({
            formulaConf: formulaConfGet(formulaConf, _formulaConfs)
          });
        }}
      />
    );
  };

  const renderConditions = () => {
    return Array.from({ length: conditionCount })
      .fill()
      .reduce((memo, _, index) => {
        const value = conditions[index] || {};
        return [...memo, value];
      }, [])
      .map(({ from, to, alias, color }, index) => {
        return (
          <Fragment key={index}>
            <Row>
              <Col md={11}>
                <Row>
                  <Col md={3}>
                    <div className='new-input-wrap'>
                      <label className='label-text'>From</label>

                      <Input
                        size='sm'
                        label=''
                        type='number'
                        required={false}
                        placeholder='Enter From Value'
                        value={from || ''}
                        inputChanged={(from) => {
                          const _conditions = conditionsGet(
                            { from },
                            index,
                            conditions
                          );

                          setConditions(_conditions);

                          appInputParamChangedHandle({
                            conditions: _conditions
                          });
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className='new-input-wrap'>
                      <label className='label-text'>To</label>

                      <Input
                        size='sm'
                        label=''
                        type='number'
                        required={false}
                        placeholder='Enter To Value'
                        value={to || ''}
                        inputChanged={(to) => {
                          const _conditions = conditionsGet(
                            { to },
                            index,
                            conditions
                          );

                          setConditions(_conditions);

                          appInputParamChangedHandle({
                            conditions: _conditions
                          });
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className='new-input-wrap'>
                      <label className='label-text'>Alias</label>

                      <Input
                        size='sm'
                        label=''
                        type='text'
                        required={false}
                        placeholder='Enter Alias'
                        value={alias || ''}
                        inputChanged={(alias) => {
                          const _conditions = conditionsGet(
                            { alias },
                            index,
                            conditions
                          );

                          setConditions(_conditions);

                          appInputParamChangedHandle({
                            conditions: _conditions
                          });
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className='new-input-wrap'>
                      <label className='label-text'>Colour</label>

                      <Input
                        size='sm'
                        label=''
                        type='color'
                        required={false}
                        placeholder='Enter Colour'
                        value={color || ''}
                        inputChanged={(color) => {
                          const _conditions = conditionsGet(
                            { color },
                            index,
                            conditions
                          );

                          setConditions(_conditions);

                          appInputParamChangedHandle({
                            conditions: _conditions
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={1} className='text-right'>
                <div className='row-btn-wrap'>
                  <label></label>

                  <button
                    className='icon-button'
                    onClick={() => {
                      const _conditions = [
                        ...conditions.slice(0, index),
                        ...conditions.slice(index + 1)
                      ];

                      setConditions(_conditions);

                      setConditionCount((conditionCount) => --conditionCount);

                      appInputParamChangedHandle({
                        conditions: _conditions
                      });
                    }}
                  >
                    <i className='fa fa-trash' aria-hidden='true'></i>
                  </button>
                </div>
              </Col>
            </Row>
          </Fragment>
        );
      });
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <p className='head-label-text'>Select Type</p>

        <div className='new-input-wrap'>
          <RadioButtons
            noOfButtons={2}
            getButtonLableAtIndex={(index) => {
              return runhourTypeCollection[index];
            }}
            buttonClickAtIndex={(index) => {
              const _runhourType = runhourTypeCollection[index];

              setRunhourType(_runhourType);

              appInputParamChangedHandle({ runhourType: _runhourType });
            }}
            active={runhourTypeActiveIndex}
          />
        </div>

        <div className='content-border-wrap minh100_42'>
          {runhourTypeActiveIndex === 1 && (
            <div className='custom-range-wrap'>
              {renderConditions()}

              <button
                className='icon-text-btn mg-b-20'
                onClick={() => {
                  setConditionCount((conditionCount) => ++conditionCount);
                }}
              >
                <i className='fa fa-plus-circle' aria-hidden='true'></i> Add
                Condition
              </button>
            </div>
          )}

          {renderParameterConfig()}
        </div>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default OtherConfig;
