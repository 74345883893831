import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import { CheckBox, Button } from '../../../../../../../common/components';

const OthersConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [showUnhealthyAssetCount, setShowUnhealthyAssetCount] = useState();

  const [showGraphDuringVisualization, setShowGraphDuringVisualization] =
    useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams.assetHealthCountConfig &&
        props.widgetParams.assetHealthCountConfig.value;

      setShowUnhealthyAssetCount(conf.showUnhealthyAssetCount);

      setShowGraphDuringVisualization(conf.showGraphDuringVisualization);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      assetHealthCountConfig: {
        value: {
          showUnhealthyAssetCount,
          showGraphDuringVisualization,
          ...object
        }
      }
    };

    props.appInputParamChanged(value);
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <Row>
          <Col md={12}>
            <div className='new-input-wrap'>
              <div className='new-custom-checkbox'>
                <CheckBox
                  label={'Show unhealthy asset count'}
                  size='sm'
                  checked={showUnhealthyAssetCount}
                  checkboxClicked={(showUnhealthyAssetCount) => {
                    setShowUnhealthyAssetCount(showUnhealthyAssetCount);

                    appInputParamChangedHandle({
                      showUnhealthyAssetCount
                    });
                  }}
                />
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className='new-input-wrap'>
              <div className='new-custom-checkbox'>
                <CheckBox
                  label={'Show graph during visualization'}
                  size='sm'
                  checked={showGraphDuringVisualization}
                  checkboxClicked={(showGraphDuringVisualization) => {
                    setShowGraphDuringVisualization(
                      showGraphDuringVisualization
                    );

                    appInputParamChangedHandle({
                      showGraphDuringVisualization
                    });
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default OthersConfig;
