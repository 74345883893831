import React, { Fragment, useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container } from 'reactstrap';
import ClickOutHandler from 'react-onclickout'
// const ClickOutHandler = require('react-onclickout');
import { isNotificationPopUp, getAllNotifications, updateNotificationStatus, updateNotificationStatusReset, turnOffNotification, notificationCategory, updateTurnOffNotificationReset, getAllNotificationCount, getNotificationSettings } from './action';
import { updateAlertLogStatus } from '../Alert/action';
import { getAlertNotifications, getReportNotifications, getInsightNotificaitons } from './getNotifications';
import CommonNotification from './components/AllNotifications/CommonNotification';
import { NOTIFICATION_TYPES } from './constant';
import {Loader} from '../../../common/components';
import { Context, UserContext } from '../Main/context';
import { NOT_ACCESS_MESSAGE } from '../Main/appSetting';
import { NOTIFICATION } from '../Main/permissionContants';

const INITIAL_OFFSET = 0;
const LIMIT = 4;

function sortNotification(props) {
    const context = useContext(Context);
    const userContext = useContext(UserContext);

    const [isOpenAction, setActionOpen] = useState(false);
    const [selectedId, setSelectedID] = useState(null);
    const [notification, setNotificaions] = useState([]);
    const [notificationId, setNotificationId] = useState("")

    const [showMore, setShowMore] = useState(true);
    const [index, setIndex] = useState(LIMIT);
    const [isOpen, clickOutSide] = useState(false);

    useEffect(() => {
        // props.getNotificationSettings({});
        props.getAllNotifications({
            page_no: 1,
            page_size: 20,
        });
        props.notificationCategory({
            page_no: 1,
            page_size: 20
        })
        props.getNotificationSettings();
    }, []);

    useEffect(() => {
        if (props.allNotifications && props.allNotifications.data && Array.isArray(props.allNotifications.data) && props.allNotifications.data.length > 0) {
            setNotificaions(props.allNotifications.data.slice(INITIAL_OFFSET, LIMIT));
        }
        clickOutSide(true);
    }, [props.allNotifications]);

    useEffect(() => {
        if (props.notificationStatus && props.notificationStatus.success) {
            props.updateNotificationStatusReset();
            // context.notify.success('Mark as read');
        }
        if (props.turnOffNotification && props.turnOffNotification.success) {
            props.updateTurnOffNotificationReset();
            context.notify.success('Updated notificaiton status');
        }
    }, [props.notificationStatus, props.turnOffNotification]);

    const loadMore = () => {
        if (props.allNotifications && props.allNotifications.data && Array.isArray(props.allNotifications.data) && props.allNotifications.data.length > 0) {
            const newIndex = index + LIMIT;
            if (props.allNotifications.data.length > newIndex) {
                setShowMore(true);
            } else setShowMore(false);
            let onLoadList = props.allNotifications.data.slice(0, newIndex);
            setIndex(newIndex);
            setNotificaions(onLoadList);
        }
    }

    const genericNotification = ({ hyperLinkMsg, icon, index, item, redirectLink, priorityLevel }) => {
        let singleNotify = notification && notification.length == 1;
        return <Col sm={12} key={index}>
            <div className={singleNotify && !item.status ? "blue-card first-card-round" : index == 0 && !item.status ? "blue-card first-card" : !item.status ? 'blue-card' : singleNotify && item.status ? 'blue-card read-status first-card-round' : index == 0 && item.status ? 'blue-card read-status first-card' : item.status ? 'blue-card read-status' : ''}>
                <CommonNotification
                    data={{ hyperLinkMsg, icon, index, item, redirectLink, selectedId, isOpenAction, priorityLevel }}
                    history={props.history}
                    // isNotificationPopUp={() => props.isNotificationPopUp(false)}
                    isNotificationPopUp={() => context.notification.show(!context.notification.isShow)}
                    setSelectedID={() => setSelectedID(item.id)}
                    // setActionOpen={() => setActionOpen(true)}
                    setActionOpen={flag => setActionOpen(flag)}
                    setNotificationId={setNotificationId}
                    notificationId={notificationId}
                    onReadStatus={() => {
                        if (userContext.permission(NOTIFICATION.EDIT_NOTFICATION_BY_ID)) {
                            props.updateNotificationStatus({ status: item.status ? 'unread' : 'read', id: item.id });
                            item.status = item.status ? false : true;
                            props.getAllNotificationCount({});
                        } else context.notify.error(NOT_ACCESS_MESSAGE)
                    }}
                    notificationStatus={() => {
                        if (userContext.permission(NOTIFICATION.EDIT_NOTFICATION_BY_ID)) {
                            props.updateNotificationStatus({ status: item.status ? 'unread' : 'read', id: item.id });
                            item.status = item.status ? false : true;
                            props.getAllNotificationCount({});
                        } else context.notify.error(NOT_ACCESS_MESSAGE)
                    }}
                    categories={props.categories.data}
                />

                {/* <h4>
                    <span className={item.category_id == 6 ? 'read-icon' : !item.status ? 'icon' : 'read-icon'}><i className={icon}></i></span>
                    <span className={item.category_id == 6 ? 'notification-msg' : 'notification-msg cursor'} onClick={() => {
                        props.history.push({
                            pathname: redirectLink,
                        });
                        props.isNotificationPopUp(false);
                    }}>{hyperLinkMsg}</span>

                    <span className="float-right dropdown" onClick={() => {
                        setSelectedID(item.id);
                        setActionOpen(true);
                    }}><i className='fas fa-ellipsis-v'></i></span>
                    {item.id === selectedId && isOpenAction && <CustomPopOver
                        left={'mt-26rem'}
                        closePopup={setActionOpen}
                        onReadStatus={() => {
                            props.updateNotificationStatus({ status: item.status ? 'unread' : 'read', id: item.id });
                            item.status = item.status ? false : true;
                            props.getAllNotificationCount({});
                        }}
                        item={item}
                    />}
                </h4>
                {
                    item.type == 'alerts' && !item.status && item.category_id == 1 ? <NotifyActions
                        data={item}
                        notificationStatus={() => {
                            // props.updateNotificationStatus({ status: true, id: item.id });
                            // item.status = true;
                            props.updateNotificationStatus({ status: item.status ? 'unread' : 'read', id: item.id });
                            item.status = item.status ? false : true;
                            props.getAllNotificationCount({});
                        }}
                    /> : null
                } */}
            </div>
        </Col>
    }

    return (
        <ClickOutHandler onClickOut={() => {
            context.notification.show(!context.notification.isShow)
            // props.isNotificationPopUp(false);
            clickOutSide(false);
        }}>
            <div className='header-notification-wrapper'>
                <div>
                    {/* <div className="arrow-top float-right"></div> */}
                    {
                        props.allNotifications && props.allNotifications.success == undefined ? <div className='header-notification-container'>
                            <div className="something-went-wrong">
                                <Loader loading={!props.allNotifications.success} ></Loader>
                            </div>
                        </div> :
                            notification && Array.isArray(notification) && notification.length > 0 ? <div className={props.allNotifications && props.allNotifications.total_record > 0 && props.allNotifications.total_record <= 4 ? 'header-notification-container hight-wrap' : 'header-notification-container'}>
                                <Container>
                                    <div className='title-filter-wrap'>
                                        <h4 className='title-wrap'>Notifications</h4>
                                        <div className='filter-wrap'>
                                            {/* <button>
                                                <i class="fa fa-sliders" aria-hidden="true"></i>
                                            </button> */}                                            
                                    
                                            <h4 className='all-notification-link'>
                                                <a
                                                    onClick={() => {
                                                        props.history.push('/Notification');
                                                        context.notification.show(!context.notification.isShow)
                                                }}>
                                                    View All
                                                </a>
                                            </h4>
                                        </div>
                                    </div>
                                    
                                    {/* <h4 className='all-notification-link'>
                                        <a
                                            onClick={() => {
                                                props.history.push('/Notification');
                                                context.notification.show(!context.notification.isShow)
                                        }}>
                                            View All
                                        </a>
                                        Notifications
                                    </h4> */}
                                    <div className='notifications-list'>

                                        <Row>
                                            {
                                                notification.map((item, index) => {
                                                    // return item.type && item.type == NOTIFICATION_TYPES.ALERTS ? genericNotification(getAlertNotifications(item, index)) : item.type == NOTIFICATION_TYPES.REP0RTS ? genericNotification(getReportNotifications(item, index)) : item.type == NOTIFICATION_TYPES.INSIGHTS ? genericNotification(getInsightNotificaitons(item, index)) : null;

                                                    return item.type && item.type == 'alerts' ? genericNotification(getAlertNotifications(item, index)) : item.type == 'reports' ? genericNotification(getReportNotifications(item, index)) : item.type == 'insights' ? genericNotification(getInsightNotificaitons(item, index)) : null;
                                                })
                                            }
                                            {
                                                props.allNotifications && props.allNotifications.total_record > 4 &&
                                                <Fragment>
                                                    {
                                                        showMore && <Col sm={12}>
                                                            <div className="view-all-notification" onClick={() => loadMore()}>
                                                                <span>LOAD MORE</span>
                                                            </div>
                                                        </Col>
                                                    }
                                                    {!showMore && <Col sm={12}>
                                                        <div className="view-all-notification" onClick={() => {
                                                            props.history.push('/Notification');
                                                            context.notification.show(!context.notification.isShow)
                                                            // props.isNotificationPopUp(false);
                                                        }}>
                                                            <span>VIEW ALL</span>
                                                        </div>
                                                    </Col>}
                                                </Fragment>
                                            }

                                        </Row>
                                    </div>
                                </Container>
                            </div> : <div className='header-notification-container'>
                                <div className="something-went-wrong">
                                    <span>Something Went Wrong!!!</span>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </ClickOutHandler>
    );
}

const mapStateToProps = state => {
    return {
        isNotificationOpen: state.notificationPopUp.isNotificationOpen,
        allNotifications: state.getAllNotifications,
        notificationStatus: state.updateNotificationStatus,
        turnOffNotification: state.turnOffNotification,
        categories: state.notificationCategory,
    };
};

export default connect(
    mapStateToProps, {
    isNotificationPopUp,
    getAllNotifications,
    updateNotificationStatus,
    updateNotificationStatusReset,
    updateAlertLogStatus,
    turnOffNotification,
    notificationCategory,
    updateTurnOffNotificationReset,
    getAllNotificationCount,
    getNotificationSettings
}
)(sortNotification);