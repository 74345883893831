import Config from 'Config'
import round from 'lodash/round';

export * from './UserRole/constant'
export * from './Main/constant'
export * from '../../common/container/constants'
export * from './Toaster/constant'
export * from './AssetInsights/constant'
export * from './AssetDetails/constant'
export * from './EnterpriseInsights/constant'
export * from './Widgets/constant'
export * from './Alert/constant'
export * from './Co2Emission/constant'

export const URL = Config.api_url;
export const AssetUrl = Config.api_url_v1 + '/../';
//export const URL_V1 = 'https://demo-api.smartsen.se:9093/api';
export const URL_V1 = Config.api_url_v1;
export const NOTIFICATION_URL = Config.api_notification_url;
export const WEATHER_URL = Config.weather_url;
export const WEATHER_APP_ID = "abe181f8fb820050c8c48b876f39fc92";
export const Token = localStorage.getItem('token');
export const ROUND_PRECISION = 2;
export const DEFAULT_COLORS = ['#7990DE', '#E37587', '#FFD56C', '#69C6A9'];

export const READING_TYPE_MAP = {
    2001: {
        'default': 'KWh',
        'converted': 'MWh'
    },
    2002: {
        'default': 'KW',
        'converted': 'MW'
    },
    2008: {
        'default': 'KVA',
        'converted': 'MVA'
    },
};

export const handleValueUnit = (targetValue, readingTypeId, factor = 1000, defaultUnit = '') => {
    let value = parseFloat(targetValue);
    let actualValue = value;
    let unit = defaultUnit;
    try {
        const readingMap = READING_TYPE_MAP[readingTypeId];
        if (readingMap !== undefined) {
            unit = readingMap['default'];
        }
        if (!isNaN(value)) {
            if (value > 1000) {
                value = value / factor;
                unit = readingMap !== undefined ? readingMap['converted'] : '';
            }
            value = round(value, ROUND_PRECISION).toFixed(ROUND_PRECISION);
        } else {
            value = 'N/A';
            actualValue = 0;
        }
    } catch (e) {
        console.error(e);
    }
    return {
        value,
        actualValue,
        unit
    };
};

export const handleNumber = (givenNumber, defaultValue = 'N/A') => {
    const numberData = parseFloat(givenNumber);
    if (!isNaN(numberData)) {
        return round(numberData, ROUND_PRECISION).toFixed(ROUND_PRECISION)
    }
    return defaultValue;
};

export const formatNumber = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        let numberData = parseFloat(amount);
        if (isNaN(numberData)) {
            numberData = 0.0;
        }

        const negativeSign = numberData < 0 ? "-" : "";

        let i = parseInt(numberData = Math.abs(Number(numberData) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(numberData - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
    return 'N/A';
}

export const getLocalItem = (key, defaultValue = '', json = true) => {
    const localValue = localStorage.getItem(key);
    if (localValue === null) {
        return defaultValue;
    }
    return json ? JSON.parse(localValue) : localValue;
};

export const getPercentageChange = (oldNumber, newNumber) => {
    const result = {
        value: 0,
        trend: ''
    };
    oldNumber = parseFloat(oldNumber);
    newNumber = parseFloat(newNumber);
    if ((!isNaN(oldNumber) && !isNaN(newNumber)) && (oldNumber > 0 && newNumber > 0)) {
        let trend = 'up';
        let value = ((oldNumber - newNumber) / oldNumber) * 100;
        if (value < 0) {
            trend = 'down';
            value *= -1;
        } else if (value === 0) {
            trend = 'equal';
        }
        if (!isNaN(value)) {
            result['value'] = round(value, ROUND_PRECISION);
        }
        result['trend'] = trend;
    }
    return result;
};


export const FORMAT = {
    DATE_TIME: "lll",
    DATE: "YYYY-MM-DD",
    DATE_MONTH: "DD MMM",
    DATE_MONTH_YEAR: "DD MMM YY",
    TIME: "HH:mm",
    ISO_DATE_TIME: "YYYY-MM-DD HH:mm:ss",
    MONTH_NAME_DATE: "ll",
    TIME_MERIDIEM: "hh:mm a",
    DATE_MONTH_YEAR_TIME_MERIDIEM: "DD MMM YY hh:mm a",
    DATE_MONTH_YEAR_SLASH: "DD/MM/YYYY",
    MONTH_DATE_YEAR_TIME: 'MMM DD, YYYY HH:mm',
    MONTH_DATE_YEAR:'MMM DD, YYYY'
};

export const ASSETS = 'https://s3.ap-south-1.amazonaws.com/assets.smartsen.se/public/assets/images';