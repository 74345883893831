import React, { Fragment, useEffect, useState } from 'react';

import plantSensorConfGet from '../common/plantSensorConfGet';
import plantSensorConfsOutputGet from '../common/plantSensorConfsOutputGet';
import valueGet from '../common/valueGet';
import PlantWiseConfiguration from '../Common/PlantWiseConfiguration';
import { Button } from '../../../../../../../common/components';

const plantConfsGet = (plantConfs) => {
  const _plantConfs =
    plantConfs &&
    plantConfs.reduce((memo, plantConf) => {
      return [...(memo || []), plantConf];
    }, null);

  return _plantConfs ? _plantConfs : [plantSensorConfGet(0)];
};

const OthersConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [plantConfs, setPlantConfs] = useState();

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf = props.widgetParams;

      const plantSensorConfs = conf.parameters && conf.parameters.value;

      setPlantConfs(plantConfsGet(plantSensorConfs));

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      parameters: valueGet(plantSensorConfsOutputGet(plantConfs)),
      ...object
    };

    props.appInputParamChanged(value);
  };

  const renderPlantWiseConfiguration = () => {
    return (
      <PlantWiseConfiguration
        plantConfs={plantConfs}
        onPlantConfsChange={(plantConfs) => {
          setPlantConfs(plantConfs);

          appInputParamChangedHandle({
            parameters: valueGet(plantSensorConfsOutputGet(plantConfs))
          });
        }}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        <p className='head-label-text mg-b-15'>Plant Configuration</p>

        {renderPlantWiseConfiguration()}

        <div className='button-wrapper'>
          <Button
            buttonType='primary'
            size='md'
            innerContent='Back'
            className='buttonBorder'
            buttonClick={() => {
              props.onTabChangeTrigger(-1);
            }}
          />

          <Button
            buttonType='primary'
            size='md'
            innerContent='Next'
            className='buttonFill'
            buttonClick={() => {
              props.onTabChangeTrigger(1);
            }}
          />
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default OthersConfig;
