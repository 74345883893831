import DateTime from "../../../common/utils/dateTimeUtils";
import { FORMAT } from "../constants";
import { capitalizeFirstLetter, getUserName } from "../utils";
import { NOTIFICATION_CATEGORIES } from './constant';


export const getAlertNotifications = (item, index) => {

    let { status, id, message } = item.content && item.content;
    let { category_id, custom_type_id, type_id, name, priority, created_at, created_by, username } = item;
    let icon = 'fas fa-exclamation-circle';
    var user_name;
    if (created_by) {
        user_name = getUserName(created_by);
    } else user_name = username;
    let date = DateTime.format(created_at, FORMAT.MONTH_DATE_YEAR_TIME);
    let alertidAndName = ` ${custom_type_id} - ${name} is ${status} at ${date}`;
    let deletedAlert = ` ${item.content && item.content.custom_type_id ? item.content.custom_type_id : ''} - ${item.content && item.content.name}`;
    let priorityLevel;
    if (category_id == NOTIFICATION_CATEGORIES.AlERT_DELETED) {
        priorityLevel = item.content && item.content.priority
    } else priorityLevel = priority

    var redirectLink;
    if (category_id == NOTIFICATION_CATEGORIES.AlERT_GENERATED) {
        localStorage.setItem('activeAlertLogIndex', 0);
        redirectLink = `/Alert/AlertDetails/${custom_type_id}/${name}?page=alert-logs`
    } else if ((category_id == NOTIFICATION_CATEGORIES.AlERT_PAUSED) || (category_id == NOTIFICATION_CATEGORIES.AlERT_PAUSED_FOR_MORE_THAN_7_DAYS) || (category_id == NOTIFICATION_CATEGORIES.AlERT_PRIORITY_CAHNGED)) {
        localStorage.setItem('activeAlertLogIndex', 1);
        redirectLink = `/Alert/AlertDetails/${custom_type_id}/${name}?page=alert-logs`
    } else if (category_id !== NOTIFICATION_CATEGORIES.AlERT_DELETED) {
        redirectLink = `/Alert/AlertDetails/AlertLogHistory/${id}/${custom_type_id}/${name}`;
    }

    let hyperLinkMsg = category_id == NOTIFICATION_CATEGORIES.AlERT_GENERATED ? ` ${alertidAndName}` : category_id == NOTIFICATION_CATEGORIES.AlERT_RESOLVED ? ` ${alertidAndName} by ${user_name}` : category_id == NOTIFICATION_CATEGORIES.AlERT_PAUSED ? ` ${alertidAndName} by ${user_name}` : category_id == NOTIFICATION_CATEGORIES.AlERT_ACKNOWLEGE ? ` ${alertidAndName} by ${user_name}` : category_id == NOTIFICATION_CATEGORIES.AlERT_PRIORITY_CAHNGED ? ` ${custom_type_id} - ${name} ${message} by ${user_name}` : category_id == NOTIFICATION_CATEGORIES.AlERT_DELETED ? ` ${deletedAlert} is deleted at ${date} by ${user_name}` : category_id == NOTIFICATION_CATEGORIES.AlERT_NOT_RESOLVED ? ` ${custom_type_id} - ${name} ${message}` : category_id == NOTIFICATION_CATEGORIES.AlERT_PAUSED_FOR_MORE_THAN_7_DAYS ? ` ${custom_type_id} - ${name} ${message}` : category_id == NOTIFICATION_CATEGORIES.HIGH_PRIORITY_ALERT ? ` ${custom_type_id} - ${name} with "High" priority is not acted upon from last 4 hours` : null;

    return { item, icon, hyperLinkMsg, index, redirectLink, priorityLevel };
}

export const getReportNotifications = (item, index) => {
    let { category_id, custom_type_id, id, type_id, name, priority, created_at, created_by, username } = item;
    let { status, message } = item.content && item.content;
    let icon = 'fas fa-file';

    let user_name;
    if (created_by) {
        user_name = getUserName(created_by)
    } else user_name = username;

    let reportName = ` ${custom_type_id} - ${name}`;
    let date = DateTime.format(created_at, FORMAT.MONTH_DATE_YEAR_TIME);
    let addedEmailList = `${message} ${reportName} at ${date} by ${user_name}`

    let hyperLinkMsg = category_id == NOTIFICATION_CATEGORIES.REPORT_GENERATED ? `${reportName} is generated at ${date} Please click to check more details.` : category_id == NOTIFICATION_CATEGORIES.REPORT_ADDED_EMAIL ? addedEmailList : category_id == NOTIFICATION_CATEGORIES.REPORT_REMOVED_EMAIL ? addedEmailList : null;
    let redirectLink = `/Report?tab=Report-List`;

    return { item, icon, hyperLinkMsg, index, redirectLink };
}

export const getInsightNotificaitons = (item, index) => {
    let icon = 'far fa-lightbulb';
    let redirectLink = `AssetInsights`;
    let { category_id, custom_type_id, id, type_id, name, priority, created_at, created_by, username } = item;
    let { status, message, insight_count} = item.content && item.content;

    let user_name;
    if (created_by) {
        user_name = getUserName(created_by)
    } else user_name = username;

    let date = DateTime.format(created_at, FORMAT.MONTH_DATE_YEAR_TIME);
    let contentMessage = `${name} is ${status} at ${date}`;

    let priorityLevel = priority;

    let hyperLinkMsg = category_id == NOTIFICATION_CATEGORIES.INSIGHT_GENERATED ? `${contentMessage}` : category_id == NOTIFICATION_CATEGORIES.INSIGHT_IGNORED ? `${contentMessage} by ${user_name}` : category_id == NOTIFICATION_CATEGORIES.INSIGHT_RESOLVED ? `${contentMessage} by ${user_name}` : category_id == NOTIFICATION_CATEGORIES.INSIGHT_NOT_ACTED_UPON ? `There are ${insight_count} insights which are open for more than 2 days` : category_id == NOTIFICATION_CATEGORIES.INSIGHT_IGNORED_MIN_25 ? `There are ${insight_count} insights which are ignored in last few weeks` : null;

    return { item, icon, hyperLinkMsg, index, redirectLink, priorityLevel };

}