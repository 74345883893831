import { SHOW_CONFIRMBOX } from '../constants'
import { HIDE_CONFIRMBOX } from './constant'

export function showConfirmBox(value) {
  return {
    type: SHOW_CONFIRMBOX,
    payload: value
  }
}

export function hideConfirmBox(value) {
  return {
    type: HIDE_CONFIRMBOX,
    payload: value
  }
}