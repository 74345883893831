import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';

import valueGet from './ConfigComponents/common/valueGet';
import { getTemplateWidgets } from '../../action';
import { getSystemTemplateWidgets } from '../../../AssetScreenDesign/action';
import SelectDropDown from '../../../../../common/components/Select';
import { Button, CheckBox } from '../../../../../common/components';
import { Label, Col, Row } from 'reactstrap';

const PAGE_SIZE = 200;

const _widgetList = [''];

const _config = {
  systemInsights: false,
  systemAssetInsights: false,
  networkInsights: false,
  systemAlerts: false,
  showAllInsights: false,
  gropu_widget_select: _widgetList
};

const _valueGet = (object) => {
  return Object.entries(object).reduce(
    (memo, [key, value]) => {
      return {
        ...memo,
        [key]: valueGet(value)
      };
    },
    Object.entries(_config).reduce((memo, [key, value]) => {
      return {
        ...memo,
        [key]: valueGet(value)
      };
    }, {})
  );
};

const WidgetSelect = (_props) => {
  const { getTemplateWidgets, getSystemTemplateWidgets, ...props } = _props;

  const location = useLocation();

  const {
    assetId: assetLocationId,
    system_id,
    system_type_id,
    template_id
  } = parse(location.search);

  const [widgetList, setWidgetList] = useState(_widgetList);

  const widgetsData = useMemo(() => {
    switch (true) {
      case location.state?.systemFlag && !!props._widgetsData:
        return props._widgetsData;

      case !!props.widgetsData:
        return props.widgetsData;

      default:
        return [];
    }
  }, [location.state, props.widgetsData, props._widgetsData]);

  useEffect(() => {
    switch (true) {
      case location.state?.systemFlag:
        return getSystemTemplateWidgets({ system_id: template_id });

      default:
        return getTemplateWidgets({
          template_id: props.dashobardId,
          page_no: 1,
          page_size: PAGE_SIZE
        });
    }
  }, [
    location.state,
    template_id,
    props.dashobardId,
    getTemplateWidgets,
    getSystemTemplateWidgets
  ]);

  useEffect(() => {
    if (Array.isArray(props.value) && props.value.length > 0) {
      setWidgetList(props.value);
    }
  }, [props.value]);

  const editWidgetConfiguration = (widget) => {
    switch (true) {
      case location.state.systemFlag:
        return props.history.push({
          pathname: `/System/WidgetBuilder/${template_id}/${widget.widget_id.id}`,
          search: stringify({
            edit_id: widget.id,
            system_id,
            system_type_id,
            template_id
          }),
          state: {
            ...location.state,
            widget: widget
          }
        });

      default:
        return props.history.push({
          pathname: `/Templates/WidgetBuilder/${props.dashobardId}/${widget.widget_id.id}`,
          search: stringify({
            assetId: assetLocationId,
            edit_id: widget.id
          }),
          state: {
            ...location.state,
            widget: widget
          }
        });
    }
  };

  return (
    <div className='w-100'>
      <Row className='mb-2'>
        <Col>
          <CheckBox
            size='lg'
            label='System Insights'
            checked={props.systemInsights}
            checkboxClicked={(systemInsights) => {
              props.onChange(
                _valueGet({
                  systemInsights
                }),
                false,
                ' '
              );
            }}
            className='ml-3'
          />
        </Col>
      </Row>

      <Row className='mb-2'>
        <Col>
          <CheckBox
            size='lg'
            label='System Asset Insights'
            checked={props.systemAssetInsights}
            checkboxClicked={(systemAssetInsights) => {
              props.onChange(
                _valueGet({
                  systemAssetInsights
                }),
                false,
                ' '
              );
            }}
            className='ml-3'
          />
        </Col>
      </Row>

      <Row className='mb-2'>
        <Col>
          <CheckBox
            size='lg'
            label='Network Insights'
            checked={props.networkInsights}
            checkboxClicked={(networkInsights) => {
              props.onChange(
                _valueGet({
                  networkInsights
                }),
                false,
                ' '
              );
            }}
            className='ml-3'
          />
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col>
          <CheckBox
            size='lg'
            label='System Alerts'
            checked={props.systemAlerts}
            checkboxClicked={(systemAlerts) => {
              props.onChange(
                _valueGet({
                  systemAlerts
                }),
                false,
                ' '
              );
            }}
            className='ml-3'
          />
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col>
          <CheckBox
            size='lg'
            label='Show All Insights'
            checked={props.showAllInsights}
            checkboxClicked={(showAllInsights) => {
              props.onChange(
                _valueGet({
                  showAllInsights
                }),
                false,
                ' '
              );
            }}
            className='ml-3'
          />
        </Col>
      </Row>

      <Col
        className={`select-dropdown custom-select-parameter ${
          props.systemInsights ||
          props.systemAssetInsights ||
          props.networkInsights ||
          props.showAllInsights ||
          props.systemAlerts
            ? 'disabled'
            : ''
        }`}
        xl={props.width ? props.width : 12}
      >
        <Row>
          <Col>
            <div className='add-widget-btn'>
              <Button
                className='buttonFill'
                buttonType='primary'
                size='sm'
                buttonClick={() => {
                  let oldWidgetList = [...widgetList];

                  oldWidgetList.push('');

                  setWidgetList([...oldWidgetList]);
                }}
                innerContent='Add Widget'
              />
            </div>
          </Col>
        </Row>

        {widgetList.map((item, i) => {
          return (
            <Row key={i} className='mb-3'>
              <span
                className='text-danger delete'
                onClick={() => {
                  let oldWidgetList = [...widgetList];

                  oldWidgetList.splice(i, 1);

                  setWidgetList([...oldWidgetList]);

                  props.onChange([...oldWidgetList]);
                }}
              >
                Delete
              </span>

              <span
                className='text-edit edit'
                onClick={() => {
                  let a = widgetsData.filter((elem) => {
                    return elem.id === item;
                  })[0];
                  editWidgetConfiguration(a);
                }}
              >
                Edit
              </span>

              <Col xl={12}>
                <Label for={props.name} className='label'>
                  Select Widget
                  {props.required === true ? (
                    <span className='text-danger'> * </span>
                  ) : null}
                </Label>

                <SelectDropDown
                  name='widget'
                  options={widgetsData
                    .filter((w) => {
                      return !widgetList.includes(w.id);
                    })
                    .map((a) => {
                      a.label =
                        a.config && a.config.widget_name
                          ? a.config.widget_name
                          : '';
                      a.value = a.id;
                      return a;
                    })}
                  value={
                    widgetsData
                      .filter((elem) => {
                        return elem.id === item;
                      })
                      .map((el) => {
                        el.label =
                          el.config && el.config.widget_name
                            ? el.config.widget_name
                            : '';
                        el.value = el.id;
                        return el;
                      })[0]
                  }
                  onChange={(e) => {
                    let oldWidgetList = [...widgetList];

                    oldWidgetList[i] = e.id;

                    setWidgetList([...oldWidgetList]);

                    props.onChange([...oldWidgetList]);
                  }}
                  placeholder={props.placeholder}
                  isMulti={false}
                />
              </Col>
            </Row>
          );
        })}
      </Col>
    </div>
  );
};

const filterdWidgetsDataGet = (widgetsData) => {
  return widgetsData?.filter((wid) => {
    let type = wid.widget_id?.type;

    return !['Group', 'Container'].includes(type);
  });
};

const mapStateToProps = (state) => {
  return {
    widgetsData: filterdWidgetsDataGet(state.getTemplateWidgets.data),
    _widgetsData: filterdWidgetsDataGet(state.systemTemplateWidgets.data)
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getTemplateWidgets,
    getSystemTemplateWidgets
  })(WidgetSelect)
);
