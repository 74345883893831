import React, { Fragment } from 'react';

import usePlantTagCollection from '../common/usePlantTagCollection';
import { Select } from '../../../../../../../common/components';

const TagPlantSelect = (props) => {
  const [plantCollection, plantOptions, , , onPlantChangeHandle] =
    usePlantTagCollection(props.tagParameterConfs, props.allPlantFlag);

  return (
    <Fragment>
      <div className='d-flex justify-content-between'>
        <label className='label-text'>
          Plant <span className='text-danger'>*</span>
        </label>
      </div>

      <Select
        isMulti={false}
        placeholder='Select Plant'
        options={plantOptions.reduce((memo, plant) => {
          const match = (props.plantCollection || []).find(
            ({ value: { plantId } }) => plantId === plant.value.plantId
          );

          return !match ? [...memo, plant] : memo;
        }, [])}
        value={(() => {
          const option = plantCollection[0];

          const value = option && option.value;

          return value && value.plantId && option;
        })()}
        onChange={(plant) => {
          onPlantChangeHandle(plant, 0);

          props.onChange(plant);
        }}
        isDisabled={false}
      />
    </Fragment>
  );
};

export default TagPlantSelect;
