import React from "react";
import { Fragment } from "react";
import searchIcon from '../../../../../../assets/images/user-search.svg';
import { Input } from '../../../../../common/components';

function CommonSearch(props) {
    return <Fragment>
        {/* {
            <div className='search-bar'>
                <i className="fas fa-times fa-lg crossIcon icon" onClick={() => props.setSearchBar(false)} aria-hidden="true"></i>
                <Input
                    name='to'
                    size='sm'
                    value={props.searchValue}
                    type='input'
                    inputChanged={(value) => props.setSearchValue(value)}
                    width={12}
                    required={false}
                    placeholder='Search...'
                    autoFocus={true}
                />
            </div>
        } */}
        <span className='select-partner-title'>
            {props.title}
            {
                // !props.isSearchBarOpen && <div className='float-right'>
                //     <img
                //         src={searchIcon}
                //         alt="smartsense"
                //         height={16}
                //         onClick={() => props.setSearchBar(true)}
                //     />
                // </div>
            }
        </span>
        <div className='search-bar'>
            {/* <i className="fas fa-times fa-lg crossIcon icon" onClick={() => props.setSearchBar(false)} aria-hidden="true"></i> */}
            {/* <i className="fa fa-search" aria-hidden="true"></i> */}
            <Input
                name='to'
                size='sm'
                value={props.searchValue}
                type='input'
                inputChanged={(value) => props.setSearchValue(value)}
                width={12}
                required={false}
                placeholder='Search...'
                autoFocus={true}
            />
        </div>
    </Fragment>
}

export default CommonSearch;