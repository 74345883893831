export const GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA =
  'GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA';

export const GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA_SUCCESS =
  'GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA_SUCCESS';

export const GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA_FAILURE =
  'GET_ASSET_HEALTH_COUNT_CBM_ASSETS_DATA_FAILURE';

export const GET_ASSET_HEALTH_COUNT_ASSETS_DATA =
  'GET_ASSET_HEALTH_COUNT_ASSETS_DATA';

export const GET_ASSET_HEALTH_COUNT_ASSETS_DATA_SUCCESS =
  'GET_ASSET_HEALTH_COUNT_ASSETS_DATA_SUCCESS';

export const GET_ASSET_HEALTH_COUNT_ASSETS_DATA_FAILURE =
  'GET_ASSET_HEALTH_COUNT_ASSETS_DATA_FAILURE';
