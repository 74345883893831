import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import TagPlantSelect from './TagPlantSelect';
import TagParameterSelect from './TagParameterSelect';
import { Input } from '../../../../../../../common/components';

const TagParameterConfigNormal = (props) => {
  const [plant, setPlant] = useState();

  useEffect(() => {
    props.plant && setPlant(props.plant);
  }, [props.plant]);

  return (
    <Fragment>
      <Row className='px-3'>
        {!props.aliasVisiblityToggle && (
          <Col
            md={props.inputFieldColumnWidth || 4}
            className='select-dropdown'
          >
            <div className='d-flex justify-content-between'>
              <label className='label-text'>Alias</label>
            </div>

            <Input
              size='sm'
              label=''
              type='text'
              required={false}
              placeholder='Enter Alias'
              value={props.tagParameterConfs[0].alias || ''}
              inputChanged={(alias) => {
                alias !== props.tagParameterConfs[0].alias &&
                  props.onParameterTagConfsChange({
                    ...props.tagParameterConfs[0],
                    alias
                  });
              }}
              className='p-0 m-0'
            />
          </Col>
        )}

        {!props.plant && (
          <Col
            md={props.inputFieldColumnWidth || 4}
            className='select-dropdown'
          >
            <TagPlantSelect
              allPlantFlag={props.allPlantFlag}
              tagParameterConfs={props.tagParameterConfs}
              onChange={setPlant}
            />
          </Col>
        )}

        <Col md={props.inputFieldColumnWidth || 4} className='select-dropdown'>
          <TagParameterSelect
            blankVisiblityToggle={props.blankVisiblityToggle}
            plant={plant}
            tagParameterConfs={props.tagParameterConfs}
            onChange={props.onParameterTagConfsChange}
          />
        </Col>
      </Row>

      <Row>
        {!props.unitVisiblityToggle && (
          <Fragment>
            <Col
              md={props.inputFieldColumnWidth || 4}
              className='select-dropdown'
            >
              <Input
                type='text'
                label='Unit Prefix'
                placeholder='Enter Unit Prefix'
                required={false}
                value={props.tagParameterConfs[0].unitPrefix || ''}
                inputChanged={(unitPrefix) => {
                  props.onParameterTagConfsChange({
                    ...props.tagParameterConfs[0],
                    unitPrefix
                  });
                }}
                className='p-0 pl-3 m-0'
              />
            </Col>

            <Col
              md={props.inputFieldColumnWidth || 4}
              className='select-dropdown'
            >
              <Input
                type='text'
                label='Unit Suffix'
                placeholder='Enter Unit Suffix'
                required={false}
                value={props.tagParameterConfs[0].unitSuffix || ''}
                inputChanged={(unitSuffix) => {
                  props.onParameterTagConfsChange({
                    ...props.tagParameterConfs[0],
                    unitSuffix
                  });
                }}
                className='p-0 m-0'
              />
            </Col>
          </Fragment>
        )}
      </Row>

      {props.colorVisiblityToggle && (
        <Row>
          <Col
            md={props.inputFieldColumnWidth || 4}
            className='select-dropdown'
          >
            <Input
              type='color'
              label='Color'
              placeholder='Chart Color'
              value={props.tagParameterConfs[0].color || ''}
              inputChanged={(color) => {
                color !== props.tagParameterConfs[0].color &&
                  props.onParameterTagConfsChange({
                    ...props.tagParameterConfs[0],
                    color
                  });
              }}
              className='p-0 pl-3 m-0'
            />
          </Col>
        </Row>
      )}

      {!!props.Component && (
        <Fragment>
          {props.Component(
            props.tagParameterConfs,
            props.onParameterTagConfsChange
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default TagParameterConfigNormal;
