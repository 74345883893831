import React from 'react';

import sortIconLight from '../../../../assets/images/sorting-icon-light.svg';
import sortIconDark from '../../../../assets/images/sorting-icon-dark.svg';

const ReorderTrigger = (props) => {
  return (
    <div className='reorder-trigger'>
      <span className='reorder-block'>
        <a
          href='#'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <img
            src={sortIconLight}
            className='logo light-theme-sort-img sort-icon'
            style={props.reorderableShow ? { display: 'none' } : {}}
            onClick={() => props.onSetReorderableShow(true)}
            alt='smartsense'
          />
        </a>

        <a
          href='#'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <img
            src={sortIconDark}
            className='logo dark-theme-sort-img sort-icon'
            style={props.reorderableShow ? { display: 'none' } : {}}
            onClick={() => props.onSetReorderableShow(true)}
            alt='smartsense'
          />
        </a>
      </span>
    </div>
  );
};

export default ReorderTrigger;
