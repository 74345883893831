import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import parameterConfsInputGet from '../common/parameterConfsInputGet';
import useSensorParameterCollection from '../common/useSensorParameterCollection';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import SensorParameterSelect from '../Common/SensorParameterSelect';
import { Input, Select } from '../../../../../../../common/components';

const grainCollection = ['15 min', '30 min', '60 min', '1 Day'];

const parameterConfsGet = (value) => {
  const parameterConfs = value && [{ locParam: value }];

  return parameterConfsInputGet(parameterConfs);
};

const _parameterConfsGet = (parameterConfs) => {
  const _parameterConfs = parameterConfsOutputGet(parameterConfs);

  return (
    _parameterConfs &&
    (() => {
      const { locParam } = _parameterConfs[0];

      return { locParam };
    })()
  );
};

const isInValidGet = (value) => {
  switch (true) {
    case !value.dayEndTime:
    case !value.dayStartTime:
    case !value.locParam:
      return true;

    default:
      return false;
  }
};

const ParametersConfig = (props) => {
  const [initialized, setInitialized] = useState();

  const [parameterConfs, setParameterConfs] = useState();

  const [grain, setGrain] = useState();

  const [dayStartTime, setDayStartTime] = useState();

  const [dayEndTime, setDayEndTime] = useState();

  const [
    sensorCollection,
    sensorOptions,
    parameterCollection,
    parameterOptionsCollection,
    onSensorChangeHandle,
    onParameterChangeHandle
  ] = useSensorParameterCollection(parameterConfs || []);

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams.digitboxConfig &&
        props.widgetParams.digitboxConfig.value;

      setParameterConfs(parameterConfsGet(conf && conf.locParam));

      setGrain((conf && conf.grain) || grainCollection[0]);

      setDayStartTime(conf && conf.dayStartTime);

      setDayEndTime(conf && conf.dayEndTime);

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const value = {
      type: props.type,
      grain,
      dayStartTime,
      dayEndTime,
      ..._parameterConfsGet(parameterConfs),
      ...object
    };

    const isInValid = isInValidGet(value);

    props.appInputParamChanged({
      digitboxConfig: {
        value,
        isInValid
      }
    });
  };

  const renderFn = () => {
    return (
      <div className='content-border-wrap minh100_42'>
        <Row>
          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Location *</label>

              <SensorParameterSelect
                type='sensor'
                options={sensorOptions}
                value={sensorCollection[0] || ''}
                onChange={(sensor) => {
                  onSensorChangeHandle(sensor, 0);
                }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Parameter *</label>

              <SensorParameterSelect
                type='parameter'
                options={parameterOptionsCollection[0] || []}
                value={parameterCollection[0] || ''}
                onChange={(parameter) => {
                  onParameterChangeHandle(parameter, 0, (parameterConf) => {
                    const parameterConfs = [parameterConf];

                    setParameterConfs(parameterConfs);

                    appInputParamChangedHandle(
                      _parameterConfsGet(parameterConfs)
                    );
                  });
                }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Data Frequency</label>

              <Select
                options={grainCollection.reduce((memo, value) => {
                  return [
                    ...memo,
                    {
                      label: value,
                      value
                    }
                  ];
                }, [])}
                isMulti={false}
                placeholder='Select Data Frequency'
                value={(() => {
                  return (
                    grain && {
                      label: grain,
                      value: grain
                    }
                  );
                })()}
                onChange={({ value }) => {
                  setGrain(value);

                  appInputParamChangedHandle({ grain: value });
                }}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Day Start Time</label>

              <Input
                size='sm'
                label=''
                type='time'
                required={false}
                placeholder='Day Start Time'
                className='time-input'
                value={dayStartTime || ''}
                inputChanged={(_dayStartTime) => {
                  setDayStartTime(_dayStartTime);

                  appInputParamChangedHandle({ dayStartTime: _dayStartTime });
                }}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className='new-input-wrap'>
              <label className='label-text'>Day End Time</label>

              <Input
                size='sm'
                label=''
                type='time'
                required={false}
                placeholder='Day End Time'
                className='time-input'
                value={dayEndTime || ''}
                inputChanged={(_dayEndTime) => {
                  setDayEndTime(_dayEndTime);

                  appInputParamChangedHandle({ dayEndTime: _dayEndTime });
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParametersConfig;
