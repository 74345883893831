export default (index, parameterCollection, sensorCollection = []) => {
  const disabled = (() => {
    switch (true) {
      case parameterCollection.slice(0, index).some((parameter) => !parameter):
        return true;

      case index && sensorCollection.length < index:
        return true;

      default:
        return false;
    }
  })();

  return disabled;
};