import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, scan } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    GET_COMPUTED_READING_DATA,
    GET_COMPUTED_READING_DATA_SUCCESS,
    GET_COMPUTED_READING_DATA_SUCCESS_TREND,
    GET_COMPUTED_READING_DATA_FAILURE,
    GET_REFRESH_DATA,
    GET_REFRESH_DATA_SUCCESS,
    GET_REFRESH_DATA_FAILURE,
    GET_READING_DATA,
    GET_READING_DATA_SUCCESS,
    GET_READING_DATA_FAILURE,
    URL,
    GET_FAULT_READING_DATA,
    GET_FAULT_READING_DATA_FAILURE,
    GET_FAULT_READING_DATA_SUCCESS,
    GET_FAULT_READING_DATA_SUCCESS_TREND,
    GET_MEMBER_PROFILE_DATA,
    GET_MEMBER_PROFILE_DATA_SUCCESS,
    GET_MEMBER_PROFILE_DATA_FAILURE,
    ERROR,
    AssetUrl,
    GET_COMPUTED_PARAMETER,
    GET_COMPUTED_PARAMETER_SUCCESS,
    GET_COMPUTED_PARAMETER_FAILURE,
    GET_READING_TYPES_PRELOAD,
    GET_READING_TYPES_PRELOAD_SUCCESS,
    GET_READING_TYPES_PRELOAD_FAILURE,
    GET_CUSTOM_DASHBOARD,
    GET_CUSTOM_DASHBOARD_SUCCESS,
    GET_CUSTOM_DASHBOARD_FAILURE,
    CREATE_CUSTOM_DASHBOARD,
    CREATE_CUSTOM_DASHBOARD_SUCCESS,
    CREATE_CUSTOM_DASHBOARD_FAILURE
} from '../constants';
import { httpMethodPost } from '../https';

const getRefreshDataSuccess = (payload) => ({ type: GET_REFRESH_DATA_SUCCESS, payload });
const getMemberProfileDataSuccess = (payload) => ({ type: GET_MEMBER_PROFILE_DATA_SUCCESS, payload });

// const PRELOAD_URL = `${URL}/../../api/users/preload`;
const PRELOAD_URL = `${URL}/users/preload`;

export const getRefreshDataEpic = (action$) => action$.pipe(
    ofType(GET_REFRESH_DATA),
    mergeMap(action => {
        return ajax
            .getJSON(PRELOAD_URL, {
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
            })
            .pipe(
                map((response) => getRefreshDataSuccess(response)),
                catchError((error) =>
                    of(
                        {
                            type: GET_REFRESH_DATA_FAILURE,
                            payload: [],
                            error: true,
                        },
                        { type: ERROR, payload: error },
                    ),
                ),
            )
    })
);

export const getMemberProfileEpic = (action$) =>
    action$.pipe(
        ofType(GET_MEMBER_PROFILE_DATA),
        mergeMap((action) =>
            ajax
                .getJSON(`${URL}/member/profile/`, {
                    Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
                })
                .pipe(
                    map((response) => getMemberProfileDataSuccess(response)),
                    catchError((error) =>
                        of(
                            {
                                type: GET_MEMBER_PROFILE_DATA_FAILURE,
                                payload: [],
                                error: true,
                            },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );

const getReadingDataSuccess = (payload) => ({ type: GET_READING_DATA_SUCCESS, payload });

export const getReadingDataEpic = (action$) =>
    action$.pipe(
        ofType(GET_READING_DATA),
        mergeMap((action) =>
            ajax
                .getJSON(
                    `${AssetUrl}/api/v2/reading/reading_series/?agg_date_offset=0&fromdate=${action.payload.from_date}&todate=${action.payload.to_date}&readingtypeid=${action.payload.readingtypeid}&memberId=${action.payload.memberId}&sensorid=${action.payload.sensorId}&grain=${action.payload.grain}`,
                    { Authorization: `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}` },
                )
                .pipe(
                    map((response) => getReadingDataSuccess([action.payload, response])),
                    catchError((error) =>
                        of(
                            {
                                type: GET_READING_DATA_FAILURE,
                                payload: [],
                                error: true,
                            },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );

const getComputedReadingDataSuccess = (payload) => ({ type: GET_COMPUTED_READING_DATA_SUCCESS, payload });

const getComputedReadingDataSuccessWithTrend = (payload) => ({
    type: GET_COMPUTED_READING_DATA_SUCCESS_TREND,
    payload,
});

export const getComputedReadingDataEpic = (action$) =>
    action$.pipe(
        ofType(GET_COMPUTED_READING_DATA),
        mergeMap((action) =>
            ajax
                .post(
                    `${AssetUrl}/api/reading_computed/?memberId=${action.payload.memberId}`,
                    action.payload,
                    {
                        Authorization: `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
                        'Content-Type': 'application/json',
                    },
                )
                .pipe(
                    map((response) => {
                        if (action.payload.isTrend) {
                            return getComputedReadingDataSuccessWithTrend(response.response);
                        } else {
                            return getComputedReadingDataSuccess(response.response);
                        }
                    }),
                    catchError((error) =>
                        of(
                            {
                                type: GET_COMPUTED_READING_DATA_FAILURE,
                                payload: [],
                                error: true,
                            },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );

const getFaultReadingDataSuccess = (payload) => ({ type: GET_FAULT_READING_DATA_SUCCESS, payload });
const getFaultReadingDataSuccessWithTrend = (payload) => ({
    type: GET_FAULT_READING_DATA_SUCCESS_TREND,
    payload,
});

export const getFaultReadingDataEpic = (action$) =>
    action$.pipe(
        ofType(GET_FAULT_READING_DATA),
        mergeMap((action) =>
            ajax
                .getJSON(
                    `${AssetUrl}/api/v2/reading/reading_series/?agg_date_offset=0&fromdate=${action.payload.from_date}&todate=${action.payload.to_date}&readingtypeid=${action.payload.readingtypeid}&memberId=${action.payload.memberId}&sensorid=${action.payload.sensorId}&grain=${action.payload.grain}`,
                    { Authorization: `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}` },
                )
                .pipe(
                    map((response) => {
                        if (action.payload.isTrend) {
                            return getFaultReadingDataSuccessWithTrend(response);
                        } else {
                            return getFaultReadingDataSuccess(response);
                        }
                    }),
                    catchError((error) =>
                        of(
                            {
                                type: GET_FAULT_READING_DATA_FAILURE,
                                payload: [],
                                error: true,
                            },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );


export const getCalculatedParameterEpic = (action$) =>
    action$.pipe(
        ofType(GET_COMPUTED_PARAMETER),
        mergeMap((action) =>
            ajax
                .getJSON(`${URL}/calcd-params/?page_no=1&page_size=200&&fromdate=${action.payload.from_date}&todate=${action.payload.to_date}`, {
                    Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
                })
                .pipe(
                    map((response) => {
                        return {
                            type: GET_COMPUTED_PARAMETER_SUCCESS,
                            payload: response
                        }
                    }),
                    catchError((error) =>
                        of(
                            {
                                type: GET_COMPUTED_PARAMETER_FAILURE,
                                payload: [],
                                error: true,
                            },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );

export const getReadingTypesPreloadEpic = (action$) =>
    action$.pipe(
        ofType(GET_READING_TYPES_PRELOAD),
        mergeMap((action) =>
            ajax
                .getJSON(`${URL}/constants/reading-types`, {
                    Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.authToken'))}`,
                })
                .pipe(
                    map((response) => {
                        // localStorage.setItem("smartsense.readingtypes", JSON.stringify(action.payload))
                        return {
                            type: GET_COMPUTED_PARAMETER_SUCCESS,
                            payload: response
                        }
                    }),
                    catchError((error) =>
                        of(
                            {
                                type: GET_COMPUTED_PARAMETER_FAILURE,
                                payload: [],
                                error: true,
                            },
                            { type: ERROR, payload: error },
                        ),
                    ),
                ),
        ),
    );
