import * as AppConstants from './constant';

const INITIAL_STATE = {
    data: [],
    checkedData: [],
    loading: false,
    error: false,
    success: undefined,
    message: null,
    total_pages: null,
    total_record: null,
    isCalculatedParameterOpen: false,
    isAllCalculatedParameterChecked: false
}

export function getCalculatedParameterList(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_CALCULATED_PARAMETER_LIST:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_CALCULATED_PARAMETER_LIST_SUCCESS:
            action.response && action.response.result && Array.isArray(action.response.result) && action.response.result.map((parameter, index) => {
                parameter['isCalculatedParameterChecked'] = false;
            })
            return {
                ...state,
                data: action.response.result,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_CALCULATED_PARAMETER_LIST_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }
        case AppConstants.CHECK_ALL_CALCULATED_PARAMETERS:
            let list = state.data;
            if (action.payload.value === "checkAll") {
                list && Array.isArray(list) && list.map(item => {
                    item.isCalculatedParameterChecked = action.payload.checkedFlag;
                });
            }
            return {
                ...state,
                isAllCalculatedParameterChecked: action.payload.checkedFlag,
                data: list,
                checkedData: []
            }

        case AppConstants.CHECK_CALCULATED_PARAMETER:
            let data = state.data;
            data && Array.isArray(data) && data.map(item => {
                if (action.payload && action.payload.data && (item.id == action.payload.data.id)) {
                    item.isCalculatedParameterChecked = action.payload.isChecked;
                }
            });
            if (!action.payload.isChecked) {
                const indx = state.checkedData.findIndex(v => v.id === action.payload.data.id);
                state.checkedData.splice(indx, indx >= 0 ? 1 : 0);
            }
            return {
                ...state,
                data,
                checkedData: action.payload.isChecked ? state.checkedData.concat(action.payload.data) : state.checkedData
            }

        case AppConstants.DELETE_CALCULATED_PARAMETER_RESET:
            return {
                ...state,
                loading: false,
                checkedData: [],
                error: false,
                success: undefined
            }
        case AppConstants.UPDATE_CALCULATED_PARAMETER_RESET:
            return {
                ...state,
                loading: false,
                checkedData: [],
                error: false,
                success: undefined
            }
        case AppConstants.CHECKDATA_CALCULATED_PARAMETER_RESET:
            return {
                ...state,
                loading: false,
                data: [],
                checkedData: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function calculatedParameterModal(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.IS_OPEN_CALCULATED_PARAMETER_MODAL:
            return {
                ...state,
                isCalculatedParameterOpen: action.payload
            }
        default:
            return state;
    }
}

export function getAllClientList(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_CLIENT_LIST:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                data: action.response,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_CLIENT_LIST_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }


        default:
            return state;
    }
}

export function getAllPlantList(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.GET_ALL_PLANT_LIST:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.GET_ALL_PLANT_LIST_SUCCESS:
            return {
                ...state,
                data: action.response,
                total_pages: action.response.total_pages,
                total_record: action.response.total_record,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.GET_ALL_PLANT_LIST_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
            }


        default:
            return state;
    }
}

export function deleteCalculatedParameter(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.DELETE_CALCULATED_PARAMETER:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.DELETE_CALCULATED_PARAMETER_SUCCESS:

            return {
                ...state,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.DELETE_CALCULATED_PARAMETER_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.DELETE_CALCULATED_PARAMETER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function createCalculatedParameter(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.CREATE_CALCULATED_PARAMETERS:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }

        case AppConstants.CREATE_CALCULATED_PARAMETERS_SUCCESS:
            return {
                ...state,
                data: action.response.response,
                error: false,
                loading: false,
                success: true
            }

        case AppConstants.CREATE_CALCULATED_PARAMETERS_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false,
                loading: false,
                message: action.payload && action.payload.response && action.payload.response.message 
            }
        case AppConstants.CREATE_CALCULATED_PARAMETER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function updateCalculatedParameter(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.UPDATE_CALCULATED_PARAMETER:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.UPDATE_CALCULATED_PARAMETER_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.UPDATE_CALCULATED_PARAMETER_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.UPDATE_CALCULATED_PARAMETER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export function verifyTargetSensorParameter(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AppConstants.VERIFY_TARGET_SENSOR_PARAMETER:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        case AppConstants.VERIFY_TARGET_SENSOR_PARAMETER_SUCCESS:
            return {
                ...state,
                data: action.response,
                error: false,
                loading: false,
                success: true
            }
        case AppConstants.VERIFY_TARGET_SENSOR_PARAMETER_FAILURE:
            return {
                ...state,
                error: true,
                data: [],
                success: false
            }
        case AppConstants.VERIFY_TARGET_SENSOR_PARAMETER_RESET:
            return {
                ...state,
                loading: true,
                data: [],
                error: false,
                success: undefined
            }
        default:
            return state;
    }
}

export const calculatedParameter = {
    getCalculatedParameterList,
    calculatedParameterModal,
    getAllClientList,
    getAllPlantList,
    deleteCalculatedParameter,
    createCalculatedParameter,
    updateCalculatedParameter,
    verifyTargetSensorParameter
}