import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import useFormulaConfigComputed from '../common/useFormulaConfigComputed';
import _formulaConfsGet from '../common/_formulaConfsGet';
import { Input, CheckBox } from '../../../../../../../common/components';
import ParameterConfigComputedCore from '../Common/ParameterConfigComputedCore';
import ParameterConfigComputedCollection from '../Common/ParameterConfigComputedCollection';

const ParameterConfigComputed = (props) => {
  const [
    initialized,
    formulaConfActiveIndex,
    formulaConfParameterCollections,
    formulaConfPointerCollections,
    onFormulaConfParameterCollectionUpdateHandle,
    onFormulaConfPointerCollectionUpdateHandle,
    onFormulaConfResetHandle,
    onFormulaConfActiveIndexChangeHandle,
    onFormulaConfCreateHandle,
    onFormulaConfDeleteHandle
  ] = useFormulaConfigComputed(props.formulaConfs, props.onFormulaConfsChange);

  const renderParameterConfigComputedCore = () => {
    return (
      <ParameterConfigComputedCore
        formulaConfActiveIndex={formulaConfActiveIndex}
        formulaConfParameterCollections={formulaConfParameterCollections}
        formulaConfPointerCollections={formulaConfPointerCollections}
        formulaConfs={props.formulaConfs}
        onFormulaConfParameterCollectionUpdateTrigger={
          onFormulaConfParameterCollectionUpdateHandle
        }
        onFormulaConfPointerCollectionUpdateTrigger={
          onFormulaConfPointerCollectionUpdateHandle
        }
        onFormulaConfCreateTrigger={onFormulaConfCreateHandle}
        onFormulaConfResetTrigger={onFormulaConfResetHandle}
      />
    );
  };

  const renderParameterConfigComputedCollection = () => {
    return (
      <ParameterConfigComputedCollection
        formulaConfActiveIndex={formulaConfActiveIndex}
        formulaConfPointerCollections={formulaConfPointerCollections}
        formulaConfParameterCollections={formulaConfParameterCollections}
        formulaConfs={props.formulaConfs}
        onFormulaConfActiveIndexChangeTrigger={
          onFormulaConfActiveIndexChangeHandle
        }
        onFormulaConfDeleteTrigger={onFormulaConfDeleteHandle}
      />
    );
  };

  const renderFn = () => {
    return (
      <Fragment>
        <Row className='alias-data-wrap'>
          <Col xs={12}>
            <Row>
              <Col md={4}>
                <div className='new-input-wrap'>
                  <div className='d-flex justify-content-between'>
                    <label className='label-text'>Alias</label>
                  </div>
                  <Input
                    size='sm'
                    label=''
                    type='text'
                    required={false}
                    placeholder='Enter Alias'
                    value={
                      props.formulaConfs[formulaConfActiveIndex].alias || ''
                    }
                    inputChanged={(alias) => {
                      alias !==
                        props.formulaConfs[formulaConfActiveIndex].alias &&
                        props.onFormulaConfsChange(
                          _formulaConfsGet(
                            {
                              ...props.formulaConfs[formulaConfActiveIndex],
                              alias
                            },
                            formulaConfActiveIndex,
                            props.formulaConfs
                          )
                        );
                    }}
                  />
                </div>
              </Col>

              <Col md={4}>
                <div className='new-input-wrap'>
                  <div className='d-flex justify-content-between'>
                    <label className='label-text'>Unit</label>

                    <label className='label-text font-weight-light'>
                      Show As
                    </label>
                  </div>

                  <Input
                    size='sm'
                    label=''
                    type='text'
                    required={false}
                    placeholder='Enter Unit'
                    value={
                      props.formulaConfs[formulaConfActiveIndex].unit || ''
                    }
                    inputChanged={(unit) => {
                      unit !==
                        props.formulaConfs[formulaConfActiveIndex].unit &&
                        props.onFormulaConfsChange(
                          _formulaConfsGet(
                            {
                              ...props.formulaConfs[formulaConfActiveIndex],
                              unit
                            },
                            formulaConfActiveIndex,
                            props.formulaConfs
                          )
                        );
                    }}
                  />
                </div>
              </Col>

              <Col md={4}>
                <div className='new-input-wrap row-checkbox-right'>
                  <div className='new-custom-checkbox'>
                    <CheckBox
                      label={'Show Partial Calculation Result'}
                      size='sm'
                      checked={
                        props.formulaConfs[formulaConfActiveIndex].showPartial
                      }
                      checkboxClicked={(showPartial) => {
                        props.onFormulaConfsChange(
                          _formulaConfsGet(
                            {
                              ...props.formulaConfs[formulaConfActiveIndex],
                              showPartial
                            },
                            formulaConfActiveIndex,
                            props.formulaConfs
                          )
                        );
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            {renderParameterConfigComputedCore()}
          </Col>
        </Row>

        {renderParameterConfigComputedCollection()}
      </Fragment>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParameterConfigComputed;