import React, { Fragment, useState, useEffect, useContext } from "react";
import { Steps, Step } from "react-step-builder";
import { Row, Col, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button, ModalBox } from "../../../common/components";
import { ClientAccounts, ClientProfile, Accounts, ClientPlants } from './components';
import partnerLogin from '../../../../assets/images/login/partnerLogin.png';
import smartsense from '../../../../assets/images/login/smartsense.png';
import dummyLogo from '../../../../assets/images/dummy-logo.png';
import { getAllClients, getAllClientsBySwitchUser, getAllPlants, getAllPlantsBySwitchUser, getAllUsersForSpecificPlant, getAllUsersForSpecificPlantBySwitchUser, getClientDashboard, getClientDashboardBySwitchUser, switchUser } from './action'
import { URL } from '../constants';
import { Context, UserContext } from '../Main/context';
import { NOT_ACCESS_MESSAGE } from '../Main/appSetting';
import { PARTNER } from '../Main/permissionContants';


import logoLight from '../../../../assets/images/SmartSense_Small_RGB.png';
import logoDark from '../../../../assets/images/smartsense-logo.svg';

const Navigation = (props) => {
    return (
        <div className='float-right buttom-buttons'>
            <Row>
                {
                    props.current && props.current !== 1 && <Col>
                        <div className='back'>
                            <Button
                                buttonType='primary'
                                size='md'
                                buttonClick={() => {
                                    props.setSearchValue(null)
                                    props.setSearchBar(false);
                                    props.getPrevActiveStep(props);
                                    props.prev();
                                }}
                                innerContent={'Back'}
                            />
                        </div>
                    </Col>
                }
                <Col>
                    <div className='next'>
                        <Button
                            buttonType='primary'
                            size='md'
                            buttonClick={() => {
                                props.getActiveFormData(props)
                                props.setSearchValue(null);
                                props.setSearchBar(false);
                            }}
                            innerContent={'Continue'}
                            isDisabled={props.activeStep !== '' ? false : true}
                        />
                    </div>
                </Col>
            </Row>
        </div>

    );
};

function PartnerLogin(props) {
    let memberDetails = localStorage.getItem('smartsense.member') && JSON.parse(localStorage.getItem('smartsense.member'));
    let memberClientName = localStorage.getItem('smartsense.admin_member') && JSON.parse(localStorage.getItem('smartsense.admin_member'));
    let clientName = memberClientName && memberClientName.clientid && memberClientName.clientid.clientname;
    const context = useContext(Context);
    const userContext = useContext(UserContext);

    const [isSearchBarOpen, setSearchBar] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [accountType, setAccountType] = useState({});
    const [clientAccountType, setClientAccountType] = useState({});
    const [clientPlantsType, setClientPlantType] = useState({});
    const [clientData, setClientData] = useState({});
    const [activeStep, setActiveStep] = useState('');
    const [activeSearch, setActiveSearch] = useState('');


    useEffect(() => {
        let data = {
            client_name: clientName,
        }
        if (props.isSwitchUser) {
            getData(data, `partners/clients/?${clientName}`, props.getAllClientsBySwitchUser)
        } else props.getAllClients(data)
        localStorage.setItem('partner_login', true);
    }, []);

    useEffect(() => {
        if (isSearchBarOpen) {
            switch (activeSearch) {
                case 'clientAccount':
                    if (props.isSwitchUser) {
                        if (searchValue == '') {
                            getData('', `partners/clients/?${clientName}`, props.getAllClientsBySwitchUser)
                        } else {
                            getData('', `partners/clients/?client_name=${searchValue}`, props.getAllClientsBySwitchUser)
                        }
                    } else {
                        if (searchValue == '') {
                            props.getAllClients({ client_name: clientName })
                        } else {
                            let data = {
                                client_name: searchValue,
                                isSwitchUser: props.isSwitchUser
                            }
                            props.getAllClients(data)
                        }
                    }
                    break;

                case 'clientPlant':
                    if (props.isSwitchUser) {
                        if (searchValue == '') {
                            getData('', `partners/clients/${clientAccountType.id}/plants/?page_no=1&page_size=10`, props.getAllPlantsBySwitchUser)
                        } else {
                            getData('', `partners/clients/${clientAccountType.id}/plants/?page_no=1&page_size=10&name=${searchValue}`, props.getAllPlantsBySwitchUser)
                        }
                    } else {
                        if (searchValue == '') {
                            props.getAllPlants({
                                page_no: 1,
                                page_size: 10,
                                id: clientAccountType.id
                            });
                        } else {
                            props.getAllPlants({
                                page_no: 1,
                                page_size: 10,
                                id: clientAccountType.id,
                                isSwitchUser: props.isSwitchUser,
                                name: searchValue
                            });
                        }
                    }
                    break;

                case 'clientDashboard':
                    if (props.isSwitchUser) {
                        if (searchValue == '') {
                            getData('', `partners/clients/${clientPlantsType.clientid}/plants/${clientPlantsType.plantid}/users`, props.getAllUsersForSpecificPlantBySwitchUser)
                        } else {
                            getData('', `partners/clients/${clientPlantsType.clientid}/plants/${clientPlantsType.plantid}/users/?page_no=1&page_size=10&name=${searchValue}`, props.getAllUsersForSpecificPlantBySwitchUser)
                        }
                    } else {
                        if (searchValue == '') {
                            props.getAllUsersForSpecificPlant({
                                clientId: clientPlantsType.clientid,
                                plantId: clientPlantsType.plantid
                            });
                        } else {
                            props.getAllUsersForSpecificPlant({
                                clientId: clientPlantsType.clientid,
                                plantId: clientPlantsType.plantid,
                                isSwitchUser: props.isSwitchUser,
                                name: searchValue
                            });
                        }
                    }
                    break;

                default:
                    return null;
            }
        }
    }, [searchValue])

    function getData(data, url, clientFunction) {
        return axios.get(`${URL}/${url}`, {
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.superAuthToken'))}`,
            },
            data,
        }).then(response => clientFunction(response.data)).catch(error => {
            // context.notify.error(error.response.data.message);
        })
    }

    const config = {
        navigation: {
            component: (navProps) => <Navigation
                {...navProps}
                getActiveFormData={getActiveFormData}
                setSearchValue={setSearchValue}
                setSearchBar={setSearchBar}
                activeStep={activeStep}
                getPrevActiveStep={getPrevActiveStep}
                isSwitchUser={props.isSwitchUser}
            />,
            location: "after" // or before
        }
    };

    const getPrevActiveStep = (stepProps) => {
        if (stepProps && stepProps.current == 2 && accountType.type == 'adminProfile') {
            setActiveStep('adminProfile')
        } else if (stepProps && stepProps.current == 2 && accountType.type == 'clientProfile') {
            setActiveStep('clientProfile')
        } else if (stepProps && stepProps.current == 3) {
            setActiveStep('clientAccount')
        } else if (stepProps && stepProps.current == 4) {
            setActiveStep('clientPlant')
        } else if (stepProps && stepProps.current == 5) {
            setActiveStep('clientDashboard')
        }

    }

    const getActiveFormData = (stepProps) => {
        switch (activeStep) {
            case 'adminProfile':
                let adminToken = JSON.parse(localStorage.getItem('smartsense.authToken'));
                localStorage.setItem("smartsense.superAuthToken", JSON.stringify(adminToken));
                props.history.push('/Dashboard');
                localStorage.setItem('adminProfile', true);
                setActiveStep('');
                props.switchUser && props.switchUser(false)
                break;

            case 'clientProfile':
                const isAdminProfile = localStorage.getItem('adminProfile');
                if ((userContext.permission(PARTNER.LOGIN_USER_OF_CLIENT) || isAdminProfile === 'false')) {
                    stepProps.next();
                    if (clientAccountType.type == 'clientAccount') {
                        setActiveStep("clientAccount");
                    } else setActiveStep('');
                } else context.notify.error(NOT_ACCESS_MESSAGE)
                break;
            case 'clientAccount':
                stepProps.next();
                let data = {
                    page_no: 1,
                    page_size: 10
                }
                if (props.isSwitchUser) {
                    getData(data, `partners/clients/${clientAccountType.id}/plants`, props.getAllPlantsBySwitchUser)
                } else props.getAllPlants({
                    page_no: 1,
                    page_size: 10,
                    id: clientAccountType.id
                });

                if (clientPlantsType.type == 'clientPlant') {
                    setActiveStep("clientPlant");
                } else setActiveStep('');
                break;
            case 'clientPlant':
                stepProps.next();
                if (props.isSwitchUser) {
                    getData(data, `partners/clients/${clientPlantsType.clientid}/plants/${clientPlantsType.plantid}/users`, props.getAllUsersForSpecificPlantBySwitchUser)
                } else props.getAllUsersForSpecificPlant({
                    clientId: clientPlantsType.clientid,
                    plantId: clientPlantsType.plantid
                });
                if (clientData.type == 'clientDashboard') {
                    setActiveStep("clientDashboard");
                } else setActiveStep('');
                // stepProps.next();
                break;

            case 'clientDashboard':
                if (props.isSwitchUser) {
                    let data = { user_id: clientData.memberid }
                    return axios.post(`${URL}/partners/clients/${clientData.clientid}/users/login/`, data, {
                        headers: {
                            "Content-Type": "application/json; charset=UTF-8",
                            Authorization: `token: ${JSON.parse(localStorage.getItem('smartsense.superAuthToken'))}`,
                        }
                    }).then(response => {
                        props.getClientDashboardBySwitchUser({ response: response.data, user_id: clientData.memberid });
                        props.switchUser(false);
                        props.history.push('/Dashboard');
                        localStorage.setItem('adminProfile', false);
                        localStorage.setItem('smartsense.client_logout_data', JSON.stringify({ memberId: clientData.memberid, clientId: clientData.clientid }));
                        window.location.reload();
                    }).catch(error => {
                        // context.notify.error(error.response.data.message);
                    })
                } else {
                    props.getClientDashboard({
                        memberId: clientData.memberid,
                        clientId: clientData.clientid
                    });
                    props.history.push('/Dashboard');
                }
                setActiveStep('');
                localStorage.setItem('adminProfile', false);
                localStorage.setItem('smartsense.client_logout_data', JSON.stringify({ memberId: clientData.memberid, clientId: clientData.clientid }));
                break;
            default:
                return null;
        }
    }

    return (
        <ModalBox
            open={true}
            size='sm'
            className='partner-login-modal account-access-modal'
        >
            {/* <ModalHeader
                className="partner-login-heading"
                toggle={() => props.switchUser && props.switchUser(false)}
            >
                <Row>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <img
                            className="logo"
                            src={partnerLogin}
                            alt="smartsense"
                            height={150}
                        />
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        <div className="right-side-heading">
                            <img
                                className="logo"
                                src={smartsense}
                                alt="smartsense"
                                height={32}
                            />
                            <span className='blank-line'>|</span>
                            {
                                memberDetails && memberDetails.clientid && memberDetails.clientid.logo ?
                                    <img
                                        className="client-logo"
                                        src={memberDetails.clientid.logo}
                                        alt=""
                                        height={20}
                                    /> : <img
                                        className="client-logo"
                                        src={dummyLogo}
                                        alt=""
                                        height={20}
                                    />
                            }
                        </div>
                        <div className='smartsense-content'>
                            <span>
                                Transition from Reactive to
                                Predictive Maintenance using
                                SmartSense
                            </span>
                        </div>
                    </Col>
                </Row>

            </ModalHeader> */}

            
            <ModalHeader
                className="account-access-heading"
                toggle={() => props.switchUser && props.switchUser(false)}
            >
                <div className="logo-wrapper">
                    <img className="logo light-theme-img" src={logoLight} alt="smartsense" height="65" />
                    <img className="logo dark-theme-img" src={logoDark} alt="smartsense" height="32" />
                </div>
            </ModalHeader>
            <ModalBody>
                <Steps config={config}>
                    <Step component={() => <Accounts
                        setSearchBar={setSearchBar}
                        isSearchBarOpen={isSearchBarOpen}
                        accountType={accountType}
                        setAccountType={setAccountType}
                        setActiveStep={setActiveStep}
                        is_client={memberClientName && memberClientName.clientid && memberClientName.clientid.is_client}
                    />} />
                    <Step component={() => <ClientAccounts
                        setSearchBar={setSearchBar}
                        isSearchBarOpen={isSearchBarOpen}
                        setSearchValue={setSearchValue}
                        searchValue={searchValue}
                        clients={props.allClients}
                        setClientAccountType={setClientAccountType}
                        clientAccountType={clientAccountType}
                        setActiveStep={setActiveStep}
                        setActiveSearch={setActiveSearch}
                    />} />

                    <Step component={() => <ClientPlants
                        setSearchBar={setSearchBar}
                        isSearchBarOpen={isSearchBarOpen}
                        setSearchValue={setSearchValue}
                        searchValue={searchValue}
                        plants={props.allPlants}
                        setClientPlantType={setClientPlantType}
                        clientPlantsType={clientPlantsType}
                        setActiveStep={setActiveStep}
                        setActiveSearch={setActiveSearch}
                    />} />

                    <Step component={() => <ClientProfile
                        setSearchBar={setSearchBar}
                        isSearchBarOpen={isSearchBarOpen}
                        setSearchValue={setSearchValue}
                        searchValue={searchValue}
                        setActiveStep={setActiveStep}
                        users={props.allUsersForPlant}
                        setClientData={setClientData}
                        clientData={clientData}
                        setActiveSearch={setActiveSearch}
                    />} />
                </Steps>
            </ModalBody>
        </ModalBox>
    );
}

const mapStateToProps = (state) => {
    return {
        allClients: state.getAllClients,
        allPlants: state.getAllPlants,
        allUsersForPlant: state.getAllUsersForSpecificPlant,
        isSwitchUser: state.clientLogout.isSwitchUser
    };
};

export default connect(
    mapStateToProps, {
    getAllClients,
    getAllClientsBySwitchUser,
    getAllPlants,
    getAllUsersForSpecificPlant,
    getClientDashboard,
    getAllPlantsBySwitchUser,
    getAllUsersForSpecificPlantBySwitchUser,
    getClientDashboardBySwitchUser,
    switchUser
}
)(PartnerLogin);

