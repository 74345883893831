import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import useSensorParameterCollection from '../common/useSensorParameterCollection';
import isSensorParameterSelectDisabledGet from '../common/isSensorParameterSelectDisabledGet';
import parameterConfInputGet from '../common/parameterConfInputGet';
import collectionGet from '../common/collectionGet';
import SensorParameterSelect from '../Common/SensorParameterSelect';
import ParameterMixedCollection from '../Common/ParameterMixedCollection';
import { Input } from '../../../../../../../common/components';

const _sensorParameterItemCount = 1;

const sensorParameterItemCountGet = (collectionLength, currentValue = 0) => {
  return Math.max(collectionLength, currentValue, _sensorParameterItemCount);
};

const ParametersConfigNormal = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [sensorParameterItemCount, setSensorParameterItemCount] = useState();

  const [parameterConfActiveIndex, setParameterConfActiveIndex] = useState(
    props.parameterConfs.length - 1
  );

  const [
    sensorCollection,
    sensorOptions,
    parameterCollection,
    parameterOptionsCollection,
    onSensorChangeHandle,
    onParameterChangeHandle
  ] = useSensorParameterCollection(props.parameterConfs || []);

  useEffect(() => {
    if (!initialized && props.parameterConfs) {
      setSensorParameterItemCount(
        sensorParameterItemCountGet(
          props.parameterConfs.length,
          sensorParameterItemCount
        )
      );

      setInitialized(true);
    }
  }, [initialized, props.parameterConfs, sensorParameterItemCount]);

  const renderSensorParameterItem = (index) => {
    return (
      <Fragment>
        <Row>
          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Alias</label>

              <Input
                size='sm'
                label=''
                type='text'
                required={false}
                placeholder='Enter Alias'
                value={
                  props.parameterConfs[index]
                    ? props.parameterConfs[index].alias
                    : ''
                }
                inputChanged={(alias) => {
                  props.onParameterConfsChange(
                    collectionGet(
                      {
                        ...props.parameterConfs[index],
                        alias
                      },
                      index,
                      props.parameterConfs
                    )
                  );
                }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Location</label>

              <SensorParameterSelect
                type='sensor'
                index={index}
                options={sensorOptions}
                value={sensorCollection[index] || ''}
                onChange={(sensor) => {
                  onSensorChangeHandle(sensor, index);
                }}
                isDisabled={isSensorParameterSelectDisabledGet(
                  index,
                  parameterCollection
                )}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className='new-input-wrap'>
              <label className='label-text'>Parameter</label>

              <SensorParameterSelect
                type='parameter'
                options={parameterOptionsCollection[index] || []}
                value={parameterCollection[index] || ''}
                onChange={(parameter) => {
                  onParameterChangeHandle(parameter, index, (parameterConf) => {
                    props.onParameterConfsChange(
                      collectionGet(
                        {
                          ...props.parameterConfs[index],
                          ...parameterConf
                        },
                        index,
                        props.parameterConfs
                      )
                    );
                  });
                }}
                isDisabled={isSensorParameterSelectDisabledGet(
                  index,
                  parameterCollection
                )}
              />
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  };

  const renderParameterMixedCollection = () => {
    return (
      <ParameterMixedCollection
        confs={props.parameterConfs}
        onConfActiveIndexChangeTrigger={(index) => {
          setParameterConfActiveIndex(index);
        }}
        onConfDeleteTrigger={(index) => {
          const _parameterConfActiveIndex = (() => {
            switch (true) {
              case index < parameterConfActiveIndex:
                return parameterConfActiveIndex - 1;

              case index > parameterConfActiveIndex:
                return parameterConfActiveIndex;

              case index === parameterConfActiveIndex && !!index:
                return parameterConfActiveIndex - 1;

              default:
                return 0;
            }
          })();

          setParameterConfActiveIndex(_parameterConfActiveIndex);

          const parameterConfs = collectionGet(
            null,
            index,
            props.parameterConfs
          );

          props.onParameterConfsChange(
            parameterConfs.length
              ? parameterConfs
              : [
                  {
                    ...parameterConfInputGet(),
                    isNormal: true
                  }
                ]
          );
        }}
      />
    );
  };

  const renderFn = () => {
    return (
      <div className='config-form-wrapper'>
        {renderSensorParameterItem(parameterConfActiveIndex)}

        <button
          className='icon-text-btn'
          onClick={() => {
            setParameterConfActiveIndex(sensorParameterItemCount);

            setSensorParameterItemCount(
              (sensorParameterItemCount) => ++sensorParameterItemCount
            );

            props.onParameterConfsChange([
              ...props.parameterConfs,
              { ...parameterConfInputGet(), isNormal: true }
            ]);
          }}
        >
          <i className='fa fa-plus-circle' aria-hidden='true'></i> Add New Row
        </button>

        <br />

        {renderParameterMixedCollection()}
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParametersConfigNormal;
