import {
    GET_SHIFT_ANALYSIS_DATA,
    GET_SHIFT_ANALYSIS_DATA_SUCCESS,
    GET_SHIFT_ANALYSIS_DATA_FAILURE,
    GET_SHIFT_ANALYSIS_DATA_DIFF_INIT,
    GET_SHIFT_ANALYSIS_DATA_DIFF_SUCCESS,
    GET_SHIFT_ANALYSIS_DATA_DIFF_FAILURE,
    GET_SHIFT_ANALYSIS_SERIES,
    GET_SHIFT_ANALYSIS_SERIES_SUCCESS,
    GET_SHIFT_ANALYSIS_SERIES_FAILURE,
    GET_SHIFT_ANALYSIS_DATE_RANGE_DATA,
    GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_SUCCESS,
    GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_FAILURE,
    GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_INIT,
    GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_SUCCESS,
    GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_FAILURE
} from './constant';
import {DATE_RANGE_LIST} from "../utils";

const INITIAL_STATE = {
    data: {
        values: [],
        names: []
    },
    loading: false,
    error: false
};

const INITIAL_SERIES_STATE = {
    data: {
        series: [],
        shift: [],
        charts: {
            pieData: [],
            barData: []
        }
    },
    loading: false,
    error: false
};

const INITIAL_DATE_RANGE_STATE = {};
for (let i = 0; i < DATE_RANGE_LIST.length; i++) {
    const dateRange = DATE_RANGE_LIST[i];
    INITIAL_DATE_RANGE_STATE[dateRange] = {
        data: {
            names: [],
            values: [],
        },
        loading: false,
        error: false
    };
}

const INITIAL_DATE_RANGE_DIFF_STATE = {};
for (let i = 0; i < DATE_RANGE_LIST.length; i++) {
    const dateRange = DATE_RANGE_LIST[i];
    INITIAL_DATE_RANGE_DIFF_STATE[dateRange] = {
        data: {
            names: [],
            values: [],
        },
        loading: false,
        error: false
    };
}

export function getShiftAnalysisDataReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_SHIFT_ANALYSIS_DATA:
            return {
                ...state,
                loading: true,
                data: INITIAL_STATE.data,
                error: false
            };
        case GET_SHIFT_ANALYSIS_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_SHIFT_ANALYSIS_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: INITIAL_STATE.data,
                loading: false
            };
        default:
            return state
    }
}

export function getShiftAnalysisDataDifferenceReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_SHIFT_ANALYSIS_DATA_DIFF_INIT:
            return {
                ...state,
                loading: true,
                data: INITIAL_STATE.data,
                error: false
            };
        case GET_SHIFT_ANALYSIS_DATA_DIFF_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_SHIFT_ANALYSIS_DATA_DIFF_FAILURE:
            return {
                ...state,
                error: true,
                data: INITIAL_STATE.data,
                loading: false
            };
        default:
            return state
    }
}

export function getShiftAnalysisSeriesReducer(state = INITIAL_SERIES_STATE, action) {
    switch (action.type) {
        case GET_SHIFT_ANALYSIS_SERIES:
            return {
                ...state,
                loading: true,
                error: false
            };
        case GET_SHIFT_ANALYSIS_SERIES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_SHIFT_ANALYSIS_SERIES_FAILURE:
            return {
                ...state,
                error: true,
                loading: false
            };
        default:
            return state
    }
}

export function getShiftAnalysisDateRangeReducer(state = INITIAL_DATE_RANGE_STATE, action) {
    const {payload} = action;
    let currentValue;
    switch (action.type) {
        case GET_SHIFT_ANALYSIS_DATE_RANGE_DATA:
            currentValue = {
                [payload.request_key]: {
                    loading: true,
                    data: {
                        names: [],
                        values: [],
                    },
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_SUCCESS:
            currentValue = {
                [payload.request_key]: {
                    loading: false,
                    data: payload,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_SHIFT_ANALYSIS_DATE_RANGE_DATA_FAILURE:
            currentValue = {
                [payload.request_key]: {
                    error: true,
                    data: {
                        names: [],
                        values: [],
                    },
                    loading: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        default:
            return state
    }
}

export function getShiftAnalysisDifferenceReducer(state = INITIAL_DATE_RANGE_DIFF_STATE, action) {
    const {payload} = action;
    let currentValue;
    switch (action.type) {
        case GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_INIT:
            currentValue = {
                [payload.request_key]: {
                    loading: true,
                    data: {
                        names: [],
                        values: [],
                    },
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_SUCCESS:
            currentValue = {
                [payload.request_key]: {
                    loading: false,
                    data: payload,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_SHIFT_ANALYSIS_DATE_RANGE_DIFF_FAILURE:
            currentValue = {
                [payload.request_key]: {
                    error: true,
                    data: {
                        names: [],
                        values: [],
                    },
                    loading: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        default:
            return state
    }
}


