import queryString from 'query-string';

import {
  GET_USECASE_SUMMARY_CBM_ASSETS_DATA,
  GET_USECASE_SUMMARY_CBM_ASSETS_DATA_SUCCESS,
  GET_USECASE_SUMMARY_CBM_ASSETS_DATA_FAILURE,
  GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA,
  GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA_SUCCESS,
  GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA_FAILURE,
  GET_USECASE_SUMMARY_ASSETS_DATA,
  GET_USECASE_SUMMARY_ASSETS_DATA_SUCCESS,
  GET_USECASE_SUMMARY_ASSETS_DATA_FAILURE,
  GET_USECASE_SUMMARY_ALERT_LOGS_DATA,
  GET_USECASE_SUMMARY_ALERT_LOGS_DATA_SUCCESS,
  GET_USECASE_SUMMARY_ALERT_LOGS_DATA_FAILURE,
  GET_USECASE_SUMMARY_INSIGHT_DATA,
  GET_USECASE_SUMMARY_INSIGHT_DATA_SUCCESS,
  GET_USECASE_SUMMARY_INSIGHT_DATA_FAILURE,
  GET_USECASE_SUMMARY_TAG_DATA,
  GET_USECASE_SUMMARY_TAG_DATA_SUCCESS,
  GET_USECASE_SUMMARY_TAG_DATA_FAILURE,
  GET_USECASE_SUMMARY_IMPACT_DATA,
  GET_USECASE_SUMMARY_IMPACT_DATA_SUCCESS,
  GET_USECASE_SUMMARY_IMPACT_DATA_FAILURE
} from './constant';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  errorMessage: undefined,
  success: undefined
};

const collectionGet = (collection, _index, _collection) => {
  const index = _index - 1;

  return index === _collection.length
    ? [..._collection, collection]
    : _collection;
};

export const getUsecaseSummaryCbmAssetData = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_USECASE_SUMMARY_CBM_ASSETS_DATA:
      return {
        ...state,
        loading: true,
        data: {
          ...state.data,
          [action.payload.widgetId]: null
        },
        success: undefined,
        error: false
      };

    case GET_USECASE_SUMMARY_CBM_ASSETS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          [action.param.widgetId]: action.response.result
        },
        success: true,
        error: false,
        total_pages: action.response.total_pages,
        total_record: action.response.total_record
      };

    case GET_USECASE_SUMMARY_CBM_ASSETS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true
      };
    default:
      return state;
  }
};

export const getUsecaseSummaryAllAssetsListData = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.widgetId]: true
        },
        data: {
          ...state.data,
          [action.payload.widgetId]: null
        },
        success: {
          ...state.success,
          [action.payload.widgetId]: undefined
        },
        error: {
          ...state.error,
          [action.payload.widgetId]: false
        }
      };

    case GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        data: {
          [action.param.widgetId]: action.response
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_USECASE_SUMMARY_ALL_ASSETS_LIST_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(
          action.payload.request.url.split(/\?/)[1]
        );

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export const getUsecaseSummaryAssetsData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USECASE_SUMMARY_ASSETS_DATA:
      return {
        ...state,
        loading: true,
        data: {
          ...state.data,
          [action.payload.widgetId]: action.payload.index
            ? {
                ...state.data[action.payload.widgetId]
              }
            : {}
        },
        success: undefined,
        error: false
      };

    case GET_USECASE_SUMMARY_ASSETS_DATA_SUCCESS:
      return state.data[action.param.widgetId]
        ? {
            ...state,
            loading: false,
            data: {
              ...state.data,
              [action.param.widgetId]: {
                ...state.data[action.param.widgetId],
                [action.param.endpoint]: action.response.result
              }
            },
            success: true,
            error: false
          }
        : state;

    case GET_USECASE_SUMMARY_ASSETS_DATA_FAILURE:
      return (() => {
        const { widgetId, endpoint } = queryString.parse(
          action.payload.request.url.split(/\?/)[1]
        );

        return {
          ...state,
          loading: false,
          data: {
            ...state.data,
            [widgetId]: {
              ...state.data[widgetId],
              [endpoint]: []
            }
          },
          success: false,
          error: true
        };
      })();

    default:
      return state;
  }
};

export const getUsecaseSummaryAlertLogData = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_USECASE_SUMMARY_ALERT_LOGS_DATA:
      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: {
          ...state.data,
          [action.payload.widgetId]:
            action.payload.page_no > 1
              ? state.data[action.payload.widgetId]
              : { result: null }
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_USECASE_SUMMARY_ALERT_LOGS_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            result: collectionGet(
              action.response.result,
              action.param.page_no,
              state.data[action.param.widgetId].result || []
            )
          }
        },
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case GET_USECASE_SUMMARY_ALERT_LOGS_DATA_FAILURE:
      return (() => {
        const { widgetId, page_no } = queryString.parse(
          action.payload.request.url.split(/\?/)[1]
        );

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: {
              ...state.data[widgetId],
              result: collectionGet(
                [],
                page_no,
                state.data[widgetId].result || []
              )
            }
          },
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export const getUsecaseSummaryInsightData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USECASE_SUMMARY_INSIGHT_DATA:
      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        data: {
          ...state.data,
          [action.payload.widgetId]:
            action.payload.page_no > 1
              ? state.data[action.payload.widgetId]
              : { result: null }
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_USECASE_SUMMARY_INSIGHT_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: {
            ...action.response,
            result: collectionGet(
              action.response.result,
              action.param.page_no,
              state.data[action.param.widgetId].result || []
            )
          }
        },
        loading: {
          ...(state.loading || {}),
          [action.param.widgetId]: false
        },
        success: {
          ...(state.success || {}),
          [action.param.widgetId]: true
        },
        error: {
          ...(state.error || {}),
          [action.param.widgetId]: false
        }
      };

    case GET_USECASE_SUMMARY_INSIGHT_DATA_FAILURE:
      return (() => {
        const { widgetId, page_no } = queryString.parse(
          action.payload.request.url.split(/\?/)[1]
        );

        return {
          ...state,
          data: {
            ...state.data,
            [widgetId]: {
              ...state.data[widgetId],
              result: collectionGet(
                [],
                page_no,
                state.data[widgetId].result || []
              )
            }
          },
          loading: {
            ...(state.loading || {}),
            [widgetId]: false
          },
          success: {
            ...(state.success || {}),
            [widgetId]: false
          },
          error: {
            ...(state.error || {}),
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export const getUsecaseSummaryTagData = (
  state = {
    data: null,
    success: undefined,
    loading: false,
    error: false
  },
  action
) => {
  switch (action.type) {
    case GET_USECASE_SUMMARY_TAG_DATA:
      return {
        ...state,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]:
            action.payload.subId && state.data
              ? state.data[action.payload.widgetId]
              : []
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_USECASE_SUMMARY_TAG_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: [
            ...state.data[action.param.widgetId],
            {
              ...action.param,
              ...action.response.timeseries[
                action.response.timeseries.length - 1
              ],
              ...action.response
            }
          ].sort((a, b) => {
            return a.subId - b.subId;
          })
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_USECASE_SUMMARY_TAG_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};

export const getUsecaseSummaryImpactData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USECASE_SUMMARY_IMPACT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.widgetId]: null
        },
        loading: {
          ...(state.loading || {}),
          [action.payload.widgetId]: true
        },
        success: {
          ...(state.success || {}),
          [action.payload.widgetId]: undefined
        },
        error: {
          ...(state.error || {}),
          [action.payload.widgetId]: false
        }
      };

    case GET_USECASE_SUMMARY_IMPACT_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.param.widgetId]: action.response.result
        },
        loading: {
          ...state.loading,
          [action.param.widgetId]: false
        },
        success: {
          ...state.success,
          [action.param.widgetId]: true
        },
        error: {
          ...state.error,
          [action.param.widgetId]: false
        }
      };

    case GET_USECASE_SUMMARY_IMPACT_DATA_FAILURE:
      return (() => {
        const { widgetId } = queryString.parse(action.payload.request.url);

        return {
          ...state,
          loading: {
            ...state.loading,
            [widgetId]: false
          },
          success: {
            ...state.success,
            [action.param.widgetId]: false
          },
          error: {
            ...state.error,
            [widgetId]: true
          }
        };
      })();

    default:
      return state;
  }
};
