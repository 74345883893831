export const GET_ASSETS_DETAIL_DATA = 'GET_ASSETS_DETAIL_DATA'
export const GET_ASSETS_DETAIL_DATA_SUCCESS = 'GET_ASSETS_DETAIL_DATA_SUCCESS'
export const GET_ASSETS_DETAIL_DATA_FAILURE = 'GET_ASSETS_DETAIL_DATA_FAILURE'
export const GET_ASSETS_LIST_DATA = 'GET_ASSETS_LIST_DATA'
export const GET_ASSETS_LIST_DATA_SUCCESS = 'GET_ASSETS_LIST_DATA_SUCCESS'
export const GET_ASSETS_LIST_DATA_FAILURE = 'GET_ASSETS_LIST_DATA_FAILURE'
export const GET_ASSET_ACTION_DATA = 'GET_ASSET_ACTION_DATA'
export const GET_ASSET_ACTION_DATA_SUCCESS = 'GET_ASSET_ACTION_DATA_SUCCESS'
export const GET_ASSET_ACTION_DATA_FAILURE = 'GET_ASSET_ACTION_DATA_FAILURE'
export const POST_ASSET_ACTION_DATA = 'POST_ASSET_ACTION_DATA'
export const POST_ASSET_ACTION_DATA_SUCCESS = 'POST_ASSET_ACTION_DATA_SUCCESS'
export const POST_ASSET_ACTION_DATA_FAILURE = 'POST_ASSET_ACTION_DATA_FAILURE'

export const GET_ASSETS_ALERT_DATA = 'GET_ASSETS_ALERT_DATA'
export const GET_ASSETS_ALERT_DATA_SUCCESS = 'GET_ASSETS_ALERT_DATA_SUCCESS'
export const GET_ASSETS_ALERT_DATA_FAILURE = 'GET_ASSETS_ALERT_DATA_FAILURE'

export const POST_ALERT_ACTION_DATA = 'POST_ALERT_ACTION_DATA'
export const POST_ALERT_ACTION_DATA_SUCCESS = 'POST_ALERT_ACTION_DATA_SUCCESS'
export const POST_ALERT_ACTION_DATA_FAILURE = 'POST_ALERT_ACTION_DATA_FAILURE'

export const POST_USER_ACTIVITY_ACTION_DATA = 'POST_USER_ACTIVITY_ACTION_DATA'
export const POST_USER_ACTIVITY_ACTION_DATA_SUCCESS = 'POST_USER_ACTIVITY_ACTION_DATA_SUCCESS'
export const POST_USER_ACTIVITY_ACTION_DATA_FAILURE = 'POST_USER_ACTIVITY_ACTION_DATA_FAILURE'

export const GET_ASSETS_OPEN_INSIGHT_DATA = 'GET_ASSETS_OPEN_INSIGHT_DATA';
export const GET_ASSETS_OPEN_INSIGHT_DATA_SUCCESS = 'GET_ASSETS_OPEN_INSIGHT_DATA_SUCCESS';
export const GET_ASSETS_OPEN_INSIGHT_DATA_FAILURE = 'GET_ASSETS_OPEN_INSIGHT_DATA_FAILURE';


export const GET_ASSETS_HEALTH_INDEX_DATA = 'GET_ASSETS_HEALTH_INDEX_DATA';
export const GET_ASSETS_HEALTH_INDEX_DATA_SUCCESS = 'GET_ASSETS_HEALTH_INDEX_DATA_SUCCESS';
export const GET_ASSETS_HEALTH_INDEX_DATA_FAILURE = 'GET_ASSETS_HEALTH_INDEX_DATA_FAILURE';