import React from 'react';
import { Row, Col } from 'reactstrap';

import FormulaText from './_FormulaText';
import SensorParameterCollectionComputed from './SensorParameterCollectionComputed';
import previewIcon from '../../../../../../../../assets/images/preview.svg';
import createIcon from '../../../../../../../../assets/images/create.svg';
import resetAllIcon from '../../../../../../../../assets/images/resetAll.svg';

const ParameterConfigComputedCore = (props) => {
  const renderSensorParameterCollectionComputed = () => {
    return (
      <SensorParameterCollectionComputed
        formulaConfParameterCollection={
          props.formulaConfParameterCollections[props.formulaConfActiveIndex]
        }
        onFormulaConfParameterCollectionUpdateTrigger={
          props.onFormulaConfParameterCollectionUpdateTrigger
        }
      />
    );
  };

  const renderFormulaText = () => {
    return (
      <FormulaText
        formulaConfParameterCollection={
          props.formulaConfParameterCollections[props.formulaConfActiveIndex]
        }
        formulaConfPointerCollection={
          props.formulaConfPointerCollections[props.formulaConfActiveIndex]
        }
        formulaConfPointerCollectionKey={
          props.formulaConfs[props.formulaConfActiveIndex].formula[0].id
        }
        onFormulaConfPointerCollectionUpdateTrigger={
          props.onFormulaConfPointerCollectionUpdateTrigger
        }
      />
    );
  };

  return (
    <Row>
      {renderSensorParameterCollectionComputed()}

      <Col md={6}>
        <div className='formula-editor-wrap'>
          <div className='formula-editor-head'>
            <p className='head-label-text'>Formula</p>
            <div className='formula-editor-button-wrap'>
              <button className='icon-text-btn'>
                <img src={previewIcon} />
                Preview
              </button>
              <button
                className='icon-text-btn'
                onClick={props.onFormulaConfCreateTrigger}
              >
                <img src={createIcon} />
                Create
              </button>
              <button
                className='icon-text-btn'
                onClick={props.onFormulaConfResetTrigger}
              >
                <img src={resetAllIcon} />
                Reset All
              </button>
            </div>
          </div>
          {renderFormulaText()}
        </div>
      </Col>
    </Row>
  );
};

export default ParameterConfigComputedCore;
