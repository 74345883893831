export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILURE = 'GET_ALL_CLIENTS_FAILURE';

export const GET_ALL_PLANTS = 'GET_ALL_PLANTS';
export const GET_ALL_PLANTS_SUCCESS = 'GET_ALL_PLANTS_SUCCESS';
export const GET_ALL_PLANTS_FAILURE = 'GET_ALL_PLANTS_FAILURE';

export const GET_ALL_USERS_FOR_PLANT = 'GET_ALL_USERS_FOR_PLANT';
export const GET_ALL_USERS_FOR_PLANT_SUCCESS = 'GET_ALL_USERS_FOR_PLANT_SUCCESS';
export const GET_ALL_USERS_FOR_PLANT_FAILURE = 'GET_ALL_USERS_FOR_PLANT_FAILURE';

export const GET_CLIENT_DASHBOARD = 'GET_CLIENT_DASHBOARD';

export const CLIENT_LOGOUT = 'CLIENT_LOGOUT';
export const CLIENT_LOGOUT_SUCCESS = 'CLIENT_LOGOUT_SUCCESS';
export const CLIENT_LOGOUT_FAILURE = 'CLIENT_LOGOUT_FAILURE';
export const CLIENT_LOGOUT_RESET = 'CLIENT_LOGOUT_RESET';
export const PARTNER_MODEL = 'PARTNER_MODEL';

// export const GET_CLIENT_DASHBOARD_SUCCESS = 'GET_CLIENT_DASHBOARD_SUCCESS';
// export const GET_CLIENT_DASHBOARD_FAILURE = 'GET_CLIENT_DASHBOARD_FAILURE';