import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, debounceTime } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs'
import queryString from 'query-string';

import { GET_STATS_DATA, GET_STATS_DATA_SUCCESS, GET_STATS_DATA_FAILURE, GET_ASSETS_DATA, GET_ASSETS_DATA_SUCCESS, GET_ASSETS_DATA_FAILURE, GET_TIMELINE_DATA, GET_TIMELINE_DATA_SUCCESS, GET_TIMELINE_DATA_FAILURE, AssetUrl, ERROR } from '../constants'

import * as AppConstants from './constant';
import { httpMethodGetJSON, httpMethodPatch, httpMethodDelete, httpMethodPost, } from '../https';
import { URL } from '../constants';


export const getCbmAssets = action$ => action$.pipe(
  ofType(AppConstants.GET_CBM_ASSETS),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/asset-cbm/asset-names/stats?page_size=100&page_no=1`);
  })
);

export const getAllAssets = action$ => action$.pipe(
  ofType(AppConstants.GET_ALL_ASSETS),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/asset-cbm/assets/`);
  })
);

export const getCbmAssetsByAssetName = action$ => action$.pipe(
  ofType(AppConstants.GET_CBM_ASSETS_BY_ASSET_NAME),
  mergeMap(action => {
    let searchParams = typeof action.payload.searchQuery == 'undefined' ? `` : `?${action.payload.searchQuery}`;
    return httpMethodGetJSON({ type: AppConstants.GET_CBM_ASSETS_BY_ASSET_NAME }, `${URL}/asset-cbm/asset-names/${action.payload.asset_name}/assets/${searchParams}`);
  })
);

export const getSpecificAssetDetailsByAssetName = action$ => action$.pipe(
  ofType(AppConstants.GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME),
  mergeMap(action => {
    return httpMethodGetJSON({ type: AppConstants.GET_SPECIFIC_ASSET_DETAILS_BY_ASSET_NAME }, `${URL}/asset-cbm/asset-names/${action.payload.asset_name}/assets/${action.payload.id}/`);
  })
);

export const getAssetOverview = (action$) =>
  action$.pipe(
    ofType(AppConstants.GET_ASSET_OVERVIEW),
    mergeMap((action) => {
      return httpMethodGetJSON(
        action,
        `${URL}/asset-cbm/asset-names/overview/`
      );
    })
  );

export const getAllAssetsForDropdown = action$ => action$.pipe(
  ofType(AppConstants.GET_ALL_ASSETS_FOR_DROPDOWN),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/asset-cbm/assets/`);
  })
);

export const getAssetInsightList = action$ => action$.pipe(
  ofType(AppConstants.GET_ASSET_INSIGHT_LIST),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/asset-cbm/asset-names/${action.payload.asset_endpoint}/insights/config-min-info/`);
  })
);

export const getDetailsByInsightId = action$ => action$.pipe(
  ofType(AppConstants.GET_INSIGHT_DETAILS),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/asset-cbm/insight-action-data/${action.payload.insight_id}/`);
  })
);

export const getAllActionsOfAsset = action$ => action$.pipe(
  ofType(AppConstants.GET_ALL_ACTIONS_FOR_ASSET),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/asset-cbm/insight/actions/asset_name/${action.payload.asset}/`);
  })
);

export const createManualInsight = (action$) =>
  action$.pipe(
    ofType(AppConstants.CREATE_MANUAL_INSIGHT),
    mergeMap((action) => {
      return httpMethodPost(action, `${URL}/asset-cbm/manual/insight/`);
    })
  );


export const assetInsightsEpics = {
  getCbmAssets,
  getAllAssets,
  getCbmAssetsByAssetName,
  getSpecificAssetDetailsByAssetName,
  getAssetOverview,
  getAllAssetsForDropdown,
  getAssetInsightList,
  getDetailsByInsightId,
  getAllActionsOfAsset,
  createManualInsight
}




const getStatsDataSuccess = payload => ({ type: GET_STATS_DATA_SUCCESS, payload })

export const getStatsDataEpic = action$ => {
  return action$.pipe(
    ofType(GET_STATS_DATA),
    mergeMap(action => ajax.getJSON(`${URL}/asset_insights/stats/?from_date=${encodeURIComponent(action.payload.from_date)}&to_date=${encodeURIComponent(action.payload.to_date)}`, { Authorization: `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}` }).pipe(
      map(response => getStatsDataSuccess(response)),
      catchError(error => of(
        { type: GET_STATS_DATA_FAILURE, payload: [] },
        { type: ERROR, payload: error }
      ))
    ))
  )
};



const getAssetDataSuccess = payload => ({ type: GET_ASSETS_DATA_SUCCESS, payload })



export const getAssetDataEpic = action$ => action$.pipe(
  ofType(GET_ASSETS_DATA),
  mergeMap(action => ajax.getJSON(`${AssetUrl}/api/v2/asset_insights/${action.payload.assetName}?status=${action.payload.status}&page_no=${action.payload.pageNo}&asset_name=${encodeURIComponent(action.payload.tableFilterQuery)}&from_date=${encodeURIComponent(action.payload.from_date)}&to_date=${encodeURIComponent(action.payload.to_date)}`, { Authorization: `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}` }).pipe(
    map(response => getAssetDataSuccess(response)),
    catchError(error => of(
      { type: GET_ASSETS_DATA_FAILURE, payload: [] },
      { type: ERROR, payload: error }
    ))
  ))
);


const getTimelineDataSuccess = (payload, prevValue) => ({ type: GET_TIMELINE_DATA_SUCCESS, payload, prevValue })



export const getTimelineDataEpic = action$ => action$.pipe(
  ofType(GET_TIMELINE_DATA),
  debounceTime(200),
  mergeMap(action => ajax.getJSON(`${AssetUrl}/api/v2/asset_insights/timeline/?page_no=${action.payload.pageNo}&page_size=${action.payload.pageSize}&asset_type=${action.payload.assetType}&event_type=${action.payload.eventType}&from_date=${encodeURIComponent(action.payload.from_date)}&to_date=${encodeURIComponent(action.payload.to_date)}`, { Authorization: `token:${JSON.parse(localStorage.getItem('smartsense.authToken'))}` }).pipe(
    map(response => getTimelineDataSuccess(response, action.prevValue)),
    catchError(error => of(
      { type: GET_TIMELINE_DATA_FAILURE, payload: [] },
      { type: ERROR, payload: error }
    ))
  ))
);

