export const GET_MEMBER_DATA = 'GET_MEMBER_DATA'
export const GET_MEMBER_DATA_SUCCESS = 'GET_MEMBER_DATA_SUCCESS'
export const GET_MEMBER_DATA_FAILURE = 'GET_MEMBER_DATA_FAILURE'
export const GET_ROLE_DATA = 'GET_ROLE_DATA'
export const GET_ROLE_DATA_SUCCESS = 'GET_ROLE_DATA_SUCCESS'
export const GET_ROLE_DATA_FAILURE = 'GET_ROLE_DATA_FAILURE'
export const CREATE_ROLE = 'CREATE_ROLE'
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS'
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE'
export const CREATE_MEMBER = 'CREATE_MEMBER'
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS'
export const CREATE_MEMBER_FAILURE = 'CREATE_MEMBER_FAILURE'
export const EDIT_ROLE = 'EDIT_ROLE'
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS'
export const EDIT_ROLE_FAILURE = 'EDIT_ROLE_FAILURE'
export const EDIT_ROLE_PERMISSION = 'EDIT_ROLE_PERMISSION'
export const EDIT_ROLE_PERMISSION_SUCCESS = 'EDIT_ROLE_PERMISSION_SUCCESS'
export const EDIT_ROLE_PERMISSION_FAILURE = 'EDIT_ROLE_PERMISSION_FAILURE'
export const DELETE_ROLE = 'DELETE_ROLE'
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE'
export const ASSIGN_ROLE = 'ASSIGN_ROLE'
export const ASSIGN_ROLE_SUCCESS = 'ASSIGN_ROLE_SUCCESS'
export const ASSIGN_ROLE_FAILURE = 'ASSIGN_ROLE_FAILURE'
export const REMOVE_ROLE = 'REMOVE_ROLE'
export const REMOVE_ROLE_SUCCESS = 'REMOVE_ROLE_SUCCESS'
export const REMOVE_ROLE_FAILURE = 'REMOVE_ROLE_FAILURE'
export const EDIT_MEMBER = 'EDIT_MEMBER'
export const EDIT_MEMBER_SUCCESS = 'EDIT_MEMBER_SUCCESS'
export const EDIT_MEMBER_FAILURE = 'EDIT_MEMBER_FAILURE'
export const DELETE_MEMBER = 'DELETE_MEMBER'
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS'
export const DELETE_MEMBER_FAILURE = 'DELETE_MEMBER_FAILURE'
export const DELETE_LOCATION = 'DELETE_LOCATION'
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS'
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE'









  // // rest api query params 
export const IS_WIDGET_CLONED = 'is_widget_clone';
export const CLONE_WIDGET_FROM_USER = 'clone_from';
export const IMPERSONATE_MEMBERS = 'impersonate_members';
