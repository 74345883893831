import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import { getAssetInsightParamList, getCbmAssets } from '../Widgets/action';
import SelectDropDown from '../../../../../common/components/Select';

function InsightParams(props) {
  var [metaParam, setMetaParam] = useState([]);
  var [metaParamOpt, setMetaParamOpt] = useState([]);
  const { asset_type } = props;

  useEffect(() => {
    getInsightParam();
  }, []);

  const getInsightParam = () => {
    props.getAssetInsightParamList({
      assetType: asset_type || 'motor',
      pageNo: 1,
      page_size: 50
    });
  }

  useEffect(() => {
    if(Array.isArray(props.insightParams)){
      setMetaParamOpt(props.insightParams)
    }
  }, [props.insightParams]);

  useEffect(() => {
    if(props.value){
      setMetaParam(props.value)
    }
  }, [props.value]);

  return <Col className="select-dropdown custom-select-parameter" xl={props.width ? props.width : 12} >
    <div>
      <Label for={props.name} className='label'>{props.label}
        {props.required === true ? <span className='text-danger'> * </span> : null}
      </Label>
      <SelectDropDown
        name='asset-type'
        value={metaParam}
        onChange={(e) => {
          setMetaParam(e);
          props.onChange(e, false);
        }}
        options={metaParamOpt.map(a=>{return {...a, 'label': a && a.name, 'value': a && a.id}})}
        placeholder='Select Asset Params'
        isMulti={false}
      />
    </div>
  </Col>

}


const mapStateToProps = (state) => {
  return {
    insightParams: state.assetInsightParamList.data,
    isLoaded: state.assetInsightParamList.success,
    cbmAssetData: state.getCbmAssets.data,
  };
};

InsightParams.defaultProps = {
  appParams: {},
  appInputParamChanged: function () {}
}

InsightParams.propTypes = {
  appParams: PropTypes.object,
  appInputParamChanged: PropTypes.func
}

export default connect(mapStateToProps, {
  getAssetInsightParamList,
  getCbmAssets
})(InsightParams);