
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import { Button, CheckBox } from '../../../../../common/components';
import SelectDropDown from '../../../../../common/components/Select';
import LocationAndParameterSelect from '../Widgets/CommonControls/LocationAndParameterSelect'

const operations = [
  { value: "+", label: "Add" },
  { value: "-", label: "Substract" },
  { value: "*", label: "Multiply" },
  { value: "/", label: "Divide" },
  { value: "**", label: "Power" },
  { value: "and", label: "And" },
  { value: "or", label: "Or" },
  { value: ">", label: ">" },
  { value: "<", label: "<" },
  { value: "==", label: "==" },
  { value: "!=", label: "!=" }
];


class Operand extends Component {

  onOperandTypeChange = e => {
    let operand = null;
    if (e.target.value === "parameter") {
      operand = { id: "operand_" + Date.now(), type: "parameter", "parameter": { locationid: null, readingtypeid: null } };
    } else if (e.target.value === "constant") {
      operand = { id: "operand_" + Date.now(), type: "constant", value: "" };
    } else if (e.target.value === "formula") {
      operand = { id: "operand_" + Date.now(), type: "formula", formula: [{ type: "parameter", "parameter": { locationid: null, readingtypeid: null } }] };
    }
    this.props.onOperandChange(operand);
  }

  onFormulaChange = formula => {
    let operand = this.props.operand;
    operand["formula"] = formula;
    this.props.onOperandChange(operand);
  }

  getParameterOperand = (operand) => {
    return <Row>
      <LocationAndParameterSelect
        width={6}
        directImport={true}
        label={['Location', 'Parameter']}
        plant={this.props.plant}
        value={{
          location: {
            label: operand.parameter["locationLabel"],
            value: operand.parameter["locationid"],
            sensor_type_id: operand.parameter["sensor_type_id"],
          },
          readingtype: {
            label: operand.parameter["readingtypeLabel"],
            value: operand.parameter["readingtypeid"]
          }
        }}
        onChange={locParam => {
          operand.parameter["locationid"] = locParam.location.value;
          operand.parameter["locationLabel"] = locParam.location.label;
          operand.parameter["readingtypeid"] = locParam.readingtype.value
          operand.parameter["readingtypeLabel"] = locParam.readingtype.label;
          operand.parameter["is_calculated"] = locParam.readingtype.is_calculated;
          operand.parameter["sensor_type_id"] = locParam.location.sensor_type_id;
          this.props.onOperandChange(operand)
        }}
      />
    </Row>
  }

  getConstantOperand = (operand) => {
    return <Col xl={6} lg={6} md={6} sm={6} xs={6}>
      <Label>Value</Label>
      <Input
        value={operand.value}
        placeholder="value"
        type="text"
        onChange={(e, isInValid) => {
          operand["value"] = e.target.value;
          this.setState({ operand });
          this.props.onOperandChange(operand)
        }}
      />
    </Col>
  }

  render() {
    let operand = this.props.operand;
    return (<div className="operand">
      <Col xl={12} lg={12} md={12} sm={12} xs={12} className="header">
        <label className="radio-inline">
          <input
            name={operand.id}
            value="parameter"
            type="radio"
            className='radio-inline'
            checked={operand.type === "parameter"}
            onChange={this.onOperandTypeChange} />
          <span>Parameter</span>
        </label>
        <label className="radio-inline">
          <input
            name={operand.id}
            value="constant"
            type="radio"
            checked={operand.type === "constant"}
            onChange={this.onOperandTypeChange}
          />
          <span>Constant Value</span>
        </label>
        <label className="radio-inline">
          <input
            name={operand.id}
            value="formula"
            type="radio"
            checked={operand.type === 'formula'}
            onChange={this.onOperandTypeChange}
          />
          <span>Formula</span>
        </label>
        {this.props.showDelete && <span className="text-danger delete" onClick={() => { this.props.onDelete && this.props.onDelete() }}>Delete</span>}
      </Col>
      {operand.type === 'parameter' && this.getParameterOperand(operand)}
      {operand.type === 'constant' && this.getConstantOperand(operand)}
      {
        operand.type === 'formula' &&
        <Formula
          onChange={this.onFormulaChange}
          formula={operand.formula}
        />
      }
    </div>);
  }
}

class Formula extends Component {

  onOperandChange = (operand, index) => {
    let formula = this.props.formula;
    formula[index] = operand;
    this.props.onChange(formula);
  }

  addOperand = () => {
    let formula = this.props.formula;
    formula.push({ type: "operator", value: "+" });
    formula.push({ type: "parameter", parameter: { locationLabel: "", locationLabel: "", readingtypeid: "", readingtypeLabel: "" } });
    this.props.onChange(formula);
  }

  render() {
    let formula = this.props.formula;
    return (<div className="width100">
      <Col xl={12} lg={12} md={12} sm={12} xs={12} className="formulaMaker">
        {
          formula && formula.map((item, index) => {
            return item.type === "operator" ?
              <div className="operator" key={index}>
                <SelectDropDown
                  onChange={(selected) => {
                    formula[index].value = selected.value;
                    this.props.onChange(formula);
                  }}
                  value={operations.find(i => i.value === item.value)}
                  name="operator"
                  options={operations} />
              </div>
              :
              <div key={index}>
                <Operand
                  showDelete={index !== 0}
                  operand={JSON.parse(JSON.stringify(item))}
                  plant={this.props.plant}
                  onOperandChange={(operand) => this.onOperandChange(operand, index)}
                  onDelete={() => {
                    formula.splice(index - 1, 2);
                    this.props.onChange(formula);
                  }}
                />
              </div>
          })
        }
        <Col xl={3} lg={3} md={3} sm={3} xs={3}>
          <Button buttonType='primary' size='sm'
            buttonClick={this.addOperand}
            innerContent='+ Add Operand'
          />
        </Col>
      </Col>
    </div>);
  }
}

const _stateGet = () => {
  return {
    equation: 'Formula goes here',
    formula: [
      {
        type: 'parameter',
        parameter: { locationid: null, readingtypeid: null }
      }
    ],
    showPartial: false
  };
};

export class FormulaMaker extends Component {
  state = _stateGet();

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { formula, equation, showPartial } =
      (nextProps.value.formula && nextProps.value) || _stateGet();

    this.setState({
      formula,
      equation,
      showPartial
    });
  };

  componentDidMount = () => {
    const { formula, equation, showPartial } =
      (this.props.value.formula && this.props.value) || _stateGet();

    this.setState({
      formula,
      equation,
      showPartial
    });
  }

  isFormulaValid = (formula) => {
    if (Array.isArray(formula)) {
      return formula.filter(f => !this.isFormulaValid(f)).length === 0
    } else if (typeof (formula) === 'object') {
      switch (formula.type) {
        case 'operator':
          return operations.map(o => o.value).includes(formula.value)
        case 'parameter':
          return formula.parameter.locationLabel
            && formula.parameter.locationid
            && formula.parameter.readingtypeid
            && formula.parameter.readingtypeLabel;
        case 'formula':
          return this.isFormulaValid(formula.formula);
        case 'constant':
          return formula.value !== undefined && formula.value !== null && formula.value !== '';
      }
    }
    return false;
  }

  onFormulaChange = formula => {
    let equation = this.getEquation(formula);
    this.setState({ equation });
    const isFormulaValid = this.isFormulaValid(formula);
    this.props.onChange({ formula, equation }, !isFormulaValid);
  }

  getEquation = formula => {
    let eq = "";
    formula && formula.forEach(operand => {
      if (operand.type === "parameter") {
        eq += operand.parameter.locationLabel ? " " + operand.parameter.locationLabel : "";
        eq += operand.parameter.readingtypeLabel ? "_" + operand.parameter.readingtypeLabel : "";
      } else if (operand.type === "constant") {
        eq += operand.value ? " " + operand.value : "";
      } else if (operand.type === "formula") {
        eq += " ( " + this.getEquation(operand.formula) + " ) ";
      } else if (operand.type === "operator") {
        eq += operand.value ? " " + operand.value : "";
      }
    })
    return eq;
  }

  render() {
    return (
      <Fragment>
        <Row>
          {this.props.showPartialVisiblityToggle && (
            <Col
              md={3}
              className='d-flex justify-content-end align-items-end ml-auto'
            >
              <div>
                <CheckBox
                  label='Show Partial Calculation Result'
                  checked={this.state.showPartial}
                  checkboxClicked={(showPartial) => {
                    this.props.onChange({
                      ...this.state,
                      showPartial
                    });
                  }}
                />
              </div>
            </Col>
          )}
        </Row>

        {[
          <Formula
            key={1}
            onChange={this.onFormulaChange}
            formula={this.state.formula}
            plant={this.props.plant}
          />,
          // <Col xl={12} lg={12} md={12} sm={12} xs={12}>{this.state.equation}</Col>,
          <Fragment key={2}>
            <span className='preview'>Preview:</span>

            <div className='formula-maker'>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                {' '}
                {this.state.equation}
              </Col>
            </div>
          </Fragment>
        ]}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

FormulaMaker.defaultProps = {
  appParams: {},
  appInputParamChanged: function () { }
}

FormulaMaker.propTypes = {
  appParams: PropTypes.object,
  appInputParamChanged: PropTypes.func
}

export default connect(mapStateToProps, {})(FormulaMaker);