import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateAlertLogStatus, getAllNotificationCount } from '../../../Alert/action';
import { Input } from '../../../../../common/components/';

function notifyActions(props) {
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("");
    const [error, setError] = useState(false);
    let { id } = props.data && props.data.content;

    const updateStatus = ()=>{
        if(description){
            props.updateAlertLogStatus({ alert_status: [{ alert_log_id: id, status: status }], description: description });
            props.notificationStatus();
            props.getAllNotificationCount();
            setStatus("")
            props.setNotificationId("")
        }else{
            setError(true);
        }
    }


    return <React.Fragment>{status && props.notificationId == id ?
        <div>
    <Input 
    name="description"
    value={description}
    label="Description"
    type="text"
    inputChanged={(value, isInValid) => {
        setDescription(value)
        setError(false)
    }}
    placeholder="Description"
    disabled={false}
    required={true}
    />
    {error && <span style={{ padding: "15px", fontSize: "14px", color: "red"}}>Description can't be empty</span>}
    <div className="create-dashboard-modal-footer d-flex justify-content-between">
    <button className="cancel-button" onClick={()=>{
        setStatus("")
        props.setNotificationId("")
        setError(false);
        }}>
        Cancel
    </button>
    <button className="add-button" onClick={updateStatus}>
        Submit
    </button>
    </div>
    </div>
    :
    <p className='nofity-actions'>
        <span onClick={(e) => {
            e.stopPropagation();
            setStatus("acknowledge")
            props.setNotificationId(id)
        }}>Acknowledge</span>
        <span onClick={(e) => {
            e.stopPropagation();
            setStatus("resolved")
            props.setNotificationId(id)
        }}>Resolve</span>
        <span onClick={(e) => {
            e.stopPropagation();
            setStatus("ignore")
            props.setNotificationId(id)
        }}>Ignore</span>
    </p>}
    </React.Fragment>
}

const mapStateToProps = state => {
    return {
    };
};

export default connect(
    mapStateToProps, {
    updateAlertLogStatus,
    getAllNotificationCount
}
)(notifyActions);