import moment from 'moment';
import {
    GET_AVERAGE_DEMAND_DATA,
    GET_AVERAGE_DEMAND_DATA_FAILURE,
    GET_AVERAGE_DEMAND_DATA_SUCCESS,
    GET_DATE_RANGE_DEMAND_DATA,
    GET_DATE_RANGE_DEMAND_DATA_SUCCESS,
    GET_DATE_RANGE_DEMAND_DATA_FAILURE,
    GET_DEMAND_ANALYSIS_CHART,
    GET_DEMAND_ANALYSIS_CHART_SUCCESS,
    GET_DEMAND_ANALYSIS_CHART_FAILURE,
    GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_INIT,
    GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_RESULT,
    GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_RESULT_FAILURE,
    GET_DATE_RANGE_DEMAND_FORECAST_DATA,
    GET_DATE_RANGE_DEMAND_FORECAST_DATA_SUCCESS,
    GET_DATE_RANGE_DEMAND_FORECAST_DATA_FAILURE,
    GET_DATE_RANGE_DEMAND_FORECAST_DIFF_INIT,
    GET_DATE_RANGE_DEMAND_FORECAST_DIFF_DATA_FAILURE,
    GET_DATE_RANGE_DEMAND_FORECAST_DIFF_DATA_SUCCESS
} from "./constant";
import {
    DATE_RANGE_LIST
} from '../utils';

const AVERAGE_INITIAL_STATE = {
    data: {
        average: {
            value: null,
            unit: ''
        },
        minimumValue: {
            value: null,
            unit: '',
            eventDate: moment(),
        },
        maximumValue: {
            value: null,
            eventDate: moment(),
        },
        unit: null
    },
    loading: false,
    error: false
};

const DEMAND_INITIAL_STATE = {};
for (let i = 0; i < DATE_RANGE_LIST.length; i++) {
    const dateRange = DATE_RANGE_LIST[i];
    DEMAND_INITIAL_STATE[dateRange] = {
        data: {
            value: null,
            unit: null,
            month: null
        },
        loading: false,
        error: false
    };
}

const DEMAND_DIFF_INITIAL_STATE = {};
for (let i = 0; i < DATE_RANGE_LIST.length; i++) {
    const dateRange = DATE_RANGE_LIST[i];
    DEMAND_DIFF_INITIAL_STATE[dateRange] = {
        data: {
            value: 0,
            unit: '',
            trend: ''
        },
        loading: false,
        error: false
    };
}

const DEMAND_CHART_INITIAL_STATE = {
    data: [],
    loading: false,
    error: false
};

export function getAverageDemandReducer(state = AVERAGE_INITIAL_STATE, action) {
    switch (action.type) {
        case GET_AVERAGE_DEMAND_DATA:
            return {
                ...state,
                loading: true,
                data: AVERAGE_INITIAL_STATE.data,
                error: false
            };
        case GET_AVERAGE_DEMAND_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_AVERAGE_DEMAND_DATA_FAILURE:
            return {
                ...state,
                error: true,
                data: AVERAGE_INITIAL_STATE.data,
                loading: false
            };
        default:
            return state
    }
}

export function getDemandDateRangeReducer(state = DEMAND_INITIAL_STATE, action) {
    const {payload} = action;
    let currentValue;
    switch (action.type) {
        case GET_DATE_RANGE_DEMAND_DATA:
            currentValue = {
                [payload.request_key]: {
                    loading: true,
                    data: DEMAND_INITIAL_STATE[payload.request_key].data,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_DEMAND_DATA_SUCCESS:
            currentValue = {
                [payload.request_key]: {
                    loading: false,
                    data: payload,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_DEMAND_DATA_FAILURE:
            currentValue = {
                [payload.request_key]: {
                    error: true,
                    data: DEMAND_INITIAL_STATE[payload.request_key].data,
                    loading: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        default:
            return state
    }
}

export function getDemandDateRangeForeCastReducer(state = DEMAND_INITIAL_STATE, action) {
    const {payload} = action;
    let currentValue;
    switch (action.type) {
        case GET_DATE_RANGE_DEMAND_FORECAST_DATA:
            currentValue = {
                [payload.request_key]: {
                    loading: true,
                    data: DEMAND_INITIAL_STATE[payload.request_key].data,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_DEMAND_FORECAST_DATA_SUCCESS:
            currentValue = {
                [payload.request_key]: {
                    loading: false,
                    data: payload,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_DEMAND_FORECAST_DATA_FAILURE:
            currentValue = {
                [payload.request_key]: {
                    error: true,
                    data: DEMAND_INITIAL_STATE[payload.request_key].data,
                    loading: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        default:
            return state
    }
}


export function getDemandDateRangeDifferenceReducer(state = DEMAND_DIFF_INITIAL_STATE, action) {
    const {payload} = action;
    let currentValue;
    switch (action.type) {
        case GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_INIT:
            currentValue = {
                [payload.request_key]: {
                    loading: true,
                    data: DEMAND_DIFF_INITIAL_STATE[payload.request_key].data,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_RESULT:
            currentValue = {
                [payload.request_key]: {
                    loading: false,
                    data: payload,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_DIFFERENCE_DEMAND_DATA_RESULT_FAILURE:
            currentValue = {
                [payload.request_key]: {
                    error: true,
                    data: DEMAND_DIFF_INITIAL_STATE[payload.request_key].data,
                    loading: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        default:
            return state
    }
}


export function getDemandDateRangeForecastDifferenceReducer(state = DEMAND_DIFF_INITIAL_STATE, action) {
    const {payload} = action;
    let currentValue;
    switch (action.type) {
        case GET_DATE_RANGE_DEMAND_FORECAST_DIFF_INIT:
            currentValue = {
                [payload.request_key]: {
                    loading: true,
                    data: DEMAND_DIFF_INITIAL_STATE[payload.request_key].data,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_DEMAND_FORECAST_DIFF_DATA_SUCCESS:
            currentValue = {
                [payload.request_key]: {
                    loading: false,
                    data: payload,
                    error: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        case GET_DATE_RANGE_DEMAND_FORECAST_DIFF_DATA_FAILURE:
            currentValue = {
                [payload.request_key]: {
                    error: true,
                    data: DEMAND_DIFF_INITIAL_STATE[payload.request_key].data,
                    loading: false
                }
            };
            return {
                ...state,
                ...currentValue
            };
        default:
            return state
    }
}

export function getDemandAnalysisChartReducer(state = DEMAND_CHART_INITIAL_STATE, action) {
    switch (action.type) {
        case GET_DEMAND_ANALYSIS_CHART:
            return {
                ...state,
                loading: true,
                data: DEMAND_CHART_INITIAL_STATE.data,
                error: false
            };
        case GET_DEMAND_ANALYSIS_CHART_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false
            };
        case GET_DEMAND_ANALYSIS_CHART_FAILURE:
            return {
                ...state,
                error: true,
                data: DEMAND_CHART_INITIAL_STATE.data,
                loading: false
            };
        default:
            return state
    }
}
