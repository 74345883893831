import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DateTimeRangePicker, Icon } from '../../../../../common/components';
import  dateTimeUtils from '../../../../../common/utils/dateTimeUtils';
import { ICON_POSITION } from '../../../../../common/components/Icon/constants';
import ChevronDown from '../../../../../../assets/images/Icons/Arrows/Chevron-DownSVG.svg';

function WidgetCalenderConfig(props) {
  const defaultTrigger = () =>{
    let dateLabel = 
    dateTimeUtils.isValid(props.startDate) && 
    dateTimeUtils.isValid(props.endDate) ?
    (
      dateTimeUtils.format(props.startDate, 'MMM DD, YYYY') + ' 00:00' + ' - ' + 
      dateTimeUtils.format(props.endDate, 'MMM DD, YYYY') + ' 23:59'
    )
    :
    'Custom';

    return (
      <div className = 'calender-trigger'>
        <div className = 'calender-trigger-label'>{dateLabel}</div>
        {/* <span claevent-activity-summary-widget */}
        {/* <Icon src={ChevronDown} size = {20} position = {ICON_POSITION.CENTER_RIGHT}/> */}
        {/* <i className="fa fa-calendar"></i> */}
      </div>
    )
  }
  return (
    <div className={"inview-configuration-calender " + props.position}>
      <DateTimeRangePicker 
          preSelectedPreset={props.datePreset}
          withPortal={true}
          preset = "multi"
          getTrigger = {() => {
            return defaultTrigger()
          }}
          startDate={props.startDate}
          endDate={props.endDate}
          onDatesChange={props.handleDateRangeChange}
        />
    </div>
  );
}


const mapStateToProps = (state) => {
    return {};
};

WidgetCalenderConfig.defaultProps = {
  datePreset: 0,
  startDate: null,
  endDate: null,
  handleDateRangeChange: function(){},
  // position: 'block'
  position: 'widget-center'
}

WidgetCalenderConfig.propTypes = {
  datePreset: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  handleDateRangeChange: PropTypes.func,
  position: PropTypes.string
}

export default WidgetCalenderConfig;