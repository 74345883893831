import React from "react";
import CommonSearch from "../CommonSearch";

function ClientAccounts(props) {

    let { clients } = props;

    return (
        <div className={props.isSearchBarOpen ? 'accounts' : 'pt-20 accounts'}>
            <CommonSearch
                setSearchBar={props.setSearchBar}
                title="Select the Client’s account you want to access"
                isSearchBarOpen={props.isSearchBarOpen}
                setSearchValue={(value) => {
                    props.setSearchValue(value)
                    props.setActiveSearch('clientAccount')
                }}
                searchValue={props.searchValue}

            />
            <div className='partner-login-radio'>
                {
                    clients.loading == true ? 'Loading...' :
                        clients.data && Array.isArray(props.clients.data) && clients.data.length > 0 ? clients.data.map((item, index) => {
                            return <label className="partner-login-label" key={index}>
                                {item.clientname}
                                <input type="radio" name="radio" checked={item.clientid == props.clientAccountType.id ? true : false} onChange={() => {
                                    props.setClientAccountType({
                                        id: item.clientid,
                                        type: 'clientAccount'
                                    });
                                    props.setActiveStep('clientAccount');
                                }} />
                                <span className="checkmark"></span>
                            </label>
                        }) : <span>Client's not found!</span>
                }
            </div>
        </div>
    );
}

export default ClientAccounts;