import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';

import graphParamTypeCollectionGet from '../common/paramTypeCollectionGet';
import parameterConfsInputGet from '../common/parameterConfsInputGet';
import formulaConfsInputGet from '../common/formulaConfsInputGet';
import parameterConfsOutputGet from '../common/parameterConfsOutputGet';
import {
  Input,
  Select,
  RadioButtons
} from '../../../../../../../common/components';
import infoIcon from '../../../../../../../../assets/images/infoIcon.svg';
import _ParameterConfig from './_ParameterConfig';
import ParameterConfigNormal from './ParameterConfigNormal';
import formulaConfsOutputGet from '../common/formulaConfsOutputGet';

const valueTypeCollection = ['Value', 'Live Value'];

const paramTypeCollection = ['Cost', 'Trend'];

const graphParamTypeCollection = graphParamTypeCollectionGet();

const costingTypeCollection = ['Total', 'Minimum', 'Maximum'];

const currencyCollection = [
  '₹ (Indian Rupees)',
  'AED (United Arab Emirates Dirham)',
  'Rp (Indonesian Rupiah)',
  'MYR (Malaysian Ringgit)',
  'S$ (Singapore dollar)',
  '₫ (Vietnamese dong)',
  '$ (US Dollar)',
  '€ (Europe - Euro)',
  'A$ (Australian dollar)'
];

const numberSystemCollection = ['Lakhs and Crores', 'Millions and Billions'];

const parameterConfsGet = (value) => {
  const parameterConfs = value && !value.isComputed && [{ locParam: value }];

  return parameterConfsInputGet(parameterConfs).reduce(
    (memo, parameterConf) => {
      return [
        ...memo,
        {
          ...parameterConf,
          isComputed: false
        }
      ];
    },
    []
  );
};

const formulaConfsGet = (value) => {
  const formulaConfs =
    value &&
    value.isComputed &&
    (() => {
      const { formula, equation } = value.computedFormula;

      return [
        {
          formula: [{ formula }],
          equation
        }
      ];
    })();

  return formulaConfsInputGet(formulaConfs).reduce((memo, formulaConf) => {
    return [
      ...memo,
      {
        ...formulaConf,
        isComputed: true
      }
    ];
  }, []);
};

const _parameterConfsGet = (parameterConfs) => {
  const _parameterConfs = parameterConfsOutputGet(parameterConfs);

  return _parameterConfs && _parameterConfs[0].locParam;
};

const _formulaConfsGet = (formulaConfs) => {
  const _formulaConfs = formulaConfsOutputGet(formulaConfs);

  return (
    _formulaConfs &&
    (() => {
      const { formula, equation, ...formulaConf } = _formulaConfs[0];

      return {
        ...formulaConf,
        computedFormula: {
          ...formula[0],
          equation
        }
      };
    })()
  );
};

const isInValidGet = (value) => {
  switch (true) {
    case !value.value:
    case !value.live:
    case value.paramType === paramTypeCollection[0] && !value.cost:
    case value.paramType === paramTypeCollection[1] && !value.trend:
      return true;

    default:
      return false;
  }
};

const ParametersConfig = (props) => {
  const [initialized, setInitialized] = useState(false);

  const [activeValueType, setActiveValueType] = useState(0);

  const [paramType, setParamType] = useState();

  const [cost, setCost] = useState();

  const [trend, setTrend] = useState();

  const [valueParamType, setValueParamType] = useState();

  const [valueParameterConfs, setValueParameterConfs] = useState();

  const [valueFormulaConfs, setValueFormulaConfs] = useState();

  const [liveParamType, setLiveParamType] = useState();

  const [liveParameterConfs, setLiveParameterConfs] = useState();

  const [liveFormulaConfs, setLiveFormulaConfs] = useState();

  const paramTypeActiveIndex = paramTypeCollection.findIndex((type) => {
    return type === paramType;
  });

  const valueParamTypeActiveIndex = graphParamTypeCollection.findIndex(
    (_paramType) => _paramType === valueParamType
  );

  const liveParamTypeActiveIndex = graphParamTypeCollection.findIndex(
    (_paramType) => _paramType === liveParamType
  );

  useEffect(() => {
    if (!initialized && props.widgetParams) {
      const conf =
        props.widgetParams.digitboxConfig &&
        props.widgetParams.digitboxConfig.value;

      setParamType(
        (conf &&
          conf.paramType &&
          paramTypeCollection.find(
            (paramType) => paramType === conf.paramType
          )) ||
          paramTypeCollection[0]
      );

      setCost((conf && conf.cost) || {});

      setTrend(parameterConfsGet(conf && conf.trend));

      setValueParamType(
        conf && conf.value && conf.value.isComputed
          ? graphParamTypeCollection[1]
          : graphParamTypeCollection[0]
      );

      setValueParameterConfs(parameterConfsGet(conf && conf.value));

      setValueFormulaConfs(formulaConfsGet(conf && conf.value));

      setLiveParameterConfs(parameterConfsGet(conf && conf.live));

      setLiveParamType(
        conf && conf.live && conf.live.isComputed
          ? graphParamTypeCollection[1]
          : graphParamTypeCollection[0]
      );

      setLiveFormulaConfs(formulaConfsGet(conf && conf.live));

      setInitialized(true);
    }
  }, [initialized, props.widgetParams]);

  const appInputParamChangedHandle = (object) => {
    const outputGet = (parameterConfs, formulaConfs, graphParamType) => {
      return graphParamType === graphParamTypeCollection[0]
        ? _parameterConfsGet(parameterConfs)
        : _formulaConfsGet(formulaConfs);
    };

    const value = {
      type: props.type,
      paramType,
      cost,
      trend: _parameterConfsGet(trend),
      value: outputGet(valueParameterConfs, valueFormulaConfs, valueParamType),
      live: outputGet(liveParameterConfs, liveFormulaConfs, liveParamType),
      ...object
    };

    const isInValid = isInValidGet(value);

    props.appInputParamChanged({
      digitboxConfig: {
        value,
        isInValid
      }
    });
  };

  const renderCost = () => {
    return (
      <Row>
        <Col md={6}>
          <div className='new-input-wrap'>
            <label className='label-text'>Costing Type</label>

            <Select
              options={costingTypeCollection.reduce((memo, value) => {
                return [
                  ...memo,
                  {
                    label: value,
                    value
                  }
                ];
              }, [])}
              isMulti={false}
              placeholder='Select Costing Type'
              value={(() => {
                const value = cost.costingType;

                return (
                  value && {
                    label: value,
                    value
                  }
                );
              })()}
              onChange={({ value }) => {
                const _cost = {
                  ...cost,
                  costingType: value
                };

                setCost(_cost);

                appInputParamChangedHandle({ cost: _cost });
              }}
            />
          </div>
        </Col>

        <Col md={6}>
          <div className='new-input-wrap'>
            <label className='label-text'>Currency</label>

            <Select
              options={currencyCollection.reduce((memo, value) => {
                return [
                  ...memo,
                  {
                    label: value,
                    value
                  }
                ];
              }, [])}
              isMulti={false}
              placeholder='Select Currency'
              value={(() => {
                const value = cost.currency;

                return (
                  value && {
                    label: value,
                    value
                  }
                );
              })()}
              onChange={({ value }) => {
                const _cost = {
                  ...cost,
                  currency: value
                };

                setCost(_cost);

                appInputParamChangedHandle({ cost: _cost });
              }}
            />
          </div>
        </Col>

        <Col md={6}>
          <div className='new-input-wrap'>
            <label className='label-text'>Number System</label>

            <Select
              options={numberSystemCollection.reduce((memo, value) => {
                return [
                  ...memo,
                  {
                    label: value,
                    value
                  }
                ];
              }, [])}
              isMulti={false}
              placeholder='Select Number System'
              value={(() => {
                const value = cost.numbersystem;

                return (
                  value && {
                    label: value,
                    value
                  }
                );
              })()}
              onChange={({ value }) => {
                const _cost = {
                  ...cost,
                  numbersystem: value
                };

                setCost(_cost);

                appInputParamChangedHandle({ cost: _cost });
              }}
            />
          </div>
        </Col>

        <Col md={6}>
          <div className='new-input-wrap'>
            <label className='label-text'>Unit Rate</label>

            <Input
              size='sm'
              label=''
              type='text'
              required={false}
              placeholder='Enter Unit Rate'
              value={cost.rate || ''}
              inputChanged={(rate) => {
                const _cost = {
                  ...cost,
                  rate
                };

                setCost(_cost);

                appInputParamChangedHandle({ cost: _cost });
              }}
            />

            <div className='input-notify'>
              <img src={infoIcon} />
              <p>Preferred to use with unit parameters like kwh, kvarh etc.</p>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const renderTrend = () => {
    return (
      <ParameterConfigNormal
        parameterConfs={trend}
        onParameterConfCollectionUpdateTrigger={(_parameterConf) => {
          const trend = [_parameterConf];

          setTrend(trend);

          appInputParamChangedHandle({ trend: _parameterConfsGet(trend) });
        }}
      />
    );
  };

  const renderValueParameterConfig = () => {
    return (
      <Fragment key='value'>
        <_ParameterConfig
          paramTypeCollection={graphParamTypeCollection}
          paramTypeActiveIndex={valueParamTypeActiveIndex}
          parameterConfs={valueParameterConfs}
          formulaConfs={valueFormulaConfs}
          onParamTypeChange={(_paramType) => {
            setValueParamType(_paramType);
          }}
          onParameterConfsChange={(_parameterConfs) => {
            setValueParameterConfs(_parameterConfs);

            appInputParamChangedHandle({
              value: _parameterConfsGet(_parameterConfs)
            });
          }}
          onFormulaConfsChange={(_formulaConfs) => {
            setValueFormulaConfs(_formulaConfs);

            appInputParamChangedHandle({
              value: _formulaConfsGet(_formulaConfs)
            });
          }}
        />
      </Fragment>
    );
  };

  const renderLiveParameterConfig = () => {
    return (
      <Fragment key='live'>
        <_ParameterConfig
          paramTypeCollection={graphParamTypeCollection}
          paramTypeActiveIndex={liveParamTypeActiveIndex}
          parameterConfs={liveParameterConfs}
          formulaConfs={liveFormulaConfs}
          onParamTypeChange={(_paramType) => {
            setLiveParamType(_paramType);
          }}
          onParameterConfsChange={(_parameterConfs) => {
            setLiveParameterConfs(_parameterConfs);

            appInputParamChangedHandle({
              live: _parameterConfsGet(_parameterConfs)
            });
          }}
          onFormulaConfsChange={(_formulaConfs) => {
            setLiveFormulaConfs(_formulaConfs);

            appInputParamChangedHandle({
              live: _formulaConfsGet(_formulaConfs)
            });
          }}
        />
      </Fragment>
    );
  };

  const renderFn = () => {
    return (
      <div className='content-border-wrap'>
        <Row>
          <Col md={12}>
            <div className='new-input-wrap'>
              <RadioButtons
                noOfButtons={2}
                getButtonLableAtIndex={(i) => {
                  return valueTypeCollection[i];
                }}
                buttonClickAtIndex={(i) => {
                  setActiveValueType(i);
                }}
                active={activeValueType}
              />
            </div>
          </Col>
        </Row>

        <div className='content-border-wrap'>
          <Row>
            <Col md={12}>
              <div className='new-input-wrap'>
                <RadioButtons
                  noOfButtons={2}
                  getButtonLableAtIndex={(index) => {
                    return paramTypeCollection[index];
                  }}
                  buttonClickAtIndex={(index) => {
                    const paramType = paramTypeCollection[index];

                    setParamType(paramType);

                    appInputParamChangedHandle({
                      paramType
                    });
                  }}
                  active={paramTypeActiveIndex}
                />
              </div>
            </Col>
          </Row>

          {paramTypeActiveIndex === 0 ? renderCost() : renderTrend()}

          {activeValueType === 0
            ? renderValueParameterConfig()
            : renderLiveParameterConfig()}
        </div>
      </div>
    );
  };

  return <Fragment>{initialized && renderFn()}</Fragment>;
};

export default ParametersConfig;
