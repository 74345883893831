import formulaConfIdGet from './formulaConfIdGet';
import formulaConfInputGet from './formulaConfInputGet';

export default (formulaConfs) => {
  return formulaConfs
    ? formulaConfs.reduce((memo, formulaConf) => {
        return [
          ...memo,
          {
            ...formulaConf,
            formula: [
              {
                id: formulaConfIdGet(),
                type: 'formula',
                ...(() => {
                  return formulaConf.formula[0].type === 'parameter'
                    ? {}
                    : formulaConf.formula[0];
                })(),
                formula:
                  formulaConf.formula[0].type === 'parameter' ||
                  formulaConf.formula[0].type === 'constant'
                    ? formulaConf.formula
                    : formulaConf.formula[0].formula.reduce((memo, formula) => {
                        return [
                          ...memo,
                          {
                            ...formula,
                            ...(() => {
                              return formula.parameter
                                ? {
                                    parameter: {
                                      ...formula.parameter,
                                      locationid: formula.parameter.locationid
                                        ? formula.parameter.locationid
                                        : formula.parameter.sensorid
                                    }
                                  }
                                : {};
                            })()
                          }
                        ];
                      }, [])
              }
            ]
          }
        ];
      }, [])
    : [formulaConfInputGet()];
};
